import { Injectable } from '@angular/core';
import { ExportProjectPlannerEvent } from '../planner-module-interfaces';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PlannerExportSettingsService {
  private plannerExportSettingsLocalStorageKey = 'plannerExportSettings';

  public storeExportSettingsLocalStorage(
    event: ExportProjectPlannerEvent
  ): void {
    const { start, end, format, landscape, fileFormat } = event;
    const settings = {
      start,
      end,
      format,
      landscape,
      fileFormat,
    };

    localStorage.setItem(
      this.plannerExportSettingsLocalStorageKey,
      JSON.stringify(settings)
    );
  }

  public getExportSettingsLocalStorage(): ExportProjectPlannerEvent {
    const settings = JSON.parse(
      localStorage.getItem(this.plannerExportSettingsLocalStorageKey)
    ) as ExportProjectPlannerEvent;

    if (!settings) {
      return null;
    }

    settings.start = moment(settings.start).format('YYYY-MM-DD');
    settings.end = moment(settings.end).format('YYYY-MM-DD');

    return settings;
  }

  public deleteExportSettingsLocalStorage(): void {
    localStorage.removeItem(this.plannerExportSettingsLocalStorageKey);
  }
}
