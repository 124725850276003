<div class="modal-header" style="text-align: center">
  <h3 class="modal-title">Du har blivit utloggad</h3>
</div>
<div class="modal-body">
  <div style="text-align: center; margin: 25px 0 40px" *ngIf="storedUserValues">
    <h4>
      Lås upp som <b>{{ meUser.fullName }}</b> eller logga ut
    </h4>
  </div>
  <div
    style="text-align: center; margin: 25px 0 40px"
    *ngIf="!storedUserValues"
  >
    <h4>Ange uppgifter för att låsa upp eller logga ut</h4>
  </div>
  <form class="form-locked-screen">
    <div *ngIf="!storedUserValues">
      <label for="locked-screen-email">Användarnamn</label>
      <input
        style="
          margin-bottom: 0.3em;
          padding: 0.3em;
          background-color: #f2f2f2;
          border: 1px solid #e2e2e2;
        "
        type="email"
        id="locked-screen-email"
        class="form-control validate"
        placeholder="Användarnamn"
        [(ngModel)]="email"
        name="email"
      />
    </div>
    <label for="locked-screen-password">Lösenord</label>
    <input
      style="
        margin-bottom: 1em;
        padding: 0.3em;
        background-color: #f2f2f2;
        border: 1px solid #e2e2e2;
      "
      type="password"
      id="locked-screen-password"
      class="form-control validate"
      placeholder="Lösenord"
      [(ngModel)]="password"
      name="password"
    />
    <div class="row gutter-10">
      <div class="col-lg-12">
        <p-button
          label="Lås upp"
          icon="fa fa-key"
          (click)="unlockRequest(password)"
        ></p-button>
      </div>
    </div>
    <div class="row gutter-10" style="margin-top: 10px">
      <div class="col-lg-12 text-xs-center">
        <small
          >Eller
          <a (click)="goToLogin()" style="color: #00c9cc; cursor: pointer"
            >logga ut</a
          >
        </small>
      </div>
    </div>
  </form>
</div>
