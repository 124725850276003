import { gql } from 'apollo-angular';

// GQL Queries

const PURCHASE_EDI_ORDERS = {
  query: gql`
    query fetchPurchaseEdiOrders($last: Int) {
      company {
        id
        ediOrders(last: $last) {
          edges {
            node {
              id
              created
              companyEdiId
              supplierFolderName
              sellerOrderResponseNumber
              savedToProject
              buyerOrderResponseNumber
              projectId
              orderedBy
              project {
                id
                trueId
                mark
              }
              rows {
                edges {
                  node {
                    id
                    currencyCoded
                    itemDescription
                    quantityValue
                    unitPriceValue
                    UOMCoded
                  }
                }
              }
              source {
                name
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    last: 50,
  },
};

const EDI_SUPPLIERS = {
  query: gql`
    query fetchEdiSuppliers {
      company {
        id
        ediSuppliers {
          edges {
            node {
              id
              supplier {
                id
                name
              }
            }
          }
        }
      }
    }
  `,
};

/** Export GQL Queries on Edi Orders */
export const queries = {
  PurchaseEdiOrders: PURCHASE_EDI_ORDERS,
  EdiSuppliers: EDI_SUPPLIERS,
};
