import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { first } from 'rxjs';

import { HtmlModalService } from 'app/shared/html-modal';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { SortService } from 'app/store/sort.service';
import { CustomSort } from 'app/store/custom-sort';
import { UserFlagsService } from 'app/user-flags.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'app/shared/message/message.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'project-underlag',
  templateUrl: 'project-underlag.component.html',
})
export class ProjectUnderlagComponent implements OnInit, OnDestroy {
  loading = true;
  public projectInfo;
  public functionsThisModel;
  substratesDialogDisplay = false;
  substratesForm = {
    radio: null,
    from: '',
    to: '',
  };
  invoiceId = 0;
  public subratesCheckboxes = {
    reportDaysGroup: {
      visible: true,
      checked: true,
      parameters: { daysGroup: 1 },
    },
    displayWorker: {
      visible: false,
      checked: false,
      parameters: { displayWorker: 1 },
    },
    displayCostType: {
      visible: false,
      checked: false,
      parameters: { displayCostType: 1 },
    },
    displayComments: {
      visible: true,
      checked: true,
      parameters: { displayComments: 1 },
    },
    displayHours: {
      visible: true,
      checked: true,
      parameters: { displayHours: 1 },
    },
    displayMiles: {
      visible: true,
      checked: true,
      parameters: { displayMiles: 1 },
    },
    displayProductPrice: {
      visible: true,
      checked: true,
      parameters: { displayProductPrice: 1 },
    },
    displayProductEvenIfZero: {
      visible: true,
      checked: true,
      parameters: { displayProductEvenIfZero: 1 },
    },
    displayTodoWithTime: {
      visible: true,
      checked: false,
      parameters: { displayTodoWithTime: 1 },
    },
    displayWorkRevenue: {
      visible: true,
      checked: false,
      parameters: { displayWorkRevenue: 1 },
    },
  };
  substratesRadioOptions = {
    daysProducts: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0 },
    },
    daysProductsInvoices: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 1 },
    },
    daysProductsExtra: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, extra: 1 },
    },
    daysProductsNoExtra: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, extra: 0 },
    },
    daysProductsInvoiced: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, onlyInvoiced: 1 },
    },
    daysProductsNotInvoiced: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, onlyNotInvoiced: 1 },
    },
    onlyDays: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, onlyDays: 1 },
    },
    onlyProducts: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 0, onlyProducts: 1 },
    },
    daysPerAttendanceType: {
      renderingAction: 'printInvoice',
      parameters: {
        displayAttendanceType: 1,
        onlyDays: 1,
      },
    },
    daysPerUserCostType: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 3, displayCostType: 1 },
    },
    daysPerUserCostTypeOnlyExtra: {
      renderingAction: 'printInvoice',
      parameters: { invoices: 3, extra: 1, displayCostType: 1 },
    },
    todo: {
      renderingAction: 'printTodo',
      parameters: { hex: 2 },
    },
    offer: {
      renderingAction: 'printOffer',
      id: 'offerId',
      parameters: { hex: 2 },
    },
  };
  renderingActions = {
    printInvoice: 'project/PrintInvoicebase',
    printTodo: 'project/PrintTodo',
    printOffer: 'offer/Print/type/showPDF/id',
    printOffert: 'offert/Print/type/showPDF/id',
  };

  pdfUrl: SafeUrl;
  previewUpdate = false;
  preview = true;

  public useUserCostType = false;

  constructor(
    private domSanitizer: DomSanitizer,
    private htmlModalService: HtmlModalService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private mailDialogService: MailDialogService,
    private sortService: SortService,
    private userFlagService: UserFlagsService,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  ngOnDestroy() {
    Object.keys(this.subratesCheckboxes).forEach(checkbox => {
      const sort = {
        attribute: 'null',
        ascDesc: this.subratesCheckboxes[checkbox].checked ? 1 : 0,
        object: checkbox + '_project_specification_generator',
      };
      const event = {
        field: 'null',
        order: this.subratesCheckboxes[checkbox].checked ? 1 : 0,
      };
      this.sortService.setSort(event, sort);
    });
  }

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }
    this.reloadPreview();

    Object.keys(this.subratesCheckboxes).forEach(checkbox => {
      let sort: CustomSort = {
        attribute: 'null',
        ascDesc: this.subratesCheckboxes[checkbox].checked ? 1 : 0,
        object: 'dayreport_column_' + checkbox,
      };
      sort = this.sortService.getSort(sort);

      // eslint-disable-next-line eqeqeq
      this.subratesCheckboxes[checkbox].checked = sort.ascDesc == 1;
    });

    if (typeof this.projectInfo['invoiceId'] !== 'undefined') {
      this.invoiceId = Number(this.projectInfo['invoiceId']);
    }

    this.substratesForm.radio = this.substratesRadioOptions.daysProducts;

    this.userFlagService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.useUserCostType = flags.hasFlag('useUserCostType');
        this.loading = false;
      });
  }

  private initComponentWithDialogData() {
    this.projectInfo = this.dialogConfig.data.projectInfo;
    this.functionsThisModel = this.dialogConfig.data.functionsThisModel;
    setTimeout(() => {
      this.dialogConfig.header = 'Rapportgenerator';
    }, 0);
  }

  private getReportParameters() {
    const reportSettings = this.substratesForm.radio;

    let parameters = {
      ...reportSettings.parameters,
      from: this.substratesForm.from,
      to: this.substratesForm.to,
      fromForm: 1,
    };

    for (const keyIndex in this.subratesCheckboxes) {
      const checkboxObject = this.subratesCheckboxes[keyIndex];
      if (keyIndex === 'reportDaysGroup') {
        if (checkboxObject['checked']) {
          parameters = { ...parameters, ...checkboxObject.parameters };
        } else {
          parameters.daysGroup = 0;
        }
      } else if (checkboxObject['visible'] && checkboxObject['checked']) {
        parameters = { ...parameters, ...checkboxObject.parameters };
      }
    }

    if (this.invoiceId !== 0) {
      parameters.invoice = this.invoiceId;
    }

    const renderingAction = reportSettings.renderingAction;
    let actionId;
    if (reportSettings.id && this.projectInfo[reportSettings.id]) {
      actionId = this.projectInfo[reportSettings.id];
    } else {
      actionId = this.projectInfo.id;
    }

    return {
      parameters,
      renderingAction,
      id: actionId,
    };
  }

  private getCurrentUrl() {
    const { renderingAction, id, parameters } = this.getReportParameters();
    const queryString = Object.keys(parameters)
      .map(key => `${key}=${parameters[key]}`)
      .join('&');
    const path = [this.renderingActions[renderingAction], id].join('/');
    return `/${path}?${queryString}`;
  }

  public substratesPrint(): void {
    this.htmlModalService.ny_sida(this.getCurrentUrl(), '700', '800');
  }

  public substratesMail(): void {
    const { renderingAction, id, parameters } = this.getReportParameters();
    const files = [
      {
        path: `RENDER:${renderingAction}:${id}:${JSON.stringify(parameters)}`,
        file: 'Underlag',
      },
    ];

    this.mailDialogService.openMailDialog({
      projectId: this.projectInfo.id ? +this.projectInfo.id : 0,
      invoiceId: this.projectInfo.invoiceId ? +this.projectInfo.invoiceId : 0,
      files,
    });
  }

  closeAction() {
    this.dialogRef.close();
  }

  setCheckboxes(radioValue) {
    for (const i in this.subratesCheckboxes) {
      this.subratesCheckboxes[i]['checked'] = true;
    }

    if (radioValue === 'todoOffer') {
      for (const i in this.subratesCheckboxes) {
        this.subratesCheckboxes[i]['visible'] = false;
      }
    }
    if (radioValue === 'normal') {
      for (const i in this.subratesCheckboxes) {
        this.subratesCheckboxes[i]['visible'] = true;
      }
      this.subratesCheckboxes['displayWorker']['visible'] = false;
      this.subratesCheckboxes['displayWorker']['checked'] = false;

      this.subratesCheckboxes['displayCostType']['visible'] = false;
      this.subratesCheckboxes['displayCostType']['checked'] = false;
    }
    if (
      radioValue === 'daysPerUserCostType' ||
      radioValue === 'daysPerUserCostTypeOnlyExtra'
    ) {
      for (const i in this.subratesCheckboxes) {
        this.subratesCheckboxes[i]['visible'] = true;
      }
      // Funkar inte
      this.subratesCheckboxes['reportDaysGroup']['visible'] = false;
      this.subratesCheckboxes['displayCostType']['visible'] = false;
    }
    this.reloadPreview();
  }

  public reloadPreview(): void {
    if (this.preview) {
      this.previewUpdate = true;
      setTimeout(() => {
        this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          this.getCurrentUrl()
        );
      }, 150);
      this.previewUpdate = false;
    }
  }
  setShowReportDaysGroup(value) {
    if (value['target']['checked']) {
      this.subratesCheckboxes['displayWorker']['visible'] = false;
      this.subratesCheckboxes['displayWorker']['checked'] = false;
      this.subratesCheckboxes['displayCostType']['visible'] = false;
      this.subratesCheckboxes['displayCostType']['chekced'] = false;
    } else {
      this.subratesCheckboxes['displayWorker']['visible'] = true;
      this.subratesCheckboxes['displayCostType']['visible'] = true;
    }
  }

  setShowDisplayWorker(value) {
    if (value['target']['checked']) {
      this.subratesCheckboxes['displayTodoWithTime']['visible'] = false;
      this.subratesCheckboxes['displayTodoWithTime']['checked'] = false;
    } else {
      this.subratesCheckboxes['displayTodoWithTime']['visible'] = true;
    }
  }

  public uploadFileToProject(): void {
    const getUrl = this.getCurrentUrl() + '&saveToProject=1';
    this.http
      .get(getUrl)
      .pipe(first())
      .subscribe((res: { status: string; msg: string }) =>
        this.messageService.insertData({
          severity: res.status,
          summary: res.msg,
        } as Message)
      );
  }
}
