<ng-container *ngIf="!renaming">
  <span style="margin-right: 5px">{{ name }}</span>
  <button
    *ngIf="canRename"
    (click)="renaming = true"
    pButton
    type="button"
    icon="fa fa-edit"
  ></button>
</ng-container>
<ng-container *ngIf="renaming">
  <input
    pInputText
    #headerField
    [ngModel]="name"
    (ngModelChange)="nameChange.emit($event)"
    (blur)="renaming = false"
  />
  {{ headerField.focus() }}
</ng-container>
