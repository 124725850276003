import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { GlobalService } from '../global/index';

@Injectable()
export class IntroJSService {
  public introSubject = new Subject();

  constructor(private globalService: GlobalService, private http: HttpClient) {}

  pushIntroToSubject(arrayOfIntros) {
    this.introSubject.next(arrayOfIntros);
  }

  getIntroJson() {
    const url =
      this.globalService.getUrlPrefix() + '/hyperion/Hyperion/IntroData';
    const introDataObs = this.http.get(url);
    return introDataObs;
  }
}
