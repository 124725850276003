import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { Message } from 'primeng/api';

export interface ToastMessage extends Message {
  severity: ToastMessageSeverityType;
}

export enum ToastMessageSeverityType {
  ERROR = 'error',
  WARNING = 'warn',
  INFO = 'info',
  SUCCESS = 'success',
}

@Injectable()
export class MessageService {
  public msgs = {
    general: [],
    error: [],
  };

  public status: boolean;

  public msgsSubjects = new Subject();

  constructor() {}

  public insertDataPOSTGET(data): void {
    if (data.success && data.successMsgs.length > 0) {
      this.msgsSubjects.next(this.buildMessageObject(data, 'successMsgs'));
    }

    if (!data.success && data.errorsMsgs.length > 0) {
      this.msgsSubjects.next(this.buildMessageObject(data, 'errorsMsgs'));
    }
  }

  public insertDataFromMutation(mutationResponse): void {
    // foreach mutationdetail, most common only one but can be multiple on ex. invoice create
    if (mutationResponse['mutationDetails']) {
      mutationResponse['mutationDetails'].map(mutationDetail => {
        const mutationSeverity =
          mutationDetail.mutationSucceeded === false ? 'error' : 'success';

        const successMessages = mutationDetail.successMsgs || [];
        const errorMessages = mutationDetail.errorsMsgs || [];
        const warningMessages = mutationDetail.warningMsgs || [];
        const infoMessages = mutationDetail.infoMsgs || [];

        if (successMessages.length > 0) {
          this.msgsSubjects.next(
            // time
            this.buildMessageObject(mutationDetail, 'successMsgs')
          );
        }
        if (errorMessages.length > 0) {
          this.msgsSubjects.next(
            this.buildMessageObject(mutationDetail, 'errorsMsgs')
          );
        }
        if (warningMessages.length > 0) {
          this.msgsSubjects.next(
            this.buildMessageObject(mutationDetail, 'warningMsgs')
          );
        }
        if (infoMessages.length > 0) {
          this.msgsSubjects.next(
            this.buildMessageObject(mutationDetail, 'infoMsgs')
          );
        }

        if (
          successMessages.length +
            errorMessages.length +
            warningMessages.length +
            infoMessages.length ===
          0
        ) {
          this.msgsSubjects.next({
            severity: mutationSeverity,
            detail: '',
            summary: mutationDetail.msg,
            duration: this.getMessageDuration(mutationSeverity),
          });
        }
      });
    }
  }

  private buildMessageObject(mutationDetail, type) {
    const severityTypes = {
      errorsMsgs: 'error',
      warningMsgs: 'warn',
      infoMsgs: 'info',
      successMsgs: 'success',
    };

    let messageUl = '<ul>';
    mutationDetail[type].map(msg => {
      messageUl += '<li>' + msg + '</li>';
    });
    messageUl += '</ul>';

    const summary =
      type === 'warn' || type === 'info' ? '' : mutationDetail.msg;
    const severityType = severityTypes[type];

    return {
      severity: severityType,
      detail: messageUl, // ul
      summary: summary,
      duration: this.getMessageDuration(severityType),
    };
  }

  // if success, hang for 5 sek  if error, hang for 15 sek
  private getMessageDuration(type): number {
    return type === 'success' ? 5000 : 15000;
  }

  public insertData(message: ToastMessage | any, isError?: boolean): void {
    if (!message.severity && message.type) {
      message.severity = message.type;
    }

    switch (message.severity) {
      case 'error':
        message.summary =
          message.summary === '' ? 'Något blev fel' : message.summary;
        break;
      case 'warn':
        break;
      case 'info':
        break;
      case 'success':
        break;
    }

    if (message.textArray && message.textArray.length) {
      const messageItems = message.textArray.map(
        messageItem => `<li>${messageItem}</li>`
      );
      message.detail = `<ul>${messageItems.join('')}</ul>`;
    }

    if (!message.duration) {
      message.duration = this.getMessageDuration(message.severity);
    }

    if (!message.severity) {
      message.severity = ToastMessageSeverityType.INFO;
    }

    this.msgsSubjects.next(message);
  }

  makeList(object) {
    let msgList = '<ul>';
    for (const prop in object) {
      const value = object[prop];
      switch (typeof value) {
        case 'object':
          msgList += '<li>' + this.makeList(value) + '</li>';
          break;
        default:
          msgList += '<li>' + value + '</li>';
      }
    }
    return msgList + '</ul>';
  }

  public handleResponse(data): void {
    const msgBody = [];
    const classArray = {
      0: 'warn',
      1: 'info',
      2: 'error',
      3: 'success',
    };

    let alertClass = classArray[data.status ? 1 : 0];

    if (typeof data.msg === 'string') {
      msgBody.push(data.msg);
    } else if (typeof data.msg === 'object') {
      const msg = this.makeList(data.msg);
      msgBody.push(msg);

      alertClass = 'error';
    } else {
      msgBody.push('Okänt fel');
    }

    this.insertData({ textArray: msgBody, type: alertClass });
  }
}
