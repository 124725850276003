<div class="p-grid">
  <form
    *ngIf="headRotForm && !invoiceCreatedWithUseRowBasedTaxReduction"
    [formGroup]="headRotForm"
    class="p-grid p-col-12"
  >
    <p-table
      [value]="houseWorkTypes[invoiceType]"
      class="p-col-12 inline-table"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="p-text-truncate">Utfört arbete</th>
          <th class="p-text-truncate">Antal timmar</th>
          <th class="p-text-truncate">
            Materialkostnad <small>ink. moms</small>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-houseWorkType>
        <tr *ngIf="houseWorkType.name !== 'OvrigKostnad'">
          <td class="p-text-bold">{{ houseWorkType.description }}</td>
          <td>
            <span class="p-inputgroup">
              <input
                pInputText
                formControlName="{{ houseWorkType.name + 'AntalTimmar' }}"
              />
              <span class="p-inputgroup-addon"> h </span>
            </span>
          </td>
          <td>
            <span class="p-inputgroup">
              <input
                pInputText
                formControlName="{{ houseWorkType.name + 'Materialkostnad' }}"
              />
              <span class="p-inputgroup-addon"> kr </span>
            </span>
          </td>
        </tr>
        <tr *ngIf="houseWorkType.name === 'OvrigKostnad'">
          <td class="p-text-bold">Övrig kostnad <small>ink. moms</small></td>
          <td colspan="2">
            <span class="p-inputgroup">
              <input pInputText formControlName="OvrigKostnad" />
              <span class="p-inputgroup-addon"> kr </span>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </form>

  <h4 class="p-col-12">Personer</h4>
  <app-personer-rot-info
    [invoiceType]="invoiceType"
    [rotPersons]="rotPersons"
    class="p-col-12"
  ></app-personer-rot-info>
</div>
