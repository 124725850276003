import {
  Component,
  Input,
  ViewChild,
  ViewChildren,
  QueryList,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Table } from 'primeng/table';
import { Subscription, BehaviorSubject } from 'rxjs';

import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms/index';
import { GlobalService } from '../../../shared/global/index';
import { TodoTopicsService } from '../../../shared/company/index';
import { OfferTodoRowComponent } from './offer-todo-row/offer-todo-row.component';
import { SingleOfferService } from '../single-offer.service';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'app-offer-todo',
  templateUrl: './offer-todo.component.html',
  styleUrls: ['./offer-todo.component.scss'],
  providers: [FormHandlerService],
})
export class OfferTodoComponent implements OnInit, OnDestroy {
  @Input() offerInfo;
  @Input() labels;
  @Input() section;
  @Input() isGreenTaxReduction: boolean;
  @ViewChildren(OfferTodoRowComponent)
  @ViewChild('todoTable', { static: true })
  todoTable: Table;

  offerTodoRowComponents: QueryList<OfferTodoRowComponent>;
  todoTopicsDropdown = new BehaviorSubject<any[]>([]);
  taxDropdown = new BehaviorSubject<any[]>([]);
  public greenTaxDropdownOptions = new BehaviorSubject<SelectItem[]>([]);
  bigColumn = new BehaviorSubject<string>('descriptionOffer');
  todoTopicsSub: Subscription;
  todoSub: Subscription;
  dataSet = [];
  buttons: ButtonsAsync = {};
  buttonObject = ['create'];
  formFields = {
    model: 'Todotopic',
    attributes: {
      Name: '',
    },
  };
  todoTopicForm: FormGroup;

  constructor(
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private todoTopicsService: TodoTopicsService,
    private apolloQueryService: ApolloQueryService,
    private singleOfferService: SingleOfferService
  ) {}

  ngOnInit() {
    this.taxDropdown.next(this.globalService.getTaxOptions());
    this.greenTaxDropdownOptions.next(this.globalService.getGreenTaxOptions());
    this.buttons = this.formHandler.getButtonValuesAsync(this.buttonObject);
    this.formInit();

    this.todoSub = this.singleOfferService.todos$.subscribe(itm => {
      this.dataSet = itm;
      this.getTodoTopics();
    });
  }

  addRowIfNoEmpty() {
    const todos = this.dataSet;
    const todosLength: string = (todos.length - 1).toString();
    if (typeof todos[todosLength] !== 'undefined') {
      const todo = todos[todosLength];
      if (typeof todo['descriptionOffer'] !== 'undefined') {
        if (
          todo['descriptionOffer'] !== '' &&
          todo['descriptionOffer'] !== null
        ) {
          this.addRow();
        }
      }
    }
  }

  ngOnDestroy() {
    this.todoSub && this.todoSub.unsubscribe();
    this.todoTopicsSub && this.todoTopicsSub.unsubscribe();
  }

  changeBigColumn(value) {
    this.bigColumn.next(value);
  }

  formInit() {
    this.todoTopicForm = this.formHandler.groupedFormSimple(this.formFields);
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.todoTopicForm)
      .then(() => {
        this.todoTopicForm.controls['Name']['label'] = 'Ange ny rubrik';
      });
  }

  getTodoTopics() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyTodoTopics')
      .subscribe(({ data, sub }) => {
        this.todoTopicsSub = sub;
        this.todoTopicsDropdown.next(
          this.todoTopicsService.dataFormater(data, 'labelsNoDefault')
        );

        if (this.dataSet.length === 0) {
          this.addRow();
        } else {
          this.addRowIfNoEmpty();
        }
      });
  }

  addRow() {
    const typeId =
      this.todoTopicsDropdown['value'].length > 0
        ? this.todoTopicsDropdown['value'][0]['value']
        : null;
    const taxValue =
      this.taxDropdown['value'].length > 0
        ? this.taxDropdown['value'][this.taxDropdown['value'].length - 1][
            'value'
          ]
        : null;

    const newObject = {
      id: null,
      type: typeId,
      descriptionOffer: null,
      description: null,
      unitPrice: null,
      quantity: null,
      unit: 'st',
      cost: null,
      tax: taxValue,
      rot: null,
      orderNr: null,
      greenTaxReduction: null,
    };
    this.dataSet.push(newObject);
  }

  removeRow(index: number) {
    this.dataSet.splice(index, 1);
  }

  setRowId(eventParam, index) {
    this.dataSet[+index]['id'] = Number(eventParam['id']);
  }

  createTopic() {
    const crudType = 'create';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.todoTopicForm
    );
    dataToMutation['active'] = 1;
    this.formHandler.lockButtonsAsync(this.buttons);

    if (!this.formHandler.formValid([this.todoTopicForm])) {
      this.formHandler.unlockButtonsAsync(this.buttons);
    } else {
      const refetchArr = [{ name: 'companyTodoTopics', variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'todotopic',
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              { form: this.todoTopicForm, argument: crudType + 'Todotopic' },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.formInit();
            }

            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.unlockButtonsAsync(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.formHandler.unlockButtonsAsync(this.buttons);
          }
        );
    }
  }
}
