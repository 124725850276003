<div class="p-grid p-g-nopad" [id]="'offer_container_' + offerVariable['id']">
  <div class="p-col-12 p-g-nopad">
    <spinner *ngIf="loadingAsync | async"></spinner>
  </div>

  <div class="p-col-12 p-grid p-p-0" *ngIf="(loadingAsync | async) === false">
    <app-offer-side-nav
      class="p-xxl-3 p-col-4 p-p-0"
      [hasDeromeWebshopProducts]="hasDeromeWebshopProducts"
      [offer]="offer"
      [offerMode]="offerMode"
      [projectInfo]="projectInfo"
      [activeSection]="activeSection"
      [offerRowHeaderInfo]="offerHeaderRowInfo"
      (updatedAttr)="updateOfferAttr($event)"
      (showPreview)="showPreview.next($event)"
      (sideNavActions)="actionsFromSideNav($event)"
      (scrollToSection)="scrollToSection($event)"
    ></app-offer-side-nav>

    <div class="p-col-8 p-xxl-9 p-p-0">
      <!-- Comments and Document display -->
      <div class="p-col-12 p-g-nopad">
        <div
          class="p-col-12"
          style="display: flex; justify-content: space-between"
        >
          <div
            class="p-col-6"
            *ngIf="
              (toggleForms.comment | async) ||
              (toggleForms.document | async) ||
              offerMode === 'created'
            "
          >
            <app-button
              *ngIf="offerMode === 'created'"
              (click)="
                toggleForm('comment', true); toggleForm('document', false)
              "
              [plainText]="{ label: 'Kommentarer' }"
              [badge]="totalComments"
              [model]="'Comment'"
            ></app-button>

            <app-button
              *ngIf="offerMode === 'created'"
              class="ml5"
              (click)="
                toggleForm('document', true); toggleForm('comment', false)
              "
              [plainText]="{ label: 'Dokument' }"
              [badge]="totalDocuments"
              [model]="'Document'"
            ></app-button>

            <app-button
              *ngIf="
                (toggleForms.comment | async) || (toggleForms.document | async)
              "
              (click)="
                toggleForm('document', false); toggleForm('comment', false)
              "
              [type]="'showOnly'"
              [model]="'Offer'"
              class="ml5"
            ></app-button>
          </div>
        </div>
      </div>

      <div
        class="p-col-12"
        *ngIf="
          showProductExternalConnectInterface &&
          (meUser.type === '3' || meUser.type === '2')
        "
      >
        <p-card class="p-col-12 p-g-nopad">
          <div class="p-grid">
            <app-project-external-dropdown
              class="p-col-12 p-g-nopad"
              [offerId]="dataToChild.id"
              [projectExternalSource]="'derome-api'"
              [preSelectedExternalProject]="currentExternalProject"
              [type]="'list-with-label'"
              (externalProjectConnectionChanged)="updatePrices($event)"
            ></app-project-external-dropdown>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-g-nopad">
        <div [style.display]="(toggleForms.comment | async) ? 'block' : 'none'">
          <app-offer-comment
            [offerInfo]="dataToChild"
            (totalComments)="setTotalComments($event)"
          ></app-offer-comment>
        </div>
        <div
          [style.display]="(toggleForms.document | async) ? 'block' : 'none'"
        >
          <app-offer-document
            [offerInfo]="dataToChild"
            (totalDocuments)="setTotalDocuments($event)"
          ></app-offer-document>
        </div>
      </div>

      <div class="p-col-12 p-g-nopad">
        <app-offer-sign-status
          [offerId]="offer['id']"
          (showDocuments)="
            toggleForm('document', true); toggleForm('comment', false)
          "
        ></app-offer-sign-status>
      </div>

      <!-- Contact and Establishment form Strarts -->
      <div
        class="p-col-12 p-g-nopad"
        id="offer-edit-section--1"
        *ngIf="offer['type'] !== 'ata'"
      >
        <div class="p-grid">
          <div class="p-col-12 p-lg-6 p-g">
            <p-card>
              <ng-template pTemplate="header">
                <span class="p-d-flex p-jc-between">
                  <h4>Kund</h4>
                  <span class="">
                    <i
                      class="edit-panel fa"
                      [class.fa-pencil]="!(toggleForms.client | async)"
                      [class.fa-times]="toggleForms.client | async"
                      (click)="toggleForm('client')"
                    ></i>
                  </span>
                </span>
              </ng-template>

              <div *ngIf="!(toggleForms.client | async)">
                <ul class="column-info-ul">
                  <li *ngFor="let client of listValueClientForm">
                    <div
                      [innerHTML]="
                        clientContactForm['controls'][client]['label']
                      "
                    ></div>
                    <div>
                      {{ clientContactForm['controls'][client]['value'] }}
                    </div>
                  </li>
                </ul>
              </div>

              <div *ngIf="toggleForms.client | async">
                <div style="margin-bottom: 8px">
                  <span
                    class="link"
                    (click)="showMoreFields.next(!showMoreFields.value)"
                  >
                    <span *ngIf="!(showMoreFields | async)"
                      ><i class="fa fa-plus"></i> Visa fler fält</span
                    >
                    <span *ngIf="showMoreFields | async"
                      ><i class="fa fa-minus"></i> Visa färre fält</span
                    >
                  </span>
                </div>

                <form
                  [formGroup]="clientContactForm"
                  class="full-width"
                  autocomplete="off"
                >
                  <div class="p-pt-2">
                    <div
                      *ngIf="
                        !clientContactForm.value.trueId ||
                        clientContactForm.value.trueId === '0'
                      "
                    >
                      <label>Koppla till kund</label>
                      <span class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-search"></i>
                        </span>
                        <app-contact-select
                          createButton="false"
                          (selectedContactEvent)="
                            setContactFromAutosuggest($event, 'client')
                          "
                        ></app-contact-select>
                      </span>
                    </div>
                    <div
                      *ngIf="
                        clientContactForm.value.trueId &&
                        clientContactForm.value.trueId !== '0'
                      "
                    >
                      <label>Kopplad till kund</label>
                      <span class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-search"></i>
                        </span>
                        <input
                          pInputText
                          [value]="clientContactForm.value.trueId"
                          readonly
                        />
                        <button
                          *ngIf="
                            clientContactForm.value.trueId !== '0' ||
                            clientContactForm.value.trueId
                          "
                          pButton
                          class="p-inputgroup-addon p-button-danger-inverted"
                          icon="pi pi-times"
                          type="button"
                          (click)="removeParent(clientContactForm)"
                          pTooltip="Ta bort koppling"
                          tooltipPosition="top"
                        ></button>
                      </span>
                    </div>
                  </div>

                  <input type="hidden" formControlName="trueId" />

                  <appForm2-simple-input
                    *ngIf="
                      clientContactForm.get('contactType')?.value !== 'Privat'
                    "
                    formControlName="orderBuisnessName"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="name"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="orgNr"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="address"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="address2"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="cityCode"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="city"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="phone"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="mobilePhone"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="mail"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="propertyName"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="housingAssociationOrgNumber"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="apartmentDesignation"
                  ></appForm2-simple-input>

                  <appForm2-simple-dropDown
                    *ngIf="showMoreFields | async"
                    formControlName="betalningsvillkor"
                    [dropDownParams]="{
                      options: betalningsvillkorOptions
                    }"
                  ></appForm2-simple-dropDown>
                </form>

                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <div
                      style="margin-bottom: 10px"
                      *ngIf="contactTypeModel.client.show | async"
                    >
                      <appForm2-list-dropDown
                        [(ngModel)]="contactTypeModel.client.value"
                        [ngModelOptions]="{ standalone: true }"
                        [dropDownParams]="{
                          options: contactTypes,
                          styleClass: 'form-control scale-up'
                        }"
                      ></appForm2-list-dropDown>
                    </div>
                    <div
                      [pTooltip]="
                        !clientContactForm.value.trueId ||
                        clientContactForm.value.trueId === '0'
                          ? 'Låter dig i nästa steg spara uppgifterna ovan som en post i företagets kontaktlista'
                          : 'Ta bort knytning för att spara ny kontakt'
                      "
                    >
                      <app-button
                        (onClick)="
                          contactTypeModel.client.show.value
                            ? confirmCreateContact('client')
                            : contactTypeModel.client.show.next(true)
                        "
                        [disabled]="
                          clientContactForm.value.trueId !== '0' &&
                          clientContactForm.value.trueId
                        "
                        [type]="'save'"
                        [model]="'Contact'"
                      ></app-button>
                    </div>
                  </div>
                  <div>
                    <app-button
                      (click)="toggleForm('client')"
                      [type]="'close'"
                    ></app-button>
                  </div>
                </div>
              </div>
            </p-card>
          </div>

          <div class="p-col-12 p-lg-6 p-g">
            <p-card>
              <ng-template pTemplate="header">
                <span class="p-d-flex p-jc-between">
                  <h4>Arbetsställe</h4>
                  <span>
                    <span
                      style="margin-right: 15px"
                      *ngIf="toggleForms.establishment | async"
                      class="edit-panel"
                      ><small
                        (click)="copyContactToEstablishment()"
                        style="margin-right: 5px"
                        >Kopiera kund</small
                      >
                      <i
                        class="fa fa-files-o"
                        pTooltip="<strong>Kopiera kund</strong><br><br>Om kund och arbetställe är samma, klicka för att kopiera över uppgifterna från kund."
                        tooltipPosition="top"
                        [escape]="false"
                      ></i>
                    </span>
                    <i
                      class="edit-panel fa"
                      [class.fa-pencil]="!(toggleForms.establishment | async)"
                      [class.fa-times]="toggleForms.establishment | async"
                      (click)="toggleForm('establishment')"
                    ></i>
                  </span>
                </span>
              </ng-template>

              <div *ngIf="!(toggleForms.establishment | async)">
                <ul class="column-info-ul">
                  <li *ngFor="let establishment of listValueEstablishmentForm">
                    <div
                      [innerHTML]="
                        establishmentContactForm['controls'][establishment][
                          'label'
                        ]
                      "
                    ></div>
                    <div>
                      {{
                        establishmentContactForm['controls'][establishment][
                          'value'
                        ]
                      }}
                    </div>
                  </li>
                </ul>
              </div>

              <div *ngIf="toggleForms.establishment | async">
                <div style="margin-bottom: 8px">
                  <span
                    class="link"
                    (click)="showMoreFields.next(!showMoreFields.value)"
                  >
                    <span *ngIf="!(showMoreFields | async)"
                      ><i class="fa fa-plus"></i> Visa fler fält</span
                    >
                    <span *ngIf="showMoreFields | async"
                      ><i class="fa fa-minus"></i> Visa färre fält</span
                    >
                  </span>
                </div>

                <form
                  [formGroup]="establishmentContactForm"
                  class="full-width"
                  autocomplete="off"
                >
                  <div class="p-pt-2">
                    <div
                      *ngIf="
                        !establishmentContactForm.value.trueId ||
                        establishmentContactForm.value.trueId === '0'
                      "
                    >
                      <label>Koppla till kund</label>
                      <span class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-search"></i>
                        </span>
                        <app-contact-select
                          createButton="false"
                          (selectedContactEvent)="
                            setContactFromAutosuggest($event, 'establishment')
                          "
                        ></app-contact-select>
                      </span>
                    </div>
                    <div
                      *ngIf="
                        establishmentContactForm.value.trueId &&
                        establishmentContactForm.value.trueId !== '0'
                      "
                    >
                      <label>Kopplad till kund</label>
                      <span class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                          <i class="pi pi-search"></i>
                        </span>
                        <input
                          pInputText
                          [value]="establishmentContactForm.value.trueId"
                          readonly
                        />
                        <button
                          *ngIf="
                            establishmentContactForm.value.trueId !== '0' ||
                            establishmentContactForm.value.trueId
                          "
                          pButton
                          class="p-inputgroup-addon p-button-danger-inverted"
                          icon="pi pi-times"
                          type="button"
                          (click)="removeParent(establishmentContactForm)"
                          pTooltip="Ta bort koppling"
                          tooltipPosition="top"
                        ></button>
                      </span>
                    </div>
                  </div>

                  <appForm2-simple-input
                    formControlName="orderBuisnessName"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="name"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="orgNr"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="address"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="address2"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="cityCode"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="city"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    *ngIf="showMoreFields | async"
                    formControlName="mobilePhone"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="phone"
                  ></appForm2-simple-input>

                  <appForm2-simple-input
                    formControlName="mail"
                  ></appForm2-simple-input>
                </form>

                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div>
                    <div
                      style="margin-bottom: 10px"
                      *ngIf="contactTypeModel.establishment.show | async"
                    >
                      <appForm2-list-dropDown
                        [(ngModel)]="contactTypeModel.establishment.value"
                        [ngModelOptions]="{ standalone: true }"
                        [dropDownParams]="{
                          options: contactTypes,
                          styleClass: 'form-control scale-up'
                        }"
                      ></appForm2-list-dropDown>
                    </div>
                  </div>
                  <div>
                    <app-button
                      (click)="toggleForm('establishment')"
                      [type]="'close'"
                    ></app-button>
                  </div>
                </div>
              </div>
            </p-card>
          </div>
        </div>
      </div>
      <!--END Contact and Establishment form END-->

      <!-- Offer Starts -->
      <form [formGroup]="offerForm" class="p-col-12 p-g-nopad">
        <!-- templateName -->
        <div
          id="templateNameCard"
          class="p-col-12"
          *ngIf="offerMode === 'template'"
        >
          <p-card>
            <div class="p-grid">
              <div class="p-col-12">
                <h4>
                  {{ offerForm.controls['templateName']['label'] }}
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    pTooltip="Om du vill ange något i huvudet på varje sida så kan du göra det här."
                    [escape]="false"
                    >?</span
                  >
                </h4>
              </div>
              <div class="p-col-12 templateName">
                <appForm2-list-input
                  formControlName="templateName"
                ></appForm2-list-input>
              </div>
            </div>
          </p-card>
        </div>
        <!-- templateName end -->
        <!-- header -->
        <div class="p-col-12">
          <p-card>
            <div class="p-grid">
              <div class="p-col-12">
                <h4>
                  {{ offerForm.controls['sendFromUserId']['label'] }}
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    pTooltip="Om du vill ange något i huvudet på varje sida så kan du göra det här."
                    [escape]="false"
                    >?</span
                  >
                </h4>
              </div>
              <div class="p-col-12 sendFromUserId">
                <appForm2-list-dropDown
                  formControlName="sendFromUserId"
                  [dropDownParams]="{ options: adminOptions }"
                ></appForm2-list-dropDown>
              </div>
            </div>
          </p-card>
        </div>

        <div class="p-col-12">
          <p-card>
            <div class="p-grid">
              <div class="p-col-12">
                <h4
                  [innerHtml]="offerForm.controls['headerText']['label']"
                ></h4>
              </div>
              <div class="p-col-12 header-text">
                <appForm2-list-input
                  formControlName="headerText"
                ></appForm2-list-input>
              </div>
            </div>
          </p-card>
        </div>
        <!-- header end -->

        <!-- dynamic cards -->
        <ng-container
          *ngFor="
            let row of offer['offerAttr'];
            index as i;
            trackBy: trackSectionById
          "
        >
          <div
            #section
            class="p-col-12"
            [attr.data-row-id]="row['order']"
            id="offer-edit-section-{{ row['order'] }}"
            *ngIf="row.type === 'offerAttribute' || row.type === 'module'"
            [class.transparent]="!row.active"
            (click)="!row.active ? makeSectionActive(row['order']) : null"
          >
            <p-card>
              <div class="p-grid">
                <div
                  class="p-col-12"
                  style="display: flex; justify-content: space-between"
                >
                  <div class="header-paste">
                    <h4>
                      {{
                        row.label !== 'Timkostnad'
                          ? row.label
                          : 'Övriga kostnader vid tillkommande arbeten'
                      }}

                      <span
                        class="p-text-normal"
                        *ngIf="row.name === 'concerning'"
                      >
                        ({{ 50 - offerForm.controls[row.name]?.value?.length }}
                        tecken kvar)
                      </span>
                    </h4>
                    <p
                      class="inlineB"
                      *ngIf="row['exampelText']"
                      [pTooltip]="row.exampelText"
                      (click)="
                        offerForm.controls[row.name].setValue(row.exampelText)
                      "
                    >
                      <i class="fa fa-align-justify"> </i>
                    </p>
                  </div>

                  <div class="flex-line">
                    <p-checkbox
                      class="mr10"
                      [binary]="true"
                      [ngModel]="!!row.breakpage"
                      [ngModelOptions]="{ standalone: true }"
                      (onChange)="
                        updateSectionStates(row['order'], $event, 'breakpage')
                      "
                      label="Bryt"
                      tooltipPosition="left"
                      pTooltip="Om ett stycke inte får plats på aktuell sida så flyttas det till nästa sida, markera denna om stycket ska brytas och inte flyttas till ny sida"
                    ></p-checkbox>
                    <p-checkbox
                      [binary]="true"
                      class="mr10"
                      [ngModel]="!!row.forceBreakpage"
                      [ngModelOptions]="{ standalone: true }"
                      (onChange)="
                        updateSectionStates(
                          row['order'],
                          $event,
                          'forceBreakpage'
                        )
                      "
                      label="Ny sida"
                      pTooltip="Gör en sidbrytning och lägger detta stycke först på den nya sidan"
                      tooltipPosition="left"
                    ></p-checkbox>
                  </div>
                </div>

                <!-- Autocomplete -->
                <div
                  class="p-col-12"
                  *ngIf="
                    offerMode === 'create' &&
                      row.autoSuggest &&
                      row.type !== 'module';
                    else no_auto_suggest
                  "
                >
                  <p-autoComplete
                    *ngIf="row.inputType === 'textField'"
                    [placeholder]="row.label"
                    [suggestions]="autoResults[row.name] | async"
                    (completeMethod)="
                      autoCompleteByFieldSearch($event, row.name)
                    "
                    inputStyleClass="form-control"
                    styleClass="wide-panel"
                    [formControlName]="row.name"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                  >
                    <ng-template let-object pTemplate="item">
                      <div class="p-helper-clearfix" style="font-size: 12px">
                        <div class="autocomplete-row">
                          <div style="width: 100%">{{ object }}</div>
                          <div style="float: none" class="clear-both"></div>
                        </div>
                      </div>
                    </ng-template>
                  </p-autoComplete>

                  <offer-autocomplete-text-area
                    [class]="row.name"
                    *ngIf="row.inputType === 'textArea'"
                    [sectionName]="row.name"
                    [value]="offerForm['controls'][row.name]['value']"
                    (setFieldModel)="
                      offerForm.controls[row.name].setValue($event)
                    "
                  ></offer-autocomplete-text-area>
                </div>
                <!-- End autocomplete -->

                <ng-template #no_auto_suggest>
                  <div class="p-col-12">
                    <appForm2-list-input
                      [class]="row.name"
                      *ngIf="
                        row.inputType === 'textField' &&
                        !row.addDatePicker &&
                        row.name !== 'insurance' &&
                        row.name !== 'inputForHourCost'
                      "
                      [formControlName]="row.name"
                    ></appForm2-list-input>

                    <appForm2-list-texteditor
                      [class]="row.name"
                      *ngIf="row.inputType === 'textArea'"
                      [formControlName]="row.name"
                    ></appForm2-list-texteditor>

                    <appForm2-list-calendar
                      [class]="row.name"
                      *ngIf="row.addDatePicker"
                      [formControlName]="row.name"
                    ></appForm2-list-calendar>

                    <appForm2-simple-input
                      [styleClass]="'p-col-12 p-g-nopad'"
                      *ngIf="
                        row.name === 'insurance' ||
                        (row.name === 'inputForHourCost' &&
                          row.inputType === 'textField')
                      "
                      [formControlName]="row.name"
                    ></appForm2-simple-input>

                    <ng-container
                      *ngIf="
                        row.name === 'insurance' ||
                        row.name === 'inputForHourCost'
                      "
                    >
                      <ng-container
                        *ngFor="let control of customSections[row.name]"
                      >
                        <appForm2-simple-input
                          [styleClass]="'p-col-12 p-g-nopad mt10'"
                          *ngIf="control !== 'validToDate'"
                          [formControlName]="control"
                        ></appForm2-simple-input>

                        <appForm2-simple-calendar
                          [styleClass]="'p-col-12 p-g-nopad mt10'"
                          *ngIf="control === 'validToDate'"
                          [formControlName]="control"
                        ></appForm2-simple-calendar>
                      </ng-container>
                    </ng-container>

                    <app-offer-signatures
                      *ngIf="row.name === 'signaturs'"
                      [dataSet]="signatures"
                      [offerInfo]="dataToChild"
                      [labels]="labels | async"
                    ></app-offer-signatures>

                    <app-offer-contracts
                      *ngIf="row.name === 'contractors'"
                      [dataSet]="contractors"
                      [offerInfo]="dataToChild"
                      [labels]="labels | async"
                    ></app-offer-contracts>

                    <app-offer-organization
                      *ngIf="row.name === 'organization'"
                      [dataSet]="organization"
                      [offerInfo]="dataToChild"
                      [labels]="labels | async"
                    ></app-offer-organization>

                    <div
                      class="p-col-12 p-g-nopad"
                      *ngIf="row.name === 'todos'"
                    >
                      <app-offer-todo
                        [offerInfo]="dataToChild"
                        [labels]="labels | async"
                        [section]="row"
                        [isGreenTaxReduction]="isGreenTaxReduction"
                      ></app-offer-todo>
                      <app-offer-calculation-rows
                        [offerInfo]="dataToChild"
                        [labels]="labels | async"
                        [isGreenTaxReduction]="isGreenTaxReduction"
                      ></app-offer-calculation-rows>
                      <derome-webshop-products
                        (hasDeromeWebshopProductsEmitter)="
                          setHasDeromeWebshopProducts($event)
                        "
                        *ngIf="showDeromeWebshopProducts"
                        [offerInfo]="dataToChild"
                        (actionSave)="actionsFromSideNav($event)"
                        (refreshOffer)="refreshOffer()"
                      ></derome-webshop-products>
                      <app-offer-summation
                        [dataSet]="rot | async"
                        [offerInfo]="dataToChild"
                        (newRotData)="handleNewRotData($event)"
                      ></app-offer-summation>
                    </div>
                  </div>
                </ng-template>
              </div>
            </p-card>
          </div>
        </ng-container>
        <!-- dynamic cards ENDS -->

        <!-- footer -->
        <div class="p-col-12">
          <p-card>
            <div class="p-grid">
              <div class="p-col-12">
                <h4
                  [innerHtml]="offerForm.controls['footerText']['label']"
                ></h4>
              </div>
              <div class="p-col-12">
                <appForm2-list-input
                  formControlName="footerText"
                ></appForm2-list-input>
              </div>
            </div>
          </p-card>
        </div>
        <!-- footer END -->
      </form>
      <!--END Offer END-->
    </div>
  </div>
</div>
