<app-header></app-header>
<div class="page-container">
  <p-table [value]="subcompanies">
    <ng-template pTemplate="header">
      <tr>
        <th>Användare</th>
        <th>Företag</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-subcompany>
      <tr>
        <td>
          {{ subcompany.ownerUser.firstName }}
          {{ subcompany.ownerUser.lastName }}
        </td>
        <td>
          <a
            href="/CompanyHasCompany/ownerSwitchToSubCompany?companyUserHasCompanyUserId={{
              subcompany.id
            }}"
            >Gå till {{ subcompany.companyHasCompany.company.name }}</a
          >
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
