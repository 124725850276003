<div class="p-grid">
  <div class="p-col-12 p-g-nopad">
    <div class="p-col-12 p-xl-2 calculation-header">
      <h4 class="form-control-label">Material / övriga kostnader</h4>
    </div>
  </div>
  <p-table
    #userCalculationRowTable
    class="p-col-12 transactionsEditTable"
    [rowHover]="true"
    [value]="dataSet"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          <label
            class="form-control-label"
            [innerHTML]="labels['UserCalculationRow']['name']"
          ></label>
        </th>
        <th style="width: 55px" pSortableColumn="quantity">
          <label
            class="form-control-label"
            [innerHTML]="labels['UserCalculationRow']['quantity']"
          ></label>
        </th>

        <th style="width: 80px" pSortableColumn="unit">
          <label
            class="form-control-label"
            [innerHTML]="labels['UserCalculationRow']['unit']"
          ></label>
        </th>
        <th style="width: 100px" pSortableColumn="cost">
          <label
            class="form-control-label"
            [innerHTML]="labels['UserCalculationRow']['cost']"
          ></label>
        </th>
        <th style="width: 80px" pSortableColumn="tax">
          <label
            class="form-control-label"
            [innerHTML]="labels['UserCalculationRow']['tax']"
          ></label>
        </th>
        <th
          style="width: 40px"
          pSortableColumn="greenTaxReduction"
          *ngIf="isGreenTaxReduction"
        >
          <label class="form-control-label">Grön</label>
        </th>
        <th style="width: 40px" pSortableColumn="procent">
          <label
            class="form-control-label"
            [pTooltip]="labels['UserCalculationRow']['procent']"
            >%</label
          >
        </th>
        <th style="width: 100px">
          <label class="form-control-label">Pris</label>
        </th>
        <th style="width: 6rem" pSortableColumn="order">
          <label
            class="form-control-label"
            [pTooltip]="labels['UserCalculationRow']['order']"
          >
            <i class="fa fa-sort"></i>
          </label>
        </th>
        <th style="width: 60px">
          <label class="form-control-label">Hantera</label>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-object let-rowIndex="rowIndex">
      <tr
        app-calculation-row-row
        [dataObjectFromParent]="object"
        [offerInfo]="offerInfo"
        [labels]="labels"
        [taxDropdown]="taxDropdown | async"
        [showProductPrices]="showProductPrices"
        (deletedObject)="removeRow(rowIndex)"
        (createdObject)="updateCalculationRows($event, rowIndex)"
        (addRowIfNoEmpty)="addRowIfNoEmpty()"
        [isMissingDeromeProductIntegration]="isMissingDeromeProductIntegration"
        [isGreenTaxReduction]="isGreenTaxReduction"
        [greenTaxDropdownOptions]="greenTaxDropdownOptions | async"
      ></tr>
    </ng-template>
  </p-table>

  <div class="p-col-12 align-right">
    <app-button (click)="addRow()" [type]="'addNoText'"></app-button>
  </div>
</div>
