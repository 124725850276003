<app-feature-preview
  image="/images/hyperion/feature-previews/overblick.jpg"
  feature="Överblick"
>
  <p>
    När du använder {{ appName }}s projektverktyg får du automatiskt en ökad
    insikt och kontroll över din byggverksamhet genom våra statistikverktyg. Du
    får snabbt en överblick över intäkter, kostnader, tidrapporter,
    materialåtgång och mil som rapporterats varje månad. Du kan även hålla koll
    på hur många offerter som blivit godkända och mycket mer.
  </p>
</app-feature-preview>
