import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { ApolloQueryService } from 'app/shared/apollo';
import { HelperService } from 'app/shared/helpers';

@Component({
  selector: 'home-updates',
  templateUrl: 'home-updates.component.html',
  styleUrls: ['./home-updates.component.scss'],
})
export class HomeUpdatesComponent implements OnInit, OnDestroy {
  appUpdatesSub: Subscription;
  appUpdates = [];

  constructor(
    private apolloQueryService: ApolloQueryService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.getUpdates();
  }

  getUpdates() {
    this.apolloQueryService
      .apolloWatchQueryTwo('appUpdates', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.appUpdatesSub = sub;
        if (data) {
          this.appUpdates = this.helperService.cleanFromNode(
            data['appUpdates']
          );
        }
      });
  }

  ngOnDestroy() {
    this.appUpdatesSub && this.appUpdatesSub.unsubscribe();
  }
}
