import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from 'app/settings/company/services/accounting-plan.types';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';

import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'companyCostType-edit',
  templateUrl: 'company-cost-type-edit.component.html',
  styleUrls: ['./company-cost-type.scss'],
  providers: [FormHandlerService],
})
export class EditCostTypeComponent implements OnInit {
  @Input() public costType;
  @Input() public hasAccountingPlanFunction: boolean;
  @Input() public accountingPlanAccounts: AccountingPlanAccount[] = [];

  // Form
  componentMainForm: FormGroup;
  dataModel = 'companyCostType';
  dataModelCapitalized;
  formSettings;
  dropDownParams = { options: QuantityUnits, filter: true };
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;

  fieldsForForm = {
    name: '',
    procent: '',
    unit: '',
  };
  formFields = {
    model: 'CompanyCostType',
    attributes: {},
  };
  buttons;
  componentPrefix;

  constructor(
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private accountingPlanService: AccountingPlanService
  ) {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.formSettings = this.globalService.getFormSettings();
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.formsInit({
      name: this.costType.name,
      procent: this.costType.procent,
      unit: this.costType.unit,
      accountingPlanAccount: this.costType.accountingPlanAccount,
      reverseVatAccountingPlanAccount:
        this.costType.reverseVatAccountingPlanAccount,
      active: this.costType.active,
    });

    this.accountingPlanAccountsDropdownParams =
      this.accountingPlanService.getAccountsDropdownParams(
        this.accountingPlanAccounts
      );
  }

  formsInit(attributes) {
    this.formFields['attributes'] = attributes;
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  actionUpdate(shouldActivate = false) {
    const crudType = 'update';
    const dataToMutation = {
      id: +this.costType.id,
      name: this.componentMainForm.controls.name.value,
      procent: this.componentMainForm.controls.procent.value,
      unit: this.componentMainForm.controls.unit.value,
      accountingPlanAccountId:
        this.componentMainForm.controls.accountingPlanAccount.value,
      reverseVatAccountingPlanAccountId:
        this.componentMainForm.controls.reverseVatAccountingPlanAccount.value,
      active: this.componentMainForm.controls.active.value,
    };

    if (shouldActivate) {
      dataToMutation.active = 1;
    }

    this.buttons = this.formHandler.lockButtons(this.buttons);
    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.setLoadingClasses();
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            let setLoadingClassesInput;
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
            } else {
              setLoadingClassesInput = 'error';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  actionActivate() {
    this.actionUpdate(true);
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort produkttypen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.formHandler.setLoadingClasses('start');
        this.deleteProjectType();
      },
      reject: () => {},
    });
  }

  private deleteProjectType() {
    const crudType = 'update';
    const dataToMutation = {
      id: +this.costType.id,
      active: 0,
    };
    this.buttons = this.formHandler.lockButtons(this.buttons, true);
    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.mutationService.displayMutationStatus(data);
          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);
          if (data.mutationSucceededAllArguments) {
            this.formHandler.setLoadingClasses('success');
          } else {
            this.formHandler.setLoadingClasses('error');
          }
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }
}
