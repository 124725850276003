import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { GlobalService } from '../../../shared/global/index';

import { CompanySuperService } from '../company.super.service';

@Injectable()
export class CompanyAllProjectsService extends CompanySuperService {
  dataModel = 'project';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  public dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }
    return dataSetFormatted;
  }

  public makeLabelsArray(data, getStatuses = false) {
    const dataArray = data.company.projects.edges;
    const dataObjectsWithLabels = [];

    dataObjectsWithLabels.push({ label: 'Visa alla projekt', value: null });

    dataArray.forEach(itm => {
      const trueIdText =
        itm.node.trueId !== null && itm.node.trueId !== 0
          ? itm.node.trueId + ', '
          : '';
      const markning =
        itm.node.mark && trueIdText !== '' ? itm.node.mark : '(Ingen märkning)';

      const dataObject = {
        label: trueIdText + markning,
        value: getStatuses
          ? { id: itm.node.id, status: +itm.node.status }
          : +itm.node.id,
      };

      dataObjectsWithLabels.push(dataObject);
    });

    return dataObjectsWithLabels;
  }

  public makeLabelsArrayForStatuses(data, statusArray, getStatuses = false) {
    const dataArray = data.company.projects.edges;
    const dataObjectsWithLabels = [];

    const nullObject = { label: 'Visa alla projekt', value: null };
    if (getStatuses) {
      nullObject['value'] = { id: null };
    }
    dataObjectsWithLabels.push(nullObject);

    dataArray.forEach(element => {
      const trueIdText = element.node.trueId || '';
      const markning =
        element.node.mark || element.node.trueId
          ? element.node.mark
          : '(Ingen märkning)';

      const dataObject = {
        label: `${trueIdText}, ${markning}`,
        value: +element.node.id as any,
        status: element.node.status,
      };

      if (getStatuses) {
        dataObject.value = {
          id: element.node.id,
          status: +element.node.status,
        };
      }

      const acceptedStatus = statusArray.some(
        status => element.node.status === status
      );

      if (acceptedStatus) {
        dataObjectsWithLabels.push(dataObject);
      }
    });

    return dataObjectsWithLabels;
  }

  private makeObjectsForForms(data) {
    const dataArray = data.company.projects.edges;
    const dataObjectsForForms = [];

    dataArray.forEach(element => {
      const dataObject = { ...element.node };
      dataObject['extraText'] = dataObject.extra ? 'Ja' : 'Nej';
      dataObject[
        'projectInfo'
      ] = `${element.node.trueId}, ${element.node.mark}`;

      dataObjectsForForms.push(dataObject);
    });

    return dataObjectsForForms;
  }
}
