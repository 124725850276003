import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { first } from 'rxjs';

import { AuthService } from 'app/shared/auth/auth.service';
import { ConfigService } from 'app/shared/config/config.service';
import { UnpaidInvoicesGQL } from './graphql/unpaidInvoices.generated';
import { UserLocalStorageService } from 'app/shared/user';
import { CrmInvoice } from 'generated/types';
import moment from 'moment';

@Component({
  selector: 'app-struqtur-payment-reminder-notification',
  templateUrl: 'payment-reminder-notification.component.html',
  styleUrls: ['payment-reminder-notification.component.scss'],
})
export class PaymentReminderNotificationComponent implements OnInit {
  public userFirstName: string;
  public invoice?: CrmInvoice;
  public daysDue: number;

  @Output() public isLockedAccount = new EventEmitter<boolean>();

  constructor(
    private userLocalStorageService: UserLocalStorageService,
    private authService: AuthService,
    public configService: ConfigService,
    private unpaidInvoicesGQL: UnpaidInvoicesGQL
  ) {}

  public ngOnInit(): void {
    this.authService.isLoggedInObs.subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        return;
      }
      this.userFirstName = this.userLocalStorageService.getMEUser().firstName;
      this.checkForUnpaidInvoices();
    });
  }

  private checkForUnpaidInvoices(): void {
    this.unpaidInvoicesGQL
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const hasUnpaidInvoices = res.data.company.hasUnpaidCrmInvoices !== 0;
        const dueDate = new Date(
          res.data.company.latestUnpaidCrmInvoice?.dueDate
        );
        const today = moment();
        this.daysDue = Math.floor(
          moment.duration(today.diff(dueDate)).asDays()
        );
        if (hasUnpaidInvoices && this.daysDue > 0) {
          this.invoice = res.data.company.latestUnpaidCrmInvoice;
        }
      });
  }
}
