import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloSuperService, ApolloErrorHandler } from 'app/shared/apollo';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class CompanySuperService extends ApolloSuperService {
  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super();
    // apollo,
    //             apolloErrorHandler
  }

  cleanFromNode(dataParam) {
    const dataArray = dataParam.edges;
    const dataArrayWithObjects = [];
    for (const object in dataArray) {
      if (typeof dataArray[object]['node'] !== 'undefined') {
        dataArrayWithObjects.push(Object.assign({}, dataArray[object]['node']));
      }
    }

    return dataArrayWithObjects;
  }
}
