<app-header> </app-header>

<div class="page-container">
  <div class="home-component extra-functions">
    <div class="p-grid">
      <div class="p-col-12 p-g-nopad">
        <iframe [src]="url" style="width: 100%; height: 600px"></iframe>
      </div>
    </div>
  </div>
</div>
