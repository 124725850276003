<p-autoComplete
  #autoComplete
  [formControl]="control"
  [placeholder]="placeholder"
  [suggestions]="searchProductsSuggestions"
  (completeMethod)="searchProducts($event)"
  (onSelect)="actionSelect($event)"
  delay="800"
  pAutoFocus
  [autofocus]="autofocus"
  [appendTo]="appendTo"
>
  <ng-template pTemplate="header">
    <div class="p-grid p-text-bold">
      <div class="p-col-3 p-text-nowrap">Artnr</div>
      <div class="p-col">Benämning</div>
      <div class="p-col-2 p-text-nowrap" *ngIf="showPrices">Pris</div>
      <div class="p-col-2 p-text-nowrap" *ngIf="showPrices">Inpris</div>
      <div class="p-col-2">Leverantör</div>
    </div>
  </ng-template>
  <ng-template let-product pTemplate="item">
    <div class="p-grid" style="margin: 0; padding: 0.2rem">
      <div class="p-col-3 p-text-truncate" style="word-break: break-all">
        {{ product.artnr }}
      </div>
      <div class="p-col" style="white-space: pre-line">
        {{ product.benamning }}
      </div>
      <div class="p-col-2 p-text-nowrap" *ngIf="showPrices">
        {{ product.avtalspris }}
      </div>
      <div class="p-col-2 p-text-nowrap" *ngIf="showPrices">
        {{ product.avtalsprisCost }}
      </div>
      <div class="p-col-2 p-text-nowrap">
        {{ product.leverantor }}
      </div>
    </div>
  </ng-template>
</p-autoComplete>
