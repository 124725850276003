import { Component, Input } from '@angular/core';
import { DocumentFolder } from '../document-metadata';

@Component({
  selector: 'app-docu-template-folder-hero',
  templateUrl: './docu-template-folder-hero.component.html',
  styleUrls: ['./docu-template-folder-hero.component.scss'],
})
export class DocuTemplateFolderHeroComponent {
  @Input() folder: DocumentFolder;
  @Input() primary = true;
}
