import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-initial-icon',
  templateUrl: './initial-icon.component.html',
  styleUrls: ['./initial-icon.component.scss'],
})
export class InitialIconComponent {
  public initial = '';
  public color = '#fff0';

  @Input() set text(text: string) {
    if (!text) {
      return;
    }
    this.initial = this.getInitial(text);
    this.color = this.getColor(text);
  }

  private getInitial(text: string): string {
    if (!text) {
      return '';
    }
    return Array.from(text.trim())[0]?.toUpperCase();
  }

  private getColor(text: string): string {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }
}
