import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './index';
import { HyperionSharedModule } from '../shared/shared.module';
import { LogoutComponent } from '../logout/logout.component';
import { FooterModule } from 'app/shared/footer/footer.module';

@NgModule({
  imports: [CommonModule, BrowserModule, HyperionSharedModule, FooterModule],
  declarations: [LoginComponent, LogoutComponent],
  exports: [LoginComponent],
  providers: [],
})
export class LoginModule {}
