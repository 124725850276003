import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';
import { BehaviorSubject, Subscription, first, map } from 'rxjs';
import { Table } from 'primeng/table';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { HelperService } from '../../shared/helpers/index';
import { ServiceLocator } from '../../shared/helpers/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { SortService } from 'app/store/sort.service';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { CustomSort } from 'app/store/custom-sort';
import { CompanySeatsGQL } from './graphql/companySeats.generated';
import {
  CompanyUsersGQL,
  CompanyDeletedUsersGQL,
  CompanyUserFragment,
  RestoreUserGQL,
} from './graphql/users.generated';
import { SplitIoService } from 'app/shared/split-io.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'app/shared/message';
import { UserFlagsService } from 'app/user-flags.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: 'user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
  providers: [AppDialogService],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  @Input() public isModal = false;
  @ViewChild('userTable', { static: true }) public userTable: Table;

  public loading = true;
  public displayDialog = false;
  public showAdvancedSearch = false;
  public tableCols = [];
  public usersAsync: BehaviorSubject<CompanyUserFragment[]> =
    new BehaviorSubject([]);
  public sort: CustomSort = {
    attribute: 'id',
    ascDesc: -1,
    object: 'users_table',
  };
  public labelsToDisplay: string[] = [
    'firstName',
    'lastName',
    'phone',
    'mobilePhone',
    'email',
  ];
  public companyUserCount = 0;
  public companySeatsCount = 0;
  public showSeats = false;
  private seatsEnabled = false;
  public seatsUsagePercentage: number;
  public labels = {};
  private usersSub: Subscription;
  private defaultTableCol = [];
  private contactListTableCol = [];

  public helperService: HelperService;
  public sortService: SortService;

  public deletedUsersAsync: BehaviorSubject<CompanyUserFragment[]> =
    new BehaviorSubject([]);
  public deletedLoading = false;
  public showDeletedUsers = false;
  public showDeletedUsersButton = false;

  constructor(
    private labelsService: HyperionLabelsService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private companySeatsGQL: CompanySeatsGQL,
    private companyUsersGQL: CompanyUsersGQL,
    private companyDeletedUsersGQL: CompanyDeletedUsersGQL,
    private restoreUserGQL: RestoreUserGQL,
    private splitIoService: SplitIoService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userFlagsService: UserFlagsService
  ) {
    this.helperService = ServiceLocator.injector.get(HelperService);
    this.sortService = ServiceLocator.injector.get(SortService);
    this.getUsers();
    this.getLabels();
    this.dialogConfig.header = 'Användare';
  }

  public ngOnInit(): void {
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => (this.showDeletedUsersButton = flags.isAdmin));
    this.sort = this.sortService.getSort(this.sort);
    this.splitIoService
      .getTreatment('show_company_seats')
      .subscribe(treatment => {
        this.seatsEnabled = treatment === 'on';
      });
  }

  public ngOnDestroy(): void {
    if (this.usersSub) {
      this.usersSub.unsubscribe();
    }
  }

  private getLabels(): void {
    this.labelsService.getLabels([{ model: 'User' }]).subscribe(labels => {
      this.labelsToDisplay.forEach(label => {
        if (labels['User'][label]) {
          this.labels[label] = labels['User'][label];
        }
      });

      this.defaultTableCol = [
        {
          field: 'firstName',
          header: labels['User']['firstName'],
          width: 'auto',
        },
        {
          field: 'lastName',
          header: labels['User']['lastName'],
          width: 'auto',
        },
        { field: 'phone', header: labels['User']['phone'], width: '10%' },

        {
          field: 'mobilePhone',
          header: labels['User']['mobilePhone'],
          width: '10%',
        },
        { field: 'email', header: labels['User']['email'], width: 'auto' },
      ];
      this.contactListTableCol = [
        {
          field: 'firstName',
          header: labels['User']['firstName'],
          width: 'auto',
        },
        {
          field: 'lastName',
          header: labels['User']['lastName'],
          width: 'auto',
        },

        {
          field: 'relative1name',
          header: labels['User']['relative1name'],
          width: 'auto',
        },
        {
          field: 'relative1phone',
          header: labels['User']['relative1phone'],
          width: 'auto',
        },
        {
          field: 'relative2name',
          header: labels['User']['relative2name'],
          width: 'auto',
        },
        {
          field: 'relative2phone',
          header: labels['User']['relative2phone'],
          width: 'auto',
        },
      ];
      this.tableCols = this.defaultTableCol;
    });
  }

  public getUsers(): void {
    this.companyUsersGQL
      .fetch()
      .pipe(
        first(),
        map(res => res.data.company.users.edges.map(edge => edge.node))
      )
      .subscribe(users => {
        this.usersAsync.next(users);
        this.loading = false;
      });
    this.getSeats();
  }

  public getDeletedUsers(): void {
    this.deletedLoading = true;
    this.companyDeletedUsersGQL
      .fetch()
      .pipe(
        first(),
        map(res => res.data.company.deletedUsers.edges.map(edge => edge.node))
      )
      .subscribe(users => {
        this.deletedUsersAsync.next(users);
        this.deletedLoading = false;
      });
  }

  public getSeats(): void {
    this.companySeatsGQL
      .fetch()
      .pipe(first())
      .subscribe(seats => {
        this.companySeatsCount = seats.data.company.seats?.seats;
        this.companyUserCount = seats.data.company.seats?.usedSeats;
        this.seatsUsagePercentage = Math.round(
          (this.companyUserCount / this.companySeatsCount) * 100
        );
        this.showSeats = this.seatsEnabled && this.companySeatsCount > 0;
      });
  }

  public changeSortOrder(event: { field: string; order: number }): void {
    this.sortService.setSort(event, this.sort);
  }

  public resetExpandedRows(): void {
    if (this.userTable) {
      this.userTable.expandedRowKeys = {};
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  public handleDelete(event: any): void {
    this.resetExpandedRows();
    this.getUsers();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  public handleUpdated(event: any): void {
    this.getUsers();
  }

  public resetSearchParams(): void {
    this.userTable.reset();
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  public openCreateUserDialog(): void {
    this.displayDialog = !this.displayDialog;
  }

  public changeDisplayedTableCols(): void {
    /* eslint-disable eqeqeq */
    this.tableCols =
      this.tableCols == this.contactListTableCol
        ? this.defaultTableCol
        : this.contactListTableCol;
    /* eslint-enable eqeqeq */
  }

  public handleCreateUser(): void {
    this.displayDialog = false;
    this.getUsers();
  }

  public actionShowDeletedUsers(): void {
    this.showDeletedUsers = true;
    this.getDeletedUsers();
  }

  public actionRestoreUser(user: CompanyUserFragment): void {
    const name = `${user.firstName} ${user.lastName}`;
    this.confirmationService.confirm({
      header: `Återställ ${name}?`,
      message:
        `Är du säker på att du vill återställa användaren ${name}?<br>` +
        ' Att lägga till användare kan medföra en ökad månadskostnad om ni överstiger det antal användare ni betalar för idag.<br>' +
        ' Om ni överstiger det antal användare ni betalar för idag kommer er betalplan att justeras till det nya antalet användare.',
      accept: () => this.restoreUser(Number(user.id)),
    });
  }

  private restoreUser(userId: number): void {
    this.restoreUserGQL
      .mutate({ userId: userId })
      .pipe(first())
      .subscribe(res => {
        this.getDeletedUsers();
        this.getUsers();
        this.messageService.insertDataFromMutation(
          res.data.restoreUserMutation
        );
      });
  }
}
