import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import '@struqtur/dhtmlx-scheduler-enterprise';
import '@struqtur/dhtmlx-scheduler-enterprise/codebase/locale/locale_sv';
import '@struqtur/dhtmlx-scheduler-enterprise/codebase/ext/dhtmlxscheduler_minical.js';
import '@struqtur/dhtmlx-scheduler-enterprise/codebase/ext/dhtmlxscheduler_limit.js';
import '@struqtur/dhtmlx-scheduler-enterprise/codebase/ext/dhtmlxscheduler_tooltip.js';
import {
  SchedulerEnterprise,
  SchedulerStatic,
} from '@struqtur/dhtmlx-scheduler-enterprise';
import {
  EventColorsEnum,
  EventTextColorEnum,
  EventTypeEnum,
  MapTypeToDropdown,
  RepeatEventEnum,
} from 'app/planner/planner-module-enums';
import {
  ContextMenuEvent,
  LightboxDropdown,
  SchedulerEvent,
  SchedulerEventPlannedTime,
  SchedulerEventProject,
  UpdateColorEvent,
} from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import {
  PlannerQueryVars,
  Project,
  ProjectTodo,
  User,
  UserPlannedWork,
} from 'app/planner/services/planner-query-types';
import { PlannerReadService } from 'app/planner/services/planner-read.service';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { DateService } from 'app/shared/helpers/date.service';
import { HelperService } from 'app/shared';
import { isWeekend } from 'app/planner/utils/gantt';

declare let Scheduler: SchedulerEnterprise;

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-scheduler-view',
  templateUrl: './scheduler-view.component.html',
  styleUrls: ['./scheduler-view.component.scss'],
})
export class SchedulerViewComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('scheduler', { static: true })
  private schedulerContainer: ElementRef;
  @ViewChild('schedulerLightbox') private lightbox: ElementRef;

  private readonly SchedulerLocalStorageStateKey: string =
    'plannerSchedulerState';

  private scheduler: SchedulerStatic;

  private isViewResolved = false;
  private isViewResolvedSubject: Subject<boolean> = new Subject<boolean>();
  private isViewResolvedSubscription: Subscription;
  private scheduledEvents: SchedulerEvent[] = [];
  private currentSchedulerEvent: SchedulerEventPlannedTime | null = null;
  private initLoad = true;
  private activeDateSpan: { min: Date; today: Date; max: Date };
  private filterProjects: LightboxDropdown[] = [];
  private filterCoworkers: LightboxDropdown[] = [];
  private filterColors: string[] = [];

  public scheduledEvent:
    | SchedulerEventProject
    | SchedulerEventPlannedTime
    | null = null;
  public allTodos: {
    projectId: number;
    todos: ProjectTodo[];
  }[];
  public projects: Project[];
  public projectsFilterDropdown: LightboxDropdown[] = [];
  public coworkersDropdown: LightboxDropdown[];
  public todosDropdown: LightboxDropdown[];
  public isNewEvent: boolean;
  public colorContextMenuTitle = 'Välj färg';
  public contextMenuEventSubject: Subject<ContextMenuEvent> =
    new Subject<ContextMenuEvent>();
  public datePickerLocale: any;
  public currentDateRange: { start: Date; end: Date };
  public currentState: any = null;
  public isDisplayingProjects = false;
  public isLoading = false;
  public childrenEdgeDates: { start: string; end: string } = null;

  public handleCloseEvent = () => {
    this.scheduler.endLightbox(false);
    this.scheduledEvent = null;
    this.lightbox.nativeElement.style.display = 'none';
  };

  constructor(
    private plannerReadService: PlannerReadService,
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private helperService: HelperService,
    private messageService: MessageService,
    private dateService: DateService
  ) {
    this.isViewResolvedSubscription = this.isViewResolvedSubject.subscribe(
      () => {
        if (
          this.scheduledEvents &&
          this.isViewResolved &&
          this.projects &&
          this.coworkersDropdown &&
          this.allTodos
        ) {
          this.isLoading = false;
          this.clearWeekNumbers();
          this.scheduler.parse(this.scheduledEvents);
          this.addWeekNumbers();
        }
      }
    );
  }

  public ngOnInit(): void {
    this.isLoading = true;
    this.scheduler = Scheduler.getSchedulerInstance();
    this.initSchedulerSettings();

    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
    this.getCompanyWorkers();
  }

  public ngOnDestroy(): void {
    this.initLoad = true;
    this.isViewResolvedSubscription.unsubscribe();
  }

  public ngAfterViewInit(): void {
    this.isViewResolvedSubject.next((this.isViewResolved = true));
  }

  private storeCurrentState = (): void => {
    this.currentState = this.scheduler.getState();
    localStorage.setItem(
      this.SchedulerLocalStorageStateKey,
      JSON.stringify(this.currentState)
    );
  };

  private getCurrentState = (): void => {
    this.currentState = JSON.parse(
      localStorage.getItem(this.SchedulerLocalStorageStateKey)
    );
  };

  private initSchedulerSettings(): void {
    this.scheduler.config = {
      ...this.scheduler.config,
      multi_day_height_limit: 70,
      hour_size_px: 42,
      details_on_create: true,
      details_on_dblclick: true,
      icons_edit: [],
      icons_select: [],
      scroll_hour: 5,
      time_step: 15,
      header: ['day', 'week', 'month', 'date', 'prev', 'today', 'next'],
      max_month_events: 6,
      event_duration: 60,
    };
    this.initSchedulerEventHandlers();
    this.initSchedulerTemplates();
    this.initSchedulerFilters();

    this.scheduler.init(
      this.schedulerContainer.nativeElement,
      new Date(),
      'week'
    );
  }

  private initSchedulerTemplates(): void {
    this.setDayDateTemplate();
    this.setWeekDateTemplate();
    this.setMonthDateTemplate();
    this.initLightBoxTemplate();
    this.scheduler.templates.event_text = this.eventTextTemplate;
    this.scheduler.templates.event_bar_text = this.eventBarTextTemplate;
    this.scheduler.addMarkedTimespan({
      days: [0, 6],
      zones: 'fullday',
      css: 'red_day',
    });
    this.scheduler.templates.tooltip_text = (
      start: Date,
      end: Date,
      event: SchedulerEventPlannedTime
    ) => {
      if (event.eventType === EventTypeEnum.PlannedTime) {
        return this.eventTextTemplate(start, end, event, true);
      } else if (event.eventType === EventTypeEnum.Project) {
        return this.projectEventTooltipTemplate(start, end, event);
      }
    };
    this.scheduler.templates.month_events_link = (
      date: Date,
      count: number
    ) => {
      return (
        '<a class="show-more-events">Visa fler (' + count + ' händelser)</a>'
      );
    };
  }

  private initSchedulerEventHandlers(): void {
    this.scheduler.attachEvent('onClick', id => {
      this.scheduler.showLightbox(id);
      this.calculateLightboxPosition();
      return false;
    });
    this.scheduler.attachEvent('onDblClick', () => {
      return false;
    });
    this.scheduler.attachEvent('onContextMenu', this.handleContextMenuEvent);
    this.scheduler.attachEvent('onBeforeDrag', (id, mode: string) => {
      if (mode === 'create') {
        return true;
      }

      const event = this.scheduler.getEvent(id);
      if (event.eventType !== EventTypeEnum.PlannedTime) {
        return false;
      }
      this.clearWeekNumbers();
      this.currentSchedulerEvent = Object.assign({}, event);
      return true;
    });
    this.scheduler.attachEvent('onDragEnd', (id, mode) =>
      this.handleDragEvent(id, mode)
    );

    this.scheduler.attachEvent('onViewChange', (mode, date: Date) => {
      if (!this.initLoad) {
        this.storeCurrentState();
      }

      this.updateMiniCalendarRange(mode, date);

      if (
        !this.activeDateSpan ||
        date.getMonth() !== this.activeDateSpan.today.getMonth()
      ) {
        this.getScheduledProjects(this.calculateDataQueryDateSpan(date));
      }

      this.setDateScaleHeight(mode);
      this.clearWeekNumbers();
      this.addWeekNumbers();
    });

    this.scheduler.attachEvent('onDataRender', this.handleOnDataRender);
  }

  private handleOnDataRender = (): void => {
    if (this.initLoad) {
      this.getCurrentState();
      this.initLoad = false;
      if (this.currentState) {
        this.scheduler.setCurrentView(
          new Date(this.currentState.date),
          this.currentState.mode
        );
        return;
      }

      this.scheduler.updateView();
    }

    if (this.scheduler.getState().mode !== 'month') {
      this.reduceEventWidth();
    }
    const icon = document.querySelector<HTMLElement>('.dhx_multi_day_icon');
    if (icon) {
      icon.style.visibility = 'hidden';
    }
  };

  private initLightBoxTemplate(): void {
    this.scheduler.showLightbox = id => {
      this.scheduledEvent = this.scheduler.getEvent(id);
      this.isNewEvent = !!this.scheduler.getState().new_event;
      if (this.isNewEvent) {
        this.scheduledEvent.color = EventColorsEnum.Blue;
        this.scheduledEvent.textColor = EventTextColorEnum.White;
      }
      if (this.scheduledEvent.eventType === EventTypeEnum.Project) {
        const project = this.projects.find(p => +p.id === +id);
        const children = [...project.plannedWork, ...project.todos];
        const childrenDates = children.map(child => {
          return {
            startDate: moment(child.startDate).format('YYYY-MM-DD HH:mm'),
            endDate: moment(child.endDate).format('YYYY-MM-DD HH:mm'),
          };
        });

        this.childrenEdgeDates = this.plannerDataAdapterService.getEdgeDates([
          ...childrenDates.map(item => item.startDate),
          ...childrenDates.map(item => item.endDate),
        ]);
      }
      this.scheduler.startLightbox(id, this.lightbox.nativeElement);
    };
  }

  public handleSaveEvent(plannedWork: UserPlannedWork): void {
    if (plannedWork.repeat === RepeatEventEnum.NoRepeat) {
      const events =
        this.plannerDataAdapterService.mapDataToSchedulerEventPlannedTime([
          plannedWork,
        ]);
      this.scheduler.parse(events);
    } else {
      const state = this.scheduler.getState();
      this.getScheduledProjects(this.calculateDataQueryDateSpan(state.date));
    }
    this.lightbox.nativeElement.style.display = 'none';
  }

  public handleUpdateEvent(plannedWork: UserPlannedWork): void {
    if (plannedWork.repeat !== RepeatEventEnum.NoRepeat) {
      const state = this.scheduler.getState();
      this.getScheduledProjects(this.calculateDataQueryDateSpan(state.date));
    } else {
      this.clearWeekNumbers();
      const event = this.scheduler.getEvent(plannedWork.id);
      event.start_date = new Date(plannedWork.startDate);
      event.end_date = new Date(plannedWork.endDate);
      event.projectId = plannedWork.projectId;
      event.text = plannedWork.messageToUser;
      event.color = plannedWork.color;
      event.textColor = this.plannerDataAdapterService.setTextColor(
        plannedWork.color
      );
      event.todoId = plannedWork.todoId;
      event.userId = plannedWork.userId;
      this.scheduler.updateEvent(event.id.toString());

      this.addWeekNumbers();
      this.scheduler.updateView();
    }
  }

  public handleUpdateProject(project: Partial<Project>): void {
    this.clearWeekNumbers();
    const event: SchedulerEventProject = this.scheduler.getEvent(project.id);
    event.start_date = moment(project.startDate).toDate();
    event.end_date = moment(project.endDate)
      .set('hours', 23)
      .set('minutes', 59)
      .toDate();
    event.color = project.color;
    event.textColor = this.plannerDataAdapterService.setTextColor(
      project.color
    );
    this.scheduler.updateEvent(event.id.toString());
    this.addWeekNumbers();
  }

  public updateEventColor(update: UpdateColorEvent): void {
    this.clearWeekNumbers();

    const event = this.scheduler.getEvent(update.id);
    event.color = update.color;
    event.textColor = this.plannerDataAdapterService.setTextColor(update.color);
    this.scheduler.updateEvent(event.id.toString());

    this.addWeekNumbers();
  }

  public handleDeleteEvent(id: number): void {
    this.scheduler.deleteEvent(id);
  }

  private getScheduledProjects(queryVars: PlannerQueryVars): void {
    forkJoin([
      this.plannerReadService.getProjects(queryVars).pipe(take(1)),
      this.plannerReadService
        .getProjectsInternalWithPlannedTime(queryVars)
        .pipe(take(1)),
    ]).subscribe({
      next: ([projects, projectsInternal]) => {
        const events =
          this.plannerDataAdapterService.mapDataWithInternalProjectsToSchedulerEvents(
            projects,
            projectsInternal
          );

        const projectsDropdownList =
          this.plannerDataAdapterService.mapTypeToDropdownList<Project>(
            [...projects, ...projectsInternal],
            MapTypeToDropdown.Projects
          );

        this.scheduledEvents = this.helperService.addOrReplaceArray(
          this.scheduledEvents,
          events,
          'id'
        );

        this.projectsFilterDropdown = this.helperService.addOrReplaceArray(
          this.projectsFilterDropdown,
          projectsDropdownList,
          'value'
        );
      },
      error: error => console.warn(error),
      complete: () => this.isViewResolvedSubject.next(this.isViewResolved),
    });
  }

  private getAllCompanyProjects(queryVars: PlannerQueryVars): void {
    this.plannerReadService
      .getProjects(queryVars)
      .pipe(take(1))
      .subscribe(projects => {
        this.allTodos = [];
        projects.map(x => {
          if (x.todos.length > 0) {
            this.allTodos.push({
              projectId: x.id,
              todos: x.todos,
            });
          }
        });

        this.projects = projects;
        this.isViewResolvedSubject.next(this.isViewResolved);
      });
  }

  private getCompanyWorkers(): void {
    this.plannerReadService.getUsers().subscribe(users => {
      this.coworkersDropdown = [];
      this.coworkersDropdown =
        this.plannerDataAdapterService.mapTypeToDropdownList<User>(
          users,
          MapTypeToDropdown.Coworkers
        );
    });
  }

  private setDayDateTemplate(): void {
    this.scheduler.templates.day_date = date => {
      const day = this.scheduler.date.date_to_str('%j %F %Y');
      return day(date);
    };

    this.scheduler.templates.day_scale_date = date => {
      return `<div style="text-align: left;">
        ${this.weekScaleDate(date)}
      </div>`;
    };
  }

  private setWeekDateTemplate(): void {
    this.scheduler.templates.week_date = this.weekDate;
    this.scheduler.templates.week_scale_date = this.weekScaleDate;
  }

  /**
   * Returns the custom template for the active date in the week view header.
   * @param date Current active date in the calendar.
   * @returns '<Month> <Year> - <Month> <Year> - v.<Week>' (if the min and max year are not equal)
   * @returns '<Month> - <Month> <Year> - v.<Week>' (if min and max month are not equal)
   * @returns '<Month> <Year> - v.<Week>'
   */
  private weekDate = (date: Date) => {
    const state = this.scheduler.getState();
    const min = state.min_date;
    const max = state.max_date;
    const week = this.scheduler.date.date_to_str('%W');
    const month = this.scheduler.date.date_to_str('%F');
    const year = this.scheduler.date.date_to_str('%Y');

    if (year(min) !== year(max)) {
      return [
        ...new Set([
          [month(min), year(min)].join(' '),
          [month(max), year(max)].join(' '),
        ]),
        'v.' + week(date),
      ].join(' - ');
    }
    return [
      [...new Set([month(min), month(max)])].join(' - '),
      year(date),
      'v.' + week(date),
    ].join(' ');
  };

  /**
   * Returns the custom template for each day in the week view.
   * @param date Current active date in the calendar.
   * @returns Name of the day with its date below.
   */
  private weekScaleDate = (date: Date) => {
    const day = this.scheduler.date.date_to_str('%l');
    const dateNumber = this.scheduler.date.date_to_str('%j');
    const today = moment(date).isSame(new Date(), 'day') ? 'today' : '';
    const dayName = moment(date).locale('en').format('dddd').toLowerCase();
    return `<div class="week-scale-date ${dayName} ${today}">
        <span class="day-text">
          ${day(date)}
        </span>
        <br />
        <div class="day-number">
          ${dateNumber(date)}
        </div>
      </div>`;
  };

  private setMonthDateTemplate(): void {
    this.scheduler.templates.month_date = date => {
      const dateToStr = this.scheduler.date.date_to_str(
        this.scheduler.config.month_date
      );
      const dateToWeekNum = this.scheduler.date.date_to_str('%W');
      const maxViewDate = this.scheduler.date.add(date, 1, 'month');
      return (
        dateToStr(date) +
        ', v.' +
        dateToWeekNum(date) +
        ' - ' +
        dateToWeekNum(maxViewDate)
      );
    };

    this.scheduler.templates.month_day = date => {
      const isToday = moment(date).isSame(new Date(), 'day');
      const day = this.scheduler.date.date_to_str('%d');
      return `<div class="${isToday ? 'month-today' : ''}">
        ${day(date)}
      </div>`;
    };

    this.scheduler.templates.month_scale_date = (date): string => {
      const formatMonthScale = this.scheduler.date.date_to_str('%l');
      const dayLocalizedName = formatMonthScale(date);
      const dayName = moment(date).locale('en').format('dddd').toLowerCase();

      return `<span class="${dayName}">${dayLocalizedName}</span>`;
    };

    this.scheduler.templates.month_date_class = (date): string =>
      moment(date).locale('en').format('dddd').toLowerCase();
  }

  private clearWeekNumbers = (): void => {
    const state = this.scheduler.getState();
    if (state.mode === 'month') {
      document.querySelectorAll('.dhx_week_cell').forEach(element => {
        element.parentNode.removeChild(element);
      });
    }
  };

  private addWeekNumbers = (): void => {
    const state = this.scheduler.getState();
    if (state.mode === 'month') {
      this.addWeekNumberInMonthView(state.date);
    }
  };

  private addWeekNumberInMonthView = (date: Date): void => {
    const dateToWeekNum = this.scheduler.date.date_to_str('%W');
    const maxViewDate = (origin: Date, increase: number) =>
      this.scheduler.date.add(origin, increase, 'week');
    const weeks = document.querySelectorAll('.dhx_cal_data table tbody tr');
    Array.from(weeks).forEach(week => {
      week.insertAdjacentHTML('afterbegin', '<td class="dhx_week_cell"></td>');
    });
    const weekCell = document.querySelectorAll('.dhx_week_cell');
    Array.from(weekCell).forEach((w, i) => {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      w.insertAdjacentHTML(
        'afterbegin',
        `<div>v.${dateToWeekNum(maxViewDate(firstDay, i))}</div>`
      );
    });
  };

  private handleDragEvent(id: number | string, mode: string): void {
    const newMomentResetTime = (date: Date | string) =>
      moment(date).set('hours', 0).set('minutes', 0);

    const isInsideProjectRange = (
      date: moment.Moment,
      e: SchedulerEventPlannedTime
    ): boolean => {
      const projectData: Project = this.projects.find(
        project => +project.id === +e.projectId
      );

      if (this.plannerDataAdapterService.isSystemTypeProject(projectData)) {
        return true;
      }

      if (e.projectId != null) {
        const project: SchedulerEventProject = this.scheduler.getEvent(
          e.projectId
        );
        const pStart = newMomentResetTime(project.start_date);
        const pEnd = newMomentResetTime(project.end_date);
        return (
          date.valueOf() >= pStart.valueOf() && date.valueOf() <= pEnd.valueOf()
        );
      }
      return false;
    };

    const isInsideWorkTaskRange = (
      date: moment.Moment,
      e: SchedulerEventPlannedTime
    ): boolean => {
      if (e.todoId != null) {
        const todos = this.allTodos.find(x => +x.projectId === +e.projectId);
        const todo = todos.todos.find(t => +t.id === +e.todoId);
        const pStart = newMomentResetTime(todo.startDate);
        const pEnd = newMomentResetTime(todo.endDate);
        return (
          date.valueOf() >= pStart.valueOf() && date.valueOf() <= pEnd.valueOf()
        );
      }
      return true;
    };

    const event: SchedulerEventPlannedTime = this.scheduler.getEvent(id);
    if (mode === 'resize' || mode === 'move') {
      const newStart = moment(event.start_date);
      const newEnd = moment(event.end_date);
      const start = moment(this.currentSchedulerEvent.start_date);
      const end = moment(this.currentSchedulerEvent.end_date);

      if (
        newStart.valueOf() !== start.valueOf() ||
        newEnd.valueOf() !== end.valueOf()
      ) {
        if (
          isInsideProjectRange(newMomentResetTime(event.start_date), event) &&
          isInsideProjectRange(newMomentResetTime(event.end_date), event) &&
          isInsideWorkTaskRange(newMomentResetTime(event.start_date), event) &&
          isInsideWorkTaskRange(newMomentResetTime(event.end_date), event)
        ) {
          const plannedWork: UserPlannedWork =
            this.plannerDataAdapterService.mapSchedulerEventPlannedTimeToUserPlannedWork(
              event
            );

          this.plannerWriteService.updatePlannedTime(plannedWork).subscribe(
            result => {
              if (result.mutationSucceededAllArguments === true) {
                this.handleUpdateEvent(plannedWork);
              } else {
                this.messageService.insertDataFromMutation(result);
              }
            },
            error => {
              console.warn(error);
            },
            () => {
              this.addWeekNumbers();
            }
          );
        } else {
          const validation =
            event.todoId !== null ? 'arbetsmomentets' : 'projektets';
          const msg: ToastMessage = {
            severity: ToastMessageSeverityType.ERROR,
            summary: 'Något blev fel',
            detail: `Den planerade tiden är utanför ${validation} tidsintervall`,
          };
          this.messageService.insertData(msg);
          event.start_date = this.currentSchedulerEvent.start_date;
          event.end_date = this.currentSchedulerEvent.end_date;
          this.scheduler.updateEvent(event.id.toString());
          this.scheduler.render();
        }
      }
    }
  }

  public handleFilterUpdate(filter: {
    projects: LightboxDropdown[];
    coworkers: LightboxDropdown[];
    colors: string[];
  }): void {
    this.filterProjects = filter.projects;
    this.filterCoworkers = filter.coworkers;
    this.filterColors = filter.colors;
    this.scheduler.updateView();
  }

  private initSchedulerFilters(): void {
    (this.scheduler as any).filter_day = this.filterCalendar;
    (this.scheduler as any).filter_week = this.filterCalendar;
    (this.scheduler as any).filter_month = this.filterCalendar;
  }

  private filterCalendar = (
    id: number | string,
    event: SchedulerEventPlannedTime
  ): boolean => {
    const projectId = event?.projectId ? +event.projectId : +event.id;
    if (
      this.filterProjects.length === 0 &&
      this.filterCoworkers.length === 0 &&
      this.filterColors.length === 0
    ) {
      return true;
    }

    const coworker =
      this.filterCoworkers.length > 0
        ? !!this.filterCoworkers.find(c => +c.value === +event?.userId)
        : true;
    const project =
      this.filterProjects.length > 0
        ? !!this.filterProjects.find(p => +p.value === +projectId)
        : true;
    const color =
      this.filterColors.length > 0
        ? !!this.filterColors.find(c => c === event?.color)
        : true;

    if (coworker && project && color) {
      return true;
    }
    return false;
  };

  private eventTextTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime,
    isTooltip = false
  ): string => {
    const project = this.projects?.find(p => +p.id === +event.projectId);
    const coworker = this.coworkersDropdown?.find(
      c => +c.value === +event.userId
    );
    const projectTodos = this.allTodos?.find(
      at => +at.projectId === +event.projectId
    );
    let todo: ProjectTodo;
    if (projectTodos) {
      todo = projectTodos.todos.find(t => +t.id === +event.todoId);
    }

    return (
      (isTooltip
        ? '<div style="font-size: 1.1em;"><strong>Planerad tid</strong></div>'
        : '') +
      (project
        ? '<div class="event-text-template-overflow"><strong>Projekt:</strong><br />' +
          project.trueId +
          ', ' +
          project.mark +
          '</div>'
        : '') +
      (coworker
        ? '<div class="event-text-template-overflow"><strong>Medarbetare:</strong><br />' +
          coworker.label +
          '</div>'
        : '') +
      (todo
        ? '<div class="event-text-template-overflow"><strong>Arbetsmoment:</strong><br />' +
          todo.topic.Name +
          ', ' +
          todo.description +
          '</div>'
        : '') +
      (event.text
        ? '<div class="event-text-template-overflow"><strong>Meddelande:</strong><br />' +
          event.text +
          '</div>'
        : '')
    );
  };

  private eventBarTextTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime
  ) => {
    let project: Project;
    if (event.projectId) {
      project = this.projects?.find(p => +p.id === +event.projectId);
    } else {
      project = this.projects?.find(p => +p.id === +event.id);
    }
    return project
      ? project.trueId + ', ' + project.mark
      : event.id + ' ' + event.text;
  };

  public isPlannedTimeEvent(): boolean {
    if (this.scheduledEvent == null) {
      return false;
    }
    return !!(this.scheduledEvent as SchedulerEventPlannedTime).projectId;
  }

  public handleUpdateColorEvent(event: UpdateColorEvent): void {
    let apiCall: (item: any) => Observable<any>;
    if (event.isProject) {
      apiCall = this.plannerWriteService.updateProject;
    } else {
      apiCall = this.plannerWriteService.updatePlannedTime;
    }
    apiCall({ id: +event.id, color: event.color }).subscribe(
      result => {
        if (result.mutationSucceededAllArguments) {
          this.updateEventColor(event);
        } else {
          this.messageService.insertDataFromMutation(result);
        }
      },
      error => {
        console.warn(error);
      }
    );
  }

  private handleContextMenuEvent = (id: number, event: any) => {
    if (id) {
      const schedulerEvent = this.scheduler.getEvent(id);
      this.contextMenuEventSubject.next({ id, schedulerEvent, event });
      return false;
    }
    return true;
  };

  public createNewPlannedTime(): void {
    this.clearWeekNumbers();
    const { startHour, startMinutes, endHour, endMinutes } =
      this.dateService.getStandardWorkHours();

    const startDate = moment().hour(startHour).minute(startMinutes).second(0);
    const endDate = moment().hour(endHour).minute(endMinutes).second(0);
    const event: SchedulerEventPlannedTime = {
      color: EventColorsEnum.Blue,
      end_date: endDate.format('YYYY-MM-DD HH:mm'),
      start_date: startDate.format('YYYY-MM-DD HH:mm'),
      projectId: null,
      text: null,
      textColor: EventTextColorEnum.White,
      todoId: null,
      userId: null,
      eventType: EventTypeEnum.PlannedTime,
    };
    this.scheduler.addEventNow(event);

    this.calculateLightboxPosition();
    this.addWeekNumbers();
  }

  private setDateScaleHeight = (mode: string) => {
    if (mode !== 'month') {
      this.scheduler.xy.scale_height = 55;
    } else {
      this.scheduler.xy.scale_height = 20;
    }
    this.scheduler.updateView();
  };

  private calculateLightboxPosition = () => {
    const box = this.scheduler.getLightbox();
    const width = window.innerWidth / 2 - box.offsetWidth / 2;
    const height = window.innerHeight / 2 - 350 / 2;
    box.style.top = height + 'px';
    box.style.left = width + 'px';
  };

  private calculateDataQueryDateSpan = (date: Date): PlannerQueryVars => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const min = this.scheduler.date.add(firstDay, -1, 'month');
    const max = this.scheduler.date.add(lastDay, 1, 'month');

    this.activeDateSpan = { min, today: date, max };

    return {
      ...PlannerReadService.DEFAULT_PLANNER_QUERY_VARS,
      fromDate: moment(min).format('YYYY-MM-DD'),
      toDate: moment(max).format('YYYY-MM-DD'),
    } as PlannerQueryVars;
  };

  public toggleMenu = (): void => {
    const grid = document.getElementById('calendar-grid');
    const drawer = document.getElementById('menu');
    if (drawer.offsetWidth > 0) {
      drawer.style.width = 0 + 'px';
      drawer.style.border = 'none';
      drawer.style.padding = '0';
      grid.style.columnGap = '0';
    } else {
      drawer.style.width = 312 + 'px';
      drawer.style.padding = '0 7px 0 0';
      drawer.style.borderRight = '1px solid #e2e2e2';
      grid.style.columnGap = '7px';
    }
  };

  public handleMiniCalendarChange(dates: Date[]): void {
    this.scheduler.setCurrentView(dates[0]);
  }

  private updateMiniCalendarRange = (mode: string, date: Date): void => {
    const state = this.scheduler.getState();
    let start: Date;
    let end: Date;
    if (mode === 'month') {
      start = this.scheduler.date.month_start(date);
      const next = this.scheduler.date.add(start, 1, 'month');
      end = this.scheduler.date.add(next, -1, 'day');
    } else {
      start = state.min_date;
      end = this.scheduler.date.add(state.max_date, -1, 'day');
    }
    this.currentDateRange = { start, end };
  };

  public toggleMoreProjects(): void {
    this.isDisplayingProjects = !this.isDisplayingProjects;
    if (this.isDisplayingProjects) {
      this.scheduler.config.multi_day_height_limit = 300;
    } else {
      this.scheduler.config.multi_day_height_limit = 70;
    }
    this.scheduler.updateView();
  }

  /**
   * Get all single day events, per day, from the view (day & week)
   * and reduces width 10px from the event furthest to
   * the right per unique y-position.
   */
  private reduceEventWidth = (): void => {
    const days = Array.from(
      document.querySelectorAll('.dhx_scale_holder, .dhx_scale_holder_now')
    );
    days.forEach(day => {
      const events = Array.from(
        day.querySelectorAll<HTMLElement>(':scope > div.dhx_cal_event')
      );
      const yPositionsUnique = [
        ...new Set(events.map(event => event.offsetTop)),
      ];
      yPositionsUnique.forEach(position => {
        const yEvents = events.filter(e => e.offsetTop === position);
        const xPosition = Math.max(...yEvents.map(x => x.offsetLeft));
        const xEvent = yEvents.find(x => x.offsetLeft === xPosition);
        xEvent.style.width = xEvent.offsetWidth - 10 + 'px';
      });
    });
  };

  private projectEventTooltipTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime
  ): string => {
    const project = this.projects?.find(p => +p.id === +event.id);
    return `
      <div style="font-size: 1.1em;">
        <strong>Projekt</strong>
      </div>
      <div class="event-text-template-overflow">
        <strong>
          ${project.trueId}, ${project.mark}
        </strong>
      </div>
      <div style="height: 3px">&nbsp;</div>
      <div class="event-text-template-overflow">
        ${moment(event.start_date).format('YYYY-MM-DD')} - ${moment(
      event.end_date
    ).format('YYYY-MM-DD')}
      </div>`;
  };
}
