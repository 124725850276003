import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

import { HelperService } from '../../../shared/helpers/index';

@Component({
  selector: 'home-start-days-holder',
  templateUrl: 'days-read-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeStartDaysReadHolder implements OnChanges {
  @Input() daysFromParent;
  @Input() loading = true;

  overlay = {
    doneWork: null,
  };
  dataSet = [];

  constructor(private helperService: HelperService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['daysFromParent'].currentValue['edges']) {
      this.dataSet = this.helperService.cleanFromNode(
        changes['daysFromParent'].currentValue
      );
    }
  }
}
