import {
  Component,
  forwardRef,
  Injector,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-wide-textarea',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WideFormTextarea2Component),
      multi: true,
    },
  ],
  template: `
    <div class="p-grid form-group">
      <div class="p-col-12 p-md-3">
        <div class="wide-label">
          <label
            class="wide-label"
            *ngIf="!showToolTip"
            style="display:inline"
            [innerHTML]="htmlAttributes['label']['value'] || control.label"
          ></label>
          <label class="wide-label" *ngIf="showToolTip" style="display:inline"
            >{{ htmlAttributes['label']['value'] || control.label }}
            <span
              class="{{ tooltip['class'] }}"
              [tooltipStyleClass]="tooltip['tooltipStyleClass']"
              [tooltipPosition]="tooltip['tooltipPosition']"
              [pTooltip]="tooltip['msg']"
              [escape]="false"
            >
              ?</span
            >
          </label>
        </div>
      </div>
      <div class="p-col-12 p-md-9" #container style="position:relative">
        <textarea
          pInputTextarea
          style="flex-grow: 8"
          class="form-control resize-vert"
          [escape]="false"
          tooltipEvent="focus"
          tooltipPosition="bottom"
          [pTooltip]="control.hints || null"
          [rows]="rows ? rows : 2"
          [(ngModel)]="value"
          [disabled]="disabled"
          (blur)="onTouched()"
          (input)="pushChanges($event.target.value)"
          [class.form-control-danger]="control.showErrors || ''"
          [attr.id]="htmlAttributes['input']['id']"
          [attr.tabindex]="htmlAttributes['input']['tabIndex']"
          [attr.form-control-name]="control['name']"
        ></textarea>
        <div
          *ngIf="control.showErrors"
          style="flex-grow: 1;"
          class="vendium-tool-tip"
        >
          <p
            *ngFor="let error of control['mergedErrors']"
            class="form-control-feedback"
          >
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="error"></span>
          </p>
        </div>
      </div>
    </div>
  `,
})
export class WideFormTextarea2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  value: any;
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled: boolean;
  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
