import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { FormHandlerService } from 'app/shared/forms';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { CompanyFunctionsService } from 'app/shared/company/index';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';

import { CompanyInvoiceService } from '../../services/company-invoice.service';
import { InvoiceType } from '../../enums/invoice-type.enum';

@Component({
  selector: 'app-invoice-head-rot',
  templateUrl: './invoice-head-rot.component.html',
  styleUrls: ['./invoice-head-rot.component.scss'],
  providers: [FormHandlerService],
})
export class InvoiceHeadRotComponent implements OnChanges {
  formFields = {
    model: 'InvoiceRotRequestingExtra',
    nestedForm: false,
    attributes: {},
  };

  @Input() invoiceType;
  @Input() rotPersons: any[];
  @Input() private invoice;

  InvoiceType = InvoiceType;
  public invoiceCreatedWithUseRowBasedTaxReduction: boolean;
  headRotForm: ExtendedFormGroup;
  public readonly houseWorkTypes: object;

  constructor(
    private companyInvoiceService: CompanyInvoiceService,
    private formhandler: FormHandlerService,
    private companyFunctionsService: CompanyFunctionsService,
    private globalInvoiceService: GlobalInvoiceService
  ) {
    this.invoiceCreatedWithUseRowBasedTaxReduction = true;

    this.houseWorkTypes = this.globalInvoiceService.houseWorkTypes;

    const houseWorkWithCostTypes = [
      ...this.houseWorkTypes[InvoiceType.Rot],
      ...this.houseWorkTypes[InvoiceType.Rut],
      ...this.houseWorkTypes[InvoiceType.Green],
    ].map(houseWorkType => {
      if (houseWorkType.type === 'OTHERCOSTS') {
        return {
          [houseWorkType.name]: '',
        };
      }

      return {
        [`${houseWorkType.name}AntalTimmar`]: '',
        [`${houseWorkType.name}Materialkostnad`]: '',
      };
    });

    this.formFields.attributes = Object.assign({}, ...houseWorkWithCostTypes);

    this.headRotForm = this.formhandler.groupedFormSimple(this.formFields);
    this.formhandler.groupSetLabelsRules(this.formFields, this.headRotForm);
    this.companyInvoiceService.updateForm('rot', this.headRotForm);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.invoice) {
      this.invoiceCreatedWithUseRowBasedTaxReduction =
        !this.invoice.source.isLegacyTaxReductionInvoice;
    }
  }
}
