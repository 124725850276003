import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HelperService } from 'app/shared/helpers';
import { AppDialogService } from 'app/shared/dialogs';
import { ApolloQueryService, ApolloMutationService } from 'app/shared/apollo';
import { AttendanceReportService } from 'app/attendance-report/services/attendance-report.service';
import { AddAttendanceReportComponent } from '../attendance-report-dialogs/add-attendance-report/add-attendance-report.component';
import { EditAttendanceReportTimeComponent } from '../attendance-report-dialogs/edit-attendance-report-time/edit-attendance-report-time.component';

@Component({
  selector: 'attendance-reports-list',
  templateUrl: './attendance-reports-list.component.html',
  providers: [AppDialogService],
})
export class AttendanceReportsListComponent implements OnDestroy, OnChanges {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() isCompany = false;
  @Input() projectData: any = {};
  @Input() attendanceReports = [];
  @Input() inCollectionList = false;
  @Input() withoutRelations = false;
  @Input() attendanceReportsInfo = { projectId: null, orgNr: null };
  @Output() loadContractors = new EventEmitter();
  @Output() refetchAttendanceReports = new EventEmitter();

  gmapImg = '';
  attendanceReportSub: any;
  attendanceReportsSum = null;
  attendanceReportsSub: Subscription;
  attendanceReportsTableColumns: any[] = [
    { field: 'companyOrgNr' },
    { field: 'realUser_PreAttendanceReportTypeHyperion.firstName' },
    { field: 'realUser_PreAttendanceReportTypeHyperion.lastName' },
    { field: 'realUser_PreAttendanceReportTypeHyperion.personalNumber' },
    { field: 'startTime' },
    { field: 'startOrStopTime' },
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    public helperService: HelperService,
    private dialogService: AppDialogService,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private attendanceReportService: AttendanceReportService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['projectData'] &&
      this.projectData !== changes['projectData'].previousValue
    ) {
      this.attendanceReports = [...this.projectData['attendanceReports']];
      if (!this.withoutRelations && !this.inCollectionList) {
        this.getAttendanceReportsCompleteData();
      }
      this.cdr.markForCheck();
    }
  }

  getAttendanceReportsCompleteData() {
    const variables = {
      orgNr: '' + this.attendanceReportsInfo.orgNr,
      projectId: this.attendanceReportsInfo.projectId,
    };

    this.apolloQueryService
      .apolloQuery('attendanceReportsCompleteData', variables)
      .subscribe(data => {
        this.attendanceReportsSub = data.sub;
        this.loadContractors.emit();

        this.attendanceReportsSum =
          data.data.projectGlobal.attendanceReportsMeta_PreProjectTypeHyperion.sumHours;
        this.attendanceReports = [
          ...this.attendanceReportService.formatAttendanceReportsCompleteData(
            data.data,
            this.attendanceReports
          ),
        ];

        this.cdr.markForCheck();
      });
  }

  editTime(rowData, isStart: boolean, shouldUpdate: boolean, editData?) {
    const attendanceReportId = isStart ? rowData.start.id : rowData.id;
    const attendanceReportInfo = {
      userLabel:
        rowData.realUser_PreAttendanceReportTypeHyperion.firstName +
        ' ' +
        rowData.realUser_PreAttendanceReportTypeHyperion.lastName,
      startOrStopTime: editData
        ? editData.startOrStopTime
        : isStart
        ? rowData.startTime
        : rowData.startOrStopTime,
    };

    if (shouldUpdate) {
      this.dialogService.data = {
        startOrStopId: attendanceReportId,
        attendanceReportInfo,
        mode: 'update',
      };
      const header = `Redigera tid för ${attendanceReportInfo['userLabel']} ${attendanceReportInfo['startOrStopTime']}`;
      this.dialogService
        .openComponent(EditAttendanceReportTimeComponent, header)
        .onClose.subscribe(res => {
          if (res) {
            const newTime = {
              id: res.id,
              latitude: res.latitude,
              longitude: res.longitude,
              commentAboutUpdate: res.commentAboutUpdate,
              startOrStopTime: res.startOrStopTime,
            };

            const existingRow = rowData;
            const edits = isStart
              ? existingRow.startEditsCached
              : existingRow.editsCached;
            edits.splice(0, 0, newTime);

            this.updateAttendanceReports(existingRow);
          }
        });
    }
  }

  stopTime(rowData) {
    const attendanceReportId = rowData.id;
    const attendanceReportInfo = {
      userLabel:
        rowData.realUser_PreAttendanceReportTypeHyperion.firstName +
        ' ' +
        rowData.realUser_PreAttendanceReportTypeHyperion.lastName,
    };

    this.dialogService.data = {
      startOrStopId: attendanceReportId,
      attendanceReportInfo,
      mode: 'stop',
    };
    const header = `Stoppa tid för ${attendanceReportInfo['userLabel']}`;
    this.dialogService
      .openComponent(EditAttendanceReportTimeComponent, header)
      .onClose.subscribe(res => {
        if (res) {
          const existingRow = rowData;
          existingRow.comment = res.comment;
          existingRow.startOrStopTime = res.startOrStopTime;

          this.updateAttendanceReports(existingRow);
        }
      });
  }

  cancelAttendanceReport(rowData, isStart: boolean, editData?) {
    const attendanceReportId = isStart ? rowData.start.id : rowData.id;
    const attendanceReportInfo = {
      userLabel:
        rowData.realUser_PreAttendanceReportTypeHyperion.firstName +
        ' ' +
        rowData.realUser_PreAttendanceReportTypeHyperion.lastName,
      startOrStopTime: editData
        ? editData.startOrStopTime
        : isStart
        ? rowData.startTime
        : rowData.startOrStopTime,
    };

    this.dialogService.data = {
      startOrStopId: attendanceReportId,
      attendanceReportInfo,
      mode: 'cancel',
    };
    const header = `Makulera tid för ${attendanceReportInfo['userLabel']} ${attendanceReportInfo['startOrStopTime']}`;
    this.dialogService
      .openComponent(EditAttendanceReportTimeComponent, header)
      .onClose.subscribe(res => {
        if (res) {
          const newTime = {
            commentAboutUpdate: res.commentAboutUpdate,
            startOrStopTime: isStart
              ? rowData.startTime
              : rowData.startOrStopTime,
          };

          if (isStart) {
            rowData.startIsCanceled = 'true';
          } else {
            rowData.isCanceled = 'true';
          }
          const edits = isStart
            ? rowData.startEditsCached
            : rowData.editsCached;
          edits.splice(0, 0, newTime);

          this.updateAttendanceReports(rowData);
        }
      });
  }

  updateRow(rowData) {
    const dataToMutation = {
      id: Number(rowData.realUser_PreAttendanceReportTypeHyperion.classId),
      attendanceId: Number(rowData.id),
      companyOrgNr: rowData.companyOrgNr,
      firstName: rowData.realUser_PreAttendanceReportTypeHyperion.firstName,
      lastName: rowData.realUser_PreAttendanceReportTypeHyperion.lastName,
      personalNumber:
        rowData.realUser_PreAttendanceReportTypeHyperion.personalNumber,
    };
    this.updateAttendanceFreeUser(dataToMutation, rowData);
  }

  updateAttendanceFreeUser(dataToMutationParam, rowData) {
    const dataModel = 'attendanceFreeUser';
    const dataToMutation = dataToMutationParam;

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        dataModel,
        'update',
        false,
        dataToMutation,
        ['id', 'companyOrgNr']
      )
      .subscribe(executedData => {
        if (
          this.withoutRelations ||
          rowData.oldCompanyOrgNr !== rowData.companyOrgNr
        ) {
          this.refetchAttendanceReports.emit();
        }

        if (
          (rowData.realUser_PreAttendanceReportTypeHyperion.oldFirstName !==
            rowData.realUser_PreAttendanceReportTypeHyperion.firstName ||
            rowData.realUser_PreAttendanceReportTypeHyperion.oldLastName !==
              rowData.realUser_PreAttendanceReportTypeHyperion.lastName ||
            rowData.realUser_PreAttendanceReportTypeHyperion
              .oldPersonalNumber !==
              rowData.realUser_PreAttendanceReportTypeHyperion
                .personalNumber) &&
          !this.withoutRelations
        ) {
          this.getAttendanceReportsCompleteData();
        }
        executeMutationSub.unsubscribe();
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  openAddAttendanceReport() {
    const mode = this.isCompany ? 'user' : 'freeUser';

    this.dialogService.data = {
      attendanceReportsInfo: this.attendanceReportsInfo,
      mode,
    };
    this.dialogService
      .openComponent(AddAttendanceReportComponent)
      .onClose.subscribe(res => {
        if (res) {
          const variables = {
            projectId: Number(this.attendanceReportsInfo.projectId),
            id: Number(res.id),
          };
          const v = { id: Number(this.attendanceReportsInfo.projectId) };
          this.apolloQueryService
            .apolloWatchQueryTwo('attendanceReports', v)
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ sub }) => {
              this.attendanceReportSub = sub;
            });
          this.apolloQueryService
            .apolloQuery('singleAttendanceReport', variables)
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ data }) => {
              this.attendanceReports = [
                ...this.attendanceReportService.formatSingleAttendanceReport(
                  data,
                  this.attendanceReports
                ),
              ];
              setTimeout(() => {
                this.cdr.markForCheck();
                setTimeout(() => this.cdr.markForCheck(), 10);
              }, 10);
            });
        }
      });
  }

  setGmapImg(rowData, isStart = false) {
    const lat = isStart ? rowData.start.latitude : rowData.latitude;
    const long = isStart ? rowData.start.longitude : rowData.longitude;
    this.gmapImg = `<img src='https://api.mapbox.com/styles/v1/mapbox/streets-v10/static/pin-s+ecaa0e(${long},${lat})/${long},${lat},14/400x400?access_token=pk.eyJ1IjoiamFtaXVtYWthbmp1b2xhIiwiYSI6ImNqbDNuMnhidzE1bDczcHF5M2VuNWw1N2IifQ.9t057otobGOi9RLsDRuG7Q'>`;
  }

  private updateAttendanceReports(rowData: any) {
    const attendanceReports = [...this.attendanceReports];
    const index = attendanceReports.findIndex(
      a => Number(a.id) === Number(rowData.id)
    );
    if (index !== -1) {
      attendanceReports[index] = rowData;
      this.attendanceReports = attendanceReports;
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    this.attendanceReportsSub && this.attendanceReportsSub.unsubscribe();
    this.attendanceReportSub && this.attendanceReportSub.unsubscribe();
    this.cdr.detach();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
