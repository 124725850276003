<div class="page-container">
  <p-card>
    <div class="p-grid">
      <div class="p-col-12">
        <h2>Allmänna uppgifter</h2>
      </div>
      <div class="p-col-12" *ngIf="loading">
        <spinner></spinner>
      </div>
      <div class="p-col-12" *ngIf="!loading">
        <form
          [formGroup]="componentMainForm"
          (keyup.enter)="actionUpdate()"
          class="p-grid full-width"
        >
          <div class="p-col-12 p-lg-3">
            <appForm2-simple-input
              formControlName="firstName"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="lastName"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="personalNumber"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="mobilePhone"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="phone"
            ></appForm2-simple-input>
          </div>

          <div class="p-col-12 p-lg-3">
            <appForm2-simple-input
              formControlName="email"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="address"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="address2"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="cityCode"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="city"
            ></appForm2-simple-input>
          </div>

          <div class="p-col-12 p-lg-3">
            <div>
              <label class="form-control-label">Uppdatera användarnamn</label>
              <span class="p-inputgroup">
                <input
                  pInputText
                  [placeholder]="meData.username.split('@')[0]"
                  formControlName="newUsername"
                />
                <span class="p-inputgroup-addon">@{{ meData.companyId }} </span>
              </span>
            </div>
            <appForm2-simple-input
              formControlName="password"
              type="password"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="repeat_password"
              type="password"
            ></appForm2-simple-input>
          </div>

          <div class="p-col-12 p-lg-3">
            <div
              class="upload-logo"
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 300px;
              "
            >
              <h4>Mailsignatur <small>Accepterar .jpg & .png</small></h4>

              <div
                style="text-align: center; margin-bottom: 0.5em"
                *ngIf="!imgError"
              >
                <img
                  style="max-width: 60%"
                  [src]="urlPrefix + '/user/mailSignature?rand=' + rand"
                  (error)="imgReadError()"
                />
              </div>
              <div
                style="text-align: center; margin-bottom: 0.5em"
                *ngIf="imgError"
              >
                <i
                  class="fa fa-times-rectangle delete"
                  title="Signatur finns inte eller kunde inte läsas"
                ></i>
              </div>
              <p-fileUpload
                name="PictureForm[image]"
                accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
                chooseLabel="Ange fil"
                [multiple]="false"
                [url]="urlPrefix + '/user/UploadMailSignature'"
                uploadLabel="Ladda upp"
                cancelLabel="Avbryt"
                invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
                invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
                (onUpload)="onUpload($event)"
              >
                <ng-template pTemplate="content">
                  <app-drag-and-drop-template></app-drag-and-drop-template>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>

          <div class="p-grid p-col-12">
            <div class="p-col-12 settings-sub-header">
              <h2>Kontaktpersoner / anhöriga</h2>
            </div>
            <div class="p-col-12 p-lg-4">
              <div class="p-col-12">
                <h3>Anhörig 1</h3>
              </div>

              <appForm2-simple-input
                formControlName="relative1name"
              ></appForm2-simple-input>
              <appForm2-simple-input
                formControlName="relative1phone"
              ></appForm2-simple-input>
              <appForm2-simple-input
                formControlName="relative1other"
              ></appForm2-simple-input>
            </div>
            <div class="p-col-12 p-lg-4">
              <div class="p-col-12">
                <h3>Anhörig 2</h3>
              </div>

              <appForm2-simple-input
                formControlName="relative2name"
              ></appForm2-simple-input>
              <appForm2-simple-input
                formControlName="relative2phone"
              ></appForm2-simple-input>
              <appForm2-simple-input
                formControlName="relative2other"
              ></appForm2-simple-input>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 align-right">
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="buttons.update.disabled"
          (click)="actionUpdate()"
          label="Uppdatera"
          icon="pi pi-save"
        ></button>
      </div>
    </div>
  </p-card>
</div>
