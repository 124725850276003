import { Component } from '@angular/core';

@Component({
  selector: 'app-drag-and-drop-template',
  templateUrl: './drag-and-drop-template.component.html',
  styleUrls: ['./drag-and-drop-template.component.scss'],
})
export class DragAndDropTemplateComponent {
  constructor() {
    this.disableChooseButton();
  }

  public triggerFileSelector = () => {
    const element: HTMLElement = document.querySelectorAll(
      'input[type=file]'
    )[0] as HTMLElement;
    element.click();
  };

  private disableChooseButton = () => {
    const element: HTMLInputElement = document.querySelector(
      '.p-fileupload-choose'
    ) as HTMLInputElement;

    if (element) {
      element.style.visibility = 'hidden';
      element.style.position = 'absolute';
    }
  };
}
