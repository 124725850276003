<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-3 p-g" *ngIf="isPrivilegedUser">
    <hyperion-card hasHeader="true">
      <div class="header">
        <h4>Beställare</h4>
        <span class="pull-right">
          <i
            *ngIf="toggleForms.client"
            class="edit-panel fa fa-save"
            (click)="actionUpdate('client')"
          ></i>
          <i
            class="edit-panel fa"
            [class.fa-pencil]="!toggleForms.client"
            [class.fa-times]="toggleForms.client"
            (click)="toggleForm('client')"
          ></i>
        </span>
      </div>
      <div *ngIf="!toggleForms.client">
        <ul class="column-info-ul p-text-truncate">
          <li *ngIf="projectData.clientContact.contactType !== 'Privat'">
            <div>{{ projectLabels.Contact.orderBuisnessName }}:</div>
            <div>{{ projectData['clientContact']['orderBuisnessName'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.name }}:</div>
            <div>{{ projectData['clientContact']['name'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.address }}:</div>
            <div>
              {{ projectData['clientContact']['address'] }}<br />
              {{ projectData['clientContact']['cityCode'] }}
              {{ projectData['clientContact']['city'] }}
            </div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.mail }}:</div>
            <div>{{ projectData['clientContact']['mail'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.mailInvoice }}:</div>
            <div>{{ projectData['clientContact']['mailInvoice'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.mobilePhone }}:</div>
            <div>{{ projectData['clientContact']['mobilePhone'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.phone }}:</div>
            <div>{{ projectData['clientContact']['phone'] }}</div>
          </li>
        </ul>
      </div>
      <div *ngIf="toggleForms.client" class="p-grid">
        <form *ngIf="clientForm" [formGroup]="clientForm" class="full-width">
          <div *ngIf="!saveAsNewContact" class="p-col-12">
            <div class="form-group">
              <label
                class="form-control-label"
                *ngIf="autoComplete.client.model > 0"
                >Beställare knyten till kontakt:</label
              >
              <label
                class="form-control-label"
                *ngIf="autoComplete.client.model < 1"
                >Knyt beställare till kontakt:</label
              >
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"
                  ><i class="fa fa-search"></i
                ></span>
                <input
                  *ngIf="autoComplete.client.setFromStart"
                  disabled
                  type="text"
                  pInputText
                  [(ngModel)]="autoComplete.client.model"
                  [ngModelOptions]="{ standalone: true }"
                />
                <p-autoComplete
                  *ngIf="!autoComplete.client.setFromStart"
                  placeholder="Sök befintlig kontakt"
                  [suggestions]="autoComplete.client.results"
                  (completeMethod)="searchContacts($event, 'client')"
                  inputStyleClass="form-control"
                  styleClass="wide-panel"
                  [(ngModel)]="autoComplete.client.model"
                  [ngModelOptions]="{ standalone: true }"
                  (onSelect)="setContactFromAutosuggest($event, 'client')"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  field="trueId"
                >
                  <ng-template let-object pTemplate="item">
                    <div class="p-helper-clearfix" style="font-size: 12px">
                      <div class="autocomplete-row autocomplete-header-row">
                        <div style="width: 10%">ID</div>
                        <div style="width: 22%">Namn</div>
                        <div style="width: 23%">Företag</div>
                        <div style="width: 42%">Adress</div>
                        <div style="float: none" class="clear-both"></div>
                      </div>
                      <div class="autocomplete-row">
                        <div style="width: 10%">{{ object.trueId }}</div>
                        <div style="width: 22%">{{ object.name }}</div>
                        <div style="width: 22%">
                          {{
                            object.contactType === 'Privat'
                              ? '-'
                              : object.orderBuisnessName
                          }}
                        </div>
                        <div style="width: 22%">
                          {{ object.address }}, {{ object.address2 }}
                        </div>
                        <div style="width: 22%">
                          {{ object.cityCode }}, {{ object.city }}
                        </div>
                        <div style="float: none" class="clear-both"></div>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
                <span
                  *ngIf="!autoComplete.client.showSave"
                  class="p-inputgroup-addon darken remove hover-pointer"
                  (click)="removeCoupling('client')"
                  pTooltip="Knyt ej"
                  tooltipPosition="left"
                  ><i class="fa fa-times"></i
                ></span>
              </div>
            </div>
          </div>

          <appForm2-simple-input
            *ngIf="
              !clientForm.get('contactType') ||
              clientForm.get('contactType').value !== 'Privat'
            "
            formControlName="orderBuisnessName"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="name"></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="orgNr"
          ></appForm2-simple-input>

          <appForm2-simple-dropDown
            formControlName="betalningsvillkor"
            [dropDownParams]="{ options: betalningsvillkorOptions }"
          ></appForm2-simple-dropDown>

          <appForm2-simple-input
            formControlName="address"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="address2"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="cityCode"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="city"></appForm2-simple-input>

          <appForm2-simple-input
            *ngIf="
              !clientForm.get('contactType') ||
              clientForm.get('contactType').value === 'Privat'
            "
            formControlName="propertyName"
          ></appForm2-simple-input>

          <appForm2-simple-input
            *ngIf="
              !clientForm.get('contactType') ||
              clientForm.get('contactType').value === 'Privat'
            "
            formControlName="housingAssociationOrgNumber"
          ></appForm2-simple-input>

          <appForm2-simple-input
            *ngIf="
              !clientForm.get('contactType') ||
              clientForm.get('contactType').value === 'Privat'
            "
            formControlName="apartmentDesignation"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="phone"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="mobilePhone"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="mail"></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="mailInvoice"
          ></appForm2-simple-input>

          <appForm2-simple-checkbox
            *ngIf="
              !clientForm.get('contactType') ||
              clientForm.get('contactType').value !== 'Privat'
            "
            formControlName="reverseTax"
          ></appForm2-simple-checkbox>

          <div class="project-client-new-contact">
            <p-checkbox
              label="Spara som ny kontakt"
              name="saveAsNewContact"
              [(ngModel)]="saveAsNewContact"
              [binary]="true"
              [ngModelOptions]="{ standalone: true }"
            ></p-checkbox>
            <div *ngIf="saveAsNewContact">
              <p-radioButton
                label="Privat"
                name="contactType"
                value="Privat"
                formControlName="contactType"
              ></p-radioButton>
              <p-radioButton
                label="Företag"
                name="contactType"
                value="Företag"
                formControlName="contactType"
              ></p-radioButton>
            </div>
          </div>
        </form>
        <div class="p-col-12 align-right">
          <app-button
            (click)="actionUpdate('client')"
            [type]="'update'"
          ></app-button>
        </div>
      </div>
    </hyperion-card>
  </div>
  <div
    class="p-col-12 p-g"
    [class.p-md-6]="isPrivilegedUser"
    [class.p-lg-3]="isPrivilegedUser"
  >
    <hyperion-card hasHeader="true">
      <div class="header">
        <h4>Arbetsställe</h4>
        <span class="pull-right" *ngIf="isPrivilegedUser">
          <i
            *ngIf="toggleForms.establishment"
            class="edit-panel fa fa-save"
            (click)="actionUpdate('establishment')"
          ></i>
          <i
            class="edit-panel fa"
            [class.fa-pencil]="!toggleForms.establishment"
            [class.fa-times]="toggleForms.establishment"
            (click)="toggleForm('establishment')"
          ></i>
        </span>
      </div>
      <div *ngIf="!toggleForms.establishment">
        <ul class="column-info-ul p-text-truncate">
          <li>
            <div>{{ projectLabels.Contact.orderBuisnessName }}:</div>
            <div>
              {{ projectData['establishmentContact']['orderBuisnessName'] }}
            </div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.name }}:</div>
            <div>{{ projectData['establishmentContact']['name'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.address }}:</div>
            <div>
              {{ projectData['establishmentContact']['address'] }}<br />
              {{ projectData['establishmentContact']['cityCode'] }}
              {{ projectData['establishmentContact']['city'] }}
            </div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.mail }}:</div>
            <div>{{ projectData['establishmentContact']['mail'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.mobilePhone }}:</div>
            <div>{{ projectData['establishmentContact']['mobilePhone'] }}</div>
          </li>
          <li>
            <div>{{ projectLabels.Contact.phone }}:</div>
            <div>{{ projectData['establishmentContact']['phone'] }}</div>
          </li>
        </ul>
      </div>
      <div *ngIf="toggleForms.establishment" class="p-grid">
        <form
          *ngIf="establishmentForm"
          [formGroup]="establishmentForm"
          class="full-width"
        >
          <appForm2-simple-input
            formControlName="orderBuisnessName"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="name"></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="orgNr"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="address"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="address2"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="cityCode"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="city"></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="phone"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="mobilePhone"
          ></appForm2-simple-input>

          <appForm2-simple-input formControlName="mail"></appForm2-simple-input>
        </form>
        <div class="p-col-12 align-right">
          <app-button
            (onClick)="actionUpdate('establishment')"
            [type]="'update'"
            [disabled]="buttons.establishment['update']['disabled']"
          ></app-button>
        </div>
      </div>
    </hyperion-card>
  </div>

  <div class="p-col-12 p-md-6 p-lg-3 p-g" *ngIf="isPrivilegedUser">
    <hyperion-card hasHeader="true">
      <div class="header">
        <h4>Information</h4>
        <span class="pull-right">
          <i
            *ngIf="toggleForms.information"
            class="edit-panel fa fa-save"
            (click)="actionUpdate('information')"
          ></i>
          <i
            class="edit-panel fa"
            [class.fa-pencil]="!toggleForms.information"
            [class.fa-times]="toggleForms.information"
            (click)="toggleForm('information')"
          ></i>
        </span>
      </div>
      <div *ngIf="!toggleForms.information">
        <ul class="column-info-ul p-text-truncate">
          <li>
            <div>Märkning:</div>
            <div>{{ projectData['mark'] }}</div>
          </li>
          <li>
            <div>Skapat av:</div>
            <div>
              {{ projectData['createdByUser']['firstName'] }}
              {{ projectData['createdByUser']['lastName'] }}
            </div>
          </li>
          <li>
            <div>Planerat från → till:</div>
            <div>
              {{ projectData['startDate'] }} → {{ projectData['endDate'] }}
            </div>
          </li>
          <li>
            <div>{{ projectLabels.Project.madeBy }}:</div>
            <div>{{ projectData['madeBy'] }}</div>
          </li>
          <li>
            <div>Offererat ex.moms:</div>
            <div>
              <strong>{{ offertTotal | formatNum: 0 }} (ex.rot)</strong>
            </div>
          </li>
          <li>
            <div>{{ projectLabels.Project.orderNumber }}:</div>
            <div>{{ projectData['orderNumber'] }}</div>
          </li>
        </ul>
      </div>
      <div *ngIf="toggleForms.information" class="p-grid">
        <form
          *ngIf="informationForm"
          [formGroup]="informationForm"
          class="full-width"
        >
          <appForm2-simple-input formControlName="mark"></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="madeBy"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="offertSum"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="offertSumWork"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="orderNumber"
          ></appForm2-simple-input>

          <appForm2-simple-calendar
            formControlName="startDate"
          ></appForm2-simple-calendar>

          <appForm2-simple-calendar
            formControlName="endDate"
          ></appForm2-simple-calendar>

          <appForm2-simple-dropDown
            *ngIf="informationForm.controls['typeId']"
            formControlName="typeId"
            (rightFuncCall)="openProjectSettingsDialog()"
            [dropDownParams]="{
              options: functionsData.projectCostTypes,
              rightIcon: 'fa fa-pencil-square-o hover-pointer'
            }"
          ></appForm2-simple-dropDown>

          <appForm2-simple-dropDown
            formControlName="offerId"
            [dropDownParams]="{ options: offertDropDownParams, filter: true }"
          ></appForm2-simple-dropDown>

          <app-project-external-dropdown
            *ngIf="
              projectData &&
              showProductExternalConnectInterface &&
              isPrivilegedUser
            "
            [disableOnSelect]="true"
            [projectId]="projectInfo.id"
            [preSelectedExternalProject]="currentExternalProject"
            [projectExternalSource]="'derome-api'"
            [type]="'simple'"
            (externalProjectConnectionChanged)="
              setExternalProjectSettings($event)
            "
          >
          </app-project-external-dropdown>

          <appForm2-simple-input
            formControlName="constructionSiteNumber"
          ></appForm2-simple-input>
        </form>
        <div class="p-col-12 align-right">
          <app-button
            (onClick)="actionUpdate('information')"
            [type]="'update'"
            [model]="'Project'"
            [disabled]="buttons.information['update']['disabled']"
          ></app-button>
        </div>
      </div>
    </hyperion-card>
  </div>
  <div
    class="p-col-12 p-md-6 p-lg-3 project-main-buttons"
    *ngIf="isPrivilegedUser"
  >
    <div class="p-grid">
      <div class="p-col-12">
        <button
          pButton
          icon="pi pi-file"
          label="Rapportgenerator"
          (click)="openUnderlagDialog()"
        ></button>
      </div>
      <div class="p-col-12 p-md-6 p-g-nopad">
        <button
          pButton
          icon="pi pi-wallet"
          label="Fakturera"
          *ngIf="isAdminUser"
          (click)="openInvoiceDialog()"
        ></button>
      </div>
      <div class="p-col-12 p-grid" *ngIf="!hideMoveBtns">
        <div class="p-col-12">
          <button
            pButton
            type="button"
            *ngIf="!noWhereToMoveTo"
            (click)="updateProjectStatus('back')"
            icon="pi pi-angle-double-left"
            label="{{ moveBackBtn.value }}"
          ></button>
        </div>
        <div class="p-col-12">
          <app-split-button
            *ngIf="moveForwardBtn.show && isAdminUser"
            [data]="{
              label: moveForwardBtn.value,
              icon: 'pi pi-angle-double-right'
            }"
            [btnModel]="forwardOptions"
            (onClick)="updateProjectStatus('forward')"
          ></app-split-button>
        </div>
      </div>
      <div class="p-col-6">
        <a [href]="exportLink">
          <button pButton label="Ladda ned" icon="pi pi-file-excel"></button>
        </a>
      </div>

      <div class="p-col-6">
        <button
          pButton
          (click)="confirmDeleteProject()"
          label="Radera"
          icon="pi pi-trash"
        ></button>
      </div>

      <div *ngIf="isAdminUser && shareProjectPartner" class="p-col-12">
        <button
          pButton
          label="Samarbetspartners Data"
          icon="pi pi-paperclip"
          (click)="openProjectChildsDialog()"
        ></button>
      </div>
    </div>

    <div class="p-grid"></div>

    <div *ngIf="isAdminUser" class="p-col-12">
      <appForm2-list-checkbox
        [ngModel]="availableForTimeReport"
        (ngModelChange)="updateAvailableForTimeReport($event)"
        [htmlAttributes]="{
          label: { value: 'Möjligt att tidrapportera på detta projekt' }
        }"
      ></appForm2-list-checkbox>
    </div>
  </div>
</div>
<div *ngIf="isPrivilegedUser">
  <div class="p-grid">
    <div
      class="p-col-12 p-g"
      [class.p-lg-6]="!(state | async).width.normal"
      [class.p-lg-12]="(state | async).width.normal"
    >
      <hyperion-card hasHeader="true">
        <div class="header toggle-buttons">
          <h4>
            Utfört arbete
            <span
              class="help-tooltip"
              tooltipPosition="top"
              pTooltip="För att redigera klicka på det du vill ändra och sen klicka på spara <i style='font-size:17px' class='fa fa-save'></i>. För att få fler möjligheter att redigera klicka på växla överblick."
              [escape]="false"
              >?</span
            >
          </h4>
          <app-button
            (click)="toggleWidth('normal')"
            [type]="'toogleWidth'"
          ></app-button>
        </div>
        <project-day
          [projectInfo]="projectInfo"
          [projectLabels]="projectLabels"
          [projectInvoiceData]="projectInvoiceData | async"
          [daysFromParent]="projectDaysData.normal | async"
          [functionsThisModel]="functionsThisModel"
          [functionsData]="functionsData"
          [state]="{
            width: (state | async).width.normal,
            height: (state | async).length.normalDay
          }"
          (handleDeletedDay)="handleDeletedDay($event)"
          (callGetInvoiceData)="getInvoiceData()"
          (toggleDay)="setWidth('normal', $event)"
          [dataLoading]="loadingAsync.days | async"
        ></project-day>
      </hyperion-card>
    </div>
    <div
      class="p-col-12 p-g"
      [class.p-lg-6]="!(state | async).width.normal"
      [class.p-lg-12]="(state | async).width.normal"
    >
      <hyperion-card hasHeader="true">
        <div class="header toggle-buttons">
          <h4 [id]="'normal-products-' + projectInfo['id']">
            Material / övriga kostnader
            <span
              class="help-tooltip"
              tooltipPosition="top"
              pTooltip="För att redigera klicka på det du vill ändra och sen klicka på spara <i style='font-size:17px' class='fa fa-save'></i>. För att få fler möjligheter att redigera klicka på växla överblick."
              [escape]="false"
              >?</span
            >
          </h4>
          <p
            *ngIf="showBookProductLink"
            style="
              display: inline;
              font-size: small;
              font-weight: 400;
              cursor: pointer;
            "
          >
            <span style="padding-left: 10px">
              <a (click)="showBookProductDiaologFunc()"
                >Bokade produkter</a
              ></span
            >
            /
            <span>
              <a target="popup" (click)="openPlockLista()">Plocklista</a>
            </span>
          </p>
          <app-button
            (click)="toggleWidth('normal', true)"
            [type]="'toogleWidth'"
          ></app-button>
        </div>
        <project-product
          [projectInfo]="projectInfo"
          [projectLabels]="projectLabels"
          [projectInvoiceData]="projectInvoiceData | async"
          [productsFromParent]="projectProductsData.normal | async"
          [projectCostTypesFromParent]="projectCostTypes"
          [dataLoading]="loadingAsync.products | async"
          [functionsData]="functionsData"
          [state]="{
            width: (state | async).width.normal,
            height: (state | async).length.normalProduct
          }"
          (changeExtra)="handleChangedExtraProduct($event)"
          (moveProductToCostType)="moveProductToCostType($event)"
          (callGetInvoiceData)="getInvoiceData()"
          [showProductPrices]="showProductPrices"
          [isMissingDeromeProductIntegration]="
            isMissingDeromeProductIntegration
          "
        ></project-product>
      </hyperion-card>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <button
        pButton
        type="button"
        class="block p-button-secondary"
        [class.gray-button]="
          (projectDaysData.extra | async)?.daysTimeHour2Data?.length == 0 &&
          (projectProductsData.extra | async)?.length == 0
        "
        (click)="toggleExtra()"
        label="{{ (state | async).extraBtnText }} extraarbete"
      ></button>
    </div>
  </div>
  <div class="p-grid" *ngIf="(state | async).showExtra">
    <div class="p-col-12">
      <h2>Extraarbete</h2>
    </div>
    <div
      class="p-col-12 p-g"
      [class.p-lg-6]="!(state | async).width.extra"
      [class.p-lg-12]="(state | async).width.extra"
    >
      <hyperion-card hasHeader="true">
        <div class="header toggle-buttons">
          <h4>
            Utfört arbete
            <span
              class="help-tooltip"
              tooltipPosition="top"
              pTooltip="För att redigera klicka på det du vill ändra och sen klicka på spara <i style='font-size:17px' class='fa fa-save'></i>. För att få fler möjligheter att redigera klicka på växla överblick."
              [escape]="false"
              >?</span
            >
          </h4>
          <app-button
            (click)="toggleWidth('extra')"
            [type]="'toogleWidth'"
          ></app-button>
        </div>
        <project-day
          [projectInfo]="projectInfo"
          [projectLabels]="projectLabels"
          [projectInvoiceData]="projectInvoiceData | async"
          [functionsData]="functionsData"
          [daysFromParent]="projectDaysData.extra | async"
          [functionsThisModel]="functionsThisModel"
          [isExtra]="true"
          [dataLoading]="loadingAsync.days | async"
          [state]="{
            width: (state | async).width.extra,
            height: (state | async).length.extraDay
          }"
          (handleDeletedDay)="handleDeletedDay($event)"
          (callGetInvoiceData)="getInvoiceData()"
          (toggleDay)="setWidth('extra', $event)"
        ></project-day>
      </hyperion-card>
    </div>
    <div
      class="p-col-12 p-g"
      [class.p-lg-6]="!(state | async).width.extra"
      [class.p-lg-12]="(state | async).width.extra"
    >
      <hyperion-card hasHeader="true">
        <div class="header toggle-buttons">
          <h4 [id]="'extra-products-' + projectInfo['id']">
            Material / övriga kostnader
            <span
              class="help-tooltip"
              tooltipPosition="top"
              pTooltip="För att redigera klicka på det du vill ändra och sen klicka på spara <i style='font-size:17px' class='fa fa-save'></i>. För att få fler möjligheter att redigera klicka på växla överblick."
              [escape]="false"
              >?</span
            >
          </h4>
          <app-button
            (click)="toggleWidth('extra', true)"
            [type]="'toogleWidth'"
          ></app-button>
        </div>
        <project-product
          [projectInfo]="projectInfo"
          [projectLabels]="projectLabels"
          [projectInvoiceData]="projectInvoiceData | async"
          [productsFromParent]="projectProductsData.extra | async"
          [isExtra]="true"
          [projectCostTypesFromParent]="projectCostTypes"
          [functionsData]="functionsData"
          [dataLoading]="loadingAsync.products | async"
          [state]="{
            width: (state | async).width.extra,
            height: (state | async).length.extraProduct
          }"
          (callGetInvoiceData)="getInvoiceData()"
          (changeExtra)="handleChangedExtraProduct($event)"
          [isMissingDeromeProductIntegration]="
            isMissingDeromeProductIntegration
          "
        ></project-product>
      </hyperion-card>
    </div>
  </div>
  <div class="p-grid" *ngIf="isAdminUser">
    <div class="p-col-12 p-lg-6 p-xl-4 p-g">
      <hyperion-card hasHeader="true">
        <div class="header">
          <h4>A-conto</h4>
        </div>
        <p>
          Nedan listas det som redan är fakturerat för detta projekt. Detta
          räknas samman och utgör "Summa A-conto" under "Intäkt för projektet".
        </p>
        <aconto-list [projectInfo]="projectInfo"></aconto-list>
      </hyperion-card>
    </div>
    <div class="p-col-12 p-lg-6 p-xl-4 p-g">
      <hyperion-card hasHeader="true">
        <div class="header">
          <h4>Kundens priser</h4>
        </div>
        <p>
          Nedan kan du ange procentpålägg på kostnader för din kund. Ange även
          vad du fakturerar kunden i timmen och vad du fakturerar kunden för
          körda mil.
        </p>
        <div>
          <customer-price
            [projectCostTypesFromParent]="projectCostTypes"
            [projectUserCostTypes]="projectUserCostTypes"
            [projectData]="projectData"
            [projectInvoiceData]="projectInvoiceData | async"
            (callGetInvoiceData)="getInvoiceData()"
          >
          </customer-price>
        </div>
      </hyperion-card>
    </div>

    <div class="p-col-12 p-xl-4 p-g">
      <hyperion-card hasHeader="true">
        <div class="header flex-header">
          <h4>Allt summeras</h4>
          <button
            (click)="toggleShowProjectCosts()"
            *ngIf="isAdminUser"
            type="button"
            class="p-button pull-right"
            style="float: left"
          >
            <span
              ><i
                class="fa"
                [class.fa-plus]="!showProjectCosts"
                [class.fa-minus]="showProjectCosts"
              ></i
              ><span *ngIf="!showProjectCosts"> Visa projektets kostnader</span
              ><span *ngIf="showProjectCosts">
                Dölj projektets kostnader</span
              ></span
            >
          </button>
        </div>
        <p>
          Nedan räknas den återstående summa ut som ska faktureras. Detta
          utifrån "Kundens kostnader" minus "A-conto".
        </p>
        <div class="p-grid project-revenue scrollbox">
          <div class="p-col-7 p-g-nopad summary-entry-header project-labels">
            <span>Intäkt för projekt</span>
          </div>
          <div class="p-col-5 summary-entry"></div>
          <div class="p-col-7 summary-entry">
            <span>Summa A-conto</span>
          </div>
          <div class="p-col-5 summary-entry">
            <span>{{ getSums('aconto') | formatNum }} kr</span>
          </div>
          <div class="p-col-7 summary-entry">
            <span>Summa ber. intäkter</span>
          </div>
          <div class="p-col-5 summary-entry">
            <span>{{ getSums('revenue') | formatNum }} kr</span>
          </div>
          <div class="p-col-7 summary-entry">
            <span>Återstående summa</span>
          </div>
          <div class="p-col-5 summary-entry">
            <span>{{ getSums('remaining') | formatNum }} kr</span>
          </div>
        </div>
      </hyperion-card>
    </div>
  </div>
  <div id="project-costs" *ngIf="showProjectCosts">
    <div class="p-grid">
      <div class="p-col-12">
        <project-cost
          [projectCostTypes]="projectCostTypes"
          [projectUserCostTypes]="projectUserCostTypes"
          [projectInfo]="projectInfo"
          [projectData]="projectData"
          [projectInvoiceData]="projectInvoiceData | async"
          [normalDays]="projectDaysData.normal | async"
          [extraDays]="projectDaysData.extra | async"
          (callGetInvoiceData)="getInvoiceData()"
        ></project-cost>
      </div>
    </div>
  </div>
</div>
