<app-header>
  <app-button
    *ngIf="showCreateButton"
    (onClick)="openCreateInvoice()"
    [type]="'showCreate'"
    [model]="'Invoice'"
  >
  </app-button>
</app-header>

<div class="page-container">
  <div class="p-grid print-no-show p-ai-center">
    <div class="p-md-10 p-g-nopad">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
        <input
          type="text"
          pInputText
          (input)="search.next($event.target.value)"
          placeholder="Sök faktura"
        />
      </div>
    </div>
    <div class="p-inputgroup" class="p-md-2 p-g-nopad align-right">
      <app-button
        [class]="'pull-right'"
        (onClick)="toggleAdvancedSearch()"
        [disabled]="loading"
        [type]="'showExtendSearch'"
      ></app-button>
    </div>
  </div>

  <div class="p-grid" *ngIf="showAdvancedSearch">
    <div class="p-col-12">
      <p-accordion [multiple]="true">
        <p-accordionTab header="Grunduppgifter" [selected]="true">
          <div class="p-grid p-g-nopad">
            <div class="p-col-12 p-md-4">
              <div class="p-col-12">
                <label>Fakturanummer</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ fakturaNr: $event.target.value })"
                />
              </div>

              <div class="p-col-12">
                <label>OCR</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ ocr: $event.target.value })"
                />
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-col-12">
                <label>Kundnummer</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ contactTrueId: $event.target.value })"
                />
              </div>

              <div class="p-col-12">
                <label>Kontaktperson</label>
                <input
                  type="text"
                  pInputText
                  (input)="
                    filters.next({
                      contactOrderBuisnessName: $event.target.value
                    })
                  "
                />
              </div>
            </div>
            <div class="p-col-12 p-md-4">
              <div class="p-col-12">
                <label>Fakturatyp</label>
                <p-dropdown
                  [options]="invoiceTypes"
                  (onChange)="
                    filters.next({
                      typeInvoice: $event.value ? [$event.value] : null
                    })
                  "
                ></p-dropdown>
              </div>
              <div class="p-col-12">
                <label>Betalningsstatus</label>
                <p-dropdown
                  [options]="invoiceStatus"
                  (onChange)="
                    filters.next({
                      status: $event.value
                    })
                  "
                ></p-dropdown>
              </div>
            </div>
          </div>
        </p-accordionTab>
        <p-accordionTab header="Fakturauppgifter" [selected]="true">
          <div class="p-grid">
            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <label>Vår referens</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ our_reference: $event.target.value })"
                />
              </div>
              <div class="p-col-12">
                <label>Er referens</label>
                <input
                  type="text"
                  pInputText
                  (input)="
                    filters.next({ your_reference: $event.target.value })
                  "
                />
              </div>
            </div>

            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <label>Övrigt</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ ovrigt: $event.target.value })"
                />
              </div>
              <div class="p-col-12">
                <label>Ert ordernummer</label>
                <input
                  type="text"
                  pInputText
                  (input)="filters.next({ your_order_nr: $event.target.value })"
                />
              </div>
            </div>

            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <label>Projekt ID</label>
                <input
                  type="text"
                  pInputText
                  (input)="
                    filters.next({ projectsTrueIds: $event.target.value })
                  "
                />
              </div>
            </div>

            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <label>Från fakturadatum</label>
                <p-calendar
                  [ngModel]="null"
                  (ngModelChange)="fdatumFromFilter($event)"
                  [showIcon]="true"
                  [showWeek]="true"
                ></p-calendar>
              </div>

              <div class="p-col-12">
                <label>T.om fakturadatum</label>
                <p-calendar
                  [ngModel]="null"
                  (ngModelChange)="fdatumToFilter($event)"
                  [showIcon]="true"
                  [showWeek]="true"
                ></p-calendar>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" *ngIf="runningType === 'invoice'">
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'all' ? 'underline' : ''
        "
        (click)="fastFilter('all')"
        >ALLA</span
      >
      /
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'payd' ? 'underline' : ''
        "
        (click)="fastFilter('payd')"
        >BETALDA</span
      >
      /
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'unpayd' ? 'underline' : ''
        "
        (click)="fastFilter('unpayd')"
        >OBETALDA</span
      >
      /
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'unpaydpassed' ? 'underline' : ''
        "
        (click)="fastFilter('unpaydpassed')"
        >OBETALDA FÖRFALLNA</span
      >
      /
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'credit' ? 'underline' : ''
        "
        (click)="fastFilter('credit')"
        >KREDIT</span
      >
      /
      <span
        class="link"
        [style.text-decoration]="
          selectedFastFilter === 'taxreductionnot' ? 'underline' : ''
        "
        (click)="fastFilter('taxreductionnot')"
        >SKATTEREDUKTION EJ INKOMMEN</span
      >
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12">
      <p-table
        [lazy]="true"
        (onLazyLoad)="
          runningType === 'supplierInvoice'
            ? getSupplierInvoicesLazy($event)
            : getInvoicesLazy($event)
        "
        [(selection)]="selectedInvoices"
        [rowTrackBy]="helperService.rowTrackBy"
        [value]="invoices"
        dataKey="id"
        [columns]="cols"
        [rowHover]="true"
        [paginator]="true"
        [rowsPerPageOptions]="[50, 100, 250, 500]"
        [rows]="100"
        #dt
        [responsive]="true"
        [totalRecords]="totalInvoices"
        (onSort)="changeSort($event)"
        paginatorPosition="both"
        [loading]="loading"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th class="col-checkbox">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              class="p-text-truncate"
              style="width: {{ col.width }}"
            >
              <span> {{ col.header }}</span>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th
              [style.width]="
                runningType === 'supplierInvoice' ? '6rem' : '11rem'
              "
            ></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoice let-columns="columns">
          <tr class="p-selectable-row" [id]="'invoice' + invoice['fakturaNr']">
            <td>
              <p-tableCheckbox [value]="invoice"></p-tableCheckbox>
            </td>
            <td
              *ngFor="let col of columns"
              class="p-text-truncate"
              style="position: relative; white-space: pre-line"
            >
              <a [routerLink]="'../' + invoice.id" class="stretched-link"></a>
              <span class="p-column-title">{{ col.header }}</span>
              <span
                *ngIf="col.field !== 'totalBox'; else elseBlock"
                pTooltip="{{ invoice[col.field] }}"
                tooltipPosition="left"
                >{{ invoice[col.field] }}</span
              >
              <ng-template #elseBlock>
                <span>{{ invoice[col.field] | formatNum: 0 }} </span>
              </ng-template>
            </td>

            <td class="text-cell-right">
              <i
                *ngIf="invoice.sended"
                pTooltip="Fakturan är skickad"
                tooltipPosition="left"
                class="pi pi-send"
              ></i>

              <i
                *ngIf="invoice.kreditfaktura === 1"
                pTooltip="Kreditfaktura"
                tooltipPosition="left"
                class="pi pi-minus-circle"
              ></i>

              <i
                *ngIf="invoice['status'] === 5"
                pTooltip="Makulerad"
                tooltipPosition="left"
                (click)="showMailModalReminder(invoice['id'])"
                class="pi pi-ban"
              ></i>

              <i
                pTooltip="Hantera betalningar"
                tooltipPosition="left"
                (click)="openPayment(invoice)"
                *ngIf="invoice['status'] !== 5 && runningType === 'invoice'"
                [class]="
                  'pi pi-wallet status-' +
                  invoice['status'] +
                  ' passed-' +
                  invoice['paymentPassed_PreInvoiceTypeHyperion']
                "
              ></i>

              <i
                pTooltip="Hantera skattereduktion"
                tooltipPosition="left"
                (click)="showTaxReductionDialog(invoice['id'])"
                *ngIf="runningType !== 'supplierInvoice'"
                style="display: none"
                [class]="
                  'pi pi-home typeInvoice-' +
                  invoice['typeInvoice'] +
                  ' taxReductionStatus-' +
                  invoice['TaxreductionStatusCalculatedAsString']
                "
              ></i>

              <i
                pTooltip="Skicka påminnelse"
                tooltipPosition="left"
                *ngIf="
                  invoice['paymentPassed_PreInvoiceTypeHyperion'] === 'true' &&
                  runningType !== 'supplierInvoice'
                "
                (click)="showMailModalReminder(invoice['id'])"
                class="pi pi-bell"
              ></i>

              <p-menu
                #menu
                appendTo="body"
                [popup]="true"
                [model]="generateMenu(invoice)"
              ></p-menu>
              <i
                type="button"
                class="pi pi-ellipsis-v"
                (click)="openMenu(menu, $event)"
              ></i>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="paginatorleft" let-state>
          <appForm2-list-dropDown
            style="display: inline-block"
            [(ngModel)]="selectedListOption"
            (ngModelChange)="listOptionsChanged($event)"
            [htmlAttributes]="{ label: { value: 'För markerade...' } }"
            [dropDownParams]="{
              options: updateInvoiceonListOptions,
              placeholder: 'Välj åtgärd…'
            }"
          ></appForm2-list-dropDown>
          <h3 style="display: inline-block" class="p-ml-3">
            Visar {{ dt.rows > invoices.length ? invoices.length : dt.rows }} av
            {{ totalInvoices }}
          </h3>
        </ng-template>
        <ng-template pTemplate="paginatorright" let-state>
          <button
            type="button"
            (click)="changeCols()"
            label="Byt Kolumner"
            icon="pi pi-table"
            style="display: none"
            pButton
          ></button>
          <app-button (onClick)="exportExel()" [type]="'setExcel'"></app-button>
        </ng-template>

        <ng-template pTemplate="summary" let-columns>
          <tr>
            <th></th>
            <th></th>
            <th *ngFor="let col of columns">
              <span *ngIf="col.field === 'totalBox'"> </span>
            </th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="emptyStateTR">
            <td [attr.colspan]="columns.length + 5">
              <app-emptyState [model]="'Invoice'"></app-emptyState>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
