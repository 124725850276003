import { Apollo } from 'apollo-angular';
import {
  Component,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';

import {
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms';
import { GlobalService } from '../../../shared/global/index';
import { HelperService } from '../../../shared/helpers';
import { queries } from 'app/shared/apollo/queries/index';
import {
  CompanyUserCostTypeService,
  CompanyDivisionService,
  CompanyFunctionsService,
} from '../../../shared/company/index';

@Component({
  selector: 'app-user-edit',
  templateUrl: 'edit-user.component.html',
  providers: [FormHandlerService],
})
export class EditUserComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public inModal = false;
  @Input() public userIdInput = null;
  @Output() public userDeleted = new EventEmitter();
  @Output() public userUpdated = new EventEmitter();

  public componentMainForm: FormGroup;
  private dataModel = 'user';
  private dataModelCapitalized: string;
  private formFields = {
    model: 'User',
    attributes: {
      address: null,
      address2: null,
      allowed: null,
      city: null,
      cityCode: null,
      clearingNr: null,
      companyDivisionId: null,
      contoNr: null,
      costTypeId: null,
      email: null,
      firstName: null,
      fixedSalary: null,
      fortnoxTrueId: null,
      id: null,
      lastName: null,
      mobilePhone: null,
      note: null,
      personalNumber: null,
      phone: null,
      relative1name: null,
      relative1other: null,
      relative1phone: null,
      relative2name: null,
      relative2other: null,
      relative2phone: null,
      salary: null,
      skattetabell: null,
      socialFee: null,
      type: null,
      vacationPeriods: null,
      salaryType: null,
    },
  };
  public loading: boolean;
  public salaryTypes = [
    { label: 'Arbetstid', value: 'TID' },
    { label: 'Timlön', value: 'ARB' },
  ];
  public userTypes = [
    { label: 'Medarbetare', value: 1 },
    { label: 'Underentreprenör', value: 4 },
    { label: 'Arbetsledare', value: 2 },
    { label: 'Admin', value: 3 },
  ];
  public socialFees = [
    { label: '31.42', value: 31.42 },
    { label: '19.73', value: 19.73 },
    { label: '10.21', value: 10.21 },
    { label: '0', value: 0 },
  ];
  public skattetabellOptions = [
    { label: '29', value: 29 },
    { label: '30', value: 30 },
    { label: '31', value: 31 },
    { label: '32', value: 32 },
    { label: '33', value: 33 },
    { label: '34', value: 34 },
    { label: '35', value: 35 },
    { label: '36', value: 36 },
    { label: '37', value: 37 },
  ];
  private functionsSub: Subscription;
  public userCostTypes: any[];
  public divisionsOptions: any;
  public showDivisions = false;
  public useFortnox = false;
  public isContractor = false;
  public buttons: any;
  private userId: number;
  private companyDivisionSub: Subscription;
  private companyUserCostTypeSub: Subscription;
  public tooltip =
    '<h4>Admin</h4>' +
    '<p>Admin får samma funktioner som dig, hantera projekt, användare, offerter, fakturor, statistik osv...</p>' +
    '<h4>Arbetsledare</h4>' +
    '<p>Kan tidsrapportera men även hantera Projekt samt Offerter. Har även möjlighet att lägga upp nya produkter och kontakter.</p>' +
    '<h4>Medarbetare</h4>' +
    '<p>En "Medarbetare" kan bla. tidrapportera, hantera bilder, se arbetsmoment och spara produkter till olika projekt.</p>' +
    '<h4>Underentreprenör</h4>' +
    '<p>En "Underentreprenör" har samma rättigheter som en Medarbetare i systemet.</p>';

  constructor(
    private activeRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private companyUserCostTypeService: CompanyUserCostTypeService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyDivisionService: CompanyDivisionService,
    private apolloQueryService: ApolloQueryService,
    private formHandler: FormHandlerService,
    private helperService: HelperService,
    private angularApollo: Apollo
  ) {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
      updateAndSendUserPassword: {
        model: this.dataModel,
      },
    };

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );

    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  public ngOnInit(): void {
    this.getUserCostTypes();

    this.functionsSub = this.companyFunctionsService
      .getCompanyFunctions()
      .subscribe(() => {
        this.setComponentFromCompanyFunctions();
      });

    if (!this.inModal) {
      this.activeRoute.params.subscribe(data => {
        this.getUser(+data['id']);
        this.userId = +data['id'];
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.inModal) {
      for (const propName in changes) {
        if (propName === 'userIdInput') {
          const userId = changes['userIdInput'].currentValue;
          this.getUser(+userId);
          this.userId = +userId;
        }
      }
    }
  }

  public ngOnDestroy(): void {
    this.functionsSub.unsubscribe();
    if (typeof this.companyDivisionSub !== 'undefined') {
      this.companyDivisionSub.unsubscribe();
    }
    if (typeof this.companyUserCostTypeSub !== 'undefined') {
      this.companyUserCostTypeSub.unsubscribe();
    }
  }

  /*
    COMMON FUNCTIONS
  */

  private getUser(idParam: number): void {
    this.loading = true;

    const variables = {
      id: Number(idParam),
    };

    this.apolloQueryService
      .apolloQuery('companyEditUser', variables)
      .subscribe(({ data }) => {
        this.formsInit(data);
      });
  }

  private setContractorBoolean(dataParam: any): void {
    const userData = dataParam['company']['users']['edges'][0]['node'];
    this.isContractor = userData['contractor'] === 1 ? true : false;
  }

  private getUserCostTypes(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUserCostType')
      .subscribe(({ data, sub }) => {
        this.companyUserCostTypeSub = sub;
        this.userCostTypes = this.companyUserCostTypeService.dataFormater(
          data,
          'labels'
        );
      });
  }

  private formsInit(data: any): void {
    Object.keys(this.formFields.attributes).forEach(key => {
      if (
        key === 'vacationPeriods' ||
        key === 'allowed' ||
        key === 'costTypeId' ||
        key === 'socialFee'
      ) {
        this.formFields.attributes[key] =
          +data['company']['users']['edges'][0]['node'][key];
      } else {
        this.formFields.attributes[key] =
          data['company']['users']['edges'][0]['node'][key];
      }
    });
    this.formHandler.groupSetLabelsRules(this.formFields).then(groupedForm => {
      this.componentMainForm = groupedForm;
      this.setContractorBoolean(data);
      this.loading = false;
    });
  }

  public actionUpdate(): void {
    const crudType = 'update';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );
    dataToMutation['id'] = Number(dataToMutation['id']);

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.lockButtons(this.buttons);

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.apolloQueryService.apolloQuery('companyUsers').subscribe();
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
            this.userUpdated.emit();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  public actionUpdateAndSendUserPassword(): void {
    const crudType = 'updateAndSendPasswordFor';
    const dataToMutation = {};
    dataToMutation['id'] = Number(this.componentMainForm.controls['id'].value);

    this.buttons = this.formHandler.lockButtons(this.buttons);

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.mutationService.displayMutationStatus(executedData);
          }

          this.buttons = this.formHandler.unlockButtons(this.buttons);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
          this.buttons = this.formHandler.unlockButtons(this.buttons);
        }
      );
  }

  public actionDelete(): void {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort användaren?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteMutateWithStoreUpdate();
      },
      reject: () => {},
    });
  }

  private deleteMutateWithStoreUpdate(): void {
    const action = 'delete';
    const model = 'user';
    const queryName = 'companyUsers';

    const mutation = this.helperService.getMutationQuery(model, action);

    const dataToMutation = { id: Number(this.userId) };
    this.buttons = this.formHandler.lockButtons(this.buttons, true);
    const apolloVars = {};

    const hyperionMutationType = this.helperService.getMutationType(
      model,
      action
    );
    apolloVars[hyperionMutationType] = {};

    for (const key of Object.keys(dataToMutation)) {
      const keyValue = dataToMutation[key];
      apolloVars[hyperionMutationType][key] = keyValue;
    }

    this.angularApollo
      .mutate({
        mutation,
        variables: apolloVars,
      })
      .subscribe(
        ({ data }) => {
          const keyName = 'userTypeHyperionMutation';
          const mutatedData = data[keyName];
          this.mutationService.displayMutationStatus(mutatedData);
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);

          this.userDeleted.emit();
        },
        onerror => {
          throw onerror;
        }
      );
  }

  private setComponentFromCompanyFunctions(): void {
    this.showDivisions = this.companyFunctionsService.companyFunctionIsSet(
      'useCompanyDivisions'
    );
    if (this.showDivisions) {
      this.getDivisions();
    }

    this.useFortnox = this.companyFunctionsService.companyFunctionIsSet(
      'exportDaysToFortnox'
    );
  }

  private getDivisions(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyDivision')
      .subscribe(({ data, sub }) => {
        this.companyDivisionSub = sub;
        this.divisionsOptions = this.companyDivisionService.dataFormater(
          data,
          'labels'
        );
      });
  }
}
