import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first, delay } from 'rxjs/operators';

import { CompanyAppParamsService } from '../../shared/company';

@Component({
  selector: 'app-home-redirect',
  template: '<spinner></spinner>',
})
export class HomeRedirectComponent implements OnInit {
  constructor(
    private companyAppParamsService: CompanyAppParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.companyAppParamsService.appParams
      .pipe(first(), delay(0))
      .subscribe(({ hyperionHomePage }) => {
        this.router.navigateByUrl(hyperionHomePage, {
          replaceUrl: true,
        });
      });
  }
}
