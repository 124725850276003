import {
  Component,
  forwardRef,
  Injector,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-list-checkbox',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListCheckBox2Component),
      multi: true,
    },
  ],
  template: `
    <div class="checker p-col-12 p-g-nopad">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="value"
        [disabled]="disabled"
        (onChange)="pushChanges($event.checked)"
        [attr.id]="htmlAttributes['input']['id']"
        [attr.form-control-name]="control['name']"
        [label]="htmlAttributes['label']['value'] || control.label"
      ></p-checkbox>
      <div *ngIf="control.showErrors" class="vendium-tool-tip">
        <p
          *ngFor="let error of control.mergedErrors"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class ListCheckBox2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  value: boolean;
  onChange: (_: any) => void;
  onTouched: () => void;
  disabled: boolean;
  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? !!value : null;
  }
  pushChanges(checked: boolean) {
    this.onChange(+checked);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
