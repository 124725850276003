import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormHandlerService } from '../../../../shared/forms/index';
import { CompanyInvoiceService } from '../../services/company-invoice.service';

@Component({
  selector: 'app-invoice-head-shippingadress',
  templateUrl: './invoice-head-shippingadress.component.html',
  styleUrls: [
    './invoice-head-shippingadress.component.scss',
    '../invoice-head.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InvoiceHeadShippingadressComponent,
      multi: true,
    },
    FormHandlerService,
  ],
})
export class InvoiceHeadShippingadressComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  formFields = {
    model: 'Invoice',
    nestedForm: false,
    attributes: {
      companyName: '',
      companyAdress: '',
      companyAddressRow2: '',
      companyWebb: '',
      companyTlfn: '',
      companyFax: '',
      companyMail: '',
      companyBankGiro: '',
      companyPostGiro: '',
      companyIban: '',
      companyBicSwift: '',
      companyOrgNr: '',
    },
  };

  @Input() shippingAdress;
  headCompanyForm: FormGroup;
  FormSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private companyInvoiceService: CompanyInvoiceService,
    private cdr: ChangeDetectorRef,
    private formhandler: FormHandlerService
  ) {
    this.headCompanyForm = this.formhandler.groupedFormSimple(this.formFields);
    this.formhandler
      .groupSetLabelsRules(this.formFields, this.headCompanyForm)
      .then(() => {
        this.cdr.markForCheck();
      });
    this.companyInvoiceService.updateForm('shipping', this.headCompanyForm);
  }

  ngOnInit() {
    this.onChange(this.headCompanyForm.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.shippingAdress && changes.shippingadress.currentValue != null) {
      this.headCompanyForm = this.fb.group(changes.shippingadress);
      this.cdr.markForCheck();
    }
  }

  writeValue = (val: any[]) => this.headCompanyForm.patchValue(val);

  onChange = (_: any) => {};

  registerOnChange(fn) {
    this.headCompanyForm.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.headCompanyForm.disable() : this.headCompanyForm.enable();
  }

  registerOnTouched() {}
}
