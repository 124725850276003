import { gql, Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

import {
  emailValidator,
  minLengthValidator,
  orgOrPersonalNumberValidator,
  phoneValidator,
  requiredTrueValidator,
  requiredValidator,
} from 'app/shared/forms/validators';

const COMPANY_AND_USER_INFO_QUERY = gql`
  query CompanyAndUserInfo {
    company {
      id
      name
      orgNr
    }

    me {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

@Component({
  selector: 'app-home-recommend',
  templateUrl: './home-recommend.component.html',
  styleUrls: ['./home-recommend.component.scss'],
})
export class HomeRecommendComponent implements OnInit, OnDestroy {
  public signupForm = this.formBuilder.group({
    companyOrgNo: ['', orgOrPersonalNumberValidator()],
    companyName: ['', [minLengthValidator(2)]],
    name: ['', [minLengthValidator(2)]],
    phone: ['', [phoneValidator(), requiredValidator()]],
    email: ['', [emailValidator()]],
    prospectCompanyName: ['', [minLengthValidator(2), requiredValidator()]],
    prospectName: ['', [minLengthValidator(2), requiredValidator()]],
    prospectPhone: ['', [phoneValidator(), requiredValidator()]],
    acceptOne: [false, [requiredTrueValidator()]],
    acceptTwo: [false, [requiredTrueValidator()]],
  });

  public companyName: string;
  public showThankYou = false;
  public popupTermsDetailsVisible = false;
  public loading = false;
  public appName = localStorage.getItem('appname');
  public appLogoPath = localStorage.getItem('appappLogoPath');

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.prefillFormWithCompanyInfo();
  }

  public ngOnDestroy(): void {
    this.showThankYou = false;
    this.popupTermsDetailsVisible = false;
  }

  private prefillFormWithCompanyInfo(): void {
    this.getCompanyInfo().subscribe(({ data }: any) => {
      this.signupForm.patchValue({
        companyOrgNo: data.company.orgNr,
        companyName: data.company.name,
        name: `${data.me.firstName} ${data.me.lastName}`,
        phone: data.me.phone,
        email: data.me.email,
      });

      this.companyName = data.company.name;
    });
  }

  private getCompanyInfo(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: COMPANY_AND_USER_INFO_QUERY,
    });
  }

  public showTermsDetails(): void {
    this.popupTermsDetailsVisible = true;
  }

  public submitForm(): void {
    this.signupForm.markAllAsTouched();
    if (!this.signupForm.valid) {
      return;
    }
    const {
      name,
      companyName,
      email,
      phone,
      prospectCompanyName,
      prospectName,
      prospectPhone,
    } = this.signupForm.value;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('company', companyName);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('recommended_company_name', prospectCompanyName);
    formData.append('recommended_contact_name', prospectName);
    formData.append('recommended_contact_phone', prospectPhone);

    this.loading = true;

    this.http
      .post('/website/recommend', formData)
      .pipe(first())
      .subscribe((data: any) => {
        this.loading = false;

        if (data.success) {
          this.showThankYou = true;
        }
      });
  }
}
