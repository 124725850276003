import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { urlPrefix } from './document.service';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentLogoService {
  private logoUrl: Subject<string>;

  constructor(private http: HttpClient) {
    this.logoUrl = new BehaviorSubject(this.getLogoBaseUrl());
  }

  private getLogoBaseUrl(): string {
    return `${urlPrefix}/company/getLogo`;
  }
  private uploadLogoUrl(): string {
    return `${urlPrefix}/hantverksDoku/uploadLogo`;
  }

  getLogoUrl(): Observable<string> {
    return this.logoUrl;
  }

  uploadLogo(blob: Blob): Observable<void> {
    const data = new FormData();
    data.append('PictureForm[image]', blob);
    return this.http.post<any>(this.uploadLogoUrl(), data).pipe(
      map(response => {
        if (response.status === 'success') {
          return response;
        } else {
          throw new Error(`Failed to upload logo: ${response.msg}`);
        }
      }),
      map(() => {
        this.logoUrl.next(
          `${this.getLogoBaseUrl()}?cacheBuster=${Math.random()}`
        );
      })
    );
  }
}
