import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { DocuPreviewComponent } from './docu-preview/docu-preview.component';
import { DocumentService } from './document.service';
import { DocuHeaderFoldersComponent } from './docu-header-folders/docu-header-folders.component';
import { DocuEditComponent } from './docu-edit/docu-edit.component';
import { DocuRowComponent } from './docu-row/docu-row.component';
import { DocuColumnComponent } from './docu-column/docu-column.component';
import { DocuFieldComponent } from './docu-field/docu-field.component';
import { DocuColumnsComponent } from './docu-columns/docu-columns.component';
import { DocuSaveDialogComponent } from './docu-save-dialog/docu-save-dialog.component';
import { LoadingModule } from '../shared/loading/loading.module';
import { DocuSidebarComponent } from './docu-sidebar/docu-sidebar.component';
import { DocuMyDocumentsComponent } from './docu-my-documents/docu-my-documents.component';
import { DocuTemplateFolderComponent } from './docu-template-folder/docu-template-folder.component';
import { SpacerComponent } from './spacer/spacer.component';
import { DocuTemplateFoldersComponent } from './docu-template-folders/docu-template-folders.component';
import { DocuDirtyService } from './docu-dirty.service';
import { DocuDirtyConfirmDiscardDialogComponent } from './docu-dirty-confirm-discard-dialog/docu-dirty-confirm-discard-dialog.component';
import { DocuRenameableComponent } from './docu-renameable/docu-renameable.component';
import { DocuUpsellSavesDialogComponent } from './docu-upsell-saves-dialog/docu-upsell-saves-dialog.component';
import { DocuUpgradeBannerComponent } from './docu-upgrade-banner/docu-upgrade-banner.component';
import { DocuLogoComponent } from './docu-logo/docu-logo.component';
import { DocuFolderLinkComponent } from './docu-folder-link/docu-folder-link.component';
import { DocuFolderListComponent } from './docu-folder-list/docu-folder-list.component';
import { DocuSubscribeDialogComponent } from './docu-subscribe-dialog/docu-subscribe-dialog.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { DocumentLogoService } from './document-logo.service';
import { DocuUpsellExportsDialogComponent } from './docu-upsell-exports-dialog/docu-upsell-exports-dialog.component';
import { DocuSignupDialogComponent } from './docu-signup-dialog/docu-signup-dialog.component';
import { HyperionSharedModule } from '../shared/shared.module';
import { DocuUpgradeToProjectManagerDialogComponent } from './docu-upgrade-to-project-manager-dialog/docu-upgrade-to-project-manager-dialog.component';
import { DocuTemplateFolderHeroComponent } from './docu-template-folder-hero/docu-template-folder-hero.component';
import { DocuSignupComponent } from './docu-signup/docu-signup.component';
import { DocuSignupPageComponent } from './docu-signup-page/docu-signup-page.component';
import { DocuUpsellDialogComponent } from './docu-upsell-dialog/docu-upsell-dialog.component';
import { DocuUpsellLogoDialogComponent } from './docu-upsell-logo-dialog/docu-upsell-logo-dialog.component';
import { SharedDialogModule } from '../shared/dialogs/shared.dialog.module';
import { AddProjectComponent } from './add-project/add-project.component';
import { HeaderModule } from 'app/header/header.module';
import { CardModule } from 'primeng/card';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SidebarModule } from 'primeng/sidebar';
import { ProjectModule } from 'app/project/project.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InputTextModule,
    InputTextareaModule,
    CheckboxModule,
    TableModule,
    EditorModule,
    DragulaModule.forRoot(),
    LoadingModule,
    PipesModule,
    HyperionSharedModule,
    SharedDialogModule,
    HeaderModule,
    CardModule,
    SplitButtonModule,
    SidebarModule,
    ProjectModule,
  ],
  declarations: [
    DocuColumnComponent,
    DocuColumnsComponent,
    DocuDirtyConfirmDiscardDialogComponent,
    DocuEditComponent,
    DocuFieldComponent,
    DocuFolderLinkComponent,
    DocuFolderListComponent,
    DocuHeaderFoldersComponent,
    DocuLogoComponent,
    DocuMyDocumentsComponent,
    DocuPreviewComponent,
    DocuRenameableComponent,
    DocuRowComponent,
    DocuSaveDialogComponent,
    DocuSidebarComponent,
    DocuSignupDialogComponent,
    DocuSubscribeDialogComponent,
    DocuTemplateFoldersComponent,
    DocuTemplateFolderComponent,
    DocuUpgradeBannerComponent,
    DocuUpsellSavesDialogComponent,
    DocuUpsellExportsDialogComponent,
    SpacerComponent,
    DocuUpgradeToProjectManagerDialogComponent,
    DocuTemplateFolderHeroComponent,
    DocuSignupComponent,
    DocuSignupPageComponent,
    DocuUpsellDialogComponent,
    DocuUpsellLogoDialogComponent,
    AddProjectComponent,
  ],
  exports: [DocuEditComponent],
  providers: [DocumentService, DocuDirtyService, DocumentLogoService],
})
export class DocuModule {}
