<td [formGroup]="componentMainForm">
  <p-dropdown
    formControlName="type"
    [options]="topicDropdown"
    filter="true"
    appendTo="body"
  ></p-dropdown>
</td>

<td pResizableColumn [formGroup]="componentMainForm">
  <textarea
    pInputTextarea
    formControlName="descriptionOffer"
    (focus)="changeBigColumn.emit('descriptionOffer')"
  ></textarea>
</td>

<td pResizableColumn [formGroup]="componentMainForm">
  <textarea
    pInputTextarea
    formControlName="description"
    (focus)="changeBigColumn.emit('description')"
  ></textarea>
</td>

<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="quantity" />
</td>

<td [formGroup]="componentMainForm">
  <p-dropdown
    formControlName="unit"
    [options]="units"
    appendTo="body"
  ></p-dropdown>
</td>

<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="unitPrice" />
</td>

<td [formGroup]="componentMainForm">
  <p-dropdown
    formControlName="tax"
    [options]="taxDropdown"
    appendTo="body"
  ></p-dropdown>
</td>

<td [formGroup]="componentMainForm" *ngIf="isGreenTaxReduction">
  <p-dropdown
    formControlName="greenTaxReduction"
    [options]="greenTaxDropdownOptions"
    appendTo="body"
  >
    <ng-template pTemplate="selectedItem" let-item>
      <i [ngClass]="item.icon" class="p-mr-2"></i>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <div class="p-d-flex p-ai-center">
        <i [ngClass]="item.icon" class="p-mr-2"></i>
        <span>{{ item.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>
</td>

<td [formGroup]="componentMainForm" *ngIf="!isGreenTaxReduction">
  <div
    class="form-group"
    [class.has-danger]="componentMainForm.controls['rot']['showErrors']"
  >
    <p-checkbox
      [(ngModel)]="rotBool"
      [binary]="true"
      [ngModelOptions]="{ standalone: true }"
      (onChange)="setRotBool($event)"
    ></p-checkbox>
    <div *ngIf="componentMainForm.controls['rot']['showErrors']">
      <p
        *ngFor="let error of componentMainForm.controls['rot']['mergedErrors']"
        class="form-control-feedback"
      >
        <span><i class="fa fa-times"></i> </span
        ><span [innerHTML]="error"></span>
      </p>
    </div>
  </div>
</td>

<td [formGroup]="componentMainForm">
  <div
    class="form-group"
    [class.has-danger]="componentMainForm.controls['cost']['showErrors']"
  >
    <input
      type="hidden"
      formControlName="cost"
      class="form-control"
      [placeholder]="componentMainForm.controls['cost']['label']"
      [class.form-control-danger]="
        componentMainForm.controls['cost']['showErrors']
      "
      (input)="keyUp.next($event)"
      pInputText
    />
    <input
      disabled="disabled"
      class="form-control"
      value="{{ dataObjectFromParent['cost'] | formatNum }}"
    />
    <div *ngIf="componentMainForm.controls['cost']['showErrors']">
      <p
        *ngFor="let error of componentMainForm.controls['cost']['mergedErrors']"
        class="form-control-feedback"
      >
        <span><i class="fa fa-times"></i> </span
        ><span [innerHTML]="error"></span>
      </p>
    </div>
  </div>
</td>

<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="orderNr" />
</td>

<td>
  <i (click)="handleDelete()" [class]="buttons.delete.icon | async"></i>
</td>
