<p-autoComplete
  #ac
  forceSelection="true"
  placeholder="Sök / Lägg till underleverantör"
  [suggestions]="results"
  (completeMethod)="search($event)"
  (onSelect)="select($event, ac)"
  [dropdown]="true"
  field="id"
>
  <ng-template pTemplate="header">
    <span class="p-grid p-p-1 p-m-0">
      <span class="p-col-6 p-text-bold"> Företagsnamn </span>
      <span class="p-col-6 p-text-bold"> OrgNr </span>
    </span>
  </ng-template>
  <ng-template pTemplate="item" let-item>
    <span class="p-grid p-p-1 p-m-0">
      <span class="p-col-6">
        {{ item.name }}
      </span>
      <span class="p-col-6">
        {{ item.orgNr }}
      </span>
    </span>
  </ng-template>
</p-autoComplete>
