import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, first } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HelperService } from 'app/shared/helpers/index';
import { GlobalService } from 'app/shared/global/index';
import { CompanyAllProjectsService } from 'app/shared/company/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { HtmlModalService } from 'app/shared/html-modal';
import { CompanyCostTypeService } from 'app/shared/company/index';

import { FetchProjectGQL } from '../project-info/graphql/project.generated';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';

@Component({
  selector: 'project-supplier-invoice',
  templateUrl: 'project-supplier-invoice.component.html',
  styleUrls: ['project-supplier-invoice.component.scss'],
})
export class ProjectSupplierInvoiceLegacyComponent
  implements OnDestroy, OnInit
{
  @Input() public functionsData: any = { companyCostTypes: [] };

  public dataSet = [];
  public relationshipsLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    true
  );
  public autoCompleteResults = [];
  public allProjectsLabels = [];
  public supplierInvoiceSub: Subscription;
  public allProjectsSub: Subscription;

  public projectId: number;

  constructor(
    private route: ActivatedRoute,
    public helperService: HelperService,
    private globalService: GlobalService,
    private companyAllProjectsService: CompanyAllProjectsService,
    private htmlModalService: HtmlModalService,
    private apolloQueryService: ApolloQueryService,
    private companyCostTypeService: CompanyCostTypeService,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;

      this.apolloQueryService
        .apolloWatchQueryTwo('companyCostType', null, 'cache-and-network')
        .subscribe(data => {
          this.functionsData['companyCostTypes'] =
            this.companyCostTypeService.dataFormater(data.data, 'labels');

          this.getSupplierInvoices();
          this.getAllProjects();
        });
    });
  }

  public getSupplierInvoices(): void {
    const variables = {
      id: +this.projectId,
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectSupplierInvoices', variables)
      .subscribe(data => {
        this.projectCountService.count(this.projectId);
        this.supplierInvoiceSub = data.sub;
        this.dataSet = this.helperService.cleanFromNode(
          data.data['project'][
            'supplierInvociesBySource_PreProjectTypeHyperion'
          ]
        );
        this.relationshipsLoading.next(false);
      });
  }

  public rowTrackBy(index: number, row: any): void {
    return row.name;
  }

  public getAllProjects(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAllProjects', null, 'cache-and-network')
      .subscribe(data => {
        this.allProjectsSub = data.sub;
        this.allProjectsLabels = this.companyAllProjectsService.dataFormater(
          data.data,
          'labels'
        );
      });
  }

  public ngOnDestroy(): void {
    if (typeof this.supplierInvoiceSub !== 'undefined') {
      this.supplierInvoiceSub.unsubscribe();
    }
    if (typeof this.allProjectsSub !== 'undefined') {
      this.allProjectsSub.unsubscribe();
    }
  }

  public viewAllInvoicesPDF(): void {
    const urlParam =
      this.globalService.getUrlPrefix() +
      '/project/downloadSupplierInvoices?projectId=' +
      this.projectId;
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }
}
