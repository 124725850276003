import { Component, OnInit, Input } from '@angular/core';

import { FormHandlerService } from 'app/shared/forms';
import { FormGroup } from '@angular/forms';
import { ApolloMutationService } from 'app/shared';

@Component({
  selector: '[app-project-child-row]',
  templateUrl: './project-child-row.component.html',
  styleUrls: ['./project-child-row.component.scss'],
})
export class ProjectChildRowComponent implements OnInit {
  @Input() data;
  @Input() parentId;
  @Input() tableBtns;
  @Input() dropDownOptions;

  dataModelCapitalized = 'Day';
  formFields = {
    model: 'Day',
    attributes: {
      hoursToInvoice: null,
      doneWork: null,
      mileToInvoice: null,
      userId: null,
      newCostTypeId: null,
      todoId: null,
      extra: null,
    },
  };
  componentMainForm: FormGroup;

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService
  ) {}

  ngOnInit(): void {
    this.appendDatatToFormAttr();
    this.formInit();
  }

  formInit() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler.groupSetLabelsRules(
      this.formFields,
      this.componentMainForm
    );
    console.log(this.componentMainForm);
  }

  appendDatatToFormAttr() {
    Object.keys(this.formFields['attributes']).forEach(key => {
      this.formFields['attributes'][key] = this.data[key];
    });
  }

  markRowAsSaved(): void {
    const formValue = this.componentMainForm.value;
    const dataToMutation = {
      projectId: this.parentId,
      mile: this.data['mile'],
      date: this.data['date'],
      extra: formValue['extra'],
      todoId: formValue['todoId'],
      userId: formValue['userId'],
      doneWork: formValue['doneWork'],
      subcontractorDayParentId: this.data['id'],
      hoursToInvoice: formValue['hoursToInvoice'],
    };
    this.tableBtns = {
      ...this.tableBtns,
      ...this.setTableBtns(dataToMutation['subcontractorDayParentId'], true),
    };
    this.actionCreate(dataToMutation);
  }

  actionCreate(dataToMutation) {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'day',
          'create',
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: 'create' + this.dataModelCapitalized,
              },
            ]);

            if (!executedData.mutationSucceededAllArguments) {
              this.tableBtns = {
                ...this.tableBtns,
                ...this.setTableBtns(
                  dataToMutation['subcontractorDayParentId'],
                  false
                ),
              };
            }

            executeMutationSub.unsubscribe();
          },
          err => {
            this.tableBtns = {
              ...this.tableBtns,
              ...this.setTableBtns(
                dataToMutation['subcontractorDayParentId'],
                false
              ),
            };
          }
        );
    }
  }

  setTableBtns = (key: string, value: boolean): any => ({
    ['row' + key]: value,
  });
}
