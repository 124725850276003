<div class="subcontractor-drag">
  <hyperion-card>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="contractor-header">
          <h4>
            {{ subcontractorData['subcontractor']['name'] }}
            <span>{{ subcontractorData['subcontractor']['orgNr'] }}</span>
            <span class="italic small-text"
              >&nbsp; UE till: {{ parentData['name'] }}</span
            >
            <span *ngIf="subcontractorData.haveErrors">
              <i
                class="fa fa-exclamation-triangle fa-lg warning"
                pTooltip="Innehåller fel"
              ></i
            ></span>
          </h4>
          <div class="contractor-actions">
            <app-button
              (onClick)="deleteRelationProjectSubcontractor()"
              [type]="'delete'"
              [model]="'Company'"
            ></app-button>

            <app-button
              class="ml5"
              *ngIf="showAttendanceReports"
              (onClick)="toggleShowAttendanceReports()"
              [type]="'hide'"
              [model]="'Day'"
            ></app-button>
            <app-button
              class="ml5"
              *ngIf="!showAttendanceReports"
              (onClick)="toggleShowAttendanceReports()"
              [type]="'show'"
              [model]="'Day'"
            ></app-button>
          </div>
        </div>
      </div>
      <div class="p-col-12" *ngIf="showAttendanceReports">
        <attendance-reports-list
          [projectData]="subcontractorData['subcontractor']"
          [attendanceReportsInfo]="attendanceReportsInfo"
          (refetchAttendanceReports)="refetchAttendanceReports.emit()"
        ></attendance-reports-list>
      </div>
    </div>
  </hyperion-card>
</div>
<div
  class="hyperion-dnd-container"
  [class.empty-container]="children.length === 0"
>
  <div *ngFor="let subcontractorData of children">
    <div>
      <subcontractor-drag
        [subcontractorData]="subcontractorData"
        [parentData]="subcontractorData['subcontractor']"
        [projectId]="projectId"
        (subcontractorWithChildren)="emitSubcontractorWithChildren()"
        (refetchAttendanceReports)="refetchAttendanceReports.emit()"
        (subcontractorDeleted)="subcontractorDeleted.emit($event)"
      >
      </subcontractor-drag>
    </div>
  </div>
</div>
