<div class="image-gallery">
  <p-dataView
    [value]="images"
    layout="grid"
    [paginator]="true"
    paginatorPosition="top"
    [rows]="24"
    [totalRecords]="totalImages"
    [loading]="isLoading"
    [lazy]="lazy"
    (onLazyLoad)="loadImages($event)"
  >
    <ng-template let-image pTemplate="gridItem">
      <div class="p-col-12 p-md-4 p-xl-2 image-gallery__image-wrapper">
        <p-panel class="image-gallery__image-panel">
          <p-header
            *ngIf="!readonly"
            class="image-gallery__image-panel__header"
          >
            <p class="image-gallery__image-panel__header__comment">
              {{ image.comment }}
            </p>
            <app-button
              (click)="imageDeleteEmit(image.id)"
              [type]="'deleteNoText'"
            ></app-button>
          </p-header>
          <spinner
            *ngIf="!allThumbnailsPrefetched"
            class="image-gallery__image-panel__image-placeholder"
          ></spinner>
          <img
            *ngIf="allThumbnailsPrefetched"
            [src]="image.thumbnail"
            [alt]="image.comment"
            class="image-gallery__image-panel__image"
            (click)="openFullscreen(image)"
          />
          <p-footer>
            <div class="image-gallery__date-box">
              Uppladdad: {{ image.created }}
            </div>

            <div class="image-gallery__image-panel__footer" *ngIf="!readonly">
              <div>
                <p-checkbox
                  [(ngModel)]="image.displayOnPrint"
                  [binary]="true"
                  (onChange)="updateDisplayOnPrint($event, image)"
                ></p-checkbox>
                Visa vid PDF
              </div>
              <div
                class="image-gallery__rotate-box"
                *ngIf="!imageIsRotating(image.id)"
              >
                <span
                  class="hover-pointer"
                  pTooltip="Rotera vänster"
                  tooltipPosition="top"
                  (click)="rotateImage(image, 'left')"
                  ><i class="fa fa-undo"></i
                ></span>
                <span
                  class="hover-pointer"
                  pTooltip="Rotera höger"
                  tooltipPosition="top"
                  (click)="rotateImage(image, 'right')"
                  ><i class="fa fa-repeat"></i
                ></span>
              </div>
              <div
                class="image-gallery__rotate-box"
                *ngIf="imageIsRotating(image.id)"
              >
                <span><i class="fa fa-hourglass-half fa-spin"></i></span>
              </div>
            </div>
            <div class="image-gallery__image-panel__footer" *ngIf="readonly">
              {{ image.comment }}
            </div>
          </p-footer>
        </p-panel>
      </div>
    </ng-template>
    <p-footer></p-footer>
  </p-dataView>
  <p-galleria
    [(value)]="images"
    [(visible)]="fullScreenOpen"
    [(activeIndex)]="selectedIndex"
    [fullScreen]="true"
    [showThumbnails]="false"
    [showItemNavigators]="true"
    (activeIndexChange)="onActiveIndexChanged($event)"
  >
    <ng-template pTemplate="item" let-item>
      <img
        *ngIf="!item.largeVersionLoaded"
        [src]="item.thumbnail"
        class="image-gallery__image--full-screen"
        [width]="item.width"
        [height]="item.height"
      />
      <img
        *ngIf="item.largeVersionLoaded"
        [src]="item.largerImage"
        class="image-gallery__image--full-screen"
      />
    </ng-template>
    <ng-template pTemplate="caption" let-item>
      <div class="image-gallery__image--full-screen__command-wrapper">
        <div class="image-gallery__date-box">Uppladdad: {{ item.created }}</div>

        <div
          class="image-gallery__image--full-screen__command-content"
          *ngIf="!commentEditable"
        >
          <span *ngIf="item.comment">{{ item.comment }}</span>

          <span style="font-style: italic" *ngIf="!item.comment && readonly"
            >Ingen kommentar.</span
          >
          <span style="font-style: italic" *ngIf="!item.comment && !readonly"
            >Klicka på ikonen för att kommentera bilden.</span
          >
          <a
            class="fa fa-edit image-gallery__image--full-screen__command-link"
            (click)="commentEditable = true"
            style="cursor: pointer; margin: 0 1rem"
            *ngIf="!readonly"
          ></a>
        </div>
        <div
          class="image-gallery__image--full-screen__command-content"
          *ngIf="commentEditable"
        >
          <textarea
            pInputTextarea
            class="p-inputtext"
            rows="2"
            [(ngModel)]="item.comment"
            placeholder="Kommentera bild..."
            style="width: 100%"
          ></textarea>
          <div
            class="image-gallery__image--full-screen__comment-commands"
            style="font-size: 20px; display: flex; justify-content: center"
          >
            <app-button
              (click)="
                imageUpdateEmit(item.id, item.comment, item.displayOnPrint)
              "
              [type]="'update'"
              style="margin-right: 1rem"
            ></app-button>
            <a
              (click)="commentEditable = false"
              class="fa fa-close image-gallery__image--full-screen__command-link"
            ></a>
          </div>
        </div>
      </div>
    </ng-template>
  </p-galleria>
</div>
