import {
  Component,
  OnDestroy,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChildren,
  ViewChild,
  QueryList,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';

import { HttpService } from 'app/shared/http';
import { GlobalService } from 'app/shared/global';
import { HelperService } from 'app/shared/helpers';
import { CompanyUsersService } from 'app/shared/user';
import { FormHandlerService } from 'app/shared/forms';
import { HtmlModalService } from 'app/shared/html-modal';
import { ProjectTodosService, TodoTopicsService } from 'app/shared/company';
import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';

import { ProjectTodoRowComponent } from './project-todo-row.component';
import * as moment from 'moment';

@Component({
  selector: 'project-todo',
  templateUrl: 'project-todo.component.html',
  styleUrls: ['./project-todo.component.scss'],
  providers: [ProjectTodosService, FormHandlerService],
})
export class ProjectTodoComponent implements OnDestroy, OnInit {
  @Input() projectInfo;
  @Input() projectData;
  @Input() meUser;
  @Output() todoCrudAction = new EventEmitter();
  @ViewChildren(ProjectTodoRowComponent)
  projectRowComponents: QueryList<ProjectTodoRowComponent>;
  @ViewChild(AutoComplete)
  autoCompleteComponent: AutoComplete;

  buttons;
  topicButtons;
  dataModel = 'todo';
  dataModelCapitalized;
  componentMainForm: FormGroup;
  todoTopicForm: FormGroup;
  setFields = {
    componentMainForm: {
      model: 'Todo',
      nestedForm: false,
      attributes: {
        description: '',
        startDate: null,
        endDate: null,
        estimatedTime: '',
        type: null,
      },
    },
    todoTopicForm: {
      model: 'Todotopic',
      nestedForm: false,
      attributes: {
        Name: '',
      },
    },
  };

  public usersDropdownSplitBtn: {
    label: string;
    command: () => void;
  }[];

  usersSub: Subscription;
  createTopicModel;
  todoTopicsDropdown;
  altTodoTopicsDropdown;
  eraseTopicModel;
  loading = new BehaviorSubject(true);
  chartsLoading = new BehaviorSubject(true);
  dataSet = {
    todos: [],
    todoTopics: [],
    todoTopicsWithChilds: [],
  };
  usersDropdown = [];
  subcontractorsDropdown = [];
  reset = false;
  results: BehaviorSubject<any> = new BehaviorSubject([]);
  todoAutoModel: string;
  charts = {
    pie: {
      chart: {
        type: 'pie',
        backgroundColor: 'transparent',
      },
      title: {
        text: 'Timmar efter arbetsmoment',
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            this.point.name +
            '</b>: ' +
            Math.round(this.percentage) +
            ' %, ' +
            Math.round(this.y) +
            'h'
          );
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            color: '#000000',
            connectorColor: '#000000',
            formatter: function () {
              return (
                '<b>' +
                this.point.name +
                '</b>:' +
                Math.round(this.percentage) +
                ' %'
              );
            },
          },
        },
      },
      series: [
        {
          data: [],
        },
      ],
    },

    bars: {
      chart: {
        type: 'bar',
        height: 600,
        backgroundColor: 'transparent',
        zoomType: 'y',
      },
      title: {
        text: 'Timmar per arbetsmoment fördelat på medarbetare samt estimerad tid',
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          min: 0,
          text: 'Medarbetare',
        },
      },
      plotOptions: {
        series: {
          stacking: 'normal',
        },
      },
      series: [],
    },
  };
  totals = { estimatedTime: 0, totalHours: 0 };
  projectDays = [];
  singleProjectTodoDaysSub: Subscription;
  topicsSub: Subscription;
  todosSub: Subscription;

  interrupt = false;

  private readonly sevenWorkingDaysAsHours: number = 56;
  private addSevenDays = (date: Date): string =>
    moment(date).add(7, 'days').format('YYYY-MM-DD');

  constructor(
    private apolloQueryService: ApolloQueryService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private htmlModalService: HtmlModalService,
    private httpService: HttpService,
    private mailDialogService: MailDialogService,
    private mutationService: ApolloMutationService,
    private projectTodosService: ProjectTodosService,
    private todoTopicsService: TodoTopicsService,
    private userService: CompanyUsersService,
    public helperService: HelperService
  ) {}

  ngOnInit() {
    this.getUsers();
    this.projectSubcontractorsRelations();

    if (this.meUser['type'] === '3' || this.meUser['type'] === '2') {
      this.getChartsData(this.projectInfo['id']);
    }

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    const topicButtonObject = {
      create: {
        model: 'todotopic',
      },
      delete: {
        model: 'todotopic',
      },
    };

    this.initForm();

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.topicButtons = this.formHandler.getButtonValues(topicButtonObject);

    this.subscribeToDays();
  }

  projectSubcontractorsRelations() {
    const variables = {
      multiId: [this.projectInfo.id],
    };
    this.subcontractorsDropdown = [];

    this.apolloQueryService
      .apolloQuery('projectSubcontractorsRelations', variables)
      .subscribe(({ data }) => {
        const subs = [];

        this.apolloQueryService
          .cleanFromNode(data.company.projects)
          .map(project => {
            this.apolloQueryService
              .cleanFromNode(project['subcontractorsRelations'])
              .map(rel => {
                if (
                  +rel['copied'] === 1 &&
                  +rel['mainContractorWhantsToPutTodos'] === 1 &&
                  +rel['clientAcceptTodos'] === 1
                ) {
                  // and other rights
                  subs.push({
                    label: rel.subcontractor.name,
                    value: rel.id,
                  });
                }
              });
          });
        if (subs.length > 0) {
          subs.push({ label: 'Dela med underentreprenör', value: null });
        }
        this.subcontractorsDropdown = subs;
      });
  }

  subscribeToDays() {
    const variables = {
      projectId: +this.projectInfo['id'],
    };
    this.apolloQueryService
      .apolloWatchQueryTwo('singleProjectTodoDays', variables)
      .subscribe(data => {
        this.singleProjectTodoDaysSub = data.sub;
        this.projectDays = this.helperService.cleanFromNode(
          data.data['project']['days']
        );
        this.getTodoTopics();
      });
  }

  ngOnDestroy() {
    this.singleProjectTodoDaysSub &&
      this.singleProjectTodoDaysSub.unsubscribe();
    this.topicsSub && this.topicsSub.unsubscribe();
    this.todosSub && this.todosSub.unsubscribe();
    this.usersSub && this.usersSub.unsubscribe();
  }

  initForm() {
    Promise.all([
      this.formHandler.groupSetLabelsRules(this.setFields['componentMainForm']),
      this.formHandler.groupSetLabelsRules(this.setFields['todoTopicForm']),
    ]).then(([mainForm, todoTopic]) => {
      mainForm.controls['estimatedTime']['label'] =
        'Estimerad tid <small>timmar</small>';
      mainForm.controls['startDate']['label'] = 'Startdatum';
      mainForm.controls['endDate']['label'] = 'Slutdatum';
      mainForm.controls['description']['label'] = 'Arbetsmoment';
      mainForm.controls['type']['label'] = 'Ange rubrik *';

      todoTopic.controls['Name']['label'] = 'Ange ny rubrik';

      this.componentMainForm = mainForm;
      this.componentMainForm.patchValue({
        startDate: this.projectData.startDate,
        endDate: this.addSevenDays(this.projectData.startDate),
        estimatedTime: this.sevenWorkingDaysAsHours,
      });
      this.todoTopicForm = todoTopic;
    });
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    // add projectId to mutation
    dataToMutation['projectId'] = this.projectInfo['id'];
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const returnAttributes = this.getReturnAttributes();

      const refetchVars = { id: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectTodos', variables: refetchVars },
        { name: 'singleProjectTotalCounts', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.componentMainForm.controls['description'].setValue('');
              this.componentMainForm.controls['estimatedTime'].setValue('');
            } else {
              this.loading.next(false);
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  createTopic() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.todoTopicForm
    );

    dataToMutation['active'] = 1;

    // add projectId to mutation
    this.topicButtons = this.formHandler.lockButtons(this.topicButtons);

    if (!this.formHandler.formValid([this.todoTopicForm])) {
      this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
    } else {
      const returnAttributes = ['id', 'Name', 'companyId'];
      const refetchArr = [{ name: 'companyTodoTopics', variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'todotopic',
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              { form: this.todoTopicForm, argument: crudType + 'Todotopic' },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.todoTopicForm.controls['Name'].setValue('');
            }

            this.topicButtons = this.formHandler.unlockButtons(
              this.topicButtons
            );
            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
            this.topicButtons = this.formHandler.unlockButtons(
              this.topicButtons
            );
          }
        );
    }
  }

  calculateTotals() {
    let total = 0;

    this.projectRowComponents.forEach(item => {
      const timeValue = item.componentMainForm.controls['estimatedTime'].value;
      if (!isNaN(timeValue)) {
        total += Number(timeValue);
      }
    });

    this.totals['estimatedTime'] = total;
  }

  eraseTopic() {
    if (this.eraseTopicModel != null) {
      this.eraseTopicMutation();
    }
  }

  eraseTopicMutation() {
    const crudType = 'update';
    const dataToMutation = {};

    dataToMutation['id'] = Number(this.eraseTopicModel);
    dataToMutation['active'] = 0;

    // add projectId to mutation
    this.topicButtons = this.formHandler.lockButtons(this.topicButtons);

    const refetchArr = [{ name: 'companyTodoTopics', variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'todotopic',
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.eraseTopicModel = null;
          }
          this.mutationService.displayMutationStatus(executedData);

          this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
        }
      );
  }

  getReturnAttributes() {
    const returnAttributes = [
      'id',
      'type',
      'orderNr',
      'description',
      'descriptionOffer',
      'done',
      'estimatedTime',
      'offerId',
      'todoType',
    ];

    return returnAttributes;
  }

  getTodoTopics() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyTodoTopics', null, 'cache-and-network')
      .subscribe(data => {
        this.topicsSub = data.sub;
        this.todoTopicsDropdown = this.todoTopicsService.dataFormater(
          data.data,
          'labels'
        );
        this.altTodoTopicsDropdown = this.todoTopicsService.dataFormater(
          data.data,
          'altLabels'
        );
        this.dataSet.todoTopics = this.helperService.cleanFromNode(
          data.data['company']['todotopics']
        );
        this.getTodos();
      });
  }

  getTodos() {
    const variables = {
      id: this.projectInfo.id,
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectTodos', variables, 'cache-and-network')
      .subscribe(data => {
        this.todosSub = data.sub;
        this.loading.next(false);
        this.dataSet.todos = this.projectTodosService.dataFormater(
          data.data,
          'listNew'
        );
        this.dataSet.todos = this.countHoursForTodos(this.dataSet.todos);
        this.sortTodos(this.dataSet.todos);
        setTimeout(() => {
          this.calculateTotals();
        }, 500);
      });
  }

  countHoursForTodos(todosParam) {
    const dayArray = this.projectDays;
    const todos = todosParam;
    this.totals['totalHours'] = 0;
    for (const todoIndex in todos) {
      const todo = todos[todoIndex];
      todo['utslag'] = {
        totalHours: 0,
        users: [],
      };

      for (const dayIndex in dayArray) {
        const day = dayArray[dayIndex];
        if (
          typeof day['todoRelation'] !== 'undefined' &&
          day['todoRelation'] !== null
        ) {
          if (Number(todo['id']) === Number(day['todoRelation']['todo_id'])) {
            todo['utslag']['totalHours'] += Number(day['hours']);

            this.totals['totalHours'] += Number(day['hours']);

            let user = {};
            userArr: for (const usIndex in todo['utslag']['users']) {
              const userFromArr = todo['utslag']['users'][usIndex];

              if (Number(userFromArr['id']) === Number(day['user']['id'])) {
                user = userFromArr;
                break userArr;
              }
            }

            if (!user.hasOwnProperty('hours')) {
              user['id'] = day['user']['id'];
              user['username'] =
                day['user']['firstName'] + ' ' + day['user']['lastName'];
              user['hours'] = Number(day['hours']);
              todo['utslag']['users'].push(user);
            } else {
              user['hours'] += +day['hours'];
            }
          }
        }
      }
    }
    return [...todos];
  }

  sortTodos(dataParam) {
    this.dataSet.todoTopicsWithChilds = [];
    const todos = dataParam;

    const topics = {};
    for (const todoIndex in todos) {
      const todo = todos[todoIndex];

      if (!topics.hasOwnProperty(todo['topic']['id'])) {
        const topic = {
          id: todo['topic']['id'],
          name: todo['topic']['Name'],
          childs: [],
        };

        topic['childs'].push(todo);
        topics[todo['topic']['id']] = topic;
      } else {
        topics[todo['topic']['id']]['childs'].push(todo);
      }
    }

    for (const key in topics) {
      const topicWithChilds = topics[key];
      this.dataSet.todoTopicsWithChilds.push(topicWithChilds);
    }

    this.loading.next(false);
  }

  printTodo() {
    const url =
      this.globalService.getUrlPrefix() +
      '/project/PrintTodo/' +
      this.projectInfo['id'];

    this.htmlModalService.ny_sida(url, '700', '800');
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.userService.makeLabelsArray(data);
        this.setUsersDropdownSplitBtn(data);
        this.addNullUser();
      });
  }

  setUsersDropdownSplitBtn(data) {
    this.usersDropdownSplitBtn = this.apolloQueryService
      .cleanFromNode(data['company']['users'])
      .map(item => ({
        label: item.firstName + ' ' + item.lastName,
        command: () => {
          this.showMailModule(item.email);
        },
      }));
  }

  addNullUser() {
    const nullUser = {
      value: null,
      label: 'Välj medarbetare...',
    };

    this.usersDropdown.splice(0, 0, nullUser);
  }

  deleteRow(dataObjectParam) {
    const dataSet = this.dataSet['todoTopicsWithChilds'];

    parentArr: for (const index in dataSet) {
      const topic = dataSet[index];

      for (const todoInd in topic['childs']) {
        const todo = topic['childs'][todoInd];

        if (Number(todo['id']) === Number(dataObjectParam['id'])) {
          topic['childs'].splice(+todoInd, 1);
          if (topic['childs'].length === 0) {
            dataSet.splice(+index, 1);
          }
          break parentArr;
        }
      }
    }

    this.dataSet['todoTopicsWithChilds'] = [...dataSet];

    this.deleteTodoEmit(dataObjectParam);
  }

  estimatedChangedInChild() {
    this.calculateTotals();
  }

  deleteTodoEmit(todo) {
    const deleteTodoEvent = {
      projectId: this.projectInfo['id'],
      todoObj: todo,
      todoId: todo['id'],
      action: 'delete',
    };
    this.todoCrudAction.emit(deleteTodoEvent);
  }

  updateTodoEmit(todo) {
    const updateTodoEvent = {
      projectId: this.projectInfo['id'],
      todoObj: todo,
      todoId: todo['id'],
      action: 'update',
    };
    this.todoCrudAction.emit(updateTodoEvent);
  }

  handleCreated(emittedTodoParam) {
    const objectToEmit = {
      id: emittedTodoParam['id'],
      description: emittedTodoParam['description'],
      done: emittedTodoParam['done'],
    };
    this.createTodoEmit(objectToEmit);
  }

  createTodoEmit(todo) {
    const createTodoEvent = {
      projectId: this.projectInfo['id'],
      todoObj: todo,
      todoId: todo['id'],
      action: 'create',
    };
    this.todoCrudAction.emit(createTodoEvent);
  }

  closeDropdown() {
    setTimeout(() => {
      this.autoCompleteComponent.overlayVisible = false;
    }, 20);
  }

  search() {
    if (this.componentMainForm.controls['description'].value.length > 1) {
      this.searchTodos(this.componentMainForm.controls['description'].value);
    } else {
      this.autoCompleteComponent.overlayVisible = false;
    }
  }

  searchTodos(query) {
    const url =
      this.globalService.getUrlPrefix() +
      '/todo/AutoCompleteHyperion?q=' +
      query +
      '&limit=50';
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.results.next(data);
      if (this.results.value.length > 0) {
        this.autoCompleteComponent.overlayVisible = true;
      } else {
        this.autoCompleteComponent.overlayVisible = false;
      }
    });
  }

  setTodoFromAutosuggest(value) {
    const todo = { ...value };

    this.componentMainForm.controls['description'].setValue(
      todo['description']
    );
    this.componentMainForm.controls['type'].setValue(todo['type']);
    this.autoCompleteComponent.overlayVisible = false;
  }

  getChartsData(projectId) {
    this.getChartsHttp(projectId, 'pie');
    this.getChartsHttp(projectId, 'bars');
  }

  getChartsHttp(projectId, chartType) {
    const url =
      this.globalService.getUrlPrefix() +
      '/project/todo?projectId=' +
      projectId +
      '&chartType=' +
      chartType;
    return this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      const dataAsJSON = data;
      chartType === 'pie' && this.insertPieData(dataAsJSON['data']);
      chartType === 'bars' && this.insertBarsData(dataAsJSON);
      this.chartsLoading.next(false);
    });
  }

  insertPieData(data) {
    const seriesData = [];

    for (const i in data) {
      const dataArr = data[i];
      const description =
        dataArr[0].length < 50 ? dataArr[0] : dataArr[0].slice(0, 50) + '...';

      const dataObj = {
        name: description,
        y: dataArr[1],
      };

      seriesData.push(dataObj);
    }

    this.charts['pie']['series'][0]['data'] = seriesData;
  }

  insertBarsData(barsJson) {
    this.charts['bars']['series'] = barsJson['data'];
    this.charts['bars']['xAxis']['categories'] = barsJson['categories'];
  }

  showMailModule(email = null) {
    const files = [
      {
        path: `RENDER:printTodo:${this.projectInfo['id']}:{}`,
        file: 'Arbetsmoment',
      },
    ];

    this.mailDialogService.openMailDialog({
      email,
      projectId: +this.projectInfo['id'],
      files,
    });
  }

  public calculateEstimatedTime = (): void => {
    const startDate = this.componentMainForm.get('startDate').value;
    const endDate = this.componentMainForm.get('endDate').value;
    const timeController = this.componentMainForm.get('estimatedTime');

    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate).set('hour', 23).set('minutes', 59);
      const time = Math.ceil(moment.duration(end.diff(start)).asDays() * 8);
      timeController.patchValue(time);
    }
  };
}
