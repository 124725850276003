import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor() {}

  /** List of production TLDs */
  private productionDomains = ['struqtur.se', 'proffsapp.derome.se'];

  /** List of keywords that we cannot have in the production hostname */
  private exclusionKeywords = ['staging', 'dev', 'test'];

  /**
   * getEnvironmentType
   */
  public getEnvironmentType(): 'PRODUCTION' | 'NON_PRODUCTION' {
    if (
      location.hostname &&
      this.productionDomains.some(domain =>
        location.hostname.includes(domain)
      ) &&
      this.doesNotContain(this.exclusionKeywords, location.hostname)
    ) {
      return 'PRODUCTION';
    }
    return 'NON_PRODUCTION';
  }

  /**
   * Returns true if no `exclusionKeywords` are found in the `searchString`
   * @param exclusionKeywords The array of words not to include
   * @param searchString The string to be searched for
   */
  private doesNotContain(
    exclusionKeywords: string[],
    searchString: string
  ): boolean {
    return exclusionKeywords.every(keyword =>
      searchString.indexOf(keyword) === -1 ? true : false
    );
  }
}
