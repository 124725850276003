import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import {
  OnInit,
  AfterViewInit,
  OnDestroy,
  Component,
  Inject,
} from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '../shared/auth/auth.service';
import { GlobalService } from '../shared/global';
import {
  CompanyAppParamsService,
  CompanyFunctionsService,
} from '../shared/company';
import {
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2GoogleTagManager,
} from 'angulartics2';
import { UserLocalStorageService } from '../shared/user';
import { ConfigService } from '../shared/config/config.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { DOCUMENT } from '@angular/common';

import { PrimeNGConfig } from 'primeng/api';

declare global {
  interface Window {
    _hsq: any;
  }
}

const FIRST_MIMAS_CONTACT_QUERY = gql`
  query fetchFirstMimasContactEmail {
    me {
      id
    }
    company {
      id
      name
      firstMimasContactEmail
    }
  }
`;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit, OnDestroy, AfterViewInit {
  public isAccountLocked: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private loadingAsync = new BehaviorSubject<boolean>(false);
  public loading = false;
  public parentRoute = null;

  constructor(
    protected router: Router,
    public authService: AuthService,
    private globalService: GlobalService,
    private primeNGConfig: PrimeNGConfig,
    private companyFunctionsService: CompanyFunctionsService,
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    private userLocalStorageService: UserLocalStorageService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private companyAppParamsService: CompanyAppParamsService,
    protected configService: ConfigService,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document,
    protected apollo: Apollo
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      this.setLoading(event);
    });

    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event: NavigationEnd) => {
        let route = event.url.split('/')[1];
        if (route.split('?')[1] !== 'undefined') {
          route = route.split('?')[0];
        }

        this.parentRoute = route;
      });
  }

  public ngOnInit(): void {
    this.globalService.toggleNavBarLoadingFromService
      .pipe(takeUntil(this.destroy$))
      .subscribe(x => {
        this.loading = x;
        this.loadingAsync.next(x);
      });

    this.primeNGConfig.setTranslation({
      dateFormat: 'yy-mm-dd',
      firstDayOfWeek: 1,
      dayNamesMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
      weekHeader: 'v.',
      monthNames: [
        'Januari',
        'Februari',
        'Mars',
        'April',
        'Maj',
        'Juni',
        'Juli',
        'Augusti',
        'September',
        'Oktober',
        'November',
        'December',
      ],
      today: 'Idag',
    });
  }

  private setLoading(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.loadingAsync.next(true);
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel
    ) {
      this.loadingAsync.next(false);
      this.loading = false;
    }
  }

  /**
   * This code lives here instead of AppComponent since this component extends AppComponent.
   * Otherwise, it would run twice.
   */
  public ngAfterViewInit(): void {
    const hsq: any = (window._hsq = window._hsq || []);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        hsq.push(['setPath', `/v2${event.url}`]);
        hsq.push(['trackPageView']);
      });

    this.authService.isLoggedInObs.subscribe(isLoggedIn => {
      this.fetchMimasContact().subscribe(({ data }: any) => {
        if (data?.company?.firstMimasContactEmail) {
          hsq.push(['setPath', '/v2']);
          hsq.push([
            'identify',
            {
              id: data.me.id,
              email: data.company.firstMimasContactEmail,
            },
          ]);
        }
      });
    });
  }

  public isParentActive(item): boolean {
    return item === this.parentRoute.split('/')[1];
  }

  public updateLockedAccount(isLocked: boolean): void {
    this.isAccountLocked = isLocked;
  }

  private fetchMimasContact(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: FIRST_MIMAS_CONTACT_QUERY,
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
