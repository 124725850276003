<div
  class="dialog-container"
  [style.width]="state === 'companyForm' ? '700px' : 'initial'"
>
  <div *ngIf="state === 'chooseSolution'" style="width: 85vw">
    <div class="dialog-title-row">
      <div class="p-grid">
        <div class="p-col-12 align-center">
          <h2>
            <span>Välkommen till {{ appParams.name }}</span>
          </h2>
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div
        class="p-col-12"
        [class.p-lg-6]="appParams.environment !== 'beijerbygg'"
      >
        <div class="p-grid p-g-nopad">
          <div class="p-col-12 align-center">
            <p>
              För att hjälpa er att komma igång med systemet så snabbt och
              effektivt som möjligt erbjuder vi er möjligheten att få en
              <span class="strong-text"
                >helt kostnadsfri genomgång av systemet. Denna tar ca 30 min och
                sker via telefon</span
              >
              samt att vi hjälper till med att koppla ihop er dator med vår så
              att vi kan visa hela systemet för er.

              <br /><br />Välj vilken typ av konto som ni är intresserade av så
              kontaktar vi er inom kort för att boka in en lämplig tid för
              genomgång av systemet.

              <br /><br />Ni kan kontakta oss på
              {{ appParams.supportPhone }} eller via mail,
              <a [href]="'mailto:' + appParams.supportMail">{{
                appParams.supportMail
              }}</a
              >, om ni har frågor eller vill boka en genomgång av systemet redan
              idag.
            </p>
          </div>
        </div>
      </div>
      <div
        *ngIf="appParams.environment !== 'beijerbygg'"
        class="p-col-12 p-lg-6"
      >
        <div class="shadow-card" (click)="setChosenSolution('ProjectComplete')">
          <div class="p-grid">
            <div class="p-col-10 card-content">
              <h4>Projektverktyg komplett</h4>
              <p>
                Få tillgång till hela projektverktyget samt funktionerna i
                E-liggare premium
              </p>
            </div>
            <div
              class="p-col-2 action-icon align-center"
              [class.active-choice]="chosenSolution === 'ProjectComplete'"
            >
              <i
                class="fa"
                [class.fa-check-circle-o]="chosenSolution === 'ProjectComplete'"
                [class.fa-check]="chosenSolution !== 'ProjectComplete'"
              ></i>
            </div>
          </div>
        </div>
        <div class="shadow-card" (click)="setChosenSolution('PlPremium')">
          <div class="p-grid">
            <div class="p-col-10 card-content">
              <h4>E-liggare premium</h4>
              <p>
                Skapa obegränsat med egna projekt, GPS positionering vid in- och
                utstämpling samt många andra funktioner
              </p>
            </div>
            <div
              class="p-col-2 action-icon align-center"
              [class.active-choice]="chosenSolution === 'PlPremium'"
            >
              <i
                class="fa"
                [class.fa-check-circle-o]="chosenSolution === 'PlPremium'"
                [class.fa-check]="chosenSolution !== 'PlPremium'"
              ></i>
            </div>
          </div>
        </div>
        <div class="shadow-card" (click)="setChosenSolution('Pl')">
          <div class="p-grid">
            <div class="p-col-10 card-content">
              <h4>E-liggare gratis</h4>
              <p>
                Skapa upp till 3 egna projekt samt obegränsad rapportering som
                underentreprenör
              </p>
            </div>
            <div
              class="p-col-2 action-icon align-center"
              [class.active-choice]="chosenSolution === 'Pl'"
            >
              <i
                class="fa"
                [class.fa-check-circle-o]="chosenSolution === 'Pl'"
                [class.fa-check]="chosenSolution !== 'Pl'"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="state === 'companyForm'"
    style="width: 700px; max-width: 85vw"
    (keyup.enter)="goForward()"
  >
    <div class="dialog-title-row">
      <div class="p-grid">
        <div class="p-col-12 align-center">
          <h2><span>Uppdatera företagsprofilen </span></h2>
        </div>
        <div class="p-col-12 align-center">
          <p>
            Vänligen fyll i era företagsuppgifter för att komma igång med
            systemet.
          </p>
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-g-nopad">
        <spinner *ngIf="loading"></spinner>
        <form
          [formGroup]="componentMainForm"
          *ngIf="!loading"
          class="register-form full-width"
        >
          <div class="p-col-12">
            <appForm2-simple-input
              formControlName="name"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="orgNr"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="phone"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="address"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="cityCode"
            ></appForm2-simple-input>
            <appForm2-simple-input
              formControlName="city"
            ></appForm2-simple-input>
          </div>

          <div class="p-col-6">
            <appForm2-simple-input
              [autocomplete]="'off'"
              formControlName="password"
              type="password"
            ></appForm2-simple-input>
          </div>
          <div class="p-col-6">
            <appForm2-simple-input
              [autocomplete]="'off'"
              formControlName="repeat_password"
              type="password"
            ></appForm2-simple-input>
          </div>
        </form>
      </div>
      <div class="p-col-12"></div>
    </div>
  </div>

  <div *ngIf="state === 'finalScreen'">
    <div class="dialog-title-row">
      <div class="p-grid">
        <div class="p-col-12 align-center">
          <h2>Nu är ni färdiga att börja använda systemet!</h2>
        </div>
        <div class="p-col-12 align-center">
          <p>
            Vi på {{ appParams.name }} är väldigt glada att ni har valt att
            skapa ett konto hos oss. {{ appParams.name }} används i dag av
            tusentals företag för att förenkla deras vardag och effektivisera
            deras arbete.

            <br /><br />Vi är övertygade om att {{ appParams.name }} även kan
            hjälpa er verksamhet att bli effektivare och minska det
            administrativa arbetet så att mer tid kan läggas på bättre och
            roligare saker.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="state === 'finalScreen'" class="p-grid">
    <div class="align-center p-col-12">
      <app-button
        (onClick)="closeAction()"
        [disabled]="buttons.update.disabled"
        [type]="'forward'"
      ></app-button>
    </div>
  </div>

  <div *ngIf="state !== 'finalScreen'" class="p-grid">
    <div class="p-col-12">
      <app-button
        *ngIf="state === 'companyForm'"
        (onClick)="goBack()"
        (keyup.enter)="goBack()"
        [disabled]="buttons.update.disabled"
        [type]="'back'"
      ></app-button>
      <app-button
        (onClick)="goForward()"
        [disabled]="buttons.update.disabled"
        [class]="'pull-right'"
        [type]="'forward'"
      ></app-button>
    </div>
  </div>
</div>
