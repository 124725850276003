import {
  Component,
  Input,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { HttpService } from 'app/shared/http';
import { GlobalService } from '../../shared/global/index';
import { HelperService } from '../../shared/helpers/index';
import { ProjectCostService } from './project-cost.service';
import { MessageService } from '../../shared/message/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { ApolloMutationService } from '../../shared/apollo/index';

@Component({
  selector: 'project-cost',
  templateUrl: 'project-cost.component.html',
  styleUrls: ['project-cost.component.scss'],
})
export class ProjectCostComponent implements OnDestroy, OnInit {
  @Input() projectInfo;
  @Input() projectData;
  @Input() projectInvoiceData = {};
  @Input() normalDays;
  @Input() projectCostTypes;
  @Input() projectUserCostTypes;
  @Input() extraDays;
  @Output() callGetInvoiceData = new EventEmitter();

  daysCostsDataSet = new BehaviorSubject([]);
  loading = new BehaviorSubject(false);
  callServiceSub: Subscription;
  workSumAmount = 0;
  mtrlSumAmount = 0;
  mileSumAmount = 0;
  overheadSumAmount = 0;
  workSumProcent = 0;
  mtrlSumProcent = 0;
  mileSumProcent = 0;
  overheadSumProcent = 0;

  calculateAllCost = 0;
  resultProcent = 0;
  resultOfferProcent = 0;
  calculateOfferSum = 0;
  calculateResult = 0;
  calculateResultOffer = 0;
  daysCostSource = 'salary';
  daysCostSourceOptions = [
    { label: 'Schablon', value: 'salary' },
    { label: 'Yrkestyp', value: 'costType' },
  ];
  showDaysCostsTable = false;
  showDaysCostsTypeTable = false;
  addOverheadCost = false;
  daysForTableView = [];
  rowDaysGroupMetadata = {};
  daysForTableViewLoading = true;
  groupByForDaysForTableView;
  groupByForDaysForTableViewOptions = [
    { label: 'Yrkestyp', value: 'costTypeId' },
    { label: 'Användare', value: 'userId' },
  ];
  showProductTable = false;
  productsForTableView = [];
  groupByForProductsForTableView;
  rowProductsGroupMetadata = {};
  productsForTableViewLoading = true;
  groupByForProductsForTableViewOptions = [
    { label: 'Extra / Normal', value: 'extraString' },
    { label: 'Kostnadstyp', value: 'costTypeString' },
    {
      label: 'Kostnadstyp + Extra / Normal',
      value: 'costTypeIdWithExtraString',
    },
  ];
  showMileTable = false;
  milesForTableView = [];
  groupByForMilesForTableView;
  rowMilesGroupMetadata = {};
  milesForTableViewLoading = true;
  groupByForMilesForTableViewOptions = [
    { label: 'Extra / Normal', value: 'extra' },
    { label: 'Användare', value: 'userId' },
  ];
  toggleInputs = false;
  projectControls = {
    mileCost: null,
    privMileCost: null,
    invoicedInOtherSystem: null,
  };

  tableColums = [
    'Namn',
    'Lön',
    'Helglön',
    'Sem. ers',
    'Arb. giv.avgift',
    'Soc. försäkring',
    'Trak. halvdag',
    'Trak. heldag',
    'Trak. natt',
    'Milersättning',
    'Summa',
  ];

  constructor(
    private httpService: HttpService,
    private htmlModalService: HtmlModalService,
    private globalService: GlobalService,
    private projectCostService: ProjectCostService,
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef
  ) {}

  setSumVars() {
    this.workSumAmount = 0;
    this.overheadSumAmount = 0;
    const totalsOnHours = this.calculateTotalWages();

    if (this.daysCostSource === 'costType') {
      // Calculate the cost of labour on fixed ProjectUserCost
      this.projectInvoiceData['projectUserCostTypes'].forEach(userCostType => {
        this.workSumAmount += userCostType.internalCostTotal
          ? +userCostType.internalCostTotal
          : 0;
      });
    } else {
      // Calculate the cost for labour based on taxes, holiday pay etc
      this.workSumAmount = totalsOnHours.totalWages;
    }

    if (this.addOverheadCost) {
      this.overheadSumAmount = totalsOnHours.totalHourlyOverHeadCost;
    }

    this.mileSumAmount = this.getTotalMiles() * this.projectData.mileCost;

    this.calculateAllCost =
      this.workSumAmount +
      this.mtrlSumAmount +
      this.mileSumAmount +
      this.overheadSumAmount;

    this.calculateOfferSum =
      Number(this.projectData['offertSum']) +
      Number(this.projectData['offertSumWork']);

    const subtractFrom =
      Number(this.projectInvoiceData['invoicedTotal']) +
      Number(this.projectControls['invoicedInOtherSystem']);

    this.calculateResult = subtractFrom - this.calculateAllCost;
    this.calculateResultOffer = this.calculateOfferSum - this.calculateAllCost;

    if (subtractFrom > 0) {
      this.resultProcent = Math.round(
        (this.calculateResult / subtractFrom) * 100
      );
    }

    if (this.calculateOfferSum > 0) {
      this.resultOfferProcent = Math.round(
        (this.calculateResultOffer / this.calculateOfferSum) * 100
      );
    }

    const tot = this.calculateAllCost;
    if (tot > 0) {
      this.workSumProcent = Math.round((this.workSumAmount / tot) * 100);
      this.mtrlSumProcent = Math.round((this.mtrlSumAmount / tot) * 100);
      this.overheadSumProcent = Math.round(
        (this.overheadSumAmount / tot) * 100
      );
      this.mileSumProcent = Math.abs(
        this.workSumProcent +
          this.mtrlSumProcent +
          this.overheadSumProcent -
          100
      );
    } else {
      this.workSumProcent = 0;
      this.mtrlSumProcent = 0;
      this.mileSumProcent = 0;
      this.overheadSumProcent = 0;
    }

    this.cdr.markForCheck();
  }

  daySourceChange(source) {
    this.daysCostSource = source;
    if (source === 'costType') {
      this.updateDaysRowGroupMetaData('userId');
    }
    this.setSumVars();
  }

  ngOnInit() {
    this.getCostsDaysData();

    this.updateDaysRowGroupMetaData(
      (this.groupByForDaysForTableView = 'userId')
    );
    this.updateMilesRowGroupMetaData(
      (this.groupByForMilesForTableView = 'userId')
    );
    this.updateProductsRowGroupMetaData(
      (this.groupByForProductsForTableView = 'costTypeString')
    );

    this.callServiceSub = this.projectCostService.callService.subscribe(
      data => {
        this.getCostsDaysData();
        setTimeout(() => {
          this.showDaysCostsTypeTable && this.updateDaysRowGroupMetaData();
          this.showMileTable && this.updateMilesRowGroupMetaData();
          this.showProductTable && this.updateProductsRowGroupMetaData();

          this.setSumVars();
        }, 2000);
      }
    );

    this.setProjectControls();
  }

  // FIXME: rename this method
  addProcent(value: number, percentage = 0): number {
    return value * (1 + percentage / 100);
  }

  updateProductsRowGroupMetaData(
    groupByForProductsForTableView = this.groupByForProductsForTableView
  ) {
    this.productsForTableView = [];
    this.mtrlSumAmount = 0;
    this.projectCostTypes.forEach(costType => {
      costType['products'].map(product => {
        this.mtrlSumAmount += +(+product.avtalsprisCost * +product.antal);
        this.productsForTableView.push({
          ...product,
          ...{
            costTypeIdWithExtraString:
              costType.name +
              ' - ' +
              (+product.extra === 1 ? 'Extra' : 'Normal'),
            costTypeString: costType.name,
            extraString: +product.extra === 1 ? 'Extra' : 'Normal',
            avtalsprisPlusProcent: this.addProcent(
              +product.avtalspris,
              +costType.procent
            ),
          },
        });
      });
    });

    this.groupByForProductsForTableView = groupByForProductsForTableView;
    this.productsForTableViewLoading = true;
    this.rowProductsGroupMetadata = {};

    this.productsForTableView = this.helperService.sortArray(
      this.productsForTableView,
      groupByForProductsForTableView,
      1
    );
    if (this.productsForTableView) {
      for (let i = 0; i < this.productsForTableView.length; i++) {
        const rowData = this.productsForTableView[i];
        const grpAttribute = rowData[groupByForProductsForTableView];
        if (i === 0) {
          this.rowProductsGroupMetadata[grpAttribute] = {
            index: 0,
            size: 1,
            sum: rowData.avtalsprisPlusProcent * rowData.antal,
            costSum: rowData.avtalsprisCost * rowData.antal,
          };
        } else {
          const previousRowData = this.productsForTableView[i - 1];
          const previousRowGroup =
            previousRowData[groupByForProductsForTableView];
          if (grpAttribute === previousRowGroup) {
            this.rowProductsGroupMetadata[grpAttribute].size++;
            this.rowProductsGroupMetadata[grpAttribute].sum =
              this.rowProductsGroupMetadata[grpAttribute].sum +
              +rowData.avtalsprisPlusProcent * +rowData.antal;
            this.rowProductsGroupMetadata[grpAttribute].costSum =
              this.rowProductsGroupMetadata[grpAttribute].costSum +
              +rowData.avtalsprisCost * +rowData.antal;
          } else {
            this.rowProductsGroupMetadata[grpAttribute] = {
              index: i,
              size: 1,
              sum: rowData.avtalsprisPlusProcent * rowData.antal,
              costSum: rowData.avtalsprisCost * rowData.antal,
            };
          }
        }
      }
    }
    this.productsForTableViewLoading = false;
  }

  daysConcatAppendAttributes() {
    const userCostTypes = this.projectUserCostTypes;
    return this.normalDays['daysData']
      .concat(this.extraDays['daysData'])
      .filter(day => +day.timeHour === 1)
      .map(day => {
        const userCostTypeObject = userCostTypes.find(
          type => +type.id === +day.costTypeId
        );
        return day.user
          ? {
              ...day,
              ...{
                userId: day.user.id,
                userCostTypeObject: userCostTypeObject,
              },
            }
          : {
              ...day,
              ...{ userId: null, userCostTypeObject: userCostTypeObject },
            };
      });
  }

  updateMilesRowGroupMetaData(
    groupByForMilesForTableView = this.groupByForMilesForTableView
  ) {
    this.milesForTableViewLoading = true;
    this.milesForTableView = this.helperService.sortArray(
      this.daysConcatAppendAttributes().filter(day => +day.mile > 0),
      groupByForMilesForTableView,
      1
    );
    this.groupByForMilesForTableView = groupByForMilesForTableView;
    this.rowMilesGroupMetadata = {};

    const clean = day => this.helperService.stringToCleanNumber(day['mile']);

    if (this.milesForTableView) {
      for (let i = 0; i < this.milesForTableView.length; i++) {
        const rowData = this.milesForTableView[i];
        const grpAttribute = rowData[groupByForMilesForTableView];
        if (i === 0) {
          this.rowMilesGroupMetadata[grpAttribute] = {
            index: 0,
            size: 1,
            mileSum: clean(rowData),
          };
        } else {
          const previousRowData = this.milesForTableView[i - 1];
          const previousRowGroup = previousRowData[groupByForMilesForTableView];
          if (grpAttribute === previousRowGroup) {
            this.rowMilesGroupMetadata[grpAttribute].size++;
            this.rowMilesGroupMetadata[grpAttribute].mileSum =
              this.rowMilesGroupMetadata[grpAttribute].mileSum + clean(rowData);
          } else {
            this.rowMilesGroupMetadata[grpAttribute] = {
              index: i,
              size: 1,
              mileSum: clean(rowData),
            };
          }
        }
      }
    }
    this.milesForTableViewLoading = false;
  }

  updateDaysRowGroupMetaData(
    groupByForDaysForTableView = this.groupByForDaysForTableView
  ) {
    this.daysForTableViewLoading = true;
    this.daysForTableView = this.helperService.sortArray(
      this.daysConcatAppendAttributes(),
      groupByForDaysForTableView,
      1
    );
    this.groupByForDaysForTableView = groupByForDaysForTableView;
    this.rowDaysGroupMetadata = {};

    if (this.daysForTableView) {
      this.daysForTableView.forEach((rowData, index, daysForTableView) => {
        const groupKey = rowData[groupByForDaysForTableView];
        const internalCost =
          (rowData.userCostTypeObject &&
            +rowData.userCostTypeObject.internalCost) ||
          0;
        const hours = +rowData.hours;
        const groupSum = hours * internalCost;

        if (index === 0) {
          this.rowDaysGroupMetadata[groupKey] = {
            index,
            size: 1,
            hours,
            groupSum,
          };
          return;
        }

        const previousRowData = daysForTableView[index - 1];
        const previousRowGroup = previousRowData[groupByForDaysForTableView];

        if (groupKey === previousRowGroup) {
          // If same group, just accumulate
          this.rowDaysGroupMetadata[groupKey].size++;
          this.rowDaysGroupMetadata[groupKey].hours += hours;
          this.rowDaysGroupMetadata[groupKey].groupSum += groupSum;
        } else {
          this.rowDaysGroupMetadata[groupKey] = {
            index,
            size: 1,
            hours,
            groupSum,
          };
        }
      });
    }
    this.daysForTableViewLoading = false;
  }

  ngOnDestroy() {
    this.callServiceSub.unsubscribe();
  }

  getCostsDaysData() {
    const url =
      this.globalService.getUrlPrefix() +
      '/project/costsHyperion?projectId=' +
      this.projectInfo['id'] +
      '&timeHour=-1&extra=-1';
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      if (data.hasOwnProperty('data')) {
        this.daysCostsDataSet.next(data['data']);
        this.setSumVars();
      }
    });
  }

  setProjectControls() {
    this.projectControls['mileCost'] = this.projectData['mileCost'];
    this.projectControls['privMileCost'] = this.projectData['privMileCost'];
    this.projectControls['invoicedInOtherSystem'] =
      this.projectData['invoicedInOtherSystem'];
  }

  openCostModal() {
    const url =
      this.globalService.getUrlPrefix() +
      '/project/costs?projectId=' +
      this.projectInfo['id'] +
      '&timeHour=-1&extra=-1';
    this.htmlModalService.ny_sida(url, 1200, 1000);
  }

  costUser(data) {
    let costUser =
      data['wageSum'] +
      data['vacationSum'] +
      data['socialFeeSum'] +
      data['foraSum'];

    if (costUser > 0) {
      costUser = Math.round(
        costUser / (data['totalHours'] - data['totalWeekendHours'])
      );
      return 'Kostnad/h ' + costUser + 'kr'; // Hämta currency sedan
    } else {
      return '';
    }
  }

  calculateTotalWages() {
    const dataSet = this.daysCostsDataSet.value;
    let totalWages = 0;
    let totalHourlyOverHeadCost = 0;

    for (const i in dataSet) {
      const data = dataSet[i];

      totalWages += +data['sum'];
      totalHourlyOverHeadCost += +data['hourlyOverHeadCost'];
    }

    return {
      totalWages: totalWages,
      totalHourlyOverHeadCost: totalHourlyOverHeadCost,
    };
  }

  getTotalMiles() {
    let dataSet;

    dataSet = this.normalDays['daysData'].concat(this.extraDays['daysData']);

    let totalMile = 0;

    for (const i in dataSet) {
      const day = dataSet[i];

      if (Number(day['timeHour']) === 1) {
        totalMile += +(typeof day['mile'] === 'string'
          ? this.helperService.stringToCleanNumber(day['mile'])
          : day['mile']);
      }
    }

    return totalMile;
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.projectInfo['id']),
      invoicedInOtherSystem: this.projectControls['invoicedInOtherSystem'],
      mileCost: this.projectControls['mileCost'],
      privMileCost: this.projectControls['privMileCost'],
    };

    const returnKeys = ['mileCost', 'privMileCost', 'invoicedInOtherSystem'];

    this.loading.next(true);

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'project',
        crudType,
        false,
        dataToMutation,
        returnKeys
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.messageService.insertData({
              textArray: ['Projektet uppdaterades'],
              time: 2000,
              type: 'success',
            });

            for (const i in returnKeys) {
              this.projectData[returnKeys[i]] = executedData[returnKeys[i]];
            }

            this.setProjectControls();
            this.getCostsDaysData();
            this.callGetInvoiceData.emit();
          } else {
            this.messageService.insertData({
              textArray: ['Projektet uppdaterades inte'],
              time: 2000,
              type: 'error',
            });
          }

          this.loading.next(false);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }
}
