import { gql } from 'apollo-angular';

import { GQLFragment, Query } from './index';

import { Contact } from './contact';

import { ProjectExternalId } from './project';

/**
 * Old text snippets that have been replaced by fragments,
 * to be kept until mutations are updated.
 *
 * @deprecated use fragments instead
 */
const inner = {
  get singleCompanyOffer() {
    return `
      id
      trueId
      status
      typeOffer
      groupCosts
      user {
        id
        firstName
        lastName
      }
      clientContact {
        id
        name
        orderBuisnessName
      }
      concerning
      state
      sended
    `;
  },
  singleCompanyOfferTemplates: `
    id
    templateName
  `,
  singleOfferComment: `
    id
    comment
    created
    user {
      firstName
      lastName
    }
  `,
};

// GQL FRAGMENTS

const OFFER_FRAGMENT_SINGLE_COMPANY_OFFER = gql`
  fragment SingleCompanyOffer on Offer {
    id
    trueId
    zignedId
    isDigitallySigned
    created
    status
    typeOffer
    groupCosts
    user {
      id
      firstName
      lastName
    }
    clientContact {
      ...ClientContactBase
    }
    concerning
    state
    sended
    isLocked
  }
  ${Contact.fragments.clientContactBase}
` as unknown as GQLFragment;

const FRAGMENT_OFFER_TEMPLATE = gql`
  fragment OfferTemplate on Offer {
    id
    templateName
  }
` as unknown as GQLFragment;

/** GQL Fragments on Offer  */
export const Offer = {
  fragments: {
    offerTemplate: FRAGMENT_OFFER_TEMPLATE,
    singleCompanyOffer: OFFER_FRAGMENT_SINGLE_COMPANY_OFFER,
  },
};

/** GQL Fragments on Comment */
export const Comment = {
  fragments: {
    singleOfferComment: gql`
      fragment SingleOfferComment on Comment {
        id
        comment
        created
        user {
          firstName
          lastName
        }
      }
    `,
  },
};

// GQL QUERIES

const COMPANY_OFFERS = {
  query: gql`
    query companyOffers($status: [Int]) {
      company {
        id
        offers(status: $status) {
          totalCount
          edges {
            node {
              ...SingleCompanyOffer
            }
          }
        }
      }
    }
    ${Offer.fragments.singleCompanyOffer}
  `,
  testVariables: {},
};

const SIMPLE_SINGLE_OFFER = {
  query: gql`
    query fetchSingleOfferSimpleQuery($id: Int) {
      offer(id: $id) {
        id
        trueId
        clientContact {
          ...ClientContactBase
        }
      }
    }
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {},
};

const COMPANY_OFFERS_WITH_SUMMATION = {
  query: gql`
    query fetchcompanyOffersWithSummationQuery($status: [Int]) {
      company {
        id
        offers(status: $status) {
          edges {
            node {
              id
              trueId
              status
              concerning
              state
              created
              sended
              typeOffer
              groupCosts
              priceSummation_PreOfferTypeHyperion {
                priceListSumInkTax
                priceListSum
                tax
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const COMPANY_OFFERS_TEMPLATES: Query = {
  get query() {
    return gql`
      query fetchCompanyOfferTemplatesQuery {
        company {
          id
          offerTemplates {
            totalCount
            edges {
              node {
                ...OfferTemplate
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: Offer.fragments.offerTemplate,
};

const SINGLE_OFFER_COMMENTS = {
  query: gql`
    query fetchSingleOfferCommentsQuery($id: Int) {
      offer(id: $id) {
        id
        comments {
          totalCount
          edges {
            node {
              ...SingleOfferComment
            }
          }
        }
      }
    }
    ${Comment.fragments.singleOfferComment}
  `,
  testVariables: {},
};

const SINGLE_OFFER = {
  query: gql`
    query fetchSingleOfferQuery($id: Int, $type: String) {
      offer(id: $id, type: $type) {
        id
        sendFromUserId
        headerText
        footerText
        inputForHourCost
        inputForMachinHourCost
        inputForMaterialProcent
        inputForMileCost
        inputForTravelCost
        insurance
        mail
        phone
        rotPerson1
        rotPerson2
        rotSum
        rotYear
        status
        isLocked
        templateId
        templateName
        trueId
        type
        validToDate
        typeOffer
        groupCosts
        user {
          id
          firstName
          lastName
        }
        clientContact {
          ...ClientContactBase
          orgNr
          mail
          propertyName
          housingAssociationOrgNumber
          apartmentDesignation
          betalningsvillkor
          address
          address2
          phone
          mobilePhone
          cityCode
          city
          trueId
          contactType
        }
        establishmentContact {
          id
          name
          orderBuisnessName
          orgNr
          mail
          propertyName
          housingAssociationOrgNumber
          apartmentDesignation
          address
          address2
          phone
          mobilePhone
          cityCode
          city
          trueId
        }
        attributesWithMeta_PreOfferTypeHyperion {
          edges {
            node {
              offerFieldId
              label
              name
              value
              type
              inputType
              exampelTextOnHover
              addAsExempel
              exampelText
              active
              breakpage
              forceBreakpage
              order
              autoSuggest
              addDatePicker
            }
          }
        }
        calculation {
          id
          deromeWebshopStatus
        }
        calculationRows {
          edges {
            node {
              id
              name
              cost
              listPrice
              listPriceUnit
              totalAmount
              rowSumPlusProcent_PreUserCalculationRowTypeHyperion
              procent
              tax
              quantity
              unit
              productId
              calculationId
              order
              source
              sourceId
              greenTaxReduction
            }
          }
        }
        comments {
          totalCount
          edges {
            node {
              ...SingleOfferComment
            }
          }
        }
        documentMeta(first: -1) {
          totalCount
        }
        externalIds {
          edges {
            node {
              ...ProjectExternalIdBase
            }
          }
        }
        todos {
          edges {
            node {
              id
              type
              descriptionOffer
              description
              quantity
              unit
              unitPrice
              cost
              tax
              rot
              orderNr
              greenTaxReduction
            }
          }
        }
        signaturs {
          edges {
            node {
              id
              name
              personalNumber
            }
          }
        }
        contractors {
          edges {
            node {
              id
              person
              buisness
            }
          }
        }
        organization {
          edges {
            node {
              id
              person
              position
            }
          }
        }
        versions {
          edges {
            node {
              id
              version
            }
          }
        }
      }
    }
    ${Comment.fragments.singleOfferComment}
    ${Contact.fragments.clientContactBase}
    ${ProjectExternalId.fragments.base}
  `,
  testVariables: {},
};

const OFFER_DOCUMENTS = {
  query: gql`
    query fetchOfferDocumentsQuery($id: Int) {
      offer(id: $id) {
        id
        documentMeta {
          totalCount
          edges {
            node {
              id
              comment
              file
              offerId
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

/** EXPORTS */

export const refetchQueries = {
  companyOffers: inner.singleCompanyOffer,
  companyOfferTemplates: inner.singleCompanyOfferTemplates,
  singleOfferComment: inner.singleOfferComment,
};

export const queries = {
  companyOffers: COMPANY_OFFERS,
  simpleSingleOffer: SIMPLE_SINGLE_OFFER,
  companyOffersWithSummation: COMPANY_OFFERS_WITH_SUMMATION,
  companyOfferTemplates: COMPANY_OFFERS_TEMPLATES,
  singleOfferComments: SINGLE_OFFER_COMMENTS,
  singleOffer: SINGLE_OFFER,
  offerDocuments: OFFER_DOCUMENTS,
};
