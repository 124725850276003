<form id="docu-signup-form" #docuForm>
  <div>
    <form [formGroup]="userInfoForm">
      <appForm2-simple-input
        formControlName="firstName"
      ></appForm2-simple-input>
      <appForm2-simple-input formControlName="lastName"></appForm2-simple-input>
      <appForm2-simple-input formControlName="email"></appForm2-simple-input>
    </form>

    <form [formGroup]="companyInfoForm">
      <appForm2-simple-input formControlName="phone"></appForm2-simple-input>
      <appForm2-simple-input formControlName="name"></appForm2-simple-input>
      <appForm2-simple-input formControlName="orgNr"></appForm2-simple-input>
    </form>

    <p class="tos-notice">
      Genom att skapa ett konto accepterar du Struqturs
      <a href="https://www.struqtur.se/sv/anvandarvillkor">användaravtal</a>.
    </p>
  </div>

  <div class="align-right">
    <app-button
      *ngIf="cancelUrl"
      (onClick)="cancelUrlTrigger()"
      type="cancel"
    ></app-button>
    <app-button
      *ngIf="!cancelUrl"
      (onClick)="cancel()"
      type="cancel"
    ></app-button>
    <app-button
      *ngIf="!saving"
      class="ml5"
      [disabled]="saving"
      overrideButtonType="primary"
      (onClick)="submitForm()"
      [plainText]="{ label: 'Skapa konto' }"
    ></app-button>
    <app-button
      *ngIf="saving"
      class="ml5"
      [disabled]="saving"
      overrideButtonType="primary"
      [plainText]="{ label: 'Skapar...' }"
    ></app-button>
  </div>
</form>
