import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BooleanPipe } from './boolean-pipe/boolean.pipe';
import { NullTextPipe } from './null-text-pipe/null-text.pipe';
import { DynamicPipe } from './dynamic-pipe/dynamic.pipe';
import { FormatNumberPipe, CapitalizePipe, UrlSafePipe } from './index';

@NgModule({
  imports: [CommonModule],
  declarations: [
    BooleanPipe,
    CapitalizePipe,
    DynamicPipe,
    FormatNumberPipe,
    NullTextPipe,
    UrlSafePipe,
  ],
  exports: [
    BooleanPipe,
    CapitalizePipe,
    DynamicPipe,
    FormatNumberPipe,
    NullTextPipe,
    UrlSafePipe,
  ],
  providers: [],
})
export class PipesModule {}
