import { Apollo } from 'apollo-angular';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormHandlerService } from 'app/shared/forms/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo/index';
import { HelperService } from 'app/shared/helpers';
import { queries, getRefetchQuerieOf } from 'app/shared/apollo/queries/index';
import { Subscription } from 'rxjs';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';

@Component({
  selector: 'app-offer-comment',
  templateUrl: './offer-comment.component.html',
  providers: [FormHandlerService],
})
export class OfferCommentComponent implements OnInit, OnDestroy {
  @Input() public offerInfo: { id: string | number };
  @Output() public totalComments = new EventEmitter();

  private dataModelCapitalized = 'Comment';
  public buttons: { comment: ButtonsAsync } = { comment: {} };
  private buttonObject = ['create'];
  public comments = [];
  public apolloQueryName = 'singleOfferComments';
  public componentMainForm: FormGroup;
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      comment: '',
    },
  };
  private singleOfferCommentsSub: Subscription;

  constructor(
    public helperService: HelperService,
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService
  ) {}

  public ngOnInit(): void {
    this.buttons.comment = this.formHandler.getButtonValuesAsync(
      this.buttonObject
    );
    this.getComments();
    this.initForm();
  }

  private initForm(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['comment']['label'] =
          'Skriv en ny kommentar';
      });
  }

  public getComments(): void {
    const variables = { id: +this.offerInfo.id };
    this.apolloQueryService
      .apolloWatchQueryTwo(this.apolloQueryName, variables, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.singleOfferCommentsSub = sub;
        this.totalComments.emit(data['offer']['comments']['totalCount']);
        this.comments = this.apolloQueryService.cleanFromNode(
          data['offer']['comments']
        );
      });
  }

  public actionCreate(): void {
    const crudType = 'create';
    const dataToMutation = {
      offerId: Number(this.offerInfo.id),
      comment: this.componentMainForm.controls['comment'].value,
    };

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.lockButtonsAsync(this.buttons.comment);

      const refetchArr = getRefetchQuerieOf('singleOfferComment');
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'comment',
          crudType,
          false,
          dataToMutation,
          [refetchArr]
        )
        .subscribe(
          executedData => {
            if (executedData.mutationSucceededAllArguments) {
              this.initForm();
              this.getComments();
            } else {
              this.formHandler.showServerErrorsOnAttributes(executedData, [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ]);
            }
            this.formHandler.unlockButtonsAsync(this.buttons.comment);
            executeMutationSub.unsubscribe();
          },
          err => {
            this.formHandler.unlockButtonsAsync(this.buttons.comment);
            console.log(err);
          }
        );
    }
  }

  public ngOnDestroy(): void {
    if (this.singleOfferCommentsSub) {
      this.singleOfferCommentsSub.unsubscribe();
    }
  }
}
