import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-docu-renameable',
  templateUrl: './docu-renameable.component.html',
  styleUrls: ['./docu-renameable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocuRenameableComponent {
  @Input() name: string;
  @Input() canRename = true;
  @Output() nameChange: EventEmitter<string> = new EventEmitter();

  renaming = false;
}
