<div>
  <form
    *ngIf="componentMainForm"
    [formGroup]="componentMainForm"
    class="p-grid"
  >
    <span class="p-col-12 p-lg-5">
      <input
        pInputText
        formControlName="prefix"
        [id]="
          componentPrefix +
          'companyProjectType_prefix_' +
          dataObjectFromParent.id
        "
      />
    </span>
    <span class="p-col-12 p-lg-5">
      <input
        pInputText
        formControlName="name"
        [id]="
          componentPrefix +
          'companyProjectType_prefix_' +
          dataObjectFromParent.id
        "
      />
    </span>

    <div class="p-col-12 p-lg-2 p-d-flex p-jc-between">
      <button
        pButton
        class="p-button-icon-danger"
        [disabled]="buttons.delete.disabled"
        (click)="confirmActionDelete()"
        icon="pi pi-trash"
      ></button>
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.update.disabled"
        (click)="actionUpdate()"
        icon="pi pi-save"
      ></button>
    </div>
  </form>
</div>
