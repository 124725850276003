import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NextInvoiceNrQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NextInvoiceNrQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', nextInvoiceNr?: number | null, fortnox?: { __typename?: 'CompanyFortnox', useFortnoxInvoiceNumber?: number | null } | null } | null };

export type SetNextInvoiceNrPreferenceMutationVariables = Types.Exact<{
  nextPreferredInvoiceNumber?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetNextInvoiceNrPreferenceMutation = { __typename?: 'HyperionSchemaMutation', companyTypeHyperionMutation?: { __typename?: 'Company', id: string, nextPreferredInvoiceNumber?: number | null, nextInvoiceNr?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export const NextInvoiceNrDocument = gql`
    query nextInvoiceNr {
  company {
    nextInvoiceNr
    fortnox {
      useFortnoxInvoiceNumber
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NextInvoiceNrGQL extends Apollo.Query<NextInvoiceNrQuery, NextInvoiceNrQueryVariables> {
    document = NextInvoiceNrDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetNextInvoiceNrPreferenceDocument = gql`
    mutation setNextInvoiceNrPreference($nextPreferredInvoiceNumber: Int) {
  companyTypeHyperionMutation(
    updateCompany: {nextPreferredInvoiceNumber: $nextPreferredInvoiceNumber}
  ) {
    id
    nextPreferredInvoiceNumber
    nextInvoiceNr
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetNextInvoiceNrPreferenceGQL extends Apollo.Mutation<SetNextInvoiceNrPreferenceMutation, SetNextInvoiceNrPreferenceMutationVariables> {
    document = SetNextInvoiceNrPreferenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }