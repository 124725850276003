import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
  ElementRef,
  EventEmitter,
  Output,
  AfterViewInit,
} from '@angular/core';
import { DocumentMetadata } from '../document-metadata';
import { List } from 'immutable';
import { DocumentRowInfo, ViewId } from '../document';
import { DragulaService } from 'ng2-dragula';
import {
  DocumentChange,
  DocumentChangeRowSetUsed,
  DocumentChangeRowReorder,
} from '../document-change';
import { SaveAction, DocumentService } from '../document.service';
import { Observable } from 'rxjs';
import { DocuUpsellExportsDialogComponent } from '../docu-upsell-exports-dialog/docu-upsell-exports-dialog.component';
import { CompanyDefinitionService } from '../../shared/company/company-definition.service';
import { AppDialogService } from 'app/shared/dialogs';

@Component({
  selector: 'app-docu-sidebar',
  templateUrl: './docu-sidebar.component.html',
  styleUrls: ['./docu-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AppDialogService],
})
export class DocuSidebarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() metadata: DocumentMetadata;
  @Input() document: List<DocumentRowInfo>;
  @Input() dirty: boolean;
  @Input() focusedSection: number;
  @Output() docChange: EventEmitter<DocumentChange> = new EventEmitter();
  @Output() scrollToSection: EventEmitter<number> = new EventEmitter();
  @Output() save: EventEmitter<SaveAction> = new EventEmitter();

  hasSubscribed: Observable<boolean>;
  lastScroll: number;
  saveBtnItems = [];
  pdfBtnItems = [];

  constructor(
    private dragulaService: DragulaService,
    private hostElement: ElementRef,
    private documentService: DocumentService,
    private companyDefinitionService: CompanyDefinitionService,
    private dialogService: AppDialogService
  ) {
    this.setupSectionDragEvents();

    this.saveBtnItems = [
      {
        label: 'Spara',
        command: () => {
          this.clickSave();
        },
      },
      {
        label: 'Spara som...',
        /* icon: "pi pi-file", */ command: () => {
          this.clickSaveAs();
        },
      },
    ];
  }

  get pdfDownloadUrl(): string {
    return this.documentService.getPdfUrl(this.metadata.id, false, false);
  }

  ngOnInit() {
    this.hasSubscribed = this.companyDefinitionService.hasSubscribed();
  }

  ngAfterViewInit(): void {
    this.pdfBtnItems = [
      {
        label: 'Visa PDF',
        command: () => {
          this.openPDF('popup');
        },
      },
      {
        label: 'Ladda ner PDF',
        command: () => {
          this.openPDF('download');
        },
      },
    ];
  }

  ngOnDestroy() {
    this.dragulaService.destroy('docu-edit-section-list');
  }

  trackSectionById(index: number, item: DocumentRowInfo): ViewId {
    return item.viewId;
  }

  setSectionEnabled(section: DocumentRowInfo, enable: boolean) {
    this.docChange.emit(
      new DocumentChangeRowSetUsed({
        id: section.viewId,
        enable: enable,
      })
    );
  }

  setupSectionDragEvents() {
    // Adapted from https://github.com/valor-software/ng2-dragula/blob/master/src/components/dragula.provider.ts
    // for Immutable.js

    let srcIndex: number = null;
    const domIndexOf = (parent, child) =>
      Array.prototype.indexOf.call(parent.children, child);

    /* this.dragulaService.setOptions("docu-edit-section-list", {
      mirrorContainer: this.hostElement.nativeElement,
    }); */

    this.dragulaService.drag().subscribe(({ name, el, source }) => {
      if (name === 'docu-edit-section-list') {
        srcIndex = domIndexOf(source, el);
      }
    });
    this.dragulaService.drop().subscribe(({ name, el, source }) => {
      if (name === 'docu-edit-section-list') {
        const targetIndex = domIndexOf(source, el);
        const src = this.document.get(srcIndex);
        const below =
          targetIndex > 0
            ? this.document.get(targetIndex - 1)
            : { id: null, viewId: null };
        this.docChange.emit(
          new DocumentChangeRowReorder({
            id: src.viewId,
            belowId: below.viewId,
          })
        );
      }
    });
  }

  showExportUpsell() {
    this.dialogService.data = { documentId: this.metadata.id };
    this.dialogService.openComponent(DocuUpsellExportsDialogComponent);

    /* this.dialogService.open(DocuUpsellExportsDialogComponent, {
      data: this.metadata.id,
    }); */
  }

  openPDF(type = null) {
    let pdfPreviewUrl = this.documentService.getPdfUrl(
      this.metadata.id,
      true,
      false
    );

    if (type === 'download') {
      pdfPreviewUrl = this.documentService.getPdfUrl(
        this.metadata.id,
        false,
        false
      );
    }

    if (type === 'popup') {
      window.open(pdfPreviewUrl, 'popup', 'width=900,height=800');
    } else {
      window.open(pdfPreviewUrl, '_blank');
    }
  }

  clickSave() {
    this.save.emit(SaveAction.Save);
  }

  clickSaveAs() {
    this.save.emit(SaveAction.SaveAs);
  }
}
