import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, first, map, Subscription } from 'rxjs';
import { FetchProjectQuery } from '../../graphql/project.generated';
import {
  AcontoInvoiceFragment,
  GetAcontosGQL,
  GetSubprojectAcontosGQL,
} from '../graphql/aconto.generated';

@Component({
  selector: 'app-project-aconto',
  templateUrl: './project-aconto.component.html',
  styleUrls: ['./project-aconto.component.scss'],
})
export class ProjectAcontoComponent implements OnInit, OnDestroy {
  constructor(
    private getAcontoGQL: GetAcontosGQL,
    private getAcontosSubprojectGQL: GetSubprojectAcontosGQL
  ) {}

  @Input() public projectData: BehaviorSubject<FetchProjectQuery['project']>;
  @Input() public subprojects = false;
  private projectId: number;
  private projectDataSub: Subscription;

  public total: number;
  public totalThisProject: number;
  public acontos: AcontoInvoiceFragment[];
  public menus: { [key: number]: MenuItem[] } = {};

  public ngOnInit(): void {
    this.subprojects ? this.getSubprojectAcontos() : this.getAcontos();
  }

  public ngOnDestroy(): void {
    this.projectDataSub.unsubscribe();
  }

  private getSubprojectAcontos(): void {
    this.projectDataSub = this.projectData.subscribe(project => {
      if (!project.id) {
        return;
      }
      this.projectId = Number(project.id);
      this.getAcontosSubprojectGQL
        .fetch({ projectId: this.projectId })
        .pipe(
          first(),
          map(res => res.data.project.subProjectsAconto.edges.map(e => e.node))
        )
        .subscribe(acontos => {
          this.acontos = acontos;

          this.acontos.map(ac => (this.menus[ac.id] = this.getMenu(ac)));

          this.totalThisProject = this.acontos
            .map(ac => Number(ac.invoicedByProject))
            .reduce((acc, n) => acc + n, 0);

          this.total = this.acontos
            .map(ac => Number(ac.sumBox))
            .reduce((acc, n) => acc + n, 0);
        });
    });
  }

  private getAcontos(): void {
    this.projectDataSub = this.projectData.subscribe(project => {
      if (!project.id) {
        return;
      }
      this.projectId = Number(project.id);
      this.getAcontoGQL
        .fetch({ projectId: this.projectId })
        .pipe(
          first(),
          map(res => res.data.project.aconto.edges.map(e => e.node))
        )
        .subscribe(acontos => {
          this.acontos = acontos;

          this.acontos.map(ac => (this.menus[ac.id] = this.getMenu(ac)));

          this.totalThisProject = this.acontos
            .map(ac => Number(ac.invoicedByProject))
            .reduce((acc, n) => acc + n, 0);

          this.total = this.acontos
            .map(ac => Number(ac.sumBox))
            .reduce((acc, n) => acc + n, 0);
        });
    });
  }

  private getMenu(aconto: AcontoInvoiceFragment): MenuItem[] {
    const id = aconto.id;
    const menu: MenuItem[] = [
      {
        label: 'Visa faktura',
        icon: 'pi pi-file-pdf',
        url: this.getPdfUrl(id),
      },
      {
        label: 'Visa underlag',
        icon: 'pi pi-file-pdf',
        url: this.getBaseUrl(id),
        disabled: !(aconto.daysCount > 0 || aconto.productsCount > 0),
      },
      {
        label: 'Visa produkter',
        icon: 'pi pi-shopping-cart',
        url: this.getBaseProductsUrl(id),
        disabled: aconto.productsCount == 0,
      },
      {
        label: 'Visa tidrapporter',
        icon: 'pi pi-clock',
        url: this.getBaseDaysUrl(id),
        disabled: aconto.daysCount == 0,
      },
    ];
    return menu;
  }

  public openPdf(id: string): void {
    window.open(this.getPdfUrl(id));
  }

  private getPdfUrl(id: string): string {
    return '/invoice/Print/type/showPDF/id/' + id;
  }
  private getBaseUrl(id: string): string {
    return (
      '/project/PrintInvoicebase/' +
      this.projectId +
      '?invoices=0&invoice=' +
      id
    );
  }
  private getBaseProductsUrl(id: string): string {
    return this.getBaseUrl(id) + '&onlyProducts=1';
  }
  private getBaseDaysUrl(id: string): string {
    return this.getBaseUrl(id) + '&onlyDays=1';
  }
}
