import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CompanyInvoiceService {
  private invoiceForms = new BehaviorSubject<any>({});
  invoiceFormsChange$ = this.invoiceForms.asObservable();

  private searchValue = new BehaviorSubject<any>({});
  searchValueChange$ = this.searchValue.asObservable();

  private ordinaryRowsFromModeUpdate = new BehaviorSubject<any>([]);
  ordinaryRowsFromModeUpdate$ = this.ordinaryRowsFromModeUpdate.asObservable();

  dataModel = 'invoice';

  constructor() {}

  private dataFormater(data, mode) {
    // mode == labels || list

    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
      case 'rows':
        dataSetFormatted = this.makeRowObjects(data);
        break;
      case 'company':
        dataSetFormatted = this.makeCompanyObject(data);
        break;
    }

    return dataSetFormatted;
  }

  private makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      if (dataArray[i].node['active'] === 1) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.name,
          value: dataArray[i].node.id,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  private makeObjectsForForms(data) {
    /*
        För aktuell model:
    */

    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const dataObject = {};
      for (const key in dataArray[i].node) {
        dataObject[key] = dataArray[i].node[key];
      }
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }

  private makeRowObjects(data) {
    const dataArray = data.edges;
    const dataObjectsForForms = [];
    for (const i in dataArray) {
      const dataObject = {};
      for (const key in dataArray[i].node) {
        dataObject[key] = dataArray[i].node[key];
      }
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }

  private makeCompanyObject(data) {
    const dataFromComponent = data.company;
    const dataObject = {};
    for (const key in dataFromComponent) {
      if (typeof dataFromComponent[key] !== 'object') {
        dataObject[key] = dataFromComponent[key];
      }
    }
    return dataObject;
  }

  private formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;
    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }
    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }

  updateForm(name, form) {
    // get forms as an object && set as observable
    this.invoiceForms[name] = form;
    this.invoiceForms.next(this.invoiceForms);
  }

  sendRowsToBody(row: any[]) {
    this.ordinaryRowsFromModeUpdate.next(row);
  }

  updateSerchValue(contact) {
    // get forms as an object && set as observable
    this.searchValue.next(contact);
  }
}
