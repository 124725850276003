<p-galleria
  [(visible)]="showFullscreen"
  [fullScreen]="true"
  [value]="images | async"
  [(activeIndex)]="imageIndex"
  [showThumbnails]="false"
  [showItemNavigators]="true"
  [baseZIndex]="100000"
  (activeIndexChange)="preloadImages($event)"
>
  <ng-template pTemplate="item" let-item>
    <spinner [hidden]="preloadLargeStatus.get(item.id)"></spinner>
    <div>
      <img
        *ngIf="preloadLargeStatus.get(item.id)"
        style="max-height: 80vh"
        [src]="item.largerImage"
      />
    </div>
  </ng-template>
  <ng-template pTemplate="caption" let-item>
    <p-card class="p-mt-2">
      <div class="p-grid">
        <span
          *ngIf="!item.editable"
          class="p-col-12 p-d-flex p-jc-between p-ai-center"
        >
          <h3>
            {{ item.comment ? item.comment : '' }}
          </h3>
          <button
            *ngIf="!readonly"
            pButton
            (click)="item.editable = true"
            type="button"
            icon="pi pi-pencil"
            class="legacy-rounded-button p-button-text"
          ></button>
        </span>
        <span *ngIf="item.editable" class="p-inputgroup p-col-12">
          <input
            pInputText
            #comment
            [value]="item.comment ? item.comment : ''"
          />
          <button
            pButton
            (click)="
              changeComment(item.id, comment.value); item.editable = false
            "
            type="button"
            icon="pi pi-save"
            class="p-inputgroup-addon p-button-primary-hyperion"
          ></button>
        </span>
        <span class="p-col-12">
          Uppladdad: {{ item.created }} av {{ item.user.firstName }}
          {{ item.user.lastName }}
        </span>
      </div>
    </p-card>
  </ng-template>
</p-galleria>

<div class="p-grid">
  <div class="p-col-12 p-d-flex p-jc-between p-ai-center">
    <span *ngIf="projectId">
      <button
        pButton
        (click)="downloadZip()"
        class="p-mr-2"
        icon="pi pi-folder"
        label="Ladda ner"
      ></button>
      <button
        pButton
        (click)="showPdf()"
        class="p-mr-2"
        icon="pi pi-file-pdf"
        label="Visa"
      ></button>
      <button
        pButton
        (click)="downloadPdf()"
        class="p-mr-2"
        icon="pi pi-file-pdf"
        label="Ladda ner"
      ></button>
    </span>
    <p-paginator
      *ngIf="showPaginator"
      [rows]="imagesPerPage"
      [totalRecords]="totalImages"
      (onPageChange)="changePage($event)"
      [first]="pageIndex * imagesPerPage"
    ></p-paginator>
  </div>

  <p-card class="p-col-12 p-md-6 p-lg-4 p-xxl-3 p-xxxl-2" *ngIf="projectId">
    <ng-template pTemplate="header">
      <h3>Ladda upp bilder</h3>
    </ng-template>
    <p-fileUpload
      (onUpload)="fetchImages()"
      name="PictureForm[]"
      accept="image/*,.heif,.heic"
      [multiple]="true"
      [url]="'/project/uploadPictures/projectId/' + projectId"
      uploadLabel="Ladda upp"
      cancelLabel="Avbryt"
      invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
      invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
    >
      <ng-template pTemplate="content">
        <app-drag-and-drop-template></app-drag-and-drop-template>
      </ng-template>
    </p-fileUpload>
  </p-card>

  <p-card
    *ngFor="let image of images | async; let index = index"
    key="index"
    class="p-col-12 p-md-6 p-lg-4 p-xxl-3 p-xxxl-2"
  >
    <ng-template pTemplate="header">
      <h4 class="p-col-12 p-m-0" *ngIf="!projectId">
        <a [routerLink]="'/project/' + image.project.id + '/pictures'">
          {{ image.project.trueId }} {{ image.project.mark }}
        </a>
      </h4>
      <span
        *ngIf="!image.editable"
        class="p-col-12 p-d-flex p-jc-between p-ai-center"
      >
        <h3 class="p-m-0">
          {{ image.comment ? image.comment : '' }}
        </h3>
        <button
          *ngIf="!readonly"
          pButton
          (click)="image.editable = true"
          type="button"
          icon="pi pi-pencil"
          class="legacy-rounded-button p-button-text"
        ></button>
      </span>
      <span *ngIf="image.editable" class="p-inputgroup p-col-12">
        <input
          pInputText
          #comment
          [value]="image.comment ? image.comment : ''"
        />
        <button
          pButton
          (click)="
            changeComment(image.id, comment.value); image.editable = false
          "
          type="button"
          icon="pi pi-save"
          class="p-inputgroup-addon p-button-primary-hyperion"
        ></button>
      </span>
    </ng-template>
    <div
      class="p-grid p-d-flex p-ai-center p-jc-center"
      [style.height]="'350px'"
    >
      <img
        (click)="openFullscreen(index)"
        [style]="{ 'border-radius': '6px' }"
        src="{{ image.thumbnail }}"
      />
    </div>
    <ng-template pTemplate="footer">
      <label class="p-col-12"
        >Uppladdad: {{ image.created }} av {{ image.user.firstName }}
        {{ image.user.lastName }}</label
      >
      <span
        class="p-col-12 p-d-flex p-jc-between p-ai-center"
        *ngIf="!readonly"
      >
        <span>
          <p-inputSwitch
            class="p-mr-2"
            [ngModel]="image.displayOnPrint === 1"
            (onChange)="displayOnPrint(image.id, $event.checked)"
          ></p-inputSwitch>
          <label>Visa i PDF</label>
        </span>
        <span class="p-d-flex p-ai-center">
          <button
            pButton
            (click)="rotateImage(image.id)"
            class="p-mr-2"
            icon="pi pi-replay"
          ></button>
          <button
            pButton
            (click)="rotateImage(image.id, true)"
            class="p-mr-2"
            icon="pi pi-refresh"
          ></button>
          <button
            pButton
            (click)="confirmDelete(image.id)"
            icon="pi pi-trash"
            class="p-button-icon-danger"
          ></button>
        </span>
      </span>
    </ng-template>
  </p-card>
  <div class="p-col-12 p-d-flex p-jc-end p-ai-center">
    <p-paginator
      *ngIf="showPaginator"
      [rows]="imagesPerPage"
      [totalRecords]="totalImages"
      (onPageChange)="changePage($event)"
      [first]="pageIndex * imagesPerPage"
    ></p-paginator>
  </div>
</div>
