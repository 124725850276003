import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { IdleService } from 'app/shared/idle';

import { AuthService } from './auth.service';

// Kollar om användaren har behörighet att navigera till route
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private idleService: IdleService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.checkLogin(state).pipe(
      map(res => {
        if (res) {
          return res;
        }

        this.authService.redirectUrl = state.url;
        this.router.navigateByUrl('/login');
        return res;
      })
    );
  }

  checkLogin(state): Observable<boolean> {
    return this.authService.checkStatus().pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.idleService.stopIdle();
        }
      })
    );
  }
}
