import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { MessageService } from 'app/shared/message';
import { first } from 'rxjs';
import {
  CreateRotGQL,
  UpdateRotGQL,
  FetchRotsGQL,
  RotFragment,
  DeleteRotGQL,
} from './graphql/rot.generated';

@Component({
  selector: 'app-project-rot',
  templateUrl: './project-rot.component.html',
  styleUrls: ['./project-rot.component.scss'],
})
export class ProjectRotComponent implements OnInit {
  public projectId: number;
  public forms: FormGroup[] = [];

  constructor(
    private route: ActivatedRoute,
    private fetchRotsService: FetchRotsGQL,
    private createRotService: CreateRotGQL,
    private updateRotService: UpdateRotGQL,
    private deleteRotService: DeleteRotGQL,
    private messageService: MessageService,
    private projectCountService: ProjectCountService
  ) {}

  public createForm(rot: RotFragment = null): FormGroup {
    return new FormGroup({
      id: new FormControl(rot?.id),
      name: new FormControl(rot?.name),
      personalNumber: new FormControl(rot?.personalNumber, [
        Validators.minLength(10),
        Validators.maxLength(13),
      ]),
      nameOfProperty: new FormControl(rot?.nameOfProperty),
      housingAssociationOrgNumber: new FormControl(
        rot?.housingAssociationOrgNumber,
        [Validators.minLength(10), Validators.maxLength(11)]
      ),
      apartmentDesignation: new FormControl(rot?.apartmentDesignation),
      customerWantedWorkAmount: new FormControl(rot?.customerWantedWorkAmount, [
        Validators.max(75000),
      ]),
    });
  }

  public ngOnInit(): void {
    this.fetchRots();
  }

  private fetchRots(): void {
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;
      this.fetchRotsService
        .fetch({ projectId: this.projectId })
        .pipe(first())
        .subscribe(res => {
          this.projectCountService.count(this.projectId);
          const forms: FormGroup[] = res.data.project.rots.edges
            .map(e => e.node)
            .map(r => this.createForm(r));
          forms.push(this.createForm());
          this.forms = forms;
        });
    });
  }

  public submit(form: FormGroup, isDelete = false): void {
    form.markAllAsTouched();
    if (!form.valid) {
      return;
    }

    const rot: RotFragment = { ...form.value, projectId: this.projectId };

    if (isDelete) {
      this.deleteRot(rot);
    } else if (rot.id === null) {
      this.createRot(rot);
    } else {
      this.updateRot(rot);
    }
  }
  private createRot(rot: RotFragment): void {
    this.createRotService
      .mutate({ input: { ...rot, id: Number(rot.id) } })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectRotTypeHyperionMutation
        );
        this.fetchRots();
      });
  }
  private updateRot(rot: RotFragment): void {
    this.updateRotService
      .mutate({ input: { ...rot, id: Number(rot.id) } })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectRotTypeHyperionMutation
        );
        this.fetchRots();
      });
  }
  private deleteRot(rot: RotFragment): void {
    this.deleteRotService
      .mutate({ input: { ...rot, id: Number(rot.id) } })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectRotTypeHyperionMutation
        );
        this.fetchRots();
      });
  }
}
