import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { DialogModule } from 'primeng/dialog';
import { TreeTableModule } from 'primeng/treetable';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { SidebarModule } from 'primeng/sidebar';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { ColorPickerModule } from 'ngx-color-picker';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DividerModule } from 'primeng/divider';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';

import {
  ProjectAcontoComponent,
  ProjectAtaComponent,
  ProjectCommentComponent,
  ProjectHandleCommentComponent,
  ProjectContentComponent,
  ProjectContentDialogComponent,
  ProjectCostComponent,
  ProjectChildComponent,
  ProjectIndexComponent,
  ProjectDayComponent,
  ProjectProductComponent,
  ProjectMainComponent,
  ProjectComponent,
  ProjectTodoComponent,
  ProjectTodoRowComponent,
  CustomerPriceComponent,
  CustomerPriceProjectCostTypeRowComponent,
  CustomerPriceUserCostTypeRowComponent,
  ProjectUnderlagComponent,
  ProjectDocumentsComponent,
  ProjectDocumentRowComponent,
  ProjectDocumentUploadComponent,
  ProjectShareComponent,
  ProjectSupplierInvoiceComponent,
  SupplierInvoiceRowHolderComponent,
  ProjectShareRowComponent,
  ProjectRotComponent,
  ProjectRotRowComponent,
  ProjectInstallmentComponent,
  ProjectInstallmentSingleContainerComponent,
  ProjectInstallmentRowComponent,
  ProjectInstallmentCreateComponent,
  ProjectCostService,
  ProjectDefaultTodoModule,
} from './index';

import { ProjectCalendarService } from './project-calendar/project-calendar.service';
import { ProjectCalendarComponent } from './project-calendar/project-calendar.component';
import { ProjectCalendarGanttComponent } from './project-calendar/project-calendar-gantt.component';
import { AddPlannedWorkComponent } from './project-calendar/add-planned-work/add-planned-work.component';
import { ProjectImagesComponent } from './project-images/project-images.component';
import { UploadProjectImagesComponent } from './project-images/upload-project-images.component';
import { ProjectHandleModule } from './project-handle/project-handle.module';

import { HyperionSharedModule } from 'app/shared/shared.module';
import { SharedDialogModule } from 'app/shared/dialogs/shared.dialog.module';
import { CompanyProjectTypeModule } from 'app/settings/company/company-project-type/company-project-type.module';
import { OfferModule } from 'app/offer/offer.module';
import { UserSettingsModule } from 'app/registry/users/user-settings.module';
import { ClickOutsideModule } from 'app/shared/click-outside/click-outside.module';
import { ProjectProductBookingComponent } from './project-product-booking/project-product-booking.component';
import { ShareProjectCompanyComponent } from './project-share/share-project-company/share-project-company.component';
import { ShareProjectCustomerComponent } from './project-share/share-project-customer/share-project-customer.component';
import { ShareProjectClientRightsComponent } from './project-share/share-project-company/client/share-project-client-rights.component';
import { ProjectChildRowComponent } from './project-child/project-child-row/project-child-row.component';
import { DocuModule } from 'app/docu/docu.module';
import { CreateProjectProductComponent } from './project-product/create-project-product/create-project-product.component';
import { ProjectExternalModule } from 'app/shared/company/derome-integration/project-external.module';
import { ProjectImagesService } from './project-images/project-images.service';
import { HeaderModule } from 'app/header/header.module';
import { ProjectModule } from 'app/project/project.module';
import { ContactModule } from 'app/contact/contact.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    AutoCompleteModule,
    HyperionSharedModule,
    CompanyProjectTypeModule,
    CalendarModule,
    TableModule,
    OverlayPanelModule,
    SplitButtonModule,
    TabMenuModule,
    SharedModule,
    DialogModule,
    TreeTableModule,
    RadioButtonModule,
    TabViewModule,
    Ng2HighchartsModule,
    ClickOutsideModule,
    ColorPickerModule,
    DocuModule,
    DividerModule,
    AccordionModule,
    InputSwitchModule,

    ProjectDefaultTodoModule,
    SharedDialogModule,
    SidebarModule,
    UserSettingsModule,
    ProjectHandleModule,
    OfferModule,
    ProjectExternalModule,
    HeaderModule,
    ProjectModule,
    ContactModule,
  ],
  declarations: [
    ProjectAcontoComponent,
    ProjectAtaComponent,
    ProjectCostComponent,
    ProjectChildComponent,
    ProjectCalendarComponent,
    ProjectCalendarGanttComponent,
    ProjectIndexComponent,
    ProjectComponent,
    ProjectCommentComponent,
    ProjectHandleCommentComponent,
    ProjectContentComponent,
    ProjectContentDialogComponent,
    ProjectMainComponent,
    ProjectDayComponent,
    ProjectProductComponent,
    ProjectTodoComponent,
    ProjectTodoRowComponent,
    CustomerPriceComponent,
    CustomerPriceProjectCostTypeRowComponent,
    CustomerPriceUserCostTypeRowComponent,
    ProjectImagesComponent,
    UploadProjectImagesComponent,
    ProjectUnderlagComponent,
    ProjectDocumentsComponent,
    ProjectDocumentRowComponent,
    ProjectDocumentUploadComponent,
    ProjectShareComponent,
    ProjectSupplierInvoiceComponent,
    SupplierInvoiceRowHolderComponent,
    ProjectShareRowComponent,
    ProjectRotComponent,
    ProjectRotRowComponent,
    ProjectInstallmentComponent,
    ProjectInstallmentSingleContainerComponent,
    ProjectInstallmentRowComponent,
    ProjectInstallmentCreateComponent,
    AddPlannedWorkComponent,
    ProjectProductBookingComponent,
    ShareProjectCompanyComponent,
    ShareProjectCustomerComponent,
    ShareProjectClientRightsComponent,
    ProjectChildRowComponent,
    CreateProjectProductComponent,
  ],
  exports: [
    ProjectCommentComponent,
    ProjectHandleCommentComponent,
    ProjectContentComponent,
    ProjectContentDialogComponent,
    ProjectIndexComponent,
    ProjectMainComponent,
    ProjectDayComponent,
    ProjectProductComponent,
  ],
  providers: [ProjectCostService, ProjectCalendarService, ProjectImagesService],
})
export class OldProjectModule {}
