<div class="dialog-container">
  <div class="p-grid full-width">
    <div class="p-col-12">
      <p-tabView
        [orientation]="orientation"
        class="create-project-tabview"
        (onChange)="changeActiveTab($event); scrollToTop()"
      >
        <p-tabPanel header="Detaljer" [selected]="activeTab === 'project'">
          <div class="content" style="max-height: 100%" id="scrolStyle">
            <div class="p-grid p-g-nopad">
              <div class="p-col-12">
                <h3 class="page-subtitle">
                  Detaljer
                  <span
                    class="help-tooltip"
                    tooltipPosition="bottom"
                    pTooltip="Här kan du göra anteckningar<br>om projektet<br>Med 'Vår Referens' menas ansvarig<br>på ert företag<br><br>Datumfälten är obligatoriska"
                    [escape]="false"
                    >?</span
                  >
                </h3>
                <form [formGroup]="projectForm" (ngSubmit)="actionCreate()">
                  <appForm2-wide-input
                    formControlName="mark"
                    [tooltip]="{
                      msg: '<strong>Märkning</strong><br><br>Märkningen följer med projektet genom hela processen. Märkningen är tillsammans med projekt id det som visas när man till exempel ska tidrapportera.'
                    }"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="madeBy"
                  ></appForm2-wide-input>

                  <appForm2-wide-dropDown
                    formControlName="status"
                    [dropDownParams]="{ options: statusOptions }"
                  ></appForm2-wide-dropDown>

                  <appForm2-wide-input
                    formControlName="offertSum"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="offertSumWork"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="orderNumber"
                  ></appForm2-wide-input>

                  <appForm2-wide-dropDown
                    formControlName="typeId"
                    (rightFuncCall)="toogleDialog()"
                    *ngIf="functionsThisModel['useProjectTypes']"
                    [dropDownParams]="{
                      options: projectTypes,
                      rightIcon: 'fa fa-pencil-square-o hover-pointer'
                    }"
                  ></appForm2-wide-dropDown>

                  <appForm2-wide-calendar
                    formControlName="startDate"
                    appendTo="body"
                  ></appForm2-wide-calendar>

                  <appForm2-wide-calendar
                    formControlName="endDate"
                    appendTo="body"
                  ></appForm2-wide-calendar>

                  <app-project-external-dropdown
                    *ngIf="
                      (meUser.type === '3' || meUser.type === '2') &&
                      showProductExternalConnectInterface
                    "
                    [disableOnSelect]="true"
                    [projectExternalSource]="'derome-api'"
                    [type]="'wide'"
                    (externalProjectConnectionChanged)="
                      onExternalProjectConnectionChanged($event)
                    "
                  >
                  </app-project-external-dropdown>

                  <appForm2-wide-input
                    formControlName="constructionSiteNumber"
                  ></appForm2-wide-input>
                </form>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div></div>
            <div>
              <app-button
                [disabled]="buttons.create.disabled"
                (onClick)="actionCreate()"
                [type]="'create'"
                [model]="'Project'"
              ></app-button>
            </div>
            <div>
              <app-button
                (click)="activeTab = 'client'; scrollToTop()"
                [type]="'forward'"
              ></app-button>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Beställare" [selected]="activeTab === 'client'">
          <div class="content" style="overflow-y: hidden; max-height: 100%">
            <div class="p-grid p-g-nopad">
              <div class="p-col-12">
                <h3 class="page-subtitle">
                  Beställare
                  <span
                    class="help-tooltip"
                    tooltipPosition="bottom"
                    pTooltip="Ange beställande företag/person<br>Detta är även fakturaadress<br><br>Inga fält är obligatoriska"
                    [escape]="false"
                    >?</span
                  >
                </h3>
                <form
                  [formGroup]="clientContactForm"
                  (ngSubmit)="actionCreate()"
                >
                  <div class="p-grid form-group">
                    <div class="p-col-12 p-md-3">
                      <label class="form-control-label-alt"
                        >Sök befintlig kontakt</label
                      >
                    </div>
                    <div class="p-col-12 p-md-9">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"
                          ><i class="fa fa-search"></i
                        ></span>
                        <input
                          *ngIf="autoComplete.client.setFromStart | async"
                          disabled
                          type="text"
                          pInputText
                          [(ngModel)]="autoComplete.client.model"
                          [ngModelOptions]="{ standalone: true }"
                        />
                        <p-autoComplete
                          *ngIf="!(autoComplete.client.setFromStart | async)"
                          [suggestions]="autoComplete.client.results | async"
                          (completeMethod)="searchContacts($event, 'client')"
                          inputStyleClass="form-control"
                          [(ngModel)]="autoComplete.client.model"
                          [ngModelOptions]="{ standalone: true }"
                          (onSelect)="
                            setContactFromAutosuggest($event, 'client')
                          "
                          [style]="{ width: '100%' }"
                          [inputStyle]="{ width: '100%' }"
                          field="trueId"
                        >
                          <ng-template let-object pTemplate="item">
                            <div
                              class="p-helper-clearfix"
                              style="font-size: 12px"
                            >
                              <div
                                class="autocomplete-row autocomplete-header-row"
                              >
                                <div style="width: 10%">ID</div>
                                <div style="width: 22%">Namn</div>
                                <div style="width: 23%">Företag</div>
                                <div style="width: 42%">Adress</div>
                                <div
                                  style="float: none"
                                  class="clear-both"
                                ></div>
                              </div>
                              <div class="autocomplete-row">
                                <div style="width: 10%">
                                  {{ object.trueId }}
                                </div>
                                <div style="width: 22%">{{ object.name }}</div>
                                <div style="width: 22%">
                                  {{ object.orderBuisnessName }}
                                </div>
                                <div style="width: 22%">
                                  {{ object.address }}, {{ object.address2 }}
                                </div>
                                <div style="width: 22%">
                                  {{ object.cityCode }}, {{ object.city }}
                                </div>
                                <div
                                  style="float: none"
                                  class="clear-both"
                                ></div>
                              </div>
                            </div>
                          </ng-template>
                        </p-autoComplete>
                        <span
                          *ngIf="!(autoComplete.client.showSave | async)"
                          class="p-inputgroup-addon darken remove hover-pointer"
                          (click)="removeCoupling()"
                          pTooltip="Knyt ej"
                          tooltipPosition="left"
                          ><i class="fa fa-times"></i
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div class="p-grid">
                    <label class="p-col-3">Typ</label>
                    <span class="p-col-9">
                      <p-radioButton
                        *ngFor="let contactTypeValue of this.CONTACT_TYPES"
                        class="p-col-6"
                        formControlName="contactType"
                        [value]="contactTypeValue"
                        [label]="contactTypeValue"
                        [(ngModel)]="clientTypeValue"
                      ></p-radioButton>
                    </span>
                  </div>

                  <appForm2-wide-input
                    formControlName="orderBuisnessName"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="name"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="orgNr"
                  ></appForm2-wide-input>

                  <appForm2-wide-dropDown
                    [dropDownParams]="{ options: betalningsvillkorOptions }"
                    formControlName="betalningsvillkor"
                  >
                  </appForm2-wide-dropDown>

                  <appForm2-wide-input
                    formControlName="address"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="address2"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="cityCode"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="city"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="propertyName"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="housingAssociationOrgNumber"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="apartmentDesignation"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="phone"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="mobilePhone"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="mail"
                  ></appForm2-wide-input>

                  <appForm2-wide-checkbox
                    formControlName="contact"
                    *ngIf="autoComplete.client.showSave | async"
                    [tooltip]="{
                      msg: '<strong>Skapa kontakt</strong><br><br>Om du klickar i denna ruta så kommer uppgifterna ovan sparas som en kontakt. <br><br>En kontakt kan du återanvända till exempel när du ska skapa fler projekt med samma beställare.'
                    }"
                  ></appForm2-wide-checkbox>
                </form>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div>
              <app-button
                (click)="activeTab = 'project'; scrollToTop()"
                [type]="'back'"
              ></app-button>
            </div>
            <div>
              <app-button
                (click)="actionCreate()"
                [disabled]="buttons.create.disabled"
                [type]="'create'"
                [model]="'Project'"
              ></app-button>
            </div>
            <div>
              <app-button
                (click)="activeTab = 'establishment'; scrollToTop()"
                [type]="'forward'"
              ></app-button>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel
          header="Arbetsställe"
          [selected]="activeTab === 'establishment'"
        >
          <div class="content" style="position: relative; overflow-y: hidden">
            <div class="p-grid p-g-nopad">
              <div class="p-col-12" style="position: relative">
                <div class="chose-establishment" *ngIf="!establishmentChosen">
                  <div>
                    <p
                      class="link"
                      (click)="
                        copyValuesToEstablishment(); establishmentChosen = true
                      "
                    >
                      Om arbetsställe är
                      <span class="strong-text">samma</span> som beställare,
                      klicka här
                    </p>
                    <p class="link" (click)="establishmentChosen = true">
                      Om arbetsställe är annat än beställare, klicka här
                    </p>
                  </div>
                </div>
                <!-- arbetsställe -->
                <h3 class="page-subtitle div-with-right-icon">
                  Arbetsställe
                  <span
                    class="help-tooltip"
                    tooltipPosition="bottom"
                    pTooltip="Inte obligatoriskt. <br>Var arbetet ska utföras.<br><br>Inga fält är obligatoriska"
                    [escape]="false"
                    >?</span
                  >
                  <i
                    class="fa fa-copy hover-pointer"
                    (click)="copyValuesToEstablishment()"
                    pTooltip="<strong>Kopiera över</strong><br><br>Om beställare och arbetställe är samma, klicka för att kopiera över uppgifterna från beställare."
                    tooltipPosition="top"
                    [escape]="false"
                  ></i>
                </h3>
                <form
                  [formGroup]="establishmentContactForm"
                  (ngSubmit)="actionCreate()"
                >
                  <div class="p-grid form-group">
                    <div class="p-col-12 p-md-3">
                      <label class="form-control-label-alt"
                        >Sök befintlig kontakt</label
                      >
                    </div>
                    <div class="p-col-12 p-md-9">
                      <div class="p-inputgroup">
                        <span class="p-inputgroup-addon"
                          ><i class="fa fa-search"></i
                        ></span>
                        <p-autoComplete
                          [suggestions]="
                            autoComplete.establishment.results | async
                          "
                          (completeMethod)="
                            searchContacts($event, 'establishment')
                          "
                          inputStyleClass="form-control"
                          (onSelect)="
                            setContactFromAutosuggest($event, 'establishment')
                          "
                          [style]="{ width: '100%' }"
                          [inputStyle]="{ width: '100%' }"
                          field="trueId"
                        >
                          <ng-template let-object pTemplate="item">
                            <div
                              class="p-helper-clearfix"
                              style="font-size: 12px"
                            >
                              <div
                                class="autocomplete-row autocomplete-header-row"
                              >
                                <div style="width: 10%">ID</div>
                                <div style="width: 22%">Namn</div>
                                <div style="width: 23%">Företag</div>
                                <div style="width: 42%">Adress</div>
                                <div
                                  style="float: none"
                                  class="clear-both"
                                ></div>
                              </div>
                              <div class="autocomplete-row">
                                <div style="width: 10%">
                                  {{ object.trueId }}
                                </div>
                                <div style="width: 22%">{{ object.name }}</div>
                                <div style="width: 22%">
                                  {{ object.orderBuisnessName }}
                                </div>
                                <div style="width: 22%">
                                  {{ object.address }}, {{ object.address2 }}
                                </div>
                                <div style="width: 22%">
                                  {{ object.cityCode }}, {{ object.city }}
                                </div>
                                <div
                                  style="float: none"
                                  class="clear-both"
                                ></div>
                              </div>
                            </div>
                          </ng-template>
                          <!--<ng-template let-object pTemplate="dataset">
                                                        {{object | json}}
                                                    </ng-template>-->
                        </p-autoComplete>
                      </div>
                    </div>
                  </div>

                  <div class="p-grid">
                    <label class="p-col-3">Typ</label>
                    <span class="p-col-9">
                      <p-radioButton
                        *ngFor="let contactTypeValue of this.CONTACT_TYPES"
                        class="p-col-6"
                        formControlName="contactType"
                        [value]="contactTypeValue"
                        [label]="contactTypeValue"
                        [(ngModel)]="establishmentTypeValue"
                      ></p-radioButton>
                    </span>
                  </div>

                  <appForm2-wide-input
                    formControlName="orderBuisnessName"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="name"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="orgNr"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="address"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="address2"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="cityCode"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="city"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="propertyName"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="housingAssociationOrgNumber"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="apartmentDesignation"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="phone"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="mobilePhone"
                  ></appForm2-wide-input>

                  <appForm2-wide-input
                    formControlName="mail"
                  ></appForm2-wide-input>

                  <appForm2-list-checkbox
                    formControlName="contact"
                    [tooltip]="{
                      msg: '<strong>Skapa kontakt</strong><br><br>Om du klickar i denna ruta så kommer uppgifterna ovan sparas som en kontakt. <br><br>En kontakt kan du återanvända till exempel när du ska skapa fler projekt med samma beställare.'
                    }"
                  >
                  </appForm2-list-checkbox>
                </form>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div>
              <app-button
                (click)="activeTab = 'client'"
                [type]="'back'"
              ></app-button>
            </div>
            <div>
              <div *ngIf="useDefualtTodosOnProjects">
                <app-button
                  (onClick)="actionCreate()"
                  [disabled]="buttons.create.disabled"
                  [type]="'create'"
                  [model]="'Project'"
                ></app-button>
              </div>
            </div>
            <div>
              <app-button
                *ngIf="useDefualtTodosOnProjects"
                (click)="activeTab = 'defaultTodos'; scrollToTop()"
                [type]="'forward'"
              ></app-button>
              <app-button
                *ngIf="!useDefualtTodosOnProjects"
                (onClick)="actionCreate()"
                [disabled]="buttons.create.disabled"
                [type]="'create'"
                [model]="'Project'"
              ></app-button>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel
          *ngIf="useDefualtTodosOnProjects"
          header="Standardarbetsmoment"
          [selected]="activeTab === 'defaultTodos'"
        >
          <div class="content" style="overflow-y: hidden; max-height: 100%">
            <div class="p-grid p-g-nopad">
              <div class="p-col-12">
                <project-default-todo></project-default-todo>
              </div>
            </div>
          </div>
          <div class="nav-buttons">
            <div>
              <app-button
                (click)="activeTab = 'establishment'"
                [type]="'back'"
              ></app-button>
            </div>
            <div>
              <app-button
                (onClick)="actionCreate()"
                [disabled]="buttons.create.disabled"
                [type]="'create'"
                [model]="'Project'"
              ></app-button>
            </div>
            <div></div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
