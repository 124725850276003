import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';

import { HyperionCard } from './card.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [HyperionCard],
  declarations: [HyperionCard],
})
export class HyperionCardModule {}
