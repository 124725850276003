import {
  Component,
  OnInit,
  QueryList,
  AfterContentInit,
  TemplateRef,
  ContentChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ServerId } from '../document';
import { DocumentService } from '../document.service';
import { map } from 'rxjs/operators';
import { DocuSubscribeDialogComponent } from '../docu-subscribe-dialog/docu-subscribe-dialog.component';
import { PrimeTemplate } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { AppDialogService } from 'app/shared/dialogs';

@Component({
  selector: 'app-docu-upsell-dialog',
  templateUrl: './docu-upsell-dialog.component.html',
  styleUrls: ['./docu-upsell-dialog.component.scss'],
  providers: [AppDialogService],
})
export class DocuUpsellDialogComponent implements OnInit, AfterContentInit {
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  public yearlyPrice: Observable<number>;
  titleTemplate: TemplateRef<any>;
  extraButtonsTemplate: TemplateRef<any>;

  constructor(
    private documentService: DocumentService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private dialogService: AppDialogService
  ) {
    this.dialogConfig.showHeader = false;
  }

  ngOnInit() {
    this.yearlyPrice = this.documentService
      .pricing()
      .pipe(map(pricing => pricing.yearly));
  }

  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'title':
          this.titleTemplate = item.template;
          break;
        case 'extraButtons':
          this.extraButtonsTemplate = item.template;
          break;
        default:
          throw new Error('Invalid template type: ' + item.getType());
      }
    });
  }

  closeDialog(result = 'undefined') {
    this.dialogRef.close(result);
  }

  showSubscribeDialog() {
    this.dialogService
      .openComponent(DocuSubscribeDialogComponent)
      .onClose.subscribe(result => {
        console.log(result);

        result && this.closeDialog(result);
      });
    // this.dialogService.open(DocuSubscribeDialogComponent).beforeClose().subscribe(result => this.dialogRef.close(result));
  }
}
