<div class="p-grid">
  <div class="p-col-12">
    <p-table [rowHover]="true" [columns]="cols" [value]="payments">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
          </th>
          <th style="width: 280px">Sparad av</th>
          <th style="width: 30px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-payment let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ payment[col.field] }}
          </td>
          <td>
            {{ getPaymentCreator(payment['user']) }}
          </td>
          <td>
            <i (click)="deletePayment(payment)" class="fa fa-lg fa-trash-o"></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length + 2">
            <app-emptyState
              [model]="'ProjectInstallment'"
              [whereToCreate]="'topRight'"
            ></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12" *ngIf="componentMainForm" style="margin-top: 20px">
    <h4>Ange ny betalning (Aktuellt saldo: {{ balance | formatNum }}kr)</h4>

    <form [formGroup]="componentMainForm">
      <div class="p-col-4 p-g-nopad">
        <p-calendar
          formControlName="date"
          firstDayOfWeek="1"
          dateFormat="yy-mm-dd"
          dataType="string"
          [showIcon]="true"
          appendTo="body"
          [showWeek]="true"
        ></p-calendar>
      </div>
      <div class="p-col-4 p-g-nopad">
        <appForm2-simple-input formControlName="sum"></appForm2-simple-input>
      </div>

      <div class="p-col-4">
        <br />
        <app-button
          [class]="'pull-right'"
          (click)="createPayment()"
          [type]="'save'"
        ></app-button>
      </div>
    </form>
  </div>
</div>
