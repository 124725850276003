<div class="p-grid emptystate">
  <div class="p-col-12 align-center">
    <img [src]="bgImgSrc" />
  </div>

  <div class="p-col-12 align-center">
    <h2>{{ msgHeader }}</h2>
    <p *ngIf="showDetail">{{ msgSub }}</p>
  </div>
</div>
