import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';

import { HelperService } from 'app/shared/helpers/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { ApolloQueryService } from 'app/shared/apollo';
import { takeUntil } from 'rxjs/operators';
import { HyperionLabelsService } from '../../shared/labels';

@Component({
  selector: 'aconto-list',
  templateUrl: 'project-aconto.component.html',
  styleUrls: ['project-aconto.component.scss'],
})
export class ProjectAcontoComponent implements OnDestroy, OnInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() projectInfo;

  dataModel = 'projectAcontoList';
  acontoList = new BehaviorSubject([]);
  acontoSum = {
    sumBox: null,
    currency: null,
  };
  acontoSub: Subscription;
  public labels: any;

  constructor(
    private helperService: HelperService,
    private htmlModalService: HtmlModalService,
    private apolloQueryService: ApolloQueryService,
    private labelService: HyperionLabelsService
  ) {}

  ngOnInit() {
    this.queryAcontoList({ id: this.projectInfo.id });
    this.loadLabels();
  }

  private queryAcontoList(variables: { id: any }) {
    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel, variables)
      .subscribe(data => {
        this.acontoSub = data.sub;
        this.acontoList.next(
          this.helperService.cleanFromNode(
            data.data['project']['acontoList_PreProjectTypeHyperion']
          )
        );
        this.acontoSum = this.countTotal();
      });
  }

  countTotal() {
    const sum = {
      sumBox: null,
      currency: null,
    };

    const acontoList = this.acontoList.value;

    for (const acontoIndex in acontoList) {
      const aconto = acontoList[acontoIndex];
      sum['sumBox'] += +aconto['sumBox'];
      sum['currency'] = aconto['currency'];
    }

    return sum;
  }

  private loadLabels(): void {
    this.labelService
      .getLabels([{ model: 'Invoice' }])
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.labels = data.Invoice;
      });
  }

  openModal(urlParam) {
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }

  ngOnDestroy() {
    this.acontoSub && this.acontoSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
