<style>
  textarea {
    width: 100%;
  }
</style>

<div class="todo-autocomplete smaller-inputs">
  <appForm2-list-texteditor
    [(ngModel)]="value"
    [ngModelOptions]="{ standalone: true }"
    (ngModelChange)="setModelOnInput($event)"
  ></appForm2-list-texteditor>
  <p-autoComplete
    [suggestions]="results | async"
    inputStyleClass="form-control hide-input"
    (onSelect)="setValueFromAutosuggest($event)"
    [style]="{ width: '100%' }"
    [inputStyle]="{ width: '100%' }"
    styleClass="wide-panel"
    [(ngModel)]="autoModel"
    [ngModelOptions]="{ standalone: true }"
  >
    <ng-template let-object pTemplate="item">
      <div class="p-helper-clearfix" style="font-size: 12px">
        <div class="autocomplete-row">
          <div style="width: 100%">{{ object }}</div>
          <div style="float: none" class="clear-both"></div>
        </div>
      </div>
    </ng-template>
  </p-autoComplete>
</div>
