import { Component } from '@angular/core';
import { IntroJSService } from 'app/shared/intro-js/index';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
})
export class HomeComponent {
  show = {
    menu: false,
  };

  headerClass;

  constructor(private introJSService: IntroJSService) {}

  startIntroRequest() {
    const introArray = ['app', 'home'];

    this.introJSService.pushIntroToSubject(introArray);
  }
}
