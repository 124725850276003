import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Image } from 'app/shared/extended-directives/image-gallery';

@Injectable()
export class ProjectImagesService {
  constructor(private apollo: Apollo) {}

  public fetch(
    projectId: number,
    take: number = null,
    skip: number = null
  ): Observable<Image[]> {
    return this.apollo
      .query({
        query: FETCH_PROJECT_IMAGES.query,
        variables: {
          projectId: projectId,
          first: take,
          offset: skip,
        },
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          const data = JSON.parse(JSON.stringify(result.data));
          return data.project.pictureComments.edges.map(
            edge => edge.node
          ) as Image[];
        })
      );
  }
}

const FETCH_PROJECT_IMAGES = {
  query: gql`
    query fetchProjectImages($projectId: Int, $first: Int, $offset: Int) {
      project(id: $projectId) {
        id
        pictureComments(last: $first, offset: $offset) {
          edges {
            node {
              id
              comment
              displayOnPrint
              width
              height
              thumbnail: imageUrl(width: 300, height: 300)
              largerImage: imageUrl(width: 2000, height: 2000)
              created
            }
          }
        }
      }
    }
  `,
};
