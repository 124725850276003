<form [formGroup]="componentMainForm">
  <div class="p-grid">
    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-textarea
        formControlName="commentAboutUpdate"
      ></appForm2-wide-textarea>
    </div>

    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-calendar
        [showTime]="true"
        formControlName="startOrStopTime"
        [appendTo]="'body'"
      ></appForm2-wide-calendar>
    </div>

    <div class="p-col-12 align-right">
      <app-button
        *ngIf="mode === 'cancel'"
        (onClick)="crudAction()"
        [type]="'discard'"
      ></app-button>
      <app-button
        *ngIf="mode !== 'cancel'"
        (onClick)="crudAction()"
        [type]="'update'"
      ></app-button>
    </div>
  </div>
</form>
