import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';

import { HelperService } from 'app/shared/helpers';
import { SortService } from 'app/store/sort.service';
import { ApolloQueryService } from 'app/shared/apollo';
import { HyperionLabelsService } from 'app/shared/labels/index';
import { PurchaseTransactionService } from '../../purchase-transaction.service';
import { CustomSort } from 'app/store/custom-sort';

@Component({
  selector: 'app-order-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class OrderComponent implements OnDestroy {
  @ViewChild('dt', { static: true }) public orderTable: Table;

  showAdvancedSearch = false;
  formReady = false;
  loading = true;
  cols: { field: string; header: any }[];
  orders = [];
  projects = [];
  selectedOrders: any[] = [];
  supplierList: any[];
  totalOrders: any = '....';
  supplierTimeout: any;
  searchAttributes = [];
  selectedSupplierFilter = 0;
  selectedFastFilter = 'all';
  displayDialog = false;
  sort: CustomSort = {
    attribute: 'id',
    ascDesc: -1,
    object: 'edi_order_table',
  };
  orderSub: Subscription;
  projectSub: Subscription;
  supplierListSub: Subscription;

  constructor(
    private purchaseTransactionService: PurchaseTransactionService,
    private confirmationService: ConfirmationService,
    private apolloQueryService: ApolloQueryService,
    private labelsService: HyperionLabelsService,
    public helperService: HelperService,
    private sortService: SortService
  ) {
    this.getLabel();
    this.sort = this.sortService.getSort(this.sort);
  }

  changeSort = event => this.sortService.setSort(event, this.sort);

  private getLabel() {
    this.labelsService.getLabels([{ model: 'EdiOrder' }]).subscribe(labels => {
      this.cols = [
        {
          field: 'buyerOrderResponseNumber',
          header: labels.EdiOrder.buyerOrderResponseNumber,
        },
        {
          field: 'orderedBy',
          header: labels.EdiOrder.orderedBy,
        },
        { field: 'projectTrueId', header: labels.EdiOrder.projectId },
        { field: 'sourceName', header: labels.CompanySupplier.name },
        {
          field: 'sellerOrderResponseNumber',
          header: labels.EdiOrder.sellerOrderResponseNumber,
        },
        { field: 'created', header: labels.EdiOrder.created },
      ];
      this.searchAttributes = [
        {
          field: 'buyerOrderResponseNumber',
          header: labels.EdiOrder.buyerOrderResponseNumber,
          type: 'contains',
        },
        {
          field: 'projectTrueId',
          header: labels.EdiOrder.projectId,
          type: 'contains',
        },
        {
          field: 'sourceName',
          header: labels.CompanySupplier.name,
          type: 'contains',
        },
        {
          field: 'sellerOrderResponseNumber',
          header: labels.EdiOrder.sellerOrderResponseNumber,
          type: 'contains',
        },
        { field: 'created', header: labels.EdiOrder.created, type: 'contains' },
      ];
      this.fetchData();
    });
  }

  fastFilter(type, dt) {
    this.selectedFastFilter = type;
    dt.filters = {};

    switch (type) {
      case 'all':
        dt.filter('', 'savedToProject', 'equals');
        break;
      case 'saved':
        dt.filter(1, 'savedToProject', 'equals');
        break;
      case 'unsaved':
        dt.filter(0, 'savedToProject', 'equals');
        break;
    }

    if (this.selectedSupplierFilter > 0) {
      dt.filter(this.selectedSupplierFilter, 'companyEdiId', 'equals');
    }
  }

  fastSupplierFilter(supplierId: number, dt) {
    this.selectedSupplierFilter = supplierId;
    if (this.selectedSupplierFilter === 0) {
      dt.filter('', 'companyEdiId', 'equals');
    } else {
      dt.filter(this.selectedSupplierFilter, 'companyEdiId', 'equals');
    }
  }

  confirmSaveOrderToProject(projectId) {
    let selectedProject = '';
    this.projects.forEach(
      project =>
        project['value'] === +projectId && (selectedProject = project['label'])
    );
    this.selectedOrders.length > 0 &&
      projectId &&
      this.confirmationService.confirm({
        message: 'Vill du spara ordrar till projekt ' + selectedProject + '?',
        header: 'Spara',
        icon: 'fa fa-save',
        accept: () => {
          this.saveOrderToProject(projectId);
        },
        reject: () => {},
      });
  }

  private saveOrderToProject(projectId: any) {
    this.selectedOrders.forEach(order => {
      const orderRows = [...order['rows']];
      delete order.rows;
      delete order.project;
      delete order.projectTrueId;
      delete order.source;
      delete order.sourceName;
      order['projectId'] = projectId;
      const variables = {
        updateEdiOrder: order,
        orderRows: orderRows,
      };
      this.purchaseTransactionService.mutateOrder(variables).subscribe();
    });
    this.selectedOrders = [];
  }

  private fetchData(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'PurchaseEdiOrders',
        { last: 2500 },
        'cache-and-network'
      )
      .subscribe(({ data, sub }) => {
        this.orderSub = sub;
        this.orders = this.apolloQueryService
          .cleanFromNode(data['company']['ediOrders'])
          .map(order => {
            delete order['__typename'];
            return {
              ...order,
              ...{
                projectTrueId: order['project']
                  ? order['project']['trueId']
                  : null,
              }, // Show Project True id instead
              ...{
                sourceName: order?.source?.name,
              },
              ...{
                rows: order['rows']['edges'].map(row =>
                  this.deleteTypeName(row['node'])
                ),
              },
            };
          });

        this.totalOrders = this.orders.length;
        this.loading = false;
      });
    this.projects = [{ label: 'Flytta till projekt...', value: null }];
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAllProjects', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.projectSub = sub;
        const projectArr = this.apolloQueryService.cleanFromNode(
          data['company']['projects']
        );
        projectArr.forEach(project => {
          project && this.projects.push(this.returnOptionsObject(project));
        });
      });

    this.apolloQueryService
      .apolloWatchQueryTwo('EdiSuppliers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.supplierListSub = sub;
        this.supplierList = this.sortSuppliersAlphabetically(
          this.apolloQueryService.cleanFromNode(data['company']['ediSuppliers'])
        );
      });
  }

  // Create dropdown options;
  returnOptionsObject = project => ({
    label: `${project.trueId}, ${project['mark'] ? project['mark'] : ''} `,
    value: +project.id,
  });

  ngOnDestroy() {
    // Make sure subscription is available befoe unsubscribe
    this.supplierListSub && this.supplierListSub.unsubscribe();
    this.projectSub && this.projectSub.unsubscribe();
    this.orderSub && this.orderSub.unsubscribe();
  }

  deleteTypeName(obj: any) {
    const parsedObj = JSON.parse(JSON.stringify(obj));
    delete parsedObj['__typename'];
    return parsedObj;
  }

  sortSuppliersAlphabetically(supplierArray: any[]) {
    return supplierArray.sort((elemA, elemB) => {
      const nameA = elemA['supplier']['name'].toUpperCase(); // ignore upper and lowercase
      const nameB = elemB['supplier']['name'].toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // names must be equal
    });
  }
}
