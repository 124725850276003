import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Subject,
} from 'rxjs';
import {
  AttendanceReportFragment,
  CompanyDataGQL,
  CompanyFragment,
  SubcontractorFragment,
  SubcontractorsGQL,
  RemoveSubcontractorGQL,
  SubcontractorsRelationsGQL,
  AllowChangeGQL,
  GetAllAttendanceReportsGQL,
  GetHoursGQL,
} from './graphql/attendance.generated';
import {
  UsersGQL,
  UserFragment,
} from '../project-payment-plan/graphql/paymentplan.generated';
import { HttpService } from 'app/shared/http';
import { DatePipe } from '@angular/common';
import { MessageService } from 'app/shared/message';
import { ConfirmationService } from 'primeng/api';
import { AppDialogService } from 'app/shared/dialogs';
import { AddSubcontractorMailComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-mail/add-subcontractor-mail.component';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import cloneDeep from 'lodash.clonedeep';

export enum Mode {
  edit,
  cancel,
  close,
}

const NOT_CONNECTED_STRING = 'Ej Kopplade';

@Component({
  selector: 'app-project-attendance',
  templateUrl: './project-attendance.component.html',
  styleUrls: ['./project-attendance.component.scss'],
})
export class ProjectAttendanceComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private getAllAttendanceService: GetAllAttendanceReportsGQL,
    private getCompanyDataService: CompanyDataGQL,
    private getUsersService: UsersGQL,
    private getSubcontractorsService: SubcontractorsGQL,
    private httpService: HttpService,
    private datePipe: DatePipe,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private dialogService: AppDialogService,
    private removeSubcontractorService: RemoveSubcontractorGQL,
    private getSubcontractorsRelationsService: SubcontractorsRelationsGQL,
    private allowChangeService: AllowChangeGQL,
    private projectCountService: ProjectCountService,
    private getHoursService: GetHoursGQL
  ) {}

  public projectId: string;
  public workplaceId: string;

  public reports: BehaviorSubject<AttendanceReportFragment[]> =
    new BehaviorSubject([]);

  public totalHours: BehaviorSubject<number> = new BehaviorSubject(0);

  public companyData: BehaviorSubject<CompanyFragment> = new BehaviorSubject(
    {}
  );
  public users: BehaviorSubject<UserFragment[]> = new BehaviorSubject([]);

  public subcontractors: Subject<SubcontractorFragment[]> = new Subject();

  public subcontractorReportMap: {
    [orgNr: string]: BehaviorSubject<AttendanceReportFragment[]>;
  } = {};

  private subcontractorRelationsMap: { [key: string]: string } = {};
  public sumHourMap: { [orgNr: string]: string } = {};

  public showCreateAttendance = false;
  public attendanceForm: FormGroup;
  public attendanceCreateId: string;

  public showCreateSubcontractor = false;
  public subcontractorForm: FormGroup;

  public showEditAttendance = false;
  public editAttendanceForm: FormGroup;
  public editAttendanceMode: Mode;
  public editAttendanceId: string;
  public editAttendanceOrgNr: string;

  public allowAddSubcontractors: boolean;

  public ngOnInit(): void {
    this.fetchUsers();
    this.fetchCompanyData();
    this.fetchSubcontractors();

    this.fetchAllReports();
    this.fetchAllHours();
    this.fetchSubcontractorsRelations();

    this.resetEditAttendanceForm();
    this.resetAttendanceForm();
  }

  private resetAttendanceForm(): void {
    this.attendanceForm = new FormGroup({
      userId: new FormControl(null),
      startTime: new FormControl(new Date(), [Validators.required]),
      stopTime: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      personalNr: new FormControl(),
    });
  }

  public createAttendance(): void {
    this.attendanceForm.markAllAsTouched();
    if (!this.attendanceForm.valid) {
      return;
    }

    this.companyData.pipe(first()).subscribe(cd => {
      const url = '/attendanceReport/lateRegistrationHyperion';
      const params =
        cd.orgNr === this.attendanceCreateId
          ? {
              projectId: this.projectId,
              startTime: this.datePipe.transform(
                this.attendanceForm.controls.startTime.value,
                'yyyy-MM-dd HH:mm:ss'
              ),
              stopTime: this.datePipe.transform(
                this.attendanceForm.controls.stopTime.value,
                'yyyy-MM-dd HH:mm:ss'
              ),
              userType: 'user',
              userId: this.attendanceForm.controls.userId.value,
            }
          : {
              projectId: this.projectId,
              startTime: this.datePipe.transform(
                this.attendanceForm.controls.startTime.value,
                'yyyy-MM-dd HH:mm:ss'
              ),
              stopTime: this.datePipe.transform(
                this.attendanceForm.controls.stopTime.value,
                'yyyy-MM-dd HH:mm:ss'
              ),
              userType: 'freeUser',
              firstName: this.attendanceForm.controls.firstName.value,
              lastName: this.attendanceForm.controls.lastName.value,
              personalNumber: this.attendanceForm.controls.personalNr.value,
              subCompanyOrgNr: this.attendanceCreateId,
            };
      this.httpService
        .makeHttpPostRequest(url, params)
        .pipe(first())
        .subscribe((res: { msg: any; status: string }) => {
          this.messageService.insertData({
            textArray: [res.msg],
            type: res.status,
          });
          if (res.status === 'success') {
            this.fetchSubcontractors();
            this.fetchAllReports();
            this.fetchSubcontractors();
            this.fetchAllHours();

            this.resetAttendanceForm();
          }
        });
    });
  }

  public openAttendanceForm(orgNr: string = null): void {
    this.resetAttendanceForm();
    if (!orgNr) {
      this.companyData.pipe(first()).subscribe(cd => {
        this.attendanceCreateId = cd.orgNr;
        this.showCreateAttendance = true;
      });
      return;
    }
    this.attendanceCreateId = orgNr;
    this.showCreateAttendance = true;
  }

  public createSubcontractor(id: string): void {
    this.confirmationService.confirm({
      header: 'Lägg till underleverantör till projektet?',
      message:
        'Säker att du vill lägga till nya underleverantören till projektet?',
      accept: _ => {
        this.addSubcontractor(id);
      },
    });

    this.showCreateSubcontractor = false;
  }

  public removeSubcontractor(id: string): void {
    const relId = Number(this.subcontractorRelationsMap[id]);
    this.confirmationService.confirm({
      header: 'Ta bort underleverantör?',
      message: 'Säker på att du vill ta bort underleverantören från projektet?',
      accept: _ => {
        this.removeSubcontractorService
          .mutate({ id: relId })
          .pipe(first())
          .subscribe(res => {
            this.messageService.insertDataFromMutation(
              res.data.subcontractorRelationToProjectTypeHyperionMutation
            );
            if (
              res.data.subcontractorRelationToProjectTypeHyperionMutation.mutationDetails.every(
                d => d.mutationSucceeded
              )
            ) {
              this.fetchSubcontractors();
              this.fetchAllHours();
              this.fetchAllReports();
            }
          });
      },
    });
  }

  public fetchSubcontractorsRelations(): void {
    this.getSubcontractorsRelationsService
      .fetch({ projectId: Number(this.projectId) })
      .pipe(first())
      .subscribe(res => {
        res.data.project.subcontractorsRelations.edges
          .map(e => e.node)
          .forEach(rel => {
            this.subcontractorRelationsMap[rel.subcontractorId] = rel.id;
          });
      });
  }

  private fetchUsers(): void {
    this.getUsersService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const users = res.data.company.users.edges.map(e => e.node);
        this.users.next(users);
      });
  }

  private fetchSubcontractors(): void {
    this.getSubcontractorsService
      .fetch({ projectId: Number(this.projectId) })
      .pipe(first())
      .subscribe(res => {
        const subcontractors =
          res.data.project.subcontractorsRelations.edges.map(e => ({
            ...e.node.subcontractor,
            orgNr: e.node.subcontractor.orgNr.replace(/[^0-9]/g, ''),
          }));
        subcontractors.push({ id: null, orgNr: NOT_CONNECTED_STRING });
        subcontractors.forEach(s => {
          if (!this.subcontractorReportMap[s.orgNr]) {
            this.subcontractorReportMap[s.orgNr] = new BehaviorSubject([]);
          }
        });
        this.subcontractors.next(subcontractors);
      });
  }

  private fetchAllReports(): void {
    combineLatest([
      this.companyData.pipe(filter(cd => cd.orgNr !== undefined)),
      this.subcontractors.pipe(filter(s => s.length > 0)),
    ])
      .pipe(first())
      .subscribe(([companyData, subcontractors]) => {
        this.getAllAttendanceService
          .fetch({ projectId: Number(this.projectId) })
          .pipe(
            map(res =>
              res.data.projectGlobal?.attendanceReportsStopParentIsNull.edges.map(
                e => e.node
              )
            )
          )
          .subscribe(reports =>
            this.setReportData(reports, subcontractors, companyData)
          );
      });
  }

  private setReportData(
    reports: AttendanceReportFragment[],
    subcontractors: SubcontractorFragment[],
    companyData: CompanyFragment
  ): void {
    if (!reports) {
      return;
    }

    reports = reports.map(r => ({
      ...r,
      companyOrgNr: r.companyOrgNr.replace(/[^0-9]/g, ''),
    }));

    const orgNrs = subcontractors.map(sc => sc.orgNr);
    const reportMap: { [orgNr: string]: AttendanceReportFragment[] } = {};

    this.reports.next(
      reports.filter(r => r.companyOrgNr === companyData.orgNr)
    );

    orgNrs.forEach(orgNr => {
      reportMap[orgNr] = reports.filter(r => r.companyOrgNr === orgNr);
    });

    const notConnected = reports.filter(
      r => ![...orgNrs, companyData.orgNr].includes(r.companyOrgNr)
    );
    reportMap[NOT_CONNECTED_STRING] = notConnected;

    if (reportMap[NOT_CONNECTED_STRING].length === 0) {
      const newSubcontractors = subcontractors.filter(
        s => s.orgNr !== NOT_CONNECTED_STRING
      );
      this.subcontractors.next(newSubcontractors);
    }

    orgNrs.forEach(orgNr =>
      this.subcontractorReportMap[orgNr].next(reportMap[orgNr])
    );
  }

  private fetchCompanyData(): void {
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;

      this.getCompanyDataService
        .fetch({ projectId: Number(this.projectId) })
        .pipe(first())
        .subscribe(res => {
          this.projectCountService.count(Number(this.projectId));
          const companyData = cloneDeep(res.data.company);
          companyData.orgNr = companyData.orgNr.replace(/[^0-9]/g, '');
          this.companyData.next(companyData);

          this.workplaceId = res.data.project.constructionSiteNumber;
          this.allowAddSubcontractors = Boolean(
            res.data.project.allowSubcontractorToAdSubcontractors
          );
          this.showCreateAttendance = false;
        });
    });
  }

  public addSubcontractor(id: string): void {
    const url = '/attendanceReport/AddSubcontractorToProject';

    const params = {
      subcontractorId: Number(id),
      projectId: Number(this.projectId),
      parentId: 0,
      clientProject: 0,
      attendance: 1,
      mainContractorWhantsTimereports: 1,
      mainContractorWhantsToPutTodos: 1,
    };
    this.httpService
      .makeHttpPostRequest(url, params)
      .pipe(first())
      .subscribe(data => {
        this.fetchSubcontractors();
        this.fetchSubcontractorsRelations();
        this.fetchAllReports();
        this.fetchAllHours();
        this.openMailDialog(data.model);
        this.messageService.insertData(
          { textArray: [data.msg], type: data.status },
          data.success
        );
      });
  }

  private resetEditAttendanceForm(): void {
    this.editAttendanceForm = new FormGroup({
      comment: new FormControl(null, [Validators.required]),
      time: new FormControl(new Date()),
    });
  }

  public openEditAttendanceForm(id: string, orgNr: string, mode: Mode): void {
    this.resetEditAttendanceForm();
    this.editAttendanceId = id;
    this.editAttendanceOrgNr = orgNr;
    this.editAttendanceMode = mode;
    this.showEditAttendance = true;
  }

  public editAttendanceAction(): void {
    this.editAttendanceForm.markAllAsTouched();
    if (!this.editAttendanceForm.valid) {
      return;
    }
    switch (this.editAttendanceMode) {
      case Mode.edit:
        this.editAttendance();
        break;
      case Mode.close:
        this.closeAttendance();
        break;
      case Mode.cancel:
        this.cancelAttendance();
        break;
    }
  }

  private closeAttendance(): void {
    const url = '/AttendanceReport/Stop/' + this.editAttendanceId;

    const params = {
      comment: this.editAttendanceForm.controls.comment.value,
      stopTime: this.datePipe.transform(
        this.editAttendanceForm.controls.time.value,
        'yyyy-MM-dd HH:mm:ss'
      ),
    };

    this.httpService
      .makeHttpPostRequest(url, params)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertData({
          textArray: [res.msg],
          type: res.status,
        });
        if (res.status === 'success') {
          this.fetchSubcontractors();
          this.fetchAllReports();
          this.fetchAllHours();
          this.resetEditAttendanceForm();
          this.showEditAttendance = false;
        }
      });
  }
  private editAttendance(): void {
    const url = '/AttendanceReport/Update/' + this.editAttendanceId;

    const params = {
      commentAboutUpdate: this.editAttendanceForm.controls.comment.value,
      startOrStopTime: this.datePipe.transform(
        this.editAttendanceForm.controls.time.value,
        'yyyy-MM-dd HH:mm:ss'
      ),
    };

    this.httpService
      .makeHttpPostRequest(url, params)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertData({
          textArray: [res.msg],
          type: res.status,
        });
        if (res.status === 'success') {
          this.fetchSubcontractors();
          this.fetchAllReports();
          this.fetchAllHours();
          this.resetEditAttendanceForm();
          this.showEditAttendance = false;
        }
      });
  }

  private cancelAttendance(): void {
    const url = '/AttendanceReport/Cancel/' + this.editAttendanceId;

    const params = {
      commentAboutUpdate: this.editAttendanceForm.controls.comment.value,
    };

    this.httpService
      .makeHttpPostRequest(url, params)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertData({
          textArray: [res.msg],
          type: res.status,
        });
        if (res.status === 'success') {
          this.fetchSubcontractors();
          this.fetchAllReports();
          this.fetchAllHours();
          this.resetEditAttendanceForm();
          this.showEditAttendance = false;
        }
      });
  }

  public getEditHeader(): string {
    switch (this.editAttendanceMode) {
      case Mode.edit:
        return 'Updatera tid';
      case Mode.close:
        return 'Avsluta tid';
      case Mode.cancel:
        return 'Makulera tid';
    }
  }
  public showTime(): boolean {
    return this.editAttendanceMode !== Mode.cancel;
  }

  private openMailDialog(subcontractorData): void {
    this.dialogService.data = { subcontractorInfo: subcontractorData };
    this.dialogService.openComponent(AddSubcontractorMailComponent);
  }

  public allowChange(allow: boolean): void {
    this.allowChangeService
      .mutate({ projectId: Number(this.projectId), allow: Number(allow) })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectTypeHyperionMutation
        );
        this.fetchCompanyData();
      });
  }

  public allOk(): boolean {
    return this.allHavePersonalNumbers() && this.hasWorkplaceId();
  }

  public allHavePersonalNumbers(): boolean {
    return this.reports
      .getValue()
      .every(
        report => report.realUser_PreAttendanceReportTypeHyperion.personalNumber
      );
  }

  public hasWorkplaceId(): boolean {
    return this.workplaceId != null;
  }

  private fetchAllHours(): void {
    combineLatest([
      this.subcontractors.pipe(filter(s => s.length > 0)),
      this.companyData.pipe(filter(cd => cd.orgNr !== undefined)),
    ])
      .pipe(first())
      .subscribe(([subcontractors, companyData]) => {
        const orgNrs = subcontractors.map(s => s.orgNr);
        orgNrs.push(companyData.orgNr);

        orgNrs.forEach(orgNr =>
          this.getHoursService
            .fetch({
              projectId: Number(this.projectId),
              orgNr: orgNr,
            })
            .pipe(first())
            .subscribe(
              res =>
                (this.sumHourMap[orgNr] =
                  res.data.project.attendanceReportsMeta_PreProjectTypeHyperion.sumHours)
            )
        );
      });
  }

  public getGatherlist(): void {
    const url = '/attendanceReport/gatherList?projectId=' + this.projectId;
    window.open(url, '_blank');
  }
}
