import {
  Component,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
  OnInit,
  LOCALE_ID,
  Inject,
} from '@angular/core';
import { formatNumber } from '@angular/common';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';

import { FormHandlerService } from 'app/shared/forms';
import { ApolloMutationService } from 'app/shared/apollo';
import { HelperService } from 'app/shared/helpers';
import { HtmlModalService } from 'app/shared/html-modal';
import { CustomSort } from 'app/store/custom-sort';
import { SortService } from 'app/store/sort.service';

import { FunctionsData } from 'app/old-project/functions-data';
import { ProjectProductService } from './project-product.service';

import { ProductDetails } from 'app/shared/product-details/product-details';
import { ProductDetailsService } from 'app/shared/product-details/product-details.service';

@Component({
  selector: 'project-product',
  templateUrl: 'project-product.component.html',
  styleUrls: ['project-product.component.scss'],
  providers: [FormHandlerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectProductComponent implements OnChanges, OnInit {
  @Input() public dataLoading: boolean;
  @Input() public functionsData: FunctionsData;
  @Input() public isExtra = false;
  @Input() public projectInfo;
  @Input() public projectInvoiceData;
  @Input() public projectLabels;
  @Input() public showProductPrices = true;
  @Input() public state = { width: false, height: false };
  @Input() private productsFromParent;
  @Input() private projectCostTypesFromParent;
  @Input() public isMissingDeromeProductIntegration = false;

  @Output() private changeExtra = new EventEmitter();
  @Output() private moveProductToCostType = new EventEmitter();
  @Output() private callGetInvoiceData = new EventEmitter();

  @ViewChild('projectProductsTable', { static: true })
  public projectProductsTable: Table;

  public companyCostTypeDialog = {
    show: false,
    product: null,
    oldCostType: null,
    newCostType: null,
  };
  public dataSetAsync: BehaviorSubject<any> = new BehaviorSubject([]);
  public dateDialog = { show: false, product: null, oldDate: null };
  public productTableCols: any[];
  public scrollHeight = '450px';
  public sort: CustomSort = {
    attribute: 'date',
    ascDesc: -1,
    object: 'project_product_child_table',
  };
  public tableElementId;
  public tableRows = 25;

  private createdProductId = null;
  private dataModel = 'projectproduct';

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private cdr: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private htmlModalService: HtmlModalService,
    private mutationService: ApolloMutationService,
    private productDetailsService: ProductDetailsService,
    private projectProductService: ProjectProductService,
    private sortService: SortService,
    public helperService: HelperService
  ) {
    this.sort = this.sortService.getSort(this.sort);
  }

  ngOnInit() {
    this.tableElementId = this.projectProductService.getElementId(
      this.projectInfo.id,
      this.isExtra,
      'ProductTable'
    );

    this.productTableCols = this.projectProductService.getTableColumns(false);

    this.openFirstRow();
    this.cdr.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.state) {
      if (
        changes.state.previousValue &&
        changes.state.previousValue.width !== changes.state.currentValue.width
      ) {
        this.productTableCols = this.projectProductService.getTableColumns(
          this.showLargeTable
        );
      }

      this.scrollHeight =
        this.state.width || this.state.height ? '450px' : '250px';
    }

    if (changes.productsFromParent) {
      this.summarizeCostTypeTotal(this.productsFromParent);
    }
  }

  public changeSort(event) {
    return this.sortService.setSort(event, this.sort);
  }

  public rowTrackById(index: number, row: any) {
    return row.id;
  }

  /** Updates the prices with the product details API without saving them */
  public updatePrices(product) {
    if (this.productDetailsService.usesProductDetailsAPI(product)) {
      this.getProductDetails(product)
        .pipe(take(1))
        .subscribe(productDetails => {
          // NOTE: We need to explicitly assign each relevant property,
          //       as not to lose reference to the original product.
          product.listPrice =
            this.productDetailsService.toProjectProduct(
              productDetails
            ).listPrice;
          product.avtalsprisCost =
            this.productDetailsService.toProjectProduct(
              productDetails
            ).avtalsprisCost;
          product['comparativePrice'] = this.getComparativePrice(product);

          // This is just to show the user the updated preview of the totalSum
          product.totalSum = product.avtalspris * product.antal;

          this.cdr.markForCheck();
        });
    }
  }

  public triggerRemovalOfSourceData(product) {
    this.removeSourceData(product).pipe(take(1)).subscribe();
  }

  private removeSourceData(product): Observable<boolean> {
    if (!this.productDetailsService.usesProductDetailsAPI(product)) {
      return of(false);
    }

    return this.productDetailsService
      .getProductDetails(product.source, product.sourceId)
      .pipe(
        map(productDetails => {
          if (product.benamning !== productDetails.name) {
            product.source = null;
            product.sourceId = null;
            return true;
          }
          return false;
        })
      );
  }

  public onEditComplete(product, changedCostType = false) {
    if (this.productDetailsService.usesProductDetailsAPI(product)) {
      this.removeSourceData(product).subscribe(hasRemovedSourceData => {
        if (hasRemovedSourceData) {
          return this.actionUpdate(
            this.getDataToMutate(product, changedCostType)
          );
        }

        this.getProductDetails(product)
          .pipe(take(1))
          .subscribe(productDetails => {
            const extendedProduct = this.mergeProductDetailsWithProduct(
              product,
              productDetails
            );

            this.actionUpdate(
              this.getDataToMutate(extendedProduct, changedCostType)
            );
          });
      });
    } else {
      this.actionUpdate(this.getDataToMutate(product, changedCostType));
    }
  }

  public showPdf(id) {
    const urlParam = '/invoice/Print/type/showPDF/id/' + id;
    this.showPdfFromUrl(urlParam);
  }

  public showPdfFromUrl(urlParam: string) {
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }

  public usesProductDetailsDeromeAPI(product): boolean {
    return this.productDetailsService.usesProductDetailsDeromeAPI(product);
  }

  public confirmDeleteProduct(dataToMutationParam) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort produkten från projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteProduct(dataToMutationParam);
      },
      reject: () => {},
    });
  }

  public openDateDialog(product) {
    this.dateDialog.product = product;
    this.dateDialog.show = true;
    this.dateDialog.oldDate = product.date;
  }

  public dateDialogCancel(product) {
    this.dateDialog.show = false;
    product.date = this.dateDialog.oldDate;
  }

  public dateDialogConfirm(product) {
    this.dateDialog.show = false;
    this.onEditComplete(product);
  }

  public openCostTypeDialog(product) {
    const costTypes = this.dataSetAsync.value as any[];

    const costType = costTypes.find(ct => product.projectCostTypeId === ct.id);

    if (costType && costType.costTypeId) {
      this.companyCostTypeDialog.newCostType = costType.costTypeId;
      this.companyCostTypeDialog.oldCostType = costType.costTypeId;
    }

    this.companyCostTypeDialog.product = product;
    this.companyCostTypeDialog.show = true;
  }

  public costTypeDialogCancel() {
    this.companyCostTypeDialog.show = false;
  }

  public costTypeDialogConfirm(product) {
    this.companyCostTypeDialog.show = false;

    if (
      this.companyCostTypeDialog.oldCostType !==
      this.companyCostTypeDialog.newCostType
    ) {
      console.log('has changed costType model');
      product['companyCostTypeId'] = this.companyCostTypeDialog.newCostType;
    }

    this.onEditComplete(product, true);
  }

  public getFormatedDate(date) {
    return this.helperService.getFormatedDateTime(date);
  }

  public objectToArray(value: any, args: any[] = null): any {
    value = this.helperService.cleanFromNode(value);
    const dd = Object.keys(value).map(key =>
      Object.assign({ key }, value[key])
    );
    return dd;
  }

  public onProductCreation(productData: { createdProductId: string }) {
    this.createdProductId = productData.createdProductId;
    this.callGetInvoiceData.emit();
  }

  /**
   * Whether we should display the large table or not
   *
   * This getter is a helper for readability within the component without changing
   * the external interface
   *
   * TODO: Update the interface to a more meaningful variable.
   */
  public get showLargeTable(): boolean {
    return this.state.width;
  }

  public getTooltip(property: string, product): string {
    if (property === 'benamning') {
      const user = product.user
        ? `${product.user.firstName} ${product.user.lastName}`
        : `Okänd användare`;

      return `Tillagd av: ${user}, ${this.getFormatedDate(product.created)}`;
    }
  }

  private getComparativePrice(product): string {
    if (!product.listPrice || !product.listPriceUnit) {
      return '';
    }

    const currency = product.currency || 'kr';
    const listPrice: string = formatNumber(
      product.listPrice,
      this.localeId,
      '1.2-2'
    );
    return `${listPrice} ${currency} / ${product.listPriceUnit}`;
  }

  public getProductDetails(product): Observable<ProductDetails> {
    if (!this.productDetailsService.usesProductDetailsAPI(product)) {
      return of({} as ProductDetails);
    }

    return this.productDetailsService.getProductDetails(
      product.source,
      product.sourceId,
      product.antal,
      {
        projectId: this.projectInfo.id || null,
      }
    );
  }

  public showAvtalsprisCostColumn(): boolean {
    return this.projectProductService.showAvtalsprisCostColumn(
      this.showLargeTable
    );
  }

  public showJamforsprisColumn(): boolean {
    return this.projectProductService.showJamforsprisColumn();
  }

  private mergeProductDetailsWithProduct(
    product: any,
    productDetails: ProductDetails
  ) {
    const extendedProduct = {
      ...product,
      ...this.productDetailsService.toProjectProduct(productDetails),
    };

    // Force the sales price to be the one we have set
    extendedProduct.avtalspris = product.avtalspris;

    return extendedProduct;
  }

  private getDataToMutate(product, changedCostType: boolean) {
    const attributesToMutate = [
      'benamning',
      'antal',
      'avtalspris', // forsaljspris
      'avtalsprisCost', // inkopspris
      'extra',
      'enhet',
      'date',
      'extra',
      'source',
      'sourceId',
    ];

    if (changedCostType) {
      attributesToMutate.push('companyCostTypeId');
    }

    const dataToMutate = Object.fromEntries(
      attributesToMutate.map(attribute => [attribute, product[attribute]])
    );

    return {
      ...dataToMutate,
      id: +product.id,
      extra: product.extraBool ? 1 : 0,
    };
  }

  private actionUpdate(dataToMutate) {
    const refetchVars = { projectId: +this.projectInfo.id };
    const refetchArr = [
      { name: 'projectCostTypeWithProducts', variables: refetchVars },
    ];

    this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'update',
        false,
        dataToMutate,
        null,
        refetchArr
      )
      .pipe(take(1))
      .subscribe(executedData => {
        if (executedData.mutationSucceededAllArguments) {
          this.callGetInvoiceData.emit();
        }
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  private expandCostTypeByProduct(product) {
    const costTypes = this.dataSetAsync.value as any[];
    const index = costTypes.findIndex(
      costType => costType.id === product.projectCostTypeId
    );

    if (index > -1) {
      this.expandCostTypeRow({
        data: costTypes[index],
      });
      return index;
    }
  }

  private scrollToAddedProduct(product, productIndex: number) {
    const classTableBody = 'p-datatable-tbody';

    setTimeout(() => {
      const scrollDiv =
        this.projectProductsTable.containerViewChild.nativeElement.getElementsByClassName(
          classTableBody
        )[0];

      scrollDiv.scrollIntoView();
    }, 1000);
  }

  private expandCostTypeRow(event) {
    this.projectProductsTable.expandedRowKeys = {};
    const product = event.data;
    this.projectProductsTable.expandedRowKeys[product.costTypeId] = true;
  }

  private deleteProduct(product: any) {
    const dataToMutation = { id: +product.id };
    const refetchVars = { projectId: +this.projectInfo.id };
    const refetchArr = [
      { name: 'projectCostTypeWithProducts', variables: refetchVars },
    ];

    this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'delete',
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .pipe(take(1))
      .subscribe(executedData => {
        if (executedData.mutationSucceededAllArguments) {
          this.removeDeletedProductFromDataSet(product.id);
          this.callGetInvoiceData.emit();
        }
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  private removeDeletedProductFromDataSet(productId) {
    const costTypes = this.dataSetAsync.value as any[];

    for (let index = 0; index < costTypes.length; index++) {
      const costType = costTypes[index];

      const costTypeProducts = costType.childsAsync.value.filter(
        product => product.id !== productId
      );

      if (!costTypeProducts.length) {
        costTypes.splice(index, 1);
      } else {
        costType.childsAsync.next(costTypeProducts);
      }
    }

    this.dataSetAsync.next([...costTypes]);
  }

  private summarizeCostTypeTotal(costTypes: any[]) {
    /** The product to scroll to, in case it was newly created */
    let goToProduct = null;
    let goToProductIndex;

    costTypes.forEach(costType => {
      let costTypeSum = 0;

      const products = costType.childsAsync.value.map((product, index) => {
        product = {
          ...product,
          antal: +product.antal,
          avtalspris: +product.avtalspris,
          avtalsprisCost: +product.avtalsprisCost,
          classes: '',
          comparativePrice: this.getComparativePrice(product),
        };

        costTypeSum += product.antal * product.avtalspris;

        if (
          this.createdProductId !== null &&
          +this.createdProductId === +product.id
        ) {
          this.createdProductId = null;
          product.classes = 'created-row';
          goToProductIndex = index;
          goToProduct = product;
        }
        return product;
      });
      costType.childsAsync.next(products);

      costType['costTypeSum'] =
        this.projectProductService.roundNumber(costTypeSum);
    });

    this.dataSetAsync.next([...costTypes]);

    if (goToProduct !== null) {
      this.scrollToAddedProduct(goToProduct, goToProductIndex);
    }
  }

  private openFirstRow() {
    const dataSet = this.dataSetAsync.value;
    if (dataSet.length > 0 && dataSet.length < 8) {
      const firstRow = dataSet[0];
      this.projectProductsTable.expandedRowKeys[firstRow.costTypeId] = true;
    }
  }
}
