import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { HelperService } from '../../shared/helpers/index';
import { GlobalService } from '../../shared/global/index';
import { CompanyAllProjectsService } from '../../shared/company/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { HtmlModalService } from 'app/shared/html-modal';

@Component({
  selector: 'project-supplier-invoice',
  templateUrl: 'project-supplier-invoice.component.html',
  styleUrls: ['project-supplier-invoice.component.scss'],
})
export class ProjectSupplierInvoiceComponent implements OnDestroy, OnInit {
  @Input() projectInfo;
  @Input() functionsData;

  dataSet = [];
  relationshipsLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  autoCompleteResults = [];
  allProjectsLabels = [];
  supplierInvoiceSub: Subscription;
  allProjectsSub: Subscription;

  constructor(
    public helperService: HelperService,
    private globalService: GlobalService,
    private companyAllProjectsService: CompanyAllProjectsService,
    private htmlModalService: HtmlModalService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.getSupplierInvoices();
    this.getAllProjects();
  }

  getSupplierInvoices() {
    const variables = {
      id: +this.projectInfo['id'],
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectSupplierInvoices', variables)
      .subscribe(data => {
        this.supplierInvoiceSub = data.sub;
        this.dataSet = this.helperService.cleanFromNode(
          data.data['project'][
            'supplierInvociesBySource_PreProjectTypeHyperion'
          ]
        );
        this.relationshipsLoading.next(false);
      });
  }

  rowTrackBy(index: number, row: any) {
    return row.name;
  }

  getAllProjects() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAllProjects', null, 'cache-and-network')
      .subscribe(data => {
        this.allProjectsSub = data.sub;
        this.allProjectsLabels = this.companyAllProjectsService.dataFormater(
          data.data,
          'labels'
        );
      });
  }

  ngOnDestroy() {
    if (typeof this.supplierInvoiceSub !== 'undefined') {
      this.supplierInvoiceSub.unsubscribe();
    }
    if (typeof this.allProjectsSub !== 'undefined') {
      this.allProjectsSub.unsubscribe();
    }
  }

  viewAllInvoicesPDF() {
    let ids = '';
    const dataSet = this.dataSet;

    for (const index in dataSet) {
      const x = this.helperService.cleanFromNode(dataSet[index]['invoices']);
      for (const i in x) {
        ids += '|' + x[i]['id'];
      }
    }

    const urlParam =
      this.globalService.getUrlPrefix() +
      '/invoice/MassHandleHyperion?showInvoices=' +
      Math.random() +
      '&invoiceAction=' +
      ids;
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }
}
