import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { FormHandlerService } from 'app/shared/forms';
import { HelperService } from 'app/shared/helpers';
import { MessageService } from 'app/shared/message';
import { HttpService } from 'app/shared/http';

@Component({
  selector: 'edit-attendance-report-time',
  templateUrl: './edit-attendance-report-time.component.html',
})
export class EditAttendanceReportTimeComponent implements OnInit {
  mode: string;
  mutationData;
  startOrStopId;
  componentMainForm: FormGroup;
  attendanceReportInfo: any = {};
  dataModelCapitalized = 'AttendanceReport';

  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      id: '',
      commentAboutUpdate: '',
      startOrStopTime: new Date(),
    },
  };

  constructor(
    private formHandler: FormHandlerService,
    private helperService: HelperService,
    private messageService: MessageService,
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initComponentWithDialogData();
    this.setFormFieldAndInitForm();
  }

  private initComponentWithDialogData() {
    this.startOrStopId = this.dialogConfig['data']['startOrStopId'];
    this.attendanceReportInfo =
      this.dialogConfig['data']['attendanceReportInfo'];
    this.mode = this.dialogConfig['data']['mode'];
  }

  setFormFieldAndInitForm() {
    if (this.startOrStopId) {
      this.formFields.attributes.id = this.startOrStopId;
    } else {
      this.closeAction();
      this.messageService.insertData({
        textArray: ['Något gick fel, var god försök igen'],
        time: 2000,
        type: 'error',
      });
    }

    if (
      this.mode !== 'stop' &&
      this.attendanceReportInfo.hasOwnProperty('startOrStopTime')
    ) {
      const dateFromData = new Date(
        this.helperService.parseDateWithTimeSafariFriendly(
          this.attendanceReportInfo.startOrStopTime
        )
      );
      this.formFields.attributes['startOrStopTime'] = dateFromData;
    }
    this.initForm();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  crudAction() {
    if (this.mode === 'cancel') {
      this.cancelAttendanceReport();
    }
    if (this.mode === 'update') {
      this.createTimeEdit();
    }
    if (this.mode === 'stop') {
      this.stopAttendanceReport();
    }
  }

  stopAttendanceReport() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const url =
        '/AttendanceReport/Stop/' + this.componentMainForm.controls.id.value;

      const controls = this.componentMainForm.controls;
      const startOrStopTime = this.helperService.dateWithTimeFormatTimeZone(
        controls.startOrStopTime.value
      );
      const postVars = {
        comment: controls.commentAboutUpdate.value,
        stopTime: startOrStopTime,
      };

      this.httpService.makeHttpPostRequest(url, postVars).subscribe(data => {
        if (data.status === 'success') {
          this.mutationData = data.model;
          this.closeAction();
        } else {
          if (data.model) {
            this.mutationData = data.model;
          }
        }
        this.messageService.insertData({
          textArray: [data.msg],
          time: 2000,
          type: data.status,
        });
      });
    }
  }

  cancelAttendanceReport() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const url =
        '/AttendanceReport/Cancel/' + this.componentMainForm.controls.id.value;
      const controls = this.componentMainForm.controls;
      const postVars = {
        commentAboutUpdate: controls.commentAboutUpdate.value,
      };

      this.httpService.makeHttpPostRequest(url, postVars).subscribe(data => {
        if (data.status === 'success') {
          this.mutationData = {
            commentAboutUpdate: postVars.commentAboutUpdate,
          };
          this.closeAction();
        }

        this.messageService.insertData({
          textArray: [data.msg],
          time: 2000,
          type: data.status,
        });
      });
    }
  }

  createTimeEdit() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const url =
        '/AttendanceReport/Update/' + this.componentMainForm.controls.id.value;
      const controls = this.componentMainForm.controls;
      const startOrStopTime = this.helperService.dateWithTimeFormatTimeZone(
        controls.startOrStopTime.value
      );
      const postVars = {
        commentAboutUpdate: controls.commentAboutUpdate.value,
        startOrStopTime: startOrStopTime,
      };

      this.httpService.makeHttpPostRequest(url, postVars).subscribe(data => {
        if (data.status === 'success') {
          this.mutationData = data.model;
          this.closeAction();
        }

        this.messageService.insertData({
          textArray: [data.msg],
          time: 2000,
          type: data.status,
        });
      });
    }
  }

  closeAction() {
    this.dialogRef.close(this.mutationData);
  }
}
