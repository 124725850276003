import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateProjectMutationVariables = Types.Exact<{
  createProject?: Types.InputMaybe<Types.ProjectTypeHyperionMutationInput>;
  createProjectEstablishmentContact?: Types.InputMaybe<Types.ContactTypeHyperionMutationInput>;
  createProjectClientContact?: Types.InputMaybe<Types.ContactTypeHyperionMutationInput>;
}>;


export type CreateProjectMutation = { __typename?: 'HyperionSchemaMutation', projectTypeHyperionMutation?: { __typename?: 'Project', id: string, status?: number | null, trueId?: string | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const CreateProjectDocument = gql`
    mutation createProject($createProject: ProjectTypeHyperionMutationInput, $createProjectEstablishmentContact: ContactTypeHyperionMutationInput, $createProjectClientContact: ContactTypeHyperionMutationInput) {
  projectTypeHyperionMutation(
    createProject: $createProject
    createProjectEstablishmentContact: $createProjectEstablishmentContact
    createProjectClientContact: $createProjectClientContact
  ) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
    id
    status
    trueId
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }