<app-header> </app-header>

<app-seo-info
  title="Kategorier för våra redigerbara hantverksdokument"
  description="Våra kategorier av redigerbara hantverksdokument innehåller mallar för alla typer av bygg- och hantverksföretag. Testa kostnadsfritt."
>
</app-seo-info>

<div class="page-container">
  <nav class="folders-grid" *appLoaded="folders | async as loadedFolders">
    <div
      class="folder docu-folder-{{ folder.color }}"
      *ngFor="let folder of loadedFolders"
    >
      <h2>{{ folder.name }}</h2>
      <p>
        {{ folder.description }}
      </p>
      <app-spacer></app-spacer>
      <button
        pButton
        type="button"
        label="Se mallar"
        [routerLink]="folder.id"
      ></button>
    </div>
  </nav>
  <app-docu-upgrade-banner></app-docu-upgrade-banner>
</div>
