/********************************************************************

Flöde:
1: Användare inaktiv under x tid - trigga upp en modal med letning om inaktivitet
2: Inom modal klocka som tickar ned, rör på musen/klicka för att stänga
3: Klocka till 0 = stäng letnings-modal, öppna ny modal ange lösenord för att låsa upp eller logga ut
4: Efter x tid logga ut helt - router.redirect(['/login'])

PANI 160914

********************************************************************/

import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'ng-idle',
  template: '',
})
export class IdleComponent implements OnDestroy {
  @Input() latestModal;
  @Output() closeModal = new EventEmitter();
  @Output() toggleModal = new EventEmitter();

  idleSub;
  hours = 24; // antal timmar max ska leta, vad händer om max går ut?
  timeEnds = 60 * 60 * this.hours; // Ska leta i sekunder
  userTimeString;
  userTime;
  runIdleSub;
  autMode = 'showWarning';
  timer;

  constructor(private authService: AuthService, private idle: Idle) {
    // sets an idle timeout of ... seconds, for testing purposes.
    idle.setIdle(20); // Slutat göra något

    // sets a timeout period of ... seconds. after (idle + timeout) of inactivity, the user will be considered timed out.
    idle.setTimeout(this.timeEnds);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Blir inactiva startar tiden om
    idle.onIdleStart.subscribe(() => {
      this.onIdleStartLoginAutModal();
    });

    idle.onIdleEnd.subscribe(() => {
      this.onIdleEndLoginAutModal();
    });

    idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.loginAutModal(countdown);
    });

    idle.onTimeout.subscribe();
    this.watchForIdleness();
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.stopWatching();
  }

  watchForIdleness() {
    this.userTimeString = localStorage.getItem('MEsessionTime');
    this.userTime = this.userTimeString * 1 - 25; // minus 25 sek då den väntar att idla efter att 20sek har gått

    this.idle.watch();
  }

  stopWatching() {
    console.log('IDLE STOP');
    this.idle.stop();
  }

  /************************************
    AUT STARTAR
  *************************************/

  // efter X sekunder så kommer loggin popupup, får inte försvinna när man rör
  private loginAutModal(countdown: number) {
    // logik för vår aut

    let warningCountDown;
    if (this.userTime > 10 * 60) {
      warningCountDown = 300;
    } else {
      warningCountDown = 20;
    }

    const theInitiateTimeForWarningAutModule: number =
      this.timeEnds - this.userTime + warningCountDown;
    const theInitiateTimeForAutModule: number = this.timeEnds - this.userTime;

    // warnignsruta kommer upp,
    if (
      theInitiateTimeForWarningAutModule === countdown &&
      this.autMode === 'showWarning'
    ) {
      // När inte komma upp? om inte vi håller på att logga in i igen = vi vet att  lockedScreen körs
      this.toggleModalEmit('timeout', 'open');

      this.autMode = 'pendingShowLogin';
    } else if (
      theInitiateTimeForAutModule === countdown &&
      this.autMode === 'pendingShowLogin'
    ) {
      // warningsruta  försvinner när man rör
      // Finns modal, finns den specifika, dvs warning, om den gör det så ta bort den

      if (this.latestModal.active && this.latestModal.name === 'timeout') {
        this.toggleModalEmit('timeout', 'close');
      }

      // Öppnar login modal
      if (!this.latestModal.active) {
        this.stopWatching();
        this.toggleModalEmit('locked', 'open');
        this.autMode = 'showLogin';
      }
    }
  }

  onIdleStartLoginAutModal() {
    if (this.autMode === 'showLogin') {
      if (
        this.latestModal.name === 'lockedScreen' &&
        !this.latestModal.active
      ) {
        this.autMode = 'showWarning';
      }
    } else {
      this.autMode = 'showWarning';
    }
  }

  // Finns warning? Om den gör det så ta bort den
  onIdleEndLoginAutModal() {
    if (this.autMode === 'pendingShowLogin') {
      this.keepServerSessionAlive();

      if (this.latestModal.active) {
        this.closeModal.emit();
      }
    }
  }

  private keepServerSessionAlive() {
    this.authService.checkStatus().subscribe();
  }

  toggleModalEmit(nameParam, actionParam) {
    const event = { name: nameParam, action: actionParam };
    this.toggleModal.emit(event);
  }
}
