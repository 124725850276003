import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApolloMutationService } from '../shared/apollo/index';

@Injectable({
  providedIn: 'root',
})
export class PurchaseTransactionService {
  constructor(private mutationService: ApolloMutationService) {}

  mutateOrder(variables): Observable<void> {
    return this.mutationService
      .staticConstructQueryAndExecuteMutation(
        'updateOrderMutation',
        variables,
        'ediOrder'
      )
      .pipe(
        map(
          data => {
            this.mutationService.displayMutationStatus(data);
            return data;
          },
          err => {
            console.warn(err);
            return err;
          }
        )
      );
  }
}
