import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { HttpService } from '../../shared/http/index';
import { MessageService } from '../../shared/message/index';
import { HelperService } from '../../shared/helpers/index';
import {
  CompanyUsersService,
  UserLocalStorageService,
} from 'app/shared/user/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { MeUser } from 'app/shared/user/me-user';

@Component({
  selector: 'day-advanced',
  templateUrl: 'day-export.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HttpService],
})
export class DayExportComponent implements OnDestroy, OnInit {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public meUser: MeUser;
  advancedVariables = {
    displayDays: 1,
    displayType: 'perUser',
    checkedPeriodType: 'Week',
    checkedPeriodYearValue: null,
    checkedPeriodTypeValue: null,
  };

  dates = [];
  dataSet = [];
  usersDropdown;
  updateDays = [];
  dataModel = 'day';
  yearsDropdown = [];
  weeksDropdown = [];
  showDaysForUsers = [];
  normalDate = new Date();
  usersSub: Subscription;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  beforeFirst: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isDayFormVisible: boolean;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
    private mutationService: ApolloMutationService,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private userService: CompanyUsersService,
    private apolloQueryService: ApolloQueryService,
    private userLocalStorageService: UserLocalStorageService,
    private confirmationService: ConfirmationService
  ) {
    this.advancedVariables['checkedPeriodTypeValue'] =
      this.helperService.getWeekNumber(new Date());
    this.advancedVariables['checkedPeriodYearValue'] =
      this.helperService.getCurrentYear();
    this.yearsDropdown = this.makeYearsOptions();
    this.weeksDropdown = this.makeWeekOptions(
      this.helperService.getCurrentYear()
    );
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  public markActionItems: MenuItem[] = [
    {
      label: 'Markera alla',
      command: () => this.mark('markAll'),
    },
  ];

  ngOnInit() {
    this.getUsers();
    this.setUsers();
  }

  changeUsers() {
    const model = this.advancedVariables;

    if (this.showDaysForUsers.length > 0) {
      model['users'] = {};
      for (const index in this.showDaysForUsers) {
        const userObject = this.showDaysForUsers[index];
        const userKey = '' + userObject;
        model['users'][userKey] = 'on';
      }
    } else {
      delete model['users'];
    }

    this.advancedVariables = model;
  }

  mark(task) {
    const toBeMarked = [];
    let toBeMarkedContainsExported = false;
    for (const userIndex in this.dataSet) {
      const userObject = this.dataSet[userIndex];
      for (const projectIndex in userObject['projects']) {
        const projectDates = userObject['projects'][projectIndex]['dates'];
        for (const dateIndex in projectDates) {
          const dateObject = projectDates[dateIndex];

          if (dateObject.hasOwnProperty('days')) {
            for (const dayIndex in dateObject['days']) {
              const day =
                this.dataSet[userIndex]['projects'][projectIndex]['dates'][
                  dateIndex
                ]['days'][dayIndex];

              if (task === 'markNotExported' && day['isExported']) {
                continue;
              }

              if (task === 'unmark') {
                day['isMarkedForExport'] = false;
              } else {
                toBeMarked.push(day);
                if (day['isExported']) {
                  toBeMarkedContainsExported = true;
                }
              }
            }
          }
        }
      }
    }

    const markDays = () => {
      for (const day of toBeMarked) {
        day['isMarkedForExport'] = true;
      }
    };

    if (!toBeMarkedContainsExported) {
      markDays();
      return;
    }

    this.confirmationService.confirm({
      message:
        'Minst en av tiderna som du vill markera har redan exporterats. Om du väljer att exportera denna tid igen så kommer den tidigare exporterade posten i Fortnox kalendarium att raderas. Om den tidrapportern redan har lönekörts kommer detta att resultera i en diff på er nästa lönekörning. Vill du markera tiderna för export?',
      header: 'Bekräfta markering av redan exporterade tidrapporter',
      accept: () => {
        markDays();
        this.cdr.detectChanges();
      },
      reject: () => {},
    });
  }

  changeWeek(directionParam) {
    let weekModel = this.advancedVariables['checkedPeriodTypeValue'];

    if (directionParam === 'down') {
      weekModel =
        weekModel > this.weeksDropdown[0]['value'] ? weekModel - 1 : weekModel;
    } else {
      weekModel =
        weekModel < this.weeksDropdown[this.weeksDropdown.length - 1]['value']
          ? weekModel + 1
          : weekModel;
    }

    setTimeout(() => {
      this.advancedVariables['checkedPeriodTypeValue'] = weekModel;
      this.cdr.detectChanges();
    }, 0);
  }

  changeYear() {
    this.weeksDropdown = this.makeWeekOptions(
      this.advancedVariables['checkedPeriodYearValue']
    );
  }

  makeYearsOptions() {
    const start = 2014;
    const end = this.helperService.getCurrentYear() + 1;
    const options = [];

    for (let i = start; i <= end; i++) {
      const yearObject = {
        label: '' + i,
        value: i,
      };
      options.push(yearObject);
    }

    return options;
  }

  makeWeekOptions(year) {
    const start = 1;
    const end = this.helperService.weeksInYear(year);
    const options = [];

    for (let i = start; i <= end; i++) {
      const weekObject = {
        label: '' + i,
        value: i,
      };
      options.push(weekObject);
    }

    return options;
  }

  setUsers() {
    const newVariables = this.advancedVariables;

    if (this.meUser && this.meUser.type !== '3') {
      const userId = this.meUser.id;
      newVariables['users'] = {};
      newVariables['users'][userId] = 'on';
    }
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.userService.makeLabelsArray(data);
        this.showDaysForUsers = [];
      });
  }

  getDays() {
    this.loading.next(true);

    this.httpService
      .makeHttpPostRequest('/day/AdvancedHyperion', this.advancedVariables)
      .subscribe(
        data => {
          this.handleDataResponse(data);
          this.loading.next(false);
          this.beforeFirst.next(false);
        },
        onerror => {
          this.loading.next(false);
          this.beforeFirst.next(false);
        }
      );
  }

  handleDataResponse(dataParam) {
    const status = dataParam['status'];
    const msg = dataParam['msg'];
    const dataSet = dataParam['return'];

    if (status === 'success') {
      this.dates = dataSet['dates'];
      this.dataSet = this.formatData(dataSet['data']);
    } else {
      this.messageService.insertData({ textArray: [msg], type: status });
    }
  }

  formatData(data) {
    const formatedData = data;
    for (const userIndex in formatedData) {
      const userData = formatedData[userIndex];
      userData['projectsArray'] = this.makeProjectsArray(userData['projects']);
    }
    return formatedData;
  }

  returnHasOwnPropertyDay(projectDate) {
    const hasDay = projectDate.hasOwnProperty('days');
    return hasDay;
  }

  makeProjectsArray(projectsObject) {
    const projectsArray = [];

    for (const key in projectsObject) {
      if (key !== 'dates') {
        const project = projectsObject[key];

        const projectParam = project;

        let formatedProject;
        const dates = projectParam['dates'];

        for (const dateIndex in dates) {
          const dateObject = dates[dateIndex];
          if (dateObject.hasOwnProperty('days')) {
            for (const dayIndex in dateObject['days']) {
              const dayObject = dateObject['days'][dayIndex];
              dayObject['isExported'] =
                dayObject['day']['exportedByUserId'] > 0;
              dayObject['isNotarized'] = dayObject['day']['notarized'] > 0;
              dayObject['touched'] = false;
              dayObject['isMarkedForExport'] = false;
              dayObject['error'] = { status: true, msgArray: [] };
            }
          }
        }

        formatedProject = projectParam;

        projectsArray.push(formatedProject);
      }
    }

    return projectsArray;
  }

  sendReports() {
    this.loading.next(true);
    this.updateDays = [];

    for (const userIndex in this.dataSet) {
      const userObject = this.dataSet[userIndex];
      for (const projectIndex in userObject['projects']) {
        const projectDates = userObject['projects'][projectIndex]['dates'];
        for (const dateIndex in projectDates) {
          const dateObject = projectDates[dateIndex];

          if (dateObject.hasOwnProperty('days')) {
            for (const dayIndex in dateObject['days']) {
              const dayObject = dateObject['days'][dayIndex];
              dayObject['error'] = { status: true, msgArray: [] };

              if (!dayObject['isMarkedForExport']) {
                continue;
              }

              dayObject['day']['exportedByUserId'] = +this.meUser.id || null;
              this.updateDays.push(dayObject);
            }
          }
        }
      }
    }
    this.daysMutat1();
  }

  daysMutat1() {
    const createDaySub = this.daysMutat().subscribe(data => {
      this.loading.next(false);

      const errors = [];
      for (const dayIndex in this.updateDays) {
        const day = this.updateDays[dayIndex];
        const mutationDetails = data['mutationDetails'][dayIndex] ?? null;

        if (!mutationDetails) {
          continue;
        }

        const errorMessages = mutationDetails?.errorsMsgs ?? [];
        for (const error of errorMessages) {
          if (!errors.includes(error)) {
            errors.push(error);
          }
        }

        if (errorMessages.length > 0) {
          day['error'] = {
            status: false,
            msgArray: errorMessages,
            msg: errorMessages.join('. '),
          };
          continue;
        }

        day['isExported'] = true;
        day['isNotarized'] = true;
        day['isMarkedForExport'] = false;
        day['error'] = {
          status: true,
          msgArray: {},
          msg: mutationDetails['msg'],
        };
      }

      this.cdr.detectChanges();

      if (errors.length > 0) {
        const errorMessage =
          'Markerade tidrapporter kunde inte exporteras. Kontrollera felen nedan:';
        this.messageService.insertData({
          textArray: [errorMessage, ...errors],
          type: 'error',
        });
      } else {
        this.messageService.insertData({
          textArray: ['Tidrapporter exporterades'],
          type: 'success',
        });
      }
    });
  }

  daysMutat() {
    const x = [];
    for (const dayIndex in this.updateDays) {
      const y = this.updateDays[dayIndex]['day'];
      x.push({ id: +y['id'], exportedByUserId: +y['exportedByUserId'] });
    }

    const variables = { updateDayMulti: x };

    return Observable.create(observer => {
      const dayMutationObs = this.mutationService
        .staticConstructQueryAndExecuteMutation(
          'createDayPartial',
          variables,
          this.dataModel
        )
        .subscribe(data => {
          observer.next(data);
          observer.complete();
          dayMutationObs.unsubscribe();
        });
    });
  }

  public onMark($event, day): void {
    day.touched = true;

    if (day.isMarkedForExport && day.isExported) {
      this.confirmationService.confirm({
        message:
          'Om du väljer att exportera denna tid igen så kommer den tidigare exporterade posten i Fortnox kalendarium att raderas. Om den tidrapportern redan har lönekörts kommer detta att resultera i en diff på er nästa lönekörning. Vill du markera tiden för export?',
        header: 'Bekräfta markering av redan exporterad tidrapport',
        accept: () => {},
        reject: () => {
          day.isMarkedForExport = false;
          this.cdr.detectChanges();
        },
      });
    }
  }

  printTable() {
    window.print();
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.usersSub && this.usersSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
