import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { RadioButtonModule } from 'primeng/radiobutton';

import { PlannerReadService } from './services/planner-read.service';
import { PlannerDataAdapterService } from './services/planner-data-adapter.service';

import { PlannerComponent } from './planner.component';
import { SchedulerViewComponent } from './scheduler/scheduler-view/scheduler-view.component';
import { EditPlannedTimeViewComponent } from './dialogs/edit-planned-time-view/edit-planned-time-view.component';
import { ColorPickerComponent } from './utils/color-picker/color-picker.component';
import { EditProjectViewComponent } from './dialogs/edit-project-view/edit-project-view.component';
import { GanttViewComponent } from './gantt/gantt-view/gantt-view.component';
import { ColorContextMenuComponent } from './utils/color-context-menu/color-context-menu.component';
import { SchedulerSideMenuComponent } from './scheduler/side-menu/scheduler-side-menu.component';
import { EditWorkTaskViewComponent } from './dialogs/edit-work-task-view/edit-work-task-view.component';
import { ResourceViewComponent } from './resource/resource-view/resource-view.component';
import { ExportPdfComponent } from './dialogs/export-pdf/export-pdf.component';
import { HeaderModule } from 'app/header/header.module';
import { CardModule } from 'primeng/card';
import { TreeSelectModule } from 'primeng/treeselect';
import { SidebarModule } from 'primeng/sidebar';

@NgModule({
  declarations: [
    PlannerComponent,
    SchedulerViewComponent,
    SchedulerSideMenuComponent,
    EditPlannedTimeViewComponent,
    EditProjectViewComponent,
    ColorPickerComponent,
    ColorContextMenuComponent,
    GanttViewComponent,
    EditWorkTaskViewComponent,
    ResourceViewComponent,
    ExportPdfComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    CalendarModule,
    MultiSelectModule,
    RouterModule,
    ConfirmDialogModule,
    DialogModule,
    SelectButtonModule,
    ToggleButtonModule,
    CheckboxModule,
    TooltipModule,
    HeaderModule,
    RadioButtonModule,
    CardModule,
    TreeSelectModule,
    SidebarModule,
  ],
  providers: [PlannerReadService, PlannerDataAdapterService],
})
export class PlannerModule {}
