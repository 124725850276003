<div style="overflow-x: auto">
  <p-table
    *ngIf="type === CostCalculationMode.ByUser"
    class="inline-table"
    [value]="costs?.byUser.users"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 12rem">Namn</th>
        <th style="width: 8rem">Lön</th>
        <th style="width: 8rem">Helglön</th>
        <th style="width: 6rem">Sem. ers.</th>
        <th style="width: 8rem">Arb.giv. avgift</th>
        <th style="width: 6rem">Socialförs.</th>
        <th style="width: 9rem" *ngIf="showSubsistenceOnTimereport">
          Trak. halvdag
        </th>
        <th style="width: 9rem" *ngIf="showSubsistenceOnTimereport">
          Trak. heldag
        </th>
        <th style="width: 9rem" *ngIf="showSubsistenceOnTimereport">
          Trak. natt
        </th>
        <th style="width: 10rem" *ngIf="setMile">Milersättning</th>
        <th style="width: 8rem">Summa</th>
      </tr>
    </ng-template>
    <!--      Finns det ett bättre sätt?         -->
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-600)">{{
            rowData.name
          }}</span>
          <span class="p-d-block" style="color: var(--primary-color-400)"
            >{{ rowData.userType }}
            {{ rowData.contractor ? ', inhyrd' : '' }}</span
          >
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.salaryHours }}h *
            {{ rowData.salaryCost | currency: 'SEK' }}/h
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.salaryTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.weekendHours }}h *
            {{ rowData.weekendCost | currency: 'SEK' }}/h
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.weekendTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.vacationPercentage }}%
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.vacationTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.employerFeePercentage }}%
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.employerFeeTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.socialInsurancePercentage }}%
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.socialInsuranceTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td *ngIf="showSubsistenceOnTimereport">
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.allowanceHalfDays }} st *
            {{ rowData.allowanceHalfDayCost | currency: 'SEK' }}
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.allowanceHalfDayTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td *ngIf="showSubsistenceOnTimereport">
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.allowanceDays }} st *
            {{ rowData.allowanceDayCost | currency: 'SEK' }}
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.allowanceDayTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td *ngIf="showSubsistenceOnTimereport">
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.allowanceNights }} st *
            {{ rowData.allowanceNightCost | currency: 'SEK' }}
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.allowanceNightTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td *ngIf="setMile">
          <span class="p-d-block" style="color: var(--primary-color-400)">
            {{ rowData.miles }} mil *
            {{ rowData.mileCost | currency: 'SEK' }}/mil
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.milesTotalCost | currency: 'SEK' }}
          </span>
        </td>
        <td>
          <span class="p-d-block" style="color: var(--primary-color-400)"
            ><p></p>
          </span>
          <span class="p-d-block" style="color: var(--primary-color-600)"
            >{{ rowData.totalCost | currency: 'SEK' }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div class="p-grid" *ngIf="type === CostCalculationMode.ByCostType">
  <div class="p-col-12 p-lg-6">
    <label>Grupperat på anställd</label>
    <p-table
      class="inline-table"
      [value]="costs.byLaborType.users"
      [dataKey]="'id'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Anställd</th>
          <th>Timmar</th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <a [pRowToggler]="rowData">
              {{ rowData.name }}
            </a>
          </td>
          <td>{{ rowData.hours }}h</td>
          <td>{{ rowData.cost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data>
        <tr *ngFor="let type of data.types">
          <td>{{ type.name }}</td>
          <td>{{ type.hours }}h</td>
          <td>{{ type.cost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12 p-lg-6">
    <label>Grupperat på yrkestyp</label>
    <p-table
      class="inline-table"
      [value]="costs.byLaborType.types"
      [dataKey]="'id'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Yrkestyp</th>
          <th>Timmar</th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <a [pRowToggler]="rowData">
              {{ rowData.name }}
            </a>
          </td>
          <td>{{ rowData.hours }}h</td>
          <td>{{ rowData.total | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data>
        <tr *ngFor="let user of data.users">
          <td>{{ user.name }}</td>
          <td>{{ user.hours }}h</td>
          <td>{{ user.cost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
