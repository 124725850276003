import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApolloQueryService, ApolloMutationService } from 'app/shared/apollo';
import { CustomSort } from './custom-sort';
import { UserLocalStorageService } from 'app/shared/user';

@Injectable()
export class SortService {
  private dataModel = 'userGuiSorting';

  private sortObjects: {
    [key: string]: CustomSort;
  } = {};

  constructor(
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private userLocalStorageService: UserLocalStorageService
  ) {}

  public getSortStateFromServer(): Observable<any> {
    return Observable.create(observer => {
      this.apolloQueryService
        .apolloQuery(this.dataModel)
        .subscribe(({ data }) => {
          if (!data.me) {
            observer.error('Gick inte att hämta användarinformation.');

            return;
          }

          this.formatState(data.me.userGuiSortings.edges);

          observer.next('done');
          observer.complete();
        });
    });
  }

  private formatState(
    edges: { node: CustomSort & { __typename?: string } }[]
  ): void {
    edges.forEach(value => {
      const sortObject = { ...value.node };
      delete sortObject.__typename;

      this.sortObjects[sortObject.object] = sortObject;
    });
  }

  public getSort(sortObject: CustomSort): CustomSort {
    return this.sortObjects[sortObject.object] || { ...sortObject };
  }

  public setSort(
    event: {
      field: string;
      order: number;
    },
    sortObject: CustomSort
  ): CustomSort {
    const objectName = sortObject.object;
    let save = true;

    if (
      this.sortObjects[objectName] &&
      this.sortObjects[objectName].attribute === event.field &&
      this.sortObjects[objectName].ascDesc === event.order
    ) {
      save = false;
    }

    this.sortObjects[objectName] = {
      ...sortObject,
      ascDesc: event.order,
      attribute: event.field,
    };

    if (save) {
      this.saveSort(this.sortObjects[objectName]);
    }

    return this.sortObjects[objectName];
  }

  private saveSort(sortObject: CustomSort): void {
    const dataForMutation: CustomSort = { ...sortObject };

    if (!dataForMutation.id) {
      const userId = this.userLocalStorageService.getMEUser().id;
      if (userId) {
        dataForMutation['userId'] = userId;
        this.actionCreate(dataForMutation);
      }
    } else {
      this.actionUpdate(dataForMutation);
    }
  }

  private actionUpdate(dataToMutation): void {
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'update',
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          executeMutationSub.unsubscribe();
        },
        err => {
          console.error(err);
        }
      );
  }

  private actionCreate(dataToMutation): void {
    const returnParams = ['id'];

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'create',
        false,
        dataToMutation,
        returnParams
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments === 0) {
            this.sortObjects[dataToMutation.object] = {
              ...dataToMutation,
              id: executedData.id,
            };
          }

          executeMutationSub.unsubscribe();
        },
        err => {
          console.error(err);
        }
      );
  }
}
