import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { MessageService } from 'app/shared/message/index';
import { GlobalService } from 'app/shared/global/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { FormHandlerService } from 'app/shared/forms/index';
import { HelperService } from 'app/shared/helpers';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';

@Component({
  selector: 'intro-company-form',
  templateUrl: 'intro-company-form.component.html',
  providers: [FormHandlerService],
})
export class IntroCompanyFormComponent implements OnDestroy, OnInit {
  loading = true;
  dataModel = 'company';
  dataModelCapitalized = 'Company';
  chosenSolution: string;
  state = 'chooseSolution';
  formSettings;
  buttons;

  meUser: MeUserWithCompany;
  appParams = {
    supportMail: null,
    supportPhone: null,
    name: null,
    environment: null,
  };
  goToNextState = {
    company: false,
    user: false,
  };
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {},
    attrToExcludeFromRulesAndLabel: ['repeat_password', 'password'],
  };
  componentMainForm: FormGroup;
  sub: Subscription;

  constructor(
    protected globalService: GlobalService,
    protected htmlModalService: HtmlModalService,
    protected helperService: HelperService,
    protected formBuilder: FormBuilder,
    protected mutationService: ApolloMutationService,
    protected messageService: MessageService,
    protected apolloQueryService: ApolloQueryService,
    protected formHandler: FormHandlerService,
    private dialogRef: DynamicDialogRef,
    private userLocalStorageService: UserLocalStorageService
  ) {}

  ngOnInit() {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.setAppParams();

    const buttonObject = {
      update: { model: this.dataModel },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.getCompanySettingAndInitForm();
  }

  private getCompanySettingAndInitForm() {
    this.sub = this.apolloQueryService
      .apolloQuery('companyAllSettings')
      .subscribe(({ data }) => {
        this.formFields.attributes = this.setFormAtributes(data);
        this.initForm();
        this.loading = false;
      });
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['password'].setValidators([
          Validators.minLength(1),
        ]);
        this.componentMainForm.controls['repeat_password'].setValidators([
          Validators.minLength(1),
        ]);
      });

    this.componentMainForm.controls['password']['label'] = 'Ditt lösenord*';
    this.componentMainForm.controls['repeat_password']['label'] =
      'Upprepa lösenord*';
  }

  setAppParams() {
    this.appParams['supportMail'] = localStorage.getItem('appsupportMail');
    this.appParams['supportPhone'] = localStorage.getItem('appsupportPhone');
    this.appParams['name'] = localStorage.getItem('appname');
    this.appParams['environment'] = localStorage.getItem(
      'appgetenvStruqturMode'
    );
  }

  goForward() {
    if (this.state === 'chooseSolution') {
      this.state = 'companyForm';
    } else if (this.state === 'companyForm') {
      this.submitAction();
    }
  }

  goBack() {
    this.state = 'chooseSolution';
  }

  setFormAtributes(data) {
    const fieldsToSet = [
      'name',
      'orgNr',
      'phone',
      'address',
      'cityCode',
      'city',
    ];
    const attributes = {};
    const dataObject = data['company'];

    fieldsToSet.forEach(key => {
      attributes[key] = dataObject[key];
    });
    attributes['password'] = '';
    attributes['repeat_password'] = '';

    return attributes;
  }

  actionUpdateSettingsDone() {
    const crudType = 'update';
    const dataToMutation = { startupSettingsAreDone: 1 };
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      // If errors in client

      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          ['id', 'startupSettingsAreDone']
        )
        .subscribe(
          executedData => {
            if (executedData.mutationSucceededAllArguments) {
              localStorage.setItem('MEcompanyStartupSettingsAreDone', '1');
              this.state = 'finalScreen';
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.componentMainForm.value;

    if (typeof this.chosenSolution === 'undefined') {
      this.setChosenSolution('ProjectComplete');
    }
    if (this.appParams['environment'] === 'beijerbygg') {
      this.setChosenSolution('Pl');
    }

    dataToMutation['registeredForProduct'] = this.chosenSolution;

    delete dataToMutation['password'];
    delete dataToMutation['repeat_password'];

    this.buttons = this.formHandler.lockButtons(this.buttons);

    const passwordLength =
      this.componentMainForm.controls['password']['value'].length;
    const repeatPasswordLength =
      this.componentMainForm.controls['repeat_password']['value'].length;

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else if (passwordLength !== 0 && repeatPasswordLength !== 0) {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            if (executedData.mutationSucceededAllArguments) {
              this.afterUpdate('company');
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.log(err);
          }
        );
    }
  }

  submitAction() {
    this.actionUpdate();
    this.updateUser();
  }

  afterUpdate(modelWithSuccess) {
    this.goToNextState[modelWithSuccess] = true;

    let goToNextState = false;
    for (const index in this.goToNextState) {
      const stateModel = this.goToNextState[index];

      if (stateModel === true) {
        goToNextState = true;
      } else {
        goToNextState = false;
        break;
      }
    }

    if (goToNextState) {
      this.actionUpdateSettingsDone();
      localStorage.setItem('MEcompanyStartupSettingsAreDone', '1');
    }
  }

  updateUser() {
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.meUser['id']),
      password: this.componentMainForm.controls['password']['value'],
      repeat_password:
        this.componentMainForm.controls['repeat_password']['value'],
    };

    this.buttons = this.formHandler.lockButtons(this.buttons);

    const passwordLength =
      this.componentMainForm.controls['password']['value'].length;
    const repeatPasswordLength =
      this.componentMainForm.controls['repeat_password']['value'].length;

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.messageService.insertData({
        textArray: ['Inställningarna uppdaterades inte'],
        type: 'error',
      });

      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else if (passwordLength === 0 || repeatPasswordLength === 0) {
      this.formHandler.setErrorFlag(false, this.componentMainForm, 'submitted');

      this.messageService.insertData({
        textArray: ['Måste ange lösenord'],
        type: 'error',
      });

      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'user',
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            if (executedData.mutationSucceededAllArguments) {
              this.afterUpdate('user');
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.log(err);
          }
        );
    }
  }

  setChosenSolution(value) {
    this.chosenSolution = value;
  }

  closeAction() {
    this.dialogRef.close(this.chosenSolution);
  }
}
