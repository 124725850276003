import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { FormHandlerService } from 'app/shared/forms/index';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';

@Component({
  selector: '[app-offer-todo-row]',
  templateUrl: './offer-todo-row.component.html',
})
export class OfferTodoRowComponent implements OnDestroy, OnInit {
  @Input() offerInfo;
  @Input() dataObjectFromParent;
  @Input() labels;
  @Input() topicDropdown = [];
  @Input() taxDropdown = [];
  @Input() greenTaxDropdownOptions: SelectItem[];
  @Input() bigColumn;
  @Input() isGreenTaxReduction: boolean;
  @Output() spliceObjectNotYetCreated = new EventEmitter();
  @Output() changeBigColumn = new EventEmitter();
  @Output() deletedObject = new EventEmitter();
  @Output() createdObject = new EventEmitter();
  @Output() addRowIfNoEmpty = new EventEmitter();

  public buttons: ButtonsAsync;
  public rotBool = false;
  public units = [...QuantityUnits];
  public componentMainForm: FormGroup;
  public keyUp = new Subject<string>();

  private buttonObject = ['delete'];
  private isCreating = false;
  private dataModel = 'todo';
  private dataModelCapitalized = 'Todo';
  private formFields = {
    model: this.dataModelCapitalized,
  };
  private formValueChgSub: Subscription;
  private oldFormValueState = '';

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.buttons = this.formHandler.getButtonValuesAsync(this.buttonObject);
    this.initForm();
    this.setCheckbox();
  }

  private setCheckbox() {
    this.dataObjectFromParent['rot'] > 0
      ? (this.rotBool = true)
      : (this.rotBool = false);
  }

  public setRotBool(event) {
    this.componentMainForm['controls']['rot'].setValue(+event.checked);
  }

  ngOnDestroy() {
    this.formValueChgSub && this.formValueChgSub.unsubscribe();
  }

  private subscribeToInput() {
    this.formValueChgSub = this.componentMainForm.valueChanges
      .pipe(
        tap(() => {
          this.calcRowSum();
          this.updateParentObject();
        }),
        debounceTime(500)
      )
      .subscribe(offerTodoRowFormValueChanges => {
        const currentFormValue: string = JSON.stringify(
          offerTodoRowFormValueChanges
        );
        if (this.oldFormValueState !== currentFormValue) {
          this.handleInput();
          this.addRowIfNoEmpty.emit();
        }
        this.oldFormValueState = currentFormValue;
      });
  }

  private updateParentObject() {
    this.dataObjectFromParent.descriptionOffer =
      this.componentMainForm['controls']['descriptionOffer']['value'];
    this.dataObjectFromParent.description =
      this.componentMainForm['controls']['description']['value'];
    this.dataObjectFromParent.orderNr =
      this.componentMainForm['controls']['orderNr']['value'];
    this.dataObjectFromParent.quantity =
      +this.componentMainForm['controls']['quantity']['value'];
    this.dataObjectFromParent.tax =
      this.componentMainForm['controls']['tax']['value'];
    this.dataObjectFromParent.type =
      this.componentMainForm['controls']['type']['value'];
    this.dataObjectFromParent.rot =
      this.componentMainForm['controls']['rot']['value'];
    this.dataObjectFromParent.unitPrice =
      +this.componentMainForm['controls']['unitPrice']['value'];
    this.dataObjectFromParent.unit =
      this.componentMainForm['controls']['unit']['value'];
    this.dataObjectFromParent.cost =
      this.componentMainForm['controls']['cost']['value'];
    this.dataObjectFromParent.greenTaxReduction =
      this.componentMainForm.controls.greenTaxReduction.value;
  }

  private initForm() {
    this.formFields['attributes'] = { ...this.dataObjectFromParent };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
    this.subscribeToInput();
  }

  private handleInput() {
    if (!this.isCreating) {
      const id = this.componentMainForm['controls']['id']['value'];

      if (id === null) {
        this.actionCreate();
      } else {
        this.actionUpdate();
      }
    }
  }

  private actionCreate() {
    this.isCreating = true;
    const crudType = 'create';

    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    dataToMutation['offerId'] = Number(this.offerInfo['id']);
    delete dataToMutation['id'];
    this.formHandler.lockButtonsAsync(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.unlockButtonsAsync(this.buttons);
      this.isCreating = false;
    } else {
      const returnKeys = Object.keys(this.componentMainForm.value);
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnKeys
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.handleCreatedObject(executedData);
            } else {
              this.mutationService.displayMutationStatus(executedData);
            }
            this.formHandler.unlockButtonsAsync(this.buttons);

            executeMutationSub.unsubscribe();

            this.isCreating = false;
          },
          err => {
            this.isCreating = false;
            this.formHandler.unlockButtonsAsync(this.buttons);
            console.log(err);
          }
        );
    }
    // låsas upp här
  }

  private handleCreatedObject(executedData) {
    this.componentMainForm['controls']['id'].setValue(executedData['id']);
    const createdObject = executedData;
    delete createdObject['mutationDetails'];
    delete createdObject['__typename'];
    this.createdObject.emit(createdObject);
  }

  private actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );

    if (dataToMutation['id'] > 0) {
      this.formHandler.lockButtonsAsync(this.buttons);
      if (!this.formHandler.formValid([this.componentMainForm])) {
        // If errors in client
        this.formHandler.setErrorFlag(
          false,
          this.componentMainForm,
          'submitted'
        ); // Show errors (param "submitted" marks all fields as touched)
        this.formHandler.unlockButtonsAsync(this.buttons);
      } else {
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            this.dataModel,
            crudType,
            false,
            dataToMutation
          )
          .subscribe(
            executedData => {
              this.formHandler.showServerErrorsOnAttributes(executedData, [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ]);
              if (!executedData.mutationSucceededAllArguments) {
                this.mutationService.displayMutationStatus(executedData);
              }
              this.formHandler.unlockButtonsAsync(this.buttons);

              executeMutationSub.unsubscribe();
            },
            err => {
              console.log(err);
              this.formHandler.unlockButtonsAsync(this.buttons);
            }
          );
      }
    }
  }

  private formatNumberToRealFloat(val): number {
    if (val === '' || val === null) {
      return 0;
    } else {
      val = val.toString();
      val = val.replace(/ /gi, '');
      val = val.replace(',', '.');
      val = val.replace(' ', '');

      if (val % 1 === 0) {
        return parseInt(val, 10);
      } else {
        return parseFloat(val);
      }
    }
  }

  private calcRowSum() {
    const todoRowQuantity: number = this.formatNumberToRealFloat(
      this.componentMainForm.controls.quantity.value
    );
    const todoRowUnitPrice: number = this.formatNumberToRealFloat(
      this.componentMainForm.controls.unitPrice.value
    );
    const todoCost: number = todoRowQuantity * todoRowUnitPrice;
    this.componentMainForm.controls.cost.setValue(todoCost, {
      emitEvent: false,
    });
  }

  public handleDelete() {
    const id = this.componentMainForm['controls']['id']['value'];

    if (id === null) {
      this.spliceObjectNotYetCreated.emit();
    } else {
      this.actionDelete();
    }
  }

  private actionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort arbetsmomentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.formHandler.lockButtonsAsync(this.buttons, true);

        this.deleteTodo();
      },
      reject: () => {},
    });
  }

  private deleteTodo() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);

          if (data.mutationSucceededAllArguments) {
            this.deletedObject.emit(this.dataObjectFromParent['id']);
            this.componentMainForm.controls.id.setValue(-11);
          }

          this.mutationService.displayMutationStatus(data);

          this.formHandler.unlockButtonsAsync(this.buttons, true);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.formHandler.unlockButtonsAsync(this.buttons, true);
        }
      );
  }

  changeBigColumnEmit(value) {
    this.changeBigColumn.emit(value);
  }
}
