import { gql } from 'apollo-angular';

import { MutationDetails } from './common';
import { Day } from '../queries/day';

export const CreateDay = gql`
  mutation createDayMutation(
    $createDay: DayTypeHyperionMutationInput
    $createDayProducts: [ProjectproductTypeHyperionMutationInput]
  ) {
    dayTypeHyperionMutation(
      createDay: $createDay
      createDayProducts: $createDayProducts
    ) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
      ...DayUsers
    }
  }
  ${Day.fragments.dayUsers}
  ${MutationDetails.fragments.attributes}
`;
