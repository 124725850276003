import { gql } from 'apollo-angular';

import { User } from './user';

// GQL FRAGMENTS

const TODO_WITH_USERS = gql`
  fragment TodoWithUsers on Todo {
    id
    description
    done
    projectId
    usersTodoRelation {
      edges {
        node {
          id
          user {
            ...UserNameFull
          }
        }
      }
    }
  }
  ${User.fragments.fullName}
`;

const TODO_EXPANDED = gql`
  fragment TodoExpanded on Todo {
    id
    description
    descriptionOffer
    done
    endDate
    estimatedTime
    offerId
    orderNr
    parentId
    projectId
    startDate
    topic {
      id
      Name
    }
    todoType
    type
    usersTodoRelation {
      edges {
        node {
          id
          todoId
          userId
          user {
            ...UserNameFullAndColor
          }
        }
      }
    }
  }
  ${User.fragments.fullNameAndColor}
`;

/** Export GQL Fragments on Todo */
export const Todo = {
  fragments: {
    withUsers: TODO_WITH_USERS,
    expanded: TODO_EXPANDED,
  },
};
