import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { PanelModule } from 'primeng/panel';
import { TableModule as PTableModule } from 'primeng/table';

import { BooleanPipe } from 'app/shared/pipes/boolean-pipe/boolean.pipe';
import { NullTextPipe } from '../pipes/null-text-pipe/null-text.pipe';

import { DynamicPipe } from 'app/shared/pipes/dynamic-pipe/dynamic.pipe';
import { TableComponent } from './table.component';
import { HyperionSharedModule } from '../shared.module';

@NgModule({
  declarations: [TableComponent],
  providers: [BooleanPipe, DecimalPipe, DatePipe, DynamicPipe, NullTextPipe],
  imports: [CommonModule, HyperionSharedModule, PanelModule, PTableModule],
  exports: [TableComponent],
})
export class TableModule {}
