<div
  #colorContextMenu
  id="color-context-menu-container"
  [style.left.px]="posX"
  [style.top.px]="posY"
  class="context-menu"
>
  <div class="color-context-menu">
    <div class="header">{{ title }}</div>
    <ul>
      <li *ngFor="let color of colors">
        <div
          [ngClass]="{
            'color-circle-container': true,
            'active-color': activeColor === color
          }"
          [pTooltip]="getColorName(color)"
          (click)="updateColor(color)"
        >
          <div class="color-circle" [style]="{ 'background-color': color }">
            &nbsp;
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
