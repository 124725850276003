<app-header>
  <p-button
    type="button"
    label="{{ isDisplayingProjects ? 'Dölj projekt' : 'Visa fler projekt' }}"
    icon="{{ isDisplayingProjects ? 'pi pi-angle-up' : 'pi pi-angle-down' }}"
    [disabled]="currentState?.mode === 'month' || isLoading"
    [style]="{ 'width': '157px', 'margin-right': '5px' }"
    (onClick)="toggleMoreProjects()"
  >
  </p-button>
  <p-button
    type="button"
    icon="pi pi-plus"
    label="Skapa planerad tid"
    [styleClass]="'p-button-primary-hyperion'"
    [disabled]="isLoading"
    (onClick)="createNewPlannedTime()"
  >
  </p-button>
</app-header>

<div class="page-container">
  <div class="header-row">
    <div>
      <p-button
        (onClick)="toggleMenu()"
        [style]="{ 'border-radius': '50%' }"
        [icon]="'pi pi-bars'"
      >
      </p-button>
    </div>
  </div>
  <div id="calendar-grid" class="calendar-grid">
    <app-scheduler-side-menu
      [dateRange]="currentDateRange"
      [projects]="projectsFilterDropdown"
      [coworkers]="coworkersDropdown"
      (datePicked)="handleMiniCalendarChange($event)"
      (filterUpdate)="handleFilterUpdate($event)"
    ></app-scheduler-side-menu>
    <div #scheduler class="dhx-cal-container"></div>
  </div>

  <div #schedulerLightbox class="custom-lightbox">
    <app-edit-planned-time-view
      *ngIf="scheduledEvent && (isPlannedTimeEvent() || isNewEvent)"
      [scheduledEvent]="scheduledEvent"
      [isNewEvent]="isNewEvent"
      [allTodos]="allTodos"
      [projects]="projects"
      [coworkersDropdown]="coworkersDropdown"
      (closeLightBox)="handleCloseEvent()"
      (saveEvent)="handleSaveEvent($event)"
      (updateEvent)="handleUpdateEvent($event)"
      (deleteEvent)="handleDeleteEvent($event)"
    ></app-edit-planned-time-view>
    <app-edit-project-view
      *ngIf="scheduledEvent && !isPlannedTimeEvent() && !isNewEvent"
      [scheduledEvent]="scheduledEvent"
      [childrenEdgeDates]="childrenEdgeDates"
      (closeLightBox)="handleCloseEvent()"
      (updateEvent)="handleUpdateProject($event)"
    ></app-edit-project-view>
  </div>

  <!-- Context menu, hidden on load -->
  <app-color-context-menu
    [title]="colorContextMenuTitle"
    [contextMenuEventObserver]="contextMenuEventSubject.asObservable()"
    (updateColorEvent)="handleUpdateColorEvent($event)"
  ></app-color-context-menu>
</div>
