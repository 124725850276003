import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class CompanyPicturesService extends CompanySuperService {
  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    // mode == labels || list

    let dataSetFormatted;

    switch (mode) {
      case 'pictures':
        dataSetFormatted = this.formatPictures(data);
        break;
    }

    return dataSetFormatted;
  }

  formatPictures(data) {
    const dataArray = data.company.pictures.edges;
    const dataObjectsArray = [];

    for (const index in dataArray) {
      const objectWithNode = dataArray[index];
      const object = {};

      for (const key in objectWithNode.node) {
        object[key] = objectWithNode.node[key];
      }

      dataObjectsArray.push(object);
    }

    return dataObjectsArray;
  }
}
