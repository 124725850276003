import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FetchProjectQuery } from '../graphql/project.generated';
import { ContactFormContext } from '../../../../contact/contact-form/contact-form.component';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-project-info-view',
  templateUrl: './project-info-view.component.html',
  styleUrls: ['./project-info-view.component.scss'],
})
export class ProjectInfoViewComponent {
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Output() public dataChangedEvent = new EventEmitter<boolean>();
  @Output() public clientChangedEvent = new EventEmitter<number>();

  public contactFormContext = ContactFormContext;
  public showClientSidebar = false;
  public showEstablishmentSidebar = false;
  public showProjectSidebar = false;

  public toggleClientSidebar(): void {
    this.showClientSidebar = !this.showClientSidebar;
  }

  public toggleEstablishmentSidebar(): void {
    this.showEstablishmentSidebar = !this.showEstablishmentSidebar;
  }

  public toggleProjectSidebar(): void {
    this.showProjectSidebar = !this.showProjectSidebar;
  }

  public onClientChanged(id: number): void {
    this.showClientSidebar = false;
    this.clientChangedEvent.emit(id);
  }

  public onEstablishmentChanged(id: number): void {
    this.showEstablishmentSidebar = false;
    this.dataChangedEvent.emit(true);
  }

  public onProjectUpdated(id: number): void {
    this.showProjectSidebar = false;
    this.dataChangedEvent.emit(true);
  }
}
