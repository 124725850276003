<app-header> </app-header>

<div class="page-container">
  <hyperion-card>
    <div class="p-grid home-evive-container">
      <ng-container
        *ngIf="showThankYou; then thankYou; else signUp"
      ></ng-container>
    </div>
  </hyperion-card>

  <ng-template #signUp>
    <div class="p-grid-col-7 home-evive-content">
      <h1>Entreprenadjuridik – ta hjälp av en entreprenadjurist!</h1>

      <h3>Har ni frågor och funderingar kring entreprenadjuridik?</h3>
      <p>
        För oss på Struqtur är det viktigt att ni ska kunna få juridisk
        vägledning inom den snåriga entreprenadjuridiken. En entreprenad
        innebär, utöver det praktiska arbetet, att en hel del beslut behöver
        fattas och/eller att det inte går som planerat – då det kan vara bra att
        ta hjälp av en entreprenadjurist.
      </p>

      <p>
        Via vår samarbetspartner Evive Law kan ni få hjälp med era
        entreprenadjuridiska frågor och behov.
      </p>

      <h4>Som kund hos Struqtur erbjuds:</h4>
      <p>30 minuter fri juridisk rådgivning per företag och år av Evive Law.</p>

      <h4>Några exempel på vad Evive Law kan hjälpa er med:</h4>
      <p>
        Granska entreprenadkontrakt, hantera frågor kring ÄTA-arbeten, hinder
        och störningar, aviseringar samt besiktningar, entreprenadjuridiska
        utbildningar samt agera biträde vid tvist både innan och i domstol, m.m.
      </p>

      <p>Fyll i formuläret så kontaktar en jurist dig snarast.</p>

      <img class="evive-logo" src="/images/evive/evive_law_black.svg" />
    </div>

    <div class="p-grid-col-5 home-evive-form-content">
      <h2>Frågeformulär entreprenadjuridik</h2>
      <form [formGroup]="signupForm" (ngSubmit)="submitForm()">
        <div class="p-fluid">
          <div class="p-field">
            <label for="category">Kategori</label>
            <p-dropdown
              id="category"
              [options]="categories"
              formControlName="category"
            ></p-dropdown>
          </div>
          <div class="p-field">
            <label for="companyOrgNo">Org.nr</label>
            <input
              id="companyOrgNo"
              name="company_orgno"
              type="text"
              readonly
              formControlName="companyOrgNo"
              pInputText
            />
          </div>
          <div class="p-field">
            <label for="companyName">Företagsnamn</label>
            <input
              id="companyName"
              name="company_name"
              type="text"
              readonly
              formControlName="companyName"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('companyName').errors &&
                signupForm.controls.companyName.touched
              "
            >
              {{ signupForm.get('companyName').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="name">För- och efternamn</label>
            <input
              id="name"
              name="name"
              type="text"
              formControlName="name"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('name').errors &&
                signupForm.controls.name.touched
              "
            >
              {{ signupForm.get('name').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="phone">Telefon</label>
            <input
              id="phone"
              name="phone"
              type="text"
              formControlName="phone"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('phone').errors &&
                signupForm.controls.phone.touched
              "
            >
              {{ signupForm.get('phone').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="email">E-post</label>
            <input
              id="email"
              name="email"
              type="text"
              formControlName="email"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('email').errors &&
                signupForm.controls.email.touched
              "
            >
              {{ signupForm.get('email').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <p-checkbox
              name="accept"
              label="Jag samtycker till att Struqtur överlämnar mina personuppgifter till Evive Group AB, org.nr. 559218–0144 (“Evive Law”) för behandling på det sätt som beskrivs nedan."
              formControlName="accept"
              [binary]="true"
            ></p-checkbox>
            <p
              appFormErrorMessage
              *ngIf="signupForm.get('accept').errors && signupForm.touched"
            >
              {{ signupForm.get('accept').errors.message }}
            </p>
            <p><a (click)="showTermsDetails()">Visa mer om samtycket</a></p>
          </div>
          <button
            pButton
            class="p-button-primary-hyperion"
            type="submit"
            label="Skicka intresseanmälan"
            icon="pi pi-send"
            [disabled]="loading"
          ></button>
        </div>
        <p-dialog
          header="Evive Law"
          [modal]="true"
          [dismissableMask]="true"
          [draggable]="false"
          [(visible)]="popupTermsDetailsVisible"
          styleClass="terms-details-popup"
        >
          <h4>Begäran om samtycke till överlämnande av personuppgifter</h4>
          <p>
            Struqtur vill överlämna personuppgifter om dig till Evive Group AB,
            org.nr. 559218–0144 (“Evive Law”) som är Struqtur:s juridiska
            samarbetspartner. Detta behöver vi göra för att Evive Law ska kunna
            kontakta, ingå avtal, besvara och åta sig det juridiska uppdraget
            för dig eller det bolag du representerar. Evive Law blir ensamt
            personuppgiftsansvarig för sin behandling av dina personuppgifter
            efter överföringen från oss. De personuppgifter som Evive Law
            behöver för att kunna kontakta, ingå avtal, besvara och åta sig det
            juridiska uppdraget för dig eller det bolag du representerar är:
          </p>

          <ul>
            <li>Ditt namn</li>
            <li>E-post</li>
            <li>Telefonnummer</li>
          </ul>
          <p>
            Du har alltid rätt att enkelt kunna motsätta dig Evive Law:s
            behandling av dina personuppgifter. Du har alltid rätt att återkalla
            ditt samtycke beträffande Evive Law:s rätt att behandla dina
            personuppgifter.
          </p>
          <p>
            Om du vill veta mer om hur Evive Law behandlar personuppgifter så
            kan du läsa
            <a
              target="_blank"
              href="https://www.evivegroup.com/integritetspolicy/"
              >Evive Law:s integritetspolicy</a
            >.
          </p>
        </p-dialog>
      </form>
    </div>
  </ng-template>

  <ng-template #thankYou>
    <div class="p-grid-col-12" class="thank-you">
      <h1>Ditt formulär är nu skickat!</h1>
      <p>
        En kopia på ditt ifyllda formulär är skickat till din angivna
        e-postadress.
      </p>
      <p>En jurist från Evive Law kommer kontakta dig inom kort.</p>
      <img class="evive-logo" src="images/evive/evive_law_black.svg" />
      <p>
        Har du några frågor vet du väl alltid om att vår support finns
        tillgänglig för dig? Supporten når du på 031-300 65 20 eller
        support@struqtur.se
      </p>
    </div>
  </ng-template>
</div>
