<p-accordion class="p-col-12" [multiple]="true">
  <p-accordionTab [selected]="true" header="Sök">
    <div class="p-grid" [formGroup]="filters">
      <span class="p-col-4">
        <label>Id</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-hashtag"></i>
          </span>
          <input
            pInputText
            formControlName="trueId"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Vår referens</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-user"></i>
          </span>
          <input
            pInputText
            formControlName="madeBy"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Arbetsställe</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-home"></i>
          </span>
          <input
            pInputText
            formControlName="establishmentInfo"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Märkning</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-tag"></i>
          </span>
          <input
            pInputText
            formControlName="mark"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Kundens ordernr.</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-shopping-bag"></i>
          </span>
          <input
            pInputText
            formControlName="orderNumber"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Kund</label>
        <span class="p-inputgroup">
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-user"></i>
          </span>
          <input
            pInputText
            formControlName="clientInfo"
            placeholder=""
            [style.background-color]="'white'"
          />
        </span>
      </span>
      <span class="p-col-4">
        <label>Skapad av</label>

        <app-users-multiselect
          (selectedUsersEvent)="selectCreatedByIdDropdown($event)"
        ></app-users-multiselect>
      </span>
      <span class="p-col-4">
        <label>Klarmarkerat</label>

        <p-multiSelect
          placeholder="Välj klarmarkering"
          [filter]="false"
          [options]="projectDoneStateDropdownOptions"
          (onChange)="onDoneStateChange($event)"
          optionLabel="label"
          optionValue="value"
          [maxSelectedLabels]="2"
          selectedItemsLabel="Alla valda"
        >
        </p-multiSelect>
      </span>
    </div>
  </p-accordionTab>
  <p-accordionTab [selected]="true" header="Sammanställning">
    <div class="p-grid">
      <div class="p-col-4 p-grid">
        <label class="p-col-12">Välj status på projekt</label>
        <p-checkbox
          *ngFor="let statusLabel of statusLabels"
          class="p-col-12"
          [label]="statusLabel.label"
          [(ngModel)]="projectListService.statusMap[statusLabel.value]"
          [binary]="true"
        ></p-checkbox>
      </div>
      <div class="p-col-4 p-grid">
        <label class="p-col-12">Välj kolumner - Projektinformation</label>
        <p-checkbox
          *ngFor="let col of infoColumns"
          class="p-col-12"
          [(ngModel)]="col.active"
          [binary]="true"
          [label]="col.header"
        ></p-checkbox>
      </div>
      <div class="p-col-4 p-grid">
        <label class="p-col-12">Välj Period</label>
        <span class="p-col-12">
          <label>Från</label>
          <p-calendar
            [showIcon]="true"
            [(ngModel)]="startDate"
            [showWeek]="true"
          ></p-calendar>
        </span>
        <span class="p-col-12">
          <label>Till</label>
          <p-calendar
            [showIcon]="true"
            [(ngModel)]="endDate"
            [showWeek]="true"
          ></p-calendar>
        </span>

        <span class="p-col-12">
          <button
            pButton
            [style.width]="'100%'"
            class="p-mb-2"
            label="Exportera intäkter"
            [icon]="
              loadingExport ? 'pi pi-spinner pi-spin' : 'pi pi-file-excel'
            "
            [disabled]="loadingExport"
            (click)="exportPerCostTypeExcel()"
            pTooltip="Rapport som visar ofakturerade intäkter för löpande timmar, och fakturerat/ej fakturerat material/övriga kostnader per kostnadstyp för vald period"
            tooltipPosition="bottom"
          ></button>
        </span>
      </div>
    </div>
  </p-accordionTab>

  <p-accordionTab [selected]="true" header="KPIer">
    <div class="p-grid">
      <span class="p-col-4" *ngFor="let col of kpiColumns">
        <label>{{ col.label }}</label>
        <span class="p-grid p-jc-center" *ngFor="let subCol of col.items">
          <p-checkbox
            [(ngModel)]="subCol.active"
            class="p-col-11"
            [binary]="true"
            [label]="subCol.header"
          >
          </p-checkbox>
          <span
            class="p-col-1 p-d-flex p-jc-center p-ai-center"
            style="color: var(--primary-color-400)"
            [pTooltip]="subCol.tooltip"
            tooltipPosition="bottom"
            [escape]="false"
          >
            ?
          </span>
        </span>
      </span>

      <span class="p-col-4 p-d-flex p-flex-column p-jc-end">
        <button
          pButton
          class="p-mb-2"
          [style.width]="'100%'"
          label="Exportera till Excel"
          [icon]="loadingExcel ? 'pi pi-spinner pi-spin' : 'pi pi-file-excel'"
          [disabled]="loadingExcel"
          (click)="exportTable()"
          pTooltip="Exportera valda KPIer till Excel"
          tooltipPosition="bottom"
        ></button>
        <button
          pButton
          label="Hämta & Filtrera"
          [style.width]="'100%'"
          icon="pi pi-filter"
          (click)="filterDate()"
          class="p-button-primary-hyperion"
        ></button>
      </span>
    </div>
  </p-accordionTab>
</p-accordion>
