import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetDocumentsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetDocumentsQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', documentMeta?: { __typename?: 'DocumentMetaConnection', edges?: Array<{ __typename?: 'DocumentMetaEdge', node?: { __typename?: 'DocumentMeta', id: string, comment?: string | null, created?: string | null, file?: string | null, publicForAllUsers?: number | null, public?: number | null, path?: string | null } | null } | null> | null } | null } | null };

export type UpdateDocumentMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  comment?: Types.InputMaybe<Types.Scalars['String']>;
  publicForAllUsers?: Types.InputMaybe<Types.Scalars['Int']>;
  public?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateDocumentMutation = { __typename?: 'HyperionSchemaMutation', documentMetaTypeHyperionMutation?: { __typename?: 'DocumentMeta', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export type DeleteDocumentMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteDocumentMutation = { __typename?: 'HyperionSchemaMutation', documentMetaTypeHyperionMutation?: { __typename?: 'DocumentMeta', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export const GetDocumentsDocument = gql`
    query getDocuments($projectId: Int) {
  project(id: $projectId) {
    documentMeta {
      edges {
        node {
          id
          comment
          created
          file
          publicForAllUsers
          public
          path
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDocumentsGQL extends Apollo.Query<GetDocumentsQuery, GetDocumentsQueryVariables> {
    document = GetDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDocumentDocument = gql`
    mutation updateDocument($id: Int, $comment: String, $publicForAllUsers: Int, $public: Int) {
  documentMetaTypeHyperionMutation(
    updateDocumentMeta: {id: $id, comment: $comment, publicForAllUsers: $publicForAllUsers, public: $public}
  ) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDocumentGQL extends Apollo.Mutation<UpdateDocumentMutation, UpdateDocumentMutationVariables> {
    document = UpdateDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: Int) {
  documentMetaTypeHyperionMutation(deleteDocumentMeta: {id: $id}) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDocumentGQL extends Apollo.Mutation<DeleteDocumentMutation, DeleteDocumentMutationVariables> {
    document = DeleteDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }