import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApolloQueryService } from 'app/shared/apollo';
import {
  ContactProjectsRootObject,
  ContactProjectsCompany,
} from './contact-projects';

@Injectable({
  providedIn: 'root',
})
export class ContactProjectsService {
  constructor(private apolloQueryService: ApolloQueryService) {}

  public getProjectsPerContact(
    contactId: number
  ): Observable<ProjectsPerContact> {
    const variables = { contactId };

    return this.apolloQueryService
      .apolloWatchQueryTwo('contactProjects', variables, 'cache-and-network')
      .pipe(
        map<{ data: ContactProjectsRootObject }, ProjectsPerContact>(
          ({ data }) => {
            return {
              contactId,
              projects: this.companyToProjectsReduced(data.company),
            };
          }
        )
      );
  }

  /**
   * Transforms a ContactProjectsCompany into a ProjectsReduced array
   *
   * @param company the company response from the contactProjects query
   */
  private companyToProjectsReduced(
    company: ContactProjectsCompany
  ): ProjectsReduced[] {
    return company.contacts.edges.flatMap(contactEdge => {
      contactEdge = JSON.parse(JSON.stringify(contactEdge));
      return contactEdge.node.children.edges.flatMap(contactNode => {
        return contactNode.node.projects.edges.map<ProjectsReduced>(
          projectEdge => {
            const projectNode = projectEdge.node;
            return {
              id: +projectNode.id,
              trueId: isNaN(+projectNode.trueId)
                ? projectNode.trueId
                : +projectNode.trueId,
              clientContactOrderBusinessName:
                projectNode.clientContact.orderBuisnessName,
              establishmentContactName: projectNode.establishmentContact.name,
              establishmentContactAddress:
                projectNode.establishmentContact.address,
              mark: projectNode.mark,
              startDate: new Date(projectNode.startDate),
              endDate: new Date(projectNode.endDate),
            };
          }
        );
      });
    });
  }
}

export interface ProjectsPerContact {
  contactId: number;
  projects: ProjectsReduced[];
}

export interface ProjectsReduced {
  id: number;
  trueId: number | string;
  clientContactOrderBusinessName: string;
  establishmentContactName: string;
  establishmentContactAddress: string;
  mark: string;
  startDate: Date;
  endDate: Date;
}
