<app-header>
  <app-button
    *ngIf="useUserConnectionToProject && !gottenUnRelatedProjects"
    pTooltip="Om du saknar ett projekt kan du laddda det här och tilldela det till dig själv. Efter du valt aktuellt projekt hittar du detta längst ner på sidan."
    tooltipPosition="bottom"
    (click)="getUnRelatedProjects()"
    [type]="'showAllProjects'"
  ></app-button>
  <app-button
    *ngIf="gottenUnRelatedProjects"
    (click)="getRelatedProjects()"
    [type]="'showMy'"
    [model]="'Project'"
  ></app-button>
  <app-button
    (click)="openConnectionsDialog()"
    [type]="'showConnectionsDialog'"
    [badge]="notificationsCount.value > 0 ? (notificationsCount | async) : null"
  ></app-button>
  <app-button
    (click)="openCreateProjectDialog()"
    [type]="'showCreate'"
    [model]="'Project'"
  ></app-button>
</app-header>
