import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { AccordionTab } from 'primeng/accordion';
const LOCALSTORAGE_PREFIX = 'SaveExpansion';

@Directive({
  selector: '[appSaveExpansion]',
})
export class SaveExpansionDirective implements OnInit {
  constructor(private tab: AccordionTab) {}

  /**
   * Name to save expansion state to in localstorage.
   * Gets concatenated with 'SaveExpansion'
   */
  @Input() public appSaveExpansion: string;
  @Input() public set allowOpening(value: boolean) {
    this._allowOpening = value;
    this.getSelected();
  }
  public get allowOpening(): boolean {
    return this._allowOpening;
  }

  private _allowOpening = true;

  public ngOnInit(): void {
    this.getSelected();
  }

  @HostListener('click') public onClick(): void {
    this.setSelected();
  }

  private getSelected(): void {
    if (!this.appSaveExpansion || !this.tab) {
      return;
    }
    const isExpanded = window.localStorage.getItem(
      LOCALSTORAGE_PREFIX + this.appSaveExpansion
    );
    if (isExpanded === null) {
      return;
    }

    this.tab.selected = isExpanded === 'true' && this.allowOpening;
  }

  private setSelected(): void {
    if (!this.appSaveExpansion || !this.tab) {
      return;
    }
    window.localStorage.setItem(
      LOCALSTORAGE_PREFIX + this.appSaveExpansion,
      String(this.tab.selected)
    );
  }
}
