import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MessageService } from 'app/shared/message';
import { first, Observable, Subscription } from 'rxjs';
import { FetchProjectQuery } from '../graphql/project.generated';
import {
  GetProjectParametersGQL,
  UpdateProjectParametersGQL,
} from './graphql/project-parameters.generated';

@Component({
  selector: 'app-project-parameter-settings',
  templateUrl: './project-parameter-settings.component.html',
  styleUrls: ['./project-parameter-settings.component.scss'],
})
export class ProjectParameterSettingsComponent implements OnInit, OnDestroy {
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Output() public projectParametersUpdated = new EventEmitter<void>();
  public costPerMile = 0;
  public mileAllowance = 0;
  public separatelyInvoiced = 0;
  private projectDataSub: Subscription;
  private projectId: number;

  constructor(
    private getProjectParametersService: GetProjectParametersGQL,
    private updateProjectParametersService: UpdateProjectParametersGQL,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.projectDataSub = this.projectData.subscribe(data => {
      this.getProjectParametersService
        .fetch({ projectId: Number(data.id) })
        .pipe(first())
        .subscribe(res => {
          const projectData = res.data.project;
          if (projectData === null) {
            return;
          }
          this.projectId = Number(data.id);
          this.costPerMile = projectData.mileCost;
          this.mileAllowance = projectData.privMileCost;
          this.separatelyInvoiced = projectData.invoicedInOtherSystem;
        });
    });
  }

  public ngOnDestroy(): void {
    this.projectDataSub?.unsubscribe();
  }

  public saveParameters(): void {
    this.updateProjectParametersService
      .mutate({
        project: {
          id: this.projectId,
          mileCost: this.costPerMile,
          privMileCost: this.mileAllowance,
          invoicedInOtherSystem: this.separatelyInvoiced,
        },
      })
      .subscribe(res => {
        const success =
          res.data.projectTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        this.messageService.insertDataFromMutation(
          res.data.projectTypeHyperionMutation
        );
        this.projectParametersUpdated.emit();
      });
  }
}
