import { gql } from 'apollo-angular';

import { User } from './user';
import { GQLFragment } from '.';

// GQL Queries
const INVOICE_PAYMENTS = {
  query: gql`
    query fetchInvoicePaymentsQuery($id: Int) {
      company {
        id
        invoices(id: $id) {
          edges {
            node {
              id
              payments {
                edges {
                  node {
                    id
                    invoiceId
                    user {
                      ...UserNameFull
                    }
                    sum
                    date
                    created
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {},
};

const COMPANY_INVOICE_TEMPLATE_TEXT = {
  query: gql`
    query CompanyInvoiceTemplateText {
      company {
        id
        logoUrl
        invoiceLogoWith
        InvoiceTemplateText {
          id
          footer
          header
        }
      }
    }
  `,
};

const INVOICE_TAX_REDUCTION = {
  query: gql`
    query fetchInvoiceTaxReduction($id: Int) {
      company {
        id
        invociesAndSupplierInvoices(id: $id) {
          edges {
            node {
              id
              typeInvoice
              payDate
              status
              statusRot
              invoiceRotRequestings {
                edges {
                  node {
                    id
                    customerWantedWorkAmount
                    name
                    personalNumber
                    nameOfProperty
                    apartmentDesignation
                    housingAssociationOrgNumber
                    invoiceRotRequestingExports {
                      edges {
                        node {
                          id
                          created
                          xml
                          user {
                            ...UserNameFull
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {},
};

/** Export GQL Queries on Attendance */
export const queries = {
  invoicePayments: INVOICE_PAYMENTS,
  companyInvoiceTemplateText: COMPANY_INVOICE_TEMPLATE_TEXT,
  invoiceTaxReduction: INVOICE_TAX_REDUCTION,
};

const FRAGMENT_HOUSE_WORKTYPE_COSTS = gql`
  fragment HouseWorkTypeCosts on InvoiceRotRequestingExtra {
    # ROT
    ByggAntalTimmar
    ByggMaterialkostnad
    ElAntalTimmar
    ElMaterialkostnad
    GlasPlatarbeteAntalTimmar
    GlasPlatarbeteMaterialkostnad
    MarkDraneringarbeteAntalTimmar
    MarkDraneringarbeteMaterialkostnad
    MurningAntalTimmar
    MurningMaterialkostnad
    MalningTapetseringAntalTimmar
    MalningTapetseringMaterialkostnad
    VvsAntalTimmar
    VvsMaterialkostnad

    # RUT
    TillsynAvBostadAntalTimmar
    TillsynAvBostadMaterialkostnad
    MobleringAntalTimmar
    MobleringMaterialkostnad
    TransportTillForsaljningAntalTimmar
    TransportTillForsaljningMaterialkostnad
    TvattVidTvattinrattningAntalTimmar
    TvattVidTvattinrattningMaterialkostnad
    PersonligOmsorgAntalTimmar
    TvattVidTvattinrattningMaterialkostnad
    StadningAntalTimmar
    StadningMaterialkostnad
    KladOchTextilvardAntalTimmar
    KladOchTextilvardMaterialkostnad
    SnoskottningAntalTimmar
    SnoskottningMaterialkostnad
    TradgardsarbeteAntalTimmar
    TradgardsarbeteMaterialkostnad
    BarnpassningAntalTimmar
    BarnpassningMaterialkostnad
    FlyttjansterAntalTimmar
    FlyttjansterMaterialkostnad
    ItTjansterAntalTimmar
    ItTjansterMaterialkostnad
    ReparationAvVitvarorAntalTimmar
    ReparationAvVitvarorMaterialkostnad

    # Green
    SolcellerAntalTimmar
    SolcellerMaterialkostnad
    LagringEgenproduceradElAntalTimmar
    LagringEgenproduceradElMaterialkostnad
    LaddningspunktElfordonAntalTimmar
    LaddningspunktElfordonMaterialkostnad

    OvrigKostnad
  }
` as unknown as GQLFragment;

export const Invoice = {
  fragments: {
    houseWorkTypeCosts: FRAGMENT_HOUSE_WORKTYPE_COSTS,
  },
};
