<app-header> </app-header>

<div class="page-container">
  <h3>SIE-export <small>Välj period du vill exportera</small></h3>

  <p-table [value]="exports" [loading]="loading" [rowHover]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Period</th>
        <th>Info</th>
        <th>Hämta</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td>{{ row.label }}</td>
        <td>{{ row.info }}</td>
        <td>
          <a [href]="row.url" class="link" *ngIf="row.possibleToExport"
            >Hämta</a
          >
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr class="emptyStateTR">
        <td colspan="3">
          <app-emptyState></app-emptyState>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
