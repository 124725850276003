import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateProjectproductMutationVariables = Types.Exact<{
  createProjectproduct?: Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>;
}>;


export type CreateProjectproductMutation = { __typename?: 'HyperionSchemaMutation', projectproductTypeHyperionMutation?: { __typename?: 'Projectproduct', id: string, userId?: number | null, projectCostTypeId?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type UpdateProjectproductMutationVariables = Types.Exact<{
  updateProjectproduct?: Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>;
}>;


export type UpdateProjectproductMutation = { __typename?: 'HyperionSchemaMutation', projectproductTypeHyperionMutation?: { __typename?: 'Projectproduct', id: string, projectId?: number | null, projectCostTypeId?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type DeleteProjectproductMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteProjectproductMutation = { __typename?: 'HyperionSchemaMutation', projectproductTypeHyperionMutation?: { __typename?: 'Projectproduct', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type RemoveCosttypeFromProjectMutationVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  costtypeId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RemoveCosttypeFromProjectMutation = { __typename?: 'HyperionSchemaMutation', projectCostTypeTypeHyperionMutation?: { __typename?: 'ProjectCostType', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const CreateProjectproductDocument = gql`
    mutation createProjectproduct($createProjectproduct: ProjectproductTypeHyperionMutationInput) {
  projectproductTypeHyperionMutation(createProjectproduct: $createProjectproduct) {
    id
    userId
    projectCostTypeId
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectproductGQL extends Apollo.Mutation<CreateProjectproductMutation, CreateProjectproductMutationVariables> {
    document = CreateProjectproductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectproductDocument = gql`
    mutation updateProjectproduct($updateProjectproduct: ProjectproductTypeHyperionMutationInput) {
  projectproductTypeHyperionMutation(updateProjectproduct: $updateProjectproduct) {
    id
    projectId
    projectCostTypeId
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectproductGQL extends Apollo.Mutation<UpdateProjectproductMutation, UpdateProjectproductMutationVariables> {
    document = UpdateProjectproductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProjectproductDocument = gql`
    mutation deleteProjectproduct($id: Int) {
  projectproductTypeHyperionMutation(deleteProjectproduct: {id: $id}) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectproductGQL extends Apollo.Mutation<DeleteProjectproductMutation, DeleteProjectproductMutationVariables> {
    document = DeleteProjectproductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveCosttypeFromProjectDocument = gql`
    mutation removeCosttypeFromProject($projectId: Int, $costtypeId: Int) {
  projectCostTypeTypeHyperionMutation(
    deleteProjectCostType: {id: $costtypeId, projectId: $projectId}
  ) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveCosttypeFromProjectGQL extends Apollo.Mutation<RemoveCosttypeFromProjectMutation, RemoveCosttypeFromProjectMutationVariables> {
    document = RemoveCosttypeFromProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }