import {
  Input,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  Output,
  OnInit,
  OnDestroy,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormBuilder,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { first, Subscription } from 'rxjs';
import * as currency from 'currency.js';
import { ConfirmationService } from 'primeng/api';

import { CompanyInvoiceService } from '../services/company-invoice.service';
import { InvoiceStateService } from '../services/invoice-state.service';
import { InvoiceType } from '../enums/invoice-type.enum';
import { InvoiceMode } from '../enums/invoice-mode.enum';
import { RowType } from '../enums/row-type.enum';
import {
  CompanyFunctionsService,
  ProductsAutosuggestService,
} from 'app/shared/company/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import { InvoiceBodyService } from './invoice-body.service';
import { DateRange } from '../date-range';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
  AccountingPlanAccountsDropdownItem,
} from 'app/settings/company/services/accounting-plan.types';
import { CompanyAccountsGQL } from '../graphql/companyAccounts.generated';
import gql from 'graphql-tag';
import { ApolloQueryService } from 'app/shared';

export interface InvoiceProduct {
  artnr: string;
  benamning: string;
  id: string;
}

@Component({
  selector: 'app-struktur-invoice-body',
  templateUrl: 'invoice-body.component.html',
  styleUrls: ['./invoice-body.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InvoiceBodyComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceBodyComponent implements OnInit, OnChanges, OnDestroy {
  @Input() fullInvoiceToOpen;
  @Input() mode: string;
  @Input() noRot: boolean;
  @Input() selectByDateRange: DateRange;

  /** Whether we are in SupplierInvoice or Invoice */
  @Input() public context: 'invoice' | 'supplierInvoice' = 'invoice';

  @Output() sumChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowProjectToGet: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  lastSelectedProjectId: EventEmitter<string> = new EventEmitter<string>();

  // Enum mapping
  InvoiceMode = InvoiceMode;
  InvoiceType = InvoiceType;

  fromInvoice = false;
  parsedInvoiceData = { projects: [], transactionTypes: [], transactions: [] };
  projects = {};
  rowsOpened = {};
  filterSubtypes = {};
  rowIndex;
  rowLoading: boolean;
  rowMessage = [];
  invoiceBodyForm: FormGroup;
  invoiceMode = [InvoiceMode.NoData];
  invoiceType = InvoiceType.Normal;
  invoiceTypeSubInfo = '';
  taxReductionErrors = '';
  private calcTotalInterval: number;
  openedProjects = [];
  selectedopenedProjects: string;
  public invoiceCreatedWithUseRowBasedTaxReduction: boolean;
  public isTaxReduction: boolean;
  public invoiceTypeString: string;
  houseWorkTypeTypes = [];
  public houseWorkTypeTypesMap: { [key: string]: string } = {};
  vats = [
    { value: 25, label: '25 %' },
    { value: 12, label: '12 %' },
    { value: 6, label: '6 %' },
    { value: 0, label: '0 %' },
    { value: 99, label: 'endast benämning' },
  ];
  units = [...QuantityUnits.map(unit => ({ ...unit, label: unit.value }))];
  mainTypes = [
    { value: 0, label: 'Arbetstid EXTRA' },
    { value: 1, label: 'Arbetstid' },
    { value: 2, label: 'Produkter' },
    { value: 3, label: 'Delbetalningar' },
    { value: 4, label: 'Fritt' },
    { value: 5, label: 'Från offert' },
    { value: 6, label: 'Offererat Projekt' },
    { value: 7, label: 'Traktamente natt' },
    { value: 8, label: 'Traktamente heldag' },
    { value: 9, label: 'Traktamente halvdag' },
    { value: 10, label: 'ÄTA' },
    { value: 11, label: 'Produkter EXTRA' },
  ];

  filteredMainTypes = this.mainTypes.filter(
    itm => itm.value < InvoiceMode.Free
  );
  rowDataContainers = [];
  projectsDropdown: any[];
  selectedProjectId: string;
  showProductsDropDown = false;
  public companyProducts: InvoiceProduct[] = [];
  projectTrueIds = [];
  selectedProjectTrueId;
  private fixedCompanyProducts: InvoiceProduct[] = [];
  private projectStateSub: Subscription;
  private invoiceToRowsub: Subscription;
  private rotSubscription: Subscription;
  private invoiceSubscription: Subscription;
  private projectsDropdownSub: Subscription;
  private projectIdfromDropdown: Subscription;
  private invoiceModeSubscription: Subscription;
  private invoiceRowsByModeUpdateSub: Subscription;
  private invoiceTrueIdSub: Subscription;
  private usedRowDetails: any[] = [];
  private getLoadedProjectsAtasSub: Subscription;
  private getSelectedProjectsAtasSub: Subscription;
  private loadedProjectsAtas: any[];
  private selectedProjectsAtasIds: string[];
  public companyAccountingPlanAccounts: AccountingPlanAccount[];
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;
  public accountingPlanAccountsDropdownItemMap: {
    [key: number]: AccountingPlanAccountsDropdownItem;
  } = {};
  public hasAccountingPlanFunction: boolean;

  private companyProductsSub: any;

  private companyMilageForCustomerAccountingPlanAccountId: string;
  private reverseVatCompanyMilageForCustomerAccountingPlanAccountId: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private invoiceStateService: InvoiceStateService,
    private companyInvoiceService: CompanyInvoiceService,
    private confirmationService: ConfirmationService,
    private companyFunctionsService: CompanyFunctionsService,
    private accountingPlanService: AccountingPlanService,
    private invoiceBodyService: InvoiceBodyService,
    private globalInvoiceService: GlobalInvoiceService,
    private productsAutosuggestService: ProductsAutosuggestService,
    private companyAccountsService: CompanyAccountsGQL,
    private apolloQueryService: ApolloQueryService
  ) {
    this.invoiceCreatedWithUseRowBasedTaxReduction = true;
    this.showProductsDropDown =
      this.companyFunctionsService.companyFunctionIsSet(
        'connectProductsToInvoiceRows'
      ) ||
      this.companyFunctionsService.companyFunctionIsSet('useFortnoxWarehouse');
    this.hasAccountingPlanFunction =
      this.companyFunctionsService.companyFunctionIsSet('useAccountingPlan');
    this.createForm();
  }

  public ngOnInit(): void {
    if (this.showProductsDropDown) {
      this.setupSearchQuery();
    }

    this.companyInvoiceService.invoiceFormsChange$
      .pipe(first())
      .subscribe(forms => {
        forms['invoice'].controls['kreditfaktura'].valueChanges.subscribe(() =>
          this.rowDataContainers.forEach(c =>
            c.rows.forEach(r => (r.antal *= -1))
          )
        );
      });

    this.companyAccountsService
      .fetch()
      .pipe(first())
      .subscribe(data => {
        this.companyMilageForCustomerAccountingPlanAccountId =
          data.data.company.companyMilageForCustomerAccountingPlanAccountId;
        this.reverseVatCompanyMilageForCustomerAccountingPlanAccountId =
          data.data.company.reverseVatCompanyMilageForCustomerAccountingPlanAccountId;
      });

    this.calcTotalInterval = setInterval(() => {
      this.calculateTotal();
    }, 1200) as any;

    this.rotSubscription = this.invoiceStateService.invoiceTypeState$.subscribe(
      event => {
        this.invoiceTypeSubInfo = '';
        this.invoiceType = event;

        this.houseWorkTypeTypes = [
          {
            value: '',
            label: 'Välj typ',
          },
          ...(
            this.globalInvoiceService.houseWorkTypes[this.invoiceType] || []
          ).map(houseWorkType => ({
            value: houseWorkType.type,
            label: houseWorkType.description,
          })),
        ];

        this.houseWorkTypeTypes.forEach(
          t => (this.houseWorkTypeTypesMap[t.value] = t.label)
        );

        this.invoiceTypeString = this.globalInvoiceService.getInvoiceTypeString(
          this.invoiceType
        );

        this.isTaxReduction = this.globalInvoiceService.isTaxReduction(
          this.invoiceType
        );

        this.cdr.detectChanges();
      }
    );
    this.invoiceModeSubscription =
      this.invoiceStateService.invoiceTypeMode$.subscribe(event => {
        this.invoiceMode = event;
        this.changeInvoiceMode();
      });
    this.projectIdfromDropdown =
      this.invoiceStateService.projectIdfromDropdown$.subscribe(arr => {
        this.openedProjects = arr;
      });
    this.invoiceSubscription =
      this.invoiceStateService.parsedInvoiceData$.subscribe(value => {
        this.parsedInvoiceData = value;
        this.changeInvoiceMode();
      });
    this.invoiceToRowsub =
      this.invoiceStateService.parsedInvoiceDataToRow$.subscribe(val => {
        // added from row +
        this.rowLoading = false;
        this.parsedInvoiceData['customers'] = [];
        if (this.parsedInvoiceData.transactions.length < 1) {
          this.invoiceMode = [InvoiceMode.Running];
        }

        val.transactionTypes.forEach(obj =>
          this.parsedInvoiceData.transactionTypes.push(obj)
        );
        val.customers.forEach(obj =>
          this.parsedInvoiceData['customers'].push(obj)
        );
        val.transactions.forEach(obj =>
          this.parsedInvoiceData.transactions.push(obj)
        );

        this.changeInvoiceMode();
        this.selectedProjectId = null;
      });

    this.invoiceTrueIdSub = this.invoiceStateService.invoiceTrueIds$.subscribe(
      event => {
        const arr = event;
        this.projectTrueIds = arr;
        this.projectTrueIds.splice(0, 1, {
          label: 'Inget projekt',
          value: null,
        });
      }
    );
    this.projectsDropdownSub =
      this.invoiceStateService.projectsDropdown$.subscribe(
        projectArr => (this.projectsDropdown = projectArr)
      );
    this.countNumberOfRows(this.rowDataContainers.length); // Append rows if open without id.
    this.projectStateSub = this.invoiceStateService.clearProjects$.subscribe(
      state => this.clearOpenedProject(state)
    );

    this.accountingPlanService
      .getAccounts()
      .subscribe((accounts: AccountingPlanAccount[]) => {
        this.companyAccountingPlanAccounts = accounts;

        this.accountingPlanAccountsDropdownParams =
          this.accountingPlanService.getAccountsDropdownParams(
            this.companyAccountingPlanAccounts
          );
        this.accountingPlanAccountsDropdownParams.options.forEach(
          i => (this.accountingPlanAccountsDropdownItemMap[i.value] = i)
        );
      });

    this.getSelectedProjectsAtasSub =
      this.invoiceStateService.getSelectedProjectsAtas.subscribe(atasIds => {
        if (atasIds.length && !this.invoiceMode.includes(InvoiceMode.ATA)) {
          this.invoiceMode.push(InvoiceMode.ATA);
        }

        this.selectedProjectsAtasIds = atasIds;

        this.changeInvoiceMode();
      });

    this.getLoadedProjectsAtasSub =
      this.invoiceStateService.getLoadedProjectsAtas.subscribe(atas => {
        this.loadedProjectsAtas = atas;
      });
  }

  private setupSearchQuery(): void {
    this.productsAutosuggestService.setupSearchQuery();

    this.productsAutosuggestService.searchQueryOutput.subscribe(products => {
      this.companyProducts = [...this.fixedCompanyProducts, ...products];
    });
  }

  public searchProducts(filter: { query: string }): void {
    this.productsAutosuggestService.searchNext(filter.query);
  }

  public setProductFromAutocomplete(value, row): void {
    row.benamning = value.benamning;
    row.product_id = value.id;
    row.enhet = value.enhet;
    row.apris = value.avtalspris;
  }

  public removeProductFromRow(row): void {
    row.product_id = null;
  }

  onRowSelect(event, container) {
    if (!this.hasCheckedTwin(event.data)) {
      event.data.used = true;
      // If container is multiRow, check if rowdetail already exists in rows, add if not.
      /* eslint-disable eqeqeq */
      if (
        container.isMultiRow &&
        !container.rows.find(row => row.rowDetailKey == event.data.id)
      ) {
        /* eslint-enable eqeqeq */
        const itm = event.data;
        const rowDetail = container.rowDetail;
        container.rows.push({
          rowDetailKey: itm.id,
          benamning: itm.benamning,
          apris: itm.apris,
          antal: itm.antal,
          tax: itm.tax,
          pris: itm.apris * itm.antal,
          enhet: itm.enhet,
          project_id: rowDetail[0].project_id,
          invoiceId: rowDetail[0].invoiceId ? rowDetail[0].invoiceId : 0,
          discount: rowDetail[0].discount ? rowDetail[0].discount : 0,
          // eslint-disable-next-line eqeqeq
          arbetskostnad: itm.arbetskostnad ? itm.arbetskostnad == 1 : false,
          trueId: rowDetail[0].trueId ? rowDetail[0].trueId : null,
          accountingPlanAccountId: rowDetail[0].accountingPlanAccountId,
        });
      }
    }
    this.recalc(container);
  }

  onRowUnselect(event, container) {
    event.data.used = false;

    if (container.isMultiRow) {
      container.rows = container.rows.filter(row => {
        // eslint-disable-next-line eqeqeq
        return row.rowDetailKey != event.data.id;
      });
    }

    this.recalc(container);
  }

  public changeMarkForItmDetails(checked: boolean, container) {
    container.rowDetail.forEach(tran => {
      if (!this.hasCheckedTwin(tran)) {
        tran.used = checked;
      } else {
        tran.used = false;
      }
    });
    if (container.isMultiRow) {
      container.rows = this.buildMultiRow(container.rowDetail);
    } else {
      this.buildMultiRow(container.rowDetail);
    }

    this.recalc(container);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.fullInvoiceToOpen) {
      this.invoiceCreatedWithUseRowBasedTaxReduction =
        !this.fullInvoiceToOpen.source.isLegacyTaxReductionInvoice;

      for (const project of this.fullInvoiceToOpen.projects ?? []) {
        this.updateInvoiceRowDropDownTrueId(project);
      }

      this.parsedInvoiceData = this.fullInvoiceToOpen;
      this.rowDataContainers = this.groupDetails().filter(
        itm => itm.mainTypeId === RowType.StandAloneRow
      );
      this.fromInvoice = true;
      this.rowDataContainers.forEach(itm => this.buildRows(itm));
      this.changeInvoiceMode();
    }

    if (
      changes.selectByDateRange &&
      changes.selectByDateRange.currentValue &&
      this.rowDataContainers
    ) {
      // Update selections
      this.rowDataContainers = this.invoiceBodyService.selectByDate(
        changes.selectByDateRange.currentValue,
        this.rowDataContainers
      );

      // Update totals
      this.rowDataContainers.forEach(container => this.recalc(container));
      this.calculateTotal();
    }
  }

  updateInvoiceRowDropDownTrueId(valueForCheck) {
    const exists = this.projectTrueIds.find(
      itm => itm.value === valueForCheck.id
    );
    if (!exists) {
      // If id does not exist on the dropdown, append the array
      this.projectTrueIds.splice(1, 0, {
        label: `${valueForCheck.trueId}, ${valueForCheck.mark}`,
        value: valueForCheck.id,
      });
    }
  }

  ngOnDestroy() {
    clearInterval(this.calcTotalInterval);
    this.invoiceToRowsub && this.invoiceToRowsub.unsubscribe();
    this.rotSubscription && this.rotSubscription.unsubscribe();
    this.projectsDropdownSub && this.projectsDropdownSub.unsubscribe();
    this.invoiceSubscription && this.invoiceSubscription.unsubscribe();
    this.invoiceModeSubscription && this.invoiceModeSubscription.unsubscribe();
    this.invoiceRowsByModeUpdateSub &&
      this.invoiceRowsByModeUpdateSub.unsubscribe();
    this.companyProductsSub && this.companyProductsSub.unsubscribe();

    this.getSelectedProjectsAtasSub.unsubscribe();
    this.getLoadedProjectsAtasSub.unsubscribe();
  }

  get DynamicFormControls(): FormArray {
    return this.invoiceBodyForm.get('rowDataContainers') as FormArray;
  }

  private invoiceRow(container = null, parsedInvoiceData = null) {
    const row = {
      arbetskostnad: false,
      antal: null,
      apris: null,
      benamning: '',
      discount: null,
      enhet: this.units[0].value,
      pris: null,
      tax: 25,
      open: true,
      houseWorkHoursToReport: null,
      houseWorkType: null,
      project_id: null,
      product_id: null,
      trueId: null,
      invoiceId: 0,
      accountingPlanAccountId: null,
      rowDetailKey: null,
    };

    if (container) {
      // Setting accountingPlanAccountId from transactions when loading projects into invoice
      row.accountingPlanAccountId = +parsedInvoiceData.transactionTypes.find(
        invoiceData => invoiceData.id === container.subTypeId
      ).accountingPlanAccountId;

      // Setting accountingPlanAccountId from existing invoice
      if (!row.accountingPlanAccountId) {
        row.accountingPlanAccountId =
          +container.rowDetail[0].accountingPlanAccountId;
      }

      // eslint-disable-next-line eqeqeq
      if (container.mainTypeId == this.mainTypes[3].value) {
        row.benamning = parsedInvoiceData.transactionTypes.find(
          itm => itm.id === container.subTypeId
        ).name;
      } else {
        row.benamning = parsedInvoiceData.transactionTypes.find(
          itm => itm.id === container.subTypeId
        ).typeName;
        if (
          (container.rowDetail[0].isExtra ||
            this.parsedInvoiceData.projects.length > 1) &&
          !this.hasIDTag(row.benamning)
        ) {
          row.benamning += ' (';
          if (this.parsedInvoiceData.projects.length > 1) {
            row.benamning += parsedInvoiceData.transactionTypes.find(
              itm => itm.id === container.subTypeId
            ).projectTrueId;
            if (container.rowDetail[0].isExtra) {
              row.benamning += ' ';
            }
          }
          if (container.rowDetail[0].isExtra) {
            row.benamning += 'Extra';
          }
          row.benamning += ')';
        }
      }
      row.antal = this.calcAntal(container);
      row.apris = container.rowDetail[0].apris;
      row.houseWorkHoursToReport =
        container.rowDetail[0].houseWorkHoursToReport;
      row.houseWorkType = container.rowDetail[0].houseWorkType;
      row.pris = this.calcRowPrice(container.rowDetail);
      if (container.unit) {
        row.enhet = container.unit;
      } else {
        row.enhet = this.isUnitSame(container.rowDetail)
          ? container.rowDetail[0].enhet
          : null;
      }

      if (
        container &&
        (container.mainTypeId === RowType.Products ||
          container.mainTypeId === RowType.ProductsExtra ||
          container.mainTypeId === RowType.Offer ||
          container.mainTypeId === RowType.ATA) &&
        container.isFree
      ) {
        row.apris = row.pris;
      }
      row.tax = container.rowDetail[0].tax;
      row.project_id = container.rowDetail[0].project_id;
      row.product_id = container.rowDetail[0].product_id;
      row.discount = container.rowDetail[0].discount;
      row.invoiceId = container.rowDetail[0].invoiceId;
      row.arbetskostnad =
        container.rowDetail && container.rowDetail[0].arbetskostnad === 1
          ? true
          : false;
      row.trueId = container.rowDetail ? container.rowDetail[0].trueId : null;
      row.rowDetailKey = container.rowDetail ? container.rowDetail[0].id : null;
    }
    return row;
  }

  public labelToProjectTrueId(label: string): string {
    return label.split(',').shift();
  }

  public labelToAccountingPlanAccountNumber(label: string): string {
    return label.split(' -').shift();
  }

  hasIDTag(benamning: string): boolean {
    return benamning.endsWith(')');
  }

  public addEmptyRowContainer(): void {
    const row = this.invoiceRow();
    const projectIds = this.rowDataContainers
      .map(c => c.rows)
      .flat()
      .map(r => r?.project_id);

    const projectId: string = projectIds.at(-1);
    const item = this.projectTrueIds.find(i => i.value === projectId);

    if (item) {
      this.setRowProjectId(row, item);
    }

    const or = {
      mainTypeId: RowType.StandAloneRow,
      subTypeId: 0, // timereport type and product type, OBS should be devided
      isExtra: false,
      isFree: true, // why, this is connected to recalc function, nice if abel to skip
      empty: true,
      rows: [row],
    };
    this.rowDataContainers.push(or);
    this.DynamicFormControls.push(new FormControl(or));
  }

  private clearOpenedProject(state) {
    // Clear opened project from view
    if (!state) {
      return;
    } // if state === false
    this.rowDataContainers.splice(0, this.rowDataContainers.length);
    this.selectedProjectId = null;
    this.rowLoading = false;
    this.projects = {}; //  empty projects visually
    this.parsedInvoiceData = {
      transactionTypes: [],
      transactions: [],
      projects: [],
    };
    this.addEmptyRowContainer();
  }

  private _resetForm() {
    while (this.DynamicFormControls.length !== 0) {
      this.DynamicFormControls.removeAt(0);
    }
    this.calculateTotal();
  }

  /**
   * Deletes a single row
   */
  public actionDeleteRow(itm, ix) {
    this.confirmationService.confirm({
      message: 'Vill du verkligen ta bort raden?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => this.deleteRow(itm, ix),
    });
  }

  private deleteRow(itm: any, ix: number): void {
    if (
      this.rowDataContainers[ix]['rowDetail'] &&
      this.rowDataContainers[ix]['rowDetail'].length > 0
    ) {
      const count = this.rowDataContainers.filter(
        obj =>
          obj['rowDetail'] &&
          obj['rowDetail'][0]['project_id'] !== null &&
          obj['rowDetail'][0]['project_id'] ===
            this.rowDataContainers[ix]['rowDetail'][0]['project_id']
      ).length;

      if (count === 1) {
        this.invoiceStateService.removeFrmSelectedProjects(
          this.rowDataContainers[ix]['rowDetail'][0]['project_id']
        );
      }
    }

    const rowIndex = (this.rowDataContainers[ix]['rows'] as []).findIndex(row =>
      Object.is(row, itm)
    );

    if (rowIndex !== -1) {
      this.rowDataContainers[ix]['rows'].splice(rowIndex, 1);
    }

    if (!itm.mileRow) {
      if (itm.rowDetailKey && this.rowDataContainers[ix].isMultiRow) {
        this.rowDataContainers[ix]['rowDetail']
          .filter(rd => rd.id === itm.rowDetailKey)
          .forEach(rd => (rd.used = false));

        this.rowDataContainers[ix]['rowDetailSelected'] =
          this.rowDataContainers[ix]['rowDetailSelected'].filter(
            rd => rd.id !== itm.rowDetailKey
          );
      } else {
        this.rowDataContainers[ix]['rowDetail'].forEach(
          rd => (rd.used = false)
        );
        this.rowDataContainers[ix]['rowDetailSelected'] = [];
      }
    }

    this.DynamicFormControls.insert(
      ix,
      new FormControl(this.rowDataContainers[ix])
    );
    this.DynamicFormControls.removeAt(ix + 1);
  }

  public buildRows(
    container: any,
    parsedInvoiceData = this.parsedInvoiceData
  ): void {
    const containerRows = container.rows;
    container.rows = [];
    if (!container.isMultiRow) {
      container.isAprisSame = this.isAPrisSame(container.rowDetail);
      container.rows.push(this.invoiceRow(container, parsedInvoiceData));
    } else {
      container.rowDetail.forEach((_, i: number) => {
        container.rowDetail[i].discount = 0;
        if (containerRows && containerRows[0]) {
          container.rowDetail[i].discount = containerRows[0].discount;
          container.rowDetail[i].tax = containerRows[0].tax;
          container.rowDetail[i].accountingPlanAccountId =
            containerRows[0].accountingPlanAccountId;
        }
      });
      container.rows = this.buildMultiRow(container.rowDetail);
    }

    const miles = this.calcMiles(container.rowDetail);

    if (miles) {
      this.addMileRow(container.rows, miles, container.rowDetail);
    }

    if (container.mainTypeId !== RowType.Offer) {
      container.rowDetail = container.rowDetail.sort((a, b) =>
        a.date > b.date ? -1 : a.date < b.date ? 1 : 0
      );
    }

    container.rowDetailSelected = Object.assign(
      [],
      container.rowDetail.filter(d => d.used)
    );
    this.recalc(container);
  }

  public changeType(ix: number, container: any, addByUserOnRow = false) {
    this.changeDetail(container, this.parsedInvoiceData);

    container.rowDetail.length > 0
      ? this.buildRows(container, this.parsedInvoiceData)
      : (container.rows = []);
    if (addByUserOnRow) {
      container.isAddrdToForm = true;
      this.DynamicFormControls.insert(ix, new FormControl(container));
      this.DynamicFormControls.removeAt(ix + 1);
    }
  }

  public setFilterSubtypes(id: number, row) {
    const retval = this.parsedInvoiceData.transactionTypes
      .filter(itm => itm.mainTypeId === +id)
      .map(itm => ({ value: itm.id, label: itm.name }));
    this.filterSubtypes[row] = retval;
  }

  public hasCheckedTwin(item: any) {
    if (item.used) {
      return false;
    } // guard - this is the checked node
    return (
      undefined !==
      this.usedRowDetails.find(urd => urd.id === item.id && urd.used === true)
    );
  }

  public recalc(container: any, rix: number = null) {
    // OBS MANI ABS REMOVED RIX IN CODE 2018-06-15
    container =
      this.globalInvoiceService.replaceCommaWithDotOnInvoiceRows(container);

    if (!(container.isFree || container.isMultiRow)) {
      const antal = this.calcAntal(container);
      const cal = this.calcRowPrice(container.rowDetail);
      let mult = 0;
      if (container.rows[0]) {
        mult = container.rows[0].discount / 100;
      }
      const totprice = cal - cal * mult;

      if (
        container.mainTypeId === RowType.Products ||
        container.mainTypeId === RowType.ProductsExtra ||
        container.mainTypeId === RowType.Offer ||
        container.mainTypeId === RowType.ATA
      ) {
        container.rows[0].apris = cal;
      }

      container.rows[0].antal = antal;
      container.rows[0].pris = totprice;

      const miles = this.calcMiles(container.rowDetail);
      this.setMileRow(container.rows, miles, container.rowDetail);
    }
  }

  // Form Control Code
  public writeValue(val: any[]) {
    this.invoiceBodyForm.patchValue(val);
  }

  public registerOnTouched(fn: any) {}

  public registerOnChange(fn: any) {
    this.invoiceBodyForm.valueChanges.subscribe(fn);
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.invoiceBodyForm.disable() : this.invoiceBodyForm.enable();
  }

  private addMileRow(rows: any[], miles: number, rowDetail): void {
    rows.push({
      benamning: 'Milersättning ' + (rowDetail[0].isExtra ? '(Extra)' : ''),
      apris: rowDetail[0].milesApris,
      antal: miles,
      tax: 25,
      enhet: 'mil',
      discount: 0,
      pris: rowDetail[0].milesApris * miles,
      mileRow: true,
      project_id: rowDetail[0].project_id,
      invoiceId: rowDetail ? rowDetail[0].invoiceId : null,
      trueId: rowDetail ? rowDetail[0].trueId : null,
      accountingPlanAccountId:
        rowDetail[0].accountingPlanAccountId ??
        (this.invoiceType === InvoiceType.InvertedVat
          ? this.reverseVatCompanyMilageForCustomerAccountingPlanAccountId
          : this.companyMilageForCustomerAccountingPlanAccountId),
    });
  }

  private buildMultiRow(rowDetail: any[]): any[] {
    const retval = [];
    rowDetail.forEach(itm => {
      if (itm.used) {
        const product = this.companyProducts.find(
          x => Number(x.id) === itm.product_id
        );
        retval.push({
          rowDetailKey: itm.id,
          benamning: itm.benamning,
          apris: itm.apris,
          antal: itm.antal,
          tax: itm.tax,
          pris: itm.apris * itm.antal,
          enhet: itm.enhet,
          project_id: itm.project_id,
          product_id: itm.product_id,
          invoiceId: itm.invoiceId ? itm.invoiceId : 0,
          discount: itm.discount ? itm.discount : 0,
          arbetskostnad: itm.arbetskostnad === 1,
          trueId: itm.trueId || null,
          accountingPlanAccountId: itm.accountingPlanAccountId,
          product: product || itm.product,
        });
      }
    });
    return retval;
  }

  public getProjectData(rowIndex) {
    this.rowIndex = rowIndex;
    this.rowLoading = true;
    this.rowMessage = [];
    this.rowProjectToGet.emit(this.selectedProjectId);
    this.cdr.detectChanges();
  }

  setContainerRow(element) {
    element.rows = [];
    element.rows.push(this.invoiceRow());
    return {
      ...element,
      rowElement: true,
      open: true,
      rowDetail: [],
      subTypeId: 1,
    };
  }

  private buildContainersSetForm() {
    this.rowDataContainers.forEach(itm => {
      if (
        itm.mainTypeId === RowType.ATA &&
        !this.selectedProjectsAtasIds.includes(
          itm.subTypeId.substring(0, itm.subTypeId.length - 3)
        )
      ) {
        return;
      }

      this.buildRows(itm);
      this.DynamicFormControls.patchValue([]);
      this.DynamicFormControls.push(new FormControl(itm)); // create formArry dynmically
    });
  }

  private changeInvoiceMode() {
    this._resetForm(); // Empty form value
    try {
      this.usedRowDetails = [];

      const types = this.invoiceBodyService.getRowTypesByInvoiceModes(
        this.invoiceMode
      );

      if (this.invoiceMode.includes(InvoiceMode.Free)) {
        this.rowDataContainers.forEach(itm => {
          itm.isFree = true;

          this.DynamicFormControls.patchValue([]);
          this.DynamicFormControls.push(new FormControl(itm));
        });
      } else if (types.length > 0) {
        this.rowDataContainers = this.groupDetails().filter(
          itm => types.indexOf(itm.mainTypeId) > -1
        );
        this.buildContainersSetForm();
        this.filteredMainTypes = this.mainTypes.filter(
          itm => types.indexOf(itm.value) > -1
        );
      } else {
        this.rowDataContainers.splice(0, this.rowDataContainers.length);
        this.addEmptyRowContainer();
      }

      this.calculateTotal();
    } catch (error) {
      console.warn(error);
    }

    if (
      this.companyFunctionsService.companyFunctionIsSet('useFortnoxWarehouse')
    ) {
      const productIds = this.rowDataContainers
        .filter(row => row.rowDetail?.some(x => x.product_id))
        .flatMap(r => r.rowDetail.flatMap(x => x.product_id));
      if (productIds.length > 0) {
        this.apolloQueryService
          .apolloWatchQueryTwoQuery(
            gql`
              query fetchProducts($productIds: [Int]) {
                company {
                  products(multiId: $productIds) {
                    edges {
                      node {
                        id
                        artnr
                        benamning
                      }
                    }
                  }
                }
              }
            `,
            {
              productIds,
            },
            'no-cache'
          )
          .subscribe(result => {
            this.fixedCompanyProducts = result.data.company.products.edges.map(
              e => ({
                id: e.node.id,
                artnr: e.node.artnr,
                benamning: e.node.benamning,
              })
            );
            this.companyProducts = this.fixedCompanyProducts;
          });
      }

      const expandableRows = this.rowDataContainers.filter(row =>
        row.rowDetail?.some(x => x.product_id)
      );
      for (const expandableRow of expandableRows) {
        expandableRow.isMultiRow = true;
        this.buildRows(expandableRow, this.parsedInvoiceData);
      }
    }
    this.countNumberOfRows(this.rowDataContainers.length);
  }

  private countNumberOfRows(rows) {
    // if rows < 1 append row
    if (rows < 1) {
      this.addEmptyRowContainer();
    }
  }

  private calcAntal(container: any) {
    if (
      container.mainTypeId === RowType.Products ||
      container.mainTypeId === RowType.ProductsExtra ||
      container.mainTypeId === RowType.Offer ||
      container.mainTypeId === RowType.ATA
    ) {
      return 1;
    }

    if (
      container.rowDetail.length > 1 &&
      container.mainTypeId !== 1 &&
      !container.isAprisSame
    ) {
      return 1;
    }

    return container.rowDetail
      .filter(itm => itm.used)
      .reduce((a, c) => a + c.antal, 0);
  }

  private calcMiles = (rowDetail: any[]) =>
    rowDetail.filter(itm => itm.used).reduce((a, c) => a + c.miles, 0);

  private calcRowPrice(rowDetail: any[]) {
    return rowDetail
      .filter(itm => itm.used)
      .reduce((a, c) => {
        if (c.tax < 99) {
          const psc = c.antal;
          return Math.round(c.apris * psc * 100) / 100 + a;
        }
      }, 0);
  }

  public calculateTotal(): any {
    let invoiceAmounts = this.globalInvoiceService.sumInvoiceRows(
      this.invoiceType,
      this.rowDataContainers
    );

    if (this.invoiceType === InvoiceType.InvertedVat) {
      this.invoiceTypeSubInfo =
        'Ingen moms har debiterats på denna faktura, eftersom det är köparen som är skyldig att betala mervärdesskatt till staten enligt bestämmelser i Mervärdesskattelagen 1 kap 2 § första stycket 4B.'; // Omvänd skattskyldighet. Kundens momsregistreringsnummer: SEadsd01
    }

    this.companyInvoiceService.invoiceFormsChange$.subscribe(forms => {
      const taxReductionErrors: string[] = [];

      const taxReductionExtra = forms['rot'];
      const taxReductionPersonInfo = forms['rotPersonInfo'];
      const invoiceForm = forms['invoice'];

      if (
        typeof taxReductionExtra !== 'undefined' &&
        typeof taxReductionPersonInfo !== 'undefined'
      ) {
        if (this.isTaxReduction) {
          if (this.invoiceCreatedWithUseRowBasedTaxReduction) {
            const isTaxReductionDefinitionMissing =
              this.globalInvoiceService.isTaxReductionDefinitionMissing(
                this.rowDataContainers,
                this.invoiceType
              );
            if (isTaxReductionDefinitionMissing) {
              const taxReductionTypeName =
                this.globalInvoiceService.getInvoiceTypeString(
                  this.invoiceType
                );
              taxReductionErrors.push(
                'För raderna måste posterna "' +
                  taxReductionTypeName +
                  ' Antal" och "' +
                  taxReductionTypeName +
                  ' typ" anges'
              );
            }
          } else {
            if (
              Object.keys(taxReductionExtra.value)
                .map(key => taxReductionExtra.value[key])
                .join('').length < 1
            ) {
              taxReductionErrors.push(
                'Någon av posterna "Utfört arbete" måste anges'
              );
            }
          }

          let wantedWorkAmountAllPersons = 0;
          let totalTaxReductionPossible = 0;
          const persons = taxReductionPersonInfo.value.persons;

          for (const person of persons) {
            wantedWorkAmountAllPersons += person.wantedWorkAmount * 1;
            totalTaxReductionPossible += person.customerWantedWorkAmount * 1;
          }

          if (totalTaxReductionPossible < invoiceAmounts.taxReduction.value) {
            invoiceAmounts.taxReduction = currency(totalTaxReductionPossible);
          }

          if (
            invoiceAmounts.taxReduction.value > 0 &&
            wantedWorkAmountAllPersons !== invoiceAmounts.taxReduction.value
          ) {
            // User has provided incorrect tax reduction amount.
            taxReductionErrors.push(
              'Skattereduktionen på ' +
                invoiceAmounts.taxReduction +
                ' är inte rätt fördelad'
            );
          }

          setTimeout(() => {
            if (taxReductionErrors.length) {
              taxReductionErrors.unshift(
                'Var vänlig åtgärda följande inmatningsfel:'
              );
              this.taxReductionErrors = taxReductionErrors.join('<br>');
            } else {
              this.taxReductionErrors = null;
            }
            this.invoiceStateService.setTaxReductionErrors(
              this.taxReductionErrors
            );
          }, 0);
        }
      }

      let lockSaveButton = false;
      if (
        invoiceForm &&
        invoiceForm.value['kreditfaktura'] === 1 &&
        invoiceAmounts.sum.value > 0
      ) {
        // credit but sum is not negative
        this.taxReductionErrors = 'Kreditfaktura måste vara negativ';
        lockSaveButton = true;
      }

      invoiceAmounts =
        this.globalInvoiceService.calculateInvoiceAmounts(invoiceAmounts);
      this.sumChanged.emit({
        totalBox: invoiceAmounts.total,
        sumBox: invoiceAmounts.exclVat,
        netto: invoiceAmounts.net,
        momsBox: invoiceAmounts.vat,
        bruttoBox: invoiceAmounts.sum,
        sumRounded: invoiceAmounts.sumRounded,
        rotBox: invoiceAmounts.taxReduction,
        rounding: invoiceAmounts.rounded,
        brutto: invoiceAmounts.gross,
        taxReductionWork: invoiceAmounts.taxReductionWork,
        taxReductionMaterial: invoiceAmounts.taxReductionMaterial,
        lockSaveButton: lockSaveButton,
      });
    });
  }

  get transactions(): FormArray {
    return this.invoiceBodyForm.get('transactions') as FormArray;
  }

  public toggleRows(itm, row = null, status = null) {
    if (row !== null && status !== null) {
      if (this.rowsOpened[row]) {
        delete this.rowsOpened[row];
        delete this.filterSubtypes[row];
      } else {
        this.rowsOpened[row] = status;
      }
    }
    itm.open = !itm.open;
  }

  private changeDetail(itm, parsedInvoiceData = this.parsedInvoiceData) {
    const transactions = parsedInvoiceData.transactions.filter(
      rd =>
        rd.mainTypeId === itm.mainTypeId &&
        rd.subTypeId === itm.subTypeId &&
        rd.isExtra === itm.isExtra
    );
    itm.rowDetail.forEach(element => {
      const ix = this.usedRowDetails.findIndex(urd => urd === element);
      this.usedRowDetails.splice(ix, 1);
    });
    itm.rowDetail = [];

    transactions.forEach(rd => {
      const rdCopy = Object.assign({}, rd);
      rdCopy.used = !this.usedRowDetails.some(
        urd => urd.id === rd.id && urd.used === true
      );
      this.usedRowDetails.push(rdCopy);
      itm.rowDetail.push(rdCopy);
    });
  }

  private createForm() {
    this.invoiceBodyForm = this.fb.group({
      rowDataContainers: this.fb.array([]),
    });
    // Send form to service for mutation
    this.companyInvoiceService.updateForm('invoiceRows', this.invoiceBodyForm);
  }

  private setThreeRows = () => {
    this._resetForm();
    for (let index = 0; index < 3; index++) {
      this.addEmptyRowContainer();
    }
  };

  private groupDetails(parsedInvoiceData = this.parsedInvoiceData) {
    const retval = [];
    parsedInvoiceData.transactions?.forEach(element =>
      this.groupDetail(element, retval, parsedInvoiceData)
    );
    return retval;
  }

  private groupDetail(element, elements: any[], parsedInvoiceData) {
    const found = elements.find(
      itm =>
        itm.rowDetail[0].mainTypeId === element.mainTypeId &&
        itm.rowDetail[0].subTypeId === element.subTypeId &&
        itm.rowDetail[0].isExtra === element.isExtra // MANI NEEDED ON CHANGE 2018-11-01 ?
    );
    element.used = true;
    element.isFree = false;
    // need to make a copy
    element = Object.assign({}, element);
    this.usedRowDetails.push(element);
    if (found) {
      found.rowDetail.push(element);
    } else {
      const item = {
        rowDetail: [element],
        mainTypeId: this.mainTypes.find(itm => itm.value === element.mainTypeId)
          .value,
        subTypeId: parsedInvoiceData.transactionTypes.find(
          itm => itm.id === element.subTypeId
        ).id,
        isExtra: element.isExtra,
        daTEst: 'hej',
        unit: parsedInvoiceData.transactionTypes.find(
          itm => itm.id === element.subTypeId
        ).unit
          ? parsedInvoiceData.transactionTypes.find(
              itm => itm.id === element.subTypeId
            ).unit
          : null,
        // eslint-disable-next-line eqeqeq
        isMultiRow: element.mainTypeId == RowType.Installaments,
      };
      elements.push(item);
    }
  }

  private isAPrisSame = (rowDetail: any[]) =>
    rowDetail.every(itm => itm.apris === rowDetail[0].apris);

  private isUnitSame = (rowDetail: any[]) =>
    rowDetail.every(itm => itm.enhet === rowDetail[0].enhet);

  private setMileRow(rows: any[], miles: number, rowDetail): void {
    const mileRow = rows.find(itm => itm.mileRow);

    if (mileRow && miles >= 0) {
      mileRow.antal = miles;
      mileRow.pris = rowDetail[0].milesApris * miles; // rowDetail[0].milesApris * miles;
    }
    if (mileRow && miles <= 0) {
      rows.splice(
        rows.findIndex(itm => itm.mileRow),
        1
      );
    }
    if (!mileRow && miles > 0) {
      this.addMileRow(rows, miles, rowDetail);
    }
  }

  public actionSetProjectId(row, item): void {
    const onlyOneRow =
      this.rowDataContainers.map(c => c.rows).flat().length === 1;

    if (onlyOneRow) {
      this.setRowProjectId(row, item);
      return;
    }

    this.confirmationService.confirm({
      header: 'Koppla alla rader?',
      message: `Vill du koppla samtliga rader på fakturan till projekt ${item.label}?`,
      accept: () => {
        this.setAllRowsProjectID(item);
      },
      reject: () => {
        this.setRowProjectId(row, item);
      },
    });
  }

  private setRowProjectId(row, item): void {
    row.project_id = item.value;
    row.trueId = this.labelToProjectTrueId(item.label);
  }

  private setAllRowsProjectID(item): void {
    this.rowDataContainers.forEach(container => {
      container.rows?.forEach(row => {
        this.setRowProjectId(row, item);
      });
    });
  }

  public setHouseWorkType(type: string): void {
    this.confirmationService.confirm({
      header: 'Sätt ROT-typ på samtliga rader?',
      message: 'Vill du sätta ROT-typen på samtliga rader på fakturan?',
      accept: () => {
        this.rowDataContainers.forEach(c =>
          c.rows.forEach(r => (r.houseWorkType = type))
        );
      },
    });
  }
}
