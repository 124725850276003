import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  GetInvoicesGQL,
  GetSingleInvoiceGQL,
  GetSingleSupplierInvoiceGQL,
  GetSupplierInvoicesGQL,
  InvoiceSearchGQL,
  InvoiceSelectFragmentFragment,
  SupplierInvoiceSearchGQL,
} from './graphql/invoice-select.generated';
import {
  DropdownSelectListGroup,
  DropdownSelectListItem,
} from '../../../shared/dropdown-select/dropdown-select.d';
import { first } from 'rxjs';

@Component({
  selector: 'app-invoice-select',
  templateUrl: './invoice-select.component.html',
  styleUrls: ['./invoice-select.component.scss'],
})
export class InvoiceSelectComponent implements OnInit, OnChanges {
  @Input() public invoiceId: number;
  @Input() public disabled = false;
  @Input() public isSupplierInvoice = false;
  @Output() private selectedInvoiceEvent = new EventEmitter<number>();

  public loading = false;
  public groups: DropdownSelectListGroup[];
  public selectedItem: DropdownSelectListItem;

  constructor(
    private getInvoicesService: GetInvoicesGQL,
    private getSupplierInvoicesService: GetSupplierInvoicesGQL,
    private getSingleInvoiceService: GetSingleInvoiceGQL,
    private getSingleSupplierInvoiceService: GetSingleSupplierInvoiceGQL,
    private invoiceSearchService: InvoiceSearchGQL,
    private supplierInvoiceSearchService: SupplierInvoiceSearchGQL
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.invoiceId || !changes.invoiceId) {
      return;
    }
    if (this.isSupplierInvoice) {
      this.fetchSingleSupplierInvoice(this.invoiceId);
    } else {
      this.fetchSingleInvoice(this.invoiceId);
    }
  }

  public ngOnInit(): void {
    this.prePopulate();
  }

  public onSearch(query: string): void {
    this.loading = true;
    if (this.isSupplierInvoice) {
      this.searchSupplierInvoices(query);
    } else {
      this.searchInvoices(query);
    }
  }

  private searchInvoices(query: string): void {
    this.invoiceSearchService
      .fetch({ searchString: query })
      .pipe(first())
      .subscribe(res => {
        const invoices = res.data.company.invoiceSearch.edges.map(e => e.node);
        this.setGroupItems(invoices);
      });
  }

  private searchSupplierInvoices(query: string): void {
    this.supplierInvoiceSearchService
      .fetch({ searchString: query })
      .pipe(first())
      .subscribe(res => {
        const invoices = res.data.company.supplierInvoiceSearch.edges.map(
          e => e.node
        );
        this.setGroupItems(invoices);
      });
  }
  private fetchSingleInvoice(id: number): void {
    this.getSingleInvoiceService
      .fetch({ id: id })
      .pipe(first())
      .subscribe(res => {
        const invoice = res.data.company.invoices.edges[0].node;
        this.selectedItem = this.getListItem(invoice);
      });
  }
  private fetchSingleSupplierInvoice(id: number): void {
    this.getSingleSupplierInvoiceService
      .fetch({ id: id })
      .pipe(first())
      .subscribe(res => {
        const invoice = res.data.company.supplierInvoices.edges[0].node;
        this.selectedItem = this.getListItem(invoice);
      });
  }

  private fetchInvoices(): void {
    this.getInvoicesService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const invoices = res.data.company.invoices.edges.map(e => e.node);
        this.setGroupItems(invoices);
      });
  }

  private fetchSupplierInvoices(): void {
    this.getSupplierInvoicesService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const invoices = res.data.company.supplierInvoices.edges.map(
          e => e.node
        );
        this.setGroupItems(invoices);
      });
  }

  public prePopulate(): void {
    this.loading = true;
    if (this.isSupplierInvoice) {
      this.fetchSupplierInvoices();
    } else {
      this.fetchInvoices();
    }
  }

  public onSelection(item: DropdownSelectListItem): void {
    if (!item?.id) {
      return;
    }
    this.prePopulate();
    this.selectedInvoiceEvent.emit(Number(item.id));
  }

  private setGroupItems(invoices: InvoiceSelectFragmentFragment[]): void {
    const listItems = invoices.map(i => this.getListItem(i));
    this.groups = [{ label: '', items: listItems }];
    this.loading = false;
  }

  private getListItem(
    invoice: InvoiceSelectFragmentFragment
  ): DropdownSelectListItem {
    return {
      id: invoice.id,
      displayId: invoice.fakturaNr,
      label: '',
      metadata: {},
    };
  }
}
