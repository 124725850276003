<form *ngIf="headCompanyForm" [formGroup]="headCompanyForm" class="p-grid">
  <div class="p-col-12 p-md-4 p-grid p-ac-start">
    <span class="p-col-12">
      <label>Namn</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input pInputText formControlName="companyName" />
      </span>
    </span>
    <span class="p-col-12">
      <label>Address rad 1</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input pInputText formControlName="companyAdress" />
      </span>
    </span>
    <span class="p-col-12">
      <label>Address rad 2</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input pInputText formControlName="companyAddressRow2" />
      </span>
    </span>
  </div>
  <div class="p-col-12 p-md-4 p-grid p-ac-start">
    <span class="p-col-12">
      <label>Webbsida</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-link"></i>
        </span>
        <input pInputText formControlName="companyWebb" />
      </span>
    </span>
    <span class="p-col-6">
      <label>Telefon</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-phone"></i>
        </span>
        <input pInputText formControlName="companyTlfn" />
      </span>
    </span>
    <span class="p-col-6">
      <label>Fax</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-print"></i>
        </span>
        <input pInputText formControlName="companyFax" />
      </span>
    </span>
    <span class="p-col-12">
      <label>E-mail</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
        </span>
        <input pInputText formControlName="companyMail" />
      </span>
    </span>
  </div>

  <div class="p-col-12 p-md-4 p-grid">
    <span class="p-col-12">
      <label>Bankgiro</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-credit-card"></i>
        </span>
        <input pInputText formControlName="companyBankGiro" />
      </span>
    </span>
    <span class="p-col-12">
      <label>Plusgiro</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-credit-card"></i>
        </span>
        <input pInputText formControlName="companyPostGiro" />
      </span>
    </span>
    <span class="p-col-12">
      <label>IBAN</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-credit-card"></i>
        </span>
        <input pInputText formControlName="companyIban" />
      </span>
    </span>
    <span class="p-col-12">
      <label>BIC/SWIFT</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-credit-card"></i>
        </span>
        <input pInputText formControlName="companyBicSwift" />
      </span>
    </span>
    <span class="p-col-12">
      <label>Org.nr</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-building"></i>
        </span>
        <input pInputText formControlName="companyOrgNr" />
      </span>
    </span>
  </div>
</form>
