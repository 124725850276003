import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProjectTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetProjectTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectTypes?: { __typename?: 'CompanyProjectTypeConnection', edges?: Array<{ __typename?: 'CompanyProjectTypeEdge', node?: { __typename?: 'CompanyProjectType', id: string, name?: string | null, prefix?: string | null } | null } | null> | null } | null } | null };

export const GetProjectTypesDocument = gql`
    query getProjectTypes {
  company {
    projectTypes {
      edges {
        node {
          id
          name
          prefix
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectTypesGQL extends Apollo.Query<GetProjectTypesQuery, GetProjectTypesQueryVariables> {
    document = GetProjectTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }