import { Injectable } from '@angular/core';
import PROJECT_STATUS from 'app/shared/global/project-status.enum';
import { MessageService } from 'app/shared/message';
import { Project } from 'generated/types';
import { ConfirmationService } from 'primeng/api';
import { first, Observable, Subject } from 'rxjs';
import { MoveProjectGQL } from './project-list/graphql/projects.generated';

@Injectable({
  providedIn: 'root',
})
export class ProjectMoveService {
  constructor(
    private confirmationService: ConfirmationService,
    private moveProjectGQL: MoveProjectGQL,
    private messageService: MessageService
  ) {}

  public moveProjects(
    projects: Project[],
    status: PROJECT_STATUS
  ): Observable<boolean> {
    const obs = new Subject<boolean>();
    const hasSubProjects = projects.some(p => p.subProjectCount > 0);
    const isDeleted = projects.some(p => p.status === PROJECT_STATUS.DELETED);

    const ids = projects.map(p => Number(p.id));
    this.askToMove(hasSubProjects, isDeleted, status)
      .pipe(first())
      .subscribe(({ move, includeSubProjects }) => {
        if (!move) {
          obs.next(true);
          return;
        }
        this.moveProjectGQL
          .mutate({
            ids: ids,
            status: status,
            includeSubProjects: includeSubProjects,
          })
          .pipe(first())
          .subscribe(res => {
            const success = res.data.moveProjectToStatusMutation.every(r =>
              r.mutationDetails.every(md => md.mutationSucceeded)
            );
            obs.next(success);
            this.messageService.insertDataFromMutation(
              res.data.moveProjectToStatusMutation
            );
          });
      });

    return obs;
  }

  public moveProject(
    project: Project,
    status: PROJECT_STATUS
  ): Observable<boolean> {
    const obs = new Subject<boolean>();

    const hasSubProjects = project.subProjectCount > 0;
    const isDeleted = project.status === PROJECT_STATUS.DELETED;

    this.askToMove(hasSubProjects, isDeleted, status)
      .pipe(first())
      .subscribe(({ move, includeSubProjects }) => {
        if (!move) {
          obs.next(true);
          return;
        }
        this.moveProjectGQL
          .mutate({
            ids: [Number(project.id)],
            status: status,
            includeSubProjects: includeSubProjects,
          })
          .pipe(first())
          .subscribe(res => {
            const success = res.data.moveProjectToStatusMutation.every(r =>
              r.mutationDetails.every(md => md.mutationSucceeded)
            );
            obs.next(success);
            this.messageService.insertDataFromMutation(
              res.data.moveProjectToStatusMutation
            );
          });
      });
    return obs;
  }

  private askToMove(
    hasSubProjects: boolean,
    isDeleted: boolean,
    status: PROJECT_STATUS
  ): Observable<{ move: boolean; includeSubProjects: boolean }> {
    const obs = new Subject<{ move: boolean; includeSubProjects: boolean }>();

    let message: { header: string; message: string };
    let subProjectMessage: { header: string; message: string } = {
      header: 'Flytta underprojekt?',
      message: 'Vill du flytta underprojekten också?',
    };

    switch (status) {
      case PROJECT_STATUS.ONGOING:
        message = {
          header: 'Flytta till pågående?',
          message: 'Vill du flytta projektet till pågående?',
        };
        break;
      case PROJECT_STATUS.PLANNED:
        message = {
          header: 'Flytta till planerade?',
          message: 'Vill du flytta projektet till planerade?',
        };
        break;
      case PROJECT_STATUS.FINISHED:
        message = {
          header: 'Avsluta projekt?',
          message: 'Vill du avsluta projektet?',
        };
        break;
      case PROJECT_STATUS.DELETED:
        message = {
          header: 'Radera projekt?',
          message: 'Vill du radera projektet?',
        };
        break;
      case PROJECT_STATUS.ARCHIVED:
        message = {
          header: 'Arkivera projekt?',
          message: 'Vill du arkivera projektet?',
        };
        break;
    }

    if (isDeleted) {
      message = {
        header: 'Återställ projekt?',
        message: 'Vill du återställa projektet?',
      };
      subProjectMessage = {
        header: 'Återställ underprojekt?',
        message: 'Vill du återställa underprojekten också?',
      };
    }

    this.confirmationService.confirm({
      ...message,
      acceptLabel: 'Ja',
      rejectLabel: 'Nej',
      accept: () => {
        if (!hasSubProjects) {
          obs.next({ move: true, includeSubProjects: false });
          return;
        }

        setTimeout(() => {
          this.confirmationService.confirm({
            ...subProjectMessage,
            acceptLabel: 'Ja',
            rejectLabel: 'Nej',
            accept: () => {
              obs.next({ move: true, includeSubProjects: true });
            },
            reject: () => {
              obs.next({ move: true, includeSubProjects: false });
            },
          });
        }, 300);
      },
      reject: () => {
        obs.next({ move: false, includeSubProjects: false });
      },
    });

    return obs;
  }
}
