import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';

import { HyperionSharedModule } from '../shared/shared.module';
import { SharedDialogModule } from '../shared/dialogs/shared.dialog.module';
import { OrderComponent } from './order/index/index.component';
import { OrderRowComponent } from './order/order/order.component';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    AccordionModule,
    HyperionSharedModule,
    SharedDialogModule,
    HeaderModule,
  ],
  declarations: [OrderComponent, OrderRowComponent],
})
export class PurchaseTransactionModule {}
