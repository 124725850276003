import { Injectable } from '@angular/core';
import { initDB } from '@frecency/indexeddb-datasource';
import { from, map, Observable } from 'rxjs';

const DB_NAME = 'project-frecency';
const NAMESPACE = 'project';
const MAX_RESULTS = 10;

@Injectable({
  providedIn: 'root',
})
export class ProjectFrecencyService {
  private db;

  constructor() {
    this.db = initDB(DB_NAME);
  }

  public addVisit(projectId: number): void {
    from(this.db.visit(NAMESPACE, projectId.toString()));
  }

  public getList(): Observable<number[]> {
    return from(this.db.list(NAMESPACE)).pipe(
      map((list: string[]) => list.slice(0, MAX_RESULTS)),
      map((list: string[]) => list.map((item: string) => Number(item)))
    ) as Observable<number[]>;
  }
}
