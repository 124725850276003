import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { GlobalService } from 'app/shared/global';
import { ConfirmationService } from 'primeng/api';
import { ApolloMutationService } from 'app/shared';
import { CalculationRowType } from '../offer-calculation-rows/offer-calculation-rows.component';
import { SingleOfferService } from '../single-offer.service';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import { first } from 'rxjs/operators';

enum DeromeWebshopStatus {
  Missing = 0,
  Initialized = 1,
  CheckedOut = 2,
  OrderConfirmed = 3,
}

@Component({
  selector: 'derome-webshop-products',
  templateUrl: './derome-webshop-products.component.html',
  styleUrls: ['./derome-webshop-products.component.scss'],
})
@Injectable()
export class DeromeWebshopProducts implements OnInit {
  @Output()
  public hasDeromeWebshopProductsEmitter = new EventEmitter<boolean>();
  @Input() public offerInfo: {
    id?: any;
    status: number;
    currentExternalProject?: string;
    typeOffer?: any;
    groupCosts?: any;
    calculation?: any;
    mode?: string;
    [key: string]: any;
  };

  @Input() public deromeProducts: CalculationRowType[];
  @Output() public actionSave = new EventEmitter();
  @Output() public refreshOffer = new EventEmitter();

  public taxDropdownOptions: any;
  public selectedTaxDropdownItem: number;
  public hasWebshopProducts = false;

  constructor(
    private mutationService: ApolloMutationService,
    private globalService: GlobalService,
    private confirmationService: ConfirmationService,
    private singleOfferService: SingleOfferService
  ) {
    this.taxDropdownOptions = this.globalService.getTaxOptions();
  }

  public ngOnInit(): void {
    this.singleOfferService.webshopRows$.subscribe(
      (webshopRows: CalculationRowType[]) => {
        this.deromeProducts = webshopRows;

        this.hasWebshopProducts = this.deromeProducts.length > 0;

        this.hasDeromeWebshopProductsEmitter.emit(
          Boolean(this.deromeProducts.length)
        );
      }
    );
  }

  public get isWebshopOrderCreated(): boolean {
    return (
      this.offerInfo?.calculation?.deromeWebshopStatus >
      DeromeWebshopStatus.Missing
    );
  }

  public get isWebshopOrderCheckedOut(): boolean {
    return (
      this.offerInfo?.calculation?.deromeWebshopStatus ===
      DeromeWebshopStatus.CheckedOut
    );
  }

  public get isWebshopOrderConfirmed(): boolean {
    return (
      this.offerInfo?.calculation?.deromeWebshopStatus ===
      DeromeWebshopStatus.OrderConfirmed
    );
  }

  public get isWebshopDisabled(): boolean {
    return this.isWebshopOrderCheckedOut || this.isWebshopOrderConfirmed;
  }

  public createPunchOutRequest(): void {
    this.saveOffer();
    this.openWebshop(
      `/deromeWebshop/initializeSession?offerId=${this.offerInfo.id}`
    );
  }

  public editPunchOutRequest(): void {
    this.saveOffer();
    this.openWebshop(
      `/deromeWebshop/editWebshopSession?offerId=${this.offerInfo.id}`
    );
  }

  public checkoutPunchOutRequest(): void {
    this.saveOffer();
    this.openWebshop(
      `/deromeWebshop/checkoutWebshopSession?offerId=${this.offerInfo.id}`
    );
  }

  public handleDeleteButton(deromeProductId: number): void {
    const deromeProduct = this.deromeProducts.find(
      product => product.id === deromeProductId
    );

    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort produkten?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            'userCalculationRow',
            'delete',
            false,
            {
              id: +deromeProduct.id,
            }
          )
          .subscribe(
            (executedData: any) => {
              if (executedData.mutationSucceededAllArguments) {
                this.deromeProducts = this.deromeProducts.filter(
                  (productData: CalculationRowType) =>
                    productData.id !== deromeProductId
                );
              }
              executeMutationSub.unsubscribe();
              this.mutationService.displayMutationStatus(executedData);
            },
            (err: any) => {
              console.error(err);
            }
          );
      },
      reject: () => {},
    });
  }

  public handleShowUnitLabel(unit: string): string {
    return QuantityUnits.find(qtyUnit => qtyUnit.value === unit)?.label || unit;
  }

  public handleUpdate(deromeProductId: number): void {
    const deromeProduct = this.deromeProducts.find(
      product => product.id === deromeProductId
    );

    deromeProduct.order = isNaN(+deromeProduct.order)
      ? null
      : deromeProduct.order;
    deromeProduct.procent = isNaN(parseFloat(deromeProduct.procent as string))
      ? 0
      : deromeProduct.procent;

    const updateDeromeProduct = {
      id: deromeProduct.id,
      cost: deromeProduct.cost,
      listPrice: deromeProduct.listPrice,
      listPriceUnit: deromeProduct.listPriceUnit,
      name: deromeProduct.name,
      order: deromeProduct.order,
      procent: deromeProduct.procent,
      productId: deromeProduct.productId,
      quantity: deromeProduct.quantity,
      source: deromeProduct.source,
      sourceId: deromeProduct.sourceId,
      tax: deromeProduct.tax,
      totalAmount: deromeProduct.totalAmount,
      unit: deromeProduct.unit,
    };

    this.mutationService
      .constructQueryAndExecuteMutation(
        'userCalculationRow',
        'update',
        false,
        updateDeromeProduct,
        [
          'tax',
          'procent',
          'order',
          'rowSumPlusProcent_PreUserCalculationRowTypeHyperion',
        ]
      )
      .pipe(first())
      .subscribe(
        (executedData: any) => {
          if (!executedData.mutationSucceededAllArguments) {
            this.mutationService.displayMutationStatus(executedData);
          }

          deromeProduct.tax = +executedData.tax;
          deromeProduct.procent = executedData.procent;
          deromeProduct.order = executedData.order;
          deromeProduct.rowSumPlusProcent_PreUserCalculationRowTypeHyperion =
            executedData.rowSumPlusProcent_PreUserCalculationRowTypeHyperion;
        },
        (err: any) => {
          console.error(err);
        }
      );
  }

  private saveOffer(): void {
    this.actionSave.emit({
      value: this.offerInfo.id,
      name: 'update',
      variables: {
        status: this.offerInfo.status < 0 ? 0 : this.offerInfo.status,
        id: this.offerInfo.id,
        type: 'offer',
      },
    });
  }

  private openWebshop(url: string) {
    const webshopWindow = window.open(url, '_blank');
    webshopWindow.focus();
    const intervalHandle = setInterval(() => {
      try {
        if (
          webshopWindow.location.host === window.location.host ||
          webshopWindow.closed
        ) {
          clearInterval(intervalHandle);
          if (!webshopWindow.closed) {
            webshopWindow.close();
          }
          this.refreshOffer.emit();
        }
      } catch (ex) {
        // Location is another domain and therefor it cannot be retrieved.
        // This means that the user is not yet redirected back to our application.
      }
    }, 1000);
  }
}
