import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ProjectTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectTypes?: { __typename?: 'CompanyProjectTypeConnection', edges?: Array<{ __typename?: 'CompanyProjectTypeEdge', node?: { __typename?: 'CompanyProjectType', id: string, name?: string | null, prefix?: string | null, formattedName?: string | null } | null } | null> | null } | null } | null };

export const ProjectTypesDocument = gql`
    query projectTypes {
  company {
    projectTypes {
      edges {
        node {
          id
          name
          prefix
          formattedName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectTypesGQL extends Apollo.Query<ProjectTypesQuery, ProjectTypesQueryVariables> {
    document = ProjectTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }