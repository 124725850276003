import { Config } from '../config';
import { config as defaultConfig } from './default.config';

export const config: Config = {
  ...defaultConfig,
  name: 'trebolit',
  subdomain: ['trebolit'],
  title: 'Trebolit',
  theme: {
    ...defaultConfig.theme,
    logoPath: '/images/trebolit/logo.png',
    brandPrimaryColor: '#08aad6',
    brandSecondaryColor: '#c7dbe1',
    topMenuBackgroundColor: '#08aad6',
    favicons: [
      {
        rel: 'shortcut icon',
        href: '/images/trebolit/icon.png',
      },
    ],
  },
};
