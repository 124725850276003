import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as moment from 'moment';

import {
  ApolloErrorHandler,
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { CompanyFunctionsService } from 'app/shared/company/companyFunctions.service';
import {
  CompanyInfo,
  CompanyInfoService,
} from 'app/shared/company/company-info.service';
import { CompanyInvoiceOfferSettingsService } from 'app/shared/company/companyInvoiceOfferSettings.service';
import { CompanyProjectTypeService } from 'app/shared/company/company-project-type.service';
import { CompanyProjectTypeSettingsComponent } from 'app/settings/company/company-project-type/company-project-type-settings.component';
import { ContactsAutosuggestService } from 'app/shared/company/contact/contacts.autosuggest.service';
import { ExternalProject } from 'app/shared/company/derome-integration/external-project.interface';
import { DialogComponent } from 'app/shared/dialogs/dialog.component';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { UserLocalStorageService } from 'app/shared/user/user-local-storage.service';

import { ProjectDefaultTodoService } from 'app/old-project/project-default-todo/project-default-todo.service';

@Component({
  selector: 'project-create',
  templateUrl: 'projectCreate.component.html',
  styleUrls: ['projectCreate.component.scss'],
})
export class ProjectCreateComponent
  extends DialogComponent
  implements OnDestroy, OnInit
{
  useDefualtTodosOnProjects = false;
  todosSaveToProject = [];

  loading = true;
  projectParams;

  buttons = {
    create: {
      label: 'Skicka',
      disabled: false,
    },
  };

  // ----- Dropdowns
  // betalningsvillkor
  betalningsvillkorOptions = [
    { label: '0 dagar', value: 0 },
    { label: '10 dagar', value: 10 },
    { label: '15 dagar', value: 15 },
    { label: '20 dagar', value: 20 },
    { label: '30 dagar', value: 30 },
  ];

  // Forms
  commonFields = {
    orderBuisnessName: '',
    name: '',
    orgNr: '',
    address: '',
    address2: '',
    cityCode: '',
    city: '',
    propertyName: '',
    housingAssociationOrgNumber: '',
    apartmentDesignation: '',
    phone: '',
    mobilePhone: '',
    mail: '',
    trueId: '',
    contact: 0,
    contactType: 'Företag',
  };

  fieldsForForms = {
    clientContactForm: {
      model: 'Contact',
      nestedForm: false,
      attributes: {
        betalningsvillkor: null,
        ...this.commonFields,
      },
    },
    establishmentContactForm: {
      model: 'Contact',
      nestedForm: false,
      attributes: {
        ...this.commonFields,
      },
    },
    projectForm: {
      model: 'Project',
      nestedForm: false,
      attributes: {
        mark: '',
        madeBy: '',
        status: 1,
        offertSum: '',
        offertSumWork: '',
        orderNumber: '',
        typeId: '',
        startDate: this.helperService.dateFormatTimeZone(new Date(), true),
        endDate: '',
        constructionSiteNumber: '',
        externalProjectId: '',
        externalProjectSource: '',
      },
    },
  };

  clientContactForm: FormGroup;
  establishmentContactForm: FormGroup;
  projectForm: FormGroup;

  autoComplete = {
    client: {
      results: new BehaviorSubject([]),
      model: null,
      showSave: new BehaviorSubject(true),
      setFromStart: new BehaviorSubject(false),
    },
    establishment: {
      results: new BehaviorSubject([]),
      model: null,
      showSave: new BehaviorSubject(true),
    },
  };

  activeTab = 'project';

  index = 0;

  // projectStatus
  statusOptions = [];

  statusPreSelected;

  // projectType
  projectTypes = [];
  projectTypesSub: Subscription;

  orientation = 'left';

  establishmentChosen = false;

  functionsThisModel = {};
  public establishmentTypeValue: string;
  public clientTypeValue: string;

  public showProductExternalConnectInterface = false;
  public meUser: MeUserWithCompany;

  readonly CONTACT_TYPE_PRIVAT: string = 'Privat';
  readonly CONTACT_TYPE_FORETAG: string = 'Företag';
  readonly CONTACT_TYPES: string[] = [
    this.CONTACT_TYPE_FORETAG,
    this.CONTACT_TYPE_PRIVAT,
  ];

  constructor(
    protected mutationService: ApolloMutationService,
    protected projectTypeService: CompanyProjectTypeService,
    protected apolloErrorHandler: ApolloErrorHandler,
    protected contactsAutosuggestService: ContactsAutosuggestService,
    protected companyInvoiceOfferSettingsService: CompanyInvoiceOfferSettingsService,
    protected companyFunctionsService: CompanyFunctionsService,
    protected projectDefaultTodoService: ProjectDefaultTodoService,
    protected apolloQueryService: ApolloQueryService,
    public dialogRef: DynamicDialogRef,
    private dialogService: AppDialogService,
    private dialogConfig: DynamicDialogConfig,
    private companyInfoService: CompanyInfoService,
    private userLocalStorageService: UserLocalStorageService
  ) {
    super();
    this.dialogConfig.header = 'Skapa nytt projekt';
  }

  ngOnInit() {
    this.functionsThisModel = this.companyFunctionsService['companyFunctions'];

    this.useDefualtTodosOnProjects =
      this.companyFunctionsService.companyFunctionIsSet(
        'useDefualtTodosOnProjects'
      );

    this.orientation = this.helperService.getOrientation();

    if (this.functionsThisModel['useProjectTypes']) {
      this.getProjectTypes();
    }

    this.projectParams = this.globalService.getModelParams('project');
    this.constructStatusOptions();

    this.initForms();
    this.establishmentTypeValue = this.CONTACT_TYPE_FORETAG;
    this.clientTypeValue = this.CONTACT_TYPE_FORETAG;

    this.companyInfoService.companyInfo$.subscribe(
      (companyInfo: CompanyInfo) => {
        this.showProductExternalConnectInterface =
          companyInfo.showProductExternalConnectInterface;
      }
    );

    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  getCompanyInfo() {
    this.apolloQueryService
      .apolloQuery('companyInvoiceOfferSettings')
      .subscribe(({ data }) => {
        this.clientContactForm.controls['betalningsvillkor'].setValue(
          +data.company.betalningsvillkor
        );
      });
  }

  actionCreate() {
    this.buttons = this.formHandler.lockButtons(this.buttons);
    // If errors in client
    if (
      !this.formHandler.formValid([
        this.clientContactForm,
        this.establishmentContactForm,
        this.projectForm,
      ])
    ) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const createProjectSub = this.createProject().subscribe(data => {
        this.mutationService.displayMutationStatus(data);
        this.formHandler.showServerErrorsOnAttributes(
          data,
          [
            { form: this.projectForm, argument: 'createProject' },
            {
              form: this.establishmentContactForm,
              argument: 'createProjectEstablishmentContact',
            },
            {
              form: this.clientContactForm,
              argument: 'createProjectClientContact',
            },
          ],
          true
        );

        if (data.mutationSucceededAllArguments) {
          this.afterActionCreate(data);
        }

        this.buttons = this.formHandler.unlockButtons(this.buttons);
      });
    }
  }

  afterActionCreate(data) {
    this.dialogRef.close(data);
  }

  createProject() {
    return Observable.create(observer => {
      const projectValues = {
        createProject: {
          ...this.projectForm.value,
          ...{
            status: Number(this.projectForm.controls['status'].value),
            offertSum: Number(this.projectForm.controls['offertSum'].value),
            offertSumWork: Number(
              this.projectForm.controls['offertSumWork'].value
            ),
          },
        },
        createProjectClientContact: {
          ...this.clientContactForm.value,
          ...{
            betalningsvillkor: Number(
              this.clientContactForm.controls['betalningsvillkor'].value
            ),
          },
        },
        createProjectEstablishmentContact: {
          ...this.establishmentContactForm.value,
        },
      };

      if (this.useDefualtTodosOnProjects) {
        this.projectDefaultTodoService.getTodos.next('go');
        if (this.projectDefaultTodoService.todos.length > 0) {
          projectValues['createProject']['defaultTodos'] =
            this.projectDefaultTodoService.todos;
        }
      }

      if (this.functionsThisModel['useProjectTypes']) {
        projectValues['createProject']['typeId'] =
          this.projectForm.controls['typeId'].value;
      }

      const dayMutationObs = this.mutationService
        .staticConstructQueryAndExecuteMutation(
          'createProjectMutation',
          projectValues,
          'project'
        )
        .subscribe(data => {
          observer.next(data);
          observer.complete();
          dayMutationObs.unsubscribe();
        });
    });
  }

  getProjectTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyProjectType')
      .subscribe(({ data, sub }) => {
        this.projectTypesSub = sub;
        this.projectTypes = this.projectTypeService.dataFormater(
          data,
          'labels',
          false
        );
      });
  }

  closeAction() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.projectTypesSub && this.projectTypesSub.unsubscribe();
  }

  initForms() {
    this.fieldsForForms['projectForm']['attributes']['endDate'] = moment()
      .add(21, 'days')
      .format(this.helperService.dateFormat());

    this.projectForm = this.formHandler.groupedFormSimple(
      this.fieldsForForms['projectForm']
    );
    this.clientContactForm = this.formHandler.groupedFormSimple(
      this.fieldsForForms['clientContactForm']
    );
    this.establishmentContactForm = this.formHandler.groupedFormSimple(
      this.fieldsForForms['establishmentContactForm']
    );

    this.getCompanyInfo();

    this.formHandler
      .groupSetLabelsRules(this.fieldsForForms['projectForm'], this.projectForm)
      .then();
    this.formHandler
      .groupSetLabelsRules(
        this.fieldsForForms['clientContactForm'],
        this.clientContactForm
      )
      .then();
    this.formHandler
      .groupSetLabelsRules(
        this.fieldsForForms['establishmentContactForm'],
        this.establishmentContactForm
      )
      .then();
  }

  constructStatusOptions() {
    const statusIds = this.projectParams.status.CONST;
    const statusValues = this.projectParams.status.strings;
    const statusArray = [];

    for (const i in statusIds) {
      if (
        i === 'PLANNED' ||
        i === 'ONGOING' ||
        i === 'FINISHED' ||
        i === 'ARCHIVED'
      ) {
        const option = {
          label: statusValues[statusIds[i]][1][0],
          value: statusIds[i],
        };
        statusArray.push(option);
      }
    }

    this.statusOptions = statusArray;
  }

  toogleDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { isModal: 'true' };
    this.dialogService.openComponent(CompanyProjectTypeSettingsComponent);
  }

  searchContacts(event, model) {
    this.contactsAutosuggestService.getContacts(event.query).then(data => {
      if (data['success']) {
        this.autoComplete[model]['results'].next(data['data']);
      }
      this.messageService.insertDataPOSTGET(data);
    });
  }

  setContactFromAutosuggest(value = {}, model) {
    let form;

    if (model === 'client') {
      form = this.clientContactForm;

      this.clientContactForm.controls['contact'].setValue(0);

      this.autoComplete[model]['model'] = value['trueId'];
      this.autoComplete[model]['setFromStart'].next(true);
      this.autoComplete[model]['showSave'].next(false);
    } else {
      form = this.establishmentContactForm;
    }

    for (const key in value) {
      const valueVal = key === 'betalningsvillkor' ? +value[key] : value[key];
      if (
        key !== 'id' &&
        key !== 'contact' &&
        form['controls'].hasOwnProperty(key)
      ) {
        form['controls'][key].setValue(valueVal);
      }
    }
  }

  removeCoupling() {
    this.clientContactForm.controls['contact'].setValue(0);
    this.clientContactForm.controls['trueId'].setValue('');

    this.autoComplete['client']['showSave'].next(true);
    this.autoComplete['client']['setFromStart'].next(false);
    this.autoComplete['client']['model'] = null;
  }

  copyValuesToEstablishment() {
    for (const key in this.clientContactForm.controls) {
      if (
        this.establishmentContactForm.controls.hasOwnProperty(key) &&
        key !== 'id' &&
        key !== 'contact'
      ) {
        const newValue = this.clientContactForm.controls[key].value;
        this.establishmentContactForm.controls[key].setValue(newValue);
      }
    }

    this.activeTab = 'establishment';
  }

  changeActiveTab(event) {
    const index = event['index'];

    switch (index) {
      case 0:
        this.activeTab = 'project';
        break;
      case 1:
        this.activeTab = 'client';
        break;
      case 2:
        this.activeTab = 'establishment';
        break;
      case 3:
        this.activeTab = 'defaultTodos';
        break;
    }
  }

  scrollToTop() {
    const mdDialogContainer =
      document.getElementsByClassName('p-dialog-content')[0];

    mdDialogContainer.scrollTop = 0;
  }

  public onExternalProjectConnectionChanged(externalProject: ExternalProject) {
    if (!externalProject || !externalProject.id || !externalProject.source) {
      return;
    }

    this.projectForm.controls.externalProjectId.setValue(externalProject.id);
    this.projectForm.controls.externalProjectSource.setValue(
      externalProject.source
    );
  }
}
