import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-docu-dirty-confirm-discard-dialog',
  templateUrl: './docu-dirty-confirm-discard-dialog.component.html',
  styleUrls: ['./docu-dirty-confirm-discard-dialog.component.scss'],
})
export class DocuDirtyConfirmDiscardDialogComponent {
  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.dialogConfig.header = 'Osparade ändringar!';
  }

  closeAction(val: any = 'undefined') {
    this.dialogRef.close(val);
  }
}
