<div class="p-grid">
  <span class="p-col-12">
    <span>
      Välj en rubrik som du vill att arbetsmomentet ska ha, skriv sedan
      arbetsmomentet i rutan och spara det genom att trycka på
      {{ isEdit ? 'spara' : 'skapa' }}. Klicka på pennan för att skapa fler
      rubriker.
    </span>
  </span>
  <span class="p-col-12">
    <label>Ange Rubrik*</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon"> <i class="pi pi-list"></i> </span>
      <p-dropdown
        #drop
        placeholder="Välj rubrik här"
        [options]="todotopics | async"
        [optionLabel]="'Name'"
        [optionValue]="'id'"
        [filter]="true"
        [(ngModel)]="todo.topic.id"
        required
      ></p-dropdown>
      <button
        pButton
        (click)="showHandleHeaders = !showHandleHeaders"
        class="p-inputgroup-addon"
        icon="pi pi-pencil"
        type="button"
      ></button>
    </span>
  </span>
  <span class="p-col-12">
    <label>Arbetsmoment*</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-briefcase"></i>
      </span>
      <textarea
        pInputTextarea
        required
        [(ngModel)]="todo.description"
      ></textarea>
    </span>
  </span>
  <span class="p-col-6" *ngIf="!isCreateDefault">
    <label>Startdatum</label>
    <p-calendar
      [showIcon]="true"
      [(ngModel)]="todo.startDate"
      dataType="string"
      [showWeek]="true"
    ></p-calendar>
  </span>
  <span class="p-col-6" *ngIf="!isCreateDefault">
    <label>Slutdatum</label>
    <p-calendar
      [showIcon]="true"
      [(ngModel)]="todo.endDate"
      dataType="string"
      [showWeek]="true"
    ></p-calendar>
  </span>
  <span class="p-col-6">
    <label>Estimerad tid (timmar)</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon"> <i class="pi pi-clock"></i> </span>
      <input pInputText type="number" [(ngModel)]="todo.estimatedTime" />
    </span>
  </span>
  <span class="p-col-6">
    <label>Ordning</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon"> <i class="pi pi-hashtag"></i> </span>
      <input pInputText type="number" [(ngModel)]="todo.orderNr" />
    </span>
  </span>
  <span class="p-col-12" *ngIf="!isCreateDefault">
    <label>Knyt anställda</label>
    <p-multiSelect
      display="chip"
      [options]="usersAsync | async"
      optionValue="id"
      [(ngModel)]="selectedUserIds"
      [filter]="false"
      [overlayOptions]="{ appendTo: 'body' }"
    >
      <ng-template pTemplate="selectedItems" let-user>
        <div class="p-d-inline" *ngFor="let user of selectedUserIds">
          {{ userStringFromId(user) }},
        </div>
        <div *ngIf="selectedUserIds.length == 0" class="p-d-inline">
          Välj anställda
        </div>
      </ng-template>
      <ng-template pTemplate="item" let-user>
        {{ user.firstName }} {{ user.lastName }}
      </ng-template>
    </p-multiSelect>
  </span>
  <span class="p-col-12" *ngIf="isCreateDefault">
    <p-inputSwitch
      [(ngModel)]="todo.defaultForAllProjects"
      class="p-mr-2"
    ></p-inputSwitch>
    <label> Standard för alla projekt </label>
  </span>
  <span class="p-col-12 p-d-flex p-jc-end">
    <button
      pButton
      class="p-button-primary-hyperion"
      [icon]="isEdit ? 'pi pi-save' : 'pi pi-plus'"
      [label]="isEdit ? 'Spara' : 'Skapa'"
      (click)="!isEdit ? createTodo(todo) : updateTodo(todo)"
      type="button"
    ></button>
  </span>
</div>
<p-dialog
  header="Hantera rubriker"
  [(visible)]="showHandleHeaders"
  [modal]="true"
  [dismissableMask]="true"
>
  <div class="p-grid" style="max-width: 50vw; max-height: 70vh">
    <span class="p-col-12">
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-clock"></i>
        </span>
        <input pInputText #newtopic />
        <button
          pButton
          (click)="addTopic(newtopic.value)"
          class="p-inputgroup-addon p-button-primary-hyperion"
          icon="pi pi-plus"
          type="button"
        ></button>
      </span>
    </span>
    <span *ngFor="let topic of todotopics | async" class="p-col-12">
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon"> <i class="pi pi-list"></i> </span>
        <input pInputText [(ngModel)]="topic.Name" placeholder="Ny rubrik" />
        <button
          pButton
          (click)="updateTopic(topic.id, topic.Name)"
          class="p-inputgroup-addon p-button-primary-hyperion"
          icon="pi pi-save"
          type="button"
        ></button>
        <button
          pButton
          (click)="deleteTopic(topic.id)"
          class="p-inputgroup-addon p-button-danger"
          icon="pi pi-trash"
          type="button"
        ></button>
      </span>
    </span>
  </div>
</p-dialog>
