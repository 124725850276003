<p-chart
  [height]="height"
  type="bar"
  [data]="data"
  [options]="options"
></p-chart>
<ul class="legend">
  <li *ngFor="let legendItem of legendItems" class="legend-item">
    <span
      class="legend-color"
      [style]="{ 'background-color': legendItem.color }"
    ></span>
    {{ legendItem.label }}
  </li>
</ul>
