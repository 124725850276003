<div>
  <form class="p-grid" [formGroup]="componentMainForm">
    <span class="p-col-12 p-lg-5">
      <input
        pInputText
        formControlName="prefix"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyProjectType_prefix'"
      />
    </span>
    <span class="p-col-12 p-lg-5">
      <input
        pInputText
        formControlName="name"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyProjectType_prefix'"
      />
    </span>

    <div class="p-col-6 p-lg-2">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="btnDisabled"
        (click)="actionCreate()"
        icon="pi pi-plus"
        label="Lägg till"
      ></button>
    </div>
  </form>
</div>
