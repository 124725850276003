import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { DragDropModule } from 'primeng/dragdrop';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { HyperionSharedModule } from '../../shared/shared.module';
import { UserSettingsModule } from 'app/registry/users/user-settings.module';
import { SharedDialogModule } from '../../shared/dialogs/shared.dialog.module';
import { ConnectionsModule } from 'app/attendance-report/connections/connections.module';
import { SubcontractorDragComponent } from './subcontractor-drag/subcontractor-drag.component';
import { ContractorDragAndDropComponent } from './contractor-drag-and-drop/contractor-drag-and-drop.component';
import { AttendanceReportsListModule } from 'app/attendance-report/handle/attendance-reports-list/attendance-reports-list.module';
import { AttendanceReportDialogsModule } from 'app/attendance-report/handle/attendance-report-dialogs/attendance-report.dialogs.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    CheckboxModule,
    DropdownModule,
    TooltipModule,
    TableModule,
    AttendanceReportsListModule,
    AttendanceReportDialogsModule,
    ConnectionsModule,
    UserSettingsModule,
    HyperionSharedModule,
    HyperionCardModule,
    LoadingModule,
    SharedDialogModule,
    DragDropModule,
  ],
  declarations: [ContractorDragAndDropComponent, SubcontractorDragComponent],
  exports: [ContractorDragAndDropComponent],
})
export class HandleModule {}
