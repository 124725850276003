<div class="p-grid">
  <div class="p-col-12">
    <div class="subcontractor-mail-dialog">
      <p>
        Vill du maila
        <span class="strong-text">{{ subcontractorInfo['name'] }}</span> på
      </p>
      <div style="width: 100%">
        <appForm2-list-input
          [(ngModel)]="emailModel"
          [htmlAttributes]="{ label: { value: 'Email' } }"
        ></appForm2-list-input>
      </div>
      <p>och meddela att du delat detta projekt med dem?</p>
    </div>
  </div>
  <div class="p-col-12 space-between">
    <app-button (onClick)="closeAction()" [type]="'cancel'"></app-button>
    <app-button
      (onClick)="sendMailToSubcontractor()"
      [type]="'send'"
    ></app-button>
  </div>
</div>
