import {
  Component,
  forwardRef,
  Injector,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-simple-textarea',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFormTextarea2Component),
      multi: true,
    },
  ],
  template: `
    <div>
      <label
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        >{{ htmlAttributes['label']['value'] || control.label }}
        <span
          *ngIf="showToolTip"
          class="{{ tooltip['class'] }}"
          [tooltipStyleClass]="tooltip['tooltipStyleClass']"
          [tooltipPosition]="tooltip['tooltipPosition']"
          [pTooltip]="tooltip['msg']"
          [escape]="false"
        >
          ?</span
        >
      </label>
      <div #container style="position:relative">
        <textarea
          pInputTextarea
          class="form-control resize-vert"
          style="flex-grow: 8"
          [escape]="false"
          tooltipEvent="focus"
          tooltipPosition="bottom"
          [pTooltip]="control.hints || null"
          [rows]="rows || 2"
          [(ngModel)]="value"
          [disabled]="disabled"
          (blur)="onTouched()"
          (input)="pushChanges($event.target.value)"
          [attr.tabindex]="htmlAttributes['input']['tabIndex']"
          [attr.id]="htmlAttributes['input']['id']"
          [attr.form-control-name]="control['name']"
          [class.form-control-danger]="control.showErrors || ''"
        ></textarea>
      </div>
      <div
        *ngIf="control.showErrors"
        style="flex-grow: 1;"
        class="vendium-tool-tip"
      >
        <p
          *ngFor="let error of control['mergedErrors']"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class SimpleFormTextarea2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  value: any;
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled: boolean;
  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
