import { gql } from 'apollo-angular';

import { MutationDetails } from './common';

export const UpdateOrder = gql`
  mutation updateOrderMutation(
    $updateEdiOrder: EdiOrderTypeHyperionMutationInput
    $orderRows: [EdiOrderRowTypeHyperionMutationInput]
  ) {
    ediOrderTypeHyperionMutation(
      updateEdiOrder: $updateEdiOrder
      orderRows: $orderRows
    ) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
      id
      projectId
      savedToProject
      project {
        id
        trueId
      }
    }
  }
  ${MutationDetails.fragments.attributes}
`;
