// Angular imports
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

// PrimeNG
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { EditorModule } from 'primeng/editor';
import { SharedModule } from 'primeng/api';
import { AutoFocusModule } from 'primeng/autofocus';

import { ConfirmationService } from 'primeng/api';

// Apollo own services
import { ApolloMutationService, ApolloErrorHandler } from './apollo/index';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';

// Company
import {
  ChosenSolutionService,
  CompanyAppParamsService,
  CompanyAttendanceTypeService,
  CompanyCostTypeService,
  CompanyDivisionService,
  CompanyFunctionsService,
  CompanyInvoiceOfferSettingsService,
  CompanyNoticeBoardService,
  CompanyPicturesService,
  CompanyAllProjectsService,
  CompanySingleProjectService,
  CompanyProjectsService,
  CompanyProjectsForStatusZeroService,
  CompanyProjectTypeService,
  ContactsAutosuggestService,
  CompanyUserCostTypeService,
  ProductsAutosuggestService,
  ProjectCostTypeService,
  ProjectDaysService,
  ProjectProductsService,
  ProjectTodosService,
  ProjectDefaultTodosService,
  TodoTopicsService,
  ProjectTypesWithProjectsService,
  ProjectUserCostTypeService,
  UserDayService,
  ProjectBookmarksService,
} from './company/index';

import { ClickOutsideModule } from './click-outside/click-outside.module';

import { GanttComponent, ImageGalleryComponent } from './extended-directives';

import { GlobalService } from './global/index';

import { HelperService } from './helpers/index';

import { HttpService } from './http/index';

import { HyperionLabelsService } from './labels/index';
import { LabelsService } from './labels/index';

import { LoadingModule } from './loading/loading.module';

import { HtmlModalService } from './html-modal/index';

import { IntroJSService } from './intro-js/index';

import { MenuService } from './menu/index';

import { MessageService } from './message/index';

import { DefaultTasks } from './resolve/index';

import { HyperionRulesService } from './rules/index';

import { StartupService } from './startup/index';

import { CompanyUsersService, UserLocalStorageService } from './user/index';

import { PipesModule } from './pipes/pipes.module';

import { StoreModule } from 'app/store/store.module';
import { ModalsModule } from 'app/shared/idle/modals/modals.module';
import { TitleComponent } from './page-info/title.component';
import { GuestTitleComponent } from './page-info/guest-title.component';
import { MetaComponent } from './page-info/meta.component';
import { SeoInfoComponent } from './page-info/seo-info.component';
import { PageInfoService } from './page-info/page-info.service';
import { AnalyticsService } from './analytics/analytics.service';
import { PageNotFoundComponent } from '../not-found-component';
import { ApplicationMessageComponent } from './message/application-message/application-message.component';
import { ButtonComponent } from './buttons/button.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { SplitButtonComponent } from './buttons/split-button.component';
import { ReusableForm2Module } from './forms/reUseableForm2/reusable-form-2.module';
import { ValidationDescriptorComponent } from './validation-descriptor/validation-descriptor.component';
import { DragAndDropTemplateComponent } from './file-upload/drag-and-drop-template.component';
import { FormErrorMessageDirective } from 'app/shared/forms/form-error-message.directive';
import { DropdownSelectComponent } from './dropdown-select/dropdown-select.component';
import { InitialIconComponent } from './initial-icon/initial-icon.component';
import { ProjectSelectComponent } from './project-select/project-select.component';
import { ProjectProductFormComponent } from './projectproduct/project-product-form/project-product-form.component';
import { DividerModule } from 'primeng/divider';
import { SaveExpansionDirective } from '../save-expansion.directive';
import { DisableArrowNavigationDirective } from '../disable-arrow-navigation.directive';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CommaPipe } from './pipes/comma.pipe';
import { ProductSearchComponent } from './product-search/product-search.component';
import { ImagesComponent } from './images/images.component';
import { CardModule } from 'primeng/card';
import { PrintPdfService } from './print-pdf.service';
import { UsersMultiselectComponent } from './users-multiselect/users-multiselect.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    LoadingModule,
    ClickOutsideModule,
    StoreModule,

    ModalsModule,

    ReactiveFormsModule,
    RouterModule,

    ButtonModule,
    ConfirmDialogModule,
    DialogModule,
    InputTextModule,
    OverlayPanelModule,
    MenuModule,
    MessagesModule,
    SharedModule,
    TooltipModule,
    PipesModule,
    DropdownModule,
    CalendarModule,
    CheckboxModule,
    InputTextareaModule,
    SplitButtonModule,
    EditorModule,
    ReusableForm2Module,
    DataViewModule,
    PanelModule,
    GalleriaModule,
    DividerModule,
    AutoCompleteModule,
    InputSwitchModule,
    CardModule,
    PaginatorModule,
    FileUploadModule,
    AutoFocusModule,
    MultiSelectModule,
  ],
  declarations: [
    ImageGalleryComponent,

    GanttComponent,

    TitleComponent,
    GuestTitleComponent,
    MetaComponent,
    SeoInfoComponent,

    PageNotFoundComponent,

    ApplicationMessageComponent,

    ButtonComponent,
    SplitButtonComponent,

    EmptyStateComponent,
    ValidationDescriptorComponent,
    DragAndDropTemplateComponent,
    FormErrorMessageDirective,
    DropdownSelectComponent,
    InitialIconComponent,
    ProjectSelectComponent,
    ProjectProductFormComponent,
    SaveExpansionDirective,
    DisableArrowNavigationDirective,
    CommaPipe,
    ProductSearchComponent,
    ImagesComponent,
    UsersMultiselectComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    HyperionCardModule,
    LoadingModule,
    PipesModule,

    ReactiveFormsModule,
    RouterModule,

    AccordionModule,
    AutoCompleteModule,
    CalendarModule,
    ChartModule,
    CheckboxModule,
    ConfirmDialogModule,
    DialogModule,
    DropdownModule,
    FileUploadModule,
    InputTextareaModule,
    InputTextModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    SharedModule,
    TabViewModule,
    TooltipModule,
    ToggleButtonModule,
    ReusableForm2Module,
    DataViewModule,
    GalleriaModule,

    GanttComponent,

    ImageGalleryComponent,

    ButtonComponent,
    SplitButtonComponent,
    EmptyStateComponent,
    TitleComponent,
    GuestTitleComponent,
    MetaComponent,
    SeoInfoComponent,
    ApplicationMessageComponent,
    ValidationDescriptorComponent,
    DragAndDropTemplateComponent,
    FormErrorMessageDirective,
    DropdownSelectComponent,
    InitialIconComponent,
    ProjectSelectComponent,
    ProjectProductFormComponent,
    SaveExpansionDirective,
    DisableArrowNavigationDirective,
    CommaPipe,
    ProductSearchComponent,
    ImagesComponent,
    UsersMultiselectComponent,
  ],
  providers: [
    ApolloErrorHandler,
    ConfirmationService,
    HelperService,
    MessageService,
    AnalyticsService,
    MenuService,
  ],
})
export class HyperionSharedModule {
  static forRoot(): ModuleWithProviders<HyperionSharedModule> {
    return {
      ngModule: HyperionSharedModule,
      providers: [
        ApolloErrorHandler,
        ApolloMutationService,
        DefaultTasks,
        ConfirmationService,
        FormBuilder,
        GlobalService,
        HtmlModalService,
        HelperService,
        HttpService,
        HyperionLabelsService,
        LabelsService,
        HyperionRulesService,
        IntroJSService,
        MenuService,
        MessageService,
        StartupService,
        UserLocalStorageService,
        ChosenSolutionService,
        CompanyAppParamsService,
        CompanyAttendanceTypeService,
        CompanyCostTypeService,
        CompanyDivisionService,
        CompanyFunctionsService,
        CompanyInvoiceOfferSettingsService,
        CompanyNoticeBoardService,
        CompanyAllProjectsService,
        CompanySingleProjectService,
        CompanyProjectsService,
        CompanyProjectsForStatusZeroService,
        ProjectBookmarksService,
        CompanyProjectTypeService,
        CompanyUserCostTypeService,
        CompanyPicturesService,
        CompanyUsersService,
        ProductsAutosuggestService,
        ProjectCostTypeService,
        ProjectDaysService,
        ProjectProductsService,
        ProjectTodosService,
        ProjectDefaultTodosService,
        ContactsAutosuggestService,
        TodoTopicsService,
        ProjectTypesWithProjectsService,
        ProjectUserCostTypeService,
        UserDayService,
        PageInfoService,
        PrintPdfService,
      ],
    };
  }
}
