import {
  Attribute,
  Component,
  forwardRef,
  OnInit,
  Injector,
  AfterContentInit,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-simple-input',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFormInput2Component),
      multi: true,
    },
  ],
  template: `
    <div [class]="styleClass">
      <label
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
      >
        <span
          [innerHtml]="htmlAttributes['label']['value'] || control.label"
        ></span>
        <span
          *ngIf="showToolTip"
          class="{{ tooltip['class'] }}"
          [tooltipStyleClass]="tooltip['tooltipStyleClass']"
          [tooltipPosition]="tooltip['tooltipPosition']"
          [pTooltip]="tooltip['msg']"
          [escape]="false"
        >
          ?</span
        >
      </label>
      <div #container>
        <input
          pInputText
          class="form-control"
          [(ngModel)]="value"
          [disabled]="disabled"
          (blur)="blurEvent()"
          [autocomplete]="autocomplete"
          [escape]="false"
          tooltipEvent="focus"
          tooltipPosition="bottom"
          [pTooltip]="control.hints || null"
          style="margin: 0px !important"
          [type]="type"
          (input)="pushChanges($event.target.value)"
          [attr.tabindex]="htmlAttributes['input']['tabIndex']"
          [class.form-control-danger]="control.showErrors || ''"
          [attr.id]="htmlAttributes['input']['id']"
          [attr.form-control-name]="control.name"
        />
        <div *ngIf="control.showErrors" class="vendium-tool-tip">
          <p
            *ngFor="let error of control['mergedErrors']"
            class="form-control-feedback"
          >
            <span (click)="control.reset()"><i class="fa fa-times"></i> </span
            ><span [innerHTML]="error"></span>
          </p>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
})
export class SimpleFormInput2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @Input() disabled: boolean;

  value: any;
  onChange: (_: any) => void;
  onTouched: () => void;
  ngControl: NgControl;

  constructor(private inj: Injector, @Attribute('type') public type: string) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }
  pushChanges(value: any) {
    this.onChange(value);
  }
  blurEvent(): void {
    this.onTouched();
    this.onBlur.emit(true);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
