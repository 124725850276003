<app-header> </app-header>

<div class="page-container">
  <ng-container
    *appLoaded="
      folders | async as loadedFolders;
      busy: copying;
      notFoundFilter: notFoundFilter
    "
  >
    <ng-container *ngFor="let folder of loadedFolders; first as isPrimary">
      <app-seo-info
        *ngIf="isPrimary"
        [title]="folder.name"
        [description]="folder.description"
      ></app-seo-info>
      <app-docu-template-folder-hero
        [folder]="folder"
        [primary]="isPrimary"
      ></app-docu-template-folder-hero>
      <ul class="documents-grid">
        <li
          class="document"
          *ngFor="let document of folder.documents"
          (click)="documentClicked(document.id, -3)"
        >
          <h2>{{ document.name }}</h2>
          <ng-container [ngSwitch]="isLoggedIn">
            <button
              *ngSwitchCase="true"
              pButton
              type="button"
              label="Fyll i dokument"
            ></button>
            <button
              *ngSwitchDefault
              pButton
              type="button"
              label="Visa dokument"
            ></button>
          </ng-container>
          <p>
            {{ document.explanation }}
          </p>
          <app-spacer></app-spacer>
        </li>
      </ul>
    </ng-container>
  </ng-container>
  <app-docu-header-folders docuRoot="../.."></app-docu-header-folders>

  <app-docu-upgrade-banner></app-docu-upgrade-banner>
</div>
