import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from '../../shared/message/index';
import { HelperService } from '../../shared/helpers/index';
import { GlobalService } from '../../shared/global/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { SortService } from 'app/store/sort.service';
import { CustomSort } from 'app/store/custom-sort';

@Component({
  selector: 'supplier-invoice-row-holder',
  templateUrl: 'supplier-invoice-row-holder.component.html',
  styleUrls: ['./supplier-invoice-row-holder.component.scss'],
})
export class SupplierInvoiceRowHolderComponent implements OnChanges, OnInit {
  @Input() dataSet = [];
  @Input() projectInfo;
  @Input() functionsData;
  @Input() projectDropdown;
  @Input() companyCostTypeId;

  extraDropdown = [
    { value: 0, label: 'Normal' },
    { value: 1, label: 'Extra' },
  ];
  sort: CustomSort = {
    attribute: 'isMovedToProjectProductId',
    ascDesc: 1,
    object: 'project_SupplierInvoiceRowHolder_table',
  };
  urlPrefix;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public helperService: HelperService,
    private globalService: GlobalService,
    private htmlModalService: HtmlModalService,
    private sortService: SortService,
    private confirmationService: ConfirmationService
  ) {
    this.sort = this.sortService.getSort(this.sort);
  }

  ngOnInit() {
    if (this.companyCostTypeId) {
      this.companyCostTypeId = +this.companyCostTypeId;
    } else {
      this.companyCostTypeId = +this.getUEmaterialId();
    }

    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'dataSet') {
        this.dataSet = this.cleanDataSet(this.dataSet);
      }
    }
  }

  changeSort(event) {
    this.sortService.setSort(event, this.sort);
  }

  cleanDataSet(dataSet) {
    const cleanDataSet = this.helperService.cleanFromNode(dataSet);

    for (const index in cleanDataSet) {
      const invoice = cleanDataSet[index];

      if (invoice.hasOwnProperty('rows')) {
        invoice['rows'] = this.helperService.cleanFromNode(invoice['rows']);
        invoice['rows'] = this.addValuesToRows(invoice['rows']);
        invoice['allRowsMovedToProjectProduct'] =
          this.checkAllRowsMovedToProjectProduct(invoice['rows']);
        invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion'] =
          this.helperService.cleanFromNode(
            invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion']
          );
        invoice['supplier'] = invoice['supplier'];
      }
    }

    return cleanDataSet;
  }

  checkAllRowsMovedToProjectProduct(rows) {
    let allIsMoved = true;

    for (const index in rows) {
      const row = rows[index];
      if (row['isMovedToProjectProductId'] < 1) {
        allIsMoved = false;
      }
    }

    return allIsMoved;
  }

  getProjectLabel(projectId) {
    let projectLabel;

    for (const index in this.projectDropdown) {
      const project = this.projectDropdown[index];

      if (Number(projectId) === Number(project['value'])) {
        projectLabel = project['label'];
      }
    }

    return projectLabel;
  }

  updatePris(row) {
    const salePrice: number = +row.apris;
    const amount: number = +row.antal;
    const discount: number = +('' + row.discount).replace(',', '.');
    const surcharge: number = +('' + row.surcharge).replace(',', '.');

    const subTotal: number = salePrice * amount;
    const subTotalDiscount = (subTotal * discount) / 100;

    const totalValue = subTotal - subTotalDiscount;

    const sum = totalValue * (1 + surcharge / 100);

    row['prisWithDiscount'] = sum.toFixed(2);
  }

  addValuesToRows(rows) {
    const newRows = [];

    // eslint-disable-next-line guard-for-in
    for (const index in rows) {
      const row = rows[index];

      // eslint-disable-next-line eqeqeq
      if (this.projectInfo['id'] == row['project_id']) {
        row['extra'] = 0;
        row['surcharge'] = 0;
        row['discount'] = +row['discount'];

        row['discountOriginal'] = +row['discount'];
        row['prisOriginal'] = +row['pris'];

        const numVal1 = Number(row['apris'] * row['antal']);
        const numVal2 = Number(row['discount']) / 100;
        const totalValue = numVal1 - numVal1 * numVal2;
        row['pris'] = totalValue.toFixed(2);

        row['prisWithDiscount'] = row['pris'];

        this.updatePris(row);

        if (row['isMovedToProjectProductId'] < 1) {
          row['project_id'] =
            Number(row['project_id']) < 1
              ? Number(this.projectInfo['id'])
              : Number(row['project_id']);

          row['move'] = true;
          row['companyCosttypeId'] = this.companyCostTypeId;
        }
        newRows.push(row);
      }
    }

    return newRows;
  }

  getUEmaterialId() {
    let UEmaterialId = null;

    for (const index in this.functionsData['companyCostTypes']) {
      const costType = this.functionsData['companyCostTypes'][index];

      if (costType['isUEmaterial'] === 1) {
        UEmaterialId = costType['value'];
        break;
      }
    }

    return UEmaterialId;
  }

  saveMarkedRows(invoiceParam) {
    const values = {};
    const invoice = invoiceParam;

    for (const index in invoice['rows']) {
      const row = invoice['rows'][index];

      if (row['isMovedToProjectProductId'] < 1) {
        if (row['move']) {
          if (!values.hasOwnProperty('Invoice')) {
            values['Invoice'] = {};
            if (!values['Invoice'].hasOwnProperty(invoice['id'])) {
              values['Invoice'][invoice['id']] = {};
            }
          }
          values['Invoice'][invoice['id']][row['id']] = {};

          const rowObject = values['Invoice'][invoice['id']][row['id']];

          const extraValue = row['extra'] ? 1 : 0;
          rowObject['extra'] = extraValue;

          const projectIdValue = Number(row['project_id']);
          rowObject['projectId'] = projectIdValue;

          const companyCosttypeIdValue = Number(row['companyCosttypeId']);
          rowObject['companyCosttypeId'] = companyCosttypeIdValue;

          rowObject['discount'] = Number(row['discount']);

          rowObject['surcharge'] = Number(row['surcharge']);

          rowObject['move'] = 'on';
        }
      }
    }
    this.rowsServerRequest(values, invoice);
  }

  rowsServerRequest(values, invoiceParam, groupAllRows = false) {
    this.rowsHttpPost(values, groupAllRows).subscribe(data => {
      if (data['status'] === 'success') {
        this.messageService.insertData({
          textArray: [data['msg']],
          time: 2000,
          type: 'success',
        });

        if (groupAllRows) {
          // set all move to "on" before the toggle
          for (const index in this.dataSet) {
            const invoiceInDataSet = this.dataSet[index];
            if (invoiceParam['id'] === invoiceInDataSet['id']) {
              for (const rIndex in invoiceInDataSet['rows']) {
                const row = invoiceInDataSet['rows'][rIndex];
                if (row['isMovedToProjectProductId'] < 1) {
                  row['move'] = true;
                  row['project_id'] = this.projectInfo['id'];
                }
              }
            }
          }
        }

        this.toggleRowsAsSaved(invoiceParam);
      } else {
        this.messageService.insertData(
          { textArray: [data['msg']], time: 2000, type: 'error' },
          true
        );
      }
    });
  }

  toggleRowsAsSaved(invoiceParam) {
    const invoice = invoiceParam;
    const dataSet = this.dataSet;

    for (const index in dataSet) {
      const invoiceInDataSet = dataSet[index];
      if (invoice['id'] === invoiceInDataSet['id']) {
        for (const rIndex in invoiceInDataSet['rows']) {
          const row = invoiceInDataSet['rows'][rIndex];
          if (row['isMovedToProjectProductId'] < 1) {
            if (row['move']) {
              row['isMovedToProjectProductId'] = 1;
            }
          }
        }
      }
      invoiceInDataSet['allRowsMovedToProjectProduct'] =
        this.checkAllRowsMovedToProjectProduct(invoiceInDataSet['rows']);
    }

    const newDataSet = [...dataSet];
    this.dataSet = newDataSet;
  }

  saveInvoiceToProject(invoiceParam) {
    this.confirmationService.confirm({
      message:
        'Med detta alternativ kommer  hela fakturan sparas till detta projekt <b>oavsett projektmärkning på raderna</b>. Om fakturan ska sparas till olika projekt spara de separata raderna för sig. ',
      header: 'Bekräfta val',
      accept: () => {
        const values = {};
        const invoice = invoiceParam;
        values['invoiceId'] = invoice['id'];
        values['companyCostTypeIdForInvoice'] = invoiceParam.companyCostTypes;
        this.rowsServerRequest(values, invoice, true);
      },
      reject: () => {},
    });
  }

  rowsHttpPost(valuesParam, groupAllRows) {
    // <<<<<<<<<<<<<<<<<<< OBS FAKE URL >>>>>>>>>>>>>>>>>>>
    let url =
      this.globalService.getUrlPrefix() +
      '/old-project/StoreInvoiceRowsToProjectproductHyperion';
    const contentType = 'application/x-www-form-urlencoded; charset=UTF-8';

    const values = valuesParam;

    if (!groupAllRows) {
      return this.http.post(url, values, {
        headers: new HttpHeaders({
          'Content-Type': contentType,
        }),
      });
    } else {
      url +=
        '?invoiceId=' +
        values['invoiceId'] +
        '&groupAllRowsToOneProjectProduct=1&companyCostTypeIdForInvoice=' +
        (values['companyCostTypeIdForInvoice'] > 0
          ? values['companyCostTypeIdForInvoice']
          : '');

      return this.http.post(url, {
        headers: new Headers({
          'Content-Type': contentType,
        }),
      });
    }
  }

  openDocument(invoiceId) {
    const url = this.urlPrefix + '/invoice/Print/type/showPDF/id/' + invoiceId;

    this.htmlModalService.ny_sida(url, 900, 800);
  }

  openSourcedocument(urlParam) {
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }
}
