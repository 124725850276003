import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TableModule } from 'primeng/table';
import { HyperionSharedModule } from '../shared/shared.module';
import { FooterModule } from '../shared/footer/footer.module';
import { IndexComponent } from './index.component';
import { TrialPeriodNotificationComponent } from './trial-period-notification/trial-period-notification.component';
import { PaymentReminderNotificationComponent } from './payment-reminder-notification/payment-reminder-notification.component';
import { CrmComponent } from './crm/crm.component';
import { SidebarMenuModule } from '../sidebar-menu/sidebar-menu.module';

@NgModule({
  imports: [
    CommonModule,
    ScrollPanelModule,
    SlideMenuModule,
    ContextMenuModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    PanelMenuModule,
    TabMenuModule,
    TieredMenuModule,
    TableModule,
    HyperionSharedModule,
    FooterModule,
    SidebarMenuModule,
  ],
  declarations: [
    IndexComponent,
    TrialPeriodNotificationComponent,
    PaymentReminderNotificationComponent,
    CrmComponent,
  ],
  exports: [
    IndexComponent,
    TrialPeriodNotificationComponent,
    PaymentReminderNotificationComponent,
    CrmComponent,
  ],
})
export class IndexModule {}
