<div class="p-grid">
  <div class="p-col-12 p-lg-5">
    <hyperion-card hasHeader="true">
      <div class="header">
        <h3>Skapa ny kommentar</h3>
      </div>

      <div class="p-grid p-g-nopad">
        <div class="p-col-12">
          <p style="margin-bottom: 0.75em">
            Här kan du skriva kommentarer som blir knutna till detta projekt.
            Kommentarerna visas inte för kunden, de visas endast för er inom
            företaget och som har behörighet att navigera hit.
          </p>
          <div>
            <form [formGroup]="componentMainForm" (ngSubmit)="actionCreate()">
              <appForm2-list-textarea
                [rows]="4"
                formControlName="comment"
              ></appForm2-list-textarea>
            </form>
          </div>
          <app-button
            class="pull-right"
            (onClick)="actionCreate()"
            [type]="'create'"
            [model]="'Comment'"
            [disabled]="buttons.create.disabled"
          ></app-button>
        </div>
      </div>
    </hyperion-card>
  </div>

  <div class="p-col-12 p-lg-7">
    <div class="p-grid p-g-nopad">
      <div class="p-col-12 p-g-nopad">
        <spinner *ngIf="loading | async"></spinner>
        <div [hidden]="loading | async">
          <div
            *ngFor="
              let dataObject of dataSet;
              trackBy: helperService.rowTrackBy
            "
            style="margin-bottom: 0.75em"
          >
            <hyperion-card>
              <project-handle-comment
                [dataObjectFromParent]="dataObject"
                [dataModelCapitalized]="dataModelCapitalized"
              >
              </project-handle-comment>
            </hyperion-card>
          </div>

          <div *ngIf="dataSet.length == 0">
            <app-emptyState
              [model]="'Comment'"
              [whereToCreate]="'left'"
            ></app-emptyState>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
