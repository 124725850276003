import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, first, BehaviorSubject } from 'rxjs';
import {
  FetchProjectAdminCommentsGQL,
  FetchProjectCommentsGQL,
  FetchProjectCommentsQuery,
} from './graphql/project.comments.query.generated';

import {
  CreateCommentGQL,
  UpdateCommentGQL,
  DeleteCommentGQL,
} from './graphql/project.comments.mutation.generated';
import { MessageService } from '../../../shared/message/index';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { CommentsAccessLevelEnum } from './enums/comments-enum';
import { UserLocalStorageService } from 'app/shared/user';
import { UserFlagsService } from 'app/user-flags.service';

type Comment =
  FetchProjectCommentsQuery['company']['projects']['edges'][0]['node']['comments']['edges'][0]['node'];

@Component({
  selector: 'app-project-comments',
  templateUrl: './project-comments.component.html',
  styleUrls: ['./project-comments.component.scss'],
})
export class ProjectCommentsComponent implements OnInit {
  public projectComments: Observable<Array<Comment>>;
  public projectCommentsAdminOnly: Observable<Array<Comment>>;
  public commentText = '';
  public commentTextAdminOnly = '';

  public CommentsAccessLevelEnum = CommentsAccessLevelEnum;

  private projectId: number;
  private projectCommentsSub = new BehaviorSubject<Array<Comment>>([]);
  private projectCommentsAdminOnlySub = new BehaviorSubject<Array<Comment>>([]);

  public currentUserId: number;
  public currentUserIsAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private projectCommentsService: FetchProjectCommentsGQL,
    private fetchProjectAdminCommentsGQL: FetchProjectAdminCommentsGQL,
    private createCommentService: CreateCommentGQL,
    private updateCommentService: UpdateCommentGQL,
    private deleteCommentService: DeleteCommentGQL,
    private projectCountService: ProjectCountService,
    private userLocalStorageService: UserLocalStorageService,
    private userFlagsService: UserFlagsService
  ) {}

  public ngOnInit(): void {
    this.projectComments = this.projectCommentsSub.asObservable();
    this.projectCommentsAdminOnly =
      this.projectCommentsAdminOnlySub.asObservable();
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;
      this.setComments();
    });
    this.currentUserId = Number(this.userLocalStorageService.getMEUser().id);
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.currentUserIsAdmin = flags.isAdmin;

        if (this.currentUserIsAdmin) {
          this.setAdminComments();
        }
      });
  }

  public create(
    text: string,
    accessLevel = CommentsAccessLevelEnum.ALL_USERS
  ): void {
    const comment = {
      accessLevel: accessLevel,
      comment: text,
      projectId: this.projectId,
    };

    this.createCommentService
      .mutate({
        createCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.projectCountService.count(this.projectId);
        this.messageService.insertDataFromMutation(mutationData);

        if (accessLevel === CommentsAccessLevelEnum.ADMINS_ONLY) {
          this.commentTextAdminOnly = '';
        } else {
          this.commentText = '';
        }

        this.setComments();
      });
  }

  public update(currentComment: Comment, text: string): void {
    const comment = {
      id: Number(currentComment.id),
      comment: text,
    };

    this.updateCommentService
      .mutate({
        updateCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.messageService.insertDataFromMutation(mutationData);
        this.setComments();
      });
  }

  public delete(currentComment: Comment): void {
    const comment = {
      id: Number(currentComment.id),
    };

    this.deleteCommentService
      .mutate({
        deleteCommentObject: comment,
      })
      .pipe(first())
      .subscribe(result => {
        this.projectCountService.count(this.projectId);
        const mutationData = result?.data?.commentTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.messageService.insertDataFromMutation(mutationData);
        this.setComments();
      });
  }

  private setComments(): void {
    if (this.currentUserIsAdmin) {
      this.setAdminComments();
    }

    this.projectCommentsService
      .fetch({ id: this.projectId })
      .pipe(first())
      .subscribe(result => {
        const comments =
          result.data.company.projects.edges[0].node.comments.edges.map(
            commentEdge => commentEdge.node
          ) as Array<Comment>;

        this.projectCommentsSub.next(comments);
      });
  }

  private setAdminComments(): void {
    this.fetchProjectAdminCommentsGQL
      .fetch({ id: this.projectId })
      .subscribe(result => {
        const comments =
          result.data.company.projects.edges[0].node.adminComments.edges.map(
            commentEdge => commentEdge.node
          ) as Array<Comment>;

        this.projectCommentsAdminOnlySub.next(comments);
      });
  }
}
