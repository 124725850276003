import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class ChosenSolutionService {
  chosenSolutionSubject = new Subject();

  setChosenSolution(chosenSolutionParam) {
    localStorage.setItem('chosenSolution', chosenSolutionParam);
    this.chosenSolutionSubject.next(chosenSolutionParam);
  }
}
