<label>
  <span *ngIf="showLabel">{{ column.col.label }}</span>
  <app-docu-field
    *ngIf="showField"
    [value]="column.col.value"
    [type]="column.col.inputType"
    [errorMsg]="column.col.errorMsg"
    (valueChange)="updateValue($event)"
  ></app-docu-field>
</label>

<ul class="docu-column-rows" *ngIf="showChildren && !column.rows.isEmpty()">
  <li>
    <app-docu-columns
      [columns]="rowHeaderColumns()"
      [width]="12"
      [labelsOnly]="true"
    ></app-docu-columns>
  </li>
  <li>
    <div class="docu-column-row-button-bar"></div>
  </li>

  <ng-container
    *ngFor="let row of column.rows; index as i; trackBy: trackRowById"
  >
    <li>
      <!-- TODO Should width be column.col.width instead, so that all columns have the same size? -->
      <app-docu-row
        [row]="row"
        (docChange)="docChange.emit($event)"
        [subRow]="true"
        [width]="12"
      ></app-docu-row>
    </li>
    <li>
      <div class="docu-column-row-button-bar">
        <button
          pButton
          *ngIf="row.row.copyAbility"
          (click)="copyRow(row)"
          icon="pi pi-plus"
        ></button>
        <button
          pButton
          *ngIf="row.row.deleteAbility"
          class="p-ml-2"
          (click)="deleteRow(row)"
          icon="pi pi-trash"
        ></button>
      </div>
    </li>
  </ng-container>
</ul>
