import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

import { MessageService } from '../../shared/message/index';
import { GlobalService } from '../../shared/global/index';
import { ApolloMutationService } from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms/index';

const constDataModel = 'costumerRelationToProject';
@Component({
  selector: 'project-share-row',
  templateUrl: 'project-share-row.component.html',
  styleUrls: ['./project-share-row.component.scss'],
  providers: [FormHandlerService],
})
export class ProjectShareRowComponent implements OnInit {
  @Input() projectInfo;
  @Input() dataObjectfromParent;

  loading = true;
  lockButtons = false;
  dataModel = constDataModel;
  dataModelCapitalized;
  attributes = [
    'offer',
    'timereport',
    'product',
    'invoice',
    'picture',
    'document',
    'todo',
    'invoiceNotification',
    'documentNotification',
    'comment',
  ];
  formStructure = {
    model: 'CostumerRelationToProject',
    attributes: {},
  };
  customerRelationshipForm: FormGroup;
  disabledForButtons: BehaviorSubject<boolean> = new BehaviorSubject(false);
  buttonsAsync = {
    update: {
      disabled: this.disabledForButtons,
    },
    delete: {
      disabled: this.disabledForButtons,
    },
  };
  customerViewLink;

  constructor(
    private messageService: MessageService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService
  ) {
    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
  }

  ngOnInit() {
    this.initForm();
    this.buildCustomerViewLink();
  }

  initForm() {
    this.attributes.forEach(attr => {
      this.formStructure['attributes'][attr] = this.dataObjectfromParent[attr]
        ? this.dataObjectfromParent[attr]
        : 0;
    });
    this.customerRelationshipForm = this.formHandler.groupedFormSimple(
      this.formStructure
    );
    this.loading = false;
    this.formHandler
      .groupSetLabelsRules(this.formStructure, this.customerRelationshipForm)
      .then();
  }

  buildCustomerViewLink() {
    this.customerViewLink =
      this.globalService.getUrlPrefix() +
      '/privateCostumer/view?adminRequestCostumerId=' +
      this.dataObjectfromParent['privateCostumer']['id'] +
      '&adminRequestProjectId=' +
      this.projectInfo['id'];
  }

  actionUpdate() {
    this.lockButtons = true;
    const crudType = 'update';
    const dataToMutation = this.customerRelationshipForm.value;
    dataToMutation['id'] = +this.dataObjectfromParent.id;

    if (!this.formHandler.formValid([this.customerRelationshipForm])) {
      this.formHandler.setErrorFlag(
        false,
        this.customerRelationshipForm,
        'submitted'
      );
      this.lockButtons = false;
    } else {
      const refetchVars = { id: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectCustomerRelationships', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.customerRelationshipForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            this.lockButtons = false;
            executeMutationSub.unsubscribe();
          },
          err => {
            console.error(err);
            this.lockButtons = false;
          }
        );
    }
  }

  confirmDeleteComment() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort kommentaren?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteComment();
      },
      reject: () => {},
    });
  }

  private deleteComment() {
    this.lockButtons = true;
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectfromParent.id) };
    const refetchVars = { id: +this.projectInfo['id'] };
    const refetchArr = [
      { name: 'projectCustomerRelationships', variables: refetchVars },
      { name: 'singleProjectTotalCounts', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            this.customerRelationshipForm
          );

          if (data.mutationSucceededAllArguments) {
            this.messageService.insertData({
              textArray: ['Delning togs bort'],
              time: 2000,
              type: 'success',
            });
          } else {
            this.messageService.insertData({
              textArray: ['Lyckades inte ta bort delningen'],
              time: 2000,
              type: 'error',
            });
          }

          this.lockButtons = false;
          executeMutationSub.unsubscribe();
        },
        err => {
          this.lockButtons = false;
        }
      );
  }
}
