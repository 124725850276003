<div>
  <div class="attendance-list-table-holder">
    <p-table
      #attendanceReportsTable
      dataKey="id"
      [rowHover]="true"
      [rowTrackBy]="helperService.rowTrackBy"
      [value]="attendanceReports"
      [columns]="attendanceReportsTableColumns"
      styleClass="attendance-report-list-table"
    >
      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td colspan="6">
            <app-emptyState [model]="'Day'"></app-emptyState>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 15%">Företagets Org. nr</th>
          <th style="width: 15%">Förnamn</th>
          <th style="width: 15%">Efternamn</th>
          <th style="width: 17%">Personnummer</th>
          <th style="width: 18%">Arbetspass påbörjas</th>
          <th style="width: 18%">Arbetspass avslutas</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td>
            <!-- Org Nr -->
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class !==
                'AttendanceFreeUser'
              "
            >
              {{ rowData.companyOrgNr }}
            </span>
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class ===
                'AttendanceFreeUser'
              "
            >
              <appForm2-list-input
                [(ngModel)]="rowData.companyOrgNr"
                (keyup.enter)="updateRow(rowData)"
              ></appForm2-list-input>
            </span>
          </td>
          <td>
            <!-- Förnamn -->
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class !==
                'AttendanceFreeUser'
              "
            >
              <span
                *ngIf="
                  rowData.realUser_PreAttendanceReportTypeHyperion
                    ?.firstName !== '' &&
                    rowData.realUser_PreAttendanceReportTypeHyperion
                      ?.firstName !== null;
                  else missing_first_name
                "
              >
                {{
                  rowData.realUser_PreAttendanceReportTypeHyperion?.firstName
                }}
              </span>
              <ng-template #missing_first_name>
                <span class="error"
                  >Saknas
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    pTooltip="<strong>Förnamn saknas</strong><br>Personen kan själv ange det under sin Profil eller kan admin lägga till det under <br><br>Dina medarbetare (längst upp till höger på denna sida)"
                    [escape]="false"
                    >?</span
                  >
                </span>
              </ng-template>
            </span>
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class ===
                'AttendanceFreeUser'
              "
            >
              <appForm2-list-input
                (keyup.enter)="updateRow(rowData)"
                [(ngModel)]="
                  rowData.realUser_PreAttendanceReportTypeHyperion.firstName
                "
              ></appForm2-list-input>
            </span>
          </td>
          <td>
            <!-- Efternamn -->
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class !==
                'AttendanceFreeUser'
              "
            >
              <span
                *ngIf="
                  rowData.realUser_PreAttendanceReportTypeHyperion?.lastName !==
                    '' &&
                    rowData.realUser_PreAttendanceReportTypeHyperion
                      ?.lastName !== null;
                  else missing_last_name
                "
              >
                {{ rowData.realUser_PreAttendanceReportTypeHyperion?.lastName }}
              </span>
              <ng-template #missing_last_name>
                <span class="error"
                  >Saknas
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    pTooltip="<strong>Efternamn saknas</strong><br>Personen kan själv ange det under sin Profil eller kan admin lägga till det under <br><br>Dina medarbetare (längst upp till höger på denna sida)"
                    [escape]="false"
                    >?</span
                  >
                </span>
              </ng-template>
            </span>
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class ===
                'AttendanceFreeUser'
              "
            >
              <appForm2-list-input
                (keyup.enter)="updateRow(rowData)"
                [(ngModel)]="
                  rowData.realUser_PreAttendanceReportTypeHyperion.lastName
                "
              ></appForm2-list-input>
            </span>
          </td>
          <td>
            <!-- Personnummer -->
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class !==
                'AttendanceFreeUser'
              "
            >
              <span
                *ngIf="
                  rowData.realUser_PreAttendanceReportTypeHyperion
                    ?.personalNumber !== '' &&
                    rowData.realUser_PreAttendanceReportTypeHyperion
                      ?.personalNumber !== null;
                  else missing_personal_number
                "
              >
                {{
                  rowData.realUser_PreAttendanceReportTypeHyperion
                    ?.personalNumber
                }}
              </span>
              <ng-template #missing_personal_number>
                <span class="error"
                  >Saknas
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    pTooltip="<strong>Personnummer saknas</strong><br>Personen kan själv ange personnummer i sin profil, eller kan admin lägga till det via<br> Register -> Användare"
                    [escape]="false"
                    >?</span
                  >
                </span>
              </ng-template>
            </span>
            <span
              *ngIf="
                rowData.realUser_PreAttendanceReportTypeHyperion.class ===
                'AttendanceFreeUser'
              "
            >
              <input
                type="text"
                style="width: 80%"
                pInputText
                (keyup.enter)="updateRow(rowData)"
                [(ngModel)]="
                  rowData.realUser_PreAttendanceReportTypeHyperion
                    .personalNumber
                "
              />
              <span style="margin-left: 5px" (click)="updateRow(rowData)"
                ><i class="fa fa-floppy-o link"></i
              ></span>
            </span>
          </td>
          <td [class.is-canceled]="rowData?.startIsCanceled === 'true'">
            <!-- Arbetspass påbörjas -->
            <div class="edits-history" *ngIf="rowData.start">
              <div
                class="edits-history-item"
                *ngFor="let edit of rowData.startEditsCached; let first = first"
              >
                <span
                  [pTooltip]="edit.commentAboutUpdate"
                  tooltipPosition="right"
                  [class.link]="rowData?.startIsCanceled === 'false' && first"
                  (click)="
                    editTime(
                      rowData,
                      true,
                      rowData?.startIsCanceled === 'false' && first,
                      edit
                    )
                  "
                >
                  {{ edit.startOrStopTime }}
                </span>
                <i
                  class="delete fa fa-times"
                  *ngIf="rowData?.startIsCanceled === 'false' && first"
                  (click)="cancelAttendanceReport(rowData, true, edit)"
                ></i>
              </div>
            </div>
            <span
              [pTooltip]="rowData?.start?.comment"
              tooltipPosition="right"
              [class.italic]="rowData?.startEditsCached?.length > 0"
              [class.link]="
                rowData?.startEditsCached?.length === 0 &&
                rowData?.startIsCanceled === 'false'
              "
              (click)="
                editTime(
                  rowData,
                  true,
                  rowData?.startIsCanceled === 'false' &&
                    rowData?.startEditsCached?.length === 0
                )
              "
            >
              {{ rowData.startTime }}
            </span>
            <i
              *ngIf="
                rowData?.startEditsCached?.length === 0 &&
                rowData?.startIsCanceled === 'false'
              "
              style="margin-left: 3px"
              class="delete fa fa-times"
              (click)="cancelAttendanceReport(rowData, true)"
              pTooltip="Makulera tid"
              tooltipPosition="top"
            ></i>
            <i
              *ngIf="rowData?.start?.latitude && rowData?.start?.longitude"
              style="margin-left: 3px"
              class="fa fa-map-marker hover-pointer"
              (click)="setGmapImg(rowData, true); gmap_stop.toggle($event)"
            ></i>
          </td>
          <td [class.is-canceled]="rowData?.isCanceled === 'true'">
            <!-- Arbetspass avslutas -->
            <div class="edits-history" *ngIf="rowData.start">
              <div
                class="edits-history-item"
                *ngFor="let edit of rowData.editsCached; let first = first"
              >
                <span
                  [pTooltip]="edit.commentAboutUpdate"
                  tooltipPosition="right"
                  [class.link]="rowData?.isCanceled === 'false' && first"
                  (click)="
                    editTime(
                      rowData,
                      false,
                      rowData?.isCanceled === 'false' && first,
                      edit
                    )
                  "
                >
                  {{ edit.startOrStopTime }}
                </span>
                <i
                  class="delete fa fa-times"
                  *ngIf="rowData?.isCanceled === 'false' && first"
                  (click)="cancelAttendanceReport(rowData, false, edit)"
                ></i>
              </div>
            </div>
            <span
              [pTooltip]="rowData?.comment"
              tooltipPosition="right"
              [class.italic]="rowData?.editsCached?.length > 0"
              [class.link]="
                rowData?.editsCached?.length === 0 &&
                rowData?.isCanceled === 'false'
              "
              (click)="
                editTime(
                  rowData,
                  false,
                  rowData?.isCanceled === 'false' &&
                    rowData?.editsCached?.length === 0
                )
              "
            >
              {{ rowData.startOrStopTime }}
            </span>
            <span
              *ngIf="!rowData.startOrStopTime"
              class="link strong-text"
              (click)="stopTime(rowData)"
              >Avsluta tid</span
            >
            <i
              *ngIf="
                rowData?.editsCached?.length === 0 &&
                rowData?.isCanceled === 'false' &&
                rowData.startOrStopTime
              "
              style="margin-left: 3px"
              class="delete fa fa-times"
              (click)="cancelAttendanceReport(rowData, false)"
            ></i>
            <i
              *ngIf="rowData?.latitude && rowData?.longitude"
              style="margin-left: 3px"
              class="fa fa-map-marker hover-pointer"
              (click)="setGmapImg(rowData, false); gmap_stop.toggle($event)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-overlayPanel #gmap_stop>
      <div [innerHTML]="gmapImg"></div>
    </p-overlayPanel>
  </div>
</div>
<div
  *ngIf="!inCollectionList && !withoutRelations"
  class="p-mt-2 p-d-flex p-jc-between"
>
  <span>
    Tidssummering:
    <i
      *ngIf="attendanceReportsSum === null"
      class="fa fa-hourglass-half fa-spin"
      aria-hidden="true"
    ></i>
    {{ attendanceReportsSum }} timmar
  </span>

  <button
    pButton
    class="p-button-primary-hyperion"
    (click)="openAddAttendanceReport()"
    label="Skapa tidrapport"
    icon="pi pi-plus"
  ></button>
</div>
