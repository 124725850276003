import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import { SelectItemGroup } from 'primeng/api';

import { GlobalService } from 'app/shared/global';
import { ApolloQueryService } from 'app/shared/apollo/index';
import { DocumentMetadata } from '../document-metadata';
import { ApolloMutationService } from '../../shared/apollo/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { HelperService } from 'app/shared/helpers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-docu-my-documents',
  templateUrl: './docu-my-documents.component.html',
  styleUrls: ['./docu-my-documents.component.scss'],
})
export class DocuMyDocumentsComponent implements OnInit, OnDestroy {
  labels;
  documents = [];
  folders: SelectItemGroup[] = [];
  status: number[];
  archived: boolean;
  loading = true;
  routerSub;
  getUrlPrefix;
  cols = [];
  docuTemplateSub: Subscription;
  showAdvancedSearch = false;
  @ViewChild('documentTable') documentTable: Table;
  companyDocumentsSub: Subscription;

  constructor(
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private labelsService: HyperionLabelsService,
    private activatedRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public helperService: HelperService
  ) {
    if (this.activatedRoute.snapshot.paramMap.get('id')) {
      this.status = [+this.activatedRoute.snapshot.paramMap.get('id')];
      this.archived = true;
    } else {
      this.status = [0, 1, 2];
    }
  }

  ngOnInit() {
    this.routerSub = this.activatedRoute.params.subscribe(() => {
      this.loading = true;
      if (+this.activatedRoute.snapshot.paramMap.get('id') === 3) {
        this.status = [+this.activatedRoute.snapshot.paramMap.get('id')];
        this.archived = true;
      } else {
        this.archived = false;
        this.status = [0, 1, 2];
      }
      this.setComponentUp();
    });
    this.getUrlPrefix = this.globalService.getUrlPrefix();
  }

  setComponentUp() {
    this.labelsService.getLabels([{ model: 'Document' }]).subscribe(data => {
      this.cols = [
        { field: 'parentName', header: data.Document.templateName }, // { field: 'templateName', header: data.Document.templateName },
        { field: 'templateName', header: data.Document.title }, // { field: 'title', header: data.Document.title },
        {
          field: 'projectString',
          header: data.Document.projectId,
          width: '120px',
        },
        { field: 'date', header: data.Document.date, width: '120px' },
      ];

      this.labels = data;
      this.fetchData();
    });
  }

  deleteDocument(doc: DocumentMetadata, event: MouseEvent) {
    // Disable the row-wide link
    event.preventDefault();
    event.stopPropagation();

    this.confirmationService.confirm({
      message: 'Vill du radera dokumentet?',
      header: 'Radera',
      icon: 'fa fa-trash',
      accept: () => {
        this.createMutation({ id: doc.id }, 'delete').subscribe(res => {
          // or better still check is successfull and write to chache
          this.fetchData();
        });
      },
      reject: () => {},
    });
  }

  archiveDocument(docId, event: MouseEvent) {
    // Disable the row-wide link
    event.preventDefault();
    event.stopPropagation();
    this.confirmationService.confirm({
      message: 'Vill du arkivera dokumentet?',
      header: 'Archive',
      icon: 'fa fa-angle-double-right',
      accept: () => {
        this.createMutation({ id: docId, status: 3 }, 'update', [
          'id',
          'status',
        ]).subscribe();
      },
      reject: () => {},
    });
  }

  createMutation(variables, type, response = []): Observable<void> {
    return this.mutationService
      .constructQueryAndExecuteMutation(
        'Document',
        type,
        false,
        variables,
        response
      )
      .pipe(
        map(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            return executedData;
          },
          err => {
            console.warn(err);
            return err;
          }
        )
      );
  }

  fetchData() {
    this.apolloQueryService
      .apolloWatchQueryTwo('documentTemplates', {}, 'cache-and-network')
      .subscribe(data => {
        this.docuTemplateSub = data.sub;
        this.folders.push({
          label: 'Visa alla',
          value: '',
          items: [{ label: 'Visa alla', value: '' }],
        });

        const mainFolders = this.apolloQueryService.cleanFromNode(
          data.data.globalDocumentsByFolder
        );
        mainFolders.map(folder => {
          const items = this.apolloQueryService
            .cleanFromNode(folder.folders)
            .map(childFolder => {
              return { label: childFolder.name, value: childFolder.id };
            });

          items.push({ label: folder.name, value: folder.id });
          const dropDownItem = {
            label: folder.name,
            value: folder.id,
            items: items,
          };
          this.folders.push(dropDownItem);
        });
      });

    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyDocuments',
        { status: this.status },
        'cache-and-network'
      )
      .subscribe(data => {
        this.companyDocumentsSub = data.sub;
        const documents = this.apolloQueryService.cleanFromNode(
          data.data['company']['documents']
        );
        this.documents = documents.map(docu => {
          let project = 'Inget projekt';
          let parentName = '';
          if (docu['template']) {
            !!docu['template']['templateName'] &&
              (parentName = docu['template']['templateName']);
          }
          if (docu.project) {
            project = docu.project.trueId + ', ' + docu.project.mark;
          }

          return { ...docu, projectString: project, parentName };
        });

        this.loading = false;
        setTimeout(() => {
          this.documentTable.globalFilterFields = [
            'templateName',
            'templateExplanation',
            'metaDescription',
            'title',
            'type',
            'date',
            'status',
            'parentName',
            'projectString',
            'project.trueId',
            'project.mark',
          ];
        }, 100);
      });
  }

  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
    this.companyDocumentsSub && this.companyDocumentsSub.unsubscribe();
    this.docuTemplateSub && this.docuTemplateSub.unsubscribe();
  }
}
