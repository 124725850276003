import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms/index';

@Component({
  selector: 'project-rot-row',
  templateUrl: 'project-rot-row.component.html',
  providers: [FormHandlerService],
})
export class ProjectRotRowComponent implements OnInit {
  @Input() private projectInfo;
  @Input() private dataObjectfromParent;
  @Output() private objectUpdated = new EventEmitter();

  public componentMainForm: FormGroup;
  private dataModel = 'projectRot';
  private dataModelCapitalized;
  private formFields = {
    model: 'ProjectRot',
    attributes: {},
  };

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService
  ) {}

  public ngOnInit(): void {
    this.setFormAttribute();
  }

  private setFormAttribute(): void {
    this.formFields['attributes'] = {
      name: this.dataObjectfromParent.name,
      personalNumber: this.dataObjectfromParent.personalNumber,
      nameOfProperty: this.dataObjectfromParent.nameOfProperty,
      housingAssociationOrgNumber:
        this.dataObjectfromParent.housingAssociationOrgNumber,
      apartmentDesignation: this.dataObjectfromParent.apartmentDesignation,
      customerWantedWorkAmount:
        this.dataObjectfromParent.customerWantedWorkAmount,
    };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formInit();
  }

  private formInit(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  public actionUpdate(): void {
    const crudType = 'update';
    const dataToMutation = {
      id: +this.dataObjectfromParent.id,
      personalNumber:
        this.componentMainForm.controls['personalNumber']['value'],
      name: this.componentMainForm.controls['name']['value'],

      nameOfProperty:
        this.componentMainForm.controls['nameOfProperty']['value'],
      housingAssociationOrgNumber:
        this.componentMainForm.controls['housingAssociationOrgNumber']['value'],
      apartmentDesignation:
        this.componentMainForm.controls['apartmentDesignation']['value'],
      customerWantedWorkAmount:
        this.componentMainForm.controls['customerWantedWorkAmount']['value'],
    };

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.objectUpdatedEmit(false);
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          [
            'id',
            'personalNumber',
            'name',
            'nameOfProperty',
            'housingAssociationOrgNumber',
            'apartmentDesignation',
            'customerWantedWorkAmount',
          ]
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(
              executedData,
              [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ],
              true
            );

            if (executedData.mutationSucceededAllArguments) {
              this.objectUpdatedEmit(true, executedData);
            } else {
              this.objectUpdatedEmit(false);
            }
            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  private objectUpdatedEmit(success, executedData?): void {
    const executedObject = {
      success: success,
      executedData: executedData,
    };

    this.objectUpdated.emit(executedObject);
  }
}
