<div class="p-grid">
  <div class="p-col-12">
    <spinner *ngIf="loading"></spinner>
  </div>
  <div class="p-grid" *ngIf="!loading">
    <div class="p-col-12">
      <h2>
        {{ rowData.source.name }} ordernr:
        {{ rowData.sellerOrderResponseNumber }}
      </h2>
    </div>

    <form class="p-col-12 p-g-nopad" [formGroup]="form">
      <div
        class="p-col-12 p-md-3 p-g-nopad"
        *ngIf="+rowData['savedToProject'] === 0"
      >
        <appForm2-wide-dropDown
          class="increase-text"
          formControlName="projectId"
          [dropDownParams]="{ options: projects, filter: true }"
        ></appForm2-wide-dropDown>
      </div>
      <div class="p-col-12" *ngIf="+rowData['savedToProject'] === 1">
        <h3>Projekt {{ projectInfo }}</h3>
      </div>
    </form>

    <div class="p-col-12">
      <p>
        <strong
          >Medarbetare har angivit:
          {{ rowData['buyerOrderResponseNumber'] }}</strong
        >
      </p>
    </div>

    <form class="p-col-12" [formGroup]="formArray">
      <p-table
        #singleRowTable
        [rowHover]="true"
        class="transactionsEditTable"
        [value]="rowData['rows']"
        formArrayName="rows"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>
              <label class="form-control-label">Benämning</label>
            </th>
            <th style="width: 20%">
              <label class="form-control-label">Antal</label>
            </th>
            <th style="width: 20%">
              <label class="form-control-label">Enhet</label>
            </th>
            <th style="width: 20%">
              <label class="form-control-label">Apris</label>
            </th>
          </tr>
        </ng-template>

        <ng-template
          pTemplate="body"
          let-index="rowIndex"
          *ngFor="let row of DynamicFormControls['controls']"
        >
          <tr class="dataLine" [formGroupName]="index">
            <td>
              <appForm2-list-textarea
                formControlName="itemDescription"
              ></appForm2-list-textarea>
            </td>
            <td>
              <appForm2-list-input
                formControlName="quantityValue"
              ></appForm2-list-input>
            </td>
            <td>
              <appForm2-list-input
                formControlName="UOMCoded"
              ></appForm2-list-input>
            </td>
            <td>
              <appForm2-list-input
                formControlName="unitPriceValue"
              ></appForm2-list-input>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>
    <div class="p-col-12 align-right">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="lockButtons || +rowData['savedToProject'] === 1"
        (click)="mutateOrderRow()"
        icon="pi pi-save"
        label="Spara"
      ></button>
    </div>
  </div>
</div>
