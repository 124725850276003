import {
  Component,
  Input,
  Output,
  ElementRef,
  HostListener,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  DropdownSelectListGroup,
  DropdownSelectListItem,
} from './dropdown-select.d';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent {
  @Input() public groups: DropdownSelectListGroup[] = [];
  @Input() public entityLabel: string;
  @Input() public selectedItem: DropdownSelectListItem;
  @Input() public loading = false;
  @Input() public disabled = false;
  @Output() selectedItemChangedEvent =
    new EventEmitter<DropdownSelectListItem>();
  @Output() searchEvent = new EventEmitter<string>();
  @ViewChild('filterInput') filterInput: ElementRef;
  @Output() isEmptyEvent = new EventEmitter<boolean>();

  public filterText = '';
  public isExpanded = false;
  public filteredGroups: DropdownSelectListGroup[] = [];
  private timeoutId: number;

  public constructor(private eRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  public clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isExpanded = false;
    }
  }

  public toggleExpanded(): void {
    this.isExpanded = !this.isExpanded;
    if (this.isExpanded) {
      this.filterInput.nativeElement.focus();
    }
  }

  public setSelected(item: DropdownSelectListItem): void {
    this.selectedItem = item;
    this.selectedItemChangedEvent.emit(item);
    this.isExpanded = false;
    this.filterText = '';
    this.groups = [];
  }

  public resetSelectedItem(): void {
    this.setSelected(undefined);
  }

  public onFilterTextChanged(text: string): void {
    this.groups = [];

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (text.length > 2) {
      this.timeoutId = setTimeout(() => this.searchEvent.emit(text), 300);
    } else if (text.length === 0) {
      this.isEmptyEvent.emit(true);
    }
  }

  public identifyItem(index, item) {
    return item.id;
  }
  public selectTopItem(): void {
    const groups =
      this.filteredGroups.length > 0 ? this.filteredGroups : this.groups;
    const topItem = groups
      .map(g => g.items)
      .flat()
      .at(0);

    this.setSelected(topItem);
  }
}
