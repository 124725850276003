<div class="page-container">
  <p-card class="p-grid">
    <div class="p-col-12">
      <a href="/document/create" style="float: right">
        <button
          pButton
          icon="pi pi-plus"
          label="Skapa dokumall"
          class="p-button-primary-hyperion"
        ></button>
      </a>
    </div>
    <div class="p-col-12" *ngFor="let category of documents?.categories">
      <h2>{{ category.name }}</h2>
      <p-table
        *ngIf="category.templates.length > 0"
        [value]="category.templates"
      >
        <ng-template pTemplate="body" let-template>
          <tr>
            <td>
              <a href="/document/adminEdit/{{ template.id }}">
                {{ template.name }}
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div *ngFor="let subcategory of category.categories">
        <p-table *ngIf="subcategory" [value]="subcategory.templates">
          <ng-template pTemplate="header">
            <tr>
              <th>{{ subcategory.name }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-template>
            <tr>
              <td>
                <a href="/document/adminEdit/{{ template.id }}">
                  {{ template.name }}
                </a>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-card>
</div>
