import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, from as observableFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentService, Pricing, InvoicePeriod } from '../document.service';
import { GlobalService } from '../../shared/global/index';
import { MessageService } from '../../shared/message/index';
import { FormHandlerService } from '../../shared/forms/index';

@Component({
  selector: 'app-docu-subscribe-dialog',
  templateUrl: './docu-subscribe-dialog.component.html',
  styleUrls: ['./docu-subscribe-dialog.component.scss'],
  providers: [FormHandlerService],
})
export class DocuSubscribeDialogComponent implements OnInit {
  pricing: Observable<Pricing>;
  urlPrefix: string;
  saving = false;
  form: FormGroup;

  private companyFormStructure = {
    model: 'Company',
    nestedForm: false,
    attributes: {
      address: '',
      city: '',
      cityCode: '',
    },
  };

  constructor(
    private formHandler: FormHandlerService,
    private documentService: DocumentService,
    private globalService: GlobalService,
    private messageService: MessageService,
    private http: HttpClient,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.dialogConfig.header = 'Börja prenumerera';
  }

  ngOnInit() {
    this.formHandler
      .groupSetLabelsRules(this.companyFormStructure)
      .then(form => {
        form.addControl(
          'invoicePeriod',
          new FormControl(InvoicePeriod.Yearly, Validators.required)
        );
        form.controls.invoicePeriod.label = '*Fakturaperiod';
        this.form = form;
      });
    this.pricing = this.documentService.pricing();
  }

  closeDialog(result = false) {
    this.dialogRef.close(result);
  }

  subscribe() {
    const form = this.form;

    if (this.formHandler.checkFormsValidity([form])) {
      this.saving = true;
      const url = this.urlPrefix + '/document/StartSubscription';
      const contentType = 'application/json';
      const data = {
        invoicePeriod: form.controls.invoicePeriod.value,
        company: {
          address: form.controls.address.value,
          city: form.controls.city.value,
          cityCode: form.controls.cityCode.value,
        },
      };

      this.http
        .post(url, data, {
          headers: new HttpHeaders({
            'Content-Type': contentType,
          }),
        })
        .pipe(map(res => res))
        .subscribe(res => {
          this.messageService.insertData({
            textArray: [res['msg']],
            type: res['status'],
          });
          if (res['status'] === 'error') {
            this.saving = false;
          } else {
            this.closeDialog(true);
          }
        });
    }
  }
}
