<div class="dialog-container">
  <spinner *ngIf="loading"></spinner>
  <div *ngIf="!loading">
    <form
      [formGroup]="componentMainForm"
      class="no-margin create-product"
      (ngSubmit)="actionCreate()"
    >
      <div class="p-grid">
        <div class="p-col-12 p-lg-6 p-g-nopad">
          <appForm2-simple-dropDown
            formControlName="companyCostTypeId"
            [dropDownParams]="{ options: companyCostTypes }"
          ></appForm2-simple-dropDown>

          <appForm2-simple-input
            formControlName="artnr"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="benamning"
          ></appForm2-simple-input>

          <appForm2-simple-dropDown
            formControlName="enhet"
            [dropDownParams]="{ options: units }"
            appendTo="body"
          ></appForm2-simple-dropDown>
        </div>
        <div class="p-col-12 p-lg-6 p-g-nopad">
          <appForm2-simple-input
            formControlName="avtalspris"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="avtalsprisCost"
          ></appForm2-simple-input>

          <appForm2-simple-input
            formControlName="leverantor"
          ></appForm2-simple-input>

          <appForm2-simple-checkbox
            formControlName="top"
          ></appForm2-simple-checkbox>
        </div>
        <div class="p-col-12">
          <app-button
            [disabled]="buttons.create.disabled"
            (onClick)="actionCreate()"
            [type]="'save'"
          ></app-button>
        </div>
      </div>
    </form>
  </div>
</div>
