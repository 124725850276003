import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService } from 'app/shared/message';
import { first, forkJoin } from 'rxjs';
import {
  AddProjectTypeGQL,
  GetAllProjectTypesGQL,
  ProjectTypeFragment,
  RemoveProjectTypeGQL,
  UpdateProjectTypeGQL,
} from './graphql/projecttypes.generated';

@Component({
  selector: 'app-project-type-form',
  templateUrl: './project-type-form.component.html',
  styleUrls: ['./project-type-form.component.scss'],
})
export class ProjectTypeFormComponent implements OnInit {
  @Output() public projectTypesUpdateEvent: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('prefix') private prefixInput;
  @ViewChild('name') private nameInput;

  public projectTypes: ProjectTypeFragment[];
  public loading = false;

  constructor(
    private getProjectTypes: GetAllProjectTypesGQL,
    private updateProjectType: UpdateProjectTypeGQL,
    private removeProjectType: RemoveProjectTypeGQL,
    private addProjectType: AddProjectTypeGQL,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.fetchProjectTypes();
  }

  private fetchProjectTypes(): void {
    this.loading = true;
    this.getProjectTypes
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        const projectTypes = [];
        data.company.projectTypes.edges.forEach(pt =>
          projectTypes.push({ ...pt.node })
        );
        this.projectTypes = projectTypes.reverse();
        this.loading = false;
      });
  }

  public onDelete(id: string): void {
    this.removeProjectType
      .mutate({ id: Number(id) })
      .pipe(first())
      .subscribe(data => {
        const md = data.data.companyProjectTypeTypeHyperionMutation;
        this.messageService.insertDataFromMutation(md);

        if (md.mutationDetails[0].mutationSucceeded) {
          this.fetchProjectTypes();
          this.projectTypesUpdateEvent.emit(true);
        }
      });
  }

  public onSave(): void {
    forkJoin(
      this.projectTypes.map(pt =>
        this.updateProjectType
          .mutate({
            type: { id: Number(pt.id), name: pt.name, prefix: pt.prefix },
          })
          .pipe(first())
      )
    ).subscribe(data => {
      const allSuccess = data.every(
        d =>
          d.data.companyProjectTypeTypeHyperionMutation.mutationDetails[0]
            .mutationSucceeded
      );
      if (allSuccess) {
        this.fetchProjectTypes();
        this.projectTypesUpdateEvent.emit(true);
        this.messageService.insertDataFromMutation(
          data[0].data.companyProjectTypeTypeHyperionMutation
        );
      } else {
        data.forEach(d =>
          this.messageService.insertDataFromMutation(
            d.data.companyProjectTypeTypeHyperionMutation
          )
        );
      }
    });
  }

  public onAdd(prefix: string, name: string): void {
    this.prefixInput.nativeElement.value = '';
    this.nameInput.nativeElement.value = '';
    this.addProjectType
      .mutate({ type: { name: name, prefix: prefix } })
      .pipe(first())
      .subscribe(data => {
        const md = data.data.companyProjectTypeTypeHyperionMutation;
        this.messageService.insertDataFromMutation(md);

        if (md.mutationDetails[0].mutationSucceeded) {
          this.fetchProjectTypes();
          this.projectTypesUpdateEvent.emit(true);
        }
      });
  }
}
