<style>
  :host ::ng-deep .p-dropdown {
    min-width: auto;
  }
</style>
<form [formGroup]="componentMainForm" class="input-no-margin">
  <div class="p-grid">
    <div class="p-col-12 p-lg-1">
      <div
        *ngIf="!isCreate"
        class="form-group full-width"
        [class.has-danger]="componentMainForm.controls.invoiceId.showErrors"
      >
        <label
          class="form-control-label show-on-small-device"
          [class.is-first]="isFirst"
          >Fakturerad</label
        >
        <p-checkbox
          [binary]="true"
          *ngIf="showInvoiceCheckBox"
          [(ngModel)]="invoiceCheckValue"
          [ngModelOptions]="{ standalone: true }"
        ></p-checkbox>

        <div *ngIf="componentMainForm.controls['invoiceId']['showErrors']">
          <p
            *ngFor="
              let error of componentMainForm.controls.invoiceId.mergedErrors
            "
            class="form-control-feedback"
          >
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="error"></span>
          </p>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-2 p-g-nopad">
      <appForm2-simple-dropDown
        formControlName="typeId"
        [htmlAttributes]="{ label: { responsive: true } }"
        [dropDownParams]="{ options: invoiceRowTypeListDropdown }"
      ></appForm2-simple-dropDown>
    </div>
    <div class="p-col-12 p-lg-2 p-g-nopad">
      <appForm2-simple-input
        formControlName="benamning"
        [htmlAttributes]="{ label: { responsive: true } }"
      ></appForm2-simple-input>
    </div>
    <div class="p-col-6 p-lg-1 align-center p-g-nopad">
      <appForm2-simple-checkbox
        formControlName="arbetskostnad"
      ></appForm2-simple-checkbox>
    </div>
    <div class="p-col-6 p-lg-1 p-g-nopad">
      <appForm2-simple-input
        formControlName="antal"
        [htmlAttributes]="{ label: { responsive: true } }"
      ></appForm2-simple-input>
    </div>
    <div class="p-col-6 p-lg-1 p-g-nopad">
      <appForm2-simple-input
        formControlName="apris"
        [htmlAttributes]="{ label: { responsive: true } }"
      ></appForm2-simple-input>
    </div>
    <div class="p-col-6 p-lg-1 p-g-nopad">
      <appForm2-simple-dropDown
        formControlName="tax"
        [htmlAttributes]="{ label: { responsive: true } }"
        [dropDownParams]="{ options: optionallyInvoiceTaxDropdown }"
      ></appForm2-simple-dropDown>
    </div>
    <div class="p-col-12 p-lg-1 p-g-nopad">
      <appForm2-simple-input
        formControlName="pris"
        [htmlAttributes]="{ label: { responsive: true } }"
      ></appForm2-simple-input>
    </div>

    <div
      class="p-col-12 icon-btn-div"
      [class.p-lg-1]="!isCreate"
      [class.p-lg-2]="isCreate"
    >
      <app-button
        *ngIf="isCreate"
        (click)="actionCreate()"
        [type]="'createNoText'"
      ></app-button>
      <app-button
        *ngIf="!isCreate"
        (click)="actionUpdate()"
        [type]="'updateNoText'"
      ></app-button>
    </div>

    <div *ngIf="!isCreate" class="p-col-12 p-lg-1 icon-btn-div">
      <app-button
        (click)="confirmDeleteDocument()"
        [type]="'deleteNoText'"
      ></app-button>
    </div>
  </div>
</form>
