<div class="p-grid">
  <div class="p-col-12">
    <form [formGroup]="componentMainForm">
      <div class="p-grid">
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input
            formControlName="firstName"
          ></appForm2-wide-input>
        </div>
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input formControlName="lastName"></appForm2-wide-input>
        </div>
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input
            formControlName="personalNumber"
          ></appForm2-wide-input>
        </div>
      </div>
    </form>
  </div>
  <div class="p-col-12 align-right">
    <app-button
      (onClick)="actionCreate()"
      [class]="'block'"
      [type]="'create'"
    ></app-button>
  </div>
</div>
