<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading" [class.page-container]="!isModal">
  <p-card [class.no-style]="isModal">
    <div class="p-grid">
      <div class="p-grid p-col-12 show-on-large-device p-g-nopad">
        <div class="p-col-5">Namn</div>
      </div>

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="
          let dataObjectFromParent of dataSet;
          trackBy: helperService.rowTrackBy
        "
      >
        <companyDivision-edit
          [dataObjectFromParent]="dataObjectFromParent"
          (dataDeleted)="dataRemoved($event)"
        ></companyDivision-edit>
      </div>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let createComponent of createDataObjectArray"
      >
        <create-company-division
          (dataObjectDeleted)="spliceDeletedDataObject(createComponent)"
          (createSibling)="addCreateComponent(createComponent)"
        ></create-company-division>
      </div>
    </div>
  </p-card>
</div>
