import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import {
  transition,
  trigger,
  state,
  style,
  animate,
} from '@angular/animations';
import { BlockableUI } from 'primeng/api';
import { SortService } from 'app/store/sort.service';
import { CustomSort } from 'app/store/custom-sort';

@Component({
  selector: 'hyperion-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
  animations: [
    trigger('panelContent', [
      state(
        'hidden',
        style({
          height: '0px',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      transition(
        'visible => hidden',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hidden => visible',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class HyperionCard implements BlockableUI, OnInit, AfterViewInit {
  @Input() toggleable: boolean;
  @Input() header = '';
  @Input() toggleId = '';

  @Input() hasHeader = false;
  @Input() collapsed = false;
  @Input() style: any;
  @Input() styleClass: string;
  @Output() collapsedChange: EventEmitter<any> = new EventEmitter();
  @Output() onBeforeToggle: EventEmitter<any> = new EventEmitter();
  @Output() onAfterToggle: EventEmitter<any> = new EventEmitter();

  public animating: boolean;

  toggleObject: CustomSort;

  constructor(private el: ElementRef, private sortService: SortService) {}

  ngOnInit() {
    if (this.storeToogleServerSide()) {
      this.toggleObject = {
        attribute: 'null',
        ascDesc: 1,
        object: this.toggleId,
      };

      this.toggleObject = this.sortService.getSort(this.toggleObject);
    }
  }

  storeToogleServerSide = () =>
    this.toggleId !== '' && typeof this.toggleId !== 'undefined';

  ngAfterViewInit() {
    if (this.storeToogleServerSide()) {
      this.collapsed = this.toggleObject.ascDesc === -1;
    }
  }

  toggle(event) {
    this.animating = true;
    this.onBeforeToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed,
    });

    let newSettings = { field: 'null', order: -1 };
    if (this.toggleable) {
      if (this.collapsed) {
        this.expand(event);
        newSettings = { field: 'null', order: 1 };
      } else {
        this.collapse(event);
      }
    }

    if (this.storeToogleServerSide()) {
      this.sortService.setSort(newSettings, this.toggleObject);
    }

    this.onAfterToggle.emit({
      originalEvent: event,
      collapsed: this.collapsed,
    });

    // TODO: Use onDone of animate callback instead with RC6
    setTimeout(() => {
      this.animating = false;
    }, 200);

    event.preventDefault();
  }

  expand(event) {
    this.collapsed = false;
    this.collapsedChange.emit(this.collapsed);
  }

  collapse(event) {
    this.collapsed = true;
    this.collapsedChange.emit(this.collapsed);
  }

  getBlockableElement(): HTMLElement {
    return this.el.nativeElement.children[0];
  }
}
