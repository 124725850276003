import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';

@Component({
  selector: 'create-project-type',
  templateUrl: 'company-project-type-create.component.html',
  providers: [FormHandlerService],
})
export class CreateProjectTypeComponent implements OnInit {
  @Output() dataObjectDeleted = new EventEmitter();
  @Output() public createSibling = new EventEmitter();

  componentMainForm: FormGroup;
  dataModel = 'companyProjectType';
  dataModelCapitalized = 'CompanyProjectType';
  formSettings;
  formFields = {
    model: 'CompanyProjectType',
    nestedForm: false,
    attributes: {
      name: '',
      prefix: '',
    },
  };
  componentPrefix;
  btnDisabled = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService
  ) {
    this.formSettings = this.globalService.getFormSettings();
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.initForm();
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(groupedForm => {
        groupedForm['controls']['name']['label'] =
          'Skriv för att skapa ny projekttyp';
        groupedForm['controls']['prefix']['label'] =
          'Skriv för att skapa ny projekttyp';
        this.cdr.markForCheck();
      });
  }

  actionCreate() {
    const crudType = 'create';

    const dataToMutation = this.componentMainForm.value;

    this.btnDisabled = true;

    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.btnDisabled = false;
      this.formHandler.setLoadingClasses();
    } else {
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(
              executedData,
              [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ] // argument = like "createCompanyCostType"
            );
            let setLoadingClassesInput;
            if (executedData.mutationSucceededAllArguments) {
              // kommer hit = hela vägen in i mål
              setLoadingClassesInput = 'success';
              this.dataObjectDeleted.emit();
            } else {
              // Klarar validering men inte hard request + Lägg till Error handling
              setLoadingClassesInput = 'error';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.btnDisabled = false;

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }
}
