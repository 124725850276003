// Angular stuff
import { NgModule, Injector, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { Angulartics2Module } from 'angulartics2';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import 'hammerjs';

import { ApolloGraphQLModule } from 'app/shared/apollo/apollo-graphql.module';
import { ApolloMasterModule } from 'app/shared/apollo/apollo-master.module';
import { AttendanceReportModule } from 'app/attendance-report/attendance-report.module';
import { AuthService } from 'app/shared/auth/auth.service';
import { CompanyInfoService } from 'app/shared/company/company-info.service';
import { FooterModule } from 'app/shared/footer/footer.module';
import { HyperionErrorModule } from 'app/shared/errors';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { LOCATION_TOKEN, locationProvider } from 'app/shared/location';
import { ServiceLocator } from 'app/shared/helpers/service-locator';
import { VersionCheckGuard } from 'app/shared/version-check/version-check.guard';
import { VersionCheckService } from 'app/shared/version-check/version-check.service';

import { AppComponent } from './app.component';

import { BrowserSupportComponent } from 'app/browser-support/browser-support.component';
import { CompanyModule } from 'app/company/company.module';
import { DayModule } from 'app/day/day.module';
import { DocuModule } from 'app/docu/docu.module';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { HomeModule } from 'app/home/home.module';
import { InvoiceModule } from 'app/invoice/invoice.module';
import { LoginModule } from 'app/login/login.module';
import { OfferModule } from 'app/offer/offer.module';
import { OverviewModule } from 'app/overview/overview.module';
import { OldProjectModule } from 'app/old-project/old-project.module';
import { PurchaseTransactionModule } from 'app/purchase-transaction/purchase-transaction.module';
import { SettingsModule } from 'app/settings/settings.module';
import { RegistryModule } from 'app/registry/registry.module';
import { StoreModule } from 'app/store/store.module';
import { PlannerModule } from './planner/planner.module';
import { FeaturePreviewModule } from './feature-preview/feature-preview.module';
import { ConfigService } from 'app/shared/config/config.service';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarMenuModule } from './sidebar-menu/sidebar-menu.module';
import { SubMenuModule } from './sub-menu/sub-menu.module';
import { HeaderModule } from './header/header.module';
import { IndexModule } from 'app/index/index.module';
import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  DetachedRouteHandle,
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';
import { RoutesService } from './routes.service';

export function routeFactory(routesService: RoutesService): () => void {
  return () => routesService.setRoutes();
}

class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>();

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return route.data.reuseComponent === true;
  }

  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle
  ): void {
    this.routeStore.set(this.getRouteFullPath(route), handle);
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    /**
     * This makes sure we do not reuse/reattach component when disableReuse state is set
     */
    if (window.history.state?.disableReuse) {
      delete window.history.state.disableReuse;
      window.history.replaceState(window.history.state, '');
      this.routeStore.delete(this.getRouteFullPath(route));
    }

    return route.data.reuseComponent &&
      !!this.routeStore.get(this.getRouteFullPath(route))
      ? true
      : false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.routeStore.get(this.getRouteFullPath(route));
  }

  private getRouteFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(r => r.routeConfig?.path)
      .filter(r => r)
      .join('/');
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      scrollPositionRestoration: 'enabled',
    }),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StoreModule,
    DragDropModule,
    DynamicDialogModule,

    ApolloGraphQLModule,

    FooterModule,
    AttendanceReportModule,
    HomeModule,
    LoginModule,
    InvoiceModule,
    OfferModule,
    OverviewModule,
    SettingsModule,
    RegistryModule,
    OldProjectModule,
    CompanyModule,
    DayModule,
    DocuModule,
    PurchaseTransactionModule,
    PlannerModule,
    FeaturePreviewModule,

    HyperionSharedModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearHash: true,
        clearQueryParams: true,
      },
    }),

    TableModule,
    InputTextModule,
    IndexModule,

    HyperionErrorModule,
    ApolloMasterModule,
    HammerModule,

    SidebarMenuModule,
    SubMenuModule,
    HeaderModule,
    ScrollPanelModule,
  ],
  declarations: [AppComponent, BrowserSupportComponent],
  exports: [],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: routeFactory,
      deps: [RoutesService],
      multi: true,
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    { provide: LOCATION_TOKEN, useFactory: locationProvider },
    { provide: LOCALE_ID, useValue: 'sv' },
    AuthService,
    GlobalInvoiceService,
    CompanyInfoService,
    DynamicDialogRef,
    DynamicDialogConfig,
    VersionCheckService,
    VersionCheckGuard,
    ConfigService,
    {
      provide: 'googleTagManagerId',
      useFactory: (configService: ConfigService) => {
        return configService.getEnvConfig().gtmId;
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: 'googleTagManagerAuth',
      useFactory: (configService: ConfigService) => {
        return configService.getEnvConfig().gtmAuth;
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: 'googleTagManagerPreview',
      useFactory: (configService: ConfigService) => {
        return configService.getEnvConfig().gtmPreview;
      },
      deps: [ConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;

    registerLocaleData(localeSv);
  }
}
