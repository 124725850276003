import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms/index';
import { GlobalService } from '../../shared/global/index';

@Component({
  templateUrl: 'days-settings.component.html',
  selector: 'days-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DaysSettingsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  buttons;
  setFields: any;
  selectedDays = 1;
  showDivider = false;
  dataModelCapitalized;
  dataModel = 'company';
  toggleValuesArray = [];
  daysToReportOptions = [];
  daySettingsSub: Subscription;
  showSubsistenceOnTimereportBool = 0;
  loading = new BehaviorSubject<boolean>(true);
  mdTooltip =
    'Detta val visas endast för dig som är administratör. Döljer du valet så är det inte möjligt för användare att rapportera traktamente. Du kan givetvis aktivera möjligheten igen.';

  constructor(
    protected globalService: GlobalService,
    protected formHandler: FormHandlerService,
    protected mutationService: ApolloMutationService,
    protected apolloQueryService: ApolloQueryService,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.dialogConfig.header = 'Tidrapporteringsinställningar';
  }

  ngOnInit() {
    const buttonObject = {
      update: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );

    this.setDaysToReportOptions();

    this.apolloQueryService
      .apolloWatchQueryTwo('daysSettings')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.daySettingsSub = sub;
        this.selectedDays = Number(data['company']['daysToReportTime']);
        this.showSubsistenceOnTimereportBool = Number(
          data['company']['showSubsistenceOnTimereport']
        );
        this.loading.next(false);
      });
  }

  setDaysToReportOptions() {
    const maxDays = 30;

    for (let i = 0; i <= maxDays; i++) {
      const indexOject = {
        value: i,
        label: `${i}`,
      };

      this.daysToReportOptions.push(indexOject);
    }

    const extraOptions = [
      { value: 60, label: '60' },
      { value: 90, label: '90' },
    ];

    for (const i in extraOptions) {
      this.daysToReportOptions.push(extraOptions[i]);
    }
  }

  actionUpdate() {
    const variables = {
      daysToReportTime: this.selectedDays,
      showSubsistenceOnTimereport: this.showSubsistenceOnTimereportBool,
    };
    this.buttons = this.formHandler.lockButtons(this.buttons);

    this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'update',
        false,
        variables,
        ['daysToReportTime', 'showSubsistenceOnTimereport']
      )
      .pipe(take(1))
      .subscribe(executedData => {
        if (executedData.mutationSucceededAllArguments) {
          status = 'error';
        } else {
          this.closeAction();
        }

        this.buttons = this.formHandler.unlockButtons(this.buttons);
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  closeAction() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.daySettingsSub && this.daySettingsSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
