import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from 'app/shared/global';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProjectExternalSources } from './project-external-sources.enum';
import { ExternalProject } from './external-project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectExternalService {
  private URL = this.globalService.getUrlPrefix() + '/project/external';
  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {}

  public getLabel(projectId: string, projectName: string) {
    return `${projectId} - ${projectName}`;
  }

  public getExternalProjects(
    source: ProjectExternalSources | string
  ): Observable<ExternalProject[]> {
    return this.httpClient
      .get<any>(`${this.URL}/${source}`)
      .pipe(
        map<any, ExternalProject[]>(returnData => returnData.projects || [])
      );
  }
}
