<p-table class="inline-table" [value]="reports | async">
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of columns"
        [style.width]="col.width"
        [pSortableColumn]="col.field"
      >
        {{ col.header }}
        <p-sortIcon [field]="col.field"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-report>
    <tr>
      <td>{{ report.orgNr }}</td>
      <td>{{ report.name }}</td>
      <td>{{ report.personnr }}</td>
      <td>
        <span class="p-grid">
          <span
            class="p-col-12 cancelled"
            *ngFor="let edit of report.start.edits"
            [pTooltip]="edit.comment"
          >
            {{ edit.time }}
          </span>
          <span class="p-col-12 p-d-flex p-ai-center p-jc-between">
            <a
              [class]="report.start.timestamp.cancelled ? 'cancelled' : ''"
              (click)="edit(report.start, report.orgNr)"
              [pTooltip]="report.start.timestamp.comment"
            >
              {{ report.start.timestamp.time }}
            </a>
            <button
              pButton
              (click)="cancel(report.start)"
              *ngIf="!report.start.timestamp.cancelled"
              class="p-button-text p-button-icon-danger legacy-rounded-button"
              icon="pi pi-times"
            ></button>
          </span>
        </span>
      </td>
      <td>
        <span *ngIf="report.stop.timestamp.time" class="p-grid">
          <span
            class="p-col-12 cancelled"
            *ngFor="let edit of report.stop.edits"
            [pTooltip]="edit.comment"
          >
            {{ edit.time }}
          </span>
          <span class="p-col-12 p-d-flex p-ai-center p-jc-between">
            <a
              [class]="report.stop.timestamp.cancelled ? 'cancelled' : ''"
              (click)="edit(report.stop, report.orgNr)"
              [pTooltip]="report.stop.timestamp.comment"
            >
              {{ report.stop.timestamp.time }}
            </a>
            <button
              pButton
              (click)="cancel(report.stop)"
              *ngIf="!report.stop.timestamp.cancelled"
              class="p-button-text p-button-icon-danger legacy-rounded-button"
              icon="pi pi-times"
            ></button>
          </span>
        </span>
        <span *ngIf="!report.stop.timestamp.time">
          <a (click)="closeReport(report)">Avsluta tid</a>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr>
      <td colspan="3"></td>
      <td colspan="2">
        <span class="p-d-flex p-jc-between p-ai-center">
          <p class="p-text-bold">Tidssummering:</p>
          <span> {{ sumHours }}h </span>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
