import { Injectable } from '@angular/core';

import { MessageService } from '../message/index';

import { GlobalService, ERROR_TYPES } from '../global/index';

import { ModalBridgeService } from 'app/shared/idle/modals/modal-bridge.service';

@Injectable()
export class ApolloErrorHandler {
  constructor(
    private messageService: MessageService,
    private modalBridgeService: ModalBridgeService,
    private globalService: GlobalService
  ) {}

  /*
      IF NEEDS DIFFERENT HANDLERS, ELSE MERGE
  */

  handleQueryErrors(errorParam) {
    this.sendErrorMessage(errorParam);
  }

  handleMutationErrors(errorParam) {
    this.sendErrorMessage(errorParam);
  }

  superErrorHandler(error: any) {
    if (
      this.globalService.identifyErrorCode(error) === ERROR_TYPES.AUTH_ERROR
    ) {
      this.modalBridgeService.triggerModal('locked');
    } else {
      this.sendErrorMessage(error);
    }
  }

  sendErrorMessage(errors) {
    this.messageService.insertData(
      { textArray: [errors], type: 'error' },
      true
    );
  }
}

/*
request kommer in
    får vi felmeddelande?
        ja
            stop, login

        Nej
            Gör request mot server / går vidarte
*/
