import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'connections',
  templateUrl: './connections.component.html',
})
export class ConnectionsComponent implements OnInit {
  mutationDone = false;
  subcontractorsData = [];
  attendanceClientsData = [];
  public connectionsData = {};

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initiateComponentFromDialogData();
    this.appendConnections();
  }

  initiateComponentFromDialogData() {
    this.connectionsData = this.dialogConfig['data']['connectionsData'];
    setTimeout(() => {
      this.dialogConfig.header = 'Hantera delning av E-liggaren';
    }, 0);
  }

  appendConnections() {
    this.subcontractorsData = this.connectionsData['subcontractors'];
    this.attendanceClientsData = this.connectionsData['attendanceClients'];
  }

  closeAction() {
    this.dialogRef.close(this.mutationDone);
  }

  handleMutationDone() {
    this.mutationDone = true;
  }
}
