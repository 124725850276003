<div class="p-col-12 p-g-nopad">
  <hyperion-card>
    <div class="p-grid">
      <div class="p-col-12 p-grid">
        <div class="p-col-4">
          <h3>Kostnader</h3>
        </div>
        <div class="p-col-2">&nbsp;</div>

        <div class="p-col-3">Procent av totala kostnader</div>
        <div class="p-col-3" style="text-align: center">Total</div>
      </div>

      <div class="p-col-12 p-grid">
        <div class="p-col-4">
          <b>Arbetstid</b>
          <small
            (click)="showDaysCostsTable = !showDaysCostsTable"
            class="link p-ml-2"
            >Visa mer</small
          >
          <appForm2-list-dropDown
            ngModel="salary"
            (ngModelChange)="daySourceChange($event)"
            [dropDownParams]="{ options: daysCostSourceOptions }"
          ></appForm2-list-dropDown>
        </div>
        <div class="p-col-4">
          <span *ngIf="showDaysCostsTable && daysCostSource === 'costType'">
            Gruppering
            <appForm2-list-dropDown
              ngModel="userId"
              (ngModelChange)="updateDaysRowGroupMetaData($event)"
              [dropDownParams]="{ options: groupByForDaysForTableViewOptions }"
            ></appForm2-list-dropDown>
          </span>
        </div>

        <div class="p-col-1" *ngIf="!showDaysCostsTable">
          <span>{{ workSumProcent }}%</span>
        </div>
        <div class="p-col-3" *ngIf="!showDaysCostsTable">
          <span class="pull-right">{{ workSumAmount | formatNum: 2 }} kr</span>
        </div>
      </div>

      <div
        class="p-col-12 p-grid"
        *ngIf="daysCostSource === 'costType' && showDaysCostsTable"
      >
        <p-table
          [value]="daysForTableView"
          [rowHover]="true"
          [sortField]="groupByForDaysForTableView"
          sortMode="single"
          [dataKey]="groupByForDaysForTableView"
        >
          <ng-template pTemplate="header">
            <tr>
              <th *ngIf="groupByForDaysForTableView === 'userId'">Användare</th>
              <th *ngIf="groupByForDaysForTableView === 'costTypeId'">
                Yrkestyp
              </th>
              <th>Timmar</th>
              <th>Kostnad</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-rowIndex="rowIndex"
            let-expanded="expanded"
            let-columns="columns"
          >
            <tr
              class="p-component-header"
              *ngIf="
                rowDaysGroupMetadata[rowData[groupByForDaysForTableView]]
                  .index === rowIndex
              "
            >
              <td>
                <a href="#" [pRowToggler]="rowData">
                  <i
                    [ngClass]="
                      expanded
                        ? 'fa fa-fw fa-chevron-circle-down'
                        : 'fa fa-fw fa-chevron-circle-right'
                    "
                  ></i>
                  <span *ngIf="groupByForDaysForTableView === 'userId'"
                    >{{ rowData.user.firstName }}
                    {{ rowData.user.lastName }}</span
                  >
                  <span *ngIf="groupByForDaysForTableView === 'costTypeId'">{{
                    rowData.userCostTypeObject.name
                  }}</span>
                </a>
              </td>
              <td>
                {{
                  rowDaysGroupMetadata[rowData[groupByForDaysForTableView]]
                    .hours | formatNum: 2
                }}
              </td>
              <td>
                {{
                  rowDaysGroupMetadata[rowData[groupByForDaysForTableView]]
                    .groupSum | formatNum: 2
                }}
                kr
              </td>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-rowIndex="rowIndex"
          >
            <tr>
              <td *ngIf="groupByForDaysForTableView === 'costTypeId'">
                {{ rowData.user.firstName }} {{ rowData.user.lastName }}
              </td>
              <td *ngIf="groupByForDaysForTableView === 'userId'">
                {{ rowData.userCostTypeObject.name }}
              </td>

              <td>{{ rowData.hours }}</td>
              <td>
                {{
                  rowData.hours * rowData.userCostTypeObject.internalCost
                    | formatNum: 2
                }}
                kr
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div style="text-align: right">
              {{ workSumAmount | formatNum: 2 }} kr
            </div>
          </ng-template>
        </p-table>
      </div>

      <div
        class="p-col-12 p-grid"
        *ngIf="daysCostSource === 'salary' && showDaysCostsTable"
      >
        <p-table
          [value]="daysCostsDataSet | async"
          #daysCostsTable
          rowGroupMode="subheader"
          [columns]="tableColums"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [style.width]>{{ col }}</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pRowToggler]="rowData">
              <td>
                <div class="p-text-truncate">
                  <span>{{ rowData.firstName }} {{ rowData.lastName }}</span>
                </div>
                <div>
                  <span>{{
                    rowData.contractor === '1' ? 'Inhyrd' : 'Anställd'
                  }}</span>
                </div>
                <div>
                  <span>{{ costUser(rowData) }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{
                      rowData['totalHours'] - rowData['totalWeekendHours']
                        | formatNum: 1
                    }}h x</span
                  >
                </div>
                <div>
                  <span>{{ rowData['salary'] | formatNum: 2 }} kr/h</span>
                </div>
                <div class="top-border">
                  <span>{{ rowData['wageSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{ rowData['totalWeekendHours'] | formatNum: 2 }}h x</span
                  >
                </div>
                <div>
                  <span
                    >{{
                      rowData['salary'] +
                        rowData['salary'] *
                          (+projectData.weekendHourProcent / 100) | formatNum: 2
                    }}
                    kr/h</span
                  >
                </div>
                <div class="top-border">
                  <span>{{ rowData['weekendWageSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td>
                <div style="visibility: hidden"><span>0</span></div>
                <div>
                  <span>({{ rowData['vacationProcent'] * 100 }}%)</span>
                </div>
                <div class="top-border">
                  <span>{{ rowData['vacationSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td>
                <div style="visibility: hidden"><span>0</span></div>
                <div>
                  <span>({{ rowData['socialFee'] }}%)</span>
                </div>
                <div class="top-border">
                  <span>{{ rowData['socialFeeSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td>
                <div style="visibility: hidden"><span>0</span></div>
                <div>
                  <span>({{ projectData['socialInsurance'] }}%)</span>
                </div>
                <div class="top-border">
                  <span>{{ rowData['foraSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{ rowData['subsistenceHalfDay'] | formatNum: 2 }} st
                    x</span
                  >
                </div>
                <div>
                  <span
                    >{{
                      projectData['trakHalfDayPrice'] | formatNum: 2
                    }}
                    kr</span
                  >
                </div>
                <div class="top-border">
                  <span
                    >{{
                      rowData['subsistenceHalfDaySum'] | formatNum: 2
                    }}
                    kr</span
                  >
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{ rowData['subsistenceDay'] | formatNum: 2 }} st x</span
                  >
                </div>
                <div>
                  <span
                    >{{ projectData['trakDayPrice'] | formatNum: 2 }} kr</span
                  >
                </div>
                <div class="top-border">
                  <span
                    >{{ rowData['subsistenceDaySum'] | formatNum: 2 }} kr</span
                  >
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{ rowData['subsistenceNight'] | formatNum: 2 }} st x</span
                  >
                </div>
                <div>
                  <span
                    >{{ projectData['trakNightPrice'] | formatNum: 2 }} kr</span
                  >
                </div>
                <div class="top-border">
                  <span
                    >{{
                      rowData['subsistenceNightSum'] | formatNum: 2
                    }}
                    kr</span
                  >
                </div>
              </td>
              <td>
                <div>
                  <span
                    >{{ rowData['totalPrivMiles'] | formatNum: 2 }} mil x</span
                  >
                </div>
                <div>
                  <span
                    >{{
                      projectData['privMileCost'] | formatNum: 1
                    }}
                    kr/mil</span
                  >
                </div>
                <div class="top-border">
                  <span>{{ rowData['totalMilesSum'] | formatNum: 2 }} kr</span>
                </div>
              </td>
              <td [style.vertical-align]="'bottom'">
                <div style="visibility: hidden"><span>0</span></div>
                <div style="visibility: hidden"><span>0</span></div>
                <div>
                  <span class="bold-weight"
                    >{{ rowData['sum'] | formatNum: 2 }} kr</span
                  >
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td [attr.colspan]="columns.length">
                <app-emptyState [model]="'Project'"></app-emptyState>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <!-- OVERHEAD OPTION-->
      <div class="p-col-12 p-grid">
        <div class="p-col-8">
          <appForm2-list-checkbox
            [(ngModel)]="addOverheadCost"
            (click)="setSumVars()"
            [htmlAttributes]="{ label: { value: 'Inkludera omkostnader' } }"
          ></appForm2-list-checkbox>
        </div>
        <div class="p-col-1">
          <span *ngIf="addOverheadCost">{{ overheadSumProcent }}%</span>
        </div>
        <div class="p-col-3">
          <span class="pull-right" *ngIf="addOverheadCost"
            >{{ overheadSumAmount | formatNum: 2 }} kr</span
          >
        </div>
      </div>

      <!-- MILE TABLE-->
      <div class="p-col-12 p-grid">
        <div class="p-col-4">
          <b>Milkostnader</b>&nbsp;<small
            (click)="showMileTable = !showMileTable"
            class="link"
            >Visa mer</small
          >
        </div>
        <div class="p-col-4">
          <span *ngIf="showMileTable">
            Gruppering
            <appForm2-list-dropDown
              ngModel="userId"
              (ngModelChange)="updateMilesRowGroupMetaData($event)"
              [dropDownParams]="{ options: groupByForMilesForTableViewOptions }"
            ></appForm2-list-dropDown>
          </span>
        </div>
        <div class="p-col-1">
          <span *ngIf="!showMileTable">{{ mileSumProcent }}%</span>
        </div>
        <div class="p-col-3">
          <span class="pull-right" *ngIf="!showMileTable"
            >{{ mileSumAmount | formatNum: 2 }} kr</span
          >
        </div>
      </div>
      <div
        class="p-col-12 p-grid"
        *ngIf="!milesForTableViewLoading && showMileTable"
      >
        <p-table
          [value]="milesForTableView"
          [dataKey]="groupByForMilesForTableView"
          [rowHover]="true"
          [sortField]="groupByForMilesForTableView"
          sortMode="single"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Användare</th>
              <th>Antal mil</th>
              <th>Kostnad</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-rowIndex="rowIndex"
            let-expanded="expanded"
            let-columns="columns"
          >
            <tr
              class="p-component-header"
              *ngIf="
                rowMilesGroupMetadata[rowData[groupByForMilesForTableView]]
                  .index === rowIndex
              "
            >
              <td>
                <a href="#" [pRowToggler]="rowData">
                  <i
                    [ngClass]="
                      expanded
                        ? 'fa fa-fw fa-chevron-circle-down'
                        : 'fa fa-fw fa-chevron-circle-right'
                    "
                  ></i>

                  <span *ngIf="groupByForMilesForTableView === 'userId'"
                    >{{ rowData.user.firstName }}
                    {{ rowData.user.lastName }}</span
                  >
                  <span *ngIf="groupByForMilesForTableView === 'extra'">{{
                    rowData[groupByForMilesForTableView] == 1
                      ? 'Extra'
                      : 'Normal'
                  }}</span>
                </a>
              </td>
              <td>
                {{
                  rowMilesGroupMetadata[rowData[groupByForMilesForTableView]]
                    .mileSum | formatNum: 2
                }}
              </td>
              <td>
                {{
                  rowMilesGroupMetadata[rowData[groupByForMilesForTableView]]
                    .mileSum * projectData.mileCost | formatNum: 2
                }}
                kr
              </td>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-rowIndex="rowIndex"
          >
            <tr>
              <td>{{ rowData.user.firstName }} {{ rowData.user.lastName }}</td>
              <td>{{ rowData.mile }}</td>
              <td>
                {{ rowData.mile * projectData.mileCost | formatNum: 2 }} kr
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div style="text-align: right">
              {{ mileSumAmount | formatNum: 2 }} kr
            </div>
          </ng-template>
        </p-table>
      </div>

      <!-- COSTS TABLE-->
      <div class="p-col-12 p-grid">
        <div class="p-col-4">
          <b>Material / övriga kostnader</b>&nbsp;<small
            (click)="showProductTable = !showProductTable"
            class="link"
            >Visa mer</small
          >
        </div>
        <div class="p-col-4">
          <span *ngIf="showProductTable">
            Gruppering
            <appForm2-list-dropDown
              ngModel="costTypeString"
              (ngModelChange)="updateProductsRowGroupMetaData($event)"
              [dropDownParams]="{
                options: groupByForProductsForTableViewOptions
              }"
            ></appForm2-list-dropDown>
          </span>
        </div>
        <div class="p-col-1">
          <span *ngIf="!showProductTable">{{ mtrlSumProcent }}%</span>
        </div>
        <div class="p-col-3">
          <span class="pull-right" *ngIf="!showProductTable"
            >{{ mtrlSumAmount | formatNum: 2 }} kr</span
          >
        </div>
      </div>

      <div
        class="p-col-12"
        *ngIf="!productsForTableViewLoading && showProductTable"
      >
        <p-table
          [value]="productsForTableView"
          [rowHover]="true"
          [sortField]="groupByForProductsForTableView"
          sortMode="single"
          [dataKey]="groupByForProductsForTableView"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Benämning</th>
              <th class="column-number">Fsg.pris</th>
              <th class="column-number">Kostnad</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-rowIndex="rowIndex"
            let-expanded="expanded"
            let-columns="columns"
          >
            <tr
              class="p-component-header"
              *ngIf="
                rowProductsGroupMetadata[
                  rowData[groupByForProductsForTableView]
                ].index === rowIndex
              "
            >
              <td>
                <a href="#" [pRowToggler]="rowData">
                  <i
                    [ngClass]="
                      expanded
                        ? 'fa fa-fw fa-chevron-circle-down'
                        : 'fa fa-fw fa-chevron-circle-right'
                    "
                  ></i>
                  <span>{{ rowData[groupByForProductsForTableView] }}</span>
                </a>
              </td>
              <td class="column-number">
                {{
                  rowProductsGroupMetadata[
                    rowData[groupByForProductsForTableView]
                  ].sum | formatNum: 2
                }}
                kr
              </td>
              <td class="column-number">
                {{
                  rowProductsGroupMetadata[
                    rowData[groupByForProductsForTableView]
                  ].costSum | formatNum: 2
                }}
                kr
              </td>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-rowIndex="rowIndex"
          >
            <tr>
              <td>{{ rowData.benamning }}</td>
              <td class="column-number">
                {{
                  rowData.avtalsprisPlusProcent * rowData.antal | formatNum: 2
                }}
                kr
              </td>
              <td class="column-number">
                {{ rowData.avtalsprisCost * rowData.antal | formatNum: 2 }} kr
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="summary">
            <div class="column-number">
              {{ mtrlSumAmount | formatNum: 2 }} kr
            </div>
          </ng-template>
        </p-table>
      </div>

      <div class="p-grid p-col-12 project-cost-summary-costs">
        <div class="p-col-4">&nbsp;</div>
        <div class="p-col-5">
          <span class="pull-right">Summa kostnader</span>
        </div>
        <div class="p-col-3">
          <span class="pull-right">
            {{ calculateAllCost | formatNum: 2 }} kr
          </span>
        </div>
      </div>

      <div class="p-col-12 p-grid p-g-nopad">
        <div class="p-col-4 p-g-nopad">
          <div class="p-col-12 p-g-nopad">
            <span class="link" (click)="toggleInputs = !toggleInputs"
              >Ange parametrar</span
            >
          </div>
          <div class="p-col-12 p-grid p-g-nopad" *ngIf="toggleInputs">
            <div class="p-col-10">
              <span style="vertical-align: -webkit-baseline-middle"
                >Kostnad per mil</span
              >
            </div>
            <div class="p-col-2">
              <input
                type="text"
                [(ngModel)]="projectControls.mileCost"
                class="form-control no-margin"
                style="width: 100%"
                pInputText
                (change)="actionUpdate()"
              />
            </div>
            <div class="p-col-10">
              <span style="vertical-align: -webkit-baseline-middle"
                >Milersättning för anställda</span
              >
            </div>
            <div class="p-col-2">
              <input
                type="text"
                [(ngModel)]="projectControls.privMileCost"
                class="form-control no-margin"
                style="width: 100%"
                pInputText
                (change)="actionUpdate()"
              />
            </div>
            <div class="p-col-8">
              <span style="vertical-align: -webkit-baseline-middle"
                >Fakturerat manuellt</span
              >
            </div>

            <div class="p-col-4">
              <input
                type="text"
                [(ngModel)]="projectControls.invoicedInOtherSystem"
                class="form-control no-margin"
                style="width: 100%"
                pInputText
                (change)="actionUpdate()"
              />
            </div>
          </div>
          <br />
        </div>
        <div class="p-col-8 project-cost-summary-costs-results">
          <div class="p-col-12 p-grid">
            <div class="p-col-9">
              <span
                >Resultat i förhållande till fakturerat ({{
                  projectInvoiceData['invoicedTotal'] +
                    +projectControls.invoicedInOtherSystem | formatNum: 2
                }}
                kr)<br />
                Täckningsgrad: {{ this.resultProcent }}%</span
              >
            </div>
            <div class="p-col-3">
              <span class="pull-right">
                {{ calculateResult | formatNum: 2 }} kr
              </span>
            </div>
          </div>

          <div class="p-col-12 p-grid p-g-nopad">
            <div class="p-col-9">
              <span class=""
                >Resultat i förhållande till offererat ({{
                  calculateOfferSum | formatNum: 2
                }}
                kr)<br />
                Täckningsgrad: {{ this.resultOfferProcent }}%</span
              >
            </div>
            <div class="p-col-3">
              <span class="pull-right">
                {{ calculateResultOffer | formatNum: 2 }} kr
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </hyperion-card>
</div>
