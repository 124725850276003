import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { Spinner } from './index';
import { LoadedDirective } from './loaded.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [Spinner, LoadedDirective],
  exports: [Spinner, LoadedDirective],
  providers: [],
})
export class LoadingModule {}
