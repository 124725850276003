<div class="p-grid">
  <div class="p-col-12 p-lg-6">
    <h1>Sätt din layout</h1>
    <div class="p-grid p-g-nopad">
      <div class="p-col-12 p-lg-6">
        <h4 class="modal-section-title">
          <p-checkbox
            label="Förhandsgranska"
            [binary]="true"
            [(ngModel)]="preview"
            (onChange)="previewToggle()"
          ></p-checkbox>
        </h4>
        <h4 class="modal-section-title">
          Kolumner <i class="fa fa-file-pdf-o red"></i>
        </h4>
      </div>
      <div class="p-col-12 p-lg-6">
        <div>
          <p-radioButton
            label="Senast först"
            name="order-group"
            value="latestFirst"
            [(ngModel)]="order"
            inputId="order-latestFirst"
            (onClick)="previewCall()"
          ></p-radioButton>
        </div>
        <div>
          <p-radioButton
            label="Äldst först"
            name="order-group"
            value="oldestFirst"
            [(ngModel)]="order"
            inputId="order-oldestFirst"
            (onClick)="previewCall()"
          ></p-radioButton>
        </div>
      </div>
    </div>
    <div class="p-grid p-g-nopad">
      <div class="p-col-12 p-lg-6">
        <div class="modal-section-div">
          <div>
            <p-checkbox
              name="columns-group"
              value="date"
              [(ngModel)]="columns"
              label="Datum"
              (onChange)="updatePreviewDetails($event, 'date', 'column')"
            ></p-checkbox>
          </div>
          <div>
            <p-checkbox
              name="columns-group"
              value="hours"
              [(ngModel)]="columns"
              label="Timmar"
              (onChange)="updatePreviewDetails($event, 'hours', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.setMile">
            <p-checkbox
              name="columns-group"
              value="mile"
              [(ngModel)]="columns"
              label="Mil"
              (onChange)="updatePreviewDetails($event, 'mile', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.setPrivMile">
            <p-checkbox
              name="columns-group"
              value="privMile"
              [(ngModel)]="columns"
              label="Privata mil"
              (onChange)="updatePreviewDetails($event, 'privMile', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.usePickUserCostTypeOnTimereport">
            <p-checkbox
              name="columns-group"
              value="costTypes"
              [(ngModel)]="columns"
              label="Yrkestyp"
              (onChange)="updatePreviewDetails($event, 'costTypes', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.usePickOvertimeOnTimereport">
            <p-checkbox
              name="columns-group"
              value="attendance"
              [(ngModel)]="columns"
              label="Närvarotyp"
              (onChange)="updatePreviewDetails($event, 'attendance', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.useNotarized">
            <p-checkbox
              name="columns-group"
              value="notarized"
              [(ngModel)]="columns"
              label="Attesterad"
              (onChange)="updatePreviewDetails($event, 'notarized', 'column')"
            ></p-checkbox>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-lg-6">
        <div class="modal-section-div">
          <div>
            <p-checkbox
              name="columns-group"
              value="user"
              [(ngModel)]="columns"
              label="Användare"
              (onChange)="updatePreviewDetails($event, 'user', 'column')"
            ></p-checkbox>
          </div>
          <div>
            <p-checkbox
              name="columns-group"
              value="doneWork"
              [(ngModel)]="columns"
              label="Kommentarer"
              (onChange)="updatePreviewDetails($event, 'doneWork', 'column')"
            ></p-checkbox>
          </div>
          <div>
            <p-checkbox
              name="columns-group"
              value="project"
              [(ngModel)]="columns"
              label="Projekt"
              (onChange)="updatePreviewDetails($event, 'project', 'column')"
            ></p-checkbox>
          </div>
          <div>
            <p-checkbox
              name="columns-group"
              value="todo"
              [(ngModel)]="columns"
              label="Arbetsmoment"
              (onChange)="updatePreviewDetails($event, 'todo', 'column')"
            ></p-checkbox>
          </div>
          <div *ngIf="showSubstistenceOptions | async">
            <p-checkbox
              name="columns-group"
              value="allowance"
              [(ngModel)]="columns"
              label="Traktamente"
              (onChange)="updatePreviewDetails($event, 'allowance', 'column')"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-g-nopad">
      <div class="p-col-12">
        <h4 class="modal-section-title">
          Layout <i class="fa fa-file-pdf-o red"></i>
        </h4>
      </div>
    </div>
    <div class="p-grid p-g-nopad">
      <div class="p-col-12 p-lg-6">
        <div class="modal-section-div">
          <div>
            <span style="margin-right: 10px"
              ><p-radioButton
                name="layout-group"
                value="portrait"
                label="Stående"
                [(ngModel)]="layout"
                inputId="layout-portrait"
                (onClick)="previewCall()"
              ></p-radioButton
            ></span>
          </div>
          <div>
            <p-radioButton
              label="Liggande"
              name="layout-group"
              value="landscape"
              [(ngModel)]="layout"
              inputId="layout-landscape"
              (onClick)="previewCall()"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-lg-6">
        <div class="modal-section-div">
          <div>
            <p-checkbox
              #checkbox
              [ngModel]="sortFieldsLayout['onlyUserSums']['ascDesc']"
              label="Endast summa"
              (onChange)="
                updatePreviewDetails($event, 'onlyUserSums', 'layout')
              "
              [binary]="true"
            ></p-checkbox>
          </div>
          <div *ngIf="functionsThisModel.usePickOvertimeOnTimereport">
            <p-checkbox
              [ngModel]="sortFieldsLayout['sumAttendance']['ascDesc']"
              label="Visa summa per närvarotyp"
              (onChange)="
                updatePreviewDetails($event, 'sumAttendance', 'layout')
              "
              [binary]="true"
            ></p-checkbox>
          </div>
          <div>
            <p-checkbox
              [ngModel]="sortFieldsLayout['sumTodos']['ascDesc']"
              label="Visa summa per arbetsmoment"
              (onChange)="updatePreviewDetails($event, 'sumTodos', 'layout')"
              [binary]="true"
            ></p-checkbox>
          </div>

          <div *ngIf="functionsThisModel.usePickUserCostTypeOnTimereport">
            <p-checkbox
              [ngModel]="sortFieldsLayout['sumWorkType']['ascDesc']"
              label="Visa summa per yrkestyp"
              (onChange)="updatePreviewDetails($event, 'sumWorkType', 'layout')"
              [binary]="true"
            ></p-checkbox>
          </div>

          <div *ngIf="showSubstistenceOptions | async">
            <p-checkbox
              [ngModel]="sortFieldsLayout['sumSubsistence']['ascDesc']"
              label="Summera traktamenten"
              (onChange)="
                updatePreviewDetails($event, 'sumSubsistence', 'layout')
              "
              [binary]="true"
            ></p-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-g-nopad">
      <div class="p-col-12">
        <h4 class="modal-section-title">
          Huvud <i class="fa fa-file-pdf-o red"></i>
        </h4>
      </div>
    </div>

    <div class="p-grid p-g-nopad">
      <div class="p-col-12">
        <div class="modal-section-div">
          <div>
            <p-radioButton
              label="Inkludera aktuella användare"
              name="head-group"
              value="current"
              [(ngModel)]="head"
              inputId="head-possible"
              (onClick)="previewCall()"
            ></p-radioButton>
          </div>
          <div>
            <p-radioButton
              label="Inkludera möjliga användare"
              name="head-group"
              value="possible"
              [(ngModel)]="head"
              inputId="head-possible"
              (onClick)="previewCall()"
            ></p-radioButton>
          </div>
          <div>
            <p-radioButton
              label="Inkludera alla användare"
              name="head-group"
              value="all"
              [(ngModel)]="head"
              inputId="head-all"
              (onClick)="previewCall()"
            ></p-radioButton>
          </div>
          <div>
            <p-radioButton
              label="Inkludera inga användare"
              name="head-group"
              value="not"
              [(ngModel)]="head"
              inputId="head-not"
              (onClick)="previewCall()"
            ></p-radioButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-lg-6">
    <div style="width: 950px"></div>

    <div *ngIf="preview">
      <spinner *ngIf="loading"></spinner>

      <div *ngIf="!loading" class="pdf-container">
        <iframe
          [attr.src]="pdfUrl"
          class="pdf-view-object"
          frameborder="0"
          scrolling="no"
        >
          <p>
            Kan inte läsa pdf i webbläsaren.
            <a [href]="pdfUrl" target="_blank">Tryck här för att ladda ned.</a>
          </p>
        </iframe>
      </div>
    </div>
  </div>
  <div class="p-col-12">
    <span class="p-inputgroup p-mb-2">
      <app-project-select
        style="width: 14rem"
        (selectedProjectEvent)="selectProject = $event.id"
      ></app-project-select>
      <button
        class="p-inputgroup-addon p-mr-2"
        pButton
        icon="pi pi-save"
        class="p-mr-2"
        label="Spara till projekt"
        [disabled]="!selectProject"
        (click)="saveToProjectCall(selectProject)"
      ></button>
    </span>
    <div>
      <button
        pButton
        class="p-mr-2"
        icon="pi pi-file-pdf"
        label="Visa pdf"
        (click)="exportFile('showPdf')"
      ></button>
      <button
        pButton
        class="p-mr-2"
        icon="pi pi-download"
        label="Hämta pdf"
        (click)="exportFile('getPdf')"
      ></button>
      <button
        pButton
        class="p-mr-2"
        icon="pi pi-file-excel"
        label="Hämta excel"
        (click)="exportFile('getExcel')"
      ></button>
    </div>
  </div>
</div>
