import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SendToZignedMutationVariables = Types.Exact<{
  offerId?: Types.InputMaybe<Types.Scalars['Int']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SendToZignedMutation = { __typename?: 'HyperionSchemaMutation', sendOfferToZignedMutation?: { __typename?: 'Offer', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type OfferInfoQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type OfferInfoQuery = { __typename?: 'HyperionSchemaQuery', offer?: { __typename?: 'Offer', id: string, trueId?: string | null, clientContact?: { __typename?: 'Contact', mail?: string | null } | null } | null };

export const SendToZignedDocument = gql`
    mutation sendToZigned($offerId: Int, $email: String) {
  sendOfferToZignedMutation(offerId: $offerId, participantEmail: $email) {
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendToZignedGQL extends Apollo.Mutation<SendToZignedMutation, SendToZignedMutationVariables> {
    document = SendToZignedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OfferInfoDocument = gql`
    query offerInfo($id: Int) {
  offer(id: $id) {
    id
    trueId
    clientContact {
      mail
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OfferInfoGQL extends Apollo.Query<OfferInfoQuery, OfferInfoQueryVariables> {
    document = OfferInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }