import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetDefaultTodosQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetDefaultTodosQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectDefualtTodos?: { __typename?: 'ProjectDefualtTodoConnection', edges?: Array<{ __typename?: 'ProjectDefualtTodoEdge', node?: { __typename?: 'ProjectDefualtTodo', id: string, defaultForAllProjects?: number | null, description?: string | null, topic?: { __typename?: 'Todotopic', Name?: string | null } | null } | null } | null> | null } | null } | null };

export type DefaultTodoFragment = { __typename?: 'ProjectDefualtTodo', id: string, defaultForAllProjects?: number | null, description?: string | null, topic?: { __typename?: 'Todotopic', Name?: string | null } | null };

export const DefaultTodoFragmentDoc = gql`
    fragment defaultTodo on ProjectDefualtTodo {
  id
  defaultForAllProjects
  description
  topic {
    Name
  }
}
    `;
export const GetDefaultTodosDocument = gql`
    query getDefaultTodos {
  company {
    projectDefualtTodos {
      edges {
        node {
          ...defaultTodo
        }
      }
    }
  }
}
    ${DefaultTodoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDefaultTodosGQL extends Apollo.Query<GetDefaultTodosQuery, GetDefaultTodosQueryVariables> {
    document = GetDefaultTodosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }