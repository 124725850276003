import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalService } from '../global';

export interface CompleteMetadata {
  [modelName: string]: SingleModelMetadata;
}

interface SingleModelMetadata {
  hints: {
    [key: string]: string;
  };
  labels: {
    [key: string]: string;
  };
  rules: any; // TODO: need to figure this type later
}

/** The expected response from the Labels service */
export interface LabelsResponse {
  [modelName: string]: {
    [key: string]: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  private METADATA_ENDPOINT = `${this.globalService.getUrlPrefix()}/hyperion/hyperion/attributeMeta`;
  private ALL_MODELS = 'All';
  private metadata: CompleteMetadata = {};

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {}

  /**
   * Returns data for a single model
   *
   * @param model the model to fetch data from
   */
  public getSingleModel(model: string): Observable<SingleModelMetadata> {
    return this.getAll([model]).pipe(
      map(completeMetadata => {
        return completeMetadata[model];
      })
    );
  }

  /**
   * Returns all metadata for an array of models
   *
   * @param models the models to fetch data from
   */
  public getAll(models: string[] = []): Observable<CompleteMetadata> {
    const modelsToFetch: string[] = (!models.length && [this.ALL_MODELS]) || [];

    models.forEach(model => {
      if (!(model in this.metadata)) {
        modelsToFetch.push(model);
      }
    });

    // if everything in cache, just return the whole thing
    if (this.isModelsInCache(modelsToFetch)) {
      return of(this.metadata);
    }

    // if something is missing from cache, fetch those and merge to the current metadata
    return this.httpClient
      .get<CompleteMetadata>(this.METADATA_ENDPOINT, {
        params: {
          models: modelsToFetch.join(','),
        },
      })
      .pipe(
        map((response: CompleteMetadata) => {
          // merge previous and new response
          return (this.metadata = {
            ...this.metadata,
            ...response,
          });
        })
      );
  }

  /**
   * Checks whether the the content is in cache or not
   * @param modelsToFetch the array of the models to fetch from the API
   */
  private isModelsInCache(modelsToFetch: string[]) {
    return (
      !modelsToFetch.length ||
      (Object.getOwnPropertyNames(this.metadata).length !== 0 &&
        modelsToFetch[0] === this.ALL_MODELS &&
        modelsToFetch.length === 1)
    );
  }

  /**
   * Returns a key-value object where the key is the model and the value are the labels.
   *
   * @param modelArray the array with the strings of the models
   */
  public getLabels(modelArray: string[]): Observable<LabelsResponse> {
    return this.getAll(modelArray).pipe(
      map((data: CompleteMetadata) => {
        const response: LabelsResponse = {};
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            response[key] = data[key].labels;
          }
        }
        return response;
      })
    );
  }
}
