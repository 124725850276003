<div class="sidebar-menu-container">
  <div class="header">
    <div class="logo">
      <img [src]="theme.iconPath" />
    </div>
    <div class="user-and-notification-section">
      <div class="user-section">
        <a routerLink="/settings/user">
          <span class="user-abbr">{{ userAbbr }}</span>
        </a>
      </div>
      <div [hidden]="userFlags.isWorker" class="notifications-section">
        <button
          (click)="toggleNotifications()"
          class="notifications-button"
          [ngClass]="{ active: isNotificationsOpened }"
        >
          <span class="notifications-dot" *ngIf="notificationsCount"> </span>
          <mat-icon>mail_outline</mat-icon>
        </button>
        <app-sidebar-notification
          [ngClass]="{ open: isNotificationsOpened }"
          [isOpen]="isNotificationsOpened"
          (setNotificationCountEvent)="setNotificationCount($event)"
          (setNotificationOpenEvent)="setNotificationOpen($event)"
        ></app-sidebar-notification>
      </div>
    </div>
  </div>

  <div class="body">
    <div *ngFor="let menuLink of sidebarMenuLinks" [class]="menuLink.path">
      <a
        [routerLink]="menuLink.path"
        [state]="{ disableReuse: !routesShouldReuse.includes(menuLink.path) }"
        routerLinkActive="active"
      >
        <button
          type="button"
          class="sidebar-menu-button"
          [class.inverted]="theme.invert"
        >
          <span class="icon"><img src="{{ menuLink.data.icon }}" /></span>
          <span class="label">{{ menuLink.data.label }}</span>
        </button>
      </a>
    </div>
  </div>
</div>
