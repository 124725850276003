import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchProjectCommentsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchProjectCommentsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, projects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, comments?: { __typename?: 'CommentConnection', edges?: Array<{ __typename?: 'CommentEdge', node?: { __typename?: 'Comment', id: string, created?: string | null, comment?: string | null, projectId?: number | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export type FetchProjectAdminCommentsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchProjectAdminCommentsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, projects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, adminComments?: { __typename?: 'CommentConnection', edges?: Array<{ __typename?: 'CommentEdge', node?: { __typename?: 'Comment', id: string, created?: string | null, comment?: string | null, projectId?: number | null, accessLevel?: number | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export type UserNameFullFragment = { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null };

export const UserNameFullFragmentDoc = gql`
    fragment UserNameFull on User {
  id
  firstName
  lastName
}
    `;
export const FetchProjectCommentsDocument = gql`
    query fetchProjectComments($id: Int) {
  company {
    id
    projects(id: $id) {
      edges {
        node {
          id
          comments {
            edges {
              node {
                id
                created
                comment
                projectId
                user {
                  ...UserNameFull
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${UserNameFullFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectCommentsGQL extends Apollo.Query<FetchProjectCommentsQuery, FetchProjectCommentsQueryVariables> {
    document = FetchProjectCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProjectAdminCommentsDocument = gql`
    query fetchProjectAdminComments($id: Int) {
  company {
    id
    projects(id: $id) {
      edges {
        node {
          id
          adminComments {
            edges {
              node {
                id
                created
                comment
                projectId
                accessLevel
                user {
                  ...UserNameFull
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${UserNameFullFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectAdminCommentsGQL extends Apollo.Query<FetchProjectAdminCommentsQuery, FetchProjectAdminCommentsQueryVariables> {
    document = FetchProjectAdminCommentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }