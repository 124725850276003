<app-header>
  <button
    pButton
    label="Visa summering"
    (click)="openProjectDataDialog()"
  ></button>
  <app-project-select
    #projectSelect
    (selectedProjectEvent)="onSelectedProjectDropdown($event)"
  ></app-project-select>

  <button
    pButton
    *ngIf="status !== PROJECT_STATUS.INHOUSE"
    class="p-button-primary-hyperion"
    label="Skapa projekt"
    (click)="showCreateSidebar = true"
    icon="pi pi-plus"
  ></button>
  <button
    pButton
    *ngIf="status === PROJECT_STATUS.INHOUSE"
    class="p-button-primary-hyperion"
    label="Skapa internt projekt"
    (click)="showCreateInternalSidebar = true"
    icon="pi pi-plus"
    [disabled]="!userFlags.isAdmin"
  ></button>
</app-header>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="showCreateSidebar"
  position="right"
>
  <app-project-form (projectCreatedEvent)="onProjectCreatedEvent($event)">
  </app-project-form>
</p-sidebar>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="showCreateInternalSidebar"
  position="right"
>
  <div class="p-grid">
    <h1 class="p-col-12">Skapa internt projekt</h1>
    <div class="p-col-12">
      <label>Märkning</label>
      <input pInputText #internalMark />
    </div>
    <div class="p-col-12 p-d-flex p-jc-end">
      <button
        pButton
        class="p-button-primary-hyperion"
        label="Skapa"
        icon="pi pi-plus"
        (click)="
          actionCreateInternalProject(internalMark.value);
          internalMark.value = null
        "
      ></button>
    </div>
  </div>
</p-sidebar>

<div class="page-container">
  <p-table
    #dt
    *ngIf="service.userSortSetting"
    [lazy]="true"
    (onLazyLoad)="getProjects($event)"
    [columns]="infoColumns"
    [value]="projects | async"
    [sortField]="service.userSortSetting.attribute"
    [sortOrder]="service.userSortSetting.ascDesc"
    (onSort)="setSortSettings(dt)"
    [paginator]="true"
    paginatorPosition="both"
    [rows]="100"
    [rowsPerPageOptions]="[50, 100, 250, 500]"
    [totalRecords]="count || '...'"
    [rowHover]="true"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="Visar {first} till {last} av {totalRecords} projekt"
    [loading]="loading"
    dataKey="id"
  >
    <ng-template pTemplate="caption">
      <div class="p-grid">
        <span
          [class]="
            useProjectTypes ? 'p-inputgroup p-col-5' : 'p-inputgroup p-col-7'
          "
        >
          <span class="p-inputgroup-addon" [style.background-color]="'white'">
            <i class="pi pi-search"></i>
          </span>
          <input
            pInputText
            (input)="searchSubject.next($event.target.value)"
            placeholder="Sök"
            [style.background-color]="'white'"
          />
        </span>

        <p-dropdown
          *ngIf="useProjectTypes"
          class="p-col-2"
          [options]="projectTypeList"
          optionLabel="formattedName"
          optionValue="id"
          (onChange)="onSelectProjectType($event.value)"
        ></p-dropdown>

        <div class="p-col-5 p-d-flex p-jc-end">
          <button
            class="p-mr-2 p-button-brand-primary"
            pButton
            label="Återställ filtrering"
            icon="pi pi-replay"
            *ngIf="searchString || projectTypeId || useKpiSearch"
            (click)="reset()"
          ></button>
          <button
            pButton
            [style.height]="'100%'"
            [style.float]="'right'"
            class="p-as-cclientContactenter"
            label="Utökad sökning"
            icon="pi pi-search-plus"
            (click)="showExtendedSearch = !showExtendedSearch"
          ></button>
        </div>
        <app-project-extended-search
          [table]="dt"
          [infoColumns]="infoColumns"
          [kpiColumns]="kpiColumns"
          [hidden]="!showExtendedSearch"
          [filters]="filters"
          (searchCriteriaChanged)="getProjectsWithKpis()"
        >
        </app-project-extended-search>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <div>
        <p-dropdown
          #actionMenu
          placeholder="Välj åtgärd"
          [options]="actions"
          optionLabel="label"
          optionValue="value"
          (onChange)="handleTableSelection(dt, actionMenu, $event)"
        >
          <ng-template pTemplate="item" let-action>
            <span class="p-d-flex p-ai-center">
              <i [class]="action.icon" class="p-mr-2"> </i>
              <span>{{ action.label }}</span>
            </span>
          </ng-template>
        </p-dropdown>
      </div>
    </ng-template>
    <ng-template pTemplate="paginatorright"> </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th [style.width]="'3.5rem'">
          <div class="p-d-flex p-jc-between p-ai-center">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            <button
              class="p-button-text legacy-rounded-button"
              pTooltip="Visa alla underprojekt"
              (click)="actionExpandAll()"
              pButton
              [style]="{
                transform: allExpanded ? 'rotate(90deg)' : 'rotate(0deg)'
              }"
              icon="pi pi-angle-double-right"
            ></button>
          </div>
        </th>
        <th
          *ngFor="let col of allColumns"
          [pSortableColumn]="col.field"
          [style.width]="col.width"
          [hidden]="!col.active"
        >
          <span
            class="p-d-inline-block p-text-truncate"
            [pTooltip]="col.header"
            [style.maxWidth]="'90%'"
          >
            {{ col.header }}</span
          >
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th [style.width]="'10rem'"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-project let-expanded="expanded">
      <tr
        *ngIf="
          project.mainProject?.status !== project.status ||
          useKpiSearch ||
          searchString ||
          projectTypeId
        "
        [pSelectableRow]="project"
      >
        <td>
          <div class="p-d-flex p-jc-between p-ai-center">
            <p-tableCheckbox [value]="project"></p-tableCheckbox>
            <button
              class="p-button-text legacy-rounded-button"
              *ngIf="
                project.subProjects?.edges?.length > 0 &&
                !useKpiSearch &&
                !(searchString || projectTypeId)
              "
              [pRowToggler]="project"
              pTooltip="Visa underprojekt"
              pButton
              [style]="{
                transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)'
              }"
              icon="pi pi-chevron-right : "
            ></button>
          </div>
        </td>

        <td
          *ngFor="let col of allColumns"
          style="white-space: pre-line; position: relative"
          [hidden]="!col.active"
        >
          <a [routerLink]="['../', project.id]" class="stretched-link"></a>
          <span class="p-d-flex p-jc-between p-ai-center">
            <span class="p-d-block" style="line-height: 1.2rem">
              <app-initial-icon
                *ngIf="col.field === 'mark'"
                [text]="project.mark + project.trueId"
              ></app-initial-icon>
              {{
                col.isCurrency
                  ? (project[col.field] | currency: 'SEK')
                  : project[col.field]
              }}
            </span>
            <app-project-status-icon
              *ngIf="col.field === 'mark'"
              [project]="project"
            ></app-project-status-icon>
          </span>
        </td>

        <td class="text-cell-right p-text-truncate">
          <i
            *ngIf="project.done"
            class="pi pi-check p-p-1"
            tooltipPosition="top"
            [pTooltip]="doneStateLabels[project.done].label"
            [ngClass]="{
              'icon-done-state': project.done === PROJECT_DONE_STATE.DONE,
              'icon-done-with-changes-state':
                project.done === PROJECT_DONE_STATE.DONE_WITH_CHANGES
            }"
          ></i>

          <a [routerLink]="['../', project.mainProject?.id, 'ata']">
            <i
              *ngIf="project.isAta"
              class="pi pi-link gray"
              tooltipPosition="top"
              [pTooltip]="
                'Projektet är en ÄTA från projekt ' +
                project.mainProject?.trueId +
                '. Klicka för att visa'
              "
            ></i>
          </a>

          <a [routerLink]="['../', project.id, 'worktasks']">
            <i
              [class.green]="allDoneMap[project.id]"
              [class.gray]="!allDoneMap[project.id]"
              class="pi pi-list"
              *ngIf="project.todoCount"
              tooltipPosition="top"
              [pTooltip]="
                allDoneMap[project.id]
                  ? 'Alla arbetsmoment är klara!'
                  : 'Det finns ' +
                    project.todos.totalCount +
                    ' arbetsmoment i projektet varav ' +
                    doneCountMap[project.id] +
                    ' klara.'
              "
            ></i>
          </a>

          <i
            class="pi pi-clock p-p-1"
            *ngIf="project.notInvoicedDaysCount"
            tooltipPosition="top"
            pTooltip="Det finns {{
              project.notInvoicedDaysCount
            }} tidsrapporter som inte är fakturerade."
          ></i>

          <a [routerLink]="['../', project.id, 'supplierinvoices']">
            <i
              [ngClass]="
                project.unMovedSupplierInvoiceRowsSTAT ? 'red' : 'gray'
              "
              class="pi pi-inbox"
              *ngIf="
                project.supplierInvoiceRowsSTAT ||
                project.unMovedSupplierInvoiceRowsSTAT
              "
              tooltipPosition="top"
              [pTooltip]="
                project.unMovedSupplierInvoiceRowsSTAT
                  ? 'Det finns ' +
                    project.unMovedSupplierInvoiceRowsSTAT +
                    ' leverantörsfakturor som inte är attesterade.'
                  : 'Det finns leverantörsfakturor med totalt ' +
                    project.supplierInvoiceRowsSTAT +
                    ' rader inom projektet.'
              "
            ></i>
          </a>

          <i
            class="pi pi-flag p-p-1"
            *ngIf="project.shortMessage"
            tooltipPosition="top"
            [pTooltip]="project.shortMessage"
            [style.color]="'var(--brand-primary-color)'"
          ></i>

          <p-menu
            appendTo="body"
            #menu
            [popup]="true"
            [model]="actionMenuMap[project.id]"
          ></p-menu>

          <i (click)="openMenu(menu, $event)" class="pi pi-ellipsis-v"></i>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-mainproject>
      <tr
        *ngFor="let project of mainproject.subProjects.edges"
        [pSelectableRow]="project.node"
      >
        <td>
          <p-tableCheckbox [value]="project.node"></p-tableCheckbox>
        </td>

        <td
          *ngFor="let col of allColumns"
          style="white-space: pre-line; position: relative"
          [hidden]="!col.active"
        >
          <a [routerLink]="['../', project.node.id]" class="stretched-link"></a>
          <span class="p-d-flex p-jc-between p-ai-center">
            <span class="p-d-block" style="line-height: 1.2rem">
              <app-initial-icon
                *ngIf="col.field === 'mark'"
                [text]="project.node.mark + project.node.trueId"
              ></app-initial-icon>
              {{
                col.isCurrency
                  ? (project.node[col.field] | currency: 'SEK')
                  : project.node[col.field]
              }}
            </span>
            <app-project-status-icon
              *ngIf="col.field === 'mark'"
              [project]="project.node"
            ></app-project-status-icon>
          </span>
        </td>

        <td class="text-cell-right p-text-truncate">
          <i
            *ngIf="project.node.done"
            class="pi pi-check p-p-1"
            tooltipPosition="top"
            [pTooltip]="doneStateLabels[project.node.done].label"
            [ngClass]="{
              'icon-done-state': project.node.done === PROJECT_DONE_STATE.DONE,
              'icon-done-with-changes-state':
                project.node.done === PROJECT_DONE_STATE.DONE_WITH_CHANGES
            }"
          ></i>

          <a [routerLink]="['../', project.node.mainProject?.id, 'ata']">
            <i
              *ngIf="project.node.isAta"
              class="pi pi-link gray"
              tooltipPosition="top"
              [pTooltip]="
                'Projektet är en ÄTA från projekt ' +
                project.node.mainProject?.trueId +
                '. Klicka för att visa'
              "
            ></i>
          </a>

          <a [routerLink]="['../', project.node.id, 'worktasks']">
            <i
              [class.green]="allDoneMap[project.node.id]"
              [class.gray]="!allDoneMap[project.node.id]"
              class="pi pi-list"
              *ngIf="project.node.todoCount"
              tooltipPosition="top"
              [pTooltip]="
                allDoneMap[project.node.id]
                  ? 'Alla arbetsmoment är klara!'
                  : 'Det finns ' +
                    project.node.todos.totalCount +
                    ' arbetsmoment i projektet varav ' +
                    doneCountMap[project.node.id] +
                    ' klara.'
              "
            ></i>
          </a>

          <i
            class="pi pi-clock p-p-1"
            *ngIf="project.node.notInvoicedDaysCount"
            tooltipPosition="top"
            pTooltip="Det finns {{
              project.node.notInvoicedDaysCount
            }} tidsrapporter som inte är fakturerade."
          ></i>

          <a [routerLink]="['../', project.node.id, 'supplierinvoices']">
            <i
              [ngClass]="
                project.node.unMovedSupplierInvoiceRowsSTAT ? 'red' : 'gray'
              "
              class="pi pi-inbox"
              *ngIf="
                project.node.supplierInvoiceRowsSTAT ||
                project.node.unMovedSupplierInvoiceRowsSTAT
              "
              tooltipPosition="top"
              [pTooltip]="
                project.node.unMovedSupplierInvoiceRowsSTAT
                  ? 'Det finns ' +
                    project.node.unMovedSupplierInvoiceRowsSTAT +
                    ' leverantörsfakturor som inte är attesterade.'
                  : 'Det finns leverantörsfakturor med totalt ' +
                    project.node.supplierInvoiceRowsSTAT +
                    ' rader inom projektet.'
              "
            ></i>
          </a>

          <i
            class="pi pi-flag p-p-1"
            *ngIf="project.node.shortMessage"
            tooltipPosition="top"
            [pTooltip]="project.node.shortMessage"
            [style.color]="'var(--brand-primary-color)'"
          ></i>

          <p-menu
            appendTo="body"
            #menu
            [popup]="true"
            [model]="actionMenuMap[project.node.id]"
          ></p-menu>

          <i (click)="openMenu(menu, $event)" class="pi pi-ellipsis-v"></i>
        </td>
      </tr>
      <tr
        *ngIf="
          mainproject.subProjects?.edges?.length > 0 &&
          !useKpiSearch &&
          !(searchString || projectTypeId)
        "
      >
        <td [colSpan]="allColumns.length + 2"></td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="showTotals">
        <td></td>
        <td
          class="p-text-truncate"
          *ngFor="let col of allColumns"
          [hidden]="!col.active"
        >
          <span *ngIf="col.isCurrency">
            {{ totals[col.field] | currency: 'SEK' }}
          </span>
          <span *ngIf="!col.isCurrency">
            {{ totals[col.field] }}
          </span>
        </td>
        <td></td>
      </tr>
    </ng-template>
  </p-table>
</div>
