import * as Types from '../../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetProductExtraMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  extra?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SetProductExtraMutation = { __typename?: 'HyperionSchemaMutation', setProjectProductExtraMutation?: { __typename?: 'Projectproduct', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type RemoveMaterialInvoiceConnectionMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RemoveMaterialInvoiceConnectionMutation = { __typename?: 'HyperionSchemaMutation', removeMaterialInvoiceConnectionMutation?: { __typename?: 'Projectproduct', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export const SetProductExtraDocument = gql`
    mutation setProductExtra($id: Int, $extra: Boolean) {
  setProjectProductExtraMutation(projectProductId: $id, extra: $extra) {
    id
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetProductExtraGQL extends Apollo.Mutation<SetProductExtraMutation, SetProductExtraMutationVariables> {
    document = SetProductExtraDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveMaterialInvoiceConnectionDocument = gql`
    mutation removeMaterialInvoiceConnection($id: Int) {
  removeMaterialInvoiceConnectionMutation(id: $id) {
    id
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveMaterialInvoiceConnectionGQL extends Apollo.Mutation<RemoveMaterialInvoiceConnectionMutation, RemoveMaterialInvoiceConnectionMutationVariables> {
    document = RemoveMaterialInvoiceConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }