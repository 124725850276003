import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { ApolloMutationService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';
import { HelperService } from 'app/shared/helpers';
import { UserLocalStorageService } from 'app/shared/user';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'attendance-report-create-project',
  templateUrl: './create-project.component.html',
})
export class AttendanceReportCreateProjectComponent implements OnInit {
  mutationDone = false;
  componentMainForm: FormGroup;
  dataModel = 'project';
  dataModelCapitalized = 'Project';
  public toolTipMsgMark =
    '<strong>Märkning</strong><br>Märkningen följer med projektet genom hela processen. Märkningen är tillsammans med projekt id det som visas när man till exempel ska tidrapportera.';
  public toolTipMsgCTN =
    '<strong>Byggarbetsplats-id</strong><br>Ange Byggarbetsplats-id som du har blivit tilldelad från Skatteverket. Du kan ange vilket nummer som helst om du inte mottagit ett nummer, det ska vara 13 tecken..';

  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      mark: '',
      madeBy: '',
      constructionSiteNumber: '',
    },
  };

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private helperService: HelperService,
    public dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private userLocalStorageService: UserLocalStorageService
  ) {
    this.dialogConfig.header = 'Lägg till projekt';
  }

  ngOnInit() {
    this.setFieldsForForm();
  }

  setFieldsForForm() {
    this.formFields.attributes.madeBy =
      this.userLocalStorageService.getMEUser().fullName;

    this.initForm();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['constructionSiteNumber']['label'] =
          'Byggarbetsplats-id';
        this.componentMainForm.controls['constructionSiteNumber'].setValidators(
          [Validators.required]
        );
      });
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.componentMainForm.value;
    const today = this.helperService.dateFormatTimeZone(new Date(), true);
    const tomorrow = this.helperService.dateFormatTimeZone(
      new Date(new Date().setDate(new Date().getDate() + 1)),
      true
    );
    dataToMutation['startDate'] = today;
    dataToMutation['endDate'] = tomorrow;

    if (this.formHandler.formValid([this.componentMainForm])) {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          [
            'id',
            'constructionSiteNumber',
            'mark',
            'subcontractorRelationToProjectId',
            'trueId',
          ]
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.mutationDone = executedData;
              this.closeAction();
            }

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  } // End actionCreate

  closeAction() {
    this.dialogRef.close(this.mutationDone);
  }
}
