import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';

import { HyperionSharedModule } from 'app/shared/shared.module';
import { AttendanceReportsListComponent } from 'app/attendance-report/handle/attendance-reports-list/attendance-reports-list.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    OverlayPanelModule,
    TableModule,
    TooltipModule,
    HyperionSharedModule,
  ],
  declarations: [AttendanceReportsListComponent],
  exports: [AttendanceReportsListComponent],
})
export class AttendanceReportsListModule {}
