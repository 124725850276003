import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import {
  AttendanceReportFragment,
  BaseAttendanceReportFragment,
} from '../graphql/attendance.generated';
import { Mode } from '../project-attendance.component';

interface Report {
  orgNr: string;
  name: string;
  personnr: string;
  start: Time;
  stop: Time;
}
interface Timestamp {
  time: string;
  cancelled: boolean;
  comment: string;
}
interface Time {
  id: string;
  timestamp: Timestamp;
  edits: Timestamp[];
}

@Component({
  selector: 'app-project-attendance-table',
  templateUrl: './project-attendance-table.component.html',
  styleUrls: ['./project-attendance-table.component.scss'],
})
export class ProjectAttendanceTableComponent implements OnInit {
  @Input() public reportsInput: BehaviorSubject<AttendanceReportFragment[]>;
  @Input() public sumHours: string;

  @Output() public editEvent: EventEmitter<{
    id: string;
    orgNr: string;
    mode: Mode;
  }> = new EventEmitter();

  public reports: BehaviorSubject<Report[]> = new BehaviorSubject([]);

  public columns = [
    {
      header: 'OrgNr',
      field: 'orgNr',
      width: '8rem',
    },
    {
      header: 'Namn',
      field: 'name',
      width: 'auto',
    },
    {
      header: 'Personnr',
      field: 'personnr',
      width: '8rem',
    },
    {
      header: 'Starttid',
      field: 'start.timestamp.time',
      width: '15rem',
    },
    {
      header: 'Sluttid',
      field: 'stop.timestamp.time',
      width: '15rem',
    },
  ];

  constructor() {}

  public ngOnInit(): void {
    this.reportsInput.subscribe(res => {
      this.reports.next(res.map(r => this.prepareReport(r)));
    });
  }

  private prepareReport(report: AttendanceReportFragment): Report {
    const orgNr = report.companyOrgNr;
    const name =
      report.realUser_PreAttendanceReportTypeHyperion.firstName +
      ' ' +
      report.realUser_PreAttendanceReportTypeHyperion.lastName;
    const personnr =
      report.realUser_PreAttendanceReportTypeHyperion.personalNumber;

    const startReport = this.getLatestEdit(report.start);
    const stopReport = this.getLatestEdit(report);

    const startTime = {
      time: startReport.startOrStopTime,
      cancelled:
        report.start.isCanceled_PreAttendanceReportTypeHyperion === 'true',
      comment: startReport.commentAboutUpdate,
    };
    const stopTime = {
      time: stopReport.startOrStopTime,
      cancelled: report.isCanceled_PreAttendanceReportTypeHyperion === 'true',
      comment: stopReport.commentAboutUpdate,
    };
    const startEdits = this.getEditList(report.start);
    const stopEdits = this.getEditList(report);

    const start = {
      id: report.start.id,
      timestamp: startTime,
      edits: startEdits,
    };
    const stop = {
      id: report.id,
      timestamp: stopTime,
      edits: stopEdits,
    };

    return {
      orgNr: orgNr,
      name: name,
      personnr: personnr,
      start: start,
      stop: stop,
    };
  }

  private getEditList(report: AttendanceReportFragment): Timestamp[] {
    if (
      report.editsCached_PreAttendanceReportTypeHyperion?.edges.length === 0
    ) {
      return [];
    }
    const allRevs =
      report.editsCached_PreAttendanceReportTypeHyperion.edges.map(e => e.node);
    if (report.isCanceled_PreAttendanceReportTypeHyperion === 'false') {
      allRevs.push(report);
    }

    const latest = this.getLatestEdit(report);

    const edits = allRevs
      .filter(e => !(e === latest))
      .sort((a, b) => Number(a.id) - Number(b.id));
    return edits.map(e => ({
      time: e.startOrStopTime,
      cancelled: e.isCanceled_PreAttendanceReportTypeHyperion === 'true',
      comment: e.commentAboutUpdate,
    }));
  }

  private getLatestEdit(
    report: AttendanceReportFragment
  ): AttendanceReportFragment | BaseAttendanceReportFragment {
    if (report.editsCached_PreAttendanceReportTypeHyperion.edges.length === 0) {
      return report;
    }

    return report.editsCached_PreAttendanceReportTypeHyperion.edges
      .map(e => e.node)
      .sort((a, b) => Number(b.id) - Number(a.id))[0];
  }

  public cancel(time: Time): void {
    this.editEvent.emit({
      id: time.id,
      orgNr: null,
      mode: Mode.cancel,
    });
  }

  public edit(time: Time, orgNr: string): void {
    this.editEvent.emit({
      id: time.id,
      orgNr: orgNr,
      mode: Mode.edit,
    });
  }

  public closeReport(report: Report): void {
    this.editEvent.emit({
      id: report.stop.id,
      orgNr: report.orgNr,
      mode: Mode.close,
    });
  }
}
