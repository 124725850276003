import { Injectable } from '@angular/core';

import {
  LARGE_TABLE_COLUMNS,
  SMALL_TABLE_COLUMNS,
} from './project-product.config';

@Injectable({
  providedIn: 'root',
})
export class ProjectProductService {
  constructor() {}

  public getTableColumns(islargeTable = true) {
    let columns = islargeTable ? LARGE_TABLE_COLUMNS : SMALL_TABLE_COLUMNS;

    if (!this.showAvtalsprisCostColumn(islargeTable)) {
      columns = columns.filter(column => column.field !== 'avtalsprisCost');
    }

    if (!this.showJamforsprisColumn()) {
      columns = columns.filter(column => column.field !== 'jamforspris');
    }

    return this.setColumnsDefaults(columns);
  }

  /**
   * Rounds a number to 2 decimal places
   * Explanation and benchmarks: https://stackoverflow.com/a/48764436/1740488
   */
  public roundNumber(num: number): any {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  /** Generates an id to be used in the view */
  public getElementId(id: string, isExtra: boolean, suffix: string): string {
    return `${id}${isExtra ? 'extra' : 'normal'}${suffix}`;
  }

  public showAvtalsprisCostColumn(isLargeTable?: boolean): boolean {
    if (isLargeTable) {
      return true;
    }
    return this.isDerome();
  }

  public showJamforsprisColumn(): boolean {
    return this.isDerome();
  }

  private isDerome(): boolean {
    return localStorage.getItem('appgetenvStruqturMode') === 'derome';
  }

  private setColumnsDefaults(columns) {
    return columns.map(column => ({
      ...column,
      type: column.type ? column.type : '',
      width: column.width ? column.width : 'auto',
      tooltip: column.tooltip ? column.tooltip : '',
      textAlign: column.textAlign ? column.textAlign : 'right',
    }));
  }
}
