<div class="page-container">
  <div class="p-grid p-g-nopad">
    <p-card class="p-col-12 p-lg-6">
      <app-fortnox-oauth-integration></app-fortnox-oauth-integration>
    </p-card>
    <p-card class="p-col-12 p-lg-6">
      <app-visma-eaccounting-integration></app-visma-eaccounting-integration>
    </p-card>
  </div>
</div>
