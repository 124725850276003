<div class="p-grid">
  <div class="p-col-12">
    <div class="document-holder">
      <div class="p-grid document-title">
        <div class="p-col-8 document-column-title">Ladda upp dokument</div>
      </div>

      <hyperion-card>
        <div class="p-grid">
          <div class="p-col-12 project-title-section">
            <project-document-upload [projectId]="projectInfo.id">
            </project-document-upload>
          </div>
        </div>
      </hyperion-card>
    </div>
  </div>

  <div class="p-col-12">
    <div class="document-holder">
      <div class="p-grid document-title">
        <div class="p-col-12 document-column-title">Befintliga dokument</div>
      </div>
      <spinner *ngIf="loading | async"></spinner>
      <app-emptyState
        *ngIf="dataSet.length === 0 && !(loading | async)"
        model="Document"
        [whereToCreate]="'left'"
      ></app-emptyState>
      <div *ngFor="let document of dataSet; trackBy: helperService.rowTrackBy">
        <hyperion-card>
          <project-document-row
            [dataObjectFromParent]="document"
            [projectInfo]="projectInfo"
            (linkToPreviewEmitter)="handleNewPreviewUrl($event)"
            (dataUpdated)="onDataUpdated()"
          ></project-document-row>
        </hyperion-card>
      </div>
    </div>
  </div>
</div>
