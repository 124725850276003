import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchCompanyNoticesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchCompanyNoticesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, companyUserGlobalMessages?: { __typename?: 'CompanyUserGlobalMessageConnection', edges?: Array<{ __typename?: 'CompanyUserGlobalMessageEdge', node?: { __typename?: 'CompanyUserGlobalMessage', id: string, body?: string | null, closedByUserId?: number | null, created?: string | null, sourceName_PreCompanyUserGlobalMessageTypeHyperion?: string | null, type?: number | null } | null } | null> | null } | null } | null };

export type FetchUnreadCompanyNoticesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchUnreadCompanyNoticesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, unreadCompanyUserGlobalMessages?: number | null } | null };

export type SetAsReadMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SetAsReadMutation = { __typename?: 'HyperionSchemaMutation', setAllCompanyUserGlobalMessagesReadMutation?: { __typename?: 'Company', unreadCompanyUserGlobalMessages?: number | null } | null };

export const FetchCompanyNoticesDocument = gql`
    query fetchCompanyNotices {
  company {
    id
    companyUserGlobalMessages(last: 50) {
      edges {
        node {
          id
          body
          closedByUserId
          created
          sourceName_PreCompanyUserGlobalMessageTypeHyperion
          type
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchCompanyNoticesGQL extends Apollo.Query<FetchCompanyNoticesQuery, FetchCompanyNoticesQueryVariables> {
    document = FetchCompanyNoticesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchUnreadCompanyNoticesDocument = gql`
    query fetchUnreadCompanyNotices {
  company {
    id
    unreadCompanyUserGlobalMessages
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchUnreadCompanyNoticesGQL extends Apollo.Query<FetchUnreadCompanyNoticesQuery, FetchUnreadCompanyNoticesQueryVariables> {
    document = FetchUnreadCompanyNoticesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetAsReadDocument = gql`
    mutation setAsRead {
  setAllCompanyUserGlobalMessagesReadMutation {
    unreadCompanyUserGlobalMessages
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetAsReadGQL extends Apollo.Mutation<SetAsReadMutation, SetAsReadMutationVariables> {
    document = SetAsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }