import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchDaysQueryVariables = Types.Exact<{
  daysId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchDaysQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, days?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, date?: string | null, startTime?: string | null, stopTime?: string | null, doneWork?: string | null, userId?: number | null, hours?: number | null, extra?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, overtimeHardCoded?: string | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, project?: { __typename?: 'Project', id: string, trueId?: string | null } | null } | null } | null> | null } | null } | null };

export type FetchCompanyUsersCostTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchCompanyUsersCostTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string, name?: string | null, active?: number | null } | null } | null> | null } | null } | null };

export type FetchDaysUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchDaysUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, costTypeId?: number | null } | null } | null> | null } | null } | null };

export type FetchAttendanceTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchAttendanceTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, attendanceTypes_PreCompanyTypeHyperion?: { __typename?: 'attendanceTypes_PreCompanyTypeHyperion_ModelFunctionConnection', edges?: Array<{ __typename?: 'attendanceTypes_PreCompanyTypeHyperion_ModelFunctionEdge', node?: { __typename?: 'attendanceTypes_PreCompanyTypeHyperion_ModelFunction', code?: string | null, name?: string | null } | null } | null> | null } | null } | null };

export type FetchProjectTodosQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchProjectTodosQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', id: string, todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, description?: string | null, descriptionOffer?: string | null, done?: number | null, endDate?: string | null, estimatedTime?: number | null, offerId?: number | null, orderNr?: number | null, parentId?: number | null, projectId?: number | null, startDate?: string | null, todoType?: number | null, type?: number | null, topic?: { __typename?: 'Todotopic', id: string, Name?: string | null } | null, usersTodoRelation?: { __typename?: 'UserTodoRelationConnection', edges?: Array<{ __typename?: 'UserTodoRelationEdge', node?: { __typename?: 'UserTodoRelation', id: string, todoId?: number | null, userId?: number | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, color?: string | null } | null } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export const FetchDaysDocument = gql`
    query fetchDays($daysId: Int) {
  company {
    id
    days(id: $daysId) {
      edges {
        node {
          id
          date
          startTime
          stopTime
          doneWork
          userId
          hours
          extra
          hoursToInvoice
          privMile
          mile
          mileToInvoice
          subsistenceDay
          subsistenceHalfDay
          subsistenceNight
          overtimeHardCoded
          todoRelation {
            id
            todo_id
          }
          costTypeHyperion {
            id
            companyCostTypeId
          }
          project {
            id
            trueId
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchDaysGQL extends Apollo.Query<FetchDaysQuery, FetchDaysQueryVariables> {
    document = FetchDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchCompanyUsersCostTypesDocument = gql`
    query fetchCompanyUsersCostTypes {
  company {
    id
    userCostTypes {
      edges {
        node {
          id
          name
          active
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchCompanyUsersCostTypesGQL extends Apollo.Query<FetchCompanyUsersCostTypesQuery, FetchCompanyUsersCostTypesQueryVariables> {
    document = FetchCompanyUsersCostTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchDaysUsersDocument = gql`
    query fetchDaysUsers {
  company {
    id
    users {
      edges {
        node {
          id
          firstName
          lastName
          costTypeId
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchDaysUsersGQL extends Apollo.Query<FetchDaysUsersQuery, FetchDaysUsersQueryVariables> {
    document = FetchDaysUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchAttendanceTypesDocument = gql`
    query fetchAttendanceTypes {
  company {
    id
    attendanceTypes_PreCompanyTypeHyperion {
      edges {
        node {
          code
          name
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchAttendanceTypesGQL extends Apollo.Query<FetchAttendanceTypesQuery, FetchAttendanceTypesQueryVariables> {
    document = FetchAttendanceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProjectTodosDocument = gql`
    query fetchProjectTodos($id: Int) {
  project(id: $id) {
    id
    todos {
      edges {
        node {
          id
          description
          descriptionOffer
          done
          endDate
          estimatedTime
          offerId
          orderNr
          parentId
          projectId
          startDate
          topic {
            id
            Name
          }
          todoType
          type
          usersTodoRelation {
            edges {
              node {
                id
                todoId
                userId
                user {
                  id
                  firstName
                  lastName
                  color
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectTodosGQL extends Apollo.Query<FetchProjectTodosQuery, FetchProjectTodosQueryVariables> {
    document = FetchProjectTodosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }