<div>
  <div class="scrollbox customer-price p-mb-3">
    <div class="p-grid project-labels">
      <div class="p-col-4">Kostnader för kund</div>
      <div class="p-col-3"></div>
      <div class="p-col-5">Beräknade intäkter</div>
    </div>
    <div *ngFor="let projectCostType of projectCostTypesFromParent">
      <customer-price-row-project-cost-type
        [dataObjectFromParent]="projectCostType"
      ></customer-price-row-project-cost-type>
    </div>
    <div *ngFor="let projectUserCostType of projectUserCostTypes">
      <customer-price-row-user-cost-type
        [dataObjectFromParent]="projectUserCostType"
        [projectData]="projectData"
      ></customer-price-row-user-cost-type>
    </div>
    <div *ngIf="!loading">
      <div class="p-grid full-width">
        <div class="p-col-2">
          <input
            style="width: 100%"
            type="text"
            [(ngModel)]="projectMilePrice"
            class="form-control"
            pInputText
            (keyup)="hasTyped = true"
          />
        </div>
        <div class="p-col-1">kr</div>
        <div class="p-col-4 break-words">Mil</div>
        <div class="p-col-5">{{ projectMilePriceTotal | formatNum }} kr</div>
      </div>

      <div class="p-grid full-width" *ngIf="hasSubsistence">
        <div class="p-col-2">
          <input
            style="width: 100%"
            type="text"
            [(ngModel)]="trakDayPrice"
            class="form-control"
            pInputText
            (keyup)="hasTyped = true"
          />
        </div>
        <div class="p-col-1">kr</div>
        <div class="p-col-4 break-words">Traktamente dag</div>
        <div class="p-col-5">{{ trakDayPriceTotal | formatNum }} kr</div>
      </div>

      <div class="p-grid full-width" *ngIf="hasSubsistence">
        <div class="p-col-2">
          <input
            style="width: 100%"
            type="text"
            [(ngModel)]="trakHalfDayPrice"
            class="form-control"
            pInputText
            (keyup)="hasTyped = true"
          />
        </div>
        <div class="p-col-1">kr</div>
        <div class="p-col-4 break-words">Traktamente halvdag</div>
        <div class="p-col-5">{{ trakHalfDayPriceTotal | formatNum }} kr</div>
      </div>

      <div class="p-grid full-width" *ngIf="hasSubsistence">
        <div class="p-col-2">
          <input
            style="width: 100%"
            type="text"
            [(ngModel)]="trakNightPrice"
            class="form-control"
            pInputText
            (keyup)="hasTyped = true"
          />
        </div>
        <div class="p-col-1">kr</div>
        <div class="p-col-4 break-words">Traktamente natt</div>
        <div class="p-col-5">{{ trakNightPriceTotal | formatNum }} kr</div>
      </div>

      <div class="p-grid" *ngIf="loading">
        <div class="p-col-12">
          <spinner [inComponent]="true"></spinner>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 align-right">
      <app-button
        [disabled]="buttons.update.disabled"
        (onClick)="actionUpdate()"
        [type]="'update'"
      ></app-button>
    </div>
  </div>
</div>
