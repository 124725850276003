import { Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { ProjectContentDialogComponent } from './project-content-dialog.component';

@Injectable({ providedIn: 'root' })
export class ProjectContentDialogService {
  constructor(private appDialogService: AppDialogService) {}

  public openProjectContentDialog(data): DynamicDialogRef {
    this.appDialogService.layout = 'wide';
    this.appDialogService.data = data;
    return this.appDialogService.openComponent(ProjectContentDialogComponent);
  }
}
