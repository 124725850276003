import { gql } from 'apollo-angular';

// GQL FRAGMENTS

// Document
const FRAGMENT_DOCUMENT_METADATA = gql`
  fragment DocumentMetadata on Document {
    id
    templateName
    templateExplanation
    metaDescription
    title
    projectId
    type
    date
    status
    folderId
    project {
      id
      trueId
      mark
    }
    template {
      templateName
    }
  }
`;

export const Document = {
  fragments: {
    metadata: FRAGMENT_DOCUMENT_METADATA,
  },
};

// DocumentFolder
const FRAGMENT_DOCUMENT_FOLDER_METADATA = gql`
  fragment DocumentFolderMetadata on DocumentFolder {
    id
    description
    name
    color
    templateDocuments {
      edges {
        node {
          ...DocumentMetadata
        }
      }
    }
  }
  ${Document.fragments.metadata}
`;

export const DocumentFolder = {
  fragments: {
    metadata: FRAGMENT_DOCUMENT_FOLDER_METADATA,
  },
};

// GQL QUERIES

const DOCUMENT = {
  query: gql`
    query fetchDocument($id: Int) {
      documentGlobal(id: $id) {
        ...DocumentMetadata
        documentFolder {
          id
          name
          color
        }
      }
    }
    ${Document.fragments.metadata}
  `,
  testVariables: {},
};

const DOCUMENT_SAVE_COUNT = {
  query: gql`
    query fetchDocumentSaveCount {
      me {
        id
        documentSaves {
          totalCount
        }
      }
    }
  `,
};

const DOCUMENT_EXPORT_COUNT = {
  query: gql`
    query fetchDocumentExportCount {
      me {
        id
        documentExports {
          totalCount
        }
      }
    }
  `,
};

const DOCUMENT_TEMPLATES = {
  query: gql`
    query fetchDocumentTemplates {
      globalDocumentsByFolder {
        edges {
          node {
            ...DocumentFolderMetadata
            folders {
              edges {
                node {
                  ...DocumentFolderMetadata
                }
              }
            }
          }
        }
      }
    }
    ${DocumentFolder.fragments.metadata}
  `,
};

/** EXPORTS */

export const queries = {
  document: DOCUMENT,
  documentSaveCount: DOCUMENT_SAVE_COUNT,
  documentExportCount: DOCUMENT_EXPORT_COUNT,
  documentTemplates: DOCUMENT_TEMPLATES,
};
