import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { GlobalService } from '../global/index';

@Injectable()
export class StartupService {
  constructor(private http: HttpClient, private globalService: GlobalService) {}
  initiateStartUp() {
    const url =
      this.globalService.getUrlPrefix() + '/Company/HyperionStartupServices';
    return this.http
      .get(url)
      .toPromise()
      .then(response => response);
  }
}
