import { Injectable } from '@angular/core';
import { RouterStateSnapshot, Router } from '@angular/router';
import { Subject, ReplaySubject } from 'rxjs';
import { LocationStrategy } from '@angular/common';

interface GoogleAnalytics {
  (action: 'set', name: 'page', value: string);
  (action: 'send', hitType: 'pageview');
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private googleAnalytics: GoogleAnalytics;

  constructor(private locationStrategy: LocationStrategy) {
    this.googleAnalytics = window['ga'];
    if (this.googleAnalytics === undefined) {
      // Don't break the page if Google Analytics is unavailable...
      console.warn('Google analytics is unavailable');
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.googleAnalytics = () => {};
    }
  }

  public navigatedToPage(state: RouterStateSnapshot) {
    const absoluteUrl = `${this.locationStrategy.getBaseHref()}${state.url}`;
    this.googleAnalytics('set', 'page', absoluteUrl);
    this.googleAnalytics('send', 'pageview');
  }
}
