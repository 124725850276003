import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentService } from '../document.service';
import { ServerId } from '../document';
import { map, shareReplay, first } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-docu-upsell-exports-dialog',
  templateUrl: './docu-upsell-exports-dialog.component.html',
  styleUrls: ['./docu-upsell-exports-dialog.component.scss'],
})
export class DocuUpsellExportsDialogComponent implements OnInit {
  remainingExports: Observable<number>;
  allowDownload: Observable<boolean>;
  private documentId: ServerId;

  constructor(
    private documentService: DocumentService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.dialogConfig.header = 'Dokument export';
    this.documentId = this.dialogConfig.data['documentId'] as ServerId;
  }

  get pdfPreviewUrl(): string {
    return this.documentService.getPdfUrl(this.documentId, true, false);
  }
  get pdfDownloadUrl(): string {
    return this.documentService.getPdfUrl(this.documentId, false, false);
  }

  ngOnInit() {
    this.remainingExports = this.documentService
      .remainingExports()
      .pipe(shareReplay(1));
    this.allowDownload = this.remainingExports.pipe(map(n => n > 0));
  }

  closeDialogIfAllowed() {
    window.open(this.pdfDownloadUrl, '_blank');
    this.allowDownload.pipe(first()).subscribe(allow => {
      if (allow) {
        this.dialogRef.close();
      }
    });
  }
}
