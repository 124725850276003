import { Component, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/shared/config/config.service';
import { Theme } from 'app/shared/config/config';
import {
  ActivatedRoute,
  Event,
  Router,
  RouterEvent,
  Routes,
} from '@angular/router';
import { RouteWithData } from 'app/routes.service';
import { filter, Subscription } from 'rxjs';

@Component({
  selector: 'app-sub-menu',
  templateUrl: 'sub-menu.component.html',
  styleUrls: ['sub-menu.component.scss'],
})
export class SubMenuComponent implements OnDestroy {
  public theme: Theme;
  public subMenuLinks: Routes;
  public activatedRoute: ActivatedRoute;
  private routeChange: Subscription;

  constructor(
    protected configService: ConfigService,
    public route: ActivatedRoute,
    private router: Router
  ) {
    this.theme = this.configService.getConfig().theme;
    this.activatedRoute = this.getParentRoute(this.route);

    this.subMenuLinks =
      this.activatedRoute.snapshot.routeConfig?.children?.filter(
        (subMenuRoute: RouteWithData) => subMenuRoute.data?.showInSubMenu
      );

    this.subMenuLinks?.forEach(sml =>
      sml.data?.countService?.setup(this.activatedRoute)
    );

    this.updateActivatedRouteOnRouteChange();
  }

  public ngOnDestroy(): void {
    this.routeChange.unsubscribe();
  }

  private updateActivatedRouteOnRouteChange(): void {
    this.routeChange = this.router.events
      .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
      .subscribe(_ => {
        this.activatedRoute = this.getParentRoute(this.route);
      });
  }

  /**
   * Travels up the parent tree to find first parent route of
   * route with sub menu routes
   */
  private getParentRoute(currentRoute: ActivatedRoute): ActivatedRoute {
    if (currentRoute.snapshot.routeConfig?.data?.showInSubMenu) {
      return currentRoute.parent;
    }

    if (currentRoute.snapshot.routeConfig?.children?.length > 0) {
      return currentRoute;
    }

    if (currentRoute.snapshot.routeConfig?.data?.showInMainMenu) {
      return currentRoute;
    }

    return this.getParentRoute(currentRoute.parent);
  }
}
