<div class="dialog-container">
  <div class="p-grid full-width">
    <div class="p-col-12">
      <p-autoComplete
        [suggestions]="results"
        (completeMethod)="searchProducts($event)"
        inputStyleClass="form-control"
        styleClass="wide-panel-small"
        placeholder="Sök produkt du vill se uttag för"
        [(ngModel)]="productAutoModel"
        [ngModelOptions]="{ standalone: true }"
        field="benamning"
        (onSelect)="setProductFromAutosuggest($event)"
        [style]="{ 'width': '100%', 'text-align': 'left' }"
        [inputStyle]="{ 'width': '100%', 'text-align': 'left' }"
      >
        <ng-template let-object pTemplate="item">
          <div class="p-helper-clearfix" style="font-size: 12px">
            <div class="autocomplete-row autocomplete-header-row">
              <div style="width: 23%">Art. nr</div>
              <div style="width: 47%">Benämning</div>
              <div style="width: 15%">Förs.pris</div>
              <div style="width: 15%">Inköpspris</div>

              <div style="float: none" class="clear-both"></div>
            </div>
            <div class="autocomplete-row">
              <div style="width: 23%">{{ object.artnr }}</div>
              <div style="width: 47%">{{ object.benamning }}</div>
              <div style="width: 15%">{{ object.avtalspris }}</div>
              <div style="width: 15%">
                {{ object.avtalsprisCost }}
              </div>

              <div style="float: none" class="clear-both"></div>
            </div>
          </div>
        </ng-template>
      </p-autoComplete>
    </div>
    <div class="p-col-3">
      <p-calendar
        firstDayOfWeek="1"
        dateFormat="yy-mm-dd"
        dataType="string"
        showIcon="true"
        [(ngModel)]="dateGt"
        (onSelect)="updateDateFilter($event, 'dateGt', 'gte')"
        (onInput)="updateDateFilter($event.target.value, 'dateGt', 'gte')"
        [showWeek]="true"
      ></p-calendar>
    </div>
    <div class="p-col-3">
      <p-calendar
        firstDayOfWeek="1"
        dateFormat="yy-mm-dd"
        dataType="string"
        showIcon="true"
        [(ngModel)]="dateLt"
        (onSelect)="updateDateFilter($event, 'dateLt', 'lte')"
        (onInput)="updateDateFilter($event.target.value, 'dateLt', 'lte')"
        [showWeek]="true"
      ></p-calendar>
    </div>
    <div class="p-col-12">
      <div class="p-grid">
        <spinner *ngIf="loading"></spinner>

        <div class="p-col-12">
          <p-table
            [value]="products"
            dataKey="id"
            [rowHover]="true"
            #productsTable
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="benamning" style="width: 30%">
                  <input
                    placeholder="Benämning"
                    style="width: 80%"
                    type="text"
                    (input)="
                      productsTable.filter(
                        $event.target.value,
                        'benamning',
                        'contains'
                      )
                    "
                  />
                  <p-sortIcon field="benamning"></p-sortIcon>
                </th>
                <th pSortableColumn="created">
                  Sparad<p-sortIcon field="created"></p-sortIcon>
                </th>
                <th pSortableColumn="date">
                  Datum<p-sortIcon field="date"></p-sortIcon>
                </th>
                <th pSortableColumn="antal">
                  Antal<p-sortIcon field="antal"></p-sortIcon>
                </th>

                <th pSortableColumn="projectString">
                  <input
                    placeholder="Projekt"
                    style="width: 80%"
                    type="text"
                    (input)="
                      productsTable.filter(
                        $event.target.value,
                        'projectString',
                        'contains'
                      )
                    "
                  />
                  <p-sortIcon field="projectString"></p-sortIcon>
                </th>
                <th pSortableColumn="clientString">
                  <input
                    placeholder="Beställare"
                    style="width: 80%"
                    type="text"
                    (input)="
                      productsTable.filter(
                        $event.target.value,
                        'clientString',
                        'contains'
                      )
                    "
                  />
                  <p-sortIcon field="clientString"></p-sortIcon>
                </th>
                <th pSortableColumn="userString">
                  <input
                    placeholder="Användare"
                    style="width: 80%"
                    type="text"
                    (input)="
                      productsTable.filter(
                        $event.target.value,
                        'userString',
                        'contains'
                      )
                    "
                  />
                  <p-sortIcon field="userString"></p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
              <tr (click)="productsTable.toggleRow(rowData)">
                <td style="white-space: pre-line">{{ rowData.benamning }}</td>
                <td style="white-space: pre-line">{{ rowData.created }}</td>
                <td style="white-space: pre-line">{{ rowData.date }}</td>
                <td style="white-space: pre-line">{{ rowData.antal }}</td>
                <td style="white-space: pre-line">
                  {{ rowData.projectString }}
                </td>
                <td style="white-space: pre-line">
                  {{ rowData.clientString }}
                </td>
                <td style="white-space: pre-line">{{ rowData.userString }}</td>
              </tr>
            </ng-template>

            <ng-template pTemplate="footer">
              <tr>
                <td colspan="3">Total</td>
                <td>{{ sumAntal(productsTable) }}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
              <tr class="emptyStateTR">
                <td colspan="5">
                  <app-emptyState
                    [model]="'Product'"
                    [whereToCreate]="'topRight'"
                  ></app-emptyState>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
