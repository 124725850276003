<spinner *ngIf="loading" [inComponent]="true"></spinner>
<div *ngIf="!loading" class="start-smart-scroll small-text">
  <div class="p-grid p-text-bold">
    <div class="p-col-12 p-lg-4">Användare</div>
    <div class="p-col-12 p-lg-4">Skapad</div>
    <div class="p-col-12 p-lg-3">Projekt</div>
  </div>
  <div class="scroll-body small">
    <div *ngFor="let object of dataSet">
      <div
        class="p-grid home-start-object"
        pTooltip="<p><strong>Kommentar</strong></p><p>{{ object.comment }}</p>"
        tooltipPosition="bottom"
        [escape]="false"
      >
        <div class="p-col-12 p-lg-4">
          <label class="home-start-responsive-label strong-text"
            >Användare</label
          >
          <p>{{ object.user.firstName }} {{ object.user.lastName }}</p>
        </div>
        <div class="p-col-12 p-lg-4">
          <label class="home-start-responsive-label strong-text">Skapad</label>
          <p>{{ object.created }}</p>
        </div>
        <div class="p-col-12 p-lg-3">
          <label class="home-start-responsive-label strong-text">Projekt</label>
          <a [href]="urlPrefix + '/v2/project/' + object.project.id">{{
            object.project.trueId
          }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
