import * as Types from '../../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CostTypesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CostTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string, name?: string | null, cost?: number | null, active?: number | null } | null } | null> | null } | null } | null, project?: { __typename?: 'Project', milePrice?: number | null, trakDayPrice?: number | null, trakHalfDayPrice?: number | null, trakNightPrice?: number | null, days?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', type?: string | null, costTypeId?: number | null, hoursToInvoice?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, mileToInvoice?: number | null } | null } | null> | null } | null, projectCostTypes?: { __typename?: 'ProjectCostTypeConnection', edges?: Array<{ __typename?: 'ProjectCostTypeEdge', node?: { __typename?: 'ProjectCostType', id: string, name?: string | null, procent?: number | null, companyCostTypeId?: string | null } | null } | null> | null } | null, userCostTypes?: { __typename?: 'ProjectUserCostTypeConnection', edges?: Array<{ __typename?: 'ProjectUserCostTypeEdge', node?: { __typename?: 'ProjectUserCostType', id: string, companyCostTypeId?: number | null, cost?: number | null } | null } | null> | null } | null, projectProducts?: { __typename?: 'ProjectproductConnection', edges?: Array<{ __typename?: 'ProjectproductEdge', node?: { __typename?: 'Projectproduct', antal?: number | null, avtalspris?: number | null, companyCostTypeId?: number | null, projectCostTypeId?: number | null } | null } | null> | null } | null } | null };

export type CompanyCostTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyCostTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', userCostTypes?: { __typename?: 'CompanyUserCostTypeConnection', edges?: Array<{ __typename?: 'CompanyUserCostTypeEdge', node?: { __typename?: 'CompanyUserCostType', id: string, name?: string | null, cost?: number | null, active?: number | null } | null } | null> | null } | null, companyCostTypes?: { __typename?: 'CompanyCostTypeConnection', edges?: Array<{ __typename?: 'CompanyCostTypeEdge', node?: { __typename?: 'CompanyCostType', id: string, name?: string | null, unit?: string | null, procent?: number | null, active?: number | null } | null } | null> | null } | null } | null };

export type ProjectCostTypeFragment = { __typename?: 'ProjectCostType', id: string, name?: string | null, procent?: number | null, companyCostTypeId?: string | null };

export type ProjectUserCostTypeFragment = { __typename?: 'ProjectUserCostType', id: string, companyCostTypeId?: number | null, cost?: number | null };

export type CompanyUserCostTypeFragment = { __typename?: 'CompanyUserCostType', id: string, name?: string | null, cost?: number | null, active?: number | null };

export type CompanyCostTypeFragment = { __typename?: 'CompanyCostType', id: string, name?: string | null, unit?: string | null, procent?: number | null, active?: number | null };

export type ProductFragment = { __typename?: 'Projectproduct', antal?: number | null, avtalspris?: number | null, companyCostTypeId?: number | null, projectCostTypeId?: number | null };

export type UpdateProjectCostTypeMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  cost?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateProjectCostTypeMutation = { __typename?: 'HyperionSchemaMutation', projectCostTypeTypeHyperionMutation?: { __typename?: 'ProjectCostType', id: string, procent?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type UpdateProjectUserCostTypeMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  cost?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateProjectUserCostTypeMutation = { __typename?: 'HyperionSchemaMutation', projectUserCostTypeTypeHyperionMutation?: { __typename?: 'ProjectUserCostType', id: string, cost?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type UpdateProjectMilePriceMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  milePrice?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateProjectMilePriceMutation = { __typename?: 'HyperionSchemaMutation', projectTypeHyperionMutation?: { __typename?: 'Project', id: string, milePrice?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type UpdateProjectPricesMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  milePrice?: Types.InputMaybe<Types.Scalars['Float']>;
  trakDayPrice?: Types.InputMaybe<Types.Scalars['Float']>;
  trakHalfDayPrice?: Types.InputMaybe<Types.Scalars['Float']>;
  trakNightPrice?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateProjectPricesMutation = { __typename?: 'HyperionSchemaMutation', projectTypeHyperionMutation?: { __typename?: 'Project', id: string, milePrice?: number | null, trakDayPrice?: number | null, trakHalfDayPrice?: number | null, trakNightPrice?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type UpdateSubprojectsMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateSubprojectsMutation = { __typename?: 'HyperionSchemaMutation', updateSubprojectCostsMutation?: { __typename?: 'Project', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type CreateProjectCostTypeMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  companyCostTypeId?: Types.InputMaybe<Types.Scalars['String']>;
  procent?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type CreateProjectCostTypeMutation = { __typename?: 'HyperionSchemaMutation', projectCostTypeTypeHyperionMutation?: { __typename?: 'ProjectCostType', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type CreateProjectUserCostTypeMutationVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  companyCostTypeId?: Types.InputMaybe<Types.Scalars['Int']>;
  cost?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type CreateProjectUserCostTypeMutation = { __typename?: 'HyperionSchemaMutation', projectUserCostTypeTypeHyperionMutation?: { __typename?: 'ProjectUserCostType', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export const ProjectCostTypeFragmentDoc = gql`
    fragment projectCostType on ProjectCostType {
  id
  name
  procent
  companyCostTypeId
}
    `;
export const ProjectUserCostTypeFragmentDoc = gql`
    fragment projectUserCostType on ProjectUserCostType {
  id
  companyCostTypeId
  cost
}
    `;
export const CompanyUserCostTypeFragmentDoc = gql`
    fragment companyUserCostType on CompanyUserCostType {
  id
  name
  cost
  active
}
    `;
export const CompanyCostTypeFragmentDoc = gql`
    fragment companyCostType on CompanyCostType {
  id
  name
  unit
  procent
  active
}
    `;
export const ProductFragmentDoc = gql`
    fragment product on Projectproduct {
  antal
  avtalspris
  companyCostTypeId
  projectCostTypeId
}
    `;
export const CostTypesDocument = gql`
    query costTypes($id: Int) {
  company {
    userCostTypes {
      edges {
        node {
          ...companyUserCostType
        }
      }
    }
  }
  project(id: $id) {
    milePrice
    trakDayPrice
    trakHalfDayPrice
    trakHalfDayPrice
    trakNightPrice
    days {
      edges {
        node {
          type
          costTypeId
          hoursToInvoice
          subsistenceDay
          subsistenceHalfDay
          subsistenceNight
          mileToInvoice
        }
      }
    }
    projectCostTypes {
      edges {
        node {
          ...projectCostType
        }
      }
    }
    userCostTypes {
      edges {
        node {
          ...projectUserCostType
        }
      }
    }
    projectProducts {
      edges {
        node {
          ...product
        }
      }
    }
  }
}
    ${CompanyUserCostTypeFragmentDoc}
${ProjectCostTypeFragmentDoc}
${ProjectUserCostTypeFragmentDoc}
${ProductFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CostTypesGQL extends Apollo.Query<CostTypesQuery, CostTypesQueryVariables> {
    document = CostTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyCostTypesDocument = gql`
    query companyCostTypes {
  company {
    userCostTypes {
      edges {
        node {
          ...companyUserCostType
        }
      }
    }
    companyCostTypes {
      edges {
        node {
          ...companyCostType
        }
      }
    }
  }
}
    ${CompanyUserCostTypeFragmentDoc}
${CompanyCostTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyCostTypesGQL extends Apollo.Query<CompanyCostTypesQuery, CompanyCostTypesQueryVariables> {
    document = CompanyCostTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectCostTypeDocument = gql`
    mutation updateProjectCostType($id: Int, $cost: Float) {
  projectCostTypeTypeHyperionMutation(
    updateProjectCostType: {id: $id, procent: $cost}
  ) {
    id
    procent
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectCostTypeGQL extends Apollo.Mutation<UpdateProjectCostTypeMutation, UpdateProjectCostTypeMutationVariables> {
    document = UpdateProjectCostTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectUserCostTypeDocument = gql`
    mutation updateProjectUserCostType($id: Int, $cost: Float) {
  projectUserCostTypeTypeHyperionMutation(
    updateProjectUserCostType: {id: $id, cost: $cost}
  ) {
    id
    cost
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectUserCostTypeGQL extends Apollo.Mutation<UpdateProjectUserCostTypeMutation, UpdateProjectUserCostTypeMutationVariables> {
    document = UpdateProjectUserCostTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectMilePriceDocument = gql`
    mutation updateProjectMilePrice($id: Int, $milePrice: Float) {
  projectTypeHyperionMutation(updateProject: {id: $id, milePrice: $milePrice}) {
    id
    milePrice
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectMilePriceGQL extends Apollo.Mutation<UpdateProjectMilePriceMutation, UpdateProjectMilePriceMutationVariables> {
    document = UpdateProjectMilePriceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectPricesDocument = gql`
    mutation updateProjectPrices($id: Int, $milePrice: Float, $trakDayPrice: Float, $trakHalfDayPrice: Float, $trakNightPrice: Float) {
  projectTypeHyperionMutation(
    updateProject: {id: $id, milePrice: $milePrice, trakDayPrice: $trakDayPrice, trakHalfDayPrice: $trakHalfDayPrice, trakNightPrice: $trakNightPrice}
  ) {
    id
    milePrice
    trakDayPrice
    trakHalfDayPrice
    trakNightPrice
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectPricesGQL extends Apollo.Mutation<UpdateProjectPricesMutation, UpdateProjectPricesMutationVariables> {
    document = UpdateProjectPricesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSubprojectsDocument = gql`
    mutation updateSubprojects($id: Int) {
  updateSubprojectCostsMutation(projectId: $id) {
    id
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSubprojectsGQL extends Apollo.Mutation<UpdateSubprojectsMutation, UpdateSubprojectsMutationVariables> {
    document = UpdateSubprojectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectCostTypeDocument = gql`
    mutation createProjectCostType($name: String, $projectId: Int, $companyCostTypeId: String, $procent: Float) {
  projectCostTypeTypeHyperionMutation(
    createProjectCostType: {name: $name, companyCostTypeId: $companyCostTypeId, projectId: $projectId, procent: $procent}
  ) {
    id
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectCostTypeGQL extends Apollo.Mutation<CreateProjectCostTypeMutation, CreateProjectCostTypeMutationVariables> {
    document = CreateProjectCostTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectUserCostTypeDocument = gql`
    mutation createProjectUserCostType($projectId: Int, $companyCostTypeId: Int, $cost: Float) {
  projectUserCostTypeTypeHyperionMutation(
    createProjectUserCostType: {companyCostTypeId: $companyCostTypeId, projectId: $projectId, cost: $cost}
  ) {
    id
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectUserCostTypeGQL extends Apollo.Mutation<CreateProjectUserCostTypeMutation, CreateProjectUserCostTypeMutationVariables> {
    document = CreateProjectUserCostTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }