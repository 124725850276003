import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateCommentMutationVariables = Types.Exact<{
  createCommentObject?: Types.InputMaybe<Types.CommentTypeHyperionMutationInput>;
}>;


export type CreateCommentMutation = { __typename?: 'HyperionSchemaMutation', commentTypeHyperionMutation?: { __typename?: 'Comment', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type UpdateCommentMutationVariables = Types.Exact<{
  updateCommentObject?: Types.InputMaybe<Types.CommentTypeHyperionMutationInput>;
}>;


export type UpdateCommentMutation = { __typename?: 'HyperionSchemaMutation', commentTypeHyperionMutation?: { __typename?: 'Comment', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type DeleteCommentMutationVariables = Types.Exact<{
  deleteCommentObject?: Types.InputMaybe<Types.CommentTypeHyperionMutationInput>;
}>;


export type DeleteCommentMutation = { __typename?: 'HyperionSchemaMutation', commentTypeHyperionMutation?: { __typename?: 'Comment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const CreateCommentDocument = gql`
    mutation createComment($createCommentObject: CommentTypeHyperionMutationInput) {
  commentTypeHyperionMutation(createComment: $createCommentObject) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
    id
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCommentGQL extends Apollo.Mutation<CreateCommentMutation, CreateCommentMutationVariables> {
    document = CreateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCommentDocument = gql`
    mutation updateComment($updateCommentObject: CommentTypeHyperionMutationInput) {
  commentTypeHyperionMutation(updateComment: $updateCommentObject) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
    id
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCommentGQL extends Apollo.Mutation<UpdateCommentMutation, UpdateCommentMutationVariables> {
    document = UpdateCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCommentDocument = gql`
    mutation deleteComment($deleteCommentObject: CommentTypeHyperionMutationInput) {
  commentTypeHyperionMutation(deleteComment: $deleteCommentObject) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCommentGQL extends Apollo.Mutation<DeleteCommentMutation, DeleteCommentMutationVariables> {
    document = DeleteCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }