import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class IdleService {
  idleControl: Subject<string> = new Subject<string>();

  startIdle() {
    this.idleControl.next('start');
  }

  stopIdle() {
    this.idleControl.next('stop');
  }
}
