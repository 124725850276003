<app-header *ngIf="!inModal"></app-header>
<p-sidebar [(visible)]="showCreateProjectDialog" position="right">
  <app-project-form
    (projectCreatedEvent)="displayNewProject($event)"
  ></app-project-form>
</p-sidebar>
<div
  class="page-container p-grid"
  *appLoaded="isLoaded | async; notFound: notFound | async"
>
  <div class="docu-edit-sidebar-container p-col-12 p-lg-3">
    <app-docu-sidebar
      [metadata]="documentMetadata | async"
      [document]="documentSectionHeaders | async"
      [dirty]="dirty"
      [focusedSection]="focusedSection"
      (docChange)="applyChange($event)"
      (scrollToSection)="scrollToSection($event)"
      (save)="saveNow($event)"
    ></app-docu-sidebar>
  </div>
  <p-card class="p-col-12 p-lg-9">
    <ul class="">
      <li>
        <div class="docu-row">
          <div class="p-grid p-d-flex p-ai-center">
            <div class="p-col-4">
              <h2>Ange projekt</h2>
            </div>
            <div class="p-col-5">
              <app-project-select
                [projectId]="projectId ?? (documentMetadata | async).projectId"
                (selectedProjectEvent)="changeProjectId($event.id)"
              ></app-project-select>
            </div>
            <div class="p-col-3 p-d-flex p-jc-end">
              <button
                pButton
                icon="pi pi-plus"
                label="Skapa projekt"
                class="p-button-primary-hyperion"
                (click)="openAddProjectDialog()"
              ></button>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="document-header p-grid">
          <div class="p-col-8">
            <h1 class="p-text-truncate">
              <app-docu-renameable
                [name]="(documentMetadata | async).name"
                (nameChange)="changeName($event)"
              ></app-docu-renameable>
            </h1>
            <p style="white-space: break-spaces">
              {{ (documentMetadata | async).explanation }}
            </p>
          </div>
          <app-docu-logo
            class="p-col-4"
            [uploading]="logoUploading"
            [image]="logo | async"
            (imageChange)="changeLogo($event)"
          >
          </app-docu-logo>
        </div>
      </li>

      <li
        #section
        id="docu-edit-section-{{ row.row.id }}"
        [attr.data-row-id]="row.row.id"
        *ngFor="
          let row of (document | async).rows;
          index as i;
          trackBy: trackSectionById
        "
      >
        <app-docu-row
          [row]="row"
          [canRename]="true"
          (docChange)="applyChange($event)"
          [width]="12"
        ></app-docu-row>
      </li>
    </ul>
  </p-card>
</div>
<app-docu-upgrade-banner></app-docu-upgrade-banner>
