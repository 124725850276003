<form
  [formGroup]="loadedForm"
  *appLoaded="form | async as loadedForm"
  #formBody
>
  <div class="p-grid">
    <div class="p-col-12 p-g-nopad">
      <appForm2-simple-input
        formControlName="templateName"
      ></appForm2-simple-input>
      <appForm2-simple-dropDown
        [dropDownParams]="params"
        formControlName="projectId"
        *appLoaded="projectIdDropDownParams | async as params"
        appendTo="body"
      >
      </appForm2-simple-dropDown>
    </div>

    <div class="p-col-12 align-right">
      <app-button type="cancel" (click)="closeAction()"></app-button>
      <app-button class="ml5" type="create" (click)="save()"></app-button>
    </div>
  </div>
</form>
