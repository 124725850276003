import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

import { MessageService } from 'app/shared/message/index';
import { HelperService } from 'app/shared/helpers/index';
import { GlobalService } from 'app/shared/global/index';
import { ContactsAutosuggestService } from 'app/shared/company/index';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';

const constDataModel = 'costumerRelationToProject';

@Component({
  selector: 'app-share-project-customer',
  templateUrl: './share-project-customer.component.html',
  styleUrls: ['../project-share-row.component.scss'],
  providers: [FormHandlerService],
})
export class ShareProjectCustomerComponent {
  @Input() projectInfo;
  dataModel = constDataModel;
  dataModelCapitalized;
  loading = true;
  lockButtons = false;
  formStructure = {
    model: 'PrivateCostumer',
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
      telephonenumber: '',
      mobilenumber: '',
    },
  };
  formStructureBoxes = {
    model: 'CostumerRelationToProject',
    attributes: {
      offer: 0,
      timereport: 0,
      product: 0,
      invoice: 0,
      picture: 0,
      document: 0,
      todo: 0,
      invoiceNotification: 0,
      documentNotification: 0,
      comment: 0,
    },
  };
  inputForm: FormGroup;
  checkBoxesForm: FormGroup;
  results: any[];
  autoCompleteModel: any;

  constructor(
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    public helperService: HelperService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private contactsAutosuggestService: ContactsAutosuggestService
  ) {
    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
    this.initAllForms();
  }

  initAllForms() {
    Promise.all([
      this.formHandler.groupSetLabelsRules(this.formStructure),
      this.formHandler.groupSetLabelsRules(this.formStructureBoxes),
    ]).then(([inputForm, checkBoxesForm]) => {
      this.inputForm = inputForm;
      this.checkBoxesForm = checkBoxesForm;
      this.loading = false;
    });
  }

  searchContacts(event) {
    this.contactsAutosuggestService.getContacts(event.query).then(data => {
      if (data['success']) {
        const result = [];
        for (const i in data['data']) {
          result.push(data['data'][i]);
        }
        this.results = result;
      }
      this.messageService.insertDataPOSTGET(data);
    });
  }

  setContactFromAutosuggest(value) {
    this.inputForm.reset();

    this.inputForm['controls']['firstName'].setValue(value['name']);
    this.inputForm['controls']['email'].setValue(value['mail']);
    this.inputForm['controls']['telephonenumber'].setValue(value['phone']);
    this.inputForm['controls']['mobilenumber'].setValue(value['mobilePhone']);

    this.autoCompleteModel = null;
  }

  actionCreate() {
    this.createPrivateCustomer().subscribe(data => {
      if (data !== 'fail') {
        this.createCustomerRelationToProject(data['id']);
      }
    });
  }

  createPrivateCustomer() {
    this.lockButtons = true;
    const crudType = 'create';
    const dataToMutation = this.inputForm.value;

    return Observable.create(observer => {
      if (!this.formHandler.formValid([this.inputForm])) {
        this.lockButtons = false;
        observer.next('fail');
        observer.complete();
      } else {
        const returnVars = [
          'id',
          'firstName',
          'lastName',
          'email',
          'telephonenumber',
          'mobilenumber',
        ];

        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            'privateCostumer',
            crudType,
            false,
            dataToMutation,
            returnVars
          )
          .subscribe(
            executedData => {
              this.mutationService.displayMutationStatus(executedData);

              this.formHandler.showServerErrorsOnAttributes(executedData, [
                {
                  form: this.inputForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ]);

              if (executedData.mutationSucceededAllArguments) {
                observer.next(executedData);
                observer.complete();
              } else {
                observer.next('fail');
                observer.complete();
              }

              executeMutationSub.unsubscribe();
            },
            err => {
              console.log(err);
            }
          );
      }
    });
  }

  createCustomerRelationToProject(customerId) {
    this.lockButtons = true;
    const crudType = 'create';
    const dataToMutation = this.checkBoxesForm.value;
    dataToMutation['project_id'] = +this.projectInfo['id'];
    dataToMutation['costumer_id'] = +customerId;

    if (!this.formHandler.formValid([this.checkBoxesForm])) {
      this.lockButtons = false;
    } else {
      const refetchVars = { id: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectCustomerRelationships', variables: refetchVars },
        { name: 'singleProjectTotalCounts', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.checkBoxesForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            executedData.mutationSucceededAllArguments && this.initAllForms();
            this.lockButtons = false;
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.lockButtons = false;
          }
        );
    }
  }
}
