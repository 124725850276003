<div class="p-grid">
  <form
    class="p-grid p-col-10"
    [formGroup]="componentMainForm"
    (keyup.enter)="actionUpdate()"
  >
    <div
      class="p-col-12"
      [class.p-lg-3]="hasAccountingPlanFunction"
      [class.p-lg-6]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Namn</label>
      <input
        pInputText
        formControlName="name"
        [disabled]="!costType.active"
        [id]="componentPrefix + 'companyCostType_name_' + costType.id"
      />
    </div>

    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Procent</label>
      <input
        pInputText
        [disabled]="!costType.active"
        formControlName="procent"
        [id]="componentPrefix + 'companyCostType_procent_' + costType.id"
      />
    </div>

    <div
      class="p-col-12"
      [class.p-lg-2]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Enhet</label>
      <p-dropdown
        [disabled]="!costType.active"
        formControlName="unit"
        [options]="dropDownParams.options"
      ></p-dropdown>
    </div>

    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <label class="form-control-label show-on-small-device">Välj konto</label>
      <p-dropdown
        [filter]="true"
        [disabled]="!costType.active"
        [ngModel]="costType.accountingPlanAccountId"
        formControlName="accountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>

    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <label class="form-control-label show-on-small-device"
        >Välj konto för omvänd moms</label
      >
      <p-dropdown
        [filter]="true"
        [disabled]="!costType.active"
        [ngModel]="costType.reverseVatAccountingPlanAccountId"
        formControlName="reverseVatAccountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
  </form>

  <div class="p-col-6 p-lg-1 p-d-flex p-ai-start" *ngIf="costType.active">
    <div class="p-col-12">
      <button
        pButton
        class="p-button-icon-danger"
        [disabled]="buttons.delete.disabled || costType.isMaterial"
        (click)="confirmActionDelete()"
        icon="pi pi-trash"
      ></button>
    </div>
  </div>
  <div class="p-col-6 p-lg-1 p-d-flex p-ai-start" *ngIf="costType.active">
    <div class="p-col-12">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.update.disabled"
        (click)="actionUpdate()"
        icon="pi pi-save"
      ></button>
    </div>
  </div>
  <div class="p-col-6 p-lg-2 p-d-flex p-ai-start" *ngIf="!costType.active">
    <div class="p-col-12">
      <button
        pButton
        [disabled]="buttons.update.disabled"
        (click)="actionActivate()"
        label="Aktivera"
      ></button>
    </div>
  </div>
</div>
