import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { AppDialogService } from 'app/shared/dialogs';
import { ApolloMutationService } from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms/index';
import { MessageService } from '../../shared/message/index';
import { GlobalService } from '../../shared/global/index';
import { DocuEditComponent } from 'app/docu/docu-edit/docu-edit.component';
import { HtmlModalService } from 'app/shared/html-modal';

@Component({
  selector: 'project-document-row',
  templateUrl: 'project-document-row.component.html',
  providers: [AppDialogService, FormHandlerService],
})
export class ProjectDocumentRowComponent implements OnInit {
  @Input() public dataObjectFromParent: {
    id: string;
    comment: string;
    created: string;
    file: string;
    projectId: number;
    public: 0 | 1;
    publicForAllUsers: 0 | 1;
    userId: number;
    __typename: string;
  };

  @Input() public projectInfo: {
    shortMessage: {
      placeholder: string;
      data: string;
      isSet: boolean;
    };
    id: number;
    status: number;
    trueId: string;
    mark: string;
    client: number;
    establishment: number;
    offerId: any;
    offertId: any;
    subcontractorRelationToProjectId: number;
  };

  @Output() linkToPreviewEmitter = new EventEmitter();
  @Output() dataUpdated = new EventEmitter();

  urlPrefix: string;
  dataModel = 'documentMeta';
  dataModelCapitalized = 'DocumentMeta';
  componentMainForm: FormGroup;
  formFields = {
    model: this.dataModelCapitalized,
  };
  buttons = {
    update: {
      label: 'Uppdatera',
      disabled: false,
    },
    delete: {
      label: 'Ta bort',
      disabled: false,
    },
  };

  public FILE_PREFIX = 'Dokument_DbDocument_Document_';

  constructor(
    private appDialogService: AppDialogService,
    private confirmationService: ConfirmationService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private messageService: MessageService,
    private mutationService: ApolloMutationService,
    private htmlModalService: HtmlModalService
  ) {}

  ngOnInit() {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.formsInit();
  }

  formsInit() {
    this.formFields['attributes'] = {
      comment: this.dataObjectFromParent.comment,
      publicForAllUsers: this.dataObjectFromParent.publicForAllUsers,
      public: this.dataObjectFromParent.public,
    };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['publicForAllUsers']['label'] = '';
        this.componentMainForm['controls']['public']['label'] = '';
      });
  }

  public getDocumentUrl() {
    return `${this.urlPrefix}/project/getDocument?file=${this.dataObjectFromParent.file}&projectId=${this.projectInfo['id']}`;
  }

  public getDocumentPreviewUrl() {
    return `${this.getDocumentUrl()}&output=show`;
  }

  public openDocument() {
    const url = this.getDocumentPreviewUrl();
    this.htmlModalService.ny_sida(url, 900, 800);
  }

  public editDocument() {
    this.appDialogService.layout = 'wide';
    this.appDialogService.data = {
      id2: this.documentId,
    };
    this.appDialogService
      .openComponent(DocuEditComponent, 'Redigera Dokument')
      .onClose.subscribe(() => this.reloadDocuments());
  }

  public reloadDocuments() {
    this.dataUpdated.emit();
  }

  sendLinkToPreview(previewUrl, getUrl) {
    const urls = {
      preview: previewUrl,
      get: getUrl,
    };
    this.linkToPreviewEmitter.emit(urls);
  }

  actionUpdate() {
    const crudType = 'update';
    const publicValue =
      this.componentMainForm.controls['public'].value === 1 ||
      this.componentMainForm.controls['public'].value === true
        ? 1
        : 0;
    const publicForAllUsersValue =
      this.componentMainForm.controls['publicForAllUsers'].value === 1 ||
      this.componentMainForm.controls['publicForAllUsers'].value === true
        ? 1
        : 0;
    const dataToMutation = {
      id: Number(this.dataObjectFromParent.id),
      comment: this.componentMainForm.controls['comment'].value,
      public: publicValue,
      publicForAllUsers: publicForAllUsersValue,
    };

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchVars = { id: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectDocumentMeta', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  public get isEditable(): boolean {
    return this.dataObjectFromParent.file.startsWith(this.FILE_PREFIX);
  }

  public get documentId(): number {
    return +this.dataObjectFromParent.file.replace(this.FILE_PREFIX, '');
  }

  confirmDeleteDocument() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort dokumentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.buttons = this.formHandler.lockButtons(this.buttons);
        this.deleteDocument();
      },
      reject: () => {},
    });
  }

  private deleteDocument() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };
    const refetchVars = { id: +this.projectInfo['id'] };
    const refetchArr = [
      { name: 'projectDocumentMeta', variables: refetchVars },
      { name: 'singleProjectTotalCounts', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            this.componentMainForm
          );

          if (data.mutationSucceededAllArguments) {
            this.messageService.insertData({
              textArray: ['Dokumentet raderades'],
              time: 2000,
              type: 'success',
            });
          } else {
            this.messageService.insertData({
              textArray: ['Något gick fel. Dokumentet kunde inte raderades'],
              time: 2000,
              type: 'success',
            });
          }
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }
}
