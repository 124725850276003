import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IndexButtonComponent } from './index.component';
import { HyperionLabelsService } from '../labels';

@Component({
  selector: 'app-button',
  providers: [HyperionLabelsService],
  styleUrls: ['./button.component.scss'],
  template: `
    <span class="btn-notification">
      <button
        pButton
        type="button"
        [disabled]="disabled"
        [className]="btnClass"
        [label]="btnLabel"
        [icon]="btnIcon"
        (click)="onClickHandler()"
        [title]="title ?? ''"
      ></button>
      <span *ngIf="badge" class="badge">{{ badge }}</span>
    </span>
  `,
})
export class ButtonComponent extends IndexButtonComponent implements OnChanges {
  @Input() plainText: { label: string; icon: string };
  @Input() public title: string;
  allowedTextType = [
    'Jag har inte bestämt mig än',
    'Jag vill bli kund!',
    'Ladda ner',
    'Börja Prenumerera',
    'Sparar...',
    'Skapa konto',
    'Skapar...',
    'Spara valda rapporter till fortnox',
    'Aktivera',
  ];

  constructor(labelService: HyperionLabelsService) {
    super(labelService);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    changes.extraText && this.setButton();
  }

  setButton(): void {
    switch (this.type) {
      // CRUD / DIRECT ACTIONS START
      case 'add':
        this.btnLabel = 'Lägg till ' + this.modelStrings[0];
        break;
      case 'addNoText':
        this.btnIcon = 'pi pi-plus';
        break;
      case 'save':
        this.btnLabel = 'Spara ' + this.modelStrings[0];
        break;
      case 'saveAs':
        this.btnLabel = 'Spara som...';
        break;

      case 'create':
        this.btnLabel = 'Spara ' + this.modelStrings[0];
        this.btnClass = 'p-button-primary';
        break;

      case 'createNoText':
        this.btnIcon = 'fa fa-save fa-lg';
        this.btnClass = 'p-button-primary';
        break;

      case 'update':
        this.btnLabel = 'Uppdatera ' + this.modelStrings[0];
        this.btnClass = 'p-button-primary';
        break;
      case 'updateNoText':
        this.btnIcon = 'fa fa-save fa-lg';
        this.btnClass = 'p-button-primary';
        break;

      case 'delete':
        this.btnLabel = 'Radera ' + this.modelStrings[0];
        this.btnIcon = 'pi pi-trash';
        break;

      case 'deleteNoText':
        this.btnIcon = 'fa fa-trash-o fa-lg';
        break;

      case 'show':
        this.btnLabel = 'Visa ' + this.modelStrings[0];
        if (this.model === 'User') {
          this.btnIcon = 'pi pi-users';
        }
        break;

      case 'showOnly':
        this.btnLabel = 'Visa endast ' + this.modelStrings[0];
        break;

      case 'hide':
        this.btnLabel = 'Dölj ' + this.modelStrings[0];
        break;

      case 'close':
        this.btnLabel = 'Stäng';
        this.btnIcon = 'fa fa-times';
        break;
      case 'closeNoteText':
        this.btnIcon = 'fa fa-window-close-o';
        break;
      case 'archive':
        this.btnLabel = 'Arkivera ' + this.modelStrings[0];
        this.btnIcon = 'pi pi-angle-double-right';
        break;
      case 'archiveProjects':
        this.btnLabel = `Arkivera (${this.extraText})`;
        this.btnIcon = 'fa fa-archive';
        break;
      case 'unArchive':
        this.btnLabel = 'Flytta till pågående';
        this.btnIcon = 'pi pi-angle-double-left';
        break;

      case 'createUtkast':
        this.btnLabel = 'Skapa utkast';
        break;
      case 'updateTemplate':
        this.btnLabel = 'Uppdatera mall';
        break;
      case 'saveMall':
        this.btnLabel = 'Spara mall';
        break;
      case 'createMall':
        this.btnLabel = 'Skapa mall';
        break;

      case 'showAllProjects': // FIX
        this.btnLabel = 'Saknar du ett projekt?';
        break;

      case 'showMy':
        this.btnLabel = 'Visa Mina ' + this.modelStrings[0];
        break;

      // SIMPLE START
      case 'print':
        this.btnLabel = 'Skriv ut';
        break;

      case 'share':
        this.btnLabel = 'Dela ' + this.modelStrings[0];
        break;

      case 'back':
        this.btnLabel = 'Föregående';
        break;
      case 'forward':
        this.btnLabel = 'Nästa';
        break;

      case 'restore':
        this.btnLabel = 'Återställ ' + this.modelStrings[0];
        break;
      case 'cancel':
        this.btnLabel = 'Avbryt';

        break;
      case 'confirm':
        this.btnLabel = 'Bekräfta';
        break;
      case 'send':
        this.btnLabel = 'Skicka ' + this.modelStrings[0];
        break;
      case 'load':
        this.btnLabel = 'Ladda ' + this.modelStrings[0];
        break;

      case 'discard':
        this.btnLabel = 'Makulera';
        break;

      case 'showCreate':
        this.btnLabel = 'Skapa ' + this.modelStrings[0];
        this.btnIcon = 'pi pi-plus';
        this.btnClass = 'p-button-primary-hyperion';
        break;

      case 'showSettings':
        this.btnLabel = null;
        this.btnIcon = 'pi pi-cog';
        break;

      case 'unMarkAll':
        this.btnLabel = 'Avmarkera alla';
        break;

      case 'showReportGenerator':
        this.btnLabel = 'Rapportgenerator';
        this.btnIcon = 'fa fa-file-text-o';
        break;

      case 'book':
        this.btnLabel = 'Boka';
        break;
      case 'pick':
        this.btnLabel = 'Plocka';
        break;

      // SIMPLE END

      // File
      case 'getExcel':
        this.btnLabel = 'Ladda ner';
        this.btnIcon = 'fa fa-file-excel-o';
        break;
      case 'getExcelProjectCosts':
        this.btnLabel = 'Kostnader per projekt';
        this.btnIcon = 'fa fa-file-excel-o';
        break;
      case 'setExcel':
        this.btnLabel = 'Exportera till excel';

        this.btnIcon = 'fa fa-file-excel-o';
        break;

      case 'getPdf':
        this.btnLabel = 'Ladda ner';
        this.btnIcon = 'fa fa-file-pdf-o';
        break;
      case 'showPdf':
        this.btnLabel = 'Visa';
        this.btnIcon = 'fa fa-file-pdf-o';
        break;
      // File end

      // Alter gui START

      case 'showExtendSearch':
        this.btnLabel = 'Utökad sökning';
        this.btnIcon = 'fa fa-search-plus';
        break;

      case 'showPreview':
        this.btnLabel = 'Förhandsgranska';
        break;

      case 'hidePreview':
        this.btnLabel = 'Dölj förhandsgranskning';
        break;

      case 'toggleOverview':
        this.btnLabel = 'Visa översikt';

        break;
      case 'toogleWidth':
        this.btnLabel = 'Växla överblick';
        break;
      case 'expandView':
        this.btnLabel = 'Visa mer';
        break;

      case 'showSummation':
        this.btnLabel = 'Visa summeringar';
        break;
      // Alter gui END

      // WE DONT IF ITS CONSISTENT START
      // DOCU
      case 'goBack':
        this.btnLabel = 'Gå tillbaka';
        break;
      case 'discardChanges':
        this.btnLabel = 'Släng ändringar';
        break;

      case 'showMailComponent':
        this.btnLabel = 'Mail';
        this.btnIcon = 'fa fa-envelope-o';
        break;

      case 'emailNoText':
        this.btnIcon = 'fa fa-envelope-o';
        break;

      case 'sickDaysSeparated':
        this.btnLabel = 'Bryt ej ut frånvarodagar';
        break;
      case 'sickDaysNotSeparated':
        this.btnLabel = 'Bryt ut frånvarodagar';
        break;

      case 'gatherList': // check
        this.btnLabel = 'Visa återsamlingslista';
        break;

      case 'showDataFilter': // check
        this.btnLabel = 'Samarbetspartners data';
        break;

      case 'createAtta':
        this.btnLabel = 'Skapa ÄTA';
        break;
      case 'createProjectAta':
        this.btnLabel = 'Skapa ÄTA';
        this.btnIcon = 'pi pi-plus';
        this.btnClass = 'p-button-primary-hyperion';
        break;

      case 'updateMall':
        this.btnLabel = 'Uppdatera mall';
        break;

      case 'getSummation':
        this.btnLabel = 'Visa sammanställning';
        break;

      case 'sendToinvoice':
        this.btnLabel = 'Fakturera';
        break;

      case 'unLock':
        this.btnLabel = 'Lås upp';
        this.btnIcon = 'fa fa-key';
        break;
      case 'download':
        this.btnLabel = 'Ladda ner ' + this.modelStrings[0];
        this.btnIcon = 'fa fa-download';
        break;
      case 'showAll':
        this.btnLabel = 'Visa all';
        break;

      case 'showConnectionsDialog':
        this.btnLabel = 'Delning / Inbjudningar';
        break;
      case 'string':
        this.btnLabel = this.modelStrings[2];
        break;
      case 'definite_string':
        this.btnLabel = this.modelStrings[0];
        break;
      case 'connect':
        this.btnLabel = 'Koppla';
        break;

      case 'deliveryAdd':
        this.btnLabel = 'Leveransadress';
        break;
      case 'contactAdd':
        this.btnLabel = 'Besöksadress';
        break;
      // Model has to be fixed
      case 'Projektverktyg':
        this.btnLabel = 'Projektverktyg';
        break;
      case 'E-liggare':
        this.btnLabel = 'E-liggare';
        break;
      case 'invite':
        this.btnLabel = 'Inbjudningar';
        break;

      // logins specific
      case 'logout':
        this.btnLabel = 'Logga ut';
        break;
      case 'login':
        this.btnLabel = 'Logga in';
        this.btnClass = 'p-button-primary-hyperion';
        break;
      case 'loginNoText':
        this.btnIcon = 'fa fa-sign-in';
        break;

      case 'goHome':
        this.btnLabel = 'Gå till startsida';
        break;
      case 'resetPassword':
        this.btnLabel = 'Skicka nya användaruppgifter';
        break;
      case 'resetPassword':
        this.btnLabel = 'Skicka nya användaruppgifter';
        break;

      case 'select':
        this.btnLabel = 'Välj';
        this.btnIcon = 'fa fa-check-square-o';
        break;
      case 'selectDate':
        this.btnLabel = 'Välj datum';
        this.btnIcon = 'fa fa-check-square-o';
        break;
    }

    if (this.plainText) {
      this.btnLabel = this.plainText.label;
      this.btnIcon = this.plainText.icon || '';
    }

    if (this.allowedOverrideButtonType.includes(this.overrideButtonType)) {
      switch (this.overrideButtonType) {
        case 'secondary':
          this.btnClass = 'p-button-secondary';
          break;
        case 'primary':
          this.btnClass = 'p-button-primary';
          break;
      }
    }

    this.appendInputedClass();

    this.btnLabel &&
      (this.btnLabel = this.btnLabel.trim()) &&
      (this.btnLabel =
        this.btnLabel.charAt(0).toUpperCase() + this.btnLabel.slice(1));
    this.btnClass = this.btnClass.trim();
  }
}
