<h2
  class="docu-folder-{{ metadata.folder.color }}"
  [class.docu-edit-dirty]="dirty"
>
  {{ metadata.name }}
</h2>

<ol class="docu-edit-section-list" [dragula]="'docu-edit-section-list'">
  <li
    id="li{{ row.id }}"
    *ngFor="let row of document; index as i; trackBy: trackSectionById"
    class="docu-edit-section-list-item"
    [class.focused]="row.id == focusedSection"
  >
    <span
      class="docu-edit-section-list-scroll-button"
      (click)="scrollToSection.emit(row)"
      >{{ row.header }}</span
    >
    <app-spacer></app-spacer>
    <p-checkbox
      [binary]="true"
      [ngModel]="row.used"
      (ngModelChange)="setSectionEnabled(row, $event)"
    ></p-checkbox>
  </li>
</ol>

<div class="p-d-flex p-jc-between">
  <button
    pButton
    *ngIf="metadata.isTemplate"
    overrideButtonType="primary"
    (click)="clickSaveAs()"
    label="Spara"
  ></button>

  <app-split-button
    *ngIf="!metadata.isTemplate"
    type="save"
    [btnModel]="saveBtnItems"
    (onClick)="clickSave()"
  ></app-split-button>
  <ng-container [ngSwitch]="hasSubscribed | async">
    <p-splitButton
      *ngSwitchCase="true"
      [model]="pdfBtnItems"
      (onClick)="openPDF('download')"
      label="Ladda ner"
      icon="pi pi-file-pdf"
    ></p-splitButton>

    <button
      pButton
      *ngSwitchDefault
      (click)="showExportUpsell()"
      icon="pi pi-file-pdf"
      label="Ladda ner"
    ></button>
  </ng-container>
</div>
