<ng-container *appLoaded="!uploading">
  <button
    (click)="openPicker()"
    (dragover)="onDragOver($event)"
    (dragenter)="onDragEnter($event)"
    (dragleave)="onDragLeave($event)"
    (drop)="onDrop($event)"
    [disabled]="uploading"
    [class.uploading]="uploading"
    [class.load-failed]="loadFailed"
    [class.drag-hovering]="hovering"
    [class.drag-hovering-illegal]="hovering === 'illegal'"
  >
    <img
      class="logo logo-primary"
      [srcset]="image"
      (load)="imageLoaded(true)"
      (error)="imageLoaded(false)"
    />
    <div class="logo logo-fallback"></div>
  </button>
  <input
    #fileInput
    type="file"
    [accept]="acceptableMimeTypes.join(', ')"
    [ngModel]="undefined"
    (ngModelChange)="changeLogo(fileInput.files[0])"
  />
</ng-container>
