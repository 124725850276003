<div class="todo-rows">
  <div
    class="p-grid"
    style="border-bottom: solid 1px lightgray"
    *ngIf="meUser.type === '1'"
  >
    <div class="p-col-9">
      <label class="p-d-md-none">Arbetsmoment</label>
      {{ dataObjectFromParent.description }}
    </div>
    <div class="p-col-3">
      <label class="p-d-md-none">Ordning</label>
      {{ dataObjectFromParent.orderNr }}
    </div>
  </div>

  <form
    [formGroup]="componentMainForm"
    *ngIf="meUser.type === '3' || meUser.type === '2'"
  >
    <div class="p-grid p-g-nopad full-width">
      <div class="p-col-12 p-lg-2" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">{{
          componentMainForm.controls['description']['label']
        }}</label>
        <appForm2-list-textarea
          formControlName="description"
        ></appForm2-list-textarea>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">{{
          componentMainForm.controls['orderNr']['label']
        }}</label>
        <appForm2-list-input formControlName="orderNr"></appForm2-list-input>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <div
          style="padding-top: 8px"
          class="form-group inline"
          [class.has-danger]="componentMainForm.controls.done.showErrors"
        >
          <label class="form-control-label responsive-row-label p-d-md-none"
            >Status</label
          >
          <p-checkbox
            [(ngModel)]="doneBoolean"
            [binary]="true"
            [ngModelOptions]="{ standalone: true }"
          ></p-checkbox>
          <span *ngIf="doneBoolean" style="color: #23c400; padding-left: 5px"
            >Utfört</span
          >
          <span *ngIf="!doneBoolean" style="color: #ea3c0b; padding-left: 5px"
            >Ej utfört</span
          >
          <div *ngIf="componentMainForm.controls.done.showErrors">
            <p
              *ngFor="let error of componentMainForm.controls.done.mergedErrors"
              class="form-control-feedback"
            >
              <span><i class="fa fa-times"></i> </span
              ><span [innerHTML]="error"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">{{
          componentMainForm.controls['startDate']['label']
        }}</label>
        <appForm2-list-calendar
          formControlName="startDate"
          dateFormat="yy-mm-dd"
        ></appForm2-list-calendar>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">{{
          componentMainForm.controls['endDate']['label']
        }}</label>
        <appForm2-list-calendar
          formControlName="endDate"
          dateFormat="yy-mm-dd"
        ></appForm2-list-calendar>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">{{
          componentMainForm.controls['estimatedTime']['label']
        }}</label>
        <appForm2-list-input
          formControlName="estimatedTime"
        ></appForm2-list-input>
      </div>
      <div class="p-col-6 p-lg-1" [class.transparent]="doneBoolean">
        <label class="p-d-md-none">Utslag</label>
        <div
          *ngIf="dataObjectFromParent.utslag.users.length > 0"
          style="padding-top: 8px"
          tooltipPosition="top"
          class="link"
          [pTooltip]="utslagUserHoursTooltips()"
          [escape]="false"
        >
          {{ dataObjectFromParent.utslag.totalHours | formatNum }}h
          <i class="fa fa-hand-pointer-o"></i>
        </div>
        <div
          *ngIf="dataObjectFromParent.utslag.users.length === 0"
          style="padding-top: 8px"
        >
          {{ dataObjectFromParent.utslag.totalHours | formatNum }}h
        </div>
      </div>
      <div class="p-col-12 p-lg-1">
        <app-button
          [disabled]="buttons.update.disabled"
          (onClick)="actionUpdate()"
          [type]="'updateNoText'"
        ></app-button>
        <div *ngIf="dataObjectFromParent.offerId > 0">
          <small>Arbetsmomentet är offertbunden.</small>
        </div>
        <app-button
          class="ml5"
          *ngIf="dataObjectFromParent.offerId == null"
          [disabled]="buttons.delete.disabled"
          (onClick)="confirmDeleteTodoRow()"
          [type]="'deleteNoText'"
        ></app-button>
      </div>
      <div class="p-col-12 p-lg-2">
        <label class="p-d-md-none">Knyt medarbetare</label>
        <div>
          <div
            *ngFor="let relation of userTodoRelations"
            class="user-todo-relation-row"
          >
            <div>{{ getUserName(relation.userId) }}</div>
            <div>
              <i
                class="fa fa-times delete"
                (click)="confirmDeleteUserTodoRelation(relation.id)"
              ></i>
            </div>
          </div>
        </div>
        <appForm2-list-dropDown
          ngModel="null"
          (ngModelChange)="addUser($event)"
          [ngModelOptions]="{ standalone: true }"
          [dropDownParams]="{ options: usersDropdown, filter: true }"
        ></appForm2-list-dropDown>
      </div>

      <div *ngIf="subcontractorsDropdown.length > 0" class="p-col-12 p-lg-2">
        <label class="p-d-md-none">Skicka till underentreprenör</label>
        <appForm2-list-dropDown
          [ngModel]="null"
          (ngModelChange)="shareTodo($event)"
          [ngModelOptions]="{ standalone: true }"
          [dropDownParams]="{ options: subcontractorsDropdown, filter: true }"
        ></appForm2-list-dropDown>
      </div>
    </div>
  </form>
</div>
