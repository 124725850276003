<div class="hero docu-folder-{{ folder.color }}">
  <app-spacer></app-spacer>
  <div class="content">
    <h1>
      <ng-container *ngIf="folder.parent as parent">
        <span *ngFor="let pathElem of parent.path()">
          <app-docu-folder-link
            docuRoot="../.."
            [folder]="pathElem"
            *ngIf="primary; else parentName"
          ></app-docu-folder-link>
          <ng-template #parentName>{{ pathElem.name }}</ng-template>
          /
        </span>
      </ng-container>
      {{ folder.name }}
    </h1>
    <p>{{ folder.description }}</p>
    <p *ngIf="primary">Alla dokument är redigerbara.</p>
  </div>
  <app-spacer></app-spacer>
</div>
