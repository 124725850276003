<div class="p-grid">
  <div class="p-col-12">
    <h1>
      <ng-container *ngTemplateOutlet="titleTemplate"></ng-container>
    </h1>
  </div>
  <div class="p-col-12">
    <p>För endast {{ yearlyPrice | async }}kr/år får du:</p>
    <ul>
      <li>En plats för alla dokument</li>
      <li>Obegränsad tillgång till alla våra dokument</li>
      <li>Spara dina dokument och återanvänd dem genom att kopiera</li>
      <li>Lägg till din egen logga</li>
    </ul>
  </div>
  <div class="p-col-12 align-right">
    <app-button
      (onClick)="closeDialog('undefined')"
      [plainText]="{ label: 'Jag har inte bestämt mig än' }"
    ></app-button>
    <ng-container *ngTemplateOutlet="extraButtonsTemplate"></ng-container>
    <app-button
      class="ml5"
      overrideButtonType="primary"
      (onClick)="showSubscribeDialog()"
      [plainText]="{ label: 'Jag vill bli kund!' }"
    ></app-button>
  </div>
</div>
