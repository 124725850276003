import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SetDoneMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  done?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetDoneMutation = { __typename?: 'HyperionSchemaMutation', todoTypeHyperionMutation?: { __typename?: 'Todo', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export type DeleteTodoMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteTodoMutation = { __typename?: 'HyperionSchemaMutation', todoTypeHyperionMutation?: { __typename?: 'Todo', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export const SetDoneDocument = gql`
    mutation setDone($id: Int, $done: Int) {
  todoTypeHyperionMutation(updateTodo: {id: $id, done: $done}) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDoneGQL extends Apollo.Mutation<SetDoneMutation, SetDoneMutationVariables> {
    document = SetDoneDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTodoDocument = gql`
    mutation deleteTodo($id: Int) {
  todoTypeHyperionMutation(deleteTodo: {id: $id}) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTodoGQL extends Apollo.Mutation<DeleteTodoMutation, DeleteTodoMutationVariables> {
    document = DeleteTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }