import {
  Directive,
  Input,
  ViewContainerRef,
  TemplateRef,
  ComponentFactory,
  ComponentFactoryResolver,
} from '@angular/core';
import { Spinner } from './spinner.component';
import { PageNotFoundComponent } from '../../not-found-component';

enum Status {
  Normal,
  Loading,
  NotFound,
}

@Directive({
  selector: '[appLoaded]',
})
export class LoadedDirective<T> {
  @Input() set appLoaded(value: T) {
    this.ctx.appLoaded = value;
    this.update();
  }

  @Input() set appLoadedBusy(isBusy: boolean) {
    this.isBusy = isBusy;
    this.update();
  }

  @Input() set appLoadedNotFound(notFound: boolean) {
    this.notFoundFilter = () => notFound;
    this.update();
  }

  @Input() set appLoadedNotFoundFilter(filter: (T) => boolean) {
    this.notFoundFilter = filter;
    this.update();
  }

  private ctx: {
    appLoaded: T;
  } = {
    appLoaded: null,
  };
  private isBusy: boolean;
  private notFoundFilter: (T) => boolean = () => false;
  private spinnerFactory: ComponentFactory<Spinner>;
  private notFoundFactory: ComponentFactory<PageNotFoundComponent>;
  private status: Status = undefined;

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private componentResolver: ComponentFactoryResolver
  ) {
    this.spinnerFactory = componentResolver.resolveComponentFactory(Spinner);
    this.notFoundFactory = componentResolver.resolveComponentFactory(
      PageNotFoundComponent
    );
  }

  private pickStatus(): Status {
    if (this.notFoundFilter(this.ctx.appLoaded)) {
      return Status.NotFound;
    } else if (!this.ctx.appLoaded || this.isBusy) {
      return Status.Loading;
    } else {
      return Status.Normal;
    }
  }

  private update() {
    const newStatus = this.pickStatus();
    if (newStatus !== this.status) {
      this.status = newStatus;
      this.viewContainer.clear();
      switch (newStatus) {
        case Status.Normal:
          this.viewContainer.createEmbeddedView(this.templateRef, this.ctx);
          break;
        case Status.Loading:
          this.viewContainer.createComponent(this.spinnerFactory);
          break;
        case Status.NotFound:
          this.viewContainer.createComponent(this.notFoundFactory);
          break;
      }
    }
  }
}
