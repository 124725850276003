<div class="todo-rows">
  <form [formGroup]="componentMainForm">
    <div class="p-grid p-g-nopad full-width">
      <div class="p-col-12 p-lg-3">
        <label
          class="form-control-label responsive-row-label show-on-small-device"
          >Arbetsmoment</label
        >
        <appForm2-list-textarea
          formControlName="description"
        ></appForm2-list-textarea>
      </div>
      <div class="p-col-6 p-lg-1">
        <label
          class="form-control-label responsive-row-label show-on-small-device"
          >Ordning</label
        >
        <appForm2-list-input formControlName="orderNr"></appForm2-list-input>
      </div>
      <div class="p-col-6 p-lg-1">
        <label
          class="form-control-label responsive-row-label show-on-small-device"
          >Estimerad tid</label
        >
        <appForm2-list-input
          formControlName="estimatedTime"
          (keyup)="estimatedTimeChange.emit()"
        ></appForm2-list-input>
      </div>
      <div class="p-col-6 p-lg-2 align-center">
        <label class="show-on-small-device">Spara med detta projekt</label>
        <p-checkbox
          [(ngModel)]="saveToProject"
          [binary]="true"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="defaultForAllProjects"
        ></p-checkbox>
      </div>
      <div class="p-col-6 p-lg-2 align-center">
        <div
          class="form-group inline"
          [class.has-danger]="
            componentMainForm.controls['defaultForAllProjects']['showErrors']
          "
        >
          <label
            class="form-control-label responsive-row-label show-on-small-device"
            >Spara för alla projekt</label
          >
          <p-checkbox
            [(ngModel)]="defaultForAllProjects"
            [binary]="true"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="setSaveToProject($event.checked)"
          ></p-checkbox>
          <div
            *ngIf="
              componentMainForm.controls['defaultForAllProjects']['showErrors']
            "
          >
            <p
              *ngFor="
                let error of componentMainForm.controls.defaultForAllProjects
                  .mergedErrors
              "
              class="form-control-feedback"
            >
              <span><i class="fa fa-times"></i> </span
              ><span [innerHTML]="error"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="p-col-12 p-lg-1"></div>
      <div class="p-col-12 p-lg-2">
        <app-button
          [disabled]="buttons.update.disabled"
          (onClick)="actionUpdate()"
          [type]="'updateNoText'"
        ></app-button>
        <app-button
          class="ml5"
          *ngIf="dataObjectFromParent.offerId == null"
          [disabled]="buttons.delete.disabled"
          (onClick)="confirmDeleteTodo()"
          [type]="'deleteNoText'"
        ></app-button>
      </div>
    </div>
  </form>
</div>
