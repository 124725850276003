import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';

import { DialogComponent } from '../dialog.component';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../../shared/apollo/index';
import { CompanyCostTypeService } from '../../../shared/company/index';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';

@Component({
  selector: 'app-product',
  templateUrl: 'product.component.html',
})
export class ProductComponent
  extends DialogComponent
  implements OnDestroy, OnInit
{
  companyCostTypes;

  dataModel = 'product';
  dataModelCapitalized = 'Product';
  buttons;
  dataSet = [];
  hasSetMaterial = false;
  componentMainFormFields: {};
  units = [...QuantityUnits];

  componentMainForm: FormGroup;
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      companyCostTypeId: '',
      artnr: '',
      benamning: '',
      enhet: '',
      avtalspris: '',
      avtalsprisCost: '',
      leverantor: '',
      top: 0,
    },
  };
  loading = true;
  costTypesLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  companyCostTypeSub: Subscription;

  constructor(
    private costTypeService: CompanyCostTypeService,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    super();
    this.dialogConfig.header = 'Skapa ny produkt / kostnad';
  }

  ngOnInit() {
    this.getCompanyCostTypes();

    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.initiateForms();
  }

  ngOnDestroy() {
    this.companyCostTypeSub && this.companyCostTypeSub.unsubscribe();
  }

  getCompanyCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyCostType')
      .subscribe(({ data, sub }) => {
        this.companyCostTypeSub = sub;
        this.companyCostTypes = this.costTypeService.dataFormater(
          data,
          'labels'
        );
        this.setControlToMaterial();

        this.costTypesLoading.next(false);
      });
  }

  initiateForms() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
    this.loading = false;
  }

  setControlToMaterial() {
    for (const costTypeIndex in this.companyCostTypes) {
      const costType = this.companyCostTypes[costTypeIndex];
      if (costType['isMaterial'] === 1) {
        this.componentMainForm.controls['companyCostTypeId'].setValue(
          costType['value']
        );
      }
    }
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );
    dataToMutation['stored'] = 1; // Måste skicka med stored = 1
    this.formFields['companyCostTypeId'] = dataToMutation['companyCostTypeId'];

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.lockButtons(this.buttons);
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.initiateForms();
              this.closeAction();
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.log(err);
          }
        );
    }
  }

  closeAction() {
    this.dialogRef.close();
  }
}
