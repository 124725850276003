<spinner *ngIf="loading" [inComponent]="true"></spinner>
<div *ngIf="!loading" class="start-smart-scroll small-text">
  <div class="p-grid p-text-bold">
    <div class="p-col-12 p-lg-4">Användare</div>
    <div class="p-col-12 p-lg-4">Datum</div>
    <div class="p-col-12 p-lg-3">Timmar</div>
  </div>
  <div class="scroll-body small">
    <div *ngFor="let object of dataSet">
      <div
        class="p-grid home-start-object"
        pTooltip="<p><strong>Utfört arbete</strong></p><p>{{
          object.doneWork
        }}</p>"
        tooltipPosition="bottom"
        [escape]="false"
      >
        <div class="p-col-12 p-lg-4">
          <p>{{ object.user.firstName }} {{ object.user.lastName }}</p>
        </div>
        <div class="p-col-12 p-lg-4">
          <p>{{ object.date }}</p>
        </div>
        <div class="p-col-12 p-lg-3">
          <p>{{ object.hours }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
