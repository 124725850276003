import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject } from 'rxjs';

import { GlobalService } from 'app/shared/global';
import { HttpService } from 'app/shared/http';
import { AddSubcontractorComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor/add-subcontractor.component';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';

@Component({
  selector: 'app-subcontractor-handle',
  templateUrl: './subcontractor-handle.component.html',
  providers: [AppDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubcontractorHandleComponent implements OnDestroy {
  autoCompleteQuery = null;
  selectedSubcontractor: any;
  autoCompleteResults = new BehaviorSubject([]);

  constructor(
    private cdr: ChangeDetectorRef,
    private httpService: HttpService,
    private globalService: GlobalService,
    private dialogService: AppDialogService
  ) {}

  autoCompleteClickHandler(autoCompleteElement: AutoComplete, event) {
    const target = event.target || event.srcElement || event.currentTarget;
    const tagName = target.tagName;

    if (
      tagName === 'INPUT' &&
      (this.autoCompleteQuery === null || this.autoCompleteQuery === '')
    ) {
      this.searchSubcontractor(autoCompleteElement);
    }
  }

  searchSubcontractor(autoCompleteElement?: AutoComplete, event?) {
    const query = event ? event.query : null;
    let url = this.globalService.getUrlPrefix();
    url += query
      ? '/subcontractor/AutoComplete?q=' + query
      : '/subcontractor/AutoComplete?q=';

    url += '&addSelf=true';

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      if (!event && data.length === 0) {
        data.push({ id: null });
      }

      this.autoCompleteResults.next(data);

      if (!event) {
        autoCompleteElement.show();
      }
    });
  }

  selectSubcontractor(event, autoCompleteElement: AutoComplete) {
    this.selectedSubcontractor = { ...event };
    const inputEl = document
      .getElementById('subcontractor-auto-complete')
      .children[0].getElementsByTagName('input')[0];
    inputEl.blur();
  }

  openAddSubcontractorDialog() {
    this.dialogService
      .openComponent(AddSubcontractorComponent)
      .onClose.subscribe(res => {
        if (res) {
          const subcontractor = {
            id: res.id,
            name: res.name,
            orgNr: res.orgNr,
            approvedByCompanyId: res.approvedByCompanyId,
          };

          this.selectedSubcontractor = subcontractor;
          this.cdr.detectChanges();
        }
      });
  }

  subcontractorUpdatedHandle(event: any) {
    if (this.selectedSubcontractor) {
      const subcontractor = { ...this.selectedSubcontractor };
      for (const key in event) {
        const value = event[key];

        subcontractor[key] = value;
      }
      this.selectedSubcontractor = subcontractor;
      this.cdr.detectChanges();
    }
  }

  subcontractorDeletedHandle() {
    this.selectedSubcontractor = null;
  }

  ngOnDestroy() {
    this.cdr.detach();
  }
}
