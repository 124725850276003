import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateProjectDateSpanMutationVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type UpdateProjectDateSpanMutation = { __typename?: 'HyperionSchemaMutation', updateProjectDateSpanMutation?: { __typename?: 'Project', id: string, startDate?: string | null, endDate?: string | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null, errorsMsgs?: Array<string | null> | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type UpdateTodoDateSpanMutationVariables = Types.Exact<{
  todoId?: Types.InputMaybe<Types.Scalars['Int']>;
  startDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
  endDate?: Types.InputMaybe<Types.Scalars['DateTime']>;
}>;


export type UpdateTodoDateSpanMutation = { __typename?: 'HyperionSchemaMutation', updateTodoDateSpanMutation?: { __typename?: 'Todo', id: string, startDate?: string | null, endDate?: string | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null, errorsMsgs?: Array<string | null> | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const UpdateProjectDateSpanDocument = gql`
    mutation updateProjectDateSpan($projectId: Int, $startDate: DateTime, $endDate: DateTime) {
  updateProjectDateSpanMutation(
    projectId: $projectId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    mutationDetails {
      msg
      mutationSucceeded
      errorsMsgs
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectDateSpanGQL extends Apollo.Mutation<UpdateProjectDateSpanMutation, UpdateProjectDateSpanMutationVariables> {
    document = UpdateProjectDateSpanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTodoDateSpanDocument = gql`
    mutation updateTodoDateSpan($todoId: Int, $startDate: DateTime, $endDate: DateTime) {
  updateTodoDateSpanMutation(
    todoId: $todoId
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    startDate
    endDate
    mutationDetails {
      msg
      mutationSucceeded
      errorsMsgs
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTodoDateSpanGQL extends Apollo.Mutation<UpdateTodoDateSpanMutation, UpdateTodoDateSpanMutationVariables> {
    document = UpdateTodoDateSpanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }