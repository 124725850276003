import { gql } from 'apollo-angular';

import { User } from './user';
import { Contact } from './contact';
import { GQLFragment, Query } from './index';

const PROJECT_BOOKMARKS = {
  query: gql`
    query fetchProjectBookmarks {
      me {
        id
        ProjectBookmarks {
          edges {
            node {
              Project {
                id
                status
                trueId
                mark
                shortMessage
                offerId
                offertId
                color
                invoiceData_PreProjectTypeHyperion {
                  supplier {
                    rowsCountMoved
                    rowsCountUnMoved
                  }
                }
                notInvoicedDaysCount
                endDate
                typeId
                daysFromActiveness_PreProjectTypeHyperion
                client
                establishment
                establishmentContact {
                  id
                  name
                  address
                }
                clientContact {
                  ...ClientContactBase
                }
                projectBookmarks {
                  edges {
                    node {
                      id
                      userId
                      projectId
                    }
                  }
                }
                todos {
                  totalCount
                  edges {
                    node {
                      id
                      projectId
                      description
                      done
                      usersTodoRelation {
                        edges {
                          node {
                            id
                            user {
                              ...UserNameFull
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
    ${Contact.fragments.clientContactBase}
  `,
};

const GENERAL_DATA = {
  query: gql`
    query fetchGeneralData {
      company {
        id
        generalData_PreCompanyTypeHyperion {
          invoiceRowTypeList {
            edges {
              node {
                key
                description
              }
            }
          }
          invoiceTaxList {
            edges {
              node {
                key
                description
              }
            }
          }
        }
      }
    }
  `,
};

const COMPANY_EDIT_USER = {
  query: gql`
    query companyEditUser($id: Int) {
      company {
        id
        users(id: $id) {
          edges {
            node {
              id
              address
              address2
              allowed
              city
              cityCode
              clearingNr
              contoNr
              fortnoxTrueId
              companyDivisionId
              contractor
              costTypeId
              email
              firstName
              fixedSalary
              lastName
              mobilePhone
              personalNumber
              phone
              relative1name
              relative1other
              relative1phone
              relative2name
              relative2other
              relative2phone
              salary
              skattetabell
              socialFee
              type
              note
              vacationPeriods
              salaryType
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 2,
  },
};

const COMPANY_DIVISION_AND_USERS = {
  query: gql`
    query fecthCompanyDivisionAndUsers {
      company {
        id
        divisions {
          edges {
            node {
              id
              name
              users {
                edges {
                  node {
                    ...UserNameFull
                  }
                }
              }
            }
          }
        }
        users {
          edges {
            node {
              ...UserNameFull
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
};

const USERS_CONNECTED_TO_PROJECT = {
  query: gql`
    query fecthUsersConnectedToProject($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              relationsToUsers {
                edges {
                  node {
                    id
                    userId
                    projectId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const USER_SALARY_VACATION = {
  query: gql`
    query fetchSalaryVacationPeriodDatas($id: Int) {
      company {
        id
        users(id: $id) {
          edges {
            node {
              id
              SalaryVacationPeriodDatas(last: 5) {
                edges {
                  node {
                    id
                    start
                    stop
                    reductionOfworkingHours
                    reductionOfworkingHoursUsed
                    days
                    sumSalary
                    vacationProcent
                    userRightDays
                    userPayRightDays
                    userUnPayRightDays
                    paydUsedDays
                    unpaydUsedDays
                    daysCalculated
                    sumSalaryCalculated
                    reductionOfworkingHoursCalculated
                    actuallyPaydUsedDays
                    actuallyUnpaydUsedDays
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 2,
  },
};

const ME = {
  query: gql`
    query fetchMe {
      me {
        id
        username
        companyId
        firstName
        lastName
        email
        address
        address2
        cityCode
        city
        phone
        mobilePhone
        language
        salary
        fixedSalary
        personalNumber
        skattetabell
        clearingNr
        contoNr
        fortnoxTrueId
        relative1name
        relative2name
        relative1phone
        relative2phone
        relative1other
        relative2other
      }
    }
  `,
};

const CUSTOMER_CONTACTS: Query = {
  get query() {
    return gql`
      query fetchCustomerContacts {
        company {
          id
          contacts: costumerContacts {
            totalCount
            edges {
              node {
                ...ContactSettingsBase
                contactType
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: Contact.fragments.settingsBase,
};

const SUPPLIER_CONTACTS: Query = {
  get query() {
    return gql`
      query fetchSupplierContacts {
        company {
          id
          contacts: supplierContacts {
            totalCount
            edges {
              node {
                ...ContactSettingsBase
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: Contact.fragments.settingsBase,
};

const SINGLE_CONTACT = {
  query: gql`
    query fetchSingleContact($id: Int) {
      company {
        id
        contacts(id: $id) {
          edges {
            node {
              id
              trueId
              contactType
              reverseTax
              orderBuisnessName
              name
              orgNr
              betalningsvillkor
              bankgiro
              plusgiro
              bicSwift
              iban
              hourCost
              costProcent
              address
              address2
              cityCode
              city
              propertyName
              housingAssociationOrgNumber
              apartmentDesignation
              phone
              mobilePhone
              mail
              mailInvoice
              note
              visitingAddress
              visitingAddress2
              visitingCityCode
              visitingCity
              visitingPhone
              deliveryName
              deliveryAddress
              deliveryAddress2
              deliveryCityCode
              deliveryCity
              deliveryPhone
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const SHOW_CONTACT_LIST = {
  query: gql`
    query fetchShowContactList {
      company {
        id
        showContactListForAllUsers
      }
    }
  `,
};

const APP_UPDATES = {
  query: gql`
    query appUpdates {
      appUpdates {
        edges {
          node {
            publishDate
            publishHead
            publishBody
            publishLink
          }
        }
      }
    }
  `,
};

const USER_GUI_SORTING = {
  query: gql`
    query fetchUserGuiSorting {
      me {
        id
        userGuiSortings {
          edges {
            node {
              id
              object
              ascDesc
              attribute
            }
          }
        }
      }
    }
  `,
};

const COMPANY_CONNECTIONS = {
  query: gql`
    query fetchCompanyConnections {
      company {
        id
        subcontractors {
          edges {
            node {
              id
              name
              orgNr
              approvedByCompanyId
            }
          }
        }
        attendanceClients_PreCompanyTypeHyperion {
          edges {
            node {
              id
              name
              orgNr
              approvedByCompanyId
              company {
                id
                name
                orgNr
              }
            }
          }
        }
      }
    }
  `,
};

const PROJECT_CONSTRUCTION_SITE_NUMBER = {
  query: gql`
    query fetchProjectConstructionSiteNumber($id: Int) {
      project(id: $id) {
        id
        constructionSiteNumber
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const SUBCONTRACTOR_FREE_USERS = {
  query: gql`
    query fetchSubcontractorFreeUsers($orgNr: String) {
      company {
        id
        subcontractors(orgNr: $orgNr) {
          edges {
            node {
              id
              freeUsers {
                edges {
                  node {
                    id
                    active
                    firstName
                    lastName
                    personalNumber
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const COMPANY_ALL_FREE_USERS = {
  query: gql`
    query fetchAllFreeUsers($orgNr: String) {
      company {
        id
        freeUsers(companyOrgNr: $orgNr) {
          edges {
            node {
              id
              active
              firstName
              lastName
              personalNumber
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

/** FIXME: I believe name should be Plain, double check this. */
const PROJECTS_PLANE = {
  query: gql`
    query projectsPlane($status: [Int]) {
      company {
        id
        projects(status: $status) {
          edges {
            node {
              id
              mark
              trueId
              madeBy
            }
          }
        }
      }
    }
  `,
  testVariables: {
    status: [1],
  },
};

const COMPANY_ACCOUNTS = {
  query: gql`
    query CompanyAccounts {
      company {
        id
        account {
          id
          defaultAccountsPayableAccount
          defaultClientFundsAccount
          defaultFactoringReceivableAccount
          defaultInterestAccount
          defaultPaymentAccount
          defaultReminderAccount
          defaultReversedVatAccount
          defaultRotavdragAccount
          defaultRoundingAccount
          defaultTransactionAccount
          defaultVat12Account
          defaultVat12IncomeAccount
          defaultVat25Account
          defaultVat25IncomeAccount
          defaultVat6Account
          defaultVat6IncomeAccount
          defaultVatlessIncomeAccount
          financialYear
          sharedVerYearly
          sieSerie
        }
      }
    }
  `,
};

const SIE_EXPORTS = {
  query: gql`
    query sieExports {
      company {
        id
        sieExports_PreCompanyTypeHyperion {
          edges {
            node {
              id
              label
              info
              possibleToExport
              url
            }
          }
        }
      }
    }
  `,
};

export const queries = {
  appUpdates: APP_UPDATES,
  companyAccounts: COMPANY_ACCOUNTS,
  companyAllFreeUsers: COMPANY_ALL_FREE_USERS,
  companyConnections: COMPANY_CONNECTIONS,
  companyDivisionAndUsers: COMPANY_DIVISION_AND_USERS,
  companyEditUser: COMPANY_EDIT_USER,
  costumerContacts: CUSTOMER_CONTACTS,
  generalData: GENERAL_DATA,
  me: ME,
  projectBookmarks: PROJECT_BOOKMARKS,
  projectConstructionSiteNumber: PROJECT_CONSTRUCTION_SITE_NUMBER,
  projectsPlane: PROJECTS_PLANE,
  showContactList: SHOW_CONTACT_LIST,
  sieExports: SIE_EXPORTS,
  singleContact: SINGLE_CONTACT,
  subcontractorFreeUsers: SUBCONTRACTOR_FREE_USERS,
  supplierContacts: SUPPLIER_CONTACTS,
  userGuiSorting: USER_GUI_SORTING,
  userSalaryVacation: USER_SALARY_VACATION,
  usersConnectedToProject: USERS_CONNECTED_TO_PROJECT,
};
