import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { HyperionLabelsService } from 'app/shared/labels/hyperion-labels.service';
import { LabelsResponse } from 'app/shared/labels/labels.service';
import {
  ContactInvoicesService,
  InvoicesReduced,
} from './contact-invoices.service';
import { Contact } from 'app/contact/contact';
import { TableMetadata } from 'app/shared/table/table-metadata';
import { COLUMN_TYPES } from 'app/shared/table/column-types';
import { DateService } from 'app/shared/helpers/date.service';

@Component({
  selector: 'app-contact-invoices',
  templateUrl: './contact-invoices.component.html',
  styleUrls: ['./contact-invoices.component.scss'],
})
export class ContactInvoicesComponent implements OnInit, OnDestroy {
  @Input() public contact: Contact;

  @Input() private triggerReload$: Observable<void>;

  /** Emits the total number of invoices */
  @Output() totalCount = new EventEmitter();

  /** The data that feeds the table */
  public tableData: InvoicesReduced[];
  public tableMetadata: TableMetadata;

  public dateRange: {
    from: string;
    to: string;
  } = {
    from: null,
    to: null,
  };

  public labels: LabelsResponse;

  /** A local copy of the data used for persisting the unmodified data for filtering */
  private pristineData: InvoicesReduced[];

  private loadDataSubject$ = new BehaviorSubject(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private contactInvoicesService: ContactInvoicesService,
    private dateService: DateService,
    private labelService: HyperionLabelsService
  ) {}

  ngOnInit() {
    if (!this.contact || !this.contact.id) {
      return;
    }

    this.labelService
      .getLabels([
        { model: 'Invoice' },
        { model: 'Contact' },
        { model: 'Project' },
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(labels => {
        this.setupTable(labels);
      });

    this.loadDataSubject$
      .pipe(
        switchMap(() =>
          this.contactInvoicesService.getInvoicesPerContact(+this.contact.id)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.pristineData = data.invoices;
        this.tableData = data.invoices;
        this.totalCount.emit(data.invoices.length);
      });

    // Listen to trigger reload
    this.triggerReload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadDataSubject$.next(null);
    });
  }

  private setupTable(labels: LabelsResponse) {
    this.labels = labels;
    this.tableMetadata = {
      columnsMetadata: [
        {
          ...COLUMN_TYPES.ID,
          key: 'fakturaNr',
          label: labels.Invoice.fakturaNr,
        },
        {
          ...COLUMN_TYPES.TEXT,
          key: 'contactOrderBusinessName',
          label: labels.Contact.orderBuisnessName,
        },
        {
          ...COLUMN_TYPES.ID,
          key: 'contactTrueId',
          label: labels.Contact.trueId,
        },
        {
          ...COLUMN_TYPES.DATE,
          key: 'date',
          label: labels.Invoice.fdatum,
        },
        {
          ...COLUMN_TYPES.DATE,
          key: 'dueDate',
          label: labels.Invoice.ffdatum,
        },
        {
          ...COLUMN_TYPES.ID,
          key: 'projectsTrueId',
          label: labels.Invoice.projectId,
        },
        {
          ...COLUMN_TYPES.DECIMAL,
          key: 'total',
          label: labels.Invoice.totalBox,
        },
      ],

      sortBy: {
        attribute: 'fakturaNr',
        ascDesc: -1,
        object: 'contact_invoices_table',
      },

      globalFilterFields: [
        'fakturaNr',
        'contactOrderBusinessName',
        'contactTrueId',
        'projectsTrueId',
        'total',
      ],

      emptyStateSettings: {
        model: 'Invoice',
      },

      showTableTotals: false,

      paginator: {
        rows: 50,
      },
    };
  }

  public filterData(model: 'from' | 'to', date: string) {
    this.dateRange[model] = date;

    this.tableData = this.pristineData.filter(invoice => {
      return this.dateService.isBetween(
        invoice.date,
        this.dateRange.from,
        this.dateRange.to
      );
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
