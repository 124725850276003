<div class="p-grid">
  <div class="p-col-12">
    <h2>Ändra projektparametrar</h2>
    <span>
      Ange värdet för de parametrar som påverkar projektets kostnader, och spara
      det genom att trycka på "Spara". När parametrarna är sparade kommer
      projektets kostnader uppdateras.
    </span>
  </div>
  <div class="p-col-12">
    <label>Kostnad per mil</label>
    <input pInputText type="text" [(ngModel)]="costPerMile" />
  </div>
  <div class="p-col-12">
    <label>Milersättning för anställda</label>
    <input pInputText type="text" [(ngModel)]="mileAllowance" />
  </div>
  <div class="p-col-12">
    <label>Separat fakturerat belopp</label>
    <input pInputText type="text" [(ngModel)]="separatelyInvoiced" />
  </div>
  <div class="p-col-12 p-d-flex p-jc-end">
    <button
      pButton
      class="p-button-primary-hyperion"
      icon="pi pi-save"
      label="Spara"
      (click)="saveParameters()"
      type="button"
    ></button>
  </div>
</div>
