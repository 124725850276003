import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { OfferExternalProjectConnectionService } from 'app/shared/company/derome-integration/offer-external-project-connection.service';
import { ProjectExternalProjectConnectionService } from 'app/shared/company/derome-integration/project-external-project-connection.service';
import { ProjectExternalService } from 'app/shared/company/derome-integration/project-external.service';
import {
  ListFormDropDown2Component,
  SimpleFormDropDown2Component,
  WideFormDropDown2Component,
} from 'app/shared/forms';

import { ExternalProjectWithLabel } from './external-project-with-label.interface';
import { ExternalProject } from '../external-project.interface';
import { ProjectExternalSources } from '../project-external-sources.enum';

@Component({
  selector: 'app-project-external-dropdown',
  templateUrl: './project-external-dropdown.component.html',
  styleUrls: ['./project-external-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectExternalDropdownComponent implements OnInit, OnChanges {
  @Input() projectExternalSource: ProjectExternalSources | string;
  @Input() preSelectedExternalProject?: ExternalProjectWithLabel;
  @Input() projectId?: number = 0;
  @Input() offerId?: number = 0;

  /** If set to true, we do not trigger the API call, and instead just emit the modified value */
  @Input() disableOnSelect?: boolean = false;

  /**
   * matches our reusable form types plus 'list-with-label' that is used in offer
   *
   * TODO: drop the default value when we have fully migrated
   */
  @Input() type?: 'simple' | 'wide' | 'list' | 'list-with-label' | 'default' =
    'default';

  @ViewChild('dropdown') public dropdownElement: Dropdown;
  @ViewChild('appForm2Dropdown')
  public appForm2DropdownElement:
    | WideFormDropDown2Component
    | ListFormDropDown2Component
    | SimpleFormDropDown2Component;

  @Output()
  externalProjectConnectionChanged: EventEmitter<ExternalProject> = new EventEmitter<ExternalProject>();

  public model: any;
  public options: ExternalProjectWithLabel[];
  public placeholder: string;
  public isDisabled = false;
  public tooltipMessage =
    'Knyt till projektprislista för att få dina projektunika priser. Vid uppdatering av projektprislista kommer inköpspris och försäljningspris att uppdateras till ordinarie priser';
  public label = 'Hämta Projektprislista';

  private noExternalProjectOption: ExternalProjectWithLabel = {
    label: 'Inget projekt angett...',
    value: {
      source: 'derome-api',
      name: '',
      id: '',
    },
  };

  public dropdownParams: any;
  public tooltip: any;
  public htmlAttributes: any;

  constructor(
    private offerExternalProjectConnection: OfferExternalProjectConnectionService,
    private projectExternalProjectConnection: ProjectExternalProjectConnectionService,
    private projectExternalService: ProjectExternalService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService
  ) {
    this.options = [this.noExternalProjectOption];

    this.tooltip = {
      msg: this.tooltipMessage,
    };
    this.htmlAttributes = { label: { value: this.label } };
  }

  ngOnInit() {
    this.setPlaceholder();
    this.refreshDropdownParams();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.preSelectedExternalProject &&
      changes.preSelectedExternalProject.currentValue
    ) {
      this.options = [changes.preSelectedExternalProject.currentValue];
      this.setPlaceholder();
      this.refreshDropdownParams();
    }
  }

  private setPlaceholder(): void {
    this.placeholder = this.preSelectedExternalProject
      ? this.preSelectedExternalProject.label
      : 'Inget projekt angett...';
  }

  public onShow(): void {
    const isFirstLoad = this.options.length === 1;

    if (!isFirstLoad) {
      return;
    }

    this.options = [this.options[0], { label: 'Laddar...', value: null }];

    this.refreshDropdownParams();

    this.loadItems().subscribe(externalProjectWithLabels => {
      if (!externalProjectWithLabels.length) {
        this.setDropdownWithNoAvailableProjects();
      } else {
        this.options = [
          this.noExternalProjectOption,
          ...externalProjectWithLabels,
        ];

        this.refreshDropdownParams();
      }
    });
  }

  public loadItems(): Observable<ExternalProjectWithLabel[]> {
    return this.projectExternalService
      .getExternalProjects(this.projectExternalSource)
      .pipe(
        map<ExternalProject[], ExternalProjectWithLabel[]>(
          (projects: ExternalProject[]) => {
            return projects.map<ExternalProjectWithLabel>(
              (project: ExternalProject) => {
                return {
                  label: this.projectExternalService.getLabel(
                    project.id,
                    project.name
                  ),
                  value: { ...project },
                };
              }
            );
          }
        )
      );
  }

  public selectExternalProject($event: any) {
    const selectedOption: ExternalProject = $event.value;

    if (!selectedOption) {
      return;
    }

    if (this.disableOnSelect) {
      this.externalProjectConnectionChanged.emit(selectedOption);
      return;
    }

    let service:
      | OfferExternalProjectConnectionService
      | ProjectExternalProjectConnectionService;
    // TODO merge services.
    let id: number;

    if (this.projectId) {
      service = this.projectExternalProjectConnection;
      id = this.projectId;
    } else if (this.offerId) {
      service = this.offerExternalProjectConnection;
      id = this.offerId;
    } else {
      console.log(
        'Component ProjectExternalDropdownComponent is missing the offer or project id input.'
      );
      return;
    }

    service
      .setExternalProjectId(id, selectedOption)
      .subscribe((returnRequest: any) => {
        if (returnRequest && returnRequest.success) {
          this.externalProjectConnectionChanged.emit(selectedOption);
          const successToast: ToastMessage = {
            severity: ToastMessageSeverityType.INFO,
            summary: 'Projektpriser uppdateras',
          };
          this.messageService.insertData(successToast);
        }
      });
  }

  private setDropdownWithNoAvailableProjects(): void {
    this.options = [];
    this.placeholder = 'Inga tillgängliga projekt';
    this.isDisabled = true;

    this.refreshDropdownParams();

    const dropdownElement =
      this.dropdownElement || this.appForm2DropdownElement;

    dropdownElement.hide(null);
  }

  private refreshDropdownParams() {
    this.dropdownParams = {
      ...this.dropdownParams,
      options: this.options,
      placeholder: this.placeholder,
      disabled: this.isDisabled,
    };
    this.cdr.markForCheck();
  }
}
