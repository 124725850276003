import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';
import { CompanyAllProjectsService } from 'app/shared/company';

@Component({
  selector: 'attendance-report-construction-site-number',
  templateUrl: './construction-site-number.component.html',
})
export class AttendanceReportConstructionSiteNumberComponent
  implements OnInit, OnDestroy
{
  private destroy$: Subject<boolean> = new Subject<boolean>();

  mutationDone = false;
  projectSub: Subscription;
  componentMainForm: FormGroup;
  dataModel = 'project';
  dataModelCapitalized = 'Project';
  projectsDropdown = [{ label: 'Laddar....', value: null }];
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      id: '',
      constructionSiteNumber: '',
    },
  };

  constructor(
    private dialogRef: DynamicDialogRef,
    private formHandler: FormHandlerService,
    public dialogConfig: DynamicDialogConfig,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private allProjectsService: CompanyAllProjectsService
  ) {
    this.dialogConfig.header = 'Lägg till byggarbetsplats-id';
    this.initForm();
  }

  ngOnInit() {
    this.getProjectsDropdown();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['id']['label'] = 'Välj projekt';
        this.componentMainForm['controls']['constructionSiteNumber']['label'] =
          'Byggarbetsplats-id';
      });
  }

  getProjectsDropdown() {
    const projectStatuses = [0, 1];
    const variables = { status: projectStatuses };

    this.apolloQueryService
      .apolloWatchQueryTwo('companyProjects', variables, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.projectSub = data.sub;
        this.projectsDropdown =
          this.allProjectsService.makeLabelsArrayForStatuses(
            data.data,
            projectStatuses
          );
        const nullValue = { label: 'Välj projekt', value: null };
        this.projectsDropdown.splice(0, 1, nullValue);
      });
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation: any = this.componentMainForm.value;
    dataToMutation.id = Number(dataToMutation.id);

    if (this.formHandler.formValid([this.componentMainForm])) {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.mutationDone = true;
              this.closeAction();
            }

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  chooseProject(eventValue) {
    this.componentMainForm.controls.constructionSiteNumber.setValue('');
    if (!eventValue) {
      return;
    }

    const variables = { id: Number(eventValue) };
    this.apolloQueryService
      .apolloQuery('projectConstructionSiteNumber', variables)
      .subscribe(({ data }) => {
        if (data.project) {
          const constructionSiteNumber: string =
            data.project.constructionSiteNumber;
          constructionSiteNumber &&
            this.componentMainForm.controls.constructionSiteNumber.setValue(
              constructionSiteNumber
            );
        }
      });
  }

  closeAction() {
    this.dialogRef.close(this.mutationDone);
  }

  ngOnDestroy() {
    this.projectSub && this.projectSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
