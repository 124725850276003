<div class="p-grid p-nopad p-mt-1">
  <div class="p-col-6">
    <app-contact-form
      [context]="
        typeString === 'leverantör'
          ? contactFormContext.Supplier
          : contactFormContext.Customer
      "
      [contactId]="id"
      showSearch="false"
      (contactUpdatedEvent)="onFormSubmitted($event)"
    >
    </app-contact-form>

    <div class="align-right">
      <button
        pButton
        [disabled]="deleteButtonIsDisabled"
        class="p-button-icon-danger"
        icon="pi pi-trash"
        label="Radera"
        (click)="actionDelete()"
      ></button>
    </div>
  </div>
</div>
