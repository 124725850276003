<div class="contractor-drag-and-drop">
  <div class="hyperion-dnd-container contractor-drop-zone">
    <div *ngFor="let subcontractorData of subcontractorsData" class="">
      <subcontractor-drag
        #subcontractorDrag
        [subcontractorData]="subcontractorData"
        [parentData]="mainCompanyData"
        [projectId]="projectId"
        (subcontractorWithChildren)="childrenChanged($event)"
        (refetchAttendanceReports)="refetchAttendanceReports.emit()"
        (subcontractorDeleted)="subcontractorDeleted.emit($event)"
      >
      </subcontractor-drag>
    </div>
  </div>
</div>
