<div class="p-grid page-container">
  <p-card class="p-col-12">
    <h3>Ladda upp ny bild</h3>
    <p-fileUpload
      name="PictureForm[image]"
      accept="image/*"
      chooseLabel="Ange fil"
      [multiple]="false"
      [url]="urlPrefix + '/company/UploadQualityStamp'"
      uploadLabel="Ladda upp"
      cancelLabel="Avbryt"
      invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
      invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
      (onUpload)="onUpload($event)"
    >
      <ng-template pTemplate="content">
        <app-drag-and-drop-template></app-drag-and-drop-template>
      </ng-template>
    </p-fileUpload>
  </p-card>
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12" *ngIf="loading">
        <spinner></spinner>
      </div>
      <p-table
        class="p-col-12"
        *ngIf="!loading"
        [value]="dataSet"
        [columns]="tableColums"
        [rowTrackBy]="helperService.rowTrackBy"
      >
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th
              *ngFor="let col of columns"
              [pSortableColumn]="col.field"
              [style.width]="col.width"
            >
              {{ col.header }}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-columns="columns">
          <tr>
            <td>
              <img
                style="max-height: 150px; max-width: 150px"
                [src]="rowData['url']"
                alt=""
              />
            </td>
            <td>
              <p-checkbox
                class="table"
                (onChange)="onCheckboxChange(rowData, 'invoice', $event)"
                [(ngModel)]="rowData.invoiceBool"
                binary="tdue"
              ></p-checkbox>
            </td>
            <td>
              <p-checkbox
                class="table"
                (onChange)="onCheckboxChange(rowData, 'offer', $event)"
                [(ngModel)]="rowData.offerBool"
                binary="tdue"
              ></p-checkbox>
            </td>
            <td>
              <button
                pButton
                class="p-button-icon-danger"
                icon="pi pi-trash"
                (click)="confirmActionDelete(rowData.id)"
              ></button>
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage" let-columns>
          <tr class="emptyStateTR">
            <td [attr.colspan]="columns.length">
              <app-emptyState [model]="'CompanyQualitystamp'"></app-emptyState>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
