import { GlobalService } from 'app/shared/global/index';
import { HttpService } from 'app/shared/http';
import { ProductsAutosuggestService } from './products.autosuggest.service';

const productsAutoSuggestServiceFactory = (
  httpService: HttpService,
  globalService: GlobalService
) => {
  return new ProductsAutosuggestService(httpService, globalService);
};

export default {
  provide: ProductsAutosuggestService,
  useFactory: productsAutoSuggestServiceFactory,
  deps: [HttpService, GlobalService],
};
