import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { MeUser, MeUserWithCompany } from './me-user';

export enum UserTypeEnum {
  Admin = '3',
  Foreman = '2',
  Worker = '1',
}

@Injectable()
export class UserLocalStorageService {
  public meSubject = new Subject<MeUser>();

  private LOCAL_STORAGE_PREFIX = 'ME';

  private meUserAttributes = [
    'firstName',
    'lastName',
    'type',
    'email',
    'sessionTime',
    'id',
    'username',
    'includeOverhead',
  ];

  constructor() {}

  public setMEUser(user: MeUser): { success: boolean; msg?: string } {
    if (user) {
      for (const attribute of this.meUserAttributes) {
        if (!(attribute in user)) {
          continue;
        }

        const lsKey = this.LOCAL_STORAGE_PREFIX + attribute;

        localStorage.setItem(lsKey, user[attribute]);
      }

      this.meSubject.next(this.getMEUser());

      return { success: true };
    } else {
      return { success: false, msg: 'Något gick fel' };
    }
  }

  /**
   * Deletes the currently stored user in localstorage
   */
  public deleteMEUser(): void {
    for (const attribute of this.meUserAttributes) {
      const lsKey = this.LOCAL_STORAGE_PREFIX + attribute;

      localStorage.removeItem(lsKey);
    }
  }

  /**
   * Retrieves the current user from localstorage
   */
  public getMEUser(): MeUser {
    const meUser: MeUser = {
      firstName: null,
      lastName: null,
      type: null,
      email: null,
      sessionTime: null,
      id: null,
      username: null,
      includeOverhead: null,
    };

    for (const attribute of this.meUserAttributes) {
      meUser[attribute] = localStorage.getItem(
        this.LOCAL_STORAGE_PREFIX + attribute
      );
    }

    return {
      ...meUser,
      fullName: `${meUser.firstName} ${meUser.lastName}`,
      initials: this.getUserInitials(meUser),
    };
  }

  /**
   * Retrieves the current user and the company data from localstorage
   */
  public getMeUserWithCompany(): MeUserWithCompany {
    return {
      ...this.getMEUser(),

      companyName: localStorage.getItem('MEcompanyName'),
      companyLogo: localStorage.getItem('MEcompanyLogo'),
      companyId: localStorage.getItem('MEcompanyId'),
    };
  }

  private getUserInitials(user: MeUser): string {
    const firstNameInitial = user.firstName ? user.firstName[0] : '';
    const lastNameInitial = user.lastName ? user.lastName[0] : '';
    return (
      firstNameInitial.charAt(0) + lastNameInitial.charAt(0)
    ).toUpperCase();
  }
}
