import { gql } from 'apollo-angular';

import { MutationDetails } from './common';

export const UpdateInvoiceSerie = gql`
  mutation updateInvoiceSerie(
    $updateInvoiceSerie: InvoiceTypeHyperionMutationInput
  ) {
    invoiceTypeHyperionMutation(updateInvoiceSerie: $updateInvoiceSerie) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
    }
  }
  ${MutationDetails.fragments.attributes}
`;
