import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullTextPipe',
})
export class NullTextPipe implements PipeTransform {
  transform(value: string, args: any[] = []): string {
    return value ? value : '';
  }
}
