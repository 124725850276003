<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading" [class.page-container]="!isModal">
  <p-card [class.no-style]="isModal" class="p-mb-4 p-d-block">
    <h3 class="p-mt-0">Inställningar</h3>
    <p-checkbox
      label="Använd internkostnad som standard i projekt"
      [(ngModel)]="useInternalCostAsDefaultProjectCost"
      [binary]="true"
      (onChange)="onChangeUseInternalCostAsDefaultProjectCost($event.checked)"
    ></p-checkbox>
  </p-card>
  <p-card [class.no-style]="isModal">
    <div class="p-grid">
      <div class="p-grid p-col-12 show-on-large-device p-g-nopad">
        <div
          [class.p-col-2]="hasAccountingPlanFunction"
          [class.p-col-4]="!hasAccountingPlanFunction"
        >
          Namn
        </div>
        <div
          [class.p-col-1]="hasAccountingPlanFunction"
          [class.p-col-3]="!hasAccountingPlanFunction"
        >
          Timpris (kr/h)
        </div>
        <div
          [class.p-col-1]="hasAccountingPlanFunction"
          [class.p-col-3]="!hasAccountingPlanFunction"
        >
          Internkostnad (kr/h)
        </div>
        <div *ngIf="hasAccountingPlanFunction" class="p-col-3">Konto</div>
        <div *ngIf="hasAccountingPlanFunction" class="p-col-3">
          Konto omvänd moms
        </div>
      </div>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="
          let userCostType of systemTypes;
          trackBy: helperService.rowTrackBy
        "
      >
        <div>
          <companyUserCostType-edit
            [userCostType]="userCostType"
            [hasAccountingPlanFunction]="hasAccountingPlanFunction"
            [accountingPlanAccounts]="accountingPlanAccounts"
          ></companyUserCostType-edit>
        </div>
      </div>

      <hr class="line-separator" />

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let userCostType of dataSet; trackBy: helperService.rowTrackBy"
      >
        <div>
          <companyUserCostType-edit
            [userCostType]="userCostType"
            [hasAccountingPlanFunction]="hasAccountingPlanFunction"
            [accountingPlanAccounts]="accountingPlanAccounts"
          ></companyUserCostType-edit>
        </div>
      </div>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let createComponent of createDataObjectArray"
      >
        <companyUserCostType-create
          (dataObjectDeleted)="spliceDeletedDataObject(createComponent)"
          (createSibling)="addCreateComponent(createComponent)"
          [hasAccountingPlanFunction]="hasAccountingPlanFunction"
          [accountingPlanAccounts]="accountingPlanAccounts"
        ></companyUserCostType-create>
      </div>
      <div *ngIf="dataSetInactive.length" class="p-col-12 p-g-nopad">
        <hr class="line-separator" />
        <h2>Inaktiverade yrkestyper</h2>

        <div
          *ngFor="
            let userCostType of dataSetInactive;
            trackBy: helperService.rowTrackBy
          "
        >
          <companyUserCostType-edit
            [userCostType]="userCostType"
            [hasAccountingPlanFunction]="hasAccountingPlanFunction"
            [accountingPlanAccounts]="accountingPlanAccounts"
          ></companyUserCostType-edit>
        </div>
      </div>
    </div>
  </p-card>
</div>
