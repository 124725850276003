// TODO: re-enable max-classes-per-file rule and refactor, disabling due to time constraints
/* eslint-disable max-classes-per-file */

import { DocumentAndMetadata, DocumentMetadata } from './document-metadata';
import {
  ViewId,
  ServerId,
  Document,
  DocumentRow,
  DocumentColumn,
  DocumentRowInfo,
  generateViewId,
  collectIdsFromRow,
  DocumentColumnInfo,
  QualifiedServerId,
} from './document';
import { List, Record, Map, Set, Seq } from 'immutable';

export enum DocumentChangeType {
  CellEdit = 'CellEdit',
  RowEditHeader = 'RowEditHeader',
  RowSetUsed = 'RowSetUsed',
  RowDelete = 'RowDelete',
  RowCopy = 'RowCopy',
  RowReorder = 'RowReorder',
}

function cloneDocumentChange(documentChange: DocumentChange) {
  switch (documentChange.type) {
    case DocumentChangeType.CellEdit:
      return new DocumentChangeCellEdit(documentChange);
    case DocumentChangeType.RowEditHeader:
      return new DocumentChangeRowEditHeader(documentChange);
    case DocumentChangeType.RowSetUsed:
      return new DocumentChangeRowSetUsed(documentChange);
    case DocumentChangeType.RowDelete:
      return new DocumentChangeRowDelete(documentChange);
    case DocumentChangeType.RowCopy:
      return new DocumentChangeRowCopy(documentChange).update('idMap', idMap =>
        Map(idMap)
      );
    case DocumentChangeType.RowReorder:
      return new DocumentChangeRowReorder(documentChange);
  }
}

interface DocumentChangeBase {
  type: DocumentChangeType;
  completed: boolean;
  errorMsg: string;

  id: ViewId;
  readonly affectedItems: Set<ViewId>;

  applyToDocument(document: Document): Document;
}

export class DocumentChangeCellEdit
  extends Record({
    type: DocumentChangeType.CellEdit,
    completed: false,
    errorMsg: null,
    id: null,
    value: null,
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.CellEdit;

  value: string;

  get affectedItems(): Set<ViewId> {
    if (this.id) {
      return Set.of(this.id);
    }
  }

  applyToDocument(document: Document): Document {
    return document.setIn(
      document.getItemPath(this.id).unshift('rows').push('col', 'value'),
      this.value
    );
  }
}

export class DocumentChangeRowEditHeader
  extends Record({
    type: DocumentChangeType.RowEditHeader,
    completed: false,
    errorMsg: null,
    id: null,
    value: null,
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.RowEditHeader;

  value: string;

  get affectedItems(): Set<ViewId> {
    if (this.id) {
      return Set.of(this.id);
    }
  }

  applyToDocument(document: Document): Document {
    return document.setIn(
      document.getItemPath(this.id).unshift('rows').push('row', 'header'),
      this.value
    );
  }
}

export class DocumentChangeRowSetUsed
  extends Record({
    type: DocumentChangeType.RowSetUsed,
    completed: false,
    errorMsg: null,
    id: null,
    enable: true,
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.RowSetUsed;

  enable: boolean;

  get affectedItems(): Set<ViewId> {
    if (this.id) {
      return Set.of(this.id);
    }
  }

  applyToDocument(document: Document): Document {
    return document.setIn(
      document.getItemPath(this.id).unshift('rows').push('row', 'used'),
      this.enable
    );
  }
}

export class DocumentChangeRowDelete
  extends Record({
    type: DocumentChangeType.RowDelete,
    completed: false,
    errorMsg: null,
    id: null,
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.RowDelete;

  get affectedItems(): Set<ViewId> {
    if (this.id) {
      return Set.of(this.id);
    }
  }

  applyToDocument(document: Document): Document {
    return document.deleteRow(this.id);
  }
}

export class DocumentChangeRowCopy
  extends Record({
    type: DocumentChangeType.RowCopy,
    completed: false,
    errorMsg: null,
    id: null,
    idMap: Map(),
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.RowCopy;

  idMap: Map<ViewId, ViewId>;

  get affectedItems(): Set<ViewId> {
    if (this.id && this.idMap) {
      return Set.of(this.id).union(
        Map(this.idMap).keys(),
        Map(this.idMap).values()
      );
    }
  }

  applyToDocument(document: Document): Document {
    return document.copyRow(this.id, this.idMap);
  }
}

export function mkRowCopy(row: DocumentRow): DocumentChangeRowCopy {
  const viewIdMap = collectIdsFromRow(row).toMap().map(generateViewId);
  return new DocumentChangeRowCopy({
    id: row.row.viewId,
    idMap: viewIdMap,
  });
}

export class DocumentChangeRowReorder
  extends Record({
    type: DocumentChangeType.RowReorder,
    completed: false,
    errorMsg: null,
    id: null,
    belowId: null,
  })
  implements DocumentChangeBase
{
  type: DocumentChangeType.RowReorder;

  belowId: ViewId;

  get affectedItems(): Set<ViewId> {
    if (this.id && this.belowId) {
      return Set.of(this.id, this.belowId).filter(id => id !== null);
    }
  }

  applyToDocument(document: Document): Document {
    return document.reorderRow(this.id, this.belowId);
  }
}

export type DocumentChange =
  | DocumentChangeCellEdit
  | DocumentChangeRowEditHeader
  | DocumentChangeRowSetUsed
  | DocumentChangeRowDelete
  | DocumentChangeRowCopy
  | DocumentChangeRowReorder;

export class DocumentChanges extends Record({
  id: null,
  projectId: null,
  templateName: null,
  title: null,

  copy: false,

  changes: List(),
  serverIdMap: Map(),
}) {
  id: ServerId;
  projectId: number;
  templateName: string;
  title: string;

  copy: boolean;

  changes: List<DocumentChange>;
  serverIdMap: Map<ViewId, QualifiedServerId>;
}

function mergeChanges(changes: List<DocumentChange>): List<DocumentChange> {
  const mergeable: Set<DocumentChangeType> = Set.of(
    DocumentChangeType.CellEdit,
    DocumentChangeType.RowSetUsed,
    DocumentChangeType.RowEditHeader
  );
  const boundaries: Set<DocumentChangeType> = Set.of(
    /**
     * RowCopy needs to be a merge boundary.
     * If the user does an Edit -> Copy -> Edit,
     * the copy should still have the value from the first edit
     */
    DocumentChangeType.RowCopy
  );

  const lastChangesById: {
    [changeType: string]: { [id: string]: number[] };
  } = {};
  mergeable.forEach(changeType => {
    lastChangesById[changeType] = {};
  });

  changes.forEach((change, index) => {
    if (mergeable.contains(change.type)) {
      if (lastChangesById[change.type][change.id] === undefined) {
        lastChangesById[change.type][change.id] = [index];
      } else {
        const lastChanges = lastChangesById[change.type][change.id];
        lastChanges[lastChanges.length - 1] = index;
      }
    }

    if (boundaries.contains(change.type)) {
      mergeable.forEach(changeType => {
        change.affectedItems.forEach(item => {
          const lastChanges = lastChangesById[changeType][item];
          if (lastChanges !== undefined) {
            lastChanges.push(-1);
          }
        });
      });
    }
  });
  return changes.filter((change, index) => {
    if (mergeable.contains(change.type)) {
      const lastEdit = lastChangesById[change.type][change.id];
      return lastEdit.indexOf(index) !== -1;
    } else {
      return true;
    }
  });
}

function dedupeChangeList(changes: List<DocumentChange>): List<DocumentChange> {
  changes = mergeChanges(changes);
  // TODO: Drop changes that only touch deleted rows

  return changes;
}

export function mkDocumentChanges(
  documentMetadata: DocumentMetadata,
  document: Document,
  changes: List<DocumentChange>
): DocumentChanges {
  changes = dedupeChangeList(changes).filterNot(change => change.completed);
  const allAffectedViewIds = changes
    .toSet()
    .flatMap(change => change.affectedItems);
  const serverIdMap = document.serverIdMap.filter((serverId, clientId) =>
    allAffectedViewIds.contains(clientId)
  );
  return new DocumentChanges({
    id: documentMetadata.id,
    projectId: documentMetadata.project && documentMetadata.project.id,
    templateName: documentMetadata.name,
    title: documentMetadata.title,
    changes,
    serverIdMap,
  });
}

// The DocumentChanges imported from the server won't be a "true" DocumentChanges (it will have
// the same structure, but none of the custom or Immutable-provided methods)
export function cloneDocumentChanges(
  changes: DocumentChanges
): DocumentChanges {
  return new DocumentChanges(changes).merge({
    changes: List(changes.changes).map(cloneDocumentChange),
    serverIdMap: Map(changes.serverIdMap),
  });
}
