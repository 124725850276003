import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import {
  CompanyCostTypeService,
  CompanyProjectsService,
  CompanyProjectsForStatusZeroService,
  CompanyFunctionsService,
  CompanyUserCostTypeService,
} from 'app/shared/company/index';
import { HyperionLabelsService } from 'app/shared/labels/index';
import { GlobalService } from 'app/shared/global/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';

@Component({
  selector: 'project-content-dialog',
  templateUrl: 'project-content-dialog.component.html',
})
export class ProjectContentDialogComponent implements OnDestroy, OnInit {
  dataModel = 'companyProjects';
  labelModels = [
    { model: 'Project' },
    { model: 'Contact' },
    { model: 'User' },
    { model: 'Day' },
    {
      model: 'Projectproduct',
      labels: ['avtalspris', 'antal', 'benamning'],
    },
  ];

  projectLabels = {};
  projectParams;
  dataSet = [];
  statusText;
  loading = new BehaviorSubject(true);
  pagReset = false;
  statusModel = [];
  userCostTypesRaw;
  functionsSub: Subscription;
  checkForCompanyFunctions = [
    'usePickUserCostTypeOnTimereport',
    'setMile',
    'setPrivMile',
    'useNotarized',
    'advancedUserCanNotarizeTimereports',
  ];
  functionsThisModel = {};
  functionsData = {};
  meUser: MeUserWithCompany;
  dataSetAsync: BehaviorSubject<any> = new BehaviorSubject([]);
  activeSub: number;
  public projectId: number;
  public statusParam = 1;
  selectedProject = {};
  userCostTypesSub: Subscription;
  companyCostTypesSub: Subscription;
  madeCrudOperations = false;
  projectSub: Subscription;

  constructor(
    private globalService: GlobalService,
    private labelsService: HyperionLabelsService,
    private projectService: CompanyProjectsService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyUserCostTypeService: CompanyUserCostTypeService,
    private companyCostTypeService: CompanyCostTypeService,
    private companyProjectsForStatusZeroService: CompanyProjectsForStatusZeroService,
    private apolloQueryService: ApolloQueryService,
    public dialogRef: DynamicDialogRef,
    private userLocalStorageService: UserLocalStorageService,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }

    this.projectParams = this.globalService.getModelParams('project');

    this.getLabels();

    this.getCompanyFunctionsThisModel();

    this.getFunctionsData();

    this.getCostTypes();

    this.getProjects(this.statusParam);

    this.writeStatusText(this.statusParam);
  }

  private initComponentWithDialogData() {
    this.projectId = this.dialogConfig['data']['projectId'];
    this.statusParam = this.dialogConfig['data']['statusParam'] || 1;
    setTimeout(() => {
      this.dialogConfig.header = 'Projekt ...';
    }, 1);
  }

  ngOnDestroy() {
    this.projectSub && this.projectSub.unsubscribe();
    this.userCostTypesSub && this.userCostTypesSub.unsubscribe();
    this.companyCostTypesSub && this.companyCostTypesSub.unsubscribe();
  }

  selectProject() {
    const dataSet = this.dataSetAsync.value;
    const projectId = this.projectId;

    for (const index in dataSet) {
      const project = dataSet[index];
      if (Number(project['id']) === Number(projectId)) {
        this.selectedProject = project;
        if (this.dialogConfig['data']['projectId']) {
          this.dialogConfig.header =
            'Projekt ' +
            this.selectedProject['trueId'] +
            ' ' +
            (this.selectedProject['mark']
              ? ', ' + this.selectedProject['mark']
              : '' + this.selectedProject['mark']);
        }
        break;
      }
    }
  }

  writeStatusText(statusParam) {
    const statusStrings = this.projectParams.status.strings;
    const status = [
      statusStrings[0][1][1],
      statusStrings[1][1][1],
      statusStrings[2][1][1],
      statusStrings[3][1][1],
      statusStrings[4][1][1],
    ];
    this.statusText = status[statusParam];
  }

  getProjects(statusParam) {
    this.dataSet = [];
    this.dataSetAsync.next([]);

    this.statusModel = [statusParam];

    this.projectSub && this.projectSub.unsubscribe();

    this.subscribeToProjectData(statusParam);
  }

  subscribeToProjectData(statusParam) {
    const variables = { status: this.statusModel };

    this.apolloQueryService
      .apolloWatchQueryTwo('companyProjects', variables, 'cache-and-network')
      .subscribe(data => {
        this.projectSub = data.sub;

        this.loading.next(false);
        const newDataSet =
          this.companyProjectsForStatusZeroService.dataFormater(
            data.data,
            'list'
          );
        this.dataSet = newDataSet;
        this.dataSetAsync.next(newDataSet);
        this.selectProject();
      });
    this.activeSub = statusParam;
  }

  closeAction() {
    this.projectSub && this.projectSub.unsubscribe();
    this.dialogRef.close(this.madeCrudOperations);
  }

  projectDeleted() {
    this.madeCrudOperations = true;
    this.closeAction();
  }

  getLabels() {
    this.labelsService.getLabels(this.labelModels).subscribe(data => {
      this.projectLabels = data;
    });
  }

  getCompanyFunctionsThisModel() {
    for (const companyFunction in this.checkForCompanyFunctions) {
      const functionName = this.checkForCompanyFunctions[companyFunction];
      if (this.companyFunctionsService.companyFunctionIsSet(functionName)) {
        this.functionsThisModel[functionName] = true;
      } else {
        this.functionsThisModel[functionName] = false;
      }
    }

    if (this.companyFunctionsService.companyFunctionIsSet('useNotarized')) {
      this.functionsThisModel['useNotarized'] =
        +this.meUser['type'] === 3 ||
        (+this.meUser['type'] === 2 &&
          this.companyFunctionsService.companyFunctionIsSet(
            'advancedUserCanNotarizeTimereports'
          ));
    }
  }

  getFunctionsData() {
    // Yrkestyper
    if (this.functionsThisModel['usePickUserCostTypeOnTimereport']) {
      this.getUserCostTypes();
    }
  }

  removeProjectWithUpdatedStatusFromDataset(idParam) {
    this.madeCrudOperations = true;
    this.closeAction();
  }

  updateMessage(dataParam) {
    const id = dataParam['id'];
    const message = dataParam['message'];

    const projects = this.dataSetAsync.value;

    for (const index in projects) {
      const project = projects[index];

      if (Number(project['id']) === Number(id)) {
        project['shortMessage'] = message;
        break;
      }
    }

    const newData = [...projects];
    this.dataSetAsync.next(newData);
  }

  getUserCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUserCostType', null, 'cache-and-network')
      .subscribe(data => {
        this.userCostTypesSub = data.sub;
        this.userCostTypesRaw = data.data;
        this.functionsData['userCostTypes'] =
          this.companyUserCostTypeService.dataFormater(data.data, 'labels');
      });
  }

  getCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyCostType', null, 'cache-and-network')
      .subscribe(data => {
        this.companyCostTypesSub = data.sub;
        this.functionsData['companyCostTypes'] =
          this.companyCostTypeService.dataFormater(data.data, 'labels');
      });
  }

  updateProjectInDataSet(updatedObject) {
    const model = updatedObject['formname'];
    let appendOnModel = 'project';

    if (model !== 'information') {
      appendOnModel = model + 'Contact';
    }

    let newUpdatedObject = {};

    newUpdatedObject = this.projectService.formatExecutedData(updatedObject);

    let newDataSet = this.dataSetAsync.value;

    for (const index in newDataSet) {
      const project = newDataSet[index];

      if (Number(project['id']) === Number(newUpdatedObject['projectId'])) {
        newDataSet = this.updateSingleProject(
          project,
          model,
          appendOnModel,
          newUpdatedObject,
          newDataSet,
          index
        );

        break;
      }
    }

    const newArr = [...newDataSet];

    this.dataSet = newArr;
    this.dataSetAsync.next(newDataSet);
  }

  updateSingleProject(
    project,
    model,
    appendOnModel,
    newUpdatedObject,
    newDataSet,
    index
  ) {
    let updateOnModel = project;
    if (model !== 'information') {
      updateOnModel = { ...project[appendOnModel] };
    }

    for (const keyA in newUpdatedObject['executedData']) {
      if (updateOnModel.hasOwnProperty(keyA) && keyA !== 'id') {
        updateOnModel[keyA] = newUpdatedObject['executedData'][keyA];
      }
    }

    let newProject = updateOnModel;

    if (model !== 'information') {
      newProject = project;
      newProject[appendOnModel] = updateOnModel;
    }

    const dataSet = [...newDataSet];
    const spliceIndex = Number(index);

    const dataSetWithUpdatedProject = dataSet.splice(
      spliceIndex,
      1,
      newProject
    );

    return newDataSet;
  }
}
