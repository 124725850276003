import { HostListener, Directive } from '@angular/core';
import { Observable } from 'rxjs';

import { SaveAction } from './document.service';

@Directive()
export abstract class CanBeDirty {
  abstract dirty: boolean;

  abstract save(action: SaveAction, unloading: boolean): Observable<boolean>;

  @HostListener('window:beforeunload', ['$event'])
  unloadCheckDirty(ev: BeforeUnloadEvent): string {
    if (this.dirty) {
      ev.returnValue =
        'Är du säker på att du vill slänga dina osparade ändringar?';
      return ev.returnValue;
    }
  }
}
