import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'app/shared/auth/auth.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-guest-title',
  templateUrl: './guest-title.component.html',
})
export class GuestTitleComponent implements OnInit {
  guest: Observable<boolean>;
  @Input() title: string;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.guest = this.authService.isLoggedInObs.pipe(
      map(loggedIn => !loggedIn)
    );
  }
}
