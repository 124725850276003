import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable } from 'rxjs';

@Injectable()
export class CompanyDivisionService extends CompanySuperService {
  dataModel = 'division';
  showAllText: boolean;

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode, showAllText?) {
    // mode == labels || list

    if (showAllText) {
      this.showAllText = true;
    }

    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    dataObjectsWithLabels.push({ label: 'Ingen vald...', value: null });

    for (const i in dataArray) {
      const dataObject = {
        // Create object to push
        label: dataArray[i].node.name,
        value: +dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }

    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      dataObjectsForForms.push({ ...dataArray[i].node });
    }
    return dataObjectsForForms;
  }
}
