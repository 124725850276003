import {
  Component,
  ViewEncapsulation,
  OnInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import '@struqtur/dhtmlx-gantt';
import { Gantt, GanttStatic } from '@struqtur/dhtmlx-gantt';
import {
  EventColorsEnum,
  EventTextColorEnum,
  EventTypeEnum,
  GanttSizes,
  MapTypeToDropdown,
  PlannerViewContext,
} from 'app/planner/planner-module-enums';
import {
  LightboxDropdown,
  SchedulerEvent,
  SchedulerEventPlannedTime,
} from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import {
  PlannerQueryVars,
  Project,
  ProjectTodo,
  User,
  UserPlannedWork,
} from 'app/planner/services/planner-query-types';
import { MessageService } from 'app/shared/message';
import { PlannerReadService } from 'app/planner/services/planner-read.service';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import {
  calculateGanttColumnWidth,
  getTemplateClassByDate,
} from 'app/planner/utils/gantt';
import { GlobalService } from 'app/shared/global';
import { DateService } from 'app/shared/helpers/date.service';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { Observable, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import '../../dhtmlx/export-api.js';
import '../../dhtmlx/dhtmlxgantt_fullscreen.js';

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-resource-view',
  templateUrl: './resource-view.component.html',
  styleUrls: ['./resource-view.component.scss'],
})
export class ResourceViewComponent implements OnInit {
  @ViewChild('resource', { static: true }) private resourceRef: ElementRef;
  @ViewChild('customLightBox', { static: true })
  private customLightBox: ElementRef;
  @ViewChild('filterCoworkers', { static: true })
  private filterCoworkersDropdown: Dropdown;
  @ViewChild('filterProjects', { static: true })
  private filterProjectsDropdown: Dropdown;

  public datePickerLocale: string;
  private gantt: GanttStatic;
  private ganttData = {
    users: [],
    projects: [],
    plannedWork: [],
    todos: [],
    data: [] as SchedulerEventPlannedTime[],
  };
  private coworkers: User[];
  private currentState: any;
  private readonly ResourceLocalStorageStateKey: string =
    'plannerResourceState';

  public filterForm: FormGroup;
  public zoomLevelDropdown: SelectItem[] = [];
  public resourceEventPlannedTime: SchedulerEvent;
  public EventTypeEnum = EventTypeEnum;
  public isNewEvent: boolean;
  public projectsData: Project[] = [];
  public allTodos: {
    projectId: number;
    todos: ProjectTodo[];
  }[] = [];
  public coworkersDropdown: LightboxDropdown[] = [];
  public isLoading: boolean;
  public PlannerViewContext = PlannerViewContext;

  constructor(
    private globalService: GlobalService,
    private plannerReadService: PlannerReadService,
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.datePickerLocale = this.globalService.getLocale('sv');

    this.initGantt();
  }

  private initGantt(): void {
    this.gantt = Gantt.getGanttInstance();
    this.initGanttConfig();
    this.initGanttZoom();
    this.initGanttTemplates();
    this.initGanttEvents();
    this.initFilters();
    this.initGanttResources();
    this.gantt.init(this.resourceRef.nativeElement);
  }

  private initGanttConfig(): void {
    this.gantt.config = {
      ...this.gantt.config,
      columns: [
        {
          name: 'user',
          width: GanttSizes.Sidebar,
          label: 'Medarbetare',
          tree: true,
          template: (task: any) => {
            const { parent, projectId, text, todoId } = task;

            const project: Project = this.ganttData.projects.find(
              dataProject => +dataProject.id === +projectId
            );

            const todoName = this.ganttData.todos.find(
              todo => +todo.id === todoId
            )?.description;

            let description = project.trueId;

            if (project.mark.length) {
              description += ` - ${project.mark}`;
            }

            if (todoName) {
              description += ` [${todoName}]`;
            }

            return parent ? description : text;
          },
        },
      ],
      date_format: '%Y-%m-%d %H:%i',
      xml_date: '%Y-%m-%d %H:%i',
      open_split_tasks: true,
    };

    this.gantt.plugins({
      tooltip: true,
      marker: true,
      export_api: true,
    });
  }

  private loadSavedState = (): void => {
    this.currentState = this.getCurrentState();

    if (this.currentState) {
      const start = this.dateService.newDateString(this.currentState.min_date);
      const end = this.dateService.newDateString(this.currentState.max_date);

      this.filterForm.setValue({
        startDate: start,
        endDate: end,
        projects: null,
        coworkers: null,
        selectedZoomLevel: 'week',
      });
    }
  };

  private getCurrentState = (): any => {
    return JSON.parse(localStorage.getItem(this.ResourceLocalStorageStateKey));
  };

  private storeCurrentState = (): void => {
    this.currentState = this.gantt.getState();
    this.currentState = {
      ...this.currentState,
      min_date: this.dateService.newDateString(
        this.filterForm.get('startDate').value
      ),
      max_date: this.dateService.newDateString(
        this.filterForm.get('endDate').value
      ),
    };

    localStorage.setItem(
      this.ResourceLocalStorageStateKey,
      JSON.stringify(this.currentState)
    );
  };

  private initFilters(): void {
    this.filterForm = this.formBuilder.group({
      startDate: moment().subtract(1, 'month').toDate(),
      endDate: moment().add(1, 'month').toDate(),
      selectedZoomLevel: 'week',
      coworkers: null,
      projects: null,
    });

    this.loadSavedState();

    this.filterForm
      .get('startDate')
      .valueChanges.subscribe({ next: this.handleDateChange });
    this.filterForm
      .get('endDate')
      .valueChanges.subscribe({ next: this.handleDateChange });
    this.filterForm
      .get('selectedZoomLevel')
      .valueChanges.subscribe({ next: this.handleChangeZoomLevel });
    this.filterForm
      .get('projects')
      .valueChanges.subscribe({ next: this.loadFilteredData });
    this.filterForm
      .get('coworkers')
      .valueChanges.subscribe({ next: this.loadFilteredData });
  }

  private calculateDataQueryDateSpan = (): PlannerQueryVars => {
    const start = this.dateService.newDateString(
      this.filterForm.get('startDate').value
    );
    const end = this.dateService.newDateString(
      this.filterForm.get('endDate').value
    );

    return {
      ...PlannerReadService.DEFAULT_PLANNER_QUERY_VARS,
      fromDate: start,
      toDate: end,
    } as PlannerQueryVars;
  };

  private loadFilteredData = (): void => {
    this.gantt.clearAll();

    const filteredProjects = this.filterForm.get('projects').value;
    const filteredCoworkers = this.filterForm.get('coworkers').value;

    const filteredProjectsIds = filteredProjects?.map(
      (project: any) => +project.value
    );
    const filteredCoworkersIds = filteredCoworkers?.map(
      (coworker: any) => +coworker.value
    );

    if (filteredProjects?.length || filteredCoworkers?.length) {
      const filteredData = this.ganttData.data.filter(
        (event: SchedulerEventPlannedTime) => {
          return (
            filteredProjectsIds?.includes(+event.projectId) ||
            filteredCoworkersIds?.includes(+event.userId)
          );
        }
      );

      const filteredDataPlaceholders = this.ganttData.data.filter(
        (event: SchedulerEventPlannedTime) => {
          return filteredData.find(fevent => fevent.parent === event.id);
        }
      );

      this.gantt.parse({
        data: [...filteredData, ...filteredDataPlaceholders],
        links: [],
      });
    } else {
      this.gantt.parse({ data: this.ganttData.data, links: [] });
    }
  };

  private initGanttZoom(): void {
    const zoomConfig = {
      levels: [
        {
          name: 'day',
          label: 'Dag',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(1.5),
          scales: [
            { unit: 'day', step: 1, format: '%Y - v%W' },
            {
              unit: 'day',
              step: 1,
              format: '%d %M',
              css: date => getTemplateClassByDate(date, 'day'),
            },
          ],
        },
        {
          name: 'week',
          label: 'Vecka',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(7.5),
          scales: [
            { unit: 'week', step: 1, format: '%Y - v%W' },
            {
              unit: 'day',
              step: 1,
              format: '%d %M',
              css: date => getTemplateClassByDate(date, 'day'),
            },
          ],
        },
        {
          name: 'month',
          label: 'Månad',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(4 * 1),
          scales: [
            { unit: 'quarter', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%F',
              css: date => getTemplateClassByDate(date, 'month'),
            },
            {
              unit: 'week',
              step: 1,
              format: 'v%W',
            },
          ],
        },
        {
          name: 'quarter',
          label: 'Kvartal',
          height: 70,
          min_column_width: calculateGanttColumnWidth(4 * 3),
          scales: [
            { unit: 'quarter', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%F',
              css: date => getTemplateClassByDate(date, 'month'),
            },
            {
              unit: 'week',
              step: 1,
              format: 'v%W',
            },
          ],
        },
        {
          name: 'year',
          label: 'År',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(12.5),
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%F',
              css: date => getTemplateClassByDate(date, 'month'),
            },
          ],
        },
      ],
    };

    this.zoomLevelDropdown = zoomConfig.levels.map(level => ({
      label: level.label,
      value: level.name,
    }));

    this.gantt.ext.zoom.init(zoomConfig);
    this.gantt.ext.zoom.setLevel('week');
  }

  private handleChangeZoomLevel = (zoomLevel: string): void => {
    this.gantt.ext.zoom.setLevel(zoomLevel);
  };

  private initGanttResources(): void {
    this.loadDataFromDateSpan();
    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
    this.getCompanyWorkers();
  }

  private loadDataFromDateSpan(): void {
    this.isLoading = true;

    this.getScheduledProjects(this.calculateDataQueryDateSpan()).subscribe(
      ([projects, projectsInternal]) => {
        this.ganttData =
          this.plannerDataAdapterService.mapProjectsDataToGanttResources([
            ...projects,
            ...projectsInternal,
          ]);

        this.ganttData =
          this.plannerDataAdapterService.mapUserPlannedWorkToResourcesData(
            this.ganttData,
            this.gantt.uid
          );

        const projectsWithPlannedWork = this.ganttData.projects.filter(
          project => project.plannedWork.length
        );

        this.filterProjectsDropdown.options =
          this.plannerDataAdapterService.mapTypeToDropdownList(
            projectsWithPlannedWork,
            MapTypeToDropdown.Projects
          );

        this.filterCoworkersDropdown.options = this.coworkersDropdown.filter(
          coworker =>
            this.ganttData.data.find(event => event.userId === +coworker.value)
        );

        this.gantt.parse({ data: this.ganttData.data, links: [] });
        this.gantt.sort((a, b) => a.text?.localeCompare(b.text));

        this.isLoading = false;

        this.gantt.addMarker({
          start_date: new Date(),
        });
      }
    );
  }

  private getScheduledProjects(
    queryVars: PlannerQueryVars
  ): Observable<[Project[], Project[]]> {
    return forkJoin([
      this.plannerReadService.getProjects(queryVars).pipe(take(1)),
      this.plannerReadService
        .getProjectsInternalWithPlannedTime(queryVars)
        .pipe(take(1)),
    ]);
  }

  private initGanttTemplates(): void {
    this.gantt.templates.task_class = (
      start,
      end,
      task: SchedulerEventPlannedTime
    ) => {
      const children = this.gantt.getChildren(task.id);
      if (task.eventType === EventTypeEnum.Placeholder && children.length) {
        return 'hidden-task';
      }
      return '';
    };

    this.gantt.templates.task_text = (
      start,
      end,
      task: SchedulerEventPlannedTime
    ) => {
      return (
        this.plannerDataAdapterService.getDuration(
          task.realStartDate,
          task.realEndDate
        ) + 'h'
      );
    };

    this.gantt.templates.scale_cell_class = date =>
      getTemplateClassByDate(date, this.getCurrentZoomLevelName());
    this.gantt.templates.timeline_cell_class = (item, date) =>
      getTemplateClassByDate(date, this.getCurrentZoomLevelName());

    this.gantt.templates.format_date = (date: Date) =>
      this.gantt.date.date_to_str('%Y-%m-%d %H:%i')(date);
    this.gantt.templates.xml_format = (date: Date) =>
      this.gantt.date.date_to_str(this.gantt.config.xml_date)(date);

    this.gantt.templates.tooltip_text = this.setTooltipTemplate;

    this.initLightBoxTemplate();
  }

  private initLightBoxTemplate(): void {
    this.gantt.showLightbox = id => {
      this.resourceEventPlannedTime = this.gantt.getTask(id);
      this.customLightBox.nativeElement.style.display = 'block';
    };
  }

  private setTooltipTemplate = (start: Date, end: Date, task: any): string => {
    if (task.eventType === EventTypeEnum.PlannedTime) {
      return this.plannedTimeEventTooltipTemplate(start, end, task);
    }

    return '';
  };

  private plannedTimeEventTooltipTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime
  ): string => {
    const project = this.ganttData.projects?.find(
      p => +p.id === +event.projectId
    );
    const coworker = this.coworkersDropdown?.find(
      c => +c.value === +event.userId
    );
    const projectTodos = this.allTodos?.find(
      at => +at.projectId === +event.projectId
    );
    let todo: ProjectTodo;
    if (projectTodos) {
      todo = projectTodos.todos.find(t => +t.id === +event.todoId);
    }

    return (
      '<div style="font-size: 1.1em;"><strong>Planerad tid</strong></div>' +
      (project
        ? `<div class="event-text-template-overflow"><strong>Projekt:</strong><br />
          ${project.trueId}, ${project.mark}</div>`
        : '') +
      (todo
        ? `<div class="event-text-template-overflow"><strong>Arbetsmoment:</strong><br />
          ${todo.topic.Name}, ${todo.description}</div>`
        : '') +
      `<div class="event-text-template-overflow"><strong>Starttid:</strong><br />
        ${moment(event.realStartDate).format('YYYY-MM-DD HH:mm')}
      </div>` +
      `<div class="event-text-template-overflow"><strong>Sluttid:</strong><br />
        ${moment(event.realEndDate).format('YYYY-MM-DD HH:mm')}
      </div>` +
      (coworker
        ? `<div class="event-text-template-overflow"><strong>Medarbetare:</strong><br />
            ${coworker.label}
          </div>`
        : '') +
      (event.text
        ? `<div class="event-text-template-overflow"><strong>Meddelande:</strong><br />
            ${event.text}
          </div>`
        : '')
    );
  };

  /**
   * This populates the dialog edit-planned-time-view with the data
   */
  public initNewPlannedTime(newTask?: any): void {
    const { startHour, startMinutes, endHour, endMinutes } =
      this.dateService.getStandardWorkHours();

    const task: SchedulerEventPlannedTime = {
      id: this.gantt.uid(),
      duration: 24,
      color: EventColorsEnum.Blue,
      end_date: new Date().setHours(endHour, endMinutes),
      realEndDate: new Date().setHours(endHour, endMinutes),
      parent: undefined,
      projectId: undefined,
      progress: 0,
      start_date: new Date().setHours(startHour, startMinutes),
      realStartDate: new Date().setHours(startHour, startMinutes),
      text: '',
      textColor: EventTextColorEnum.White,
      todoId: undefined,
      type: 'task',
      userId: undefined,
      eventType: EventTypeEnum.PlannedTime,
      ...newTask,
    };
    this.resourceEventPlannedTime = task;
    this.customLightBox.nativeElement.style.display = 'block';
    this.isNewEvent = true;
  }

  private getAllCompanyProjects(queryVars: PlannerQueryVars): void {
    this.plannerReadService
      .getProjects(queryVars)
      .pipe(take(1))
      .subscribe(projects => {
        projects.map(project => {
          if (!project.todos.length) {
            return;
          }

          const existingTodoIndex = this.allTodos.findIndex(
            existingTodo => +existingTodo.projectId === +project.id
          );

          if (existingTodoIndex >= 0) {
            this.allTodos[existingTodoIndex] = {
              projectId: project.id,
              todos: project.todos,
            };
          } else {
            this.allTodos.push({
              projectId: project.id,
              todos: project.todos,
            });
          }
        });

        this.projectsData = projects;
      });
  }

  private getCompanyWorkers(): void {
    this.plannerReadService
      .getUsers()
      .pipe(take(1))
      .subscribe(coworkers => {
        this.coworkers = coworkers;
        this.coworkersDropdown =
          this.plannerDataAdapterService.mapTypeToDropdownList<User>(
            coworkers,
            MapTypeToDropdown.Coworkers
          );
      });
  }

  private initGanttEvents(): void {
    this.gantt.attachEvent(
      'onTaskOpened',
      id => {
        const task = this.gantt.getTask(id);
        if (this.gantt.isSplitTask(task)) {
          task.render = '';
          this.gantt.render();
        }
      },
      null
    );
    this.gantt.attachEvent(
      'onTaskClosed',
      id => {
        const task = this.gantt.getTask(id);
        if (task.eventType === EventTypeEnum.Placeholder) {
          task.render = 'split';
          this.gantt.render();
        }
      },
      null
    );

    this.gantt.attachEvent('onDataRender', this.storeCurrentState, null);

    this.gantt.attachEvent(
      'onEmptyClick',
      e => {
        if (this.getCurrentZoomLevelName() !== 'day') {
          return;
        }

        const task = this.gantt.getTask(
          +e.target.offsetParent?.dataset?.taskId
        );
        if (task) {
          const { startHour, startMinutes, endHour, endMinutes } =
            this.dateService.getStandardWorkHours();

          const newTask = {
            userId: +task.userId,
            realStartDate: this.getDateFromClick(e).setHours(
              startHour,
              startMinutes
            ),
            realEndDate: this.getDateFromClick(e).setHours(endHour, endMinutes),
          };

          this.initNewPlannedTime(newTask);
        }
      },
      null
    );

    this.gantt.attachEvent('onAfterTaskDrag', this.handleAfterDragEvent, null);
  }

  private handleDateChange = (): void => {
    this.gantt.clearAll();
    this.loadDataFromDateSpan();
  };

  private getCurrentZoomLevelName(): string {
    return this.gantt.ext.zoom.getLevels()[
      this.gantt.ext.zoom.getCurrentLevel()
    ].name;
  }

  private getDateFromClick(e: PointerEvent): Date {
    const posX = this.gantt.getScrollState().x;
    const position = e.clientX - this.gantt.config.grid_width + posX;
    return this.gantt.dateFromPos(position);
  }

  public handleCloseEvent(): void {
    this.customLightBox.nativeElement.style.display = 'none';
    this.gantt.hideLightbox();
    this.resourceEventPlannedTime = null;
  }

  public handleAfterSaveEvent(taskData: UserPlannedWork): void {
    const userFullName = this.plannerDataAdapterService.getUserFullname(
      this.coworkers.find(cw => +cw.id === +taskData.userId)
    );

    const placeholderId =
      this.getTaskPlaceholderId(taskData) ||
      this.createTaskPlaceholderParent(taskData, userFullName);

    const newTask: SchedulerEventPlannedTime = {
      id: +taskData.id,
      todoId: +taskData.todoId,
      userId: +taskData.userId,
      projectId: +taskData.projectId,
      color: taskData.color,
      textColor: this.plannerDataAdapterService.setTextColor(taskData.color),
      text: taskData.messageToUser,
      end_date: moment(taskData.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .toDate(),
      start_date: moment(taskData.startDate)
        .set('hours', 0)
        .set('minutes', 0)
        .toDate(),
      realStartDate: taskData.startDate,
      realEndDate: taskData.endDate,
      eventType: EventTypeEnum.PlannedTime,
    };

    const addedTask = this.gantt.addTask(newTask, placeholderId);
    this.gantt.sort((a, b) => a.text?.localeCompare(b.text));
    this.gantt.showTask(addedTask);
    this.gantt.open(placeholderId);

    this.isNewEvent = false;
  }

  private deleteTask(task: UserPlannedWork): void {
    this.gantt.deleteTask(task.id);

    if (!this.hasTaskPlaceholderChildren(task)) {
      this.gantt.deleteTask(task.parent);
    }
  }

  private hasTaskPlaceholderChildren(task: UserPlannedWork): boolean {
    return this.gantt.getChildren(task.parent).length ? true : false;
  }

  private getTaskPlaceholderId(task: UserPlannedWork): number | null {
    const [placeholderTask] = this.gantt.getTaskBy(
      t =>
        t.eventType === EventTypeEnum.Placeholder && +t.userId === +task.userId,
      null
    );
    return placeholderTask?.id;
  }

  private createTaskPlaceholderParent(
    task: UserPlannedWork,
    userFullName?: string
  ): number {
    const placeholder =
      this.plannerDataAdapterService.createScheduledResourcePlaceholder(
        task,
        this.gantt.uid(),
        userFullName
      );
    this.gantt.addTask(placeholder);

    return +placeholder.id;
  }

  private handleAfterDragEvent = (taskId: string | number, mode: string) => {
    const plannedTime = this.gantt.getTask(taskId);

    if (mode === 'move') {
      /*
       * End date is set to the next day at 00:00 when dragging to new day, we prevent it
       * by subtracting end date with 1 minute
       */
      plannedTime.end_date = moment(plannedTime.end_date)
        .subtract(1, 'minutes')
        .toDate();

      const newStartDate = moment(plannedTime.start_date).format('YYYY-MM-DD');
      const newEndDate = moment(plannedTime.end_date).format('YYYY-MM-DD');

      plannedTime.realStartDate = moment(newStartDate)
        .set('hour', moment(plannedTime.realStartDate).hour())
        .set('minute', moment(plannedTime.realStartDate).minute())
        .toString();

      plannedTime.realEndDate = moment(newEndDate)
        .set('hour', moment(plannedTime.realEndDate).hour())
        .set('minute', moment(plannedTime.realEndDate).minute())
        .toString();

      this.plannerWriteService
        .updatePlannedTime({
          id: +plannedTime.id,
          startDate: this.plannerDataAdapterService.formatDateToString(
            new Date(plannedTime.realStartDate)
          ),
          endDate: this.plannerDataAdapterService.formatDateToString(
            new Date(plannedTime.realEndDate)
          ),
        })
        .subscribe(result => {
          if (result.mutationSucceededAllArguments) {
            this.gantt.updateTask(plannedTime.id.toString(), plannedTime);
          } else {
            this.messageService.insertDataFromMutation(result);
          }
        });
    }
  };

  public handleAfterDeleteEvent(taskId: number): void {
    const taskToDelete = this.gantt.getTask(taskId);
    this.deleteTask(taskToDelete);
  }

  public handleUpdatePlannedTimeEvent(willUpdateTask: UserPlannedWork): void {
    const task = this.gantt.getTask(
      willUpdateTask.id
    ) as SchedulerEventPlannedTime;

    const updatedTask: SchedulerEventPlannedTime = {
      ...task,
      color: willUpdateTask.color,
      text: willUpdateTask.messageToUser,
      textColor: this.plannerDataAdapterService.setTextColor(
        willUpdateTask.color
      ),
      start_date: moment(willUpdateTask.startDate)
        .set('hours', 0)
        .set('minutes', 0)
        .toDate(),
      end_date: moment(willUpdateTask.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .toDate(),
      realStartDate: willUpdateTask.startDate,
      realEndDate: willUpdateTask.endDate,
      todoId: +willUpdateTask.todoId,
      userId: +willUpdateTask.userId,
      projectId: +willUpdateTask.projectId,
    };

    const placeholderResourceUser = this.ganttData.data.find(
      event =>
        event.eventType === EventTypeEnum.Placeholder &&
        event.userId === willUpdateTask.userId
    );

    if (placeholderResourceUser) {
      updatedTask.parent = +placeholderResourceUser.userId;
    }

    this.gantt.updateTask(task.id.toString(), updatedTask);
  }

  public exportToPDF(): void {
    const settings = {
      name: 'resource-' + new Date().toISOString() + '.pdf',
      locale: 'sv',
      skin: 'material',
      raw: false,
      server: `${window.location.origin}/planner/exportToPDF`,
      additional_settings: {
        landscape: true,
      },
    };

    this.gantt.exportToPDF(settings);
  }
}
