import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DateSpan } from 'app/project/project-list/project-list.service';
import { CompanyFunctionsService } from 'app/shared/company';
import { UserLocalStorageService } from 'app/shared/user';
import { UserFlagsService } from 'app/user-flags.service';
import { Card } from 'primeng/card';
import { BehaviorSubject, first, Observable, Subscription } from 'rxjs';
import { FetchProjectQuery } from '../graphql/project.generated';
import {
  CostCalculationMode,
  ProjectKpiCollection,
  ProjectOverviewData,
  ProjectOverviewService,
} from './project-overview.service';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.scss'],
})
export class ProjectOverviewComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Input() public projectId: number;
  @Input() public shouldUpdate: boolean;

  @ViewChild('diagramCard') public card: Card;
  public diagramHeight: number;

  public projectOverviewData = new BehaviorSubject<ProjectOverviewData>(
    {} as ProjectOverviewData
  );
  public projectKpiData = new BehaviorSubject<ProjectKpiCollection>(
    {} as ProjectKpiCollection
  );
  public includeOverhead: boolean;
  public setMile: boolean;
  public isLoading = true;
  public loadingOverlayTransitionDuration = 500;
  public loadingOverlayHidingInProgress = false;
  private projectDataSub: Subscription;
  private overviewDataSub: Subscription;
  public isAdmin: boolean;

  private includeSubProjects: boolean;
  private timeSpan: DateSpan;

  constructor(
    private projectOverviewService: ProjectOverviewService,
    private companyFunctionsService: CompanyFunctionsService,
    private userLocalStorageService: UserLocalStorageService,
    private userFlagsService: UserFlagsService
  ) {}

  public ngAfterViewInit(): void {
    this.diagramHeight = this.card.getBlockableElement().offsetHeight;
  }

  public ngOnInit(): void {
    const localstorageMeUser = this.userLocalStorageService.getMEUser();
    this.setMile = this.companyFunctionsService.companyFunctionIsSet('setMile');
    this.includeOverhead =
      localstorageMeUser.includeOverhead === null ||
      localstorageMeUser.includeOverhead === 'true';
    this.projectDataSub = this.projectData.subscribe(() => {
      this.fetchOverviewData();
    });
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => (this.isAdmin = flags.isAdmin));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.projectId) {
      this.projectId = Number(changes.projectId.currentValue);
      this.fetchOverviewData();
    }
    if (changes.shouldUpdate?.currentValue) {
      this.fetchOverviewData();
    }
  }

  public fetchOverviewData(): void {
    if (!this.shouldUpdate) {
      return;
    }
    this.isLoading = true;
    this.overviewDataSub?.unsubscribe();

    this.overviewDataSub = this.projectOverviewService
      .fetchOverviewData(this.projectId, this.timeSpan, this.includeSubProjects)
      .subscribe(projectOverviewData => {
        this.projectOverviewData.next(projectOverviewData);
        this.loadingOverlayHidingInProgress = true;
        setTimeout(() => {
          this.isLoading = false;
          this.loadingOverlayHidingInProgress = false;
        }, this.loadingOverlayTransitionDuration);
      });
  }

  public ngOnDestroy(): void {
    this.overviewDataSub?.unsubscribe();
    this.projectDataSub?.unsubscribe();
  }

  public onCostCalculationChanged(
    costCalculationMode: CostCalculationMode
  ): void {
    this.projectOverviewService.changeCalculationMode(costCalculationMode);
  }

  public onTimeSpanChanged(timeSpan: DateSpan): void {
    this.timeSpan = timeSpan;
    this.fetchOverviewData();
  }

  public onIncludeSubProjectsChanged(includeSubProjects: boolean): void {
    this.includeSubProjects = includeSubProjects;
    this.fetchOverviewData();
  }
}
