<div class="p-grid invoice-person-rot-info">
  <form *ngIf="headRotInfoForm" [formGroup]="headRotInfoForm" class="p-col-12">
    <div
      formArrayName="persons"
      *ngFor="let person of DynamicFormControls['controls']; let i = index"
    >
      <div class="p-grid" [formGroupName]="i">
        <div class="p-col-4 p-grid p-ac-start">
          <span class="p-col-12">
            <label>Namn</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-user"></i>
              </span>
              <input pInputText formControlName="name" />
            </span>
          </span>
          <span class="p-col-12">
            <label>Personnummer*</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-hashtag"></i>
              </span>
              <input pInputText formControlName="personalNumber" />
            </span>
          </span>
        </div>

        <div class="p-col-4 p-grid p-ac-start">
          <span class="p-col-12">
            <label>Fastighetsbeteckning</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-building"></i>
              </span>
              <input
                pInputText
                [disabled]="isRUT"
                formControlName="nameOfProperty"
              />
            </span>
          </span>
          <span class="p-col-12">
            <label>BRF org.nummer</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-hashtag"></i>
              </span>
              <input
                pInputText
                [disabled]="isRUT"
                formControlName="housingAssociationOrgNumber"
              />
            </span>
          </span>

          <span class="p-col-12">
            <label>Lägenhetsbeteckning</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-building"></i>
              </span>
              <input
                pInputText
                [disabled]="isRUT"
                formControlName="apartmentDesignation"
              />
            </span>
          </span>
        </div>
        <div class="p-col-4 p-grid p-ac-start">
          <span class="p-col-12">
            <label>Maximal skattereduktion*</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-money-bill"></i>
              </span>
              <input pInputText formControlName="customerWantedWorkAmount" />
            </span>
          </span>

          <span class="p-col-12">
            <label>Belopp du begär*</label>
            <span class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-money-bill"></i>
              </span>
              <input pInputText formControlName="wantedWorkAmount" />
            </span>
          </span>

          <div class="p-col-12 p-pr-0">
            <button
              pButton
              (click)="deletePerson(i)"
              class="p-button-icon-danger"
              style="float: right"
              icon="pi pi-trash"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <button
      style="float: right"
      class="p-button-primary-hyperion"
      pButton
      (click)="addPerson()"
      label="Lägg till"
      icon="pi pi-plus"
    ></button>
  </form>
</div>
