<div style="max-height: 350px; overflow-y: scroll; overflow-x: hidden">
  <p-table
    [value]="dataSetAsync | async"
    [id]="tableElementId"
    [rowHover]="true"
    [columns]="productTableCols"
    [rowTrackBy]="helperService.rowTrackBy"
    [rows]="tableRows"
    dataKey="costTypeId"
    [loading]="dataLoading"
    #projectProductsTable
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          [ngClass]="col.class"
          [style.width]="col.width"
        >
          {{ col.header }}
        </th>
        <th style="width: 45px"></th>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="body"
      let-productTop
      let-expanded="expanded"
      let-columns="columns"
    >
      <tr [pRowToggler]="productTop">
        <td *ngFor="let col of columns" style="font-size: 13px">
          <span
            *ngIf="productTop.type === 'costType' && col.field === 'benamning'"
            class="link p-text-bold"
          >
            <div class="p-text-truncate">
              {{ productTop.name }}
            </div>
            <i
              [ngClass]="
                expanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'
              "
            ></i>
          </span>

          <span
            *ngIf="col.field === 'totalSum'"
            style="font-weight: bold; float: right"
          >
            {{ productTop.costTypeSum | formatNum }}
          </span>
        </td>
        <td style="width: 45px"></td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-product let-columns="columns">
      <tr style="border: none" class="p-component-content">
        <td [attr.colspan]="columns.length + 1" style="padding: 0">
          <p-table
            [rowHover]="true"
            #dt
            [columns]="columns"
            [value]="product.childsAsync | async"
            [sortField]="sort.attribute"
            [sortOrder]="sort.ascDesc"
            (onSort)="changeSort($event)"
            [rowTrackBy]="rowTrackById"
            class="project-product-table"
            [id]="
              'productsForCostType' +
              (isExtra ? 'Extra' : 'Normal') +
              product.id
            "
          >
            <ng-template pTemplate="header" style="display: block" let-columns>
              <tr style="background-color: #f2f2f2">
                <th
                  *ngFor="let col of columns"
                  style="text-align: center"
                  [style.width]="col.width"
                  [pSortableColumn]="col.field"
                >
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
                <th style="width: 45px"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
              <tr [ngClass]="product.classes" *ngIf="showLargeTable">
                <td class="project-product--artnr">
                  <span [id]="'productId' + product.id">
                    {{ product.artnr }}
                  </span>
                </td>
                <td class="project-product--date">
                  <span
                    [ngClass]="{ 'hover-pointer': !product.invoiceId }"
                    (click)="!product.invoiceId && openDateDialog(product)"
                  >
                    {{ product.date }}
                  </span>
                  <span *ngIf="product.supplierInvoiceId > 0">
                    <a (click)="showPdf(product.supplierInvoiceId)"
                      ><br /><i
                        pTooltip="Visa relaterad leverantörsfaktura"
                        class="fa fa-inbox hover-pointer"
                      ></i
                    ></a>
                    <span
                      *ngFor="
                        let sourceDoc of objectToArray(
                          product.supplierInvoice
                            .invoiceSourceDocuments_PreInvoiceTypeHyperion
                        )
                      "
                    >
                      <span
                        class="link"
                        style="margin-right: 3px"
                        (click)="showPdfFromUrl(sourceDoc.url)"
                        >&nbsp;<i
                          title="Reletarade dokument till leverantörsfaktura"
                          class="fa fa-file-text-o"
                        ></i
                      ></span>
                    </span>
                  </span>
                </td>
                <td class="project-product--name" pEditableColumn>
                  <span [id]="'productId' + product.id">
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          type="text"
                          [(ngModel)]="product.benamning"
                          (change)="triggerRemovalOfSourceData(product)"
                          pInputText
                          (keydown.enter)="onEditComplete(product)"
                          [class.p-disabled]="product.invoiceId"
                          [disabled]="
                            product.invoiceId ||
                            usesProductDetailsDeromeAPI(product)
                          "
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <p class="p-text-truncate">
                          {{ product.benamning }}
                        </p>
                      </ng-template>
                    </p-cellEditor>
                    <span
                      class="pi pi-user"
                      [pTooltip]="getTooltip('benamning', product)"
                    ></span>
                  </span>
                </td>
                <td class="project-product--quantity" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.antal"
                        pInputText
                        (blur)="updatePrices(product)"
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="product.invoiceId"
                        [disabled]="product.invoiceId"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.antal }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="project-product--unit" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: center"
                        type="text"
                        [(ngModel)]="product.enhet"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                        [disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.enhet }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  *ngIf="showAvtalsprisCostColumn()"
                  class="project-product--net-price"
                  pEditableColumn
                >
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.avtalsprisCost"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                        [disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.avtalsprisCost | formatNum }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="project-product--sales-price" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.avtalspris"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="product.invoiceId"
                        [disabled]="product.invoiceId"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.avtalspris | formatNum }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  *ngIf="showJamforsprisColumn()"
                  class="project-product--comparative-price"
                >
                  <span *ngIf="product.comparativePrice">{{
                    product.comparativePrice
                  }}</span>
                </td>
                <td class="project-product--procent">
                  {{ product.procent }}%
                  <i
                    *ngIf="!product.invoiceId"
                    tooltipPosition="top"
                    pTooltip="Klicka för att byta kostnadstyp"
                    (click)="openCostTypeDialog(product)"
                    class="edit-panel fa fa-pencil link"
                  ></i>
                </td>

                <td class="project-product--is-extra">
                  <p-checkbox
                    [(ngModel)]="product.extraBool"
                    [binary]="true"
                    (onChange)="onEditComplete(product)"
                  ></p-checkbox>
                </td>

                <td class="project-product--total-sum">
                  {{ product.totalSum | formatNum }}
                </td>
                <td class="project-product--is-invoiced">
                  <span
                    *ngIf="product.invoiceId"
                    tooltipPosition="top"
                    pTooltip="Fakturerad"
                    >F</span
                  >
                  <span
                    *ngIf="!product.invoiceId"
                    tooltipPosition="top"
                    pTooltip="Ej fakturerad"
                    >-</span
                  >
                </td>
                <td class="project-product--actions" style="width: 45px">
                  <span *ngIf="!product.invoiceId" style="font-size: 15px">
                    <i
                      class="fa fa-save save"
                      (click)="onEditComplete(product)"
                    ></i>
                    <i
                      style="margin-left: 0.2em"
                      class="fa fa-trash-o delete"
                      (click)="confirmDeleteProduct(product)"
                    ></i>
                  </span>
                </td>
              </tr>

              <!-- Small table -->
              <tr [ngClass]="product.classes" *ngIf="!showLargeTable">
                <td
                  class="project-product--name p-text-truncate"
                  pEditableColumn
                >
                  <span
                    [id]="'productId' + product.id"
                    tooltipPosition="top"
                    [pTooltip]="getTooltip('benamning', product)"
                  >
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input
                          style="width: 89%"
                          type="text"
                          [(ngModel)]="product.benamning"
                          pInputText
                          (change)="triggerRemovalOfSourceData(product)"
                          (keydown.enter)="onEditComplete(product)"
                          [class.p-disabled]="
                            product.invoiceId ||
                            usesProductDetailsDeromeAPI(product)
                          "
                          [disabled]="
                            product.invoiceId ||
                            usesProductDetailsDeromeAPI(product)
                          "
                        />
                      </ng-template>
                      <ng-template pTemplate="output">
                        {{ product.benamning }}
                      </ng-template>
                    </p-cellEditor>
                  </span>
                </td>
                <td class="project-product--quantity" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.antal"
                        pInputText
                        (blur)="updatePrices(product)"
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="product.invoiceId"
                        [disabled]="product.invoiceId"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.antal }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="project-product--unit" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%"
                        type="text"
                        [(ngModel)]="product.enhet"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                        [disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.enhet }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  *ngIf="showAvtalsprisCostColumn()"
                  class="project-product--net-price"
                  pEditableColumn
                >
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.avtalsprisCost"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                        [disabled]="
                          product.invoiceId ||
                          usesProductDetailsDeromeAPI(product)
                        "
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.avtalsprisCost | formatNum }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td class="project-product--sales-price" pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input
                        style="width: 89%; text-align: right"
                        type="text"
                        [(ngModel)]="product.avtalspris"
                        pInputText
                        (keydown.enter)="onEditComplete(product)"
                        [class.p-disabled]="product.invoiceId"
                        [disabled]="product.invoiceId"
                      />
                    </ng-template>
                    <ng-template pTemplate="output">
                      {{ product.avtalspris | formatNum }}
                    </ng-template>
                  </p-cellEditor>
                </td>
                <td
                  *ngIf="showJamforsprisColumn()"
                  class="project-product--comparative-price"
                >
                  <span *ngIf="product.comparativePrice">{{
                    product.comparativePrice
                  }}</span>
                </td>
                <td class="project-product--total-sum">
                  {{ product.totalSum | formatNum }}
                </td>
                <td class="project-product--is-invoiced">
                  <span
                    *ngIf="product.invoiceId"
                    tooltipPosition="top"
                    pTooltip="Fakturerad"
                    >F</span
                  >
                  <span
                    *ngIf="!product.invoiceId"
                    tooltipPosition="top"
                    pTooltip="Ej fakturerad"
                    >-</span
                  >
                </td>
                <td class="project-product--actions" style="width: 25px">
                  <span *ngIf="!product.invoiceId" style="font-size: 15px">
                    <i
                      class="fa fa-save save"
                      (click)="onEditComplete(product)"
                    ></i>
                    <i
                      style="margin-left: 0.2em"
                      class="fa fa-trash-o delete"
                      (click)="confirmDeleteProduct(product)"
                    ></i>
                  </span>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="summary">
      <div style="width: 100%; font-weight: 400" class="p-mb-5">
        <div class="pull-right">
          Summa material / övriga kostnader (ink. pålägg):
          <span *ngIf="!isExtra" class="strong-text">{{
            projectInvoiceData['productCost'] | formatNum
          }}</span
          ><span *ngIf="isExtra" class="strong-text">{{
            projectInvoiceData['productCostExtra'] | formatNum
          }}</span>
        </div>
        <div class="clear-both"></div>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="emptyStateTR">
        <td [attr.colspan]="columns.length + 1">
          <app-emptyState
            [model]="'Projectproduct'"
            [whereToCreate]="'bottom'"
          ></app-emptyState>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="dateDialog.show"
  [style]="{ width: '350' }"
  [responsive]="true"
  modal="true"
  style
  [draggable]="false"
  [resizable]="false"
>
  <div class="p-grid">
    <div class="p-col-12 small-text">
      <p class="strong-text">Uppdatera bokfört datum för:</p>
      <p *ngIf="dateDialog.show" style="margin-bottom: 8px">
        {{ dateDialog.product.benamning }}
      </p>
      <p class="strong-text">Tillagd av:</p>
      <p *ngIf="dateDialog.show">
        {{ dateDialog['product']['user']['firstName'] }}
        {{ dateDialog['product']['user']['lastName'] }},
        {{ getFormatedDate(dateDialog['product']['created']) }}
      </p>
    </div>
    <div
      *ngIf="dateDialog.show"
      class="p-col-12 align-center calendar-full-width"
    >
      <appForm2-list-calendar
        [inline]="true"
        [(ngModel)]="dateDialog.product.date"
        [htmlAttributes]="{ label: { value: ' ' } }"
      ></appForm2-list-calendar>
    </div>
    <div class="p-col-12">
      <div class="pull-right">
        <app-button
          (click)="dateDialogCancel(dateDialog.product)"
          [type]="'cancel'"
        ></app-button>
        <app-button
          [class]="'ml5'"
          (click)="dateDialogConfirm(dateDialog.product)"
          [type]="'confirm'"
        ></app-button>
      </div>
    </div>
  </div>
</p-dialog>
<p-dialog
  [(visible)]="companyCostTypeDialog.show"
  [style]="{ width: '350' }"
  [responsive]="true"
  modal="true"
  style
  [draggable]="false"
  [resizable]="false"
>
  <div class="p-grid">
    <div class="p-col-12 small-text">
      <p class="strong-text">Uppdatera kostnadstyp för:</p>
      <p *ngIf="companyCostTypeDialog.show" style="margin-bottom: 8px">
        {{ companyCostTypeDialog.product.benamning }}
      </p>
      <p class="strong-text">Tillagd av:</p>
      <p *ngIf="companyCostTypeDialog.show">
        {{ companyCostTypeDialog['product']['user']['firstName'] }}
        {{ companyCostTypeDialog['product']['user']['firstName'] }},
        {{ getFormatedDate(companyCostTypeDialog['product']['created']) }}
      </p>
    </div>
    <div class="p-col-12">
      <div *ngIf="companyCostTypeDialog.show" style="padding-top: 7px">
        <div *ngFor="let costType of functionsData.companyCostTypes">
          <p-radioButton
            [value]="costType.value"
            [label]="costType.label"
            [(ngModel)]="companyCostTypeDialog.newCostType"
          ></p-radioButton>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="pull-right">
        <app-button
          (click)="costTypeDialogCancel()"
          [type]="'cancel'"
        ></app-button>
        <app-button
          [class]="'ml5'"
          (click)="costTypeDialogConfirm(companyCostTypeDialog.product)"
          [type]="'confirm'"
        ></app-button>
      </div>
    </div>
  </div>
</p-dialog>

<app-create-project-product
  [functionsData]="functionsData"
  [isExtra]="isExtra"
  [projectInfo]="projectInfo"
  [projectLabels]="projectLabels"
  [showLargeTable]="showLargeTable"
  [showProductPrices]="showProductPrices"
  (creationCompleted)="onProductCreation($event)"
  [isMissingDeromeProductIntegration]="isMissingDeromeProductIntegration"
></app-create-project-product>
