import { gql, Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import {
  emailValidator,
  minLengthValidator,
  orgOrPersonalNumberValidator,
  phoneValidator,
  requiredTrueValidator,
} from 'app/shared/forms/validators';

const COMPANY_AND_USER_INFO_QUERY = gql`
  query CompanyAndUserInfo {
    company {
      id
      name
      orgNr
    }

    me {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

@Component({
  selector: 'app-home-evive',
  templateUrl: 'home-evive.component.html',
  styleUrls: ['./home-evive.component.scss'],
})
export class HomeEviveComponent implements OnInit, OnDestroy {
  public categories: SelectItem[] = [
    { value: 'Rådgivning', label: 'Rådgivning' },
    { value: 'Tvist', label: 'Tvist' },
    { value: 'Utbildning', label: 'Utbildning' },
    { value: 'Avtalsgranskning', label: 'Avtalsgranskning' },
    { value: 'Övrigt', label: 'Övrigt' },
  ];

  public signupForm = this.formBuilder.group({
    companyOrgNo: ['', [orgOrPersonalNumberValidator()]],
    companyName: ['', [minLengthValidator(2)]],
    category: ['Rådgivning'],
    name: ['', [minLengthValidator(2)]],
    phone: ['', [phoneValidator()]],
    email: ['', [emailValidator()]],
    accept: [false, [requiredTrueValidator()]],
  });

  public showThankYou = false;
  public loading = false;
  public popupTermsDetailsVisible = false;

  constructor(
    private formBuilder: FormBuilder,
    private apollo: Apollo,
    private http: HttpClient
  ) {}

  public ngOnInit(): void {
    this.prefillFormWithCompanyInfo();
  }

  public ngOnDestroy(): void {
    this.showThankYou = false;
    this.popupTermsDetailsVisible = false;
  }

  private prefillFormWithCompanyInfo(): void {
    this.getCompanyInfo().subscribe(({ data }: any) => {
      this.signupForm.patchValue({
        companyOrgNo: data.company.orgNr,
        companyName: data.company.name,
        name: `${data.me.firstName} ${data.me.lastName}`,
        phone: data.me.phone,
        email: data.me.email,
      });
    });
  }

  private getCompanyInfo(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: COMPANY_AND_USER_INFO_QUERY,
    });
  }

  public showTermsDetails(): void {
    this.popupTermsDetailsVisible = true;
  }

  public submitForm(): void {
    this.signupForm.markAllAsTouched();
    if (!this.signupForm.valid) {
      return;
    }
    this.loading = true;

    this.http
      .post('/evive/formsubmit', this.signupForm.value)
      .pipe(first())
      .subscribe((data: any) => {
        this.loading = false;

        if (data.success) {
          this.showThankYou = true;
        }
      });
  }
}
