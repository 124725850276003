import * as moment from 'moment';
import { GanttSizes } from '../planner-module-enums';

function isToday(date: Date, scale: moment.unitOfTime.StartOf): boolean {
  let isToday = false;
  if (scale === 'quarter') {
    for (let i = 0; i < 2; i++) {
      isToday = moment(date).isSame(moment().add(i, 'month'), 'month');
      if (isToday) {
        break;
      }
    }
  } else {
    isToday = moment(date).isSame(new Date(), scale);
  }
  return isToday;
}

export function isWeekend(date: Date): boolean {
  return date.getDay() === 0 || date.getDay() === 6;
}

export function getTemplateClassByDate(
  date: Date,
  scale: 'day' | 'week' | string
): string {
  if ((scale === 'day' || scale === 'week') && isWeekend(date)) {
    return 'weekend';
  }

  return '';
}

export function calculateGanttColumnWidth(columns: number): number {
  const contentWidth = window.innerWidth;
  const padding = 50;

  return (contentWidth - GanttSizes.Sidebar - padding) / columns;
}
