import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';

@Component({
  selector: 'add-attendance-free-user',
  templateUrl: './add-attendance-free-user.component.html',
})
export class AddAttendanceFreeUserComponent implements OnInit {
  dataModelCapitalized = 'AttendanceFreeUser';
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      firstName: '',
      lastName: '',
      personalNumber: '',
      companyOrgNr: '',
    },
  };
  mutationData;
  componentMainForm: FormGroup;
  public subcontractorData: any = {};
  dataModel = 'attendanceFreeUser';

  constructor(
    public dialogRef: DynamicDialogRef,
    private formHandler: FormHandlerService,
    public dialogConfig: DynamicDialogConfig,
    private mutationService: ApolloMutationService
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initComponentWithDialogData();
    this.formFields.attributes.companyOrgNr = this.subcontractorData.orgNr;
    this.initForm();
  }

  initComponentWithDialogData() {
    this.subcontractorData = this.dialogConfig['data']['subcontractorData'];
    setTimeout(() => {
      this.dialogConfig.header = 'Skapa ny person';
    }, 0);
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.componentMainForm.value;

    if (this.formHandler.formValid([this.componentMainForm])) {
      const returnAttributes = [
        'id',
        'firstName',
        'lastName',
        'personalNumber',
        'active',
      ];

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          null
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.mutationData = executedData;
              this.closeAction();
            }

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  closeAction() {
    this.dialogRef.close(this.mutationData);
  }
}
