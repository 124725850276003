import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PaymentPlansQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type PaymentPlansQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', installments?: { __typename?: 'ProjectInstallmentConnection', edges?: Array<{ __typename?: 'ProjectInstallmentEdge', node?: { __typename?: 'ProjectInstallment', id: string, created?: string | null, dateToInvoice?: string | null, name?: string | null, rows?: { __typename?: 'ProjectInstallmentRowConnection', edges?: Array<{ __typename?: 'ProjectInstallmentRowEdge', node?: { __typename?: 'ProjectInstallmentRow', id: string, antal?: string | null, apris?: string | null, arbetskostnad?: number | null, benamning?: string | null, invoiceId?: number | null, invoiced?: boolean | null, pris?: string | null, tax?: number | null, typeId?: number | null } | null } | null> | null } | null, user?: { __typename?: 'User', id: string, lastName?: string | null, firstName?: string | null, isDeleted?: number | null, hidden?: number | null, allowed?: number | null } | null } | null } | null> | null } | null } | null };

export type PlanFragment = { __typename?: 'ProjectInstallment', id: string, created?: string | null, dateToInvoice?: string | null, name?: string | null, rows?: { __typename?: 'ProjectInstallmentRowConnection', edges?: Array<{ __typename?: 'ProjectInstallmentRowEdge', node?: { __typename?: 'ProjectInstallmentRow', id: string, antal?: string | null, apris?: string | null, arbetskostnad?: number | null, benamning?: string | null, invoiceId?: number | null, invoiced?: boolean | null, pris?: string | null, tax?: number | null, typeId?: number | null } | null } | null> | null } | null, user?: { __typename?: 'User', id: string, lastName?: string | null, firstName?: string | null, isDeleted?: number | null, hidden?: number | null, allowed?: number | null } | null };

export type UserFragment = { __typename?: 'User', id: string, lastName?: string | null, firstName?: string | null, isDeleted?: number | null, hidden?: number | null, allowed?: number | null };

export type RowFragment = { __typename?: 'ProjectInstallmentRow', id: string, antal?: string | null, apris?: string | null, arbetskostnad?: number | null, benamning?: string | null, invoiceId?: number | null, invoiced?: boolean | null, pris?: string | null, tax?: number | null, typeId?: number | null };

export type InvoiceRowTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InvoiceRowTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', generalData_PreCompanyTypeHyperion?: { __typename?: 'generalData_PreCompanyTypeHyperion_ModelFunction', invoiceRowTypeList?: { __typename?: 'invoiceRowTypeListConnection', edges?: Array<{ __typename?: 'invoiceRowTypeListEdge', node?: { __typename?: 'invoiceRowTypeList', description?: string | null, key?: number | null } | null } | null> | null } | null } | null } | null };

export type InvoiceRowTypeFragment = { __typename?: 'invoiceRowTypeList', description?: string | null, key?: number | null };

export type TaxTypeFragment = { __typename?: 'invoiceTaxList', description?: string | null, key?: number | null };

export type TaxTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TaxTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', generalData_PreCompanyTypeHyperion?: { __typename?: 'generalData_PreCompanyTypeHyperion_ModelFunction', invoiceTaxList?: { __typename?: 'invoiceTaxListConnection', edges?: Array<{ __typename?: 'invoiceTaxListEdge', node?: { __typename?: 'invoiceTaxList', description?: string | null, key?: number | null } | null } | null> | null } | null } | null } | null };

export type UsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, lastName?: string | null, firstName?: string | null, isDeleted?: number | null, hidden?: number | null, allowed?: number | null } | null } | null> | null } | null } | null };

export type AddPlanMutationVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
  date?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AddPlanMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentTypeHyperionMutation?: { __typename?: 'ProjectInstallment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type DeletePlanMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeletePlanMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentTypeHyperionMutation?: { __typename?: 'ProjectInstallment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type AddRowMutationVariables = Types.Exact<{
  planId?: Types.InputMaybe<Types.Scalars['Int']>;
  typeId?: Types.InputMaybe<Types.Scalars['Int']>;
  invoiced?: Types.InputMaybe<Types.Scalars['Boolean']>;
  benamning?: Types.InputMaybe<Types.Scalars['String']>;
  arbetskostnad?: Types.InputMaybe<Types.Scalars['Int']>;
  antal?: Types.InputMaybe<Types.Scalars['String']>;
  apris?: Types.InputMaybe<Types.Scalars['String']>;
  pris?: Types.InputMaybe<Types.Scalars['String']>;
  tax?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AddRowMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentRowTypeHyperionMutation?: { __typename?: 'ProjectInstallmentRow', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type DeleteRowMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteRowMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentRowTypeHyperionMutation?: { __typename?: 'ProjectInstallmentRow', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type EditRowMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  typeId?: Types.InputMaybe<Types.Scalars['Int']>;
  invoiced?: Types.InputMaybe<Types.Scalars['Boolean']>;
  benamning?: Types.InputMaybe<Types.Scalars['String']>;
  arbetskostnad?: Types.InputMaybe<Types.Scalars['Int']>;
  antal?: Types.InputMaybe<Types.Scalars['String']>;
  apris?: Types.InputMaybe<Types.Scalars['String']>;
  pris?: Types.InputMaybe<Types.Scalars['String']>;
  tax?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type EditRowMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentRowTypeHyperionMutation?: { __typename?: 'ProjectInstallmentRow', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export type EditPlanMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
  date?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditPlanMutation = { __typename?: 'HyperionSchemaMutation', projectInstallmentTypeHyperionMutation?: { __typename?: 'ProjectInstallment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, errorsMsgs?: Array<string | null> | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export const RowFragmentDoc = gql`
    fragment row on ProjectInstallmentRow {
  id
  antal
  apris
  arbetskostnad
  benamning
  invoiceId
  invoiced
  pris
  tax
  typeId
}
    `;
export const UserFragmentDoc = gql`
    fragment user on User {
  id
  lastName
  firstName
  isDeleted
  hidden
  allowed
}
    `;
export const PlanFragmentDoc = gql`
    fragment plan on ProjectInstallment {
  id
  created
  dateToInvoice
  name
  rows {
    edges {
      node {
        ...row
      }
    }
  }
  user {
    ...user
  }
}
    ${RowFragmentDoc}
${UserFragmentDoc}`;
export const InvoiceRowTypeFragmentDoc = gql`
    fragment invoiceRowType on invoiceRowTypeList {
  description
  key
}
    `;
export const TaxTypeFragmentDoc = gql`
    fragment taxType on invoiceTaxList {
  description
  key
}
    `;
export const PaymentPlansDocument = gql`
    query paymentPlans($projectId: Int) {
  project(id: $projectId) {
    installments {
      edges {
        node {
          ...plan
        }
      }
    }
  }
}
    ${PlanFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentPlansGQL extends Apollo.Query<PaymentPlansQuery, PaymentPlansQueryVariables> {
    document = PaymentPlansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceRowTypesDocument = gql`
    query invoiceRowTypes {
  company {
    generalData_PreCompanyTypeHyperion {
      invoiceRowTypeList {
        edges {
          node {
            ...invoiceRowType
          }
        }
      }
    }
  }
}
    ${InvoiceRowTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceRowTypesGQL extends Apollo.Query<InvoiceRowTypesQuery, InvoiceRowTypesQueryVariables> {
    document = InvoiceRowTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TaxTypesDocument = gql`
    query taxTypes {
  company {
    generalData_PreCompanyTypeHyperion {
      invoiceTaxList {
        edges {
          node {
            ...taxType
          }
        }
      }
    }
  }
}
    ${TaxTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TaxTypesGQL extends Apollo.Query<TaxTypesQuery, TaxTypesQueryVariables> {
    document = TaxTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UsersDocument = gql`
    query users {
  company {
    users {
      edges {
        node {
          ...user
        }
      }
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UsersGQL extends Apollo.Query<UsersQuery, UsersQueryVariables> {
    document = UsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddPlanDocument = gql`
    mutation addPlan($projectId: Int, $name: String, $userId: Int, $date: String) {
  projectInstallmentTypeHyperionMutation(
    createProjectInstallment: {projectId: $projectId, name: $name, responsibleUserId: $userId, dateToInvoice: $date}
  ) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddPlanGQL extends Apollo.Mutation<AddPlanMutation, AddPlanMutationVariables> {
    document = AddPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlanDocument = gql`
    mutation deletePlan($id: Int) {
  projectInstallmentTypeHyperionMutation(deleteProjectInstallment: {id: $id}) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePlanGQL extends Apollo.Mutation<DeletePlanMutation, DeletePlanMutationVariables> {
    document = DeletePlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddRowDocument = gql`
    mutation addRow($planId: Int, $typeId: Int, $invoiced: Boolean, $benamning: String, $arbetskostnad: Int, $antal: String, $apris: String, $pris: String, $tax: Int) {
  projectInstallmentRowTypeHyperionMutation(
    createProjectInstallmentRow: {projectInstallmentId: $planId, typeId: $typeId, invoiced: $invoiced, benamning: $benamning, arbetskostnad: $arbetskostnad, antal: $antal, apris: $apris, pris: $pris, tax: $tax}
  ) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddRowGQL extends Apollo.Mutation<AddRowMutation, AddRowMutationVariables> {
    document = AddRowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRowDocument = gql`
    mutation deleteRow($id: Int) {
  projectInstallmentRowTypeHyperionMutation(
    deleteProjectInstallmentRow: {id: $id}
  ) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRowGQL extends Apollo.Mutation<DeleteRowMutation, DeleteRowMutationVariables> {
    document = DeleteRowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditRowDocument = gql`
    mutation editRow($id: Int, $typeId: Int, $invoiced: Boolean, $benamning: String, $arbetskostnad: Int, $antal: String, $apris: String, $pris: String, $tax: Int) {
  projectInstallmentRowTypeHyperionMutation(
    updateProjectInstallmentRow: {id: $id, typeId: $typeId, invoiced: $invoiced, benamning: $benamning, arbetskostnad: $arbetskostnad, antal: $antal, apris: $apris, pris: $pris, tax: $tax}
  ) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditRowGQL extends Apollo.Mutation<EditRowMutation, EditRowMutationVariables> {
    document = EditRowDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditPlanDocument = gql`
    mutation editPlan($id: Int, $name: String, $userId: Int, $date: String) {
  projectInstallmentTypeHyperionMutation(
    updateProjectInstallment: {id: $id, name: $name, dateToInvoice: $date, responsibleUserId: $userId}
  ) {
    mutationDetails {
      msg
      errorsMsgs
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditPlanGQL extends Apollo.Mutation<EditPlanMutation, EditPlanMutationVariables> {
    document = EditPlanDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }