import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AccordionModule } from 'primeng/accordion';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { CookieService } from 'ngx-cookie-service';

import {
  HomeComponent,
  HomeBenefitsComponent,
  HomeExtraFunctionsComponent,
  HomeEviveComponent,
  HomeUpdatesComponent,
  HomeStartComponent,
  CompanyNoticeBoardReadHolderComponent,
  HomeStartDaysReadHolder,
  HomeStartNewsHolder,
  HomeStartPicturesReadHolder,
  CompanyPictureFeedComponent,
  HomeStartProjectCommentsReadHolder,
  UpcommingActivitiesComponent,
  HomeRecommendComponent,
} from './index';

import { IntroCompanyFormComponent } from './intro-screens/index';
import { HyperionSharedModule } from '../shared/shared.module';
import { SharedDialogModule } from '../shared/dialogs/shared.dialog.module';
import { SettingsModule } from '../settings/settings.module';
import { OldProjectModule } from 'app/old-project/old-project.module';
import { CompanyModule } from 'app/company/company.module';
import { OverviewComponent } from './home-start/overview/overview.component';
import { HomeRedirectComponent } from './home-redirect/home-redirect.component';
import { HeaderModule } from 'app/header/header.module';
import { CardModule } from 'primeng/card';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HyperionSharedModule,
    SharedDialogModule,
    SettingsModule,
    InfiniteScrollModule,
    AccordionModule,
    CalendarModule,
    TabMenuModule,
    SharedModule,
    DialogModule,
    TableModule,
    OldProjectModule,
    CompanyModule,
    Ng2HighchartsModule,
    HeaderModule,
    CardModule,
  ],
  declarations: [
    HomeComponent,
    HomeStartComponent,
    HomeEviveComponent,
    HomeRecommendComponent,
    HomeUpdatesComponent,
    HomeBenefitsComponent,
    HomeExtraFunctionsComponent,
    // -- CompanyNoticeBoard
    CompanyNoticeBoardReadHolderComponent,
    // -- Days
    HomeStartDaysReadHolder,

    // -- News
    HomeStartNewsHolder,
    // -- Pictures
    HomeStartPicturesReadHolder,
    // -- Picture Feed
    CompanyPictureFeedComponent,
    // -- Project Comments
    HomeStartProjectCommentsReadHolder,
    // UpcommingActivitiesComponent
    UpcommingActivitiesComponent,

    // IntroScreens
    IntroCompanyFormComponent,
    OverviewComponent,
    HomeRedirectComponent,
  ],
  exports: [IntroCompanyFormComponent],
  providers: [CookieService],
})
export class HomeModule {}
