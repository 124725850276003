import { Component, Input } from '@angular/core';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'projet-ata',
  templateUrl: 'project-ata.component.html',
})
export class ProjectAtaComponent {
  @Input() projectInfo;
  constructor(private apolloQueryService: ApolloQueryService) {}

  updateProjectAtaNavCount() {
    const refetchVars = { id: +this.projectInfo['id'] };
    this.apolloQueryService
      .apolloQuery('singleProjectTotalCounts', refetchVars)
      .subscribe();
  }
}
