<div class="p-grid">
  <h3 class="p-col-12">
    Projekt för {{ contact.trueId }} - {{ contact.name }}
  </h3>

  <div class="filters p-col-12">
    <div class="search-box p-inputgroup p-col-12 p-lg-6">
      <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
      <input
        type="text"
        pInputText
        (input)="
          contactProjectsTable.filterGlobal($event.target.value, 'contains')
        "
        placeholder="Sök Projekt"
      />
    </div>

    <div class="date-filters p-col-12 p-lg-6 p-g-nopad">
      <appForm2-wide-calendar
        [(ngModel)]="dateRange.from"
        (ngModelChange)="filterData('from', $event)"
        [appendTo]="'body'"
        [htmlAttributes]="{ label: { value: 'Från' } }"
      ></appForm2-wide-calendar>

      <appForm2-wide-calendar
        [(ngModel)]="dateRange.to"
        (ngModelChange)="filterData('to', $event)"
        [appendTo]="'body'"
        [htmlAttributes]="{ label: { value: 'Till' } }"
      ></appForm2-wide-calendar>
    </div>
  </div>

  <app-table
    class="p-col-12"
    #contactProjectsTable
    [models]="tableData"
    [tableMetadata]="tableMetadata"
  ></app-table>
</div>
