<spinner *ngIf="loading" [inComponent]="true"></spinner>
<div *ngIf="!loading" class="small-text">
  <div class="rss-single" *ngFor="let dataObject of dataFromParent">
    <div>
      <a
        [href]="dataObject.permalink"
        target="_blank"
        [innerHTML]="dataObject.title"
      ></a>
    </div>
    <div>
      <small>{{ dataObject.date }}</small>
    </div>
  </div>
</div>
