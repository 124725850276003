import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { List } from 'immutable';

import { DocumentColumn, DocumentRow, ViewId } from '../document';
import {
  DocumentChange,
  DocumentChangeCellEdit,
  DocumentChangeRowDelete,
  mkRowCopy,
} from '../document-change';

@Component({
  selector: 'app-docu-column',
  templateUrl: './docu-column.component.html',
  styleUrls: ['./docu-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocuColumnComponent {
  @Input() column: DocumentColumn;
  @Input() showLabel = true;
  @Input() showField = true;
  @Input() showChildren = true;
  @Output() docChange: EventEmitter<DocumentChange> = new EventEmitter();

  rowHeaderColumns(): List<DocumentColumn> {
    const firstRow = this.column.rows.first();
    if (
      firstRow !== undefined &&
      firstRow['row']['labelsOnceOnTopOfColumn']()
    ) {
      return firstRow['cols'];
    } else {
      return List();
    }
  }

  updateValue(newValue: string) {
    this.docChange.emit(
      new DocumentChangeCellEdit({
        id: this.column.col.viewId,
        value: newValue,
      })
    );
  }

  deleteRow(row: DocumentRow) {
    this.docChange.emit(
      new DocumentChangeRowDelete({
        id: row.row.viewId,
      })
    );
  }

  copyRow(row: DocumentRow) {
    this.docChange.emit(mkRowCopy(row));
  }

  trackRowById(index: number, item: DocumentRow): ViewId {
    return item.row.viewId;
  }
}
