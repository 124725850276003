import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CompanyUserFragment = { __typename?: 'User', id: string, address?: string | null, address2?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, mobilePhone?: string | null, costTypeId?: number | null, city?: string | null, cityCode?: string | null, type?: number | null, color?: string | null, relative1name?: string | null, relative1phone?: string | null, relative2name?: string | null, relative2phone?: string | null, approvePlanCostIncrease?: string | null, deletedTimestamp?: string | null };

export type CompanyUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, address?: string | null, address2?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, mobilePhone?: string | null, costTypeId?: number | null, city?: string | null, cityCode?: string | null, type?: number | null, color?: string | null, relative1name?: string | null, relative1phone?: string | null, relative2name?: string | null, relative2phone?: string | null, approvePlanCostIncrease?: string | null, deletedTimestamp?: string | null } | null } | null> | null } | null } | null };

export type CompanyDeletedUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyDeletedUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', deletedUsers?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, address?: string | null, address2?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null, phone?: string | null, mobilePhone?: string | null, costTypeId?: number | null, city?: string | null, cityCode?: string | null, type?: number | null, color?: string | null, relative1name?: string | null, relative1phone?: string | null, relative2name?: string | null, relative2phone?: string | null, approvePlanCostIncrease?: string | null, deletedTimestamp?: string | null } | null } | null> | null } | null } | null };

export type RestoreUserMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RestoreUserMutation = { __typename?: 'HyperionSchemaMutation', restoreUserMutation?: { __typename?: 'User', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', msg?: string | null, mutationSucceeded?: boolean | null } | null> | null } | null };

export const CompanyUserFragmentDoc = gql`
    fragment companyUser on User {
  id
  address
  address2
  firstName
  lastName
  email
  phone
  mobilePhone
  costTypeId
  city
  cityCode
  type
  color
  relative1name
  relative1phone
  relative2name
  relative2phone
  approvePlanCostIncrease
  deletedTimestamp
}
    `;
export const CompanyUsersDocument = gql`
    query companyUsers {
  company {
    users {
      edges {
        node {
          ...companyUser
        }
      }
    }
  }
}
    ${CompanyUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyUsersGQL extends Apollo.Query<CompanyUsersQuery, CompanyUsersQueryVariables> {
    document = CompanyUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CompanyDeletedUsersDocument = gql`
    query companyDeletedUsers {
  company {
    deletedUsers {
      edges {
        node {
          ...companyUser
        }
      }
    }
  }
}
    ${CompanyUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyDeletedUsersGQL extends Apollo.Query<CompanyDeletedUsersQuery, CompanyDeletedUsersQueryVariables> {
    document = CompanyDeletedUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreUserDocument = gql`
    mutation restoreUser($userId: Int) {
  restoreUserMutation(userId: $userId) {
    id
    mutationDetails {
      msg
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreUserGQL extends Apollo.Mutation<RestoreUserMutation, RestoreUserMutationVariables> {
    document = RestoreUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }