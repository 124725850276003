import { Component, Input } from '@angular/core';
import { IndexButtonComponent } from './index.component';
import { HyperionLabelsService } from '../labels';

@Component({
  selector: 'app-split-button',
  template: `
    <span class="btn-notification">
      <p-splitButton
        [styleClass]="btnClass"
        [label]="btnLabel"
        [model]="btnModel"
        [disabled]="disabled"
        [icon]="btnIcon"
        [menuStyle]="{ width: 'auto' }"
        (onClick)="onClickHandler()"
      ></p-splitButton>
      <span *ngIf="badge" class="badge">{{ badge }}</span>
    </span>
  `,
  providers: [HyperionLabelsService],
  styleUrls: ['./button.component.scss'],
})
export class SplitButtonComponent extends IndexButtonComponent {
  @Input() btnModel: {
    label: string;
    icon: string;
    command: () => void;
  }[];
  @Input() data: { label: string; icon: string };

  constructor(labelService: HyperionLabelsService) {
    super(labelService);
  }

  setButton(): void {
    switch (this.type) {
      case 'save':
        this.btnLabel = 'Spara ' + this.modelStrings[0];
        break;
      case 'update':
        this.btnLabel = 'Uppdatera ' + this.modelStrings[0];
        this.btnClass = 'p-button-primary';
        break;
      case 'create':
        this.btnLabel = 'Skapa ' + this.modelStrings[0];
        this.btnClass = 'p-button-primary';
        break;

      case 'showPdf':
        this.btnLabel = 'Visa';
        this.btnIcon = 'fa fa-file-pdf-o';
        break;

      case 'getPdf':
        this.btnLabel = 'Ladda ner';
        this.btnIcon = 'fa fa-file-pdf-o';
        break;

      case 'showMailComponent':
        this.btnLabel = 'Mail';
        this.btnIcon = 'fa fa-envelope-o';
        break;

      case 'stop':
        this.btnLabel = 'Avsluta ' + this.modelStrings[0];
        this.btnIcon = 'pi pi-angle-double-right';
        break;
      case 'saveVersion':
        this.btnLabel = 'Spara version';
        break;

      case 'upload':
        this.btnLabel = 'Ladda upp ' + this.modelStrings[0];
        this.btnIcon = 'pi pi-upload';
        this.btnClass = 'p-button-primary-hyperion';
        break;

      default:
        if (this.data) {
          this.btnLabel = this.data.label;
          this.btnIcon = this.data.icon || '';
        } else {
          throw new Error('No label or type provided on SplitButtonComponent');
        }
    }

    if (this.allowedOverrideButtonType.includes(this.overrideButtonType)) {
      switch (this.overrideButtonType) {
        case 'secondary':
          this.btnClass = 'p-button-secondary';
          break;
        case 'primary':
          this.btnClass = 'p-button-primary';
          break;
      }
    }

    this.appendInputedClass();
    this.btnLabel &&
      (this.btnLabel = this.btnLabel.trim()) &&
      (this.btnLabel =
        this.btnLabel.charAt(0).toUpperCase() + this.btnLabel.slice(1));
    this.btnClass = this.btnClass.trim();
  }
}
