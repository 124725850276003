import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';

import { HyperionSharedModule } from 'app/shared/shared.module';
import { AttendanceReportsListModule } from 'app/attendance-report/handle/attendance-reports-list/attendance-reports-list.module';
import { HandleModule } from 'app/attendance-report/handle/handle.module';
import { ProjectHandleComponent } from './project-handle.component';
import { AttendanceHeadComponent } from './attendance-head/attendance-head.component';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  declarations: [ProjectHandleComponent, AttendanceHeadComponent],
  imports: [
    CommonModule,
    AccordionModule,
    HyperionSharedModule,
    AttendanceReportsListModule,
    HandleModule,
    HeaderModule,
  ],
  exports: [ProjectHandleComponent, AttendanceHeadComponent],
})
export class ProjectHandleModule {}
