import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetCompanySettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCompanySettingsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, name?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, webb?: string | null, orgNr?: string | null, faxNr?: string | null, companyHourlyOverhead?: number | null, companyHourlyRateForCostumer?: string | null, companyMilageForCostumer?: number | null, companyMilageForCustomerAccountingPlanAccountId?: string | null, reverseVatCompanyMilageForCustomerAccountingPlanAccountId?: string | null, companyMileCost?: number | null, companyPrivMileCost?: number | null, betalningsvillkor?: number | null, interest?: number | null, plusGiro?: string | null, postGiro?: string | null, iban?: string | null, bicSwift?: string | null, companyInsurance?: string | null, fTax?: number | null, showPdfInvoicePricesWithVatPrivate?: number | null, skra?: string | null, standardInvoiceMailText?: string | null, standardOfferMailText?: string | null, socialInsurance?: number | null, trakHalfDayPrice?: number | null, trakDayPrice?: number | null, trakNightPrice?: number | null, weekendHourProcent?: number | null, trakHalfDayAccountingPlanAccountId?: string | null, reverseVatTrakHalfDayAccountingPlanAccountId?: string | null, trakNightAccountingPlanAccountId?: string | null, reverseVatTrakNightAccountingPlanAccountId?: string | null, trakDayAccountingPlanAccountId?: string | null, reverseVatTrakDayAccountingPlanAccountId?: string | null } | null };

export type UpdateCompanySettingsMutationVariables = Types.Exact<{
  companySettings?: Types.InputMaybe<Types.CompanyTypeHyperionMutationInput>;
}>;


export type UpdateCompanySettingsMutation = { __typename?: 'HyperionSchemaMutation', companyTypeHyperionMutation?: { __typename?: 'Company', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type CompanySettingsFragment = { __typename?: 'Company', id: string, name?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, webb?: string | null, orgNr?: string | null, faxNr?: string | null, companyHourlyOverhead?: number | null, companyHourlyRateForCostumer?: string | null, companyMilageForCostumer?: number | null, companyMilageForCustomerAccountingPlanAccountId?: string | null, reverseVatCompanyMilageForCustomerAccountingPlanAccountId?: string | null, companyMileCost?: number | null, companyPrivMileCost?: number | null, betalningsvillkor?: number | null, interest?: number | null, plusGiro?: string | null, postGiro?: string | null, iban?: string | null, bicSwift?: string | null, companyInsurance?: string | null, fTax?: number | null, showPdfInvoicePricesWithVatPrivate?: number | null, skra?: string | null, standardInvoiceMailText?: string | null, standardOfferMailText?: string | null, socialInsurance?: number | null, trakHalfDayPrice?: number | null, trakDayPrice?: number | null, trakNightPrice?: number | null, weekendHourProcent?: number | null, trakHalfDayAccountingPlanAccountId?: string | null, reverseVatTrakHalfDayAccountingPlanAccountId?: string | null, trakNightAccountingPlanAccountId?: string | null, reverseVatTrakNightAccountingPlanAccountId?: string | null, trakDayAccountingPlanAccountId?: string | null, reverseVatTrakDayAccountingPlanAccountId?: string | null };

export const CompanySettingsFragmentDoc = gql`
    fragment companySettings on Company {
  id
  name
  address
  address2
  cityCode
  city
  phone
  mobilePhone
  mail
  webb
  orgNr
  faxNr
  companyHourlyOverhead
  companyHourlyRateForCostumer
  companyMilageForCostumer
  companyMilageForCustomerAccountingPlanAccountId
  reverseVatCompanyMilageForCustomerAccountingPlanAccountId
  companyMileCost
  companyPrivMileCost
  betalningsvillkor
  interest
  plusGiro
  postGiro
  iban
  bicSwift
  companyInsurance
  fTax
  showPdfInvoicePricesWithVatPrivate
  skra
  standardInvoiceMailText
  standardOfferMailText
  socialInsurance
  trakHalfDayPrice
  trakDayPrice
  trakNightPrice
  weekendHourProcent
  trakHalfDayAccountingPlanAccountId
  reverseVatTrakHalfDayAccountingPlanAccountId
  trakNightAccountingPlanAccountId
  reverseVatTrakNightAccountingPlanAccountId
  trakDayAccountingPlanAccountId
  reverseVatTrakDayAccountingPlanAccountId
}
    `;
export const GetCompanySettingsDocument = gql`
    query getCompanySettings {
  company {
    ...companySettings
  }
}
    ${CompanySettingsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanySettingsGQL extends Apollo.Query<GetCompanySettingsQuery, GetCompanySettingsQueryVariables> {
    document = GetCompanySettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCompanySettingsDocument = gql`
    mutation updateCompanySettings($companySettings: CompanyTypeHyperionMutationInput) {
  companyTypeHyperionMutation(updateCompany: $companySettings) {
    id
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCompanySettingsGQL extends Apollo.Mutation<UpdateCompanySettingsMutation, UpdateCompanySettingsMutationVariables> {
    document = UpdateCompanySettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }