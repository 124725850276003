import { CreateDay } from './mutations/create-day';
import { CreateDayPartial } from './mutations/create-day-partial';
import { CreateInvoice } from './mutations/create-invoice';
import { CreateProject } from './mutations/create-project';
import { SignUp } from './mutations/sign-up';
import { UpdateInvoiceSerie } from './mutations/update-invoice-serie';
import { UpdateOrder } from './mutations/update-order';

export const mutations = {
  createDay: CreateDay,
  createDayPartial: CreateDayPartial,
  createInvoiceMutation: CreateInvoice,
  createProjectMutation: CreateProject,
  signupMutation: SignUp,
  updateInvoiceSerie: UpdateInvoiceSerie,
  updateOrderMutation: UpdateOrder,
};
