import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';

import { LogsComponent } from './logs/logs.component';
import { HyperionSharedModule } from '../shared/shared.module';

@NgModule({
  imports: [CommonModule, TableModule, HyperionSharedModule],
  declarations: [LogsComponent],
  exports: [LogsComponent],
})
export class CompanyModule {}
