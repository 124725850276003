import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  OnDestroy,
} from '@angular/core';
import { HelperService } from 'app/shared';
import { CompanyUserGlobalMessage } from 'generated/types';
import { first, Subscription } from 'rxjs';
import {
  FetchCompanyNoticesGQL,
  FetchUnreadCompanyNoticesGQL,
  SetAsReadGQL,
} from './graphql/notifications.generated';

@Component({
  selector: 'app-sidebar-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnChanges, OnDestroy {
  @Output() public setNotificationCountEvent = new EventEmitter<number>();
  @Output() public setNotificationOpenEvent = new EventEmitter<boolean>();
  @Input() public isOpen = false;

  public notificationData: CompanyUserGlobalMessage[];
  public unreadNotificationCount = 0;
  private unreadNotifications$: Subscription;

  constructor(
    private helperService: HelperService,
    private fetchCompanyNoticesGQL: FetchCompanyNoticesGQL,
    private fetchUnreadCompanyNoticesGQL: FetchUnreadCompanyNoticesGQL,
    private setAsReadGQL: SetAsReadGQL
  ) {}
  public ngOnDestroy(): void {
    this.unreadNotifications$.unsubscribe();
  }

  public ngOnInit(): void {
    this.getNotifications();
    this.watchUnreadNotifications();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isOpen && changes.isOpen.currentValue) {
      this.getNotifications();
    }
  }

  private getNotifications(): void {
    this.fetchCompanyNoticesGQL.fetch().subscribe(({ data }) => {
      const messages = data?.company?.companyUserGlobalMessages;

      if (!messages) {
        return;
      }

      this.notificationData = this.helperService.cleanFromNode(messages);
    });
  }

  private watchUnreadNotifications(): void {
    this.unreadNotifications$ = this.fetchUnreadCompanyNoticesGQL
      .watch(null, { pollInterval: 60000, errorPolicy: 'ignore' })

      .valueChanges.subscribe(res => {
        const unreadMessagesCount =
          res.data?.company?.unreadCompanyUserGlobalMessages;

        if (unreadMessagesCount === undefined) {
          return;
        }

        this.setUnreadNotificationCount(unreadMessagesCount);
      });
  }

  private setUnreadNotificationCount(count: number): void {
    this.unreadNotificationCount = count;
    this.setNotificationCountEvent.emit(count);
  }

  public markAsRead(): void {
    this.setAsReadGQL
      .mutate()
      .pipe(first())
      .subscribe(res => {
        const totalCount =
          res.data.setAllCompanyUserGlobalMessagesReadMutation
            .unreadCompanyUserGlobalMessages;
        this.getNotifications();
        this.setUnreadNotificationCount(totalCount);
      });
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: HTMLElement): void {
    const notificationsSectionElement = document.getElementsByClassName(
      'notifications-section'
    )[0];

    const clickedInside = notificationsSectionElement.contains(targetElement);

    if (!clickedInside) {
      this.setNotificationOpenEvent.emit(false);
    }
  }
}
