import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { GlobalService } from 'app/shared/global/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { ServiceLocator } from 'app/shared/helpers/index';
import { ProjectContentDialogService } from 'app/old-project/project-content/project-content-dialog.service';
import { ProjectUnderlagDialogService } from 'app/old-project/project-underlag/project-underlag-dialog.service';

@Component({
  templateUrl: 'saved-options.component.html',
})
export class SavedOptionsComponent implements OnInit {
  protected domSanitizer: DomSanitizer;
  public invoice;
  public loading = true;
  public pdfUrl: SafeUrl;
  private invoiceId;

  constructor(
    private globalInvoiceService: GlobalInvoiceService,
    private globalService: GlobalService,
    private htmlModalService: HtmlModalService,
    private mailDialogService: MailDialogService,
    private projectContentDialogService: ProjectContentDialogService,
    private projectUnderlagDialogService: ProjectUnderlagDialogService,
    private router: Router,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {
    this.domSanitizer = ServiceLocator.injector.get(DomSanitizer);
  }

  public ngOnInit(): void {
    if (!this.dialogConfig.data || !this.dialogConfig.data.vars) {
      return;
    }

    this.invoiceId = this.dialogConfig.data.vars.invoice.id;

    this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
      this.globalService.getUrlPrefix() +
        '/invoice/print/?type=showPDF&id=' +
        this.invoiceId
    );

    this.setInvoiceData(this.invoiceId);
  }

  private setInvoiceData(invoiceId) {
    setTimeout(() => {
      this.globalInvoiceService
        .getSingleInvoiceFull(+invoiceId, 'set')
        .then((invoice: any) => {
          if (invoice.contact) {
            this.invoice = invoice;
            this.loading = false;
          }
        });
    }, 1000);
  }

  public showMailModule(): void {
    this.mailDialogService.openMailDialog({ invoiceId: this.invoice.id });
  }

  public getPDF(): void {
    const url =
      this.globalService.getUrlPrefix() +
      '/invoice/print/?type=getPDF&id=' +
      this.invoice.id;
    this.htmlModalService.ny_sida(url, 900, 800);
  }

  public editInvoice(): void {
    const url =
      this.globalService.getUrlPrefix() +
      '/invoice/update/?&id=' +
      this.invoice.id;
    this.htmlModalService.ny_sida(url, 900, 800);
  }

  public openInvoiceBase(): void {
    this.projectUnderlagDialogService.openProjectUnderlag({
      projectInfo: { id: 0, invoiceId: this.invoice.id },
      functionsThisModel: { useUserCostType: true },
    });
  }

  public openProjectDialog(projectId): void {
    this.projectContentDialogService.openProjectContentDialog({ projectId });
  }

  public goToInvoiceIndex(): void {
    this.router.navigate(['/invoice/index']);
    this.dialogRef.close();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }
}
