<form [formGroup]="componentMainForm">
  <div class="p-grid single-document">
    <div class="p-col-8">
      <div class="document-meta p-d-flex p-jc-between">
        <div>{{ dataObjectFromParent.file }}</div>
        <div>
          <a [attr.href]="getDocumentUrl()" target="_blank">
            <i
              class="fa fa-download p-mr-2"
              pTooltip="Hämta"
              tooltipPosition="top"
            ></i>
          </a>
          <a
            [attr.href]="getDocumentPreviewUrl()"
            (click)="openDocument()"
            target="struqtur-preview"
          >
            <i
              class="fa fa-file-o"
              pTooltip="Visa dokument"
              tooltipPosition="top"
            ></i>
          </a>
          <i
            *ngIf="isEditable"
            class="fa fa-edit"
            (click)="editDocument()"
            pTooltip="Redigera"
            tooltipPosition="top"
          ></i>
        </div>
      </div>
      <appForm2-list-input
        placeholder="Kommentar"
        formControlName="comment"
      ></appForm2-list-input>
    </div>
    <div class="p-col-4 p-d-flex p-ai-center p-jc-between">
      <div class="p-d-flex p-ai-center">
        <p-checkbox
          label="Visa för medarbetare"
          formControlName="publicForAllUsers"
        ></p-checkbox>
        <span
          class="help-tooltip smaller"
          style="margin: 0 5px"
          tooltipPosition="top"
          pTooltip="Markera detta val för att visa dokumentet för samtliga medarbetare."
          [escape]="false"
          >?</span
        >
      </div>
      <div>
        <p-checkbox label="Publikt" formControlName="public"></p-checkbox>
        <span
          class="help-tooltip smaller"
          style="margin: 0 5px"
          tooltipPosition="top"
          pTooltip="Markera detta val för att visa dokumentet för de personer som du har delat projektet med."
          [escape]="false"
          >?</span
        >
      </div>
    </div>
    <div class="p-col-12 align-right" style="margin-top: -20px">
      <app-button (click)="confirmDeleteDocument()" type="delete"></app-button>
      <app-button
        class="ml5"
        (click)="actionUpdate()"
        type="update"
      ></app-button>
    </div>
  </div>
</form>
