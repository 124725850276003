<div class="p-grid">
  <div class="p-col-12 p-g-nopad">
    <div class="p-col-12 p-xl-4 calculation-header">
      <label class="form-control-label"
        >Produkter från Derome Webbshop
        <span
          *ngIf="offerInfo.mode === 'create'"
          class="help-tooltip"
          pTooltip="Produkter från Derome webbshop kan inte sparas till offertmallen."
          >?</span
        >
      </label>
    </div>
  </div>

  <p-table [value]="deromeProducts" class="transactionsEditTable">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">Benämning</th>
        <th pSortableColumn="quantity" style="width: 55px">Antal</th>
        <th pSortableColumn="unit" style="width: 75px">Enhet</th>
        <th pSortableColumn="pricePerUnit" style="width: 100px">A-pris</th>
        <th pSortableColumn="tax" style="width: 60px">Moms</th>
        <th pSortableColumn="surcharge" style="width: 40px">
          <span pTooltip="Påslag %">%</span>
        </th>
        <th pSortableColumn="cost" style="width: 100px">Pris</th>
        <th pSortableColumn="order" style="width: 30px">
          <i class="fa fa-sort"></i>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td style="text-align: left">{{ product.name }}</td>
        <td>{{ product.quantity }}</td>
        <td>{{ handleShowUnitLabel(product.unit) }}</td>
        <td>{{ product.cost }}</td>
        <td>
          <p-dropdown
            [options]="taxDropdownOptions"
            [(ngModel)]="+product.tax"
            (ngModelChange)="handleUpdate(product.id)"
          >
          </p-dropdown>
        </td>
        <td>
          <input
            type="text"
            pInputText
            [(ngModel)]="product.procent"
            (blur)="handleUpdate(product.id)"
          />
        </td>
        <td>
          {{ product.rowSumPlusProcent_PreUserCalculationRowTypeHyperion }}
        </td>
        <td>
          <input
            type="text"
            pInputText
            placeholder="Ordning"
            [(ngModel)]="product.order"
            (blur)="handleUpdate(product.id)"
          />
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="derome-webshop-command-bar" *ngIf="!isWebshopOrderCreated">
    <div class="derome-webshop-command">
      <p-button
        label="Skapa produktoffert"
        (onClick)="createPunchOutRequest()"
      ></p-button>
      <p>Klicka ovan för att lägga till produkter i din produktoffert.</p>
    </div>
  </div>
  <div class="derome-webshop-command-bar" *ngIf="isWebshopOrderCreated">
    <div class="derome-webshop-command">
      <p-button
        label="Redigera produktoffert"
        type="button"
        (onClick)="editPunchOutRequest()"
        [disabled]="isWebshopDisabled"
      ></p-button>
      <p *ngIf="!isWebshopDisabled">
        Klicka ovan för att redigera produkterna i din produktoffert.
      </p>
    </div>
    <div class="derome-webshop-command">
      <p-button
        label="Beställ produktoffert"
        type="button"
        (onClick)="checkoutPunchOutRequest()"
        [disabled]="isWebshopDisabled"
      ></p-button>
      <p *ngIf="!isWebshopDisabled">
        Klicka ovan för att beställa produkterna i din produktoffert.
      </p>
    </div>
  </div>
  <p class="derome-webshop-checkout-message" *ngIf="isWebshopOrderCheckedOut">
    Det går inte att redigera din produktoffert då beställningen är lagd. Tack
    för din beställning i Derome Webbshop!
  </p>
  <p class="derome-webshop-checkout-message" *ngIf="isWebshopOrderConfirmed">
    Din beställning är bekräftad och det går inte längre att redigera din
    produktoffert. Tack för din beställning i Derome Webbshop!
  </p>
</div>
