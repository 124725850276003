<form *ngIf="headInfoForm" [formGroup]="headInfoForm" class="p-grid">
  <!-- CHECK BOXES -->
  <div class="p-col-12 p-lg-2">
    <appForm2-list-checkbox
      formControlName="typeLayout"
    ></appForm2-list-checkbox>
    <appForm2-list-checkbox
      formControlName="kreditfaktura"
    ></appForm2-list-checkbox>
    <appForm2-list-checkbox
      formControlName="invoiceReminder"
    ></appForm2-list-checkbox>
    <appForm2-list-checkbox
      formControlName="projectInvoiceHistory"
    ></appForm2-list-checkbox>
  </div>
  <!-- END CHECK BOXES -->

  <div class="p-col-12 p-lg-4 p-grid">
    <span class="p-col-12">
      <label>Vår referens</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input pInputText formControlName="our_reference" />
      </span>
    </span>
    <span class="p-col-12">
      <label>Er referens</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input pInputText formControlName="your_reference" />
      </span>
    </span>
  </div>

  <div class="p-col-12 p-lg-3 p-grid">
    <span class="p-col-12">
      <label>Ert ordernummer</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-hashtag"></i>
        </span>
        <input pInputText formControlName="your_order_nr" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Dröjmålsränta</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-percentage"></i>
        </span>
        <input pInputText formControlName="interest" />
      </span>
    </span>
  </div>

  <div class="p-col-12 p-lg-3 p-grid">
    <span class="p-col-12">
      <label>Övrigt</label>

      <textarea pInputTextarea [rows]="7" formControlName="ovrigt"></textarea>
    </span>
  </div>
</form>
