import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription, Subject, first } from 'rxjs';
import { Table } from 'primeng/table';

import { HyperionLabelsService } from 'app/shared/labels/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { SortService } from 'app/store/sort.service';

import { HelperService } from 'app/shared/helpers';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { ContactDetailComponent } from 'app/contact/contact-detail/contact-detail.component';
import { CustomSort } from 'app/store/custom-sort';

import { ContactFormContext } from 'app/contact/contact-form/contact-form.component';
import { ConfirmationService } from 'primeng/api';
import { HttpService } from 'app/shared/http';
import { MessageService } from 'app/shared/message';

const constDataModel = 'companyContacts';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
  providers: [AppDialogService],
})
export class ContactListComponent implements OnInit, OnDestroy {
  dataModel = constDataModel;

  loading = true;
  showAdvancedSearch = false;
  dialogHeader: string = null;
  state = {
    create: true,
    id: null,
  };
  labelListArray: string[] = [
    'trueId',
    'orderBuisnessName',
    'name',
    'address',
    'address2',
    'cityCode',
    'mobilePhone',
    'mail',
    'city',
    'plusgiro',
    'bankgiro',
    'orgNr',
  ];

  label: any = {};
  type = 'costumer';
  typeString = 'kund';
  companyContactSub;
  totalCount: any = '....';
  cols: any[] = [];
  dataSetAsync: BehaviorSubject<any> = new BehaviorSubject([]);
  @ViewChild('customerSupplierTable', { static: true })
  customerSupplierTable: Table;
  sort: CustomSort = {
    attribute: 'dummy',
    ascDesc: -1,
    object: 'dummy',
  };
  contactSub: Subscription;

  public loadInvoicesDataSubject: Subject<void> = new Subject<void>();
  public loadProjectsDataSubject: Subject<void> = new Subject<void>();

  public totalInvoices = {};
  public totalProjects = {};

  public contactFormContext = ContactFormContext;
  public showForm = false;
  public selectedContactId: number | null = null;

  constructor(
    private labelsService: HyperionLabelsService,
    private apolloQueryService: ApolloQueryService,
    public helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private sortService: SortService,
    private confirmationService: ConfirmationService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.data.type;

    if (this.type === 'supplier') {
      this.typeString = 'leverantör';
    } else {
      this.type = 'costumer';
      this.typeString = 'kund';
    }
    this.dialogHeader = 'Skapa ny ' + this.typeString;

    this.sort = {
      attribute: 'trueId',
      ascDesc: -1,
      object: 'settings_' + this.type + '_table',
    };

    this.sort = this.sortService.getSort(this.sort);

    this.getLabel();

    this.customerSupplierTable.globalFilterFields = this.labelListArray;
  }

  private getLabel() {
    this.labelsService.getLabels([{ model: 'Contact' }]).subscribe(labels => {
      this.labelListArray.forEach(list => {
        labels['Contact'][list] && (this.label[list] = labels['Contact'][list]);
      });
      // Initiate primeng table view
      this.cols = [
        { field: 'trueId', header: 'Nummer', width: '8rem' },
        { field: 'name', header: labels['Contact']['name'], width: 'auto' },
        {
          field: 'orderBuisnessName',
          header: labels['Contact']['orderBuisnessName'],
          width: 'auto',
        },
        {
          field: 'mobilePhone',
          header: labels['Contact']['mobilePhone'],
          width: '9rem',
        },
        { field: 'mail', header: labels['Contact']['mail'], width: 'auto' },
        { field: 'city', header: labels['Contact']['city'], width: '6rem' },
      ];
      this.getContacts();
    });
  }

  getContacts() {
    const q =
      this.type === 'supplier' ? 'supplierContacts' : 'costumerContacts';

    this.apolloQueryService
      .apolloWatchQueryTwo(q, {}, 'network-only')
      .subscribe(({ data, sub }) => {
        this.contactSub = sub;
        this.totalCount = data.company.contacts.totalCount;
        this.dataSetAsync.next(
          this.apolloQueryService.cleanFromNode(data.company.contacts)
        );
        this.loading = false;
      });
  }

  resetExpandedRows = () =>
    this.customerSupplierTable &&
    (this.customerSupplierTable.expandedRowKeys = {});

  exportExcel() {
    location.href = '/contact/export';
  }

  resetSearchParams() {
    this.customerSupplierTable.reset();
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  /**
   * Triggers a reload of data on click
   * @param event contains the index of the tab in focus
   */
  public handleTabChange(event) {
    enum TABS {
      CONTACT_DETAILS = 0,
      INVOICES = 1,
      PROJECTS = 2,
    }
    switch (event.index) {
      case TABS.CONTACT_DETAILS:
        break;
      case TABS.INVOICES:
        this.loadInvoicesDataSubject.next();
        break;
      case TABS.PROJECTS:
        this.loadProjectsDataSubject.next();
        break;
    }
  }

  openCreateContactDialog() {
    this.selectedContactId = null;
    this.showForm = true;
  }

  changeSort(event) {
    this.sortService.setSort(event, this.sort);
  }

  public naturalSortOrder(event: any): void {
    event.data.sort((data1, data2) => {
      const a = data1[event.field];
      const b = data2[event.field];

      if (!a) {
        return;
      }

      return (
        a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base',
        }) * event.order
      );
    });
  }

  public onContactDeleted(contactId: number): void {
    const contacts = this.dataSetAsync.value;
    const filteredContacts = contacts.filter(
      contact => Number(contact.id) !== contactId
    );
    this.dataSetAsync.next(filteredContacts);

    this.resetExpandedRows();
    this.getContacts();
  }

  public onContactUpdated(id: number): void {
    this.showForm = false;
    this.getContacts();
    this.updateContactsInProjectPromt(id);
  }

  private updateContactsInProjectPromt(id: number): void {
    const url = '/contact/updateRelated/' + id;
    if (this.type === 'supplier') {
      return;
    }

    this.confirmationService.confirm({
      message:
        'Vill du även uppdatera de projekt och offerter med knytning till ' +
        this.typeString +
        'en?',
      header: 'Bekräfta val',
      icon: 'fa fa-save',
      accept: () => {
        this.updateContactsInProjectAccept(url);
      },
      reject: () => {},
    });
  }

  private updateContactsInProjectAccept(url: string): void {
    this.httpService.makeHttpPostRequest(url).subscribe(data => {
      const status = data['status'];
      const msg = data['msg'];

      this.messageService.insertData({ textArray: [msg], type: status });
    });
  }

  ngOnDestroy() {
    this.contactSub && this.contactSub.unsubscribe();
  }
}
