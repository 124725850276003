import * as Types from '../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RouteUserCompanyPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RouteUserCompanyPermissionsQuery = { __typename?: 'HyperionSchemaQuery', me?: { __typename?: 'User', id: string, type?: number | null, isSuperAdmin?: boolean | null, userHasCompanyUsers?: { __typename?: 'CompanyUserHasCompanyUserConnection', edges?: Array<{ __typename?: 'CompanyUserHasCompanyUserEdge', node?: { __typename?: 'CompanyUserHasCompanyUser', id: string } | null } | null> | null } | null } | null, appParams?: { __typename?: 'AppParrams', getenvStruqturMode?: string | null } | null, company?: { __typename?: 'Company', id: string, functions?: { __typename?: 'CompanyFunctionsConnection', edges?: Array<{ __typename?: 'CompanyFunctionsEdge', node?: { __typename?: 'CompanyFunctions', function?: string | null } | null } | null> | null } | null } | null };

export const RouteUserCompanyPermissionsDocument = gql`
    query routeUserCompanyPermissions {
  me {
    id
    type
    isSuperAdmin
    userHasCompanyUsers {
      edges {
        node {
          id
        }
      }
    }
  }
  appParams {
    getenvStruqturMode
  }
  company {
    id
    functions {
      edges {
        node {
          function
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RouteUserCompanyPermissionsGQL extends Apollo.Query<RouteUserCompanyPermissionsQuery, RouteUserCompanyPermissionsQueryVariables> {
    document = RouteUserCompanyPermissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }