import { gql } from 'apollo-angular';

import { MutationDetails } from './common';

export const CreateProject = gql`
  mutation createProjectMutation(
    $createProject: ProjectTypeHyperionMutationInput
    $createProjectEstablishmentContact: ContactTypeHyperionMutationInput
    $createProjectClientContact: ContactTypeHyperionMutationInput
  ) {
    projectTypeHyperionMutation(
      createProject: $createProject
      createProjectEstablishmentContact: $createProjectEstablishmentContact
      createProjectClientContact: $createProjectClientContact
    ) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
      id
      status
      trueId
    }
  }
  ${MutationDetails.fragments.attributes}
`;
