import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetOffersQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
}>;


export type GetOffersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', offers?: { __typename?: 'OfferConnection', edges?: Array<{ __typename?: 'OfferEdge', node?: { __typename?: 'Offer', id: string, trueId?: string | null, concerning?: string | null } | null } | null> | null } | null } | null };

export type DropdownOfferFragment = { __typename?: 'Offer', id: string, trueId?: string | null, concerning?: string | null };

export const DropdownOfferFragmentDoc = gql`
    fragment dropdownOffer on Offer {
  id
  trueId
  concerning
}
    `;
export const GetOffersDocument = gql`
    query getOffers($status: [Int]) {
  company {
    offers(status: $status) {
      edges {
        node {
          ...dropdownOffer
        }
      }
    }
  }
}
    ${DropdownOfferFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOffersGQL extends Apollo.Query<GetOffersQuery, GetOffersQueryVariables> {
    document = GetOffersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }