import { Apollo } from 'apollo-angular';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { detect } from 'detect-browser';
import {
  CompanyAppParamsService,
  CompanyFunctionsService,
} from './shared/company/index';
import { GlobalService } from './shared/global/index';
import {
  Angulartics2GoogleGlobalSiteTag,
  Angulartics2GoogleTagManager,
} from 'angulartics2';
import { UserLocalStorageService } from './shared/user';

import { DOCUMENT } from '@angular/common';
import { ConfigService } from 'app/shared/config/config.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.html',
})
export class AppComponent {
  public themeLoading = true;
  public supported = true; // Check for older browsers

  constructor(
    protected router: Router,
    private globalService: GlobalService,
    private companyFunctionsService: CompanyFunctionsService,
    private angulartics2GoogleGlobalSiteTag: Angulartics2GoogleGlobalSiteTag,
    private userLocalStorageService: UserLocalStorageService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private companyAppParamsService: CompanyAppParamsService,
    protected configService: ConfigService,
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private document: Document,
    protected apollo: Apollo
  ) {
    this.gtmService.addGtmToDom();
    this.angulartics2GoogleGlobalSiteTag.startTracking();
    this.angulartics2GoogleTagManager.startTracking();

    this.setTheme();

    const browser = detect();
    switch (browser && browser.name) {
      case 'ie':
        this.supported = false; // minimum ie 11
        break;
    }
  }

  private setTheme(): void {
    this.themeLoading = true;
    const config = this.configService.getConfig();
    const theme = config.theme;

    this.document.body.classList.add(config.name);

    const rootStyle = this.document.documentElement.style;
    rootStyle.setProperty('--primary-color-100', theme.primaryColor100);
    rootStyle.setProperty('--primary-color-200', theme.primaryColor200);
    rootStyle.setProperty('--primary-color-300', theme.primaryColor300);
    rootStyle.setProperty('--primary-color-400', theme.primaryColor400);
    rootStyle.setProperty('--primary-color-500', theme.primaryColor500);
    rootStyle.setProperty('--primary-color-600', theme.primaryColor600);
    rootStyle.setProperty('--link-color', theme.linkColor);
    rootStyle.setProperty(
      '--login-background',
      `url(\'${theme.loginBackground}\')`
    );
    rootStyle.setProperty('--brand-primary-color', theme.brandPrimaryColor);
    rootStyle.setProperty('--brand-secondary-color', theme.brandSecondaryColor);
    rootStyle.setProperty('--top-menu-color', theme.topMenuColor);
    rootStyle.setProperty('--top-menu-hover-color', theme.topMenuHoverColor);
    rootStyle.setProperty('--top-menu-box-shadow', theme.topMenuBoxShadowColor);
    rootStyle.setProperty(
      '--top-menu-background-color',
      theme.topMenuBackgroundColor
    );
    rootStyle.setProperty(
      '--top-menu-background-active-color',
      theme.topMenuBackgroundActiveColor
    );
    rootStyle.setProperty(
      '--top-menu-logo-max-height',
      theme.topMenuLogoMaxHeight
    );
    rootStyle.setProperty(
      '--top-menu-text-transform',
      theme.topMenuTextTransform
    );
    rootStyle.setProperty('--top-menu-font-weight', theme.topMenuFontWeight);

    rootStyle.setProperty('--home-start-dark-color', theme.homeStartDarkColor);
    rootStyle.setProperty(
      '--home-start-light-color',
      theme.homeStartLightColor
    );
    rootStyle.setProperty('--btn-primary-color', theme.buttonPrimaryColor);
    rootStyle.setProperty(
      '--btn-primary-color-dark',
      theme.buttonPrimaryColorDark
    );
    rootStyle.setProperty('--btn-success-color', theme.buttonSuccessColor);
    rootStyle.setProperty('--btn-info-color', theme.buttonInfoColor);
    rootStyle.setProperty('--btn-warning-color', theme.buttonWarningColor);
    rootStyle.setProperty('--btn-danger-color', theme.buttonDangerColor);
    rootStyle.setProperty('--header-font-family', theme.headerFontFamily);
    rootStyle.setProperty('--font-family', theme.fontFamily);
    rootStyle.setProperty('--header-weight', theme.headerWeight);

    this.setFavicons();

    this.themeLoading = false;
  }

  private setFavicons(): void {
    const head = this.document.getElementsByTagName('head')[0];
    const config = this.configService.getConfig();
    const theme = config.theme;

    // Remove existing favicons to avoid duplicates
    const favicons = head.querySelectorAll('.favicons');
    favicons.forEach(favicon => {
      favicon.remove();
    });

    theme.favicons.forEach(favicon => {
      const link = this.document.createElement('link');
      link.setAttribute('class', 'favicons');
      link.setAttribute('rel', favicon.rel);
      link.setAttribute('href', favicon.href);

      if (favicon.sizes) {
        link.setAttribute('sizes', favicon.sizes);
      }

      if (favicon.color) {
        link.setAttribute('color', favicon.color);
      }

      if (favicon.type) {
        link.setAttribute('type', favicon.type);
      }

      head.appendChild(link);
    });
  }
}
