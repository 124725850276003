import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { FormHandlerService } from '../../shared/forms/index';

@Component({
  selector: 'app-docu-signup-dialog',
  templateUrl: './docu-signup-dialog.component.html',
  styleUrls: ['./docu-signup-dialog.component.scss'],
  providers: [FormHandlerService],
})
export class DocuSignupDialogComponent {
  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.dialogConfig.header = 'Skapa konto gratis';
  }

  done(success: boolean) {
    this.dialogRef.close(success);
  }
}
