import {
  ChangeDetectorRef,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Component, Input, Output } from '@angular/core';
import {
  DeleteImageEvent,
  RotateImageEvent,
  UpdateImageEvent,
} from './image-events';
import { Image } from './image';
import { DataView } from 'primeng/dataview';
import { Observable } from 'rxjs';

@Component({
  selector: 'image-gallery',
  templateUrl: 'image-gallery-prime.component.html',
  styleUrls: ['image-gallery-prime.component.scss'],
})
export class ImageGalleryComponent implements OnChanges {
  public get fullScreenOpen(): boolean {
    return this.isFullScreenOpen;
  }
  public set fullScreenOpen(value: boolean) {
    this.isFullScreenOpen = value;
    if (value === false) {
      this.commentEditable = false;
    }
  }

  @Input() public totalImages: number;
  @Input() public images: Image[] = [];
  @Input() public isLoading: boolean;
  @Input() public readonly = false;
  @Input() public lazy = true;

  @Output() public imageRotateToParent = new EventEmitter();
  @Output() public imageUpdateToParent = new EventEmitter();
  @Output() public imageDeleteToParent = new EventEmitter();
  @Output() public fetchImages = new EventEmitter();

  @ViewChild(DataView) public dataview: DataView;

  private isFullScreenOpen = false;
  public selectedIndex = null;
  public commentEditable = false;
  public imageRotateLoading = [];
  public allThumbnailsPrefetched = false;
  public preloadedImages = {};
  private fullscreenEventListener: any;

  constructor(private ref: ChangeDetectorRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.images &&
      changes.images.currentValue !== changes.images.previousValue
    ) {
      this.allThumbnailsPrefetched = false;
      changes.images.currentValue.forEach(image =>
        this.prefetchThumbnail(image)
      );
    }
  }

  public openFullscreen(image: Image): void {
    const index = this.images.indexOf(image);
    this.fullScreenOpen = true;
    this.selectedIndex = index;
    this.commentEditable = false;
    this.prefetchLargeImage(image);
    this.prefetchPreviousAndNextImage(index);
    this.bindFullScreenListeners();
  }

  public closeFullscreen(): void {
    this.fullScreenOpen = false;
    this.ref.detectChanges();
    this.unbindFullScreenListeners();
  }

  public onActiveIndexChanged(activeIndex): void {
    this.selectedIndex = activeIndex;
    const image = this.images[activeIndex];
    image.largeVersionLoaded = true;
    this.prefetchLargeImage(image);
  }

  private prefetchLargeImage(image: Image): void {
    if (this.preloadedImages[+image.id]) {
      image.largeVersionLoaded = true;
      this.ref.detectChanges();
    } else {
      image.largeVersionLoaded = false;
    }

    this.prefetchImage(image.largerImage, () => {
      image.largeVersionLoaded = true;
      this.preloadedImages[+image.id] = true;
      this.ref.detectChanges();
    });
  }

  private prefetchPreviousAndNextImage(index: number): void {
    if (index > 0) {
      const previousImage = this.images[index - 1];
      this.prefetchImage(previousImage.largerImage, () => {
        this.preloadedImages[+previousImage.id] = true;
      });
    }
    if (index < this.images.length - 1) {
      const nextImage = this.images[index + 1];
      this.prefetchImage(nextImage.largerImage, () => {
        this.preloadedImages[+nextImage.id] = true;
      });
    }
  }

  private prefetchThumbnail(image: Image): void {
    image.thumbnailLoaded = false;
    this.prefetchImage(image.thumbnail, (success: boolean) => {
      image.thumbnailLoaded = true;
      this.allThumbnailsPrefetched = this.images.every(i => i.thumbnailLoaded);
      if (this.allThumbnailsPrefetched) {
        this.ref.detectChanges();
      }
    });
  }

  private prefetchImage(
    path: string,
    callback: (success?: boolean) => void
  ): void {
    const imageLoader = new Image();
    imageLoader.onload = () => callback(true);
    imageLoader.onerror = () => callback(false);
    imageLoader.src = path;
  }

  public updateDisplayOnPrint(event, image): void {
    this.imageUpdateEmit(image.id, image.comment, event.checked);
  }

  public imageIsRotating(id): boolean {
    return this.imageRotateLoading.some(imageId => imageId === id);
  }

  public rotateImage(image, directionParam): void {
    const degreesToObj = directionParam === 'right' ? '90' : '-90';

    this.imageRotateLoading.push(image.id);

    const eventObject: RotateImageEvent = {
      id: image.id,
      degrees: degreesToObj,
    };

    this.imageRotateToParent.emit(eventObject);

    const uri = new URL(image.thumbnail);
    uri.hash = 'reloading';
    image.thumbnail = uri.toString();
  }

  public handleRotatedImage(executedData): void {
    const image = executedData;

    const loadingIndex = this.imageRotateLoading.findIndex(
      i => +i === image.id
    );
    if (loadingIndex != null) {
      this.imageRotateLoading.splice(loadingIndex, 1);
    }

    this.loadImages({
      rows: this.dataview.rows,
      first: this.dataview.first,
    });
  }

  public handleDeletedImage(imageId, executedData): void {
    this.loadImages({
      rows: this.dataview.rows,
      first: this.dataview.first,
    });
  }

  public handleErrorsInMutations(executedData): void {}

  public handleNewImages(newImages): void {
    this.loadImages({
      rows: this.dataview.rows,
      first: this.dataview.first,
    });
  }

  public imageDeleteEmit(idParam): void {
    const eventObject: DeleteImageEvent = {
      id: idParam,
    };

    this.imageDeleteToParent.emit(eventObject);
  }

  public imageUpdateEmit(
    idParam: number,
    commentParam: string,
    displayOnPrintParam: boolean
  ): void {
    const eventObject: UpdateImageEvent = {
      id: idParam,
      comment: commentParam,
      displayOnPrint: displayOnPrintParam ? '1' : '0',
    };
    this.imageUpdateToParent.emit(eventObject);
    this.commentEditable = false;
  }

  public loadImages(event): void {
    this.fetchImages.emit({
      take: event.rows,
      skip: event.first,
    });
  }

  private bindFullScreenListeners(): void {
    this.fullscreenEventListener = (event: KeyboardEvent | MouseEvent) => {
      function isKeyEvent(
        evt: KeyboardEvent | MouseEvent
      ): evt is KeyboardEvent {
        return evt['key'];
      }

      if (isKeyEvent(event)) {
        const key = event.key;
        switch (key) {
          case 'Escape': {
            this.closeFullscreen();
            break;
          }
          case 'ArrowRight': {
            this.nextIndex();
            break;
          }
          case 'ArrowLeft': {
            this.prevIndex();
            break;
          }
        }
      } else {
        const target = event.target as Element;
        if (target.classList.contains('p-galleria-mask')) {
          this.closeFullscreen();
        }
      }
    };
    document.addEventListener('keydown', this.fullscreenEventListener);
    document.addEventListener('mousedown', this.fullscreenEventListener);
  }

  private nextIndex(): void {
    if (this.selectedIndex < this.images.length - 1) {
      this.onActiveIndexChanged(this.selectedIndex + 1);
    }
  }

  private prevIndex(): void {
    if (this.selectedIndex > 0) {
      this.onActiveIndexChanged(this.selectedIndex - 1);
    }
  }

  private unbindFullScreenListeners(): void {
    document.removeEventListener('keydown', this.fullscreenEventListener);
    document.removeEventListener('mousedown', this.fullscreenEventListener);
  }
}
