import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of as observableOf } from 'rxjs';
import { Table } from 'primeng/table';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloQueryService } from 'app/shared/apollo';
import { HyperionLabelsService } from 'app/shared/labels/index';
import { SortService } from 'app/store/sort.service';
import {
  CompanyUserCostTypeService,
  ProjectTodosService,
} from 'app/shared/company';
import { CompanyUsersService, UserLocalStorageService } from 'app/shared/user';
import { CustomSort } from 'app/store/custom-sort';

@Component({
  selector: 'project-child',
  templateUrl: 'project-child.component.html',
})
export class ProjectChildComponent implements OnInit, OnDestroy {
  @Input() projectId;
  @ViewChild('customerSupplierTable')
  customerSupplierTable: Table;
  loading = true;
  showAdvancedSearch = false;
  hasShared = false;
  hasSharedAndConnectionsAccepted = false;
  projectInfo = {};
  userSub: Subscription;
  childProjectsSub: Subscription;
  userCostTypesSub: Subscription;
  projectTodosSub: Subscription;
  dataSet = [];
  sort: CustomSort = {
    attribute: 'created',
    ascDesc: -1,
    object: 'project_child_timereports_table',
  };
  cols = {};
  dropDownOptions = {
    userIdDropDown: {},
    todoIdDropDown: {},
    newCostTypeIdDropDown: {},
  };
  userCostTypes: any;
  usersDropdown: any;
  tableBtns = {};
  labelSub: any;

  constructor(
    private sortService: SortService,
    private usersService: CompanyUsersService,
    private userLocalStorageService: UserLocalStorageService,
    private labelsService: HyperionLabelsService,
    private apolloQueryService: ApolloQueryService,
    private projectTodosService: ProjectTodosService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private companyUserCostTypeService: CompanyUserCostTypeService
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.projectId = this.dialogConfig['data']['projectId'];
      setTimeout(() => {
        this.dialogConfig.header = 'Samarbetspartners data';
      }, 0);
    }
    this.setDropdownValues();

    this.sort = this.sortService.getSort(this.sort);

    this.queryProjectChild();
  }

  private queryProjectChild() {
    this.apolloQueryService
      .apolloWatchQueryTwo('projectSubcontractorsRelations', {
        multiId: [+this.projectId],
      })
      .subscribe(ret => {
        const data = ret.data;
        const sub = ret.sub;
        this.apolloQueryService
          .cleanFromNode(data.company.projects)
          .map(project => {
            this.projectInfo = {
              id: +project['id'],
              trueId: project['trueId'],
              mark: project['mark'],
            };
            this.hasShared =
              this.apolloQueryService.cleanFromNode(
                project['subcontractorsRelations']
              ).length > 0;
          });
        this.setTableHeader();
        sub.unsubscribe();
      });
  }

  private setTableHeader() {
    this.labelSub = this.labelsService
      .getLabels([{ model: 'Day' }])
      .subscribe(labels => {
        this.cols = [
          {
            field: 'created',
            header: labels['Day']['created'],
            width: '150px',
          },
          { field: 'userFullName', header: 'Namn', width: '20%' },
          {
            field: 'doneWork',
            header: labels['Day']['doneWork'],
            width: 'auto',
          },
          { field: 'userId', header: labels['Day']['userId'], width: 'auto' },
          { field: 'todoId', header: labels['Day']['todoId'], width: 'auto' },
          {
            field: 'newCostTypeId',
            header: labels['Day']['newCostTypeId'],
            width: 'auto',
          },
          { field: 'extra', header: labels['extra'], width: '90px' },
          {
            field: 'hoursToInvoice',
            header: labels['Day']['hoursToInvoice'],
            width: '90px',
          },
          {
            field: 'mileToInvoice',
            header: labels['Day']['mileToInvoice'],
            width: '90px',
          },
          { field: 'markAsSaved', header: 'Spara', width: '90px' },
        ];
        this.getTimereports();
      });
  }

  setDropdownValues(): void {
    this.getTodos();
    this.getUserCostTypes();
    this.getUsersOption();
  }

  getUserCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUserCostType')
      .subscribe(({ data, sub }) => {
        this.userCostTypesSub = sub;
        const dropDownVal = this.companyUserCostTypeService.dataFormater(
          data,
          'labels'
        );
        dropDownVal.unshift({ label: 'Välj yrkestyp', value: null });
        this.dropDownOptions['newCostTypeIdDropDown']['options'] = dropDownVal;
        this.dropDownOptions['newCostTypeIdDropDown']['filter'] = true;
      });
  }

  getUsersOption() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.userSub = sub;
        this.dropDownOptions['userIdDropDown']['options'] =
          this.usersService.makeLabelsArray(data);
        this.dropDownOptions['userIdDropDown']['filter'] = true;
      });
  }

  getTodos() {
    const variables = { id: +this.projectId };
    this.apolloQueryService
      .apolloWatchQueryTwo('projectTodos', variables)
      .subscribe(data => {
        this.projectTodosSub = data.sub;
        const todo = this.projectTodosService.dataFormater(data.data, 'labels');
        this.dropDownOptions['todoIdDropDown']['options'] = todo;
        this.dropDownOptions['todoIdDropDown']['filter'] = true;
      });
  }

  getTimereports() {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'childProjects',
        { id: this.projectId },
        'cache-and-network'
      )
      .subscribe(({ data, sub }) => {
        this.childProjectsSub = sub;
        const projects = this.apolloQueryService.cleanFromNode(
          data.company.projects
        );
        if (projects[0]['childs']) {
          const childs = this.apolloQueryService.cleanFromNode(
            projects[0]['childs']
          );
          childs.map(child => {
            if (
              child['days'] &&
              +child['subcontractorRelationToProject'][
                'clientSharesTimereports'
              ] === 1
            ) {
              const days = this.apolloQueryService
                .cleanFromNode(child['days'])
                .filter(
                  day =>
                    +day.timeHour === 1 &&
                    (+day.notarized > 0 ||
                      +child['subcontractorRelationToProject'][
                        'clientSharesTimereportsWithoutNotarized'
                      ] === 1)
                );

              child['days'] = days.map(day => {
                day['userId'] = +this.userLocalStorageService.getMEUser().id;
                day['userFullName'] =
                  day['user']['firstName'] + ' ' + day['user']['lastName'];

                const alreadySavedToProject =
                  day['exportedToSubcontractorRelation'] !== null;

                this.tableBtns = {
                  ...this.tableBtns,
                  ...this.setTableBtns(day['id'], alreadySavedToProject),
                };

                day['savedByUser'] = this.setSavedByInfo(
                  alreadySavedToProject,
                  day['exportedToSubcontractorRelation']
                );

                return day;
              });
            } else {
              child['days'] = [];
            }
            return child;
          });

          this.dataSet = childs;
          this.hasSharedAndConnectionsAccepted =
            (this.hasShared && this.dataSet.length > 0) ||
            this.hasSharedAndConnectionsAccepted;
        }
        this.loading = false;
      });
  }

  setSavedByInfo = (infoIsSaved: boolean, savedInfo): any => ({
    show: infoIsSaved,
    savedBy: infoIsSaved
      ? { created: savedInfo['created'], ...savedInfo['savedByUser'] }
      : null,
  });

  setTableBtns = (key: string, value: boolean): any => ({
    ['row' + key]: value,
  });

  changeSort = event => this.sortService.setSort(event, this.sort);

  resetSearchParams() {
    this.customerSupplierTable.reset();
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  closeAction() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.userSub && this.userSub.unsubscribe();
    this.labelSub && this.labelSub.unsubscribe();
    this.projectTodosSub && this.projectTodosSub.unsubscribe();
    this.childProjectsSub && this.childProjectsSub.unsubscribe();
    this.userCostTypesSub && this.userCostTypesSub.unsubscribe();
  }
}
