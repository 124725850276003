import { Component, Input, OnInit } from '@angular/core';
import { CompanyFunctionsService } from 'app/shared/company';
import { UserFlagsService } from 'app/user-flags.service';
import { first } from 'rxjs';
import {
  CostCalculationMode,
  LaborCosts,
} from '../project-overview/project-overview.service';
import { ShowSubsistenceGQL } from './graphql/showSubsistence.generated';

@Component({
  selector: 'app-project-overview-labor',
  templateUrl: './project-overview-labor.component.html',
  styleUrls: ['./project-overview-labor.component.scss'],
})
export class ProjectOverviewLaborComponent implements OnInit {
  @Input() public costs: LaborCosts;
  @Input() public type: any;
  public setMile = true;

  public CostCalculationMode = CostCalculationMode;
  public showSubsistenceOnTimereport: boolean;

  constructor(
    private showSubsistenceService: ShowSubsistenceGQL,
    private functionsService: CompanyFunctionsService
  ) {}

  public ngOnInit(): void {
    this.showSubsistenceService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        this.showSubsistenceOnTimereport = Boolean(
          res.data.company.showSubsistenceOnTimereport
        );
      });
    this.setMile = this.functionsService.companyFunctionIsSet('setPrivMile');
  }
}
