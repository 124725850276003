<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading" [class.page-container]="!isModal">
  <p-card>
    <div class="p-grid">
      <div class="p-grid p-col-12 show-on-large-device p-g-nopad">
        <div class="p-col-5">Prefix</div>
        <div class="p-col-5">Namn</div>
      </div>

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="
          let dataObjectFromParent of dataSet;
          trackBy: helperService.rowTrackBy
        "
      >
        <div>
          <edit-project-type
            [dataObjectFromParent]="dataObjectFromParent"
          ></edit-project-type>
        </div>
      </div>
      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let createComponent of createDataObjectArray"
      >
        <create-project-type
          (dataObjectDeleted)="spliceDeletedDataObject(createComponent)"
          (createSibling)="addCreateComponent(createComponent)"
        ></create-project-type>
      </div>
    </div>
  </p-card>
</div>
