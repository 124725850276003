import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  OnInit,
  AfterViewInit,
  SimpleChanges,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  LOCALE_ID,
  Inject,
} from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';

import { HelperService } from '../../shared/helpers/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { MessageService } from '../../shared/message/index';
import { FormHandlerService } from '../../shared/forms/index';
import { CompanyUsersService, UserLocalStorageService } from 'app/shared/user';
import { ProjectTodosService } from '../../shared/company/index';
import { SortService } from 'app/store/sort.service';
import * as moment from 'moment';
import { CustomSort } from 'app/store/custom-sort';
import { MeUser } from 'app/shared/user/me-user';
import { GlobalService } from 'app/shared/global';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { formatNumber } from '@angular/common';

@Component({
  selector: 'project-day',
  templateUrl: 'project-day.component.html',
  styleUrls: ['project-day.component.scss'],
  providers: [FormHandlerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDayComponent
  implements OnDestroy, OnInit, OnChanges, AfterViewInit
{
  @Input() projectLabels;
  @Input() projectInfo;
  @Input() isExtra = false;
  @Input() daysFromParent;
  @Input() state = { width: false, height: false };
  @Input() functionsThisModel;
  @Input() functionsData;
  @Input() useNotarized = false;
  @Input() projectInvoiceData = {};
  @Input() dataLoading: boolean;
  @Output() toggleDay = new EventEmitter();
  @Output() callGetInvoiceData = new EventEmitter();
  @Output() handleDeletedDay = new EventEmitter();

  @ViewChildren('projectDaysTable') datatable: QueryList<Table>;
  projectDaysTable: Table;
  fieldsForForm;
  scrollHeight = '450px';
  tableRows = 25;
  usersDropdown;
  projectTodosSub: Subscription;
  descId: string;
  public meUser: MeUser;
  dataSet = { daysData: [], daysTimeHour2Data: [] };
  formInitLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dataSetAsync: BehaviorSubject<any> = new BehaviorSubject([]);
  showTable: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showDaysSummary = false;
  public showPagination = false;
  editDayWithId = null;
  userCostTypeDialog = {
    show: false,
    day: null,
    oldCostType: null,
    newCostType: null,
  };
  projectTodosDropdown = [];
  toggleTodoDropdown: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public useReportingWithTimestamps: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  private userSub: Subscription;

  public buttons;
  public componentMainForm: ExtendedFormGroup;
  private dataModel = 'day';
  private dataModelCapitalized = 'Day';
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: {},
    attrToExcludeFromRulesAndLabel: [''],
  };
  th1Data = {};

  sort: CustomSort = {
    attribute: 'date',
    ascDesc: -1,
    object: 'project_day_table',
  };

  public componentPrefix: number;

  constructor(
    @Inject(LOCALE_ID) public localeId: string,
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private usersService: CompanyUsersService,
    private messageService: MessageService,
    public helperService: HelperService,
    private confirmationService: ConfirmationService,
    private projectTodosService: ProjectTodosService,
    private apolloQueryService: ApolloQueryService,
    private cdr: ChangeDetectorRef,
    private sortService: SortService,
    private userLocalstorageService: UserLocalStorageService,
    private globalService: GlobalService
  ) {
    this.meUser = this.userLocalstorageService.getMEUser();
    this.sort = this.sortService.getSort(this.sort);
    this.componentPrefix = this.globalService.generateComponentPrefix();
  }

  public changeSort = event => this.sortService.setSort(event, this.sort);

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      this.scrollHeight =
        this.state.width || this.state.height ? '450px' : '250px';
    }
    if (changes.daysFromParent && this.daysFromParent !== undefined) {
      this.summarizeTH2ofTH1(this.daysFromParent.daysTimeHour2Data);
      this.loading.next(false);
    }
  }

  public ngOnInit(): void {
    this.setUseOnlyTimestampTimeReport();
    this.descId =
      this.projectInfo.id +
      (this.isExtra ? 'extra' + 'DayDesc' : 'normal' + 'DayDesc');

    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);

    this.formFields.attributes = {
      date: moment().toDate(),
      doneWork: '',
      hours: null,
      mile: '',
      userId: +this.meUser.id,
      todoId: '',
    };

    if (this.useReportingWithTimestamps.value) {
      this.formFields.attributes = {
        ...this.formFields.attributes,
        startTime: moment().toDate(),
        stopTime: moment().toDate(),
      };
    }

    this.getUsers();

    this.initiateForms();
    this.getTodos();
  }

  setUseOnlyTimestampTimeReport(): any {
    this.useReportingWithTimestamps.next(
      this.functionsThisModel['useOnlyTimestampTimeReport']
    );
    this.cdr.markForCheck();
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.userSub = sub;
        this.usersDropdown = this.usersService.makeLabelsArray(data);
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.projectTodosSub && this.projectTodosSub.unsubscribe();
    this.userSub && this.userSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.datatable.changes.subscribe((comps: QueryList<Table>) => {
      this.projectDaysTable = comps.first;
      this.projectDaysTable.resetPageOnSort = false;
    });

    this.dataSetAsync.subscribe(dataSet => {
      this.showPagination = dataSet.length > 50;
    });
  }

  private initiateForms(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formInitLoading.next(false);

    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls.userId.label = 'Användare';
        this.componentMainForm.controls.todoId.label = 'Arbetsmoment';
        if (this.useReportingWithTimestamps.value) {
          this.componentMainForm.controls.startTime.label =
            '*' + this.componentMainForm.controls.startTime.label;
          this.componentMainForm.controls.stopTime.label =
            '*' + this.componentMainForm.controls.stopTime.label;
        }
        this.cdr.markForCheck();
      });

    if (this.useReportingWithTimestamps.value) {
      this.componentMainForm.controls.startTime.valueChanges.subscribe(
        (startDateTimeString: string) => {
          const stopDateTime = moment(
            this.componentMainForm.controls.stopTime.value
          ).seconds(0);
          const startDateTime = moment(startDateTimeString).seconds(0);
          if (
            !this.componentMainForm.controls.hours.value ||
            stopDateTime.diff(startDateTime) < 0
          ) {
            // When startTime passes stopTime, stopTime follows.
            this.componentMainForm.controls.stopTime.setValue(
              startDateTime.toDate(),
              {
                emitEvent: false,
              }
            );
          }
          // Recalculate total hours
          this.componentMainForm.controls.hours.setValue(
            this.calculateHoursFromTimestamps(startDateTime, stopDateTime)
          );
          // hack to bypass errors while disabled on a 'required' field set by form-handler
          this.componentMainForm.controls.hours.showErrors = false;
        }
      );
      this.componentMainForm.controls.stopTime.valueChanges.subscribe(
        (stopDateTimeString: string) => {
          const startDateTime = moment(
            this.componentMainForm.controls.startTime.value
          ).seconds(0);
          const stopDateTime = moment(stopDateTimeString).seconds(0);

          // If total time becomes negative set stopTime date component to startDate + 1 day
          const timeDiff = stopDateTime.diff(startDateTime);
          if (timeDiff < 0) {
            let stopTimeWithStartDate = moment(startDateTime)
              .hour(stopDateTime.hour())
              .minute(stopDateTime.minute());

            if (stopTimeWithStartDate.diff(startDateTime) < 0) {
              stopTimeWithStartDate = stopTimeWithStartDate.add(1, 'days');
            }

            this.componentMainForm.controls.stopTime.setValue(
              stopTimeWithStartDate.toDate(),
              { emitEvent: false }
            );
          }
          // Recalculate total hours
          this.componentMainForm.controls.hours.setValue(
            this.calculateHoursFromTimestamps(startDateTime, stopDateTime)
          );
          // hack to bypass errors while disabled on a 'required' field set by form-handler
          this.componentMainForm.controls.hours.showErrors = false;
        }
      );
      this.componentMainForm.controls.hours.disable();
      // hack to bypass errors while disabled on a 'required' field set by form-handler
      this.componentMainForm.controls.hours.showErrors = false;
      this.cdr.markForCheck();
    }
  }

  private calculateHoursFromTimestamps(
    startTime: moment.Moment,
    stopTime: moment.Moment
  ): string {
    const diffHours = moment(stopTime).diff(startTime, 'hours', true);
    if (!isNaN(diffHours)) {
      return formatNumber(diffHours, this.localeId, '1.2-2');
    }
    return '';
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    dataToMutation.date = moment(dataToMutation.date).format(
      this.helperService.dateFormat()
    );

    if (this.useReportingWithTimestamps.value) {
      // Tech-debt filed [ch-9752]: The calendar does not append seconds to the dateTime.
      dataToMutation.startTime =
        this.helperService.getFormattedDateTimeWithSeconds(
          dataToMutation.startTime
        );
      dataToMutation.stopTime =
        this.helperService.getFormattedDateTimeWithSeconds(
          dataToMutation.stopTime
        );
    }

    for (const valKey in this.formFields.attrToExcludeFromRulesAndLabel) {
      delete dataToMutation[
        this.formFields.attrToExcludeFromRulesAndLabel[valKey]
      ];
    }
    this.isExtra && (dataToMutation['extra'] = 1);

    dataToMutation['projectId'] = this.projectInfo.id;
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchVars = { projectId: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'singleProjectDays', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          ['id', 'userId'],
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.formHandler.resetForm(this.componentMainForm);
              this.componentMainForm.controls.date.setValue(moment().toDate());
              this.componentMainForm.controls.userId.setValue(
                +executedData['userId']
              );
              if (this.useReportingWithTimestamps.value) {
                this.componentMainForm.controls.startTime.setValue(
                  moment().toDate()
                );
                this.componentMainForm.controls.stopTime.setValue(
                  moment().toDate()
                );
              }
              this.callGetInvoiceData.emit();
              this.focusAfterAction();
            }
            this.loading.next(false);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  focusAfterAction() {
    const descElement = document.getElementById(this.descId);
    descElement.focus();
  }

  onEditComplete(data) {
    const rowData = { ...data };

    const parentId = rowData['parentId'];

    this.th1UpdateMutation(rowData, parentId);
  }

  summarizeTH2ofTH1(values) {
    const th2Days = values;
    const summarizeAttributes = [
      'hours',
      'hoursToInvoice',
      'mile',
      'mileToInvoice',
      'privMile',
    ];

    const th1DaysTotal = {
      daysLength: 0,
      invoicedHours: 0,
      notInvoicedHours: 0,
      invoicedMiles: 0,
      notInvoicedMiles: 0,
      subsistenceDay: 0,
      subsistenceHalfDay: 0,
      subsistenceNight: 0,
    };

    for (const day in th2Days) {
      const th2DayObject = th2Days[day];
      th2DayObject.invoiceId = false;
      th2DayObject.payslipId = false;
      let hasFalseInvoiced = false;
      let hasFalsePayslipId = false;

      if (th2DayObject.timeHour === 2) {
        ++th1DaysTotal.daysLength;

        th2Days[day].th1thisDay.forEach(itm => {
          if (itm.invoiceId > 0) {
            th1DaysTotal.invoicedHours += Number(itm.hoursToInvoice);
            th1DaysTotal.invoicedMiles += Number(itm.mileToInvoice);
            th2DayObject.invoiceId = true;
          } else {
            th1DaysTotal.notInvoicedHours += Number(itm.hoursToInvoice);
            th1DaysTotal.notInvoicedMiles += Number(itm.mileToInvoice);
            hasFalseInvoiced = true;
          }

          if (itm.payslipId > 0) {
            th2DayObject.payslipId = true;
          } else {
            hasFalsePayslipId = true;
          }

          if (itm.subsistenceDay > 0) {
            ++th1DaysTotal.subsistenceDay;
          }

          if (itm.subsistenceHalfDay > 0) {
            ++th1DaysTotal.subsistenceHalfDay;
          }

          if (itm.subsistenceNight > 0) {
            ++th1DaysTotal.subsistenceNight;
          }
        });

        th2DayObject.invoiceId = hasFalseInvoiced
          ? false
          : th2DayObject.invoiceId;
        th2DayObject.payslipId = hasFalsePayslipId
          ? false
          : th2DayObject.payslipId;

        for (const key in summarizeAttributes) {
          let keyVal = 0;

          for (const th1day in th2Days[day]['th1thisDay']) {
            const th1DayObject = th2Days[day]['th1thisDay'][th1day];

            keyVal += Number(th1DayObject[summarizeAttributes[key]]);
          }

          const fixedKeyVal = Number(keyVal.toFixed(2));
          th2DayObject[summarizeAttributes[key]] = fixedKeyVal;
        }
      }
    }

    this.th1Data = th1DaysTotal;
    this.daysFromParent.daysTimeHour2Data = th2Days;
    const newData = typeof th2Days !== 'undefined' ? [...th2Days] : [];
    this.dataSetAsync.next(newData);
    this.cdr.markForCheck();
  }

  deleteTH2Request(dayId) {
    const dataToMutation = { id: Number(dayId) };
    this.confirmDeleteInvoiceSubstrate(dataToMutation);
  }

  confirmDeleteInvoiceSubstrate(dataToMutation) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort fakturaunderlaget?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteInvoiceSubstrate(dataToMutation);
      },
      reject: () => {},
    });
  }

  private deleteInvoiceSubstrate(dataToMutation: any) {
    const refetchVars = { projectId: +this.projectInfo['id'] };
    const refetchArr = [{ name: 'singleProjectDays', variables: refetchVars }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        'delete',
        false,
        dataToMutation,
        [],
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.handleDeletedDayEmit(dataToMutation['id']);
            this.callGetInvoiceData.emit();
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  handleDeletedDayEmit(dayIdParam) {
    const desKey = this.isExtra ? 'extra' : 'normal';

    const newData = {
      deletedDayId: dayIdParam,
      destination: desKey,
    };

    this.handleDeletedDay.emit(newData);
  }

  th1UpdateMutation(dayParam, parentIdParam) {
    const attributesToMutation = [
      'doneWork',
      'hoursToInvoice',
      'mileToInvoice',
    ];

    const returnArgs = [
      'id',
      'hours',
      'extra',
      'mile',
      'date',
      'invoiceId',
      'costTypeId',
      'privMile',
      'timeHour',
      'notarized',
      `notarizedUser{
                id
                firstName
                lastName
            }`,
      `user{
                firstName,
                lastName,
                id
            }`,
    ];

    const dataToMutation = { id: Number(dayParam['id']) };

    const th1Object = dayParam;

    // loopa attributenamn
    for (const key in attributesToMutation) {
      // till mutation
      dataToMutation[attributesToMutation[key]] =
        th1Object[attributesToMutation[key]];
      // till mutation response
      returnArgs.push(attributesToMutation[key]);
    }

    dataToMutation['extra'] = th1Object['extraBool'] ? 1 : 0;

    let notarizedValue;

    if (
      dayParam['notarized'] === '0' ||
      dayParam['notarized'] === null ||
      dayParam['notarized'] === 0
    ) {
      if (dayParam['notarizedBool']) {
        notarizedValue = +this.meUser.id;
      } else {
        notarizedValue = dayParam['notarized'];
      }
    } else {
      if (dayParam['notarizedBool']) {
        notarizedValue = dayParam['notarized'];
      } else {
        notarizedValue = '0';
      }
    }

    dataToMutation['notarized'] = notarizedValue;

    if (typeof th1Object['companyCostTypeId'] !== 'undefined') {
      dataToMutation['newCostTypeId'] = th1Object['companyCostTypeId'];
    }

    const crudType = 'update';
    const refetchVars = { projectId: +this.projectInfo['id'] };
    const refetchArr = [{ name: 'singleProjectDays', variables: refetchVars }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.callGetInvoiceData.emit();
            this.editDayWithId = null;
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  private returnTH2DoneWork(idParam) {
    const id = '' + idParam;
    let doneWork: string;
    const arr = this.dataSetAsync.value;

    for (const day in arr) {
      if (+arr[day]['id'] === +id) {
        doneWork = arr[day]['doneWork'];
        break;
      }
    }
    return doneWork;
  }

  addDoneWorkStringToTH2(parentIdParam, doneWorkParam) {
    const parentId = Number(parentIdParam);
    let doneWorkToMutation: string;
    doneWorkToMutation = this.returnTH2DoneWork(parentIdParam);
    if (doneWorkToMutation != null) {
      doneWorkToMutation += ' ' + doneWorkParam;
    } else {
      doneWorkToMutation = doneWorkParam;
    }

    const variables = {
      id: parentId,
      doneWork: doneWorkToMutation,
    };

    const executedDataSub1 = this.updateTH2Day(variables).subscribe(
      executedData => {
        this.mutationService.displayMutationStatus(executedData);
        executedDataSub1.unsubscribe();
      }
    );
  }

  editTH2DoneWork(idParam, doneWorkParam) {
    const variables = {
      id: Number(idParam),
      doneWork: doneWorkParam,
    };

    const executedDataSub2 = this.updateTH2Day(variables).subscribe(
      executedData => {
        this.mutationService.displayMutationStatus(executedData);
        executedDataSub2.unsubscribe();
      }
    );
  }

  updateTH2Day(variablesParam) {
    const crudType = 'update';
    const dataToMutation = {};
    const returnArgs = [];

    for (const key in variablesParam) {
      dataToMutation[key] = variablesParam[key];
      returnArgs.push('' + key);
    }

    return Observable.create(observer => {
      const refetchVars = { projectId: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'singleProjectDays', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            observer.next(executedData);
            observer.complete();
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    });
  }

  expandTH2Row(event) {
    event.data.toggled = true;
    this.toggleDay.emit(true);
  }

  collapseTH2Row(event) {
    event.data.toggled = false;
  }

  setTableRows() {
    const arr = this.dataSetAsync.value;
    let indexToCheck = this.tableRows;

    if (arr[indexToCheck]['timeHour'] === 1) {
      indexToCheck++;
      for (let i = indexToCheck; i++; i < arr.length) {
        if (this.dataSet[i]['timeHour'] === 2) {
          this.tableRows = i;
          break;
        }
      }
    }
  }

  showUserCostType(companyCostTypeId) {
    let userCostTypeString;
    for (const i in this.functionsData['userCostTypes']) {
      const userCostType = this.functionsData['userCostTypes'][i];
      if (Number(companyCostTypeId) === Number(userCostType['value'])) {
        userCostTypeString = userCostType['label'];
      }
    }
    return userCostTypeString;
  }

  openCostTypeDialog(day) {
    this.userCostTypeDialog.day = day;
    this.userCostTypeDialog.show = true;

    const costTypes = this.functionsData['userCostTypes'];

    for (const index in costTypes) {
      const costType = costTypes[index];

      if (Number(day['companyCostTypeId']) === Number(costType['value'])) {
        const costTypeModel = costType['value'];
        this.userCostTypeDialog['newCostType'] = costTypeModel;
        this.userCostTypeDialog['oldCostType'] = costTypeModel;
        break;
      }
    }
  }

  costTypeDialogCancel(dayParam) {
    this.userCostTypeDialog['show'] = false;
  }

  costTypeDialogConfirm(dayParam) {
    this.userCostTypeDialog['show'] = false;
    const day = { ...dayParam };
    if (
      this.userCostTypeDialog['oldCostType'] !==
      this.userCostTypeDialog['newCostType']
    ) {
      day['companyCostTypeId'] = this.userCostTypeDialog['newCostType'];
    }
    this.th1UpdateMutation(day, dayParam['parentId']);
  }

  regretNotarization(dayParam, dayParentId) {
    this.confirmationService.confirm({
      message: 'Vill du ångra attesteringen?',
      header: 'Bekräfta val',
      icon: 'fa fa-question-circle-o',
      accept: () => {
        const dayObj = dayParam;
        dayObj['notarizedBool'] = false;
        this.th1UpdateMutation(dayObj, dayParentId);
      },
      reject: () => {},
    });
  }

  public getUserInitials({ firstName, lastName }): string {
    return (
      '' + this.returnFirstChar(firstName) + this.returnFirstChar(lastName)
    );
  }

  private returnFirstChar(value: string): string {
    return value ? value.charAt(0).toUpperCase() : '';
  }

  allNotarized = (th2Day: any) =>
    th2Day.th1thisDay.length ===
    th2Day.th1thisDay.filter(itm => itm.notarizedBool).length;

  toggleShowDaysSummary(value?) {
    this.showDaysSummary =
      typeof value !== 'undefined' ? value : !this.showDaysSummary;
  }

  getTodos() {
    const variables = { id: Number(this.projectInfo['id']) };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectTodos', variables)
      .subscribe(data => {
        this.projectTodosSub = data.sub;
        this.projectTodosDropdown = this.projectTodosService.dataFormater(
          data.data,
          'labels'
        );
        if (this.projectTodosDropdown.length > 1) {
          this.toggleTodoDropdown.next(true);
        } else {
          this.toggleTodoDropdown.next(false);
        }
      });
  }

  innerRowTrackby = (index: number, row: any) => row.id;
}
