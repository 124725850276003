import { Component } from '@angular/core';

import { GlobalService } from 'app/shared/global';

@Component({
  selector: 'app-browser-support',
  templateUrl: './browser-support.component.html',
  styleUrls: ['./browser-support.component.scss'],
  providers: [GlobalService],
})
export class BrowserSupportComponent {
  prefix;

  constructor(private globalService: GlobalService) {
    this.prefix = this.globalService.getUrlPrefix();
  }
}
