import { Apollo } from 'apollo-angular';
import {
  Component,
  Input,
  OnInit,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { HelperService } from 'app/shared/helpers';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms';
import { ContactFormContext } from '../contact-form/contact-form.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
  providers: [FormHandlerService],
})
export class ContactDetailComponent {
  @Input() public id: number;
  @Input() public typeString: string;
  @Input() public type: string;
  @Output() public objectDeleted = new EventEmitter<number>();
  @Output() public objectUpdated = new EventEmitter<number>();

  public contactFormContext = ContactFormContext;
  public deleteButtonIsDisabled = false;

  constructor(
    private confirmationService: ConfirmationService,
    private mutationService: ApolloMutationService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private angularApollo: Apollo,
    private helperService: HelperService
  ) {}

  public actionDelete(): void {
    const dataToMutation = { id: Number(this.id) };

    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort ' + this.typeString + 'en?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteMutateWithStoreUpdate(dataToMutation);
      },
      reject: () => {},
    });
  }

  private deleteMutateWithStoreUpdate(dataToMutation): void {
    this.deleteButtonIsDisabled = true;

    const model = 'contact';
    const action = 'delete';

    const mutation = this.helperService.getMutationQuery(model, action);
    const apolloVars = {};
    const hyperionMutationType = this.helperService.getMutationType(
      model,
      action
    );
    apolloVars[hyperionMutationType] = {};

    for (const key in dataToMutation) {
      if (dataToMutation.hasOwnProperty(key)) {
        const keyValue = dataToMutation[key];
        apolloVars[hyperionMutationType][key] = keyValue;
      }
    }

    this.angularApollo
      .mutate({
        mutation,
        variables: apolloVars,
        update: (store, { data: { contactTypeHyperionMutation } }: any) => {},
      })
      .pipe(first())
      .subscribe(
        ({ data }) => {
          const keyName = 'contactTypeHyperionMutation';
          const mutatedData = data[keyName];
          this.mutationService.displayMutationStatus(mutatedData);

          const success =
            mutatedData?.mutationDetails?.[0]?.mutationSucceeded ?? false;
          if (success) {
            this.objectDeleted.emit(dataToMutation.id);
          }

          this.deleteButtonIsDisabled = false;
        },
        onerror => {
          this.deleteButtonIsDisabled = false;
          throw onerror;
        }
      );
  }

  public onFormSubmitted(id: number): void {
    this.objectUpdated.emit(id);
  }
}
