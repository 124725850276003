import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';

import {
  ProjectDaysService,
  ProjectProductsService,
  ProjectCostTypeService,
  ProjectUserCostTypeService,
  ContactsAutosuggestService,
  CompanyFunctionsService,
  CompanyInfo,
  CompanyInfoService,
} from 'app/shared/company';
import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { HelperService } from 'app/shared/helpers';
import { HttpService } from 'app/shared/http';
import { GlobalService } from 'app/shared/global';
import { AppDialogService } from 'app/shared/dialogs';
import { ExternalProject } from 'app/shared/company/derome-integration/external-project.interface';
import { ExternalProjectWithLabel } from 'app/shared/company/derome-integration/project-external-dropdown/external-project-with-label.interface';
import { ProjectExternalProjectConnectionService } from 'app/shared/company/derome-integration/project-external-project-connection.service';

import { CompanyProjectTypeSettingsComponent } from 'app/settings';
import { InvoiceContainerComponent } from 'app/invoice/single/invoice-container.component';

import { ProjectUnderlagComponent } from 'app/old-project/project-underlag/project-underlag.component';
import { ProjectChildComponent } from 'app/old-project/project-child/project-child.component';
import { ProjectCostService } from 'app/old-project/project-cost/project-cost.service';
import { ProjectProductBookingComponent } from 'app/old-project/project-product-booking/project-product-booking.component';
import { ContactValidator } from 'app/shared/contacts/contact-validator';

@Component({
  selector: 'project-main',
  templateUrl: 'project-main.component.html',
  styleUrls: ['project-main.component.scss'],
  providers: [FormHandlerService, ProjectProductsService, AppDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMainComponent implements OnChanges, OnDestroy, OnInit {
  @Input() projectData;
  @Input() projectInfo;
  @Input() projectLabels;
  @Input() projectParams;
  @Input() functionsThisModel;
  @Input() functionsData;
  @Input() CompanyUserCostTypes;
  @Input() meUser;
  @Input() projectDays;
  @Output() getAttendanceReport = new EventEmitter();
  @Output() reloadProjectData: EventEmitter<string> = new EventEmitter<any>();
  @Output()
  reloadProjectDataLight: EventEmitter<string> = new EventEmitter<any>();

  dayMile = new BehaviorSubject(null);
  dayExtraMile = new BehaviorSubject(null);
  showBookProductLink = false;
  shareProjectPartner = false;
  buttons = {
    client: {},
    establishment: {},
    information: {},
  };
  dataModel = {
    project: 'Project',
    contact: 'Contact',
  };
  buttonObject = {
    update: {
      model: this.dataModel['project'],
    },
  };
  betalningsvillkorOptions = [
    { label: '0 dagar', value: 0 },
    { label: '10 dagar', value: 10 },
    { label: '15 dagar', value: 15 },
    { label: '20 dagar', value: 20 },
    { label: '30 dagar', value: 30 },
  ];
  forwardOptions = [];
  offertTotal: number;
  clicks = 0;
  moveBackBtn = {
    value: 'Flytta till ',
    show: true,
  };
  moveForwardBtn = {
    value: 'Flytta till ',
    show: true,
  };
  state = new BehaviorSubject({
    showExtra: false,
    extraBtnText: 'Visa',
    width: {
      normal: false,
      extra: false,
    },
    length: {
      normalDay: false,
      normalProduct: false,
      extraDay: false,
      extraProduct: false,
    },
  });
  exportLink;
  projectDaysData = {
    normal: new BehaviorSubject({}),
    extra: new BehaviorSubject({}),
  };
  projectUserCostTypes = [];
  childLoading = {
    day: null,
    product: null,
  };
  underlagDialogDisplay = false;
  projectCostTypes = [];
  projectProductsData = {
    normal: new BehaviorSubject([]),
    extra: new BehaviorSubject([]),
  };
  clientForm: FormGroup;
  establishmentForm: FormGroup;
  informationForm: FormGroup;
  formFields = {
    clientFormStructure: {
      model: 'Contact',
      attributes: {},
    },
    establishmentFormStructure: {
      model: 'Contact',
      attributes: {},
    },
    informationFormStructure: {
      model: 'Project',
      attributes: {},
    },
  };
  loading = {
    client: false,
    establishment: false,
    information: false,
  };
  toggleForms = {
    client: false,
    establishment: false,
    information: false,
  };
  loadingAsync = {
    days: new BehaviorSubject(true),
    products: new BehaviorSubject(true),
  };
  invoiceDataSub: Subscription;
  projectInvoiceData = new BehaviorSubject({});
  urlPrefix;
  showProjectCosts = false;
  clientReverseTaxControl = false;
  autoComplete = {
    client: {
      results: [],
      model: null,
      showSave: true,
      setFromStart: false,
    },
    establishment: {
      results: [],
      model: null,
      showSave: true,
      setFromStart: false,
    },
  };
  projectCostTypeSub: Subscription;
  singleProjectDaysSub: Subscription;
  id: string;
  availableForTimeReport = false;
  offersSub: Subscription;
  offertDropDownParams = [];
  hideMoveBtns: boolean;
  noWhereToMoveTo: boolean;
  isPrivilegedUser: boolean;
  isAdminUser: boolean;
  public showProductPrices = true;
  public isMissingDeromeProductIntegration = false;
  public showProductExternalConnectInterface = false;
  public currentExternalProject: ExternalProjectWithLabel;
  public saveAsNewContact = false;

  private externalProjectSettings: ExternalProject;

  constructor(
    private cdr: ChangeDetectorRef,
    private mutationService: ApolloMutationService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    private helperService: HelperService,
    private projectDaysService: ProjectDaysService,
    private projectProductsService: ProjectProductsService,
    private projectCostTypeService: ProjectCostTypeService,
    private projectUserCostTypeService: ProjectUserCostTypeService,
    private http: HttpClient,
    private httpService: HttpService,
    private dialogService: AppDialogService,
    private confirmationService: ConfirmationService,
    private projectCostService: ProjectCostService,
    private contactsAutosuggestService: ContactsAutosuggestService,
    private apolloQueryService: ApolloQueryService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyInfoService: CompanyInfoService,
    private projectExternalProjectConnection: ProjectExternalProjectConnectionService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  ngOnDestroy() {
    if (this.isPrivilegedUser && this.invoiceDataSub) {
      this.invoiceDataSub.unsubscribe();
    }
    this.projectCostTypeSub && this.projectCostTypeSub.unsubscribe();
    this.singleProjectDaysSub && this.singleProjectDaysSub.unsubscribe();
    this.offersSub && this.offersSub.unsubscribe();
  }

  ngOnInit() {
    this.getOffers();
    if (+this.projectInfo.status === 0) {
      this.noWhereToMoveTo = true;
      this.forwardOptions.push({
        label: 'Flytta till pågående',
        iconPos: 'right',
        icon: 'fa fa-angle-double-right',
        command: () => {
          this.newProjectStatus(1, 'Flytta till pågående');
        },
      });
    }

    this.exportLink =
      this.urlPrefix + '/project/export/' + this.projectInfo['id'];

    if (+this.projectInfo.status < 2) {
      this.forwardOptions.push({
        label: 'Flytta till avslutade',
        iconPos: 'right',
        icon: 'fa fa-angle-double-right',
        command: () => {
          this.newProjectStatus(2, 'Flytta till avslutade');
        },
      });
    }
    if (+this.projectInfo.status < 3) {
      this.forwardOptions.push({
        label: 'Flytta till arkiverade',
        iconPos: 'right',
        icon: 'fa fa-angle-double-right',
        command: () => {
          this.newProjectStatus(3, 'Flytta till arkiverade');
        },
      });
    }

    if (+this.projectInfo.status > 3) {
      this.hideMoveBtns = true;
    }

    this.autoComplete['client']['model'] = ContactValidator.isValidTrueId(
      this.projectData['clientContact']['trueId']
    )
      ? this.projectData['clientContact']['trueId']
      : null;
    if (
      ContactValidator.isValidTrueId(
        this.projectData['clientContact']['trueId']
      )
    ) {
      this.autoComplete['client']['setFromStart'] = true;
      this.autoComplete['client']['showSave'] = false;
    }
    this.autoComplete['establishment']['model'] =
      Number(this.projectData['establishment']) > 0
        ? Number(this.projectData['establishment'])
        : null;

    this.buttons.client = this.formHandler.getButtonValues(this.buttonObject);
    this.buttons.establishment = this.formHandler.getButtonValues(
      this.buttonObject
    );
    this.buttons.information = this.formHandler.getButtonValues(
      this.buttonObject
    );

    this.offertMath();
    this.projectParams = this.globalService.getModelParams('project');

    this.constructButtons();

    this.isAdminUser = this.meUser['type'] === '3';

    this.isPrivilegedUser = this.isAdminUser || this.meUser['type'] === '2';

    if (this.isPrivilegedUser) {
      this.getInvoiceData();
    }

    const variables = { projectId: +this.projectInfo['id'] };

    this.queryProjectCostType(variables);

    this.queryProjectDay(variables);

    this.companyFunctionsService
      .hasCompanyFunction('pickProductsFromOffer')
      .subscribe(x => {
        this.showBookProductLink = x;
        this.cdr.markForCheck();
      });
    this.companyFunctionsService
      .hasCompanyFunction('shareProjectPartner')
      .subscribe(x => {
        this.shareProjectPartner = x;
        this.cdr.markForCheck();
      });

    this.companyInfoService.companyInfo$.subscribe(
      (companyInfo: CompanyInfo) => {
        this.showProductPrices = companyInfo.showProductSearchPrices;
        this.isMissingDeromeProductIntegration =
          companyInfo.isMissingDeromeProductIntegration;
        this.showProductExternalConnectInterface =
          companyInfo.showProductExternalConnectInterface;
      }
    );
  }

  private getExternalProjectId(): ExternalProjectWithLabel {
    return (
      (this.projectData.externalId && {
        label: `${this.projectData.externalId.sourceId} - ${this.projectData.externalId.name}`,
        value: this.projectData.externalId,
      }) ||
      null
    );
  }

  private queryProjectDay(variables: { projectId: number }): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('singleProjectDays', variables, 'cache-and-network')
      .subscribe(data => {
        this.singleProjectDaysSub = data.sub;
        this.id = data['data']['project']['id'];
        this.availableForTimeReport =
          data['data']['project']['availableForTimeReport'];
        this.sortDaysData(
          this.helperService.cleanFromNode(data['data']['project']['days'])
        );
        this.cdr.markForCheck();
      });
  }

  private queryProjectCostType(variables: { projectId: number }): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('projectCostTypeWithProducts', variables)
      .subscribe(data => {
        this.projectCostTypeSub = data.sub;
        this.projectCostTypes = this.helperService.cleanFromNode(
          data.data.company.projects.edges[0].node['projectCostTypes']
        );
        this.projectCostTypes = this.projectCostTypeService.summarize(
          this.projectCostTypes
        );
        const allProducts = this.projectCostTypeService.getProducts(
          this.projectCostTypes
        );
        const sortedDataProducts =
          this.projectProductsService.sortExtra(allProducts);
        this.projectProductsData['normal'].next(
          this.projectProductsService.sortCostType(
            sortedDataProducts['normal'],
            this.projectCostTypes
          )
        );
        this.projectProductsData['extra'].next(
          this.projectProductsService.sortCostType(
            sortedDataProducts['extra'],
            this.projectCostTypes
          )
        );
        this.loadingAsync['products'].next(false);
        this.cdr.markForCheck();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectData.currentValue) {
      this.setFieldsMethod();
      this.formInit();
    }

    for (const propName in changes) {
      if (propName === 'functionsThisModel') {
        if (this.functionsThisModel['extraAlwaysOpen']) {
          const state = this.state.value;

          state['showExtra'] = true;
          state['extraBtnText'] = 'Dölj';

          this.state.next(state);
        }
        this.cdr.markForCheck();
      }
    }
  }

  moveProductToCostType(eventParam) {
    const dataSet = this.projectProductsData[eventParam['desKey']].value;
  }

  openPlockLista() {
    const url =
      this.urlPrefix +
      '/ProductBooked/pickingList?projectId=' +
      this.projectInfo['id'];
    window.open(url, 'popup', 'width=730,height=800');
  }

  sortDaysData(daysData) {
    const sortedDataDays = this.projectDaysService.sortExtra(daysData);
    this.projectDaysData['normal'].next(
      this.projectDaysService.sortTimeHour(sortedDataDays['normal'])
    );
    this.projectDaysData['extra'].next(
      this.projectDaysService.sortTimeHour(sortedDataDays['extra'])
    );

    this.loadingAsync['days'].next(false);
    this.cdr.markForCheck();
  }

  updateAvailableForTimeReport(value) {
    this.availableForTimeReport = value;
    const sub = this.mutationService
      .constructQueryAndExecuteMutation('Project', 'update', null, {
        id: this.id,
        availableForTimeReport: value,
      })
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          sub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  /**
   * Loads sums of this project, fetches data from `project/InvoiceData`
   *
   * Note: the Invoice component fetches data from `project/InvoiceData`
   * as well so they rely on the same data
   */
  getInvoiceData() {
    this.reloadProjectDataLight.emit();
    const url =
      this.globalService.getUrlPrefix() +
      '/project/InvoiceData/' +
      this.projectInfo['id'];

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.handleInvoiceDataFromResponse(data);
      this.projectUserCostTypes =
        this.projectUserCostTypeService.getProjectUserCostTypes(
          this.projectData['userCostTypes'],
          this.CompanyUserCostTypes
        );
      this.cdr.markForCheck();
    });

    this.projectCostService.callService.next('go');
  }

  handleInvoiceDataFromResponse(invoiceDataParam) {
    this.projectInvoiceData.next(Object.assign({}, invoiceDataParam));
  }

  async actionUpdate(formNameParam, afterUpdate = false) {
    if (this.externalProjectSettings) {
      this.updateExternalProjectSettings();
    }

    const crudType = 'update';
    const dataToMutation: any = this.getMutationDataFromForm(formNameParam);

    let form;
    let model = 'Contact';
    const returnKeys = [];
    if (formNameParam === 'client') {
      form = this.clientForm;
    } else if (formNameParam === 'establishment') {
      form = this.establishmentForm;
    } else {
      form = this.informationForm;
      model = 'Project';
      returnKeys.push('shortMessage');
    }

    returnKeys.push(Object.keys(form.value));

    this.buttons[formNameParam] = this.formHandler.lockButtons(
      this.buttons[formNameParam]
    );

    if (!this.formHandler.formValid([form])) {
      this.buttons[formNameParam] = this.formHandler.unlockButtons(
        this.buttons[formNameParam]
      );
    } else {
      this.loading[formNameParam] = true;

      if (formNameParam === 'client' && this.saveAsNewContact) {
        const [{ id: contactParentId, trueId: contactParentTrueId }] =
          await Promise.all([
            this.createNewParentContact(
              this.getMutationDataFromForm('client')
            ).toPromise(),
            this.updateProjectClientContact(
              this.projectInfo.id,
              dataToMutation.id
            ).toPromise(),
          ]);

        this.saveAsNewContact = false;

        dataToMutation.contactParentId = contactParentId;
        dataToMutation.trueId = contactParentTrueId;
      }

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          model,
          crudType,
          false,
          dataToMutation,
          returnKeys
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(
              executedData,
              [{ form: form, argument: crudType + model }],
              afterUpdate
            );

            this.loading[formNameParam] = false;

            this.mutationService.displayMutationStatus(executedData);

            if (executedData.mutationSucceededAllArguments) {
              // Trigger content component to refecth personliggare status only when the dataToMutaion has constructionSiteNumber
              dataToMutation['constructionSiteNumber'] &&
                this.getAttendanceReport.emit();
              this.updateProjectData(formNameParam, executedData);
              this.toggleForm(formNameParam, !afterUpdate);

              if (formNameParam === 'client') {
                this.autoComplete['client']['model'] =
                  ContactValidator.isValidTrueId(executedData['trueId'])
                    ? executedData['trueId']
                    : null;
                if (ContactValidator.isValidTrueId(executedData['trueId'])) {
                  this.autoComplete['client']['setFromStart'] = true;
                  this.autoComplete['client']['showSave'] = false;
                }
              }
            }

            this.buttons[formNameParam] = this.formHandler.unlockButtons(
              this.buttons[formNameParam]
            );
            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
            this.buttons[formNameParam] = this.formHandler.unlockButtons(
              this.buttons[formNameParam]
            );
          }
        );
    }
  }

  private createNewParentContact(dataToMutation) {
    delete dataToMutation.id;

    if (dataToMutation.contactType === 'Privat') {
      delete dataToMutation.orderBuisnessName;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      'Contact',
      'create',
      false,
      { ...dataToMutation, contact: 1 },
      ['id', 'trueId']
    );
  }

  private updateProjectClientContact(projectId: number, contactId: number) {
    return this.mutationService.constructQueryAndExecuteMutation(
      'Project',
      'update',
      false,
      { id: projectId, client: contactId }
    );
  }

  getMutationDataFromForm(formNameParam) {
    const dataToMutation = {};
    let form;

    if (formNameParam === 'client') {
      form = this.clientForm;
    } else if (formNameParam === 'establishment') {
      form = this.establishmentForm;
    } else {
      form = this.informationForm;
    }

    for (const control in form['controls']) {
      const controlValue = form['controls'][control]['value'];
      dataToMutation[control] = controlValue;
      if (typeof controlValue === 'number' && control !== 'id') {
        dataToMutation[control] = '' + controlValue;
      }
      if (controlValue === null) {
        dataToMutation[control] = '';
      }
    }

    dataToMutation['id'] = Number(dataToMutation['id']);
    return dataToMutation;
  }

  getSums(keyName) {
    const projectInvoiceValue = this.projectInvoiceData.value;

    if (projectInvoiceValue.hasOwnProperty('invoicedTotal')) {
      const invoicedTotal = projectInvoiceValue['invoicedTotal'];

      const mileTotal =
        projectInvoiceValue['milePrice'] *
        (projectInvoiceValue['mile'] + projectInvoiceValue['mileExtra']);
      const productsTotal =
        projectInvoiceValue['productCost'] +
        projectInvoiceValue['productCostExtra'];
      const projectUserCostTypeTotal =
        projectInvoiceValue['projectUserCostTypesTotal'];

      const sumAll = mileTotal + productsTotal + projectUserCostTypeTotal;

      if (keyName === 'aconto') {
        return invoicedTotal;
      } else if (keyName === 'revenue') {
        return sumAll;
      } else if (keyName === 'remaining') {
        return sumAll - invoicedTotal;
      }
    }

    return 0;
  }

  updateProjectData(formNameParam, executedData) {
    const projectDataVar = this.projectData;
    const updateIn = {};
    let calcOffert = false;

    if (formNameParam === 'client' || formNameParam === 'establishment') {
      const updatedObject = {};
      for (const keyIndex in projectDataVar[formNameParam + 'Contact']) {
        const keyValue = projectDataVar[formNameParam + 'Contact'][keyIndex];
        if (executedData.hasOwnProperty(keyIndex)) {
          updatedObject[keyIndex] = executedData[keyIndex];
        }
      }
      projectDataVar[formNameParam + 'Contact'] = updatedObject;
    } else {
      for (const keyIndex in projectDataVar) {
        if (executedData.hasOwnProperty(keyIndex)) {
          projectDataVar[keyIndex] = executedData[keyIndex];
        }
      }

      calcOffert = true;
    }

    if (calcOffert) {
      this.offertMath();
    }
    this.cdr.markForCheck();
  }

  offertMath() {
    const offertSumNo = Number(this.projectData.offertSum);
    const offertWorkSumNo = Number(this.projectData.offertSumWork);
    this.offertTotal = offertSumNo + offertWorkSumNo;
    this.cdr.markForCheck();
  }

  formInit() {
    this.clientForm = this.formHandler.groupedFormSimple(
      this.formFields.clientFormStructure
    );
    this.establishmentForm = this.formHandler.groupedFormSimple(
      this.formFields.establishmentFormStructure
    );
    this.informationForm = this.formHandler.groupedFormSimple(
      this.formFields.informationFormStructure
    );
    this.currentExternalProject = this.getExternalProjectId();
    this.cdr.markForCheck();
    this.formHandler
      .groupSetLabelsRules(this.formFields.clientFormStructure, this.clientForm)
      .then(() => {
        this.cdr.markForCheck();
      });
    this.formHandler
      .groupSetLabelsRules(
        this.formFields.establishmentFormStructure,
        this.establishmentForm
      )
      .then(() => {
        this.cdr.markForCheck();
      });
    this.formHandler
      .groupSetLabelsRules(
        this.formFields.informationFormStructure,
        this.informationForm
      )
      .then(() => {
        this.cdr.markForCheck();
      });
  }

  newProjectStatus(newStatus, moveText) {
    this.confirmationService.confirm({
      message: moveText + '?',
      header: 'Bekräfta val',
      icon: 'fa fa-question-circle',
      accept: () => {
        this.moveProject(newStatus);
      },
      reject: () => {},
    });
  }

  private moveProject(newStatus) {
    const variables = { id: this.projectInfo.id, status: newStatus };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel.project,
        'update',
        false,
        variables
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.refetchProjectsData();
          }

          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  updateProjectStatus(actionParam) {
    let newStatus;
    let moveText: string;

    if (actionParam === 'forward') {
      newStatus = this.projectInfo['status'] + 1;
      moveText = this.moveForwardBtn.value;
    } else {
      newStatus = this.projectInfo['status'] + -1;
      moveText = this.moveBackBtn.value;
    }

    this.newProjectStatus(newStatus, moveText);
  }

  setFieldsMethod() {
    const projectFormData = this.projectData;
    this.formFields.clientFormStructure['attributes'] = {
      orderBuisnessName: projectFormData['clientContact']['orderBuisnessName'],
      betalningsvillkor: projectFormData['clientContact']['betalningsvillkor'],
      orgNr: projectFormData['clientContact']['orgNr'],
      name: projectFormData['clientContact']['name'],
      address: projectFormData['clientContact']['address'],
      address2: projectFormData['clientContact']['address2'],
      cityCode: projectFormData['clientContact']['cityCode'],
      id: projectFormData['clientContact']['id'],
      city: projectFormData['clientContact']['city'],
      mail: projectFormData['clientContact']['mail'],
      mailInvoice: projectFormData['clientContact']['mailInvoice'],
      mobilePhone: projectFormData['clientContact']['mobilePhone'],
      phone: projectFormData['clientContact']['phone'],
      propertyName: projectFormData['clientContact']['propertyName'],
      housingAssociationOrgNumber:
        projectFormData['clientContact']['housingAssociationOrgNumber'],
      apartmentDesignation:
        projectFormData['clientContact']['apartmentDesignation'],
      reverseTax: projectFormData['clientContact']['reverseTax'],
      trueId: projectFormData['clientContact']['trueId'],
      contactType: projectFormData.clientContact.contactType,
    };
    this.formFields.establishmentFormStructure['attributes'] = {
      orderBuisnessName:
        this.projectData.establishmentContact.orderBuisnessName,
      name: this.projectData.establishmentContact.name,
      address: this.projectData.establishmentContact.address,
      address2: this.projectData.establishmentContact.address2,
      cityCode: this.projectData.establishmentContact.cityCode,
      city: this.projectData.establishmentContact.city,
      id: this.projectData.establishmentContact.id,
      orgNr: this.projectData.establishmentContact.orgNr,
      mail: this.projectData.establishmentContact.mail,
      mobilePhone: this.projectData.establishmentContact.mobilePhone,
      phone: this.projectData.establishmentContact.phone,
    };
    this.formFields.informationFormStructure['attributes'] = {
      startDate: this.projectData.startDate,
      endDate: this.projectData.endDate,
      mark: this.projectData.mark,
      madeBy: this.projectData.madeBy,
      id: this.projectData.id,
      offertSum: this.projectData.offertSum,
      offertSumWork: this.projectData.offertSumWork,
      orderNumber: this.projectData.orderNumber,
      constructionSiteNumber: this.projectData.constructionSiteNumber,
      client: this.projectData.client,
      establishment: this.projectData.establishment,
      offerId: this.projectData.offerId,
    };

    if (this.functionsThisModel['useProjectTypes']) {
      this.formFields.informationFormStructure['attributes']['typeId'] =
        this.projectData['typeId'];
    }
  }

  constructButtons() {
    const backBtn = {
      value: 'Flytta till ',
      show: true,
    };
    const forwBtn = {
      value: 'Flytta till ',
      show: true,
    };

    switch (this.projectInfo.status) {
      case 0:
        backBtn.show = false;
        forwBtn.value += this.projectParams.status.strings[1][0][1];
        break;
      case 1:
        backBtn.value += this.projectParams.status.strings[0][0][1];
        forwBtn.value += this.projectParams.status.strings[2][0][1];
        break;
      case 2:
        backBtn.value += this.projectParams.status.strings[1][0][1];
        forwBtn.value += this.projectParams.status.strings[3][0][1];
        break;
      case 3:
        backBtn.value += this.projectParams.status.strings[2][0][1];
        forwBtn.show = false;
        break;
      case 4:
        backBtn.show = false;
        forwBtn.show = false;
        break;
    }
    this.moveBackBtn = backBtn;
    this.moveForwardBtn = forwBtn;
  }

  toggleExtra() {
    const state = this.state.value;

    if (!state.showExtra) {
      state.showExtra = true;
      state.extraBtnText = 'Dölj';
    } else {
      state.showExtra = false;
      state.extraBtnText = 'Visa';
    }

    this.state.next(state);
  }

  toggleWidth(normalOrExtra, clickedProduct = false) {
    const state = this.state.value;
    state['width'][normalOrExtra] = !state['width'][normalOrExtra];

    if (clickedProduct && state['width'][normalOrExtra]) {
      setTimeout(() => {
        const el = document.getElementById(
          normalOrExtra + '-products-' + this.projectInfo['id']
        );
        el.scrollIntoView();
      }, 500);
    }

    this.state.next(state);
  }

  setWidth(normalOrExtra, value) {
    const state = this.state.value;
    state.width[normalOrExtra] = value;
    this.state.next(state);
  }

  toggleCheckboxes() {
    const rules = {
      reportDaysGroup: ['displayWorker', 'displayCostType'],
      userCostType: ['reportDaysGroup'],
    };
  }

  toggleForm(panelNameParam, value = null) {
    if (value === true || value === false) {
      this.toggleForms[panelNameParam] = value;
    } else {
      this.toggleForms[panelNameParam] = !this.toggleForms[panelNameParam];
    }
  }

  openUnderlagDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      projectInfo: this.projectInfo,
      functionsThisModel: this.functionsThisModel,
    };
    this.dialogService.openComponent(ProjectUnderlagComponent);
  }

  handleChangedExtraProduct(emittedObject) {
    const desKey = emittedObject['destination'];
    const emittedProduct = emittedObject['product'];
    const emittedCostType = emittedObject['costType'];

    const dataSet = this.projectProductsData[desKey].value;

    let costTypeExists = false;

    for (const index in dataSet) {
      const costType = dataSet[index];

      if (
        Number(costType['id']) === Number(emittedProduct['projectCostTypeId'])
      ) {
        // there is a costType to push to

        const products = costType['childs'];
        products.push(emittedProduct);

        costTypeExists = true;

        const newProductsArray = [...products];
        costType['childsAsync'].next(newProductsArray);
        break;
      }
    }

    if (!costTypeExists) {
      const newCostType = { ...emittedCostType };

      newCostType['costTypeSum'] = 0;
      newCostType['toggled'] = false;
      newCostType['childs'].splice(0, +newCostType['childs'].length);
      newCostType['childs'].push(emittedProduct);
      newCostType['childsAsync'].next(emittedCostType['childs']);

      dataSet.push(newCostType);
    }

    const newDataSet = [...dataSet];
    this.projectProductsData[desKey].next(newDataSet);
  }

  toggleShowProjectCosts() {
    this.showProjectCosts = !this.showProjectCosts;
  }

  goToProjectInvoice() {
    const url = this.urlPrefix + '/invoice/create';
    const data = { projectId: this.projectInfo['id'] };
    this.http.post(url, JSON.stringify(data)).subscribe();
  }

  handleDeletedDay(newDataParam) {
    const newData = newDataParam;

    const desKey = newData['destination'];
    const dayId = newData['deletedDayId'];

    const dataSet = this.projectDaysData[desKey].value;

    const th2DataSet = dataSet['daysTimeHour2Data'];

    for (const index in th2DataSet) {
      const th2Day = th2DataSet[index];

      if (Number(th2Day['id']) === Number(dayId)) {
        th2DataSet.splice(index, 1);
        break;
      }
    }

    dataSet['daysTimeHour2Data'] = [...th2DataSet];

    const newDataSet = { ...dataSet };
    this.projectDaysData[desKey].next(newDataSet);
  }

  confirmDeleteProject() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteProject();
      },
      reject: () => {},
    });
  }

  private deleteProject() {
    const url = '/project/delete/id/' + this.projectInfo['id'];
    this.httpService.makeHttpPostRequest(url).subscribe(data => {
      if (data['status'] === 'success') {
        this.messageService.insertData({
          textArray: [data['msg']],
          type: 'success',
        });
        this.refetchProjectsData();
      } else {
        this.messageService.insertData(
          { textArray: [data['msg']], type: 'error' },
          true
        );
      }
    });
  }

  openInvoiceDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      projectIdFromDialpgRef: `${this.projectInfo['id']}`,
    };
    this.dialogService
      .openComponent(InvoiceContainerComponent, null, 'dark-background')
      .onClose.subscribe(res => {
        this.reloadProjectData.emit();
      });
  }

  openProjectChildsDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { projectId: this.projectInfo['id'] };
    this.dialogService
      .openComponent(ProjectChildComponent)
      .onClose.subscribe(res => {
        this.reloadProjectData.emit();
      });
  }

  openProjectSettingsDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { isModal: 'true' };
    this.dialogService.openComponent(CompanyProjectTypeSettingsComponent);
  }

  showBookProductDiaologFunc() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { id: this.projectInfo['id'] };
    this.dialogService
      .openComponent(ProjectProductBookingComponent)
      .onClose.subscribe(res => {
        this.reloadProjectData.emit();
      });
  }

  refetchProjectsData() {
    const statusModel = [Number(this.projectInfo.status)];
    this.apolloQueryService
      .apolloQuery('companyProjects', { status: statusModel })
      .subscribe();
  }

  searchContacts(event, model) {
    this.contactsAutosuggestService.getContacts(event.query).then(data => {
      if (data['success']) {
        this.autoComplete[model]['results'] = data['data'];
      }
      this.messageService.insertDataPOSTGET(data);
      this.cdr.markForCheck();
    });
  }

  setContactFromAutosuggest(value, model) {
    let form;
    model === 'client'
      ? (form = this.clientForm)
      : (form = this.establishmentForm);

    if (value.betalningsvillkor) {
      value.betalningsvillkor = +value.betalningsvillkor;
    }

    Object.keys(form['controls']).forEach(c => {
      if (value.hasOwnProperty(c) && c !== 'id') {
        form['controls'][c].setValue(value[c]);
      }
    });

    this.autoComplete[model]['showSave'] = false;
    this.cdr.markForCheck();
  }

  removeCoupling(model) {
    model === 'client' && this.clientForm['controls']['trueId'].setValue(null);
    this.autoComplete[model]['showSave'] = true;
    this.autoComplete[model]['setFromStart'] = false;
    this.autoComplete[model]['model'] = null;
  }

  getOffers() {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyOffers',
        { status: [1] },
        'cache-and-network'
      )
      .subscribe(data => {
        this.offersSub = data.sub;
        let offerIsActive = false;
        const res = this.helperService.cleanFromNode(
          data.data['company']['offers']
        );
        // Check if offer is active or archived, null value is ignored and regarded as true.
        res.forEach(x =>
          !this.projectData['offerId']
            ? (offerIsActive = true)
            : +x['id'] === +this.projectData['offerId'] &&
              (offerIsActive = true)
        );
        // Offer is not active == offer is achived. // Get over
        !offerIsActive &&
          +this.projectData['offerId'] > 0 &&
          this.getOfferById(+this.projectData['offerId']);

        const options = [];
        // Make sure push happens only when there is offers
        res.forEach(offer => {
          offer && options.push(this.returnOptionsObject(offer));
        });
        // Set first label dropdown
        options.unshift({ label: `Knyt till offert...`, value: null });
        this.offertDropDownParams = options;
        this.cdr.markForCheck();
      });
  }

  getOfferById(id) {
    this.apolloQueryService
      .apolloWatchQueryTwo('simpleSingleOffer', { id })
      .subscribe(data => {
        data.sub.unsubscribe();
        const newOptions = [...this.offertDropDownParams];
        const offer = data.data.offer;
        // Make sure push happens only when there is offers
        offer && newOptions.push(this.returnOptionsObject(offer));
        this.offertDropDownParams = newOptions;
        this.cdr.markForCheck();
      });
  }

  private returnOptionsObject(offer) {
    if (!offer || !offer.id || !offer.trueId) {
      console.error(`Invalid offer passed`, offer);
      return;
    }

    const value = +offer.id;
    let label = `${offer.trueId}`;

    const clientContact = offer.clientContact;
    if (clientContact) {
      if (clientContact.name) {
        label += `, ${clientContact.name}`;
      } else if (clientContact.orderBuisnessName) {
        label += `, ${clientContact.orderBuisnessName}`;
      }
    }

    return { label, value };
  }

  /**
   * We set the external project settings here, but we do not trigger a backend
   * request, that is done only in `updateExternalProjectSettings`
   *
   * @param externalProject the external project the project was connected to.
   */
  public setExternalProjectSettings(externalProject: ExternalProject) {
    this.externalProjectSettings = externalProject;
  }

  /**
   * This sends a request to the backend to update the external project settings
   */
  private updateExternalProjectSettings() {
    this.projectExternalProjectConnection
      .setExternalProjectId(this.projectInfo.id, this.externalProjectSettings)
      .subscribe((returnRequest: any) => {
        if (returnRequest && returnRequest.success) {
          const successToast: ToastMessage = {
            severity: ToastMessageSeverityType.INFO,
            summary: 'Projektpriser uppdateras',
          };
          this.messageService.insertData(successToast);
          this.reloadProductData();
          this.currentExternalProject = {
            label: `${this.externalProjectSettings.id} - ${this.externalProjectSettings.name}`,
            value: this.externalProjectSettings,
          };
        }
      });
  }

  /**
   * Reloads products data and updates summations
   */
  private reloadProductData() {
    this.getInvoiceData();
    const variables = { projectId: +this.projectInfo['id'] };
    this.queryProjectCostType(variables);
  }
}
