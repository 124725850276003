import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturePreviewComponent } from 'app/feature-preview/feature-preview.component';
import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { FeaturePreviewTidrapport } from './tidrapport/tidrapport.component';
import { FeaturePreviewOffert } from './offert/offert.component';
import { FeaturePreviewDoku } from './doku/doku.component';
import { FeaturePreviewProjekt } from './projekt/projekt.component';
import { FeaturePreviewFaktura } from './faktura/faktura.component';
import { FeaturePreviewInkop } from './inkop/inkop.component';
import { FeaturePreviewPlanering } from './planering/planering.component';
import { FeaturePreviewOverblick } from './overblick/overblick.component';
import { FeaturePreviewEliggare } from './e-liggare/e-liggare.component';
import { FeaturePreviewDigitalSignatureComponent } from './feature-preview-digital-signature/feature-preview-digital-signature.component';

@NgModule({
  imports: [HyperionCardModule, CommonModule],
  providers: [],
  declarations: [
    FeaturePreviewComponent,
    FeaturePreviewEliggare,
    FeaturePreviewTidrapport,
    FeaturePreviewOffert,
    FeaturePreviewDoku,
    FeaturePreviewProjekt,
    FeaturePreviewFaktura,
    FeaturePreviewInkop,
    FeaturePreviewPlanering,
    FeaturePreviewOverblick,
    FeaturePreviewDigitalSignatureComponent,
  ],
  exports: [],
})
export class FeaturePreviewModule {}
