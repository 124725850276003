<div class="p-grid dialog-title-row">
  <spinner *ngIf="loading"></spinner>

  <div class="p-grid p-col-12" *ngIf="!loading">
    <div class="p-col-6 p-grid">
      <div class="underlag-dialog">
        <div class="p-grid">
          <h4 class="modal-section-title">
            Förhandsgranska
            <p-checkbox
              [binary]="true"
              [(ngModel)]="preview"
              (onChange)="reloadPreview()"
            ></p-checkbox>
          </h4>
          <div class="p-col-12 radio-buttons">
            <ul>
              <li>
                <label for="daysProducts"
                  >Dagar samt kostnader utan fakturor</label
                >
                <input
                  id="daysProducts"
                  type="radio"
                  checked="checked"
                  [value]="substratesRadioOptions.daysProducts"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysProductsInvoices"
                  >Dagar samt kostnader med fakturor</label
                >
                <input
                  id="daysProductsInvoices"
                  type="radio"
                  [value]="substratesRadioOptions.daysProductsInvoices"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysProductsExtra"
                  >Dagar samt kostnader, endast extra</label
                >
                <input
                  id="daysProductsExtra"
                  type="radio"
                  [value]="substratesRadioOptions.daysProductsExtra"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysProductsNoExtra"
                  >Dagar samt kostnader, utan extra</label
                >
                <input
                  id="daysProductsNoExtra"
                  type="radio"
                  [value]="substratesRadioOptions.daysProductsNoExtra"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysProductsInvoiced"
                  >Dagar samt kostnader, fakturerat</label
                >
                <input
                  id="daysProductsInvoiced"
                  type="radio"
                  checked="checked"
                  [value]="substratesRadioOptions.daysProductsInvoiced"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysProductsNotInvoiced"
                  >Dagar samt kostnader, ej fakturerat</label
                >
                <input
                  id="daysProductsNotInvoiced"
                  type="radio"
                  checked="checked"
                  [value]="substratesRadioOptions.daysProductsNotInvoiced"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="onlyDays">Endast dagar</label>
                <input
                  id="onlyDays"
                  type="radio"
                  [value]="substratesRadioOptions.onlyDays"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="onlyProducts">Endast produkter</label>
                <input
                  id="onlyProducts"
                  type="radio"
                  [value]="substratesRadioOptions.onlyProducts"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('normal')"
                />
              </li>
              <li>
                <label for="daysPerAttendanceType">Dagar per närvarotyp</label>
                <input
                  id="daysPerAttendanceType"
                  type="radio"
                  [value]="substratesRadioOptions.daysPerAttendanceType"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('daysPerAttendanceType')"
                />
              </li>
              <li *ngIf="useUserCostType">
                <label for="daysPerUserCostType">Dagar per yrkestyp</label>
                <input
                  id="daysPerUserCostType"
                  type="radio"
                  [value]="substratesRadioOptions.daysPerUserCostType"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('daysPerUserCostType')"
                />
              </li>
              <li *ngIf="useUserCostType">
                <label for="daysPerUserCostTypeOnlyExtra"
                  >Dagar per yrkestyp, endast extra</label
                >
                <input
                  id="daysPerUserCostTypeOnlyExtra"
                  type="radio"
                  [value]="substratesRadioOptions.daysPerUserCostTypeOnlyExtra"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('daysPerUserCostTypeOnlyExtra')"
                />
              </li>
              <li *ngIf="invoiceId === 0">
                <label for="todo">Arbetsmoment</label>
                <input
                  id="todo"
                  type="radio"
                  [value]="substratesRadioOptions.todo"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('todoOffer')"
                />
              </li>
              <li *ngIf="projectInfo.offerId > 0">
                <label for="offer">Offert</label>
                <input
                  id="offer"
                  type="radio"
                  [value]="substratesRadioOptions.offer"
                  [(ngModel)]="substratesForm.radio"
                  name="report-type"
                  (focus)="setCheckboxes('todoOffer')"
                />
              </li>
            </ul>
          </div>

          <div class="p-col-12"><h5>Välj period (valfritt)</h5></div>
          <div class="p-col-12 p-lg-6" style="overflow: visible">
            <appForm2-list-calendar
              [(ngModel)]="substratesForm.from"
              (onBlur)="reloadPreview()"
              [htmlAttributes]="{ label: { value: 'Från...' } }"
              [highlightRedDays]="true"
            ></appForm2-list-calendar>
          </div>
          <div class="p-col-12 p-lg-6" style="overflow: visible">
            <appForm2-list-calendar
              [(ngModel)]="substratesForm.to"
              (onBlur)="reloadPreview()"
              [htmlAttributes]="{ label: { value: 'Till...' } }"
              [highlightRedDays]="true"
            ></appForm2-list-calendar>
          </div>
          <div class="p-col-12 p-lg-6">
            <ul>
              <li *ngIf="subratesCheckboxes.reportDaysGroup.visible">
                <label for="reportDaysGroup"
                  >Gruppera per datum <small>(har subval)</small></label
                >
                <input
                  id="reportDaysGroup"
                  [(ngModel)]="subratesCheckboxes.reportDaysGroup.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="setShowReportDaysGroup($event); reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayWorker.visible">
                <label for="displayWorker">Visa medarbetare</label>
                <input
                  id="displayWorker"
                  [(ngModel)]="subratesCheckboxes.displayWorker.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="setShowDisplayWorker($event); reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayCostType.visible">
                <label for="displayCostType">Visa yrkestyp</label>
                <input
                  id="displayCostType"
                  [(ngModel)]="subratesCheckboxes.displayCostType.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayComments.visible">
                <label for="displayComments">Visa kommentarer</label>
                <input
                  id="displayComments"
                  [(ngModel)]="subratesCheckboxes.displayComments.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayHours.visible">
                <label for="displayHours">Visa timmar</label>
                <input
                  id="displayHours"
                  [(ngModel)]="subratesCheckboxes.displayHours.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayMiles.visible">
                <label for="displayMiles">Visa mil</label>
                <input
                  id="displayMiles"
                  [(ngModel)]="subratesCheckboxes.displayMiles.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
            </ul>
          </div>
          <div class="p-col-12 p-lg-6">
            <ul>
              <li *ngIf="subratesCheckboxes.displayProductPrice.visible">
                <label for="displayProductPrice">Visa produktpriser</label>
                <input
                  id="displayProductPrice"
                  [(ngModel)]="subratesCheckboxes.displayProductPrice.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayProductEvenIfZero.visible">
                <label for="displayProductEvenIfZero"
                  >Visa produkter utan pris</label
                >
                <input
                  id="displayProductEvenIfZero"
                  [(ngModel)]="
                    subratesCheckboxes.displayProductEvenIfZero.checked
                  "
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayTodoWithTime.visible">
                <label for="displayTodoWithTime">Visa arbetsmoment</label>
                <input
                  id="displayTodoWithTime"
                  [(ngModel)]="subratesCheckboxes.displayTodoWithTime.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
              <li *ngIf="subratesCheckboxes.displayWorkRevenue.visible">
                <label for="displayWorkRevenue">Visa intäkter för arbete</label>
                <input
                  id="displayWorkRevenue"
                  [(ngModel)]="subratesCheckboxes.displayWorkRevenue.checked"
                  type="checkbox"
                  name="checkboxes"
                  (change)="reloadPreview()"
                />
              </li>
            </ul>
          </div>
        </div>

        <div class="p-col-12 p-d-flex">
          <button
            pButton
            (click)="uploadFileToProject()"
            label="Spara till projektet"
            icon="pi pi-save"
            class="p-mr-2"
          ></button>
          <button
            pButton
            (click)="substratesMail()"
            label="Skicka"
            icon="pi pi-envelope"
            class="p-mr-2"
          ></button>
          <button
            pButton
            (click)="substratesPrint()"
            label="Visa"
            icon="pi pi-file-pdf"
          ></button>
        </div>
      </div>
    </div>
    <div class="p-col-6">
      <div style="width: 550px"></div>

      <div *ngIf="preview">
        <spinner *ngIf="previewUpdate"></spinner>

        <div *ngIf="preview" class="pdf-container">
          <iframe
            [attr.src]="pdfUrl"
            style="width: 100%"
            class="pdf-view-object"
            frameborder="0"
            scrolling="no"
          >
            <p>
              Kan inte läsa pdf i webbläsaren.
              <a [href]="pdfUrl" target="_blank"
                >Tryck här för att ladda ned.</a
              >
            </p>
          </iframe>
        </div>
      </div>
    </div>
  </div>
</div>
