import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MessageService } from 'app/shared/message';
import { GlobalService } from 'app/shared/global';

interface BookFeatureDemoAPIResponse {
  error?: string;
  success: boolean;
}

@Component({
  selector: 'app-feature-preview',
  templateUrl: './feature-preview.component.html',
  styleUrls: ['./feature-preview.component.scss'],
})
export class FeaturePreviewComponent implements OnInit {
  @Input() image: string;
  @Input() feature: string;
  @Input() hideReturnButton = false;

  public isSending = false;
  public appName: string;
  public supportPhone: string;
  public supportMail: string;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private globalService: GlobalService,
    private location: Location
  ) {
    this.image = this.globalService.getUrlPrefix() + this.image;
  }

  ngOnInit(): void {
    this.appName = localStorage.getItem('appname');
    this.supportPhone = localStorage.getItem('appsupportPhone');
    this.supportMail = localStorage.getItem('appsupportMail');
  }

  public goBackHistory(): void {
    this.location.back();
  }

  /**
   * Sends request to backend API with feature name which will
   * create a HubSpot ticket for the feature preview request/lead
   * or open HubSpot chat if it fails
   */
  public bookDemoHandler(): void {
    this.isSending = true;

    const postData = new HttpParams().set('feature', this.feature);

    this.http
      .post<BookFeatureDemoAPIResponse>('/company/bookfeaturedemo', postData)
      .subscribe(
        response => {
          this.isSending = false;

          if (response.success) {
            this.messageService.insertData({
              textArray: [
                `Tack! Vi kontaktar dig snart för en genomgång av ${this.feature}.`,
              ],
              time: 3000,
              type: 'success',
            });
          } else {
            this.openHubSpotChat();

            console.error(
              'Backend bookfeaturedemo endpoint did not succeed',
              response
            );
          }
        },
        error => {
          this.isSending = false;

          this.openHubSpotChat();

          console.error(
            'Backend bookfeaturedemo endpoint is unreachable',
            error
          );
        }
      );
  }

  /**
   * Open HubSpot chat to catch leads if backend fails
   */
  private openHubSpotChat(): void {
    window.HubSpotConversations?.widget?.open();
  }
}
