import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { PictureComment } from 'generated/types';
import { BehaviorSubject, first } from 'rxjs';
import { CompanyPicturesGQL } from './graphql/picture-feed.generated';

@Component({
  selector: 'picture-feed',
  templateUrl: 'picture-feed.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPictureFeedComponent implements OnInit {
  public images: BehaviorSubject<PictureComment[]> = new BehaviorSubject<
    PictureComment[]
  >([]);

  public totalImages: number;
  public imagesPerPage = 50;

  constructor(private companyPicturesGQL: CompanyPicturesGQL) {}

  public ngOnInit(): void {
    this.fetchImages({ offset: 0, count: this.imagesPerPage });
  }

  public fetchImages(settings: { offset: number; count: number }): void {
    this.companyPicturesGQL
      .fetch(settings)
      .pipe(first())
      .subscribe(res => {
        this.totalImages = res.data.company.pictures.totalCount;
        this.images.next(res.data.company.pictures.edges.map(n => n.node));
      });
  }
}
