import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'planner',
  templateUrl: 'planner.component.html',
})
export class PlannerComponent implements OnInit {
  public show = {
    menu: false,
  };

  public headerClass: string;

  public ngOnInit(): void {
    this.headerClass = localStorage.getItem('appgetenvStruqturMode');
  }
}
