import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ObservableSubscription } from '@apollo/client/core';

import {
  ApolloQueryService,
  ApolloMutationService,
} from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';

@Component({
  selector: 'app-invoice-export-settings',
  templateUrl: './invoice-export-settings.component.html',
  styleUrls: ['./invoice-export-settings.component.scss'],
  providers: [FormHandlerService],
})
export class InvoiceExportSettingsComponent implements OnInit, OnDestroy {
  loading: boolean;
  disableButton: boolean;
  id;
  formFields = {
    model: 'CompanyAccounts',
    nestedForm: false,
    attributes: {
      defaultAccountsPayableAccount: '',
      defaultClientFundsAccount: '',
      defaultFactoringReceivableAccount: '',
      defaultInterestAccount: '',
      defaultPaymentAccount: '',
      defaultReminderAccount: '',
      defaultReversedVatAccount: '',
      defaultRotavdragAccount: '',
      defaultRoundingAccount: '',
      defaultTransactionAccount: '',
      defaultVat12Account: '',
      defaultVat12IncomeAccount: '',
      defaultVat25Account: '',
      defaultVat25IncomeAccount: '',
      defaultVat6Account: '',
      defaultVat6IncomeAccount: '',
      defaultVatlessIncomeAccount: '',
      financialYear: '',
      sharedVerYearly: '',
      sieSerie: '',
    },
  };
  componentMainForm: FormGroup;
  financialYearOptions = [
    { label: 'Kalenderår (1:a jan - 31:a dec)', value: '01-01 - 12-31' },
    {
      label: 'Brutet räkenskapsår (1:a maj - 30:e apr)',
      value: '05-01 - 04-30',
    },
    {
      label: 'Brutet räkenskapsår (1:a jul - 30:e jun)',
      value: '07-01 - 06-30',
    },
    {
      label: 'Brutet räkenskapsår (1:a sep - 31:a aug)',
      value: '09-01 - 08-31',
    },
  ];
  private querySubscription: ObservableSubscription;

  constructor(
    private formHandler: FormHandlerService,
    public apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService
  ) {
    this.loading = true;
  }

  ngOnInit() {
    this.queryExportSettings();
  }

  queryExportSettings(): any {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAccounts', null, 'cache-and-network')
      .subscribe(({ data, loading, sub }) => {
        this.querySubscription = sub;
        if (data.company && data.company.account && data.company.account.id) {
          this.id = data['company']['account']['id'];
          this.initForm(data['company']['account'], loading);
        } else {
          this.loading = false;
        }
      });
  }

  initForm(data, loadingState): void {
    this.formHandler
      .groupSetLabelsRules(this.formFields, null, data)
      .then(groupedForm => {
        this.componentMainForm = groupedForm;
        this.loading = loadingState;
      });
  }

  actionUpdate() {
    if (this.formHandler.checkFormsValidity([this.componentMainForm])) {
      const variables = this.componentMainForm.value;
      variables['id'] = this.id;
      this.disableButton = true;
      this.mutationService
        .constructQueryAndExecuteMutation(
          'CompanyAccounts',
          'update',
          false,
          variables
        )
        .subscribe(data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            [
              {
                form: this.componentMainForm,
                argument: 'updateCompanyAccounts',
              },
            ],
            true
          );

          this.mutationService.displayMutationStatus(data);

          this.disableButton = false;
        });
    }
  }

  ngOnDestroy() {
    this.querySubscription && this.querySubscription.unsubscribe();
  }
}
