<form [formGroup]="componentMainForm">
  <div class="p-grid">
    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-input
        formControlName="mark"
        [tooltip]="{ msg: toolTipMsgMark, tooltipPosition: 'top' }"
      ></appForm2-wide-input>
    </div>
    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-input formControlName="madeBy"></appForm2-wide-input>
    </div>
    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-input
        formControlName="constructionSiteNumber"
        [tooltip]="{ msg: toolTipMsgCTN, tooltipPosition: 'top' }"
      ></appForm2-wide-input>
    </div>
    <div class="p-col-12">
      <app-button
        [class]="'block'"
        (onClick)="actionCreate()"
        [type]="'create'"
      ></app-button>
    </div>
  </div>
</form>
