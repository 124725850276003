<td [formGroup]="componentMainForm">
  <div
    class="form-group"
    [class.has-danger]="componentMainForm.controls['name']['showErrors']"
    style="height: 100%"
  >
    <app-product-search
      (productSelected)="setProductFromAutosuggest($event)"
      formControlName="name"
      (onBlur)="setInput(componentMainForm)"
      appendTo="body"
    >
    </app-product-search>
    <div *ngIf="componentMainForm.controls.name.showErrors">
      <p
        *ngFor="let error of componentMainForm.controls.name.mergedErrors"
        class="form-control-feedback"
        (click)="removeErrors(error)"
      >
        <span><i class="fa fa-times"></i> </span
        ><span [innerHTML]="error"></span>
      </p>
    </div>
  </div>
</td>
<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="quantity" />
</td>

<td [formGroup]="componentMainForm">
  <p-dropdown
    formControlName="unit"
    [options]="unitsDropdownParams.options"
    appendTo="body"
  ></p-dropdown>
</td>

<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="cost" />
</td>
<td [formGroup]="componentMainForm">
  <p-dropdown
    formControlName="tax"
    [options]="taxDropdown"
    appendTo="body"
  ></p-dropdown>
</td>
<td [formGroup]="componentMainForm" *ngIf="isGreenTaxReduction">
  <p-dropdown
    formControlName="greenTaxReduction"
    [options]="greenTaxDropdownOptions"
    appendTo="body"
  >
    <ng-template pTemplate="selectedItem" let-item>
      <i [ngClass]="item.icon" class="p-mr-2"></i>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <div class="p-d-flex p-ai-center">
        <i [ngClass]="item.icon" class="p-mr-2"></i>
        <span>{{ item.label }}</span>
      </div>
    </ng-template>
  </p-dropdown>
</td>
<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="procent" />
</td>

<td>
  <input
    disabled="disabled"
    class="form-control"
    value="{{
      dataObjectFromParent[
        'rowSumPlusProcent_PreUserCalculationRowTypeHyperion'
      ] | formatNum
    }}"
  />
</td>
<td [formGroup]="componentMainForm">
  <input pInputText type="number" formControlName="order" />
</td>
<td>
  <i (click)="handleDelete()" [class]="buttons.delete.icon | async"></i>
</td>
