<input
  type="text"
  pInputText
  [ngModel]="value"
  (ngModelChange)="updateValue($event)"
  *ngIf="['input'].includes(type)"
/>

<textarea
  pInputTextarea
  [ngModel]="value"
  (ngModelChange)="updateValue($event)"
  *ngIf="['textarea'].includes(type)"
></textarea>

<p-editor
  [readonly]="readonly"
  (mouseenter)="readonly = false"
  [ngModel]="value"
  (ngModelChange)="updateValue($event)"
  *ngIf="['textarea_cms'].includes(type)"
></p-editor>

<p-checkbox
  [binary]="true"
  [ngModel]="binaryValue"
  (ngModelChange)="updateValue($event)"
  *ngIf="
    ['checkbox', 'checkbox_x', 'checkbox_initials', 'checkbox_yes_no'].includes(
      type
    )
  "
></p-checkbox>
