import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ContactSelectQueryVariables = Types.Exact<{
  contactId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ContactSelectQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', allContacts?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, trueId?: string | null, contactParentId?: number | null, contact?: number | null, contactType?: string | null, betalningsvillkor?: number | null, orderBuisnessName?: string | null, name?: string | null, orgNr?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, propertyName?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, mailInvoice?: string | null, type?: number | null, reverseTax?: number | null } | null } | null> | null } | null } | null };

export const ContactSelectDocument = gql`
    query contactSelect($contactId: Int) {
  company {
    allContacts(id: $contactId) {
      edges {
        node {
          id
          trueId
          contactParentId
          contact
          contactType
          betalningsvillkor
          orderBuisnessName
          name
          orgNr
          address
          address2
          cityCode
          city
          propertyName
          housingAssociationOrgNumber
          apartmentDesignation
          phone
          mobilePhone
          mail
          mailInvoice
          type
          reverseTax
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactSelectGQL extends Apollo.Query<ContactSelectQuery, ContactSelectQueryVariables> {
    document = ContactSelectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }