// TODO: re-enable max-classes-per-file rule and refactor, disabling due to time constraints
/* eslint-disable max-classes-per-file */

import { Record, List } from 'immutable';
import { Document, ServerId } from './document';
import * as moment from 'moment';

export class DocumentFolder extends Record({
  id: null,
  name: null,
  description: null,
  color: null,
  documents: null,
  folders: null,
  parent: null,
}) {
  id: ServerId;
  name: string;
  description: string;
  color: string;
  documents: List<DocumentMetadata>;
  folders: List<DocumentFolder>;
  parent: DocumentFolder;

  path(): List<DocumentFolder> {
    let path = List().asMutable();
    let curr: DocumentFolder = this;
    while (curr !== null) {
      path = path.unshift(curr);
      curr = this.parent;
    }
    return path.asImmutable() as List<DocumentFolder>;
  }
}

export class DocumentMetadata extends Record({
  id: null,
  name: null,
  title: null,
  projectId: null,
  type: null,
  explanation: null,
  metaDescription: null,
  folder: null,
  project: null,
  date: null,
}) {
  id: ServerId;
  name: string;
  title: string;
  type: string;
  projectId: ServerId;
  explanation: string;
  metaDescription: string;
  folder: DocumentFolder;
  project: DocumentProject;
  // This MUST NOT be mutated
  date: moment.Moment;

  get isTemplate(): boolean {
    return this.type === 'template';
  }
}

export class DocumentMetadataLabels extends Record({
  name: null,
  title: null,
  project: null,
  date: null,
}) {
  name: string;
  title: string;
  project: string;
  date: string;
}

// FIXME: Merge with other project definitions?
export class DocumentProject extends Record({
  id: null,
  mark: null,
  showId: null,
}) {
  id: ServerId;
  mark: string;
  showId: string;
}

export class DocumentAndMetadata extends Record({
  document: null,
  metadata: null,
}) {
  document: Document;
  metadata: DocumentMetadata;
}
