import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanPipe',
})
export class BooleanPipe implements PipeTransform {
  transform(value: 0 | 1, args: any[] = []): string {
    return value ? 'Ja' : 'Nej';
  }
}
