import { Component, Input } from '@angular/core';
import {
  FetchProjectQuery,
  SubProjectFragment,
} from '../project-detail/project-info/graphql/project.generated';
import { Column } from '../project-list/project-list.component';

@Component({
  selector: 'app-project-subproject-list',
  templateUrl: './project-subproject-list.component.html',
  styleUrls: ['./project-subproject-list.component.scss'],
})
export class ProjectSubprojectListComponent {
  @Input() set projectData(project: FetchProjectQuery['project']) {
    if (project.subProjects) {
      this.projects = project.subProjects.edges.map(e => e.node);
    }
    if (project.mainProject) {
      this.projects = [project.mainProject];
    }
  }
  public projects: SubProjectFragment[] = [];

  public columns: Column[] = [
    {
      field: 'trueId',
      header: 'ID',
      width: '10rem',
    },
    {
      field: 'mark',
      header: 'Märkning',
    },
  ];
}
