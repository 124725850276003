import {
  Component,
  Input,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';

import { DocumentRow } from '../document';
import {
  DocumentChange,
  DocumentChangeRowEditHeader,
} from '../document-change';

@Component({
  selector: 'app-docu-row',
  templateUrl: './docu-row.component.html',
  styleUrls: ['./docu-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocuRowComponent {
  @Input() row: DocumentRow;
  @Input() width: number;
  @Input() subRow = false;
  @Input() labelsOnly = false;
  @Input() canRename = false;
  @Output() docChange: EventEmitter<DocumentChange> = new EventEmitter();

  renaming = false;

  rename(newHeader: string) {
    this.docChange.emit(
      new DocumentChangeRowEditHeader({
        id: this.row.row.viewId,
        value: newHeader,
      })
    );
  }
}
