<div class="p-grid">
  <div class="p-col-4 p-grid p-ac-between">
    <div class="p-col-12">
      <h2>Digital Signeringsprocess för Offerter:</h2>
      <p>
        När du skickar en offert för digital signering har din kund möjligheten
        att signera offerten med BankID. Detta ökar tryggheten för alla parter
        då detta är juridiskt bindande.
      </p>
      <p>Här är stegen som följs:</p>
      <ol class="main">
        <li>Dokument skickas för signering:</li>
        <ol class="sub">
          <li>
            Offerten skickas automatiskt till Zigned, vår partner för digital
            signering.
          </li>
        </ol>
        <li>Notifikation via E-post:</li>
        <ol class="sub">
          <li>
            Er kund får ett e-postmeddelande från Zigned med en länk som de
            behöver klicka på för att komma till signeringssidan.
          </li>
        </ol>
        <li>Förhandsgranskning och Signering:</li>
        <ol class="sub">
          <li>
            Genom att följa länken i mailet kan mottagaren förhandsgranska
            offerten och enkelt signera den med BankID.
          </li>
        </ol>
        <li>Signerad Offert Skickas tillbaka:</li>
        <ol class="sub">
          <li>
            Den signerade versionen av offerten skickas automatiskt tillbaka
            till både ert företag och kunden.
          </li>
        </ol>
        <li>Tillgänglig i Systemet:</li>
        <ol class="sub">
          <li>
            Den signerade offerten sparas och är tillgänglig direkt i vårt
            system för framtida referens.
          </li>
        </ol>
        <li>Följ Status i Realtid:</li>
        <ol class="sub">
          <li>
            Du som skickade offerten kan enkelt följa statusen för signeringen
            direkt i vårt system, vilket ger dig fullständig insyn i processen.
          </li>
        </ol>
      </ol>
      <p>
        Med denna digitala signaturprocess strävar vi efter att göra
        offertsigneringen smidig, säker och effektiv för alla parter
        involverade.
      </p>
      <p>
        Efter att du skickat offerten för digital signering kan du inte längre
        göra ändringar i offerten.
      </p>
      <small>
        Kostnaden är 20 kr per skickad offert. Kostnaden debiteras även om er
        kund inte signerar offerten.
      </small>
    </div>
    <div class="p-col-12">
      <span class="p-inputgroup p-mb-2">
        <input
          pInputText
          placeholder="Skicka till:"
          [formControl]="emailFormControl"
          type="email"
        />
        <button
          pButton
          label="Skicka"
          class="p-button-primary-hyperion"
          [icon]="isSending ? ' pi pi-spinner pi-spin' : 'pi pi-send'"
          (click)="actionSend()"
          [disabled]="
            !(emailFormControl.valid && emailFormControl.value) || isSending
          "
        ></button>
      </span>
      <span
        *ngIf="!emailFormControl.valid && emailFormControl.touched"
        [style.color]="'var(--btn-danger-color)'"
      >
        Mailadressen är inte giltig. Var god kontrollera att du har skrivit in
        en giltig mailadress.
      </span>
    </div>
  </div>
  <div class="p-col-8">
    <div class="pdf-container">
      <iframe
        [attr.src]="pdfUrl"
        width="100%"
        class="pdf-view-object"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </div>
  </div>
</div>
