import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { List } from 'immutable';
import { DocumentMetadata, DocumentFolder } from '../document-metadata';
import { DocumentService } from '../document.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  switchMap,
  shareReplay,
  map,
  startWith,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { ServerId } from '../document';
import { AuthService } from 'app/shared/auth/auth.service';

@Component({
  selector: 'app-docu-template-folder',
  templateUrl: './docu-template-folder.component.html',
  styleUrls: ['./docu-template-folder.component.scss'],
  providers: [DocumentService],
})
export class DocuTemplateFolderComponent implements OnInit, OnDestroy {
  @Input() id: Observable<number>;
  folders: Observable<List<DocumentFolder>>;
  copying = false;

  private componentDestroyed: Subject<void> = new Subject();

  get isLoggedIn(): boolean {
    return this.authService.isLoggedIn;
  }

  constructor(
    private documentService: DocumentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {
    if (this.id === undefined) {
      this.id = this.activatedRoute.paramMap.pipe(
        map(paramMap => Number(paramMap.get('folder')))
      );
    }
  }

  ngOnInit() {
    this.folders = this.id.pipe(
      switchMap(id =>
        this.documentService.getTemplateList(id).pipe(startWith(null))
      ),
      map(folder => folder && (folder.folders || List()).unshift(folder)),
      shareReplay(1),
      takeUntil(this.componentDestroyed)
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
  }

  notFoundFilter(folders: List<DocumentFolder>): boolean {
    return folders === undefined;
  }

  documentClicked(documentId: ServerId, status = 1) {
    this.isLoggedIn && this.createDocument(documentId, status);
    !this.isLoggedIn &&
      this.router.navigate(['../../preview/', documentId], {
        relativeTo: this.activatedRoute,
      });
  }

  createDocument(templateId: ServerId, status = 1) {
    this.copying = true;
    this.documentService.copyDocument(templateId, status).subscribe(id =>
      this.router.navigate(['../..', 'edit', id], {
        relativeTo: this.activatedRoute,
      })
    );
  }
}
