<app-dropdown-select
  #dropdown
  [disabled]="disabled"
  [groups]="groups"
  [selectedItem]="selectedItem"
  [entityLabel]="'projekt'"
  [loading]="loading"
  (selectedItemChangedEvent)="onSelectedItemChanged($event)"
  (searchEvent)="onSearch($event)"
  (isEmptyEvent)="prePopulate()"
></app-dropdown-select>
