import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService } from 'app/shared/apollo';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'share-project-client-rights',
  templateUrl: './share-project-client-rights.component.html',
})
export class ShareProjectClientRightsComponent implements OnInit {
  /*********************************************
   *
   *  OBS
   *
   *  Array is used but only for design, to use prime ng table
   *  subcontractorsRelations can only be one or empty
   *  therefore can updateSubcontractorRelation be used
   */

  @Input() projectInfo;
  @Input() parentInfo = 'noParent';

  autoCompleteResults = new BehaviorSubject([]);
  autoCompleteQuery: string;
  subcontractorsRelations = [];

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }

    this.projectSubcontractorsRelations();
  }

  private initComponentWithDialogData() {
    this.projectInfo = this.dialogConfig['data']['projectInfo'];
    this.parentInfo = 'fromModal';
  }

  projectSubcontractorsRelations() {
    const variables = {
      multiId: [+this.projectInfo.id],
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('subcontractorRelationToProject', variables)
      .subscribe(ret => {
        const data = ret.data;
        const sub = ret.sub;

        this.subcontractorsRelations = [];
        this.apolloQueryService
          .cleanFromNode(data.company.projects)
          .map(project => {
            const retval = project['subcontractorRelationToProject'];

            if (retval && retval['project']) {
              retval['clientSharesTimereportsWithoutNotarized'] =
                +retval['clientSharesTimereportsWithoutNotarized'] === 1;
              retval['clientSharesTimereports'] =
                +retval['clientSharesTimereports'] === 1;
              retval['clientAcceptTodos'] = +retval['clientAcceptTodos'] === 1;

              retval['mainContractorName'] =
                retval['project']['company']['name'];
              console.log(retval);
              this.subcontractorsRelations.push(retval);
            }
          });

        sub.unsubscribe();
      });
  }

  updateSubcontractorRelation(subcontractorsRelation) {
    const dataToMutate = {
      id: subcontractorsRelation['id'],
      clientSharesTimereports: subcontractorsRelation['clientSharesTimereports']
        ? 1
        : 0,
      clientSharesTimereportsWithoutNotarized: subcontractorsRelation[
        'clientSharesTimereportsWithoutNotarized'
      ]
        ? 1
        : 0,
      clientAcceptTodos: subcontractorsRelation['clientAcceptTodos'] ? 1 : 0,
    };

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'subcontractorRelationToProject',
        'update',
        false,
        dataToMutate
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  closeAction() {
    this.dialogRef.close();
  }
}
