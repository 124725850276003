import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, BehaviorSubject } from 'rxjs';

import { FormHandlerService } from '../../shared/forms/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { HelperService } from 'app/shared/helpers';

@Component({
  selector: 'project-comment',
  templateUrl: 'project-comment.component.html',
  providers: [FormHandlerService],
})
export class ProjectCommentComponent implements OnDestroy, OnInit {
  @Input() projectInfo;
  buttons = {
    create: {
      label: 'Skicka',
      disabled: false,
    },
  };
  dataModel = 'comment';
  dataModelCapitalized = 'Comment';
  dataSet = [];
  loading = new BehaviorSubject(true);
  commentsSub: Subscription;
  componentMainFormFields: {};
  componentMainForm: FormGroup;
  fieldsForForm;
  setFields = {
    componentMainForm: {
      comment: '',
    },
  };
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      comment: '',
    },
  };

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.getComments();
    this.initForm();
  }

  initForm(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['comment']['label'] =
          'Skriv en ny kommentar';
      });
  }

  ngOnDestroy() {
    this.commentsSub && this.commentsSub.unsubscribe();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = {
      projectId: Number(this.projectInfo.id),
      comment: this.componentMainForm.controls['comment'].value,
    };

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchVars = { id: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectComments', variables: refetchVars },
        { name: 'singleProjectTotalCounts', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          data => {
            this.formHandler.showServerErrorsOnAttributes(
              data,
              this.componentMainForm
            );

            if (data.mutationSucceededAllArguments) {
              this.initForm();
              this.loading.next(true);
            }

            this.mutationService.displayMutationStatus(data);

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  getComments() {
    const variables = { id: this.projectInfo.id };
    this.apolloQueryService
      .apolloWatchQueryTwo('projectComments', variables)
      .subscribe(data => {
        this.loading.next(false);
        this.commentsSub = data.sub;
        this.dataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']['edges'][0]['node']['comments']
        );
      });
  }
}
