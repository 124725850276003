import { Component, OnInit, Input } from '@angular/core';
import { DocumentFolder } from '../document-metadata';
import { Observable } from 'rxjs';
import { DocumentService } from '../document.service';
import { List } from 'immutable';

@Component({
  selector: 'app-docu-header-folders',
  templateUrl: './docu-header-folders.component.html',
  styleUrls: ['./docu-header-folders.component.scss'],
  providers: [DocumentService],
})
export class DocuHeaderFoldersComponent implements OnInit {
  @Input() docuRoot: string;

  folders: Observable<List<DocumentFolder>>;

  constructor(private documentService: DocumentService) {}

  ngOnInit() {
    this.folders = this.documentService.getFolders();
  }
}
