import { gql } from 'apollo-angular';

import { MutationDetails } from './common';

export const CreateDayPartial = gql`
  mutation updateDayMultiMutation(
    $updateDayMulti: [DayTypeHyperionMutationInput]
  ) {
    dayTypeHyperionMutation(updateDayMulti: $updateDayMulti) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
    }
  }
  ${MutationDetails.fragments.attributes}
`;
