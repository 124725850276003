<div
  [ngStyle]="style"
  [ngClass]="styleClass"
  class="p-panel p-component p-component-content ui-corner-all hyperion-card-wrapper"
>
  <div
    *ngIf="hasHeader"
    class="p-panel-titlebar p-component-header p-helper-clearfix ui-corner-all hyperion-card-title"
  >
    <span class="p-panel-title" *ngIf="header">{{ header }}</span>
    <ng-content select=".header"></ng-content>
    <a
      *ngIf="toggleable"
      class="p-panel-titlebar-icon p-panel-titlebar-toggler ui-corner-all ui-state-default"
      href="#"
      (click)="toggle($event)"
    >
      <span
        class="fa fa-fw"
        [ngClass]="{ 'fa-minus': !collapsed, 'fa-plus': collapsed }"
      ></span>
    </a>
  </div>

  <div
    *ngIf="!hasHeader"
    style="height: 0; margin: 0; border-top: 0; padding: 0"
    class="p-panel-titlebar p-component-header p-helper-clearfix ui-corner-all"
  ></div>

  <div
    class="p-panel-content-wrapper hyperion-card-content-wrapper"
    [@panelContent]="collapsed ? 'hidden' : 'visible'"
    [ngClass]="{
      'p-panel-content-wrapper-overflown': collapsed || animating
    }"
  >
    <div class="p-panel-content p-component-content hyperion-card-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
