import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FetchProjectQuery,
  ProjectCostTypeFragment,
} from '../graphql/project.generated';
import {
  LOCALSTORAGE_KEY,
  Mode,
} from './project-material/project-material.component';

@Component({
  selector: 'app-project-work-material',
  templateUrl: './project-work-material.component.html',
  styleUrls: ['./project-work-material.component.scss'],
})
export class ProjectWorkMaterialComponent implements OnChanges {
  public projectId: number;
  public costTypeData: FetchProjectQuery['project']['projectCostTypes'];

  @Input() public isExtra = false;
  @Input() public shouldLazyLoad = false;

  @Output() public dayUpdatedEvent = new EventEmitter<number>();
  @Output() public projectProductUpdatedEvent = new EventEmitter<boolean>();

  @Input() public projectData: Observable<FetchProjectQuery['project']>;

  public projectCostTypes: BehaviorSubject<ProjectCostTypeFragment[]> =
    new BehaviorSubject([]);

  public isExpandedCards = false;
  public showPaginator = false;
  public paginatorRows = 10;
  public mode = Mode.COSTS;
  public modeEnum = Mode;

  public ngOnChanges(): void {
    const mode = localStorage.getItem(LOCALSTORAGE_KEY) as Mode;
    if (mode) {
      this.mode = mode;
    }
    this.projectData.subscribe(pd => {
      this.showPaginator = pd.totalTimeReports > this.paginatorRows;
      this.projectId = Number(pd.id);

      const costTypes = pd.projectCostTypes?.edges.map(e => e.node);

      const splitCostTypes = this.splitExtraCostTypes(costTypes);

      this.projectCostTypes.next(
        this.isExtra ? splitCostTypes.extra : splitCostTypes.normal
      );
    });
  }

  private splitExtraCostTypes(costTypes: ProjectCostTypeFragment[]): {
    normal: ProjectCostTypeFragment[];
    extra: ProjectCostTypeFragment[];
  } {
    if (!costTypes) {
      return { normal: [], extra: [] };
    }
    const normal = costTypes.map(ct => ({
      ...ct,
      products: { edges: ct.products.edges.filter(p => p.node.extra === '0') },
    }));
    const extra = costTypes.map(ct => ({
      ...ct,
      products: { edges: ct.products.edges.filter(p => p.node.extra === '1') },
    }));
    return { normal: normal, extra: extra };
  }

  public toggleExpandedCards(element: Element): void {
    this.isExpandedCards = !this.isExpandedCards;
    setTimeout(() => {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }

  public onDayUpdatedEvent(dayId: number): void {
    this.dayUpdatedEvent.emit(dayId);
  }

  public onProjectProductUpdatedEvent(updated: boolean): void {
    this.projectProductUpdatedEvent.emit(updated);
  }

  public setMode($event: Mode): void {
    this.mode = $event;
  }
}
