import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';

import { GlobalService } from 'app/shared/global';
import { ApolloQueryService, ApolloMutationService } from 'app/shared/apollo';
import { MessageService } from 'app/shared/message';
import { CompanyFunctionsService } from 'app/shared/company/index';
import { ConnectionsService } from 'app/attendance-report/connections/services/connections.service';
import { HttpService } from 'app/shared/http';
import { ProjectHandleComponent } from '../project-handle.component';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { UserLocalStorageService } from 'app/shared/user';

@Component({
  selector: 'app-attendance-head',
  templateUrl: './attendance-head.component.html',
  styleUrls: ['../project-handle.scss'],
})
export class AttendanceHeadComponent
  extends ProjectHandleComponent
  implements OnInit
{
  statusText: string;
  showAdvancedSearch = false;

  constructor(
    router: Router,
    route: ActivatedRoute,
    cdr: ChangeDetectorRef,
    httpService: HttpService,
    globalService: GlobalService,
    messageService: MessageService,
    apolloQueryService: ApolloQueryService,
    connectionsService: ConnectionsService,
    mutationService: ApolloMutationService,
    confirmationService: ConfirmationService,
    dialogService: AppDialogService,
    companyFunctionsService: CompanyFunctionsService,
    userLocalStorageService: UserLocalStorageService
  ) {
    super(
      router,
      route,
      cdr,
      httpService,
      globalService,
      messageService,
      apolloQueryService,
      connectionsService,
      mutationService,
      confirmationService,
      dialogService,
      companyFunctionsService,
      userLocalStorageService
    );
  }

  ngOnInit() {
    this.statusText =
      this.route.snapshot.paramMap.get('state') === 'active'
        ? 'Pågående'
        : 'Arkiverade';
    super.ngOnInit();
  }
}
