import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UnpaidInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnpaidInvoicesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', hasUnpaidCrmInvoices?: number | null, latestUnpaidCrmInvoice?: { __typename?: 'CrmInvoice', id: string, dueDate?: string | null, fortnoxInvoiceNr?: string | null, fileUrl?: string | null } | null } | null };

export const UnpaidInvoicesDocument = gql`
    query unpaidInvoices {
  company {
    hasUnpaidCrmInvoices
    latestUnpaidCrmInvoice {
      id
      dueDate
      fortnoxInvoiceNr
      fileUrl
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnpaidInvoicesGQL extends Apollo.Query<UnpaidInvoicesQuery, UnpaidInvoicesQueryVariables> {
    document = UnpaidInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }