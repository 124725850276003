import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';

@Component({
  selector: 'edit-project-type',
  templateUrl: 'company-project-type-edit.component.html',
  providers: [FormHandlerService],
})
export class EditProjectTypeComponent implements OnInit {
  @Input() dataObjectFromParent;

  componentMainForm: FormGroup;
  dataModel = 'companyProjectType';
  dataModelCapitalized;
  formSettings;
  formFields = {
    model: 'CompanyProjectType',
    nestedForm: false,
    attributes: {
      id: '',
      name: '',
      prefix: '',
    },
  };
  buttons;
  componentPrefix;

  constructor(
    private cdr: ChangeDetectorRef,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService
  ) {
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.formSettings = this.globalService.getFormSettings();
  }

  ngOnInit() {
    this.initForm();
    this.componentPrefix = this.globalService.generateComponentPrefix();
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(
        this.formFields,
        this.componentMainForm,
        this.dataObjectFromParent
      )
      .then(() => {
        this.cdr.markForCheck();
      });
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.componentMainForm.value;
    dataToMutation['id'] = +dataToMutation['id'];

    this.buttons = this.formHandler.lockButtons(this.buttons);
    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.setLoadingClasses();
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            let setLoadingClassesInput = 'error';
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort projekttypen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.formHandler.setLoadingClasses('start');
        this.buttons = this.formHandler.lockButtons(this.buttons, true);
        this.actionDelete();
      },
      reject: () => {},
    });
  }

  private actionDelete() {
    const crudType = 'delete';
    const dataToMutation = {
      id: Number(this.dataObjectFromParent.id),
    };
    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.mutationService.displayMutationStatus(data);

          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);
          if (data.mutationSucceededAllArguments) {
            this.formHandler.setLoadingClasses('success');
          } else {
            this.formHandler.setLoadingClasses('error');
          }
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }
}
