export const SMALL_TABLE_COLUMNS = [
  {
    field: 'benamning',
    header: 'Benämning',
    width: 'auto',
    type: 'input',
    class: 'project-product--name',
  },
  {
    field: 'antal',
    header: 'Antal',
    type: 'input',
    width: '4.5rem',
    class: 'project-product--quantity',
  },
  {
    field: 'enhet',
    header: 'Enhet',
    type: 'input',
    width: '5rem',
    class: 'project-product--unit',
  },
  {
    field: 'avtalspris',
    header: 'F. pris (st)',
    type: 'input',
    width: '6rem',
    class: 'project-product--sales-price',
  },
  {
    field: 'totalSum',
    header: 'Summa',
    type: 'number',
    width: '8rem',
    class: 'project-product--total-sum',
  },
  {
    field: 'invoiceId',
    header: 'F',
    width: '2rem',
    class: 'project-product--is-invoiced',
  },
];

export const LARGE_TABLE_COLUMNS = [
  {
    field: 'artnr',
    header: 'Artnr',
    width: '8rem',
    class: 'project-product--artnr',
  },
  {
    field: 'date',
    header: 'Datum',
    width: '6rem',
    class: 'project-product--date',
  },
  {
    field: 'benamning',
    header: 'Benämning',
    type: 'input',
    width: 'auto',
    textAlign: 'left',
    class: 'project-product--name',
  },
  {
    field: 'antal',
    header: 'Antal',
    type: 'input',
    width: '5rem',
    class: 'project-product--quantity',
  },
  {
    field: 'enhet',
    header: 'Enhet',
    type: 'input',
    width: '4rem',
    textAlign: 'center',
    class: 'project-product--unit',
  },
  {
    field: 'avtalsprisCost',
    header: 'Inköpspris (st)',
    type: 'input',
    width: '10rem',
    class: 'project-product--sales-price',
  },
  {
    field: 'avtalspris',
    header: 'F. pris (st)',
    type: 'input',
    width: '6rem',
    class: 'project-product--sales-price',
  },
  {
    field: 'jamforspris',
    header: 'Jämförpris',
    width: '8rem',
    class: 'project-product--comparative-price',
  },
  {
    field: 'procent',
    header: '%',
    width: '3rem',
    tooltip: 'Procent',
    class: 'project-product--procent',
    textAlign: 'center',
  },
  {
    field: 'extra',
    header: 'Extra',
    type: 'checkbox',
    width: '4rem',
    class: 'project-product--is-extra',
  },
  {
    field: 'totalSum',
    header: 'Summa',
    type: 'number',
    width: '8rem',
    class: 'project-product--total-sum',
  },
  {
    field: 'invoiceId',
    header: 'F',
    width: '2rem',
    class: 'project-product--is-invoiced',
  },
];
