<form
  *ngIf="headCustomerForm"
  [formGroup]="headCustomerForm"
  class="p-grid headCustomer"
>
  <div class="p-col-12 p-md-4 p-grid">
    <span class="p-col-12">
      <label>Kundnummer</label>
      <input pInputText formControlName="trueId" disabled="true" />
    </span>

    <span class="p-col-12">
      <label>Företagsnamn</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-briefcase"></i>
        </span>
        <input pInputText formControlName="orderBuisnessName" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Org/personnummer</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-hashtag"></i>
        </span>
        <input pInputText formControlName="orgNr" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Kontaktperson</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input pInputText formControlName="name" />
      </span>
    </span>
  </div>

  <div class="p-col-12 p-md-4 p-grid">
    <span class="p-col-12">
      <label>Adress</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input pInputText formControlName="address" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Adress 2</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input pInputText formControlName="address2" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Postnummer</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-envelope"></i>
        </span>
        <input pInputText formControlName="cityCode" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Ort</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-building"></i>
        </span>
        <input pInputText formControlName="city" />
      </span>
    </span>
  </div>

  <div class="p-col-12 p-md-4 p-grid">
    <span class="p-col-12">
      <label>Telefon</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-phone"></i>
        </span>
        <input pInputText formControlName="phone" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Mobiltelefon</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-mobile"></i>
        </span>
        <input pInputText formControlName="mobilePhone" />
      </span>
    </span>

    <span class="p-col-12">
      <label>E-mail</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
        </span>
        <input pInputText formControlName="mail" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Fakturamail</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
        </span>
        <input pInputText formControlName="mailInvoice" />
      </span>
    </span>
  </div>
</form>
