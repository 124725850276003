import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null } | null };

export type ProjectFormUserFragment = { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null };

export const ProjectFormUserFragmentDoc = gql`
    fragment ProjectFormUser on User {
  id
  firstName
  lastName
}
    `;
export const FetchUsersDocument = gql`
    query fetchUsers {
  company {
    users {
      edges {
        node {
          ...ProjectFormUser
        }
      }
    }
  }
}
    ${ProjectFormUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchUsersGQL extends Apollo.Query<FetchUsersQuery, FetchUsersQueryVariables> {
    document = FetchUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }