import {
  Component,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { GlobalService } from '../../../../shared/global/index';
import { FormHandlerService } from '../../../../shared/forms/index';
import { CompanyInvoiceService } from '../../services/company-invoice.service';
import { HelperService } from 'app/shared';

@Component({
  selector: 'app-invoice-head-info',
  templateUrl: './invoice-head-info.component.html',
  styleUrls: ['./invoice-head-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    FormHandlerService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InvoiceHeadInfoComponent,
      multi: true,
    },
  ],
})
export class InvoiceHeadInfoComponent
  implements OnChanges, OnDestroy, OnInit, ControlValueAccessor
{
  formFields = {
    model: 'Invoice',
    nestedForm: false,
    attributes: {
      id: null,
      interest: '',
      fdatum: null,
      ffdatum: null,
      ovrigt: '',
      our_reference: '',
      your_order_nr: '',
      your_reference: '',
      projectId: null,
      invoiceReminder: 0,
      kreditfaktura: 0,
      projectInvoiceHistory: 0,
      typeLayout: 0,
      typeInvoice: null,
    },
  };

  @Input() invoiceType: number;

  headInfoForm: FormGroup;
  locale: any;
  labels: any;
  fieldsForForm = {};
  FormSubscription: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private globalService: GlobalService,
    private companyInvoiceService: CompanyInvoiceService,
    private formhandler: FormHandlerService,
    private helperService: HelperService
  ) {
    this.initForm();
    this.companyInvoiceService.updateForm('invoice', this.headInfoForm);
  }

  ngOnInit() {
    this.locale = this.globalService.getLocale('sv');
    this.onChange(this.headInfoForm.value);

    this.FormSubscription =
      this.companyInvoiceService.invoiceFormsChange$.subscribe(() => {
        this.cdr.markForCheck();
      });
    this.cdr.markForCheck();
  }

  private initForm() {
    this.formFields['attributes']['fdatum'] = moment().format(
      this.helperService.dateFormat()
    );
    this.formFields['attributes']['ffdatum'] = moment().format(
      this.helperService.dateFormat()
    );
    this.headInfoForm = this.formhandler.groupedFormSimple(this.formFields);
    this.formhandler
      .groupSetLabelsRules(this.formFields, this.headInfoForm)
      .then(() => {
        this.headInfoForm.controls['invoiceReminder']['label'] =
          'Påminnelsefaktura';
        this.headInfoForm.controls['projectInvoiceHistory']['label'] =
          'Visa tidigare fakturor';
        this.headInfoForm.controls['typeLayout']['label'] = 'Endast pris';
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.headInfoForm) {
      this.headInfoForm.patchValue({ typeInvoice: this.invoiceType['id'] });
    }
  }

  ngOnDestroy() {
    this.FormSubscription && this.FormSubscription.unsubscribe();
  }

  writeValue(val: any[]) {
    this.headInfoForm.patchValue(val);
  }

  onChange = (_: any) => {};

  registerOnChange(fn) {
    this.headInfoForm.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.headInfoForm.disable() : this.headInfoForm.enable();
  }

  registerOnTouched() {}
}
