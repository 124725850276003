import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject, first } from 'rxjs';
import {
  AllSubcontractorsGQL,
  SubcontractorFragment,
} from '../project-attendance/graphql/attendance.generated';

@Component({
  selector: 'app-project-subcontractor-search',
  templateUrl: './project-subcontractor-search.component.html',
  styleUrls: ['./project-subcontractor-search.component.scss'],
})
export class ProjectSubcontractorSearchComponent implements OnInit {
  @Output() public selectionEmitter = new EventEmitter<string>();
  public allSubcontractors: BehaviorSubject<SubcontractorFragment[]> =
    new BehaviorSubject([]);
  @Input() public projectId: string;

  public results: any[];

  constructor(private getAllSubcontractorsService: AllSubcontractorsGQL) {}

  public ngOnInit(): void {
    this.fetchAllSubcontractors();
  }

  public fetchAllSubcontractors(): void {
    this.getAllSubcontractorsService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const allSubcontractors = res.data.company.subcontractors.edges.map(
          e => e.node
        );
        this.allSubcontractors.next(allSubcontractors);
      });
  }

  public search(event: { query: string }): void {
    const query: string = event.query.toLowerCase();
    this.allSubcontractors.pipe(first()).subscribe(s => {
      this.results = s.filter(
        sc =>
          sc.orgNr.toLowerCase().includes(query) ||
          sc.name.toLowerCase().includes(query)
      );
    });
  }

  public select(
    event: SubcontractorFragment,
    autocomplete: AutoComplete
  ): void {
    this.selectionEmitter.emit(event.id);
    autocomplete.clear();
  }
}
