import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormHandlerService } from 'app/shared/forms/index';
import { ApolloMutationService } from 'app/shared/apollo/index';

@Component({
  selector: 'customer-price-row-user-cost-type',
  templateUrl: 'user-cost-type-row.component.html',
  styleUrls: ['customer-price-row-user-cost-type.scss'],
  providers: [FormHandlerService],
})
export class CustomerPriceUserCostTypeRowComponent implements OnInit {
  @Input() dataObjectFromParent;
  @Input() projectData;
  @Output() objectUpdated = new EventEmitter();

  loading = false;
  hasTyped = false;
  dataModel = 'projectUserCostType';
  dataModelCapitalized = 'ProjectUserCostType';
  componentMainForm: FormGroup;
  formFields = {
    model: this.dataModelCapitalized,
  };

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.formFields['attributes'] = { cost: this.dataObjectFromParent.cost };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  actionUpdate() {
    if (this.hasTyped) {
      this.userCostTypeMutation();
    }
  }

  userCostTypeMutation() {
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.dataObjectFromParent['id']),
      cost: Number(this.componentMainForm.controls['cost']['value']),
    };

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.loading = true;
      const returnAttributes = ['id', 'cost'];

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            this.loading = false;

            if (executedData.mutationSucceededAllArguments) {
              this.hasTyped = false;
            }

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.loading = false;
          }
        );
    }
  }
}
