<form
  [formGroup]="contactForm"
  [style.opacity]="loading ? '0' : '1'"
  (ngSubmit)="onSubmit()"
>
  <div
    class="p-grid p-mb-2"
    *ngIf="showSearch === true && (isEdit || contactIsProjectEstablishment)"
  >
    <div
      *ngIf="!contactData.trueId || contactData.trueId === '0'"
      class="p-col-12"
    >
      <label>Koppla till kund</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-search"></i>
        </span>
        <app-contact-select
          createButton="false"
          (selectedContactEvent)="onParentContactSelected($event)"
        ></app-contact-select>
      </span>
    </div>
    <div
      class="p-col-12"
      *ngIf="contactData.trueId && contactData.trueId !== '0'"
    >
      <label>Kopplad till kund</label>
      <span [class.p-inputgroup]="contactData.contact === 0">
        <span class="p-inputgroup-addon">
          <i class="pi pi-search"></i>
        </span>
        <input pInputText [value]="contactData.trueId" readonly />
        <button
          *ngIf="contactData.contact === 0"
          pButton
          class="p-inputgroup-addon p-button-danger-inverted"
          icon="pi pi-times"
          type="button"
          (click)="removeParent()"
          pTooltip="Ta bort koppling"
          tooltipPosition="top"
        ></button>
      </span>
    </div>

    <div class="p-col-12">
      <p-divider></p-divider>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-6">
      <label>Typ</label>
      <div id="contactType" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-circle"></i>
        </span>

        <p-dropdown
          [options]="contactTypeOptions"
          [formControl]="contactForm.controls['contactType']"
          (onChange)="onContactTypeChanged($event)"
          [pTooltip]="
            contactForm.controls['contactType'].disabled
              ? 'Du kan ändra kontakttyp för existerande kontakter från Register'
              : null
          "
          pTooltipPosition="top"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-col-6">
      <label *ngIf="contactIsPrivate">Personnummer</label>
      <label *ngIf="!contactIsPrivate">Orgnummer</label>
      <div id="orgNr" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-box"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="{{ contactIsPrivate ? '19000000-0000' : '550000-0000' }}"
          [formControl]="contactForm.controls['orgNr']"
        />
      </div>
    </div>

    <div class="p-col-6" *ngIf="!contactIsPrivate">
      <label
        >Företagsnamn
        <span *ngIf="!contactIsProjectEstablishment">*</span></label
      >
      <div id="orderBuisnessName" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-briefcase"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="Bygg AB"
          [formControl]="contactForm.controls['orderBuisnessName']"
        />
      </div>
    </div>

    <div [ngClass]="contactIsPrivate ? 'p-col-12' : 'p-col-6'">
      <label
        >Kontaktperson<span
          *ngIf="contactIsPrivate && !contactIsProjectEstablishment"
          >*</span
        ></label
      >
      <div id="name" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="Johanna Svensson"
          [formControl]="contactForm.controls['name']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Telefonnummer</label>
      <div id="phone" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-phone"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="08-000 000 00"
          [formControl]="contactForm.controls['phone']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Mobilnummer</label>
      <div id="mobilePhone" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-phone"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="070-000 00 00"
          [formControl]="contactForm.controls['mobilePhone']"
        />
      </div>
    </div>

    <div class="p-col-12">
      <label>E-mail</label>
      <div id="mail" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="johanna@byggab.se"
          [formControl]="contactForm.controls['mail']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Adress</label>
      <div id="address" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="Dalagatan 7"
          [formControl]="contactForm.controls['address']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Adress2</label>
      <div id="address2" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder=""
          [formControl]="contactForm.controls['address2']"
        />
      </div>
    </div>

    <div class="p-col-4">
      <label>Postnummer</label>
      <div id="cityCode" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="111 23"
          [formControl]="contactForm.controls['cityCode']"
        />
      </div>
    </div>

    <div class="p-col-8">
      <label>Ort</label>
      <div id="city" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="Stockholm"
          [formControl]="contactForm.controls['city']"
        />
      </div>
    </div>
  </div>
  <div
    class="p-grid p-mt-0"
    *ngIf="contactIsPrivate && !contactIsProjectEstablishment"
  >
    <div class="p-col-12">
      <label>Fastighetsbeteckning</label>
      <div id="propertyName" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder=""
          [formControl]="contactForm.controls['propertyName']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>BRF-orgnr</label>
      <div id="housingAssociationOrgNumber" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-box"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="550000-0000"
          [formControl]="contactForm.controls['housingAssociationOrgNumber']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Lägenhetsnummer</label>
      <div id="apartmentDesignation" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-home"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="1001"
          [formControl]="contactForm.controls['apartmentDesignation']"
        />
      </div>
    </div>
  </div>

  <div
    class="p-grid p-mt-1"
    *ngIf="!contactIsProjectEstablishment && !contactIsSupplier"
  >
    <div class="p-col-6">
      <label>Arbetskostnad/timme</label>
      <div id="hourCost" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-money-bill"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['hourCost']"
        />
      </div>
    </div>

    <div class="p-col-6" *ngIf="!contactIsSupplier">
      <label>Materialpåslag</label>
      <div id="costProcent" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-percentage"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['costProcent']"
        />
      </div>
    </div>

    <div class="p-col-12">
      <label>Fakturamail</label>
      <div id="mailInvoice" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-at"></i>
        </span>
        <input
          type="text"
          pInputText
          placeholder="faktura@byggab.se"
          [formControl]="contactForm.controls['mailInvoice']"
        />
      </div>
    </div>

    <div class="p-col-12" *ngIf="!contactIsSupplier">
      <label>Betalningsvillkor</label>
      <div id="betalningsvillkor" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-wallet"></i>
        </span>

        <p-dropdown
          appendTo="body"
          [options]="paymentTermOptions"
          [formControl]="contactForm.controls['betalningsvillkor']"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="p-col-12 p-d-flex p-ai-center" *ngIf="!contactIsPrivate">
      <label></label>
      <div id="reverseTax" class="p-inputgroup">
        <p-checkbox
          binary="true"
          label="Omvänd moms"
          [formControl]="contactForm.controls['reverseTax']"
        ></p-checkbox>
      </div>
    </div>
    <div class="p-col-12 p-d-flex p-ai-center" *ngIf="!contactIsPrivate">
      <label></label>
      <div id="isVATRegistered" class="p-inputgroup">
        <p-checkbox
          binary="true"
          label="Momsregistrerad"
          [formControl]="contactForm.controls['isVATRegistered']"
        ></p-checkbox>
      </div>
    </div>
  </div>

  <div class="p-grid" *ngIf="contactIsSupplier">
    <div class="p-col-6">
      <label>Plusgiro</label>
      <div id="plusgiro" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-wallet"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['plusgiro']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>Bankgiro</label>
      <div id="betalningsvillkor" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-wallet"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['bankgiro']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>BIC/SWIFT</label>
      <div id="bicSwift" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-wallet"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['bicSwift']"
        />
      </div>
    </div>

    <div class="p-col-6">
      <label>IBAN</label>
      <div id="iban" class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-wallet"></i>
        </span>

        <input
          type="text"
          pInputText
          [formControl]="contactForm.controls['iban']"
        />
      </div>
    </div>
  </div>

  <div class="p-grid p-mt-0">
    <div class="p-col-12">
      <label>Notering</label>
      <div id="note" class="p-inputgroup">
        <textarea
          pInputTextarea
          class="p-inputtext"
          rows="3"
          [formControl]="contactForm.controls['note']"
        ></textarea>
      </div>
    </div>

    <div class="p-d-flex p-jc-end p-col-12">
      <button
        *ngIf="!isEdit && !contactIsProjectEstablishment"
        pButton
        class="p-button-primary-hyperion"
        label="Skapa"
        icon="pi pi-plus"
        type="submit"
      ></button>
      <button
        *ngIf="
          (isEdit && !contactIsProjectClient) || contactIsProjectEstablishment
        "
        pButton
        class="p-button-primary-hyperion"
        label="Spara"
        icon="pi pi-save"
        type="submit"
      ></button>

      <p-splitButton
        *ngIf="isEdit && contactIsProjectClient"
        label="Spara"
        class="p-button-primary-hyperion"
        [ngStyle]="{ paddingBottom: '2rem' }"
        [model]="saveProjectClientOptions"
        appendTo="body"
        (onClick)="onSubmit()"
      ></p-splitButton>
    </div>
  </div>
</form>
