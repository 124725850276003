import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, Subscription } from 'rxjs';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { GlobalService } from 'app/shared/global/index';
import { UpdateCompanySettingsGQL } from '../company-update-settings/graphql/company-update-settings.generated';
import { GetCompanySettingsGQL } from '../company-update-settings/graphql/company-update-settings.generated';

import { AccountingPlanService } from '../services/accounting-plan.service';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
  AccountingPlanAccountsDropdownItem,
} from '../services/accounting-plan.types';
import { UserFlagsService } from 'app/user-flags.service';
@Component({
  selector: 'companyInvoiceOffer-settings',
  templateUrl: 'company-invoice-offer-settings.component.html',
  providers: [FormHandlerService],
})
export class CompanyInvoiceOfferSettingsComponent implements OnInit, OnDestroy {
  public componentMainForm: ExtendedFormGroup;
  private dataModel = 'company';
  private dataModelCapitalized: string;
  private formFields = {
    model: 'Company',
    nestedForm: false,
    attributes: {
      id: '',
      betalningsvillkor: '',
      interest: '',
      plusGiro: '',
      postGiro: '',
      iban: '',
      bicSwift: '',
      companyInsurance: '',
      fTax: '',
      showPdfInvoicePricesWithVatPrivate: '',
      skra: '',
      standardInvoiceMailText: '',
      standardOfferMailText: '',
      socialInsurance: '',
      trakHalfDayPrice: '',
      trakDayPrice: '',
      trakNightPrice: '',
      weekendHourProcent: '',
      trakHalfDayAccountingPlanAccountId: '',
      reverseVatTrakHalfDayAccountingPlanAccountId: '',
      trakNightAccountingPlanAccountId: '',
      reverseVatTrakNightAccountingPlanAccountId: '',
      trakDayAccountingPlanAccountId: '',
      reverseVatTrakDayAccountingPlanAccountId: '',
    },
  };
  public fieldsForForm = {};
  public setFields = {};
  public skraOptions = [
    { label: 'Bygg', value: 'Bygg' },
    { label: 'El', value: 'El' },
    { label: 'Glas & Plåtarbete', value: 'GlasPlatarbete' },
    { label: 'Mark & Dräneringsarbete', value: 'MarkDraneringarbete' },
    { label: 'Murning', value: 'Murning' },
    { label: 'Målning & Tapetsering', value: 'MalningTapetsering' },
    { label: 'Vvs', value: 'Vvs' },
  ];
  public buttons: any;
  public componentLoading = true;
  public betalningsvillkorOptions = [
    { label: '0 dagar', value: 0 },
    { label: '10 dagar', value: 10 },
    { label: '15 dagar', value: 15 },
    { label: '20 dagar', value: 20 },
    { label: '30 dagar', value: 30 },
  ];
  public acceptLink = '{acceptLink}';
  private sub: Subscription;

  public companyAccountingPlanAccounts: AccountingPlanAccount[];
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;
  public accountingPlanAccountsDropdownItemMap: {
    [key: number]: AccountingPlanAccountsDropdownItem;
  } = {};
  public hasAccountingPlanFunction: boolean;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private getCompanySettingsService: GetCompanySettingsGQL,
    private updateCompanySettingsService: UpdateCompanySettingsGQL,
    private accountingPlanService: AccountingPlanService,
    private userFlagsService: UserFlagsService
  ) {
    const buttonObject = {
      update: {
        model: this.dataModel,
      },
    };

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  public ngOnInit(): void {
    this.queryData();
    this.getAccountingPlans();
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.hasAccountingPlanFunction = flags.hasFlag('useAccountingPlan');
      });
  }

  private queryData(): void {
    this.getCompanySettingsService
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        this.initForm(data.company);
      });
  }

  private initForm(dataToset: any): void {
    this.formHandler
      .groupSetLabelsRules(this.formFields, null, dataToset)
      .then(groupedForm => {
        this.componentMainForm = groupedForm;
        this.componentLoading = false;

        this.componentMainForm.controls.fTax.setValue(!!dataToset.fTax);
        this.componentMainForm.controls.showPdfInvoicePricesWithVatPrivate.setValue(
          !!dataToset.showPdfInvoicePricesWithVatPrivate
        );
      });
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public updateRequest(event: KeyboardEvent): void {
    const target = event.target;
    if (!target['classList'].contains('ql-editor')) {
      this.actionUpdate();
    }
  }

  public actionUpdate(): void {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const dataToMutation = this.componentMainForm.value;
      dataToMutation['id'] = Number(dataToMutation['id']);

      this.buttons = this.formHandler.lockButtons(this.buttons);

      this.updateCompanySettingsService
        .mutate({ companySettings: dataToMutation })
        .pipe(first())
        .subscribe(({ data }) => {
          this.mutationService.displayMutationStatus(
            data.companyTypeHyperionMutation
          );
          this.formHandler.showServerErrorsOnAttributes(
            data.companyTypeHyperionMutation,
            [
              {
                form: this.componentMainForm,
                argument: 'update' + this.dataModelCapitalized,
              },
            ]
          );

          this.buttons = this.formHandler.unlockButtons(this.buttons);
        });
    }
  }

  private getAccountingPlans(): void {
    this.accountingPlanService
      .getAccounts()
      .subscribe((accounts: AccountingPlanAccount[]) => {
        this.companyAccountingPlanAccounts = accounts;

        this.accountingPlanAccountsDropdownParams =
          this.accountingPlanService.getAccountsDropdownParams(
            this.companyAccountingPlanAccounts
          );
        this.accountingPlanAccountsDropdownParams.options.forEach(
          i => (i.value = String(i.value))
        );
        this.accountingPlanAccountsDropdownParams.options.forEach(
          i => (this.accountingPlanAccountsDropdownItemMap[i.value] = i)
        );
      });
  }
}
