import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyFunctionsService } from 'app/shared/company';
import { MessageService } from 'app/shared/message';
import { ConfirmationService, SelectItemGroup } from 'primeng/api';
import { BehaviorSubject, first, forkJoin, map, Observable } from 'rxjs';
import { FetchProjectQuery } from '../../graphql/project.generated';
import {
  CompanyCostTypesGQL,
  CostTypesGQL,
  CreateProjectCostTypeGQL,
  CreateProjectUserCostTypeGQL,
  UpdateProjectCostTypeGQL,
  UpdateProjectMilePriceGQL,
  UpdateProjectPricesGQL,
  UpdateProjectUserCostTypeGQL,
  UpdateSubprojectsGQL,
} from './graphql/costtype.generated';

interface CostType {
  id: number;
  name: string;
  unit: string;
  cost: number;
  total?: number;
  costKind: CostKind;
}

enum CostKind {
  projectData = 'Projektdata',
  materialOther = 'Kostnadstyp',
  work = 'Yrkestyp',
}

enum ProjectDataIds {
  milePrice,
  allowanceDayPrice,
  allowanceHalfDayPrice,
  allowanceNightPrice,
}

@Component({
  selector: 'app-project-customer-price',
  templateUrl: './project-customer-price.component.html',
  styleUrls: ['./project-customer-price.component.scss'],
})
export class ProjectCustomerPriceComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fetchCostTypes: CostTypesGQL,
    private companyCostTypesGQL: CompanyCostTypesGQL,
    private updateProjectCostTypes: UpdateProjectCostTypeGQL,
    private updateProjectUserCostTypes: UpdateProjectUserCostTypeGQL,
    private companyFunctionService: CompanyFunctionsService,
    private updateProjectPricesService: UpdateProjectPricesGQL,
    private updateProjectMilePrice: UpdateProjectMilePriceGQL,
    private updateSubprojectsGQL: UpdateSubprojectsGQL,
    private createProjectCostTypeGQL: CreateProjectCostTypeGQL,
    private createProjectUserCostTypeGQL: CreateProjectUserCostTypeGQL
  ) {}

  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Output() public costsChanged: EventEmitter<any> = new EventEmitter();

  public projectId: string;

  private trueId: string;
  private hasSubprojects: boolean;

  public showEditCostType = false;

  public costTypes: CostType[];
  public total: BehaviorSubject<number> = new BehaviorSubject(0);

  public possibleCostTypesDropDownOptions: SelectItemGroup[];

  private getPossibleCostTypes(): void {
    this.companyCostTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const companyCostTypes = res.data.company.companyCostTypes.edges
          .map(e => e.node)
          .filter(uct => uct.active)
          .filter(
            cct =>
              !this.costTypes.some(
                ct =>
                  ct.name === cct.name && ct.costKind === CostKind.materialOther
              )
          );
        const userCostTypes = res.data.company.userCostTypes.edges
          .map(e => e.node)
          .filter(uct => uct.active)
          .filter(
            uct =>
              !this.costTypes.some(
                ct => ct.name === uct.name && ct.costKind === CostKind.work
              )
          );

        this.possibleCostTypesDropDownOptions = [
          {
            label: CostKind.work,
            items: userCostTypes.map(uct => ({
              label: uct.name,
              value: {
                id: Number(uct.id),
                name: uct.name,
                unit: 'kr/h',
                cost: uct.cost,
                costKind: CostKind.work,
              },
            })),
          },
          {
            label: CostKind.materialOther,
            items: companyCostTypes.map(cct => ({
              label: cct.name,
              value: {
                id: Number(cct.id),
                name: cct.name,
                unit: '%',
                cost: cct.procent,
                costKind: CostKind.materialOther,
              },
            })),
          },
        ];
      });
  }

  private getCostTypes(): void {
    const costTypes: CostType[] = [];

    const companyUserCostTypes = {};

    const projectId = Number(this.projectId);

    this.fetchCostTypes
      .fetch({ id: projectId })
      .pipe(first())
      .subscribe(res => {
        if (!res.data.project) {
          return;
        }
        const projectData = res.data.project;
        const companyData = res.data.company;

        companyData.userCostTypes.edges
          .map(e => e.node)
          .forEach(uct => {
            companyUserCostTypes[uct.id] = uct;
          });

        const projectCostTypes = projectData.projectCostTypes.edges.map(
          e => e.node
        );

        const projectUserCostTypes = projectData.userCostTypes.edges.map(
          e => e.node
        );

        const products = projectData.projectProducts.edges.map(e => e.node);

        const days = projectData.days.edges.map(e => e.node);

        projectUserCostTypes.forEach(puct => {
          costTypes.push({
            id: Number(puct.id),
            name: companyUserCostTypes[puct.companyCostTypeId]?.name,
            cost: puct.cost,
            unit: 'kr/h',
            costKind: CostKind.work,
            total:
              days
                .filter(d => d.costTypeId === Number(puct.id))
                .map(d => d.hoursToInvoice)
                .reduce((acc, n) => acc + n, 0) * puct.cost,
          });
        });

        const milePrice: CostType = {
          id: Number(ProjectDataIds.milePrice),
          name: 'Milpris',
          unit: 'kr/mil',
          cost: projectData.milePrice,
          costKind: CostKind.projectData,
          total:
            days.map(d => d.mileToInvoice).reduce((acc, n) => acc + n, 0) *
            projectData.milePrice,
        };

        costTypes.push(milePrice);

        projectCostTypes.forEach(pct =>
          costTypes.push({
            id: Number(pct.id),
            name: pct.name,
            cost: pct.procent,
            unit: '%',
            costKind: CostKind.materialOther,
            total:
              products
                .filter(p => p.projectCostTypeId === Number(pct.id))
                .map(p => p.avtalspris * p.antal)
                .reduce((acc, n) => acc + n, 0) *
              (1 + pct.procent / 100),
          })
        );

        if (
          this.companyFunctionService.companyFunctionIsSet(
            'useAllowanceOnInvoice'
          )
        ) {
          costTypes.push({
            id: Number(ProjectDataIds.allowanceDayPrice),
            name: 'Traktamente heldag',
            unit: 'kr/dag',
            cost: projectData.trakDayPrice,
            costKind: CostKind.projectData,
            total:
              projectData.days.edges.filter(e => e.node.subsistenceDay === 1)
                .length * projectData.trakDayPrice,
          });
          costTypes.push({
            id: Number(ProjectDataIds.allowanceHalfDayPrice),
            name: 'Traktamente halvdag',
            unit: 'kr/halvdag',
            cost: projectData.trakHalfDayPrice,
            costKind: CostKind.projectData,
            total:
              projectData.days.edges.filter(
                e => e.node.subsistenceHalfDay === 1
              ).length * projectData.trakHalfDayPrice,
          });
          costTypes.push({
            id: Number(ProjectDataIds.allowanceNightPrice),
            name: 'Traktamente natt',
            unit: 'kr/natt',
            cost: projectData.trakNightPrice,
            costKind: CostKind.projectData,
            total:
              projectData.days.edges.filter(e => e.node.subsistenceNight === 1)
                .length * projectData.trakNightPrice,
          });
        }

        this.costTypes = costTypes;

        this.getPossibleCostTypes();
        this.calculateTotal();
      });
  }

  public calculateTotal(): void {
    const cts = this.costTypes;
    this.total.next(cts.map(ct => ct.total).reduce((acc, n) => acc + n, 0));
  }

  public ngOnInit(): void {
    this.projectData.subscribe(project => {
      this.trueId = project.trueId;
      this.projectId = project.id;
      this.hasSubprojects = project.subProjectCount > 0;
      this.getCostTypes();
    });
  }

  private saveCostType(costType: CostType): Observable<any> {
    switch (costType.costKind) {
      case CostKind.work:
        return this.saveWorkingCost(costType);
      case CostKind.materialOther:
        return this.saveMaterialOtherCost(costType);
    }
  }

  private saveWorkingCost(costType: CostType): Observable<any> {
    return this.updateProjectUserCostTypes
      .mutate({ id: costType.id, cost: costType.cost })
      .pipe(
        first(),
        map(
          res =>
            res.data.projectUserCostTypeTypeHyperionMutation.mutationDetails[0]
        )
      );
  }

  private saveMaterialOtherCost(costType: CostType): Observable<any> {
    return this.updateProjectCostTypes
      .mutate({ id: costType.id, cost: costType.cost })
      .pipe(
        first(),
        map(
          res => res.data.projectCostTypeTypeHyperionMutation.mutationDetails[0]
        )
      );
  }

  private saveProjectSettings(costTypes: CostType[]): Observable<any> {
    let updatedValues: any = {
      id: Number(this.projectId),
      milePrice: costTypes.find(x => x.id === Number(ProjectDataIds.milePrice))
        .cost,
    };
    if (
      this.companyFunctionService.companyFunctionIsSet('useAllowanceOnInvoice')
    ) {
      updatedValues = {
        ...updatedValues,
        trakDayPrice: costTypes.find(
          x => x.id === Number(ProjectDataIds.allowanceDayPrice)
        )?.cost,
        trakHalfDayPrice: costTypes.find(
          x => x.id === Number(ProjectDataIds.allowanceHalfDayPrice)
        )?.cost,
        trakNightPrice: costTypes.find(
          x => x.id === Number(ProjectDataIds.allowanceNightPrice)
        )?.cost,
      };
      return this.updateProjectPricesService.mutate(updatedValues).pipe(
        first(),
        map(res => res.data.projectTypeHyperionMutation.mutationDetails[0])
      );
    } else {
      return this.updateProjectMilePrice.mutate(updatedValues).pipe(
        first(),
        map(res => res.data.projectTypeHyperionMutation.mutationDetails[0])
      );
    }
  }

  public updateAll(): void {
    const projectSettingsCostTypes = this.costTypes.filter(
      type => type.costKind === CostKind.projectData
    );
    const observables = [
      this.saveProjectSettings(projectSettingsCostTypes),
      ...this.costTypes
        .filter(t => t.costKind !== CostKind.projectData)
        .map(ct => this.saveCostType(ct)),
    ];

    forkJoin(observables)
      .pipe(first())
      .subscribe(results => {
        const allSuccess = results.every(res => res.mutationSucceeded);

        if (allSuccess) {
          this.messageService.insertData({
            textArray: ['Uppdaterat priser och påslag!'],
            type: 'success',
          });
          this.costsChanged.emit();
        }
        this.showEditCostType = false;
        this.getCostTypes();
        if (this.hasSubprojects) {
          this.askToUpdateSubprojectCosts();
        }
      });
  }

  private askToUpdateSubprojectCosts() {
    this.confirmationService.confirm({
      acceptLabel: 'Ja',
      rejectLabel: 'Nej',
      header: 'Uppdatera priser och påslag på underprojekt',
      message: `Vill du att alla underprojekt till ${this.trueId} ska få samma priser och påslag som du fyllt i här?
Om du väljer Ja kommer alla underprojekt för ${this.trueId} få samma värden som huvudprojektet,
oavsett om du satt specifika priser eller påslag i ett underprojekt`,
      accept: () => {
        this.updateSubprojectsGQL
          .mutate({ id: Number(this.projectId) })
          .pipe(first())
          .subscribe(() => {
            this.messageService.insertData({
              textArray: ['Uppdaterat priser och påslag på underprojekt!'],
              type: 'success',
            });
          });
      },
    });
  }

  public addCostType(costType: CostType): void {
    switch (costType.costKind) {
      case CostKind.work:
        this.createProjectUserCostType(costType);
        break;
      case CostKind.materialOther:
        this.createProjectCostType(costType);
        break;
      default:
        return;
    }
  }

  private createProjectCostType(costType: CostType): void {
    this.createProjectCostTypeGQL
      .mutate({
        projectId: Number(this.projectId),
        name: costType.name,
        procent: costType.cost,
        companyCostTypeId: String(costType.id),
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectCostTypeTypeHyperionMutation
        );
        this.getCostTypes();
      });
    return;
  }

  private createProjectUserCostType(costType: CostType): void {
    this.createProjectUserCostTypeGQL
      .mutate({
        projectId: Number(this.projectId),
        companyCostTypeId: costType.id,
        cost: costType.cost,
      })
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectUserCostTypeTypeHyperionMutation
        );
        this.getCostTypes();
      });
  }
}
