<div class="aconto-list">
  <div class="p-grid project-labels" *ngIf="labels">
    <div class="p-col-3">{{ labels.fakturaNr }}</div>
    <div class="p-col-3"></div>
    <div class="p-col-3">{{ labels.fdatum }}</div>
    <div class="p-col-3">Summa</div>
  </div>
  <div class="scrollbox">
    <div *ngFor="let acontoObject of acontoList | async">
      <div class="p-grid">
        <div class="p-col-3 p-text-truncate">
          <span
            class="link"
            (click)="
              openModal('/invoice/Print/type/showPDF/id/' + acontoObject.id)
            "
          >
            {{ acontoObject.fakturaNr }}, Visa
          </span>
        </div>
        <div class="p-col-3 p-text-truncate">
          <div *ngIf="acontoObject.specification === '1'">
            <span
              class="link"
              (click)="
                openModal(
                  '/project/PrintInvoicebase/' +
                    projectInfo.id +
                    '?invoices=0&invoice=' +
                    acontoObject.id
                )
              "
              >Underlag
            </span>
            <span
              class="link"
              (click)="
                openModal(
                  '/project/PrintInvoicebase/' +
                    projectInfo.id +
                    '?invoices=0&invoice=' +
                    acontoObject.id +
                    '&onlyProducts=1'
                )
              "
              >Prod
            </span>
            <span
              class="link"
              (click)="
                openModal(
                  '/project/PrintInvoicebase/' +
                    projectInfo.id +
                    '?invoices=0&invoice=' +
                    acontoObject.id +
                    '&onlyDays=1'
                )
              "
              >Dagar</span
            >
          </div>
        </div>
        <div class="p-col-3">
          {{ acontoObject.fdatum | date: 'shortDate':null:'sv-SE' }}
        </div>
        <div class="p-col-3">
          {{ acontoObject.sumBox | formatNum }} {{ acontoObject.currency }}
        </div>
      </div>
    </div>
  </div>
  <hr class="total-divider" />
  <div class="aconto-total">
    <div class="p-grid">
      <div class="p-col-6"></div>
      <div class="p-col-2"><strong>Totalt</strong></div>
      <div class="p-col-3">
        <strong
          >{{ acontoSum.sumBox | formatNum }} {{ acontoSum.currency }}</strong
        >
      </div>
    </div>
  </div>
</div>
