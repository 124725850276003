import { Component, OnInit } from '@angular/core';
import { DocumentService } from '../document.service';
import { Observable } from 'rxjs';
import { DocuUpgradeToProjectManagerDialogComponent } from '../docu-upgrade-to-project-manager-dialog/docu-upgrade-to-project-manager-dialog.component';
import { AuthService } from 'app/shared/auth/auth.service';
import { AppDialogService } from 'app/shared/dialogs';

@Component({
  selector: 'app-docu-upgrade-banner',
  templateUrl: './docu-upgrade-banner.component.html',
  styleUrls: ['./docu-upgrade-banner.component.scss'],
})
export class DocuUpgradeBannerComponent implements OnInit {
  enable: Observable<boolean>;

  constructor(
    private documentService: DocumentService,
    private appDialogService: AppDialogService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    return this.authService.isLoggedInObs.subscribe(ret => {
      this.enable = ret && this.documentService.shouldShowUpgradeBanner();
    });
  }

  showDialog() {
    this.appDialogService.openComponent(
      DocuUpgradeToProjectManagerDialogComponent
    );
  }
}
