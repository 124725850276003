import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalService } from 'app/shared/global';

import { ProductDetails, ProductDetailsSource } from './product-details';
import { DayProductDetails } from 'app/day/handle/day-product-details';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsService {
  private PRODUCT_DETAILS_ENDPOINT = `product/details`;

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {}

  /**
   * Returns Product Details for a single product
   *
   * @param source the source api from which we fetch the details
   * @param sourceId the ID of the product
   * @param quantity the quantity of products to calculate the prices for
   * @param options (optional) contains one of the following 2:
   *       - projectId (optional) the projectId associated with the product
   *       - offerId (optional) the offerId associated with the product
   */
  public getProductDetails(
    source: ProductDetailsSource,
    sourceId: string,
    quantity = 1,
    options: {
      projectId?: string | number;
      offerId?: string | number;
    } = {
      projectId: '',
      offerId: '',
    }
  ): Observable<ProductDetails> {
    if (!source || !source.length) {
      throw new Error('No source api passed, required');
    }
    if (!sourceId || sourceId.length === 0) {
      throw new Error('No sourceId passed, required');
    }

    const params = {
      quantity: '' + quantity,
    };

    // cast the ids to strings
    options.projectId = `${options.projectId}`;
    options.offerId = `${options.offerId}`;

    if (options.projectId && options.projectId.length && +options.projectId) {
      params['projectId'] = +options.projectId;
    } else if (options.offerId && options.offerId.length && +options.offerId) {
      params['offerId'] = +options.offerId;
    }

    return this.httpClient.get<ProductDetails>(
      this.getProductDetailsEndpoint(source, sourceId),
      { params }
    );
  }

  public usesProductDetailsAPI(product): boolean {
    return !!(product.sourceId && product.source);
  }

  public usesProductDetailsDeromeAPI(product): boolean {
    return (
      this.usesProductDetailsAPI(product) &&
      (product.source === 'derome-api' || product.source === 'derome-odbc')
    );
  }

  /** maps a ProductDetail into a project product */
  public toProjectProduct(productDetails: ProductDetails): {
    benamning: any;
    listPrice: any;
    listPriceUnit: any;
    avtalspris: any;
    enhet: any;
    totalSum: any;
    avtalsprisCost: any;
    antal: any;
  } {
    return {
      benamning: productDetails.name,
      listPrice: productDetails.listPrice,
      listPriceUnit: productDetails.listPriceUnit,
      avtalspris: productDetails.salesPrice,
      enhet: productDetails.salesPriceUnit,
      totalSum: productDetails.totalAmount,
      avtalsprisCost: productDetails.netPrice,
      antal: productDetails.quantity,
    };
  }

  private getProductDetailsEndpoint(
    source: ProductDetailsSource,
    sourceId: string
  ): string {
    return `${this.globalService.getUrlPrefix()}/${
      this.PRODUCT_DETAILS_ENDPOINT
    }/${source}/${sourceId}`;
  }
}
