<div class="p-grid">
  <div class="p-col-12 p-g-nopad">
    <form [formGroup]="componentMainForm">
      <appForm2-simple-textarea
        [rows]="2"
        formControlName="comment"
      ></appForm2-simple-textarea>
    </form>
  </div>

  <div class="p-col-12 p-g-nopad">
    <div class="pull-right">
      <app-button
        (onClick)="confirmActionDelete()"
        [type]="'delete'"
        [disabled]="buttons.delete.disabled"
      ></app-button>
      <app-button
        class="ml5"
        (onClick)="actionUpdate()"
        [type]="'update'"
        [disabled]="buttons.update.disabled"
      ></app-button>
    </div>
  </div>
</div>
