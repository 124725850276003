import { Component, OnInit } from '@angular/core';
import { UserLocalStorageService } from 'app/shared/user/user-local-storage.service';
import { ConfigService } from 'app/shared/config/config.service';
import { Theme } from 'app/shared/config/config';
import { ActivatedRoute, Routes } from '@angular/router';
import { RouteWithData } from 'app/routes.service';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: 'sidebar-menu.component.html',
  styleUrls: ['sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  public userAbbr: string;
  public userFullName: string;
  public isNotificationsOpened: boolean;
  public notificationsCount = 0;
  public theme: Theme;
  public sidebarMenuLinks: Routes;
  public userFlags: UserFlags;
  public routesShouldReuse: string[] = [
    'project',
    'invoice',
    'supplierInvoice',
  ];

  constructor(
    private userLocalStorageService: UserLocalStorageService,
    protected configService: ConfigService,
    private activatedRoute: ActivatedRoute,
    private userFlagsService: UserFlagsService
  ) {
    const userData = this.userLocalStorageService.getMEUser();
    this.userAbbr = userData?.firstName ? userData.firstName[0] : '';
    this.userFullName = `${userData?.firstName} ${userData?.lastName}`;
    this.theme = this.configService.getConfig().theme;
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => (this.userFlags = flags));
  }

  public ngOnInit(): void {
    this.sidebarMenuLinks =
      this.activatedRoute.snapshot.routeConfig.children?.filter(
        (route: RouteWithData) => route.data?.showInMainMenu
      );
  }

  public setNotificationCount(count: number): void {
    this.notificationsCount = count;
  }

  public setNotificationOpen(open: boolean): void {
    this.isNotificationsOpened = open;
  }

  public toggleNotifications(): void {
    this.isNotificationsOpened = !this.isNotificationsOpened;
  }
}
