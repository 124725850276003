import { Injectable } from '@angular/core';
import * as currency from 'currency.js';
import { ApolloQueryService } from '../../shared/apollo';
import { SharedInvoiceServiceFunctions } from './shared-invoice-service-functions';

import { HelperService } from '../../shared/helpers/index';
import { InvoiceType } from 'app/invoice/single/enums/invoice-type.enum';

import {
  CompanyInvoiceFirst,
  CompanyInvoiceLast,
  CompanyInvoicesByIds,
  getFull,
} from './invoice-queries';

import {
  CompanySupplierInvoiceFirst,
  CompanySupplierInvoiceLast,
} from './supplier-invoice-queries';

@Injectable({
  providedIn: 'root',
})
export class GlobalInvoiceService extends SharedInvoiceServiceFunctions {
  constructor(
    public apolloQueryService: ApolloQueryService,
    public helperService: HelperService
  ) {
    super();
  }

  getSingleInvoiceFull(InvoiceId, mode = '') {
    const variables = { id: InvoiceId };
    return new Promise(resolve => {
      this.apolloQueryService
        .apolloWatchQueryTwoQuery(getFull, variables)
        .subscribe(data => {
          if (mode !== 'get') {
            this.cleanAndStoreData(data);
          }

          const invoice = this.helperService.cleanFromNode(
            data.data.company.invoices
          )[0];
          const invoiceRotRequestings = this.helperService.cleanFromNode(
            invoice.invoiceRotRequestings
          );

          const projectsTroughRows = this.helperService.cleanFromNode(
            invoice.projectsTroughRows
          );

          const rows = this.helperService.cleanFromNode(invoice.rows);
          delete invoice.rows;
          delete invoice.invoiceRotRequestings;
          delete invoice.projectsTroughRows;

          resolve(
            Object.assign(
              invoice,
              { rows },
              { invoiceRotRequestings },
              { projectsTroughRows },
              { betalningsvillkor: data.data.company.betalningsvillkor }
            )
          );
          data.sub.unsubscribe();
        });
    });
  }

  public getInvoicesPaginated(): void {
    this.getPaginatedInvoicesByQueryType(CompanyInvoiceLast, 'customer');
  }

  public getSupplierInvoicesPaginated(): void {
    this.getPaginatedInvoicesByQueryType(
      CompanySupplierInvoiceLast,
      'supplier'
    );
  }

  public mutationOcured(invoiceId: any[]) {
    if (!invoiceId || !invoiceId.length) {
      return;
    }
    const variables = { multiId: invoiceId };
    this.apolloQueryService
      .apolloWatchQueryTwoQuery(CompanyInvoicesByIds, variables)
      .subscribe(data => {
        this.cleanAndStoreData(data);
        data.subs && data.subs.unsubscribe();
      });
  }

  public isTaxReduction = (typeInvoice: number) => {
    return this.taxReductionInvoiceTypes.includes(typeInvoice);
  };

  public sumInvoiceRows: any = (
    typeInvoice: number,
    rowDataContainers: any
  ): any => {
    let invoiceAmounts = {
      total: currency(0),
      net: currency(0),
      gross: currency(0),
      exclVat: currency(0),
      vat: currency(0),
      sum: currency(0),
      sumRounded: currency(0),
      rounded: currency(0),
      workCost: currency(0),
      workVat: currency(0),
      taxReduction: currency(0),
      taxReductionWork: currency(0),
      taxReductionMaterial: currency(0),
    };
    rowDataContainers.forEach(container => {
      if (container.rows && container.rows.length > 0) {
        container = this.modifyContainerRows(container);
        invoiceAmounts = this.sumRows(container, invoiceAmounts, typeInvoice);
      }
    });
    invoiceAmounts.taxReduction = currency(
      Math.floor(invoiceAmounts.taxReduction.value)
    );
    if (typeInvoice === InvoiceType.InvertedVat) {
      invoiceAmounts.vat = currency(0);
    }
    return invoiceAmounts;
  };

  public isTaxReductionDefinitionMissing = (
    rowDataContainers: any,
    invoiceType: number
  ): boolean => {
    return rowDataContainers.some(container => {
      return container.rows?.some(row => {
        if (row.arbetskostnad) {
          // We always require housework type.
          if (!row.houseWorkType) {
            return true;
            // We do not require houseWorkHoursToReport if it's a green invoice
            // since you can recieve tax reduction on material.
          } else if (
            !row.houseWorkHoursToReport &&
            [InvoiceType.Rot, InvoiceType.Rut].includes(invoiceType)
          ) {
            return true;
          }
        }
      });
    });
  };

  public getInvoiceTypeString = (invoiceType: number): string => {
    return this.invoiceTypeString[invoiceType] || '';
  };
}
