<div
  class="docu-preview-container"
  *appLoaded="
    document | async as loadedDocument;
    notFoundFilter: notFoundFilter
  "
>
  <app-seo-info
    [title]="loadedDocument.name"
    [description]="loadedDocument.metaDescription"
  ></app-seo-info>
  <div class="docu-preview-upsell">
    <h1 class="docu-folder-{{ loadedDocument.folder.color }}">
      {{ loadedDocument.name }}
    </h1>
    <div class="docu-preview-upsell-content">
      <div class="docu-preview-explanation" *ngIf="loadedDocument.explanation">
        {{ loadedDocument.explanation }}
      </div>
      <ul class="docu-preview-advantages">
        <li>Anpassa denna dokumentmall efter dina önskemål</li>
        <li>Lägg till din egen logga</li>
        <li>Spara dina egna mallar som du själv anpassat</li>
        <li>Skriv ut eller ladda ner som pdf</li>
      </ul>
      <button
        (click)="showSignupForm()"
        pButton
        type="button"
        label="Registrera dig gratis!"
        class="docu-preview-btn-cta"
      ></button>
    </div>
  </div>
  <div class="docu-preview-document">
    <iframe *appLoaded="pdfUrl | async as pdfUrlLoaded" [src]="pdfUrlLoaded">
      Din webbläsare stödjer tyvärr inte PDF-dokument. Vänligen ladda ner
      exemplet istället.
    </iframe>
  </div>
</div>
<app-docu-header-folders docuRoot="../.."></app-docu-header-folders>
