/* eslint-disable @typescript-eslint/no-shadow */
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { List } from 'immutable';
import { map } from 'rxjs/operators';
import {
  Observable,
  of as observableOf,
  combineLatest,
  Subscription,
} from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/sv';

import { Document, cloneDocument, DocumentRow, ServerId } from './document';
import { ApolloQueryService } from '../shared/apollo';
import {
  DocumentMetadata,
  DocumentProject,
  DocumentFolder,
} from './document-metadata';
import { DocumentChanges, cloneDocumentChanges } from './document-change';
import { ApolloMutationService } from '../shared/apollo/index';
import { CompanyDefinitionService } from '../shared/company/company-definition.service';

export const urlPrefix = environment.urlPrefix;
const defaultColor = 'gray';
const trialMaxSaves = Infinity;
const trialMaxExports = 3;

export interface Pricing {
  yearly: number;
}

export enum InvoicePeriod {
  Yearly = 'Yearly',
}

export interface DocumentSubscriptionInfo {
  invoicePeriod: InvoicePeriod;
  companyAddress: string;
  companyCity: string;
  companyCityCode: string;
}

function cleanProject(project: any): DocumentProject {
  return new DocumentProject({
    id: Number(project.id),
    mark: project.mark,
    showId: Number(project.trueId),
  });
}

function cleanFolder(
  folder: any,
  parent: DocumentFolder = null
): DocumentFolder {
  let folderMetadata = new DocumentFolder({
    id: Number(folder.id),
    name: folder.name,
    description: folder.description,
    color: folder.color || defaultColor,
    parent,
  });
  folderMetadata = folderMetadata.set(
    'folders',
    folder.folders &&
      List<any>(folder.folders.edges).map(subFolder =>
        cleanFolder(subFolder.node, folderMetadata)
      )
  );
  return folderMetadata.set(
    'documents',
    folder.templateDocuments &&
      List<any>(folder.templateDocuments.edges).map(doc =>
        cleanDocumentMetadata(doc.node, folderMetadata)
      )
  );
}

function cleanDocumentMetadata(
  doc: any,
  folder: DocumentFolder = null
): DocumentMetadata {
  return new DocumentMetadata({
    id: Number(doc.id),
    name: doc.templateName,
    title: doc.title || doc.templateName,
    date: moment(doc.date).locale('sv'),
    type: doc.type,
    projectId: Number(doc.projectId),
    explanation: doc.templateExplanation,
    metaDescription: doc.metaDescription || doc.templateExplanation,
    project: doc.project && cleanProject(doc.project),
    folder: folder || cleanFolder(doc.documentFolder),
  });
}

@Injectable()
export class DocumentService implements OnDestroy {
  companyProjectsSub: Subscription;
  public readonly yearlyPrice = 2999;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private http: HttpClient,
    private mutationService: ApolloMutationService,
    private companyDefinitionsService: CompanyDefinitionService
  ) {}

  getTemplateList(folderId: ServerId): Observable<DocumentFolder> {
    return this.getFolders().pipe(
      map(folders => folders.flatMap(folder => folder.folders.push(folder))),
      map(folders =>
        folders.find(folder => {
          // eslint-disable-next-line eqeqeq
          return folder.id == folderId;
        })
      )
    );
  }

  getMetadata(id: ServerId): Observable<DocumentMetadata> {
    return this.apolloQueryService.apolloQuery('document', { id }).pipe(
      map<any, DocumentMetadata>(response => {
        const doc = response.data.documentGlobal;
        if (doc === null) {
          return undefined;
        } else {
          return cleanDocumentMetadata(doc);
        }
      })
    );
  }

  // TODO: Move these into some kind of project service?
  getProjects(): Observable<List<DocumentProject>> {
    return this.apolloQueryService
      .apolloQuery('companyProjects', { status: [0, 1, 2] })
      .pipe(
        map<any, List<DocumentProject>>(response => {
          this.companyProjectsSub = response.sub;
          const projects = response.data.company.projects.edges;
          return List(projects.map(proj => cleanProject(proj.node)));
        })
      );
  }

  getProject(id: ServerId): Observable<DocumentProject> {
    return this.apolloQueryService.apolloQuery('singleProject', { id }).pipe(
      map<any, DocumentProject>(
        response =>
          new DocumentProject({
            id: response.data.id,
            mark: response.data.mark,
            showId: response.data.trueId,
          })
      )
    );
  }

  getCopyUrl(id: ServerId): string {
    return `${urlPrefix}/document/copy/${id}`;
  }
  getPdfUrl(id: ServerId, preview: boolean, showWatermark = true): string {
    const actionType = preview ? 'showPDF' : 'getPDF';
    const mode = showWatermark ? 0 : 1;
    return `${urlPrefix}/document/${id}?type=${actionType}&mode=${mode}`;
  }

  private getDocumentUrl(id: ServerId): string {
    return `${urlPrefix}/document/getJson/${id}`;
  }
  getDocument(id: ServerId): Observable<Document> {
    return this.http
      .get<List<DocumentRow>>(this.getDocumentUrl(id))
      .pipe(map(doc => cloneDocument(doc)));
  }

  private copyDocumentUrl(id: ServerId, status = 1): string {
    return `${urlPrefix}/document/copyHyperion/id/${id}/status/${status}`;
  }
  copyDocument(id: ServerId, status = 1): Observable<ServerId> {
    return this.http
      .get<{ id: ServerId }>(this.copyDocumentUrl(id, status))
      .pipe(map(doc => doc.id));
  }

  /* Saves a document to the server and returns the new metadata
     TODO: Error handling
   */
  saveDocument(
    changes: DocumentChanges,
    projectId = null,
    overrideWithProjectData = false
  ): Observable<DocumentChanges> {
    const data = changes.toJS();
    projectId && (data['projectId'] = projectId);
    data['overrideWithProjectData'] = overrideWithProjectData;
    console.log(data);

    return this.http
      .post<DocumentChanges>(`${urlPrefix}/document/handleChanges`, data)
      .pipe(map(cloneDocumentChanges));
  }

  deleteDocument(id: ServerId): Observable<void> {
    console.log('Deleting:', id);
    return this.mutationService
      .constructQueryAndExecuteMutation('Document', 'delete', false, { id })
      .pipe(
        map(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            return executedData;
          },
          err => {
            console.warn(err);
            return err;
          }
        )
      );
  }

  getFolders(): Observable<List<DocumentFolder>> {
    return this.apolloQueryService.apolloQuery('documentTemplates', {}).pipe(
      map<any, List<DocumentFolder>>(response => {
        return List<any>(response.data.globalDocumentsByFolder.edges).map(
          folder => cleanFolder(folder.node)
        );
      })
    );
  }

  ngOnDestroy() {
    this.companyProjectsSub && this.companyProjectsSub.unsubscribe();
  }

  remainingSaves(): Observable<number> {
    const saveCount = this.apolloQueryService
      .apolloQuery('documentSaveCount', {})
      .pipe(
        map<any, number>(response => response.data.me.documentSaves.totalCount)
      );

    return combineLatest(
      this.companyDefinitionsService.hasSubscribed(),
      saveCount
    ).pipe(
      map(
        ([subscribed, saveCount]) =>
          (subscribed ? Infinity : trialMaxSaves) - (saveCount as number)
      )
    );
  }

  remainingExports(): Observable<number> {
    const exportCount = this.apolloQueryService
      .apolloQuery('documentExportCount', {})
      .pipe(
        map<any, number>(
          response => response.data.me.documentExports.totalCount
        )
      );
    return combineLatest(
      this.companyDefinitionsService.hasSubscribed(),
      exportCount
    ).pipe(
      map(
        ([subscribed, exportCount]) =>
          (subscribed ? Infinity : trialMaxExports) - (exportCount as number)
      )
    );
  }

  pricing(): Observable<Pricing> {
    return observableOf({
      yearly: this.yearlyPrice,
    });
  }

  shouldShowUpgradeBanner(): Observable<boolean> {
    return this.companyDefinitionsService.documentCanActivateProjectTestPeriod();
  }

  activateSubscription(subInfo: DocumentSubscriptionInfo): Observable<boolean> {
    console.log('TODO: implement subscription', subInfo);
    return observableOf(true);
  }
}

export enum SaveAction {
  Save,
  SaveAs,
}
