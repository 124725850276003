import { gql } from 'apollo-angular';

import { GQLFragment } from '.';

const FRAGMENT_CONTACT_SETTINGS_BASE = gql`
  fragment ContactSettingsBase on Contact {
    id
    address
    address2
    city
    cityCode
    mail
    mobilePhone
    name
    orderBuisnessName
    trueId
    contactType
  }
` as unknown as GQLFragment;

const FRAGMENT_CONTACT_SETTINGS = gql`
  fragment ContactSettings on Contact {
    ...ContactSettingsBase
    bankgiro
    orgNr
    plusgiro
  }
  ${FRAGMENT_CONTACT_SETTINGS_BASE}
` as unknown as GQLFragment;

const FRAGMENT_CLIENT_CONTACT_BASE = gql`
  fragment ClientContactBase on Contact {
    id
    name
    orderBuisnessName
    mail
    address
  }
` as unknown as GQLFragment;

export const Contact = {
  fragments: {
    clientContactBase: FRAGMENT_CLIENT_CONTACT_BASE,
    settings: FRAGMENT_CONTACT_SETTINGS,
    settingsBase: FRAGMENT_CONTACT_SETTINGS_BASE,
  },
};

const CONTACT_INVOICES = {
  query: gql`
    query contactInvoices($contactId: Int) {
      company {
        id
        contacts(id: $contactId) {
          edges {
            node {
              id
              children {
                totalCount
                edges {
                  node {
                    ...ContactSettingsBase
                    invoices {
                      totalCount
                      edges {
                        node {
                          id
                          fakturaNr
                          fdatum
                          ffdatum
                          totalBox
                          contact {
                            id
                            orderBuisnessName
                            trueId
                          }
                          projects_PreInvoiceTypeHyperion {
                            edges {
                              node {
                                id
                                trueId
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${FRAGMENT_CONTACT_SETTINGS_BASE}
  `,
  testVariables: {
    contactId: 3582,
  },
};

const CONTACT_PROJECTS = {
  query: gql`
    query contactProjects($contactId: Int) {
      company {
        id
        contacts(id: $contactId) {
          edges {
            node {
              id
              children {
                totalCount
                edges {
                  node {
                    ...ContactSettingsBase
                    projects {
                      totalCount
                      edges {
                        node {
                          id
                          trueId
                          clientContact {
                            ...ClientContactBase
                            __typename
                          }
                          establishmentContact {
                            id
                            name
                            address
                            __typename
                          }
                          mark
                          startDate
                          endDate
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${FRAGMENT_CONTACT_SETTINGS_BASE}
    ${FRAGMENT_CLIENT_CONTACT_BASE}
  `,
  testVariables: {
    contactId: 3582,
  },
};

export const queries = {
  contactInvoices: CONTACT_INVOICES,
  contactProjects: CONTACT_PROJECTS,
};
