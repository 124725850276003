import { Input, Output, EventEmitter, OnInit, Directive } from '@angular/core';

@Directive()
export class FormCommonFunctions2 implements OnInit {
  // common
  @Output() onBlur = new EventEmitter();
  htmlAttributesDefaultValues = {
    label: {
      value: null,
      responsive: false,
    },
    input: {
      type: 'text',
      id: null,
      tabIndex: null,
      placeholder: null,
    },
  };
  @Input() autocomplete = 'on';

  year = 2019;

  @Input() tooltip;
  @Input() inputParams;
  @Input() htmlAttributes = this.htmlAttributesDefaultValues;
  @Input() function: string;
  @Input() styleClass: string = null;
  @Input() public label: string = null;
  // p-inputgroup Functions
  @Output() rightFuncCall = new EventEmitter<any>();
  @Output() leftFuncCall = new EventEmitter<any>();
  // TextArea
  @Input() rows: number;
  // calender
  @Input() showTime = false;
  @Input() timeOnly = false;
  @Input() selectionMode = 'single';
  @Input() yearNavigator = true;
  @Input() yearRange: string = this.year - 4 + ':' + (this.year + 4);
  @Input() inline = false;
  // DropDown
  @Input() appendTo = null;
  // General
  control: any;
  showToolTip = false;
  tooltipDefaultValues = {
    class: 'help-tooltip',
    tooltipStyleClass: 'inlineToopTip',
    tooltipPosition: 'bottom',
    msg: null,
  };

  ngOnInit() {
    const keys = Object.keys(this.htmlAttributes);
    keys.forEach(element => {
      this.htmlAttributesDefaultValues[element] = {
        ...this.htmlAttributesDefaultValues[element],
        ...this.htmlAttributes[element],
      };
    });
    this.htmlAttributes = this.htmlAttributesDefaultValues;
    this.ShowToolTip();
  }

  ShowToolTip() {
    if (this.tooltip && this.tooltip['msg']) {
      this.tooltip = { ...this.tooltipDefaultValues, ...this.tooltip };
      this.showToolTip = true;
    }
  }

  stopFormDisplay() {
    this.htmlAttributes = null;
    this.control = null;
  }

  public triggerRightFunc() {
    this.rightFuncCall.emit(event);
  }
  public triggerLeftFunc() {
    this.leftFuncCall.emit(event);
  }
}
