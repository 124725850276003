import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

@Injectable()
export class CompanyNoticeBoardService extends CompanySuperService {
  // dataModel = 'companyNotices_PreCompanyTypeHyperion';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  public dataFormater(data): {
    id: any;
    head: any;
    text: string;
    created: any;
    userId: any;
    user: any;
  }[] {
    const dataObjectsForForms = [];

    for (const edge of data.edges) {
      dataObjectsForForms.push({
        id: edge.node.id,
        head: edge.node.head,
        text: edge.node.text,
        created: edge.node.created,
        userId: edge.node.userId,
        user: edge.node.user,
      });
    }

    return dataObjectsForForms;
  }
}
