import { Component, OnInit, Input } from '@angular/core';

import { GlobalService } from 'app/shared/global';
import { HyperionLabelsService } from '../labels';
import { EmptyStatePlacement } from './empty-state-placement';

@Component({
  selector: 'app-emptyState',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss'],
})
export class EmptyStateComponent implements OnInit {
  @Input() private model: string;
  @Input() private whereToCreate: EmptyStatePlacement;
  @Input() public showDetail?: boolean = true;
  @Input() private createable = true;

  public msgHeader: string;
  public msgSub: string;
  public bgImgSrc: string;

  constructor(
    private globalService: GlobalService,
    private labelService: HyperionLabelsService
  ) {
    this.bgImgSrc = `${this.globalService.getUrlPrefix()}/images/hyperion/emptyTrolly.png`;
  }

  ngOnInit() {
    let postStrings: string[] = [];
    const postStringsDefault = ['post', 'posten', 'posterna', 'poster'];

    if (this.model) {
      this.labelService.getLabels([{ model: this.model }]).subscribe(data => {
        if (data[this.model]) {
          const l = data[this.model];
          if (l['MODEL_LABEL_0']) {
            postStrings.push(l['MODEL_LABEL_0']);
          }
          if (l['MODEL_LABEL_1']) {
            postStrings.push(l['MODEL_LABEL_1']);
          }
          if (l['MODEL_LABEL_2']) {
            postStrings.push(l['MODEL_LABEL_2']);
          }
          if (l['MODEL_LABEL_3']) {
            postStrings.push(l['MODEL_LABEL_3']);
          }
        }

        if (postStrings.length !== 4) {
          postStrings = postStringsDefault;
        }
        this.setEmptyState(postStrings);
      });
    } else {
      this.setEmptyState(postStringsDefault);
    }
  }

  private setEmptyState(postStrings: string[]): void {
    this.msgHeader = `Inga ${postStrings[3]} hittades.`;
  }
}
