import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { CompanySuperService } from '../company.super.service';
import { ApolloErrorHandler } from 'app/shared/apollo';

@Injectable()
export class ProjectCostTypeService extends CompanySuperService {
  dataModel = 'projectCostType';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list') =>
    mode === 'labels'
      ? this.makeLabelsArray(data)
      : this.makeObjectsForForms(data);

  makeLabelsArray(data) {
    const dataArray =
      data.company.projects.edges[0].node[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      if (this.isActive(dataArray[i].node)) {
        const dataObject = {
          procent: dataArray[i].node.procent,
          isMaterial: dataArray[i].node.procent,
          name: dataArray[i].node.name,
          id: dataArray[i].node.id,
        };
        dataObjectsForForms.push(dataObject);
      }
    }
    return dataObjectsForForms;
  }

  getProducts(data) {
    const products = [];

    for (const projectCostTypeIndex in data) {
      const projectcosttype = data[projectCostTypeIndex];

      for (const productIndex in projectcosttype['products']) {
        const productObject = projectcosttype['products'][productIndex];

        products.push(productObject);
      }
    }

    return products;
  }

  summarize(data) {
    const summarizedProjectCostTypes = data;

    for (const costTypeIndex in summarizedProjectCostTypes) {
      const projectCostType = summarizedProjectCostTypes[costTypeIndex];
      let sumOfCostType = 0;

      const projectProducts = this.cleanFromNode(projectCostType['products']);
      for (const productIndex in projectProducts) {
        const product = projectProducts[productIndex];
        const sumForProduct = product['antal'] * product['avtalspris'];
        const fixedKeyVal = sumForProduct.toFixed(2);

        product['totalSum'] = Number(fixedKeyVal);
        sumOfCostType += sumForProduct;
      }

      projectCostType['products'] = projectProducts;
      projectCostType['sum'] = Math.round(sumOfCostType * 100) / 100;
    }

    return summarizedProjectCostTypes;
  }

  cleanFromNode(dataParam) {
    const dataArray = dataParam.edges;
    const dataArrayWithObjects = [];
    for (const object in dataArray) {
      if (typeof dataArray[object]['node'] !== 'undefined') {
        dataArrayWithObjects.push(Object.assign({}, dataArray[object]['node']));
      }
    }

    return dataArrayWithObjects;
  }

  private isActive(node: any): boolean {
    // eslint-disable-next-line eqeqeq
    return node.active == 1;
  }
}
