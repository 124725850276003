<app-header>
  <p-button
    type="button"
    icon="pi pi-download"
    label="Exportera"
    [style]="{ 'margin-right': '10px' }"
    (onClick)="exportProjectPlanner()"
  ></p-button>
  <p-button
    type="button"
    icon="pi pi-list"
    label="Skapa arbetsmoment"
    [style]="{ 'margin-right': '10px' }"
    [disabled]="isLoading"
    (onClick)="createNewWorkTask()"
  >
  </p-button>
  <p-button
    type="button"
    icon="pi pi-plus"
    label="Skapa planerad tid"
    [disabled]="isLoading"
    [styleClass]="'p-button-primary-hyperion'"
    (onClick)="createNewPlannedTime()"
  >
  </p-button>
</app-header>

<div class="page-container p-grid">
  <div [formGroup]="filterForm" class="p-col-12 p-grid p-p-0">
    <div class="p-col-3 p-lg-3">
      <label>Från</label>
      <p-calendar
        dateFormat="yy-mm-dd"
        showWeek="true"
        formControlName="startDate"
        [locale]="datePickerLocale"
        [showIcon]="true"
        [showWeek]="true"
      ></p-calendar>
    </div>
    <div class="p-col-3 p-lg-3">
      <label>Till</label>
      <p-calendar
        dateFormat="yy-mm-dd"
        showWeek="true"
        formControlName="endDate"
        [locale]="datePickerLocale"
        [showIcon]="true"
        [showWeek]="true"
      ></p-calendar>
    </div>
    <div class="p-col-3 p-lg-2">
      <label>Vy</label>
      <p-dropdown
        [options]="zoomLevelDropdown"
        formControlName="selectedZoomLevel"
      ></p-dropdown>
    </div>
    <div class="p-col-3 p-lg-4">
      <label>&NonBreakingSpace;</label>
      <div style="width: 100%">
        <button
          pButton
          class="p-d-block"
          label="Idag"
          style="float: right"
          icon="pi pi-calendar"
          [disabled]="isLoading"
          (click)="showToday()"
        ></button>
      </div>
    </div>
    <div class="p-col-4 p-lg-2">
      <label>Projekt</label>
      <p-multiSelect
        #filterProjects
        [options]="[]"
        optionLabel="label"
        defaultLabel="Välj projekt.."
        formControlName="projects"
        selectedItemsLabel="{0} valda"
        maxSelectedLabels="1"
        appendTo="body"
        [overlayOptions]="{ appendTo: 'body' }"
      ></p-multiSelect>
    </div>
    <div class="p-col-4 p-lg-2">
      <label>Filter</label>
      <p-treeSelect
        formControlName="filter"
        class="p-d-block"
        selectionMode="checkbox"
        [options]="filterDropdown"
        placeholder="Välj filter..."
        [overlayOptions]="{ appendTo: 'body' }"
      ></p-treeSelect>
    </div>
    <div class="p-col-5 p-d-none p-d-lg-flex"></div>
    <div class="p-col-4 p-lg-3">
      <label>Sparade lägen </label>
      <span class="p-inputgroup">
        <p-dropdown
          #stateDropdown
          formControlName="selectedState"
          placeholder="Ange namn för nytt läge..."
          [options]="statesDropdown"
          [overlayOptions]="{ appendTo: 'body' }"
          [editable]="
            stateDropdown?.value === null || stateDropdown.value?.length >= 0
          "
        >
          <ng-template pTemplate="item" let-item>
            <div
              class="p-d-flex p-jc-between p-ai-center p-p-1"
              style="width: 100%"
            >
              {{ item.label }}
              <button
                *ngIf="item.value !== null && isAdmin"
                pButton
                class="legacy-rounded-button p-button-danger-inverted"
                icon="pi pi-trash"
                pTooltip="Ta bort läge"
                (click)="deleteState(item.value)"
              ></button>
            </div>
          </ng-template>
        </p-dropdown>
        <button
          *ngIf="showResetButton"
          pButton
          class="p-inputgroup-addon p-button-brand-primary"
          icon="pi pi-replay"
          tooltipPosition="bottom"
          pTooltip="Återställ filtreringar"
          (click)="resetState()"
        ></button>
        <button
          *ngIf="!stateDropdown.value?.length && stateDropdown.value !== null"
          pButton
          class="p-inputgroup-addon p-button-primary-hyperion"
          icon="pi pi-save"
          tooltipPosition="bottom"
          pTooltip="Uppdatera valt läge"
          (click)="updateState()"
        ></button>
        <button
          *ngIf="stateDropdown.value?.length || stateDropdown.value === null"
          pButton
          class="p-inputgroup-addon p-button-primary-hyperion"
          icon="pi pi-plus"
          tooltipPosition="bottom"
          pTooltip="Lägg till nytt sparat läge"
          (click)="createState()"
          [disabled]="stateDropdown.value === null"
        ></button>
      </span>
    </div>
  </div>

  <div #gantt class="dhx-gantt-container p-col-12"></div>

  <div class="p-col-12 p-mt-2 p-d-flex p-jc-center">
    <div>
      <b>Navigation</b><br />
      • Du kan använda scrollhjulet på musen för att navigera upp och ner.<br />
      • Du kan använda SHIFT + scrollhjulet på hjulet för att navigera i
      sidled.<br />
      • Du kan använda CONTROL + scrollhjulet på hjulet för att zooma.
    </div>
  </div>

  <div #customLightBox class="custom-lightbox">
    <app-edit-planned-time-view
      *ngIf="
        ganttEventPlannedTime &&
        ganttEventPlannedTime.eventType === EventTypeEnum.PlannedTime
      "
      [scheduledEvent]="ganttEventPlannedTime"
      [isNewEvent]="isNewEvent"
      [allTodos]="allTodos"
      [projects]="projectsData"
      [coworkersDropdown]="coworkersDropdown"
      (closeLightBox)="handleCloseEvent()"
      (saveEvent)="handleAfterSavePlannedTimeEvent($event)"
      (updateEvent)="handleUpdatePlannedTimeEvent($event)"
      (deleteEvent)="handleDeleteEvent($event)"
    ></app-edit-planned-time-view>
    <app-edit-work-task-view
      *ngIf="
        ganttEventPlannedTime &&
        ganttEventPlannedTime.eventType === EventTypeEnum.WorkTask
      "
      [scheduledEvent]="ganttEventPlannedTime"
      [isNewEvent]="isNewEvent"
      [projects]="projectsData"
      [todotopicsDropdown]="todotopicsDropdown"
      (closeLightBox)="handleCloseEvent()"
      (saveEvent)="handleAfterSaveWorkTaskEvent($event)"
      (updateEvent)="handleUpdateWorkTaskEvent($event)"
      (deleteEvent)="handleDeleteEvent($event)"
    >
    </app-edit-work-task-view>
    <app-edit-project-view
      *ngIf="
        ganttEventPlannedTime &&
        ganttEventPlannedTime.eventType === EventTypeEnum.Project
      "
      [scheduledEvent]="ganttEventPlannedTime"
      [childrenEdgeDates]="childrenEdgeDates"
      (closeLightBox)="handleCloseEvent()"
      (updateEvent)="handleUpdateProject($event)"
    ></app-edit-project-view>
    <app-export-pdf
      *ngIf="isExporting"
      [isExporting]="isExporting"
      [startDate]="filterForm.controls['startDate'].value"
      [endDate]="filterForm.controls['endDate'].value"
      (closeLightBox)="handleCloseEvent()"
      (export)="exportFile($event)"
    >
    </app-export-pdf>
  </div>

  <!-- Context menu, hidden on load -->
  <app-color-context-menu
    [title]="colorContextMenuTitle"
    [contextMenuEventObserver]="contextMenuEventSubject.asObservable()"
    (updateColorEvent)="handleUpdateColorEvent($event)"
  ></app-color-context-menu>
</div>
