<div *ngIf="loading" style="padding: 50px">
  <spinner></spinner>
</div>
<div *ngIf="!loading" class="p-grid" style="width: 70vw; height: 60vh">
  <div class="p-col-12 p-grid" style="height: 100%">
    <div class="p-col-6">
      <h2>Faktura {{ invoice.fakturaNr }} skapades</h2>

      <span class="link" pTooltip="Hämta PDF" (click)="getPDF()"
        ><i class="pi pi-file-pdf"></i> Hämta </span
      ><br />
      <br />
      <span class="link" pTooltip="Skicka faktura" (click)="showMailModule()"
        ><i class="pi pi-envelope"></i> Skicka </span
      ><br />
      <br />
      <a [routerLink]="'invoice/' + invoice.id" (click)="dialogRef.close()">
        <i class="pi pi-list"></i>
        Visa faktura
      </a>
      <br />
      <span *ngIf="invoice.projectsTroughRows.length > 0">
        <br />
        <span class="link" pTooltip="Underlag" (click)="openInvoiceBase()"
          ><i class="pi pi-file"></i> Visa fakturaunderlag</span
        >
        <br />
      </span>

      <span *ngIf="invoice.projectId > 0">
        <br />
        <a
          pTooltip="Öppna projektet"
          [routerLink]="'project/' + invoice.projectId"
          (click)="dialogRef.close()"
        >
          <i class="pi pi-briefcase"></i> Hantera projekt</a
        >
        <br />
      </span>

      <span *ngFor="let project of invoice.projectsTroughRows">
        <a
          *ngIf="invoice.projectId !== project.id"
          class="link"
          pTooltip="Öppna projektet"
          [routerLink]="'project/' + project.id"
          (click)="dialogRef.close()"
          ><i class="pi pi-briefcase"></i> Hantera {{ project.trueId }},
          {{ project.mark }}</a
        >
        <br />
      </span>
    </div>
    <div class="p-col-6" style="height: 100%">
      <iframe
        [attr.src]="pdfUrl"
        class="pdf-view-object"
        frameborder="0"
        scrolling="no"
        style="width: 100%; height: 100%"
      >
        <p>
          Kan inte läsa pdf i webbläsaren.
          <a [href]="pdfUrl" target="_blank">Tryck här för att ladda ned.</a>
        </p>
      </iframe>
    </div>
  </div>
</div>
