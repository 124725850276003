import { ElementRef, Injectable } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class PrintPdfService {
  public generatePdf(ref: ElementRef, name?: string): void {
    html2canvas(ref.nativeElement, { scale: 3 }).then(canvas => {
      const imageGeneratedFromTemplate = canvas.toDataURL('image/png');
      const fileWidth = 200;
      const generatedImageHeight = (canvas.height * fileWidth) / canvas.width;
      const PDF = new jsPDF('p', 'mm', 'a4');
      PDF.addImage(
        imageGeneratedFromTemplate,
        'PNG',
        0,
        5,
        fileWidth,
        generatedImageHeight
      );
      PDF.html(ref.nativeElement.innerHTML);
      PDF.save((name ?? ref.nativeElement.nodeName) + '.pdf');
    });
  }
}
