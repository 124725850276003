import * as Types from '../../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProjectDaysQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProjectDaysQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', days?: { __typename?: 'DayConnection', totalCount: number, edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, invoiceId?: number | null, hours?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, doneWork?: string | null, date?: string | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, costTypeId?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, extra?: number | null, newCostTypeId?: number | null, overtimeHardCoded?: string | null, notarized?: number | null, todoId?: number | null, notarizedUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null } | null } | null> | null } | null } | null };

export type FetchTimeReportHeadersQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  extra?: Types.InputMaybe<Types.Scalars['Boolean']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchTimeReportHeadersQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', timeReportHeaders?: { __typename?: 'TimeReportHeaderConnection', totalCount: number, edges?: Array<{ __typename?: 'TimeReportHeaderEdge', node?: { __typename?: 'TimeReportHeader', id: string, date?: string | null, message?: string | null, hours?: number | null, hoursToInvoice?: number | null, miles?: number | null, milesToInvoice?: number | null, milesPrivate?: number | null, extra?: boolean | null, isFullyInvoiced?: boolean | null } | null } | null> | null } | null } | null };

export type FetchSingleTimeReportHeaderQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  id?: Types.InputMaybe<Types.Scalars['ID']>;
  date?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchSingleTimeReportHeaderQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', timeReportHeaders?: { __typename?: 'TimeReportHeaderConnection', edges?: Array<{ __typename?: 'TimeReportHeaderEdge', node?: { __typename?: 'TimeReportHeader', id: string, date?: string | null, message?: string | null, hours?: number | null, hoursToInvoice?: number | null, miles?: number | null, milesToInvoice?: number | null, milesPrivate?: number | null, extra?: boolean | null, isFullyInvoiced?: boolean | null } | null } | null> | null } | null } | null };

export type FetchTimeReportsForDateQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  date?: Types.InputMaybe<Types.Scalars['String']>;
  extra?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchTimeReportsForDateQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', days?: { __typename?: 'DayConnection', totalCount: number, edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, invoiceId?: number | null, hours?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, doneWork?: string | null, date?: string | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, costTypeId?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, extra?: number | null, newCostTypeId?: number | null, overtimeHardCoded?: string | null, notarized?: number | null, todoId?: number | null, notarizedUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null } | null } | null> | null } | null } | null };

export type FetchTimeReportsForBetweenDatesQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  fromDate?: Types.InputMaybe<Types.Scalars['String']>;
  toDate?: Types.InputMaybe<Types.Scalars['String']>;
  extra?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchTimeReportsForBetweenDatesQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', days?: { __typename?: 'DayConnection', totalCount: number, edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, invoiceId?: number | null, hours?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, doneWork?: string | null, date?: string | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, costTypeId?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, extra?: number | null, newCostTypeId?: number | null, overtimeHardCoded?: string | null, notarized?: number | null, todoId?: number | null, notarizedUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null } | null } | null> | null } | null } | null };

export type FetchSingleTimeReportQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchSingleTimeReportQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', days?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, invoiceId?: number | null, hours?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, doneWork?: string | null, date?: string | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, costTypeId?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, extra?: number | null, newCostTypeId?: number | null, overtimeHardCoded?: string | null, notarized?: number | null, todoId?: number | null, notarizedUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null } | null } | null> | null } | null } | null };

export type DeleteDayMutationVariables = Types.Exact<{
  deleteDay?: Types.InputMaybe<Types.DayTypeHyperionMutationInput>;
}>;


export type DeleteDayMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type SetNotarizedMutationVariables = Types.Exact<{
  day?: Types.InputMaybe<Types.DayTypeHyperionMutationInput>;
}>;


export type SetNotarizedMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type UpdateTextMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  text?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateTextMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type SetDayExtraMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  extra?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SetDayExtraMutation = { __typename?: 'HyperionSchemaMutation', setDayExtraMutation?: { __typename?: 'Day', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type RemoveTimeReportInvoiceConnectionMutationVariables = Types.Exact<{
  timeReportId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RemoveTimeReportInvoiceConnectionMutation = { __typename?: 'HyperionSchemaMutation', removeTimeReportInvoiceConnectionMutation?: { __typename?: 'Day', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export type ProjectTimeReportHeaderFragment = { __typename?: 'TimeReportHeader', id: string, date?: string | null, message?: string | null, hours?: number | null, hoursToInvoice?: number | null, miles?: number | null, milesToInvoice?: number | null, milesPrivate?: number | null, extra?: boolean | null, isFullyInvoiced?: boolean | null };

export type ProjectDayFragment = { __typename?: 'Day', id: string, invoiceId?: number | null, hours?: number | null, hoursToInvoice?: number | null, privMile?: number | null, mile?: number | null, mileToInvoice?: number | null, doneWork?: string | null, date?: string | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, costTypeId?: number | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, extra?: number | null, newCostTypeId?: number | null, overtimeHardCoded?: string | null, notarized?: number | null, todoId?: number | null, notarizedUser?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', id: string, companyCostTypeId?: number | null } | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, todoRelation?: { __typename?: 'DayTodoRelation', id: string, todo_id?: number | null } | null };

export const ProjectTimeReportHeaderFragmentDoc = gql`
    fragment ProjectTimeReportHeader on TimeReportHeader {
  id
  date
  message
  hours
  hoursToInvoice
  miles
  milesToInvoice
  milesPrivate
  extra
  isFullyInvoiced
}
    `;
export const ProjectDayFragmentDoc = gql`
    fragment ProjectDay on Day {
  id
  invoiceId
  hours
  hoursToInvoice
  privMile
  mile
  mileToInvoice
  doneWork
  date
  startTime
  stopTime
  userId
  costTypeId
  subsistenceDay
  subsistenceHalfDay
  subsistenceNight
  extra
  newCostTypeId
  overtimeHardCoded
  notarized
  notarizedUser {
    id
    firstName
    lastName
  }
  costTypeHyperion {
    id
    companyCostTypeId
  }
  user {
    id
    firstName
    lastName
  }
  todoId
  todoRelation {
    id
    todo_id
  }
}
    `;
export const GetProjectDaysDocument = gql`
    query getProjectDays($projectId: Int) {
  project(id: $projectId) {
    days {
      totalCount
      edges {
        node {
          ...ProjectDay
        }
      }
    }
  }
}
    ${ProjectDayFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectDaysGQL extends Apollo.Query<GetProjectDaysQuery, GetProjectDaysQueryVariables> {
    document = GetProjectDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchTimeReportHeadersDocument = gql`
    query fetchTimeReportHeaders($projectId: Int, $extra: Boolean, $first: Int, $offset: Int) {
  project(id: $projectId) {
    timeReportHeaders(extra: $extra, first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          ...ProjectTimeReportHeader
        }
      }
    }
  }
}
    ${ProjectTimeReportHeaderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchTimeReportHeadersGQL extends Apollo.Query<FetchTimeReportHeadersQuery, FetchTimeReportHeadersQueryVariables> {
    document = FetchTimeReportHeadersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSingleTimeReportHeaderDocument = gql`
    query fetchSingleTimeReportHeader($projectId: Int, $id: ID, $date: String) {
  project(id: $projectId) {
    timeReportHeaders(id: $id, fromDate: $date, toDate: $date) {
      edges {
        node {
          ...ProjectTimeReportHeader
        }
      }
    }
  }
}
    ${ProjectTimeReportHeaderFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSingleTimeReportHeaderGQL extends Apollo.Query<FetchSingleTimeReportHeaderQuery, FetchSingleTimeReportHeaderQueryVariables> {
    document = FetchSingleTimeReportHeaderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchTimeReportsForDateDocument = gql`
    query fetchTimeReportsForDate($projectId: Int, $date: String, $extra: Int) {
  project(id: $projectId) {
    days(fromDate: $date, toDate: $date, extra: $extra) {
      totalCount
      edges {
        node {
          ...ProjectDay
        }
      }
    }
  }
}
    ${ProjectDayFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchTimeReportsForDateGQL extends Apollo.Query<FetchTimeReportsForDateQuery, FetchTimeReportsForDateQueryVariables> {
    document = FetchTimeReportsForDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchTimeReportsForBetweenDatesDocument = gql`
    query fetchTimeReportsForBetweenDates($projectId: Int, $fromDate: String, $toDate: String, $extra: Int) {
  project(id: $projectId) {
    days(fromDate: $fromDate, toDate: $toDate, extra: $extra) {
      totalCount
      edges {
        node {
          ...ProjectDay
        }
      }
    }
  }
}
    ${ProjectDayFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchTimeReportsForBetweenDatesGQL extends Apollo.Query<FetchTimeReportsForBetweenDatesQuery, FetchTimeReportsForBetweenDatesQueryVariables> {
    document = FetchTimeReportsForBetweenDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSingleTimeReportDocument = gql`
    query fetchSingleTimeReport($projectId: Int, $id: Int) {
  project(id: $projectId) {
    days(id: $id) {
      edges {
        node {
          ...ProjectDay
        }
      }
    }
  }
}
    ${ProjectDayFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSingleTimeReportGQL extends Apollo.Query<FetchSingleTimeReportQuery, FetchSingleTimeReportQueryVariables> {
    document = FetchSingleTimeReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDayDocument = gql`
    mutation deleteDay($deleteDay: DayTypeHyperionMutationInput) {
  dayTypeHyperionMutation(deleteDay: $deleteDay) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDayGQL extends Apollo.Mutation<DeleteDayMutation, DeleteDayMutationVariables> {
    document = DeleteDayDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetNotarizedDocument = gql`
    mutation setNotarized($day: DayTypeHyperionMutationInput) {
  dayTypeHyperionMutation(updateDay: $day) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SetNotarizedGQL extends Apollo.Mutation<SetNotarizedMutation, SetNotarizedMutationVariables> {
    document = SetNotarizedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTextDocument = gql`
    mutation updateText($id: Int, $text: String) {
  dayTypeHyperionMutation(updateDay: {id: $id, doneWork: $text}) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTextGQL extends Apollo.Mutation<UpdateTextMutation, UpdateTextMutationVariables> {
    document = UpdateTextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDayExtraDocument = gql`
    mutation setDayExtra($id: Int, $extra: Boolean) {
  setDayExtraMutation(dayId: $id, extra: $extra) {
    id
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDayExtraGQL extends Apollo.Mutation<SetDayExtraMutation, SetDayExtraMutationVariables> {
    document = SetDayExtraDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTimeReportInvoiceConnectionDocument = gql`
    mutation removeTimeReportInvoiceConnection($timeReportId: Int) {
  removeTimeReportInvoiceConnectionMutation(timeReportId: $timeReportId) {
    id
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTimeReportInvoiceConnectionGQL extends Apollo.Mutation<RemoveTimeReportInvoiceConnectionMutation, RemoveTimeReportInvoiceConnectionMutationVariables> {
    document = RemoveTimeReportInvoiceConnectionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }