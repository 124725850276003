import { Apollo } from 'apollo-angular';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Subscription } from 'rxjs';

import { HttpService } from '../../shared/http/index';
import { MessageService } from '../../shared/message/index';
import { queries } from 'app/shared/apollo/queries/offers';
import {
  ApolloQueryService,
  HelperService,
  ApolloMutationService,
} from 'app/shared/index';

@Component({
  selector: 'app-create-offer',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferCreateComponent implements OnInit, OnDestroy {
  public offerVariable: { id: any; type: string };
  public offerMode: 'create' | 'created' | 'template' | 'createdFromTemplate' =
    'create';
  public projectId: any | string = null;

  private companyOfferTemplatesSub: Subscription;
  public offerTemplates = new BehaviorSubject([]);
  public setTemplateBtnActive = new BehaviorSubject(null);
  public childLoading = new BehaviorSubject(true);
  public loadingAsync = new BehaviorSubject(true);

  @Output() public deleteOffer = new EventEmitter<any>(null);

  constructor(
    private angularApollo: Apollo,
    private httpService: HttpService,
    private helperService: HelperService,
    private messageService: MessageService,
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.getOfferTemplates();
    this.createOffer();
  }

  private getOfferTemplates(): void {
    const queryName = 'companyOfferTemplates';
    this.apolloQueryService
      .apolloWatchQueryTwo(queryName, null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.companyOfferTemplatesSub = sub;
        const newDataSet = [
          ...this.helperService.cleanFromNode(
            data['company']['offerTemplates']
          ),
        ];
        newDataSet.map(template => {
          template['btns'] = [
            {
              label: 'Redigera',
              icon: 'pi pi-refresh',
              command: () => {
                this.editTemplate(+template['id']);
              },
            },
            {
              label: 'Radera',
              icon: 'pi pi-times',
              command: () => {
                this.deleteTemplate(+template['id']);
              },
            },
          ];
        });
        this.offerTemplates.next(newDataSet);
      });
  }

  public ngOnDestroy(): void {
    if (this.companyOfferTemplatesSub) {
      this.companyOfferTemplatesSub.unsubscribe();
    }
  }

  public createOffer(offerToCopyId = null): void {
    this.displayLoading();
    this.setTemplateBtnActive.next(offerToCopyId);
    if (offerToCopyId) {
      // If Id, its a template that should be  copied to a offer with status -3
      this.offerMode = 'createdFromTemplate';
      this.httpService
        .makeHttpPostRequest(
          '/offer/copy/id/' + offerToCopyId + '?copyType=offer&status=-3'
        )
        .subscribe(data => {
          if (data['status'] === 'success') {
            this.offerVariable = this.sendOfferId(+data['model']['id']);
            this.loadingAsync.next(false);
            this.getOfferTemplates();
          } else {
            this.messageService.insertData({
              textArray: [data['msg']],
              time: 2000,
              type: 'error',
            });
          }
        });
    } else {
      this.offerMode = 'create';
      const queryName = 'singleOffer';

      this.apolloQueryService
        .apolloQuery(queryName, { id: -3, type: '' })
        .subscribe(({ data }) => {
          // Write the query to place it in cache
          this.angularApollo.getClient().writeQuery({
            query: queries[queryName].query,
            variables: { id: +data['offer']['id'], type: '' },
            data: { offer: data['offer'] },
          });
          this.offerVariable = this.sendOfferId(+data['offer']['id']);
          this.loadingAsync.next(false);
        });
    }
  }

  private sendOfferId(id: number): { id: number; type: string } {
    return { id, type: '' };
  }

  private editTemplate(id: number): void {
    this.offerMode = 'template';
    this.displayLoading();
    this.offerVariable = this.sendOfferId(id);
    this.cdr.markForCheck();
    setTimeout(() => {
      // there is no callback when the offer is loaded so we simulate.... TODO
      this.childLoading.next(false);
      this.loadingAsync.next(false);
    }, 800);
  }

  private displayLoading(): void {
    this.childLoading.next(true);
    this.loadingAsync.next(true);
  }

  private deleteTemplate(id: number): void {
    const templateInfo = this.offerTemplates['value'].filter(
      el => +el['id'] === id
    );
    this.confirmationService.confirm({
      message: 'Vill du radera mall "' + templateInfo[0]['templateName'] + '"?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.mutationService
          .constructQueryAndExecuteMutation('Offer', 'update', false, {
            id: id,
            status: 4,
          })
          .subscribe(executedData => {
            if (executedData['mutationSucceededAllArguments']) {
              // load new offer template
              if (this.offerMode === 'template') {
                this.createOffer();
              }
              this.getOfferTemplates();
            }
            this.mutationService.displayMutationStatus(executedData);
          });
      },
      reject: () => {},
    });
  }
}
