<div *ngIf="formHasLoaded | async" class="create-project-product-wrapper">
  <form [formGroup]="createProjectProductForm" (ngSubmit)="actionCreate()">
    <div class="p-grid">
      <div class="p-lg-3">
        <appForm2-simple-dropDown
          formControlName="companyCostTypeId"
          [dropDownParams]="{
            options: functionsData.companyCostTypes,
            filter: true
          }"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '40' : '1') }
          }"
        ></appForm2-simple-dropDown>
      </div>

      <div class="p-lg-4">
        <div
          class="form-group"
          [class.has-danger]="
            createProjectProductForm.controls['benamning']['showErrors']
          "
        >
          <label
            class="form-control-label"
            [innerHTML]="projectLabels['Projectproduct']['benamning']"
            >{{
              createProjectProductForm.controls['benamning']['label']
            }}</label
          >
          <p-autoComplete
            [suggestions]="results | async"
            (completeMethod)="searchProducts($event)"
            inputStyleClass="form-control"
            (keyup)="setProductBenamning()"
            formControlName="benamning"
            (onSelect)="setProductFromAutosuggest($event)"
            [style]="{ width: '100%' }"
            [styleClass]="!showLargeTable ? 'wide-panel' : 'wide-panel-alt'"
            [inputStyle]="{ width: '100%' }"
            [(ngModel)]="productAutoModel"
            [inputId]="searchProductInputElementId"
            [tabindex]="projectInfo['id'] + (isExtra ? '41' : '2')"
          >
            <ng-template pTemplate="header">
              <div
                class="p-grid p-text-bold"
                style="margin: 0; padding: 0.2rem"
                *ngIf="showProductPrices; else hide_prices"
              >
                <div class="p-col-2 p-text-nowrap">Artnr</div>
                <div class="p-col-6 p-text-nowrap p-text-truncate">
                  Benämning
                </div>
                <div class="p-col-2 p-text-nowrap">Pris</div>
                <div class="p-col-2 p-text-nowrap">Inpris</div>
              </div>
              <ng-template #hide_prices>
                <div
                  class="p-grid p-text-bold"
                  style="margin: 0; padding: 0.2rem"
                >
                  <div class="p-col-2 p-text-nowrap">Artnr</div>
                  <div class="p-col-10 p-text-nowrap p-text-truncate">
                    Benämning
                  </div>
                </div>
              </ng-template>
            </ng-template>
            <ng-template let-product pTemplate="item">
              <div
                class="p-grid"
                *ngIf="showProductPrices; else hide_prices"
                style="margin: 0; padding: 0.2rem"
              >
                <div class="p-col-2 p-text-nowrap p-text-truncate">
                  {{ product.artnr }}
                </div>
                <div class="p-col-6 p-text-nowrap p-text-truncate">
                  {{ product.benamning }}
                </div>
                <div class="p-col-2 p-text-nowrap">
                  {{ product.avtalspris }}
                </div>
                <div class="p-col-2 p-text-nowrap">
                  {{ product.avtalsprisCost }}
                </div>
              </div>
              <ng-template #hide_prices>
                <div class="p-grid" style="width: 32rem">
                  <div class="p-col-2 p-text-nowrap">{{ product.artnr }}</div>
                  <div class="p-col-10 p-text-nowrap p-text-truncate">
                    {{ product.benamning }}
                  </div>
                </div>
              </ng-template>
            </ng-template>
          </p-autoComplete>
          <div class="p-py-2">
            <p-checkbox
              [binary]="true"
              [(ngModel)]="useOwnProducts"
              [ngModelOptions]="{ standalone: true }"
            ></p-checkbox>
            <span class="p-ml-2">
              <label
                >Använd endast egna produkter
                <span
                  class="help-tooltip"
                  tooltipPosition="top"
                  pTooltip="I systemet finns tusentals produkter inlagda. Vill du bara söka bland de produkter ni i företaget själva lagt in, välj detta alternativ."
                  [escape]="false"
                  >?</span
                ></label
              >
            </span>
          </div>
          <div
            *ngIf="createProjectProductForm.controls['benamning']['showErrors']"
          >
            <p
              *ngFor="
                let error of createProjectProductForm.controls.benamning[
                  'mergedErrors'
                ]
              "
              class="form-control-feedback"
            >
              <span><i class="fa fa-times"></i> </span
              ><span [innerHTML]="error"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="p-lg-1">
        <appForm2-simple-input
          formControlName="antal"
          (keyup.enter)="actionCreate()"
          (onBlur)="updatePrices()"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '43' : '4') }
          }"
        ></appForm2-simple-input>
      </div>

      <div class="p-lg-1">
        <appForm2-simple-input
          formControlName="enhet"
          (keyup.enter)="actionCreate()"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '44' : '5') }
          }"
        ></appForm2-simple-input>
      </div>

      <div class="p-lg-1" *ngIf="showAvtalsprisCost()">
        <appForm2-simple-input
          formControlName="avtalsprisCost"
          (keyup.enter)="actionCreate()"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '45' : '6') }
          }"
        ></appForm2-simple-input>
      </div>

      <div [class]="showAvtalsprisCost() ? 'p-lg-2' : 'p-lg-3'">
        <appForm2-simple-input
          formControlName="avtalspris"
          (keyup.enter)="actionCreate()"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '46' : '7') }
          }"
        ></appForm2-simple-input>
        <i
          *ngIf="formEditingIsDisabled"
          pTooltip="Töm formulär"
          (click)="initiateFormAndEnableFormEditing()"
          class="fa fa-lg fa-trash-o red s-flex-vertical-center"
        ></i>
      </div>
    </div>

    <div
      class="p-grid"
      [class.whitespace-timestamp-report]="useOnlyTimeStampReport"
    >
      <div class="p-col-4 p-md-3 p-g-nopad">
        <appForm2-simple-calendar
          class="p-g-nopad"
          formControlName="date"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '47' : '8') }
          }"
        ></appForm2-simple-calendar>
      </div>

      <div class="p-col-4 p-md-3 p-g-nopad">
        <appForm2-simple-dropDown
          *ngIf="meUser.type == '3'"
          label="Användare"
          formControlName="userId"
          [dropDownParams]="{ options: usersDropdown, filter: true }"
          [htmlAttributes]="{
            input: { tabIndex: projectInfo['id'] + (isExtra ? '48' : '9') }
          }"
        ></appForm2-simple-dropDown>
        <div *ngIf="meUser.type != '3'">
          Rapporterar för {{ meUser.firstName }} {{ meUser.lastName }}
          <input type="hidden" formControlName="userId" [value]="meUser.id" />
        </div>
      </div>

      <div class="p-col-12 p-md-6 p-sm-6 p-g-nopad">
        <div class="p-col-12 align-right btn-save">
          <app-split-button
            type="save"
            [model]="dataModelCapitalized"
            [btnModel]="productBtn"
            [disabled]="buttons.create.disabled"
            (onClick)="actionCreate()"
            [tabindex]="projectInfo['id'] + (isExtra ? '49' : '10')"
          ></app-split-button>
        </div>
      </div>
    </div>
  </form>
</div>
