import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetTodosQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetTodosQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, startDate?: string | null, endDate?: string | null, description?: string | null, orderNr?: number | null, estimatedTime?: number | null, timeUsed?: number | null, done?: number | null, topic?: { __typename?: 'Todotopic', id: string, Name?: string | null } | null, usersTodoRelation?: { __typename?: 'UserTodoRelationConnection', edges?: Array<{ __typename?: 'UserTodoRelationEdge', node?: { __typename?: 'UserTodoRelation', id: string, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export type GetUsersWithMailQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersWithMailQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null> | null } | null } | null };

export type TodoFragment = { __typename?: 'Todo', id: string, startDate?: string | null, endDate?: string | null, description?: string | null, orderNr?: number | null, estimatedTime?: number | null, timeUsed?: number | null, done?: number | null, topic?: { __typename?: 'Todotopic', id: string, Name?: string | null } | null, usersTodoRelation?: { __typename?: 'UserTodoRelationConnection', edges?: Array<{ __typename?: 'UserTodoRelationEdge', node?: { __typename?: 'UserTodoRelation', id: string, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null } | null> | null } | null };

export type UserFragment = { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, email?: string | null };

export type GetDaysQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetDaysQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', days?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', todoId?: number | null, hours?: number | null, todoRelation?: { __typename?: 'DayTodoRelation', todo_id?: number | null } | null } | null } | null> | null } | null } | null };

export const UserFragmentDoc = gql`
    fragment User on User {
  id
  firstName
  lastName
  email
}
    `;
export const TodoFragmentDoc = gql`
    fragment todo on Todo {
  id
  startDate
  endDate
  description
  orderNr
  estimatedTime
  timeUsed
  done
  topic {
    id
    Name
  }
  usersTodoRelation {
    edges {
      node {
        id
        user {
          ...User
        }
      }
    }
  }
}
    ${UserFragmentDoc}`;
export const GetTodosDocument = gql`
    query getTodos($projectId: Int) {
  project(id: $projectId) {
    todos {
      edges {
        node {
          ...todo
        }
      }
    }
  }
}
    ${TodoFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTodosGQL extends Apollo.Query<GetTodosQuery, GetTodosQueryVariables> {
    document = GetTodosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersWithMailDocument = gql`
    query getUsersWithMail {
  company {
    users {
      edges {
        node {
          ...User
        }
      }
    }
  }
}
    ${UserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersWithMailGQL extends Apollo.Query<GetUsersWithMailQuery, GetUsersWithMailQueryVariables> {
    document = GetUsersWithMailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDaysDocument = gql`
    query getDays($projectId: Int) {
  project(id: $projectId) {
    days {
      edges {
        node {
          todoId
          hours
          todoRelation {
            todo_id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDaysGQL extends Apollo.Query<GetDaysQuery, GetDaysQueryVariables> {
    document = GetDaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }