import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateProjectCostsFromCompanyUserCostTypeCostsMutationVariables = Types.Exact<{
  companyUserCostTypeId: Types.Scalars['Int'];
  internalCost?: Types.InputMaybe<Types.Scalars['Int']>;
  cost?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateProjectCostsFromCompanyUserCostTypeCostsMutation = { __typename?: 'HyperionSchemaMutation', updateProjectCostsFromCompanyUserCostTypeCostsMutation?: { __typename?: 'CompanyUserCostType', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const UpdateProjectCostsFromCompanyUserCostTypeCostsDocument = gql`
    mutation updateProjectCostsFromCompanyUserCostTypeCosts($companyUserCostTypeId: Int!, $internalCost: Int, $cost: Int) {
  updateProjectCostsFromCompanyUserCostTypeCostsMutation(
    companyUserCostTypeId: $companyUserCostTypeId
    internalCost: $internalCost
    cost: $cost
  ) {
    id
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectCostsFromCompanyUserCostTypeCostsGQL extends Apollo.Mutation<UpdateProjectCostsFromCompanyUserCostTypeCostsMutation, UpdateProjectCostsFromCompanyUserCostTypeCostsMutationVariables> {
    document = UpdateProjectCostsFromCompanyUserCostTypeCostsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }