<div class="p-grid">
  <div class="p-col-12 p-xxl-4">
    <hyperion-card>
      <div class="p-grid p-g-nopad">
        <div class="p-col-12">
          <h2>Dokument</h2>
          <p-fileUpload
            name="DocumentsForm[]"
            chooseLabel="Ange fil(er)"
            [multiple]="true"
            [url]="urlPrefix + '/offer/UploadDocuments/offerId/' + offerInfo.id"
            uploadLabel="Ladda upp"
            cancelLabel="Avbryt"
            invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
            invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
            (onUpload)="onUpload($event)"
          >
            <ng-template pTemplate="content">
              <app-drag-and-drop-template></app-drag-and-drop-template>
            </ng-template>
          </p-fileUpload>
        </div>
      </div>
    </hyperion-card>
  </div>

  <div class="p-col-12 p-xxl-8">
    <spinner *ngIf="loading | async"></spinner>
    <app-emptyState
      *ngIf="(documents | async).length === 0"
      [model]="'Document'"
      [whereToCreate]="'left'"
    ></app-emptyState>
    <div>
      <div
        *ngFor="
          let document of documents | async;
          trackBy: helperService.rowTrackBy
        "
        style="margin-bottom: 5px"
      >
        <hyperion-card>
          <app-offer-document-handle
            [parentId]="offerInfo['id']"
            [dataObjectFromParent]="document"
            [dataModelCapitalized]="dataModelCapitalized"
            (updateEmitter)="getData()"
          ></app-offer-document-handle>
        </hyperion-card>
      </div>
    </div>
  </div>
</div>
