import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SliderModule } from 'primeng/slider';
import { AccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { GalleriaModule } from 'primeng/galleria';
import { ButtonModule } from 'primeng/button';

import { HyperionSharedModule } from '../shared/shared.module';
import { SharedDialogModule } from '../shared/dialogs/shared.dialog.module';
import { OldProjectModule } from '../old-project/old-project.module';
import { InvoiceIndexComponent, InvoiceComponent } from './index';
import { InvoicePaymentComponent } from './single/invoice-payment/invoice-payment.component';
import { SingelInvoiceModule } from './single/single.invoice.module';
import { SieExportComponent } from './sie-export/sie-export.component';
import { TaxReductionDialogComponent } from './tax-reduction-dialog/tax-reduction-dialog.component';
import { HeaderModule } from 'app/header/header.module';
import { CardModule } from 'primeng/card';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionSharedModule,
    SharedDialogModule,
    OldProjectModule,
    RadioButtonModule,
    ButtonModule,
    AccordionModule,
    TableModule,
    GalleriaModule,
    SliderModule,
    SingelInvoiceModule,
    HeaderModule,
    CardModule,
  ],
  declarations: [
    InvoiceIndexComponent,
    InvoiceComponent,
    InvoicePaymentComponent,
    SieExportComponent,
    TaxReductionDialogComponent,
  ],
  exports: [InvoiceIndexComponent],
})
export class InvoiceModule {}
