<spinner *ngIf="loading" [inComponent]="true"></spinner>
<div *ngIf="!loading" class="table-scroll medium">
  <table class="table start-table small-text" style="width: 100%">
    <thead>
      <tr class="strong-text">
        <th width="40%">Typ</th>
        <th width="30%">Datum</th>
        <th width="30%">Projekt</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dataObject of dataFromParent">
        <td width="40%">{{ dataObject.msg }}</td>
        <td width="30%" [style.color]="dataObject.color">
          {{ dataObject.date }}
        </td>
        <td width="30%">
          <a [href]="dataObject.link">{{ dataObject.trueProjectId }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
