<i
  class="pi pi-circle-fill"
  [style.color]="
    hasEnded
      ? 'var(--btn-danger-color)'
      : hasStarted
      ? 'var(--btn-success-color)'
      : 'var(--btn-info-color)'
  "
  [pTooltip]="
    hasEnded
      ? 'Projektets slutdatum har passerat'
      : hasStarted
      ? 'Projektets slutdatum har ej passerat.'
      : 'Projektets startdatum har ej passerat'
  "
></i>
