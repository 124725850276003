import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PictureComment } from 'generated/types';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, first } from 'rxjs';
import { HttpService } from '../http';
import { MessageService } from 'app/shared/message';
import {
  DeleteImageGQL,
  SetDisplayOnPrintGQL,
  UpdateImageCommentGQL,
} from 'app/shared/images/graphql/images.generated';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
})
export class ImagesComponent {
  @Input() projectId: number;
  @Input() public images: BehaviorSubject<PictureComment[]>;
  @Input() public totalImages: number;
  @Input() public showPaginator = true;
  @Input() public imagesPerPage = 23;
  @Input() public readonly = false;

  @Output() public getImagesEvent: EventEmitter<{
    offset: number;
    count: number;
  }> = new EventEmitter();

  public showFullscreen = false;
  public imageIndex: number;
  private fullscreenEventListener: any;
  public pageIndex = 0;

  public preloadLargeStatus: Map<string, boolean> = new Map();
  private loadedImages: { [key: string]: HTMLImageElement } = {};

  constructor(
    private confirmationService: ConfirmationService,
    private httpService: HttpService,
    private messageService: MessageService,
    private deleteImageService: DeleteImageGQL,
    private setDisplay: SetDisplayOnPrintGQL,
    private updateImageComment: UpdateImageCommentGQL,
    private changeDetector: ChangeDetectorRef
  ) {}

  public fetchImages(): void {
    this.getImagesEvent.emit({
      offset: this.pageIndex * this.imagesPerPage,
      count: this.imagesPerPage,
    });
  }

  public rotateImage(id: string, right = false): void {
    const degrees = right ? '90' : '-90';

    const url =
      '/project/RotatePictureHyperion?pictureCommentId=' +
      id +
      '&degress=' +
      degrees;

    this.httpService.makeHttpPostRequest(url).subscribe(data => {
      if (data.id) {
        this.fetchImages();
        this.messageService.insertData({
          textArray: ['Bilden roterades'],
          type: 'success',
        });
      } else {
        this.messageService.insertData(
          {
            textArray: ['Något blev fel, kunde inte rotera bild'],
            type: 'error',
          },
          true
        );
      }
    });
  }
  public deleteImage(id: number): void {
    this.deleteImageService
      .mutate({ id: id })
      .pipe(first())
      .subscribe(data => {
        if (
          data.data.pictureCommentTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          )
        ) {
          this.fetchImages();
          this.messageService.insertData({
            textArray: ['Bilden raderades'],
            type: 'success',
          });
        } else {
          this.messageService.insertData(
            {
              textArray: ['Något blev fel, kunde inte radera bild'],
              type: 'error',
            },
            true
          );
        }
      });
  }

  public displayOnPrint(id: number, value: boolean): void {
    this.setDisplay
      .mutate({ id: id, displayOnPrint: value ? 1 : 0 })
      .pipe(first())
      .subscribe(data => {
        if (
          data.data.pictureCommentTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          )
        ) {
          this.fetchImages();
          this.messageService.insertData({
            textArray: ['Bilden uppdaterades'],
            type: 'success',
          });
        } else {
          this.messageService.insertData(
            {
              textArray: ['Något blev fel, kunde inte radera bild'],
              type: 'error',
            },
            true
          );
        }
      });
  }

  public changeComment(id: number, comment: string): void {
    this.updateImageComment
      .mutate({ id: id, comment: comment })
      .pipe(first())
      .subscribe(data => {
        if (
          data.data.pictureCommentTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          )
        ) {
          this.fetchImages();
          this.messageService.insertData({
            textArray: ['Bilden uppdaterades'],
            type: 'success',
          });
        } else {
          this.messageService.insertData(
            {
              textArray: ['Något blev fel, kunde inte radera bild'],
              type: 'error',
            },
            true
          );
        }
      });
  }

  public preloadImages(index: number): void {
    this.images.subscribe(images => {
      if (index > 0) {
        const previous = images[index - 1];
        this.prefetchLargerImage(previous);
      }

      if (index < images.length - 1) {
        const next = images[index + 1];
        this.prefetchLargerImage(next);
      }
      const current = images[index];

      this.prefetchLargerImage(current);
    });
  }

  public openFullscreen(index: number): void {
    this.showFullscreen = true;
    this.imageIndex = index;
    this.preloadImages(index);
    this.bindFullScreenListeners();
  }

  public closeFullscreen(): void {
    this.showFullscreen = false;
    this.unbindFullScreenListeners();
  }

  private prefetchLargerImage(image): void {
    this.prefetchImage(image.largerImage, success => {
      this.preloadLargeStatus.set(image.id, success);
      this.changeDetector.detectChanges();
    });
  }

  private prefetchThumbnail(image): void {
    this.prefetchImage(image.thumbnail, success => {
      image.preloadedThumbnail = success;
      this.changeDetector.detectChanges();
    });
  }

  private prefetchImage(
    path: string,
    callback: (success: boolean) => void
  ): void {
    const loader = new Image();
    loader.onload = () => {
      delete this.loadedImages[path];
      return callback(true);
    };
    loader.onerror = () => {
      delete this.loadedImages[path];
      return callback(false);
    };
    loader.src = path;
    this.loadedImages[path] = loader;
  }

  private bindFullScreenListeners(): void {
    this.fullscreenEventListener = (event: KeyboardEvent | MouseEvent) => {
      function isKeyEvent(
        evt: KeyboardEvent | MouseEvent
      ): evt is KeyboardEvent {
        return evt['key'];
      }

      if (isKeyEvent(event)) {
        const key = event.key;
        switch (key) {
          case 'Escape': {
            this.closeFullscreen();
            break;
          }
          case 'ArrowRight': {
            this.nextIndex();
            break;
          }
          case 'ArrowLeft': {
            this.prevIndex();
            break;
          }
        }
      } else {
        const target = event.target as Element;
        if (target.classList.contains('p-galleria-mask')) {
          this.closeFullscreen();
        }
      }
    };
    document.addEventListener('keydown', this.fullscreenEventListener);
    document.addEventListener('mousedown', this.fullscreenEventListener);
  }
  private unbindFullScreenListeners(): void {
    document.removeEventListener('keydown', this.fullscreenEventListener);
    document.removeEventListener('mousedown', this.fullscreenEventListener);
  }

  private nextIndex(): void {
    if (this.imageIndex < this.totalImages - 1) {
      this.imageIndex++;
      this.preloadImages(this.imageIndex);
    }
  }

  private prevIndex(): void {
    if (this.imageIndex > 0) {
      this.imageIndex--;
      this.preloadImages(this.imageIndex);
    }
  }

  public changePage(event): void {
    this.pageIndex = event.page;
    this.fetchImages();
  }

  public confirmDelete(id): void {
    this.confirmationService.confirm({
      message: 'Radera bild?',
      accept: () => {
        this.deleteImage(id);
      },
    });
  }
  public downloadZip(): void {
    const url = '/project/DownloadPictures?projectId=' + this.projectId;
    window.open(url);
  }

  public downloadPdf(): void {
    const url = '/project/printPicture/id/' + this.projectId + '/type/getPDF';
    window.open(url);
  }

  public showPdf(): void {
    const url = '/project/printPicture/id/' + this.projectId + '/type/showPDF';
    window.open(url);
  }
}
