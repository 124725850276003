<app-feature-preview
  image="/images/hyperion/feature-previews/doku.jpg"
  feature="Doku"
>
  <p>
    Med {{ appName }}s hantverksdokumentstjänst kommer du åt alla era
    dokumentmallar, både via webben och mobilen, oavsett var ni befinner er.
    Knyt dokumenten till projektet för att se till all dokumentation finns
    samlad på ett ställe. Ladda ner färdiga mallar eller anpassa efter de
    önskemål ni har.
  </p>

  <p>
    Undvik mappar och pärmar med dokument. Den enklaste dokumenttjänsten på
    marknaden för hantverkare. Kom igång med ett par enkla knapptryck. Välj
    bland många mallar i flertalet kategorier så som egenkontroller,
    arbetsmiljöplaner, avtal osv. Skapa snygga dokument redan idag!
  </p>
</app-feature-preview>
