<p-accordion class="p-col-12" collapseIcon="">
  <p-accordionTab header="Summering" disabled="true" [selected]="true">
    <div class="p-grid p-p-0 p-m-0">
      <p-table [value]="['dummyvalue']" class="p-col-12 inline-table">
        <ng-template pTemplate="header">
          <tr>
            <th class="p-text-truncate">Netto</th>
            <th class="p-text-truncate" *ngIf="showTaxReduction">
              Skattereduktion
            </th>
            <th class="p-text-truncate">Brutto</th>
            <th class="p-text-truncate">Öresutjämning</th>
            <th class="p-text-truncate">Totalt exkl. moms</th>
            <th class="p-text-truncate">Moms</th>
            <th class="p-text-truncate">Totalt</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body">
          <tr style="color: black">
            <td>{{ +sum.netto | currency: 'SEK' }}</td>
            <td *ngIf="showTaxReduction">
              {{ +sum.rotBox | currency: 'SEK' }}
            </td>
            <td>{{ +sum.brutto | currency: 'SEK' }}</td>
            <td>{{ +sum.rounding | currency: 'SEK' }}</td>
            <td>{{ +sum.sumBox | currency: 'SEK' }}</td>
            <td>{{ +sum.momsBox | currency: 'SEK' }}</td>
            <td>{{ +sum.bruttoBox | currency: 'SEK' }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="p-col-8 p-p-0"></div>

      <p-table class="p-col-4 p-pl-0 inline-table" [value]="['dummyvalue']">
        <ng-template pTemplate="header">
          <tr>
            <th>Att betala</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body">
          <tr style="color: black">
            <td>{{ +sum.totalBox | currency: 'SEK' }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="p-col-12 align-right">
        <button
          pButton
          [disabled]="saving"
          class="p-mr-2 p-button-primary-inverted"
          *ngIf="type === 0 && mode === 'update'"
          (click)="showMailModal(invoice?.source.id)"
          icon="pi pi-envelope"
          label="Skicka"
        ></button>
        <button
          pButton
          class="p-mr-2 p-button-primary-inverted"
          *ngIf="type === 0"
          [disabled]="saving"
          (click)="saveInvoiceEmit('prev')"
          label="Förhandsgranska"
          icon="pi pi-file-pdf"
        ></button>
        <button
          pButton
          [disabled]="saving"
          class="p-button-primary-hyperion"
          *ngIf="mode === 'update'"
          (click)="saveInvoiceEmit('update')"
          icon="pi pi-save"
          label="Uppdatera"
        ></button>
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="saving"
          *ngIf="mode !== 'update'"
          (click)="saveInvoiceEmit('create')"
          icon="pi pi-save"
          label="Spara"
        ></button>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>
