import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms';
import { MessageService } from '../../shared/message/index';
import { GlobalService } from '../../shared/global/index';

@Component({
  selector: 'app-user-settings',
  templateUrl: 'me-settings.component.html',
  providers: [FormHandlerService],
})
export class MeSettingsComponent implements OnDestroy, OnInit {
  componentMainForm: FormGroup;
  dataModel = 'user';
  dataModelCapitalized = 'User';
  formSettings;
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {},
  };
  dataSet;
  buttons;
  loading = true;
  meData = {
    companyId: null,
    username: null,
  };
  sub: Subscription;
  rand = 3746;
  imgError = false;
  urlPrefix;

  constructor(
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private apolloQueryService: ApolloQueryService
  ) {
    const buttonObject = {
      update: {
        model: this.dataModel,
      },
    };
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  ngOnInit() {
    this.queryDataSet();
  }

  private queryDataSet() {
    this.apolloQueryService
      .apolloWatchQueryTwo('me')
      .subscribe(({ data, sub }) => {
        this.sub = sub;
        this.dataSet = data.me;
        this.formFields.attributes = this.setFieldsFromData(data);
        data.me &&
          this.formHandler
            .groupSetLabelsRules(this.formFields)
            .then(groupedForm => {
              groupedForm.addControl('newUsername', new FormControl(''));
              groupedForm.controls.newUsername.label = 'Uppdatera användarnamn';
              this.componentMainForm = groupedForm;
              this.loading = false;
            });
      });
  }

  ngOnDestroy() {
    if (typeof this.sub !== 'undefined') {
      this.sub.unsubscribe();
    }
  }

  setFieldsFromData(data) {
    const setFields = { ...data.me };
    setFields.id = Number(setFields.id);
    this.meData.companyId = setFields.companyId;
    this.meData.username = setFields.username;
    setFields.password = '';
    setFields.repeat_password = '';
    delete setFields.companyId;
    delete setFields.__typename;
    return setFields;
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    dataToMutation['firstPartOfUsernameForUpdate'] =
      this.componentMainForm.controls.newUsername.value;

    delete dataToMutation.username;
    delete dataToMutation.newUsername;

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchArr = [{ name: 'me', variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  // Image
  imgReadError = () => (this.imgError = true);

  onUpload(event) {
    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    if (dataAsJSON.status === 'success') {
      this.rand = Math.random() * 100;
    }

    this.messageService.insertData({
      textArray: [dataAsJSON.msg],
      time: 2000,
      type: dataAsJSON.status,
    });

    this.imgError = false;
  }
}
