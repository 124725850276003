<div class="p-grid">
  <div class="p-col-12 p-grid">
    <div class="p-col-12">
      <h3>Ange följande rubriker för de olika kolumnerna</h3>
    </div>
    <div class="p-col-12 p-grid">
      <div class="p-col-12 p-lg-6">
        <strong>Obligatoriska</strong>
        <p>"artnr" Artnumret</p>
        <p>"benamning" Benämning på artikeln</p>
        <p>"fsgpris" ert pris mot kund</p>
      </div>
      <div class="p-col-12 p-lg-6">
        <strong>Frivilliga kolumner</strong>
        <p>"inpris" ert inköpspris</p>
        <p>"leverantor"</p>
        <p>"enhet" ex. st frp</p>
        <p>"top" om produten är toppmärkt markeras med en etta "1"</p>
        <p>"typ" namnet på artikeltypen</p>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-grid">
    <div class="p-col-12">
      <h3>Exempel på excelfil</h3>
    </div>
    <div class="p-col-12">
      <img
        src="/images/struktur/guide/importExcelProducts.png"
        alt="excel exempelfil"
        style="width: 100%"
      />
    </div>
  </div>
  <div class="p-col-12 p-grid">
    <div class="p-col-12">
      <h3>Ange fil</h3>
    </div>
    <div class="p-col-12">
      <p-fileUpload
        name="ExcelForm[file]"
        accept=".xlsx, .xls"
        [multiple]="false"
        maxFileSize="2000000"
        url="/product/uploadProductList"
        chooseLabel="Ange fil"
        uploadLabel="Ladda upp"
        cancelLabel="Avbryt"
        invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
        invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
        (onBeforeUpload)="onBeforeUpload($event)"
        (onBeforeSend)="onBeforeSend($event)"
        (onUpload)="onUpload($event)"
      >
        <ng-template pTemplate="content">
          <app-drag-and-drop-template></app-drag-and-drop-template>
        </ng-template>
      </p-fileUpload>
    </div>
    <div class="p-col-12">
      <p-checkbox
        class="adjust-chkbox"
        [(ngModel)]="excelFormUpdateExisting"
        [binary]="true"
        label="Uppdatera befintliga artiklar, utifrån artnr så hittar den sin match"
      ></p-checkbox>
    </div>
  </div>
</div>

<div class="p-grid" *ngIf="(loadingStatus | async) === 'loading'">
  <div class="p-col-12" style="text-align: center">
    <spinner></spinner>
    <p style="margin-bottom: 0.8em">Laddar artiklar</p>
    <app-button (click)="cancelCall()" [type]="'cancel'"></app-button>
  </div>
</div>
<div *ngIf="(loadingStatus | async) !== 'dns'" class="p-grid">
  <div *ngFor="let key of arrayKeys" class="p-col-12">
    <h3>
      {{ dataSet[key].head }} <small>{{ dataSet[key].count }} st</small>
    </h3>

    <p-table
      [value]="dataSet[key].rows | async"
      scrollable="true"
      scrollHeight="300px"
      [rows]="50"
      [paginator]="(dataSet[key]?.rows | async)?.length > 50"
      [pageLinks]="3"
      [rowsPerPageOptions]="[25, 50, 100]"
      [columns]="tableColums"
    >
      <ng-template pTemplate="header" let-rowData let-columns>
        <tr>
          <th *ngFor="let col of columns" [style.width]="col.width">
            {{
              col.field !== 'extraString'
                ? rowData[col.field]
                : dataSet[key]['extraHead']
            }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length">
            <app-emptyState [model]="'Product'"></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
