import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { ApolloQueryService } from '../../../shared/apollo/index';
import { HelperService } from '../../../shared/helpers/index';

@Component({
  selector: 'user-list',
  templateUrl: 'user-list.component.html',
})
export class UserListComponent implements OnDestroy, OnInit {
  @Input() inModal = false;

  extraAddress: string = null;
  dataModel = 'company';
  dataModelCapitalized;
  fieldsForForm;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  singleUserLoading = false;
  showContactListLoading = true;
  showContactWaiting = false;
  dataSet = [];
  totalCount;
  obs;
  selectedUser = null;
  showContactsBool = false;
  showContactListSub: Subscription;
  usersSub: Subscription;
  tableColums = [
    { field: 'firstName', header: 'Medarbetare' },
    { field: 'phone', header: 'Telefon' },
    { field: 'email', header: 'Mail' },
  ];

  constructor(
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  ngOnDestroy() {
    this.showContactListSub && this.showContactListSub.unsubscribe();
    this.usersSub && this.usersSub.unsubscribe();
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.dataSet = [];
        if (data) {
          this.dataSet = this.helperService.cleanFromNode(data.company.users);
          this.totalCount = data.company.users.totalCount;
          this.loading.next(false);
        }
      });
  }
}
