import {
  Component,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { CompanyProjectTypeService } from '../../../shared/company/index';
import { GlobalService } from '../../../shared/global/index';
import { HelperService } from 'app/shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'companyProjectType-settings',
  templateUrl: 'company-project-type-settings.component.html',
})
export class CompanyProjectTypeSettingsComponent implements OnDestroy, OnInit {
  @Input() showTitle = 'titleRow';
  isModal = false;
  loading = true;
  dataModel = 'companyProjectType';
  dataSet = [];
  createDataObjectArray = [{ fakeId: 0 }];
  componentPrefix;
  sub: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private projectTypeService: CompanyProjectTypeService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    public dialogConfig: DynamicDialogConfig,
    private globalService: GlobalService
  ) {
    this.dialogConfig.header = 'Hantera projekttyper';
  }

  ngOnInit() {
    if (this.dialogConfig.data) {
      this.isModal = this.dialogConfig['data']['isModal'];
    }

    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.queryDataSet();
  }

  private queryDataSet() {
    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel)
      .subscribe(({ data, sub }) => {
        this.sub = sub;
        this.loading = false;
        this.dataSet = this.projectTypeService.dataFormater(data, 'list');
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  spliceDeletedDataObject(createComponentObj) {
    const index = this.createDataObjectArray.indexOf(createComponentObj);
    this.createDataObjectArray.splice(index, 1);
  }

  addCreateComponent(createComponentObj) {
    if (
      this.createDataObjectArray.indexOf(createComponentObj) ===
      this.createDataObjectArray.length - 1
    ) {
      const latest =
        this.createDataObjectArray[this.createDataObjectArray.length - 1];
      const newFakeId = latest['fakeId'] + 1;
      const pushThis = { fakeId: newFakeId };

      this.createDataObjectArray.push(pushThis);
    }
  }
}
