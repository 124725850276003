<div class="product-list">
  <span class="p-inputgroup">
    <input
      [disabled]="productSearchLoading | async"
      placeholder="Sök artiklar"
      (keyup.enter)="searchForProducts()"
      pInputText
      type="text"
      [(ngModel)]="productSearchModel"
    />
    <button
      class="p-inputgroup-addon p-button-icon-danger"
      pButton
      pTooltip="Rensa sökfält"
      tooltipPosition="left"
      *ngIf="productSearchModel !== '' && (doneSearch | async)"
      (click)="clearSearch()"
      icon="pi pi-times"
    ></button>
    <button
      class="p-inputgroup-addon"
      pButton
      [disabled]="productSearchLoading | async"
      (click)="searchForProductsIfAllowed()"
      icon="pi pi-search"
    ></button>
  </span>

  <spinner *ngIf="loading | async"></spinner>
  <div [style.display]="(loading | async) === false ? 'block' : 'none'">
    <p-table
      [rows]="50"
      [totalRecords]="totalCount"
      [value]="dataSet"
      [columns]="tableColums"
      [paginator]="true"
      paginatorPosition="both"
      [rowsPerPageOptions]="[50, 100, 250, 500]"
      [rowTrackBy]="helperService.rowTrackBy"
      (onEditCancel)="onEditCancel($event)"
      [lazy]="true"
      (onLazyLoad)="paginate($event)"
      showCurrentPageReport="true"
      currentPageReportTemplate="Antal artiklar: {totalRecords}"
      [rowHover]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [ngStyle]="col.style">
            {{ col.header }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr pSelectableRow>
          <td
            *ngFor="let col of columns"
            [pEditableColumn]="rowData"
            [pEditableColumnField]="col.field"
          >
            <ng-container *ngIf="col.inputType === 'input'">
              <p-cellEditor (keydown.enter)="onEditComplete(col, rowData)">
                <ng-template pTemplate="input">
                  <appForm2-list-input
                    [(ngModel)]="rowData[col.field]"
                    (onBlur)="onEditComplete(col, rowData)"
                    [htmlAttributes]="{ label: { value: col.header } }"
                  ></appForm2-list-input>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ rowData[col.field] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.field === 'companyCostTypeId'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <appForm2-list-dropDown
                    [(ngModel)]="rowData[col.field]"
                    (ngModelChange)="onEditComplete(col, rowData)"
                    [dropDownParams]="{
                      options: companyCostTypes,
                      placeholder: col.header
                    }"
                  ></appForm2-list-dropDown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ companyCostTypeNamesObject[rowData.companyCostTypeId] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.field === 'enhet'">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <appForm2-list-dropDown
                    [(ngModel)]="rowData[col.field]"
                    (ngModelChange)="onEditComplete(col, rowData)"
                    [dropDownParams]="{
                      options: units,
                      placeholder: col.header
                    }"
                  ></appForm2-list-dropDown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ unitsTypes[rowData[col.field]] }}
                </ng-template>
              </p-cellEditor>
            </ng-container>
            <ng-container *ngIf="col.field === 'top'">
              <p-checkbox
                [(ngModel)]="rowData.top"
                (onChange)="topBoolChange($event, rowData)"
                [binary]="true"
              ></p-checkbox>
            </ng-container>
            <ng-container *ngIf="col.field === 'id'">
              <i
                class="fa fa-save save"
                (click)="rowAction(rowData, 'update')"
              ></i>
              <i
                style="margin-left: 0.3em"
                class="fa fa-trash-o delete"
                (click)="rowAction(rowData, 'delete')"
              ></i>
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length">
            <app-emptyState></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
