import { AuthGuard } from 'app/shared/auth/auth-guard.service';

import { DefaultTasks } from './shared/resolve/index';

import {
  AttendanceReportComponent,
  ConnectionsComponent,
  CollectionListComponent,
  SubcontractorHandleComponent,
} from './attendance-report/index';

import {
  DayComponent,
  DaySettingsComponent,
  DayExportComponent,
  DayAdvancedComponent,
} from './day/index';

import {
  HomeComponent,
  HomeStartComponent,
  HomeEviveComponent,
  HomeExtraFunctionsComponent,
  HomeUpdatesComponent,
  HomeRedirectComponent,
  HomeRecommendComponent,
} from './home/index';

import {
  InvoiceIndexComponent,
  InvoiceComponent,
  SieExportComponent,
} from './invoice/index';

import { ProjectComponent, ProjectIndexComponent } from './old-project/index';

import { OrderComponent } from './purchase-transaction';

import {
  OfferComponent,
  OfferCreateComponent,
  OfferIndexComponent,
} from './offer/index';

import { SettingsComponent } from './settings/settings.component';

import {
  CompanyProjectTypeSettingsComponent,
  CompanyCostTypeSettingsComponent,
  CompanyUserCostTypeSettingsComponent,
  CompanyDivisionSettingsComponent,
  CompanyInvoiceOfferSettingsComponent,
  CompanyUpdateSettingsComponent,
  MeSettingsComponent,
  CompanyQualityStampsComponent,
  InvoiceExportSettingsComponent,
  IntegrationSettingsComponent,
} from './settings/index';
import { SchedulerViewComponent } from './planner/scheduler/scheduler-view/scheduler-view.component';
import { PlannerComponent } from './planner/planner.component';
import { GanttViewComponent } from './planner/gantt/gantt-view/gantt-view.component';
import { FeaturePreviewEliggare } from 'app/feature-preview/e-liggare/e-liggare.component';
import { FeaturePreviewTidrapport } from 'app/feature-preview/tidrapport/tidrapport.component';
import { FeaturePreviewOffert } from './feature-preview/offert/offert.component';
import { FeaturePreviewDoku } from './feature-preview/doku/doku.component';
import { FeaturePreviewProjekt } from './feature-preview/projekt/projekt.component';
import { FeaturePreviewFaktura } from './feature-preview/faktura/faktura.component';
import { FeaturePreviewInkop } from './feature-preview/inkop/inkop.component';
import { FeaturePreviewPlanering } from './feature-preview/planering/planering.component';
import { FeaturePreviewOverblick } from './feature-preview/overblick/overblick.component';
import { ResourceViewComponent } from './planner/resource/resource-view/resource-view.component';
import { SubcompaniesComponent } from './subcompanies/subcompanies.component';
import { DokuadminComponent } from './dokuadmin/dokuadmin.component';
import { IndexComponent } from './index/index.component';
import { ProjectDetailComponent } from './project/project-detail/project-detail.component';
import { ProjectInfoComponent } from './project/project-detail/project-info/project-info.component';
import { DocuMyDocumentsComponent } from './docu/docu-my-documents/docu-my-documents.component';
import { DocuEditComponent } from './docu/docu-edit/docu-edit.component';
import { DocuPreviewComponent } from './docu/docu-preview/docu-preview.component';
import { DocuTemplateFolderComponent } from './docu/docu-template-folder/docu-template-folder.component';
import { DocuTemplateFoldersComponent } from './docu/docu-template-folders/docu-template-folders.component';
import { DocuDirtyService } from './docu/docu-dirty.service';
import { LoginComponent } from './login';
import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { tap, Observable, of, first, catchError, Subject } from 'rxjs';
import { LogoutComponent } from './logout/logout.component';
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectCommentsComponent } from './project/project-detail/project-comments/project-comments.component';
import { ProjectDocumentsComponent } from './project/project-detail/project-documents/project-documents.component';
import { ProjectImagesComponent } from './project/project-detail/project-images/project-images.component';
import { ProjectTodoComponent } from './project/project-detail/project-todo/project-todo.component';
import { ProjectPaymentPlanComponent } from './project/project-detail/project-payment-plan/project-payment-plan.component';
import { UserFlags, UserFlagsService } from './user-flags.service';
import { ProjectAttendanceComponent } from './project/project-detail/project-attendance/project-attendance.component';
import { ProjectRotComponent } from './project/project-detail/project-rot/project-rot.component';
import { ProjectAtaComponent } from './project/project-detail/project-ata/project-ata.component';
import { ProjectShareComponent } from './project/project-detail/project-share/project-share.component';
import { ProjectSupplierInvoiceLegacyComponent } from './project/project-detail/project-supplier-invoice/project-supplier-invoice.component';
import {
  ICountService,
  ProjectCountFields,
  ProjectCountService as ProjectCountService,
} from './project/project-count-service/project-count-service.service';
import { RegistryComponent } from './registry/registry.component';
import { ContactListComponent } from './contact/contact-list/contact-list.component';
import { CompanyProductsSettingsComponent } from './registry/product';
import { UserListComponent, UserSettingsComponent } from './registry/users';
import { InvoiceContainerComponent } from './invoice/single';
import { VersionCheckGuard } from './shared/version-check/version-check.guard';

export interface RouteWithData extends Route {
  data?: {
    icon?: string;
    label?: string;
    showInMainMenu?: boolean;
    showInSubMenu?: boolean;
    enabled?: boolean;
    type?: string;
    countService?: ICountService;
    countField?: any;
    reuseComponent?: boolean;
  };
  children?: RoutesWithData;
}

export type RoutesWithData = RouteWithData[];

@Injectable({
  providedIn: 'root',
})
export class RoutesService {
  constructor(
    private userFlagsService: UserFlagsService,
    private projectCountService: ProjectCountService,
    private router: Router
  ) {}

  private userFlags: UserFlags;

  public setRoutes(): Observable<RoutesWithData> {
    this.router.resetConfig(this.getUnauthenticatedRoutes());

    return this.getAuthenticatedRoutes().pipe(
      first(),
      tap(authenticatedRoutes => {
        const loggedInRoutes: RoutesWithData = [
          ...authenticatedRoutes,
          ...this.getUnauthenticatedRoutes(),
        ];
        this.router.resetConfig(loggedInRoutes);
      }),
      catchError((err, caught) => {
        this.router.resetConfig(this.getUnauthenticatedRoutes());
        return of(this.getUnauthenticatedRoutes());
      })
    );
  }

  private getUnauthenticatedRoutes(): RoutesWithData {
    return [
      { path: 'login', component: LoginComponent },
      {
        path: 'docu/preview/:id',
        component: DocuPreviewComponent,
      },
      { path: '**', redirectTo: 'login' },
    ];
  }

  private getAuthenticatedRoutes(): Observable<RoutesWithData> {
    const retVal = new Subject<RoutesWithData>();
    this.userFlagsService
      .getFlags(true)
      .pipe(first())
      .subscribe(flags => {
        if (!flags) {
          retVal.next([]);
          return;
        }

        this.userFlags = flags;

        retVal.next(
          this.filterEnabledRoutes([
            {
              path: '',
              component: IndexComponent,
              canActivate: [AuthGuard, VersionCheckGuard],
              resolve: { allTasks: DefaultTasks },
              providers: [AuthGuard],
              children: [
                ...this.getRootRoutes(),
                ...this.getHomeRoutes(),
                ...this.getOfferRoutes(),
                ...this.getOldProjectRoutes(),
                ...this.getProjectRoutes(),
                ...this.getDayRoutes(),
                ...this.getPlannerRoutes(),
                ...this.getAttendanceRoutes(),
                ...this.getInvoiceRoutes(),
                ...this.getSupplierInvoiceRoutes(),
                ...this.getDocuRoutes(),
                ...this.getPreviewRoutes(),
                ...this.getRegistryRoutes(),
                ...this.getSettingsRoutes(),
                { path: 'login', component: LoginComponent },
                { path: 'logout', component: LogoutComponent },
              ],
            },
          ])
        );
      });
    return retVal;
  }

  private filterEnabledRoutes(routes: RoutesWithData): RoutesWithData {
    const enabledRoutesWithData = routes.filter(
      route => route.data?.enabled !== false
    );

    return enabledRoutesWithData.map(route => {
      if (route.children) {
        route.children = this.filterEnabledRoutes(route.children);
      }

      return route;
    });
  }

  private getRootRoutes(): RoutesWithData {
    const route: RouteWithData = this.userFlags.hasFlag('useModuleStart')
      ? {
          path: '',
          component: HomeRedirectComponent,
          pathMatch: 'full',
        }
      : {
          path: '',
          redirectTo: 'docu',
          pathMatch: 'prefix',
        };

    return [route];
  }

  private getHomeRoutes(): RoutesWithData {
    return [
      {
        path: 'home',
        data: {
          showInMainMenu: true,
          label: 'Hem',
          icon: '/images/icons/Struqtur_Icon_Hem.svg',
          enabled: this.userFlags.hasFlag('useModuleStart'),
        },
        component: HomeComponent,
        children: [
          { path: '', redirectTo: 'start', pathMatch: 'prefix' },
          {
            component: HomeStartComponent,
            path: 'start',
            data: { label: 'Start', showInSubMenu: true },
          },
          {
            component: HomeUpdatesComponent,
            path: 'updates',
            data: {
              label: 'Uppdateringar',
              enabled: this.userFlags.isAdmin || this.userFlags.isForeman,
              showInSubMenu: true,
            },
          },
          {
            component: HomeExtraFunctionsComponent,
            path: 'extraFunctions',
            data: {
              label: 'Extrafunktioner',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            component: HomeEviveComponent,
            path: 'evive',
            data: {
              label: 'Juridisk Rådgivning',
              enabled:
                this.userFlags.isWhitelabelStruqtur && this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            component: HomeRecommendComponent,
            path: 'recommend',
            data: {
              label: 'Rekommendera',
              showInSubMenu: true,
              enabled:
                (this.userFlags.isWhitelabelStruqtur ||
                  this.userFlags.isWhitelabelBeijerBygg) &&
                (this.userFlags.isAdmin || this.userFlags.isForeman),
            },
          },
        ],
      },
    ];
  }

  private getAttendanceRoutes(): RoutesWithData {
    return [
      {
        path: 'attendanceReport',
        data: {
          showInMainMenu: true,
          label: 'E-liggare',
          icon: '/images/icons/Struqtur_Icon_E-liggare.svg',
          enabled:
            this.userFlags.hasFlag('useAttendance') &&
            (this.userFlags.isAdmin || this.userFlags.isForeman),
          showInSubMenu: true,
        },
        component: AttendanceReportComponent,
        children: [
          {
            path: '',
            redirectTo: 'project/active',
            pathMatch: 'prefix',
          },
          {
            component: ProjectIndexComponent,
            path: 'project/active',
            data: { label: 'Pågående', showInSubMenu: true },
          },
          {
            component: CollectionListComponent,
            path: 'collectionList',
            data: { label: 'Samlingslista/Export', showInSubMenu: true },
          },
          {
            component: SubcontractorHandleComponent,
            path: 'subcontractor',
            data: { label: 'Allmän E-liggare', showInSubMenu: true },
          },
          {
            component: ProjectIndexComponent,
            path: 'project/archived',
            data: { label: 'Arkiverade', showInSubMenu: true },
          },
          {
            path: 'handle',
            redirectTo: 'project/active',
            pathMatch: 'prefix',
          },
          {
            component: ConnectionsComponent,
            path: 'connections',
          },
        ],
      },
    ];
  }

  private getPlannerRoutes(): RoutesWithData {
    return [
      {
        path: 'planner',
        data: {
          showInMainMenu: true,
          label: 'Planering',
          icon: '/images/icons/Struqtur_Icon_Planering.svg',
          enabled:
            this.userFlags.hasFlag('useModulePlanner') &&
            (this.userFlags.isAdmin ||
              (this.userFlags.hasFlag('advancedUserCanUsePlannerModule') &&
                this.userFlags.isForeman)),
        },
        component: PlannerComponent,
        children: [
          { path: '', redirectTo: 'project', pathMatch: 'prefix' },
          {
            component: GanttViewComponent,
            path: 'project',
            data: { label: 'Projektplanering', showInSubMenu: true },
          },
          {
            component: ResourceViewComponent,
            path: 'resource',
            data: {
              label: 'Resursplanering',
              enabled: this.userFlags.hasFlag('useFeatureResourcePlanner'),
              showInSubMenu: true,
            },
          },
          {
            component: SchedulerViewComponent,
            path: 'calendar',
            data: { label: 'Schemaläggning', showInSubMenu: true },
          },
        ],
      },
    ];
  }

  private getDayRoutes(): RoutesWithData {
    return [
      {
        path: 'day',
        component: DayComponent,
        data: {
          showInMainMenu: true,
          label: 'Tidrapport',
          icon: '/images/icons/Struqtur_Icon_Tidrapport.svg',
          enabled: this.userFlags.hasFlag('useModuleTime'),
        },
        children: [
          { path: '', redirectTo: 'handle', pathMatch: 'prefix' },
          {
            component: DaySettingsComponent,
            path: 'handle',
            data: { label: 'Tidrapport', showInSubMenu: true },
          },
          {
            component: DayAdvancedComponent,
            path: 'advanced',
            data: {
              label: 'Överblick',
              enabled: this.userFlags.isForeman || this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            component: DayExportComponent,
            path: 'export',
            data: {
              label: 'Exportera',
              enabled:
                this.userFlags.hasFlag('exportDaysToFortnox') &&
                this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
        ],
      },
    ];
  }

  private getDocuRoutes(): RoutesWithData {
    return [
      {
        path: 'docu',
        data: {
          showInMainMenu: true,
          label: 'Dokument',
          icon: '/images/icons/Struqtur_Icon_Hantverksdokument.svg',
          enabled:
            this.userFlags.hasFlag('useModuleDocument') &&
            (this.userFlags.isAdmin || this.userFlags.isForeman),
        },
        children: [
          { path: '', redirectTo: 'templates', pathMatch: 'full' },
          {
            path: 'templates',
            component: DocuTemplateFoldersComponent,
            data: { label: 'Mallar', showInSubMenu: true },
          },
          {
            path: 'documents/0',
            component: DocuMyDocumentsComponent,
            pathMatch: 'prefix',
            data: { label: 'Mina dokument', showInSubMenu: true },
          },
          {
            path: 'documents/3',
            component: DocuMyDocumentsComponent,
            pathMatch: 'prefix',
            data: { label: 'Arkiverade', showInSubMenu: true },
          },
          {
            path: 'templates/:folder',
            component: DocuTemplateFolderComponent,
          },
          {
            path: 'edit/:id',
            component: DocuEditComponent,
            canDeactivate: [DocuDirtyService],
          },
        ],
      },
    ];
  }

  private getInvoiceRoutes(): RoutesWithData {
    return [
      {
        path: 'invoice',
        data: {
          showInMainMenu: true,
          label: 'Kundfaktura',
          icon: '/images/icons/Struqtur_Icon_Kundfaktura.svg',
          enabled:
            this.userFlags.hasFlag('useModuleInvoice') &&
            (this.userFlags.isAdmin ||
              (this.userFlags.hasFlag('advancedUserCanUseInvoiceModule') &&
                this.userFlags.isForeman)),
        },
        component: InvoiceComponent,
        children: [
          { path: '', redirectTo: 'index', pathMatch: 'full' },
          {
            component: InvoiceIndexComponent,
            path: 'index',
            data: {
              label: 'Kundfakturor',
              showInSubMenu: true,
              reuseComponent: true,
            },
          },
          {
            component: SieExportComponent,
            path: 'sieExport',
            data: { label: 'Export', showInSubMenu: true },
          },
          { path: ':id', component: InvoiceContainerComponent },
        ],
      },
    ];
  }

  private getSupplierInvoiceRoutes(): RoutesWithData {
    return [
      {
        path: 'supplierInvoice',
        data: {
          showInMainMenu: true,
          label: 'Inköp',
          icon: '/images/icons/Struqtur_Icon_Leverantörsfaktura.svg',
          enabled:
            this.userFlags.hasFlag('useModuleSupplierInvoice') &&
            (this.userFlags.isAdmin ||
              (this.userFlags.hasFlag(
                'advancedUserCanUseSupplierInvoiceModule'
              ) &&
                this.userFlags.isForeman)),
        },
        component: InvoiceComponent,
        children: [
          {
            path: '',
            redirectTo: 'index',
            pathMatch: 'prefix',
          },
          {
            component: InvoiceIndexComponent,
            path: 'index',
            data: {
              type: 'supplier',
              label: 'Leverantörsfakturor',
              showInSubMenu: true,
              reuseComponent: true,
            },
          },
          {
            component: OrderComponent,
            path: 'edi',
            data: {
              type: 'supplier',
              label: 'Ordrar',
              showInSubMenu: true,
            },
          },
          { path: ':id', component: InvoiceContainerComponent },
        ],
      },
    ];
  }

  private getOfferRoutes(): RoutesWithData {
    return [
      {
        path: 'offer',
        component: OfferComponent,
        data: {
          showInMainMenu: true,
          label: 'Offert',
          icon: '/images/icons/Struqtur_Icon_Offert.svg',
          enabled:
            this.userFlags.hasFlag('useModuleOffer') &&
            (this.userFlags.isAdmin || this.userFlags.isForeman),
        },
        children: [
          { path: '', redirectTo: 'index/1', pathMatch: 'prefix' },
          {
            component: OfferIndexComponent,
            path: 'index/1',
            data: { label: 'Offerter', showInSubMenu: true },
          },
          {
            component: OfferIndexComponent,
            path: 'index/0',
            data: { label: 'Utkast', showInSubMenu: true },
          },
          {
            component: OfferIndexComponent,
            path: 'index/2',
            data: { label: 'Arkiverade', showInSubMenu: true },
          },
          {
            component: OfferCreateComponent,
            path: 'create',
            data: { label: 'Skapa offert' },
          },
        ],
      },
    ];
  }

  private getOldProjectRoutes(): RoutesWithData {
    return [
      {
        path: 'old-project',
        component: ProjectComponent,
        data: {
          showInMainMenu: false,
          label: 'Projekt',
          icon: '/images/icons/Struqtur_Icon_Projekt.svg',
          enabled:
            this.userFlags.hasFlag('useModuleProject') &&
            (this.userFlags.isAdmin || this.userFlags.isForeman),
        },
        children: [
          { path: '', redirectTo: 'index/1', pathMatch: 'prefix' },
          { path: 'index', redirectTo: 'index/1', pathMatch: 'prefix' },
          {
            component: ProjectIndexComponent,
            path: 'index/0',
            data: {
              label: 'Planerade',
              enabled: this.userFlags.isAdmin || this.userFlags.isForeman,
              showInSubMenu: true,
            },
          },
          {
            component: ProjectIndexComponent,
            path: 'index/1',
            data: {
              label: 'Pågående',
              enabled: this.userFlags.isAdmin || this.userFlags.isForeman,
              showInSubMenu: true,
            },
          },
          {
            component: ProjectIndexComponent,
            path: 'index/2',
            data: {
              label: 'Avslutade',
              enabled: this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            component: ProjectIndexComponent,
            path: 'index/3',
            data: {
              label: 'Arkiverade',
              enabled: this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            component: ProjectIndexComponent,
            path: 'index/6',
            data: {
              label: 'Interna',
              enabled: this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            component: ProjectIndexComponent,
            path: 'index/4',
            data: {
              label: 'Raderade',
              enabled: this.userFlags.hasFlag(
                'abilityToRestoreDeletedProjects'
              ),
              showInSubMenu: true,
            },
          },
        ],
      },
    ];
  }

  private getProjectRoutes(): RoutesWithData {
    return [
      {
        path: 'project',
        data: {
          showInMainMenu: true,
          enabled:
            this.userFlags.hasFlag('useModuleProject') &&
            (this.userFlags.isAdmin || this.userFlags.isForeman),
          label: 'Projekt',
          icon: '/images/icons/Struqtur_Icon_Projekt.svg',
        },
        children: [
          {
            path: '',
            redirectTo: 'current',
            pathMatch: 'prefix',
          },
          {
            path: 'planned',
            component: ProjectListComponent,
            data: {
              label: 'Planerade',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin || this.userFlags.isForeman,
              reuseComponent: true,
            },
          },
          {
            path: 'current',
            component: ProjectListComponent,
            data: {
              label: 'Pågående',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin || this.userFlags.isForeman,
              reuseComponent: true,
            },
          },
          {
            path: 'finished',
            component: ProjectListComponent,
            data: {
              label: 'Avslutade',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
              reuseComponent: true,
            },
          },
          {
            path: 'archive',
            component: ProjectListComponent,
            data: {
              label: 'Arkiverade',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
              reuseComponent: true,
            },
          },
          {
            path: 'internal',
            component: ProjectListComponent,
            data: {
              label: 'Interna',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
              reuseComponent: true,
            },
          },
          {
            path: 'deleted',
            component: ProjectListComponent,
            data: {
              label: 'Raderade',
              showInSubMenu: true,
              enabled:
                this.userFlags.hasFlag('abilityToRestoreDeletedProjects') &&
                this.userFlags.isAdmin,
              reuseComponent: true,
            },
          },
          {
            path: ':id',
            component: ProjectDetailComponent,
            children: [
              { path: '', redirectTo: 'info', pathMatch: 'prefix' },
              {
                data: { label: 'Huvudinfo', showInSubMenu: true },
                path: 'info',
                component: ProjectInfoComponent,
              },
              {
                data: {
                  label: 'Arbetsmoment',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.todos,
                },
                path: 'worktasks',
                component: ProjectTodoComponent,
              },
              {
                data: {
                  label: 'Kommentarer',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.comments,
                },
                path: 'comments',
                component: ProjectCommentsComponent,
              },
              {
                data: {
                  label: 'Bilder',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.pictures,
                },
                path: 'pictures',
                component: ProjectImagesComponent,
              },
              {
                data: {
                  label: 'Dokument',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.documents,
                },
                path: 'documents',
                component: ProjectDocumentsComponent,
              },
              {
                data: {
                  label: 'Dela',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.shares,
                },
                path: 'share',
                component: ProjectShareComponent,
              },
              {
                data: {
                  label: 'ROT',
                  showInSubMenu: true,
                },
                path: 'rot',
                component: ProjectRotComponent,
              },
              {
                data: {
                  label: 'Betalplan',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.installments,
                },
                path: 'paymentplan',
                component: ProjectPaymentPlanComponent,
              },
              {
                data: {
                  label: 'Leverantörsfakturor',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.supplierInvoices,
                  enabled:
                    this.userFlags.isAdmin ||
                    (this.userFlags.isForeman &&
                      this.userFlags.hasFlag(
                        'advancedUserCanUseSupplierInvoiceModule'
                      )),
                },
                path: 'supplierinvoices',
                component: ProjectSupplierInvoiceLegacyComponent,
              },
              {
                data: {
                  label: 'ÄTA',
                  showInSubMenu: true,
                  countService: this.projectCountService,
                  countField: ProjectCountFields.atas,
                },
                path: 'ata',
                component: ProjectAtaComponent,
              },
              {
                data: {
                  label: 'E-liggare',
                  showInSubMenu: true,
                  enabled: this.userFlags.hasFlag('useAttendance'),

                  countService: this.projectCountService,
                  countField: ProjectCountFields.attendances,
                },
                path: 'attendance',
                component: ProjectAttendanceComponent,
              },
            ],
          },
        ],
      },
    ];
  }

  private getRegistryRoutes(): RoutesWithData {
    return [
      {
        path: 'registry',
        data: {
          showInMainMenu: true,
          enabled: this.userFlags.isForeman || this.userFlags.isAdmin,
          label: 'Register',
          icon: '/images/icons/Struqtur_Icon_Register.svg',
        },
        component: RegistryComponent,
        children: [
          { path: '', redirectTo: 'customers', pathMatch: 'prefix' },
          {
            path: 'customers',
            component: ContactListComponent,
            data: {
              label: 'Kunder',
              enabled: this.userFlags.isForeman || this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            path: 'suppliers',
            component: ContactListComponent,
            data: {
              label: 'Leverantörer',
              enabled: this.userFlags.isForeman || this.userFlags.isAdmin,
              showInSubMenu: true,
              type: 'supplier',
            },
          },
          {
            path: 'articles',
            component: CompanyProductsSettingsComponent,
            data: {
              label: 'Artiklar',
              enabled: this.userFlags.isForeman || this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            path: 'users',
            component: UserSettingsComponent,
            data: {
              label: 'Användare',
              enabled: this.userFlags.isAdmin,
              showInSubMenu: true,
            },
          },
          {
            path: 'subcompanies',
            component: SubcompaniesComponent,
            data: {
              label: 'Underföretag',
              enabled: this.userFlags.isAdmin && this.userFlags.hasSubCompanies,
              showInSubMenu: true,
            },
          },
          {
            path: 'friends',
            component: UserListComponent,
          },
        ],
      },
    ];
  }

  private getSettingsRoutes(): RoutesWithData {
    return [
      {
        path: 'settings',
        data: {
          showInMainMenu: true,
          label: 'Inställningar',
          icon: '/images/icons/Struqtur_Icon_Installningar.svg',
        },
        component: SettingsComponent,
        children: [
          { path: '', redirectTo: 'user', pathMatch: 'prefix' },
          {
            path: 'user',
            component: MeSettingsComponent,
            data: {
              label: 'Användarprofil',
              showInSubMenu: true,
            },
          },
          {
            path: 'company',
            component: CompanyUpdateSettingsComponent,
            data: {
              label: 'Företagsinformation',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'invoice-offer-salary',
            component: CompanyInvoiceOfferSettingsComponent,
            data: {
              label: 'Faktura & offert',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'project-types',
            component: CompanyProjectTypeSettingsComponent,
            data: {
              label: 'Projekttyper',
              showInSubMenu: true,
              enabled:
                this.userFlags.isAdmin &&
                this.userFlags.hasFlag('useProjectTypes'),
            },
          },
          {
            path: 'product-types',
            component: CompanyCostTypeSettingsComponent,
            data: {
              label: 'Kostnadstyper',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'user-product-types',
            component: CompanyUserCostTypeSettingsComponent,
            data: {
              label: 'Yrkestyper',
              showInSubMenu: true,
              enabled:
                this.userFlags.isAdmin &&
                this.userFlags.hasFlag('useUserCostType'),
            },
          },
          {
            path: 'division-types',
            component: CompanyDivisionSettingsComponent,
            data: {
              label: 'Divisioner',
              showInSubMenu: true,
              enabled:
                this.userFlags.isAdmin &&
                this.userFlags.hasFlag('useCompanyDivisions'),
            },
          },

          {
            path: 'sie-export',
            component: InvoiceExportSettingsComponent,
            data: {
              label: 'SIE-export',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'integrations',
            component: IntegrationSettingsComponent,
            data: {
              label: 'Integrationer',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'quality-stamps',
            component: CompanyQualityStampsComponent,
            data: {
              label: 'Kvalitetsstämplar',
              showInSubMenu: true,
              enabled: this.userFlags.isAdmin,
            },
          },
          {
            path: 'dokuadmin',
            component: DokuadminComponent,
            data: {
              label: 'Doku Admin',
              enabled: this.userFlags.isSuperAdmin,
              showInSubMenu: true,
            },
          },
          {
            path: 'userFriends',
            component: UserListComponent,
          },
        ],
      },
    ];
  }

  private getPreviewRoutes(): RoutesWithData {
    return [
      {
        data: {
          showInMainMenu: true,
          label: 'E-liggare',
          icon: '/images/icons/Struqtur_Icon_E-liggare.svg',
          enabled: this.userFlags.hasFlag('previewPersonalliggare'),
        },
        path: 'previewAttendanceReport',
        component: FeaturePreviewEliggare,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Tidrapport',
          icon: '/images/icons/Struqtur_Icon_Tidrapport.svg',
          enabled: this.userFlags.hasFlag('previewTidrapport'),
        },
        path: 'previewTimereport',
        component: FeaturePreviewTidrapport,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Offert',
          icon: '/images/icons/Struqtur_Icon_Offert.svg',
          enabled: this.userFlags.hasFlag('previewOffert'),
        },
        path: 'previewOffer',
        component: FeaturePreviewOffert,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Dokument',
          icon: '/images/icons/Struqtur_Icon_Hantverksdokument.svg',
          enabled: this.userFlags.hasFlag('previewDoku'),
        },
        path: 'previewDocu',
        component: FeaturePreviewDoku,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Projekt',
          icon: '/images/icons/Struqtur_Icon_Projekt.svg',
          enabled: this.userFlags.hasFlag('previewProjekt'),
        },
        path: 'previewProject',
        component: FeaturePreviewProjekt,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Kundfaktura',
          icon: '/images/icons/Struqtur_Icon_Kundfaktura.svg',
          enabled: this.userFlags.hasFlag('previewFaktura'),
        },
        path: 'previewInvoice',
        component: FeaturePreviewFaktura,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Inköp',
          icon: '/images/icons/Struqtur_Icon_Leverantörsfaktura.svg',
          enabled: this.userFlags.hasFlag('previewInköp'),
        },
        path: 'previewSupplierInvoice',
        component: FeaturePreviewInkop,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Planering',
          icon: '/images/icons/Struqtur_Icon_Planering.svg',
          enabled: this.userFlags.hasFlag('previewPlanering'),
        },
        path: 'previewPlanner',
        component: FeaturePreviewPlanering,
      },
      {
        data: {
          showInMainMenu: true,
          label: 'Överblick',
          icon: '/images/icons/Struqtur_Icon_Statistik.svg',
          enabled: this.userFlags.hasFlag('previewÖverblick'),
        },
        path: 'previewOverview',
        component: FeaturePreviewOverblick,
      },
    ];
  }
}
