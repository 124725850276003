<div
  class="docu-row"
  [class.docu-row-disabled]="!row.row.used"
  [class.docu-row-subrow]="subRow"
>
  <h2 class="docu-row-header" *ngIf="row.row.header">
    <app-docu-renameable
      [name]="row.row.header"
      (nameChange)="rename($event)"
      [canRename]="canRename"
    ></app-docu-renameable>
  </h2>
  <p *ngIf="row.row.prevText" class="docu-row-description-before">
    {{ row.row.prevText }}
  </p>

  <app-docu-columns
    [columns]="row.cols"
    (docChange)="docChange.emit($event)"
    [showLabels]="row.row.showColumnLabels()"
    [labelsOnly]="labelsOnly"
    [width]="width"
  ></app-docu-columns>

  <p *ngIf="row.row.afterText">{{ row.row.afterText }}</p>
</div>
