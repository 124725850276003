import { DecimalPipe, DatePipe } from '@angular/common';
import { BooleanPipe } from '../pipes/boolean-pipe/boolean.pipe';
import { NullTextPipe } from '../pipes/null-text-pipe/null-text.pipe';

import { ColumnMetadata } from './table-metadata';

export const COLUMN_TYPES: {
  [key: string]: Partial<ColumnMetadata>;
} = {
  ID: {
    isSortable: true,
    style: { 'text-align': 'center' },
  },

  DECIMAL: {
    pipe: { pipe: DecimalPipe, args: ['1.2-2'] },
    isSortable: true,
    isSummable: true,
    style: { 'text-align': 'right' },
  },

  BOOLEAN: {
    pipe: { pipe: BooleanPipe, args: [] },
    isSortable: true,
    style: { 'text-align': 'center' },
  },

  TEXT: {
    pipe: { pipe: NullTextPipe, args: [] },
    isSortable: true,
    style: { 'text-align': 'left' },
  },

  DATE: {
    pipe: { pipe: DatePipe, args: ['shortDate', 'CET', 'sv-SE'] },
    isSortable: true,
    style: { 'text-align': 'center' },
  },
};
