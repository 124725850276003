<div class="project-title-section">
  <div class="sub-title-row">
    <h3>
      ROT-uppgifter
      <small class="italic"
        >Uppgifterna importeras till faktura samt underlag till
        skatteverket</small
      >
    </h3>
  </div>
  <div style="clear: both"></div>
</div>

<spinner *ngIf="loading | async"></spinner>
<div *ngIf="!(loading | async)"></div>
<div class="p-grid">
  <div
    class="p-col-12 p-lg-6 p-g"
    *ngFor="let row of dataSet; let i = index"
    [class.no-show]="!showRot(dataSet[i], i, showAll.value)"
  >
    <hyperion-card>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="column-title">
            <h4>Beställare {{ i + 1 }}</h4>
          </div>
          <project-rot-row
            #projectRotRow
            [dataObjectfromParent]="row"
            [projectInfo]="projectInfo"
            (objectUpdated)="handleUpdate($event)"
          ></project-rot-row>
        </div>
      </div>
    </hyperion-card>
  </div>

  <div class="p-col-12 align-right">
    <app-button (click)="actionUpdate()" [type]="'update'"></app-button>
    <app-button
      class="ml5"
      *ngIf="!(showAll | async)"
      (click)="showAllToggle()"
      [type]="'expandView'"
    ></app-button>
  </div>
</div>
