import { gql } from 'apollo-angular';

import { Contact } from './contact';

// GQL FRAGMENTS

const ATTENDANCE_REPORT_COMMON = gql`
  fragment AttendanceReportCommon on AttendanceReport {
    id
    startTime
    latitude
    longitude
    comment
    startOrStop
    isCanceled_PreAttendanceReportTypeHyperion
    editsCached_PreAttendanceReportTypeHyperion {
      edges {
        node {
          id
          startTime
          latitude
          longitude
          commentAboutUpdate
          startOrStopTime
        }
      }
    }
  }
`;

/** Export GQL Fragments on AttendanceReport */
export const AttendanceReport = {
  fragments: {
    common: ATTENDANCE_REPORT_COMMON,
  },
};

// GQL Queries

const ATTENDANCE_REPORT_PROJECTS = {
  query: gql`
    query fetchAttendanceReportProjects($status: Int, $forceAll: Boolean) {
      company {
        id
        orgNr
        attendanceReportProjects_PreCompanyTypeHyperion(
          status: $status
          forceAll: $forceAll
        ) {
          edges {
            node {
              id
              trueId
              mark
              constructionSiteNumber
              subcontractorRelationToProjectId
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const ATTENDANCE_REPORTS = {
  query: gql`
    query fetchAttendanceReports($id: Int) {
      projectGlobal(id: $id) {
        id
        allowSubcontractorToAdSubcontractors
        subcontractorRelationToProjectId
        company {
          id
          name
          orgNr
        }
        attendanceReportsStopParentIsNull {
          edges {
            node {
              id
              companyOrgNr
              realUser_PreAttendanceReportTypeHyperion {
                id
                firstName
                lastName
                personalNumber
                errors
                class
                classId
              }
              startTime
              startOrStop
              startOrStopTime
            }
          }
        }
      }
      company {
        id
        name
        orgNr
      }
    }
  `,
  testVariables: {},
};

const ATTENDANCE_REPORTS_COMPLETE_DATA = {
  query: gql`
    query fetchAttendanceReportsCompleteData($orgNr: String, $projectId: Int) {
      projectGlobal(id: $projectId) {
        id
        attendanceReportsMeta_PreProjectTypeHyperion(orgNr: $orgNr) {
          sumHours
        }
        attendanceReportsPerformanceOptimized_PreProjectTypeHyperion(
          orgNr: $orgNr
        ) {
          edges {
            node {
              ...AttendanceReportCommon
              classVars_PreAttendanceReportTypeHyperion {
                edges {
                  node {
                    name
                    value
                  }
                }
              }
              companyOrgNr
              startOrStopTime
              projectId
              realUser_PreAttendanceReportTypeHyperion {
                id
                firstName
                lastName
                personalNumber
                errors
                class
                classId
              }
              start {
                ...AttendanceReportCommon
              }
            }
          }
        }
      }
    }
    ${AttendanceReport.fragments.common}
  `,
  testVariables: {
    orgNr: '',
    projectId: 1906,
  },
};

const SINGLE_ATTENDANCE_REPORT = {
  query: gql`
    query fetchSingleAttendanceReport($projectId: Int, $id: Int) {
      projectGlobal(id: $projectId) {
        id
        attendanceReports(id: $id) {
          edges {
            node {
              ...AttendanceReportCommon
              classVars_PreAttendanceReportTypeHyperion {
                edges {
                  node {
                    name
                    value
                  }
                }
              }
              companyOrgNr
              startOrStopTime
              projectId
              realUser_PreAttendanceReportTypeHyperion {
                id
                firstName
                lastName
                personalNumber
                errors
                class
                classId
              }
              start {
                ...AttendanceReportCommon
              }
            }
          }
        }
      }
    }
    ${AttendanceReport.fragments.common}
  `,
  testVariables: {},
};

const COLLECTION_LIST = {
  query: gql`
    query fetchCollectionListAttendanceReports(
      $projectIds: [Int]
      $userIds: [Int]
      $startOrStopTime: String
      $from: String
      $to: String
    ) {
      company {
        id
        projects(multiId: $projectIds) {
          edges {
            node {
              id
              trueId
              mark
              constructionSiteNumber
              attendanceReportsPerformanceOptimized_PreProjectTypeHyperion(
                usersMultiId: $userIds
                startOrStopTime: $startOrStopTime
                from: $from
                to: $to
              ) {
                edges {
                  node {
                    ...AttendanceReportCommon
                    classVars_PreAttendanceReportTypeHyperion {
                      edges {
                        node {
                          name
                          value
                        }
                      }
                    }
                    companyOrgNr
                    startOrStopTime
                    projectId
                    realUser_PreAttendanceReportTypeHyperion {
                      id
                      firstName
                      lastName
                      personalNumber
                      errors
                      class
                      classId
                    }
                    start {
                      ...AttendanceReportCommon
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${AttendanceReport.fragments.common}
  `,
  testVariables: {
    projectIds: [1906],
    userIds: [2],
    startOrStopTime: '',
    from: '',
    to: '',
  },
};

const ATTENDANCE_PROJECTS_SUBCONTRACTORS = {
  query: gql`
    query fetchAttendanceProjectSubcontractors($projectId: Int!) {
      company {
        id
        attendanceProjectSubcontractors_PreCompanyTypeHyperion(
          projectId: $projectId
        ) {
          edges {
            node {
              name
              orgNr
            }
          }
        }
      }
    }
  `,
  testVariables: {
    projectId: 1906,
  },
};

const ATTENDANCE_REPORT_PROJECTS_2 = {
  query: gql`
    query fetchAttendanceReportProjects2($status: Int, $forceAll: Boolean) {
      company {
        id
        attendanceReportProjects_PreCompanyTypeHyperion(
          status: $status
          forceAll: $forceAll
        ) {
          totalCount
          edges {
            node {
              id
              status
              trueId
              created
              mark
              shortMessage
              offerId
              offertId
              color
              constructionSiteNumber
              invoiceData_PreProjectTypeHyperion {
                supplier {
                  rowsCountMoved
                  rowsCountUnMoved
                }
              }
              notInvoicedDaysCount
              endDate
              typeId
              daysFromActiveness_PreProjectTypeHyperion
              client
              establishment
              establishmentContact {
                id
                name
                address
              }
              clientContact {
                ...ClientContactBase
              }
              projectBookmarks {
                edges {
                  node {
                    id
                    userId
                    projectId
                  }
                }
              }
              todos {
                totalCount
                edges {
                  node {
                    id
                    projectId
                    description
                    done
                    usersTodoRelation {
                      edges {
                        node {
                          id
                          user {
                            id
                            firstName
                            lastName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {},
};

/** Export GQL Queries on Attendance */
export const queries = {
  attendanceProjectsSubcontractors: ATTENDANCE_PROJECTS_SUBCONTRACTORS,
  attendanceReportProjects: ATTENDANCE_REPORT_PROJECTS,
  attendanceReportProjects2: ATTENDANCE_REPORT_PROJECTS_2,
  attendanceReports: ATTENDANCE_REPORTS,
  attendanceReportsCompleteData: ATTENDANCE_REPORTS_COMPLETE_DATA,
  collectionList: COLLECTION_LIST,
  singleAttendanceReport: SINGLE_ATTENDANCE_REPORT,
};
