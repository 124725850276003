import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetSharedToCustomersQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetSharedToCustomersQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', costumerRelationsToProject?: { __typename?: 'CostumerRelationToProjectConnection', edges?: Array<{ __typename?: 'CostumerRelationToProjectEdge', node?: { __typename?: 'CostumerRelationToProject', id: string, project_id?: number | null, costumer_id?: number | null, offer?: number | null, timereport?: number | null, product?: number | null, invoice?: number | null, picture?: number | null, document?: number | null, todo?: number | null, comment?: number | null, invoiceNotification?: number | null, documentNotification?: number | null, privateCostumer?: { __typename?: 'PrivateCostumer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, mobilenumber?: string | null, telephonenumber?: string | null } | null } | null } | null> | null } | null } | null };

export type GetCustomerQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetCustomerQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', contacts?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', name?: string | null, mail?: string | null, phone?: string | null, mobilePhone?: string | null } | null } | null> | null } | null } | null };

export type CreateCustomerRelationMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CostumerRelationToProjectTypeHyperionMutationInput>;
}>;


export type CreateCustomerRelationMutation = { __typename?: 'HyperionSchemaMutation', costumerRelationToProjectTypeHyperionMutation?: { __typename?: 'CostumerRelationToProject', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type CreatePrivateCustomerMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.PrivateCostumerTypeHyperionMutationInput>;
}>;


export type CreatePrivateCustomerMutation = { __typename?: 'HyperionSchemaMutation', privateCostumerTypeHyperionMutation?: { __typename?: 'PrivateCostumer', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type DeleteCustomerRelationMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteCustomerRelationMutation = { __typename?: 'HyperionSchemaMutation', costumerRelationToProjectTypeHyperionMutation?: { __typename?: 'CostumerRelationToProject', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type UpdateCustomerRelationMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CostumerRelationToProjectTypeHyperionMutationInput>;
}>;


export type UpdateCustomerRelationMutation = { __typename?: 'HyperionSchemaMutation', costumerRelationToProjectTypeHyperionMutation?: { __typename?: 'CostumerRelationToProject', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null, errors?: Array<string | null> | null } | null> | null } | null };

export type PrivateCostumerFragment = { __typename?: 'PrivateCostumer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, mobilenumber?: string | null, telephonenumber?: string | null };

export type CustomerRelationFragment = { __typename?: 'CostumerRelationToProject', id: string, project_id?: number | null, costumer_id?: number | null, offer?: number | null, timereport?: number | null, product?: number | null, invoice?: number | null, picture?: number | null, document?: number | null, todo?: number | null, comment?: number | null, invoiceNotification?: number | null, documentNotification?: number | null, privateCostumer?: { __typename?: 'PrivateCostumer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, mobilenumber?: string | null, telephonenumber?: string | null } | null };

export const PrivateCostumerFragmentDoc = gql`
    fragment privateCostumer on PrivateCostumer {
  id
  firstName
  lastName
  email
  mobilenumber
  telephonenumber
}
    `;
export const CustomerRelationFragmentDoc = gql`
    fragment customerRelation on CostumerRelationToProject {
  id
  project_id
  costumer_id
  privateCostumer {
    ...privateCostumer
  }
  offer
  timereport
  product
  invoice
  picture
  document
  todo
  comment
  invoiceNotification
  documentNotification
}
    ${PrivateCostumerFragmentDoc}`;
export const GetSharedToCustomersDocument = gql`
    query getSharedToCustomers($projectId: Int) {
  project(id: $projectId) {
    costumerRelationsToProject {
      edges {
        node {
          ...customerRelation
        }
      }
    }
  }
}
    ${CustomerRelationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSharedToCustomersGQL extends Apollo.Query<GetSharedToCustomersQuery, GetSharedToCustomersQueryVariables> {
    document = GetSharedToCustomersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCustomerDocument = gql`
    query getCustomer($id: Int) {
  company {
    contacts(id: $id) {
      edges {
        node {
          name
          mail
          phone
          mobilePhone
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCustomerGQL extends Apollo.Query<GetCustomerQuery, GetCustomerQueryVariables> {
    document = GetCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCustomerRelationDocument = gql`
    mutation createCustomerRelation($input: CostumerRelationToProjectTypeHyperionMutationInput) {
  costumerRelationToProjectTypeHyperionMutation(
    createCostumerRelationToProject: $input
  ) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCustomerRelationGQL extends Apollo.Mutation<CreateCustomerRelationMutation, CreateCustomerRelationMutationVariables> {
    document = CreateCustomerRelationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePrivateCustomerDocument = gql`
    mutation createPrivateCustomer($input: PrivateCostumerTypeHyperionMutationInput) {
  privateCostumerTypeHyperionMutation(createPrivateCostumer: $input) {
    id
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePrivateCustomerGQL extends Apollo.Mutation<CreatePrivateCustomerMutation, CreatePrivateCustomerMutationVariables> {
    document = CreatePrivateCustomerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCustomerRelationDocument = gql`
    mutation deleteCustomerRelation($id: Int) {
  costumerRelationToProjectTypeHyperionMutation(
    deleteCostumerRelationToProject: {id: $id}
  ) {
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCustomerRelationGQL extends Apollo.Mutation<DeleteCustomerRelationMutation, DeleteCustomerRelationMutationVariables> {
    document = DeleteCustomerRelationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCustomerRelationDocument = gql`
    mutation updateCustomerRelation($input: CostumerRelationToProjectTypeHyperionMutationInput) {
  costumerRelationToProjectTypeHyperionMutation(
    updateCostumerRelationToProject: $input
  ) {
    mutationDetails {
      mutationSucceeded
      msg
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCustomerRelationGQL extends Apollo.Mutation<UpdateCustomerRelationMutation, UpdateCustomerRelationMutationVariables> {
    document = UpdateCustomerRelationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }