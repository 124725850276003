import { Component, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'timeout-warning',
  templateUrl: 'timeout-warning.html',
})
export class TimeoutWarning implements OnInit {
  sessionTimeoutString;
  sessionTimeout;
  time: number;
  minutes: number;
  seconds: number;
  hours: number;

  init() {
    this.sessionTimeoutString = localStorage.getItem('MEsessionTime');

    this.sessionTimeout = this.sessionTimeoutString * 1;

    if (this.sessionTimeout > 10 * 60) {
      this.time = 300;
    } else {
      this.time = 20;
    }
  }

  countDown() {
    this.time -= 1;

    this.minutes = Math.floor(this.time / 60) % 60;
    this.seconds = this.time % 60;
  }

  ngOnInit() {
    setInterval(() => {
      this.countDown();
    }, 2000);
  }
}
