import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CalculationRowType } from './offer-calculation-rows/offer-calculation-rows.component';

@Injectable()
export class SingleOfferService {
  private todos = new BehaviorSubject<any>({});
  private calculationRows = new BehaviorSubject<any>({});
  private webshopRows = new BehaviorSubject<any>({});

  private allowOfferUpdate = new BehaviorSubject<any>(false);

  todos$ = this.todos.asObservable();
  calculationRows$ = this.calculationRows.asObservable();
  webshopRows$ = this.webshopRows.asObservable();
  allowOfferUpdate$ = this.allowOfferUpdate.asObservable();

  constructor() {}

  addTodos(todos: any) {
    this.todos.next(todos);
  }

  addCalculationRows(calculationRows: CalculationRowType[]) {
    this.calculationRows.next(calculationRows);
  }

  addWebshopRows(webshopRows: CalculationRowType[]) {
    this.webshopRows.next(webshopRows);
  }

  changeUpdateRights(granted: boolean) {
    this.allowOfferUpdate.next(granted);
  }
}
