import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { HttpService } from 'app/shared/http';
import { GlobalService } from 'app/shared/global/index';

@Component({
  selector: 'offer-autocomplete-text-area',
  templateUrl: 'offer-auto-complete.component.html',
})
export class OfferAutoCompleteComponent implements OnInit, OnDestroy {
  @Input() sectionName: string;
  @Input() value: string;
  @Output() setFieldModel = new EventEmitter();
  @ViewChild(AutoComplete, { static: true })
  autoCompleteComponent: AutoComplete;
  results = new BehaviorSubject<any>([]);

  gettingResult = false;
  autoModel: string;
  inputSubscription: Subscription;
  keyUp = new Subject<string>();

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.subscribeToInput();
  }

  closeDropdown() {
    setTimeout(() => {
      this.autoCompleteComponent.overlayVisible = false;
    }, 20);
  }

  search() {
    if (
      this.value.length > 2 &&
      this.value.length < 1700 &&
      !this.gettingResult
    ) {
      this.searchCall(this.value);
    } else {
      this.autoCompleteComponent.overlayVisible = false;
    }
  }

  searchCall(query) {
    this.gettingResult = true;

    const url =
      this.globalService.getUrlPrefix() +
      '/offer/AutoCompleteByField?limit=10&field=' +
      this.sectionName +
      '&q=' +
      query;

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.results.next(data);
      this.gettingResult = false;
      if (this.results.value.length > 0) {
        this.autoCompleteComponent.overlayVisible = true;
      } else {
        this.autoCompleteComponent.overlayVisible = false;
      }
    });
  }

  setValueFromAutosuggest(value) {
    const val = value;
    this.setFieldModel.emit(val);
    this.autoCompleteComponent.overlayVisible = false;
  }

  setModelOnInput(value) {
    this.setFieldModel.emit(value);
    this.keyUp.next(value);
  }

  subscribeToInput() {
    this.inputSubscription = this.keyUp
      .pipe(
        map(event => event),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((data: string) => {
        if (data && data.length > 2) {
          this.search();
        }
      });
  }

  ngOnDestroy() {
    this.inputSubscription && this.inputSubscription.unsubscribe();
  }
}
