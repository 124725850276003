import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Subscription, Observable } from 'rxjs';

/*

    Handle rules for forms everywhere on the app / PANI 2016-11-21

    Commentslabes
        Vi vet inte är dem behövs - säkerställa att de alltid laddas innan de behövs
            Vi vet inte server svarstiden

        När en request för labels har gjorts, can man sätta någon flagga så att den inte görs igen

*/

/* Decide which rules are to be stored global defualt in localStorage */
const globalDefaultRules = [
  'Project',
  'Contact',
  'User',
  'Day',
  'UserCalculationRow',
  'OfferContractor',
  'OfferOrganization',
  'Todo',
  'OfferSignatur',
  'InvoiceRotRequesting',
];

@Injectable()
export class HyperionRulesService {
  constructor(private apollo: Apollo) {}

  checkForRulesInLocalStorage(force?: boolean) {
    let rulesFromLocalStorage;
    rulesFromLocalStorage = localStorage.getItem('rulesInGlobal');
    return Observable.create(observer => {
      if (rulesFromLocalStorage == null || force) {
        const rulesObs: Observable<any> = this.getDefaultRules();
        const rulesSub: Subscription = rulesObs.subscribe(({ data }) => {
          const rulesInGlobal = [];
          for (const i in data.rules) {
            const item = JSON.stringify(data.rules[i].rules);
            localStorage.setItem('rulesFor' + i, item);
            rulesInGlobal.push(i);
            const rulesInGlobalToLS = JSON.stringify(rulesInGlobal);
            localStorage.setItem('rulesInGlobal', rulesInGlobalToLS);
          }
          observer.next('done rules');
        });
      } else {
        observer.next('done rules');
      }
    });
  } // End checkForRulesInLocalStorage

  checkIfServerIsReady(getRulesFromServer) {
    // FIXME: There is no hope..
    /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
    let noMakingServerRequestFlagFor_Exists = true; // satt till true så får koden gå vidare
    for (const i in getRulesFromServer) {
      if (
        localStorage.getItem(
          'makingServerRequestFlagFor_RulesFor' + getRulesFromServer[i]
        ) !== null
      ) {
        noMakingServerRequestFlagFor_Exists = false; // ingen av dem satta
      }
    }
    return noMakingServerRequestFlagFor_Exists;
    /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  }

  // Returna vad ifall den catchar error/failar?
  getRules(modelArray) {
    const rulesObject = {};
    const getRulesFromServer = [];
    for (const i in modelArray) {
      let rulesForModel;
      rulesForModel = localStorage.getItem('rulesFor' + modelArray[i]);
      if (rulesForModel != null) {
        rulesForModel = JSON.parse(rulesForModel);
        rulesObject[modelArray[i]] = rulesForModel;
      } else {
        getRulesFromServer.push(modelArray[i]);
      }
    }

    return Observable.create(observer => {
      if (getRulesFromServer.length > 0) {
        for (const model in getRulesFromServer) {
          // FÖrsta gången set att den kör alla var och en för sig
          localStorage.setItem(
            'makingServerRequestFlagFor_RulesFor' + getRulesFromServer[model],
            '1'
          );
        }

        this.getRulesFromServer(getRulesFromServer).subscribe(({ data }) => {
          for (const model in data['rules']) {
            rulesObject[model] = data['rules'][model]['rules'];
            // här är en request färdig, det är här ska tages bort
            localStorage.removeItem(
              'makingServerRequestFlagFor_RulesFor' + model
            );

            const item = JSON.stringify(data['rules'][model]['rules']);
            localStorage.setItem('rulesFor' + model, item);
          }
          observer.next(rulesObject);
          observer.complete();
        });
      } else {
        observer.next(rulesObject);
        observer.complete();
      }
    });
  } // End getRules

  getRulesFromServer(modelArray) {
    const getRulesQuery = this.constructRulesQuery(modelArray, 'getRulesQuery');
    return this.apollo.query({
      query: getRulesQuery,
    });
  }

  getDefaultRules() {
    const getRulesQuery = this.constructRulesQuery(
      globalDefaultRules,
      'globalRulesQuery'
    );
    return this.apollo.query({
      query: getRulesQuery,
    });
  }

  constructRulesQuery(modelArray, queryName) {
    let rulesQueryString: string = 'query ' + queryName + ' { rules { ';
    for (const model in modelArray) {
      rulesQueryString += modelArray[model] + ' { rules } ';
    }
    rulesQueryString += '}}';
    const rulesQuery = gql`
      ${rulesQueryString}
    `;
    return rulesQuery;
  }
}
