<app-header> </app-header>

<div class="page-container p-grid">
  <div class="p-col-12 p-grid">
    <h1 class="p-col-12 p-mb-2">Uppdateringar</h1>
    <span class="p-col-12 p-mt-0">
      <p>
        Nedan listas de senast gjorda uppdateringarna. Titta in här ibland för
        att få koll på vad som händer och vilka nya funktioner du kan använda.
      </p>
      <p>
        Vill du ha en genomgång av en ny funktion så kontakta gärna vår support.
      </p>
    </span>
  </div>

  <div class="p-col-12" *ngFor="let update of appUpdates">
    <p-card class="p-col-12">
      <ng-template pTemplate="header">
        <h2 *ngIf="update.publishHead !== update.publishDate">
          {{ update.publishHead }}
        </h2>
        <h3>
          {{ update.publishDate }}
        </h3>
      </ng-template>
      <div [innerHtml]="update.publishBody"></div>
    </p-card>
  </div>
</div>
