<div class="p-grid" style="overflow: hidden">
  <div class="p-col-12 p-g-nopad row-in-card-xl no-border p-grid">
    <div class="p-col-12 p-md-6 p-xl-offset-6 p-xl-3">
      <form [formGroup]="todoTopicForm" class="no-margin">
        <appForm2-list-input
          formControlName="Name"
          (keyup.enter)="createTopic()"
        ></appForm2-list-input>
      </form>
    </div>

    <div class="p-col-12 p-md-6 p-xl-3">
      <app-button
        (click)="createTopic()"
        [type]="'save'"
        [model]="'Todotopic'"
      ></app-button>
    </div>
  </div>

  <div class="p-col-12" style="overflow-x: auto">
    <p-table
      #todoTable
      class="transactionsEditTable"
      [value]="dataSet"
      [rowHover]="true"
      [resizableColumns]="true"
      columnResizeMode="expand"
    >
      <ng-template pTemplate="header">
        <tr class="p-text-truncate">
          <th style="width: 5rem">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['type']"
            ></label>
          </th>
          <th pResizableColumn style="width: 6.5rem">
            <label class="form-control-label"
              >Beskriv&shy;ning
              <span
                class="help-tooltip smaller"
                tooltipPosition="right"
                pTooltip="Beskrivning i offerten mot kund"
                [escape]="false"
                >?</span
              >
            </label>
          </th>
          <th pResizableColumn style="width: 8rem">
            <label class="form-control-label"
              >Arbets&shy;moment
              <span
                class="help-tooltip smaller"
                tooltipPosition="right"
                pTooltip="Genom ange text i denna kolumn kan du lägg upp ett internt arbetsmoment"
                [escape]="false"
                >?</span
              >
            </label>
          </th>
          <th style="width: 55px">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['quantity']"
            ></label>
          </th>
          <th style="width: 80px">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['unit']"
            ></label>
          </th>
          <th style="width: 80px">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['unitPrice']"
            ></label>
          </th>
          <th style="width: 80px">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['tax']"
            ></label>
          </th>
          <th style="width: 40px" *ngIf="isGreenTaxReduction">
            <label class="form-control-label">Grön</label>
          </th>
          <th style="width: 40px" *ngIf="!isGreenTaxReduction">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['rot']"
            ></label>
          </th>
          <th style="width: 4rem">
            <label
              class="form-control-label"
              [innerHTML]="labels['Todo']['cost']"
            ></label>
          </th>
          <th style="width: 35px">
            <label
              class="form-control-label"
              [pTooltip]="labels['Todo']['orderNr']"
            >
              <i class="fa fa-sort"></i>
            </label>
          </th>
          <th style="width: 60px">
            <label class="form-control-label">Hantera</label>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-todo let-rowIndex="rowIndex">
        <tr
          app-offer-todo-row
          [dataObjectFromParent]="todo"
          [offerInfo]="offerInfo"
          [labels]="labels"
          [topicDropdown]="todoTopicsDropdown | async"
          [taxDropdown]="taxDropdown | async"
          [greenTaxDropdownOptions]="greenTaxDropdownOptions | async"
          [bigColumn]="bigColumn | async"
          (changeBigColumn)="changeBigColumn($event)"
          (spliceObjectNotYetCreated)="removeRow(rowIndex)"
          (deletedObject)="removeRow(rowIndex)"
          (createdObject)="setRowId($event, rowIndex)"
          (addRowIfNoEmpty)="addRowIfNoEmpty()"
          [isGreenTaxReduction]="isGreenTaxReduction"
        ></tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12 align-right">
    <app-button (click)="addRow()" [type]="'addNoText'"></app-button>
  </div>
</div>
