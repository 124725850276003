import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import { ConfirmationService, SelectItem } from 'primeng/api';

import { FormHandlerService } from 'app/shared/forms';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { ProductsAutosuggestService } from 'app/shared/company/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { ProductDetailsService } from 'app/shared/product-details/product-details.service';
import { ProductDetails } from 'app/shared/product-details/product-details';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';
import productsAutoSuggestServiceProvider from 'app/shared/company/product/products.autosuggest.service.provider';

@Component({
  selector: '[app-calculation-row-row]',
  templateUrl: './calculation-row-row.component.html',
  styleUrls: ['../offer-calculation-rows.component.scss'],
  providers: [FormHandlerService, productsAutoSuggestServiceProvider],
})
export class CalculationRowRowComponent implements OnDestroy, OnInit {
  @Input() offerInfo: {
    id?: any;
    currentExternalProject?: string;
    typeOffer?: any;
    groupCosts?: any;
    calculation?: any;
    [key: string]: any;
  };
  @Input() dataObjectFromParent;
  @Input() labels;
  @Input() taxDropdown = [];
  @Input() showProductPrices = true;
  @Input() isMissingDeromeProductIntegration = false;
  @Input() public greenTaxDropdownOptions: SelectItem[];
  @Input() public isGreenTaxReduction = false;
  @Output() deletedObject = new EventEmitter();
  @Output() createdObject = new EventEmitter();
  @Output() addRowIfNoEmpty = new EventEmitter();

  dataModel = 'userCalculationRow';
  dataModelCapitalized = 'UserCalculationRow';
  isCreating = false;
  buttons: ButtonsAsync;
  compareString = '';
  buttonObject = ['delete'];
  offerInfoCalculationId: number;
  results = [];
  productAutoModel;
  public unitsDropdownParams = {
    options: [...QuantityUnits],
  };
  keyUp = new Subject<string>();
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      source: null,
      sourceId: null,
    },
  };
  public componentMainForm: ExtendedFormGroup;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  private nameFromAutosuggest: string;

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private productsAutoSuggestService: ProductsAutosuggestService,
    private messageService: MessageService,
    private productDetailsService: ProductDetailsService
  ) {}

  ngOnInit() {
    this.buttons = this.formHandler.getButtonValuesAsync(this.buttonObject);

    this.setFields();
    this.initForm();

    this.offerInfoCalculationId = this.getOfferInfoCalculationId();

    this.productAutoModel = { benamning: this.dataObjectFromParent.name };
  }

  private subscribeToInput() {
    this.componentMainForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe(calculationRow => {
        this.setInput(calculationRow);
      });
  }

  public setInput(calculationRow) {
    const date = new Date();
    const min = date.getMinutes();

    const newComapareString =
      '_' +
      calculationRow.name +
      '_' +
      calculationRow.quantity +
      '_' +
      calculationRow.tax +
      '_' +
      calculationRow.greenTaxReduction +
      '_' +
      calculationRow.cost +
      '_' +
      calculationRow.procent +
      '_' +
      calculationRow.calculationId +
      '_' +
      calculationRow.id +
      '_' +
      calculationRow.order +
      '_' +
      calculationRow.productId +
      '_' +
      calculationRow.unit +
      '_' +
      min;

    if (this.compareString !== newComapareString) {
      this.handleInput();
      this.addRowIfNoEmpty.emit();

      this.compareString = newComapareString;
    }
  }

  private setFields() {
    for (const [key, value] of Object.entries(this.dataObjectFromParent)) {
      if (key === 'tax') {
        this.formFields.attributes[key] = +value;
      } else {
        this.formFields.attributes[key] = value;
      }
    }
  }

  private setRowSum(value: any) {
    this.dataObjectFromParent.rowSumPlusProcent_PreUserCalculationRowTypeHyperion =
      value;
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        if (
          this.productDetailsService.usesProductDetailsDeromeAPI(
            this.dataObjectFromParent
          )
        ) {
          this.updateUnitDropdown(this.dataObjectFromParent.unit);
          this.componentMainForm.controls.name.disable();
          this.componentMainForm.controls.unit.disable();
          this.formHandler.formValid([this.componentMainForm]);
        }
        this.subscribeToInput();
      });
  }

  private handleInput() {
    this.dataObjectFromParent.quantity =
      this.componentMainForm.controls.quantity.value;
    this.dataObjectFromParent.tax = this.componentMainForm.controls.tax.value;
    this.dataObjectFromParent.cost = this.componentMainForm.controls.cost.value;
    this.dataObjectFromParent.procent =
      this.componentMainForm.controls.procent.value;
    this.dataObjectFromParent.productId =
      this.componentMainForm.controls.productId.value;
    this.dataObjectFromParent.order =
      this.componentMainForm.controls.order.value;
    this.dataObjectFromParent.name = this.componentMainForm.controls.name.value;
    this.dataObjectFromParent.unit = this.componentMainForm.controls.unit.value;
    this.dataObjectFromParent.greenTaxReduction =
      this.componentMainForm.controls.greenTaxReduction.value;

    // If manually inserted name, disassociate source and sourceId
    if (
      this.nameFromAutosuggest !== this.componentMainForm.controls.name.value
    ) {
      this.componentMainForm.controls.source.setValue(null);
      this.componentMainForm.controls.sourceId.setValue(null);
    }

    this.dataObjectFromParent.source =
      this.componentMainForm.controls.source.value;
    this.dataObjectFromParent.sourceId =
      this.componentMainForm.controls.sourceId.value;

    if (!this.isCreating) {
      const id = this.componentMainForm.controls.id.value;
      if (id === null) {
        this.actionCreate();
      } else {
        this.actionUpdate();
      }
    }
  }

  private actionCreate() {
    const product = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    if (this.productDetailsService.usesProductDetailsDeromeAPI(product)) {
      return this.updateFormWithProductDetailsData(product)
        .pipe(take(1))
        .subscribe(_ => this.create());
    } else {
      this.create();
    }
  }

  private create() {
    this.isCreating = true;
    const crudType = 'create';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    dataToMutation['calculationId'] = this.offerInfoCalculationId;
    delete dataToMutation.id;
    delete dataToMutation.rowSumPlusProcent_PreUserCalculationRowTypeHyperion;

    this.formHandler.lockButtonsAsync(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.unlockButtonsAsync(this.buttons);
      this.isCreating = false;
    } else {
      const returnKeys = Object.keys(this.componentMainForm.value);
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnKeys
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.handleCreatedObject(executedData);
            } else {
              this.mutationService.displayMutationStatus(executedData);
            }
            this.setRowSum(
              executedData.rowSumPlusProcent_PreUserCalculationRowTypeHyperion
            );
            this.formHandler.unlockButtonsAsync(this.buttons);
            executeMutationSub.unsubscribe();
            this.isCreating = false;
          },
          err => {
            this.isCreating = false;
            console.log(err);
          }
        );
    }
    // låsas upp här
  }

  handleCreatedObject(executedData) {
    this.componentMainForm.controls.id.setValue(executedData.id);

    const createdObject = executedData;
    delete createdObject.mutationDetails;
    delete createdObject.__typename;

    this.createdObject.emit(createdObject);
  }

  private actionUpdate() {
    if (
      this.productDetailsService.usesProductDetailsDeromeAPI(
        this.dataObjectFromParent
      )
    ) {
      const product = this.mutationService.getMutationDataFromForm(
        this.componentMainForm
      );
      return this.updateFormWithProductDetailsData(product)
        .pipe(take(1))
        .subscribe(_ => this.update());
    } else {
      this.update();
    }
  }

  private update() {
    const crudType = 'update';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    delete dataToMutation[
      'rowSumPlusProcent_PreUserCalculationRowTypeHyperion'
    ];

    this.formHandler.lockButtonsAsync(this.buttons);

    dataToMutation.calculationId && delete dataToMutation.calculationId;

    if (dataToMutation.id > 0) {
      if (!this.formHandler.formValid([this.componentMainForm])) {
        // If errors in client
        this.formHandler.setErrorFlag(
          false,
          this.componentMainForm,
          'submitted'
        ); // Show errors (param "submitted" marks all fields as touched)
        this.formHandler.unlockButtonsAsync(this.buttons);
      } else {
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            this.dataModel,
            crudType,
            false,
            dataToMutation,
            ['rowSumPlusProcent_PreUserCalculationRowTypeHyperion']
          )
          .subscribe(
            executedData => {
              this.formHandler.showServerErrorsOnAttributes(executedData, [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ]);
              if (!executedData.mutationSucceededAllArguments) {
                this.mutationService.displayMutationStatus(executedData);
              }
              this.setRowSum(
                executedData.rowSumPlusProcent_PreUserCalculationRowTypeHyperion
              );

              this.formHandler.unlockButtonsAsync(this.buttons);

              executeMutationSub.unsubscribe();
            },
            err => {
              console.log(err);
            }
          );
      }
    }
  }

  handleDelete() {
    if (this.componentMainForm.controls.id.value === null) {
      this.deletedObject.emit();
    } else {
      this.actionDelete();
    }
  }

  actionDelete() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };

    this.confirmationService.confirm({
      // Hur göra här?
      message: 'Är du säker på att du vill ta bort produkten?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.formHandler.lockButtonsAsync(this.buttons, true);
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            this.dataModel,
            crudType,
            false,
            dataToMutation
          )
          .subscribe(
            data => {
              if (
                !this.formHandler.showServerErrorsOnAttributes(data, [
                  {
                    form: this.componentMainForm,
                    argument: crudType + this.dataModelCapitalized,
                  },
                ]).errors
              ) {
                this.deletedObject.emit();
                this.componentMainForm.controls.id.setValue(-11);
              }
              executeMutationSub.unsubscribe();
              this.mutationService.displayMutationStatus(data);
              this.formHandler.unlockButtonsAsync(this.buttons, true);
            },
            err => {
              this.formHandler.unlockButtonsAsync(this.buttons, true);
              console.log(err);
            }
          );
      },
      reject: () => {},
    });
  }

  setProductFromAutosuggest(product) {
    this.nameFromAutosuggest = product.benamning;
    this.componentMainForm.controls.name.setValue(product.benamning);

    if (this.isMissingDeromeProductIntegration) {
      // Early return before running into product details on missing external dependency
      const toastMessage: ToastMessage = {
        severity: ToastMessageSeverityType.WARNING,
        summary:
          'Du saknar uppkoppling för ditt Kund-ID och kan därmed inte se priser. Kontakta supporten för att kunna se priser.',
      };
      this.messageService.insertData(toastMessage);
      return;
    }

    this.componentMainForm.controls.cost.setValue(product.avtalspris);

    if (
      product.enhet &&
      product.enhet.length &&
      QuantityUnits.find(type => type.value === product.enhet)
    ) {
      this.componentMainForm.controls.unit.setValue(product.enhet);
    }

    this.componentMainForm.controls.quantity.setValue(product.antal || 1);
    this.componentMainForm.controls.productId.setValue(product.id);

    if (this.productDetailsService.usesProductDetailsAPI(product)) {
      this.componentMainForm.controls.sourceId.setValue(product.sourceId);
      this.componentMainForm.controls.source.setValue(product.source);
    }

    if (this.productDetailsService.usesProductDetailsDeromeAPI(product)) {
      this.componentMainForm.controls.unit.disable();
      this.componentMainForm.controls.name.disable();
    }
  }

  /**
   * Updates the prices based on the new quantity
   */
  private updateFormWithProductDetailsData(
    product: any
  ): Observable<ProductDetails> {
    return this.productDetailsService
      .getProductDetails(product.source, product.sourceId, product.quantity, {
        offerId: this.offerInfo.id || null,
      })
      .pipe(
        tap(productDetails => {
          // If no cost was set, we set the default one that comes from product-details
          if (!this.componentMainForm.controls.cost.value) {
            this.componentMainForm.controls.cost.setValue(
              productDetails.salesPrice || ''
            );
          }
          this.componentMainForm.controls.name.setValue(productDetails.name);
          this.updateUnitDropdown(productDetails.salesPriceUnit || '');
        })
      );
  }
  public removeErrors(error: string): void {
    this.componentMainForm.controls.name.mergedErrors =
      this.componentMainForm.controls.name.mergedErrors.filter(
        e => e !== error
      );
  }

  private updateUnitDropdown(unit: string) {
    const unitDropdownParam = {
      value: unit || 'st',
      label: unit || 'Styck',
    };

    const options = this.unitsDropdownParams.options;
    if (options.map(o => o.value).indexOf(unitDropdownParam.value) === -1) {
      this.unitsDropdownParams = {
        options: [unitDropdownParam, ...options],
      };
    }
    this.componentMainForm.controls.unit.setValue(unitDropdownParam.value);
  }

  private getOfferInfoCalculationId(): number {
    if (+this.dataObjectFromParent.calculationId) {
      return +this.dataObjectFromParent.calculationId;
    }

    if (
      this.offerInfo &&
      this.offerInfo.calculation &&
      +this.offerInfo.calculation.id
    ) {
      return +this.offerInfo.calculation.id;
    }

    return;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.productsAutoSuggestService.destroy();
  }
}
