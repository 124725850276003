import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetInvoicesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', invoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type GetSupplierInvoicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSupplierInvoicesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', supplierInvoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type GetSingleInvoiceQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetSingleInvoiceQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', invoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type GetSingleSupplierInvoiceQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetSingleSupplierInvoiceQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', supplierInvoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type InvoiceSearchQueryVariables = Types.Exact<{
  searchString?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type InvoiceSearchQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', invoiceSearch?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type SupplierInvoiceSearchQueryVariables = Types.Exact<{
  searchString?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SupplierInvoiceSearchQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', supplierInvoiceSearch?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null } | null } | null> | null } | null } | null };

export type InvoiceSelectFragmentFragment = { __typename?: 'Invoice', id: string, fakturaNr?: string | null };

export const InvoiceSelectFragmentFragmentDoc = gql`
    fragment invoiceSelectFragment on Invoice {
  id
  fakturaNr
}
    `;
export const GetInvoicesDocument = gql`
    query getInvoices {
  company {
    invoices(last: 100) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInvoicesGQL extends Apollo.Query<GetInvoicesQuery, GetInvoicesQueryVariables> {
    document = GetInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSupplierInvoicesDocument = gql`
    query getSupplierInvoices {
  company {
    supplierInvoices(last: 100) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSupplierInvoicesGQL extends Apollo.Query<GetSupplierInvoicesQuery, GetSupplierInvoicesQueryVariables> {
    document = GetSupplierInvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSingleInvoiceDocument = gql`
    query getSingleInvoice($id: Int) {
  company {
    invoices(id: $id) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSingleInvoiceGQL extends Apollo.Query<GetSingleInvoiceQuery, GetSingleInvoiceQueryVariables> {
    document = GetSingleInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSingleSupplierInvoiceDocument = gql`
    query getSingleSupplierInvoice($id: Int) {
  company {
    supplierInvoices(id: $id) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSingleSupplierInvoiceGQL extends Apollo.Query<GetSingleSupplierInvoiceQuery, GetSingleSupplierInvoiceQueryVariables> {
    document = GetSingleSupplierInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceSearchDocument = gql`
    query invoiceSearch($searchString: String) {
  company {
    invoiceSearch(search: $searchString) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceSearchGQL extends Apollo.Query<InvoiceSearchQuery, InvoiceSearchQueryVariables> {
    document = InvoiceSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupplierInvoiceSearchDocument = gql`
    query supplierInvoiceSearch($searchString: String) {
  company {
    supplierInvoiceSearch(search: $searchString) {
      edges {
        node {
          ...invoiceSelectFragment
        }
      }
    }
  }
}
    ${InvoiceSelectFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierInvoiceSearchGQL extends Apollo.Query<SupplierInvoiceSearchQuery, SupplierInvoiceSearchQueryVariables> {
    document = SupplierInvoiceSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }