import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyCostTypeService } from '../../../shared/company/index';
import { CompanyFunctionsService } from 'app/shared/company/companyFunctions.service';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';
import { AccountingPlanAccount } from 'app/settings/company/services/accounting-plan.types';
import { GlobalService } from '../../../shared/global/index';
import { HelperService } from 'app/shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'companyCostType-settings',
  templateUrl: 'company-cost-type-settings.component.html',
  styleUrls: ['./company-cost-type.scss'],
})
export class CompanyCostTypeSettingsComponent implements OnDestroy, OnInit {
  @Input() isModal = false;
  @Input() showTitle = 'titleRow';

  loading = true;
  dataModel = 'companyCostType';
  public dataSet = [];
  public dataSetInactive = [];
  public systemTypes = [];
  createDataObjectArray = [{ fakeId: 0 }];
  componentPrefix;
  sub: Subscription;
  public hasAccountingPlanFunction: boolean;
  public accountingPlanAccounts: AccountingPlanAccount[] = [];

  constructor(
    private companyFunctionsService: CompanyFunctionsService,
    private companyCostTypeService: CompanyCostTypeService,
    private accountingPlanService: AccountingPlanService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();

    this.companyFunctionsService
      .hasCompanyFunction('useAccountingPlan')
      .subscribe(isEnabled => (this.hasAccountingPlanFunction = isEnabled));

    this.accountingPlanService
      .getAccounts()
      .subscribe((accountingPlanAccounts: AccountingPlanAccount[]) => {
        this.accountingPlanAccounts = accountingPlanAccounts;

        this.apolloQueryService
          .apolloWatchQueryTwo(this.dataModel, null, 'cache-and-network')
          .subscribe(({ data, sub }) => {
            this.sub = sub;
            this.loading = false;
            const fullDataSet = this.companyCostTypeService.dataFormater(
              data,
              'list',
              true
            );
            this.dataSet = fullDataSet.filter(x => !x.isMaterial && x.active);
            this.dataSetInactive = fullDataSet.filter(x => !x.active);
            this.systemTypes = fullDataSet.filter(x => x.isMaterial);
          });
      });
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  spliceDeletedDataObject(createComponentObj) {
    const index = this.createDataObjectArray.indexOf(createComponentObj);
    this.createDataObjectArray.splice(index, 1);
  }

  addCreateComponent(createComponentObj) {
    if (
      this.createDataObjectArray.indexOf(createComponentObj) ===
      this.createDataObjectArray.length - 1
    ) {
      const latest =
        this.createDataObjectArray[this.createDataObjectArray.length - 1];
      const newFakeId = latest['fakeId'] + 1;
      const pushThis = { fakeId: newFakeId };

      this.createDataObjectArray.push(pushThis);
    }
  }
}
