import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SignlogQueryVariables = Types.Exact<{
  offerId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SignlogQuery = { __typename?: 'HyperionSchemaQuery', offer?: { __typename?: 'Offer', signingEvents?: { __typename?: 'SigningEventLogConnection', edges?: Array<{ __typename?: 'SigningEventLogEdge', node?: { __typename?: 'SigningEventLog', id: string, created?: string | null, type?: string | null, by?: string | null, email?: string | null, state?: string | null, document?: { __typename?: 'DocumentMeta', id: string, file?: string | null, url?: string | null } | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null } | null> | null } | null } | null };

export type SigningEventFragment = { __typename?: 'SigningEventLog', id: string, created?: string | null, type?: string | null, by?: string | null, email?: string | null, state?: string | null, document?: { __typename?: 'DocumentMeta', id: string, file?: string | null, url?: string | null } | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null };

export type UpdateMailMutationVariables = Types.Exact<{
  offerId?: Types.InputMaybe<Types.Scalars['Int']>;
  email?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateMailMutation = { __typename?: 'HyperionSchemaMutation', replaceAgreementParticipantMutation?: { __typename?: 'Offer', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export const SigningEventFragmentDoc = gql`
    fragment signingEvent on SigningEventLog {
  id
  created
  type
  by
  email
  state
  document {
    id
    file
    url
  }
  user {
    firstName
    lastName
  }
}
    `;
export const SignlogDocument = gql`
    query signlog($offerId: Int) {
  offer(id: $offerId) {
    signingEvents {
      edges {
        node {
          ...signingEvent
        }
      }
    }
  }
}
    ${SigningEventFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SignlogGQL extends Apollo.Query<SignlogQuery, SignlogQueryVariables> {
    document = SignlogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMailDocument = gql`
    mutation updateMail($offerId: Int, $email: String) {
  replaceAgreementParticipantMutation(
    offerId: $offerId
    newParticipantEmail: $email
  ) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMailGQL extends Apollo.Mutation<UpdateMailMutation, UpdateMailMutationVariables> {
    document = UpdateMailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }