<div class="p-grid">
  <div class="p-col-12 p-lg-6 p-grid">
    <p-card class="p-col-12 p-p-0">
      <ng-template pTemplate="header">
        <h4>A-conto</h4>
      </ng-template>
      <app-project-aconto [projectData]="projectData"></app-project-aconto>
    </p-card>

    <p-accordion
      *ngIf="(projectData | async).subProjectCount > 0"
      class="p-col-12 p-p-0 p-mt-2"
    >
      <p-accordionTab header="Underprojekt" appSaveExpansion="subprojectAconto">
        <app-project-aconto
          [projectData]="projectData"
          [subprojects]="true"
        ></app-project-aconto>
      </p-accordionTab>
    </p-accordion>
  </div>
  <p-card class="p-col-12 p-lg-6">
    <ng-template pTemplate="header">
      <h4>Kundens priser</h4>
    </ng-template>
    <app-project-customer-price
      [projectData]="projectData"
      (costsChanged)="changed.emit($event)"
    ></app-project-customer-price>
  </p-card>
</div>
