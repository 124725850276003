import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  FormControl,
  Validators,
} from '@angular/forms';
import { Subscription, BehaviorSubject, Observable, first, map } from 'rxjs';
import * as moment from 'moment';

import { HtmlModalService } from 'app/shared/html-modal';
import { FormHandlerService } from '../../../shared/forms/index';
import { CompanyAllProjectsService } from '../../../shared/company/project';
import { ApolloQueryService } from '../../../shared/apollo/index';
import { InvoiceStateService } from '../services/invoice-state.service';
import { CompanyInvoiceService } from '../services/company-invoice.service';
import { InvoiceType } from '../enums/invoice-type.enum';
import { InvoiceMode } from '../enums//invoice-mode.enum';
import { HelperService } from 'app/shared';
import { DateRange } from '../date-range';
import { atLeastOne } from 'app/shared/forms/at-least-one.directive';
import { UserLocalStorageService } from 'app/shared/user';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { ContactValidator } from 'app/shared/contacts/contact-validator';
import {
  FullContactFragment,
  GetContactGQL,
} from 'app/contact/contact-select/graphql/contact.generated';
import { OfferState } from 'app/offer';
import { SelectItemGroup } from 'primeng/api';
import { formatNumber } from '@angular/common';
import { FetchProjectInfoForInvoiceGQL } from './graphql/project.generated';

@Component({
  selector: 'app-struktur-invoice-head',
  templateUrl: 'invoice-head.component.html',
  styleUrls: ['./invoice-head.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InvoiceHeadComponent,
      multi: true,
    },
    FormHandlerService,
  ],
})
export class InvoiceHeadComponent implements OnInit, OnChanges, OnDestroy {
  @Output() projectChanged: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  @Output()
  lastSelectedProjectId: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  selectByDateRange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  @Input() public projectToOpen;
  @Input() public isDialog;
  @Input() public betalningsvillkor;
  @Input() public openAllAccordions;
  @Input() public selectedProjectFrmRow;
  @Input() public invoice;
  @Input() public customer;
  @Input() public typeInvoice;
  @Input() public type;
  @Input() public rotPersons: [];

  persons;
  invoiceHeading = '';
  formFields = {
    headInfo: FormGroup,
    headCustomer: FormGroup,
    headShippingAdress: FormGroup,
  };
  invoiceTimeForm: FormGroup;
  invoiceHeadForm: FormGroup;
  formStructure = {
    model: 'Invoice',
    attributes: {},
  };
  invoiceTypes = [
    { name: 'Vanlig faktura', id: InvoiceType.Normal },
    { name: 'Omvänd skattskyldighet', id: InvoiceType.InvertedVat },
    { name: 'ROT', id: InvoiceType.Rot },
    { name: 'RUT', id: InvoiceType.Rut },
    { name: 'Grön', id: InvoiceType.Green },
  ];
  selectedInvoiceType = [];
  invoiceModes = [
    { name: 'Löpande', id: InvoiceMode.Running },
    { name: 'Löpande - extra', id: InvoiceMode.RunningExtra },

    { name: 'Betalningsplan', id: InvoiceMode.Installement },
    { name: 'Offert', id: InvoiceMode.Offer },
    { name: 'Offererat belopp', id: InvoiceMode.OfferFromProject },
  ];

  setRowsFree = false;
  formValueFromService;
  selectedInvoiceMode = [];
  invoiceType = this.invoiceTypes[0];
  InvoiceType = InvoiceType;
  searchsubForm: Subscription;
  subForm: Subscription;
  projectsDropdown = [];
  projectsSub: Subscription;
  removeFrmList: Subscription;
  selectedProjects: any[] = [];
  gettingProject: boolean; // Have to be updated via an input or subscribtion to service
  autoComplete = {
    results: new BehaviorSubject([]),
    model: null,
    showSave: new BehaviorSubject(true),
    setFromStart: new BehaviorSubject(false),
  };
  contactSearchText: string;
  results: any[];
  searchingContact = false;
  customerForm;
  invoiceForm;
  rotPersonInfoForm;
  public isTaxReduction: boolean;
  public projectsAtasOptions: SelectItemGroup[];
  private getLoadedProjectsAtasSub: Subscription;
  private invoiceSubscription: Subscription;
  private gettingProjectSubscription: Subscription;
  public projectToOpenData: {
    id: string;
    trueId?: string;
    mark?: string;
  };
  public isLoadProjectButtonBlocked = true;

  constructor(
    private companyAllProjectsService: CompanyAllProjectsService,
    private fb: FormBuilder,
    private invoiceStateService: InvoiceStateService,
    private apolloQueryService: ApolloQueryService,
    public companyInvoiceService: CompanyInvoiceService,
    private formhandler: FormHandlerService,
    private helperService: HelperService,
    private userLocalStorageService: UserLocalStorageService,
    private globalInvoiceService: GlobalInvoiceService,
    private getContactGQL: GetContactGQL,
    private htmlModalService: HtmlModalService,
    private fetchProjectForInvoiceGQL: FetchProjectInfoForInvoiceGQL
  ) {
    this.initForm();
    this.companyInvoiceService.updateForm('headCalender', this.invoiceTimeForm);
  }

  ngOnInit() {
    this.createForm();
    this.getProjects();
    this.initFormSelectByDate();

    this.invoiceSubscription =
      this.invoiceStateService.parsedInvoiceDataToRow$.subscribe(event => {
        this.invoiceStateService.selectedProjectOrInvoices(
          this.selectedProjects
        );
      });
    this.gettingProjectSubscription =
      this.invoiceStateService.loadingInvoices$.subscribe(state => {
        this.gettingProject = state;
      });
    this.removeFrmList =
      this.invoiceStateService.projectToRemoveFromOpenProjectList$.subscribe(
        id => {
          const newarr = this.selectedProjects;
          this.selectedProjects = [];
          newarr.splice(newarr.indexOf(id), 1);
          this.selectedProjects = newarr;
          this.invoiceStateService.selectedProjectOrInvoices(
            this.selectedProjects
          );
        }
      );

    this.searchsubForm =
      this.companyInvoiceService.searchValueChange$.subscribe(value => {
        if (value['trueId'] !== undefined) {
          this.formValueFromService = this.setSearchBoxPlaceHolder(
            value['trueId'],
            value['orderBuisnessName'],
            value['name']
          );
        }
      });
    this.subForm = this.companyInvoiceService.invoiceFormsChange$.subscribe(
      forms => {
        this.customerForm = forms['customer'];
        this.invoiceForm = forms['invoice'];
        this.rotPersonInfoForm = forms['rotPersonInfo'];
      }
    );

    this.getLoadedProjectsAtasSub =
      this.invoiceStateService.getLoadedProjectsAtas.subscribe(atas => {
        this.projectsAtasOptions = [
          {
            label: 'Accepterad',
            value: OfferState.ACCEPTED,
            items: atas
              .filter(ata => Number(ata.state) === OfferState.ACCEPTED)
              .map(ata => ({
                label: this.generateAtaSelectLabel(ata),
                value: ata.id,
              })),
          },
          {
            label: 'Obesvarad',
            value: OfferState.UNANSWERED,
            items: atas
              .filter(ata => Number(ata.state) === OfferState.UNANSWERED)
              .map(ata => ({
                label: this.generateAtaSelectLabel(ata),
                value: ata.id,
              })),
          },
        ];
      });
  }

  private generateAtaSelectLabel(ata: any): string {
    const totalInclVat: number = [ata.sumWork, ata.sumMaterial].reduce(
      (a, b) => Number(a) + Number(b),
      0
    );

    return `${ata.projectTrueId} - ${ata.trueId} (${formatNumber(
      totalInclVat,
      'sv-SE'
    )}kr) ${ata.concerning ? ', ' + ata.concerning : ''}`;
  }

  private initForm(): void {
    this.formStructure['attributes']['fdatum'] = moment().format(
      this.helperService.dateFormat()
    );
    this.formStructure['attributes']['ffdatum'] = moment().format(
      this.helperService.dateFormat()
    );
    this.invoiceTimeForm = this.formhandler.groupedFormSimple(
      this.formStructure
    );
    this.formhandler
      .groupSetLabelsRules(this.formStructure, this.invoiceTimeForm)
      .then();
    this.onFormValChanges();
  }

  onFormValChanges() {
    this.invoiceTimeForm.get('ffdatum').valueChanges.subscribe(date => {
      this.invoiceForm.patchValue({ ffdatum: date });
    });

    this.invoiceTimeForm.get('fdatum').valueChanges.subscribe(date => {
      this.invoiceForm && this.invoiceForm.patchValue({ fdatum: date });

      if (typeof this.betalningsvillkor !== 'undefined' && date) {
        const ffdatum = this.updateDate(date).ffdatum;
        this.invoiceTimeForm.patchValue({ ffdatum });
        this.invoiceForm && this.invoiceForm.patchValue({ ffdatum });
      }
    });
  }

  private createForm() {
    this.invoiceHeadForm = this.fb.group(this.formStructure);
  }

  updateDate(invoiceDate): { ffdatum: string; fdatum: string } {
    return {
      fdatum: moment(invoiceDate).format(this.helperService.dateFormat()),
      ffdatum: moment()
        .add(this.betalningsvillkor, 'days')
        .format(this.helperService.dateFormat()),
    };
  }

  doSingleSelect(invoiceType: any) {
    this.invoiceType = invoiceType;
    this.selectedInvoiceType = [invoiceType.id];

    this.changeInvoiceType();
  }

  removeCoupling() {
    this.autoComplete.showSave.next(true);
    this.autoComplete.setFromStart.next(false);
    this.autoComplete.model = null;
  }

  public getContactById(id: number): Observable<FullContactFragment> {
    return this.getContactGQL
      .fetch({ id: id })
      .pipe(first())
      .pipe(map(res => res.data.company.contacts.edges[0]?.node));
  }

  public setContactFromId(id: number): void {
    this.getContactById(id)
      .pipe(first())
      .subscribe(contact => this.setContactFromAutosuggest(contact));
  }

  public setContactFromAutosuggest(value = {}): void {
    // eslint-disable-next-line eqeqeq
    if (this.type == 1) {
      this.invoiceForm.patchValue({
        your_reference: this.userLocalStorageService.getMEUser().fullName,
        our_reference: value['name'],
      });
    } else {
      this.invoiceForm.patchValue({
        our_reference: this.userLocalStorageService.getMEUser().fullName,
        your_reference: value['name'],
      });
    }

    const customer = Object.assign({}, value);
    this.customer = customer;

    if (!ContactValidator.isValidTrueId(customer['trueId'])) {
      customer['trueId'] = '';
    }

    this.autoComplete.model = this.autoComplete.model = null;
    this.autoComplete.showSave.next(true);
    this.autoComplete.setFromStart.next(false);
    this.betalningsvillkor = value['betalningsvillkor'];

    this.invoiceTimeForm.patchValue(
      this.updateDate(this.invoiceTimeForm.controls.fdatum.value)
    );
    this.invoiceForm.patchValue(
      this.updateDate(this.invoiceTimeForm.controls.fdatum.value)
    );

    this.customerForm.patchValue(customer); // Send from service to head-customer for patch

    this.formValueFromService = this.setSearchBoxPlaceHolder(
      customer['trueId'],
      customer['orderBuisnessName'],
      customer['name']
    );

    if (
      this.rotPersonInfoForm &&
      this.rotPersonInfoForm.controls &&
      this.rotPersonInfoForm.controls.persons &&
      this.rotPersonInfoForm.controls.persons.controls &&
      this.rotPersonInfoForm.controls.persons.controls[0]
    ) {
      this.rotPersonInfoForm.controls.persons.controls[0].patchValue({
        name: customer['name'],
        personalNumber: customer['orgNr'],
        nameOfProperty: customer['propertyName'],
        housingAssociationOrgNumber: customer['housingAssociationOrgNumber'],
        apartmentDesignation: customer['apartmentDesignation'],
      });
    }

    this.ChangetypeInvoice(InvoiceType.Normal);
    this.selectedInvoiceType = [];
    // eslint-disable-next-line eqeqeq
    if (value['reverseTax'] == 1) {
      this.ChangetypeInvoice(InvoiceType.InvertedVat);
    }
  }

  setSearchBoxPlaceHolder(trueId, bussinessName, Name) {
    let name = '';
    let id = '';

    if (typeof bussinessName === 'string' && bussinessName.length > 0) {
      name = bussinessName;
    } else {
      name = Name;
    }
    if (ContactValidator.isValidTrueId(trueId)) {
      id = trueId;
    }
    return `${this.returnStringOnly(id)} ${
      this.returnStringOnly(id) !== '' && this.returnStringOnly(name) !== ''
        ? '-'
        : ''
    } ${this.returnStringOnly(name)}`;
  }

  // TODO: investigate this further
  returnStringOnly(value) {
    if (typeof value === 'string') {
      return value;
    }
    return '';
  }

  mapSelectedInvoiceMode(selectedInvoiceMode) {
    return selectedInvoiceMode.length > 0
      ? selectedInvoiceMode.map(itm => itm.id)
      : [];
  }

  public checkboxChng(): void {
    const modes: number[] = this.mapSelectedInvoiceMode(
      this.selectedInvoiceMode
    );
    if (this.setRowsFree) {
      modes.push(InvoiceMode.Free);
    } else {
      const index = modes.findIndex(m => m === InvoiceMode.Free);
      if (index !== -1) {
        modes.splice(index, 1);
      }
    }
    this.invoiceStateService.changeInvoiceMode(modes);
  }

  public changeInvoiceMode(): void {
    if (this.selectedInvoiceMode.find(itm => itm.id === InvoiceMode.NoData)) {
      this.setRowsFree = false;
    }

    this.checkboxChng();
  }

  public onProjectChanged(projectId: number | string): void {
    this.projectToOpen = String(projectId);
    this.selectedProjects = [Number(projectId)];
    this.projectSelected();
    this.fetchProjectForInvoiceGQL
      .fetch({ projectId: Number(projectId) })
      .pipe(
        first(),
        map(res => res.data.project)
      )
      .subscribe(data => {
        this.projectToOpenData = data;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isDialog'] && changes['isDialog'].currentValue) {
      this.isDialog = changes['isDialog'].currentValue;
    }
    if (changes['typeInvoice'] && changes['typeInvoice'].currentValue) {
      this.ChangetypeInvoice(changes['typeInvoice'].currentValue);
    }

    if (changes['projectToOpen'] && changes['projectToOpen'].currentValue) {
      this.onProjectChanged(changes['projectToOpen'].currentValue);
    }

    if (
      changes['selectedProjectFrmRow'] &&
      changes['selectedProjectFrmRow'].currentValue
    ) {
      const newarr = this.selectedProjects;
      this.selectedProjects = [];
      newarr.push(+changes['selectedProjectFrmRow'].currentValue);
      this.selectedProjects = newarr;
    }

    setTimeout(() => {
      // Only used to place functions down the que for better experience
      if (changes['customer'] && changes['customer'].currentValue) {
        this.betalningsvillkor = this.customer['betalningsvillkor']; // Set customers payment date;
        this.invoiceTimeForm.patchValue(
          this.updateDate(this.invoiceTimeForm.controls.fdatum.value)
        );
        this.invoiceForm.patchValue(
          this.updateDate(this.invoiceTimeForm.value.fdatum)
        );
      }
    }, 0);

    setTimeout(() => {
      // Only used to place functions down the que for better experience
      if (
        changes['betalningsvillkor'] &&
        changes['betalningsvillkor'].currentValue &&
        typeof this.invoiceTimeForm !== 'undefined'
      ) {
        this.invoiceTimeForm.patchValue(
          this.updateDate(this.invoiceForm.value.fdatum)
        );
        this.invoiceForm.patchValue(
          this.updateDate(this.invoiceForm.value.fdatum)
        );
      }
    }, 2);

    if (this.invoice !== undefined && this.invoice.source.fakturaNr > 0) {
      // eslint-disable-next-line eqeqeq
      if (this.invoice.source.type == 0) {
        this.invoiceHeading = 'Kundfaktura ' + this.invoice.source.fakturaNr;
        // eslint-disable-next-line eqeqeq
      } else if (this.invoice.source.type == 1) {
        this.invoiceHeading =
          'Leverantörsfaktura ' + this.invoice.source.fakturaNr;
      }
    }
  }

  changeInvoiceType = (id = this.invoiceType.id) => {
    this.invoiceStateService.changeInvoiceType(id);
    this.isTaxReduction = this.globalInvoiceService.isTaxReduction(id);
  };

  projectSelected = () =>
    this.lastSelectedProjectId.emit(
      this.selectedProjects[this.selectedProjects.length - 1]
    );

  ChangetypeInvoice(id = this.typeInvoice) {
    this.selectedInvoiceType = [];
    let index;
    this.invoiceTypes.forEach((obj, i) => {
      // eslint-disable-next-line eqeqeq
      if (obj.id == id) {
        index = i;
      }
    });
    this.invoiceType = this.invoiceTypes[index];
    this.invoiceStateService.changeInvoiceType(id);
    this.isTaxReduction = this.globalInvoiceService.isTaxReduction(id);

    if (index > 0) {
      this.selectedInvoiceType = [index.toString()];
    }
  }

  public getInvoices(): void {
    this.setRowsFree = false; // Set setRowsFree to false so box is not checked

    if (this.selectedInvoiceMode.length === 0) {
      this.selectedInvoiceMode = [
        this.invoiceModes.find(itm => itm.id === InvoiceMode.Running),
        this.invoiceModes.find(itm => itm.id === InvoiceMode.RunningExtra),
      ];
    }

    this.changeInvoiceMode();
    this.invoiceStateService.clearOpenedProjects();
    this.invoiceStateService.loadingInvoicesState(true);
    this.projectChanged.emit(this.selectedProjects);
  }

  public clearOpenedProject(): void {
    this.selectedProjects = [];
    this.invoiceStateService.clearOpenedProjects();
    this.invoiceStateService.selectedProjectOrInvoices([]);
    this.lastSelectedProjectId.emit(null);
    this.invoiceHeadForm.reset();
  }

  // Form Control Code
  writeValue(val: any[]) {
    this.invoiceHeadForm.patchValue(val);
  }

  registerOnChange(fn) {
    this.invoiceHeadForm.valueChanges.subscribe(fn);
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.invoiceHeadForm.disable() : this.invoiceHeadForm.enable();
  }

  registerOnTouched() {}

  public onLoadSelectedProjectAtas(event: any): void {
    this.invoiceStateService.setSelectedProjectsAtasIds(event.value);
  }

  private getProjects() {
    this.projectsSub && this.projectsSub.unsubscribe();
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'projectsPlane',
        { status: [0, 1, 2, 6] },
        'cache-and-network'
      )
      .subscribe(data => {
        this.projectsSub = data.sub;
        this.setProjectsDropdown(data.data);
        this.isLoadProjectButtonBlocked = false;
      });
  }

  public selectByDateFG: FormGroup;
  private initFormSelectByDate() {
    this.selectByDateFG = new FormGroup(
      {
        from: new FormControl(),
        to: new FormControl(),
      },
      atLeastOne(Validators.required, ['from', 'to'])
    );
  }

  private _showPreSelectAssistant: boolean;
  public set showPreSelectAssistant(value) {
    this._showPreSelectAssistant = value;
  }
  public get showPreSelectAssistant() {
    return this._showPreSelectAssistant;
  }

  public get showPreSelectAssistantButton() {
    return (
      this.selectedProjects.length > 0 &&
      this.selectedInvoiceMode.some(invoiceMode =>
        [InvoiceMode.Running, InvoiceMode.RunningExtra].includes(invoiceMode.id)
      )
    );
  }

  public selectByDate() {
    const from =
      (this.selectByDateFG.get('from').value &&
        moment(this.selectByDateFG.get('from').value)) ||
      null;
    const to =
      (this.selectByDateFG.get('to').value &&
        moment(this.selectByDateFG.get('to').value)) ||
      null;

    this.selectByDateRange.emit({ from, to });

    // cleanup
    this.selectByDateFG.get('from').setValue(null);
    this.selectByDateFG.get('to').setValue(null);
    this.showPreSelectAssistant = false;
  }

  public showPdf(url: string): void {
    this.htmlModalService.ny_sida(url, 900, 800);
  }

  private setProjectsDropdown(projects: any[]) {
    const trueIds = this.companyAllProjectsService.makeLabelsArray(projects);
    this.projectsDropdown =
      this.companyAllProjectsService.makeLabelsArray(projects);
    this.projectsDropdown.splice(0, 1);
    this.invoiceStateService.getProjectsDropdown(this.projectsDropdown);
    this.invoiceStateService.invoiceTrueIdToBody(trueIds);
  }

  ngOnDestroy() {
    this.projectsSub && this.projectsSub.unsubscribe();
    this.invoiceSubscription.unsubscribe();
    this.gettingProjectSubscription.unsubscribe();
    this.getLoadedProjectsAtasSub.unsubscribe();
    if (this.removeFrmList) {
      this.removeFrmList.unsubscribe();
    }
  }
}
