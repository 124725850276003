<div class="p-grid">
  <div class="p-col-12">
    <div class="project-title-section">
      <div
        class="sub-title-row"
        style="display: flex; justify-content: space-between"
      >
        <h3>
          Betalningsplan
          <small class="italic"
            >Skapade delbetalningar läses enkelt in på aktuell faktura.</small
          >
        </h3>
        <app-button
          [class]="'pull-right'"
          (click)="openDialog()"
          [type]="'showCreate'"
        ></app-button>
      </div>
      <div style="clear: both"></div>
    </div>
  </div>
</div>

<spinner *ngIf="(loading | async) || (generalDataLoading | async)"></spinner>
<div *ngIf="!(loading | async) && !(generalDataLoading | async)">
  <app-emptyState
    *ngIf="dataSet.length === 0"
    [model]="'ProjectInstallment'"
    [whereToCreate]="'topRight'"
  ></app-emptyState>
  <div class="p-grid">
    <div
      class="p-col-12"
      *ngFor="
        let installment of dataSet;
        let i = index;
        trackBy: helperService.rowTrackBy
      "
    >
      <div>
        <project-installment-container
          [dataObjectFromParent]="installment"
          [optionallyInvoiceTaxDropdown]="optionallyInvoiceTaxDropdown"
          [invoiceRowTypeListDropdown]="invoiceRowTypeListDropdown"
          [labels]="labels"
          [projectInfo]="projectInfo"
        ></project-installment-container>
      </div>
    </div>
  </div>
</div>
