import { Component, Input, OnDestroy } from '@angular/core';
import { PageInfoService, PageInfoKey } from './page-info.service';

@Component({
  selector: 'app-title',
  template: '',
})
export class TitleComponent implements OnDestroy {
  private key: PageInfoKey;

  @Input() set title(title: string) {
    this.key = this.pageInfoService.setInfo(
      {
        title: {
          value: title,
        },
      },
      this.key
    );
  }

  constructor(private pageInfoService: PageInfoService) {}

  ngOnDestroy() {
    this.pageInfoService.clearInfo(this.key);
  }
}
