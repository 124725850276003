export enum InvoiceMode {
  NoData,
  Running,
  Installement,
  Free,
  Offer,
  OfferFromProject,
  ATA,
  RunningExtra,
}
