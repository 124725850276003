<app-feature-preview
  image="/images/hyperion/feature-previews/zigned.jpg"
  feature="Digital signering av offert"
  [hideReturnButton]="true"
>
  <p>
    Att digitalt signera en offert är ett effektivt sätt att göra
    affärsprocesser smidigare och säkrare. Genom att använda digitala signaturer
    blir offerten juridiskt bindande för både säljaren och köparen. Denna
    moderna metod för signering erbjuder flera fördelar, inklusive säkerhet,
    snabbhet och bekvämlighet.
  </p>
  <p>
    Digitala signaturer bekräftar identiteten hos de inblandade parterna. Detta
    skapar en tryggare affärsmiljö och minskar risken för bedrägeri eller
    tvistefrågor
  </p>
</app-feature-preview>
