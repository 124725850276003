<spinner *ngIf="loading | async"></spinner>
<div *ngIf="!(loading | async)" class="p-grid">
  <div class="p-col-12 p-g-nopad">
    <p-tabMenu
      *ngIf="!mode"
      [model]="menuItems"
      [activeItem]="menuItems[0]"
    ></p-tabMenu>

    <div
      class="p-grid"
      *ngIf="(!mode && meUser.type === '3') || meUser.type === '2'"
    >
      <div class="p-col-12" [class.p-lg-10]="meUser.type === '3'">
        <div
          class="short-message"
          [class.has-error-message]="projectInfo.shortMessage.isSet"
        >
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fa fa-flag"></i></span>
            <input
              type="text"
              [placeholder]="projectInfo.shortMessage.placeholder"
              [disabled]="meUser.type === '2'"
              [(ngModel)]="projectInfo.shortMessage.data"
              pInputText
            />
          </div>
        </div>
      </div>
      <div class="p-col-12 p-lg-2 align-right" *ngIf="meUser.type === '3'">
        <app-button
          [overrideButtonType]="'secondary'"
          (onClick)="setShortMessage()"
          [type]="'save'"
          [disabled]="shortMessageButton.disabled"
        ></app-button>
      </div>
    </div>

    <div [ngSwitch]="viewMode" class="project-content-container">
      <ng-template [ngSwitchCase]="'mainInfo'">
        <project-main
          *ngIf="projectData"
          [functionsThisModel]="functionsThisModel"
          [functionsData]="functionsData"
          [projectData]="projectData"
          [projectLabels]="projectLabels"
          [projectParams]="projectParams"
          [projectInfo]="projectInfo"
          [CompanyUserCostTypes]="CompanyUserCostTypes"
          [meUser]="meUser"
          (getAttendanceReport)="getAttendanceReportProjects()"
          (reloadProjectData)="reloadProjectData()"
          (reloadProjectDataLight)="reloadProjectDataLight()"
        ></project-main>
      </ng-template>
      <ng-template [ngSwitchCase]="'work'">
        <project-todo
          [projectInfo]="projectInfo"
          [projectData]="projectData"
          [meUser]="meUser"
        ></project-todo>
      </ng-template>
      <ng-template [ngSwitchCase]="'comment'">
        <project-comment [projectInfo]="projectInfo"></project-comment>
      </ng-template>
      <ng-template [ngSwitchCase]="'image'">
        <project-images
          [projectInfo]="projectInfo"
          [count]="pictureTotalCounts"
        ></project-images>
      </ng-template>
      <ng-template [ngSwitchCase]="'document'">
        <project-documents
          [projectInfo]="projectInfo"
          (dataUpdated)="getTotalCountsFromServer(projectInfo['id'])"
        ></project-documents>
      </ng-template>
      <ng-template [ngSwitchCase]="'shareProject'">
        <project-share [projectInfo]="projectInfo"></project-share>
      </ng-template>
      <ng-template [ngSwitchCase]="'rotProject'">
        <project-rot [projectInfo]="projectInfo"></project-rot>
      </ng-template>
      <ng-template [ngSwitchCase]="'installment'">
        <project-installment [projectInfo]="projectInfo"></project-installment>
      </ng-template>
      <ng-template [ngSwitchCase]="'lev'">
        <project-supplier-invoice
          [projectInfo]="projectInfo"
          [functionsData]="functionsData"
        ></project-supplier-invoice>
      </ng-template>
      <ng-template [ngSwitchCase]="'ata'">
        <projet-ata [projectInfo]="projectInfo"></projet-ata>
      </ng-template>
      <ng-template [ngSwitchCase]="'personlliggare'">
        <app-project-handle [projectInfo]="projectInfo"></app-project-handle>
      </ng-template>
    </div>
  </div>
</div>
