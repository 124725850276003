import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentService } from '../document.service';
import { map } from 'rxjs/operators';
import { DocumentChanges } from '../document-change';
import { FormGroup } from '@angular/forms';
import { FormHandlerService } from '../../shared/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-docu-save-dialog',
  templateUrl: './docu-save-dialog.component.html',
  styleUrls: ['./docu-save-dialog.component.scss'],
  providers: [FormHandlerService, DocumentService],
})
export class DocuSaveDialogComponent implements OnInit {
  projectIdDropDownParams: Observable<any>;
  form: Promise<FormGroup>;
  changes: DocumentChanges;

  constructor(
    private documentService: DocumentService,
    private formHandler: FormHandlerService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.changes = this.dialogConfig.data['changes'];
    this.dialogConfig.header = 'Spara';
  }

  ngOnInit() {
    console.log('DocuSaveDialogComponent');

    this.projectIdDropDownParams = this.documentService.getProjects().pipe(
      map(projects =>
        projects
          .map(project => ({
            label: `${project.showId}, ${project.mark}`,
            value: +project.id,
          }))
          .toArray()
      ),
      map(options => ({
        options,
        filter: true,
        fullEvent: false,
        rightIcon: null,
        allowEmpty: true,
      }))
    );

    const formStructure = {
      model: 'Document',
      nestedForm: false,
      attributes: {
        templateName: this.changes.templateName,
        projectId: this.changes.projectId,
      },
    };
    this.form = this.formHandler.groupSetLabelsRules(formStructure);
  }

  closeAction() {
    this.dialogRef.close();
  }

  save() {
    this.form.then(form => {
      this.dialogRef.close(
        this.changes.merge({
          templateName: form.value.templateName,
          projectId: form.value.projectId,
        })
      );
    });
  }
}
