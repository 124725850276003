import { formatCurrency } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { SingleOfferService } from '../single-offer.service';

@Component({
  selector: 'app-offer-summation',
  templateUrl: './offer-summation.component.html',
})
export class OfferSummationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dataSet = { rotPerson1: null, rotPerson2: null, rotSum: null };
  @Input() offerInfo: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  @Output() newRotData = new EventEmitter();
  public calculationRows = [];
  public todos = [];
  public todoSub: Subscription;
  public calculationRowSub: Subscription;
  public interval: ReturnType<typeof setInterval>;
  public maxRotPerPerson = 75000;
  public totalPersonRot = 0;

  public selectedOfferType: string[] = [];

  public typeOffer: string;
  public errors = {
    rotPerson: `ROT får ej överstiga ${formatCurrency(
      this.maxRotPerPerson,
      'sv-SE',
      'SEK',
      undefined,
      '3.0-0'
    )} per person och fastighet`,
    rotSum: {
      text: 'ROT får inte vara mer än hälften av arbetskostnaden',
      show: new BehaviorSubject(false),
    },
  };
  public summations = {
    work: new BehaviorSubject(0),
    material: new BehaviorSubject(0),
    totalExVat: new BehaviorSubject(0),
    totalInclVat: new BehaviorSubject(0),
    maxRot: new BehaviorSubject(0),
    sumToPay: new BehaviorSubject(0),
  };
  public showProductExternalConnectInterface = false;
  private webshopRowsSub: Subscription;
  private rotSubscription: Subscription;

  constructor(private singleOfferService: SingleOfferService) {}

  public ngOnInit(): void {
    // eslint-disable-next-line eqeqeq
    this.offerInfo.groupCosts = this.offerInfo.groupCosts == 1;
    this.selectedOfferType = [this.offerInfo['typeOffer'].toString()];
    this.doSingleSelect();

    this.interval = setInterval(() => {
      this.todoSub = this.singleOfferService.todos$.subscribe(todos => {
        this.todos = todos;
      });

      this.calculationRows = [];

      this.webshopRowsSub = this.singleOfferService.webshopRows$.subscribe(
        webshopRows => {
          this.calculationRows.push(...webshopRows);
        }
      );

      this.calculationRowSub =
        this.singleOfferService.calculationRows$.subscribe(calculationRows => {
          this.calculationRows.push(...calculationRows);
        });
      this.summarize();
    }, 1500);
    this.rotSubscription = this.newRotData.subscribe(() => {
      this.totalPersonRot = this.totalRot();
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.offerInfo) {
      this.selectedOfferType = [this.offerInfo.typeOffer.toString()];
    }
  }

  public ngOnDestroy(): void {
    this.todoSub?.unsubscribe();
    this.webshopRowsSub?.unsubscribe();
    this.calculationRowSub?.unsubscribe();

    this.rotSubscription?.unsubscribe();

    clearInterval(this.interval);
  }

  private formatNumberToRealFloat(val): number {
    if (!val) {
      return 0;
    } else {
      val = val.toString();
      val = val.replace(/ /gi, '');
      val = val.replace(',', '.');
      val = val.replace(' ', '');

      if (val % 1 === 0) {
        return parseInt(val, 10);
      } else {
        return parseFloat(val);
      }
    }
  }

  doSingleSelect() {
    const l = this.selectedOfferType.length;
    this.typeOffer = '1';
    if (l > 0) {
      this.typeOffer = this.selectedOfferType[l - 1];
    }
    this.selectedOfferType = [this.typeOffer];
    this.newRotDataEmit();
  }

  // TODO: refactor this to get data from database instead
  private summarize(): void {
    const calculationRows = this.calculationRows;
    const todos = this.todos;

    let totalInkVAT = 0;
    let totalExlVAT = 0;

    // CALCULATION
    let calcSumExklTax = 0;
    let calcSumInkTax = 0;
    let greenDeductableAmount = 0;

    for (const calcRow of calculationRows) {
      const calcRowQuantity = this.formatNumberToRealFloat(calcRow['quantity']); // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?
      const calcRowPrice = this.formatNumberToRealFloat(calcRow['cost']); // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?

      if (
        typeof (calcRowQuantity * 1) === 'number' &&
        typeof (calcRowPrice * 1) === 'number'
      ) {
        // Behöver egentligen inte denna
        const calcRowProcent = !isNaN(calcRow['procent'])
          ? +calcRow['procent']
          : 0; // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?
        const calcRowTax = !isNaN(calcRow['tax']) ? +calcRow['tax'] : 25; // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?

        const calcRowTaxMultiplier =
          +this.typeOffer !== 2 ? calcRowTax * 0.01 + 1 * 1 : 1;

        let calcRowProcentMultiplier = 1;

        if (!isNaN(calcRowProcent)) {
          calcRowProcentMultiplier = calcRowProcent * 0.01 + 1 * 1;
        }

        const calcRowExlTax =
          calcRowQuantity * calcRowPrice * calcRowProcentMultiplier;

        calcSumInkTax += calcRowExlTax * calcRowTaxMultiplier;
        calcSumExklTax += calcRowExlTax;

        const calcRowIsGreen = !!calcRow.greenTaxReduction;

        if (calcRowIsGreen) {
          switch (calcRow.greenTaxReduction) {
            case 'Solceller':
              greenDeductableAmount +=
                calcRowExlTax * calcRowTaxMultiplier * 0.2;
              break;
            case 'LagringEgenproduceradEl':
              greenDeductableAmount +=
                calcRowExlTax * calcRowTaxMultiplier * 0.5;
              break;
            case 'LaddningspunktElfordon':
              greenDeductableAmount +=
                calcRowExlTax * calcRowTaxMultiplier * 0.5;
              break;
          }
        }
      }
    }

    totalInkVAT += calcSumInkTax;
    totalExlVAT += calcSumExklTax;

    // TODOS
    let todosSumExklTax = 0;
    let todosSumInkTax = 0;
    let todosSumOnlyROT = 0;
    for (const index in todos) {
      const todo = todos[index];

      const todoRowQuantity = this.formatNumberToRealFloat(todo['quantity']); // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?
      const todoRowUnitPrice = this.formatNumberToRealFloat(todo['unitPrice']); // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?

      const todoCost = todoRowQuantity * todoRowUnitPrice; // OBS SKA VI SÄKERSTÄLLA ATT DENNA ÄR FLOAT?
      if (typeof (todoCost * 1) === 'number') {
        // Behöver egentligen inte denna

        const todoProcent = 0; // Hårdkodad för framtidabruk
        const todoTaxMultiplier =
          +this.typeOffer !== 2 ? todo['tax'] * 0.01 + 1 * 1 : 1;
        const todoIsRot = todo['rot'] > 0;
        const todoIsGreen = !!todo.greenTaxReduction;

        todosSumExklTax += todoCost;
        todosSumInkTax += todoCost * todoTaxMultiplier;
        if (todoIsRot) {
          todosSumOnlyROT += todoCost;
        }

        if (todoIsGreen) {
          switch (todo.greenTaxReduction) {
            case 'Solceller':
              greenDeductableAmount += todoCost * todoTaxMultiplier * 0.2;
              break;
            case 'LagringEgenproduceradEl':
              greenDeductableAmount += todoCost * todoTaxMultiplier * 0.5;
              break;
            case 'LaddningspunktElfordon':
              greenDeductableAmount += todoCost * todoTaxMultiplier * 0.5;
              break;
          }
        }
      }
    }

    totalInkVAT += todosSumInkTax;
    totalExlVAT += todosSumExklTax;

    // beräkna rot
    const rotSum = !isNaN(this.dataSet['rotSum'])
      ? +this.dataSet['rotSum'] > -1
        ? +this.dataSet['rotSum']
        : 0
      : 0;

    const rotYearDivider = +this.dataSet['rotYear'] === 2015 ? 2 : 3.33333333;

    let maxRotByRows;

    const isGreenTaxReduction = this.typeOffer == '5';

    const deductableRotAmount = isGreenTaxReduction
      ? greenDeductableAmount
      : (todosSumOnlyROT * 1.25) / rotYearDivider;

    if (Math.floor(deductableRotAmount) <= this.maxRotPerPerson * 2) {
      maxRotByRows = Math.floor(deductableRotAmount);
    } else {
      maxRotByRows = this.maxRotPerPerson * 2;
    }

    this.summations.maxRot.next(maxRotByRows);

    if (rotSum <= deductableRotAmount) {
      this.errors.rotSum.show.next(false);
    } else {
      this.errors.rotSum.show.next(true);
    }

    // summera och skriv ut
    this.summations.work.next(todosSumExklTax);
    this.summations.material.next(calcSumExklTax);
    this.summations.totalExVat.next(totalExlVAT);
    this.summations.totalInclVat.next(totalInkVAT);

    const sumToPay = todosSumInkTax - rotSum + calcSumInkTax;
    this.summations.sumToPay.next(sumToPay);
  }

  totalRot() {
    const rotPerson1: number = !isNaN(this.dataSet['rotPerson1'])
      ? +this.dataSet['rotPerson1']
      : 0;
    const rotPerson2: number = !isNaN(this.dataSet['rotPerson2'])
      ? +this.dataSet['rotPerson2']
      : 0;

    const rotTotal: number = rotPerson1 + rotPerson2;

    return rotTotal;
  }

  newRotDataEmit() {
    if (+this.typeOffer !== 3 && +this.typeOffer !== 5) {
      this.dataSet['rotPerson1'] = 0;
      this.dataSet['rotPerson2'] = 0;
      this.dataSet['rotSum'] = 0;
    }
    const eventObj = {
      rotPerson1: this.dataSet['rotPerson1'],
      rotPerson2: this.dataSet['rotPerson2'],
      rotSum: this.dataSet['rotSum'],
      typeOffer: this.typeOffer,
      groupCosts: this.offerInfo.groupCosts ? 1 : 0,
    };

    this.newRotData.emit(eventObj);
  }
}
