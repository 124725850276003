import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GlobalService } from '../../../shared/global/index';
import { MenuItem } from 'primeng/api';
import { first } from 'rxjs';
import { range } from 'lodash';

type DateRange = { periodFrom: string; periodTo: string };

@Component({
  selector: 'app-overview',
  template: `
    <div>
      <style>
        .startGraphFooter:hover {
          opacity: 1;
        }
        .startGraphFooter {
          opacity: 0.3;
        }
      </style>
      <spinner *ngIf="loading" [inComponent]="true"></spinner>
      <div *ngIf="!loading">
        <div *ngIf="activeTabIndex === 'invoices'">
          <div [ng2-highcharts]="charts.invoiceChart"></div>
        </div>
        <div *ngIf="activeTabIndex === 'workedHourMile'">
          <div [ng2-highcharts]="charts.hoursMileChart"></div>
        </div>
        <p-tabMenu
          [model]="menuItems"
          [activeItem]="menuItems[0]"
          [styleClass]="'home-start-menu'"
        >
        </p-tabMenu>
        <p-dropdown
          placeholder="Välj period"
          [options]="dateRangeOptions"
          (onChange)="getChartData($event.value)"
        ></p-dropdown>
      </div>
    </div>
  `,
})
export class OverviewComponent implements OnInit {
  dateRange: DateRange = { periodFrom: '', periodTo: '' };
  public dateRangeOptions: { label: string; value: DateRange }[];

  locale;

  titles = {
    invoiceChart: 'Fakturerat',
    costsChart: 'Kostnader',
    hoursMileChart: 'Arbetade timmar/mil',
    resultChart: 'Resultatlinjediagram',
  };

  charts = {
    invoiceChart: {
      chart: {
        type: 'line',
      },
      title: {
        text: this.titles.invoiceChart,
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: 'Kronor',
        },
      },
      series: [],
    },

    costsChart: {
      chart: {
        type: 'line',
      },
      title: {
        text: this.titles.invoiceChart,
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: 'Kronor',
        },
      },
      series: [],
    },

    hoursMileChart: {
      chart: {
        type: 'line',
      },
      title: {
        text: this.titles.invoiceChart,
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: 'Timmar/mil',
        },
      },
      series: [],
    },

    resultChart: {
      chart: {
        type: 'line',
      },
      title: {
        text: this.titles.invoiceChart,
      },
      xAxis: {
        categories: [],
      },
      yAxis: {
        title: {
          text: 'Kronor',
        },
      },
      series: [],
    },
  };

  loading = true;
  public activeTabIndex: string;
  public menuItems: MenuItem[];

  constructor(private http: HttpClient, private globalService: GlobalService) {}

  ngOnInit() {
    this.createDateRangeOptions();
    this.locale = this.globalService.getLocale('sv');
    this.getChartData(this.dateRange);
    this.setMenuItems();
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        tabindex: 'invoices',
        label: 'Fakturerat',
        command: this.changeActiveTabIndex,
      },
      {
        tabindex: 'workedHourMile',
        label: 'Arbetade timmar/mil',
        command: this.changeActiveTabIndex,
      },
    ];
    this.activeTabIndex = this.menuItems[0].tabindex;
  }

  public changeActiveTabIndex = event => {
    this.activeTabIndex = event.item.tabindex;
  };

  public getChartData(dateRange?: DateRange) {
    let url = this.globalService.getUrlPrefix() + '/data/overview';

    if (dateRange) {
      url += '?from=' + dateRange.periodFrom + '&to=' + dateRange.periodTo;
    }

    this.http
      .get(url)
      .pipe(first())
      .subscribe(data => {
        this.constructCharts(data);
        const sumInvoices = [...data['invoiceSumPaydData']];
        for (const k in sumInvoices) {
          sumInvoices[k] = sumInvoices[k] + data['invoiceSumUnpaydData'][k];
        }
        this.charts.invoiceChart.series = [
          {
            name: 'Betalda fakturor',
            data: data['invoiceSumPaydData'],
            color: '#1c9817',
          },
          {
            name: 'Obetalda fakturor',
            data: data['invoiceSumUnpaydData'],
            color: '#c70606',
          },
          {
            name: 'Fakturerat',
            data: sumInvoices,
            color: '#084ca8',
          },
        ];

        this.charts.costsChart.series = [
          {
            name: 'Produkter',
            data: data['productData'],
          },
          {
            name: 'Löner',
            data: data['payslipData'],
          },
          {
            name: 'Övriga kostnader',
            data: data['verificationSumData'],
          },
          {
            name: 'Leverantörsfakturor',
            data: data['supplierInvoiceSumData'],
          },
        ];

        this.charts.hoursMileChart.series = [
          {
            name: 'Timmar',
            data: data['daysSumData'],
          },
          {
            name: 'Mil',
            data: data['milesSumData'],
          },
        ];

        this.charts.resultChart.series = [
          {
            name: 'Resultat',
            data: data['sumData'],
          },
        ];

        this.loading = false;
      });
  }

  constructCharts(dataParam) {
    this.applyCategoriesToCharts(dataParam);
    this.applyDaterangeToCharts(dataParam);
  }
  applyCategoriesToCharts(data) {
    const categories = data['categories'];

    for (const key in this.charts) {
      const chart = this.charts[key];
      chart.xAxis.categories = categories;
    }
  }

  applyDaterangeToCharts(data: DateRange): void {
    this.dateRange.periodFrom = data.periodFrom;
    this.dateRange.periodTo = data.periodTo;

    const dateRangeString = ' ' + data.periodFrom + ' - ' + data.periodTo;

    for (const key in this.charts) {
      const chart = this.charts[key];

      chart.title.text = this.titles[key] + dateRangeString;
    }
  }

  private createDateRangeOptions() {
    const thisYear = new Date().getFullYear();
    const options = range(0, 5).map(i => ({
      label: String(thisYear - i),
      value: {
        periodFrom: `${thisYear - i}-01-01`,
        periodTo: `${thisYear - i}-12-31`,
      },
    }));
    this.dateRangeOptions = options;
  }
}
