<app-feature-preview
  image="/images/hyperion/feature-previews/inkop.jpg"
  feature="Inköp"
>
  <p>
    I {{ appName }} kopplas dina leverantörsfakturor enkelt till rätt projekt så
    att du får kontroll på dina inköp, som du dessutom enkelt kan redovisa till
    dina kunder. Innan fakturering kan du på ett enkelt sätt lägga på påslag på
    inköpen. Vi har flera olika tidseffektiva lösningar på att få in dina
    leverantörsfakturor i {{ appName }}.
  </p>
</app-feature-preview>
