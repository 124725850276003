<app-feature-preview
  image="/images/hyperion/feature-previews/planering.jpg"
  feature="Planering"
>
  <p>Vi har nu lanserat vår nya kalender!</p>

  <p>
    Två kalendrar i ett som ger dig möjligheten att se och skapa tidsbokningar
    samt planera projekten för ditt företag.
  </p>
</app-feature-preview>
