import {
  Component,
  forwardRef,
  OnInit,
  Injector,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';
import { GlobalService } from '../../../global/index';

@Component({
  selector: 'appForm2-simple-calendar',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFormCalendar2Component),
      multi: true,
    },
  ],
  template: `
    <div [class]="styleClass">
      <label
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        [innerHTML]="htmlAttributes['label']['value'] || control.label"
      ></label>
      <p-calendar
        [appendTo]="appendTo"
        hourFormat="24"
        class="calendar"
        [locale]="locale"
        dataType="string"
        [(ngModel)]="value"
        dateFormat="yy-mm-dd"
        [showTime]="showTime"
        [timeOnly]="timeOnly"
        [showIcon]="true"
        [yearNavigator]="yearNavigator"
        [yearRange]="yearRange"
        [monthNavigator]="true"
        [inline]="inline"
        [selectionMode]="selectionMode"
        [style]="{ width: '100%' }"
        inputStyleClass="form-control"
        (ngModelChange)="pushChanges($event)"
        (onBlur)="blurEvent()"
        (onInput)="pushChanges(value)"
        (onSelect)="pushChanges(value)"
        [showWeek]="true"
        [attr.id]="htmlAttributes['input']['id']"
        [attr.tabindex]="htmlAttributes['input']['tabIndex']"
        [attr.form-control-name]="control['name']"
        [inputStyle]="
          control['showErrors']
            ? { border: 'solid 1px #f25858', width: 'calc(100% - 2.143em)' }
            : { width: 'calc(100% - 2.143em)' }
        "
      ></p-calendar>
      <div *ngIf="control.showErrors" class="vendium-tool-tip">
        <p
          *ngFor="let error of control.mergedErrors"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class SimpleFormCalendar2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  locale;
  value: any;
  disabled: boolean;
  ngControl: NgControl;
  onTouched: () => void;
  onChange: (_: any) => void;

  constructor(private inj: Injector, private globalService: GlobalService) {
    super();
    this.locale = this.globalService.getLocale('sv');
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value;
  }
  pushChanges(value: any): void {
    this.onChange(value);
  }

  blurEvent(): void {
    this.onBlur.emit(true);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
