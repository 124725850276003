import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { AttendanceReportService } from 'app/attendance-report/services/attendance-report.service';
import { SubcontractorDragComponent } from 'app/attendance-report/handle/subcontractor-drag/subcontractor-drag.component';

@Component({
  selector: 'contractor-drag-and-drop',
  styleUrls: ['./contractor-drag-and-drop.component.scss'],
  templateUrl: './contractor-drag-and-drop.component.html',
})
export class ContractorDragAndDropComponent implements OnChanges, OnDestroy {
  @Input() parentId = 0;
  @Input() projectId = null;
  @Input() mainCompanyData = {};
  @Input() subcontractorsData = [];
  @Output() subcontractorDeleted = new EventEmitter();
  @Output() refetchAttendanceReports = new EventEmitter();
  @ViewChildren('subcontractorDrag')
  private dragComponents: QueryList<SubcontractorDragComponent>;

  constructor(
    private attendanceReportService: AttendanceReportService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    changes['subcontractorsData'] &&
      this.setSubcontractorsAttendanceReports(this.subcontractorsData);
  }

  ngOnDestroy() {
    this.cdr.detach();
  }

  setSubcontractorsAttendanceReports(subcontractorsDataArray) {
    const subcontractors = [...subcontractorsDataArray];

    for (const index in subcontractors) {
      const subcontractor: any = { ...subcontractors[index] };
      const subcontractorData = { ...subcontractor.subcontractor };
      subcontractors[index].subcontractor = subcontractorData;
      if (subcontractor.hasOwnProperty('children')) {
        const children = [...subcontractor.children];
        this.setSubcontractorsAttendanceReports(children);
        subcontractor.children = children;
      }
      subcontractors.splice(Number(index), 1, subcontractor);
    }

    subcontractorsDataArray = subcontractors;
    this.setChildrenInSubcontractorDrag();
    this.cdr.detectChanges();
  }

  setChildrenInSubcontractorDrag() {
    if (this.dragComponents) {
      this.dragComponents.forEach((component, index) => {
        if (this.subcontractorsData[index].hasOwnProperty('children')) {
          component.setChildrenFromParent(
            this.subcontractorsData[index].children
          );
        }
      });
    }
  }

  childrenChanged(subcontractor) {
    let index;
    const subcontractorsData = [...this.subcontractorsData];
    for (const i in subcontractorsData) {
      const subcontractorInArray = this.subcontractorsData[i];
      if (Number(subcontractorInArray['id']) === Number(subcontractor['id'])) {
        index = i;
        break;
      }
    }

    if (index) {
      this.subcontractorsData[index] = subcontractor;
    } else {
      this.subcontractorsData.push(subcontractor);
    }

    this.subcontractorsData = subcontractorsData;
  }
}
