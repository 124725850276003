import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MessageService } from 'app/shared/message/index';
import { GlobalService } from 'app/shared/global/index';

@Component({
  selector: 'upload-project-images',
  templateUrl: 'upload-project-images.component.html',
})
export class UploadProjectImagesComponent implements OnInit {
  public urlPrefix;
  private jsonData = [];
  public projectInfo;

  @Input() public projectId: number;
  @Output() public imagesAddedToParent = new EventEmitter();

  constructor(
    protected globalService: GlobalService,
    protected messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  public onUpload(event): void {
    this.jsonData = [];

    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    let type = 'error';

    if (dataAsJSON['successfullyUploaded'].length > 0) {
      this.jsonData = dataAsJSON['successfullyUploaded'];
      type = 'success';
    }

    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: type,
    });

    if (type === 'success') {
      this.imagesAddedToParent.emit(this.jsonData);
    }
  }
}
