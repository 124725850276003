<div>
  <form
    class="p-grid"
    *ngIf="componentMainForm"
    [formGroup]="componentMainForm"
  >
    <appForm2-list-input
      class="p-col-12 p-lg-10"
      formControlName="name"
      (click)="createSibling.emit()"
      [htmlAttributes]="{
        input: { id: componentPrefix + 'companyProjectType_name' }
      }"
    ></appForm2-list-input>
    <div class="p-col-6 p-lg-2">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.create.disabled"
        (click)="actionCreate()"
        icon="pi pi-plus"
        label="Lägg till"
      ></button>
    </div>
  </form>
</div>
