<div class="p-col-12">
  <h3>
    Projekt från samarbetspartners

    <span
      class="help-tooltip bigger"
      tooltipPosition="right"
      pTooltip="Om en samarbetspartnerdelar ett projekt till er är det ni som bestämmer om ni vill kopiera in det till era egna projekt i systemet. Ni bestämmer även vilken data ni ska dela med er samarbetspartner.<br>
           •	När ni väljer att kopiera projektet skapas ett projekt med grundläggande uppgifter från er samarbetspartners projekt (adressuppgifter samt referens).<br>
           •	Om ni accepterar kan er samarbetspartner skicka arbetsmoment till er.<br>
           •	Om ni accepterar visas fakturerbara timmar från tidrapporter i projektet för er samarbetspartner.<br>
           •	Ni kan alltid ändra delningsinställnignar under 'Delning projekt' i projektmenyn<br>"
      [escape]="false"
      >?</span
    >
  </h3>
  <div class="p-grid">
    <p-table [rowHover]="true" [value]="subcontractorsRelations">
      <ng-template pTemplate="header">
        <tr>
          <th>Företag</th>
          <th>Dela tidrapporter</th>
          <th>Dela utan attestering</th>
          <th>Ta emot arbetsmoment</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            {{ rowData['mainContractorName'] }}
          </td>
          <td>
            <p-checkbox
              (onChange)="updateSubcontractorRelation(rowData)"
              [(ngModel)]="rowData['clientSharesTimereports']"
              [binary]="true"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="updateSubcontractorRelation(rowData)"
              [(ngModel)]="rowData['clientSharesTimereportsWithoutNotarized']"
              [binary]="true"
            ></p-checkbox>
          </td>
          <td>
            <p-checkbox
              (onChange)="updateSubcontractorRelation(rowData)"
              [(ngModel)]="rowData['clientAcceptTodos']"
              [binary]="true"
            ></p-checkbox>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-grid" *ngIf="parentInfo === 'fromModal'">
    <app-button (click)="closeAction()" [type]="'save'"></app-button>
  </div>
</div>
