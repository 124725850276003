<app-dropdown-select
  [disabled]="disabled"
  [groups]="groups"
  [selectedItem]="selectedItem"
  (selectedItemChangedEvent)="onSelection($event)"
  (searchEvent)="onSearch($event)"
  [entityLabel]="isSupplierInvoice ? 'inköp' : 'kundfaktura'"
  [loading]="loading"
  (isEmptyEvent)="prePopulate()"
>
</app-dropdown-select>
