<app-header>
  <p-button
    type="button"
    icon="pi pi-download"
    label="Exportera"
    [style]="{ 'margin-right': '10px' }"
    (onClick)="exportToPDF()"
  ></p-button>
  <p-button
    type="button"
    icon="pi pi-plus"
    label="Skapa planerad tid"
    [disabled]="isLoading"
    [styleClass]="'p-button-primary-hyperion'"
    (onClick)="initNewPlannedTime()"
  >
  </p-button>
</app-header>

<div class="page-container">
  <form [formGroup]="filterForm">
    <div class="resource-header-row">
      <div class="filter-grid">
        <label>Från</label>
        <p-calendar
          dateFormat="yy-mm-dd"
          showWeek="true"
          formControlName="startDate"
          [locale]="datePickerLocale"
          [showIcon]="true"
          [showWeek]="true"
        ></p-calendar>
        <label>Till</label>
        <p-calendar
          dateFormat="yy-mm-dd"
          showWeek="true"
          formControlName="endDate"
          [locale]="datePickerLocale"
          [showIcon]="true"
          [showWeek]="true"
        ></p-calendar>
        <label>Projekt</label>
        <p-multiSelect
          #filterProjects
          [options]="[]"
          optionLabel="label"
          defaultLabel="Välj projekt.."
          formControlName="projects"
          selectedItemsLabel="{0} valda"
          maxSelectedLabels="1"
          appendTo="body"
          [overlayOptions]="{ appendTo: 'body' }"
        ></p-multiSelect>
        <label>Medarbetare</label>
        <p-multiSelect
          #filterCoworkers
          [options]="[]"
          optionLabel="label"
          defaultLabel="Välj medarbetare.."
          formControlName="coworkers"
          selectedItemsLabel="{0} valda"
          maxSelectedLabels="1"
          appendTo="body"
          [overlayOptions]="{ appendTo: 'body' }"
        ></p-multiSelect>
        <label>Vy</label>
        <p-dropdown
          [options]="zoomLevelDropdown"
          formControlName="selectedZoomLevel"
        ></p-dropdown>
      </div>
    </div>
  </form>
  <div #resource class="dhx-resource-container"></div>
  <div #customLightBox class="custom-lightbox">
    <app-edit-planned-time-view
      *ngIf="
        resourceEventPlannedTime &&
        resourceEventPlannedTime.eventType === EventTypeEnum.PlannedTime
      "
      [viewContext]="PlannerViewContext.Resource"
      [scheduledEvent]="resourceEventPlannedTime"
      [isNewEvent]="isNewEvent"
      [allTodos]="allTodos"
      [projects]="projectsData"
      [coworkersDropdown]="coworkersDropdown"
      (closeLightBox)="handleCloseEvent()"
      (saveEvent)="handleAfterSaveEvent($event)"
      (updateEvent)="handleUpdatePlannedTimeEvent($event)"
      (deleteEvent)="handleAfterDeleteEvent($event)"
    ></app-edit-planned-time-view>
  </div>
</div>
