import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { MessageService } from 'app/shared/message';
import { BehaviorSubject, first } from 'rxjs';
import {
  CreateCustomerRelationGQL,
  CreateCustomerRelationMutationVariables,
  CreatePrivateCustomerGQL,
  CreatePrivateCustomerMutationVariables,
  CustomerRelationFragment,
  DeleteCustomerRelationGQL,
  GetCustomerGQL,
  GetSharedToCustomersGQL,
  UpdateCustomerRelationGQL,
  UpdateCustomerRelationMutationVariables,
} from './graphql/share.generated';

@Component({
  selector: 'app-project-share',
  templateUrl: './project-share.component.html',
  styleUrls: ['./project-share.component.scss'],
})
export class ProjectShareComponent implements OnInit {
  public createPrivateCustomerForm: FormGroup = new FormGroup({
    firstName: new FormControl(' '),
    lastName: new FormControl(' '),
    email: new FormControl(null, [Validators.required, Validators.email]),
    mobilenumber: new FormControl(),
    telephonenumber: new FormControl(),
  });

  public createCustomerRelationForm: FormGroup = new FormGroup({
    offer: new FormControl(false),
    timereport: new FormControl(false),
    product: new FormControl(false),
    invoice: new FormControl(false),
    picture: new FormControl(false),
    document: new FormControl(false),
    todo: new FormControl(false),
    comment: new FormControl(false),
    invoiceNotification: new FormControl(false),
    documentNotification: new FormControl(false),
  });

  public customerRelationForms: FormGroup[];

  public sharedToCustomers: BehaviorSubject<CustomerRelationFragment[]> =
    new BehaviorSubject([]);
  public projectId: number;
  public showCreateSubcontractor = false;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private getCustomerRelationsService: GetSharedToCustomersGQL,
    private createCustomerRelationService: CreateCustomerRelationGQL,
    private createPrivateCustomerService: CreatePrivateCustomerGQL,
    private getCustomerService: GetCustomerGQL,
    private deleteCustomerRelationService: DeleteCustomerRelationGQL,
    private updateCustomerRelationService: UpdateCustomerRelationGQL,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.getCustomerRelations();
  }

  private getCustomerRelations(): void {
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;
      this.getCustomerRelationsService
        .fetch({ projectId: this.projectId })
        .pipe(first())
        .subscribe(res => {
          this.projectCountService.count(this.projectId);
          const sharedToCustomers =
            res.data.project.costumerRelationsToProject.edges.map(e => e.node);

          this.customerRelationForms = sharedToCustomers.map(
            this.initCustomerRelationForm
          );

          this.sharedToCustomers.next(sharedToCustomers);
        });
    });
  }

  private initCustomerRelationForm(
    customerRelation: CustomerRelationFragment
  ): FormGroup {
    return new FormGroup({
      id: new FormControl(customerRelation.id),
      offer: new FormControl(Boolean(customerRelation.offer)),
      timereport: new FormControl(Boolean(customerRelation.timereport)),
      product: new FormControl(Boolean(customerRelation.product)),
      invoice: new FormControl(Boolean(customerRelation.invoice)),
      picture: new FormControl(Boolean(customerRelation.picture)),
      document: new FormControl(Boolean(customerRelation.document)),
      todo: new FormControl(Boolean(customerRelation.todo)),
      comment: new FormControl(Boolean(customerRelation.comment)),
      invoiceNotification: new FormControl(
        Boolean(customerRelation.invoiceNotification)
      ),
      documentNotification: new FormControl(
        Boolean(customerRelation.documentNotification)
      ),
    });
  }

  public openCustomerView(customerId: string): void {
    window.open(
      '/privateCostumer/view?adminRequestCostumerId=' +
        customerId +
        '&adminRequestProjectId=' +
        this.projectId
    );
  }

  public createPrivateCustomer(): void {
    this.createPrivateCustomerForm.markAllAsTouched();
    if (!this.createPrivateCustomerForm.valid) {
      return;
    }
    const privateCostumer: CreatePrivateCustomerMutationVariables = {
      input: {
        ...this.createPrivateCustomerForm.value,
      },
    };

    this.createPrivateCustomerService
      .mutate(privateCostumer)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.privateCostumerTypeHyperionMutation
        );

        const success =
          res.data.privateCostumerTypeHyperionMutation.mutationDetails.every(
            md => md.mutationSucceeded
          );
        const id = Number(res.data.privateCostumerTypeHyperionMutation.id);
        if (success) {
          this.createCustomerRelation(id);
        }
      });
  }

  private createCustomerRelation(customerId: number): void {
    this.createCustomerRelationForm.markAllAsTouched();
    if (!this.createCustomerRelationForm.valid) {
      return;
    }

    const customerRelation: CreateCustomerRelationMutationVariables = {
      input: {
        project_id: this.projectId,
        costumer_id: customerId,
        ...this.createCustomerRelationForm.value,
      },
    };

    this.createCustomerRelationService
      .mutate(customerRelation)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.costumerRelationToProjectTypeHyperionMutation
        );
        const success =
          res.data.costumerRelationToProjectTypeHyperionMutation.mutationDetails.every(
            md => md.mutationSucceeded
          );

        if (success) {
          this.getCustomerRelations();
        }
      });
  }

  public populateForm(customerId: number): void {
    this.getCustomerService
      .fetch({ id: customerId })
      .pipe(first())
      .subscribe(res => {
        const customer = res.data.company.contacts.edges.map(e => e.node)[0];
        if (customer === null) {
          return;
        }
        this.createPrivateCustomerForm.setValue({
          firstName: customer.name,
          lastName: '',
          email: customer.mail,
          telephonenumber: customer.phone,
          mobilenumber: customer.mobilePhone,
        });
      });
  }

  public deleteCustomerRelation(id: string): void {
    this.deleteCustomerRelationService
      .mutate({ id: Number(id) })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.costumerRelationToProjectTypeHyperionMutation
        );
        const success =
          res.data.costumerRelationToProjectTypeHyperionMutation.mutationDetails.every(
            md => md.mutationSucceeded
          );
        if (success) {
          this.getCustomerRelations();
        }
      });
  }
  public updateCustomerRelation(form: FormGroup): void {
    const customerRelation: UpdateCustomerRelationMutationVariables = {
      input: {
        ...form.value,
      },
    };

    this.updateCustomerRelationService
      .mutate(customerRelation)
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.costumerRelationToProjectTypeHyperionMutation
        );
        const success =
          res.data.costumerRelationToProjectTypeHyperionMutation.mutationDetails.every(
            md => md.mutationSucceeded
          );
        if (success) {
          this.getCustomerRelations();
        }
      });
  }
}
