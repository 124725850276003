<div *ngIf="events && events.length" class="p-grid">
  <div class="p-col-12 p-d-flex p-jc-end">
    <button
      pButton
      [icon]="loading ? 'pi pi-spinner pi-spin' : 'pi pi-refresh'"
      (click)="getEvents()"
      label="Uppdatera"
      [disabled]="loading"
    ></button>
  </div>
  <p-table [value]="events" class="p-col-12">
    <ng-template pTemplate="header">
      <tr>
        <th>Tid</th>
        <th>Status</th>
        <th>Info</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value>
      <tr>
        <td>
          {{ value.created }}
        </td>
        <td>
          {{ value.state }}
        </td>
        <td>
          {{ value.message }}
          <a *ngIf="value.documentUrl" [href]="value.documentUrl">
            <br />
            <i class="pi pi-download"></i>
            Hämta dokument
          </a>
          <a
            *ngIf="value.allowUpdateMail"
            (click)="actionShowUpdateMailDialog()"
          >
            <br />
            <i class="pi pi-envelope"></i>
            Ändra mailadress
          </a>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-dialog
  header="Uppdatera mailadress"
  [(visible)]="showUpdateMailDialog"
  [modal]="true"
  [draggable]="false"
  [style]="{ width: '700px' }"
>
  <div class="p-grid">
    <p class="p-col-12">
      Ange en ny mailadress för att skicka dokumentet till.
    </p>
    <div class="p-col-12">
      <span class="p-inputgroup p-mb-2">
        <input
          pInputText
          placeholder="Skicka till:"
          [formControl]="emailFormControl"
          type="email"
        />
        <button
          pButton
          label="Skicka"
          class="p-button-primary-hyperion"
          [icon]="isSending ? ' pi pi-spinner pi-spin' : 'pi pi-send'"
          (click)="actionUpdateMail()"
          [disabled]="
            !(emailFormControl.valid && emailFormControl.value) || isSending
          "
        ></button>
      </span>
      <span
        *ngIf="!emailFormControl.valid && emailFormControl.touched"
        [style.color]="'var(--btn-danger-color)'"
      >
        Mailadressen är inte giltig. Var god kontrollera att du har skrivit in
        en giltig mailadress.
      </span>
    </div>
  </div>
</p-dialog>
