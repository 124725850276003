import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchProjectInfoForInvoiceQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchProjectInfoForInvoiceQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null } | null };

export const FetchProjectInfoForInvoiceDocument = gql`
    query fetchProjectInfoForInvoice($projectId: Int) {
  project(id: $projectId) {
    id
    trueId
    mark
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectInfoForInvoiceGQL extends Apollo.Query<FetchProjectInfoForInvoiceQuery, FetchProjectInfoForInvoiceQueryVariables> {
    document = FetchProjectInfoForInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }