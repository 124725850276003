<div class="p-grid">
  <div class="p-col-12">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
      <input
        type="text"
        pInputText
        (input)="logsTable.filterGlobal($event.target.value, 'contains')"
        placeholder="Sök händelselogg"
      />
    </div>
  </div>
  <div class="p-col-12">
    <p-table
      [value]="logs"
      #logsTable
      [paginator]="logs.length > 50"
      sortField="created"
      sortOrder="-1"
      [rowsPerPageOptions]="[50, 100, 250, 500]"
      [rows]="100"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngFor="let col of cols"
            [pSortableColumn]="col.field"
            [style.width]="col.width"
          >
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-log>
        <tr>
          <td *ngFor="let col of cols">
            {{ log[col.field] }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="cols.length">
            <app-emptyState [model]="'Day'"></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
