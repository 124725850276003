import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, take, takeUntil, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService } from 'app/shared/apollo/apollo-mutation.service';
import { ApolloQueryService } from 'app/shared/apollo/apollo-query.service';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { CompanyUsersService } from 'app/shared/user/company-users.service';
import { FormHandlerService } from 'app/shared/forms/form-handler.service';
import { FunctionsData } from 'app/old-project/functions-data';
import { HelperService } from 'app/shared/helpers/helper.service';
import { ProductComponent } from 'app/shared/dialogs/product/product.component';
import { ProductDetails } from 'app/shared/product-details/product-details';
import { ProductDetailsService } from 'app/shared/product-details/product-details.service';
import { ProductsAutosuggestService } from 'app/shared/company/product/products.autosuggest.service';
import { ProjectProductService } from 'app/old-project/project-product/project-product.service';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { CompanyFunctionsService } from 'app/shared/company';
import productsAutoSuggestServiceProvider from 'app/shared/company/product/products.autosuggest.service.provider';

@Component({
  selector: 'app-create-project-product',
  templateUrl: './create-project-product.component.html',
  styleUrls: ['./create-project-product.component.scss'],
  providers: [
    FormHandlerService,
    AppDialogService,
    productsAutoSuggestServiceProvider,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateProjectProductComponent implements OnInit, OnDestroy {
  @Input() public functionsData: FunctionsData;
  @Input() public isExtra = false;
  @Input() public projectInfo;
  @Input() public projectLabels;
  @Input() public showLargeTable: boolean;
  @Input() public showProductPrices = true;
  @Input() private isMissingDeromeProductIntegration = false;

  @Output() private creationCompleted = new EventEmitter();

  public buttons; // TODO: add typing
  public createProjectProductForm: FormGroup;
  public dataModelCapitalized = 'Projectproduct';
  public formHasLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public meUser: MeUserWithCompany;
  public useOnlyTimeStampReport: boolean;
  public productAutoModel: string; // TODO: rename me - I am consumed in the autosuggest box
  public productBtn: {
    label: string;
    icon: string;
    command: () => void;
  }[];
  public results: BehaviorSubject<any> = new BehaviorSubject([]); // TODO: rename me - i am the results from autocomplete search
  public searchProductInputElementId;
  public useOwnProducts: boolean;
  public usersDropdown = []; // TODO: add typing for dropdown
  public formEditingIsDisabled = false;

  private dataModel = 'projectproduct';
  private formField = { model: this.dataModelCapitalized };
  private searchQuery: Subject<string> = new Subject();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private nameFromAutosuggest = '';

  constructor(
    private apolloMutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private cdr: ChangeDetectorRef,
    private companyFunctionsService: CompanyFunctionsService,
    private dialogService: AppDialogService,
    private formHandler: FormHandlerService,
    private helperService: HelperService,
    private messageService: MessageService,
    private productDetailsService: ProductDetailsService,
    private productsAutoSuggestService: ProductsAutosuggestService,
    private projectProductService: ProjectProductService,
    private usersService: CompanyUsersService,
    private userLocalStorageService: UserLocalStorageService
  ) {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.productBtn = [
      {
        label: 'Skapa produkt',
        icon: 'pi pi-plus',
        command: () => {
          this.openAddProjectDataDialog();
        },
      },
    ];
  }

  ngOnInit() {
    this.buttons = this.formHandler.getButtonValues({
      create: {
        model: this.dataModel,
      },
    });

    this.searchProductInputElementId = this.projectProductService.getElementId(
      this.projectInfo.id,
      this.isExtra,
      'ProductAutoComplete'
    );

    this.initiateForms();

    this.getUsers();

    this.setupSearchQuery();

    this.companyFunctionsService
      .hasCompanyFunction('useOnlyTimestampTimeReport')
      .subscribe(
        onlyTimeStampTimeReport =>
          (this.useOnlyTimeStampReport = onlyTimeStampTimeReport)
      );
  }

  public actionCreate(): void {
    this.cdr.markForCheck();

    const crudType = 'create';
    const dataToMutation = this.apolloMutationService.getMutationDataFromForm(
      this.createProjectProductForm
    );
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (this.isExtra) {
      dataToMutation['extra'] = 1;
    }

    if (this.createProjectProductForm.pristine) {
      this.showEmptyProductMessage();
      this.resetCreateButtonState();

      return;
    }

    if (!this.formHandler.formValid([this.createProjectProductForm])) {
      // If errors in client
      this.formHandler.setErrorFlag(
        false,
        this.createProjectProductForm,
        'submitted'
      );
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const refetchVars = { projectId: +this.projectInfo.id };
      const refetchArr = [
        { name: 'projectCostTypeWithProducts', variables: refetchVars },
      ];
      const responseVariables = ['id', 'userId', 'projectCostTypeId'];

      this.apolloMutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          responseVariables,
          refetchArr
        )
        .pipe(take(1))
        .subscribe(
          executedData => {
            this.apolloMutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.createProjectProductForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.resetForm(executedData);
              this.creationCompleted.emit({
                createdProductId: executedData.id,
              });
            }
            this.focusAfterAction();
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          },
          err => {
            this.cdr.markForCheck();
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.log(err);
          }
        );
    }
  }

  public searchProducts(event): void {
    this.productsAutoSuggestService.searchNext(
      event.query,
      this.useOwnProducts
    );
  }

  private setupSearchQuery() {
    this.productsAutoSuggestService.setupSearchQuery();

    this.productsAutoSuggestService.searchQueryOutput.subscribe(products => {
      this.results.next(products);
    });
  }

  public showAvtalsprisCost(): boolean {
    return this.projectProductService.showAvtalsprisCostColumn();
  }

  public setProductFromAutosuggest(product): void {
    this.nameFromAutosuggest = product.benamning;
    this.createProjectProductForm.controls.benamning.setValue(
      product.benamning
    );

    this.updateFormProductSource(product);

    this.createProjectProductForm.controls.antal.setValue(product.antal || 1);
    this.createProjectProductForm.controls.avtalspris.setValue(
      product.avtalspris || ''
    );
    this.createProjectProductForm.controls.enhet.setValue(product.enhet || '');
    this.createProjectProductForm.controls.artnr.setValue(
      product.artnr || null
    );

    if (this.hasCompanyCostType(product.companyCostTypeId)) {
      this.createProjectProductForm.controls.companyCostTypeId.setValue(
        product.companyCostTypeId
      );
    }

    this.createProjectProductForm.markAsDirty();

    if (this.productDetailsService.usesProductDetailsAPI(product)) {
      if (this.productDetailsService.usesProductDetailsDeromeAPI(product)) {
        this.disableFormEditing();
        // Disabling the form sets it as pristine for some unknown reason.
        this.createProjectProductForm.markAsDirty();

        if (this.isMissingDeromeProductIntegration) {
          // Early return before running into product details on missing external dependency
          const toastMessage: ToastMessage = {
            severity: ToastMessageSeverityType.WARNING,
            summary:
              'Du saknar uppkoppling för ditt Kund-ID och kan därmed inte se priser. Kontakta supporten för att kunna se priser.',
          };
          this.messageService.insertData(toastMessage);
          return;
        }
      }

      const isFullOverride = true;
      this.updateFormWithProductDetailsData(product, isFullOverride)
        .pipe(take(1))
        .subscribe();
    }

    this.cdr.markForCheck();
  }

  public setProductBenamning(): void {
    if (
      this.productAutoModel &&
      this.nameFromAutosuggest !== this.productAutoModel
    ) {
      this.createProjectProductForm.controls.benamning.setValue(
        this.productAutoModel
      );

      // since the product name has changed, it is no longer the same product
      this.updateFormProductSource({ source: null, sourceId: null });
    }
  }

  /**
   * Updates the prices based on the new quantity
   */
  public updatePrices() {
    const product = this.apolloMutationService.getMutationDataFromForm(
      this.createProjectProductForm
    );

    if (!this.productDetailsService.usesProductDetailsAPI(product)) {
      // Do nothing if not connected to details api.
      return;
    }

    return this.updateFormWithProductDetailsData(product)
      .pipe(take(1))
      .subscribe();
  }

  private updateFormWithProductDetailsData(
    projectProduct: any,
    isFullOverride = false
  ): Observable<ProductDetails> {
    return this.productDetailsService
      .getProductDetails(
        projectProduct.source,
        projectProduct.sourceId,
        projectProduct.antal,
        {
          projectId: this.projectInfo.id || null,
        }
      )
      .pipe(
        tap(productDetails => {
          this.createProjectProductForm.controls.avtalsprisCost.setValue(
            productDetails.netPrice || ''
          );
          this.createProjectProductForm.controls.enhet.setValue(
            productDetails.salesPriceUnit || ''
          );

          if (isFullOverride) {
            this.createProjectProductForm.controls.avtalspris.setValue(
              productDetails.salesPrice || ''
            );

            this.createProjectProductForm.controls.antal.setValue(
              productDetails.quantity || 1
            );
            this.createProjectProductForm.controls.benamning.setValue(
              productDetails.name
            );
          }

          this.cdr.markForCheck();
        })
      );
  }

  private updateFormProductSource(product: any): void {
    this.createProjectProductForm.controls.source.setValue(
      product.source || null
    );
    this.createProjectProductForm.controls.sourceId.setValue(
      product.sourceId || null
    );
    this.cdr.markForCheck();
  }

  public initiateFormAndEnableFormEditing(): void {
    this.enableFormEditing();
    this.initiateForms();
  }

  private initiateForms(): void {
    this.productAutoModel = null;

    this.formField['attributes'] = {
      artnr: null,
      date: moment().format(this.helperService.dateFormat()),
      avtalspris: '',
      avtalsprisCost: '',
      antal: '',
      enhet: '',
      benamning: '',
      companyCostTypeId: this.getCompanyCostTypeId(),
      userId: +this.meUser.id,
      projectId: this.projectInfo.id,
      source: null,
      sourceId: null,
    };

    this.createProjectProductForm = this.formHandler.groupedFormSimple(
      this.formField
    );
    this.formHasLoaded.next(true);
    this.formHandler
      .groupSetLabelsRules(this.formField, this.createProjectProductForm)
      .then(() => {
        this.createProjectProductForm.controls.userId['label'] = '';
        this.cdr.markForCheck();
      });
  }

  private getUsers(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.usersDropdown = this.usersService.makeLabelsArray(data);
      });
  }

  private disableFormEditing(): void {
    this.createProjectProductForm.controls.avtalsprisCost.disable();
    this.createProjectProductForm.controls.enhet.disable();
    this.createProjectProductForm.controls.benamning.disable();
    this.formEditingIsDisabled = true;
    this.cdr.markForCheck();
  }

  private enableFormEditing(): void {
    this.createProjectProductForm.controls.avtalsprisCost.enable();
    this.createProjectProductForm.controls.enhet.enable();
    this.createProjectProductForm.controls.benamning.enable();
    this.formEditingIsDisabled = false;
    this.cdr.markForCheck();
  }

  private focusAfterAction(): void {
    const benamningElement = document.getElementById(
      this.searchProductInputElementId
    );
    benamningElement.focus();
  }

  private hasCompanyCostType(value: string | number): boolean {
    return this.functionsData.companyCostTypes.some(
      companyCostType => +companyCostType.value === +value
    );
  }

  private resetForm(executedData: any): void {
    const oldCostTypeId =
      this.createProjectProductForm.controls.companyCostTypeId.value;

    this.initiateFormAndEnableFormEditing();

    // Prepopulate data for next insertion
    this.createProjectProductForm.controls.userId.setValue(
      +executedData.userId
    );
    this.createProjectProductForm.controls.companyCostTypeId.setValue(
      +oldCostTypeId
    );
  }

  private getCompanyCostTypeId(): string {
    const materialCostType = this.functionsData.companyCostTypes.find(
      costType => costType.isMaterial === 1
    );

    return materialCostType.id ? materialCostType.id : '';
  }

  private openAddProjectDataDialog(): DynamicDialogRef {
    return this.dialogService.openComponent(ProductComponent);
  }

  private resetCreateButtonState(): void {
    this.focusAfterAction();
    this.buttons = this.formHandler.unlockButtons(this.buttons);
  }

  private showEmptyProductMessage(): void {
    const toastMessage: ToastMessage = {
      severity: ToastMessageSeverityType.ERROR,
      summary: 'Något blev fel, produkten sparades inte.',
      detail: 'Formuläret får inte vara blankt.',
    };
    this.messageService.insertData(toastMessage);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
