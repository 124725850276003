<form
  [formGroup]="componentMainForm"
  class="full-width no-margin"
  autocomplete="off"
>
  <div class="p-grid row-in-card-md">
    <div class="p-col-12 p-md-5">
      <appForm2-list-input
        formControlName="person"
        (input)="keyUp.next($event)"
      ></appForm2-list-input>
    </div>

    <div class="p-col-12 p-md-5">
      <appForm2-list-input
        formControlName="buisness"
        (input)="keyUp.next($event)"
      ></appForm2-list-input>
    </div>

    <div class="p-col-12 p-md-2">
      <app-button
        [class]="'block'"
        [disabled]="buttons.delete.disabled | async"
        (onClick)="handleDelete()"
        [type]="'deleteNoText'"
      ></app-button>
    </div>
  </div>
</form>
