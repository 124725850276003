import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../shared/global/index';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'home-extraFunctions',
  templateUrl: 'home-extra-functions.component.html',
})
export class HomeExtraFunctionsComponent implements OnInit {
  url;
  showIframe = false;

  constructor(
    private globalService: GlobalService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.globalService.getUrlPrefix() + '/company/extraFunctions'
    );
  }
}
