import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { ConfigService } from 'app/shared/config/config.service';
import { Repeat } from 'immutable';
import { Observable, Subscription } from 'rxjs';
import {
  CostCalculationMode,
  ProjectKpiCollection,
  ProjectOverviewData,
} from '../project-overview/project-overview.service';

@Component({
  selector: 'app-project-overview-diagram',
  templateUrl: './project-overview-diagram.component.html',
  styleUrls: ['./project-overview-diagram.component.scss'],
})
export class ProjectOverviewDiagramComponent implements OnDestroy, OnChanges {
  @Input() public height: string;
  @Input() public projectOverviewData: Observable<ProjectOverviewData>;
  @Input() public includeOverhead: boolean;
  @Input() public setMile = true;
  public data: {
    labels: string[];
    datasets: {
      type: string;
      label: string;
      backgroundColor: string;
      borderRadius: number;
      data: number[];
    }[];
  };
  public options: {
    responsive: boolean;
    maintainAspectRatio: boolean;
    plugins: {
      legend: { display: boolean };
      tooltips: { mode: string; intersect: boolean };
    };
    scales: {
      xAxes: {
        stacked: boolean;
        ticks: { color: string; display: boolean; padding: number };
        grid: { color: string };
      };
      yAxes: {
        stacked: boolean;
        ticks: { color: string; padding: number };
        grid: { color: string };
      };
    };
  };
  public dataSubscription: Subscription;
  public legendItems: null | { label: string; color: string }[];

  public colors: DiagramColors = this.getColorsFromTheme();

  constructor(private configService: ConfigService) {}

  public ngOnChanges(): void {
    this.setDefaultLegendItems();

    this.dataSubscription = this.projectOverviewData.subscribe(data => {
      if (!data || !data.kpis) {
        return;
      }

      this.data = {
        labels: this.getLabels(),
        datasets: this.getDataSets(data.kpis),
      };
      this.setOptions();
    });
  }

  public ngOnDestroy(): void {
    this.dataSubscription?.unsubscribe();
  }

  private parseSourceData(data: ProjectKpiCollection): number[][] {
    if (Object.keys(data).length === 0) {
      return null;
    }

    if (this.setMile) {
      const personellData = this.parsePersonellData(data);
      const materialData = this.parseMaterialData(data);
      const milageData = this.parseMilageData(data);

      const sourceData = this.convertToSourceData(
        personellData,
        materialData,
        milageData
      );

      return sourceData;
    } else {
      const personellData = this.parsePersonellData(data);
      const materialData = this.parseMaterialData(data);

      const sourceData = this.convertToSourceData(personellData, materialData);
      return sourceData;
    }
  }

  private getLabels(): string[] {
    const labels = [
      'Tid och personal - intäkt',
      'Tid och personal - kostnad',
      'Material och övriga kostnader - intäkt',
      'Material och övriga kostnader - kostnad',
    ];
    if (this.setMile) {
      labels.push('Mil - intäkt', 'Mil - kostnad');
    }
    if (this.includeOverhead) {
      labels.push('Omkostnader');
    }
    return labels;
  }

  private getDataSets(data: ProjectKpiCollection): {
    type: string;
    label: string;
    backgroundColor: string;
    borderRadius: number;
    data: number[];
  }[] {
    const borderRadius = 8;
    const sourceData = this.parseSourceData(data);
    if (sourceData === null) {
      return;
    }

    const datasets = [
      {
        type: 'bar',
        label: 'Fakturerat',
        backgroundColor: this.colors.work.invoiced,
        borderRadius: borderRadius,
        data: sourceData[0],
      },
      {
        type: 'bar',
        label: 'Ej fakturerat',
        backgroundColor: this.colors.work.notInvoiced,
        borderRadius: borderRadius,
        data: sourceData[1],
      },
      {
        type: 'bar',
        label: 'Kostnad',
        backgroundColor: this.colors.work.invoiced,
        borderRadius: borderRadius,
        data: sourceData[2],
      },
      {
        type: 'bar',
        label: 'Fakturerat',
        backgroundColor: this.colors.material.invoiced,
        borderRadius: borderRadius,
        data: sourceData[3],
      },
      {
        type: 'bar',
        label: 'Ej fakturerat',
        backgroundColor: this.colors.material.notInvoiced,
        borderRadius: borderRadius,
        data: sourceData[4],
      },
      {
        type: 'bar',
        label: 'Kostnad',
        backgroundColor: this.colors.material.invoiced,
        borderRadius: borderRadius,
        data: sourceData[5],
      },
    ];

    if (this.setMile) {
      datasets.push(
        {
          type: 'bar',
          label: 'Fakturerat',
          backgroundColor: this.colors.mileage.invoiced,
          borderRadius: borderRadius,
          data: sourceData[6],
        },
        {
          type: 'bar',
          label: 'Ej fakturerat',
          backgroundColor: this.colors.mileage.notInvoiced,
          borderRadius: borderRadius,
          data: sourceData[7],
        },
        {
          type: 'bar',
          label: 'Kostnad',
          backgroundColor: this.colors.mileage.invoiced,
          borderRadius: borderRadius,
          data: sourceData[8],
        }
      );
    }

    if (this.includeOverhead) {
      datasets.push({
        type: 'bar',
        label: 'Kostnad',
        backgroundColor: this.colors.overhead,
        borderRadius: borderRadius,
        data: [
          ...Repeat(0, sourceData[0].length).toArray(),
          -data.costsOverheadAmount,
        ],
      });
    }

    return datasets;
  }

  private setOptions(): void {
    this.options = {
      responsive: false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },
      scales: {
        xAxes: {
          stacked: true,
          ticks: {
            color: '#666666',
            display: false,
            padding: 15,
          },
          grid: {
            color: 'rgba(128,128,128,0.2)',
          },
        },
        yAxes: {
          stacked: true,
          ticks: {
            color: '#666666',
            padding: 10,
          },
          grid: {
            color: 'rgba(128,128,128,0.2)',
          },
        },
      },
    };
  }

  private setDefaultLegendItems(): void {
    this.legendItems = [
      {
        label: 'Tid och personal',
        color: this.colors.work.invoiced,
      },
      {
        label: 'Material och övriga kostnader',
        color: this.colors.material.invoiced,
      },
    ];
    if (this.setMile) {
      this.legendItems.push({
        label: 'Mil',
        color: this.colors.mileage.invoiced,
      });
    }
    if (this.includeOverhead) {
      this.legendItems.push({
        label: 'Omkostnader',
        color: this.colors.overhead,
      });
    }
  }

  /**
   * Converts the parsed data to the data structure that Chart.js expect.
   *
   * It's a two-dimensional array where the first dimension maps to datasets
   * and the second dimension maps to each position in the dataset, i.e which
   * bar in the diagram to show the value.
   *
   * Each type that is passed in should render two bars. One is for the revenue,
   * which is divided into invoiced and not invoiced revenue, and the other is
   * for the cost. So each type of data add three datasets.
   */
  private convertToSourceData(...types: DiagramData[]): number[][] {
    const sourceData = [];
    for (let i = 0; i < types.length * 3; i++) {
      sourceData[i] = [];
      for (let j = 0; j < types.length * 2; j++) {
        sourceData[i][j] = 0;
      }
    }
    for (let i = 0; i < types.length; i++) {
      const colIndex = i * 2;
      const rowIndex = i * 3;
      sourceData[rowIndex][colIndex] = types[i].invoiced;
      sourceData[rowIndex + 1][colIndex] = types[i].notInvoiced;
      sourceData[rowIndex + 2][colIndex + 1] = types[i].cost * -1;
    }
    return sourceData;
  }

  private parseMilageData(kpis: ProjectKpiCollection): DiagramData {
    return {
      invoiced: kpis.invoicesMilesAmount,
      notInvoiced: kpis.revenuesMilesAmount - kpis.invoicesMilesAmount,
      cost: kpis.costsMilesAmount,
    };
  }
  private parseMaterialData(kpis: ProjectKpiCollection): DiagramData {
    return {
      invoiced: kpis.invoicesMaterialAmount,
      notInvoiced: kpis.revenuesProductsAmount - kpis.invoicesMaterialAmount,
      cost: kpis.costsProductsAmount,
    };
  }
  private parsePersonellData(kpis: ProjectKpiCollection): DiagramData {
    return {
      invoiced: kpis.invoicesWorkAmount,
      notInvoiced: kpis.revenuesWorkAmount - kpis.invoicesWorkAmount,
      cost:
        kpis.calculationMode === CostCalculationMode.ByUser
          ? kpis.costsSalaryBasedWorkAmount
          : kpis.costsUserCostTypesBasedWorkAmount,
    };
  }

  private getColorsFromTheme(): DiagramColors {
    const theme = this.configService.getConfig().theme;
    const colors: DiagramColors = {
      material: {
        invoiced: theme.materialDiagramColor,
        notInvoiced: theme.materialNotInvoicedDiagramColor,
      },
      work: {
        invoiced: theme.workDiagramColor,
        notInvoiced: theme.workNotInvoicedDiagramColor,
      },
      mileage: {
        invoiced: theme.mileageDiagramColor,
        notInvoiced: theme.mileageNotInvoicedDiagramColor,
      },
      overhead: theme.overheadDiagramColor,
    };
    return colors;
  }
}

interface DiagramData {
  invoiced: number;
  notInvoiced: number;
  cost: number;
}

interface DiagramColors {
  material: { invoiced: string; notInvoiced: string };
  work: { invoiced: string; notInvoiced: string };
  mileage: { invoiced: string; notInvoiced: string };
  overhead: string;
}
