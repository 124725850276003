import { Pipe, PipeTransform } from '@angular/core';
import { ConvertNumberService } from '../convert-number.service';

@Pipe({
  name: 'comma',
})
export class CommaPipe implements PipeTransform {
  constructor(private convertNumber: ConvertNumberService) {}

  public transform(value: string): number {
    value = String(value);
    return this.convertNumber.fromString(value);
  }
}
