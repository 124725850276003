import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'app/shared/message/index';
import { HelperService } from 'app/shared/helpers/index';
import { GlobalService } from 'app/shared/global/index';
import { HtmlModalService } from 'app/shared/html-modal';
import { SortService } from 'app/store/sort.service';
import { CustomSort } from 'app/store/custom-sort';
import { combineLatest, first, Observable } from 'rxjs';
import {
  ProjectProductTypeGQL,
  UpdateRowAmountGQL,
} from './graphql/supplierInvoices.generated';
import { MutationResult } from 'apollo-angular';

@Component({
  selector: 'supplier-invoice-row-holder',
  templateUrl: 'supplier-invoice-row-holder.component.html',
  styleUrls: ['./supplier-invoice-row-holder.component.scss'],
})
export class SupplierInvoiceRowHolderLegacyComponent
  implements OnChanges, OnInit
{
  @Input() public dataSet = [];
  @Input() public projectId;
  @Input() public functionsData;
  @Input() public projectDropdown;
  @Input() public companyCostTypeId: number;

  public extraDropdown = [
    { value: 0, label: 'Normal' },
    { value: 1, label: 'Extra' },
  ];
  public sort: CustomSort = {
    attribute: 'isMovedToProjectProductId',
    ascDesc: 1,
    object: 'project_SupplierInvoiceRowHolder_table',
  };
  public urlPrefix: string;
  public isSaveInProgress = false;

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    public helperService: HelperService,
    private globalService: GlobalService,
    private htmlModalService: HtmlModalService,
    private sortService: SortService,
    private confirmationService: ConfirmationService,
    private projectProductTypeGQL: ProjectProductTypeGQL,
    private updateRowAmountGQL: UpdateRowAmountGQL
  ) {
    this.sort = this.sortService.getSort(this.sort);
  }

  public ngOnInit(): void {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSet) {
      this.dataSet = this.cleanDataSet(this.dataSet);
    }

    if (changes.companyCostTypeId) {
      const value = changes.companyCostTypeId.currentValue;

      if (value) {
        this.companyCostTypeId = +value;
      } else {
        this.companyCostTypeId = +this.getUEmaterialId();
      }
    }

    if (!this.dataSet) {
      return;
    }
    this.dataSet
      .map(invoice => invoice.rows)
      .flat()
      .forEach(row => {
        if (row['isMovedToProjectProductId'] > 1) {
          this.projectProductTypeGQL
            .fetch({
              projectId: this.projectId,
              id: row['isMovedToProjectProductId'],
            })
            .pipe(first())
            .subscribe(res => {
              row['productType'] = res.data.project.projectProducts.edges.map(
                e => e.node
              )[0]?.type;
            });
        }
      });

    if (!this.dataSet || !this.companyCostTypeId) {
      return;
    }
    for (const invoice of this.dataSet) {
      if (invoice.hasOwnProperty('rows')) {
        for (const row of invoice['rows']) {
          row['companyCosttypeId'] = this.companyCostTypeId;
          row['originalAmount'] = row['antal'];
        }
      }
    }
  }

  public changeSort(event): void {
    this.sortService.setSort(event, this.sort);
  }

  private cleanDataSet(dataSet): any[] {
    const cleanDataSet = this.helperService.cleanFromNode(dataSet);

    for (const invoice of cleanDataSet) {
      if (invoice.hasOwnProperty('rows')) {
        invoice['rows'] = this.helperService.cleanFromNode(invoice['rows']);
        invoice['rows'] = this.addValuesToRows(invoice['rows']);
        invoice['allRowsMovedToProjectProduct'] =
          this.checkAllRowsMovedToProjectProduct(invoice['rows']);
        invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion'] =
          this.helperService.cleanFromNode(
            invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion']
          );
        invoice['supplier'] = invoice['supplier'];
      }

      invoice.id = Number(invoice.id);
    }

    return cleanDataSet;
  }

  private checkAllRowsMovedToProjectProduct(rows): boolean {
    let allIsMoved = true;

    for (const row of rows) {
      if (row['isMovedToProjectProductId'] < 1) {
        allIsMoved = false;
      }
    }

    return allIsMoved;
  }

  public getProjectLabel(projectId): string {
    let projectLabel;

    for (const project of this.projectDropdown) {
      if (Number(projectId) === Number(project['value'])) {
        projectLabel = project['label'];
      }
    }

    return projectLabel;
  }

  public updatePris(row): void {
    const salePrice: number = +row.apris;
    const amount: number = +row.antal;
    const discount: number = +('' + row.discount).replace(',', '.');
    const surcharge: number = +('' + row.surcharge).replace(',', '.');

    const subTotal: number = salePrice * amount;
    const subTotalDiscount = (subTotal * discount) / 100;

    const totalValue = subTotal - subTotalDiscount;

    const sum = totalValue * (1 + surcharge / 100);

    row['prisWithDiscount'] = sum.toFixed(2);
  }

  private addValuesToRows(rows): any[] {
    const newRows = [];

    for (const row of rows) {
      // eslint-disable-next-line eqeqeq
      if (this.projectId == row['project_id']) {
        row['extra'] = 0;
        row['surcharge'] = 0;
        row['discount'] = +row['discount'];

        row['discountOriginal'] = +row['discount'];
        row['prisOriginal'] = +row['pris'];

        const numVal1 = Number(row['apris'] * row['antal']);
        const numVal2 = Number(row['discount']) / 100;
        const totalValue = numVal1 - numVal1 * numVal2;
        row['pris'] = totalValue.toFixed(2);

        row['prisWithDiscount'] = row['pris'];

        this.updatePris(row);

        if (row['isMovedToProjectProductId'] < 1) {
          row['project_id'] =
            Number(row['project_id']) < 1
              ? Number(this.projectId)
              : Number(row['project_id']);

          row['companyCosttypeId'] = this.companyCostTypeId;
        }

        row['move'] = true;
        newRows.push(row);
      }
    }

    return newRows;
  }

  private getUEmaterialId(): number {
    let UEmaterialId = null;

    for (const costType of this.functionsData['companyCostTypes']) {
      if (costType['isUEmaterial'] === 1) {
        UEmaterialId = costType['value'];
        break;
      }
    }

    return Number(UEmaterialId);
  }

  public saveMarkedRows(invoiceParam): void {
    if (this.isSaveInProgress) {
      return;
    }
    this.isSaveInProgress = true;
    const values = { Invoice: {} };
    const invoice = invoiceParam;

    const updateAmountCalls = invoice.rows
      .filter(r => r.originalAmount !== r.antal)
      .map(row =>
        this.updateRowAmountGQL
          .mutate({ rowId: row.id, amount: row.antal })
          .pipe(first())
      );

    for (const row of invoice.rows) {
      if (row.isMovedToProjectProductId >= 1) {
        continue;
      }
      if (!row['move']) {
        continue;
      }

      if (!values['Invoice'].hasOwnProperty(invoice.id)) {
        values.Invoice[invoice.id] = {};
      }
      values.Invoice[invoice.id][row.id] = {};

      const rowObject = values.Invoice[invoice.id][row.id];

      const extraValue = row.extra ? 1 : 0;
      rowObject.extra = extraValue;

      const projectIdValue = Number(row.project_id);
      rowObject.projectId = projectIdValue;

      const companyCosttypeIdValue = Number(row.companyCosttypeId);
      rowObject.companyCosttypeId = companyCosttypeIdValue;

      rowObject.discount = Number(row.discount);

      rowObject.surcharge = Number(row.surcharge);

      rowObject.move = 'on';
    }

    const updateCallback = () => {
      if (Object.keys(values.Invoice).length > 0) {
        this.rowsServerRequest(values, invoice);
      } else {
        this.isSaveInProgress = false;
      }
    };

    if (updateAmountCalls.length > 0) {
      combineLatest(updateAmountCalls).subscribe(updateCallback);
    } else {
      updateCallback();
    }
  }

  private rowsServerRequest(values, invoiceParam, groupAllRows = false): void {
    this.rowsHttpPost(values, groupAllRows).subscribe(data => {
      if (data['status'] === 'success') {
        this.messageService.insertData({
          textArray: [data['msg']],
          time: 2000,
          type: 'success',
        });

        if (groupAllRows) {
          // set all move to "on" before the toggle
          for (const invoiceInDataSet of this.dataSet) {
            if (invoiceParam['id'] === invoiceInDataSet['id']) {
              for (const row of invoiceInDataSet['rows']) {
                if (row['isMovedToProjectProductId'] < 1) {
                  row['move'] = true;
                  row['project_id'] = this.projectId;
                }
              }
            }
          }
        }

        this.toggleRowsAsSaved(invoiceParam);
      } else {
        this.messageService.insertData(
          { textArray: [data['msg']], time: 2000, type: 'error' },
          true
        );
      }
      this.isSaveInProgress = false;
    });
  }

  private toggleRowsAsSaved(invoiceParam): void {
    const invoice = invoiceParam;
    const dataSet = this.dataSet;

    for (const invoiceInDataSet of dataSet) {
      if (invoice['id'] === invoiceInDataSet['id']) {
        for (const row of invoiceInDataSet['rows']) {
          if (row['isMovedToProjectProductId'] < 1) {
            if (row['move']) {
              row['isMovedToProjectProductId'] = 1;
            }
          }
        }
      }
      invoiceInDataSet['allRowsMovedToProjectProduct'] =
        this.checkAllRowsMovedToProjectProduct(invoiceInDataSet['rows']);
    }

    const newDataSet = [...dataSet];
    this.dataSet = newDataSet;
  }

  public saveInvoiceToProject(invoiceParam): void {
    this.confirmationService.confirm({
      message:
        'Med detta alternativ kommer alla rader kopplade till detta projekt att sparas som en rad på projektet.',
      header: 'Bekräfta val',
      accept: () => {
        this.isSaveInProgress = true;
        const values = {};
        const invoice = invoiceParam;
        values['invoiceId'] = invoice['id'];
        values['companyCostTypeIdForInvoice'] = this.companyCostTypeId;
        this.rowsServerRequest(values, invoice, true);
      },
      reject: () => {},
    });
  }

  private rowsHttpPost(valuesParam, groupAllRows): Observable<object> {
    // <<<<<<<<<<<<<<<<<<< OBS FAKE URL >>>>>>>>>>>>>>>>>>>
    let url =
      this.globalService.getUrlPrefix() +
      '/project/StoreInvoiceRowsToProjectproductHyperion';
    const contentType = 'application/x-www-form-urlencoded; charset=UTF-8';

    const values = valuesParam;

    if (!groupAllRows) {
      return this.http.post(url, values, {
        headers: new HttpHeaders({
          'Content-Type': contentType,
        }),
      });
    } else {
      url +=
        '?invoiceId=' +
        values['invoiceId'] +
        '&projectId=' +
        this.projectId +
        '&groupAllRowsToOneProjectProduct=1&companyCostTypeIdForInvoice=' +
        (values['companyCostTypeIdForInvoice'] > 0
          ? values['companyCostTypeIdForInvoice']
          : '');

      return this.http.post(url, {
        headers: new Headers({
          'Content-Type': contentType,
        }),
      });
    }
  }

  public openDocument(invoiceId): void {
    const url = this.urlPrefix + '/invoice/Print/type/showPDF/id/' + invoiceId;

    this.htmlModalService.ny_sida(url, 900, 800);
  }

  public openSourcedocument(urlParam): void {
    this.htmlModalService.ny_sida(urlParam, 900, 800);
  }
}
