import { Injectable } from '@angular/core';

@Injectable()
export class CompanyUsersService {
  cleanFromTypes(data, type) {
    const dataArray = data.company.users.edges;

    const dataObjectsWithLabels = [];

    for (const i in dataArray) {
      if (dataArray[i].node['type'] === type) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.firstName + ' ' + dataArray[i].node.lastName,
          value: Number(dataArray[i].node.id),
          costTypeId: dataArray[i].node.costTypeId,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }

    return dataObjectsWithLabels;
  }

  public makeLabelsArray(data) {
    const dataArray = data.company.users.edges;
    const dataObjectsWithLabels = [];

    for (const i in dataArray) {
      const dataObject = {
        // create object to push
        label: dataArray[i].node.firstName + ' ' + dataArray[i].node.lastName,
        value: Number(dataArray[i].node.id),
        id: Number(dataArray[i].node.id),
        color: dataArray[i].node.color,
        costTypeId: dataArray[i].node.costTypeId,
        firstName: dataArray[i].node.firstName,
        lastName: dataArray[i].node.lastName,
      };
      dataObjectsWithLabels.push(dataObject);
    }

    return dataObjectsWithLabels;
  }
}
