import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';

@Component({
  selector: 'app-create-product',
  templateUrl: 'create-edit-product.component.html',
  providers: [FormHandlerService],
})
export class CreateEditProductComponent implements OnInit {
  @Input() public companyCostTypes: { [x: string]: any };
  @Output() public createdProduct = new EventEmitter();

  private readonly dataModel = 'product';
  private readonly dataModelCapitalized = 'Product';
  public buttons: any;
  private formAttributes = {
    companyCostTypeId: '',
    artnr: '',
    benamning: '',
    enhet: '',
    avtalspris: '',
    avtalsprisCost: '',
    leverantor: '',
    top: 0,
  };
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: this.formAttributes,
  };

  public units = [...QuantityUnits];
  public componentMainForm: FormGroup;
  public loading = true;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService
  ) {}

  public ngOnInit(): void {
    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.formsInit();
  }

  private formsInit(): void {
    this.formHandler.groupSetLabelsRules(this.formFields).then(groupedForm => {
      this.componentMainForm = groupedForm;
      this.setControlToMaterial();
      this.loading = false;
    });
  }

  private setControlToMaterial(): void {
    for (const costTypeIndex of Object.keys(this.companyCostTypes)) {
      const costType = this.companyCostTypes[costTypeIndex];

      if (costType['isMaterial'] === 1) {
        this.componentMainForm.controls['companyCostTypeId'].setValue(
          costType['value']
        );
      }
    }
  }

  public actionCreate(): void {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );
    dataToMutation['stored'] = 1; // Måste skicka med stored = 1
    this.formAttributes['companyCostTypeId'] =
      dataToMutation['companyCostTypeId'];

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.formsInit();
              this.createdProduct.emit();
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }
}
