/********************************************************************

Component för att låsa upp applikationen efter att den blivit låst pga inaktivitet

PANI 160914

********************************************************************/

import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/auth/auth.service';
import { MessageService } from '../../message/index';

@Component({
  selector: 'locked-screen',
  templateUrl: 'locked-screen.html',
})
export class LockedScreen {
  @Output() closeModal = new EventEmitter();
  email: string = null;
  password: string = null;
  storedUserValues = false;
  meUser = {
    firstName: null,
    lastName: null,
    fullName: null,
    email: null,
    username: null,
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService
  ) {}

  init() {
    this.meUser = {
      firstName: localStorage.getItem('MEfirstName'),
      lastName: localStorage.getItem('MElastName'),
      fullName:
        localStorage.getItem('MEfirstName') +
        ' ' +
        localStorage.getItem('MElastName'),
      email: localStorage.getItem('MEemail'),
      username: localStorage.getItem('MEusername'),
    };

    if (this.meUser.firstName !== null) {
      this.storedUserValues = true;
    } else {
      this.storedUserValues = false;
    }
  }

  unlockRequest(password) {
    let username;

    if (this.storedUserValues) {
      username = this.meUser['username'];
    } else {
      username = this.email;
    }

    this.authService.login(username, password).subscribe(authData => {
      if (this.authService.isLoggedIn) {
        // Om rätt lösen:
        this.closeModal.emit();
        this.messageService.insertData({
          textArray: ['Inloggad!'],
          type: 'success',
        });
      } else {
        this.messageService.handleResponse(authData);
      }
    });
  }

  goToLogin() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
      this.closeModal.emit();
    });
  }
}
