import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { MessageService } from '../../shared/message/index';
import { HelperService } from '../../shared/helpers/index';
import { ProjectRotRowComponent } from './index';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'project-rot',
  templateUrl: 'project-rot.component.html',
})
export class ProjectRotComponent implements OnDestroy, OnInit {
  @Input() projectInfo;
  @ViewChildren('projectRotRow')
  private projectRots: QueryList<ProjectRotRowComponent>;

  dataSet = [];
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  showAll: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectRotsSub: Subscription;

  updatedCount = 0;
  successFullUpdates = 0;

  constructor(
    private messageService: MessageService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.getRots();
  }

  ngOnDestroy() {
    this.projectRotsSub && this.projectRotsSub.unsubscribe();
  }

  getRots() {
    const variables = { projectId: +this.projectInfo['id'] };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectRots', variables)
      .subscribe(data => {
        this.projectRotsSub = data.sub;
        this.dataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']['edges'][0]['node']['rots']
        );
        this.setShowAll();
        this.loading.next(false);
      });
  }

  setShowAll() {
    let allLonger = true;

    for (const index in this.dataSet) {
      if (this.dataSet.hasOwnProperty(index)) {
        const rotObject = this.dataSet[index];

        if (!this.isRotObjectPopulated(rotObject)) {
          allLonger = false;
          break;
        }
      }
    }

    if (allLonger) {
      this.showAll.next(true);
    }
  }

  private isRotObjectPopulated(rotObject: any): boolean {
    return (
      rotObject.personalNumber &&
      rotObject.nameOfProperty &&
      rotObject.housingAssociationOrgNumber &&
      rotObject.apartmentDesignation
    );
  }

  showRot(rotObject, index, showAll): boolean {
    return this.isRotObjectPopulated(rotObject) || index <= 1 || showAll;
  }

  showAllToggle() {
    this.showAll.next(!this.showAll.value);
  }

  public actionUpdate(): void {
    this.projectRots.forEach(item => {
      item.actionUpdate();
    });
  }

  handleUpdate(executedObject) {
    this.updatedCount++;

    if (executedObject['success']) {
      this.successFullUpdates++;
      this.appendExecutedData(executedObject['executedData']);
    }

    if (this.updatedCount === this.projectRots.length) {
      this.updatedCount = 0;

      this.handleSuccessFull();
    }
  }

  handleSuccessFull() {
    if (this.successFullUpdates === this.projectRots.length) {
      this.messageService.insertData({
        textArray: ['Rotuppgifterna uppdaterades'],
        time: 2000,
        type: 'success',
      });
    } else {
      this.messageService.insertData({
        textArray: ['Samtliga rotuppgifter uppdaterades inte korrekt'],
        time: 2000,
        type: 'error',
      });
    }

    this.successFullUpdates = 0;
  }

  appendExecutedData(executedData) {
    const newObject = executedData;
    delete newObject['mutationDetails'];
    delete newObject['__typename'];

    for (const i in this.dataSet) {
      if (this.dataSet.hasOwnProperty(i)) {
        const row = this.dataSet[i];
        if (+row['id'] === +newObject['id']) {
          this.dataSet.splice(+i, 1, newObject);
        }
      }
    }
  }
}
