import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ValidatorFn,
  FormControl,
} from '@angular/forms';
import {
  EventColorsEnum,
  MapTypeToDropdown,
} from 'app/planner/planner-module-enums';
import {
  LightboxDropdown,
  SchedulerEventWorkTask,
} from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import { Project, ProjectTodo } from 'app/planner/services/planner-query-types';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import { GlobalService } from 'app/shared/global';
import { MessageService } from 'app/shared/message';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-edit-work-task-view',
  templateUrl: './edit-work-task-view.component.html',
  styleUrls: ['./edit-work-task-view.component.scss'],
})
export class EditWorkTaskViewComponent implements OnChanges {
  @Input() public isNewEvent: boolean;
  @Input() public projects: Project[];
  @Input() public scheduledEvent: SchedulerEventWorkTask;
  @Input() public todotopicsDropdown: LightboxDropdown[];

  @Output()
  public closeLightBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public saveEvent: EventEmitter<ProjectTodo> = new EventEmitter<ProjectTodo>();
  @Output()
  public updateEvent: EventEmitter<ProjectTodo> = new EventEmitter<ProjectTodo>();
  @Output()
  public deleteEvent: EventEmitter<number> = new EventEmitter<number>();

  public isVisible = true;
  public form: FormGroup;
  public title: string;
  public isDataLoading = false;
  public isDataDeleting = false;
  public todosDropdown: LightboxDropdown[] = [];
  public projectsDropdown: LightboxDropdown[] = [];
  public datePickerLocale: any;
  public validationTextList: string[] = [null, null];
  public validationText = (list: string[]) => list.filter(Boolean).join('\n');
  public selectedProject: Project;
  public projectStartDate: Date;
  public projectEndDate: Date;

  public handleCloseLightboxEvent = () => {
    this.closeLightBox.emit(false);
  };

  constructor(
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');
  }

  public ngOnChanges(): void {
    this.mapProjectDropdown();
    this.form = this.createFormGroup(this.scheduledEvent);

    this.form.get('project').valueChanges.subscribe(({ value: projectId }) => {
      this.selectedProject = this.projects.find(
        project => project.id === projectId
      );

      this.projectStartDate = new Date(this.selectedProject.startDate);
      this.projectEndDate = new Date(this.selectedProject.endDate);
    });

    if (this.isNewEvent) {
      this.title = 'Skapa nytt arbetsmoment';
      this.scheduledEvent.text = null;
    } else {
      this.form.get('project').disable();
      this.title = 'Redigera arbetsmoment';
    }
  }

  private createFormGroup(dataItem: SchedulerEventWorkTask): FormGroup {
    const group = this.formBuilder.group(
      {
        id: dataItem.id,
        startDate: [new Date(dataItem.start_date), Validators.required],
        endDate: [new Date(dataItem.end_date), Validators.required],
        project: [dataItem.projectId, Validators.required],
        header: [dataItem.headerId, Validators.required],
        text: [dataItem.text, Validators.required],
        estimatedTime: [dataItem.estimatedTime],
        done: [dataItem.done],
        color: dataItem.color
          ? dataItem.color.toUpperCase()
          : EventColorsEnum.Blue,
      },
      { validators: this.dateLessThan('startDate', 'endDate') }
    );

    this.updateInitialDropdownValue(dataItem, group);

    return group;
  }

  private dateLessThan(from: string, to: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } => {
      const f = new Date(group.controls[from].value);
      const t = new Date(group.controls[to].value);
      if (group.controls[to].value && f.getTime() > t.getTime()) {
        this.validationTextList[0] = 'Startdatum måste vara före slutdatum';
        return {
          dates: 'Invalid date',
        };
      }
      this.validationTextList[0] = null;
      return {};
    };
  }

  private updateInitialDropdownValue(
    dataItem: SchedulerEventWorkTask,
    group: FormGroup
  ): void {
    let project = null;
    let header = null;

    if (dataItem.projectId) {
      project = this.projectsDropdown.find(
        p => +p.value === +dataItem.projectId
      );
    }

    if (dataItem.headerId) {
      header = this.todotopicsDropdown.find(
        c => +c.value === +dataItem.headerId
      );
    }

    group.patchValue({
      project,
      header,
    });
  }

  private mapProjectDropdown = () => {
    if (this.projects.length > 0) {
      this.projectsDropdown = [];
      this.projectsDropdown =
        this.plannerDataAdapterService.mapTypeToDropdownList<Project>(
          this.isNewEvent
            ? this.plannerDataAdapterService.removeInternalOnleaveProjects(
                this.projects
              )
            : this.projects,
          MapTypeToDropdown.Projects
        );
    }
  };

  public save = (): void => {
    if (this.form.valid) {
      this.form.disable();
      this.isDataLoading = true;

      const workTask = this.getWorkTaskFromForm(this.form, true);
      this.plannerWriteService.createWorkTask(workTask).subscribe(
        result => {
          if (result.mutationSucceededAllArguments) {
            this.closeLightBox.emit(false);
            this.saveEvent.emit({ ...workTask, id: result.id });
          } else {
            this.messageService.insertDataFromMutation(result);
          }
        },
        error => {
          console.warn(error);
        },
        () => {
          this.isDataLoading = false;
          this.form.enable();
        }
      );
    }
  };

  public update = (): void => {
    if (this.form.valid) {
      this.form.disable();
      this.isDataLoading = true;
      const workTask = this.getWorkTaskFromForm(this.form);
      this.plannerWriteService.updateWorkTask(workTask).subscribe(
        result => {
          if (result.mutationSucceededAllArguments) {
            this.closeLightBox.emit(false);
            this.updateEvent.emit(workTask);
          } else {
            this.messageService.insertDataFromMutation(result);
          }
        },
        error => {
          console.warn(error);
        },
        () => {
          this.isDataLoading = false;
          this.form.enable();
        }
      );
    }
  };

  public deleteConfirm(): void {
    this.confirmationService.confirm({
      header: 'Ta bort planerad tid',
      message: 'Vill du verkligen ta bort den planerade tiden?',
      acceptLabel: 'Ta bort',
      rejectLabel: 'Avbryt',
      accept: this.delete,
      reject: () => {},
    });
  }

  private delete = (): void => {
    this.isDataDeleting = true;
    this.form.disable();
    const workTask = this.getWorkTaskFromForm(this.form);
    this.plannerWriteService.deleteWorkTask(+workTask.id).subscribe(
      result => {
        if (result.mutationSucceededAllArguments) {
          this.closeLightBox.emit(false);
          this.deleteEvent.emit(+workTask.id);
        } else {
          this.messageService.insertDataFromMutation(result);
        }
      },
      err => {
        console.error(err);
      },
      () => {
        this.isDataDeleting = false;
        this.form.enable();
      }
    );
  };

  private getWorkTaskFromForm = (
    form: FormGroup,
    isNew = false
  ): ProjectTodo => {
    const values = form.getRawValue();
    const workTask = {
      id: values.id,
      description: values.text,
      done: values.done ? 1 : null,
      endDate: this.getEndDate(values),
      estimatedTime: values.estimatedTime,
      projectId: +values.project.value,
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      type: +values.header.value,
      color: values.color,
    };

    if (isNew) {
      delete workTask.id;
    }

    return workTask;
  };

  private getEndDate = (values: ProjectTodo): string => {
    if (values.endDate) {
      return moment(values.endDate).format('YYYY-MM-DD');
    } else if (values.estimatedTime) {
      return moment(values.startDate)
        .add(values.estimatedTime / 8 - 1, 'days')
        .format('YYYY-MM-DD');
    } else {
      moment(values.startDate).add('7', 'days').format('YYYY-MM-DD');
    }
  };

  public isRedDay = (date: any): boolean => {
    const day = new Date(date.year, date.month, date.day);
    return day.getDay() === 0;
  };
}
