import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';
import { AppDialogService } from 'app/shared/dialogs';
import { AddAttendanceFreeUserComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-attendance-free-user/add-attendance-free-user.component';

@Component({
  selector: 'subcontractor-edit',
  templateUrl: './subcontractor-edit.component.html',
  providers: [FormHandlerService, AppDialogService],
})
export class SubcontractorEditComponent implements OnChanges, OnDestroy {
  formFields = {
    model: 'Subcontractor',
    attributes: {
      id: '',
      name: '',
      email: '',
      orgNr: '',
    },
  };

  @Input() subcontractorData: any = {};
  @Output() subcontractorUpdated = new EventEmitter();
  @Output() subcontractorDeleted = new EventEmitter();

  fieldsForForm = {};
  freeUsersSub: Subscription;
  componentMainForm: FormGroup;
  freeUsers = new BehaviorSubject([]);
  loading = new BehaviorSubject(false);
  dataModel = 'subcontractor';
  dataModelCapitalized = 'Subcontractor';

  constructor(
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private apolloQuerService: ApolloQueryService,
    private formHandler: FormHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogService: AppDialogService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['subcontractorData'] &&
      this.subcontractorData !== changes['subcontractorData']
    ) {
      this.reInitiateComponent();
    }
  }

  private reInitiateComponent() {
    this.freeUsers.next([]);
    this.initForm();
    this.getFreeUsers();
  }

  populateAttributes() {
    const mainForm = { ...this.formFields['attributes'] };

    for (const key in mainForm) {
      let val = this.subcontractorData[key];

      if (key === 'id') {
        val = Number(val);
      }

      mainForm[key] = val ? val : null;
    }
    this.formFields['attributes'] = mainForm;
  }

  initForm() {
    this.populateAttributes();
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.cdr.detectChanges(); // Inherited change detection from parent
      });
  }

  getFreeUsers() {
    const variables = {
      orgNr: this.subcontractorData.orgNr,
    };

    if (this.subcontractorData.approvedByCompanyId === 'self') {
      this.apolloQuerService
        .apolloQuery('companyAllFreeUsers', variables)
        .subscribe(({ data }) => {
          this.freeUsers.next(data.company.freeUsers);
        });
    } else {
      this.apolloQuerService
        .apolloQuery('subcontractorFreeUsers', variables)
        .subscribe(({ data }) => {
          this.freeUsers.next(
            data.company.subcontractors.edges[0].node.freeUsers
          );
        });
    }
  }

  openAddAttendanceFreeUserDialog() {
    this.dialogService.data = { subcontractorData: this.subcontractorData };
    this.dialogService
      .openComponent(AddAttendanceFreeUserComponent)
      .onClose.subscribe(res => {
        res && this.getFreeUsers();
      });
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.loading.next(true);
      const returnAttributes = Object.keys(this.formFields['attributes']);

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          null
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            executedData.mutationSucceededAllArguments &&
              this.subcontractorUpdated.emit(
                this.updatedSubcontractor(executedData)
              );

            executeMutationSub.unsubscribe();
            this.loading.next(false);
          },
          err => {
            console.log(err);
            this.loading.next(false);
          }
        );
    }
  }

  private updatedSubcontractor = (executedData: any) => ({
    id: executedData.id,
    name: executedData.name,
    orgNr: executedData.orgNr,
    email: executedData.email,
  });

  actionDelete() {
    const subcontractorId = this.componentMainForm.controls.id.value;
    this.loading.next(true);
    const crudType = 'delete';
    const dataToMutation = {
      id: subcontractorId,
    };
    const totalReports =
      Number(this.subcontractorData.attendanceReportAmount) / 2; // count start and stop as one

    const lossOfDataText =
      totalReports > 0
        ? '<br>Det finns ' +
          totalReports +
          ' rapporter där bolagsnamnet kommer tas bort.'
        : '';

    this.confirmationService.confirm({
      message:
        'Är du säker på att du vill ta bort underentreprenören?' +
        lossOfDataText,
      header: 'Ta bort underentreprenör?',
      acceptLabel: 'Ja, ta bort',
      rejectLabel: 'Nej',

      accept: () => {
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            this.dataModel,
            crudType,
            false,
            dataToMutation
          )
          .subscribe(
            executedData => {
              this.formHandler.showServerErrorsOnAttributes(executedData, [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ]);

              this.mutationService.displayMutationStatus(executedData);

              if (executedData.mutationSucceededAllArguments) {
                this.subcontractorDeleted.emit();
              }

              executeMutationSub.unsubscribe();

              this.loading.next(false);
            },
            err => {
              console.log(err);
              this.loading.next(false);
            }
          );
      },
      reject: () => {
        this.loading.next(false);
      },
    });
  }

  ngOnDestroy() {
    this.freeUsersSub && this.freeUsersSub.unsubscribe();
  }
}
