<div class="modal-header" style="text-align: center">
  <h3 class="modal-title">Hej</h3>
</div>
<div class="modal-body" style="text-align: center">
  <p>Vid fortsatt inaktivitet kommer sessionen låsas, för er säkerhet.</p>
  <p>
    Tid kvar:
    <span *ngIf="minutes >= 1"
      >{{ minutes | number: '1.0-0' }} <span *ngIf="minutes > 1">minuter</span
      ><span *ngIf="minutes == 1">minut</span> och </span
    ><span *ngIf="seconds < 10">0</span>{{ seconds | number: '1.0-0' }} sekunder
  </p>
  <h4>Klicka var som helst för att fortsätta arbeta</h4>
</div>
