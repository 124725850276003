import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

import { HyperionFooter } from 'app/shared/footer';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { IdleModule } from 'app/shared/idle/idle.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

@NgModule({
  imports: [
    CommonModule,

    IdleModule,
    NgIdleKeepaliveModule.forRoot(),

    // PrimeNg
    ConfirmDialogModule,
    DialogModule,
    ToastModule,
    HyperionSharedModule,
  ],
  declarations: [HyperionFooter],
  exports: [HyperionFooter],
})
export class FooterModule {}
