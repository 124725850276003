import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { map, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FormHandlerService } from 'app/shared/forms/index';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';

@Component({
  selector: 'app-offer-signature-row',
  templateUrl: './signature-row.component.html',
  providers: [FormHandlerService],
})
export class SignatureRowComponent implements OnDestroy, OnInit {
  @Input() offerInfo;
  @Input() dataObjectFromParent;
  @Input() labels;
  @Output() spliceObjectNotYetCreated = new EventEmitter();
  @Output() deletedObject = new EventEmitter();
  @Output() createdObject = new EventEmitter();

  dataModel = 'offerSignatur';
  dataModelCapitalized = 'OfferSignatur';
  buttons: ButtonsAsync;
  buttonObject = ['delete'];
  keyUp = new Subject<string>();
  inputSubscription: Subscription;
  formFields = {
    model: this.dataModelCapitalized,
  };
  componentMainForm: FormGroup;

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.buttons = this.formHandler.getButtonValuesAsync(this.buttonObject);
    this.initForm();
    this.subscribeToInput();
  }

  ngOnDestroy() {
    this.inputSubscription && this.inputSubscription.unsubscribe();
  }

  subscribeToInput() {
    this.inputSubscription = this.keyUp
      .pipe(
        map(event => event['target']['value']),
        debounceTime(2500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.handleInput();
      });
  }

  initForm() {
    this.formFields['attributes'] = { ...this.dataObjectFromParent };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  handleInput() {
    const id = this.componentMainForm['controls']['id']['value'];
    const name = this.componentMainForm['controls']['name']['value'];

    if (name && name !== '' && typeof name !== 'undefined') {
      if (id === null) {
        this.actionCreate();
      } else {
        this.actionUpdate();
      }
    }
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    dataToMutation['offerId'] = Number(this.offerInfo['id']);
    delete dataToMutation['id'];
    this.formHandler.lockButtonsAsync(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.unlockButtonsAsync(this.buttons);
    } else {
      const returnKeys = Object.keys(this.componentMainForm.value);
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnKeys
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.handleCreatedObject(executedData);
            }

            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.unlockButtonsAsync(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.formHandler.unlockButtonsAsync(this.buttons);
          }
        );
    }
  }

  handleCreatedObject(executedData) {
    this.componentMainForm['controls']['id'].setValue(executedData['id']);

    const createdObject = executedData;
    delete createdObject['mutationDetails'];
    delete createdObject['__typename'];

    this.createdObject.emit(createdObject);
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    this.formHandler.lockButtonsAsync(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.unlockButtonsAsync(this.buttons);
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.unlockButtonsAsync(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.formHandler.unlockButtonsAsync(this.buttons);
          }
        );
    }
  }

  handleDelete() {
    const id = this.componentMainForm['controls']['id']['value'];

    if (id === null) {
      this.spliceObjectNotYetCreated.emit();
    } else {
      this.actionDelete();
    }
  }

  actionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort signaturen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.formHandler.lockButtonsAsync(this.buttons, true);

        this.deleteSignature();
      },
      reject: () => {},
    });
  }

  private deleteSignature() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);

          if (data.mutationSucceededAllArguments) {
            this.deletedObject.emit(this.dataObjectFromParent['id']);
          }

          this.formHandler.unlockButtonsAsync(this.buttons, true);

          this.mutationService.displayMutationStatus(data);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.formHandler.unlockButtonsAsync(this.buttons, true);
        }
      );
  }
}
