import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';
import { first, map } from 'rxjs';
import {
  GetMultiSelectUsersGQL,
  MultiSelectUserFragment,
} from './graphql/multiselectUsers.generated';

@Component({
  selector: 'app-users-multiselect',
  templateUrl: './users-multiselect.component.html',
  styleUrls: ['./users-multiselect.component.scss'],
})
export class UsersMultiselectComponent implements OnInit {
  @Output()
  private selectedUsersEvent = new EventEmitter<MultiSelectUserFragment[]>();
  @ViewChild('multiselect', { static: true })
  public multiselect: MultiSelect;
  @Input()
  public placeholder: string;

  public users: MultiSelectUserFragment[];

  constructor(private getMultiSelectUsersGQL: GetMultiSelectUsersGQL) {}

  public ngOnInit(): void {
    this.getMultiSelectUsersGQL
      .fetch()
      .pipe(
        first(),
        map(res => res.data.company.users.edges.map(e => e.node))
      )
      .subscribe(users => {
        this.users = users;
      });
  }

  public onUserSelect(event: { value: MultiSelectUserFragment[] }): void {
    this.selectedUsersEvent.emit(event.value);
  }
}
