<p-multiSelect
  #multiselect
  [options]="users"
  [placeholder]="placeholder ?? 'Välj användare'"
  (onChange)="onUserSelect($event)"
  [filter]="true"
  filterBy="firstName,lastName"
  [overlayOptions]="{ appendTo: 'body' }"
  emptyFilterMessage="Inga användare funna"
>
  <ng-template let-user pTemplate="item">
    <span>{{ user?.firstName }} {{ user?.lastName }}</span>
  </ng-template>

  <ng-template let-selectedUsers pTemplate="selectedItems">
    <div
      class="p-d-inline"
      *ngIf="!selectedUsers || selectedUsers.length === 0"
    >
      {{ placeholder ?? 'Välj användare' }}
    </div>
    <div
      *ngIf="selectedUsers && selectedUsers.length < 4"
      class="p-text-truncate"
    >
      <div class="p-d-inline" *ngFor="let user of selectedUsers; let i = index">
        <span>{{ user?.firstName }} {{ user?.lastName }}</span>
        <ng-container *ngIf="i < selectedUsers.length - 1">, </ng-container>
      </div>
    </div>
    <span *ngIf="selectedUsers && selectedUsers.length >= 4"
      >{{ selectedUsers.length }} valda</span
    >
  </ng-template>
</p-multiSelect>
