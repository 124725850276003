import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * atLeastOne is a custom validator that allows for at least one FormControl to pass a given
 * validator function within a given list.
 *
 * Example Usage:
 * ```
 * this.signupForm = new FormGroup({
 *   'username': new FormControl(null, Validators.required),
 *   'nickname': new FormControl(null, Validators.required),
 *   'userdata': new FormGroup(
 *     {
 *       'email': new FormControl(null),
 *       'phoneNumber': new FormControl(null),
 *     },
 *     atLeastOne(Validators.required, ['email','phoneNumber'])
 *   )
 * });
 * ```
 * Gracefully stolen from https://stackoverflow.com/a/52193360/1740488
 * @param validator The validator function to be checked against
 * @param controls  The array of the FormControl keys that should at least
 *                  have one validatorFn passing
 */
export const atLeastOne =
  (validator: ValidatorFn, controls: string[] = null) =>
  (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }

    const hasAtLeastOne =
      group &&
      group.controls &&
      controls.some(k => !validator(group.controls[k]));

    return hasAtLeastOne
      ? null
      : {
          atLeastOne: true,
        };
  };
