<p-table
  #table
  [customSort]="hasCustomSort"
  [dataKey]="dataKey"
  [globalFilterFields]="tableMetadata.globalFilterFields || null"
  [loading]="isLoading"
  [paginator]="hasPagination"
  [rowHover]="true"
  [rows]="hasPagination && tableMetadata.paginator?.rows"
  [rowTrackBy]="rowTrackBy"
  [value]="models"
  (onFilter)="filter($event)"
  (onSort)="!hasCustomSort && updateSort($event)"
  (sortFunction)="customSortFunction($event)"
>
  <ng-template pTemplate="caption" *ngIf="tableCaption">
    <ng-container *ngTemplateOutlet="tableCaption"></ng-container>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <ng-container
        *ngFor="
          let column of tableMetadata.columnsMetadata;
          trackBy: columnTrackBy
        "
      >
        <th
          *ngIf="column.isSortable && tableMetadata.sortBy; else nonSortable"
          class="align-center"
          [pSortableColumn]="column.key"
          [ngStyle]="column.style"
        >
          <p-sortIcon [field]="column.key"></p-sortIcon>
          <ng-container *ngTemplateOutlet="tableHeaderContents"></ng-container>
        </th>

        <ng-template #nonSortable>
          <th class="align-center" [ngStyle]="column.style">
            <ng-container
              *ngTemplateOutlet="tableHeaderContents"
            ></ng-container>
          </th>
        </ng-template>

        <ng-template #tableHeaderContents>
          <label *ngIf="!showFilterInput[column.key]">{{ column.label }}</label>

          <span *ngIf="column.isFilterable" class="table-header-filter">
            <input
              class="filter"
              type="text"
              pInputText
              *ngIf="showFilterInput[column.key]"
              [placeholder]="column.label"
              (click)="$event.stopPropagation()"
              (input)="
                table.filter($event.target.value, column.key, 'contains')
              "
            />
            <i
              class="fa fa-fw fa-filter filter-button"
              [class.filter-enabled]="showFilterInput[column.key]"
              (click)="$event.stopPropagation(); toggleFilterInput(column.key)"
            ></i>
          </span>

          <span
            *ngIf="column.helperTooltip"
            class="help-tooltip invert smaller"
            [tooltipPosition]="column.helperTooltip.position || 'right'"
            [pTooltip]="column.helperTooltip.value || null"
            [escape]="column.helperTooltip.escape !== false"
            >?</span
          >
        </ng-template>
      </ng-container>
    </tr>
  </ng-template>

  <ng-template
    pTemplate="body"
    let-rowData
    let-rowIndex="rowIndex"
    let-expanded="expanded"
    let-columns="columns"
  >
    <tr
      class="data-group-header p-component-header"
      *ngIf="
        tableMetadata.groupBy &&
        rowGroupMetadata[rowData[tableMetadata.groupBy.field]]?.index ===
          rowIndex
      "
    >
      <td
        class="p-text-truncate"
        [attr.colspan]="tableMetadata.columnsMetadata.length"
      >
        {{ rowData[tableMetadata.groupBy.label] }}
      </td>
    </tr>

    <tr class="data-row" [class.no-expand]="!rowExpand" [pRowToggler]="rowData">
      <td
        *ngFor="
          let column of tableMetadata.columnsMetadata;
          trackBy: columnTrackBy
        "
        [ngStyle]="column.style"
        class="p-text-truncate"
      >
        {{ rowData[column.key + 'text'] }}
      </td>
    </tr>

    <tr
      class="data-group-summary"
      *ngIf="
        tableMetadata.groupBy &&
        tableMetadata.groupTotalsPosition === 'bottom' &&
        rowIndex === summaryIndex(rowData[tableMetadata.groupBy.field]) &&
        !expanded
      "
    >
      <td
        *ngFor="
          let column of tableMetadata.columnsMetadata;
          trackBy: columnTrackBy
        "
        [ngStyle]="column.style"
      >
        <ng-container *ngIf="column.isSummable">
          {{
            rowGroupMetadata[rowData[tableMetadata.groupBy.field]].sums[
              column.key
            ].text
          }}
        </ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template
    pTemplate="rowexpansion"
    let-rowData
    let-rowIndex="rowIndex"
    let-rowSize="rowSize"
    *ngIf="rowExpand"
  >
    <tr class="data-row-expanded">
      <td [attr.colspan]="tableMetadata.columnsMetadata.length">
        <ng-container
          *ngTemplateOutlet="
            rowExpand;
            context: { $implicit: rowData, rowIndex: rowIndex }
          "
        ></ng-container>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr class="emptyStateTR">
      <td [attr.colspan]="tableMetadata.columnsMetadata.length">
        <app-emptyState
          *ngIf="tableMetadata.emptyStateSettings"
          [model]="tableMetadata.emptyStateSettings.model"
          [whereToCreate]="
            tableMetadata.emptyStateSettings.whereToCreate || null
          "
        ></app-emptyState>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer" let-rowData>
    <tr class="data-group-summary" *ngIf="tableMetadata.showTableTotals">
      <td
        *ngFor="
          let column of tableMetadata.columnsMetadata;
          trackBy: columnTrackBy
        "
        [ngStyle]="column.style"
      >
        <ng-container *ngIf="column.isSummable">{{
          tableSums[column.key]?.text
        }}</ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
