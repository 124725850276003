<div class="p-grid">
  <div class="p-col-12 p-lg-4">
    <hyperion-card hasHeader="true">
      <div class="header">
        <h4>Skapa ny kommentar</h4>
      </div>

      <div class="p-grid p-g-nopad">
        <div class="p-col-12">
          <p style="margin-bottom: 0.75em">
            Här kan du skriva kommentarer som blir knutna till denna offert.
            Kommentarerna visas inte för kunden.
          </p>
          <div>
            <form [formGroup]="componentMainForm">
              <appForm2-list-textarea
                [rows]="4"
                formControlName="comment"
              ></appForm2-list-textarea>
            </form>
          </div>
          <app-button
            class="pull-right"
            [disabled]="buttons.comment.create.disabled | async"
            (onClick)="actionCreate()"
            [type]="'create'"
            [model]="'Comment'"
          ></app-button>
        </div>
      </div>
    </hyperion-card>
  </div>

  <div class="p-col-12 p-lg-8">
    <app-emptyState
      *ngIf="comments.length === 0"
      [model]="'Comment'"
      [whereToCreate]="'left'"
    ></app-emptyState>
    <div
      *ngFor="let comment of comments; trackBy: helperService.rowTrackBy"
      style="margin-bottom: 10px"
    >
      <hyperion-card>
        <app-offer-comment-handle
          [dataObjectFromParent]="comment"
          [apolloQueryName]="apolloQueryName"
          [offerInfo]="offerInfo"
          (UpdateEmitter)="getComments()"
        ></app-offer-comment-handle>
      </hyperion-card>
    </div>
  </div>
</div>
