<form [formGroup]="invoiceTimeForm" class="p-col-12 p-grid p-jc-between p-m-0">
  <span class="p-col-6 p-pr-0 p-pl-0 p-d-flex p-jc-start">
    <span
      class="p-inputgroup p-mr-2"
      [style.display]="type === 1 ? 'none' : ''"
    >
      <span class="p-inputgroup-addon">
        <i class="pi pi-user"></i>
      </span>
      <app-contact-select
        [selectedContact]="customerForm.value"
        (selectedContactEvent)="setContactFromId($event)"
        [style.min-width]="'20rem'"
        [style.max-width]="'50%'"
      ></app-contact-select>
      <span class="p-inputgroup-addon">
        <i class="pi pi-search"></i>
      </span>
    </span>
    <p-dropdown
      class="p-d-flex"
      *ngIf="type === 0"
      [ngModel]="invoiceType"
      [ngModelOptions]="{ standalone: true }"
      [options]="invoiceTypes"
      optionLabel="name"
      dataKey="id"
      (onChange)="doSingleSelect($event.value)"
      [style]="{ width: '12rem' }"
    >
    </p-dropdown>
    <div *ngIf="type === 1" class="p-d-flex">
      <button
        *ngFor="
          let edge of invoice?.source
            ?.invoiceSourceDocuments_PreInvoiceTypeHyperion?.edges
        "
        pButton
        class="p-mr-2"
        icon="pi pi-file"
        iconPos="right"
        label="{{ edge.node.label }}"
        (click)="showPdf(edge.node.url)"
      ></button>
    </div>
  </span>

  <span
    class="p-pr-0 p-pl-0"
    [class.p-col-6]="!isDialog && type === 0"
    [class.p-col-3]="isDialog || type === 1"
  >
    <span
      [style.max-width]="'18rem'"
      [style.float]="'right'"
      class="p-inputgroup"
    >
      <label class="p-inputgroup-addon">Fakturadatum</label>
      <p-calendar
        class="p-d-flex"
        showIcon="true"
        formControlName="fdatum"
        appendTo="body"
        dataType="string"
        [showWeek]="true"
      ></p-calendar>
    </span>
  </span>

  <span *ngIf="type === 0 && !isDialog" class="p-col-6 p-pr-0 p-pl-0">
    <span
      [style.min-width]="'20rem'"
      [style.max-width]="'50%'"
      class="p-inputgroup"
      pTooltip="Välj projekt att spara fakturan på"
      tooltipPosition="top"
    >
      <span class="p-inputgroup-addon">
        <i class="pi pi-briefcase"></i>
      </span>
      <app-project-select
        class="p-d-flex"
        (selectedProjectEvent)="onProjectChanged($event.id)"
        [projectId]="projectToOpen"
      ></app-project-select>
    </span>
  </span>

  <span
    class="p-pr-0 p-pl-0"
    [class.p-col-6]="!isDialog && type === 0"
    [class.p-col-3]="isDialog || type === 1"
  >
    <span
      [style.float]="'right'"
      [style.max-width]="'18rem'"
      class="p-inputgroup"
    >
      <label class="p-inputgroup-addon">Förfallodatum</label>
      <p-calendar
        class="p-d-flex"
        showIcon="true"
        formControlName="ffdatum"
        appendTo="body"
        dataType="string"
        [showWeek]="true"
      ></p-calendar>
    </span>
  </span>
</form>

<p-accordion class="p-col-12 p-d-flex">
  <p-accordionTab header="Fakturauppgifter" [selected]="openAllAccordions">
    <app-invoice-head-info [invoiceType]="invoiceType"></app-invoice-head-info>
  </p-accordionTab>
</p-accordion>

<p-accordion class="p-col-12 p-d-flex">
  <p-accordionTab header="Kontaktuppgifter" [selected]="openAllAccordions">
    <app-invoice-head-shippingadress></app-invoice-head-shippingadress>
  </p-accordionTab>
</p-accordion>

<p-accordion class="p-col-12 p-d-flex">
  <p-accordionTab header="Kunduppgifter" [selected]="openAllAccordions">
    <app-invoice-head-customer
      (ChangetypeInvoice)="ChangetypeInvoice($event)"
    ></app-invoice-head-customer>
  </p-accordionTab>
</p-accordion>

<p-accordion
  [style.display]="isTaxReduction ? '' : 'none !important'"
  class="p-col-12 p-d-flex"
>
  <p-accordionTab [header]="invoiceType.name" [selected]="openAllAccordions">
    <app-invoice-head-rot
      [invoiceType]="invoiceType.id"
      [rotPersons]="rotPersons"
      [invoice]="invoice"
    ></app-invoice-head-rot>
  </p-accordionTab>
</p-accordion>

<p-accordion *ngIf="isDialog" class="p-col-12 p-d-flex">
  <p-accordionTab header="Ladda projektdata" [selected]="true" class="last">
    <div class="p-grid">
      <div class="p-d-flex p-col-12 p-ai-center p-jc-between">
        <span class="p-d-flex p-ai-center p-jc-between">
          <p-multiSelect
            [style.width]="'11rem'"
            [options]="projectsDropdown"
            defaultLabel="Välj projekt"
            [(ngModel)]="selectedProjects"
            (onChange)="projectSelected()"
            [overlayOptions]="{ appendTo: 'body' }"
            selectedItemsLabel="{0} valda projekt"
            class="p-mr-2"
            [overlayOptions]="{ appendTo: 'body' }"
          ></p-multiSelect>
          <button
            pButton
            icon="pi pi-times"
            [hidden]="selectedProjects.length === 0 || gettingProject"
            (click)="clearOpenedProject()"
            class="p-mr-2"
          ></button>
          <button
            pButton
            [disabled]="
              isLoadProjectButtonBlocked ||
              selectedProjects.length === 0 ||
              gettingProject
            "
            (click)="getInvoices()"
            label="Ladda Projekt"
            class="p-mr-2"
          ></button>
        </span>
        <span class="p-d-flex p-ai-center p-jc-between">
          <p-multiSelect
            [style.width]="'12rem'"
            defaultLabel="Välj..."
            [options]="invoiceModes"
            *ngIf="selectedProjects.length > 0"
            optionLabel="name"
            (onChange)="changeInvoiceMode()"
            [(ngModel)]="selectedInvoiceMode"
            selectedItemsLabel="{0} valda"
            class="p-mr-2"
            [overlayOptions]="{ appendTo: 'body' }"
          ></p-multiSelect>
          <p-multiSelect
            [group]="true"
            defaultLabel="Ladda in ÄTA"
            [options]="projectsAtasOptions"
            *ngIf="selectedProjects.length"
            (onChange)="onLoadSelectedProjectAtas($event)"
            selectedItemsLabel="{0} valda ÄTA"
            maxSelectedLabels="0"
            appendTo="body"
            emptyMessage="Inga ÄTA hittades"
            [overlayOptions]="{ appendTo: 'body' }"
          ></p-multiSelect>
          <button
            pButton
            *ngIf="showPreSelectAssistantButton"
            (click)="showPreSelectAssistant = true"
            icon="pi pi-calendar"
            label="Välj datum"
            class="p-mr-2"
          ></button>
        </span>
        <span class="p-d-flex p-ai-center p-jc-between">
          <p-checkbox
            [disabled]="setRowsFree"
            label="Ange poster fritt"
            [(ngModel)]="setRowsFree"
            [binary]="true"
            (onChange)="checkboxChng()"
            *ngIf="selectedProjects.length > 0"
          ></p-checkbox>
        </span>
      </div>
    </div>
  </p-accordionTab>
</p-accordion>

<p-dialog
  [(visible)]="showPreSelectAssistant"
  [draggable]="false"
  [appendTo]="'body'"
  [header]="'Välj datum'"
  [modal]="true"
  [resizable]="false"
  [responsive]="true"
>
  <form
    [formGroup]="selectByDateFG"
    (keyup.enter)="selectByDate()"
    class="p-grid form-md"
  >
    <p class="p-col-12">
      Välj inom vilket datumintervall ni vill fakturera. Endast
      <span class="emphasis">Löpande</span> och
      <span class="emphasis">Löpande - extra</span> påverkas av detta val.
    </p>
    <div class="p-col-12">
      <appForm2-simple-calendar
        class="p-col-6"
        formControlName="from"
        [appendTo]="'body'"
        [htmlAttributes]="{ label: { value: 'Från' } }"
      ></appForm2-simple-calendar>
      <appForm2-simple-calendar
        class="p-col-6"
        formControlName="to"
        [appendTo]="'body'"
        [htmlAttributes]="{ label: { value: 'Till' } }"
      ></appForm2-simple-calendar>
    </div>
  </form>
  <p-footer>
    <app-button
      (onClick)="showPreSelectAssistant = false"
      [type]="'cancel'"
    ></app-button>
    <app-button
      [disabled]="selectByDateFG.invalid"
      (onClick)="selectByDate()"
      [type]="'select'"
    ></app-button>
  </p-footer>
</p-dialog>
