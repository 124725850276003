<div
  class="p-grid"
  style="overflow: visible"
  *ngIf="meUser.type === '3' || meUser.type === '2'"
>
  <div class="p-col-12 p-lg-6 full-width p-g" style="overflow: visible">
    <hyperion-card hasHeader="true">
      <div class="header">
        <h4>Skapa nytt arbetsmoment</h4>
      </div>
      <div>
        <p style="margin-bottom: 1.5em">
          Välj en rubrik som du vill att arbetsmomentet ska ha, skriv sedan
          arbetsmomentet i rutan och spara det genom att trycka på spara. Under
          "Hantera rubriker" till höger kan du skapa fler rubriker.
        </p>
        <form *ngIf="componentMainForm" [formGroup]="componentMainForm">
          <appForm2-wide-dropDown
            formControlName="type"
            [dropDownParams]="{ options: todoTopicsDropdown, filter: true }"
          ></appForm2-wide-dropDown>

          <appForm2-wide-textarea
            formControlName="description"
          ></appForm2-wide-textarea>

          <div
            class="p-grid p-g-nopad"
            style="margin-top: -50px; margin-bottom: 14px"
          >
            <div class="p-col-12 p-md-3"></div>
            <div class="p-col-12 p-md-9">
              <div class="form-group inline todo-autocomplete">
                <p-autoComplete
                  [suggestions]="results | async"
                  inputStyleClass="form-control hide-input"
                  field="description"
                  (onSelect)="setTodoFromAutosuggest($event)"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  styleClass="wide-panel"
                  [(ngModel)]="todoAutoModel"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <ng-template let-object pTemplate="item">
                    <div class="p-helper-clearfix" style="font-size: 12px">
                      <div class="autocomplete-row">
                        <div>{{ object.description }}</div>
                        <div style="float: none" class="clear-both"></div>
                      </div>
                    </div>
                  </ng-template>
                </p-autoComplete>
              </div>
            </div>
          </div>
          <appForm2-wide-calendar
            formControlName="startDate"
            dateFormat="yy-mm-dd"
            (ngModelChange)="calculateEstimatedTime()"
          >
          </appForm2-wide-calendar>
          <appForm2-wide-calendar
            formControlName="endDate"
            dateFormat="yy-mm-dd"
            (ngModelChange)="calculateEstimatedTime()"
          >
          </appForm2-wide-calendar>
          <appForm2-wide-input
            formControlName="estimatedTime"
          ></appForm2-wide-input>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="pull-right">
                <app-button
                  [disabled]="buttons.create.disabled"
                  (onClick)="actionCreate()"
                  [type]="'create'"
                  [model]="'Todo'"
                ></app-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </hyperion-card>
  </div>
  <div class="p-col-12 p-lg-6">
    <hyperion-card hasHeader="true">
      <div class="header flex-header">
        <div class="p-col-6">
          <h4>Hantera rubriker</h4>
        </div>
        <div class="p-col-6 p-g-nopad align-right">
          <app-button
            class="pull-right"
            (click)="printTodo()"
            [type]="'showPdf'"
          ></app-button>
          <app-split-button
            class="pull-right"
            (onClick)="showMailModule()"
            [btnModel]="usersDropdownSplitBtn"
            [model]="'Mail'"
            [type]="'showMailComponent'"
          ></app-split-button>
        </div>
      </div>

      <div class="p-col-12">
        <p>
          För att få bättre ordning på projektet kan du strukturera de olika
          arbetsmomenten under olika rubriker. Till exempel El, VVS eller Bygg
          eller enskilda personer på ert företag.
        </p>
      </div>
      <form *ngIf="todoTopicForm" [formGroup]="todoTopicForm">
        <div class="p-grid p-col-12">
          <div class="p-col-12 p-lg-6">
            <appForm2-list-input
              formControlName="Name"
              class="form-group inline"
            ></appForm2-list-input>
          </div>
          <div class="p-col-12 p-lg-3">
            <app-button
              [disabled]="topicButtons.create['disabled']"
              (onClick)="createTopic()"
              [type]="'save'"
            ></app-button>
          </div>
        </div>

        <div class="p-grid p-col-12">
          <div class="p-col-12 p-lg-6">
            <appForm2-list-dropDown
              class="form-group inline"
              [dropDownParams]="{ options: altTodoTopicsDropdown }"
              [ngModel]="eraseTopicModel"
              (ngModelChange)="eraseTopicModel = $event"
              [ngModelOptions]="{ standalone: true }"
            >
            </appForm2-list-dropDown>
          </div>
          <div class="p-col-12 p-lg-3">
            <app-button
              [disabled]="topicButtons.delete.disabled"
              (onClick)="eraseTopic()"
              [type]="'delete'"
            ></app-button>
          </div>
        </div>
      </form>
    </hyperion-card>
  </div>
</div>

<!-- LIST -->
<div class="p-grid">
  <div class="p-col-12">
    <spinner *ngIf="loading | async"></spinner>
    <div *ngIf="dataSet.todoTopicsWithChilds.length == 0 && !(loading | async)">
      <app-emptyState [model]="'Todo'" [whereToCreate]="'top'"></app-emptyState>
    </div>
  </div>

  <div
    class="p-col-12"
    *ngFor="
      let topic of dataSet.todoTopicsWithChilds;
      trackBy: helperService.rowTrackBy
    "
  >
    <hyperion-card>
      <div class="p-grid">
        <div class="p-col-12">
          <h3>{{ topic.name }}</h3>
        </div>
        <div class="p-col-12 p-g-nopad">
          <div class="p-grid">
            <div class="p-col-12 p-lg-2 show-on-large-device">
              <h5>Arbetsmoment</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Ordning</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Status</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Startdatum</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Slutdatum</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Estimerad tid</h5>
            </div>
            <div class="p-col-6 p-lg-1 show-on-large-device">
              <h5>Utslag</h5>
            </div>
            <div class="p-col-12 p-lg-1 show-on-large-device"></div>
            <div class="p-col-12 p-lg-2 show-on-large-device">
              <h5>Knyt medarbetare</h5>
            </div>
            <div
              *ngIf="subcontractorsDropdown.length > 0"
              class="p-col-12 p-lg-2 show-on-large-device"
            >
              <h5>Dela med underentreprenör</h5>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-g-nopad">
          <div
            *ngFor="
              let child of topic.childs;
              trackBy: helperService.rowTrackBy
            "
          >
            <project-todo-row
              [dataObjectFromParent]="child"
              [usersDropdown]="usersDropdown"
              [subcontractorsDropdown]="subcontractorsDropdown"
              [meUser]="meUser"
            ></project-todo-row>
          </div>
        </div>
      </div>
    </hyperion-card>
  </div>
  <div class="p-col-12" *ngIf="meUser.type === '3' || meUser.type === '2'">
    <div class="p-grid">
      <div class="p-col-12 p-lg-2 show-on-large-device">
        <strong>Totalt:</strong>
      </div>
      <div class="p-col-6 p-lg-1 show-on-large-device">&nbsp;</div>
      <div class="p-col-6 p-lg-1 show-on-large-device">&nbsp;</div>
      <div class="p-col-6 p-lg-1 show-on-large-device">
        <strong>{{ totals.estimatedTime }}</strong>
      </div>
      <div class="p-col-6 p-lg-1 show-on-large-device">
        <strong>{{ totals.totalHours }}</strong>
      </div>
      <div class="p-col-12 p-lg-1 show-on-large-device"></div>
      <div class="p-col-12 p-lg-2 show-on-large-device">&nbsp;</div>
      <div
        *ngIf="subcontractorsDropdown.length > 0"
        class="p-col-12 p-lg-2 show-on-large-device"
      >
        &nbsp;
      </div>
    </div>
  </div>
</div>

<div class="p-grid" *ngIf="meUser.type === '3' || meUser.type === '2'">
  <div class="p-col-12">
    <div>
      <h2>Diagram över arbetsmoment</h2>
    </div>
    <spinner *ngIf="chartsLoading | async"></spinner>
    <div *ngIf="!(chartsLoading | async)">
      <div style="margin-bottom: 15px" [ng2-highcharts]="charts.pie"></div>
      <div [ng2-highcharts]="charts.bars"></div>
    </div>
  </div>
</div>
