import { Component, OnInit, OnDestroy } from '@angular/core';
import { HelperService } from 'app/shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';

import { Subscription, BehaviorSubject, Subject } from 'rxjs';
import { AttendanceReportService } from 'app/attendance-report/services/attendance-report.service';
import { GlobalService } from 'app/shared/global';
import { CompanyUsersService } from '../../shared/user';
import { HttpService } from 'app/shared/http';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-collection-list',
  templateUrl: './collection-list.component.html',
})
export class CollectionListComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  controlObject = {
    project: null,
    from: null,
    to: null,
    startOrStopTime: 'notClosed',
    userIds: [],
    orgNr: null,
  };

  projectsData = [];
  usersDropdown = [];
  projectsDropdown = [];
  isExport = false;
  subcontractorsDropdown = [];
  showallUsers = true;
  usersSub: Subscription;
  attendanceReportProjectsSub: Subscription;
  loading = new BehaviorSubject<boolean>(false);

  constructor(
    private helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private attendanceReportService: AttendanceReportService,
    private globalService: GlobalService,
    private usersService: CompanyUsersService,
    private httpService: HttpService
  ) {}

  ngOnInit() {
    this.setControlObject();
    this.getAttendanceReportProjects();
    this.getUsersDropdown();
  }

  setControlObject() {
    const date = new Date();
    const firstDay = this.helperService.dateFormatTimeZone(
      new Date(date.getFullYear(), date.getMonth(), 1),
      true
    );
    const lastDay = this.helperService.dateFormatTimeZone(
      new Date(date.getFullYear(), date.getMonth() + 1, 0),
      true
    );

    this.controlObject.from = firstDay;
    this.controlObject.to = lastDay;
  }

  getAttendanceReportProjects() {
    const variables = { status: -1, forceAll: false }; // -1 = alla projekt

    this.apolloQueryService
      .apolloWatchQueryTwo(
        'attendanceReportProjects',
        variables,
        'cache-and-network'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.attendanceReportProjectsSub = data.sub;
        this.projectsDropdown =
          this.attendanceReportService.formatAttendanceReportProjects(
            data.data
          );
      });
  }

  getUsersDropdown() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.usersService.makeLabelsArray(data);
      });
  }

  getAttendanceReports() {
    const variables = this.constructVariables();
    this.projectsData = [];
    this.loading.next(true);

    this.apolloQueryService
      .apolloQuery('collectionList', variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }) => {
        this.projectsData =
          this.attendanceReportService.formatProjectsWithAttendanceReports(
            data
          );
        this.loading.next(false);
      });
  }

  constructVariables() {
    let projectIds = [];

    if (this.controlObject.project === null) {
      for (let i = 1; i < this.projectsDropdown.length; i++) {
        projectIds.push(+this.projectsDropdown[i]['value']['id']);
      }
    } else {
      projectIds = [+this.controlObject.project['id']];
    }

    const variables = {
      projectIds: projectIds,
      userIds: this.controlObject.userIds,
      startOrStopTime: this.controlObject.startOrStopTime,
      from: this.controlObject.from,
      to: this.controlObject.to,
    };

    return variables;
  }

  handleExportToggleClick() {
    if (this.controlObject.project) {
      this.toggleExport();
    }
  }

  toggleExport() {
    this.isExport = !this.isExport;
  }

  getProjectSubcontractors() {
    const variables = {
      projectId: Number(this.controlObject.project.id),
    };

    this.apolloQueryService
      .apolloQuery('attendanceProjectsSubcontractors', variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }) => {
        this.subcontractorsDropdown =
          this.attendanceReportService.formatProjectSubcontractorsDropdown(
            data
          );
      });
  }

  getExportFile() {
    const address = '/attendanceReport/ExportHyperion/';
    const variables = {
      projectId: this.controlObject.project.id,
      from: this.controlObject.from,
      to: this.controlObject.to,
      orgNr: this.controlObject.orgNr,
    };

    this.httpService
      .makeHttpPostRequest(address, variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        location.href = data.url;
      });
  }

  projectChangeHandle() {
    if (!this.controlObject.project && this.isExport) {
      this.isExport = false;
    }
    if (this.controlObject.project) {
      this.getProjectSubcontractors();
    }
  }

  ngOnDestroy() {
    this.usersSub && this.usersSub.unsubscribe();
    this.attendanceReportProjectsSub &&
      this.attendanceReportProjectsSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
