import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectImagesQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectImagesQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', pictureComments?: { __typename?: 'PictureCommentConnection', totalCount: number, edges?: Array<{ __typename?: 'PictureCommentEdge', node?: { __typename?: 'PictureComment', id: string, width?: number | null, height?: number | null, comment?: string | null, created?: string | null, displayOnPrint?: number | null, thumbnail?: string | null, largerImage?: string | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null } | null } | null> | null } | null } | null };

export type ImageFragment = { __typename?: 'PictureComment', id: string, width?: number | null, height?: number | null, comment?: string | null, created?: string | null, displayOnPrint?: number | null, thumbnail?: string | null, largerImage?: string | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null };

export const ImageFragmentDoc = gql`
    fragment Image on PictureComment {
  id
  thumbnail: imageUrl(width: 300, height: 300)
  largerImage: imageUrl(width: 2000, height: 2000)
  width
  height
  comment
  user {
    firstName
    lastName
  }
  created
  displayOnPrint
}
    `;
export const ProjectImagesDocument = gql`
    query projectImages($id: Int, $offset: Int, $count: Int) {
  project(id: $id) {
    pictureComments(offset: $offset, last: $count) {
      totalCount
      edges {
        node {
          ...Image
        }
      }
    }
  }
}
    ${ImageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectImagesGQL extends Apollo.Query<ProjectImagesQuery, ProjectImagesQueryVariables> {
    document = ProjectImagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }