import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type EditTodoMutationVariables = Types.Exact<{
  todo?: Types.InputMaybe<Types.TodoTypeHyperionMutationInput>;
}>;


export type EditTodoMutation = { __typename?: 'HyperionSchemaMutation', todoTypeHyperionMutation?: { __typename?: 'Todo', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type GetProjectTodoQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProjectTodoQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, orderNr?: number | null, startDate?: string | null, endDate?: string | null, description?: string | null, estimatedTime?: number | null, usersTodoRelation?: { __typename?: 'UserTodoRelationConnection', edges?: Array<{ __typename?: 'UserTodoRelationEdge', node?: { __typename?: 'UserTodoRelation', id: string, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, isDeleted?: number | null } | null } | null } | null> | null } | null, topic?: { __typename?: 'Todotopic', id: string } | null } | null } | null> | null } | null } | null };

export type GetDefaultTodoQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetDefaultTodoQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectDefualtTodos?: { __typename?: 'ProjectDefualtTodoConnection', edges?: Array<{ __typename?: 'ProjectDefualtTodoEdge', node?: { __typename?: 'ProjectDefualtTodo', id: string, orderNr?: number | null, defaultForAllProjects?: number | null, description?: string | null, estimatedTime?: number | null, topic?: { __typename?: 'Todotopic', id: string } | null } | null } | null> | null } | null } | null };

export type GetTodoTopicsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTodoTopicsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', todotopics?: { __typename?: 'TodotopicConnection', edges?: Array<{ __typename?: 'TodotopicEdge', node?: { __typename?: 'Todotopic', id: string, Name?: string | null, active?: number | null } | null } | null> | null } | null } | null };

export type NewTodoMutationVariables = Types.Exact<{
  todo?: Types.InputMaybe<Types.TodoTypeHyperionMutationInput>;
}>;


export type NewTodoMutation = { __typename?: 'HyperionSchemaMutation', todoTypeHyperionMutation?: { __typename?: 'Todo', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type GetUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, hidden?: number | null, isDeleted?: number | null } | null } | null> | null } | null } | null };

export type AddUserMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['Int']>;
  todoId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AddUserMutation = { __typename?: 'HyperionSchemaMutation', userTodoRelationTypeHyperionMutation?: { __typename?: 'UserTodoRelation', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type RemoveUserMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RemoveUserMutation = { __typename?: 'HyperionSchemaMutation', userTodoRelationTypeHyperionMutation?: { __typename?: 'UserTodoRelation', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type DeleteTopicMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteTopicMutation = { __typename?: 'HyperionSchemaMutation', todotopicTypeHyperionMutation?: { __typename?: 'Todotopic', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type CreateTopicMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateTopicMutation = { __typename?: 'HyperionSchemaMutation', todotopicTypeHyperionMutation?: { __typename?: 'Todotopic', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type EditTopicMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EditTopicMutation = { __typename?: 'HyperionSchemaMutation', todotopicTypeHyperionMutation?: { __typename?: 'Todotopic', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type ProjectDatesQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectDatesQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', startDate?: string | null, endDate?: string | null } | null };

export type CreateDefaultTodoMutationVariables = Types.Exact<{
  description?: Types.InputMaybe<Types.Scalars['String']>;
  type?: Types.InputMaybe<Types.Scalars['Int']>;
  estimatedTime?: Types.InputMaybe<Types.Scalars['Float']>;
  defaultForAllProjects?: Types.InputMaybe<Types.Scalars['Int']>;
  orderNr?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CreateDefaultTodoMutation = { __typename?: 'HyperionSchemaMutation', projectDefualtTodoTypeHyperionMutation?: { __typename?: 'ProjectDefualtTodo', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, errors?: Array<string | null> | null, msg?: string | null } | null> | null } | null };

export type UpdateDefaultTodoMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  description?: Types.InputMaybe<Types.Scalars['String']>;
  type?: Types.InputMaybe<Types.Scalars['Int']>;
  estimatedTime?: Types.InputMaybe<Types.Scalars['Float']>;
  defaultForAllProjects?: Types.InputMaybe<Types.Scalars['Int']>;
  orderNr?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type UpdateDefaultTodoMutation = { __typename?: 'HyperionSchemaMutation', projectDefualtTodoTypeHyperionMutation?: { __typename?: 'ProjectDefualtTodo', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, errors?: Array<string | null> | null, msg?: string | null } | null> | null } | null };

export const EditTodoDocument = gql`
    mutation editTodo($todo: TodoTypeHyperionMutationInput) {
  todoTypeHyperionMutation(updateTodo: $todo) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditTodoGQL extends Apollo.Mutation<EditTodoMutation, EditTodoMutationVariables> {
    document = EditTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProjectTodoDocument = gql`
    query getProjectTodo($id: Int) {
  company {
    todos(id: $id) {
      edges {
        node {
          id
          orderNr
          startDate
          endDate
          description
          estimatedTime
          usersTodoRelation {
            edges {
              node {
                id
                user {
                  id
                  firstName
                  lastName
                  isDeleted
                }
              }
            }
          }
          topic {
            id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectTodoGQL extends Apollo.Query<GetProjectTodoQuery, GetProjectTodoQueryVariables> {
    document = GetProjectTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDefaultTodoDocument = gql`
    query getDefaultTodo($id: Int) {
  company {
    projectDefualtTodos(id: $id) {
      edges {
        node {
          id
          orderNr
          defaultForAllProjects
          description
          estimatedTime
          topic {
            id
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDefaultTodoGQL extends Apollo.Query<GetDefaultTodoQuery, GetDefaultTodoQueryVariables> {
    document = GetDefaultTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTodoTopicsDocument = gql`
    query getTodoTopics {
  company {
    todotopics {
      edges {
        node {
          id
          Name
          active
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTodoTopicsGQL extends Apollo.Query<GetTodoTopicsQuery, GetTodoTopicsQueryVariables> {
    document = GetTodoTopicsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const NewTodoDocument = gql`
    mutation newTodo($todo: TodoTypeHyperionMutationInput) {
  todoTypeHyperionMutation(createTodo: $todo) {
    id
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class NewTodoGQL extends Apollo.Mutation<NewTodoMutation, NewTodoMutationVariables> {
    document = NewTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsersDocument = gql`
    query getUsers {
  company {
    users {
      edges {
        node {
          id
          firstName
          lastName
          hidden
          isDeleted
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsersGQL extends Apollo.Query<GetUsersQuery, GetUsersQueryVariables> {
    document = GetUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddUserDocument = gql`
    mutation addUser($userId: Int, $todoId: Int) {
  userTodoRelationTypeHyperionMutation(
    createUserTodoRelation: {userId: $userId, todoId: $todoId}
  ) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddUserGQL extends Apollo.Mutation<AddUserMutation, AddUserMutationVariables> {
    document = AddUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocument = gql`
    mutation removeUser($id: Int) {
  userTodoRelationTypeHyperionMutation(deleteUserTodoRelation: {id: $id}) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserGQL extends Apollo.Mutation<RemoveUserMutation, RemoveUserMutationVariables> {
    document = RemoveUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTopicDocument = gql`
    mutation deleteTopic($id: Int) {
  todotopicTypeHyperionMutation(updateTodotopic: {id: $id, active: 0}) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTopicGQL extends Apollo.Mutation<DeleteTopicMutation, DeleteTopicMutationVariables> {
    document = DeleteTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTopicDocument = gql`
    mutation createTopic($name: String) {
  todotopicTypeHyperionMutation(createTodotopic: {Name: $name}) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTopicGQL extends Apollo.Mutation<CreateTopicMutation, CreateTopicMutationVariables> {
    document = CreateTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EditTopicDocument = gql`
    mutation editTopic($id: Int, $name: String) {
  todotopicTypeHyperionMutation(updateTodotopic: {id: $id, Name: $name}) {
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class EditTopicGQL extends Apollo.Mutation<EditTopicMutation, EditTopicMutationVariables> {
    document = EditTopicDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProjectDatesDocument = gql`
    query projectDates($projectId: Int) {
  project(id: $projectId) {
    startDate
    endDate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectDatesGQL extends Apollo.Query<ProjectDatesQuery, ProjectDatesQueryVariables> {
    document = ProjectDatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDefaultTodoDocument = gql`
    mutation createDefaultTodo($description: String, $type: Int, $estimatedTime: Float, $defaultForAllProjects: Int, $orderNr: Int) {
  projectDefualtTodoTypeHyperionMutation(
    createProjectDefualtTodo: {description: $description, type: $type, estimatedTime: $estimatedTime, defaultForAllProjects: $defaultForAllProjects, orderNr: $orderNr}
  ) {
    mutationDetails {
      mutationSucceeded
      errors
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDefaultTodoGQL extends Apollo.Mutation<CreateDefaultTodoMutation, CreateDefaultTodoMutationVariables> {
    document = CreateDefaultTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDefaultTodoDocument = gql`
    mutation updateDefaultTodo($id: Int, $description: String, $type: Int, $estimatedTime: Float, $defaultForAllProjects: Int, $orderNr: Int) {
  projectDefualtTodoTypeHyperionMutation(
    updateProjectDefualtTodo: {id: $id, description: $description, type: $type, estimatedTime: $estimatedTime, defaultForAllProjects: $defaultForAllProjects, orderNr: $orderNr}
  ) {
    mutationDetails {
      mutationSucceeded
      errors
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDefaultTodoGQL extends Apollo.Mutation<UpdateDefaultTodoMutation, UpdateDefaultTodoMutationVariables> {
    document = UpdateDefaultTodoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }