<div class="p-grid">
  <div class="p-col-12 p-xxl-4">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-d-flex p-ai-center p-jc-between p-mb-3">
          <h4>Information</h4>
          <div>
            <p-button class="p-button-sm" (click)="toggleProjectSidebar()">
              <i class="p-button-icon pi pi-pencil"></i>
            </p-button>
          </div>
        </div>
      </ng-template>

      <div class="label-value">
        <div>Märkning</div>
        <div>
          <app-initial-icon
            [text]="(projectData | async).mark + (projectData | async).trueId"
          ></app-initial-icon
          >{{ (projectData | async).mark }}
        </div>
      </div>

      <div *ngIf="(projectData | async).isAta" class="label-value">
        <div>ÄTA från projekt</div>
        <div class="p-d-flex p-jc-between">
          <a
            [routerLink]="[
              '../../',
              (projectData | async).mainProject?.id,
              'ata'
            ]"
          >
            {{ (projectData | async).mainProject?.trueId }}
          </a>
        </div>
      </div>

      <div class="label-value">
        <div>Offererat (ex. moms)</div>
        <div>
          {{
            +(projectData | async).offertSum +
              +(projectData | async)?.offertSumWork | formatNum
          }}
          <span *ngIf="(projectData | async).offer"
            >(<a
              href="/v2/offer/index/1?id={{ (projectData | async)?.offer.id }}"
              >Offert: {{ (projectData | async).offer.trueId }}</a
            >)</span
          >
        </div>
      </div>
      <div class="label-value">
        <div>Skapat av</div>
        <div>
          {{ (projectData | async).createdByUser?.firstName }}
          {{ (projectData | async).createdByUser?.lastName }}
        </div>
      </div>
      <div class="label-value">
        <div>Start/Slutdatum</div>
        <div class="p-d-flex p-ai-center">
          <app-project-status-icon
            class="p-mr-2"
            [projectAsync]="projectData"
          ></app-project-status-icon>
          {{ (projectData | async)?.startDate }} -
          {{ (projectData | async)?.endDate }}
        </div>
      </div>
      <div class="label-value">
        <div>Vår Referens</div>
        <div>{{ (projectData | async).madeBy }}</div>
      </div>
      <div class="label-value">
        <div>Kundens Ordernr</div>
        <div>{{ (projectData | async).orderNumber }}</div>
      </div>
    </p-card>
  </div>
  <div class="p-col-12 p-lg-6 p-xxl-4">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-d-flex p-ai-center p-jc-between p-mb-3">
          <h4>Kund</h4>
          <div>
            <p-button class="p-button-sm" (click)="toggleClientSidebar()">
              <i class="p-button-icon pi pi-pencil"></i>
            </p-button>
          </div>
        </div>
      </ng-template>

      <div
        class="label-value"
        *ngIf="(projectData | async).clientContact?.contactType === 'Företag'"
      >
        <div>Företag</div>
        <div>
          <span
            *ngIf="
              (projectData | async).clientContact?.orderBuisnessName &&
              (projectData | async).clientContact?.orgNr
            "
          >
            {{ (projectData | async).clientContact?.orderBuisnessName }} /
            {{ (projectData | async).clientContact?.orgNr }}
          </span>

          <span
            *ngIf="
              (projectData | async).clientContact?.orderBuisnessName &&
              !(projectData | async).clientContact?.orgNr
            "
          >
            {{ (projectData | async).clientContact?.orderBuisnessName }}
          </span>

          <span
            *ngIf="
              !(projectData | async).clientContact?.orderBuisnessName &&
              (projectData | async).clientContact?.orgNr
            "
          >
            {{ (projectData | async).clientContact?.orgNr }}
          </span>
        </div>
      </div>
      <div class="label-value">
        <div>Kontaktperson</div>
        <div>
          {{ (projectData | async).clientContact?.name }}
          <span
            *ngIf="
              (projectData | async).clientContact?.contactType !== 'Företag' &&
              (projectData | async).clientContact?.orgNr
            "
          >
            / {{ (projectData | async).clientContact?.orgNr }}
          </span>
        </div>
      </div>
      <div class="label-value">
        <div>Telefon</div>
        <div>
          <span
            *ngIf="
              (projectData | async).clientContact?.phone &&
              (projectData | async).clientContact?.mobilePhone
            "
          >
            {{ (projectData | async).clientContact?.phone }} /
            {{ (projectData | async).clientContact?.mobilePhone }}
          </span>

          <span
            *ngIf="
              (projectData | async).clientContact?.phone &&
              !(projectData | async).clientContact?.mobilePhone
            "
          >
            {{ (projectData | async).clientContact?.phone }}
          </span>

          <span
            *ngIf="
              !(projectData | async).clientContact?.phone &&
              (projectData | async).clientContact?.mobilePhone
            "
          >
            {{ (projectData | async).clientContact?.mobilePhone }}
          </span>
        </div>
      </div>
      <div class="label-value">
        <div>Adress</div>
        <div>
          <span
            *ngIf="
              (projectData | async).clientContact?.address &&
              (projectData | async).clientContact?.address2
            "
          >
            {{ (projectData | async).clientContact?.address }} /
            {{ (projectData | async).clientContact?.address2 }}
          </span>

          <span
            *ngIf="
              (projectData | async).clientContact?.address &&
              !(projectData | async).clientContact?.address2
            "
          >
            {{ (projectData | async).clientContact?.address }}
          </span>

          <span
            *ngIf="
              !(projectData | async).clientContact?.address &&
              (projectData | async).clientContact?.address2
            "
          >
            {{ (projectData | async).clientContact?.address2 }}
          </span>
          <br />
          {{ (projectData | async).clientContact?.cityCode }}
          {{ (projectData | async).clientContact?.city }}
        </div>
      </div>
      <div class="label-value">
        <div>E-mail</div>
        <div>
          {{ (projectData | async).clientContact?.mail }}<br />
          {{ (projectData | async).clientContact?.mailInvoice }}
        </div>
      </div>
      <div
        *ngIf="
          (projectData | async).clientContact?.contactType === 'Privat' &&
          ((projectData | async).clientContact.propertyName ||
            (projectData | async).clientContact.housingAssociationOrgNumber)
        "
        class="label-value"
      >
        <div>ROT</div>
        <div *ngIf="(projectData | async).clientContact.propertyName">
          {{ (projectData | async).clientContact?.propertyName }}
        </div>
        <div
          *ngIf="
            (projectData | async).clientContact.housingAssociationOrgNumber
          "
        >
          {{ (projectData | async).clientContact?.apartmentDesignation }} /
          {{ (projectData | async).clientContact?.housingAssociationOrgNumber }}
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-12 p-lg-6 p-xxl-4">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-d-flex p-ai-center p-jc-between p-mb-3">
          <h4>Arbetsställe</h4>
          <div>
            <p-button
              class="p-button-sm"
              (click)="toggleEstablishmentSidebar()"
            >
              <i class="p-button-icon pi pi-pencil"></i>
            </p-button>
          </div>
        </div>
      </ng-template>

      <div
        class="label-value"
        *ngIf="
          (projectData | async).establishmentContact?.contactType === 'Företag'
        "
      >
        <div>Företag</div>
        <div>
          <span
            *ngIf="
              (projectData | async).establishmentContact?.orderBuisnessName &&
              (projectData | async).establishmentContact?.orgNr
            "
          >
            {{ (projectData | async).establishmentContact?.orderBuisnessName }}
            /
            {{ (projectData | async).establishmentContact?.orgNr }}
          </span>

          <span
            *ngIf="
              (projectData | async).establishmentContact?.orderBuisnessName &&
              !(projectData | async).establishmentContact?.orgNr
            "
          >
            {{ (projectData | async).establishmentContact?.orderBuisnessName }}
          </span>

          <span
            *ngIf="
              !(projectData | async).establishmentContact?.orderBuisnessName &&
              (projectData | async).establishmentContact?.orgNr
            "
          >
            {{ (projectData | async).establishmentContact?.orgNr }}
          </span>
        </div>
      </div>

      <div class="label-value">
        <div>Kontaktperson</div>
        <div>{{ (projectData | async).establishmentContact?.name }}</div>
      </div>
      <div class="label-value">
        <div>Telefon</div>
        <div>
          <span
            *ngIf="
              (projectData | async).establishmentContact?.phone &&
              (projectData | async).establishmentContact?.mobilePhone
            "
          >
            {{ (projectData | async).establishmentContact?.phone }} /
            {{ (projectData | async).establishmentContact?.mobilePhone }}
          </span>

          <span
            *ngIf="
              (projectData | async).establishmentContact?.phone &&
              !(projectData | async).establishmentContact?.mobilePhone
            "
          >
            {{ (projectData | async).establishmentContact?.phone }}
          </span>

          <span
            *ngIf="
              !(projectData | async).establishmentContact?.phone &&
              (projectData | async).establishmentContact?.mobilePhone
            "
          >
            {{ (projectData | async).establishmentContact?.mobilePhone }}
          </span>
        </div>
      </div>
      <div class="label-value">
        <div>Adress</div>
        <div>
          <span
            *ngIf="
              (projectData | async).establishmentContact?.address &&
              (projectData | async).establishmentContact?.address2
            "
          >
            {{ (projectData | async).establishmentContact?.address }} /
            {{ (projectData | async).establishmentContact?.address2 }}
          </span>

          <span
            *ngIf="
              (projectData | async).establishmentContact?.address &&
              !(projectData | async).establishmentContact?.address2
            "
          >
            {{ (projectData | async).establishmentContact?.address }}
          </span>

          <span
            *ngIf="
              !(projectData | async).establishmentContact?.address &&
              (projectData | async).establishmentContact?.address2
            "
          >
            {{ (projectData | async).establishmentContact?.address2 }}
          </span>
          <br />
          {{ (projectData | async).establishmentContact?.cityCode }}
          {{ (projectData | async).establishmentContact?.city }}
        </div>
      </div>
      <div class="label-value">
        <div>E-mail</div>
        <div>{{ (projectData | async).establishmentContact?.mail }}<br /></div>
      </div>
    </p-card>
  </div>
</div>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="showProjectSidebar"
  position="right"
>
  <app-project-form
    [project]="projectData | async"
    (projectUpdatedEvent)="onProjectUpdated($event)"
  >
  </app-project-form>
</p-sidebar>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="showClientSidebar"
  position="right"
>
  <h1>Redigera kund</h1>
  <app-contact-form
    [context]="contactFormContext.ProjectClient"
    [contactId]="(projectData | async).clientContact?.id"
    (contactUpdatedEvent)="onClientChanged($event)"
  >
  </app-contact-form>
</p-sidebar>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="showEstablishmentSidebar"
  position="right"
>
  <h1>Redigera arbetsställe</h1>
  <app-contact-form
    [context]="contactFormContext.ProjectEstablishment"
    [contactId]="(projectData | async).establishmentContact?.id"
    (contactUpdatedEvent)="onEstablishmentChanged($event)"
  >
  </app-contact-form>
</p-sidebar>
