import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { GlobalService } from '../../../shared/global/index';

import { CompanySuperService } from '../company.super.service';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProjectProductsService extends CompanySuperService {
  dataModel = 'projectProduct';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const mainDataArray = data.company['projects'].edges;
    const dataObjectsForForms = [];

    for (const i in mainDataArray) {
      const projectInfo =
        mainDataArray[i]['node']['trueId'] +
        ', ' +
        mainDataArray[i]['node']['mark'];

      const subDataArray = mainDataArray[i]['node'][this.dataModel + 's'].edges;
      for (const k in subDataArray) {
        const dataObject = { ...subDataArray[i].node };

        if (dataObject['extra'] == null) {
          dataObject['extraText'] = 'Nej';
        } else {
          dataObject['extraText'] = 'Ja';
        }
        dataObject['projectInfo'] = projectInfo;
        dataObjectsForForms.push(dataObject);
      }
    }

    return dataObjectsForForms;
  }

  sortExtra(data) {
    const sortedData = {
      normal: [],
      extra: [],
    };

    for (const dataIndex in data) {
      const dataObject = data[dataIndex];
      if (dataObject['extra'] === '1') {
        sortedData.extra.push(dataObject);
      } else {
        sortedData.normal.push(dataObject);
      }
    }

    return sortedData;
  }

  sortCostType(products, costTypesParam) {
    const costTypesWithChilds = {};

    const projectCostTypesWithName = [];

    for (const projectCostTypeIndex in costTypesParam) {
      const projectCostType = costTypesParam[projectCostTypeIndex];
      projectCostTypesWithName[projectCostType['id']] = {};
      projectCostTypesWithName[projectCostType['id']]['name'] =
        projectCostType['name'];
      projectCostTypesWithName[projectCostType['id']]['procent'] =
        projectCostType['procent'];
      projectCostTypesWithName[projectCostType['id']]['costTypeId'] =
        projectCostType['companyCostTypeId'];
    }

    for (const productIndex in products) {
      const product = products[productIndex];

      const projectCostTypeId = product['projectCostTypeId'];

      if (typeof costTypesWithChilds[projectCostTypeId] === 'undefined') {
        const childsAsyncProperty: BehaviorSubject<any> = new BehaviorSubject(
          []
        );

        costTypesWithChilds[projectCostTypeId] = {
          name: projectCostTypesWithName[projectCostTypeId]['name'],
          procent: projectCostTypesWithName[projectCostTypeId]['procent'],
          type: 'costType',
          toggled: false,
          costTypeSum: 0,
          id: projectCostTypeId,
          costTypeId: projectCostTypesWithName[projectCostTypeId]['costTypeId'],
          childs: [],
        };
        costTypesWithChilds[projectCostTypeId]['childsAsync'] =
          childsAsyncProperty;
      }

      product['procent'] =
        projectCostTypesWithName[projectCostTypeId]['procent'];
      product['parentId'] = projectCostTypeId;
      product['extraBool'] = Number(product['extra']) === 1;
      product['type'] = 'product';
      costTypesWithChilds[projectCostTypeId]['costTypeSum'] +=
        product['totalSum'];
      costTypesWithChilds[projectCostTypeId]['childs'].push(product);
      costTypesWithChilds[projectCostTypeId].childsAsync.next(
        costTypesWithChilds[projectCostTypeId]['childs']
      );
    }

    const returnv = [];
    for (const costTypes in costTypesWithChilds) {
      returnv.push(costTypesWithChilds[costTypes]);
    }

    return returnv;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }
}
