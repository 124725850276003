import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceSelectComponent } from './invoice-select/invoice-select.component';
import { HyperionSharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [InvoiceSelectComponent],
  imports: [CommonModule, HyperionSharedModule],
  exports: [InvoiceSelectComponent],
})
export class InvoiceSelectModule {}
