import { Injectable } from '@angular/core';

@Injectable()
export class UserDayService {
  dataModel = 'day';

  constructor() {}

  dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i of dataArray) {
      const dataObject: {
        project?: any;
        projectInfo?: any;
        extra?: any;
        extraText?: string;
        [key: string]: any;
      } = {};

      for (const key in dataArray[i].node) {
        if (dataArray[i].node.hasOwnProperty(key)) {
          dataObject[key] = dataArray[i].node[key];
        }
      }

      if (
        dataObject.project &&
        dataObject.project.status &&
        dataObject.project.status >= 0 &&
        dataObject.project.status <= 2
      ) {
        dataObject['extraText'] = dataObject.extra == null ? 'Nej' : 'Ja';

        dataObject['projectInfo'] =
          dataObject.project.trueId + ', ' + dataObject.project.mark;

        dataObjectsForForms.push(dataObject);
      }
    }

    return dataObjectsForForms;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }

    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }
}
