<td>
  {{ data['userFullName'] }}
</td>

<td>
  {{ data['created'] }}
</td>
<td [formGroup]="componentMainForm">
  <appForm2-list-input formControlName="doneWork"></appForm2-list-input>
</td>

<td [formGroup]="componentMainForm">
  <appForm2-list-dropDown
    formControlName="userId"
    [dropDownParams]="dropDownOptions['userIdDropDown']"
  ></appForm2-list-dropDown>
</td>
<td [formGroup]="componentMainForm">
  <appForm2-list-dropDown
    formControlName="newCostTypeId"
    [dropDownParams]="dropDownOptions['newCostTypeIdDropDown']"
  ></appForm2-list-dropDown>
</td>
<td [formGroup]="componentMainForm">
  <appForm2-list-dropDown
    formControlName="todoId"
    [dropDownParams]="dropDownOptions['todoIdDropDown']"
  ></appForm2-list-dropDown>
</td>

<td [formGroup]="componentMainForm">
  <appForm2-list-checkbox formControlName="extra"></appForm2-list-checkbox>
</td>

<td [formGroup]="componentMainForm">
  <appForm2-list-input formControlName="hoursToInvoice"></appForm2-list-input>
</td>
<td [formGroup]="componentMainForm">
  <appForm2-list-input formControlName="mileToInvoice"></appForm2-list-input>
</td>

<td [formGroup]="componentMainForm">
  <div #container style="display: inline-block; position: relative">
    <app-button
      *ngIf="!data['savedByUser']['show']"
      [disabled]="tableBtns['row' + data['id']]"
      (onClick)="markRowAsSaved()"
      [type]="'updateNoText'"
    ></app-button>
    <span
      *ngIf="data['savedByUser']['show']"
      tooltipPosition="left"
      [escape]="false"
      [pTooltip]="
        'Sparad av: <br>' +
        data['savedByUser']['savedBy']['firstName'] +
        ' ' +
        data['savedByUser']['savedBy']['lastName'] +
        '<br> Sparad: <br> ' +
        data['savedByUser']['savedBy']['created']
      "
      ><i class="fa fa-save fa-lg"></i>
    </span>
  </div>
</td>
