import { Component, Input } from '@angular/core';
import { DocumentFolder } from '../document-metadata';

@Component({
  selector: 'app-docu-folder-link',
  templateUrl: './docu-folder-link.component.html',
  styleUrls: ['./docu-folder-link.component.scss'],
})
export class DocuFolderLinkComponent {
  @Input() docuRoot: string;
  @Input() folder: DocumentFolder;
}
