import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { EditorModule } from 'primeng/editor';
import { SharedModule } from 'primeng/api';

import { ListCheckBox2Component } from './list/check-box.component';
import { ListFormCalendar2Component } from './list/calender.component';
import { ListFormDropDown2Component } from './list/dropdown.component';
import { ListFormTextarea2Component } from './list/textarea.component';
import { ListFormTextEditor2Component } from './list/texteditor.component';
import { SimpleFormList2Component } from './list/input.component';

import { SimpleFormCalendar2Component } from './simple/calender.component';
import { SimpleFormDropDown2Component } from './simple/dropdown.component';
import { SimpleFormInput2Component } from './simple/input.component';
import { SimpleFormListCheckBox2Component } from './simple/check-box.component';
import { SimpleFormTextarea2Component } from './simple/textarea.component';

import { WideCheckBox2Component } from './wide/check-box.component';
import { WideFormCalendar2Component } from './wide/calender.component';
import { WideFormDropDown2Component } from './wide/dropdown.component';
import { WideFormInput2Component } from './wide/input.component';
import { WideFormTextarea2Component } from './wide/textarea.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CheckboxModule,
    DropdownModule,
    FileUploadModule,
    InputTextareaModule,
    InputTextModule,
    MessagesModule,
    MultiSelectModule,
    OverlayPanelModule,
    PanelModule,
    SharedModule,
    TooltipModule,
    ToggleButtonModule,
    SplitButtonModule,
    EditorModule,
  ],
  declarations: [
    SimpleFormDropDown2Component,
    WideFormDropDown2Component,
    ListFormDropDown2Component,

    SimpleFormInput2Component,
    WideFormInput2Component,

    SimpleFormTextarea2Component,
    WideFormTextarea2Component,
    ListFormTextarea2Component,

    SimpleFormCalendar2Component,
    WideFormCalendar2Component,
    ListFormCalendar2Component,

    SimpleFormList2Component,

    SimpleFormListCheckBox2Component,

    WideCheckBox2Component,

    ListFormTextEditor2Component,

    ListCheckBox2Component,
  ],
  exports: [
    SimpleFormDropDown2Component,
    WideFormDropDown2Component,
    ListFormDropDown2Component,

    SimpleFormInput2Component,
    WideFormInput2Component,

    SimpleFormTextarea2Component,
    WideFormTextarea2Component,
    ListFormTextarea2Component,

    SimpleFormCalendar2Component,
    WideFormCalendar2Component,
    ListFormCalendar2Component,

    SimpleFormList2Component,

    SimpleFormListCheckBox2Component,

    WideCheckBox2Component,

    ListFormTextEditor2Component,

    ListCheckBox2Component,
  ],
})
export class ReusableForm2Module {}
