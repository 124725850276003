import { Config } from '../config';
import { config as defaultConfig } from './default.config';

export const config: Config = {
  ...defaultConfig,
  name: 'bmed',
  subdomain: ['bmed'],
  title: 'bmed',
  theme: {
    ...defaultConfig.theme,
    logoPath: '/images/site/bmedLogo.png',
    loginBackground: '/images/hyperion/bmedBg.jpg',
    brandPrimaryColor: '#5bc2ee',
    brandSecondaryColor: '#e7e8e5',
    topMenuBackgroundColor: '#5bc2ee',
    buttonPrimaryColor: '#5bc2ee',
  },
};
