<div>
  <form
    class="p-grid"
    [formGroup]="componentMainForm"
    (keyup.enter)="actionUpdate()"
  >
    <div
      class="p-col-12"
      [class.p-lg-2]="hasAccountingPlanFunction"
      [class.p-lg-4]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        formControlName="name"
        [disabled]="!userCostType.active"
        [id]="componentPrefix + 'companyUserCostType_name_' + userCostType.id"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        [disabled]="!userCostType.active"
        formControlName="cost"
        [id]="componentPrefix + 'companyUserCostType_cost_' + userCostType.id"
      />
    </div>
    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <input
        pInputText
        [disabled]="!userCostType.active"
        formControlName="internalCost"
        [id]="
          componentPrefix +
          'companyUserCostType_internalCost_' +
          userCostType.id
        "
      />
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <p-dropdown
        [filter]="true"
        [disabled]="!userCostType.active"
        [ngModel]="userCostType.accountingPlanAccountId"
        formControlName="accountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <p-dropdown
        [filter]="true"
        [disabled]="!userCostType.active"
        [ngModel]="userCostType.reverseVatAccountingPlanAccountId"
        formControlName="reverseVatAccountingPlanAccount"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div class="p-col-6 p-lg-1" *ngIf="userCostType.active">
      <button
        pButton
        class="p-button-icon-danger"
        [disabled]="buttons.delete.disabled || userCostType.type === 'isWork'"
        (click)="confirmActionDelete()"
        icon="pi pi-trash"
      ></button>
    </div>
    <div class="p-col-6 p-lg-1" *ngIf="userCostType.active">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.update.disabled"
        (click)="actionUpdate()"
        icon="pi pi-save"
      ></button>
    </div>
    <div class="p-col-6 p-lg-2" *ngIf="!userCostType.active">
      <button
        pButton
        [disabled]="buttons.update.disabled"
        (click)="actionActivate()"
        label="Aktivera"
      ></button>
    </div>
  </form>
</div>
