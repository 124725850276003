import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { ContactSelectComponent } from './contact-select/contact-select.component';
import { ContactListComponent } from './contact-list/contact-list.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';
import { DividerModule } from 'primeng/divider';
import { BrowserModule } from '@angular/platform-browser';
import { HyperionSharedModule } from '../shared/shared.module';
import { CompanyProjectTypeModule } from '../settings/company/company-project-type/company-project-type.module';
import { EditorModule } from 'primeng/editor';
import { UserSettingsModule } from '../registry/users/user-settings.module';
import { SharedDialogModule } from '../shared/dialogs/shared.dialog.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { HeaderModule } from '../header/header.module';
import { TableModule as PTableModule } from 'primeng/table';
import { ContactInvoicesComponent } from './contact-detail/contact-invoices/contact-invoices.component';
import { ContactProjectsComponent } from './contact-detail/contact-projects/contact-projects.component';
import { TableModule } from '../shared/table/table.module';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  declarations: [
    ContactFormComponent,
    ContactSelectComponent,
    ContactListComponent,
    ContactDetailComponent,
    ContactProjectsComponent,
    ContactInvoicesComponent,
  ],
  imports: [
    AutoCompleteModule,
    CommonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    CheckboxModule,
    ButtonModule,
    DividerModule,
    BrowserModule,
    HyperionSharedModule,
    CompanyProjectTypeModule,
    EditorModule,
    PTableModule,
    TableModule,
    UserSettingsModule,
    SharedDialogModule,
    RadioButtonModule,
    HeaderModule,
    TableModule,
    SidebarModule,
    SplitButtonModule,
  ],
  exports: [
    ContactFormComponent,
    ContactSelectComponent,
    ContactListComponent,
    ContactDetailComponent,
    ContactInvoicesComponent,
    ContactProjectsComponent,
  ],
})
export class ContactModule {}
