import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserLocalStorageService } from 'app/shared/user';
import {
  FetchProjectProductCompanyCostTypesGQL,
  FetchProjectProductGQL,
  FetchProjectProductUsersGQL,
} from './graphql/project-product.query.generated';
import { ProjectCostType, Projectproduct } from 'generated/types';
import moment from 'moment';
import { concat, first } from 'rxjs';
import {
  CreateProjectproductGQL,
  CreateProjectproductMutation,
  UpdateProjectproductGQL,
} from './graphql/project-product.mutation.generated';
import { MessageService } from 'app/shared/message';
import { MutationResult } from 'apollo-angular';
import { UserFlagsService } from 'app/user-flags.service';
import { ProductDetailsService } from 'app/shared/product-details/product-details.service';
import { DropdownSelectListItem } from 'app/shared/dropdown-select/dropdown-select';

interface DropdownItem {
  name: string;
  value: number;
}

@Component({
  selector: 'app-project-product-form',
  templateUrl: './project-product-form.component.html',
  styleUrls: ['./project-product-form.component.scss'],
})
export class ProjectProductFormComponent implements OnChanges {
  public productsForm: FormGroup;
  public users: DropdownItem[];
  public costTypes: DropdownItem[] = [];
  public isEdit = false;
  private projectProductData: Projectproduct = { id: null };
  public onlySearchForOwn = false;
  public isDerome: boolean;

  @Input() public isEmbedded = false;

  @Input() public set isExtra(isExtra: boolean) {
    this.projectProductData.extra = isExtra ? '1' : '0';
    if (this.productsForm && this.products.length === 1) {
      this.products[0].patchValue({
        extra: this.projectProductData.extra === '1',
      });
    }
  }
  @Input() public projectId: number;
  @Input() public set projectProductId(projectProductId: number) {
    if (!projectProductId) {
      this.projectProductData = {
        id: null,
        extra: this.projectProductData.extra,
      };
      this.isEdit = false;
      return;
    }

    this.isEdit = true;

    this.fetchProjectProductGQL
      .fetch({ projectId: this.projectId, productId: projectProductId })
      .pipe(first())
      .subscribe(({ data }) => {
        const [{ node: productData }] = data.project.projectCostTypes.edges
          .map(({ node }) =>
            node.products.edges.filter(
              ({ node }) => Number(node.id) === projectProductId
            )
          )
          .filter(node => node.length)
          .flat();

        this.projectProductData = this.mapProjectProductWithCompanyCostType(
          productData,
          data.project.projectCostTypes.edges.map(
            ({ node }) => node
          ) as ProjectCostType[]
        );

        this.initForm();
      });
  }

  @Output() public projectProductUpdatedEvent: EventEmitter<number> =
    new EventEmitter<number>();

  constructor(
    private userLocalStorage: UserLocalStorageService,
    private fetchProjectProductUsersGQL: FetchProjectProductUsersGQL,
    private fetchProjectProductCompanyCostTypesGQL: FetchProjectProductCompanyCostTypesGQL,
    private fetchProjectProductGQL: FetchProjectProductGQL,
    private createProjectProductGQL: CreateProjectproductGQL,
    private updateProjectProductGQL: UpdateProjectproductGQL,
    private messageService: MessageService,
    private userFlagsService: UserFlagsService,
    private productDetailsService: ProductDetailsService
  ) {}

  public ngOnChanges(): void {
    this.initForm();

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.isDerome = flags.isWhitelabelDerome;
      });

    this.fetchProjectProductUsersGQL
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        this.users = data.company.users.edges.map(({ node }) => ({
          name: `${node.firstName} ${node.lastName}`,
          value: Number(node.id),
        }));
      });

    this.fetchProjectProductCompanyCostTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        const companyCostTypes = data.company.companyCostTypes.edges
          .filter(({ node }) => node.active)
          .map(({ node }) => ({
            name: node.name,
            value: Number(node.id),
          }));

        this.costTypes = companyCostTypes;
      });
  }

  public get productsToAdd(): Projectproduct[] {
    return this.productsForm.value.products;
  }

  private productFormGroup(): FormGroup {
    return new FormGroup({
      artnr: new FormControl(this.projectProductData.artnr),
      benamning: new FormControl(this.projectProductData.benamning, [
        Validators.required,
      ]),
      avtalspris: new FormControl(this.projectProductData.avtalspris, []),
      avtalsprisCost: new FormControl(
        this.projectProductData.avtalsprisCost,
        []
      ),
      date: new FormControl(
        this.projectProductData.date || moment().format('YYYY-MM-DD')
      ),
      enhet: new FormControl(this.projectProductData.enhet),
      antal: new FormControl(this.projectProductData.antal),
      companyCostTypeId: new FormControl(
        Number(this.projectProductData.companyCostTypeId) ||
          (this.costTypes.length && this.costTypes[0].value)
      ),
      userId: new FormControl(
        Number(this.projectProductData.userId) ||
          Number(this.userLocalStorage.getMEUser().id)
      ),
      extra: new FormControl(this.projectProductData.extra === '1'),
      projectId: new FormControl(Number(this.projectId)),
      source: new FormControl(this.projectProductData.source),
      sourceId: new FormControl(Number(this.projectProductData.sourceId)),
    });
  }

  public addNewProductToAdd(): void {
    const newProduct = this.productFormGroup();
    const lastProduct = this.products.at(-1);
    if (lastProduct) {
      newProduct.patchValue({
        userId: lastProduct.value.userId,
        date: lastProduct.value.date,
        companyCostTypeId: lastProduct.value.companyCostTypeId,
        projectId: lastProduct.value.projectId,
      });
    }
    this.products.push(newProduct);
  }

  public removeNewProductToAdd(i: number): void {
    this.products.removeAt(i);

    if (this.products.length < 1 && !this.isEmbedded) {
      this.products.push(this.productFormGroup());
    }
  }

  public resetForm(): void {
    this.initForm();
  }

  private initForm(): void {
    this.productsForm = new FormGroup({
      products: new FormArray(this.isEmbedded ? [] : [this.productFormGroup()]),
    });
  }

  public get products(): FormArray {
    return this.productsForm.get('products') as FormArray;
  }

  public selectProduct(product: any, i: number): void {
    this.products.controls[i].patchValue({
      ...product,
      companyCostTypeId: Number(product.companyCostTypeId),
    });

    // Prices for derome-api products are handled in backend after saving
    if (product.source === 'derome-api') {
      this.productDetailsService
        .getProductDetails(product.source, product.sourceId, undefined, {
          projectId: this.projectId || null,
        })
        .pipe(first())
        .subscribe(productDetails => {
          this.products.controls[i].patchValue({
            avtalsprisCost: productDetails.netPrice,
            enhet: productDetails.salesPriceUnit,
            avtalspris: productDetails.salesPrice,
            antal: productDetails.quantity,
            benamning: productDetails.name,
          });
        });
      this.products.controls[i].get('avtalsprisCost').disable();
      this.products.controls[i].get('avtalspris').disable();
    }
  }

  public onSubmit(): void {
    if (this.isEmbedded) {
      return;
    }

    if (this.isEdit) {
      this.updateProjectProductGQL
        .mutate({
          updateProjectproduct: {
            ...this.productsForm.value.products[0],
            id: this.projectProductData.id,
            extra: this.productsForm.value.products[0].extra ? '1' : '0',
            antal: this.productsForm.value.products[0].antal ?? 1,
            totalAmount:
              this.productsForm.value.products[0].antal ??
              1 * this.productsForm.value.products[0].fpris,
          },
        })
        .pipe(first())
        .subscribe(res => {
          const id = Number(
            res.data.projectproductTypeHyperionMutation.projectCostTypeId
          );
          this.projectProductUpdatedEvent.emit(id);
          this.initForm();
          const success =
            res.data.projectproductTypeHyperionMutation.mutationDetails.every(
              md => md.mutationSucceeded
            );
          const projectChanged =
            res.data.projectproductTypeHyperionMutation.projectId !==
            this.projectId;

          if (projectChanged && success) {
            this.messageService.insertData({
              textArray: ['Artikeln flyttades.'],
              type: 'success',
            });
          } else {
            this.afterMutation(res);
          }
        });

      return;
    }

    concat(
      ...this.productsForm.value.products.map((product: Projectproduct) => {
        return this.createProjectProductGQL
          .mutate({
            createProjectproduct: {
              ...product,
              id: undefined,
              extra: product.extra ? '1' : '0',
              validateOnHyperionMutation: false,
              antal: product.antal ?? 1,
            },
          })
          .pipe(first());
      })
    ).subscribe({
      next: (result: MutationResult<CreateProjectproductMutation>) => {
        this.afterMutation(result);
        const costTypeId = Number(
          result.data.projectproductTypeHyperionMutation.projectCostTypeId
        );
        this.projectProductUpdatedEvent.emit(costTypeId);
      },
      complete: () => this.initForm(),
    });
  }

  private mapProjectProductWithCompanyCostType(
    productData: Projectproduct,
    projectCostTypes: ProjectCostType[]
  ): Projectproduct {
    const companyCostTypeId = Number(
      projectCostTypes.find(
        projectCostType =>
          Number(projectCostType.id) === productData.projectCostTypeId
      )?.companyCostTypeId
    );

    return { ...productData, companyCostTypeId };
  }

  private afterMutation(
    result: MutationResult<CreateProjectproductMutation>
  ): void {
    const mutationData = result.data.projectproductTypeHyperionMutation;

    if (!mutationData) {
      return;
    }

    this.messageService.insertDataFromMutation(mutationData);
  }

  public syncCosts(productForm: FormGroup): void {
    const avtalsprisCost = productForm.value.avtalsprisCost;
    const avtalspris = productForm.value.avtalspris;

    if (avtalspris && !avtalsprisCost) {
      productForm.patchValue({
        avtalsprisCost: avtalspris,
      });
    }
    if (!avtalspris && avtalsprisCost) {
      productForm.patchValue({
        avtalspris: avtalsprisCost,
      });
    }
  }

  public actionChangeProject(
    project: DropdownSelectListItem,
    product: FormGroup
  ): void {
    product.controls.projectId.setValue(Number(project.id));
  }
}
