import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UserFlagsService } from 'app/user-flags.service';
import { Product } from 'generated/types';
import { AutoComplete } from 'primeng/autocomplete';
import { first } from 'rxjs';

const LIMIT = 50;

@Component({
  selector: 'app-product-search',
  templateUrl: './product-search.component.html',
  styleUrls: ['./product-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ProductSearchComponent,
      multi: true,
    },
  ],
})
export class ProductSearchComponent implements ControlValueAccessor, OnInit {
  public searchProductsSuggestions: Product[];
  @Input() public onlySearchForOwn = false;
  @Input() public placeholder = 'Sök produkt';

  @Input() public formControlName: string;
  @Input() public formControl: FormControl<any>;
  @Input() public autofocus = false;
  @Input() public appendTo = '';
  public showPrices = true;

  @Output() public productSelected = new EventEmitter();

  @ViewChild('autoComplete', { static: true })
  public autocomplete: AutoComplete;

  constructor(
    private http: HttpClient,
    private controlContainer: ControlContainer,
    private userFlagsService: UserFlagsService
  ) {}

  public ngOnInit(): void {
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => (this.showPrices = !flags.isWhitelabelDerome));
  }

  public get control(): AbstractControl<any> {
    return (
      this.formControl ||
      this.controlContainer.control.get(this.formControlName)
    );
  }

  public writeValue(obj: any): void {
    this.autocomplete.writeValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.autocomplete.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.autocomplete.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.autocomplete.setDisabledState(isDisabled);
  }

  public searchProducts(event: any): void {
    const stored = this.onlySearchForOwn ? 1 : 0;

    this.http
      .get<Product[]>('/product/AutoComplete', {
        params: { q: event.query, limit: LIMIT, stored: stored },
      })
      .pipe(first())
      .subscribe(res => {
        this.searchProductsSuggestions = res;
      });
  }

  public actionSelect(event: any): void {
    this.productSelected.emit(event);
  }
}
