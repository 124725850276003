import { Component, OnInit } from '@angular/core';
import { MessageService } from 'app/shared/message';
import { HttpService } from 'app/shared/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'add-subcontractor-to-project',
  templateUrl: './add-subcontractor-to-project.component.html',
})
export class AddSubcontractorToProjectComponent implements OnInit {
  mutationData;
  public parentId: any;
  public projectInfo: any = {};
  public subcontractorInfo: any = {};
  mainContractorWhantsTimereports = true;
  mainContractorWhantsToPutTodos = true;

  constructor(
    private messageService: MessageService,
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initComponentFrmDialogData();
  }

  initComponentFrmDialogData() {
    this.subcontractorInfo = this.dialogConfig['data']['subcontractorInfo'];
    this.projectInfo = this.dialogConfig['data']['projectInfo'];
    this.parentId = this.dialogConfig['data']['parentId'];
    setTimeout(() => {
      this.dialogConfig.header = 'Lägg till i projekt';
    }, 0);
  }

  actionUpdate() {
    const url = '/attendanceReport/AddSubcontractorToProject';

    const params = {
      subcontractorId: Number(this.subcontractorInfo.id),
      projectId: Number(this.projectInfo.id),
      parentId: this.parentId,
      clientProject: Number(this.projectInfo.clientProject),
      attendance: Number(this.projectInfo.attendance),
      mainContractorWhantsTimereports: this.mainContractorWhantsTimereports,
      mainContractorWhantsToPutTodos: this.mainContractorWhantsToPutTodos,
    };

    this.httpService.makeHttpPostRequest(url, params).subscribe(data => {
      if (data.status === 'success') {
        const mutationData = { model: data.model, rel: data.rel };
        this.mutationData = mutationData;
        this.closeAction();
      }
      this.messageService.insertData({
        textArray: [data.msg],
        time: 2000,
        type: data.status,
      });
    });
  }

  closeAction() {
    this.dialogRef.close(this.mutationData);
  }
}
