import { Injectable } from '@angular/core';

@Injectable()
export class ProjectCalendarService {
  private calendarIsInitialized = false;

  setCalendarInit() {
    this.calendarIsInitialized = true;
  }

  getCalendarInit() {
    return this.calendarIsInitialized;
  }
}
