import { ApolloError } from '@apollo/client/core';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HelperService } from '../helpers/index';
import { environment } from '../../../environments/environment';
import { SelectItem } from 'primeng/api';

/*
    Holds and returns reccuring constants in the app // PANI
*/

export const modelParams = {
  project: {
    status: {
      CONST: {
        PLANNED: 0,
        ONGOING: 1,
        FINISHED: 2,
        ARCHIVED: 3,
        DELETED: 4,
        LEAVE: 5,
        INHOUSE: 6,
      },
      strings: [
        {
          0: ['planerat', 'planerade', 'planerade'], // en, bestämd, flera
          1: ['Planerat', 'Planerade', 'Planerade'], // en, bestämd, flera
        },
        {
          0: ['pågående', 'pågående', 'pågående'],
          1: ['Pågående', 'Pågående', 'Pågående'],
        },
        {
          0: ['avslutat', 'avslutade', 'avslutade'],
          1: ['Avslutat', 'Avslutade', 'Avslutade'],
        },
        {
          0: ['arkiverat', 'arkiverade', 'arkiverade'],
          1: ['Arkiverat', 'Arkiverade', 'Arkiverade'],
        },
        {
          0: ['raderat', 'raderade', 'raderade'],
          1: ['Raderat', 'Raderade', 'Raderade'],
        },
        {
          0: ['frånvaro', 'frånvaro', 'frånvaro'],
          1: ['Frånvaro', 'Frånvaro', 'Frånvaro'],
        },
        {
          0: ['internt', 'interna', 'interna'],
          1: ['Internt', 'Interna', 'Interna'],
        },
      ],
    },
  },
  day: {
    extra: {
      CONST: {
        IS_NORMAL: 0,
        IS_EXTRA: 1,
      },
    },
  },
  offer: {
    status: {
      CONST: {
        DRAFT: 0,
        ONGOING: 1,
        ARCHIVED: 2,
      },
      strings: [
        {
          0: ['utkast för', 'utkast för', 'utkast för'],
          1: ['Utkast för', 'Utkast för', 'Utkast för'],
        },
        {
          0: ['skapat', 'skapade', 'skapade'],
          1: ['Skapat', 'Skapade', 'Skapade'],
        },
        {
          0: ['arkiverat', 'arkiverade', 'arkiverade'],
          1: ['Arkiverat', 'Arkiverade', 'Arkiverade'],
        },
      ],
    },
  },
};

export enum ERROR_TYPES {
  UNKNOWN,
  AUTH_ERROR,
  UNAVAILABLE,
}

/*
    Locales for calendar
*/

const locale = {
  sv: {
    firstDayOfWeek: 1,
    dayNames: [
      'Söndag',
      'Måndag',
      'Tisdag',
      'Onsdag',
      'Torsdag',
      'Fredag',
      'Lördag',
    ],
    dayNamesShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
    dayNamesMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
    monthNames: [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Maj',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec',
    ],
    weekHeader: 'V.',
  },
};

const emptyDataLabel = 'Inga poster';

const skraOptions = [
  { label: 'Bygg', value: 'Bygg' },
  { label: 'El', value: 'El' },
  { label: 'GlasPlatarbete', value: 'GlasPlatarbete' },
  { label: 'MarkDraneringsarbete', value: 'MarkDraneringarbete' },
  { label: 'Murning', value: 'Murning' },
  { label: 'MalningTapetsering', value: 'MalningTapetsering' },
  { label: 'Vvs', value: 'Vvs' },
];

const formSettings = {
  debounceTime: 1000,
};

/*
    Pagination
*/
const paginationSettings = {
  rows: 50,
  rowsOptions: [25, 50, 100, 150],
};

const taxOptions = [
  { label: '0', value: 0 },
  { label: '6', value: 6 },
  { label: '12', value: 12 },
  { label: '25', value: 25 },
];

const greenTaxOptions: SelectItem[] = [
  { label: 'Ej husarbete', value: null, icon: 'fa fa-ban' },
  { label: 'Solceller', value: 'Solceller', icon: 'fa fa-sun-o' },
  {
    label: 'Lagring egenproducerad el',
    value: 'LagringEgenproduceradEl',
    icon: 'fa fa-battery-full',
  },
  {
    label: 'Laddningspunkt elfordon',
    value: 'LaddningspunktElfordon',
    icon: 'fa fa-plug',
  },
];

/*
    Compiler configuation
*/

const urlPrefix = environment.urlPrefix; // <-- redigera denna

@Directive()
@Injectable()
export class GlobalService {
  @Output()
  toggleNavBarLoadingFromService: EventEmitter<boolean> = new EventEmitter();

  constructor(private helperService: HelperService) {}

  toggleNavBarLoading(value = null) {
    this.toggleNavBarLoadingFromService.emit(value);
  }

  getModelParams(model) {
    return modelParams[model];
  }

  getLocale(model) {
    return locale[model];
  }

  getPaginationSettings() {
    return paginationSettings;
  }

  getSkraOptions() {
    return skraOptions;
  }

  getTaxOptions() {
    return taxOptions;
  }

  public getGreenTaxOptions(): SelectItem[] {
    return greenTaxOptions;
  }

  getUrlPrefix() {
    return urlPrefix;
  }

  getAppUrlPrefix() {
    return `${urlPrefix}/v2`;
  }

  getFormSettings() {
    return formSettings;
  }

  getEmptyDataLabel() {
    return emptyDataLabel;
  }

  /**
   * TODO: Investigate why we are using this, I cannot see any good reason for it.
   */
  public generateComponentPrefix(): number {
    return Math.floor(Math.random() * 100000 + 1);
  }

  capitalizeFirstLetter(stringParam) {
    const capitalizedString =
      this.helperService.upperCaseFirstLetter(stringParam);
    return capitalizedString;
    // return string.charAt(0).toUpperCase() + string.slice(1);
  }

  constructSearchableString(dataArrayParam, searchKeys) {
    for (const index in dataArrayParam) {
      const object = dataArrayParam[index];
      object['show'] = true;
      object['dataSearch'] = '';

      for (const key in searchKeys) {
        const keyName = searchKeys[key];
        if (object.hasOwnProperty(keyName)) {
          object['dataSearch'] += (object[keyName] + ' ').toLowerCase();
        }
      }
    }

    return dataArrayParam;
  }

  public identifyErrorCode(error: ApolloError | any): ERROR_TYPES {
    const SUPPORTED_HTTP_CODES = {
      UNAUTHORISED: 401,
      SERVICE_UNAVAILABLE: 503,
    };

    const status = this.getHttpStatus(error);

    switch (status) {
      case SUPPORTED_HTTP_CODES.UNAUTHORISED:
        return ERROR_TYPES.AUTH_ERROR;
      case SUPPORTED_HTTP_CODES.SERVICE_UNAVAILABLE:
        return ERROR_TYPES.UNAVAILABLE;
      default:
        return ERROR_TYPES.UNKNOWN;
    }
  }

  /**
   * Attempts to extract the HTTP status code from a multitude of different error objects
   * @param error the error object
   * @returns the HTTP status code
   */
  private getHttpStatus(error: any): number {
    if (error.status) {
      return error.status;
    }

    if (error.networkError && error.networkError.status) {
      return error.networkError.status;
    }

    if (error.rejection && error.rejection.status) {
      return error.rejection.status;
    }

    return -1;
  }

  notAllowedNull(notAllowedNullObject = {}, component) {
    let hasNullValue = false;
    const variablesWithNull = [];

    for (const key in notAllowedNullObject) {
      const instance = notAllowedNullObject[key];

      if (typeof instance !== 'object') {
        if (instance === null) {
          hasNullValue = true;
          variablesWithNull.push(instance);
        }
      } else {
        this.notAllowedNull(instance, component);
      }
    }

    // if null, error throw
    if (hasNullValue) {
      const errorMessage =
        'NotAllowedNull Error in ' +
        component.name +
        ' on following instances: ';
      for (const index in variablesWithNull) {
        // errorMessage += "";
        // if(){
        // }
      }

      const err = new Error(errorMessage);
      throw err;
    }
  }
}
