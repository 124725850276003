import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CompanyAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanyAccountsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', trakHalfDayAccountingPlanAccountId?: string | null, reverseVatTrakHalfDayAccountingPlanAccountId?: string | null, trakNightAccountingPlanAccountId?: string | null, reverseVatTrakNightAccountingPlanAccountId?: string | null, trakDayAccountingPlanAccountId?: string | null, reverseVatTrakDayAccountingPlanAccountId?: string | null, companyMilageForCustomerAccountingPlanAccountId?: string | null, reverseVatCompanyMilageForCustomerAccountingPlanAccountId?: string | null } | null };

export const CompanyAccountsDocument = gql`
    query companyAccounts {
  company {
    trakHalfDayAccountingPlanAccountId
    reverseVatTrakHalfDayAccountingPlanAccountId
    trakNightAccountingPlanAccountId
    reverseVatTrakNightAccountingPlanAccountId
    trakDayAccountingPlanAccountId
    reverseVatTrakDayAccountingPlanAccountId
    companyMilageForCustomerAccountingPlanAccountId
    reverseVatCompanyMilageForCustomerAccountingPlanAccountId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyAccountsGQL extends Apollo.Query<CompanyAccountsQuery, CompanyAccountsQueryVariables> {
    document = CompanyAccountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }