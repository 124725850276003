<div class="p-grid">
  <div class="p-col-12 p-lg-6">
    <label>Kostnader</label>
    <p-table
      class="inline-table"
      [value]="costs?.productTypes"
      [dataKey]="'name'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Benämning</th>
          <th>Antal</th>
          <th>Inköpspris</th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <a [pRowToggler]="rowData">
              {{ rowData.name }}
            </a>
          </td>
          <td colspan="2"></td>
          <td>{{ rowData.cost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data>
        <tr *ngFor="let material of data.products">
          <td>{{ material.name }}</td>
          <td>{{ material.amount }}</td>
          <td>{{ material.price | currency: 'SEK' }}</td>
          <td>{{ material.totalCost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-12 p-lg-6">
    <label>Normal/Extra</label>
    <p-table
      class="inline-table"
      [value]="costs.byExtraNormal"
      [dataKey]="'name'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Benämning</th>
          <th colspan="2"></th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <a [pRowToggler]="rowData">
              {{ rowData.name }}
            </a>
          </td>
          <td colspan="2"></td>
          <td>{{ rowData.cost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data>
        <tr>
          <td class="p-p-2" colspan="4">
            <p-table
              class="inner-table"
              [value]="data.productTypes"
              [dataKey]="'name'"
            >
              <ng-template pTemplate="header">
                <tr>
                  <th>Benämning</th>
                  <th>Antal</th>
                  <th>Inköpspris</th>
                  <th>Kostnad</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td>
                    <a [pRowToggler]="rowData">
                      {{ rowData.name }}
                    </a>
                  </td>
                  <td></td>
                  <td></td>
                  <td>{{ rowData.cost | currency: 'SEK' }}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="rowexpansion" let-data>
                <tr *ngFor="let material of data.products">
                  <td>{{ material.name }}</td>
                  <td>{{ material.amount }}</td>
                  <td>{{ material.price | currency: 'SEK' }}</td>
                  <td>{{ material.totalCost | currency: 'SEK' }}</td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
