import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/shared/global';
import { HttpService } from 'app/shared/http';
import { Message } from 'primeng/api';
import { Subject, first } from 'rxjs';
import { UserFlagsService } from 'app/user-flags.service';

@Component({
  selector: 'app-visma-eaccounting-integration',
  templateUrl: './visma-eaccounting.component.html',
  styleUrls: ['./visma-eaccounting.component.scss'],
})
export class VismaEAccountingComponent implements OnInit {
  public authenticationUrl: string;
  public appName = localStorage.getItem('appname');
  public state: IVismaConfigurationState | undefined;
  public isLoading: boolean;
  public messages: Subject<Message[]> = new Subject();
  public canConnect = true;
  public isWhitelabel = false;

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService,
    private userFlagsService: UserFlagsService
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.isWhitelabel =
          flags.isWhitelabelDerome || flags.isWhitelabelBeijerBygg;
      });

    this.getCurrentStateAsync().then(state => {
      this.state = state;
      if (state.isConfigured) {
        this.isLoading = false;
      } else {
        const urlParam = `${this.globalService.getUrlPrefix()}/VismaEAccounting/GetConfig`;
        this.httpService.makeHttpGetRequest(urlParam).then(config => {
          this.authenticationUrl = config.data.visma_auth_url;
          this.isLoading = false;
        });
      }

      if (this.state.isConfigured && !this.state.isEnabled) {
        this.messages.next([
          {
            severity: 'success',
            summary: 'Kopplingen lyckades',
            detail: 'Kontakta supporten för att aktivera integrationen',
          },
        ]);
      } else if (this.state.isConfigured && this.state.isEnabled) {
        this.messages.next([
          {
            severity: 'success',
            summary: 'Kopplingen klar',
            detail:
              'Kopplingen mot Visma e-Ekonomi är nu uppsatt. Vänligen kontakta supporten för att slutföra installationen. Vi hjälper dig med det sista steget för att du ska kunna börja arbeta med kopplingen.',
          },
        ]);
      }

      if (this.isWhitelabel) {
        this.canConnect = false;

        this.messages.next([
          {
            severity: 'info',
            summary: 'Kontakta support',
            detail:
              'Ifall du vill sätta upp en integration till Visma eEkonomi, vänligen kontakta support.',
          },
        ]);
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('success')) {
      if (urlParams.get('success') === '0') {
        const error = urlParams.has('error') && urlParams.get('error');
        this.handleError(error);
      }
    }
  }

  public authenticate(): void {
    window.location.href = this.authenticationUrl;
  }

  private async getCurrentStateAsync(): Promise<IVismaConfigurationState> {
    const url = `${this.globalService.getUrlPrefix()}/VismaEAccounting/GetState`;
    const response = await this.httpService.makeHttpGetRequest(url);
    return response.data as IVismaConfigurationState;
  }

  private handleError(error: string): void {
    let details;
    let severity = 'warn';
    switch (error) {
      case VismaOAuthErrors.AccessDenied:
        details =
          'Kopplingen fick inga behörigheter. För att integrationen ska fungera krävs att behörigheterna som vi ber om godkänns. <br><br>Vänligen försök igen och markera alla behörigheter som presenteras.';
        break;
      case VismaOAuthErrors.Unavailable:
        details =
          'Visma eEkonomi är inte tillgänglig just nu, vänligen försök igen senare.';
        break;
      default:
        this.canConnect = false;
        severity = 'error';
        details =
          'Ett fel inträffade och vi ska undersöka det så fort vi kan. Vänligen kontakta supporten om du har några frågor kring detta.';
    }
    this.messages.next([
      {
        severity: severity,
        summary: 'Kopplingen misslyckades',
        detail: details,
      },
    ]);
  }
}

enum VismaOAuthErrors {
  AccessDenied = 'access_denied',
  Unavailable = 'temporarily_unavailable',
  ServerError = 'server_error',
}

interface IVismaConfigurationState {
  isConfigured: boolean;
  isEnabled: boolean;
}
