import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class CompanyInvoiceOfferSettingsService extends CompanySuperService {
  dataModel = 'company';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list') =>
    mode === 'labels'
      ? this.makeLabelsArray(data)
      : this.makeObjectsForForms(data);

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      if (this.isActive(dataArray[i].node)) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.name,
          value: dataArray[i].node.id,
          isMaterial: dataArray[i].node.isMaterial,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      if (this.isActive(dataArray[i].node)) {
        const dataObject = {
          procent: dataArray[i].node.procent,
          isMaterial: dataArray[i].node.procent,
          name: dataArray[i].node.name,
          id: dataArray[i].node.id,
        };
        dataObjectsForForms.push(dataObject);
      }
    }
    return dataObjectsForForms;
  }

  private isActive(node: any): boolean {
    // eslint-disable-next-line eqeqeq
    return node.active == 1;
  }
}
