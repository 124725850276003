<div class="p-grid">
  <span class="p-col-8 p-mt-2">
    Nedan kan du ange procentpåslag på kostnader för din kund. Ange även vad du
    fakturerar kunden i timmen och vad du fakturerar kunden för körda mil.
  </span>
  <p-divider class="p-col-12"></p-divider>

  <p-table [value]="costTypes" class="p-col-12 inline-table scrollable-tall">
    <ng-template pTemplate="header">
      <tr>
        <th [style.width]="'8rem'">Påslag/Pris</th>
        <th>Typ</th>
        <th [style.width]="'10rem'">Beräknade intäkter</th>
      </tr>
    </ng-template>
    <ng-template let-costType pTemplate="body">
      <tr>
        <td>{{ costType.cost }} {{ costType.unit }}</td>
        <td class="p-text-truncate">{{ costType.name }}</td>
        <td class="p-text-bold p-text-right">
          {{ costType.total | currency: 'SEK' }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="2">Totalt:</td>
        <td class="p-text-right p-text-bold">
          {{ total | async | currency: 'SEK' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="p-col-12 p-d-flex p-jc-end">
    <button
      pButton
      class="p-button-primary-hyperion"
      label="Redigera"
      icon="pi pi-pencil"
      (click)="showEditCostType = true"
    ></button>
  </div>
</div>
<p-sidebar [(visible)]="showEditCostType" position="right">
  <div class="p-grid">
    <h1 class="p-col-12">Redigera priser och påslag</h1>
    <p-table [value]="costTypes" class="p-col-12 inline-table">
      <ng-template pTemplate="header">
        <tr>
          <th>Påslag/Pris</th>
          <th>Typ</th>
        </tr>
      </ng-template>
      <ng-template let-costType pTemplate="body">
        <tr>
          <td>
            <span class="p-inputgroup">
              <input pInputText [(ngModel)]="costType.cost" />
              <span class="p-inputgroup-addon">{{ costType.unit }}</span>
            </span>
          </td>
          <td class="p-text-truncate">{{ costType.name }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td colspan="2">
            <span class="p-inputgroup">
              <p-dropdown
                #costTypeDropdown
                placeholder="Lägg till"
                appendTo="body"
                [options]="possibleCostTypesDropDownOptions"
                [group]="true"
                [filter]="true"
                emptyFilterMessage="Inga träffar"
              >
                <ng-template let-group pTemplate="group">
                  <span class="p-text-bold">{{ group.label }}</span>
                </ng-template>
                <ng-template let-costType pTemplate="item">
                  <span class="p-text-truncate p-pl-2">{{
                    costType.label
                  }}</span>
                </ng-template>
              </p-dropdown>
              <button
                pButton
                class="p-button-primary-hyperion p-inputgroup-addon"
                icon="pi pi-plus"
                pTooltip="Lägg till vald kostnadstyp"
                (click)="addCostType(costTypeDropdown.value)"
              ></button>
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-col-12 p-d-flex p-jc-end">
      <button
        pButton
        class="p-button-primary-hyperion"
        label="Spara"
        icon="pi pi-save"
        (click)="updateAll()"
      ></button>
    </div>
  </div>
</p-sidebar>
