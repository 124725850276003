import { gql } from 'apollo-angular';

import { GQLFragment, Query } from './index';
import { User } from './user';
import { Todo } from './todo';
import { Contact } from './contact';

// PROJECT CONNECTION
const FRAGMENT_PROJECT_CONNECTION_CALENDAR = gql`
  fragment ProjectConnectionCalendar on ProjectConnection {
    totalCount
    edges {
      node {
        id
        trueId
        mark
        startDate
        endDate
        color
        status
        typeId
        plannedWork(fromDate: $fromDate, toDate: $toDate) {
          edges {
            node {
              id
              userId
              breakDuration
              projectId
              startDate
              endDate
              messageToUser
              todoId
              todo {
                id
                description
              }
              user {
                ...UserNameFullAndColor
              }
            }
          }
        }
        todos {
          edges {
            node {
              ...TodoExpanded
            }
          }
        }
      }
    }
  }
  ${User.fragments.fullNameAndColor}
  ${Todo.fragments.expanded}
` as unknown as GQLFragment;

// PROJECT BOOKMARK
const FRAGMENT_PROJECT_BOOKMARK_BASE = gql`
  fragment ProjectBookmarkBase on ProjectBookmark {
    id
    userId
    projectId
  }
` as unknown as GQLFragment;

const FRAGMENT_PROJECT_EXTERNAL_ID_BASE = gql`
  fragment ProjectExternalIdBase on ProjectExternalId {
    id
    source
    sourceId
    name
  }
` as unknown as GQLFragment;

const FRAGMENT_PROJECT_BASE = gql`
  fragment ProjectBase on Project {
    id
    client
    color
    constructionSiteNumber
    created
    daysFromActiveness_PreProjectTypeHyperion
    endDate
    establishment
    madeBy
    mark
    notInvoicedDaysCount
    offerId
    offertId
    orderNumber
    shortMessage
    status
    trueId
    typeId
    clientContact {
      ...ClientContactBase
    }
    establishmentContact {
      id
      name
      address
    }
    invoiceData_PreProjectTypeHyperion {
      supplier {
        rowsCountMoved
        rowsCountUnMoved
      }
    }
    projectBookmarks {
      edges {
        node {
          ...ProjectBookmarkBase
        }
      }
    }
    externalIds {
      edges {
        node {
          ...ProjectExternalIdBase
        }
      }
    }
    todos {
      totalCount
      edges {
        node {
          ...TodoWithUsers
        }
      }
    }
  }
  ${FRAGMENT_PROJECT_BOOKMARK_BASE}
  ${FRAGMENT_PROJECT_EXTERNAL_ID_BASE}
  ${Todo.fragments.withUsers}
  ${Contact.fragments.clientContactBase}
` as unknown as GQLFragment;

/** Export GQL Fragments */
export const ProjectConnection = {
  fragments: {
    calendar: FRAGMENT_PROJECT_CONNECTION_CALENDAR,
  },
};

export const ProjectBookmark = {
  fragments: {
    base: FRAGMENT_PROJECT_BOOKMARK_BASE,
  },
};

export const ProjectExternalId = {
  fragments: {
    base: FRAGMENT_PROJECT_EXTERNAL_ID_BASE,
  },
};

export const Project = {
  fragments: {
    base: FRAGMENT_PROJECT_BASE,
  },
};

// GQL Queries

const SINGLE_PROJECT = {
  query: gql`
    query fetchSingleProjectQuery($id: Int) {
      project(id: $id) {
        id
        constructionSiteNumber
        startDate
        endDate
        madeBy
        createdBy
        mark
        mileCost
        milePrice
        offerId
        offertId
        offertSum
        offertSumWork
        orderNumber
        weekendHourProcent
        invoicedInOtherSystem
        socialInsurance
        trakHalfDayPrice
        trakDayPrice
        trakNightPrice
        privMileCost
        client
        establishment
        typeId
        shortMessage
        establishmentContact {
          name
          orderBuisnessName
          address
          address2
          cityCode
          city
          id
          mail
          mobilePhone
          orgNr
          phone
          trueId
        }
        clientContact {
          ...ClientContactBase
          address
          address2
          betalningsvillkor
          cityCode
          city
          contactType
          mail
          mailInvoice
          mobilePhone
          orgNr
          propertyName
          housingAssociationOrgNumber
          apartmentDesignation
          phone
          reverseTax
          trueId
        }
        createdByUser {
          ...UserNameFull
        }
        projectCostTypes {
          edges {
            node {
              id
              name
              procent
            }
          }
        }
        externalIds {
          edges {
            node {
              ...ProjectExternalIdBase
            }
          }
        }
        userCostTypes {
          edges {
            node {
              id
              companyCostTypeId
              cost
              internalCost
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
    ${FRAGMENT_PROJECT_EXTERNAL_ID_BASE}
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {
    id: 1906,
  },
};

const SINGLE_PROJECT_TOTAL_COUNTS = {
  query: gql`
    query fetchSingleProjectTotalCountsQuery($id: Int) {
      project(id: $id) {
        id
        invoiceData_PreProjectTypeHyperion {
          supplier {
            rowsCountMoved
            rowsCountUnMoved
          }
        }
        todos(first: -1) {
          totalCount
        }
        comments(first: -1) {
          totalCount
        }
        pictureComments(first: -1) {
          totalCount
        }
        documentMeta(first: -1) {
          totalCount
        }
        costumerRelationsToProject(first: -1) {
          totalCount
        }
        atas(first: -1) {
          totalCount
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_CALENDAR = {
  query: gql`
    query projectCalendarQuery(
      $status: [Int]
      $fromDate: String
      $toDate: String
    ) {
      company {
        id
        projects(status: $status) {
          ...ProjectConnectionCalendar
        }
      }
    }
    ${ProjectConnection.fragments.calendar}
  `,
  testVariables: {},
};

const PROJECT_COMMENTS = {
  query: gql`
    query fetchProjectComments($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              comments {
                edges {
                  node {
                    id
                    created
                    comment
                    projectId
                    user {
                      ...UserNameFull
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    id: 1906,
  },
};

const MULTI_PROJECT_COMMENTS = {
  query: gql`
    query fetchMultiProjectComments($projectIds: [Int], $userIds: [Int]) {
      company {
        id
        projects(multiId: $projectIds) {
          edges {
            node {
              id
              trueId
              mark
              comments(userId: $userIds) {
                edges {
                  node {
                    id
                    created
                    comment
                    userId
                    user {
                      ...UserNameFull
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectIds: [1906],
    userIds: [2],
  },
};

const PROJECT_CUSTOMER_RELATIONSHIPS = {
  query: gql`
    query fetchProjectCustomerRelationships($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              costumerRelationsToProject {
                edges {
                  node {
                    id
                    offer
                    timereport
                    product
                    invoice
                    picture
                    document
                    todo
                    invoiceNotification
                    comment
                    documentNotification
                    project_id
                    privateCostumer {
                      id
                      firstName
                      lastName
                      email
                      telephonenumber
                      mobilenumber
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_DOCUMENT_META = {
  query: gql`
    query fetchDocumentMeta($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              documentMeta {
                totalCount
                edges {
                  node {
                    id
                    comment
                    created
                    file
                    projectId
                    public
                    publicForAllUsers
                    userId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_ATAS: Query = {
  get query() {
    return gql`
      query fetchProjectAtas($id: Int) {
        company {
          id
          projects(id: $id) {
            edges {
              node {
                id
                atas {
                  edges {
                    node {
                      ...AtasDetails
                    }
                  }
                }
              }
            }
          }
        }
      }
      ${this.body}
    `;
  },
  body: gql`
    fragment AtasDetails on Offer {
      id
      trueId
      status
      projectId
      user {
        ...UserNameFull
      }
      clientContact {
        ...ClientContactBase
      }
      typeOffer
      groupCosts
      concerning
      state
      sended
      sumWork
      sumWorkVAT
      sumMaterial
      sumMaterialVAT
      rotSum
      invoicedAmount
    }
    ${User.fragments.fullName}
    ${Contact.fragments.clientContactBase}
  ` as unknown as GQLFragment,
  testVariables: {
    id: 1906,
  },
};

const SINGLE_PROJECT_COST_TYPES = {
  query: gql`
    query fetchSingleProjectCostTypes($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              projectCostTypes {
                edges {
                  node {
                    id
                    name
                    procent
                    companyCostTypeId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    projectId: 1906,
  },
};

const PROJECT_IMAGES = {
  query: gql`
    query fetchProjectImages($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              id
              pictureComments {
                edges {
                  node {
                    id
                    comment
                    created
                    picture
                    projectId
                    user {
                      ...UserNameFull
                    }
                    project {
                      id
                      trueId
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const PROJECT_INSTALLMENTS = {
  query: gql`
    query fetchProjectInstallments($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              id
              installments {
                totalCount
                edges {
                  node {
                    id
                    name
                    dateToInvoice
                    created
                    user {
                      ...UserNameFull
                    }
                    rows {
                      edges {
                        node {
                          id
                          typeId
                          arbetskostnad
                          invoiceId
                          benamning
                          antal
                          apris
                          tax
                          pris
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const PROJECT_DEFAULT_TODOS = {
  query: gql`
    query fetchProjectDefaultTodosQuery {
      company {
        id
        projectDefualtTodos {
          totalCount
          edges {
            node {
              id
              defaultForAllProjects
              description
              descriptionOffer
              estimatedTime
              orderNr
              todoType
              topic {
                id
                Name
              }
              type
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const PROJECT_TODOS = {
  query: gql`
    query fetchProjectTodos($id: Int) {
      project(id: $id) {
        id
        todos {
          edges {
            node {
              ...TodoExpanded
            }
          }
        }
      }
    }
    ${Todo.fragments.expanded}
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_ROTS = {
  query: gql`
    query fetchProjectRots($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              id
              rots {
                totalCount
                edges {
                  node {
                    id
                    personalNumber
                    nameOfProperty
                    housingAssociationOrgNumber
                    apartmentDesignation
                    customerWantedWorkAmount
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    projectId: 1906,
  },
};

const PROJECT_SUPPLIER_INVOICES = {
  query: gql`
    query fetchProjectSupplierInvoices($id: Int) {
      project(id: $id) {
        id
        supplierInvociesBySource_PreProjectTypeHyperion {
          edges {
            node {
              name
              companyCostTypeId
              sourceClass
              invoices {
                totalCount
                edges {
                  node {
                    id
                    companyId
                    fakturaNr
                    fdatum
                    invoiceSourceDocuments_PreInvoiceTypeHyperion {
                      edges {
                        node {
                          url
                          label
                        }
                      }
                    }
                    supplier {
                      orderBuisnessName
                    }
                    rows {
                      totalCount
                      edges {
                        node {
                          id
                          benamning
                          discount
                          antal
                          enhet
                          apris
                          tax
                          pris
                          project_id
                          isMovedToProjectProductId
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_COST_TYPE_WITH_PRODUCTS = {
  query: gql`
    query fetchProjectCostTypesWithProducts($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              id
              projectCostTypes {
                edges {
                  node {
                    id
                    name
                    procent
                    companyCostTypeId
                    products {
                      edges {
                        node {
                          id
                          date
                          artnr
                          benamning
                          created
                          invoiceId
                          enhet
                          avtalspris
                          avtalsprisCost
                          listPrice
                          listPriceUnit
                          totalAmount
                          projectCostTypeId
                          antal
                          extra
                          user {
                            ...UserNameFull
                          }
                          source
                          sourceId
                          supplierInvoiceId
                          supplierInvoice {
                            id
                            invoiceSourceDocuments_PreInvoiceTypeHyperion {
                              edges {
                                node {
                                  url
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const PROJECT_TYPES_WITH_PROJECTS = {
  query: gql`
    query fetchProjectTypesWithProjects($id: Int) {
      company {
        id
        projectTypes(id: $id) {
          edges {
            node {
              id
              projects {
                totalCount
                edges {
                  node {
                    id
                    mark
                    trueId
                    status
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const PROJECT_ACONTO_LIST = {
  query: gql`
    query fetchSingleProjectAcontoList($id: Int) {
      project(id: $id) {
        id
        acontoList_PreProjectTypeHyperion {
          edges {
            node {
              specification
              fakturaNr
              id
              sumBox
              fdatum
              currency
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECT_PRODUCTS_BOOKED = {
  query: gql`
    query projectProductsBooked($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              productsBooked {
                edges {
                  node {
                    id
                    benamning
                    antal
                    antalPicked
                    productId
                    projectId
                    product {
                      latestInventory_PreProductTypeHyperion {
                        id
                        count
                      }
                    }
                    picks {
                      edges {
                        node {
                          id
                          created
                          amount
                          deliveryNote {
                            id
                            created
                            trueId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    id: 1906,
  },
};

const PROJECTS_DATA_WITH_SUMMATION = {
  query: gql`
    query fetchProjectsDataWithSummations(
      $multiId: [Int]
      $status: [Int]
      $fromDate: String
      $toDate: String
    ) {
      company {
        id
        projects(
          multiId: $multiId
          status: $status
          fromDate: $fromDate
          toDate: $toDate
        ) {
          edges {
            node {
              ...ProjectBase
              summation_PreProjectTypeHyperion(
                fromDate: $fromDate
                toDate: $toDate
              ) {
                cost
                paydInvoicesAndCredit
                notPaydInvoices
                result
                offerSum
                workedHours
                workedHoursExtra
                invoicedHours
                hoursLeftToInvoice
                remainingInvoice
                notInvoiced
              }
            }
          }
        }
      }
    }
    ${Project.fragments.base}
  `,
  testVariables: {},
};

const ARCHIVED_PROJECTS = {
  query: gql`
    query fetchArchivedProjectsQuery($fromDate: String, $toDate: String) {
      company {
        id
        projects(status: [3], fromDate: $fromDate, toDate: $toDate) {
          totalCount
          edges {
            node {
              id
              status
              trueId
              mark
              shortMessage
              offerId
              offertId
              color
              constructionSiteNumber
              invoiceData_PreProjectTypeHyperion {
                supplier {
                  rowsCountMoved
                  rowsCountUnMoved
                }
              }
              notInvoicedDaysCount
              endDate
              typeId
              daysFromActiveness_PreProjectTypeHyperion
              client
              establishment
              establishmentContact {
                id
                name
                address
              }
              clientContact {
                ...ClientContactBase
              }
              projectBookmarks {
                edges {
                  node {
                    ...ProjectBookmarkBase
                  }
                }
              }
              todos {
                totalCount
                edges {
                  node {
                    ...TodoWithUsers
                  }
                }
              }
            }
          }
        }
      }
    }
    ${ProjectBookmark.fragments.base}
    ${Todo.fragments.withUsers}
    ${Contact.fragments.clientContactBase}
  `,
  testVariables: {},
};

const CHILD_PROJECTS = {
  query: gql`
    query childProjecs($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              childs {
                edges {
                  node {
                    id
                    mark
                    trueId
                    parentId
                    subcontractorRelationToProject {
                      id
                      clientSharesTimereports
                      clientSharesTimereportsWithoutNotarized
                    }
                    company {
                      id
                      name
                    }
                    days {
                      edges {
                        node {
                          id
                          extra
                          created
                          date
                          todoId
                          timeHour
                          newCostTypeId
                          exportedToSubcontractorRelation {
                            id
                            created
                            savedByUser {
                              ...UserNameFull
                            }
                          }
                          user {
                            ...UserNameFull
                          }
                          notarized
                          doneWork
                          hours
                          hoursToInvoice
                          mile
                          mileToInvoice
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    id: 1906,
  },
};

const CLIENTS = {
  query: gql`
    query fetchClients {
      company {
        id
        clients {
          edges {
            node {
              id
              name
              company {
                id
                name
              }
              clientProjectsNotCopied {
                edges {
                  node {
                    id
                    created
                    mark
                    trueId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
};

const PROJECT_SUBCONTRACTOR_RELATIONS = {
  query: gql`
    query fetchProjectSubcontractorsRelations($multiId: [Int]) {
      company {
        id
        projects(multiId: $multiId) {
          edges {
            node {
              id
              trueId
              mark
              subcontractorsRelations {
                edges {
                  node {
                    id
                    clientProjectChildProjectId
                    mainContractorWhantsTimereports
                    mainContractorWhantsToPutTodos
                    clientSharesTimereports
                    clientSharesTimereportsWithoutNotarized
                    clientAcceptTodos
                    copied
                    subcontractor {
                      id
                      name
                      orgNr
                      email
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

const SUBCONTRACTOR_RELATION_TO_PROJECT = {
  query: gql`
    query fetchSubcontractorRelationToProject($multiId: [Int]) {
      company {
        id
        projects(multiId: $multiId) {
          edges {
            node {
              id
              subcontractorRelationToProject {
                id
                clientProjectChildProjectId
                mainContractorWhantsTimereports
                mainContractorWhantsToPutTodos
                clientSharesTimereports
                clientSharesTimereportsWithoutNotarized
                clientAcceptTodos
                copied
                project {
                  id
                  company {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {},
};

/** EXPORTS */

export const queries = {
  singleProject: SINGLE_PROJECT,
  singleProjectTotalCounts: SINGLE_PROJECT_TOTAL_COUNTS,
  projectCalendar: PROJECT_CALENDAR,
  projectComments: PROJECT_COMMENTS,
  multiProjectComments: MULTI_PROJECT_COMMENTS,
  projectCustomerRelationships: PROJECT_CUSTOMER_RELATIONSHIPS,
  projectDocumentMeta: PROJECT_DOCUMENT_META,
  projectAtas: PROJECT_ATAS,
  singleProjectCostTypes: SINGLE_PROJECT_COST_TYPES,
  projectImages: PROJECT_IMAGES,
  projectInstallments: PROJECT_INSTALLMENTS,
  projectDefaultTodos: PROJECT_DEFAULT_TODOS,
  projectTodos: PROJECT_TODOS,
  projectRots: PROJECT_ROTS,
  projectSupplierInvoices: PROJECT_SUPPLIER_INVOICES,
  projectCostTypeWithProducts: PROJECT_COST_TYPE_WITH_PRODUCTS,
  projectTypeWithProjects: PROJECT_TYPES_WITH_PROJECTS,
  projectAcontoList: PROJECT_ACONTO_LIST,
  projectProductsBooked: PROJECT_PRODUCTS_BOOKED,
  projectsDataWithSummation: PROJECTS_DATA_WITH_SUMMATION,
  archivedProjects: ARCHIVED_PROJECTS,
  childProjects: CHILD_PROJECTS,
  clients: CLIENTS,
  projectSubcontractorsRelations: PROJECT_SUBCONTRACTOR_RELATIONS,
  subcontractorRelationToProject: SUBCONTRACTOR_RELATION_TO_PROJECT,
};
