import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EditorModule } from 'primeng/editor';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule as PTableModule } from 'primeng/table';
import { SliderModule } from 'primeng/slider';

// Settings
import {
  // Company
  // Cost Type
  CompanyCostTypeSettingsComponent,
  CreateCostTypeComponent,
  EditCostTypeComponent,
  // Division
  CompanyDivisionSettingsComponent,
  CreateCompanyDivisionComponent,
  EditCompanyDivisionComponent,
  // Invoice Offer Settings
  CompanyInvoiceOfferSettingsComponent,
  // Update Settings
  CompanyUpdateSettingsComponent,
  // User Cost Type
  CompanyUserCostTypeSettingsComponent,
  CreateUserCostTypeComponent,
  EditUserCostTypeComponent,

  // Quality Stmaps
  CompanyQualityStampsComponent,
  // Me
  MeSettingsComponent,
  InvoiceExportSettingsComponent,
  InvoiceNumberSettingsComponent,
  InvoiceMallSettingsComponent,
  IntegrationSettingsComponent,
  FortnoxOAuthComponent,
  VismaEAccountingComponent,
} from './index';

// Modules
import { HyperionSharedModule } from '../shared/shared.module';
import { TableModule } from 'app/shared/table/table.module';

import { CompanyProjectTypeModule } from './company/company-project-type/company-project-type.module';

import { SharedDialogModule } from 'app/shared/dialogs/shared.dialog.module';
import { SubcompaniesComponent } from 'app/subcompanies/subcompanies.component';
import { DokuadminComponent } from '../dokuadmin/dokuadmin.component';
import { HeaderModule } from 'app/header/header.module';
import { SettingsComponent } from './settings.component';
import { CardModule } from 'primeng/card';
import { MessagesModule } from 'primeng/messages';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    HyperionSharedModule,
    CompanyProjectTypeModule,
    EditorModule,
    PTableModule,
    TableModule,
    SharedDialogModule,
    RadioButtonModule,
    HeaderModule,
    CardModule,
    SliderModule,
    MessagesModule,
  ],
  declarations: [
    CompanyCostTypeSettingsComponent,
    CreateCostTypeComponent,
    CompanyQualityStampsComponent,
    MeSettingsComponent,
    EditCostTypeComponent,
    CompanyUserCostTypeSettingsComponent,
    CreateUserCostTypeComponent,
    EditUserCostTypeComponent,
    CompanyDivisionSettingsComponent,
    CreateCompanyDivisionComponent,
    EditCompanyDivisionComponent,
    CompanyUpdateSettingsComponent,
    CompanyUserCostTypeSettingsComponent,
    CompanyInvoiceOfferSettingsComponent,
    CompanyUpdateSettingsComponent,
    SubcompaniesComponent,
    DokuadminComponent,
    SettingsComponent,
    InvoiceExportSettingsComponent,
    InvoiceMallSettingsComponent,
    InvoiceNumberSettingsComponent,
    FortnoxOAuthComponent,
    VismaEAccountingComponent,
    IntegrationSettingsComponent,
  ],
  exports: [
    CompanyCostTypeSettingsComponent,
    CompanyUserCostTypeSettingsComponent,
    CompanyDivisionSettingsComponent,
    CompanyUpdateSettingsComponent,
  ],
})
export class SettingsModule {}
