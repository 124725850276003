enum PROJECT_STATUS {
  PLANNED = 0,
  ONGOING = 1,
  FINISHED = 2,
  ARCHIVED = 3,
  DELETED = 4,
  LEAVE = 5,
  INHOUSE = 6,
}
export const PROJECT_STATUS_LABELS = [
  { value: PROJECT_STATUS.PLANNED, label: 'Planerade' },
  { value: PROJECT_STATUS.ONGOING, label: 'Pågående' },
  { value: PROJECT_STATUS.FINISHED, label: 'Avslutade' },
  { value: PROJECT_STATUS.ARCHIVED, label: 'Arkiverade' },
  { value: PROJECT_STATUS.DELETED, label: 'Raderade' },
  { value: PROJECT_STATUS.LEAVE, label: 'Frånvaro' },
  { value: PROJECT_STATUS.INHOUSE, label: 'Interna' },
];

export default PROJECT_STATUS;
