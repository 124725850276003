<h1>Skapa underentreprenör</h1>
<form
  class="p-grid"
  [formGroup]="subcontractorForm"
  (submit)="createSubcontractor()"
>
  <span class="p-col-12">
    <label>Företagsnamn*</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-briefcase"></i>
      </span>
      <input pInputText type="text" formControlName="name" />
    </span>
  </span>
  <span class="p-col-12">
    <label>Mailadress</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-envelope"></i>
      </span>
      <input pInputText type="email" formControlName="mail" />
    </span>
  </span>
  <span class="p-col-12">
    <label>OrgNr*</label>
    <span class="p-inputgroup">
      <span class="p-inputgroup-addon">
        <i class="pi pi-hashtag"></i>
      </span>
      <input pInputText formControlName="orgNr" />
    </span>
  </span>
  <span class="p-col-12 p-d-flex p-jc-end">
    <button
      pButton
      type="submit"
      class="p-button-primary-hyperion"
      label="Skapa"
      icon="pi pi-plus"
    ></button>
  </span>
</form>
