import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Subscription, BehaviorSubject } from 'rxjs';

import { CompanyCostTypeService } from '../../shared/company/index';
import { ApolloQueryService } from '../../shared/apollo/index';
import { GlobalService } from '../../shared/global/index';
import { CompanyProductsListComponent } from 'app/registry/product/products-list/company-products-list.component';
import { HtmlModalService } from 'app/shared/html-modal';

@Component({
  selector: 'app-company-products-settings',
  templateUrl: 'company-products-settings.component.html',
  animations: [
    trigger('importProducts', [
      state(
        'void',
        style({
          height: '0px',
        })
      ),
      transition('* => *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class CompanyProductsSettingsComponent implements OnDestroy, OnInit {
  @ViewChild(CompanyProductsListComponent, { static: true })
  private companyProductsListComponent: CompanyProductsListComponent;
  public companyCostTypes: any[];
  public importProductsShow = false;
  public costTypesLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private companyCostTypeSub: Subscription;

  public showCreateProduct = false;

  constructor(
    private costTypeService: CompanyCostTypeService,
    private globalService: GlobalService,
    private apolloQueryService: ApolloQueryService,
    private htmlModalService: HtmlModalService
  ) {}

  public ngOnInit(): void {
    this.getCompanyCostTypes();
  }

  public ngOnDestroy(): void {
    if (this.companyCostTypeSub) {
      this.companyCostTypeSub.unsubscribe();
    }
  }

  private getCompanyCostTypes(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyCostType', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.companyCostTypeSub = sub;
        this.companyCostTypes = this.costTypeService.dataFormater(
          data,
          'labels'
        );
        this.costTypesLoading.next(false);
      });
  }

  public toggleImportProducts(): void {
    this.importProductsShow = !this.importProductsShow;
  }

  public refetchProducts(): void {
    this.companyProductsListComponent.refetchData();
  }

  public showProducts(type: 'excel' | 'pdf'): void {
    let url =
      this.globalService.getUrlPrefix() +
      '/product/HyperionIndex/?showProductPrice=1';
    if (type === 'excel') {
      url =
        this.globalService.getUrlPrefix() +
        '/product/HyperionIndex/?showProductPrice=1&excel=1';
    }

    this.htmlModalService.ny_sida(url, 900, 800);
  }
}
