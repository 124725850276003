<div class="p-grid">
  <div class="p-col-12">
    <hyperion-card>
      <div class="p-grid">
        <div class="p-col-12">
          <div class="project-title-section bottom-border-divider">
            <div class="sub-title-row">
              <h4>
                {{ dataObjectFromParent.name }}
                <small class="italic"
                  >Ska faktureras datum:
                  {{ dataObjectFromParent.dateToInvoice }}, skapad av
                  {{ dataObjectFromParent.user.firstName }}
                  {{ dataObjectFromParent.user.lastName }}</small
                >
              </h4>
              <app-button
                [class]="'pull-right'"
                (click)="confirmDeletePlan()"
                [type]="'delete'"
                [model]="'ProjectInstallment'"
              ></app-button>
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
        <div
          class="p-col-12 p-g-nopad"
          *ngFor="
            let row of dataSet;
            let rowIndex = index;
            trackBy: helperService.rowTrackBy
          "
        >
          <project-installment-row
            [isFirst]="rowIndex === 0"
            [projectInfo]="projectInfo"
            [projectInstallment]="dataObjectFromParent"
            [optionallyInvoiceTaxDropdown]="optionallyInvoiceTaxDropdown"
            [invoiceRowTypeListDropdown]="invoiceRowTypeListDropdown"
            [labels]="labels"
            [dataObjectFromParent]="row"
          ></project-installment-row>
        </div>
        <div class="p-col-12 p-g-nopad">
          <project-installment-row
            [isFirst]="dataSet.length === 0"
            [labels]="labels"
            [optionallyInvoiceTaxDropdown]="optionallyInvoiceTaxDropdown"
            [invoiceRowTypeListDropdown]="invoiceRowTypeListDropdown"
            [projectInfo]="projectInfo"
            [projectInstallment]="dataObjectFromParent"
            [isCreate]="true"
          ></project-installment-row>
        </div>
      </div>
    </hyperion-card>
  </div>
</div>
