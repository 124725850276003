import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2HighchartsModule } from 'ng2-highcharts';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import { ProjectDefaultTodoModule } from 'app/old-project/project-default-todo/project-default-todo.module';
import { CompanyProjectTypeModule } from 'app/settings/company/company-project-type/company-project-type.module';

import { HyperionSharedModule } from 'app/shared/shared.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { ProjectExternalModule } from 'app/shared/company/derome-integration/project-external.module';

import { DialogComponent } from './dialog.component';
import { ExtendedDialogComponent } from './extended.dialog.component';
import { MailDialogComponent } from './mail/mail-dialog.component';
import { ProductComponent } from './product/product.component';
import { ProjectCreateComponent } from './project/create/projectCreate.component';
import { ProjectDataComponent } from './project/data/projectData.component';
import { OfferDataComponent } from './offer-data/offer-data.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectDefaultTodoModule,
    CompanyProjectTypeModule,
    LoadingModule,
    // Primeng
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    TableModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    InputTextModule,
    InputTextareaModule,
    SharedModule,
    TabViewModule,
    TooltipModule,
    Ng2HighchartsModule,

    // Module
    HyperionSharedModule,
    RadioButtonModule,
    ProjectExternalModule,
  ],
  declarations: [
    DialogComponent,
    ExtendedDialogComponent,
    ProjectCreateComponent,
    MailDialogComponent,
    ProjectDataComponent,
    OfferDataComponent,
    ProductComponent,
  ],
  exports: [DialogComponent, ExtendedDialogComponent, ProjectCreateComponent],
  providers: [],
})
export class SharedDialogModule {}
