import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { first, Subscription } from 'rxjs';

import { CompanyUserCostTypeService } from '../../../shared/company/index';
import { CompanyFunctionsService } from 'app/shared/company/companyFunctions.service';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';
import { AccountingPlanAccount } from 'app/settings/company/services/accounting-plan.types';
import { GlobalService } from '../../../shared/global/index';
import { ApolloQueryService } from '../../../shared/apollo';
import { HelperService } from 'app/shared/helpers';
import { UpdateCompanySettingsGQL } from '../company-update-settings/graphql/company-update-settings.generated';
import { MessageService } from 'app/shared/message';

@Component({
  selector: 'companyUserCostType-settings',
  templateUrl: 'company-user-cost-type-settings.component.html',
})
export class CompanyUserCostTypeSettingsComponent implements OnDestroy, OnInit {
  @Input() isModal = false;

  loading = true;
  dataModel = 'companyUserCostType';
  public dataSet = [];
  public systemTypes = [];
  public dataSetInactive = [];
  createDataObjectArray = [{ fakeId: 0 }];
  componentPrefix;
  sub: Subscription;
  public hasAccountingPlanFunction: boolean;
  public accountingPlanAccounts: AccountingPlanAccount[] = [];
  public useInternalCostAsDefaultProjectCost: boolean;

  constructor(
    private companyUserCostTypeService: CompanyUserCostTypeService,
    private companyFunctionsService: CompanyFunctionsService,
    private accountingPlanService: AccountingPlanService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService,
    private updateCompanySettingsGQL: UpdateCompanySettingsGQL,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();

    this.companyFunctionsService
      .hasCompanyFunction('useAccountingPlan')
      .subscribe(isEnabled => (this.hasAccountingPlanFunction = isEnabled));

    this.accountingPlanService
      .getAccounts()
      .subscribe((accountingPlanAccounts: AccountingPlanAccount[]) => {
        this.accountingPlanAccounts = accountingPlanAccounts;

        this.queryDataSet();
      });
  }

  public onChangeUseInternalCostAsDefaultProjectCost(value: boolean) {
    this.updateCompanySettingsGQL
      .mutate({
        companySettings: {
          useInternalCostAsDefaultProjectCost: value,
        },
      })
      .pipe(first())
      .subscribe(({ data }) => {
        const success =
          data.companyTypeHyperionMutation.mutationDetails[0].mutationSucceeded;
        if (success) {
          this.messageService.insertData({
            summary: 'Inställningar uppdaterad',
            type: 'success',
          });
        } else {
          this.messageService.insertData({
            summary: 'Kunde inte uppdatera inställningar',
            type: 'error',
          });
        }
      });
  }

  private queryDataSet() {
    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel)
      .subscribe(({ data, sub }) => {
        this.sub = sub;
        this.loading = false;
        const fullDataSet = this.companyUserCostTypeService.dataFormater(
          data,
          'list',
          true
        );

        this.dataSet = fullDataSet.filter(x => x.type !== 'isWork' && x.active);
        this.dataSetInactive = fullDataSet.filter(x => !x.active);
        this.systemTypes = fullDataSet.filter(x => x.type === 'isWork');
        this.useInternalCostAsDefaultProjectCost =
          data.company.useInternalCostAsDefaultProjectCost;
      });
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  spliceDeletedDataObject(createComponentObj) {
    const index = this.createDataObjectArray.indexOf(createComponentObj);
    this.createDataObjectArray.splice(index, 1);
  }

  addCreateComponent(createComponentObj) {
    if (
      this.createDataObjectArray.indexOf(createComponentObj) ===
      this.createDataObjectArray.length - 1
    ) {
      const latest =
        this.createDataObjectArray[this.createDataObjectArray.length - 1];
      const newFakeId = latest['fakeId'] + 1;
      const pushThis = { fakeId: newFakeId };

      this.createDataObjectArray.push(pushThis);
    }
  }
}
