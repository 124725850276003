<app-docu-upsell-dialog>
  <ng-template pTemplate="title">
    Du har
    <ng-container *ngIf="(remainingExports | async) > 0; else noExports">{{
      remainingExports | async
    }}</ng-container>
    <ng-template #noExports>inga</ng-template>
    gratis export<ng-container *ngIf="(remainingExports | async) !== 1"
      >er</ng-container
    >
    kvar.
  </ng-template>
  <ng-template pTemplate="extraButtons">
    <app-button
      class="ml5"
      (onClick)="closeDialogIfAllowed()"
      [disabled]="!(allowDownload | async)"
      [plainText]="{ label: 'Ladda ner' }"
    ></app-button>
  </ng-template>
</app-docu-upsell-dialog>
