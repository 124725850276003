import { Injectable } from '@angular/core';

@Injectable()
export class HtmlModalService {
  ny_sida(url, width, height) {
    /**
     * Here you can change the properties of the window,
     * e.g. if there should be an address field, a status bar, etc.
     * The values can be "yes" or "no".
     */
    let egenskaper =
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes';

    egenskaper += ',width=' + width;
    egenskaper += ',height=' + height;
    const unik = new Date();
    const unikk =
      unik.getSeconds() + '_' + unik.getMinutes() + '_' + unik.getHours();

    return open.call(window, url, unikk, `'${egenskaper}'`);
  }

  emptyPage(width, height) {
    let egenskaper =
      'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes';

    const url = '';

    egenskaper += ',width=' + width;
    egenskaper += ',height=' + height;
    const unik = new Date();
    const unikk =
      unik.getSeconds() + '_' + unik.getMinutes() + '_' + unik.getHours();

    return open.call(window, url, unikk, `'${egenskaper}'`);
  }
}
