<div *ngIf="daysDue >= 5" class="flash-notice">
  Hej {{ userFirstName }},

  <span *ngIf="invoice">
    <a href="{{ invoice.fileUrl }}"
      ><b
        >Faktura {{ invoice.fortnoxInvoiceNr }} (klicka här för att ladda
        ner)</b
      ></a
    >
  </span>

  <span *ngIf="!invoice">
    faktura <b>{{ invoice.fortnoxInvoiceNr }}</b>
  </span>

  kan vi inte se som betald (förföll för {{ daysDue }} dagar sedan).

  <div *ngIf="daysDue > 20">
    Vi har stängt ert konto tills att betalning är mottagen.
  </div>

  <div *ngIf="daysDue <= 20">
    Har du inte mottagit fakturan eller har betalt aktuell faktura så var god
    kontakta oss eller erlägg betalning.
  </div>

  <br />
  Vid frågor, ring
  {{ configService.getConfig().supportPhone }} eller maila
  {{ configService.getConfig().economyMail }}
</div>
