import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'start-news',
  templateUrl: 'news-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeStartNewsHolder {
  @Input() dataFromParent;
  @Input() loading = true;
}
