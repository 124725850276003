import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocuEditComponent } from 'app/docu/docu-edit/docu-edit.component';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { BehaviorSubject, first } from 'rxjs';
import {
  DeleteDocumentGQL,
  GetDocumentsGQL,
  UpdateDocumentGQL,
} from './graphql/project-documents.generated';

@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDocumentsComponent implements OnInit {
  public projectId: number;
  public documents: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private readonly PREFIX = 'Dokument_DbDocument_Document_';

  public menus: MenuItem[][];

  public columns = [
    {
      title: 'Datum',
      width: '7rem',
      field: 'created',
    },
    {
      title: 'Filnamn',
      width: 'auto',
      field: 'file',
    },
    {
      title: 'Kommentar',
      width: 'auto',
      field: 'comment',
    },
    {
      title: 'Visa för medarbetare',
      width: '9rem',
      field: 'publicForAllUsers',
    },
    {
      title: 'Publikt',
      width: '8rem',
      field: 'public',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private appDialogService: AppDialogService,
    private confirmationService: ConfirmationService,
    private getDocumentsService: GetDocumentsGQL,
    private updateDocumentService: UpdateDocumentGQL,
    private deleteDocumentService: DeleteDocumentGQL,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;
      this.fetchDocuments();
    });
    this.changeDetector.markForCheck();
  }

  public fetchDocuments(): void {
    this.getDocumentsService
      .fetch({ projectId: this.projectId })
      .pipe(first())
      .subscribe(data => {
        this.projectCountService.count(this.projectId);
        const documents = data.data.project.documentMeta.edges.map(n => ({
          ...n.node,
          public: Boolean(n.node.public).valueOf(),
          publicForAllUsers: Boolean(n.node.publicForAllUsers).valueOf(),
        }));
        this.menus = documents.map(d => this.generateMenuItems(d));
        this.documents.next(documents);
      });
  }

  public updateDocument(document): void {
    this.updateDocumentService
      .mutate(document)
      .pipe(first())
      .subscribe(response => {
        const success =
          response.data.documentMetaTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        if (success) {
          this.fetchDocuments();
        }
      });
  }

  public deleteDocument(document): void {
    this.deleteDocumentService
      .mutate(document)
      .pipe(first())
      .subscribe(response => {
        const success =
          response.data.documentMetaTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        if (success) {
          this.fetchDocuments();
        }
      });
  }

  public confirmDeletion(event, document) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Radera dokumentet?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deleteDocument(document);
      },
      acceptButtonStyleClass: 'p-button-danger',
    });
  }

  public generateMenuItems(document): MenuItem[] {
    const items = [
      {
        icon: 'pi pi-external-link',
        label: 'Redigera',
        command: _ => this.editDocument(document),
        show: this.isEditable(document),
      },
      {
        icon: 'pi pi-arrow-down',
        label: 'Hämta dokument',
        command: _ => this.downloadDocument(document),
        show: true,
      },
      {
        icon: 'pi pi-file',
        label: 'Visa dokument',
        command: _ => this.showDocument(document),
        show: true,
      },
      {
        icon: 'pi pi-trash',
        iconStyle: { color: 'var(--btn-danger-color)' },
        label: 'Radera',
        command: event => this.confirmDeletion(event, document),
        show: true,
      },
    ];

    return items.filter(i => i.show);
  }

  public isEditable(document): boolean {
    return document.file.startsWith(this.PREFIX);
  }

  private getDocumentUrl(document): string {
    return `/project/getDocument?file=${document.file}&projectId=${this.projectId}`;
  }

  public showDocument(document): void {
    const url = this.getDocumentUrl(document) + '&output=show';
    window.open(url);
  }

  public downloadDocument(document): void {
    const url = this.getDocumentUrl(document);
    window.open(url);
  }

  public editDocument(document): void {
    this.appDialogService.layout = 'wide';
    this.appDialogService.data = {
      id2: Number(document.file.replace(this.PREFIX, '')),
    };
    this.appDialogService
      .openComponent(DocuEditComponent, 'Redigera Dokument')
      .onClose.subscribe(() => this.fetchDocuments());
    return;
  }
}
