import {
  Component,
  Input,
  SimpleChanges,
  ChangeDetectionStrategy,
  OnChanges,
} from '@angular/core';

import { HelperService } from '../../../shared/helpers/index';

@Component({
  selector: 'home-start-pictures-holder',
  templateUrl: 'pictures-read-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeStartPicturesReadHolder implements OnChanges {
  @Input() picturesFromParent;
  @Input() loading = true;

  dataSet;

  constructor(private helperService: HelperService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['picturesFromParent'].currentValue['edges']) {
      this.dataSet = this.helperService.cleanFromNode(
        changes['picturesFromParent'].currentValue
      );
    }
  }
}
