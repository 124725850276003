import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { HyperionSharedModule } from 'app/shared/shared.module';
import { ClickOutsideModule } from 'app/shared/click-outside/click-outside.module';
import { OfferAutoCompleteComponent } from './index';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    AutoCompleteModule,
    InputTextareaModule,
    HyperionSharedModule,
  ],
  declarations: [OfferAutoCompleteComponent],
  exports: [OfferAutoCompleteComponent],
  providers: [],
})
export class OfferAutoCompleteModule {}
