import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import {
  ContactProjectsService,
  ProjectsReduced,
} from './contact-projects.service';
import { Contact } from 'app/contact/contact';
import { TableMetadata } from 'app/shared/table/table-metadata';
import { COLUMN_TYPES } from 'app/shared/table/column-types';
import { HyperionLabelsService } from 'app/shared/labels/hyperion-labels.service';
import { LabelsResponse } from 'app/shared/labels/labels.service';
import { DateService } from 'app/shared/helpers/date.service';

@Component({
  selector: 'app-contact-projects',
  templateUrl: './contact-projects.component.html',
  styleUrls: ['./contact-projects.component.scss'],
})
export class ContactProjectsComponent implements OnInit, OnDestroy {
  @Input() public contact: Contact;

  @Input() private triggerReload$: Observable<void>;

  /** Emits the total number of projects */
  @Output() totalCount = new EventEmitter();

  public tableData: ProjectsReduced[];
  public tableMetadata: TableMetadata;

  public dateRange: {
    from: string;
    to: string;
  } = {
    from: null,
    to: null,
  };

  public labels: LabelsResponse;

  /** A local copy of the data used for persisting the unmodified data for filtering */
  private pristineData: ProjectsReduced[];

  private loadDataSubject$ = new BehaviorSubject(null);

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private contactProjectsService: ContactProjectsService,
    private dateService: DateService,
    private labelService: HyperionLabelsService
  ) {}

  ngOnInit() {
    if (!this.contact || !this.contact.id) {
      return;
    }

    this.labelService
      .getLabels([{ model: 'Contact' }, { model: 'Project' }])
      .pipe(takeUntil(this.destroy$))
      .subscribe(labels => {
        this.setupTable(labels);
      });

    this.loadDataSubject$
      .pipe(
        switchMap(() =>
          this.contactProjectsService.getProjectsPerContact(+this.contact.id)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(data => {
        this.pristineData = data.projects;
        this.tableData = data.projects;
        this.totalCount.emit(data.projects.length);
      });

    // Listen to trigger reload
    this.triggerReload$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadDataSubject$.next(null);
    });
  }

  private setupTable(labels: LabelsResponse) {
    this.labels = labels;
    this.tableMetadata = {
      columnsMetadata: [
        {
          ...COLUMN_TYPES.ID,
          key: 'trueId',
          label: labels.Project.trueId,
        },
        {
          ...COLUMN_TYPES.TEXT,
          key: 'clientContactOrderBusinessName',
          label: 'Beställare',
        },
        {
          ...COLUMN_TYPES.TEXT,
          key: 'establishmentContactName',
          label: 'Arbetställe (Kontakt)',
        },
        {
          ...COLUMN_TYPES.TEXT,
          key: 'establishmentContactAddress',
          label: 'Arbetställe (Address)',
        },
        {
          ...COLUMN_TYPES.TEXT,
          key: 'mark',
          label: labels.Project.mark,
        },
        {
          ...COLUMN_TYPES.DATE,
          key: 'startDate',
          label: labels.Project.startDate,
        },
        {
          ...COLUMN_TYPES.DATE,
          key: 'endDate',
          label: labels.Project.endDate,
        },
      ],

      sortBy: {
        attribute: 'trueId',
        ascDesc: -1,
        object: 'contact_projects_table',
      },

      globalFilterFields: [
        'trueId',
        'clientContactOrderBusinessName',
        'establishmentContactName',
        'establishmentContactAddress',
        'mark',
      ],

      emptyStateSettings: {
        model: 'Project',
      },

      showTableTotals: false,

      paginator: {
        rows: 50,
      },
    };
  }

  public filterData(model: 'from' | 'to', date: string) {
    this.dateRange[model] = date;

    this.tableData = this.pristineData.filter(project => {
      return (
        this.dateService.isBetween(
          project.startDate,
          this.dateRange.from,
          this.dateRange.to
        ) ||
        this.dateService.isBetween(
          project.endDate,
          this.dateRange.from,
          this.dateRange.to
        )
      );
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
