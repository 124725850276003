// TODO: re-enable max-classes-per-file rule and refactor, disabling due to time constraints
/* eslint-disable max-classes-per-file */

import { Record, Map, List, OrderedMap, Set } from 'immutable';
import { v4 as uuidv4 } from 'uuid';

export type ServerId = number;
export type QualifiedServerId = { type: 'row' | 'col'; id: ServerId };
export type ViewId = string;

export function generateViewId(): ViewId {
  return uuidv4();
}

export class DocumentRowInfo extends Record({
  id: null,
  viewId: null,
  header: '',
  prevText: '',
  afterText: '',
  type: null,
  used: true,
  copyAbility: false,
  deleteAbility: false,
}) {
  id: ServerId;
  viewId: ViewId;
  header: string;
  prevText: string;
  afterText: string;
  type: string;
  used: boolean;
  copyAbility: boolean;
  deleteAbility: boolean;

  labelsOnceOnTopOfColumn(): boolean {
    return this.type === 'LABELS_ONCE_ON_TOP_OF_COLUMN';
  }

  showColumnLabels(): boolean {
    return !this.labelsOnceOnTopOfColumn();
  }
}

export class DocumentColumnInfo extends Record({
  id: null,
  viewId: null,
  label: '',
  with: 0,
  inputType: null,
  value: '',
  errorMsg: null,
}) {
  id: ServerId;
  viewId: ViewId;
  label: string;
  with: number; // TYPO: width
  inputType: string;
  value: string;
  errorMsg: string;
}

export class DocumentRow extends Record({
  row: new DocumentRowInfo(),
  cols: List(),
}) {
  row: DocumentRowInfo;
  cols: List<DocumentColumn>;
}

export class DocumentColumn extends Record({
  col: new DocumentColumnInfo(),
  rows: List(),
}) {
  col: DocumentColumnInfo;
  rows: List<DocumentRow>;
}

export class Document extends Record({
  rows: List(),
  viewIdPathMap: Map(),
  serverIdMap: Map(),
}) {
  rows: List<DocumentRow>;
  // Do *not* use this directly outside Document, use getItemPath instead
  viewIdPathMap: Map<ViewId, List<any>>;
  serverIdMap: Map<ViewId, QualifiedServerId>;

  getItemPath(id: ViewId): List<any> {
    const path = this.viewIdPathMap.get(id);
    if (path === undefined) {
      throw new Error(`Unable to find cell ${id}`);
    }
    return path;
  }

  getItem(id: ViewId): any {
    return this.rows.getIn(this.getItemPath(id));
  }

  // moves to top if below === null
  reorderRow(id: ViewId, below: ViewId): Document {
    const rowPath = this.getItemPath(id);
    const parentPath = rowPath.pop();
    const oldIndex = rowPath.last();
    let newIndex = 0;
    if (below !== null) {
      const belowPath = this.getItemPath(below);
      if (!parentPath.equals(belowPath.pop())) {
        throw new Error(
          `Tried to move row to a path with a different ancestry: rowPath: ${rowPath.toJSON()}, belowPath: ${belowPath.toJSON()}`
        );
      }
      newIndex = belowPath.last() + 1;
    }
    const row = this.rows.getIn(rowPath);
    const rowsAfterReorder = this.rows.updateIn(parentPath, (parent: any) =>
      parent.remove(oldIndex).insert(newIndex, row)
    );
    return this.merge({
      rows: rowsAfterReorder,
      viewIdPathMap: this.viewIdPathMap.merge(
        collectPathMapFromRows(
          rowsAfterReorder.getIn(parentPath) as any,
          parentPath
        )
      ),
    });
  }

  copyRow(id: ViewId, viewIdMap: Map<ViewId, ViewId>): Document {
    const rowPath = this.getItemPath(id);
    const rowIndex = rowPath.last();
    const parentPath = rowPath.pop();
    const newRow = cloneDocumentRow(
      this.rows.getIn(rowPath) as any,
      true,
      row => viewIdMap.get(row.viewId),
      col => viewIdMap.get(col.viewId)
    );
    const rowsAfterCopy = this.rows.updateIn(parentPath, (parent: any) =>
      parent.insert(rowIndex + 1, newRow)
    );
    return this.merge({
      rows: rowsAfterCopy,
      viewIdPathMap: this.viewIdPathMap.merge(
        collectPathMapFromRows(
          rowsAfterCopy.getIn(parentPath) as any,
          parentPath
        )
      ),
    });
  }

  deleteRow(id: ViewId): Document {
    const rowPath = this.getItemPath(id);
    const parentPath = rowPath.pop();
    const row = this.rows.getIn(rowPath);
    const idsTransitive = collectIdsFromRow(row as any);
    const rowsAfterDelete = this.rows.deleteIn(rowPath);
    return this.merge({
      rows: rowsAfterDelete,
      viewIdPathMap: this.viewIdPathMap
        .deleteAll(idsTransitive.toIndexedSeq())
        .merge(
          collectPathMapFromRows(
            rowsAfterDelete.getIn(parentPath) as any,
            parentPath
          )
        ),
    });
  }
}

// forEachX signature: (x, indexFromParent, context) => context for children
function collectFromCol<Ctx>(
  col: DocumentColumn,
  forEachRow: (
    documentRow: DocumentRow,
    indexFromParent: number,
    context: Ctx
  ) => Ctx,
  forEachCol: (
    documentColumn: DocumentColumn,
    indexFromParent: number,
    context: Ctx
  ) => Ctx,
  context?: Ctx,
  indexFromParent?: number
) {
  context = forEachCol(col, indexFromParent, context);
  col.rows.forEach((row, index) =>
    collectFromRow(row, forEachRow, forEachCol, context, index)
  );
}

function collectFromRow<Ctx>(
  row: DocumentRow,
  forEachRow: (
    documentRow: DocumentRow,
    indexFromParent: number,
    context: Ctx
  ) => Ctx,
  forEachCol: (
    documentColumn: DocumentColumn,
    indexFromParent: number,
    context: Ctx
  ) => Ctx,
  context?: Ctx,
  indexFromParent?: number
) {
  context = forEachRow(row, indexFromParent, context);
  row.cols.forEach((col, index) =>
    collectFromCol(col, forEachRow, forEachCol, context, index)
  );
}

function mapFromCol(
  col: DocumentColumn,
  mapRow: (DocumentRowInfo) => DocumentRowInfo,
  mapCol: (DocumentColumnInfo) => DocumentColumnInfo
): DocumentColumn {
  return col
    .update('col', mapCol)
    .update('rows', rows => mapFromRows(rows as any, mapRow, mapCol));
}
function mapFromRow(
  row: DocumentRow,
  mapRow: (DocumentRowInfo) => DocumentRowInfo,
  mapCol: (DocumentColumnInfo) => DocumentColumnInfo
): DocumentRow {
  return row
    .update('row', mapRow)
    .update('cols', cols =>
      cols.map(col => mapFromCol(col as any, mapRow, mapCol))
    );
}
export function mapFromRows(
  rows: List<DocumentRow>,
  mapRow: (DocumentRowInfo) => DocumentRowInfo,
  mapCol: (DocumentColumnInfo) => DocumentColumnInfo
): List<DocumentRow> {
  return rows.map(row => mapFromRow(row, mapRow, mapCol));
}

function collectPathMapFromRows(
  rows: List<DocumentRow>,
  basePath: List<any>
): Map<ViewId, List<any>> {
  let idPathMap = Map<ViewId, List<any>>().asMutable();
  rows.forEach((rootRow, rootIndex) =>
    collectFromRow(
      rootRow,
      (row, index, path) => {
        const rowPath = path.push(index);
        idPathMap = idPathMap.set(row.row.viewId, rowPath);
        return rowPath.push('cols');
      },
      (col, index, path) => {
        const colPath = path.push(index);
        idPathMap = idPathMap.set(col.col.viewId, colPath);
        return colPath.push('rows');
      },
      basePath,
      rootIndex
    )
  );
  return idPathMap.asImmutable();
}

export function collectIdsFromRow(rootRow: DocumentRow): Set<ViewId> {
  let ids = Set().asMutable();
  collectFromRow(
    rootRow,
    row => (ids = ids.add(row.row.viewId)),
    col => (ids = ids.add(col.col.viewId))
  );
  return ids.asImmutable() as Set<string>;
}

export function collectServerIdsFromRows(
  rootRows: List<DocumentRow>
): Map<ViewId, QualifiedServerId> {
  let map = Map<ViewId, QualifiedServerId>().asMutable();
  rootRows.forEach(rootRow =>
    collectFromRow(
      rootRow,
      row => (map = map.set(row.row.viewId, { type: 'row', id: row.row.id })),
      col => (map = map.set(col.col.viewId, { type: 'col', id: col.col.id }))
    )
  );
  return map.asImmutable();
}

// Clone and convert to Records

function cloneDocumentRowInfo(
  row: DocumentRowInfo,
  clearId: boolean,
  viewIdGenerator: (DocumentRowInfo) => ViewId
): DocumentRowInfo {
  let newRow = new DocumentRowInfo(row);
  if (clearId) {
    newRow = newRow.set('id', null);
  }
  newRow = newRow
    .set('viewId', viewIdGenerator(newRow))
    .set('used', !!Number(newRow.used));
  return newRow;
}

function cloneDocumentColumnInfo(
  col: DocumentColumnInfo,
  clearId: boolean,
  viewIdGenerator: (DocumentColumnInfo) => ViewId
): DocumentColumnInfo {
  let newCol = new DocumentColumnInfo(col);
  if (clearId) {
    newCol = newCol.set('id', null);
  }
  newCol = newCol.set('viewId', viewIdGenerator(newCol));
  return newCol;
}

function cloneDocumentRow(
  row: DocumentRow,
  clearId: boolean,
  rowViewIdGenerator: (DocumentRowInfo) => ViewId = generateViewId,
  colViewIdGenerator: (DocumentColumnInfo) => ViewId = generateViewId
): DocumentRow {
  return new DocumentRow({
    row: cloneDocumentRowInfo(row.row, clearId, rowViewIdGenerator),
    cols: List(
      row.cols.map(col =>
        cloneDocumentColumn(
          col,
          clearId,
          rowViewIdGenerator,
          colViewIdGenerator
        )
      )
    ),
  });
}

function cloneDocumentColumn(
  col: DocumentColumn,
  clearId: boolean,
  rowViewIdGenerator: (DocumentRowInfo) => ViewId = generateViewId,
  colViewIdGenerator: (DocumentColumnInfo) => ViewId = generateViewId
): DocumentColumn {
  return new DocumentColumn({
    col: cloneDocumentColumnInfo(col.col, clearId, colViewIdGenerator),
    rows: List(
      col.rows.map(row =>
        cloneDocumentRow(row, clearId, rowViewIdGenerator, colViewIdGenerator)
      )
    ),
  });
}

export function cloneDocument(
  doc: List<DocumentRow>,
  clearId = false,
  rowViewIdGenerator: (DocumentRowInfo) => ViewId = generateViewId,
  colViewIdGenerator: (DocumentColumnInfo) => ViewId = generateViewId
): Document {
  const rows = List(doc).map(row =>
    cloneDocumentRow(row, clearId, rowViewIdGenerator, colViewIdGenerator)
  );
  const serverIdMap = collectServerIdsFromRows(rows);

  return new Document({
    rows: rows,
    viewIdPathMap: collectPathMapFromRows(rows, List()),
    serverIdMap,
  });
}
