import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'app/shared/message';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, debounceTime, first, Subject } from 'rxjs';
import {
  AddUserGQL,
  EditTodoGQL,
  GetUsersGQL,
  RemoveUserGQL,
} from '../project-todo-form/graphql/editTodo.generated';
import { UserFragment } from '../project-todo/graphql/project-todo.generated';
import { Todo, Topic } from '../project-todo/project-todo.component';
import { DeleteTodoGQL, SetDoneGQL } from './graphql/todo.mutation.generated';

@Component({
  selector: 'app-project-todo-subtable',
  templateUrl: './project-todo-subtable.component.html',
  styleUrls: ['./project-todo-subtable.component.scss'],
})
export class ProjectTodoSubtableComponent implements OnInit {
  @Input() public topic: Topic;
  @Output() public editEmit = new EventEmitter<string>();
  @Output() public updateEmit = new EventEmitter<boolean>();

  public columns = [
    {
      header: 'Klart',
      width: '6rem',
      field: 'done',
    },
    {
      header: 'Arbetsmoment',
      width: 'auto',
      field: 'description',
    },
    {
      header: 'Ord.',
      width: '6rem',
      field: 'orderNr',
    },
    {
      header: 'Start',
      width: '8rem',
      field: 'startDate',
    },
    {
      header: 'Slut',
      width: '8rem',
      field: 'endDate',
    },
    {
      header: 'Est.',
      width: '6.5rem',
      field: 'estimatedTime',
    },
    {
      header: 'Utslag',
      width: '6.5rem',
      field: 'timeUsed',
    },
    {
      header: 'Användare',
      width: 'auto',
      field: 'users.length',
    },
  ];

  public readonly maxLengthDescription = 40;
  public users: BehaviorSubject<UserFragment[]> = new BehaviorSubject([]);
  public updateQueue: Todo[] = [];
  public editing: Subject<boolean> = new Subject();

  constructor(
    private setDone: SetDoneGQL,
    private confirmationService: ConfirmationService,
    private deleteService: DeleteTodoGQL,
    private editTodoService: EditTodoGQL,
    private messageService: MessageService,
    private fetchUsersService: GetUsersGQL,
    private addUserService: AddUserGQL,
    private removeUserService: RemoveUserGQL
  ) {}

  public ngOnInit(): void {
    this.fetchUsers();

    this.editing.pipe(debounceTime(100)).subscribe(editing => {
      if (editing) {
        return;
      }
      this.updateQueue.forEach(todo => this.updateTodo(todo));
      this.updateQueue = [];
    });
  }

  private updateTodo(todo: Todo): void {
    if (!todo.description) {
      this.messageService.insertData({
        severity: 'error',
        textArray: ['Arbetsmoment krävs'],
      });
      this.updateEmit.emit(true);
      return;
    }
    this.editTodoService
      .mutate({
        todo: {
          id: Number(todo.id),
          startDate: todo.startDate,
          endDate: todo.endDate,
          description: todo.description,
          orderNr: todo.orderNr,
          estimatedTime: todo.estimatedTime,
        },
      })
      .pipe(first())
      .subscribe(res => {
        this.updateEmit.emit(true);
        this.messageService.insertDataFromMutation(
          res.data.todoTypeHyperionMutation
        );
      });
    this.adjustDiffUsers(todo);
  }

  public addToQueue(todo: Todo): void {
    if (this.updateQueue.includes(todo)) {
      return;
    }
    this.updateQueue.push(todo);
  }

  public setEditing(editing: boolean): void {
    this.editing.next(editing);
  }

  public clearUpdateQueue(): void {
    this.editing.next(false);
  }

  public toggleDone(todo: Todo): void {
    this.setDone
      .mutate({ id: Number(todo.id), done: Number(todo.done) })
      .pipe(first())
      .subscribe(res => {
        const success = res.data.todoTypeHyperionMutation.mutationDetails.every(
          d => d.mutationSucceeded
        );
        this.updateEmit.emit(success);
      });
  }

  public deleteTodo(todo: Todo): void {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort arbetsmomentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteService.mutate({ id: Number(todo.id) }).subscribe(_ => {});
        this.updateEmit.emit();
      },
      reject: () => {},
    });
  }

  private fetchUsers(): void {
    this.fetchUsersService
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const users = res.data.company.users.edges
          .map(n => n.node)
          .filter(u => !u.hidden && !u.isDeleted);
        this.users.next(users);
      });
  }

  private addUserToTodo(userId: number, todoId: number): void {
    this.addUserService
      .mutate({ userId: userId, todoId: todoId })
      .subscribe(_ => {});
  }
  private removeUserFromTodo(id: number): void {
    this.removeUserService.mutate({ id: id }).subscribe(_ => {});
  }

  private adjustDiffUsers(todo: Todo): void {
    const todoId = Number(todo.id);
    const oldUserIds = todo.users.map(u => u.id);

    const addedUserIds = todo.userIds.filter(id => !oldUserIds.includes(id));
    const removedUserIds = oldUserIds.filter(id => !todo.userIds.includes(id));

    const removedRelationIds = todo.relations
      .filter(r => removedUserIds.includes(r.user.id))
      .map(r => Number(r.id));

    addedUserIds
      .map(id => Number(id))
      .forEach(id => this.addUserToTodo(id, todoId));

    removedRelationIds.forEach(id => this.removeUserFromTodo(id));
  }
}
