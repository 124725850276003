import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ElementRef,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { List } from 'immutable';
import { Observable, Subscription } from 'rxjs';
import { CompanyDefinitionService } from '../../shared/company/company-definition.service';
import { first } from 'rxjs/operators';
import { DocuUpsellLogoDialogComponent } from '../docu-upsell-logo-dialog/docu-upsell-logo-dialog.component';
import { AppDialogService } from 'app/shared/dialogs';

@Component({
  selector: 'app-docu-logo',
  templateUrl: './docu-logo.component.html',
  styleUrls: ['./docu-logo.component.scss'],
})
export class DocuLogoComponent implements OnInit, OnDestroy {
  _image: string;

  get image() {
    return this._image;
  }
  @Input() set image(img: string) {
    this._image = img;
    this.loadFailed = false;
  }
  @Input() uploading: boolean;
  @Output() imageChange: EventEmitter<Blob> = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;
  // We need to check this synchronously, to avoid triggering (at least)
  // Firefox's popup blocker
  mayUpload = true;
  private mayUploadSub: Subscription;
  acceptableMimeTypes = List.of('image/png', 'image/jpeg');
  hovering: boolean | 'illegal' = false;
  loadFailed = false;

  constructor(
    private companyDefinitionService: CompanyDefinitionService,
    private dialogService: AppDialogService
  ) {}

  ngOnInit() {
    this.mayUploadSub = this.companyDefinitionService
      .canUploadLogo()
      .subscribe(mayUpload => {
        this.mayUpload = mayUpload;
      });
  }
  ngOnDestroy() {
    this.mayUploadSub.unsubscribe();
  }

  private checkPermission(ifAllowed: () => void) {
    if (this.mayUpload) {
      ifAllowed();
    } else {
      this.dialogService.openComponent(DocuUpsellLogoDialogComponent);
    }
  }

  openPicker() {
    this.checkPermission(() => this.fileInput.nativeElement.click());
  }

  changeLogo(logo: File) {
    this.imageChange.emit(logo);
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  onDragEnter(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    const file = event.dataTransfer.items[0];
    if (file && this.acceptableMimeTypes.contains(file.type)) {
      this.hovering = true;
    } else {
      this.hovering = 'illegal';
    }
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.hovering = false;
  }

  onDrop(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.checkPermission(() => {
      const file = event.dataTransfer.files[0];
      if (file && this.acceptableMimeTypes.contains(file.type)) {
        this.imageChange.emit(file);
      }
      this.hovering = false;
    });
  }

  imageLoaded(successful: boolean) {
    this.loadFailed = !successful;
  }
}
