import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActiveItem } from './active-item';

@Injectable({
  providedIn: 'root',
})
export class ActiveItemLabelService {
  constructor() {}

  public activeItem = new Subject<ActiveItem>();

  public setActiveItem(item: ActiveItem): void {
    this.activeItem.next(item);
  }

  public unsetActiveItem(): void {
    this.activeItem.next(null);
  }
}
