import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

import { MessageService } from 'app/shared/message/index';
import { HelperService } from 'app/shared/helpers/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { AppDialogService } from 'app/shared/dialogs';
import { GlobalService } from 'app/shared/global';

import { ProjectDocumentUploadComponent } from './project-document-upload.component';

@Component({
  selector: 'project-documents',
  templateUrl: 'project-documents.component.html',
  styleUrls: ['project-documents.component.scss'],
  providers: [AppDialogService],
})
export class ProjectDocumentsComponent implements OnDestroy, OnInit {
  constructor(
    private messageService: MessageService,
    public helperService: HelperService,
    private domSanitizer: DomSanitizer,
    private dialogService: AppDialogService,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService
  ) {}

  @Input() projectInfo;

  @Output() dataUpdated = new EventEmitter();

  dataSet = [];
  totalCount;
  previewUrl: SafeUrl;
  getUrl;
  preview = false;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  documentsSub: Subscription;
  public createButtonOptions: MenuItem[] = [
    {
      label: 'Skapa nytt dokument',
      icon: 'pi pi-plus',
      command: () => {
        window.open(
          `${this.globalService.getAppUrlPrefix()}/docu/templates`,
          'struqtur_doku'
        );
      },
    },
  ];

  ngOnInit() {
    this.getDocuments();
  }

  ngOnDestroy() {
    this.documentsSub && this.documentsSub.unsubscribe();
  }

  getDocuments() {
    const variables = { id: +this.projectInfo['id'] };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectDocumentMeta', variables)
      .subscribe(data => {
        this.documentsSub = data.sub;
        this.totalCount =
          data.data['company']['projects']['edges'][0]['node']['documentMeta'][
            'totalCount'
          ];
        this.dataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']['edges'][0]['node']['documentMeta']
        );
        this.loading.next(false);
      });
  }

  public onDataUpdated() {
    this.getDocuments();
    this.dataUpdated.emit();
  }

  onUpload(event) {
    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: dataAsJSON['status'],
    });
  }

  openDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      projectInfo: this.projectInfo,
    };
    this.dialogService
      .openComponent(ProjectDocumentUploadComponent)
      .onClose.subscribe(res => {
        if (typeof res !== 'undefined') {
          if (res === 'success') {
            this.loading.next(true);
            const variables = {
              id: +this.projectInfo['id'],
            };
            this.apolloQueryService
              .apolloQuery('projectDocumentMeta', variables)
              .subscribe();
            this.apolloQueryService
              .apolloQuery('singleProjectTotalCounts', variables)
              .subscribe();
          }
        }
      });
  }

  handleNewPreviewUrl(urls) {
    const previewUrl = urls['preview'];
    const getUrl = urls['get'];

    this.preview = true;
    this.previewUrl =
      this.domSanitizer.bypassSecurityTrustResourceUrl(previewUrl);
    this.getUrl = getUrl;
  }
}
