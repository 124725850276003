import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, first, Observable } from 'rxjs';
import { ProjectCountsGQL } from './graphql/project-count.generated';
export enum ProjectCountFields {
  todos,
  comments,
  pictures,
  documents,
  shares,
  installments,
  supplierInvoices,
  atas,
  attendances,
}

export interface ICountService {
  getCount: (key: any) => Observable<number>;
  setup: (route: ActivatedRoute) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectCountService implements ICountService {
  private counts: { [submenu: string]: BehaviorSubject<number> } = {};

  constructor(private getProjectCountsService: ProjectCountsGQL) {
    Object.keys(ProjectCountFields).forEach(
      key => (this.counts[key] = new BehaviorSubject(0))
    );
  }

  public setup(route: ActivatedRoute): void {
    route.params.pipe(first()).subscribe(params => {
      if (params.id) {
        this.count(params.id);
      } else {
        route.parent.params.pipe(first()).subscribe(params => {
          this.count(params.id);
        });
      }
    });
  }

  public count(id: number): void {
    this.getProjectCountsService
      .fetch({ projectId: id })
      .pipe(first())
      .subscribe(res => {
        const todoCount = res.data.project.todoCount;
        this.counts[ProjectCountFields.todos].next(todoCount);

        const commentCount = res.data.project.commentCount;
        this.counts[ProjectCountFields.comments].next(commentCount);

        const pictureCount = res.data.project.pictureCount;
        this.counts[ProjectCountFields.pictures].next(pictureCount);

        const documentCount = res.data.project.documentCount;
        this.counts[ProjectCountFields.documents].next(documentCount);

        const shareCount = res.data.project.costumerRelationsToProjectCount;
        this.counts[ProjectCountFields.shares].next(shareCount);

        const installmentCount = res.data.project.installmentsCount;
        this.counts[ProjectCountFields.installments].next(installmentCount);

        const supplierInvoiceCount = res.data.project.supplierInvoicesCount;
        this.counts[ProjectCountFields.supplierInvoices].next(
          supplierInvoiceCount
        );

        const ataCount = res.data.project.atasCount;
        this.counts[ProjectCountFields.atas].next(ataCount);

        const attendanceCount = res.data.project.attendanceReportsCount;
        this.counts[ProjectCountFields.attendances].next(attendanceCount);
      });
  }

  public getCount(key: ProjectCountFields): Observable<number> {
    return this.counts[key];
  }
}
