import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'upcomming-activities',
  templateUrl: 'upcoming-activities-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpcommingActivitiesComponent {
  @Input() dataFromParent;
  @Input() loading = true;
}
