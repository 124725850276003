import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/message';
import { first } from 'rxjs';
import { CreateSubcontractorGQL } from '../project-attendance/graphql/attendance.generated';

@Component({
  selector: 'app-project-subcontractor-form',
  templateUrl: './project-subcontractor-form.component.html',
  styleUrls: ['./project-subcontractor-form.component.scss'],
})
export class ProjectSubcontractorFormComponent implements OnInit {
  public subcontractorForm: FormGroup;

  @Output() public createEmitter = new EventEmitter();

  private resetSubcontractorForm(): void {
    this.subcontractorForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      mail: new FormControl(null, [Validators.email]),
      orgNr: new FormControl(null, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(12),
      ]),
    });
  }

  public createSubcontractor(): void {
    this.subcontractorForm.markAllAsTouched();
    if (!this.subcontractorForm.valid) {
      return;
    }

    this.createSubcontractorService
      .mutate({
        name: this.subcontractorForm.controls.name.value,
        mail: this.subcontractorForm.controls.mail.value,
        orgNr: this.subcontractorForm.controls.orgNr.value,
      })

      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.subcontractorTypeHyperionMutation
        );

        this.resetSubcontractorForm();
        this.createEmitter.emit(res.data.subcontractorTypeHyperionMutation.id);
      });
  }

  constructor(
    private messageService: MessageService,
    private createSubcontractorService: CreateSubcontractorGQL
  ) {}

  public ngOnInit(): void {
    this.resetSubcontractorForm();
  }
}
