import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { CompanySuperService } from '../company.super.service';
import { ApolloErrorHandler } from 'app/shared/apollo';

@Injectable()
export class ProjectUserCostTypeService extends CompanySuperService {
  dataModel = 'userCostType';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list') =>
    mode === 'labels'
      ? this.makeLabelsArray(data)
      : this.makeObjectsForForms(data);

  makeLabelsArray(data) {
    const dataArray =
      data.company.projects.edges[0].node[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      if (this.isActive(dataArray[i].node)) {
        const dataObject = {
          procent: dataArray[i].node.procent,
          isMaterial: dataArray[i].node.procent,
          name: dataArray[i].node.name,
          id: dataArray[i].node.id,
        };
        dataObjectsForForms.push(dataObject);
      }
    }
    return dataObjectsForForms;
  }

  getProjectUserCostTypes(
    projectUserCostTypesParam,
    companyUserCostTypesParam
  ) {
    const costTypesForProject = {};
    const costTypesForProjectArray = [];

    if (
      projectUserCostTypesParam['edges'] &&
      companyUserCostTypesParam['company']
    ) {
      const projectUserCostTypes = projectUserCostTypesParam['edges'];
      const companyUserCostTypes =
        companyUserCostTypesParam['company']['userCostTypes']['edges'];

      for (const costTypeIndex in projectUserCostTypes) {
        const costType = projectUserCostTypes[costTypeIndex];
        if (
          !costTypesForProject.hasOwnProperty(costType.node.companyCostTypeId)
        ) {
          costTypesForProject[costType.node.companyCostTypeId] = {};

          costTypesForProject[costType.node.companyCostTypeId]['cost'] =
            costType.node.cost;
          costTypesForProject[costType.node.companyCostTypeId]['id'] =
            costType.node.id;
        }
        costTypesForProject[costType.node.companyCostTypeId]['internalCost'] =
          costType.node.internalCost;
      }

      for (const costTypeIndex in companyUserCostTypes) {
        const costType = companyUserCostTypes[costTypeIndex];

        if (costTypesForProject.hasOwnProperty(costType.node.id)) {
          costTypesForProject[costType.node.id]['name'] = costType.node.name;
        }
      }

      for (const costTypeKey in costTypesForProject) {
        const costType = costTypesForProject[costTypeKey];

        const costTypeObject = {};
        costTypeObject['companyCostTypeId'] = costTypeKey;
        costTypeObject['id'] = costType['id'];

        costTypeObject['name'] = costType['name'];
        costTypeObject['cost'] = costType['cost'];
        costTypeObject['internalCost'] = costType['internalCost'];

        costTypesForProjectArray.push(costTypeObject);
      }
    }
    return costTypesForProjectArray;
  }

  private isActive(node: any): boolean {
    // eslint-disable-next-line eqeqeq
    return node.active == 1;
  }
}
