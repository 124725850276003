import { queries as uncommon } from './uncommon';
import { queries as mailQueries } from './mail';
import { queries as companyQueries } from './company';
import { queries as invoiceQueries } from './invoice';
import { queries as documentQueries } from './document';
import { queries as offersQueries } from './offers';
import { queries as projectQueries } from './project';
import { queries as dayQueries } from './day';
import { queries as attendanceQueries } from './attendance';
import { queries as purchaseTransactionQueries } from './purchaseTransaction';
import { queries as contactQueries } from './contact';

// refetchQueriesNew
import { refetchQueries as offerRefetchQueries } from './offers';
import { refetchQueries as companyRefetchQueries } from './company';

// TODO: Enable all as type Queries (not possible yet due to the lack of migration)
export const rawQueries = {
  ...uncommon,
  ...mailQueries,
  ...companyQueries,
  ...invoiceQueries,
  ...documentQueries,
  ...offersQueries,
  ...projectQueries,
  ...dayQueries,
  ...attendanceQueries,
  ...purchaseTransactionQueries,
  ...contactQueries,
};

/**
 * Parses raw queries into regular queries.
 * This means that a query with the format `query: { query: GQL, testVariables: Object }` will instead become `query: GQL`.
 * i.e. `testVariables` are dropped and the second level `query` is moved one level up to the parent.
 *
 * @param rawQueriesArg the imported queries
 */
function parseRawQueries(rawQueriesArg): any {
  const parsedQueries = {};
  for (const key in rawQueriesArg) {
    if (rawQueriesArg.hasOwnProperty(key)) {
      let query = rawQueriesArg[key];
      if (query.query) {
        query = query.query;
      }
      parsedQueries[key] = query;
    }
  }
  return parsedQueries;
}

export const queries = parseRawQueries(rawQueries);

const refetchQueriesNew = {
  ...offerRefetchQueries,
  ...companyRefetchQueries,
};

export function getRefetchQuerieOf(queryName: string): string {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  if (refetchQueriesNew[queryName] === undefined) {
    throw new Error(
      'Invalid Query Name: Stack Trace: getRefetchQuerieOf ' + queryName
    );
  } else {
    return refetchQueriesNew[queryName];
  }
}

export const mutationDetails = `
  mutationDetails {
    argument
    model
    msg
    errorsMsgs
    warningMsgs
    successMsgs
    infoMsgs
    mutationSucceeded
    modelErrorsMsgs
    modelWarningMsgs
    modelSuccessMsgs
  }`;

export interface Queries {
  [name: string]: Query;
}

export interface Query {
  query: any;
  body: GQLFragment;
  testVariables?: any;
}

/**
 * Short definition of that a GQL Fragment contains.
 * This is in no way complete!
 */
export interface GQLFragment {
  definitions: {
    name: {
      /** The name of the fragment */
      value: string;
    };
  }[];
}

export interface GQLFragments {
  base: GQLFragment;
  extended?: GQLFragment;
  [key: string]: GQLFragment;
}

export interface GQLModel {
  fragments: GQLFragments;
}
