<div class="p-grid">
  <div class="p-col-12 p-md-6 p-g-nopad">
    <connections-subcontractors
      [data]="subcontractorsData"
      mode="subcontractors"
    ></connections-subcontractors>
  </div>
  <div class="p-col-12 p-md-6 p-g-nopad">
    <connections-subcontractors
      [data]="attendanceClientsData"
      mode="attendanceClients"
      (mutationDone)="handleMutationDone()"
    ></connections-subcontractors>
  </div>
</div>
