<div class="p-grid">
  <div class="p-col-12">
    <h3>Du har osparade ändringar! Vill du spara först?</h3>
  </div>
  <div class="p-col-12">
    <app-button type="goBack" (click)="closeAction()"></app-button>
    <app-button
      class="ml5"
      type="discardChanges"
      (click)="closeAction('discard')"
    ></app-button>

    <app-button
      class="pull-right"
      type="save"
      overrideButtonType="primary"
      (click)="closeAction('save')"
    ></app-button>
  </div>
</div>
