<form [formGroup]="productsForm" (ngSubmit)="onSubmit()">
  <div class="p-grid">
    <ng-container formArrayName="products">
      <ng-container *ngFor="let product of products.controls; index as i">
        <ng-container [formGroup]="product">
          <div class="p-col-4" *ngIf="!isEmbedded">
            <label>Datum</label>
            <p-calendar
              formControlName="date"
              firstDayOfWeek="1"
              dateFormat="yy-mm-dd"
              dataType="string"
              [showIcon]="true"
              inputId="icon"
              [showWeek]="true"
            ></p-calendar>
          </div>
          <div class="p-col-8" *ngIf="!isEmbedded">
            <label>Användare</label>
            <p-dropdown
              [options]="users"
              optionLabel="name"
              optionValue="value"
              formControlName="userId"
            ></p-dropdown>
          </div>

          <div *ngIf="!isEmbedded" class="p-col-4">
            <label>Projekt</label>
            <app-project-select
              class="p-d-block"
              [projectId]="product.controls.projectId.value"
              (selectedProjectEvent)="actionChangeProject($event, product)"
            ></app-project-select>
          </div>
          <div [class]="isEmbedded ? 'p-col-4' : 'p-col-8'">
            <label>Kostnad</label>
            <p-dropdown
              [options]="costTypes"
              optionLabel="name"
              optionValue="value"
              formControlName="companyCostTypeId"
            ></p-dropdown>
          </div>
          <div [class]="isEmbedded ? 'p-col-8' : 'p-col-12'">
            <label>Benämning</label>
            <span class="p-inputgroup p-mb-2">
              <span class="p-inputgroup-addon">
                <i class="pi pi-shopping-bag"></i>
              </span>
              <app-product-search
                (productSelected)="selectProduct($event, i)"
                [onlySearchForOwn]="onlySearchForOwn"
                formControlName="benamning"
                [autofocus]="true"
              >
              </app-product-search>
            </span>
            <span *ngIf="isDerome">
              <p-inputSwitch
                [(ngModel)]="onlySearchForOwn"
                [ngModelOptions]="{ standalone: true }"
              ></p-inputSwitch>
              <label class="p-ml-2">Endast egna produkter</label>
            </span>
          </div>
          <div class="p-col-4">
            <label style="width: 50%">Antal</label>
            <label>Enhet</label>
            <span class="p-inputgroup">
              <input
                type="text"
                placeholder="1"
                pInputText
                formControlName="antal"
              />
              <input
                type="text"
                placeholder="st"
                pInputText
                formControlName="enhet"
              />
            </span>
          </div>
          <div class="p-col-4">
            <label>Inköpspris</label>
            <span class="p-inputgroup">
              <input
                type="text"
                placeholder="0"
                pInputText
                formControlName="avtalsprisCost"
                (change)="syncCosts(product)"
              />
              <span class="p-inputgroup-addon"> kr </span>
            </span>
          </div>
          <div class="p-col-4">
            <label>Försäljningspris</label>
            <span class="p-inputgroup">
              <input
                type="text"
                placeholder="0"
                pInputText
                formControlName="avtalspris"
                (change)="syncCosts(product)"
              />
              <span class="p-inputgroup-addon"> kr </span>
            </span>
          </div>
          <div class="p-col-10">
            <p-checkbox
              *ngIf="!isEmbedded"
              label="Extra"
              [binary]="true"
              [formControl]="product.controls.extra"
            ></p-checkbox>
          </div>
          <div *ngIf="!isEdit" class="p-col-2 p-d-flex p-jc-end">
            <button
              pButton
              type="button"
              class="p-button-icon-danger"
              (click)="removeNewProductToAdd(i)"
              icon="pi pi-trash"
            ></button>
          </div>
        </ng-container>
        <div class="p-col-12 p-mb-2 p-mt-2" *ngIf="!isEdit && !isEmbedded">
          <p-divider></p-divider>
        </div>
      </ng-container>
    </ng-container>

    <div class="p-col-12 p-d-flex p-jc-end">
      <button
        pButton
        *ngIf="!isEdit"
        type="button"
        class="p-button-primary-inverted p-mr-2"
        (click)="addNewProductToAdd()"
        icon=" pi pi-plus"
      ></button>
      <button
        *ngIf="!isEmbedded"
        pButton
        class="p-button-primary-hyperion"
        [label]="isEdit ? 'Redigera kostnad' : 'Spara kostnader'"
        type="submit"
      ></button>
    </div>
  </div>
</form>
