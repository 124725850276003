import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Subscription } from 'rxjs';

import { FormHandlerService } from '../../../../shared/forms/index';
import { CompanyInvoiceService } from '../../services/company-invoice.service';

@Component({
  selector: 'app-invoice-head-customer',
  templateUrl: './invoice-head-customer.component.html',
  styleUrls: ['./invoice-head-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InvoiceHeadCustomerComponent,
      multi: true,
    },
    FormHandlerService,
  ],
})
export class InvoiceHeadCustomerComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  formFields = {
    model: 'Contact',
    nestedForm: false,
    attributes: {
      contactType: 'Företag',
      trueId: '',
      address: '',
      address2: '',
      city: '',
      cityCode: '',
      name: '',
      mail: '',
      mailInvoice: '',
      mobilePhone: '',
      orderBuisnessName: '',
      orgNr: '',
      phone: '',
    },
  };

  headCustomerForm: FormGroup;

  subForm: Subscription;

  constructor(
    private companyInvoiceService: CompanyInvoiceService,
    private cdr: ChangeDetectorRef,
    private formhandler: FormHandlerService
  ) {
    this.headCustomerForm = this.formhandler.groupedFormSimple(this.formFields);
    this.formhandler.groupSetLabelsRules(
      this.formFields,
      this.headCustomerForm
    );
    this.companyInvoiceService.updateForm('customer', this.headCustomerForm);
  }

  ngOnInit() {
    this.onChange(this.headCustomerForm.value);

    this.subForm = this.companyInvoiceService.invoiceFormsChange$.subscribe(
      value => {
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy() {
    this.subForm && this.subForm.unsubscribe();
  }

  writeValue(val: any[]) {
    this.headCustomerForm.patchValue(val);
  }

  onChange = (_: any) => {};

  registerOnChange(fn) {
    this.headCustomerForm.valueChanges.subscribe(fn);
    this.onChange = fn;
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.headCustomerForm.disable() : this.headCustomerForm.enable();
  }

  registerOnTouched() {}
}
