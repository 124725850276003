import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { HelperService } from '../../shared/helpers/index';
import { GlobalService } from '../../shared/global/index';
import { ApolloMutationService } from '../../shared/apollo/index';

@Component({
  selector: 'project-installment-container',
  templateUrl: 'project-installment-single-container.component.html',
})
export class ProjectInstallmentSingleContainerComponent implements OnChanges {
  @Input() projectInfo;
  @Input() dataObjectFromParent;
  @Input() labels;
  @Input() invoiceRowTypeListDropdown;
  @Input() optionallyInvoiceTaxDropdown;

  dataSet;
  dataModel = 'projectInstallment';
  dataModelCapitalized;

  constructor(
    public helperService: HelperService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (
        propName === 'dataObjectFromParent' &&
        typeof this.dataObjectFromParent !== 'undefined'
      ) {
        this.dataSet = this.helperService.cleanFromNode(
          this.dataObjectFromParent['rows']
        );
      }
    }
  }

  prepareForms() {
    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
  }

  confirmDeletePlan() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort betalplanen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deletePlan();
      },
      reject: () => {},
    });
  }

  private deletePlan() {
    const crudType = 'delete';
    const dataToMutation = { id: +this.dataObjectFromParent.id };
    const refetchVars = { projectId: +this.projectInfo['id'] };
    const refetchArr = [
      { name: 'projectInstallments', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }
}
