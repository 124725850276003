<div class="p-col-12">
  <div class="p-grid attendance-report-subcontractor-section">
    <div class="p-col-12 p-md-8 p-lg-9">
      <div class="p-inputgroup subcontractor-auto-complete">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <p-autoComplete
          #subcontractor_autocomplete
          [(ngModel)]="autoCompleteQuery"
          [inputStyle]="{ width: '100%' }"
          [style]="{ width: '100%' }"
          placeholder="Sök / lägg till samarbetspartner för valt projekt"
          [suggestions]="autoCompleteResults | async"
          (completeMethod)="
            searchSubcontractor(subcontractor_autocomplete, $event)
          "
          (onSelect)="openAddSubcontractorToProjectDialog($event)"
          (onFocus)="searchSubcontractor(subcontractor_autocomplete)"
          field="name"
        >
          <ng-template let-object pTemplate="item">
            <div class="p-helper-clearfix" style="font-size: 12px">
              <div class="autocomplete-row autocomplete-header-row">
                <div style="width: 49%">Namn</div>
                <div style="width: 49%">Org. Nr</div>
                <div style="float: none" class="clear-both"></div>
              </div>
              <div class="autocomplete-row" *ngIf="object.id">
                <div style="width: 49%">{{ object.name }}</div>
                <div style="width: 49%">{{ object.orgNr }}</div>
                <div style="float: none" class="clear-both"></div>
              </div>
              <div class="autocomplete-row" *ngIf="!object.id">
                <div style="width: 99%">Ni har inga samarbetspartners.</div>
                <div style="float: none" class="clear-both"></div>
              </div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>
    <div class="p-col-12 p-md-4 p-lg-3 align-right">
      <app-button
        (click)="openAddSubcontractorDialog()"
        [type]="'showCreate'"
      ></app-button>
    </div>
  </div>

  <h3>
    Delade projekt till samarbetspartners

    <span
      class="help-tooltip bigger"
      tooltipPosition="right"
      pTooltip="Om ni delar ett projekt med en samarbetspartner så får denna möjlighet att kopiera projektet till sitt egna Struqturkonto. Adressuppgifter samt referens i ert egna projekt läggs till i er samarbetspartners projekt.<br><br>
        •	Grundläggande kontaktuppgifter exporteras.<br>
        •	Ni kan skicka arbetsmoment till de samarbetspartners ni har delat projektet med.<br>
        •	Om samarbetspartnern accepterar delningen så får ni tillgång till deras tidrapporter i projektet.<br>
        •	Mer inställningar hittar ni under 'Delning projekt' i projektmenyn.<br>"
      [escape]="false"
      >?</span
    >
  </h3>
  <p-table [rowHover]="true" [value]="subcontractorsRelations">
    <ng-template pTemplate="header">
      <tr>
        <th colspan="3"></th>
        <th colspan="2"><strong>Förfrågning</strong></th>
        <th colspan="2"><strong>Samarbetspartner</strong></th>
      </tr>
      <tr>
        <th>Företag</th>
        <th>Organisationsnummer</th>
        <th>Mail</th>
        <th>Önskar tidrapporter</th>
        <th>Dela arbetsmoment</th>
        <th>Delar tidrapporter</th>
        <th>Önskar arbetsmoment</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
      <tr>
        <td>{{ rowData['name'] }}</td>
        <td>{{ rowData['orgNr'] }}</td>
        <td>{{ rowData['email'] }}</td>
        <td>
          <p-checkbox
            (onChange)="updateSubcontractorRelation(rowData)"
            [(ngModel)]="rowData['mainContractorWhantsTimereports']"
            [binary]="true"
          ></p-checkbox>
        </td>
        <td>
          <p-checkbox
            (onChange)="updateSubcontractorRelation(rowData)"
            [(ngModel)]="rowData['mainContractorWhantsToPutTodos']"
            [binary]="true"
          ></p-checkbox>
        </td>
        <td>
          <p-checkbox
            *ngIf="rowData['copied']"
            disabled
            [(ngModel)]="rowData['clientSharesTimereports']"
            [binary]="true"
          ></p-checkbox>
        </td>
        <td>
          <p-checkbox
            *ngIf="rowData['copied']"
            disabled
            [(ngModel)]="rowData['clientAcceptTodos']"
            [binary]="true"
          ></p-checkbox>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
