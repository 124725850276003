<div class="p-grid">
  <p-card class="p-col-12 p-xxl-6" [ngClass]="{ 'p-xxl-12': isExpandedCards }">
    <ng-template pTemplate="header">
      <span
        #time
        class="p-d-flex p-ai-center p-jc-between"
        (click)="toggleExpandedCards(time)"
      >
        <h3>{{ isExtra ? 'Extra tidrapporter' : 'Tidrapporter' }}</h3>
        <span class="clickable">
          {{ isExpandedCards ? 'Minimera' : 'Expandera' }}
          <i
            class="pi p-ml-2 pi-plus"
            [ngClass]="{ 'pi-minus': isExpandedCards }"
          ></i>
        </span>
      </span>
    </ng-template>
    <app-project-work-performed
      *ngIf="!shouldLazyLoad"
      [isExpanded]="isExpandedCards"
      (dayUpdatedEvent)="onDayUpdatedEvent($event)"
      [projectData]="projectData"
      [isExtra]="isExtra"
    ></app-project-work-performed>
    <app-project-work-performed-lazy
      *ngIf="shouldLazyLoad"
      [isExpanded]="isExpandedCards"
      (dayUpdatedEvent)="onDayUpdatedEvent($event)"
      [projectData]="projectData"
      [isExtra]="isExtra"
    ></app-project-work-performed-lazy>
  </p-card>
  <p-card class="p-col-12 p-xxl-6" [ngClass]="{ 'p-xxl-12': isExpandedCards }">
    <ng-template pTemplate="header">
      <span
        #work
        class="p-d-flex p-ai-center p-jc-between"
        (click)="toggleExpandedCards(work)"
      >
        <h3 *ngIf="!isExtra">
          {{ mode === modeEnum.COSTS ? 'Kostnader' : 'Intäkter' }}
        </h3>
        <h3 *ngIf="isExtra">
          {{ mode === modeEnum.COSTS ? 'Extra kostnader' : 'Extra intäkter' }}
        </h3>
        <span class="clickable">
          {{ isExpandedCards ? 'Minimera' : 'Expandera' }}
          <i
            class="pi p-ml-2 pi-plus"
            [ngClass]="{ 'pi-minus': isExpandedCards }"
          ></i>
        </span>
      </span>
    </ng-template>
    <app-project-material
      [costTypeData]="projectCostTypes"
      [isExpanded]="isExpandedCards"
      [projectId]="projectId"
      [isExtra]="isExtra"
      (projectProductUpdatedEvent)="onProjectProductUpdatedEvent($event)"
      (modeChanged)="setMode($event)"
    >
    </app-project-material>
  </p-card>
</div>
