import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-offer-signatures',
  templateUrl: './signatures.component.html',
})
export class SignaturesComponent implements OnInit {
  @Input() dataSet = [];
  @Input() offerInfo;
  @Input() labels;

  ngOnInit() {
    if (this.dataSet.length === 0) {
      this.addRow();
    }
  }

  addRow() {
    const newObject = {
      id: null,
      name: null,
      personalNumber: null,
    };

    this.dataSet.push(newObject);
  }

  spliceRow(eventParam, index?) {
    const dataSetArray = this.dataSet;

    if (eventParam !== null) {
      for (const i in dataSetArray) {
        const object = dataSetArray[i];
        if (Number(object['id']) === Number(eventParam)) {
          dataSetArray.splice(+i, 1);
          break;
        }
      }
    } else {
      dataSetArray.splice(+index, 1);
    }

    const newDataSet = dataSetArray;
    this.dataSet = [...newDataSet];
  }

  setRowId(eventParam, index) {
    this.dataSet[+index]['id'] = Number(eventParam['id']);
  }
}
