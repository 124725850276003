import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from 'app/shared/apollo';
import { FormHandlerService } from 'app/shared/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'add-subcontractor',
  templateUrl: './add-subcontractor.component.html',
  providers: [FormHandlerService],
})
export class AddSubcontractorComponent {
  formFields = {
    model: 'Subcontractor',
    attributes: {
      id: '',
      name: '',
      email: '',
      orgNr: '',
    },
  };

  mutationData;
  componentMainForm: FormGroup;
  dataModel = 'subcontractor';
  dataModelCapitalized = 'Subcontractor';
  toolTipMsg =
    '<strong>Märkning</strong><br>Märkningen följer med projektet genom hela processen. Märkningen är tillsammans med projekt id det som visas när man till exempel ska tidrapportera.';

  constructor(
    private dialogRef: DynamicDialogRef,
    private formHandler: FormHandlerService,
    public dialogConfig: DynamicDialogConfig,
    private mutationService: ApolloMutationService
  ) {
    this.dialogConfig.header = 'Skapa ny underentreprenör';
    this.initForm();
  }

  initForm(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    if (this.formHandler.formValid([this.componentMainForm])) {
      const returnAttributes = Object.keys(this.formFields['attributes']);

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          null
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.mutationData = executedData;
              this.closeAction();
            }

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  closeAction() {
    this.dialogRef.close(this.mutationData);
  }
}
