import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dokuadmin',
  templateUrl: './dokuadmin.component.html',
  styleUrls: ['./dokuadmin.component.scss'],
})
export class DokuadminComponent implements OnInit {
  constructor(private http: HttpClient) {}

  public documents: any;

  public ngOnInit(): void {
    this.http
      .get('/website/documents')
      .pipe(first())
      .subscribe((result: any) => (this.documents = result));
  }
}
