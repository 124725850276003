<p-dialog
  [(visible)]="isVisible"
  [header]="title"
  [modal]="true"
  (onHide)="handleCloseLightboxEvent()"
>
  <div>
    <form *ngIf="form" [formGroup]="form">
      <div class="p-d-flex p-flex-column" style="width: 380px">
        <div class="p-mb-4 p-pt-2" style="line-height: 1.2rem">
          {{ introText }}
        </div>
        <div class="p-d-flex" style="gap: 1rem">
          <div>
            <label>Startdatum</label>
            <p-calendar
              [showIcon]="true"
              formControlName="startDate"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              [panelStyleClass]="'edit-planned-time-calendar'"
              [locale]="datePickerLocale"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>

          <div>
            <label>Slutdatum</label>
            <p-calendar
              [showIcon]="true"
              formControlName="endDate"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              [panelStyleClass]="'edit-planned-time-calendar'"
              [locale]="datePickerLocale"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>
        </div>

        <div class="p-mt-4">
          Typ av fil
          <div class="p-d-flex p-flex-wrap p-mt-3">
            <div class="p-d-flex p-ai-center p-mr-2">
              <p-radioButton
                name="fileFormat"
                formControlName="fileFormat"
                value="PDF"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">PDF för utskrift</label>
            </div>
            <div class="p-d-flex p-ai-center">
              <p-radioButton
                name="fileFormat"
                formControlName="fileFormat"
                value="PNG"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">Ladda ner bild</label>
            </div>
          </div>
        </div>

        <div class="p-mt-4" *ngIf="form.get('fileFormat').value === 'PDF'">
          Välj pappersformat

          <div class="p-d-flex p-flex-wrap p-mt-3">
            <div class="p-d-flex p-ai-center p-mr-2">
              <p-radioButton
                name="format"
                formControlName="format"
                value="A3"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">A3</label>
            </div>

            <div class="p-d-flex p-ai-center">
              <p-radioButton
                name="format"
                formControlName="format"
                value="A4"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">A4</label>
            </div>
          </div>

          <div class="p-d-flex p-mt-3">
            <div class="p-d-flex p-ai-center p-mr-2">
              <p-radioButton
                name="landscape"
                formControlName="landscape"
                [value]="false"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">Stående</label>
            </div>

            <div class="p-d-flex p-ai-center">
              <p-radioButton
                name="landscape"
                formControlName="landscape"
                [value]="true"
              ></p-radioButton>
              <label for="format" class="p-ml-2 p-mb-0">Liggande</label>
            </div>
          </div>
        </div>
      </div>

      <p-footer>
        <div class="p-d-flex p-jc-end p-mt-2">
          <p-button
            type="submit"
            [label]="'Exportera'"
            [icon]="''"
            [disabled]="this.form.invalid"
            (click)="exportPlanner()"
          ></p-button>
        </div>
      </p-footer>
    </form>
  </div>
</p-dialog>
