import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Subscription, BehaviorSubject } from 'rxjs';

import { CompanySingleProjectService } from 'app/shared/company/index';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { GlobalService } from 'app/shared/global/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { HelperService } from 'app/shared';

@Component({
  selector: 'project-content',
  templateUrl: 'project-content.component.html',
  providers: [CompanySingleProjectService],
})
export class ProjectContentComponent implements OnInit, OnDestroy {
  @Input() projectFromIndex;
  @Input() projectParams;
  @Input() projectLabels;
  @Input() functionsThisModel;
  @Input() functionsData;
  @Input() CompanyUserCostTypes;
  @Input() meUser;
  @Input() mode;
  @Output() projectMessageChange = new EventEmitter();
  @Output() todoCrudAction = new EventEmitter();

  dataModel = 'singleProject';
  viewMode = 'mainInfo';
  loading = new BehaviorSubject(false);
  shortMessageButton = {
    disabled: false,
  };
  componentPrefix;
  projectInfo = {
    shortMessage: {
      placeholder: '',
      data: '',
      isSet: false,
    },
    id: null,
    status: null,
    mark: null,
    trueId: null,
    client: null,
    establishment: null,
    offerId: null,
    offertId: null,
  };
  projectData = {};
  menuItemsObj = {
    mainInfo: {
      label: 'Huvudinfo',
      icon: 'fa fa-info',
      command: event => {
        this.viewMode = 'mainInfo';
      },
    },
    work: {
      label: 'Arbetsmoment',
      icon: 'fa fa-calendar-check-o',
      command: event => {
        this.viewMode = 'work';
      },
    },
    comment: {
      label: 'Kommentarer',
      icon: 'fa fa-commenting-o',
      command: event => {
        this.viewMode = 'comment';
      },
    },
    image: {
      label: 'Bilder',
      icon: 'fa fa-picture-o',
      command: event => {
        this.viewMode = 'image';
      },
    },
    document: {
      label: 'Dokument',
      icon: 'fa fa-file-text-o',
      command: event => {
        this.viewMode = 'document';
      },
    },
    shareProject: {
      label: 'Delning projekt',
      icon: 'fa fa-share-square-o',
      command: event => {
        this.viewMode = 'shareProject';
      },
    },
    rotProject: {
      label: 'ROT',
      icon: 'fa fa-percent',
      command: event => {
        this.viewMode = 'rotProject';
      },
    },
    installment: {
      label: 'Betalningsplan',
      icon: 'fa fa-money',
      command: event => {
        this.viewMode = 'installment';
      },
    },
    lev: {
      label: 'Leverantörsfakturor',
      icon: 'fa fa-inbox',
      command: event => {
        this.viewMode = 'lev';
      },
    },
    ata: {
      label: 'ÄTA',
      icon: 'fa fa-plus',
      command: event => {
        this.viewMode = 'ata';
      },
    },
    personlliggare: {
      label: 'Personlliggare',
      icon: 'fa fa-users',
      command: event => {
        this.viewMode = 'personlliggare';
      },
    }, // * New
  };

  projectSub: Subscription;
  totalCountSub: Subscription;
  public menuItems: MenuItem[] = [];
  attendanceReportsSub: any;
  attendanceReportsTotal = 0;
  routerSub: Subscription;
  projectSub1: any;
  pictureTotalCounts = 0;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService,
    private helperService: HelperService,
    private mutationService: ApolloMutationService,
    private singleProjectService: CompanySingleProjectService
  ) {}

  ngOnInit() {
    this.projectInfo = {
      shortMessage: {
        placeholder: 'Här kan du ange ett viktigt meddelande för detta projekt',
        data: '',
        isSet: false,
      },
      id: +this.projectFromIndex['id'],
      status: +this.projectFromIndex['status'],
      trueId: this.projectFromIndex['trueId'],
      mark: this.projectFromIndex['mark'],
      client: this.projectFromIndex['client'],
      establishment: this.projectFromIndex['establishment'],
      offerId: this.projectFromIndex['offerId'],
      offertId: this.projectFromIndex['offertId'],
    };

    this.componentPrefix = this.globalService.generateComponentPrefix();

    if (this.mode !== 'attendance') {
      this.reloadProjectData();
      this.getAttendanceReportProjects();
    } else {
      this.viewMode = 'personlliggare';

      this.getAttendanceReportProjects();
    }
  }

  reloadProjectDataLight() {
    const variables = { id: Number(this.projectInfo['id']) };

    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel, variables)
      .subscribe(data => {
        this.projectSub1 = data.sub;
        data = this.singleProjectService.dataFormater(data.data, 'stripNode');
        data = this.appendExternalId(data);
        this.projectData = data;
      });
  }

  reloadProjectData() {
    this.loading.next(true);

    this.setProjectShortMessage(this.projectFromIndex.shortMessage);

    this.getProject(this.projectInfo['id']);
    this.getTotalCountsFromServer(this.projectInfo['id']);
  }

  private setProjectShortMessage(message) {
    this.projectInfo['shortMessage']['data'] = message || '';
    this.projectInfo['shortMessage']['isSet'] = message ? true : false;
  }

  getTotalCountsFromServer(idParam) {
    const variables = { id: Number(idParam) };
    this.apolloQueryService
      .apolloWatchQueryTwo('singleProjectTotalCounts', variables)
      .subscribe(data => {
        this.totalCountSub = data.sub;
        this.setTotalCounts(data.data);
      });
  }

  getProject(idParam) {
    this.projectData = {};
    const variables = { id: Number(idParam) };

    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel, variables)
      .subscribe(data => {
        this.projectSub = data.sub;
        data = this.singleProjectService.dataFormater(data.data, 'stripNode');
        data = this.appendExternalId(data);
        this.projectData = data;
        this.setProjectShortMessage(data['shortMessage']);
        this.setMenuItems();
        this.loading.next(false);
      });
  }

  private appendExternalId(data: any): any {
    return {
      ...data,
      externalId:
        (data.externalIds &&
          data.externalIds.edges &&
          data.externalIds.edges.length &&
          this.helperService.cleanFromNode(data.externalIds)[0]) ||
        null,
    };
  }

  setTotalCounts(data) {
    const project = data.project;
    const totalCounts = {
      todos: 0,
      comments: 0,
      pictureComments: 0,
      documentMeta: 0,
      costumerRelationsToProject: 0,
      ata: 0,
    };
    this.pictureTotalCounts = project['pictureComments']['totalCount'];
    totalCounts['todos'] = project['todos']['totalCount'];
    totalCounts['comments'] = project['comments']['totalCount'];
    totalCounts['pictureComments'] = project['pictureComments']['totalCount'];
    totalCounts['documentMeta'] = project['documentMeta']['totalCount'];
    totalCounts['costumerRelationsToProject'] =
      project['costumerRelationsToProject']['totalCount'];
    totalCounts['ata'] = project['atas']['totalCount'];

    const rowsCountMoved =
      project['invoiceData_PreProjectTypeHyperion']['supplier'][
        'rowsCountMoved'
      ];
    const rowsCountUnMoved =
      project['invoiceData_PreProjectTypeHyperion']['supplier'][
        'rowsCountUnMoved'
      ];
    totalCounts['supplierInvoiceRowsSTAT'] =
      rowsCountUnMoved > 0
        ? rowsCountUnMoved
        : rowsCountMoved > 0
        ? rowsCountMoved
        : 0;

    this.menuItemsObj['work']['label'] =
      'Arbetsmoment (' + totalCounts['todos'] + ')';
    this.menuItemsObj['comment']['label'] =
      'Kommentarer (' + totalCounts['comments'] + ')';
    this.menuItemsObj['image']['label'] =
      'Bilder (' + totalCounts['pictureComments'] + ')';
    this.menuItemsObj['document']['label'] =
      'Dokument (' + totalCounts['documentMeta'] + ')';
    this.menuItemsObj['shareProject']['label'] =
      'Delning projekt (' + totalCounts['costumerRelationsToProject'] + ')';
    this.menuItemsObj['lev']['label'] =
      'Leverantörsfakturor (' + totalCounts['supplierInvoiceRowsSTAT'] + ')';
    this.menuItemsObj['ata']['label'] = 'Äta (' + totalCounts['ata'] + ')';

    rowsCountUnMoved > 0 &&
      (this.menuItemsObj['lev']['styleClass'] = 'is-unmoved-rows');
  }

  setMenuItems() {
    this.menuItems = [];

    if (this.meUser['type'] === '1') {
      this.coworkerAddItems();
    }
    if (this.meUser['type'] === '2') {
      this.coworkerAddItems();
      this.advancedAddItems();
    }
    if (this.meUser['type'] === '3') {
      this.coworkerAddItems();
      this.advancedAddItems();
    }

    if (this.meUser['type'] === '3' || this.meUser['type'] === '2') {
      this.functionsThisModel['useModuleSupplierInvoice'] &&
        this.menuItems.push(this.menuItemsObj['lev']);
      this.menuItems.push(this.menuItemsObj['ata']);
    }

    if (this.mode) {
      this.viewMode = 'personlliggare';
    }

    this.menuItems.push(this.menuItemsObj['personlliggare']);
  }

  coworkerAddItems() {
    this.menuItems.push(this.menuItemsObj['mainInfo']);
    this.menuItems.push(this.menuItemsObj['work']);
    this.menuItems.push(this.menuItemsObj['comment']);
    this.menuItems.push(this.menuItemsObj['image']);
    this.menuItems.push(this.menuItemsObj['document']);
  }

  advancedAddItems() {
    this.menuItems.push(this.menuItemsObj['shareProject']);
    this.menuItems.push(this.menuItemsObj['rotProject']);
    this.menuItems.push(this.menuItemsObj['installment']);
  }

  setShortMessage() {
    const variables = {
      id: Number(this.projectInfo.id),
      shortMessage: this.projectInfo.shortMessage.data,
    };

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation('Project', 'update', false, variables)
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            if (this.projectInfo['shortMessage']['data'] !== '') {
              this.projectInfo['shortMessage']['isSet'] = true;
            } else {
              this.projectInfo['shortMessage']['isSet'] = false;
            }
            this.projectMessageChangeEmit(
              variables['id'],
              variables['shortMessage']
            );
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  projectMessageChangeEmit(idParam, messageParam) {
    const data = {
      id: idParam,
      message: messageParam,
    };

    this.projectMessageChange.emit(data);
  }

  getAttendanceReportProjects() {
    const projectId = Number(this.projectInfo['id']);

    const variables = { id: projectId };

    this.menuItemsObj['personlliggare']['disabled'] = true;

    this.attendanceReportsSub = this.apolloQueryService
      .apolloQuery('attendanceReports', variables)
      .subscribe(({ data }) => {
        this.attendanceReportsTotal = 0;
        if (data['projectGlobal']) {
          const cleanedData = this.apolloQueryService.cleanFromNode(
            data['projectGlobal']['attendanceReportsStopParentIsNull']
          );
          cleanedData.forEach(user => {
            user['realUser_PreAttendanceReportTypeHyperion']['class'] !==
              'AttendanceFreeUser' && this.attendanceReportsTotal++;
          });
          this.menuItemsObj['personlliggare']['disabled'] = false;

          this.projectInfo['subcontractorRelationToProjectId'] =
            data['projectGlobal']['subcontractorRelationToProjectId'];
        }

        if (this.mode === 'attendance') {
          this.loading.next(false);
        }

        this.menuItemsObj['personlliggare']['label'] =
          'Personlliggare (' + this.attendanceReportsTotal + ')';
        this.setMenuItems();
      });
  }

  ngOnDestroy() {
    this.routerSub && this.routerSub.unsubscribe();
    this.totalCountSub && this.totalCountSub.unsubscribe();
    this.projectSub && this.projectSub.unsubscribe();
    this.projectSub1 && this.projectSub1.unsubscribe();
    this.attendanceReportsSub && this.attendanceReportsSub.unsubscribe();
  }
}
