<p-table
  [value]="dataSet"
  #supplierInvoiceTable
  [rowTrackBy]="helperService.rowTrackBy"
  dataKey="id"
  [paginator]="dataSet.length > 50"
  [rowsPerPageOptions]="[50, 100, 250, 500]"
  [rows]="50"
  paginatorPosition="both"
  sortField="fdatum"
  [sortOrder]="-1"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Faktura</th>
      <th>Fakturadatum</th>
      <th>Leverantör</th>
      <th style="width: 5rem">Visa</th>
      <th style="width: 5rem">Hämta</th>
      <th>Dokument</th>
      <th>Attestera</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-expanded="expanded">
    <tr>
      <td class="cursor-pointer" [pRowToggler]="rowData">
        {{ rowData['fakturaNr'] }}
      </td>
      <td [pRowToggler]="rowData">{{ rowData.fdatum }}</td>
      <td class="cursor-pointer p-text-truncate" [pRowToggler]="rowData">
        {{ rowData['supplier']['orderBuisnessName'] }}
      </td>
      <td>
        <i
          class="fa fa-file-o link"
          (click)="openDocument(rowData.id)"
          pTooltip="Förhandsgranska"
          tooltipPosition="top"
        ></i>
      </td>
      <td>
        <a [href]="urlPrefix + '/invoice/Print/type/getPDF/id/' + rowData.id">
          <i class="fa fa-download" pTooltip="Hämta" tooltipPosition="top"></i>
        </a>
      </td>
      <td>
        <div
          *ngFor="
            let sourceDoc of rowData.invoiceSourceDocuments_PreInvoiceTypeHyperion
          "
        >
          <span
            class="link"
            style="margin-right: 3px"
            (click)="openSourcedocument(sourceDoc.url)"
            >{{ sourceDoc.label }}</span
          >
        </div>
      </td>
      <td>
        <span *ngIf="rowData.allRowsMovedToProjectProduct"
          >Alla rader är attesterade</span
        >
        <div class="p-grid" *ngIf="!rowData.allRowsMovedToProjectProduct">
          <p-dropdown
            class="p-col-8"
            tooltipPosition="top"
            pTooltip="Välj materialtyp"
            [(ngModel)]="companyCostTypeId"
            [options]="functionsData.companyCostTypes"
            filter="true"
            appendTo="body"
          ></p-dropdown>
          <button
            (click)="saveInvoiceToProject(rowData)"
            [disabled]="isSaveInProgress"
            class="p-col-4"
            pButton
            icon="pi pi-save"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template
    let-invoice
    let-rowData
    pTemplate="rowexpansion"
    class="expanded-row"
  >
    <tr class="p-table-expanded-row">
      <td [attr.colspan]="7">
        <h4>Fakturarader</h4>
        <div style="overflow-x: auto; overflow-y: hidden">
          <p-table
            [value]="invoice.rows"
            styleClass="supplier-invoice-table"
            [rowTrackBy]="helperService.rowTrackBy"
            [sortField]="sort.attribute"
            [sortOrder]="sort.ascDesc"
            (onSort)="changeSort($event)"
            [rowHover]="false"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pSortableColumn="benamning" style="width: 12rem">
                  Benämning <p-sortIcon field="benamning"></p-sortIcon>
                </th>
                <th pSortableColumn="antal" style="width: 8rem">
                  Antal <p-sortIcon field="antal"></p-sortIcon>
                </th>
                <th pSortableColumn="apris" style="width: 7rem">
                  À-pris <p-sortIcon field="apris"></p-sortIcon>
                </th>
                <th pSortableColumn="discountOriginal" style="width: 8rem">
                  Rabatt lev.
                  <p-sortIcon field="discountOriginal"></p-sortIcon>
                </th>
                <th pSortableColumn="pris" style="width: 10rem">
                  Summa Lev.
                  <p-sortIcon field="pris"></p-sortIcon>
                </th>
                <th style="width: 9rem">
                  Rabatt À-pris
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    [pTooltip]="
                      'Detta fält fylls i om du vill ge din kund en rabatt på leverantörens listpris'
                    "
                    [escape]="false"
                    >?</span
                  >
                </th>
                <th style="width: 9rem">
                  <span
                    >Påslag lev.pris
                    <span
                      class="help-tooltip"
                      tooltipPosition="top"
                      [pTooltip]="
                        'Detta fält fylls i om du vill lägga ett påslag på priset som du betalat för varan'
                      "
                      [escape]="false"
                      >?</span
                    >
                  </span>
                </th>
                <th pSortableColumn="prisWithDiscount" style="width: 9rem">
                  Summa Kund
                  <p-sortIcon field="prisWithDiscount"></p-sortIcon>
                </th>
                <th style="width: 9rem">Normal/Extra</th>
                <th style="width: 10rem">Projekt</th>
                <th style="width: 10rem">Materialtyp</th>
                <th style="width: 4rem">Spara</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr>
                <td style="white-space: break-spaces">
                  {{ rowData['benamning'] }}
                </td>
                <td>
                  <span
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    [class.p-inputgroup]="rowData.enhet"
                  >
                    <input
                      pInputText
                      [(ngModel)]="rowData.antal"
                      (change)="updatePris(rowData)"
                    />
                    <span class="p-inputgroup-addon" *ngIf="rowData.enhet">
                      {{ rowData.enhet }}
                    </span>
                  </span>
                  <span *ngIf="!(rowData.isMovedToProjectProductId < 1)"
                    >{{ rowData.antal }} {{ rowData.enhet }}</span
                  >
                </td>
                <td>{{ rowData['apris'] | currency: 'SEK' }}</td>
                <td>{{ rowData.discountOriginal }} %</td>
                <td>{{ rowData['pris'] | currency: 'SEK' }}</td>
                <td>
                  <div
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    class="p-inputgroup"
                  >
                    <input
                      type="number"
                      pInputText
                      [(ngModel)]="rowData.discount"
                      (change)="updatePris(rowData)"
                      placeholder="%"
                    />
                    <span class="p-inputgroup-addon"> % </span>
                  </div>
                  <span *ngIf="!(rowData.isMovedToProjectProductId < 1)"
                    >{{ rowData.discount }} %</span
                  >
                </td>
                <td>
                  <div
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    class="p-inputgroup"
                  >
                    <input
                      type="number"
                      pInputText
                      [(ngModel)]="rowData.surcharge"
                      (change)="updatePris(rowData)"
                      placeholder="%"
                    />
                    <span class="p-inputgroup-addon"> % </span>
                  </div>
                  <span *ngIf="!(rowData.isMovedToProjectProductId < 1)"
                    >{{ rowData.surcharge }} %</span
                  >
                </td>
                <td>
                  {{ rowData['prisWithDiscount'] | currency: 'SEK' }}
                </td>
                <td>
                  <span *ngIf="!(rowData.isMovedToProjectProductId < 1)">
                    <span *ngIf="rowData.extra == 0">Normal</span>
                    <span *ngIf="rowData.extra == 1">Extra</span>
                  </span>
                  <p-dropdown
                    [(ngModel)]="rowData.extra"
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    appendTo="body"
                    [options]="extraDropdown"
                  ></p-dropdown>
                </td>
                <td>
                  <div
                    *ngIf="!(rowData.isMovedToProjectProductId < 1)"
                    class="p-text-truncate"
                  >
                    {{ getProjectLabel(rowData.project_id) }}
                  </div>
                  <p-dropdown
                    [(ngModel)]="rowData.project_id"
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    appendTo="body"
                    [options]="projectDropdown"
                    filter="true"
                  ></p-dropdown>
                </td>
                <td>
                  <p-dropdown
                    *ngIf="rowData.isMovedToProjectProductId < 1"
                    [options]="functionsData.companyCostTypes"
                    [(ngModel)]="rowData.companyCosttypeId"
                    appendTo="body"
                    filter="true"
                  ></p-dropdown>
                  <span *ngIf="rowData.isMovedToProjectProductId > 1">
                    {{ rowData.productType }}
                  </span>
                </td>
                <td>
                  <p-checkbox
                    [(ngModel)]="rowData.move"
                    [binary]="true"
                    [disabled]="!(rowData.isMovedToProjectProductId < 1)"
                  ></p-checkbox>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage" let-columns>
              <tr class="emptyStateTR">
                <td [attr.colspan]="9">
                  <app-emptyState></app-emptyState>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div
          style="padding: 10px 0"
          *ngIf="!rowData.allRowsMovedToProjectProduct"
        >
          <button
            pButton
            icon="pi pi-save"
            label="Spara"
            [disabled]="isSaveInProgress"
            class="p-button-primary-hyperion"
            style="float: right"
            (click)="saveMarkedRows(invoice)"
          ></button>
          <div class="clear-both"></div>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr class="emptyStateTR">
      <td [attr.colspan]="7">
        <app-emptyState [model]="'Invoice'"></app-emptyState>
      </td>
    </tr>
  </ng-template>
</p-table>
