<app-header> </app-header>

<div class="page-container">
  <hyperion-card>
    <div class="p-grid home-recommend-container">
      <ng-container
        *ngIf="showThankYou; then thankYou; else signUp"
      ></ng-container>
    </div>
  </hyperion-card>

  <ng-template #signUp>
    <div class="p-grid-col-7 home-recommend-content">
      <h1 class="struqtur-and-company-name">
        <img class="logo" [src]="appLogoPath" /> + {{ companyName }}
      </h1>

      <h2>Rekommendera {{ appName }} till en vän</h2>
      <h3>Du får 20% rabatt baserad på din väns årsavgift</h3>
      <p>
        När du rekryterar en vän får du 20% rabatt på din faktura beräknat på
        den första årsavgiften av din nyanslutna vän!
      </p>
      <h3>Din vän får 10% rabatt hela första året</h3>
      <p>
        Dessutom får din vän 10% rabatt hela första året av oss. En win-win helt
        enkelt!
      </p>
    </div>

    <div class="p-grid-col-5 home-recommend-form-content">
      <form [formGroup]="signupForm" (ngSubmit)="submitForm()">
        <div class="p-fluid">
          <div class="p-field">
            <label for="companyName">Ditt företag</label>
            <input
              id="companyName"
              name="company_name"
              type="text"
              readonly
              formControlName="companyName"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('companyName').errors &&
                signupForm.get('companyName').touched
              "
            >
              {{ signupForm.get('companyName').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="name">Ditt namn</label>
            <input
              id="name"
              name="name"
              type="text"
              formControlName="name"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('name').errors && signupForm.get('name').touched
              "
            >
              {{ signupForm.get('name').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="phone">Ditt telefonnummer</label>
            <input
              id="phone"
              name="phone"
              type="text"
              formControlName="phone"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('phone').errors &&
                signupForm.get('phone').touched
              "
            >
              {{ signupForm.get('phone').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="email">Din email</label>
            <input
              id="email"
              name="email"
              type="text"
              formControlName="email"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('email').errors &&
                signupForm.get('email').touched
              "
            >
              {{ signupForm.get('email').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="prospectCompanyName"
              >Företaget som du vill rekommendera</label
            >
            <input
              id="prospectCompanyName"
              name="prospect_company_name"
              type="text"
              formControlName="prospectCompanyName"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('prospectCompanyName').errors &&
                signupForm.get('prospectCompanyName').touched
              "
            >
              {{ signupForm.get('prospectCompanyName').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="prospectName">Kontaktperson på företaget</label>
            <input
              id="prospectName"
              name="prospectName"
              type="text"
              formControlName="prospectName"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('prospectName').errors &&
                signupForm.get('prospectName').touched
              "
            >
              {{ signupForm.get('prospectName').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <label for="prospectPhone">Kontaktpersonens telefonnummer</label>
            <input
              id="prospectPhone"
              name="prospect_phone"
              type="text"
              formControlName="prospectPhone"
              pInputText
            />
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('prospectPhone').errors &&
                signupForm.get('prospectPhone').touched
              "
            >
              {{ signupForm.get('prospectPhone').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <p-checkbox
              name="acceptOne"
              label="Jag har pratat med ovanstående person och rekommenderar {{
                appName
              }} att kontakta denne, eftersom jag anser att {{
                appName
              }} kan vara en bra lösning för personen."
              formControlName="acceptOne"
              [binary]="true"
            ></p-checkbox>
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('acceptOne').errors &&
                signupForm.get('acceptOne').touched
              "
            >
              {{ signupForm.get('acceptOne').errors.message }}
            </p>
          </div>
          <div class="p-field">
            <p-checkbox
              name="acceptTwo"
              label="Jag godkänner villkoren för kundrekommendationerna samt att {{
                appName
              }} kontaktar personen ovan och refererar till mig som {{
                appName
              }}-kund."
              formControlName="acceptTwo"
              [binary]="true"
            ></p-checkbox>
            <p
              appFormErrorMessage
              *ngIf="
                signupForm.get('acceptTwo').errors &&
                signupForm.get('acceptTwo').touched
              "
            >
              {{ signupForm.get('acceptTwo').errors.message }}
            </p>
            <p>
              <a (click)="showTermsDetails()"
                >Visa villkoren för rekommendationer</a
              >
            </p>
          </div>
          <button
            pButton
            type="submit"
            class="p-button-primary-hyperion"
            label="Skicka rekommendation"
            icon="pi pi-send"
            [disabled]="loading"
          ></button>
        </div>
        <p-dialog
          header="Villkor för rekommendationer"
          [modal]="true"
          [dismissableMask]="true"
          [draggable]="false"
          [(visible)]="popupTermsDetailsVisible"
          styleClass="terms-details-popup"
        >
          <h4>Förutsättningar och villkor</h4>
          <p>
            Ersättning erhålls i form av avdrag på er faktura för nästkommande
            avtalsperiod. En förutsättning för utbetalning är att den nya kunden
            betalat sin faktura.
          </p>
          <p>
            Är ersättningsbeloppet högre än er faktura, betalas kvarvarande
            ersättning ut på efterföljande fakturor.
          </p>
          <p>
            Rekommendationen är inte ersättningsgrundande om {{ appName }}, vid
            tidpunkten för rekommendationen, redan för diskussioner med det
            aktuella företaget om att investera i {{ appName }}.
          </p>
          <p>
            {{ appName }} avgör vad som är en rekommendation och om denna
            uppfyller kraven för att ersättning skall utbetalas.
          </p>
        </p-dialog>
      </form>
    </div>
  </ng-template>

  <ng-template #thankYou>
    <div class="p-grid-col-12" class="thank-you">
      <h1>Tack för din rekommendation!</h1>
      <p>
        En av våra säljare kommer nu kontakta din vän. Vi meddelar dig så fort
        affären är gjord!
      </p>
    </div>
  </ng-template>
</div>
