import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { MessageService } from 'app/shared/message/index';
import { HelperService } from 'app/shared/helpers/index';
import { GlobalService } from 'app/shared/global/index';

@Component({
  selector: 'project-todo-row',
  templateUrl: 'project-todo-row.component.html',
  providers: [FormHandlerService],
})
export class ProjectTodoRowComponent implements OnChanges, OnInit {
  @Input() dataObjectFromParent;
  @Input() usersDropdown;
  @Input() subcontractorsDropdown;
  @Input() meUser;
  @Output() updateRow = new EventEmitter();
  @Output() estimatedTimeChange = new EventEmitter();

  dataModel = 'todo';
  dataModelCapitalized = 'Todo';
  buttons;
  text: string;
  componentPrefix: number;
  avtalspris: string = null;
  results = [];
  userTodoRelations = [];
  loading = false;
  doneBoolean: boolean;
  public componentMainForm: ExtendedFormGroup;
  formFields = {
    model: this.dataModelCapitalized,
  };

  constructor(
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    const buttonObject = {
      update: {
        model: this.dataModel,
      },
      delete: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.formInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (
        propName === 'dataObjectFromParent' &&
        typeof this.dataObjectFromParent !== 'undefined'
      ) {
        this.addToUserTodoRelations();
      }
    }
  }

  addToUserTodoRelations() {
    this.userTodoRelations = [];
    const relationArr = this.helperService.cleanFromNode(
      this.dataObjectFromParent['usersTodoRelation']
    );

    for (const index in relationArr) {
      const relation = relationArr[index];
      delete relation['__typename'];
      this.userTodoRelations.push(relation);
    }
  }

  formInit() {
    this.setFieldsForForm();
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  setFieldsForForm(): void {
    const attr = new Object();
    const attrToExclude = [
      'topic',
      'days',
      'offerId',
      'descriptionOffer',
      'type',
      'utslag',
      'usersTodoRelation',
      '__typename',
    ];
    for (const dataObjectIndex in this.dataObjectFromParent) {
      if (!attrToExclude.includes(dataObjectIndex)) {
        const keyValue = this.dataObjectFromParent[dataObjectIndex];
        attr[dataObjectIndex] = keyValue;
      }
    }
    this.setCheckbox();
    this.formFields['attributes'] = attr;
  }

  setCheckbox() {
    /* eslint-disable eqeqeq */
    this.doneBoolean = !(
      this.dataObjectFromParent.done == null ||
      this.dataObjectFromParent.done == 0
    );
    /* eslint-enable eqeqeq */
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.getMutationDataFromForm();

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      this.loading = true;
      const returnAttributes = ['id', 'description', 'done'];
      const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
      const refetchArr = [{ name: 'projectTodos', variables: refetchVars }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            this.updateRowEmit(executedData);
            this.loading = false;
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  confirmDeleteTodoRow() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort arbetsmomentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.buttons = this.formHandler.lockButtons(this.buttons);
        this.deleteTodoRow();
      },
      reject: () => {},
    });
  }

  private deleteTodoRow() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };
    const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
    const refetchArr = [
      { name: 'projectTodos', variables: refetchVars },
      { name: 'singleProjectTotalCounts', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          this.formHandler.showServerErrorsOnAttributes(executedData, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);
          if (!executedData.mutationSucceededAllArguments) {
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }

          executeMutationSub.unsubscribe();
        },
        err => {
          console.error(err);
          this.messageService.insertData({ textArray: [err], type: 'error' });
          this.buttons = this.formHandler.unlockButtons(this.buttons);
        }
      );
  }

  addUser(value) {
    let isInTodoRelations = false;
    for (const i in this.userTodoRelations) {
      const userTodoRelation = this.userTodoRelations[i];

      if (Number(value) === userTodoRelation['userId']) {
        isInTodoRelations = true;
      }
    }

    if (!isInTodoRelations) {
      this.createUserTodoRelation(value);
    }
  }

  shareTodo(value) {
    if (value !== null) {
      const dataToMutation = {
        relationType: 0,
        todoId: this.dataObjectFromParent.id,
        subcontractorRelationToProjectId: value,
      };
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'subcontractorTodoTodoRelation',
          'create',
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            executeMutationSub.unsubscribe();
          },
          err => {}
        );
    }
  }

  addEmittedUserTodoRelation(emittedObj) {
    const userTodoRelation = {
      id: +emittedObj['id'],
      userId: emittedObj['userId'],
      todoId: emittedObj['todoId'],
    };
    this.userTodoRelations.push(userTodoRelation);
  }

  deleteEmittedUserTodoRelation(userTodoRelationIdParam) {
    // eslint-disable-next-line prefer-const
    let relaId = Number(userTodoRelationIdParam);

    for (const index in this.userTodoRelations) {
      const relation = this.userTodoRelations[index];

      if (Number(relation['id']) === relaId) {
        this.userTodoRelations.splice(+index, 1);
        break;
      }
    }
  }

  getUserName(userId) {
    let username: string;

    for (const index in this.usersDropdown) {
      const user = this.usersDropdown[index];

      if (Number(user['value']) === Number(userId)) {
        username = user['label'];
      }
    }

    return username;
  }

  createUserTodoRelation(userIdParam) {
    const crudType = 'create';
    const dataToMutation = {
      todoId: Number(this.dataObjectFromParent['id']),
      userId: userIdParam,
    };

    const returnArgs = ['id', 'userId', 'todoId'];
    this.buttons = this.formHandler.lockButtons(this.buttons);

    const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
    const refetchArr = [{ name: 'projectTodos', variables: refetchVars }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'userTodoRelation',
        crudType,
        false,
        dataToMutation,
        returnArgs,
        refetchArr
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          this.buttons = this.formHandler.unlockButtons(this.buttons);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.buttons = this.formHandler.unlockButtons(this.buttons);
        }
      );
  }

  utslagUserHoursTooltips() {
    let tooltip =
      '<table><tr><td style="padding-right:15px"><strong>Användare</strong></td><td><strong>Timmar</strong></td></tr>';

    for (const index in this.dataObjectFromParent['utslag']['users']) {
      const user = this.dataObjectFromParent['utslag']['users'][index];
      tooltip +=
        '<tr><td style="padding-right:15px">' +
        user['username'] +
        '</td><td>' +
        user['hours'] +
        '</td></tr>';
    }

    tooltip += '</table>';

    return tooltip;
  }

  confirmDeleteUserTodoRelation(idParam) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort knytningen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.buttons = this.formHandler.lockButtons(this.buttons);

        this.deleteUserTodoRelationMutation(idParam);
      },
      reject: () => {},
    });
  }

  private deleteUserTodoRelationMutation(idParam: any) {
    const crudType = 'delete';
    const dataToMutation = { id: Number(idParam) };
    const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
    const refetchArr = [{ name: 'projectTodos', variables: refetchVars }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'userTodoRelation',
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.deleteEmittedUserTodoRelation(idParam);
          }
          this.mutationService.displayMutationStatus(executedData);
          this.buttons = this.formHandler.unlockButtons(this.buttons);
          executeMutationSub.unsubscribe();
        },
        err => {
          this.messageService.insertData({ textArray: [err], type: 'error' });
          this.buttons = this.formHandler.unlockButtons(this.buttons);
        }
      );
  }

  getMutationDataFromForm() {
    const dataObjectToMutation = {};
    const attrToExclude = ['days', '__typename'];
    for (const control in this.componentMainForm.controls) {
      if (!attrToExclude.includes(control)) {
        dataObjectToMutation[control] =
          '' + this.componentMainForm.controls[control].value;
      }
    }

    dataObjectToMutation['id'] = Number(dataObjectToMutation['id']);
    dataObjectToMutation['done'] = this.doneBoolean ? 1 : 0;

    return dataObjectToMutation;
  }

  clearRow() {
    this.formInit();
  }

  updateRowEmit(emittedObj) {
    const todoObj = {
      id: emittedObj['id'],
      description: emittedObj['description'],
      done: emittedObj['done'],
    };
    this.updateRow.emit(todoObj);
  }
}
