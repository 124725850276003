import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { GlobalService } from '../../../shared/global/index';

import { CompanySuperService } from '../company.super.service';

@Injectable()
export class ProjectTypesWithProjectsService extends CompanySuperService {
  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list') =>
    mode === 'labels'
      ? this.makeLabelsArray(data)
      : this.makeObjectsForForms(data);

  makeLabelsArray(data) {
    const dataArray = data.company['projectTypes']['edges'];
    const dataObjectsWithLabels = [];

    dataObjectsWithLabels.push({ label: 'Välj projekt', value: null });

    for (const projectType in dataArray) {
      const projectArray = dataArray[projectType]['node']['projects']['edges'];
      for (const project in projectArray) {
        const dataObject = {
          label:
            projectArray[project].node.trueId +
            ', ' +
            projectArray[project].node.mark,
          value: projectArray[project].node.id,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeLabelsArrayForStatuses(data, statusArray, getStatuses = false) {
    const statuses = statusArray;

    const dataArray = data.company['projectTypes']['edges'];
    const dataObjectsWithLabels = [];
    const nullObject = { label: 'Välj projekt', value: null };

    if (getStatuses) {
      nullObject['value'] = { id: null };
    }

    dataObjectsWithLabels.push(nullObject);

    for (const projectType in dataArray) {
      const projectArray = dataArray[projectType]['node']['projects']['edges'];
      for (const project in projectArray) {
        const dataFromArray = projectArray[project];

        const dataObject = {
          label:
            projectArray[project].node.trueId +
            ', ' +
            projectArray[project].node.mark,
          value: projectArray[project].node.id,
        };
        if (getStatuses) {
          dataObject['value'] = {
            id: projectArray[project].node.id,
            status: projectArray[project].node.status,
          };
        }

        let acceptedStatus = false;

        statusLoop: for (const index in statuses) {
          const statusElement = statuses[index];

          if (dataFromArray['node']['status'] === statusElement) {
            acceptedStatus = true;
            break statusLoop;
          }
        }

        if (acceptedStatus) {
          dataObjectsWithLabels.push(dataObject);
        }
      }
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray =
      data.company['projectTypes']['edges'][0]['node']['projects'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const dataObject = { ...dataArray[i].node };

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }
      dataObject['projectInfo'] =
        dataObject['project']['trueId'] + ', ' + dataObject['project']['mark'];
      dataObjectsForForms.push(dataObject);
    }

    return dataObjectsForForms;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }
}
