import { Apollo } from 'apollo-angular';
import {
  Component,
  Output,
  OnDestroy,
  OnInit,
  EventEmitter,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import {
  CompanyDivisionService,
  CompanyFunctionsService,
} from 'app/shared/company/index';
import { ApolloQueryService } from 'app/shared/apollo';
import { HelperService } from 'app/shared/helpers';
import { rawQueries as queries } from 'app/shared/apollo/queries/index';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-user-create',
  templateUrl: 'create-user.component.html',
  providers: [FormHandlerService],
})
export class CreateUserComponent implements OnInit, OnDestroy {
  public componentMainForm: FormGroup;
  public dataModel = 'user';
  public dataModelCapitalized = 'User';
  public formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
      note: '',
      type: 1,
      companyDivisionId: null,
      mobilePhone: '',
      personalNumber: '',
      salary: null,
      contractor: false,
      approvePlanCostIncrease: false,
    },
  };
  public disableBtn = false;
  public userTypes = [
    { label: 'Medarbetare', value: 1 },
    { label: 'Underentreprenör', value: 4 },
    { label: 'Arbetsledare', value: 2 },
    { label: 'Admin', value: 3 },
  ];
  public showPowersExplanation = false;
  public divisionsOptions: any;
  public showDivisions = false;
  public divisionsLoading: boolean;
  private companyDivisionSub: Subscription;
  private functionsSub: Subscription;
  public readonly tooltip =
    '<h4>Admin</h4>' +
    '<p>Admin får samma funktioner som dig, hantera projekt, användare, offerter, fakturor, statistik osv...</p>' +
    '<h4>Arbetsledare</h4>' +
    '<p>Kan tidsrapportera men även hantera Projekt samt Offerter. Har även möjlighet att lägga upp nya produkter och kontakter.</p>' +
    '<h4>Medarbetare</h4>' +
    '<p>En "Medarbetare" kan bla. tidrapportera, hantera bilder, se arbetsmoment och spara produkter till olika projekt.</p>' +
    '<h4>Underentreprenör</h4>' +
    '<p>En "Underentreprenör" har samma rättigheter som en Medarbetare i systemet.</p>';
  private APPROVE_PLAN_COST_INCREASE: string =
    'Att lägga till användare kan medföra en ökad månadskostnad om ni överstiger det antal användare ni betalar för idag. ' +
    'Om ni överstiger det antal användare ni betalar för idag kommer er betalplan att justeras till det nya antalet användare.';
  private remainingUsersInPlan$: Observable<number>;
  private remainingUsersInPlan: number;

  @Output() public createEmitter: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyDivisionService: CompanyDivisionService,
    private apolloQueryService: ApolloQueryService,
    private helperService: HelperService,
    private angularApollo: Apollo,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.formsInit();
    this.dialogConfig.header = 'Skapa ny användare';
  }

  public ngOnInit(): void {
    this.functionsSub = this.companyFunctionsService
      .getCompanyFunctions()
      .subscribe(fs => {
        if (Object.keys(fs).length > 0) {
          this.companyHasDivisions();
        }
      });
    this.remainingUsersInPlan$ = this.apolloQueryService
      .apolloQuery('companyInfo')
      .pipe(map(x => x?.data?.company?.remainingUsersInPlan));
    this.remainingUsersInPlan$.subscribe((value: number) => {
      this.remainingUsersInPlan = value;
    });
  }

  public ngOnDestroy(): void {
    if (this.functionsSub) {
      this.functionsSub.unsubscribe();
    }
    if (this.companyDivisionSub) {
      this.companyDivisionSub.unsubscribe();
    }
  }

  private formsInit(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  public actionCreate(): void {
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    this.disableBtn = true;

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.disableBtn = false;
    } else {
      let approvePlanCostIncrease = false;
      if (this.remainingUsersInPlan <= 0) {
        if (confirm(this.APPROVE_PLAN_COST_INCREASE)) {
          approvePlanCostIncrease = true;
        } else {
          this.disableBtn = false;
          return;
        }
      }
      this.mutateWithStoreUpdate(dataToMutation, approvePlanCostIncrease);
    }
  }

  private mutateWithStoreUpdate(
    dataToMutation: { [x: string]: any },
    approvePlanCostIncrease?: boolean
  ): void {
    const action = 'create';
    const model = 'user';
    const queryName = 'companyUsers';
    const queryBody = queries[queryName].body;

    const mutation = this.helperService.getMutationQuery(
      model,
      action,
      queryBody
    );

    const apolloVars = {};
    const hyperionMutationType = this.helperService.getMutationType(
      model,
      action
    );
    apolloVars[hyperionMutationType] = {};

    for (const key of Object.keys(dataToMutation)) {
      const keyValue = dataToMutation[key];
      apolloVars[hyperionMutationType][key] = keyValue;
    }

    if (approvePlanCostIncrease) {
      apolloVars[hyperionMutationType] = {
        ...apolloVars[hyperionMutationType],
        approvePlanCostIncrease: true,
      };
    }

    this.angularApollo
      .mutate({
        mutation,
        variables: apolloVars,
      })
      .subscribe(
        ({ data }) => {
          const keyName = 'userTypeHyperionMutation';
          const mutatedData = data[keyName];

          mutatedData['mutationSucceededAllArguments'] =
            this.mutationService.checkMutationSucceededAll(mutatedData);
          this.mutationService.displayMutationStatus(mutatedData);
          if (mutatedData.mutationSucceededAllArguments) {
            this.dialogRef.close();
            this.createEmitter.emit(true);
            this.formsInit();
          }
          this.disableBtn = false;
        },
        onerror => {
          this.disableBtn = false;
          throw onerror;
        }
      );
  }

  private companyHasDivisions(): void {
    if (
      this.companyFunctionsService.companyFunctionIsSet('useCompanyDivisions')
    ) {
      this.showDivisions = true;
      this.getDivisions();
    }
  }

  private getDivisions(): void {
    this.divisionsLoading = true;

    this.apolloQueryService
      .apolloWatchQueryTwo('companyDivision')
      .subscribe(({ data, sub }) => {
        this.companyDivisionSub = sub;
        this.divisionsLoading = false;
        this.divisionsOptions = this.companyDivisionService.dataFormater(
          data,
          'labels'
        );
      });
  }
}
