<div>
  <div class="p-grid">
    <div class="p-col-12 p-g-nopdad">
      <form [formGroup]="componentMainForm" class="full-width">
        <div #formBody>
          <appForm2-simple-dropDown
            formControlName="responsibleUserId"
            [dropDownParams]="{ options: usersDropdown }"
          ></appForm2-simple-dropDown>
        </div>

        <appForm2-simple-calendar
          formControlName="dateToInvoice"
        ></appForm2-simple-calendar>

        <appForm2-simple-input formControlName="name"></appForm2-simple-input>
      </form>

      <div class="p-col-12" style="padding-top: 15px">
        <app-button (click)="actionCreate()" [type]="'save'"></app-button>
      </div>
    </div>

    <div class="p-col-12">
      <div style="width: 100px; max-width: 100%; height: 100px"></div>
    </div>
  </div>
</div>
