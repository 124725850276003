import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShowSubsistenceQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShowSubsistenceQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', showSubsistenceOnTimereport?: number | null } | null };

export const ShowSubsistenceDocument = gql`
    query showSubsistence {
  company {
    showSubsistenceOnTimereport
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShowSubsistenceGQL extends Apollo.Query<ShowSubsistenceQuery, ShowSubsistenceQueryVariables> {
    document = ShowSubsistenceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }