import { Component } from '@angular/core';

import { ApolloQueryService, ApolloMutationService } from 'app/shared/apollo';
import { UserLocalStorageService } from 'app/shared/user';

@Component({
  selector: 'app-application-message',
  templateUrl: './application-message.component.html',
  styleUrls: ['./application-message.component.scss'],
})
export class ApplicationMessageComponent {
  messages: any[] = [];
  msg: any[] = [];
  id: number;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private userLocalStorageService: UserLocalStorageService
  ) {
    this.id = +this.userLocalStorageService.getMEUser().id;
    this.apolloQueryService
      .apolloWatchQueryTwo('applicationMessages', {
        type: '10',
        userId: [this.id],
      })
      .subscribe(data => {
        data.sub.unsubscribe();
        const test = this.apolloQueryService.cleanFromNode(
          data.data['company']['companyUserGlobalMessages']
        );
        test.forEach(message => {
          !message['closedByUserId'] &&
            this.messages.push([
              {
                severity:
                  +message['type'] === 10
                    ? 'info'
                    : +message['type'] === 11
                    ? 'success'
                    : 'warning',
                summary:
                  +message['type'] === 10
                    ? 'Information'
                    : +message['type'] === 11
                    ? 'Information'
                    : 'Viktigt meddelande',
                detail: message['body'],
                id: +message['id'],
              },
            ]);
        });
      });
  }

  myCloseEvent(message) {
    const variables = {
      closedByUserId: this.id,
      id: message[0]['id'],
    };
    this.mutationService
      .constructQueryAndExecuteMutation(
        'companyUserGlobalMessage',
        'update',
        false,
        variables
      )
      .subscribe();
  }
}
