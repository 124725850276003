import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { RouteUserCompanyPermissionsGQL } from './routes.service.generated';
import { UserTypeEnum } from './shared/user';
import { shareReplay, catchError, map } from 'rxjs/operators';

export interface UserFlags {
  isForeman: boolean;
  isWorker: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  hasSubCompanies: boolean;
  isWhitelabelStruqtur: boolean;
  isWhitelabelBeijerBygg: boolean;
  isWhitelabelDerome: boolean;
  hasFlag(flag: string): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserFlagsService {
  private flags: Observable<UserFlags> | null;

  constructor(
    private routeUserCompanyPermissionsGQL: RouteUserCompanyPermissionsGQL
  ) {}

  public getFlags(forceRefresh = false): Observable<UserFlags> {
    if (!this.flags || forceRefresh) {
      this.setupFlags();
    }

    return this.flags;
  }

  private setupFlags(): void {
    this.flags = this.routeUserCompanyPermissionsGQL
      .fetch()
      .pipe(
        map(result => {
          const { me: user, appParams } = result.data;
          const enabledFeatureFlags: string[] =
            result.data.company.functions.edges.map(
              ({ node }) => node.function
            );

          const flags: UserFlags = {
            isForeman: user.type === +UserTypeEnum.Foreman,
            isWorker: user.type === +UserTypeEnum.Worker,
            isAdmin: user.type === +UserTypeEnum.Admin,
            isSuperAdmin: user.isSuperAdmin,
            hasSubCompanies: user.userHasCompanyUsers.edges.length !== 0,

            isWhitelabelStruqtur: appParams.getenvStruqturMode === 'struqtur',
            isWhitelabelBeijerBygg:
              appParams.getenvStruqturMode === 'beijerbygg',
            isWhitelabelDerome: appParams.getenvStruqturMode === 'derome',
            hasFlag: flag => {
              return enabledFeatureFlags.includes(flag);
            },
          };

          return flags;
        })
      )
      .pipe(
        shareReplay(1),
        catchError(err => {
          this.flags = null;
          return of(null);
        })
      ) as Observable<UserFlags>;
  }
}
