<div class="p-grid">
  <div class="p-col-12 p-lg-6">
    <label>Kostnad per anställd</label>
    <p-table class="inline-table" [value]="costs?.mileCosts?.users">
      <ng-template pTemplate="header">
        <tr>
          <th>Anställd</th>
          <th>Antal mil</th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            {{ rowData.name }}
          </td>
          <td>{{ rowData.miles }}</td>
          <td>{{ rowData.milesCost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-12 p-lg-6">
    <label>Normal/Extra</label>
    <p-table
      class="inline-table p-col-12 p-lg-6"
      [value]="costs.mileCosts?.byExtraNormal"
      [dataKey]="'name'"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Typ</th>
          <th>Antal mil</th>
          <th>Kostnad</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>
            <a [pRowToggler]="rowData" label="expand">
              {{ rowData.name }}
            </a>
          </td>
          <td>{{ rowData.miles }}</td>
          <td>{{ rowData.milesCost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data>
        <tr *ngFor="let user of data.users">
          <td>{{ user.name }}</td>
          <td>{{ user.miles }}</td>
          <td>{{ user.milesCost | currency: 'SEK' }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
