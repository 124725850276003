<app-header>
  <button
    pButton
    class="p-button-primary-hyperion"
    label="Skapa användare"
    icon="pi pi-plus"
    [disabled]="loading"
    (click)="openCreateUserDialog()"
  ></button>
</app-header>

<p-sidebar [(visible)]="displayDialog" position="right">
  <h1>Skapa Användare</h1>
  <app-user-create (createEmitter)="handleCreateUser()"> </app-user-create>
</p-sidebar>

<div [class.dialog-container]="isModal" [class.page-container]="!isModal">
  <div class="p-grid">
    <div class="p-inputgroup p-col">
      <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
      <input
        type="text"
        pInputText
        [disabled]="loading"
        (input)="userTable.filterGlobal($event.target.value, 'contains')"
        placeholder="Sök medarbetare"
      />
    </div>
    <div class="p-col-12 p-lg-6 p-xl-5 p-xxl-4 align-right">
      <button
        *ngIf="showDeletedUsersButton"
        pButton
        [disabled]="loading"
        class="p-mr-2"
        (click)="actionShowDeletedUsers()"
        label="Återställ användare"
        icon="pi pi-trash"
      ></button>
      <button
        pButton
        [disabled]="loading"
        (click)="resetSearchParams()"
        label="Utökad sökning"
        icon="pi pi-search-plus"
      ></button>
    </div>

    <div class="p-col-12" *ngIf="showAdvancedSearch">
      <p-accordion>
        <p-accordionTab header="Grunduppgifter" [selected]="true">
          <div class="p-grid" style="background-color: white">
            <div class="p-col-12 p-md-3" *ngFor="let list of labelsToDisplay">
              <label>{{ labels[list] }}</label>
              <input
                pInputText
                [ngModel]="''"
                (ngModelChange)="userTable.filter($event, list, 'contains')"
              />
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <p-accordion class="p-col-12" *ngIf="showSeats">
      <p-accordionTab header="Användare" [selected]="true">
        <p-progressBar
          [value]="seatsUsagePercentage"
          [showValue]="false"
        ></p-progressBar>
        <p>
          {{ companyUserCount }} av {{ companySeatsCount }} betalda användare
          används. Om du vill minska antalet användare du betalar för, vänligen
          kontakta supporten.
        </p>
      </p-accordionTab>
    </p-accordion>

    <div class="p-col-12">
      <spinner *ngIf="loading"></spinner>
      <div [style.display]="loading ? 'none' : 'block'">
        <p-table
          #userTable
          dataKey="id"
          [columns]="tableCols"
          [value]="usersAsync | async"
          [rowTrackBy]="helperService.rowTrackBy"
          [sortField]="sort.attribute"
          [sortOrder]="sort.ascDesc"
          styleClass="expand-rows"
          (onSort)="changeSortOrder($event)"
          paginatorPosition="bottom"
          [responsive]="true"
          [rowHover]="true"
          [rowsPerPageOptions]="[50, 100, 250, 500]"
          [rows]="100"
          [paginator]="(usersAsync | async).length > 50"
          responsiveLayout="scroll"
          [rowHover]="true"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [style.width]="col.width"
              >
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>

              <th style="width: 60px; text-align: center">
                <span
                  pTooltip="Alternera kolumner"
                  class="link"
                  (click)="changeDisplayedTableCols()"
                >
                  <i class="fa fa-columns"></i>
                </span>
              </th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-expanded="expanded"
            let-columns="columns"
          >
            <tr [pRowToggler]="rowData" class="p-selectable-row">
              <td *ngFor="let col of columns">
                <span class="p-column-title">{{ col.header }}</span>
                {{ rowData[col.field] }}
              </td>
              <td></td>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-columns="columns"
          >
            <tr class="p-table-expanded-row">
              <td
                [attr.colspan]="tableCols.length + 1"
                style="background-color: white"
              >
                <app-user-edit
                  [inModal]="true"
                  [userIdInput]="rowData['id']"
                  (userDeleted)="handleDelete($event)"
                  (userUpdated)="handleUpdated($event)"
                ></app-user-edit>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td [attr.colspan]="columns.length + 1">
                <app-emptyState
                  [model]="'User'"
                  [whereToCreate]="'topRight'"
                ></app-emptyState>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p-dialog
      header="Återställ raderade användare"
      [(visible)]="showDeletedUsers"
      [draggable]="false"
      appendTo="body"
      [modal]="true"
      [resizable]="false"
      [responsive]="true"
      [dismissableMask]="true"
    >
      <spinner *ngIf="deletedLoading"></spinner>
      <div *ngIf="!deletedLoading" class="p-grid">
        <div class="p-inputgroup p-col-12">
          <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input
            type="text"
            pInputText
            [disabled]="loading"
            (input)="
              deletedUserTable.filterGlobal($event.target.value, 'contains')
            "
            placeholder="Sök medarbetare"
          />
        </div>
        <p-table
          #deletedUserTable
          class="p-col-12"
          [value]="deletedUsersAsync | async"
          paginatorPosition="bottom"
          [rowHover]="true"
          [globalFilterFields]="['firstName', 'lastname']"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>Namn</th>
              <th style="width: 5rem"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
            <tr>
              <td>{{ user.firstName }} {{ user.lastName }}</td>
              <td>
                <button
                  pButton
                  class="p-button-primary-inverted"
                  icon="pi pi-undo"
                  pTooltip="Återställ användare"
                  tooltipPosition="left"
                  (click)="actionRestoreUser(user)"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-dialog>
  </div>
</div>
