import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  first,
  pairwise,
  Subject,
  Subscription,
} from 'rxjs';
import * as moment from 'moment';
import { Table } from 'primeng/table';
import { ConfirmationService, LazyLoadEvent, MenuItem } from 'primeng/api';

import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { ApolloMutationService } from 'app/shared/apollo';
import { AppDialogService } from 'app/shared/dialogs';
import { CompanyFunctionsService } from 'app/shared/company';
import { GlobalService } from 'app/shared/global';
import { HelperService } from 'app/shared/helpers';
import { HtmlModalService } from 'app/shared/html-modal';
import { HttpService } from 'app/shared/http';
import { HyperionLabelsService } from 'app/shared/labels';
import { LabelsResponse } from 'app/shared/labels/labels.service';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';

import { ProjectUnderlagComponent } from 'app/old-project/project-underlag/project-underlag.component';

import { InvoiceContainerComponent } from 'app/invoice/single/invoice-container.component';
import { InvoicePaymentComponent } from 'app/invoice/single/invoice-payment/invoice-payment.component';
import { InvoiceType } from 'app/invoice/single/enums/invoice-type.enum';
import { TaxReductionDialogComponent } from 'app/invoice/tax-reduction-dialog/tax-reduction-dialog.component';
import { ActiveItemLabelService } from 'app/header/active-item-label.service';
import {
  CompanyInvoiceListGQL,
  CompanyInvoiceListQueryVariables,
  CompanySupplierInvoiceListGQL,
  CompanySupplierInvoiceListQueryVariables,
  RemoveInvoiceConnectionsGQL,
} from '../graphql/invoice.query.generated';
import { Contact, Invoice } from 'generated/types';
import { QueryRef } from 'apollo-angular';
import { InvoiceStatus } from '../single/enums/invoice-status.enum';
import { Menu } from 'primeng/menu';
import {
  TableSortSettingsService,
  TablesSortable,
} from 'app/shared/helpers/table-sort-settings.service';
import { MessageService } from 'app/shared/message';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';

type FilterOptions = {
  status?: InvoiceStatus;
  statusRot?: boolean;
  paymentPassed?: boolean;
  kreditfaktura?: boolean;
  typeInvoice?: number[];
  sortColumn?: string;
  search?: string;
  fakturaNr?: string;
  ocr?: string;
  contactTrueId?: string;
  contactOrderBuisnessName?: string;
  your_reference?: string;
  our_reference?: string;
  ovrigt?: string;
  your_order_nr?: string;
  projectsTrueIds?: string;
  fromFdatum?: string;
  toFdatum?: string;
  //TODO: projectsMark
};

@Component({
  selector: 'invoice-index',
  templateUrl: 'index.component.html',
  styleUrls: ['./index.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HttpService, AppDialogService],
})
export class InvoiceIndexComponent implements OnInit, OnDestroy {
  public invoices: Invoice[] = [];
  public labels: LabelsResponse;
  public cols: any[];
  private colsDefault: any[];
  private colsContactMode: any[];
  private colsDefaultSupplier: any[];
  public showAdvancedSearch = false;
  public totalInvoices: number;
  private invoicesSubscription: Subscription;
  private supplierInvoicesSubscription: Subscription;
  private invoicesWatch: QueryRef<any>;
  private supplierInvoicesWatch: QueryRef<any>;
  public selectedFastFilter = 'all';
  public showCreateButton = false;
  public loading = true;
  public search = new Subject<string>();
  public filters = new BehaviorSubject<FilterOptions | null>(null);
  private filterOptions: FilterOptions;
  private userFlags: UserFlags;

  public runningType: 'invoice' | 'supplierInvoice';

  private currentColsSet: 'default' | 'contact' = 'default';
  @ViewChild('dt', { static: true })
  public invoiceTable: Table;

  private sort: { attribute: string; ascDesc: number; object: TablesSortable } =
    {
      attribute: 'fakturaNr',
      ascDesc: -1,
      object: 'invoice',
    };

  /* Invoice list options */
  public selectedInvoices = [];
  public selectedListOption = [];
  public updateInvoiceonListOptions = [
    {
      label: 'Hämta skattereduktion ROT/RUT',
      name: 'Hämta skattereduktion ROT/RUT',
      code: 'taxReductionFile',
      value: 'taxReductionFile',
    },
    {
      label: 'Visa valda fakturor',
      name: 'Visa valda fakturor',
      code: 'showInvoices',
      value: 'showInvoices',
    },
    {
      label: 'Markera som skickade',
      name: 'Markera som skickade',
      code: 'sended',
      value: 'sended',
    },
    {
      label: 'Markera som ej skickade',
      name: 'Markera som ej skickade',
      code: 'notSended',
      value: 'notSended',
    },
    {
      label: 'Exportera valda till excel',
      name: 'Exportera valda till excel',
      code: 'exportExcel',
      value: 'exportExcel',
    },
  ];
  public invoiceStatus = [
    { label: 'Alla', value: null },
    { label: 'Obetald', value: 0 },
    { label: 'Betald', value: 1 },
    { label: 'Makulerad', value: 5 },
  ];
  public invoiceTypes = [
    { label: 'Alla', value: null },
    { label: 'Vanlig faktura', value: InvoiceType.Normal },
    { label: 'Omvänd skattskyldighet', value: InvoiceType.InvertedVat },
    { label: 'ROT', value: InvoiceType.Rot },
    { label: 'RUT', value: InvoiceType.Rut },
    { label: 'Grön', value: InvoiceType.Green },
  ];

  constructor(
    private httpService: HttpService,
    public helperService: HelperService,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute,
    private htmlModalService: HtmlModalService,
    private dialogService: AppDialogService,
    private mailDialogService: MailDialogService,
    private labelsService: HyperionLabelsService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private globalInvoiceService: GlobalInvoiceService,
    private companyFunctionsService: CompanyFunctionsService,
    private activeItemLabelService: ActiveItemLabelService,
    private changeDetector: ChangeDetectorRef,
    private companyInvoiceList: CompanyInvoiceListGQL,
    private companySupplierInvoiceList: CompanySupplierInvoiceListGQL,
    private tableSortSettingsService: TableSortSettingsService,
    private router: Router,
    private messageService: MessageService,
    private userFlagService: UserFlagsService,
    private removeInvoiceConnectionService: RemoveInvoiceConnectionsGQL
  ) {}

  public ngOnInit(): void {
    this.handleRouteChangeInView();
    this.activatedRoute.parent.url.pipe(first()).subscribe(url => {
      const type = url[0].path;
      this.init(type);
    });
    this.userFlagService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
      });

    this.handleFilter();
    this.handleSearch();
  }

  public ngOnDestroy(): void {
    this.invoicesSubscription?.unsubscribe();
    this.supplierInvoicesSubscription?.unsubscribe();
    this.activeItemLabelService.unsetActiveItem();
  }

  public changeSort(event): void {
    this.tableSortSettingsService.saveSortSettings(
      this.runningType === 'invoice' ? 'invoice' : 'supplierInvoice',
      event.field,
      event.order
    );
  }

  private init(type: 'invoice' | 'supplierInvoice' | string): void {
    if (type === 'invoice') {
      this.showCreateButton = true;
      this.runningType = 'invoice';

      const savedSortSettings =
        this.tableSortSettingsService.getSortSettings('invoice');

      this.sort = {
        object: 'invoice',
        attribute: savedSortSettings?.column ?? 'fakturaNr',
        ascDesc: savedSortSettings?.order ?? -1,
      };
    } else if (type === 'supplierInvoice') {
      // FIXME: we need to break this out... and definitely not with a query parameter
      //        dropping the parameter from /supplierInvoice/index completely breaks it
      this.companyFunctionsService
        .hasCompanyFunction('ableToCreateSupplierInvoicesInGUI')
        .subscribe(useFunction => (this.showCreateButton = useFunction));

      this.runningType = 'supplierInvoice';

      const savedSortSettings =
        this.tableSortSettingsService.getSortSettings('supplierInvoice');

      this.sort = {
        object: 'supplierInvoice',
        attribute: savedSortSettings?.column ?? 'created',
        ascDesc: savedSortSettings?.order ?? -1,
      };
    }

    this.invoiceTable.defaultSortOrder = this.sort.ascDesc;
    const d = Object.assign({}, this.sort);

    this.invoiceTable.sortOrder = d.ascDesc;
    this.invoiceTable.sortField = d.attribute;
    this.invoiceTable.resetPageOnSort = false;

    this.labelsService
      .getLabels([{ model: 'Invoice' }, { model: 'Contact' }])
      .subscribe(data => {
        this.labels = data;

        this.colsDefault = [
          {
            field: 'fakturaNr',
            header: 'Faktnr',
            width: '8.3rem',
          },
          {
            field: 'projectsTrueIds',
            header: 'Projekt',
            width: '7rem',
          },
          {
            field: 'contactTrueId',
            header: 'Kundnr',
            width: '8rem',
          },
          {
            field: 'contactOrderBuisnessName',
            header: 'Kund',
            width: 'auto',
          },
          { field: 'fdatum', header: 'Faktdatum', width: '8rem' },
          { field: 'totalBox', header: 'Summa', width: '6.8rem' },
          { field: 'ffdatum', header: 'Förfdatum', width: '8rem' },
        ];

        this.colsDefaultSupplier = [
          {
            field: 'fakturaNr',
            header: 'Faktnr',
            width: '8.3rem',
          },
          {
            field: 'projectsTrueIds',
            header: 'Projekt',
            width: '7rem',
          },
          {
            field: 'companyName',
            header: 'Leverantör',
            width: 'auto',
          },
          { field: 'fdatum', header: 'Faktdatum', width: '8rem' },
          { field: 'totalBox', header: 'Summa', width: '6.8rem' },
          { field: 'ffdatum', header: 'Förfdatum', width: '8rem' },
        ];

        this.colsContactMode = [
          {
            field: 'fakturaNr',
            header: data.Invoice.fakturaNr,
            width: '135px',
          },
          {
            field: 'ProjectsTrueId',
            header: data.Invoice.projectId,
            width: '130px',
          },
          { field: 'ProjectsMark', header: 'Projektmärkning' },
          {
            field: 'ContactTrueId',
            header: data.Contact.trueId,
            width: '120px',
          },
          {
            field: 'ContactOrderBuisnessName',
            header: data.Contact.orderBuisnessName,
          },
          { field: 'ContactAddress', header: data.Contact.address },
          { field: 'ContactCity', header: data.Contact.city, width: '100px' },
          { field: 'ContactPhone', header: data.Contact.phone },
        ];

        if (this.runningType === 'supplierInvoice') {
          this.colsContactMode.push({ field: 'created', header: 'Skapad' });
          this.colsDefault.push({ field: 'created', header: 'Skapad' });
          this.colsDefault = this.colsDefaultSupplier;
        }

        this.cols = this.colsDefault;
      });
  }

  public toggleAdvancedSearch(): void {
    this.showAdvancedSearch = !this.showAdvancedSearch;
  }

  private handleFilter(): void {
    this.filters
      .pipe(
        debounceTime(300),
        filter(f => f != null)
      )
      .subscribe(filters => {
        if (this.runningType === 'invoice') {
          return this.getInvoicesLazy(null, filters);
        }

        if (this.runningType === 'supplierInvoice') {
          return this.getSupplierInvoicesLazy(null, filters);
        }
      });
  }

  private handleSearch(): void {
    this.search.pipe(debounceTime(300)).subscribe(searchValue => {
      if (this.runningType === 'invoice') {
        return this.getInvoicesLazy(null, { search: searchValue });
      }

      if (this.runningType === 'supplierInvoice') {
        return this.getSupplierInvoicesLazy(null, { search: searchValue });
      }
    });
  }

  private handleRouteChangeInView(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(
        ([previousEvent, currentEvent]: [NavigationEnd, NavigationEnd]) => {
          if (
            currentEvent.urlAfterRedirects === previousEvent.urlAfterRedirects
          ) {
            return;
          }

          const isInvoice =
            currentEvent.urlAfterRedirects.includes('/invoice/index');

          const isSupplierInvoice = currentEvent.urlAfterRedirects.includes(
            '/supplierInvoice/index'
          );

          if (isInvoice) {
            this.init('invoice');
            this.supplierInvoicesWatch?.stopPolling();
            this.supplierInvoicesSubscription?.unsubscribe();
          }

          if (isSupplierInvoice) {
            this.init('supplierInvoice');
            this.invoicesWatch?.stopPolling();
            this.invoicesSubscription?.unsubscribe();
          }

          if (!isInvoice && !isSupplierInvoice) {
            return;
          }

          this.invoiceTable.onLazyLoad.emit(this.invoiceTable);
        }
      );
  }

  public changePage(event): void {
    if (this.totalInvoices > 0 && event.first > this.invoices.length) {
      this.loading = true;
    }
  }

  public changeCols(): void {
    if (this.currentColsSet === 'default') {
      this.cols = this.colsContactMode;
      this.currentColsSet = 'contact';
    } else {
      this.cols = this.colsDefault;
      this.currentColsSet = 'default';
    }
  }

  public getInvoicesLazy(event?: LazyLoadEvent, filters?: FilterOptions): void {
    this.loading = true;

    this.filterOptions = { ...this.filterOptions, ...filters };

    const savedSortField = this.invoiceTable.columns
      .map(c => c.field)
      .includes(this.sort.attribute)
      ? this.sort.attribute
      : 'fakturaNr';
    const savedSortOrder = this.sort.ascDesc;

    const fetchVariables: CompanyInvoiceListQueryVariables = {
      first: savedSortOrder > 0 ? 100 : null,
      last: savedSortOrder < 0 ? 100 : null,
      offset: 0,
      status:
        this.filterOptions?.status === null || isNaN(this.filterOptions?.status)
          ? null
          : [this.filterOptions.status],
      paymentPassed: this.filterOptions?.paymentPassed ?? null,
      kreditfaktura: this.filterOptions?.kreditfaktura ?? null,
      statusRot: this.filterOptions?.statusRot ?? null,
      typeInvoice: this.filterOptions?.typeInvoice?.length
        ? this.filterOptions.typeInvoice
        : null,
      sortColumn: this.invoiceTable.columns
        .map(c => c.field)
        .includes(event?.sortField)
        ? event.sortField
        : savedSortField,
      fakturaNr: this.filterOptions?.fakturaNr ?? null,
      ocr: this.filterOptions?.ocr ?? null,
      contactTrueId: this.filterOptions?.contactTrueId ?? null,
      contactOrderBuisnessName:
        this.filterOptions?.contactOrderBuisnessName ?? null,
      your_reference: this.filterOptions?.your_reference ?? null,
      our_reference: this.filterOptions?.our_reference ?? null,
      ovrigt: this.filterOptions?.ovrigt ?? null,
      your_order_nr: this.filterOptions?.your_order_nr ?? null,
      projectsTrueIds: this.filterOptions?.projectsTrueIds ?? null,
      fromFdatum: this.filterOptions?.fromFdatum ?? null,
      toFdatum: this.filterOptions?.toFdatum ?? null,
      search: this.filterOptions?.search ?? null,
    };

    if (filters) {
      this.totalInvoices = null;
    }

    if (event) {
      if (event.sortOrder > 0) {
        fetchVariables.offset = event.first;
        fetchVariables.first = event.rows;
        fetchVariables.last = null;
      } else {
        fetchVariables.offset = event.first;
        fetchVariables.last = event.rows;
        fetchVariables.first = null;
      }
    }

    if (this.invoicesWatch) {
      this.invoicesWatch.refetch(fetchVariables);
    } else {
      this.invoicesWatch = this.companyInvoiceList.watch(fetchVariables);
    }

    if (
      this.invoicesSubscription &&
      !this.invoicesSubscription.closed &&
      !this.loading
    ) {
      return;
    }

    this.invoicesSubscription = this.invoicesWatch.valueChanges.subscribe(
      ({ data }) => {
        if (!this.totalInvoices) {
          this.totalInvoices = data.company.invoices.totalCount;
        }

        this.loading = false;
        this.invoices = data.company.invoices.edges
          .map(({ node }) => node)
          .map(i => ({
            ...i,
            TaxreductionStatusCalculatedAsString: this.getTaxReductionStatus(i),
          }));

        this.changeDetector.markForCheck();
      }
    );
  }

  private getTaxReductionStatus(invoice): string {
    if (invoice.statusRot == 1) {
      return 'in';
    } else if (
      new Date(invoice.ffdatum).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0) &&
      invoice.statusRot == 0
    ) {
      return 'late';
    }
    return 'pending';
  }

  public getSupplierInvoicesLazy(
    event?: LazyLoadEvent,
    filters?: { search?: string; fakturaNr?: string }
  ): void {
    this.loading = true;

    this.filterOptions = { ...this.filterOptions, ...filters };

    const savedSortField = this.invoiceTable.columns.includes(
      this.sort.attribute
    )
      ? this.sort.attribute
      : 'created';

    const fetchVariables: CompanySupplierInvoiceListQueryVariables = {
      first: null,
      last: 100,
      offset: 0,
      sortColumn: this.invoiceTable.columns
        .map(c => c.field)
        .includes(event?.sortField)
        ? event.sortField
        : savedSortField,
      status: null,
      fakturaNr: this.filterOptions?.fakturaNr ?? null,
      ocr: this.filterOptions?.ocr ?? null,
      contactTrueId: this.filterOptions?.contactTrueId ?? null,
      contactOrderBuisnessName:
        this.filterOptions?.contactOrderBuisnessName ?? null,
      your_reference: this.filterOptions?.your_reference ?? null,
      our_reference: this.filterOptions?.our_reference ?? null,
      ovrigt: this.filterOptions?.ovrigt ?? null,
      your_order_nr: this.filterOptions?.your_order_nr ?? null,
      projectsTrueIds: this.filterOptions?.projectsTrueIds ?? null,
      fromFdatum: this.filterOptions?.fromFdatum ?? null,
      toFdatum: this.filterOptions?.toFdatum ?? null,
      search: this.filterOptions?.search ?? null,
    };

    if (filters) {
      this.totalInvoices = null;
    }

    if (event) {
      if (event.sortOrder > 0) {
        fetchVariables.offset = event.first;
        fetchVariables.first = event.rows;
        fetchVariables.last = null;
      } else {
        fetchVariables.offset = event.first;
        fetchVariables.last = event.rows;
        fetchVariables.first = null;
      }
    }

    if (this.supplierInvoicesWatch) {
      this.supplierInvoicesWatch.refetch(fetchVariables).then(() => {
        this.loading = false;
        this.changeDetector.markForCheck();
      });
    } else {
      this.supplierInvoicesWatch =
        this.companySupplierInvoiceList.watch(fetchVariables);
    }

    if (
      this.supplierInvoicesSubscription &&
      !this.supplierInvoicesSubscription.closed
    ) {
      return;
    }

    this.supplierInvoicesSubscription =
      this.supplierInvoicesWatch.valueChanges.subscribe(({ data }) => {
        if (!this.totalInvoices) {
          this.totalInvoices = data.company.supplierInvoices.totalCount;
        }

        this.loading = false;
        this.invoices = data.company.supplierInvoices.edges.map(
          ({ node }) => node
        );

        this.changeDetector.markForCheck();
      });
  }

  public fastFilter(type: string): void {
    this.selectedFastFilter = type;
    setTimeout(() => {
      switch (type) {
        case 'all':
          this.totalInvoices = null;
          this.getInvoicesLazy(null, {
            status: null,
            paymentPassed: null,
            kreditfaktura: null,
            statusRot: null,
            typeInvoice: null,
          });
          break;
        case 'payd':
          this.getInvoicesLazy(null, {
            status: InvoiceStatus.Paid,
            paymentPassed: null,
            kreditfaktura: null,
            statusRot: null,
            typeInvoice: null,
          });
          break;
        case 'unpayd':
          this.getInvoicesLazy(null, {
            status: InvoiceStatus.Unpaid,
            paymentPassed: null,
            kreditfaktura: null,
            statusRot: null,
            typeInvoice: null,
          });
          break;
        case 'unpaydpassed':
          this.getInvoicesLazy(null, {
            paymentPassed: true,
            status: InvoiceStatus.Unpaid,
            kreditfaktura: null,
            statusRot: null,
            typeInvoice: null,
          });
          break;
        case 'credit':
          this.getInvoicesLazy(null, {
            kreditfaktura: true,
            status: null,
            paymentPassed: null,
            statusRot: null,
            typeInvoice: null,
          });
          break;
        case 'taxreductionnot':
          this.getInvoicesLazy(null, {
            kreditfaktura: false,
            typeInvoice: [InvoiceType.Rot, InvoiceType.Rut, InvoiceType.Green],
            statusRot: false,
            status: null,
            paymentPassed: null,
          });
          break;
      }
    }, 10);
  }

  public fdatumFromFilter(date: string): void {
    this.filters.next({ fromFdatum: date });
  }

  public fdatumToFilter(date: string): void {
    const nextDay = moment(date).add(1, 'days').format('YYYY-MM-DD');
    this.filters.next({ toFdatum: nextDay });
  }

  /* Mail */
  public showMailModal(id): void {
    this.mailDialogService.openMailDialog({ invoiceId: id });
  }

  public showMailModalReminder(id): void {
    this.mailDialogService.openMailDialog({ invoiceId: id, reminder: 1 });
  }

  private showPDF(id: string): void {
    const url =
      this.globalService.getUrlPrefix() +
      '/invoice/print/?type=showPDF&id=' +
      id;
    this.showPDFUrl(url);
  }

  private showPDFUrl(url: string): void {
    this.htmlModalService.ny_sida(url, 900, 800);
  }

  public exportExel(): void {
    this.listOptionsChanged('exportExcel', this.invoiceTable.filteredValue);
  }

  public listOptionsChanged(
    code: string,
    selectedInvoices = this.selectedInvoices
  ): void {
    if (selectedInvoices !== null) {
      const ids = selectedInvoices.map(obj => obj.id).join('|');
      const invoicesToExport = selectedInvoices.filter(invoice =>
        ids.includes(invoice.id)
      );

      const containsNonRutOrRotInvoices = !invoicesToExport.every(
        invoice =>
          invoice.typeInvoice == InvoiceType.Rot ||
          invoice.typeInvoice == InvoiceType.Rut
      );

      let urlParam =
        this.globalService.getUrlPrefix() + '/invoice/MassHandleHyperion?';
      switch (code) {
        case 'exportExcel':
        case 'showInvoices':
        case 'taxReductionFile':
          if (code === 'taxReductionFile' && containsNonRutOrRotInvoices) {
            this.messageService.insertData({
              textArray: [
                '<p>En eller flera fakturor du har valt är inte ROT eller RUT.</p>',
                '<p>Vid grön skattereduktion ska du klicka på <i class="pi pi-home"></i>-ikonen för att hämta filen för skattereduktion.</p>',
              ],
              time: 2000,
              type: 'error',
            });
            break;
          }

          urlParam = urlParam + code;
          this.htmlModalService.ny_sida(
            urlParam + '=' + Math.random() + '&invoiceAction=' + ids,
            900,
            800
          );
          break;
        case 'sended':
        case 'notSended':
          urlParam =
            urlParam + code + '=' + Math.random() + '&invoiceAction=' + ids;
          // Get invoices & update view
          this.httpService.makeHttpGetRequest(urlParam).then(({ success }) => {
            if (success) {
              this.globalInvoiceService.mutationOcured(
                selectedInvoices.map(obj => obj.id)
              );
            }
          });
          break;
        default:
          console.log('value not available');
      }
    }
    this.selectedListOption = []; // clean afterwards
  }

  private delete(id: string): void {
    this.confirmationService.confirm({
      message: 'Vill du radera fakturan?',
      header: 'Radera',
      icon: 'fa fa-trash',
      accept: () => {
        const dataToMutation = {
          id: Number(id),
        };
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            'invoice',
            'delete',
            false,
            dataToMutation
          )
          .subscribe(
            executedData => {
              this.mutationService.displayMutationStatus(executedData);
              executeMutationSub.unsubscribe();
              if (executedData.mutationSucceededAllArguments) {
                this.globalInvoiceService.delete(id);
              }
            },
            err => {
              console.log(err);
            }
          );
      },
      reject: () => {},
    });
  }

  private cancel(id: string): void {
    this.confirmationService.confirm({
      message:
        'Vill du makulera fakturan? <br><br>En makulerad faktura kan inte raderas efter att den har blivit makulerad',
      header: 'Makulera',
      icon: 'fa fa-ban',
      accept: () => {
        const dataToMutation = {
          id: Number(id),
          status: 5,
        };
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            'invoice',
            'update',
            false,
            dataToMutation
          )
          .subscribe(
            executedData => {
              this.mutationService.displayMutationStatus(executedData);
              executeMutationSub.unsubscribe();
              this.globalInvoiceService.mutationOcured([id]); // Get invoices,
            },
            err => {
              console.log(err);
            }
          );
      },
      reject: () => {},
    });
  }

  private openInvoiceBase(invoiceId: string): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      projectInfo: { id: 0, invoiceId: invoiceId },
      functionsThisModel: { useUserCostType: true },
    };
    this.dialogService.openComponent(ProjectUnderlagComponent);
  }

  public openCreateInvoice(): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {};
    if (this.runningType === 'supplierInvoice') {
      this.dialogService.data = { type: this.runningType };
    }

    this.dialogService
      .openComponent(InvoiceContainerComponent, null, 'dark-background')
      .onClose.pipe(first())
      .subscribe(() =>
        this.runningType === 'supplierInvoice'
          ? this.supplierInvoicesWatch.refetch()
          : this.invoicesWatch.refetch()
      );
  }

  public openPayment(invoice): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { vars: { invoice: invoice } };
    this.dialogService.openComponent(
      InvoicePaymentComponent,
      null,
      'dark-background'
    );
  }

  private openCopyInvoice(invoiceId: number): void {
    const invoice = this.invoices.find(
      invoiceFromFilter => Number(invoiceFromFilter.id) === invoiceId
    );

    if (!invoice) {
      return;
    }

    const startDate: moment.Moment = moment();
    const invoiceValidityDuration: moment.Duration = moment.duration(
      moment(invoice.ffdatum).diff(moment(invoice.fdatum))
    );
    const endDate: moment.Moment = startDate
      .clone()
      .add(invoiceValidityDuration);

    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      vars: {
        id: invoiceId,
        fdatum: startDate.format('YYYY-MM-DD'),
        ffdatum: endDate.format('YYYY-MM-DD'),
      },
    };
    this.dialogService.openComponent(InvoiceContainerComponent);
  }

  public showTaxReductionDialog(id: number): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { id };
    this.dialogService.openComponent(TaxReductionDialogComponent);
  }

  /*  End dialogs */

  public generateMenu(invoice): MenuItem[] {
    const menuItems = [
      {
        label: 'Visa PDF',
        icon: 'pi pi-file-pdf',
        command: _ => {
          this.showPDF(invoice['id']);
        },
      },
      {
        label: 'Skicka',
        icon: 'pi pi-envelope',
        command: _ => {
          this.showMailModal(invoice['id']);
        },
      },
    ];

    if (this.runningType !== 'supplierInvoice') {
      menuItems.push({
        label: 'Radera',
        icon: 'pi pi-trash',
        command: _ => {
          this.delete(invoice['id']);
        },
      });
    }

    if (
      this.runningType !== 'supplierInvoice' &&
      invoice['hasInvoiceBase_PreInvoiceTypeHyperion']
    ) {
      menuItems.splice(2, 0, {
        label: 'Visa Underlag',
        icon: 'pi pi-file',
        command: _ => {
          this.openInvoiceBase(invoice['id']);
        },
      });
    }

    if (
      this.runningType !== 'supplierInvoice' &&
      !invoice.isLegacyTaxReductionInvoice
    ) {
      menuItems.splice(2, 0, {
        label: 'Kopiera',
        icon: 'pi pi-copy',
        command: _ => {
          this.openCopyInvoice(Number(invoice['id']));
        },
      });
    }

    if (invoice['status'] !== 5) {
      menuItems.splice(menuItems.length, 0, {
        label: 'Makulera',
        icon: 'pi pi-ban',
        command: _ => {
          this.cancel(invoice['id']);
        },
      });
    }

    if (
      this.runningType === 'supplierInvoice' &&
      invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion']
    ) {
      const documents = invoice[
        'invoiceSourceDocuments_PreInvoiceTypeHyperion'
      ]?.edges?.map(e => e.node);

      if (!documents) {
        return menuItems;
      }

      for (const doc of documents) {
        menuItems.splice(1, 0, {
          label: 'Visa ' + doc.label,
          icon: 'pi pi-file',
          command: _ => {
            this.showPDFUrl(doc.url);
          },
        });
      }
    }
    if (this.userFlags.isSuperAdmin && this.runningType === 'invoice') {
      menuItems.push({
        label: 'Markera alla poster som ej fakturerade',
        icon: 'pi pi-times',
        command: () => this.removeInvoiceConnections(Number(invoice.id)),
      });
    }
    return menuItems;
  }

  private removeInvoiceConnections(invoiceId: number) {
    this.removeInvoiceConnectionService
      .mutate({
        invoiceId: invoiceId,
      })
      .pipe(first())
      .subscribe(result => {
        this.mutationService.displayMutationStatus(
          result.data.removeInvoiceConnectionsMutation
        );
      });
  }

  public toggleActive($event: any, table): void {
    if (table.isRowExpanded($event['data'])) {
      this.activeItemLabelService.setActiveItem({
        id: $event['data'].fakturaNr,
        name: $event['data'].contact.name,
      });
    } else {
      this.activeItemLabelService.unsetActiveItem();
    }
  }

  public openMenu(menu: Menu, event): void {
    menu.toggle(event);
    this.router.events.pipe(first()).subscribe(() => {
      menu.container.remove();
    });
  }
}
