import { Config } from '../config';
import { config as defaultConfig } from './default.config';

export const config: Config = {
  ...defaultConfig,
  name: 'struqtur',
  subdomain: ['app'],
  title: 'Struqtur',
  theme: {
    ...defaultConfig.theme,
    topMenuBackgroundActiveColor: '#190a2d38',
    topMenuLogoMaxHeight: '25px',
    favicons: [
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: '/apple-touch-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: '/apple-touch-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: '/apple-touch-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: '/apple-touch-icon-76x76.png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        href: '/favicon-16x16.png',
        type: 'image/png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        href: '/favicon-32x32.png',
        type: 'image/png',
      },
      {
        rel: 'icon',
        sizes: '96x96',
        href: '/favicon-96x96.png',
        type: 'image/png',
      },
      {
        rel: 'mask-icon',
        sizes: '96x96',
        href: '/safari-pinned-tab.svg',
        color: '#5bbad5',
      },
      {
        rel: 'shortcut icon',
        href: '/favicon.ico',
      },
    ],
  },
};
