import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Ng2HighchartsModule } from 'ng2-highcharts';

import { OverviewComponent } from './index';
import { HyperionSharedModule } from '../shared/shared.module';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    HyperionSharedModule,
    Ng2HighchartsModule,
    HeaderModule,
  ],
  declarations: [OverviewComponent],
})
export class OverviewModule {}
