import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from 'app/settings/company/services/accounting-plan.types';
import { AccountingPlanService } from 'app/settings/company/services/accounting-plan.service';

@Component({
  selector: 'companyUserCostType-create',
  templateUrl: 'company-user-cost-type-create.component.html',
  providers: [FormHandlerService],
})
export class CreateUserCostTypeComponent implements OnInit {
  @Output() dataObjectDeleted = new EventEmitter();
  @Output() public createSibling = new EventEmitter();
  @Input() public hasAccountingPlanFunction: boolean;
  @Input() public accountingPlanAccounts: AccountingPlanAccount[] = [];

  componentMainForm: FormGroup;
  dataModel = 'companyUserCostType';
  dataModelCapitalized = 'CompanyUserCostType';
  formSettings;
  formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      name: '',
      cost: '',
      internalCost: '',
      accountingPlanAccountId: null,
      reverseVatAccountingPlanAccountId: null,
    },
  };
  componentPrefix;
  buttons;
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private accountingPlanService: AccountingPlanService
  ) {
    this.formSettings = this.globalService.getFormSettings();
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      create: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };
    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.initForm();

    this.accountingPlanAccountsDropdownParams =
      this.accountingPlanService.getAccountsDropdownParams(
        this.accountingPlanAccounts
      );
  }

  private initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler.groupSetLabelsRules(
      this.formFields,
      this.componentMainForm
    );
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.componentMainForm.value;
    this.buttons = this.formHandler.lockButtons(this.buttons);

    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
      this.formHandler.setLoadingClasses();
    } else {
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            this.formHandler.showServerErrorsOnAttributes(
              executedData,
              [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ] // argument = like "createCompanyCostType"
            );

            let setLoadingClassesInput = 'error';
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
              this.dataObjectDeleted.emit();
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }
}
