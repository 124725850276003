import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectSearchQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectSearchQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectSearch?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null } | null> | null } | null } | null };

export type SingleProjectQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SingleProjectQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null };

export type CurrentProjectQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CurrentProjectQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, subProjects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null } | null> | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null };

export type ProjectFragment = { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null };

export type ProjectWithSubProjectFragment = { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, subProjects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null } | null> | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null };

export type ProjectInfoFragment = { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null };

export type LatestProjectsQueryVariables = Types.Exact<{
  amount?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type LatestProjectsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null } | null> | null } | null } | null };

export type InternalAndAbsenceProjectsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InternalAndAbsenceProjectsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projects?: { __typename?: 'ProjectConnection', edges?: Array<{ __typename?: 'ProjectEdge', node?: { __typename?: 'Project', subProjectCount?: number | null, id: string, trueId?: string | null, mark?: string | null, status?: number | null, systemTypeCode?: string | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null, establishmentContact?: { __typename?: 'Contact', id: string, name?: string | null, address?: string | null, address2?: string | null, city?: string | null } | null } | null } | null> | null } | null } | null };

export const ProjectInfoFragmentDoc = gql`
    fragment ProjectInfo on Project {
  id
  trueId
  mark
  status
  systemTypeCode
  clientContact {
    id
    name
    orderBuisnessName
    address
    address2
    city
  }
  establishmentContact {
    id
    name
    address
    address2
    city
  }
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  ...ProjectInfo
  subProjectCount
}
    ${ProjectInfoFragmentDoc}`;
export const ProjectWithSubProjectFragmentDoc = gql`
    fragment ProjectWithSubProject on Project {
  ...ProjectInfo
  subProjectCount
  subProjects {
    edges {
      node {
        ...ProjectInfo
      }
    }
  }
}
    ${ProjectInfoFragmentDoc}`;
export const ProjectSearchDocument = gql`
    query projectSearch($search: String, $limit: Int) {
  company {
    projectSearch(search: $search, limit: $limit) {
      edges {
        node {
          ...Project
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectSearchGQL extends Apollo.Query<ProjectSearchQuery, ProjectSearchQueryVariables> {
    document = ProjectSearchDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SingleProjectDocument = gql`
    query singleProject($projectId: Int) {
  project(id: $projectId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class SingleProjectGQL extends Apollo.Query<SingleProjectQuery, SingleProjectQueryVariables> {
    document = SingleProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CurrentProjectDocument = gql`
    query currentProject($projectId: Int) {
  project(id: $projectId) {
    ...ProjectWithSubProject
  }
}
    ${ProjectWithSubProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CurrentProjectGQL extends Apollo.Query<CurrentProjectQuery, CurrentProjectQueryVariables> {
    document = CurrentProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LatestProjectsDocument = gql`
    query latestProjects($amount: Int) {
  company {
    projects(last: $amount, status: [1]) {
      edges {
        node {
          ...Project
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class LatestProjectsGQL extends Apollo.Query<LatestProjectsQuery, LatestProjectsQueryVariables> {
    document = LatestProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InternalAndAbsenceProjectsDocument = gql`
    query internalAndAbsenceProjects {
  company {
    projects(status: [5, 6]) {
      edges {
        node {
          ...Project
        }
      }
    }
  }
}
    ${ProjectFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InternalAndAbsenceProjectsGQL extends Apollo.Query<InternalAndAbsenceProjectsQuery, InternalAndAbsenceProjectsQueryVariables> {
    document = InternalAndAbsenceProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }