import { gql, Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from 'app/shared/auth/auth.service';
import { SalesRepOwner } from './trial-period-notification';

const TRIAL_INFO_QUERY = gql`
  query fetchTrialInfo {
    company {
      salesRepOwner {
        firstName
        lastName
        email
        phone
      }
      remainingTrialDays
    }
  }
`;

@Component({
  selector: 'app-struqtur-trial-period-notification',
  templateUrl: 'trial-period-notification.component.html',
})
export class TrialPeriodNotificationComponent implements OnInit {
  public remainingTrialDays: number | null;
  public salesRepOwner: SalesRepOwner;

  constructor(private apollo: Apollo, private authService: AuthService) {}

  public ngOnInit(): void {
    this.authService.isLoggedInObs.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.fetchTrialInfo().subscribe(({ data }: any) => {
          this.remainingTrialDays = data.company.remainingTrialDays;
          this.salesRepOwner = data.company.salesRepOwner as SalesRepOwner;
        });
      }
    });
  }

  private fetchTrialInfo(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: TRIAL_INFO_QUERY,
      fetchPolicy: 'no-cache',
    });
  }
}
