<div class="flex fake-body">
  <div class="flex-container-column">
    <div>
      <h2 class="tcenter">Webbläsaren stöds inte!</h2>
    </div>
    <div class="mb2">
      <p class="tcenter">
        Vänligen använd en av de rekommenderade webbläsarna nedan.
      </p>
      <p class="tcenter">Klicka på bilden för att ladda ner den.</p>
    </div>
    <div class="flex-container">
      <div class="flex-container-div">
        <a href="https://www.google.com/intl/sv_ALL/chrome/" target="_blank">
          <img src="{{ prefix }}/images/hyperion/global/chrome.png" />
        </a>
        <p class="tcenter">Chrome <br /><strong>recomemderat</strong></p>
      </div>
      <div class="flex-container-div">
        <a
          href="https://support.apple.com/sv_SE/downloads/safari"
          target="_blank"
        >
          <img src="{{ prefix }}/images/hyperion/global/safari.png" />
        </a>
        <p class="tcenter">Safari</p>
      </div>
      <div class="flex-container-div">
        <a href="https://www.mozilla.org/en-US/firefox/" target="_blank">
          <img src="{{ prefix }}/images/hyperion/global/firefox.png" />
        </a>
        <p class="tcenter">Firefox <br /><strong>recomemderat</strong></p>
      </div>
      <div class="flex-container-div">
        <a
          href="https://www.microsoft.com/sv-se/windows/microsoft-edge"
          target="_blank"
        >
          <img src="{{ prefix }}/images/hyperion/global/medge.png" />
        </a>
        <p class="tcenter">Edge</p>
      </div>
    </div>
    <!-- <div>
      <p class="tcenter btnn">Reload Page</p>
    </div> -->
  </div>
</div>
