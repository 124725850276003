<div *ngIf="componentMainForm" class="p-grid">
  <form [formGroup]="componentMainForm" class="p-grid p-col-10">
    <div
      class="p-col-12"
      [class.p-lg-3]="hasAccountingPlanFunction"
      [class.p-lg-6]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Namn</label>
      <input
        pInputText
        formControlName="name"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyCostType_name'"
      />
    </div>

    <div
      class="p-col-12"
      [class.p-lg-1]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Procent</label>
      <input
        pInputText
        formControlName="procent"
        (click)="createSibling.emit()"
        [id]="componentPrefix + 'companyCostType_procent'"
      />
    </div>

    <div
      class="p-col-12"
      [class.p-lg-2]="hasAccountingPlanFunction"
      [class.p-lg-3]="!hasAccountingPlanFunction"
    >
      <label class="form-control-label show-on-small-device">Enhet</label>
      <p-dropdown
        formControlName="unit"
        (click)="createSibling.emit()"
        [options]="dropDownParams.options"
      ></p-dropdown>
    </div>

    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <label class="form-control-label show-on-small-device">Konto</label>
      <p-dropdown
        [filter]="true"
        formControlName="accountingPlanAccount"
        (click)="createSibling.emit()"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
    <div *ngIf="hasAccountingPlanFunction" class="p-col-12 p-lg-3">
      <label class="form-control-label show-on-small-device">Konto</label>
      <p-dropdown
        [filter]="true"
        formControlName="reverseVatAccountingPlanAccount"
        (click)="createSibling.emit()"
        [options]="accountingPlanAccountsDropdownParams.options"
      ></p-dropdown>
    </div>
  </form>

  <div class="p-col-6 p-lg-2 p-d-flex p-ai-center">
    <button
      pButton
      class="p-button-primary-hyperion"
      [disabled]="buttons.create.disabled"
      (click)="actionCreate()"
      label="Lägg till"
      icon="pi pi-plus"
    ></button>
  </div>
</div>
