import { gql } from 'apollo-angular';

const FRAGMENT_MUTATION_DETAILS_ATTRIBUTES = gql`
  fragment MutationDetailsAttributes on ModelErrorsTypeHyperionBase {
    argument
    errorsMsgs
    infoMsgs
    model
    modelErrorsMsgs
    modelSuccessMsgs
    modelWarningMsgs
    msg
    mutationSucceeded
    successMsgs
    warningMsgs
  }
`;

export const MutationDetails = {
  fragments: {
    attributes: FRAGMENT_MUTATION_DETAILS_ATTRIBUTES,
  },
};
