<div class="page-container">
  <p-card class="p-element">
    <div style="min-height: 40vh">
      <spinner *ngIf="loading"></spinner>
      <form
        *ngIf="!loading && componentMainForm"
        [formGroup]="componentMainForm"
      >
        <div class="p-grid">
          <div class="p-col-12">
            <h2>Inställningar för SIE-export</h2>
          </div>

          <div class="p-col-6">
            <label>Serie för SIE4 import-filer</label>
            <input type="text" pInputText formControlName="sieSerie" />
          </div>

          <div class="p-col-6">
            <label>Räkenskapsår</label>
            <div id="contactType" class="p-inputgroup">
              <span class="p-inputgroup-addon">
                <i class="pi pi-calendar"></i>
              </span>

              <p-dropdown
                [options]="financialYearOptions"
                formControlName="financialYear"
              >
              </p-dropdown>
            </div>
          </div>

          <div class="p-col-12">
            <h2>Bokföringskonton</h2>
          </div>

          <div class="p-col-6">
            <label>Intäktskonto med 25% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat25IncomeAccount"
            />
          </div>

          <div class="p-col-6">
            <label>25% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat25Account"
            />
          </div>

          <div class="p-col-6">
            <label>Intäktskonto med 12% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat12IncomeAccount"
            />
          </div>

          <div class="p-col-6">
            <label>12% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat12Account"
            />
          </div>

          <div class="p-col-6">
            <label>Intäktskonto med 6% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat6IncomeAccount"
            />
          </div>

          <div class="p-col-6">
            <label>6% moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVat6Account"
            />
          </div>

          <div class="p-col-6">
            <label>Momsfri intäkt</label>
            <input
              type="text"
              pInputText
              formControlName="defaultVatlessIncomeAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Omvänd moms</label>
            <input
              type="text"
              pInputText
              formControlName="defaultReversedVatAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Kundfordring</label>
            <input
              type="text"
              pInputText
              formControlName="defaultTransactionAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Leverantörsskulder</label>
            <input
              type="text"
              pInputText
              formControlName="defaultAccountsPayableAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Öresavrundning</label>
            <input
              type="text"
              pInputText
              formControlName="defaultRoundingAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Dröjsmålsränta</label>
            <input
              type="text"
              pInputText
              formControlName="defaultInterestAccount"
            />
          </div>

          <div class="p-col-6">
            <label>Påminnelseavgift</label>
            <input
              type="text"
              pInputText
              formControlName="defaultReminderAccount"
            />
          </div>

          <div class="p-col-6">
            <label>ROT/RUT-avdrag</label>
            <input
              type="text"
              pInputText
              formControlName="defaultRotavdragAccount"
            />
          </div>

          <div
            *ngIf="!loading && componentMainForm"
            class="p-col-12 p-g-nopad align-right"
          >
            <button
              pButton
              class="p-button-primary-hyperion"
              [disabled]="disableButton || loading"
              (click)="actionUpdate()"
              label="Uppdatera"
              icon="pi pi-save"
            ></button>
          </div>
        </div>
      </form>

      <div *ngIf="!loading && !componentMainForm">
        <h1>Fakturaexport är inte konfigurerat.</h1>
        <p class="error">
          Kontakta gärna vår support för att konfigurera export av fakturor.
        </p>
      </div>
    </div>
  </p-card>
</div>
