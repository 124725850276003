import { NgModule } from '@angular/core';
import { SidebarMenuComponent } from './index';
import { ButtonModule } from 'primeng/button';
import { SidebarModule } from 'primeng/sidebar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NotificationComponent } from './notification/notification.component';

@NgModule({
  imports: [
    ButtonModule,
    SidebarModule,
    CommonModule,
    MatIconModule,
    RouterModule,
  ],
  declarations: [SidebarMenuComponent, NotificationComponent],
  exports: [SidebarMenuComponent],
  providers: [],
})
export class SidebarMenuModule {}
