<style></style>
<div class="card-holder">
  <spinner *ngIf="loading" [inComponent]="true"></spinner>
  <div *ngIf="!loading">
    <div>
      <div class="msg" *ngFor="let message of messagesFromParent">
        <div>
          <div class="msg-header small-text">
            <div class="msg-header-holder">
              <span class="msg-title">{{ message.head }} </span>
            </div>
            <div class="erase-holder" *ngIf="canDelete(message)">
              <span (click)="confirmActionDelete(message)" class="erase"
                ><i class="fa fa-times"></i
              ></span>
            </div>
          </div>
          <div class="msg-text small-text">
            <p class="strong-text">{{ eraseMsg }}</p>
            <p>{{ message.text }}</p>
            <p style="text-align: right">
              {{ message.created }}, {{ message.user?.firstName }}
              {{ message.user?.lastName }}
            </p>
          </div>
        </div>
        <hr style="color: solid thin #e8e8e8" />
      </div>
    </div>
    <div *ngIf="messagesFromParent.length === 0">
      <p>Inga inlägg är skapade... så därför visas inget här.</p>
      <p>Men det är enkelt fixat, gör det första inlägget nedan.</p>
    </div>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12 p-g-nopad">
    <div>
      <div class="p-grid">
        <div class="p-col-12 p-g-nopad">
          <form *ngIf="componentMainForm" [formGroup]="componentMainForm">
            <div class="p-col-12 p-g-nopad" style="margin-top: 10px">
              <appForm2-list-input
                formControlName="head"
                (click)="toggleCreate = true"
              ></appForm2-list-input>
            </div>
            <div class="p-col-12 p-g-nopad" style="margin-top: 10px">
              <appForm2-list-textarea
                formControlName="text"
              ></appForm2-list-textarea>
            </div>
            <div class="p-col-12 p-g-nopad align-right" style="margin-top: 6px">
              <app-button
                (onClick)="actionCreate()"
                [type]="'create'"
                [model]="'CompanyNoticeboard'"
                [disabled]="connecting"
              ></app-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
