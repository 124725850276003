import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class CompanyCostTypeService extends CompanySuperService {
  dataModel = 'companyCostType';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater = (data, mode: 'labels' | 'list', showInactive = false) =>
    mode === 'labels'
      ? this.makeLabelsArray(data, showInactive)
      : this.makeObjectsForForms(data, showInactive);

  makeLabelsArray(data, showInactive) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      if (showInactive || this.isActive(dataArray[i].node)) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.name,
          value: +dataArray[i].node.id,
          isMaterial: dataArray[i].node.isMaterial,
          isUEmaterial: dataArray[i].node.isUEmaterial,
          procent: dataArray[i].node.procent,
          unit: dataArray[i].node.unit,
          accountingPlanAccountId:
            +dataArray[i].node.accountingPlanAccount?.id || null,
          reverseVatAccountingPlanAccountId:
            +dataArray[i].node.reverseVatAccountingPlanAccountId || null,
          active: dataArray[i].node.active,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data, showInactive) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      if (showInactive || this.isActive(dataArray[i].node)) {
        const dataObject = {
          procent: dataArray[i].node.procent,
          isMaterial: dataArray[i].node.isMaterial,
          name: dataArray[i].node.name,
          id: +dataArray[i].node.id,
          unit: dataArray[i].node.unit,
          accountingPlanAccountId:
            +dataArray[i].node.accountingPlanAccount?.id || null,
          reverseVatAccountingPlanAccountId:
            +dataArray[i].node.reverseVatAccountingPlanAccountId || null,
          active: dataArray[i].node.active,
        };
        dataObjectsForForms.push(dataObject);
      }
    }
    return dataObjectsForForms;
  }

  private isActive(node: any): boolean {
    // eslint-disable-next-line eqeqeq
    return node.active == 1;
  }
}
