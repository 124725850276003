<div class="page-container">
  <p-card>
    <spinner *ngIf="componentLoading"></spinner>
    <div *ngIf="!componentLoading">
      <div class="p-grid p-g-nopad">
        <form
          [formGroup]="componentMainForm"
          (keyup.enter)="actionUpdate()"
          class="full-width"
        >
          <div class="p-grid p-col-12">
            <div class="p-col-12 p-lg-4">
              <h2>Allmäna uppgifter</h2>
              <label for="name" class="form-control-label">Företagsnamn</label>
              <input pInputText formControlName="name" />

              <label for="address" class="form-control-label">Adress</label>
              <input pInputText formControlName="address" />

              <label for="address2" class="form-control-label">Adress 2</label>
              <input pInputText formControlName="address2" />

              <label for="cityCode" class="form-control-label"
                >Postnummer</label
              >
              <input pInputText formControlName="cityCode" />

              <label for="city" class="form-control-label">Ort</label>
              <input pInputText formControlName="city" />
            </div>

            <div class="p-col-12 p-lg-4">
              <h2>&nbsp;</h2>

              <label for="phone" class="form-control-label">Telefon</label>
              <input pInputText formControlName="phone" />

              <label for="mobilePhone" class="form-control-label"
                >Mobiltelefon</label
              >
              <input pInputText formControlName="mobilePhone" />

              <label for="mail" class="form-control-label">E-mail</label>
              <input pInputText formControlName="mail" />

              <label for="webb" class="form-control-label">Hemsida</label>
              <input pInputText formControlName="webb" />

              <label for="orgNr" class="form-control-label"
                >Organisationsnummer</label
              >
              <input pInputText formControlName="orgNr" />

              <label for="faxNr" class="form-control-label">Faxnummer</label>
              <input pInputText formControlName="faxNr" />
            </div>

            <div class="p-col-12 p-lg-4">
              <h2>
                Tim och milkostnader
                <span
                  class="help-tooltip bigger"
                  tooltipPosition="bottom"
                  pTooltip="Fyll i posterna nedan, det kommer spara dig mycket tid. Samtliga poster kommer automatiskt sparas tillsammans med ett nytt projekt så att projektets summor kommer bli korrekta. Du kan givetvis redigera dessa poster i projektet i efterhand."
                  [escape]="false"
                  >?</span
                >
              </h2>

              <label
                for="companyHourlyRateForCostumer"
                class="form-control-label"
                >Kundens timpris</label
              >
              <input
                pInputText
                formControlName="companyHourlyRateForCostumer"
              />

              <label for="companyMilageForCostumer" class="form-control-label"
                >Kundens milkostnad</label
              >
              <input pInputText formControlName="companyMilageForCostumer" />

              <label for="companyMileCost" class="form-control-label"
                >Företagets milkostnad</label
              >
              <input pInputText formControlName="companyMileCost" />

              <label for="companyPrivMileCost" class="form-control-label"
                >Milersättning anställda</label
              >
              <input pInputText formControlName="companyPrivMileCost" />

              <label for="companyHourlyOverhead" class="form-control-label"
                >Omkostnader</label
              >
              <input pInputText formControlName="companyHourlyOverhead" />

              <span *ngIf="hasAccountingPlanFunction">
                <label class="p-mt-4">Konto för milintäkter</label>
                <p-dropdown
                  formControlName="companyMilageForCustomerAccountingPlanAccountId"
                  *ngIf="companyAccountingPlanAccounts"
                  [options]="accountingPlanAccountsDropdownParams.options"
                  optionLabel="label"
                  optionValue="value"
                  filter="true"
                  filterBy="label"
                ></p-dropdown>

                <label class="p-mt-4">Omvänd moms-konto för milintäkter</label>
                <p-dropdown
                  formControlName="reverseVatCompanyMilageForCustomerAccountingPlanAccountId"
                  *ngIf="companyAccountingPlanAccounts"
                  [options]="accountingPlanAccountsDropdownParams.options"
                  optionLabel="label"
                  optionValue="value"
                  filter="true"
                  filterBy="label"
                ></p-dropdown>
              </span>
            </div>

            <div class="p-col-12">
              <div class="upload-logo">
                <h2>Logotyp <small>Accepterar .jpg & .png</small></h2>

                <div
                  style="text-align: center; margin-bottom: 0.5em"
                  *ngIf="!imgError"
                >
                  <img
                    style="max-width: 60%"
                    [src]="urlPrefix + '/company/GetLogos?rand=' + rand"
                    (error)="onImgReadError()"
                  />
                </div>
                <div
                  style="text-align: center; margin-bottom: 0.5em"
                  *ngIf="imgError"
                >
                  <i
                    class="fa fa-times-rectangle delete"
                    title="Logotyp finns inte eller kunde inte läsas"
                  ></i>
                </div>
                <p-fileUpload
                  name="PictureForm[image]"
                  accept=".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"
                  chooseLabel="Ange fil"
                  [multiple]="false"
                  [url]="urlPrefix + '/company/UploadCompanyLogo'"
                  uploadLabel="Ladda upp"
                  cancelLabel="Avbryt"
                  invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
                  invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
                  (onUpload)="onUpload($event)"
                >
                  <ng-template pTemplate="content">
                    <app-drag-and-drop-template></app-drag-and-drop-template>
                  </ng-template>
                </p-fileUpload>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="p-grid p-g-nopad">
        <div class="p-col-12 p-g-nopad align-right">
          <button
            pButton
            class="p-button-primary-hyperion"
            [disabled]="buttons.update.disabled"
            (click)="actionUpdate()"
            label="Uppdatera"
            icon="pi pi-save"
          ></button>
        </div>
      </div>
    </div>
  </p-card>
</div>
