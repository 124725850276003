import { Config } from '../config';
import { config as defaultConfig } from './default.config';

const skog = '#005c46';
const skogAccent = '#bccdc7';
const skogLight = '#307b68';
const myr = '#79a472';
const myrLight = '#b7cbb0';
const planta = '#cce18b';
const sol = '#f85203';
const viskan = '#00a5a0';
const virke = '#ccc09a';
const virkeLight = '#e3d8c7';

export const config: Config = {
  ...defaultConfig,
  name: 'proffsapp',
  subdomain: ['proffsapp', 'betaproffsapp'],
  supportPhone: '010 - 15 15 066',
  supportMail: 'proffsappen@struqtur.se',
  title: 'Proffsappen',
  theme: {
    ...defaultConfig.theme,
    iconPath: '/images/logo/Struqtur_Icon_RGB-White.svg',
    logoPath: '/images/proffsappen/proffsappen.png',
    loginBackground: '/images/hyperion/deromeBg2.jpg',
    linkColor: '#328953',
    brandPrimaryColor: skog,
    brandSecondaryColor: skogAccent,
    topMenuBackgroundColor: skog,
    topMenuTextTransform: 'uppercase',
    homeStartDarkColor: skog,
    homeStartLightColor: skogLight,
    buttonPrimaryColor: skog,
    buttonPrimaryColorDark: skogLight,
    buttonInfoColor: viskan,
    buttonDangerColor: sol,
    buttonWarningColor: planta,
    buttonSuccessColor: myr,
    headerFontFamily: 'Derome Sans',
    headerWeight: '300',
    favicons: [
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/images/proffsappen/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        sizes: '192x192',
        href: '/images/proffsappen/android-chrome-192x192.png',
        type: 'image/png',
      },
      {
        rel: 'icon',
        sizes: '512x512',
        href: '/images/proffsappen/android-chrome-512x512.png',
        type: 'image/png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        href: '/images/proffsappen/favicon-32x32.png',
        type: 'image/png',
      },
      {
        rel: 'icon',
        sizes: '16x16',
        href: '/images/proffsappen/favicon-16x16.png',
        type: 'image/png',
      },
      {
        rel: 'shortcut icon',
        href: '/images/proffsappen/favicon.ico',
      },
    ],
    workDiagramColor: skog,
    workNotInvoicedDiagramColor: skogLight,
    materialDiagramColor: myr,
    materialNotInvoicedDiagramColor: myrLight,
    mileageDiagramColor: virke,
    mileageNotInvoicedDiagramColor: virkeLight,
    overheadDiagramColor: planta,
  },
};
