import { Apollo } from 'apollo-angular';
import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';

import {
  ApolloErrorHandler,
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo';
import { DialogComponent } from 'app/shared/dialogs/dialog.component';
import { ProductsAutosuggestService } from 'app/shared/company/product/products.autosuggest.service';
import { ServiceLocator } from 'app/shared/helpers/service-locator';
import productsAutoSuggestServiceProvider from 'app/shared/company/product/products.autosuggest.service.provider';
import moment from 'moment';

@Component({
  selector: 'project-data',
  templateUrl: 'projectData.component.html',
  providers: [productsAutoSuggestServiceProvider],
})
export class ProjectDataComponent
  extends DialogComponent
  implements OnInit, OnDestroy
{
  @ViewChild('productsTable', { static: true }) public productsTable: Table;
  results = [];
  productAutoModel;
  display = false;
  loading = false;
  projectParams;
  labels;
  labelModels = [{ model: 'Product' }];
  public dateGt = '';
  public dateLt = '';
  activeTab = 'product';
  index = 0;
  orientation = 'top';
  products = [];
  subscription: Subscription;

  protected angularApollo: Apollo;
  protected mutationService: ApolloMutationService;
  protected apolloErrorHandler: ApolloErrorHandler;
  protected productsAutosuggestService: ProductsAutosuggestService;
  protected apolloQueryService: ApolloQueryService;
  public dialogRef: DynamicDialogRef;

  private searchQuery: Subject<string> = new Subject();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor() {
    super();
    this.angularApollo = ServiceLocator.injector.get(Apollo);
    this.mutationService = ServiceLocator.injector.get(ApolloMutationService);
    this.apolloErrorHandler = ServiceLocator.injector.get(ApolloErrorHandler);
    this.productsAutosuggestService = ServiceLocator.injector.get(
      ProductsAutosuggestService
    );

    this.apolloQueryService = ServiceLocator.injector.get(ApolloQueryService);
    this.dialogRef = ServiceLocator.injector.get(DynamicDialogRef);
  }

  ngOnInit() {
    this.orientation = this.helperService.getOrientation();
    this.dateGt = moment().add(-3, 'M').format('YYYY-MM-DD');
    this.dateLt = moment().format('YYYY-MM-DD');

    this.setupSearchQuery();
  }

  toogleDialog = () => (this.display = !this.display);

  scrollToTop() {
    const mdDialogContainer =
      document.getElementsByClassName('p-dialog-content')[0];
    mdDialogContainer.scrollTop = 0;
  }

  changeActiveTab(event) {
    const index = event['index'];

    switch (index) {
      case 0:
        this.activeTab = 'product';
        break;
      case 1:
        this.activeTab = 'days?';
        break;
    }
  }

  public closeAction(): void {
    this.dialogRef.close();
  }

  public searchProducts(event): void {
    this.productsAutosuggestService.searchNext(event.query, -1);
  }

  private setupSearchQuery(): void {
    this.productsAutosuggestService.setupSearchQuery();

    this.productsAutosuggestService.searchQueryOutput.subscribe(products => {
      this.results = products;
    });
  }

  public updateDateFilter(date: Date, field: string, operator: string): void {
    const newDate = moment(date).format('YYYY-MM-DD');
    this.productsTable.filter(newDate, field, operator);
  }

  public getProducts(id) {
    const variables = {
      productId: id,
    };
    return this.apolloQueryService.apolloWatchQueryTwo(
      'companyProjectProduct',
      variables
    );
  }

  public sumAntal(productsTable: Table): number {
    let sumAntal = 0;
    let rowsToSum = [];
    if (
      typeof productsTable['filteredValue'] !== 'undefined' &&
      productsTable['filteredValue'] !== null
    ) {
      rowsToSum = productsTable['filteredValue'];
    } else {
      rowsToSum = productsTable['_value'];
    }

    for (const product of rowsToSum) {
      sumAntal += product.antal * 1;
    }
    return sumAntal;
  }

  public setProductFromAutosuggest(value = {}) {
    this.products = [];

    const id = value['id'] * 1;

    this.labelsService
      .getLabels(this.labelModels)
      .pipe(
        switchMap(data => {
          this.loading = true;
          this.labels = data;
          return this.getProducts(id);
        })
      )
      .subscribe((data: any) => {
        this.loading = false;

        this.subscription = data.sub;
        const products = this.helperService.cleanFromNode(
          data.data.company.projectProducts_PreCompanyTypeHyperion
        );

        for (const product of products) {
          product['projectString'] =
            '' + product.project.trueId + ', ' + product.project.mark;
          product['userString'] = '';
          if (product.user) {
            product['userString'] =
              product.user.firstName + ' ' + product.user.lastName;
          }
          product['dateLt'] = product['date'];
          product['dateGt'] = product['date'];
          product['antal'] = Number(product['antal']);

          const obn = product.project.clientContact.orderBuisnessName;
          const n = product.project.clientContact.name;
          product['clientString'] = (obn ? obn + ' ' : '') + (n ? n : '');
        }
        this.products = products;

        this.productsTable.sortField = 'date';
        this.productsTable.sortOrder = 1;

        this.productsTable.filter(this.dateGt, 'dateGt', 'gte');
        this.productsTable.filter(this.dateLt, 'dateLt', 'lte');
      });
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
