import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';

import { LockedScreen, TimeoutWarning } from 'app/shared/idle';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonModule],
  declarations: [LockedScreen, TimeoutWarning],
  exports: [LockedScreen, TimeoutWarning],
})
export class ModalsModule {}
