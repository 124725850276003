import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApolloQueryService } from 'app/shared/apollo';
import {
  ContactInvoicesRootObject,
  ContactInvoicesCompany,
} from './contact-invoices';

@Injectable({
  providedIn: 'root',
})
export class ContactInvoicesService {
  constructor(private apolloQueryService: ApolloQueryService) {}

  public getInvoicesPerContact(
    contactId: number
  ): Observable<InvoicesPerContact> {
    const variables = { contactId };

    return this.apolloQueryService
      .apolloWatchQueryTwo('contactInvoices', variables, 'cache-and-network')
      .pipe(
        map<{ data: ContactInvoicesRootObject }, InvoicesPerContact>(
          ({ data }) => {
            return {
              contactId,
              invoices: this.companyToInvoicesReduced(data.company),
            };
          }
        )
      );
  }

  /**
   * Transforms a ContactInvoicesCompany into a InvoicesReduced array
   *
   * @param company the company response from the contactInvoices query
   */
  private companyToInvoicesReduced(
    company: ContactInvoicesCompany
  ): InvoicesReduced[] {
    return company.contacts.edges.flatMap(contactEdge => {
      contactEdge = JSON.parse(JSON.stringify(contactEdge));
      return contactEdge.node.children.edges.flatMap(contactNode => {
        return contactNode.node.invoices.edges.map<InvoicesReduced>(
          invoiceEdge => {
            const invoiceNode = invoiceEdge.node;
            return {
              id: +invoiceNode.id,
              contactOrderBusinessName: invoiceNode.contact.orderBuisnessName,
              contactTrueId: invoiceNode.contact.trueId,
              date: new Date(invoiceNode.fdatum),
              dueDate: new Date(invoiceNode.ffdatum),
              fakturaNr: +invoiceNode.fakturaNr,
              projectsTrueId: invoiceNode.projects_PreInvoiceTypeHyperion.edges
                .map(projectEdge => projectEdge.node.trueId)
                .join(', '),
              total: +invoiceNode.totalBox,
            };
          }
        );
      });
    });
  }
}

export interface InvoicesPerContact {
  contactId: number;
  invoices: InvoicesReduced[];
}

export interface InvoicesReduced {
  id: number;
  contactOrderBusinessName: string;
  contactTrueId: string;
  /** The date when the invoice was created - Fakturadatum (`fdatum` in the API) */
  date: Date;
  /** The due date - Förfallodatum (`ffdatum` in the API) */
  dueDate: Date;
  fakturaNr: number;
  /** A string consisting of the trueIds of projects for that invoice joined by a comma (`,`) */
  projectsTrueId: string;
  /** Total to pay including taxes */
  total: number;
}
