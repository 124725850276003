import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { DateRange } from 'app/invoice/single/date-range';
import { InvoiceMode } from 'app/invoice/single/enums/invoice-mode.enum';
import { RowType } from '../enums/row-type.enum';

@Injectable({
  providedIn: 'root',
})
export class InvoiceBodyService {
  constructor() {}

  /**
   * Given the invoice modes, we retrieve the corresponding row types
   * @param invoiceModes The list of invoice modes
   */
  public getRowTypesByInvoiceModes(invoiceModes: InvoiceMode[]): RowType[] {
    return invoiceModes.flatMap(mode => {
      switch (mode) {
        case InvoiceMode.Running:
          return [
            RowType.Days,
            RowType.Products,
            RowType.SubsistenceNight,
            RowType.SubsistenceDay,
            RowType.SubsistenceDayHalf,
          ];
        case InvoiceMode.RunningExtra:
          return [RowType.DaysExtra, RowType.ProductsExtra];
        case InvoiceMode.Installement:
          return [RowType.Installaments];
        case InvoiceMode.Offer:
          return [RowType.Offer];
        case InvoiceMode.OfferFromProject:
          return [RowType.OfferFromProject];
        case InvoiceMode.ATA:
          return [RowType.ATA];
      }
    });
  }

  public selectByDate({ from, to }: DateRange, rowDataContainers: any[]) {
    const selectableModes = [InvoiceMode.Running, InvoiceMode.RunningExtra];
    const selectableTypes = this.getRowTypesByInvoiceModes(selectableModes);

    const filteredResults = rowDataContainers.map(
      (rowDataContainer: {
        rowDetail: { date: string; used: boolean; isFree: boolean }[];
        rowDetailSelected: any[];
        mainTypeId: RowType;
      }) => {
        // If the container does not include the selectable row types, we include them
        if (!selectableTypes.includes(rowDataContainer.mainTypeId)) {
          return rowDataContainer;
        }
        // Otherwise we filter them

        // Reset Selected items
        rowDataContainer.rowDetailSelected = [];

        rowDataContainer.rowDetail.forEach(rowDetail => {
          if (rowDetail.date && (from || to)) {
            let isBetween = false;

            if (!from) {
              isBetween = moment(rowDetail.date).isSameOrBefore(to, 'day');
            } else if (!to) {
              isBetween = moment(rowDetail.date).isSameOrAfter(from, 'day');
            } else {
              isBetween = moment(rowDetail.date).isBetween(
                from,
                to,
                'day',
                '[]'
              );
            }

            if (isBetween && rowDetail.used) {
              rowDetail.used = true;
              rowDataContainer.rowDetailSelected.push(rowDetail);
            } else {
              rowDetail.used = false;
            }
            rowDetail.isFree = !rowDetail.used;
          }
        });

        return rowDataContainer;
      }
    );
    return filteredResults;
  }
}
