import {
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  Directive,
} from '@angular/core';
import { HyperionLabelsService } from '../labels';

@Directive()
export abstract class IndexButtonComponent implements OnInit, OnChanges {
  @Output() onClick = new EventEmitter<any>();
  @Input() class: string = null;
  @Input() badge: any = null;
  @Input() type: string;
  @Input() active: boolean;
  @Input() model: string;
  @Input() overrideButtonType: string;
  @Input() disabled = false;
  @Input() loading = false;
  @Input() extraText = '';
  btnLabel: string = null;
  btnIcon = '';
  btnClass = 'p-button-secondary';
  modelStrings = ['', '', ''];
  allowedClass = ['block', 'pull-right', 'ml5'];
  allowedOverrideButtonType = ['secondary', 'primary'];

  constructor(protected labelService: HyperionLabelsService) {}

  ngOnInit(): void {
    this.startBtn();
  }

  onClickHandler() {
    !this.disabled && this.onClick.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    changes.active && this.startBtn();
  }

  startBtn() {
    if (this.model) {
      this.getLabels();
    } else {
      this.setButton();
    }
  }

  getLabels() {
    let modelLabels = [];
    this.labelService.getLabels([{ model: this.model }]).subscribe(data => {
      if (data[this.model]) {
        const l = data[this.model];
        if (l['MODEL_LABEL_0']) {
          modelLabels.push(l['MODEL_LABEL_0']);
        }
        if (l['MODEL_LABEL_1']) {
          modelLabels.push(l['MODEL_LABEL_1']);
        }
        if (l['MODEL_LABEL_2']) {
          modelLabels.push(l['MODEL_LABEL_2']);
        }
      }

      if (modelLabels.length !== 3) {
        modelLabels = ['', '', ''];
      }

      this.modelStrings = modelLabels;

      this.setButton();
    });
  }

  abstract setButton(): void;

  appendInputedClass() {
    if (this.class) {
      const classArr = this.class.split(' ');

      for (let c of classArr) {
        c = c.trim();

        if (this.allowedClass.includes(c)) {
          this.btnClass = this.btnClass + ' ' + c;
        } else {
          throw new Error('class ' + c + ' does not exist on ButtonComponent');
        }
      }
    }
  }
}
