import { Apollo } from 'apollo-angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { queries } from 'app/shared/apollo/queries/index';

@Component({
  selector: 'app-offer-comment-handle',
  templateUrl: './offer-comment-handle.component.html',
  providers: [FormHandlerService],
})
export class OfferCommentHandleComponent implements OnInit {
  @Input() public offerInfo: { id: string | number }; // dataModel
  @Input() public dataObjectFromParent; // comment
  @Input() public apolloQueryName: string | number;
  @Output() public UpdateEmitter = new EventEmitter();

  private dataModel = 'comment';
  public buttonDisabled = false;
  private dataModelCapitalized = 'Comment';
  public componentMainForm: FormGroup;
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: {},
  };
  private buttons = {
    comment: {},
  };
  public buttonObject = ['update', 'delete'];

  constructor(
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private formHandler: FormHandlerService
  ) {}

  public ngOnInit(): void {
    this.buttons['comment'] = this.formHandler.getButtonValuesAsync(
      this.buttonObject
    );
    this.initForm();
  }

  private initForm(): void {
    this.formFields.attributes = { comment: this.dataObjectFromParent.comment };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['comment']['label'] =
          'Skapad ' +
          this.dataObjectFromParent['created'] +
          ' av ' +
          this.dataObjectFromParent['user']['firstName'] +
          ' ' +
          this.dataObjectFromParent['user']['lastName'];
      });
  }

  public actionUpdate(): void {
    this.buttonDisabled = true;

    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.dataObjectFromParent.id),
      comment: this.componentMainForm.controls['comment'].value,
    };

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttonDisabled = false;
    } else {
      const returnAttributes = ['id', 'comment'];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.UpdateEmitter.emit();
            }

            this.mutationService.displayMutationStatus(executedData);

            this.buttonDisabled = false;

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttonDisabled = false;
          }
        );
    }
  }

  public actionDelete(): void {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort kommentaren?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteComment();
      },
      reject: () => {
        this.buttonDisabled = false;
      },
    });
  }

  private deleteComment(): void {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };
    this.buttonDisabled = true;
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            this.componentMainForm
          );

          if (data.mutationSucceededAllArguments) {
            this.UpdateEmitter.emit();
          }

          this.mutationService.displayMutationStatus(data);

          this.buttonDisabled = false;

          executeMutationSub.unsubscribe();
        },
        err => {
          this.buttonDisabled = false;
          console.log(err);
        }
      );
  }
}
