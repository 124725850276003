import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError, retry } from 'rxjs/operators';
import { of } from 'rxjs';

import { GlobalService } from '../global/index';

import { HyperionErrorHandler } from 'app/shared/errors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public url: string;
  public content = {
    'Content-Type': 'application/json',
  };
  headers = {
    headers: new HttpHeaders(this.content),
  };

  private returnObject = {
    isAut: false,
    success: false,
    msg: null,
    successMsgs: [],
    infoMsgs: [],
    errorsMsgs: [],
    data: null,
  };

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private errorHandler: HyperionErrorHandler
  ) {}

  public makeHttpPostRequest(
    urlParam,
    valuesParam?,
    contentType = 'JSON'
  ): Observable<any> {
    return Observable.create(observer => {
      this.serverPostRequest(urlParam, valuesParam, contentType).subscribe(
        data => {
          observer.next(data);
          observer.complete();
        }
      );
    });
  }

  private serverPostRequest(urlParam, valuesParam, dataType = 'CLASSIC') {
    const url = this.globalService.getUrlPrefix() + urlParam;
    const contentType =
      dataType === 'JSON'
        ? 'application/json'
        : 'application/x-www-form-urlencoded; charset=UTF-8';
    if (valuesParam !== null) {
      return this.http.post(url, valuesParam, {
        headers: new HttpHeaders({
          'Content-Type': contentType,
        }),
      });
    } else {
      return this.http.post(url, {
        headers: new Headers({
          'Content-Type': contentType,
        }),
      });
    }
  }

  makeHttpGetRequest(url) {
    const returnObject = this.returnObject;
    return this.http
      .get(url)
      .pipe(
        map(data => {
          returnObject.isAut = true;
          returnObject.success = true;
          returnObject.data = data;
          return returnObject;
        }),
        retry(3),
        catchError(httpError => {
          console.warn({ httpError });
          if (httpError.status === 404) {
            this.errorHandler.handleAuthError();
          }
          return of(returnObject);
        })
      )
      .toPromise();
  }
}
