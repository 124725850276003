import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'app/shared/message';
import { BehaviorSubject, first } from 'rxjs';
import { Todo } from '../project-todo/project-todo.component';
import {
  EditTodoGQL,
  GetProjectTodoGQL,
  GetDefaultTodoGQL,
  GetTodoTopicsGQL,
  NewTodoGQL,
  GetUsersGQL,
  AddUserGQL,
  RemoveUserGQL,
  DeleteTopicGQL,
  CreateTopicGQL,
  EditTopicGQL,
  ProjectDatesGQL,
  CreateDefaultTodoGQL,
  UpdateDefaultTodoGQL,
} from './graphql/editTodo.generated';

@Component({
  selector: 'app-project-todo-form',
  templateUrl: './project-todo-form.component.html',
  styleUrls: ['./project-todo-form.component.scss'],
})
export class ProjectTodoFormComponent implements OnInit {
  public showHandleHeaders = false;
  @Input() public isEditAsync: BehaviorSubject<boolean>;
  @Input() private todoIdAsync: BehaviorSubject<string>;
  @Input() public projectId: string;
  @Input() public isCreateDefault = false;

  @Output() public updateEmit = new EventEmitter<boolean>();

  public todotopics: BehaviorSubject<{ id: string; Name: string }[]> =
    new BehaviorSubject([]);
  public usersAsync: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private todoId: string;
  private users: any[] = [];
  public selectedUserIds: string[] = [];
  private oldUserIds: string[] = [];
  private userRelations: any[];
  public isEdit = false;
  public todo: Todo = { topic: {} };

  constructor(
    private editTodo: EditTodoGQL,
    private newTodo: NewTodoGQL,
    private getDefaultTodo: GetDefaultTodoGQL,
    private getProjectTodo: GetProjectTodoGQL,
    private getTodoTopics: GetTodoTopicsGQL,
    private messageService: MessageService,
    private getUsers: GetUsersGQL,
    private addUser: AddUserGQL,
    private removeUser: RemoveUserGQL,
    private removeTopic: DeleteTopicGQL,
    private createTopic: CreateTopicGQL,
    private editTopic: EditTopicGQL,
    private projectDates: ProjectDatesGQL,
    private datePipe: DatePipe,
    private createDefaultTodoService: CreateDefaultTodoGQL,
    private updateDefaultTodoService: UpdateDefaultTodoGQL
  ) {}

  public ngOnInit(): void {
    this.setDefaults();
    this.fetchTodoTopics();
    this.fetchUsers();
  }

  private setDefaults(): void {
    this.isEditAsync?.subscribe(isEdit => {
      this.isEdit = isEdit;
      this.selectedUserIds = [];

      if (!this.projectId) {
        this.fetchTodo();
        return;
      }

      this.projectDates
        .fetch({ projectId: Number(this.projectId) })
        .pipe(first())
        .subscribe(res => {
          const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
          const endDate = res.data.project.endDate;
          const startDate =
            today < endDate ? today : res.data.project.startDate;

          if (!isEdit) {
            this.todo = { topic: {}, startDate: startDate, endDate: endDate };
          }

          this.fetchTodo();
        });
    });
  }

  private fetchTodo(): void {
    if (!this.isEdit) {
      return;
    }

    this.todoIdAsync.subscribe(id => {
      this.todoId = id;
      if (!id) {
        return;
      }

      if (this.isCreateDefault) {
        this.fetchDefaultTodo(id);
      } else {
        this.fetchProjectTodo(id);
      }
    });
  }

  private fetchDefaultTodo(id: string): void {
    this.getDefaultTodo
      .fetch({ id: Number(id) })
      .pipe(first())
      .subscribe(res => {
        const edges = res.data.company.projectDefualtTodos.edges;

        if (!edges || edges.length === 0) {
          return;
        }

        const todo = edges[0].node;
        this.todo = {
          ...todo,
          defaultForAllProjects: todo.defaultForAllProjects === 1,
          topic: { ...todo.topic },
        };
        this.selectedUserIds = [];
        this.oldUserIds = [];
        this.userRelations = [];
      });
  }

  private fetchProjectTodo(id: string): void {
    this.getProjectTodo
      .fetch({ id: Number(id) })
      .pipe(first())
      .subscribe(res => {
        const edges = res.data.company.todos.edges;

        if (!edges || edges.length === 0) {
          return;
        }

        const todo = edges[0].node;

        this.todo = { ...todo, topic: { ...todo.topic } };
        this.selectedUserIds = [];
        this.oldUserIds = [];
        this.userRelations = [];

        todo.usersTodoRelation.edges
          .map(n => n.node.user)
          .forEach(u => {
            this.selectedUserIds.push(u.id);
            this.oldUserIds.push(u.id);
          });

        todo.usersTodoRelation.edges
          .map(n => n.node)
          .forEach(u => this.userRelations.push(u));
      });
  }

  private fetchTodoTopics(): void {
    this.getTodoTopics
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const todoTopics = res.data.company.todotopics.edges
          .map(n => n.node)
          .filter(tt => tt.active)
          .filter(tt => tt.Name !== '')
          .map(tt => ({ id: tt.id, Name: tt.Name }));
        this.todotopics.next(todoTopics);
      });
  }

  private fetchUsers(): void {
    this.getUsers
      .fetch()
      .pipe(first())
      .subscribe(res => {
        const users = res.data.company.users.edges
          .map(n => n.node)
          .filter(u => !u.hidden && !u.isDeleted);
        this.usersAsync.next(users);
        this.users = users;
      });
  }

  public createTodo(todo: Todo): void {
    if (!todo.description || !todo.topic.id) {
      this.messageService.insertData({
        severity: 'error',
        textArray: ['Arbetsmoment och rubrik måste anges'],
      });
      return;
    }
    if (this.isCreateDefault) {
      this.createDefaultTodo(todo);
    } else {
      this.createProjectTodo(todo);
    }
  }

  private createProjectTodo(todo: Todo): void {
    this.newTodo
      .mutate({
        todo: {
          description: todo.description,
          startDate: todo.startDate,
          endDate: todo.endDate,
          type: Number(todo.topic.id),
          projectId: Number(this.projectId),
          estimatedTime: todo.estimatedTime,
        },
      })
      .subscribe(res => {
        const success = res.data.todoTypeHyperionMutation.mutationDetails.every(
          d => d.mutationSucceeded
        );
        this.messageService.insertDataFromMutation(
          res.data.todoTypeHyperionMutation
        );
        this.updateEmit.emit(success);
        if (success) {
          this.todoId = res.data.todoTypeHyperionMutation.id;
          this.addAllMissingUsers();
          this.setDefaults();
        }
      });
  }

  private createDefaultTodo(todo: Todo): void {
    this.createDefaultTodoService
      .mutate({
        description: todo.description,
        type: Number(todo.topic.id),
        estimatedTime: todo.estimatedTime ? todo.estimatedTime : 0,
        defaultForAllProjects: Number(todo.defaultForAllProjects),
        orderNr: todo.orderNr ? todo.orderNr : 0,
      })
      .subscribe(res => {
        const success =
          res.data.projectDefualtTodoTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        this.messageService.insertDataFromMutation(
          res.data.projectDefualtTodoTypeHyperionMutation
        );
        this.updateEmit.emit(success);
      });
  }

  public updateTodo(todo: Todo): void {
    if (!todo.description || !todo.topic.id) {
      this.messageService.insertData({
        severity: 'error',
        textArray: ['Arbetsmoment och rubrik måste anges'],
      });
      return;
    }
    if (this.isCreateDefault) {
      this.updateDefaultTodo(todo);
    } else {
      this.updateProjectTodo(todo);
    }
  }

  private updateProjectTodo(todo: Todo): void {
    this.editTodo
      .mutate({
        todo: {
          id: Number(todo.id),
          description: todo.description,
          startDate: todo.startDate,
          endDate: todo.endDate,
          type: Number(todo.topic.id),
          projectId: Number(this.projectId),
          orderNr: todo.orderNr,
          estimatedTime: todo.estimatedTime,
        },
      })
      .subscribe(res => {
        const success = res.data.todoTypeHyperionMutation.mutationDetails.every(
          d => d.mutationSucceeded
        );
        this.messageService.insertDataFromMutation(
          res.data.todoTypeHyperionMutation
        );
        this.updateEmit.emit(success);
      });
    this.addAllMissingUsers();
    this.deleteAllRemovedUsers();
    this.setDefaults();
  }

  private updateDefaultTodo(todo: Todo): void {
    this.updateDefaultTodoService
      .mutate({
        id: Number(todo.id),
        description: todo.description,
        type: Number(todo.topic.id),
        estimatedTime: todo.estimatedTime,
        defaultForAllProjects: Number(todo.defaultForAllProjects),
        orderNr: todo.orderNr,
      })
      .subscribe(res => {
        const success =
          res.data.projectDefualtTodoTypeHyperionMutation.mutationDetails.every(
            d => d.mutationSucceeded
          );
        this.messageService.insertDataFromMutation(
          res.data.projectDefualtTodoTypeHyperionMutation
        );
        this.updateEmit.emit(success);
      });
  }

  public userStringFromId(id: string): string {
    const user = this.users.find(u => u.id === id);
    return user?.firstName + ' ' + user?.lastName;
  }

  private addUserToTodo(userId: number, todoId: number): void {
    this.addUser.mutate({ userId: userId, todoId: todoId }).subscribe(() => {
      return;
    });
  }

  private addAllMissingUsers(): void {
    const userIds = this.selectedUserIds.filter(
      u => !this.oldUserIds.includes(u)
    );

    userIds.forEach(userId => {
      this.addUserToTodo(Number(userId), Number(this.todoId));
    });
  }

  private removeUserFromTodo(id: number): void {
    this.removeUser.mutate({ id: id }).subscribe(() => {
      return;
    });
  }

  private deleteAllRemovedUsers(): void {
    const userIds = this.oldUserIds.filter(
      u => !this.selectedUserIds.includes(u)
    );

    this.userRelations
      .filter(ur => userIds.includes(ur.user.id))
      .forEach(ur => {
        this.removeUserFromTodo(ur.id);
      });
  }

  public addTopic(name: string): void {
    this.createTopic.mutate({ name: name }).subscribe(res => {
      this.messageService.insertDataFromMutation(
        res.data.todotopicTypeHyperionMutation
      );
      this.fetchTodoTopics();
    });
  }

  public deleteTopic(id: string): void {
    this.removeTopic.mutate({ id: Number(id) }).subscribe(res => {
      this.messageService.insertDataFromMutation(
        res.data.todotopicTypeHyperionMutation
      );
      this.fetchTodoTopics();
    });
  }

  public updateTopic(id: string, name: string): void {
    this.editTopic.mutate({ id: Number(id), name: name }).subscribe(res => {
      this.messageService.insertDataFromMutation(
        res.data.todotopicTypeHyperionMutation
      );
      this.fetchTodoTopics();
    });
  }
}
