<div [ngSwitch]="type">
  <ng-container *ngSwitchCase="'default'">
    <label class="p-col-12"
      >{{ label }}
      <span class="help-tooltip" pTooltip="{{ tooltipMessage }}">?</span></label
    >

    <p-dropdown
      #dropdown
      class="p-col-12"
      [placeholder]="placeholder"
      [options]="options"
      (onChange)="selectExternalProject($event)"
      [disabled]="isDisabled"
      (onShow)="onShow()"
    >
    </p-dropdown>
  </ng-container>

  <ng-container *ngSwitchCase="'wide'">
    <appForm2-wide-dropDown
      #appForm2Dropdown
      [dropDownParams]="dropdownParams"
      [htmlAttributes]="htmlAttributes"
      [tooltip]="tooltip"
      [(ngModel)]="model"
      (ngModelChange)="selectExternalProject({ value: $event })"
      (show)="onShow()"
    >
    </appForm2-wide-dropDown>
  </ng-container>

  <ng-container *ngSwitchCase="'simple'">
    <appForm2-simple-dropDown
      #appForm2Dropdown
      [dropDownParams]="dropdownParams"
      [htmlAttributes]="htmlAttributes"
      [tooltip]="tooltip"
      [(ngModel)]="model"
      (ngModelChange)="selectExternalProject({ value: $event })"
      (show)="onShow()"
    >
    </appForm2-simple-dropDown>
  </ng-container>

  <ng-container *ngSwitchCase="'list'">
    <appForm2-list-dropDown
      #appForm2Dropdown
      [dropDownParams]="dropdownParams"
      [htmlAttributes]="htmlAttributes"
      [tooltip]="tooltip"
      [(ngModel)]="model"
      (ngModelChange)="selectExternalProject({ value: $event })"
      (show)="onShow()"
    >
    </appForm2-list-dropDown>
  </ng-container>

  <ng-container *ngSwitchCase="'list-with-label'">
    <div class="p-col-12">
      <h4>
        {{ label }}
        <span
          class="help-tooltip"
          tooltipPosition="top"
          pTooltip="{{ tooltipMessage }}"
          >?</span
        >
      </h4>
    </div>
    <div class="p-col-12">
      <appForm2-list-dropDown
        #appForm2Dropdown
        [dropDownParams]="dropdownParams"
        [htmlAttributes]="htmlAttributes"
        [tooltip]="tooltip"
        [(ngModel)]="model"
        (ngModelChange)="selectExternalProject({ value: $event })"
        (show)="onShow()"
      >
      </appForm2-list-dropDown>
    </div>
  </ng-container>
</div>
