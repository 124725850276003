import { gql } from 'apollo-angular';

import { Invoice } from 'app/shared/apollo/queries/invoice';

const dataAttributesLight = `
      id
      fakturaNr
      type
      kreditfaktura
      invoiceReminder
      sumBox
      momsBox
      rotBox
      totalBox
      bruttoBox
      status
      statusRot
      rotYear
      projectInvoiceHistory
      interest
      sended
      created
      companyId
      projectId
      externalFakturaNr
      ovrigt
      fakturaAdress
      fakturaOrgNr
      typeInvoice
      typeLayout
      description
      companyName
      companyAdress
      companyAddressRow2
      companyTlfn
      companyFax
      companyMail
      companyOrgNr
      companyWebb
      companyBankGiro
      companyPostGiro
      companyIban
      companyBicSwift
      fdatum
      ffdatum
      importedFrom
      importedFromId
      payDate
      our_reference
      your_reference
      your_order_nr
      contact_id
      balance
      invoiceFee
      taxReductionWork
      taxReductionMaterial
      paymentPassed_PreInvoiceTypeHyperion
      hasInvoiceBase_PreInvoiceTypeHyperion
      projects_PreInvoiceTypeHyperion {
        edges {
          node {
            id
            trueId
            mark
          }
        }
      }
      invoiceSourceDocuments_PreInvoiceTypeHyperion {
        edges {
            node {
                url
                label
            }
        }
      }
      contact {
        id
        trueId
        address
        address2
        city
        cityCode
        name
        mail
        mobilePhone
        orderBuisnessName
        orgNr
        phone
      }
    `;

export const CompanySupplierInvoiceFirst = gql`
query CompanySupplierInvoiceFirst ($first: Int, $offset: Int) {
  company  {
    id
    invoices: supplierInvoices (first: $first, offset: $offset) {
      totalCount
      edges {
        node {
          ${dataAttributesLight}
        }
      }
    }
  }
}`;
export const CompanySupplierInvoiceLast = gql`
query CompanySupplierInvoiceLast ($last: Int, $offset: Int) {
  company  {
    id
    invoices: supplierInvoices (last: $last, offset: $offset) {
      totalCount
      edges {
        node {
          ${dataAttributesLight}
        }
      }
    }
  }
}`;
export const CompanySupplierInvoiceAll = gql`
query CompanySupplierInvoiceAll {
  company  {
    id
    invoices: supplierInvoices {
      totalCount
      edges {
        node {
          ${dataAttributesLight}
        }
      }
    }
  }
}`;

export const CompanySupplierInvoicesByIds = gql`
query fetchCompanySupplierInvoicesByIds($multiId: [Int]) {
  company {
    id
    invoices: supplierInvoices(multiId: $multiId) {
      totalCount
      edges {
        node {
          ${dataAttributesLight}
        }
      }
    }
  }
}`;

export const RotPersonsByProjectId = gql`
  query getContactPersonsByProjectId($id: Int) {
    project(id: $id) {
      id
      rots {
        edges {
          node {
            id
            personalNumber
            nameOfProperty
            housingAssociationOrgNumber
            apartmentDesignation
            customerWantedWorkAmount
            name
          }
        }
      }
    }
  }
`;
export const getFull = gql`
query getFullInvoice($id: Int) {
  company {
    id
    betalningsvillkor
    invoices: supplierInvoices(id: $id) {
      totalCount
      edges {
        node {

          ${dataAttributesLight}

          projectsTroughRows {
            edges {
              node {
                id
                trueId
                mark
              }
            }
          }

          days (first:-1) {
            totalCount
          }
          products (first:-1) {
            totalCount
          }
          invoiceRotRequestingsExtra {
            ...HouseWorkTypeCosts
          }
          invoiceRotRequestings {
            edges {
              node {
                id
                personalNumber
                nameOfProperty
                housingAssociationOrgNumber
                apartmentDesignation
                customerWantedWorkAmount
                wantedWorkAmount
                name
              }
            }
          }

          rows {
            edges {
              node {
                id
                typeId
                benamning
                antal
                enhet
                project_id
                apris
                discount
                tax
                pris
                arbetskostnad
                accountingPlanAccountId
                project{
                  id
                  trueId
                  mark
                }
              }
            }
          }
        }
      }
    }
  }
}
${Invoice.fragments.houseWorkTypeCosts}
`;
