import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

import { HttpService } from 'app/shared/http';
import { GlobalService } from '../../../shared/global';

@Injectable()
export class InvoiceService {
  invoicePathForProject = '';

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService
  ) {
    this.invoicePathForProject =
      this.globalService.getUrlPrefix() + '/project/InvoiceData/';
  }

  projectsInvoiceData(ids: string[]) {
    const subs = [];
    ids.forEach(projectId => {
      subs.push(
        this.httpService
          .makeHttpGetRequest(
            this.invoicePathForProject + projectId + '?fromInvoice=1'
          )
          .then(({ data }) => data)
      );
    });

    return forkJoin(subs);
  }
}
