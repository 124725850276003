import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TooltipModule } from 'primeng/tooltip';

import { TableModule } from 'primeng/table';
import { RouterModule } from '@angular/router';

import {
  CreateUserComponent,
  EditUserComponent,
  UserReductionWorkingHoursComponent,
  UserSalaryVacationComponent,
  UserListComponent,
} from './index';
import { HyperionSharedModule } from '../../shared/shared.module';
import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { LoadingModule } from '../../shared/loading/loading.module';
import { UserSettingsComponent } from 'app/registry/users/user-settings.component';
import { SharedDialogModule } from '../../shared/dialogs/shared.dialog.module';
import { HeaderModule } from 'app/header/header.module';
import { SidebarModule } from 'primeng/sidebar';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionCardModule,
    LoadingModule,
    RouterModule,
    // Primeng
    AccordionModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    SharedModule,
    TabViewModule,
    ToggleButtonModule,
    TooltipModule,
    HyperionSharedModule,
    TableModule,
    SharedDialogModule,
    HeaderModule,
    SidebarModule,
  ],
  declarations: [
    UserSettingsComponent,
    CreateUserComponent,
    EditUserComponent,
    UserListComponent,
    UserReductionWorkingHoursComponent,
    UserSalaryVacationComponent,
  ],
  exports: [UserSettingsComponent],
})
export class UserSettingsModule {}
