import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import * as moment from 'moment';

import { GlobalService } from '../../shared/global/index';
import { ApolloQueryService } from '../../shared/apollo';
import { MessageService } from '../../shared/message/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { GlobalInvoiceService } from '../../global-services/invoice/invoice.service';
import { InvoiceType } from '../single/enums/invoice-type.enum';

@Component({
  selector: 'app-tax-reduction-dialog',
  templateUrl: './tax-reduction-dialog.component.html',
  styleUrls: ['./tax-reduction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxReductionDialogComponent implements OnInit {
  @Input() id: number;
  loading = true;
  invoice: any;
  url: string;
  paydate: any;
  taxreducationTypeString = '';
  tableDatas: any[] = [];
  cols = [];
  public greenTaxReduction = InvoiceType.Green;

  constructor(
    private http: HttpClient,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService,
    private messageService: MessageService,
    private change: ChangeDetectorRef,
    public dialogConfig: DynamicDialogConfig,
    private labelsService: HyperionLabelsService,
    private globalInvoiceService: GlobalInvoiceService
  ) {
    this.url = this.globalService.getUrlPrefix();
  }

  ngOnInit() {
    if (this.dialogConfig.data) {
      this.initComponentWithDialogData();
    }

    this.fetchLabelAndComponentData();
  }

  private initComponentWithDialogData() {
    this.id = this.dialogConfig.data.id;
    setTimeout(() => {
      this.dialogConfig.header = 'Hantera skattereduktion';
    }, 0);
  }

  private fetchLabelAndComponentData() {
    this.labelsService
      .getLabels([{ model: 'InvoiceRotRequesting' }])
      .subscribe(labels => {
        this.cols = [
          { field: 'created', header: 'Exporterad' },
          { field: 'fullName', header: 'Namn' },
          {
            field: 'personalNumber',
            header: labels.InvoiceRotRequesting.personalNumber,
          },
          {
            field: 'nameOfProperty',
            header: labels.InvoiceRotRequesting.nameOfProperty,
          },
          { field: 'workPrice', header: labels.InvoiceRotRequesting.workPrice },
          { field: 'workPayd', header: labels.InvoiceRotRequesting.workPayd },
          {
            field: 'wantedWorkAmount',
            header: labels.InvoiceRotRequesting.wantedWorkAmount,
          },
        ];
        this.fecthData();
      });
  }

  getXML() {
    setTimeout(() => {
      this.fecthData();
    }, 500);
  }

  fecthData() {
    this.tableDatas = [];
    let tableObject = {};

    this.apolloQueryService
      .apolloQuery('invoiceTaxReduction', { id: this.id })
      .subscribe(({ data }) => {
        const res = this.apolloQueryService.cleanFromNode(
          data.company.invociesAndSupplierInvoices
        );
        this.invoice = res[0];
        res[0]['statusRot'] &&
          (this.invoice['statusRot'] = +res[0]['statusRot']);
        this.invoice['invoiceRotRequestings'] =
          this.apolloQueryService.cleanFromNode(
            res[0]['invoiceRotRequestings']
          );
        let exports = [];
        this.invoice['invoiceRotRequestings'].forEach(
          (invoiceRotRequesting, index) => {
            exports = this.apolloQueryService.cleanFromNode(
              invoiceRotRequesting['invoiceRotRequestingExports']
            );

            tableObject = {};
            tableObject['nameOfProperty'] = invoiceRotRequesting.nameOfProperty
              ? invoiceRotRequesting.nameOfProperty
              : '';
            tableObject['personalNumber'] = invoiceRotRequesting.personalNumber
              ? invoiceRotRequesting.personalNumber
              : '';
            tableObject['housingAssociationOrgNumber'] =
              invoiceRotRequesting.housingAssociationOrgNumber
                ? invoiceRotRequesting.housingAssociationOrgNumber
                : '';

            exports.forEach(element => {
              if (element['xml']) {
                element = {
                  ...element,
                  ...{ xml: JSON.parse(element['xml']) },
                };
              }
              tableObject['created'] = element.created;
              tableObject['fullName'] = `${
                element.user['firstName'] ? element.user['firstName'] : ''
              } ${element.user['lastName'] ? element.user['lastName'] : ''}`;
              tableObject['workPrice'] = element.xml['workPrice']
                ? element.xml['workPrice']
                : '';
              tableObject['workPayd'] = element.xml['workPayd']
                ? element.xml['workPayd']
                : '';
              tableObject['wantedWorkAmount'] = element.xml['wantedWorkAmount']
                ? element.xml['wantedWorkAmount']
                : '';
              this.tableDatas.push(tableObject);
            });
          }
        );
        this.paydate = this.invoice['payDate'];

        if (this.invoice['payDate']) {
          this.paydate = moment(this.invoice['payDate'])
            .add(9, 'days')
            .format('YYYY-MM-DD');
        }

        this.taxreducationTypeString =
          this.globalInvoiceService.getInvoiceTypeString(
            this.invoice['typeInvoice']
          );

        this.loading = false;
        this.change.detectChanges();
      });
  }

  updateStatusRot(statusRot) {
    const url =
      this.url +
      '/invoice/UpdateStatusRotHyperion?invoiceId=' +
      this.id +
      '&status=' +
      statusRot;
    this.invoice['statusRot'] = statusRot;
    this.http
      .post(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      })
      .pipe(map(res => res))
      .subscribe(res => {
        this.messageService.insertData({
          textArray: [res['msg']],
          type: res['status'],
        });

        this.globalInvoiceService.mutationOcured([this.id]);
      });
  }
}
