<p-card class="p-p-0">
  <div class="p-d-flex p-jc-between p-ai-center p-mb-4">
    <span class="p-d-flex p-ai-center">
      <i class="icon-offer p-mr-2"></i>
      <p class="contents-header">Innehåll</p>
    </span>
  </div>
  <ol
    class="p-p-0"
    cdkDropList
    (cdkDropListDropped)="onDragAndDropMenu($event)"
  >
    <li
      *ngIf="offer['type'] !== 'ata'"
      class="list-item"
      [class.focused]="(activeSection | async) === -1"
      (click)="scrollToSection.emit(-1)"
    >
      <span class="scroll-button"> Kontakt</span>
      <p-checkbox [binary]="true" [ngModel]="true" disabled></p-checkbox>
    </li>
    <ng-container *ngFor="let row of offer['offerAttr']">
      <li
        cdkDrag
        *ngIf="row.type === 'offerAttribute' || row.type === 'module'"
        class="list-item"
        [class.focused]="row['order'] === (activeSection | async)"
        (click)="scrollToSection.emit(row['order'])"
      >
        <span class="scroll-button">
          {{ row.label }}
        </span>
        <span
          ><i class="pi pi-sort p-mr-2"></i>
          <p-checkbox
            [binary]="true"
            [ngModel]="row.active === 1"
            (onChange)="updateRowActiveState(row['order'], $event)"
            pTooltip="Markera för att använda för denna offerten / mall"
          ></p-checkbox>
        </span>
      </li>
    </ng-container>
  </ol>

  <div class="p-grid p-p-0">
    <ng-container
      [formGroup]="componentMainForm"
      *ngIf="(showBtnsForTemplate | async) && offer['type'] !== 'ata'"
    >
      <div class="p-col-12" *ngIf="(showEditTemplateBtns | async) === false">
        <button
          pButton
          class="p-button-primary-hyperion"
          (click)="updateTemplate(1)"
          icon="pi pi-save"
          label="Skapa offert"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-6" *ngIf="(showEditTemplateBtns | async) === false">
        <button
          pButton
          [disabled]="btnlockedAsyn | async"
          (click)="updateTemplate(0)"
          label="Spara utkast"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-6" *ngIf="(showEditTemplateBtns | async) === false">
        <button
          pButton
          *ngIf="!showInput"
          [disabled]="
            hasDeromeWebshopProducts ||
            (showInput &&
              componentMainForm.controls['templateName'].value.length < 1)
          "
          (click)="createTemplate()"
          [title]="
            hasDeromeWebshopProducts &&
            'Produkter från Derome webbshop kan inte sparas till offertmallen.'
          "
          label="Spara mall"
          style="width: 100%"
        ></button>
        <button
          pButton
          *ngIf="showInput"
          [disabled]="
            showInput &&
            componentMainForm.controls['templateName'].value.length < 1
          "
          (click)="createTemplate()"
          label="Spara mall"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-12" *ngIf="showEditTemplateBtns | async">
        <button
          pButton
          class="p-button-primary-hyperion"
          (click)="setVariablesAndEmitNavAction('update')"
          icon="pi pi-save"
          label="Spara mall"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-12">
        <button
          pButton
          [disabled]="btnlockedAsyn | async"
          (click)="showPdfInModal()"
          label="Förhandsgranska"
          icon="pi pi-file-pdf"
          style="width: 100%"
        ></button>
      </div>

      <div class="p-col-12" *ngIf="showInput">
        <input
          pInputText
          formControlName="templateName"
          placeholder="Mallens namn"
          style="width: 100%"
        />
      </div>
    </ng-container>

    <ng-container *ngIf="(showBtnsForTemplate | async) === false">
      <div
        class="p-col-12"
        *ngIf="(hideButtons | async) === false"
        [id]="offer['id'] + 'VersionsDropdown'"
      >
        <button
          *ngIf="offer.status === 0"
          pButton
          (click)="saveFromDraft()"
          label="Spara offert"
          icon="pi pi-save"
          class="p-button-primary-inverted"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-12">
        <div
          class="p-my-2 p-d-flex p-jc-center p-align-end"
          *ngIf="offer.isLocked"
        >
          <i class="pi pi-lock p-mx-1"></i>
          <span>Offerten är låst och kan inte uppdateras.</span>
        </div>
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="(btnlockedAsyn | async) || offer.isLocked"
          (click)="setVariablesAndEmitNavAction('update')"
          icon="pi pi-save"
          label="Uppdatera"
          style="width: 100%"
        ></button>
      </div>
      <div
        class="p-col-12"
        *ngIf="(hideButtons | async) === false && offer.status !== 0"
      >
        <button
          pButton
          icon="pi pi-envelope"
          label="Skicka"
          [disabled]="btnlockedAsyn | async"
          (click)="showMailModule()"
          style="width: 100%"
        ></button>
      </div>
      <div
        class="p-col-12"
        *ngIf="
          (hideButtons | async) === false &&
          (showSendZigned | async) &&
          offer.status !== 0
        "
      >
        <button
          pButton
          label="Digital signering"
          (click)="actionShowDigitalSignDialog()"
          [disabled]="btnlockedAsyn | async"
          style="width: 100%"
        >
          <i class="icon-bankid"></i>
        </button>
      </div>
      <div class="p-col-12">
        <button
          pButton
          [disabled]="btnlockedAsyn | async"
          (click)="showPdfInModal()"
          icon="pi pi-file-pdf"
          label="Förhandsgranska"
          style="width: 100%"
        ></button>
      </div>

      <div class="p-col-12" *ngIf="(hideButtons | async) === false">
        <button
          pButton
          [disabled]="btnlockedAsyn | async"
          (click)="confirmCopyOffer()"
          icon="pi pi-copy"
          label="Kopiera"
          style="width: 100%"
        ></button>
      </div>

      <div
        class="p-col-12"
        *ngIf="(hideButtons | async) === false && offer.status !== 0"
      >
        <button
          pButton
          [label]="
            'Skapa ' + (offer.type === 'ata' ? 'underprojekt' : 'projekt')
          "
          icon="pi pi-external-link"
          (click)="createProjectFromOffer()"
          [disabled]="btnlockedAsyn | async"
          class="p-button-primary-inverted"
          style="width: 100%"
        ></button>
      </div>

      <div class="p-col-12">
        <p-checkbox
          [ngModelOptions]="{ standalone: true }"
          label="Autospara"
          [binary]="true"
          [trueValue]="1"
          [falseValue]="0"
          [ngModel]="autoSaveOptions.ascDesc"
          (click)="autoSaveCheckboxClicked()"
        ></p-checkbox>
      </div>
    </ng-container>

    <ng-container *ngIf="offer['type'] === 'ata' && offerMode === 'create'">
      <div class="p-col-12">
        <button
          pButton
          [disabled]="btnlockedAsyn | async"
          (click)="showPdfInModal()"
          icon="pi pi-file-pdf"
          label="Förhandsgranska"
          style="width: 100%"
        ></button>
      </div>
      <div class="p-col-12">
        <button
          pButton
          class="p-button-primary-hyperion"
          icon="pi pi-save"
          label="Skapa ÄTA"
          [disabled]="btnlockedAsyn | async"
          (click)="updateTemplate(1)"
          style="width: 100%"
        ></button>
      </div>
    </ng-container>
  </div>
</p-card>
