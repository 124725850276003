import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import {
  CompanyProjectTypeSettingsComponent,
  EditProjectTypeComponent,
  CreateProjectTypeComponent,
} from './index';
import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { LoadingModule } from '../../../shared/loading/loading.module';
import { HyperionSharedModule } from '../../../shared/shared.module';
import { CardModule } from 'primeng/card';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionCardModule,
    LoadingModule,
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    SharedModule,
    TabViewModule,
    TooltipModule,
    HyperionSharedModule,
    CardModule,
  ],
  declarations: [
    CompanyProjectTypeSettingsComponent,
    EditProjectTypeComponent,
    CreateProjectTypeComponent,
  ],
  exports: [CompanyProjectTypeSettingsComponent],
})
export class CompanyProjectTypeModule {}
