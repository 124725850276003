<style>
  @media (min-width: 641px) {
    .wide-label {
      font-weight: 600 !important;
      font-size: 0.8em;
    }
  }
</style>
<div style="overflow: visible">
  <!-- TOPIC CRUD -->
  <div class="p-grid" style="overflow: visible">
    <div class="p-col-12 p-lg-6 p-g-nopad full-width" style="overflow: visible">
      <hyperion-card hasHeader="true">
        <div class="header">
          <h4>Skapa nytt standardarbetsmoment</h4>
        </div>

        <div class="p-grid">
          <form [formGroup]="componentMainForm">
            <div class="p-col-12 p-g-nopad">
              <appForm2-wide-dropDown
                formControlName="type"
                [dropDownParams]="{ options: todoTopicsDropdown }"
              ></appForm2-wide-dropDown>
            </div>

            <div class="p-col-12 p-g-nopad">
              <div class="p-grid p-g-nopad">
                <div class="p-col-12 p-md-3">
                  <label class="wide-label">Arbetsmoment</label>
                </div>
                <div class="p-col-12 p-md-9">
                  <div
                    class="form-group inline"
                    [class.has-danger]="
                      componentMainForm.controls['description']['showErrors']
                    "
                  >
                    <textarea
                      style="width: 100%"
                      pInputTextarea
                      [(ngModel)]="todoSearchString"
                      [ngModelOptions]="{ standalone: true }"
                      rows="3"
                      (input)="search()"
                      (clickOutside)="closeDropdown()"
                      class="resize-vert"
                    ></textarea>
                    <p-autoComplete
                      [suggestions]="results | async"
                      inputStyleClass="form-control hide-input"
                      field="description"
                      (onSelect)="setTodoFromAutosuggest($event)"
                      [style]="{ width: '100%', display: 'none' }"
                      [inputStyle]="{ width: '100%' }"
                      [(ngModel)]="todoAutoModel"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <ng-template let-object pTemplate="item">
                        <div class="p-helper-clearfix" style="font-size: 12px">
                          <div class="autocomplete-row">
                            <div>{{ object.description }}</div>
                            <div style="float: none" class="clear-both"></div>
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                    <div
                      *ngIf="
                        componentMainForm.controls['description']['showErrors']
                      "
                    >
                      <p
                        *ngFor="
                          let error of componentMainForm.controls.description
                            .mergedErrors
                        "
                        class="form-control-feedback"
                      >
                        <span><i class="fa fa-times"></i> </span
                        ><span [innerHTML]="error"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="p-col-12 p-g-nopad">
              <appForm2-wide-input
                formControlName="estimatedTime"
              ></appForm2-wide-input>
            </div>

            <div class="p-col-12 p-g-nopad align-right">
              <app-button
                [disabled]="buttons.create.disabled"
                (onClick)="actionCreate()"
                [type]="'create'"
              ></app-button>
            </div>
          </form>
        </div>
      </hyperion-card>
    </div>

    <div class="p-col-12 p-lg-6">
      <div class="p-grid">
        <div class="p-col-12">
          <h4>Hantera rubriker</h4>
          <p>
            För att få bättre ordning på projektet kan du strukturera de olika
            arbetsmomenten under olika rubriker. Till exempel El, VVS eller Bygg
            eller enskilda personer på ert företag.
          </p>
        </div>

        <div class="p-col-12 p-g-nopad">
          <form [formGroup]="todoTopicForm">
            <div class="p-grid">
              <div class="p-col-12 p-lg-9">
                <appForm2-list-input
                  formControlName="Name"
                  (keyup.enter)="createTopic()"
                ></appForm2-list-input>
              </div>
              <div class="p-col-12 p-lg-3">
                <app-button
                  class="block"
                  (onClick)="createTopic()"
                  [disabled]="topicButtons.create.disabled"
                  [type]="'save'"
                  [model]="'Todotopic'"
                ></app-button>
              </div>
            </div>
          </form>
        </div>

        <div class="p-col-12 p-g-nopad">
          <div class="p-grid">
            <div class="p-col-12 p-lg-9">
              <appForm2-list-dropDown
                [(ngModel)]="eraseTopicModel"
                [ngModelOptions]="{ standalone: true }"
                [dropDownParams]="{
                  options: altTodoTopicsDropdown,
                  filter: true
                }"
              ></appForm2-list-dropDown>
            </div>
            <div class="p-col-12 p-lg-3">
              <app-button
                class="block"
                (onClick)="eraseTopic()"
                [disabled]="topicButtons.delete.disabled"
                [type]="'delete'"
                [model]="'Todotopic'"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- LIST -->
  <div class="todo-container">
    <spinner *ngIf="loading"></spinner>
    <div *ngIf="!loading">
      <div *ngIf="dataSet.todoTopicsWithChilds.length == 0">
        <app-emptyState
          [model]="'Todo'"
          [whereToCreate]="'top'"
        ></app-emptyState>
      </div>

      <div
        class="p-grid p-g-nopad todo-topic"
        *ngFor="let topic of dataSet.todoTopicsWithChilds"
      >
        <div class="p-col-12">
          <hyperion-card>
            <div class="p-grid">
              <div class="p-col-12">
                <h3>{{ topic.name }}</h3>
              </div>
              <div class="p-col-12 p-g-nopad">
                <div class="p-grid">
                  <div class="p-col-12 p-lg-3 show-on-large-device">
                    <h5>Arbetsmoment</h5>
                  </div>
                  <div class="p-col-6 p-lg-1 show-on-large-device">
                    <h5>Ordning</h5>
                  </div>
                  <div class="p-col-6 p-lg-1 show-on-large-device">
                    <h5>Estimerad tid</h5>
                  </div>
                  <div class="p-col-6 p-lg-2 show-on-large-device">
                    <h5>Spara med detta projekt</h5>
                  </div>
                  <div class="p-col-6 p-lg-2 show-on-large-device">
                    <h5>Spara för alla projekt</h5>
                  </div>
                  <div class="p-col-12 p-lg-1 show-on-large-device"></div>
                  <div class="p-col-12 p-lg-2 show-on-large-device"></div>
                </div>
              </div>
              <div class="p-col-12 p-g-nopad">
                <div *ngFor="let child of topic.childs">
                  <project-default-todo-row
                    [dataObjectFromParent]="child"
                    [usersDropdown]="usersDropdown"
                    (estimatedTimeChange)="estimatedChangedInChild()"
                  ></project-default-todo-row>
                </div>
              </div>
            </div>
          </hyperion-card>
        </div>
      </div>
      <div class="p-grid todo-totals">
        <div class="p-col-12 p-lg-3">
          <h4>Totalt:</h4>
        </div>
        <div class="p-col-6 p-lg-1"></div>
        <div class="p-col-6 p-lg-1">
          <span class="strong-text">{{ totals.estimatedTime }}</span>
        </div>
        <div class="p-col-6 p-lg-2"></div>
        <div class="p-col-6 p-lg-2"></div>
        <div class="p-col-12 p-lg-1"></div>
        <div class="p-col-12 p-lg-2"></div>
      </div>
    </div>
  </div>
</div>
