import { Directive, HostListener } from '@angular/core';

const NOT_ALLOWED_KEYS = [
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Down',
  'Up',
  'Left',
  'Right',
];

@Directive({
  selector: '[appDisableArrowNavigation]',
})
export class DisableArrowNavigationDirective {
  constructor() {}
  @HostListener('keydown', ['$event']) public disableNavigation(
    event: any
  ): void {
    if (NOT_ALLOWED_KEYS.includes(event?.key)) {
      event.stopPropagation();
    }
  }
}
