import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchRotsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchRotsQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', rots?: { __typename?: 'ProjectRotConnection', edges?: Array<{ __typename?: 'ProjectRotEdge', node?: { __typename?: 'ProjectRot', id: string, name?: string | null, personalNumber?: string | null, nameOfProperty?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, customerWantedWorkAmount?: number | null } | null } | null> | null } | null } | null };

export type RotFragment = { __typename?: 'ProjectRot', id: string, name?: string | null, personalNumber?: string | null, nameOfProperty?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, customerWantedWorkAmount?: number | null };

export type CreateRotMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ProjectRotTypeHyperionMutationInput>;
}>;


export type CreateRotMutation = { __typename?: 'HyperionSchemaMutation', projectRotTypeHyperionMutation?: { __typename?: 'ProjectRot', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type UpdateRotMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ProjectRotTypeHyperionMutationInput>;
}>;


export type UpdateRotMutation = { __typename?: 'HyperionSchemaMutation', projectRotTypeHyperionMutation?: { __typename?: 'ProjectRot', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type DeleteRotMutationVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.ProjectRotTypeHyperionMutationInput>;
}>;


export type DeleteRotMutation = { __typename?: 'HyperionSchemaMutation', projectRotTypeHyperionMutation?: { __typename?: 'ProjectRot', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export const RotFragmentDoc = gql`
    fragment Rot on ProjectRot {
  id
  name
  personalNumber
  nameOfProperty
  housingAssociationOrgNumber
  apartmentDesignation
  customerWantedWorkAmount
  apartmentDesignation
  customerWantedWorkAmount
}
    `;
export const FetchRotsDocument = gql`
    query fetchRots($projectId: Int) {
  project(id: $projectId) {
    rots {
      edges {
        node {
          ...Rot
        }
      }
    }
  }
}
    ${RotFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchRotsGQL extends Apollo.Query<FetchRotsQuery, FetchRotsQueryVariables> {
    document = FetchRotsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRotDocument = gql`
    mutation createRot($input: ProjectRotTypeHyperionMutationInput) {
  projectRotTypeHyperionMutation(createProjectRot: $input) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRotGQL extends Apollo.Mutation<CreateRotMutation, CreateRotMutationVariables> {
    document = CreateRotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRotDocument = gql`
    mutation updateRot($input: ProjectRotTypeHyperionMutationInput) {
  projectRotTypeHyperionMutation(updateProjectRot: $input) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRotGQL extends Apollo.Mutation<UpdateRotMutation, UpdateRotMutationVariables> {
    document = UpdateRotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRotDocument = gql`
    mutation deleteRot($input: ProjectRotTypeHyperionMutationInput) {
  projectRotTypeHyperionMutation(deleteProjectRot: $input) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRotGQL extends Apollo.Mutation<DeleteRotMutation, DeleteRotMutationVariables> {
    document = DeleteRotDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }