import { Injectable, Inject } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { GlobalService } from 'app/shared/global/global.service';
import { VersionCheckService } from './version-check.service';
import { of, Observable } from 'rxjs';
import { LOCATION_TOKEN } from 'app/shared/location';

@Injectable()
export class VersionCheckGuard implements CanActivate {
  constructor(
    private versionCheckService: VersionCheckService,
    private globalService: GlobalService,
    @Inject(LOCATION_TOKEN) private location: Location
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.versionCheckService.isAppMarkedForHardReload) {
      const url = this.globalService.getAppUrlPrefix() + state.url;

      this.location.href = url;

      return of(false);
    }

    return of(true);
  }
}
