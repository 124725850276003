import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../shared/global/index';

@Component({
  selector: 'app-docu-upgrade-to-project-manager-dialog',
  templateUrl: './docu-upgrade-to-project-manager-dialog.component.html',
  styleUrls: ['./docu-upgrade-to-project-manager-dialog.component.scss'],
})
export class DocuUpgradeToProjectManagerDialogComponent implements OnInit {
  fakeLoading = true;

  constructor(private globalService: GlobalService) {}

  ngOnInit() {
    const url = `${this.globalService.getUrlPrefix()}/site/SetUpProjectTestFromDocu`;

    setTimeout(() => {
      this.fakeLoading = false;

      setTimeout(() => {
        window.location.href = url;
      }, 3000);
    }, 4500);
  }
}
