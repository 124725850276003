import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeletedUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DeletedUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', deletedUsers?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null } | null };

export const DeletedUsersDocument = gql`
    query deletedUsers {
  company {
    deletedUsers {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletedUsersGQL extends Apollo.Query<DeletedUsersQuery, DeletedUsersQueryVariables> {
    document = DeletedUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }