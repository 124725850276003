import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ApolloQueryService } from 'app/shared/apollo/index';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sie-export',
  templateUrl: './sie-export.component.html',
  styleUrls: ['./sie-export.component.scss'],
})
export class SieExportComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean;
  exports = [];

  constructor(public apolloQueryService: ApolloQueryService) {}

  ngOnInit() {
    this.loading = true;
    this.getExportsData();
  }

  private getExportsData() {
    this.apolloQueryService
      .apolloWatchQueryTwo('sieExports', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        ({ data }) => {
          if (data.company && data.company.sieExports_PreCompanyTypeHyperion) {
            this.exports = this.apolloQueryService.cleanFromNode(
              data['company']['sieExports_PreCompanyTypeHyperion']
            );
          }
          this.loading = false;
        },
        _ => {
          this.loading = false;
        }
      );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
