import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import Organisationsnummer from 'organisationsnummer';
import Personnummer from 'personnummer';

export interface FormValidatorError extends ValidationErrors {
  error: boolean;
  message: string;
}

export const orgOrPersonalNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): FormValidatorError | null => {
    const isValid =
      Organisationsnummer.valid(control.value) ||
      Personnummer.valid(control.value);

    return isValid
      ? null
      : {
          error: true,
          message: 'Inte ett giltigt org.nr eller personnummer',
        };
  };
};

export const phoneValidator = (): ValidatorFn => {
  return (control: AbstractControl): FormValidatorError | null => {
    const pattern = '[- +()0-9]+';
    const minLength = 6;

    const isValid =
      control.value?.match(new RegExp(pattern)) &&
      control.value?.length >= minLength;

    return isValid
      ? null
      : {
          error: true,
          message: 'Inte ett giltigt telefonnummer',
        };
  };
};

export const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): FormValidatorError | null => {
    const isValidEmail = !Validators.email(control);

    return isValidEmail
      ? null
      : {
          error: true,
          message: 'Inte en giltig epost-adress',
        };
  };
};

export const requiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): FormValidatorError | null => {
    const isValid = !Validators.required(control);

    return isValid
      ? null
      : {
          error: true,
          message: 'Måste anges',
        };
  };
};

export const requiredTrueValidator = (): ValidatorFn => {
  return (control: AbstractControl): FormValidatorError | null => {
    const isValid = !Validators.requiredTrue(control);

    return isValid
      ? null
      : {
          error: true,
          message: 'Måste accepteras',
        };
  };
};

export const minLengthValidator = (minLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = !Validators.minLength(minLength)(control);

    return isValid
      ? null
      : {
          error: true,
          message: `Måste vara minst ${minLength} tecken`,
        };
  };
};

export const maxLengthValidator = (maxLength: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = !Validators.maxLength(maxLength)(control);

    return isValid
      ? null
      : {
          error: true,
          message: `Måste vara högst ${maxLength} tecken`,
        };
  };
};
