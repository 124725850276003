import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetContactByIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetContactByIdQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', contacts?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, trueId?: string | null, contactType?: string | null, orderBuisnessName?: string | null, name?: string | null, orgNr?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, betalningsvillkor?: number | null, apartmentDesignation?: string | null, housingAssociationOrgNumber?: string | null, reverseTax?: number | null, propertyName?: string | null } | null } | null> | null } | null } | null };

export type OfferContactFragment = { __typename?: 'Contact', id: string, trueId?: string | null, contactType?: string | null, orderBuisnessName?: string | null, name?: string | null, orgNr?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, betalningsvillkor?: number | null, apartmentDesignation?: string | null, housingAssociationOrgNumber?: string | null, reverseTax?: number | null, propertyName?: string | null };

export type CopyOfferMutationVariables = Types.Exact<{
  offerId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CopyOfferMutation = { __typename?: 'HyperionSchemaMutation', copyOfferMutation?: { __typename?: 'Offer', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', errors?: Array<string | null> | null } | null> | null } | null };

export const OfferContactFragmentDoc = gql`
    fragment OfferContact on Contact {
  id
  trueId
  contactType
  orderBuisnessName
  name
  orgNr
  address
  address2
  cityCode
  city
  phone
  mobilePhone
  mail
  betalningsvillkor
  apartmentDesignation
  housingAssociationOrgNumber
  reverseTax
  propertyName
}
    `;
export const GetContactByIdDocument = gql`
    query getContactById($id: Int) {
  company {
    contacts(id: $id) {
      edges {
        node {
          ...OfferContact
        }
      }
    }
  }
}
    ${OfferContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactByIdGQL extends Apollo.Query<GetContactByIdQuery, GetContactByIdQueryVariables> {
    document = GetContactByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CopyOfferDocument = gql`
    mutation copyOffer($offerId: Int) {
  copyOfferMutation(offer: {id: $offerId}) {
    id
    mutationDetails {
      errors
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CopyOfferGQL extends Apollo.Mutation<CopyOfferMutation, CopyOfferMutationVariables> {
    document = CopyOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }