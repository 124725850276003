import {
  Component,
  Input,
  ViewChildren,
  QueryList,
  SimpleChanges,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';

import { CustomerPriceProjectCostTypeRowComponent } from './project-cost-type/project-cost-type-row.component';
import { CustomerPriceUserCostTypeRowComponent } from './user-cost-type/user-cost-type-row.component';
import { FormHandlerService } from '../../shared/forms/index';
import { ApolloMutationService } from '../../shared/apollo/index';

@Component({
  selector: 'customer-price',
  templateUrl: 'project-customer-price.component.html',
  styleUrls: ['project-customer-price.component.scss'],
})
export class CustomerPriceComponent implements OnInit, OnChanges {
  @Input() projectCostTypesFromParent = [];
  @Input() projectUserCostTypes;
  @Input() projectData;
  @Input() projectInvoiceData = {};
  @Output() callGetInvoiceData = new EventEmitter();
  @ViewChildren(CustomerPriceProjectCostTypeRowComponent)
  rowsWithData: QueryList<CustomerPriceProjectCostTypeRowComponent>;
  @ViewChildren(CustomerPriceUserCostTypeRowComponent)
  rowsWithDataUserCostType: QueryList<CustomerPriceUserCostTypeRowComponent>;

  loading = false;
  projectMilePrice: number = null;
  trakDayPrice = 0;
  trakNightPrice = 0;
  trakHalfDayPrice = 0;
  projectMilePriceTotal = 0;
  trakDayPriceTotal = 0;
  trakNightPriceTotal = 0;
  trakHalfDayPriceTotal = 0;
  hasSubsistence = false;
  buttons;
  hasTyped = false;
  dataModel = 'customerPrice';

  constructor(
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService
  ) {}

  ngOnInit() {
    const buttonObject = {
      update: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.projectMilePrice = this.projectData.milePrice;

    this.trakDayPrice = this.projectData.trakDayPrice;
    this.trakNightPrice = this.projectData.trakNightPrice;
    this.trakHalfDayPrice = this.projectData.trakHalfDayPrice;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.projectCostTypesFromParent = this.projectCostTypesFromParent.filter(
      (projectCostType: any) => projectCostType.products.length
    );

    for (const propName in changes) {
      if (
        propName === 'projectCostTypesFromParent' ||
        propName === 'projectInvoiceData'
      ) {
        this.appendProjectCostTypesTotals(
          this.projectCostTypesFromParent,
          this.projectInvoiceData
        );
      }

      if (
        propName === 'projectUserCostTypes' ||
        propName === 'projectInvoiceData'
      ) {
        this.appendProjectUserCostTypesTotals(
          this.projectUserCostTypes,
          this.projectInvoiceData
        );
      }
    }
  }

  private appendProjectCostTypesTotals(
    costTypes: any[],
    invoiceData: any
  ): void {
    if (
      typeof invoiceData !== 'undefined' &&
      typeof costTypes !== 'undefined' &&
      invoiceData.hasOwnProperty('costs') &&
      costTypes.length
    ) {
      const invoiceCosts = invoiceData.costs;

      costTypes = costTypes
        .filter((costType: any) => costType.products.length)
        .map((costType: any) => {
          costType.total = +invoiceCosts[+costType.id]?.cost;
        });
    }
  }

  appendProjectUserCostTypesTotals(userCostTypes, invoiceData) {
    if (
      typeof invoiceData !== 'undefined' &&
      typeof userCostTypes !== 'undefined'
    ) {
      if (
        invoiceData.hasOwnProperty('projectUserCostTypes') &&
        userCostTypes.length > 0
      ) {
        const projectUserCostTypes = invoiceData['projectUserCostTypes'];

        // append totals to existing
        for (const index in userCostTypes) {
          const userCostType = userCostTypes[index];

          for (const costIndex in projectUserCostTypes) {
            const userCostSum = projectUserCostTypes[costIndex];

            if (userCostSum['id'] === 'subsistenceDay') {
              this.trakDayPriceTotal = userCostSum['total'];
              this.hasSubsistence = true;
            } else if (userCostSum['id'] === 'subsistenceHalfDay') {
              this.trakHalfDayPriceTotal = userCostSum['total'];
              this.hasSubsistence = true;
            } else if (userCostSum['id'] === 'subsistenceNight') {
              this.trakNightPriceTotal = userCostSum['total'];
              this.hasSubsistence = true;
            } else {
              if (Number(userCostSum['id']) === Number(userCostType['id'])) {
                userCostType['total'] = userCostSum['total'];
              }
            }
          }
        }
      }
    }

    if (typeof this.projectInvoiceData !== 'undefined') {
      if (
        this.projectInvoiceData.hasOwnProperty('mile') &&
        this.projectInvoiceData.hasOwnProperty('mile')
      ) {
        const mile = this.projectInvoiceData['mile'];
        const mileExtra = this.projectInvoiceData['mileExtra'];

        this.projectMilePriceTotal = this.projectMilePrice * (mile + mileExtra);
      }
    }
  }

  actionUpdate() {
    this.rowsWithData.forEach(item => {
      const checkValidityForRow = item.actionUpdate();
    });

    this.rowsWithDataUserCostType.forEach(item => {
      const checkValidityForRow = item.actionUpdate();
    });

    this.updateMilePrice();
    setTimeout(() => {
      this.callGetInvoiceData.emit();
    }, 1500);
  }

  updateMilePrice() {
    if (this.hasTyped) {
      const crudType = 'update';

      const variables = {
        id: this.projectData.id,
        milePrice: +this.projectMilePrice,

        trakDayPrice: +this.trakDayPrice,
        trakNightPrice: +this.trakNightPrice,
        trakHalfDayPrice: +this.trakHalfDayPrice,
      };

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation('project', crudType, false, variables)
        .subscribe(
          executedData => {
            if (executedData.mutationSucceededAllArguments) {
              this.hasTyped = false;
            }

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  callGetInvoiceDataEmit() {
    this.callGetInvoiceData.emit();
  }
}
