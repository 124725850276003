<div>
  <div *ngIf="componentMainForm">
    <form
      [formGroup]="componentMainForm"
      (keyup.enter)="actionUpdate()"
      class="p-grid"
    >
      <appForm2-list-input
        class="p-col-12 p-lg-10"
        formControlName="name"
        [htmlAttributes]="{
          input: {
            id:
              componentPrefix +
              'companyDivision_name_' +
              dataObjectFromParent.id
          }
        }"
      ></appForm2-list-input>
      <div class="p-col-6 p-lg-1">
        <button
          pButton
          class="p-button-icon-danger"
          [disabled]="buttons.delete.disabled"
          (click)="confirmActionDelete()"
          icon="pi pi-trash"
        ></button>
      </div>
      <div class="p-col-6 p-lg-1">
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="buttons.update.disabled"
          (click)="actionUpdate()"
          icon="pi pi-save"
        ></button>
      </div>
    </form>
  </div>
</div>
