<form
  [formGroup]="componentMainForm"
  class="full-width no-margin inline-grid-label-form"
>
  <div class="p-grid">
    <div class="p-col-12 p-g-nopad" #project_id_control2>
      <appForm2-wide-dropDown
        formControlName="id"
        [dropDownParams]="{ options: projectsDropdown, filter: true }"
      ></appForm2-wide-dropDown>
    </div>
    <div class="p-col-12 p-g-nopad">
      <appForm2-wide-input
        formControlName="constructionSiteNumber"
      ></appForm2-wide-input>
    </div>
    <div class="p-col-12">
      <app-button
        [class]="'block'"
        (onClick)="actionUpdate()"
        [type]="'update'"
      ></app-button>
    </div>
  </div>
</form>
