import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';

import { HyperionSharedModule } from '../../shared/shared.module';
import { OldProjectModule } from '../../old-project/old-project.module';
import { InvoiceHeadInfoComponent } from './invoice-head/invoice-head-info/invoice-head-info.component';
import { InvoiceHeadCustomerComponent } from './invoice-head/invoice-head-customer/invoice-head-customer.component';
import { InvoiceHeadShippingadressComponent } from './invoice-head/invoice-head-shippingadress/invoice-head-shippingadress.component';
import { InvoiceHeadRotComponent } from './invoice-head/invoice-head-rot/invoice-head-rot.component';
import { PersonerRotInfoComponent } from './invoice-head/invoice-head-rot/personer-rot-info/personer-rot-info.component';
import { SavedOptionsComponent } from './saved-options/saved-options.component';
import { InvoiceHeadComponent } from './invoice-head/invoice-head.component';
import { InvoiceBodyComponent } from './invoice-body/invoice-body.component';
import { InvoiceFooterComponent } from './invoice-footer/invoice-footer.component';
import { InvoiceContainerComponent } from './index';
import { ContactModule } from 'app/contact/contact.module';
import { CardModule } from 'primeng/card';
import { HeaderModule } from 'app/header/header.module';
import { MessageModule } from 'primeng/message';
import { InvoiceSelectModule } from '../invoice-select/invoice-select.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionSharedModule,
    OldProjectModule,
    // PrimeNg
    AccordionModule,
    AutoCompleteModule,
    RadioButtonModule,
    ProgressBarModule,
    TableModule,
    ContactModule,
    CardModule,
    HeaderModule,
    MessageModule,
    InvoiceSelectModule,
  ],
  declarations: [
    InvoiceHeadInfoComponent,
    InvoiceHeadCustomerComponent,
    InvoiceHeadShippingadressComponent,
    InvoiceHeadComponent,
    InvoiceBodyComponent,
    InvoiceHeadRotComponent,
    PersonerRotInfoComponent,
    InvoiceFooterComponent,
    InvoiceContainerComponent,
    SavedOptionsComponent,
  ],
  exports: [InvoiceContainerComponent, SavedOptionsComponent],
})
export class SingelInvoiceModule {}
