import { gql, Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { AuthService } from 'app/shared/auth/auth.service';

const USER_CRM_RIGHTS_QUERY = gql`
  query fetchUserCrmRights {
    me {
      id
      hasCrmRights
      ownerUserId
    }
  }
`;

@Component({
  selector: 'app-struqtur-crm',
  templateUrl: 'crm.component.html',
  styleUrls: ['crm.component.scss'],
})
export class CrmComponent implements OnInit {
  public hasCrmRights: boolean;
  public showLinkToMainAccount: boolean;
  public searchCompanyName: string;

  constructor(private apollo: Apollo, private authService: AuthService) {}

  public ngOnInit(): void {
    this.authService.isLoggedInObs.subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.fetchUserRights().subscribe(({ data }: any) => {
          this.hasCrmRights = data.me.hasCrmRights === 'true';
          this.showLinkToMainAccount = data.me.ownerUserId != null;
        });
      }
    });
  }

  private fetchUserRights(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: USER_CRM_RIGHTS_QUERY,
      fetchPolicy: 'no-cache',
    });
  }

  public submitForm(): void {
    window.location.href =
      '/crm/foretagsfakta2/admin?companyName=' + this.searchCompanyName;
  }
}
