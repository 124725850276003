import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IdleComponent, IdleService } from 'app/shared/idle';
import { ModalsModule } from 'app/shared/idle/modals/modals.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [IdleComponent],
  exports: [IdleComponent, ModalsModule],
  providers: [IdleService],
})
export class IdleModule {}
