import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

import { FormHandlerService } from '../../shared/forms/index';
import { HelperService } from '../../shared/helpers/index';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message/index';
import { GlobalService } from '../../shared/global/index';
import {
  ProductsAutosuggestService,
  CompanyFunctionsService,
  CompanyInfo,
  CompanyInfoService,
} from 'app/shared/company/index';
import { ApolloQueryService, ApolloMutationService } from '../../shared/apollo';
import productsAutoSuggestServiceProvider from 'app/shared/company/product/products.autosuggest.service.provider';

@Component({
  selector: 'app-project-product-booking',
  templateUrl: './project-product-booking.component.html',
  providers: [FormHandlerService, productsAutoSuggestServiceProvider],
})
export class ProjectProductBookingComponent implements OnInit, OnDestroy {
  projectId: string;
  bookedProducts: any[];
  sum = null;
  picking: BehaviorSubject<boolean> = new BehaviorSubject(false);
  reset: boolean;
  useOwnProducts = false;
  showStockColumn = false;
  showAllPicked = false;
  disabelProductAutoComplete = false;
  benamningId;
  urlPrefix;
  deliverNotes = [];
  productAutoModel: string;
  results: BehaviorSubject<any> = new BehaviorSubject([]);
  formFields = {
    model: 'ProductBooked',
    attributes: {
      extra: 0,
      antal: '',
      discount: '',
      avtalspris: '',
      benamning: '',
      productId: '',
      avtalsprisCost: '',
      avtalsprisOriginal: '',
      projectId: '',
    },
  };
  componentMainForm: FormGroup;
  bookedProductSub: Subscription;

  public showProductPrices = true;
  public isMissingDeromeProductIntegration = false;

  private searchQuery: Subject<string> = new Subject();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private helperService: HelperService,
    private cdr: ChangeDetectorRef,
    private formHandler: FormHandlerService,
    private messageService: MessageService,
    public dialogConfig: DynamicDialogConfig,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private productsAutoSuggestService: ProductsAutosuggestService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyInfoService: CompanyInfoService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.initForm();
  }

  ngOnInit() {
    if (this.dialogConfig.data) {
      this.initComponentWithDataFrmDialog();
    }

    this.getBookedProducts();

    this.benamningId = this.projectId + ('normal' + 'ProductAutoComplete');

    this.companyFunctionsService
      .hasCompanyFunction('useStockSystem')
      .subscribe(x => (this.showStockColumn = x));

    this.companyInfoService.companyInfo$.subscribe(
      (companyInfo: CompanyInfo) => {
        this.showProductPrices = companyInfo.showProductSearchPrices;
        this.isMissingDeromeProductIntegration =
          companyInfo.isMissingDeromeProductIntegration;
      }
    );

    this.setupSearchQuery();
  }

  private initComponentWithDataFrmDialog() {
    this.projectId = this.dialogConfig['data']['id'];
    setTimeout(() => {
      this.dialogConfig.header = 'Boka produkter';
    }, 0);
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['extra']['label'] = 'Extra';
        this.componentMainForm.controls['antal']['label'] = 'Antal';

        this.componentMainForm.controls['projectId'].setValue(this.projectId);
        this.cdr.markForCheck();
      });
  }

  updateAutoSuggestObj(val: boolean): void {
    this.useOwnProducts = val;
  }

  public searchProducts(event): void {
    this.productsAutoSuggestService.searchNext(
      event.query,
      this.useOwnProducts
    );
  }

  private setupSearchQuery() {
    this.productsAutoSuggestService.setupSearchQuery();

    this.productsAutoSuggestService.searchQueryOutput.subscribe(products => {
      this.results.next(products);
    });
  }

  setProductBenamning() {
    this.productAutoModel === 'string' &&
      this.componentMainForm.controls['benamning'].setValue(
        this.productAutoModel
      );
  }

  setProductFromAutosuggest(value) {
    const product = { ...value };
    this.componentMainForm.controls['benamning'].setValue(product['benamning']);
    if (this.isMissingDeromeProductIntegration) {
      // Early return before running into product details on missing external dependency
      const toastMessage: ToastMessage = {
        severity: ToastMessageSeverityType.WARNING,
        summary:
          'Du saknar uppkoppling för ditt Kund-ID och kan därmed inte se priser. Kontakta supporten för att kunna se priser.',
      };
      this.messageService.insertData(toastMessage);
      return;
    }
    this.componentMainForm.controls['avtalspris'].setValue(
      product['avtalspris']
    );
    this.componentMainForm.controls['avtalsprisOriginal'].setValue(
      product['avtalspris']
    );
    this.componentMainForm.controls['avtalsprisCost'].setValue(
      product['avtalsprisCost']
    );

    this.componentMainForm.controls['productId'].setValue(product['id']);
    this.disabelProductAutoComplete = true;
    this.calculate();
  }

  calculate() {
    const val = this.componentMainForm.controls['antal'].value;

    val &&
      this.componentMainForm.controls['antal'].setValue(val.replace(',', '.'));
    const price = +this.componentMainForm.controls['avtalsprisOriginal'].value;
    const discount = +this.componentMainForm.controls['discount'].value;
    const antal = this.componentMainForm.controls['antal'].value;

    const afterDiscount =
      Math.round((price - (price * discount) / 100) * 100) / 100;

    if (afterDiscount > 0) {
      this.componentMainForm.controls['avtalspris'].setValue(afterDiscount);
      this.sum = Math.round(afterDiscount * antal * 100) / 100;
    }
  }

  resetForm() {
    this.disabelProductAutoComplete = false;
    this.componentMainForm.patchValue(this.formFields.attributes);
    this.componentMainForm.controls['projectId'].setValue(this.projectId);

    this.sum = null;
    this.productAutoModel = '';
  }

  getBookedProducts() {
    this.apolloQueryService
      .apolloWatchQueryTwo('projectProductsBooked', { id: this.projectId })
      .subscribe(({ data, sub }) => {
        this.bookedProductSub = sub;

        const rawData = this.helperService.cleanFromNode(
          data['company']['projects']['edges'][0]['node']['productsBooked']
        );
        rawData.map(bookedProd => {
          let amountLeft = bookedProd['antal'];
          bookedProd['picks'] = this.helperService.cleanFromNode(
            bookedProd['picks']
          );
          bookedProd['latestInventory'] =
            bookedProd['product']['latestInventory_PreProductTypeHyperion'][
              'count'
            ];

          bookedProd['antalPickedOnload'] = 0;
          bookedProd['picks'].forEach(pick => {
            const deliverNotesFiltered = this.deliverNotes.filter(
              x => x['id'] === pick['deliveryNote']['id']
            );
            bookedProd['antalPickedOnload'] += +pick['amount'];

            amountLeft = amountLeft - pick['amount'];
            pick['deliveryNote'] &&
              deliverNotesFiltered.length === 0 &&
              this.deliverNotes.push(pick['deliveryNote']);
          });

          const amount =
            amountLeft > 0 && bookedProd['latestInventory'] > 0
              ? amountLeft
              : 0;

          bookedProd['antalPicked'] = amount;
        });
        this.bookedProducts = rawData;
        this.cdr.markForCheck();
      });
  }

  bookProducts() {
    this.mutateData(this.componentMainForm.value, 'create');
  }

  setAmountToPick(i, value) {
    this.bookedProducts[i]['antalPicked'] = value;
  }

  updateBookedProduct() {
    const url = this.urlPrefix + '/ProductPicked/PickBookedHyperion';
    const contentType = 'application/json';
    this.picking.next(true);
    const data = [];
    this.bookedProducts.forEach(booked => {
      data.push({
        productId: booked['productId'],
        projectId: this.projectId,
        productBookedId: booked['id'],
        amount: booked['antalPicked'],
      });
    });

    this.http
      .post(url, data, {
        headers: new HttpHeaders({
          'Content-Type': contentType,
        }),
      })
      .pipe(map(res => res))
      .subscribe(res => {
        this.picking.next(false);
        res['status'] === 'success' && this.getBookedProducts();
        this.messageService.insertData({
          textArray: [res['msg']],
          type: res['status'],
        });
      });
  }

  deleteBookedProduct(id) {
    this.mutateData({ id }, 'delete');
  }

  openDeliveryNote(deliverNoteId) {
    const url = `${this.urlPrefix}/productPicked/Print/type/showPDF/id/${deliverNoteId}`;
    window.open(url, 'popup', 'width=900,height=800');
  }

  mutateData(variables, action) {
    this.mutationService
      .constructQueryAndExecuteMutation(
        'ProductBooked',
        action,
        null,
        variables
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          this.resetForm();
          this.getBookedProducts();
        },
        err => {
          console.log(err);
        }
      );
  }

  ngOnDestroy() {
    this.bookedProductSub && this.bookedProductSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
