<p-table
  [value]="topic.todos"
  (onEditInit)="setEditing(true)"
  (onEditComplete)="clearUpdateQueue()"
>
  <ng-template pTemplate="header">
    <tr>
      <th
        *ngFor="let col of columns"
        [pSortableColumn]="col.field"
        [style.width]="col.width"
        [style.whiteSpace]="'break-spaces'"
      >
        <span class="p-d-flex p-jc-between p-ai-center">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </span>
      </th>
      <th style="width: 6rem"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-todo>
    <tr>
      <td class="p-text-center">
        <p-checkbox
          (onChange)="toggleDone(todo)"
          [(ngModel)]="todo.done"
          binary="true"
        >
        </p-checkbox>
      </td>
      <td
        [pEditableColumn]="todo.description"
        pEditableColumnField="description"
        style="white-space: break-spaces"
      >
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="output">
            {{ todo.description }}
          </ng-template>
          <ng-template pTemplate="input">
            <textarea
              pInputTextarea
              appDisableArrowNavigation
              [(ngModel)]="todo.description"
              (keydown.enter)="addToQueue(todo)"
            ></textarea>
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        [pEditableColumn]="todo.orderNr"
        pEditableColumnField="orderNr"
        class="p-text-center"
      >
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="output">
            {{ todo.orderNr }}
          </ng-template>
          <ng-template pTemplate="input">
            <input
              pInputText
              appDisableArrowNavigation
              [(ngModel)]="todo.orderNr"
              (keydown.enter)="addToQueue(todo)"
            />
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        [pEditableColumn]="todo.startDate"
        pEditableColumnField="startDate"
        class="p-text-center"
      >
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="output">
            {{ todo.startDate }}
          </ng-template>
          <ng-template pTemplate="input">
            <p-calendar
              appDisableArrowNavigation
              [(ngModel)]="todo.startDate"
              dataType="string"
              (onSelect)="addToQueue(todo)"
              appendTo="body"
              [showWeek]="true"
            ></p-calendar>
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        [pEditableColumn]="todo.endDate"
        pEditableColumnField="endDate"
        class="p-text-center"
      >
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="output">
            {{ todo.endDate }}
          </ng-template>
          <ng-template pTemplate="input">
            <p-calendar
              appDisableArrowNavigation
              [(ngModel)]="todo.endDate"
              dataType="string"
              (onSelect)="addToQueue(todo)"
              appendTo="body"
              [showWeek]="true"
            ></p-calendar>
          </ng-template>
        </p-cellEditor>
      </td>
      <td
        [pEditableColumn]="todo.estimatedTime"
        pEditableColumnField="estimatedTime"
        class="p-text-center"
      >
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="output">
            {{ todo.estimatedTime }}h
          </ng-template>
          <ng-template pTemplate="input">
            <input
              appDisableArrowNavigation
              pInputText
              [(ngModel)]="todo.estimatedTime"
              (keydown.enter)="addToQueue(todo)"
            />
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ todo.timeUsed }}h</td>
      <td [pEditableColumn]="todo.users" pEditableColumnField="users">
        <p-cellEditor (change)="addToQueue(todo)">
          <ng-template pTemplate="input">
            <p-multiSelect
              #userSelect
              [options]="users | async"
              [(ngModel)]="todo.userIds"
              (onChange)="addToQueue(todo)"
              optionValue="id"
              optionLabel="firstName"
              appDisableArrowNavigation
              [overlayOptions]="{ appendTo: 'body' }"
            >
              <ng-template pTemplate="item" let-user>
                {{ user.firstName }} {{ user.lastName }}
              </ng-template>
            </p-multiSelect>
          </ng-template>
          <ng-template pTemplate="output">
            <div class="p-grid">
              <span
                class="p-col-12 p-text-truncate"
                *ngIf="todo.users.length === 0"
              >
                <a> Knyt användare </a>
              </span>
              <span
                *ngFor="let user of todo.users"
                class="p-col-12 p-text-truncate"
                >{{ user.firstName }} {{ user.lastName }}</span
              >
            </div>
          </ng-template>
        </p-cellEditor>
      </td>
      <td class="p-text-center">
        <span class="p-d-flex p-jc-between">
          <button
            pButton
            (click)="editEmit.emit(todo.id)"
            class="p-button-text legacy-rounded-button"
            icon="pi pi-pencil"
          ></button>
          <button
            pButton
            (click)="deleteTodo(todo)"
            class="p-button-text legacy-rounded-button p-button-icon-danger"
            icon="pi pi-trash"
          ></button>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
