import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { HelperService } from 'app/shared/helpers';
import { ApolloMutationService } from 'app/shared/apollo';

@Component({
  selector: 'free-users',
  templateUrl: './free-users.component.html',
})
export class FreeUsersComponent implements OnChanges {
  @Input() usersData: any = {};
  @Output() mutationError = new EventEmitter();

  totalCount = 0;
  globalSearchString: string;
  dataSet = new BehaviorSubject([]);
  freeUsersTableColumns: any[] = [
    { field: 'firstName' },
    { field: 'lastName' },
    { field: 'personalNumber' },
  ];

  constructor(
    public helperService: HelperService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['usersData'] && this.usersData !== changes['usersData']) {
      this.updateDataSet();
    }
  }

  private updateDataSet() {
    let totalCount = 0;
    const dataSet = [];
    const edges = this.usersData.edges;
    for (const edge in edges) {
      const node = { ...edges[edge]['node'] };
      if (node.hasOwnProperty('__typename')) {
        delete node.__typename;
      }
      node.id = Number(node.id);
      if (node.active === 1) {
        dataSet.push(node);
        totalCount++;
      }
    }
    this.totalCount = totalCount;
    this.dataSet.next(dataSet);
  }

  deleteClickHandler(rowDataParam) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort personen?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        const rowData = { ...rowDataParam };
        rowData.active = 0;
        this.actionUpdate(rowData);
      },
      reject: () => {},
    });
  }

  actionUpdate(rowData: any) {
    const crudType = 'update';
    const dataToMutation = { ...rowData };
    const returnAttributes = [
      'id',
      'firstName',
      'lastName',
      'personalNumber',
      'active',
    ];

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'attendanceFreeUser',
        crudType,
        false,
        dataToMutation,
        returnAttributes
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.updateNodeInDataset(executedData);
          } else {
            this.mutationError.emit();
          }

          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  updateNodeInDataset(executedData: any) {
    let dataSet: any[] = [...this.dataSet.value];
    if (executedData.active === 0) {
      dataSet = dataSet.filter(p => p.id !== Number(executedData.id));
      this.totalCount = this.totalCount - 1;
    } else {
      const updatedPerson = {
        id: Number(executedData.id),
        firstName: executedData.firstName,
        lastName: executedData.lastName,
        personalNumber: executedData.personalNumber,
        active: executedData.active,
      };

      const index = dataSet.findIndex(p => p.id === updatedPerson.id);
      dataSet.splice(index, 1, updatedPerson);
    }

    this.dataSet.next(dataSet);
  }

  editCompleteHandler(event) {
    if (event) {
      this.actionUpdate(event.data);
    }
  }
}
