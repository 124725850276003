import { Injectable } from '@angular/core';
import { ApolloFetchPolicy, ApolloQueryService } from 'app/shared';
import { Observable } from 'rxjs';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from './accounting-plan.types';
import { accountingPlanQueries } from './queries/accounting-plan-accounts';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AccountingPlanService {
  constructor(private apolloQueryService: ApolloQueryService) {}

  public getAccounts(): Observable<AccountingPlanAccount[]> {
    return this.apolloQueryService
      .apolloWatchQueryTwoQuery(
        accountingPlanQueries.getCompanyAccountingPlanAccounts,
        null,
        ApolloFetchPolicy.CACHE_AND_NETWORK
      )
      .pipe(
        map((response: any): AccountingPlanAccount => {
          const data = JSON.parse(JSON.stringify(response.data));
          return data.company.accountingPlanAccounts.edges.map((edge: any) => ({
            ...edge.node,
            id: +edge.node.id,
          }));
        })
      );
  }

  public getAccountsDropdownParams(
    accounts: AccountingPlanAccount[]
  ): AccountingPlanAccountsDropdown {
    return {
      options: [
        {
          label: 'Välj konto',
          value: null,
        },
        ...accounts.map(account => ({
          value: +account.id,
          label: `${account.number} - ${account.description}`,
        })),
      ],
      filter: true,
    };
  }
}
