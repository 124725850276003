<app-feature-preview
  image="/images/hyperion/feature-previews/e-liggare.jpg"
  feature="E-liggare"
>
  <p>
    {{ appName }} tillhandahåller en elektronisk E-liggare som är utvecklad
    speciellt för hantverkare.
  </p>

  <p>
    Som huvudentreprenör kan du se vilka som är på plats på arbetsplatsen. När
    Skatteverket kommer på besök för att genomföra en kontroll behöver man bara
    ta upp telefonen ur fickan och visa vilka som är på arbetsplatsen för
    tillfället. Det går även att maila ett underlag på vilka som har varit på
    arbetsplatsen historiskt.
  </p>

  <p>
    {{ appName }}s E-liggare är godkänd av Skatteverket och därmed ett enkelt
    sätt att följa lagar och regler angående elektroniska E-liggare på
    byggarbetsplatser.
  </p>
</app-feature-preview>
