import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable } from 'rxjs';

@Injectable()
export class CompanyProjectTypeService extends CompanySuperService {
  dataModel = 'projectType';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  showAllText: boolean;

  dataFormater(data, mode, showAllText = true, showAllTextLabel = null) {
    // mode == labels || list

    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(
          data,
          showAllText,
          showAllTextLabel
        );
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data, showAllText, showAllTextLabel) {
    /*
            För aktuell modell:
            I dataset, inget "company" för "projectType" i modell
        */

    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    if (this.showAllText) {
      dataObjectsWithLabels.push({ label: 'Visa alla', value: null });
    } else {
      dataObjectsWithLabels.push({
        label:
          showAllTextLabel !== null ? showAllTextLabel : 'Ingen typ angiven',
        value: null,
      });
    }

    for (const i in dataArray) {
      const dataObject = {
        // Create object to push
        label: dataArray[i].node.prefix + ':' + dataArray[i].node.name,
        value: +dataArray[i].node.id,
        addToMeny: dataArray[i].node.addToMeny,
      };
      dataObjectsWithLabels.push(dataObject);
    }

    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    /*
            För aktuell modell:
            I dataset, inget "company" för "projectType" i modell
        */

    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const dataObject = {
        prefix: dataArray[i].node.prefix,
        name: dataArray[i].node.name,
        id: +dataArray[i].node.id,
      };
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }
}
