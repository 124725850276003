import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LabelsService, LabelsResponse } from './labels.service';

export { LabelsService } from './labels.service';

@Injectable()
export class HyperionLabelsService {
  constructor(private labelsService: LabelsService) {}

  /**
   * Returns a key-value object where the key is the model and the value are the labels.
   *
   * @param modelArray the array with the strings of the models
   */
  public getLabels(
    modelArray: string[] | LabelModel[]
  ): Observable<LabelsResponse> {
    // If the model Array is encapsulated as LabelModels, then we flatten it.
    if (this.isLabelModels(modelArray[0])) {
      const parsedModels: string[] = [];

      for (const model of modelArray as LabelModel[]) {
        parsedModels.push(model.model);
      }
      modelArray = parsedModels;
    }

    return this.labelsService.getLabels(modelArray as string[]);
  }

  /**
   * TS Guard to verify if the models is in the LabelModels format.
   */
  private isLabelModels(object: any): object is LabelModel {
    return 'model' in object;
  }
}

/**
 * @example  `labelmodel = { model: 'Product };`
 */
interface LabelModel {
  model: string;
  labels?: string[];
}
