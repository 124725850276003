import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/shared/global';
import { HttpService } from 'app/shared/http';
import { Message } from 'primeng/api';
import { Subject, first } from 'rxjs';
import { UserFlagsService } from 'app/user-flags.service';

@Component({
  selector: 'app-fortnox-oauth-integration',
  templateUrl: './fortnox-oauth-integration.component.html',
  styleUrls: ['./fortnox-oauth-integration.component.scss'],
})
export class FortnoxOAuthComponent implements OnInit {
  public authenticationUrl: string;
  public appName = localStorage.getItem('appname');
  public state: IFortnoxConfigurationState | undefined;
  public isLoading: boolean;
  public messages: Subject<Message[]> = new Subject();
  public canConnect = true;
  public isWhitelabel = false;

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService,
    private userFlagsService: UserFlagsService
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.isWhitelabel =
          flags.isWhitelabelDerome || flags.isWhitelabelBeijerBygg;
      });

    this.getCurrentStateAsync().then(state => {
      this.state = state;
      if (state.isConfigured) {
        this.isLoading = false;
      } else {
        const urlParam = `${this.globalService.getUrlPrefix()}/FortnoxOAuthSetup/GetConfig`;
        this.httpService.makeHttpGetRequest(urlParam).then(config => {
          this.authenticationUrl = config.data.fortnox_auth_url;
          this.isLoading = false;
        });
      }

      if (this.state.isConfigured && !this.state.isEnabled) {
        this.messages.next([
          {
            severity: 'success',
            summary: 'Kopplingen lyckades',
            detail: 'Kontakta supporten för att aktivera integrationen',
          },
        ]);
      } else if (this.state.isConfigured && this.state.isEnabled) {
        this.messages.next([
          {
            severity: 'success',
            summary: 'Kopplingen klar',
            detail:
              'Kopplingen mot Fortnox är nu uppsatt.<br> Vänligen kontakta supporten för att slutföra installationen.<br> Vi hjälper dig med det sista steget för att du ska kunna börja arbeta med kopplingen.',
          },
        ]);
      }

      if (this.isWhitelabel) {
        this.canConnect = false;

        this.messages.next([
          {
            severity: 'info',
            summary: 'Kontakta support',
            detail:
              'Ifall du vill sätta upp en integration till Fortnox, vänligen kontakta support.',
          },
        ]);
      }
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('success')) {
      if (urlParams.get('success') === '0') {
        const error = urlParams.has('error') && urlParams.get('error');
        this.handleError(error);
      }
    }
  }

  public authenticate(): void {
    window.location.href = this.authenticationUrl;
  }

  private async getCurrentStateAsync(): Promise<IFortnoxConfigurationState> {
    const url = `${this.globalService.getUrlPrefix()}/FortnoxOAuthSetup/GetState`;
    const response = await this.httpService.makeHttpGetRequest(url);
    return response.data as IFortnoxConfigurationState;
  }

  private handleError(error: string): void {
    let details;
    let severity = 'warn';
    switch (error) {
      default:
        this.canConnect = false;
        severity = 'error';
        details =
          'Ett fel inträffade i uppsättningen. <br> Vänligen kontakta vår support så undersöker vi vad felet beror på.';
    }
    this.messages.next([
      {
        severity: severity,
        summary: 'Kopplingen misslyckades',
        detail: details,
      },
    ]);
  }
}

enum FortnoxOAuthErrors {
  AccessDenied = 'access_denied',
  Unavailable = 'temporarily_unavailable',
  ServerError = 'server_error',
}

interface IFortnoxConfigurationState {
  isConfigured: boolean;
  isEnabled: boolean;
}
