import {
  Component,
  forwardRef,
  Input,
  Injector,
  OnInit,
  AfterContentInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-simple-dropDown',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFormDropDown2Component),
      multi: true,
    },
  ],
  template: `
    <div [class.has-danger]="control && control.showErrors">
      <label
        *ngIf="!showToolTip"
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        [innerHTML]="htmlAttributes['label']['value'] || control.label || label"
      ></label>
      <label
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        *ngIf="showToolTip"
        >{{ htmlAttributes['label']['value'] || control.label || label }}
        <span
          class="{{ tooltip['class'] }}"
          [tooltipStyleClass]="tooltip['tooltipStyleClass']"
          [tooltipPosition]="tooltip['tooltipPosition']"
          [pTooltip]="tooltip['msg']"
          [escape]="false"
        >
          ?</span
        >
      </label>
      <div class="p-inputgroup">
        <button
          pButton
          *ngIf="dropDownParams['leftIcon']"
          type="button"
          [disabled]="dropDownParams['disabled'] || disabled"
          [icon]="dropDownParams['leftIcon']"
          (click)="triggerLeftFunc()"
          class="p-button-secondary"
        ></button>
        <p-dropdown
          #dropdown
          [appendTo]="appendTo"
          [(ngModel)]="value"
          (onBlur)="onTouched()"
          (onChange)="pushChanges($event.value)"
          (onShow)="onShow($event)"
          [filter]="dropDownParams['filter']"
          [options]="dropDownParams['options']"
          [attr.form-control-name]="control['name']"
          [attr.id]="htmlAttributes['input']['id']"
          [group]="dropDownParams['group'] || false"
          [filterBy]="dropDownParams['filterBy'] || 'label'"
          [attr.tabindex]="htmlAttributes['input']['tabIndex']"
          [disabled]="dropDownParams['disabled'] || disabled"
          [placeholder]="dropDownParams['placeholder'] || null"
          [styleClass]="dropDownParams['styleClass'] || 'form-control'"
          [style]="
            control.showErrors
              ? { border: 'solid 1px #f25858', width: '100%' }
              : { width: '100%' }
          "
        ></p-dropdown>
        <button
          pButton
          *ngIf="dropDownParams['rightIcon']"
          type="button"
          [disabled]="dropDownParams['disabled'] || disabled"
          [icon]="dropDownParams['rightIcon']"
          (click)="triggerRightFunc()"
          class="p-button-secondary"
        ></button>
      </div>
      <div *ngIf="control.showErrors" class="vendium-tool-tip">
        <p
          *ngFor="let error of control.mergedErrors"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class SimpleFormDropDown2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @Input() public dropDownParams: any;

  @Output() public show: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown') public dropdownElement: Dropdown;

  value: NgModel;
  ngControl: NgControl;
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled: boolean;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** bubbles up the onShow event */
  public onShow(event: any): void {
    this.show.emit(event);
  }

  /** triggers the hide functio nin the parent */
  public hide(event: any): void {
    if (this.dropdownElement.hide) {
      this.dropdownElement.hide();
    }
  }
}
