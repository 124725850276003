<div>
  <hyperion-card [class.no-style]="inModal">
    <div class="p-grid user-list">
      <div class="p-col-12 p-g-nopad">
        <div class="p-col-12 p-lg-4">
          <h2>Kompisar ({{ totalCount }} st)</h2>
        </div>
      </div>

      <div class="p-col-12">
        <p-table
          dataKey="id"
          [value]="dataSet"
          rowExpandMode="single"
          selectionMode="single"
          [columns]="tableColums"
          [rowTrackBy]="helperService.rowTrackBy"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData>
            <tr [pRowToggler]="rowData">
              <td>{{ rowData['firstName'] }} {{ rowData['lastName'] }}</td>
              <td>
                <span *ngIf="rowData.phone">{{ rowData.phone }}, </span>
                <span *ngIf="rowData.mobilePhone">{{ rowData.phone }}</span>
              </td>
              <td>{{ rowData['email'] }}</td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-columns="columns"
          >
            <tr class="p-table-expanded-row">
              <td [attr.colspan]="columns.length">
                <div
                  class="p-grid p-fluid"
                  style="font-size: 16px; padding: 20px"
                >
                  <div>
                    <h4>{{ rowData.firstName }} {{ rowData.lastName }}</h4>
                    <p>{{ rowData.address }}</p>
                    <p>{{ rowData.address2 }}</p>
                    <p>{{ rowData.cityCode }} {{ rowData.city }}</p>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td [attr.colspan]="columns.length">
                <app-emptyState [model]="'User'"></app-emptyState>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </hyperion-card>
  <div></div>
</div>
