import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

import { FormHandlerService } from '../../../shared/forms/index';
import { PurchaseTransactionService } from '../../purchase-transaction.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
  providers: [FormHandlerService],
})
export class OrderRowComponent implements OnInit {
  @Input() rowData;
  @Input() projects;

  loading = true;
  lockButtons = false;
  projectInfo: string = null;
  rowsObject = {
    itemDescription: '',
    quantityValue: '',
    unitPriceValue: '',
    UOMCoded: '',
  };
  form: FormGroup;
  formArray: FormGroup;
  formFieldsRows = {
    model: 'EdiOrderRow',
    nestedForm: true,
    attributes: {
      rows: [this.rowsObject],
    },
  };
  formFieldsOrder = {
    model: 'EdiOrder',
    attributes: {
      projectId: null,
    },
  };

  constructor(
    private formhandler: FormHandlerService,
    private purchaseTransactionService: PurchaseTransactionService
  ) {}

  ngOnInit() {
    this.rowData['projectId'] &&
      (this.formFieldsOrder['attributes']['projectId'] =
        +this.rowData['projectId']);
    this.rowData['projectId'] &&
      (this.projectInfo = `${this.rowData['project']['trueId']}, ${
        this.rowData['project']['mark'] ? this.rowData['project']['mark'] : ''
      } `);
    this.initForm();
  }

  initForm() {
    this.formArray = this.formhandler.groupedFormSimple(this.formFieldsRows);
    this.form = this.formhandler.groupedFormSimple(this.formFieldsOrder);

    Promise.all([
      this.formhandler.groupSetLabelsRules(this.formFieldsRows, this.formArray),
      this.formhandler.groupSetLabelsRules(this.formFieldsOrder, this.form),
    ]).then(() => {
      this.form.controls['projectId']['label'] = 'Projekt';
      this.setRowData();
      this.loading = false;
    });
  }

  mutateOrderRow() {
    this.lockButtons = true;
    const order = { ...this.rowData, ...this.form.value };
    delete order.rows;
    delete order.project;
    delete order.projectTrueId;
    delete order.source;
    delete order.sourceName;
    const orderRows = this.DynamicFormControls.value;

    const variables = {
      updateEdiOrder: order,
      orderRows: orderRows,
    };

    !this.projectInfo &&
      this.purchaseTransactionService.mutateOrder(variables).subscribe(data => {
        this.formhandler.showServerErrorsOnAttributes(
          data,
          [{ form: this.form, argument: 'EdiOrderRow' }],
          true
        );
        this.lockButtons = false;
      });
  }

  setRowData() {
    this.rowData['rows'].forEach(row => {
      const attribute = Object.assign(this.rowsObject, row);
      this.formhandler.addItemToFormArray(
        this.formArray,
        this.formFieldsRows,
        'rows',
        attribute
      );
    });
    this.DynamicFormControls.removeAt(0);
    this.disableControls();
  }

  disableControls(): void {
    this.DynamicFormControls['controls'].forEach((group: FormGroup) => {
      (group.get('UOMCoded') as FormControl).disable();
      (group.get('unitPriceValue') as FormControl).disable();
    });
  }

  get DynamicFormControls() {
    return this.formArray.get('rows') as FormArray;
  }
}
