export const QuantityUnits = [
  { value: 'st', label: 'Styck' },

  // packaging
  { value: 'förp', label: 'Förpackning' },
  { value: 'ark', label: 'Ark' },
  { value: 'ask', label: 'Ask' },
  { value: 'bal', label: 'Bal' },
  { value: 'brk', label: 'Burk' },
  { value: 'bnt', label: 'Bunt' },
  { value: 'hnk', label: 'Hink' },
  { value: 'kar', label: 'Kar' },
  { value: 'kart', label: 'Kartong' },
  { value: 'lass', label: 'Lass' },
  { value: 'pack', label: 'Pack' },
  { value: 'pall', label: 'Pall' },
  { value: 'par', label: 'Par' },
  { value: 'påse', label: 'Påse' },
  { value: 'pkt', label: 'Paket' },
  { value: 'säck', label: 'Säck' },
  { value: 'sats', label: 'Sats' },
  { value: 'spann', label: 'Spann' },
  { value: 'tub', label: 'Tub' },
  { value: 'rulle', label: 'Rulle' },

  // distance
  { value: 'mil', label: 'Mil' },
  { value: 'km', label: 'Kilometer' },
  { value: 'kvm', label: 'Kvadratmeter' },
  { value: 'meter', label: 'Meter' },
  { value: 'lpm', label: 'Löpmeter' },
  { value: 'dm', label: 'Decimeter' },
  { value: 'cm', label: 'Centimeter' },
  { value: 'mm', label: 'Milimeter' },
  { value: 'bkm', label: 'Kubikmeter' },

  // calender
  { value: 'mån', label: 'Mån' },
  { value: 'dag', label: 'Dag' },
  { value: 'k-dag', label: 'Kalenderdag' },
  { value: 'h', label: 'Timmar' },
  { value: 'tim', label: 'Timmar' },

  // units
  { value: 'ton', label: 'Ton' },
  { value: 'kilo', label: 'Kilo' },
  { value: 'g', label: 'Gram' },
  { value: 'hg', label: 'Hektogram' },
  { value: 'liter', label: 'Liter' },
  { value: 'ml', label: 'Milliliter' },
  { value: 'h-tal', label: 'Hundratal' },

  // random
  { value: 'utl', label: 'Utlägg' },
];
