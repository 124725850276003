<div style="position: relative">
  <spinner *ngIf="loading | async" class="spinner-fill"></spinner>

  <div style="overflow-y: scroll; max-height: 350px">
    <p-table
      [value]="dataSetAsync | async"
      [styleClass]="
        'row-hover' +
        (scrollHeight === '250px'
          ? ' small-scroll'
          : scrollHeight === '450px'
          ? ' big-scroll'
          : '')
      "
      [loading]="dataLoading"
      [rows]="tableRows"
      [paginator]="showPagination"
      [class.whitespace-pagination]="!showPagination"
      [pageLinks]="3"
      dataKey="id"
      [rowsPerPageOptions]="[10, 25, 50]"
      #projectDaysTable
      (onRowCollapse)="collapseTH2Row($event)"
      (onRowExpand)="expandTH2Row($event)"
      [rowTrackBy]="helperService.rowTrackBy"
      [sortField]="sort.attribute"
      [sortOrder]="sort.ascDesc"
      (onSort)="changeSort($event)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            class="date-column col-date"
            [pSortableColumn]="'date'"
            [class.overview-col]="state.width"
          >
            Datum
            <p-sortIcon [field]="'date'"></p-sortIcon>
          </th>
          <th
            class="done-work-column col-done-work"
            [class.overview-col]="state.width"
          >
            <span
              >Arbete
              <span
                class="help-tooltip invert smaller"
                tooltipPosition="top"
                pTooltip="I denna kolumn visas/redigeras arbetsmoment tillhörande de tider som rapporterats på respektive datum. I kolumnen kan du också redigera datumets fakturaunderlag."
                [escape]="false"
                >?</span
              >
            </span>
          </th>
          <th
            *ngIf="state.width"
            class="col-timmar"
            [pSortableColumn]="'hours'"
          >
            Timmar
            <p-sortIcon [field]="'hours'"></p-sortIcon>
          </th>
          <th
            class="word-break-in-th col-f-timmar"
            [pSortableColumn]="'hoursToInvoice'"
          >
            <span
              >F. timmar
              <span
                class="help-tooltip invert smaller"
                tooltipPosition="top"
                pTooltip="Fakturerbara timmar"
                [escape]="false"
                >?</span
              ></span
            >
            <p-sortIcon [field]="'hoursToInvoice'"></p-sortIcon>
          </th>
          <th *ngIf="state.width && functionsThisModel.setMile" class="col-mil">
            Mil
          </th>
          <th
            *ngIf="state.width && functionsThisModel.setPrivMile"
            class="col-p-mil"
          >
            Privata mil
          </th>
          <th class="word-break-in-th col-f-mil">
            <span
              >F. mil
              <span
                class="help-tooltip invert smaller"
                tooltipPosition="top"
                pTooltip="Fakturerbara mil"
                [escape]="false"
                >?</span
              >
            </span>
          </th>
          <th *ngIf="state.width" class="col-extra">Extra</th>
          <th
            class="col-yrkestyp"
            *ngIf="state.width && functionsThisModel.useUserCostType"
          >
            Yrkestyp
          </th>
          <th
            class="col-user-cost-type"
            *ngIf="state.width && !functionsThisModel.useUserCostType"
          ></th>
          <th class="col-l-f">L / F</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-expanded="expanded"
        let-columns="columns"
      >
        <tr>
          <td class="date-column col-date" [class.overview-col]="state.width">
            <span
              [id]="'dayId' + rowData.id"
              *ngIf="rowData.timeHour == '2'"
              style="font-weight: bold"
              [class]="'link ' + 'dayId' + rowData.id"
              (click)="projectDaysTable.toggleRow(rowData)"
              [pTooltip]="rowData['date']"
            >
              {{ rowData['date'] }}
              <i
                *ngIf="rowData.th1thisDay.length > 0"
                class="fa"
                [class.fa-chevron-right]="!expanded"
                [class.fa-chevron-down]="expanded"
              ></i>
            </span>
          </td>
          <td
            class="done-work-column col-done-work"
            [class.overview-col]="state.width"
          >
            <div
              *ngIf="rowData.timeHour === 2 && expanded"
              style="width: 100%; max-width: 100%"
              class="flex-container"
            >
              <div style="width: 80%">
                <textarea
                  class="form-control resize-vert"
                  pInputTextarea
                  [(ngModel)]="rowData['doneWork']"
                  (input)="rowData.showSaveIcon = true"
                  style="width: 100%; box-sizing: border-box"
                >
                </textarea>
              </div>
              <div>
                <i
                  *ngIf="
                    functionsThisModel.useNotarized && !allNotarized(rowData)
                  "
                  class="fa fa-exclamation-triangle"
                  tooltipPosition="top"
                  pTooltip="Samtliga dagar ej attesterade"
                ></i>
                <i
                  *ngIf="rowData.showSaveIcon"
                  class="fa fa-save hover-pointer"
                  style="font-size: 16px"
                  (click)="editTH2DoneWork(rowData.id, rowData.doneWork)"
                ></i>
                <i
                  *ngIf="rowData.th1thisDayAsync.value.length === 0"
                  class="fa fa-trash-o delete hover-pointer"
                  style="font-size: 16px"
                  (click)="deleteTH2Request(rowData.id)"
                  tooltipPosition="top"
                  pTooltip="Radera fakturaunderlag"
                ></i>
              </div>
            </div>
            <span
              *ngIf="rowData.timeHour === 2 && !expanded"
              class="flex-container"
            >
              <div class="p-text-truncate" [pTooltip]="rowData['doneWork']">
                {{ rowData['doneWork'] }}
              </div>
              <div>
                <i
                  *ngIf="
                    functionsThisModel.useNotarized && !allNotarized(rowData)
                  "
                  class="fa fa-exclamation-triangle"
                  tooltipPosition="top"
                  pTooltip="Samtliga dagar ej attesterade"
                ></i>
              </div>
            </span>
          </td>
          <td
            class="col-timmar"
            *ngIf="state.width"
            [pTooltip]="rowData['hours'] | formatNum"
          >
            {{ rowData['hours'] | formatNum }}
          </td>
          <td class="col-f-timmar">
            <span
              *ngIf="rowData.timeHour == '2'"
              [pTooltip]="rowData['hoursToInvoice'] | formatNum"
              >{{ rowData['hoursToInvoice'] | formatNum }}</span
            >
          </td>
          <td
            class="col-mil"
            [pTooltip]="rowData['mile'] | formatNum"
            *ngIf="state.width && functionsThisModel.setMile"
          >
            {{ rowData['mile'] | formatNum }}
          </td>
          <td
            class="col-p-mil"
            [pTooltip]="rowData['privMile'] | formatNum"
            *ngIf="state.width && functionsThisModel.setPrivMile"
          >
            {{ rowData['privMile'] | formatNum }}
          </td>
          <td class="col-f-mil">
            <span
              *ngIf="rowData.timeHour == '2'"
              [pTooltip]="rowData['mileToInvoice'] | formatNum"
            >
              {{ rowData['mileToInvoice'] | formatNum }}
            </span>
          </td>
          <td class="col-extra" *ngIf="state.width"></td>
          <!-- FIXME: Why do I have an empty cell here?? @batista 20191028 -->
          <td
            class="col-yrkestyp"
            *ngIf="state.width && functionsThisModel.useUserCostType"
          ></td>
          <!-- FIXME: Why do I have an empty cell here?? @batista 20191028 -->
          <td
            class="col-user-cost-type"
            *ngIf="state.width && !functionsThisModel.useUserCostType"
          ></td>
          <!-- FIXME: Why do I have an empty cell here?? @batista 20191028 -->
          <td class="col-l-f">
            <span
              *ngIf="rowData.payslipId"
              tooltipPosition="top"
              pTooltip="Utbetald som lön"
              >L
            </span>
            <span
              *ngIf="rowData.invoiceId"
              tooltipPosition="top"
              pTooltip="Fakturerad"
              >F</span
            >
          </td>
        </tr>
      </ng-template>
      <ng-template let-rowData pTemplate="rowexpansion">
        <tr>
          <td
            style="padding: 0"
            class="sub-table-container"
            [attr.colspan]="
              5 +
              (state.width ? 2 : 0) +
              (state.width && functionsThisModel.setMile ? 1 : 0) +
              (state.width && functionsThisModel.setPrivMile ? 1 : 0) +
              (state.width && functionsThisModel.useUserCostType ? 1 : 0) +
              (state.width && !functionsThisModel.useUserCostType ? 1 : 0)
            "
          >
            <p-table
              [value]="rowData.th1thisDayAsync | async"
              [rowTrackBy]="innerRowTrackby"
              styleClass="rowData-inner-table"
            >
              <ng-template pTemplate="body" let-rowData>
                <tr>
                  <td
                    class="date-column col-date"
                    [class.overview-col]="state.width"
                  >
                    <span
                      [pTooltip]="
                        rowData.user.firstName + ' ' + rowData.user.lastName
                      "
                      >{{
                        rowData.user.firstName + ' ' + rowData.user.lastName
                      }}</span
                    >
                  </td>
                  <td
                    class="done-work-column col-done-work p-overflow-hidden"
                    [class.overview-col]="state.width"
                  >
                    <div style="display: flex; justify-content: space-between">
                      <div style="flex: 1; padding-right: 5px">
                        <span
                          *ngIf="editDayWithId !== rowData.id"
                          (click)="editDayWithId = rowData.id"
                          class="hover-pointer hover-underline"
                          style="white-space: break-spaces"
                          [pTooltip]="rowData['doneWork']"
                          >{{ rowData['doneWork'] }}</span
                        >
                        <textarea
                          *ngIf="editDayWithId === rowData.id"
                          class="resize-vert"
                          pInputTextarea
                          rows="3"
                          [(ngModel)]="rowData['doneWork']"
                          style="width: 100%; box-sizing: border-box"
                        >
                        </textarea>
                      </div>
                      <div style="width: 30px">
                        <i
                          class="fa fa-level-up hover-pointer"
                          (click)="
                            addDoneWorkStringToTH2(
                              rowData.parentId,
                              rowData['doneWork']
                            )
                          "
                          tooltipPosition="top"
                          pTooltip="Addera kommentar till fakturaunderlag"
                        ></i>
                        <span *ngIf="functionsThisModel.useNotarized">
                          <i
                            class="fa fa-check hover-pointer"
                            (click)="
                              rowData.notarizedBool = true;
                              th1UpdateMutation(rowData, rowData.parentId)
                            "
                            tooltipPosition="top"
                            pTooltip="Attestera"
                            *ngIf="!rowData.notarizedBool"
                          ></i>
                          <span
                            *ngIf="
                              rowData.notarizedBool &&
                              rowData.notarizedUser === null
                            "
                            class="hover-pointer"
                            (click)="
                              regretNotarization(rowData, rowData.parentId)
                            "
                            tooltipPosition="top"
                            pTooltip="Klicka för att ångra attestering"
                            >{{ meUser.initials }}</span
                          >
                          <span
                            *ngIf="
                              rowData.notarizedBool &&
                              rowData.notarizedUser !== null
                            "
                            class="hover-pointer"
                            (click)="
                              regretNotarization(rowData, rowData.parentId)
                            "
                            tooltipPosition="top"
                            pTooltip="Klicka för att ångra attestering"
                            >{{ getUserInitials(rowData.notarizedUser) }}</span
                          >
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    class="col-timmar"
                    *ngIf="state.width"
                    [pTooltip]="rowData.hours | formatNum"
                  >
                    {{ rowData.hours | formatNum }}
                  </td>
                  <td class="col-f-timmar">
                    <input
                      type="text"
                      style="width: 80%"
                      [(ngModel)]="rowData['hoursToInvoice']"
                      pInputText
                      [class.p-disabled]="rowData.invoiceId"
                      [disabled]="rowData.invoiceId"
                    />
                  </td>
                  <td
                    class="col-mil"
                    *ngIf="state.width && functionsThisModel.setMile"
                    [pTooltip]="rowData.mile | formatNum"
                  >
                    {{ rowData.mile }}
                  </td>
                  <td
                    class="col-p-mil"
                    *ngIf="state.width && functionsThisModel.setPrivMile"
                    [pTooltip]="rowData.privMile | formatNum"
                  >
                    {{ rowData.privMile }}
                  </td>
                  <td class="col-f-mil">
                    <span>
                      <input
                        type="text"
                        style="width: 70%"
                        [(ngModel)]="rowData['mileToInvoice']"
                        pInputText
                        [class.p-disabled]="rowData.invoiceId"
                        [disabled]="rowData.invoiceId"
                      />
                      <i
                        class="fa fa-save hover-pointer"
                        style="font-size: 16px"
                        *ngIf="!state.width"
                        (click)="th1UpdateMutation(rowData, rowData.parentId)"
                      ></i>
                    </span>
                  </td>
                  <td class="col-extra" *ngIf="state.width">
                    <p-checkbox
                      [(ngModel)]="rowData.extraBool"
                      [binary]="true"
                      (onChange)="th1UpdateMutation(rowData, rowData.parentId)"
                    ></p-checkbox>
                  </td>
                  <td
                    class="col-yrkestyp p-overflow-hidden"
                    *ngIf="state.width && functionsThisModel.useUserCostType"
                  >
                    <span>
                      <span
                        class="hover-pointer p-text-truncate"
                        tooltipPosition="top"
                        pTooltip="Klicka för att byta yrkestyp"
                        (click)="openCostTypeDialog(rowData)"
                        style="white-space: break-spaces"
                      >
                        {{ showUserCostType(rowData.companyCostTypeId) }}
                      </span>
                      <i
                        class="fa fa-save hover-pointer"
                        style="font-size: 16px"
                        *ngIf="state.width"
                        (click)="th1UpdateMutation(rowData, rowData.parentId)"
                      ></i>
                    </span>
                  </td>
                  <td
                    class="col-user-cost-type"
                    *ngIf="state.width && !functionsThisModel.useUserCostType"
                  >
                    <div>
                      <i
                        class="fa fa-save hover-pointer"
                        style="font-size: 16px"
                        *ngIf="state.width"
                        (click)="th1UpdateMutation(rowData, rowData.parentId)"
                      ></i>
                    </div>
                  </td>
                  <td class="col-l-f">
                    <span
                      *ngIf="rowData.payslipId"
                      tooltipPosition="top"
                      pTooltip="Utbetald som lön"
                      >L
                    </span>
                    <span
                      *ngIf="rowData.invoiceId"
                      tooltipPosition="top"
                      pTooltip="Fakturerad"
                      >F</span
                    >
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="summary">
        <div
          style="width: 100%; display: flex; justify-content: space-between"
          class="p-mb-5"
        >
          <div>
            <span
              (click)="toggleShowDaysSummary()"
              class="link"
              style="float: left"
              ><i
                class="fa"
                [class.fa-plus]="!showDaysSummary"
                [class.fa-minus]="showDaysSummary"
              ></i
              ><span *ngIf="!showDaysSummary"> Visa summering</span
              ><span *ngIf="showDaysSummary"> Dölj summering</span></span
            >
          </div>
          <div>
            <span
              >Summa timmar/mil:
              <span *ngIf="!isExtra" class="strong-text"
                >{{ projectInvoiceData['hour'] | formatNum }} /
                {{ projectInvoiceData['mile'] | formatNum }}</span
              ><span *ngIf="isExtra" class="strong-text"
                >{{ projectInvoiceData['hourExtra'] | formatNum }} /
                {{ projectInvoiceData['mileExtra'] | formatNum }}</span
              ></span
            >
          </div>
        </div>
        <div *ngIf="showDaysSummary" style="margin-top: 6px">
          <table
            style="max-width: 530px; font-style: italic"
            class="table-no-style td-align-left"
          >
            <tbody>
              <tr>
                <td>Antal dagar: {{ th1Data['daysLength'] }} st</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Fakturerade timmar:
                  {{ th1Data['invoicedHours'] | formatNum }} st
                </td>
                <td>
                  Fakturerade mil: {{ th1Data['invoicedMiles'] | formatNum }} st
                </td>
              </tr>
              <tr>
                <td>
                  Ej fakturerade timmar:
                  {{ th1Data['notInvoicedHours'] | formatNum }} st
                </td>
                <td>
                  Ej fakturerade mil:
                  {{ th1Data['notInvoicedMiles'] | formatNum }} st
                </td>
              </tr>

              <tr>
                <td><br />Traktamenten</td>
                <td></td>
              </tr>

              <tr>
                <td>Heldag:</td>
                <td>{{ th1Data['subsistenceDay'] }} st</td>
              </tr>

              <tr>
                <td>Halvdag:</td>
                <td>{{ th1Data['subsistenceHalfDay'] }} st</td>
              </tr>

              <tr>
                <td>Natt:</td>
                <td>{{ th1Data['subsistenceNight'] }} st</td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>

      <ng-template pTemplate="emptymessage">
        <tr class="emptyStateTR">
          <td
            [attr.colspan]="
              5 +
              (state.width ? 2 : 0) +
              (state.width && functionsThisModel.setMile ? 1 : 0) +
              (state.width && functionsThisModel.setPrivMile ? 1 : 0) +
              (state.width && functionsThisModel.useUserCostType ? 1 : 0) +
              (state.width && !functionsThisModel.useUserCostType ? 1 : 0)
            "
          >
            <app-emptyState
              [model]="'Day'"
              [whereToCreate]="'bottom'"
            ></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- Ändra yrkestyp -->
  <p-dialog
    [(visible)]="userCostTypeDialog.show"
    [style]="{ width: '350' }"
    [responsive]="true"
    modal="true"
    style
    [draggable]="false"
    [resizable]="true"
  >
    <div class="p-grid">
      <div class="p-col-12 small-text">
        <p class="strong-text">Uppdatera yrkestyp för:</p>
        <p *ngIf="userCostTypeDialog.show" style="margin-bottom: 8px">
          {{ userCostTypeDialog.day.date }},
          {{ userCostTypeDialog.day.description }}
        </p>
        <p class="strong-text">Användare:</p>
        <p *ngIf="userCostTypeDialog.show">
          {{ userCostTypeDialog.day.user.firstName }}
          {{ userCostTypeDialog.day.user.firstName }}
        </p>
      </div>
      <div class="p-col-12">
        <div
          *ngIf="userCostTypeDialog.show"
          style="padding-top: 7px; overflow: scroll; max-height: 40vh"
        >
          <div *ngFor="let userCostType of functionsData.userCostTypes">
            <p-radioButton
              [value]="userCostType.value"
              [label]="userCostType.label"
              [(ngModel)]="userCostTypeDialog.newCostType"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div class="pull-right">
          <app-button
            (click)="costTypeDialogCancel(userCostTypeDialog.day)"
            [type]="'cancel'"
          ></app-button>
          <app-button
            [class]="'ml5'"
            (click)="costTypeDialogConfirm(userCostTypeDialog.day)"
            [type]="'confirm'"
          ></app-button>
        </div>
      </div>
    </div>
  </p-dialog>
  <!--    CREATE DAY FORM      -->
  <div class="create-day-form-wrapper" *ngIf="!(formInitLoading | async)">
    <form [formGroup]="componentMainForm">
      <div class="p-grid">
        <div class="p-col-5 p-g-nopad">
          <ng-container *ngIf="!(useReportingWithTimestamps | async)">
            <appForm2-simple-calendar
              class="p-g-nopad"
              formControlName="date"
              [htmlAttributes]="{
                input: { tabIndex: projectInfo['id'] + (isExtra ? '30' : '20') }
              }"
            ></appForm2-simple-calendar>
          </ng-container>

          <ng-container *ngIf="useReportingWithTimestamps | async">
            <appForm2-simple-calendar
              class="p-g-nopad"
              formControlName="startTime"
              [showTime]="true"
              [htmlAttributes]="{
                input: { id: componentPrefix + 'startTime' }
              }"
            ></appForm2-simple-calendar>

            <appForm2-simple-calendar
              class="p-g-nopad"
              formControlName="stopTime"
              [showTime]="true"
              [htmlAttributes]="{ input: { id: componentPrefix + 'stopTime' } }"
            ></appForm2-simple-calendar>
          </ng-container>
        </div>

        <div class="p-col-5 p-g-nopad">
          <appForm2-simple-textarea
            [rows]="1"
            formControlName="doneWork"
            [htmlAttributes]="{
              input: {
                tabIndex: projectInfo['id'] + (isExtra ? '31' : '21'),
                id: descId
              }
            }"
          ></appForm2-simple-textarea>
        </div>

        <div class="p-col-6 p-md-1 p-g-nopad">
          <appForm2-simple-input
            formControlName="hours"
            [htmlAttributes]="{
              input: { tabIndex: projectInfo['id'] + (isExtra ? '32' : '22') }
            }"
          ></appForm2-simple-input>
        </div>

        <div class="p-col-6 p-md-1 p-g-nopad">
          <appForm2-simple-input
            formControlName="mile"
            [htmlAttributes]="{
              input: { tabIndex: projectInfo['id'] + (isExtra ? '33' : '23') }
            }"
          ></appForm2-simple-input>
        </div>
      </div>

      <div class="p-grid project-day--form-bottom-row">
        <div class="p-col-12 p-md-4 p-g-nopad">
          <appForm2-simple-dropDown
            *ngIf="meUser.type === '3'"
            formControlName="userId"
            [dropDownParams]="{ options: usersDropdown, filter: true }"
            [htmlAttributes]="{
              input: { tabIndex: projectInfo['id'] + (isExtra ? '34' : '24') }
            }"
          ></appForm2-simple-dropDown>
          <div *ngIf="meUser.type !== '3'">
            Rapporterar för {{ meUser.firstName }} {{ meUser.lastName }}
            <input type="hidden" formControlName="userId" [value]="meUser.id" />
          </div>
        </div>

        <div class="p-col-12 p-md-4 p-g-nopad">
          <appForm2-simple-dropDown
            *ngIf="toggleTodoDropdown | async"
            formControlName="todoId"
            [dropDownParams]="{ options: projectTodosDropdown, filter: true }"
            [htmlAttributes]="{
              input: { tabIndex: projectInfo['id'] + (isExtra ? '35' : '25') }
            }"
          ></appForm2-simple-dropDown>
        </div>
        <div class="p-col-12 p-md-4 p-g-nopad">
          <div class="p-col-12 align-right btn-save">
            <app-button
              [disabled]="buttons.create.disabled"
              (onClick)="actionCreate()"
              [type]="'create'"
              [model]="'Day'"
              [tabindex]="projectInfo['id'] + (isExtra ? '36' : '26')"
            ></app-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
