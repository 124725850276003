<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading" [class.page-container]="!isModal">
  <p-card [class.no-style]="isModal">
    <div class="p-grid">
      <div *ngIf="showTitle === 'card'" class="p-col-12">
        <h3>Produkttyper</h3>
      </div>
      <div class="p-grid p-col-10">
        <div
          [class.p-col-3]="hasAccountingPlanFunction"
          [class.p-col-6]="!hasAccountingPlanFunction"
        >
          Namn
        </div>
        <div
          [class.p-col-1]="hasAccountingPlanFunction"
          [class.p-col-3]="!hasAccountingPlanFunction"
        >
          %
        </div>
        <div
          [class.p-col-2]="hasAccountingPlanFunction"
          [class.p-col-3]="!hasAccountingPlanFunction"
        >
          Enhet
        </div>
        <div *ngIf="hasAccountingPlanFunction" class="p-col-3">Konto</div>
        <div *ngIf="hasAccountingPlanFunction" class="p-col-3">
          Konto omvänd moms
        </div>
      </div>

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="
          let systemType of systemTypes;
          trackBy: helperService.rowTrackBy
        "
      >
        <companyCostType-edit
          [costType]="systemType"
          [hasAccountingPlanFunction]="hasAccountingPlanFunction"
          [accountingPlanAccounts]="accountingPlanAccounts"
        ></companyCostType-edit>
        <hr class="line-separator" />
      </div>

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let costType of dataSet; trackBy: helperService.rowTrackBy"
      >
        <companyCostType-edit
          [costType]="costType"
          [hasAccountingPlanFunction]="hasAccountingPlanFunction"
          [accountingPlanAccounts]="accountingPlanAccounts"
        ></companyCostType-edit>
      </div>

      <div
        class="p-col-12 p-g-nopad"
        *ngFor="let createComponent of createDataObjectArray"
      >
        <companyCostType-create
          (dataObjectDeleted)="spliceDeletedDataObject(createComponent)"
          (createSibling)="addCreateComponent(createComponent)"
          [hasAccountingPlanFunction]="hasAccountingPlanFunction"
          [accountingPlanAccounts]="accountingPlanAccounts"
        ></companyCostType-create>
      </div>

      <div *ngIf="dataSetInactive.length" class="p-col-12 p-g-nopad">
        <hr class="line-separator" />
        <h2>Inaktiverade produkttyper</h2>

        <div
          *ngFor="
            let costType of dataSetInactive;
            trackBy: helperService.rowTrackBy
          "
        >
          <companyCostType-edit
            [costType]="costType"
            [hasAccountingPlanFunction]="hasAccountingPlanFunction"
            [accountingPlanAccounts]="accountingPlanAccounts"
          ></companyCostType-edit>
        </div>
      </div>
    </div>
  </p-card>
</div>
