import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first, Subscription } from 'rxjs';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { MessageService } from '../../../shared/message/index';
import { GlobalService } from '../../../shared/global/index';
import {
  GetCompanySettingsGQL,
  GetCompanySettingsQuery,
  UpdateCompanySettingsGQL,
} from './graphql/company-update-settings.generated';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
  AccountingPlanAccountsDropdownItem,
} from '../services/accounting-plan.types';
import { AccountingPlanService } from '../services/accounting-plan.service';
import { UserFlagsService } from 'app/user-flags.service';

@Component({
  selector: 'companyUpdate-settings',
  templateUrl: 'company-update-settings.component.html',
  styleUrls: ['./company-update-settings.scss'],
  providers: [FormHandlerService],
})
export class CompanyUpdateSettingsComponent implements OnInit, OnDestroy {
  public componentMainForm: FormGroup;
  private dataModel = 'company';
  private dataModelCapitalized = 'Company';
  private formFields = {
    model: this.dataModelCapitalized,
    attributes: {
      id: '',
      name: '',
      address: '',
      address2: '',
      cityCode: '',
      city: '',
      phone: '',
      mobilePhone: '',
      mail: '',
      webb: '',
      orgNr: '',
      faxNr: '',
      companyHourlyOverhead: '',
      companyHourlyRateForCostumer: '',
      companyMilageForCostumer: '',
      companyMileCost: '',
      companyPrivMileCost: '',
      companyMilageForCustomerAccountingPlanAccountId: null,
      reverseVatCompanyMilageForCustomerAccountingPlanAccountId: null,
    },
  };
  public urlPrefix: string;
  public buttons: any;
  public componentLoading = true;
  public rand = 3746;
  public imgError = false;

  public companyAccountingPlanAccounts: AccountingPlanAccount[];
  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;
  public accountingPlanAccountsDropdownItemMap: {
    [key: number]: AccountingPlanAccountsDropdownItem;
  } = {};
  public hasAccountingPlanFunction: boolean;

  constructor(
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private getCompanySettingsService: GetCompanySettingsGQL,
    private updateCompanySettingsService: UpdateCompanySettingsGQL,
    private accountingPlanService: AccountingPlanService,
    private userFlagsService: UserFlagsService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();

    const buttonObject = {
      update: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  public ngOnInit(): void {
    this.getAccountingPlans();
    this.queryData();
    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.hasAccountingPlanFunction = flags.hasFlag('useAccountingPlan');
      });
  }

  private queryData(): void {
    this.getCompanySettingsService
      .fetch()
      .pipe(first())
      .subscribe(({ data }) => {
        this.initForm(data);
      });
  }
  private initForm(data: GetCompanySettingsQuery): void {
    this.formHandler
      .groupSetLabelsRules(this.formFields, null, data['company'])
      .then(groupedForm => {
        this.componentMainForm = groupedForm;
        this.componentLoading = false;
      });
  }

  public ngOnDestroy(): void {}

  public actionUpdate(): void {
    const dataToMutation = this.componentMainForm.value;
    dataToMutation['id'] = Number(dataToMutation['id']);
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      this.updateCompanySettingsService
        .mutate({ companySettings: dataToMutation })
        .pipe(first())
        .subscribe(({ data }) => {
          this.mutationService.displayMutationStatus(
            data.companyTypeHyperionMutation
          );
          this.formHandler.showServerErrorsOnAttributes(
            data.companyTypeHyperionMutation,
            [
              {
                form: this.componentMainForm,
                argument: 'update' + this.dataModelCapitalized,
              },
            ]
          );

          this.buttons = this.formHandler.unlockButtons(this.buttons);
        });
    }
  }

  public onImgReadError(): void {
    this.imgError = true;
  }

  public onUpload(event): void {
    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    if (dataAsJSON['status'] === 'success') {
      this.rand = Math.random() * 100;
    }

    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: dataAsJSON['status'],
    });

    this.imgError = false;
  }

  private getAccountingPlans(): void {
    this.accountingPlanService
      .getAccounts()
      .subscribe((accounts: AccountingPlanAccount[]) => {
        this.companyAccountingPlanAccounts = accounts;

        this.accountingPlanAccountsDropdownParams =
          this.accountingPlanService.getAccountsDropdownParams(
            this.companyAccountingPlanAccounts
          );
        this.accountingPlanAccountsDropdownParams.options.forEach(
          i => (i.value = String(i.value))
        );
        this.accountingPlanAccountsDropdownParams.options.forEach(
          i => (this.accountingPlanAccountsDropdownItemMap[i.value] = i)
        );
      });
  }
}
