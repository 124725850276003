import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoComplete } from 'primeng/autocomplete';
import { ConfirmationService } from 'primeng/api';

import { GlobalService } from 'app/shared/global';
import { ApolloQueryService, ApolloMutationService } from 'app/shared/apollo';
import { MessageService } from 'app/shared/message';
import { CompanyFunctionsService } from 'app/shared/company/index';
import { ConnectionsService } from 'app/attendance-report/connections/services/connections.service';
import { HttpService } from 'app/shared/http';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { AttendanceReportCreateProjectComponent } from 'app/attendance-report/handle/attendance-report-dialogs/create-project/create-project.component';
import { AttendanceReportConstructionSiteNumberComponent } from 'app/attendance-report/handle/attendance-report-dialogs/construction-site-number/construction-site-number.component';
import { AddSubcontractorComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor/add-subcontractor.component';
import { ConnectionsComponent } from 'app/attendance-report';
import { AddSubcontractorToProjectComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-to-project/add-subcontractor-to-project.component';
import { AddSubcontractorMailComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-mail/add-subcontractor-mail.component';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { UserLocalStorageService } from 'app/shared/user';

@Component({
  selector: 'app-project-handle',
  templateUrl: './project-handle.component.html',
  styleUrls: ['./project-handle.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HttpService, AppDialogService],
})
export class ProjectHandleComponent implements OnInit, OnDestroy {
  @Input() dataSetAsync;
  @Input() projectInfo;
  @Input() isProjectBookmarks;
  @Output() getUnrelatedProjects = new EventEmitter();
  @Output() toggle = new EventEmitter<any>();

  divisionsPanel = [];
  projectsDropdownAll = [];
  subcontractorsData = [];
  attendanceReportsWithoutRelations = [];
  loading = true;
  isMain = false;
  useUserConnectionToProject: boolean;
  gottenUnRelatedProjects: boolean;
  showExtraProjects = false;
  showSubcontractors = false;
  allowSubcontractorsToAddSubcontractors = false;
  attendanceReportsWithoutRelationsHaveErrors = false;
  autoCompleteResults = new BehaviorSubject([]);
  private componentDestroyed: Subject<void> = new Subject();
  public notificationsCount: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  autoCompleteQuery: string;
  subcontractorRelationToProjectId = 0;
  projectData: any = {};
  connectionsData = {};
  selectedProject: any = {};
  companiesWithAttendanceReports = {};
  attendanceReportsInfo = {
    projectId: null,
    orgNr: null,
  };
  companyInfo = {
    id: null,
    name: null,
    orgNr: null,
  };
  companyConnectionsSub: Subscription;
  usersConnectedToProjectSub: Subscription;
  companyDivisionAndUsersSub: Subscription;
  attendanceReportsSub: Subscription;
  refetchAttReportSub: Subscription;

  public meUser: MeUserWithCompany;

  constructor(
    private router: Router,
    protected route: ActivatedRoute,
    protected cdr: ChangeDetectorRef,
    private httpService: HttpService,
    private globalService: GlobalService,
    private messageService: MessageService,
    private apolloQueryService: ApolloQueryService,
    private connectionsService: ConnectionsService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private dialogService: AppDialogService,
    private companyFunctionsService: CompanyFunctionsService,
    private userLocalStorageService: UserLocalStorageService
  ) {}

  ngOnInit() {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.setUseUserConnectionToProject();
    this.getCompanyDivision();
    this.getConnections();
  }

  setUseUserConnectionToProject(): void {
    this.companyFunctionsService
      .getCompanyFunctions()
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.useUserConnectionToProject =
          this.companyFunctionsService.companyFunctionIsSet(
            'useUserConnectionToProject'
          );
      });
  }

  getConnections(): void {
    this.companyConnectionsSub = this.apolloQueryService
      .apolloQuery('companyConnections')
      .subscribe(data => {
        this.connectionsData = this.connectionsService.formatConnectionsData(
          data.data
        );
        const attendanceClients = this.connectionsData['attendanceClients'];
        this.notificationsCount.next(
          this.connectionsService.countNotApprovedAttendanceClients(
            attendanceClients
          )
        );
      });
  }

  changeProject() {
    this.autoCompleteQuery = null;
    if (this.selectedProject) {
      this.loading = true;
      this.getUserConnectedToProject();
      this.getProject();
    }
  }

  getProject() {
    const url =
      this.globalService.getUrlPrefix() +
      '/AttendanceReport/SubcontractorsSorted/projectId/' +
      this.selectedProject['id']; // denna URLen går igenom
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      if (data['status'] === 'success') {
        this.subcontractorsData = data['data'];
        this.companiesWithAttendanceReports = {};
        this.handleSubcontractorData(this.subcontractorsData);

        this.getAttendanceReports();
      } else {
        this.messageService.insertData({
          textArray: [
            'Något gick fel vid hämtning av projekt, var god försök igen',
          ],
          type: 'error',
        });
      }
    });
  }

  /* Divisions And users Connected */
  divisionsUserSelected(_boolean, userId, divisionName) {
    let runQuery = false;
    const index = this.divisionsPanel.findIndex(
      obj => obj['title'] === divisionName
    );
    this.divisionsPanel[index]['users'].forEach(user => {
      +user['id'] === +userId && (user['isChecked'] = _boolean);
      +user['id'] === +userId &&
        _boolean === 1 &&
        (runQuery = true) &&
        this.userConnectionToProject(+user['id'], 'create');
      +user['id'] === +userId &&
        _boolean === 0 &&
        this.userConnectionToProject(+user['projectId'], 'delete');
    });
    setTimeout(() => {
      runQuery && this.getUserConnectedToProject();
    }, 0);
  }

  selectOrDeselectDivisionAllPersons(division, _boolean) {
    let runQuery = false;
    const index = this.divisionsPanel.findIndex(
      obj => obj['title'] === division
    );
    this.divisionsPanel[index]['users'].forEach(user => {
      if (user['isChecked'] !== +_boolean) {
        user['isChecked'] = +_boolean;
        +_boolean === 1 &&
          (runQuery = true) &&
          this.userConnectionToProject(+user['id'], 'create');
        +user['projectId'] &&
          +_boolean === 0 &&
          this.userConnectionToProject(+user['projectId'], 'delete');
      }
    });
    setTimeout(() => {
      runQuery && this.getUserConnectedToProject();
    }, 0);
  }

  userConnectionToProject(id, updateConnection) {
    let variables = {};
    if (updateConnection === 'delete') {
      variables = { id };
    } else {
      variables = { userId: id, projectId: this.selectedProject['id'] };
    }
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'userHasProject',
        updateConnection,
        false,
        variables
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          this.cdr.markForCheck();
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  getUserConnectedToProject() {
    const variables = { id: +this.selectedProject['id'] };
    this.usersConnectedToProjectSub = this.apolloQueryService
      .apolloQuery('usersConnectedToProject', variables)
      .subscribe(({ data }) => {
        let ids = [];
        if (data['company']['projects']['edges'].length > 0) {
          ids = this.apolloQueryService.cleanFromNode(
            data['company']['projects']['edges'][0]['node']['relationsToUsers']
          );
        }
        // Loop tru divisionsPanel and set isCheked to 1 if id exist
        this.divisionsPanel.forEach(object => {
          if (object['users'].length > 0) {
            object['users'].forEach(user => {
              if (ids.findIndex(x => x['userId'] === +user['id']) > -1) {
                (user['isChecked'] = 1),
                  (user['projectId'] =
                    +ids[ids.findIndex(x => x['userId'] === +user['id'])][
                      'id'
                    ]);
              } else {
                user['isChecked'] = 0;
              }
            });
          }
        });
      });
  }

  getCompanyDivision() {
    this.companyDivisionAndUsersSub = this.apolloQueryService
      .apolloQuery('companyDivisionAndUsers', null)
      .subscribe(({ data }) => {
        const divisions = this.apolloQueryService.cleanFromNode(
          data['company']['divisions']
        );
        const users = this.apolloQueryService.cleanFromNode(
          data['company']['users']
        );

        // Only if the value is provided directly
        this.projectInfo && (this.selectedProject = this.projectInfo);
        this.projectInfo && this.changeProject();
        // Call function to display value on view
        this.setDivisionsPanel(divisions, users);
      });
  }

  setDivisionsPanel = (divisions, users) => {
    const dividedUsers = [];
    const divisionToSet = [];
    divisions.forEach(division => {
      this.apolloQueryService
        .cleanFromNode(division['users'])
        .forEach(x => dividedUsers.push(x.id));
      divisionToSet.push({
        title: division.name,
        users: this.apolloQueryService.cleanFromNode(division['users']),
      });
    });

    // Check if user id exist on dividedUsers
    divisionToSet.push({
      title: 'Saknar division',
      users: users.filter(user => !dividedUsers.includes(user['id'])),
    });
    this.divisionsPanel = divisionToSet;
    this.cdr.markForCheck();
  };

  // End Divisions

  private handleAttendanceReportsData(attendanceReportsData: {}) {
    const projectData = { ...attendanceReportsData['data']['projectGlobal'] };
    this.companyInfo = attendanceReportsData['data']['company'];
    projectData['attendanceReports'] = [];
    this.projectData = projectData;

    let isMain = false;

    if (this.projectData.company && this.projectData.company.id) {
      isMain =
        Number(this.projectData.company.id) === Number(this.companyInfo.id);
    }

    this.isMain = isMain;

    this.allowSubcontractorsToAddSubcontractors =
      projectData.allowSubcontractorToAdSubcontractors === 1;

    this.handleProjectData(projectData); // ingen subscribe
    this.sortAttendanceReports(); // ingen subs
    this.setAttendanceReportsInfo(attendanceReportsData['data']); // ingen subscribe

    this.loading = false; // släpper inte denna

    this.cdr.markForCheck();
  }

  setAttendanceReportsInfo(attendanceReportsData) {
    const company = attendanceReportsData.company;
    const attendanceReportsInfo = { ...this.attendanceReportsInfo };
    attendanceReportsInfo.projectId = Number(this.projectData.id);
    attendanceReportsInfo.orgNr = company.orgNr;

    this.attendanceReportsInfo = attendanceReportsInfo;
  }

  handleSubcontractorData(subcontractors) {
    let index = subcontractors.length;
    while (index--) {
      const subcontractorObject: any = subcontractors[index];

      if (subcontractorObject.subcontractor) {
        subcontractorObject.subcontractor.attendanceReports = []; // attendanceReports will end up here

        const orgNr = this.formatOrgNr(subcontractorObject.subcontractor.orgNr);
        this.companiesWithAttendanceReports[orgNr] = [];

        if (subcontractorObject.hasOwnProperty('children')) {
          this.handleSubcontractorData(subcontractorObject.children);
        }
      } else {
        subcontractors.splice(index, 1);
      }
    }
  }

  handleProjectData(projectData: any) {
    this.attendanceReportsWithoutRelations = [];

    const orgNr = this.formatOrgNr(this.companyInfo.orgNr);
    this.companiesWithAttendanceReports[orgNr] = [];

    Object.keys(this.companiesWithAttendanceReports).forEach(key => {
      this.companiesWithAttendanceReports[key] = [];
    });

    this.matchAttendanceReportToCompany(
      projectData.attendanceReportsStopParentIsNull
    );
  }

  matchAttendanceReportToCompany(attendanceReports: any) {
    const edges = attendanceReports ? attendanceReports.edges : [];

    for (const index in edges) {
      const node = { ...edges[index].node };
      if (node.hasOwnProperty('__typename')) {
        delete node.__typename;
      }

      const orgNr = this.formatOrgNr(node.companyOrgNr);
      if (this.companiesWithAttendanceReports.hasOwnProperty(orgNr)) {
        this.companiesWithAttendanceReports[orgNr].push(node);
      } else {
        this.attendanceReportsWithoutRelations.push(node);
      }
    }

    this.attendanceReportsWithoutRelationsHaveErrors =
      this.contractorHaveErrors(this.attendanceReportsWithoutRelations);
  }

  sortAttendanceReports() {
    const projectOrgNr = this.formatOrgNr(this.companyInfo.orgNr);
    const subcontractorData = [...this.subcontractorsData];

    Object.keys(this.companiesWithAttendanceReports).forEach(orgNr => {
      if (orgNr === projectOrgNr) {
        this.projectData.attendanceReports = [
          ...this.companiesWithAttendanceReports[orgNr],
        ];
        this.projectData['haveErrors'] = this.contractorHaveErrors(
          this.projectData.attendanceReports
        );
      } else {
        this.appendAttendanceReportsToSubcontractor(orgNr, subcontractorData);
      }
    });

    this.subcontractorsData = subcontractorData;
  }

  contractorHaveErrors(attendanceReports) {
    let haveErrors = false;

    for (const index in attendanceReports) {
      const attendanceReport: any = attendanceReports[index];

      if (
        attendanceReport.realUser_PreAttendanceReportTypeHyperion.errors
          .length > 0
      ) {
        haveErrors = true;
        break;
      }
    }

    return haveErrors;
  }

  appendAttendanceReportsToSubcontractor(orgNr: string, subcontractorsArray) {
    const subcontractors = [...subcontractorsArray];
    for (const index in subcontractors) {
      const subcontractorObject: any = subcontractors[index];

      const subcontractorOrgNr = this.formatOrgNr(
        subcontractorObject.subcontractor.orgNr
      );
      if (orgNr === subcontractorOrgNr) {
        const attendanceReports = [
          ...this.companiesWithAttendanceReports[orgNr],
        ];
        subcontractorObject.subcontractor.attendanceReports = attendanceReports;
        subcontractorObject['haveErrors'] =
          this.contractorHaveErrors(attendanceReports);
        break;
      }

      if (subcontractorObject.hasOwnProperty('children')) {
        this.appendAttendanceReportsToSubcontractor(
          orgNr,
          subcontractorObject.children
        );
      }
    }
    subcontractorsArray = subcontractors;
  }

  getAttendanceReports() {
    const projectId = Number(this.selectedProject['id']);
    const variables = { id: projectId };

    this.apolloQueryService
      .apolloWatchQueryTwo('attendanceReports', variables, 'cache-first')
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        data.sub.unsubscribe();
        this.handleAttendanceReportsData(data);
      });
  }

  /* Dialogs */
  openConnectionsDialog() {
    this.toggle.emit();
    this.dialogService.layout = 'wide';
    this.dialogService.data = { connectionsData: this.connectionsData };
    this.dialogService
      .openComponent(ConnectionsComponent)
      .onClose.subscribe(res => {
        res && this.refetchAttendanceReportProjects();
      });
  }

  getUnRelatedProjects() {
    this.showExtraProjects = false;
    this.gottenUnRelatedProjects = true;
    this.getUnrelatedProjects.emit({ status: 1, forceAll: true });
  }

  getRelatedProjects() {
    this.gottenUnRelatedProjects = false;
    this.getUnrelatedProjects.emit();
  }

  openCreateProjectDialog() {
    this.toggle.emit();
    this.dialogService.layout = 'auto';
    this.dialogService
      .openComponent(AttendanceReportCreateProjectComponent)
      .onClose.subscribe(res => {
        if (res) {
          const variables = { status: 1, forceAll: false };
          if (this.isInArchived()) {
            variables.status = 3;
          }
          this.apolloQueryService
            .apolloQuery('attendanceReportProjects', variables)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(res2 => {
              this.selectedProject = res;
              this.changeProject();
            });
        }
      });
  }

  openConstructionSiteNumberDialog() {
    this.toggle.emit();
    this.dialogService
      .openComponent(AttendanceReportConstructionSiteNumberComponent)
      .onClose.subscribe(res => {
        if (res) {
          const variables = { status: 1, forceAll: false };
          if (this.isInArchived()) {
            variables.status = 3;
          }
          this.apolloQueryService
            .apolloQuery('attendanceReportProjects', variables)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe();
        }
      });
  }

  openAddSubcontractorDialog() {
    this.dialogService
      .openComponent(AddSubcontractorComponent)
      .onClose.subscribe(res => {
        if (res) {
          setTimeout(() => {
            this.openAddSubcontractorToProjectDialog(res);
          }, 250);
        }
      });
  }

  openAddSubcontractorToProjectDialog(subcontractorData, tst = null) {
    const projectData = this.selectedProject;
    this.autoCompleteQuery = null;
    projectData['attendance'] = 1;
    projectData['clientProject'] = 0;

    this.dialogService.data = {
      subcontractorInfo: subcontractorData,
      projectInfo: projectData,
      parentId: +projectData.subcontractorRelationToProjectId || 0,
    };

    this.dialogService
      .openComponent(AddSubcontractorToProjectComponent)
      .onClose.subscribe(res => {
        res && this.addSubcontractorHandle(res, subcontractorData);
      });
  }

  private addSubcontractorHandle(res: any, subcontractorData: any) {
    const subcontractorCopiedData = [...this.subcontractorsData];
    const subcontractor = this.constructSubcontractorObject(res);
    subcontractorCopiedData.push(subcontractor);
    this.subcontractorsData = [...subcontractorCopiedData];
    const orgNr = this.formatOrgNr(res.model.orgNr);
    this.companiesWithAttendanceReports[orgNr] = [];
    this.cdr.markForCheck();
    setTimeout(() => {
      this.openAddSubcontractorMailDialog(subcontractorData);
    }, 250);
  }

  openAddSubcontractorMailDialog(subcontractorData) {
    this.dialogService.data = { subcontractorInfo: subcontractorData };
    this.dialogService.openComponent(AddSubcontractorMailComponent);
  }

  searchSubcontractor(autoCompleteElement?: AutoComplete, event?) {
    const query = event ? event.query : null;

    let url = this.globalService.getUrlPrefix();
    url += query
      ? '/subcontractor/AutoComplete?q=' + query
      : '/subcontractor/AutoComplete?q=';

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      if (!event && data.length === 0) {
        data.push({ id: null });
      }

      this.autoCompleteResults.next(data);
      if (!event) {
        autoCompleteElement.show();
      }
    });
  }

  refetchAttendanceReports() {
    const projectId = Number(this.selectedProject['id']);
    const variables = { id: projectId };
    this.refetchAttReportSub = this.apolloQueryService
      .apolloQuery('attendanceReports', variables)
      .subscribe();
  }

  setShowSubcontractors = (to = true) => {
    this.showSubcontractors = to;
  };

  confirmDeleteProject() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteProject();
      },
      reject: () => {},
    });
  }

  private deleteProject() {
    const url = '/project/delete/id/' + this.projectData.id;
    this.loading = true;
    this.httpService
      .makeHttpPostRequest(url)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(data => {
        if (data['status'] === 'success') {
          this.selectedProject = null;
          this.loading = false;
        }
        this.messageService.insertData({
          textArray: [data['msg']],
          type: data.status,
        });
      });
  }

  subcontractorDeleted(relationId, subcontractorsData, isInChildren = false) {
    const copiedSubcontractorData = [...subcontractorsData];
    let subcontractor;
    let indexOfArray = null;
    for (const index in copiedSubcontractorData) {
      const relation: any = copiedSubcontractorData[index];

      if (Number(relation.id) === Number(relationId)) {
        // - If deleted relation
        subcontractor = relation;
        indexOfArray = index;
        break;
      }

      if (relation.hasOwnProperty('children')) {
        // If not deleted relation, search in children
        const childrenOfRelation = this.subcontractorDeleted(
          relationId,
          relation.children,
          true
        ); // false if not found in children, array of updated children if found
        if (childrenOfRelation) {
          relation.children = childrenOfRelation;
          copiedSubcontractorData.splice(Number(index), 1, relation);
          subcontractorsData = [...copiedSubcontractorData];
          if (isInChildren) {
            return subcontractorsData;
          } else {
            this.subcontractorsData = [...subcontractorsData];
            this.cdr.markForCheck();
            break;
          }
        }
      }
    }

    if (!indexOfArray) {
      return false;
    } // if relation is not found

    const orgNr = this.formatOrgNr(subcontractor.subcontractor.orgNr);
    delete this.companiesWithAttendanceReports[orgNr]; // Delete company from project

    const attendanceReports = [
      ...subcontractor.subcontractor.attendanceReports,
    ]; // Transfer attendanceReports to withoutRelation
    this.attendanceReportsWithoutRelations = [
      ...this.attendanceReportsWithoutRelations,
      ...attendanceReports,
    ];

    if (subcontractor.hasOwnProperty('children')) {
      // If the relation has children, let the children take its place
      const children = [...subcontractor.children];
      copiedSubcontractorData.splice(Number(indexOfArray), 1, ...children);
    } else {
      // relation doesn't have children, erase it
      copiedSubcontractorData.splice(Number(indexOfArray), 1);
    }

    subcontractorsData = [...copiedSubcontractorData];
    if (!isInChildren) {
      this.subcontractorsData = subcontractorsData;
      this.cdr.markForCheck();
    }
    {
      return subcontractorsData; // return what to append on children
    }
  }

  toggleAllowSubcontractorsToAddSubcontractors() {
    const dataToMutation = {
      id: Number(this.projectData.id),
      allowSubcontractorToAdSubcontractors: this
        .allowSubcontractorsToAddSubcontractors
        ? 1
        : 0,
    };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'project',
        'update',
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  confirmUpdateProjectStatus(statusParam: number) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill arkivera projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.archieveProject(statusParam);
      },
      reject: () => {},
    });
  }

  private archieveProject(statusParam: number) {
    const dataToMutation = {
      id: Number(this.projectData.id),
      status: statusParam,
    };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'project',
        'update',
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.selectedProject = null;
            this.refetchAttendanceReportProjects();
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
          this.cdr.markForCheck();
        },
        err => {
          this.selectedProject = null;
        }
      );
  }

  isInArchived = () =>
    this.router.url ===
    '/attendanceReport/old-project/archived;mode=attendance;state=archived';

  formatOrgNr = orgNr => orgNr.replace(/\D/g, '');

  getGatherList() {
    const url =
      this.globalService.getUrlPrefix() +
      '/attendanceReport/gatherList?projectId=' +
      this.selectedProject['id'];
    window.open(url, '_blank');
  }

  constructSubcontractorObject = (res: any) => ({
    id: res.rel.id,
    parentId: 0,
    haveErrors: false,
    subcontractor: {
      id: res.model.id,
      attendanceReports: [],
      companyId: res.model.companyId,
      email: res.model.email,
      name: res.model.name,
      orgNr: res.model.orgNr,
    },
  });

  private refetchAttendanceReportProjects() {
    const variables = { status: 1, forceAll: false };
    if (this.isInArchived()) {
      variables.status = 3;
    }
    this.apolloQueryService
      .apolloQuery('attendanceReportProjects', variables)
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe();
  }

  ngOnDestroy() {
    this.companyConnectionsSub && this.companyConnectionsSub.unsubscribe();
    this.usersConnectedToProjectSub &&
      this.usersConnectedToProjectSub.unsubscribe();
    this.companyDivisionAndUsersSub &&
      this.companyDivisionAndUsersSub.unsubscribe();
    this.attendanceReportsSub && this.attendanceReportsSub.unsubscribe();
    this.refetchAttReportSub && this.refetchAttReportSub.unsubscribe();

    this.componentDestroyed.next();
    this.componentDestroyed.complete();
    this.cdr && this.cdr.detach();
  }
}
