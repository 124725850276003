<div *ngIf="themeLoading"></div>

<div *ngIf="!themeLoading && supported">
  <router-outlet></router-outlet>
  <hyperion-footer></hyperion-footer>
</div>

<div *ngIf="!themeLoading && !supported">
  <app-browser-support></app-browser-support>
</div>
