import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FormHandlerService } from 'app/shared/forms';
import { GlobalService } from 'app/shared/global';
import { HelperService } from 'app/shared/helpers';
import { MessageService } from 'app/shared/message';
import { HttpService } from 'app/shared/http';
import { CompanyUsersService } from 'app/shared/user';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'add-attendance-report',
  templateUrl: './add-attendance-report.component.html',
})
export class AddAttendanceReportComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  formFields = {
    model: 'AttendanceReport',
    attributes: {
      userType: null,
      projectId: null,
      startTime: new Date(),
      stopTime: null,
    },
    attrToExcludeFromRulesAndLabel: ['userType', 'stopTime'],
  };
  formFieldsForFreeUser = {
    model: 'AttendanceFreeUser',
    attributes: {
      firstName: null,
      lastName: null,
      personalNumber: null,
      companyOrgNr: null,
    },
  };

  mutationData;
  usersDropdown = [];
  public mode = 'freeUser';
  freeUserForm: FormGroup;
  usersSub: Subscription;
  componentMainForm: FormGroup;
  public attendanceReportsInfo: any;
  dataModelCapitalized = 'AttendanceReport';

  constructor(
    private helperService: HelperService,
    private messageService: MessageService,
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private usersService: CompanyUsersService,
    private formHandler: FormHandlerService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initiateComponentWithDialogData();
    this.setFieldsForForm();
    this.mode === 'user' && this.getUsersDropdown();
  }

  initiateComponentWithDialogData() {
    this.attendanceReportsInfo =
      this.dialogConfig['data']['attendanceReportsInfo'];
    this.mode = this.dialogConfig['data']['mode'];
    setTimeout(() => {
      this.dialogConfig.header = 'Skapa ny stämpling';
    }, 0);
  }

  setFieldsForForm() {
    this.formFields['attributes'].projectId =
      this.attendanceReportsInfo.projectId;
    this.formFields['attributes'].userType = this.mode;

    if (this.mode === 'freeUser') {
      this.formFieldsForFreeUser['attributes']['companyOrgNr'] =
        this.attendanceReportsInfo.orgNr;
    } else {
      this.formFields['attributes']['userId'] = null;
    }
    this.initForm();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.freeUserForm = this.formHandler.groupedFormSimple(
      this.formFieldsForFreeUser
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls.stopTime['label'] = 'Sluttid';
      });
    this.formHandler
      .groupSetLabelsRules(this.formFieldsForFreeUser, this.freeUserForm)
      .then();
  }

  actionCreate() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const url = '/attendanceReport/lateRegistrationHyperion';
      const controls: any = this.componentMainForm.controls;
      const params = {
        userType: controls.userType.value,
        projectId: controls.projectId.value,
        startTime: this.helperService.dateWithTimeFormatTimeZone(
          controls.startTime.value
        ),
        stopTime: controls.stopTime.value
          ? this.helperService.dateWithTimeFormatTimeZone(
              controls.stopTime.value
            )
          : null,
      };

      if (this.mode === 'freeUser') {
        params['firstName'] = this.freeUserForm.controls.firstName.value;
        params['lastName'] = this.freeUserForm.controls.lastName.value;
        params['personalNumber'] =
          this.freeUserForm.controls.personalNumber.value;
        params['subCompanyOrgNr'] =
          this.freeUserForm.controls.companyOrgNr.value;
      } else {
        params['userId'] = controls.userId.value;
      }

      this.httpService
        .makeHttpPostRequest(url, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          if (data.status === 'success') {
            const mutationData = data.model;
            this.mutationData = mutationData;
            this.closeAction();
          }
          this.messageService.insertData({
            textArray: [data.msg],
            time: 2000,
            type: data.status,
          });
        });
    }
  } // End actionCreate

  getUsersDropdown() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.usersService.makeLabelsArray(data);
        this.usersDropdown.splice(0, 0, {
          label: 'Välj användare',
          value: null,
        });
      });
  }

  closeAction() {
    this.dialogRef.close(this.mutationData);
  }

  ngOnDestroy() {
    this.usersSub && this.usersSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
