import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHandlerService } from '../../shared/forms/index';
import { AuthRegisterParams, AuthService } from 'app/shared/auth/auth.service';
import { filter, first, tap } from 'rxjs/operators';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-docu-signup',
  templateUrl: './docu-signup.component.html',
  styleUrls: ['./docu-signup.component.scss'],
  providers: [FormHandlerService],
})
export class DocuSignupComponent implements OnInit {
  private documentId;

  userFormStructure = {
    model: 'User',
    nestedForm: false,
    attributes: {
      firstName: '',
      lastName: '',
      email: '',
    },
  };
  companyFormStructure = {
    model: 'Company',
    nestedForm: false,
    attributes: {
      phone: '',
      name: '',
      orgNr: '',
    },
  };

  userInfoForm: FormGroup;
  companyInfoForm: FormGroup;
  saving = false;
  @Input() cancelUrl: string = null;
  @Output() done = new EventEmitter<boolean>();
  @ViewChild('docuForm') public docuForm: ElementRef;

  constructor(
    private formHandler: FormHandlerService,
    private authService: AuthService,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data'] && this.dialogConfig['data']['documentId']) {
      this.documentId = this.dialogConfig['data']['documentId'];
    }
    this.setupForm();
  }

  private setupForm() {
    this.userInfoForm = this.formHandler.groupedFormSimple(
      this.userFormStructure
    );
    this.companyInfoForm = this.formHandler.groupedFormSimple(
      this.companyFormStructure
    );

    Promise.all([
      this.formHandler.groupSetLabelsRules(
        this.userFormStructure,
        this.userInfoForm
      ),
      this.formHandler.groupSetLabelsRules(
        this.companyFormStructure,
        this.companyInfoForm
      ),
    ]).then();
  }

  cancelUrlTrigger() {
    window.location.href = this.cancelUrl;
  }

  cancel() {
    this.done.next(false);
  }

  submitForm() {
    if (this.formHandler.formValid([this.userInfoForm, this.companyInfoForm])) {
      this.saving = true;
      const formValues: AuthRegisterParams = {
        user: this.userInfoForm.value,
        company: this.companyInfoForm.value,
      };
      if (this.documentId) {
        formValues.sourceDocumentId = this.documentId;
      }
      this.authService.register(formValues).subscribe({
        next: data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            [
              { form: this.userInfoForm, argument: 'createUser' },
              { form: this.companyInfoForm, argument: 'createCompany' },
            ],
            true
          );
          if (data.loginUrl) {
            window.location.href = data.loginUrl;
          }
        },
        error: err => {
          this.saving = false;
          throw err;
        },
        complete: () => {
          this.saving = false;
        },
      });
    }
  }
}
