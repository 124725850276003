import { Apollo, QueryRef } from 'apollo-angular';
import { FetchPolicy } from '@apollo/client/core';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { AuthService } from 'app/shared/auth/auth.service';
import { GlobalService, ERROR_TYPES } from '../global/index';
import { rawQueries as queries, getRefetchQuerieOf } from './queries/index';

export enum ApolloFetchPolicy {
  CACHE_FIRST = 'cache-first',
  NETWORK_ONLY = 'network-only',
  CACHE_ONLY = 'cache-only',
  CACHE_AND_NETWORK = 'cache-and-network',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby',
}

@Injectable()
export class ApolloQueryService {
  constructor(
    private apollo: Apollo,
    private globalService: GlobalService,
    private authService: AuthService
  ) {}

  public getApolloQuery = (queryName: string) => queries[queryName].query;
  public getRefetchQuerieOf = (queryName: string) =>
    getRefetchQuerieOf(queryName);

  /*
   * OBS
   *   Primär är 'apolloWatchQueryTwo' och ska användas framgent,
   *   finns fortffarande komponenter som använder 'apolloWatchQuery',
   *   optimalt ska dessa bytas ut
   * OBS
   */

  /* Lyssna efter ändringar */

  /*** Ny Apolo query for direct querry from service **/
  apolloWatchQueryTwoQuery(
    query,
    queryVars = null,
    fetchPolicyParam: FetchPolicy | 'cache-and-network' = 'network-only'
  ) {
    const queryOptions = {
      query: query,
      variables: queryVars,
      fetchPolicy: fetchPolicyParam,
    };
    const maxRetrys = 3;

    return Observable.create(observer =>
      this.performWatchQuery(queryOptions, maxRetrys, observer)
    );
  }

  apolloWatchQueryTwo(
    queryName: string,
    queryVars = null,
    fetchPolicyParam: FetchPolicy | 'cache-and-network' = 'network-only'
  ) {
    const queryOptions = {
      query: queries[queryName].query,
      variables: queryVars,
      fetchPolicy: fetchPolicyParam,
    };
    const maxRetrys = 3;

    return Observable.create(observer => {
      this.performWatchQuery(queryOptions, maxRetrys, observer);
    });
  }

  private performWatchQuery(queryOptions: any, maxRetrys: number, observer) {
    const sub = this.apollo.watchQuery(queryOptions).valueChanges.subscribe(
      ({ data }) => {
        if (typeof data !== 'undefined') {
          observer.next({ data, sub, queryOptions });
        }
      },
      error => {
        if (
          this.globalService.identifyErrorCode(error) === ERROR_TYPES.AUTH_ERROR
        ) {
          this.authService.authenticatedChange.subscribe(status => {
            if (status) {
              this.performWatchQuery(queryOptions, maxRetrys, observer);
            }
          });
          throw error;
        } else {
          if (maxRetrys > 0) {
            // console.log("DO RETRY");
            --maxRetrys;
            sub.unsubscribe();
            setTimeout(() => {
              this.performWatchQuery(queryOptions, maxRetrys, observer);
            }, 50);
          } else {
            throw error;
          }
        }
      }
    );
  }

  /* Hämta data en gång */
  apolloQuery(
    queryName: string,
    queryVars = null,
    fetchPolicyParam: FetchPolicy = 'network-only'
  ) {
    const requestQuery = queries[queryName].query;
    let dataObservable: Observable<any>;

    dataObservable = this.apollo.query({
      query: requestQuery,
      variables: queryVars,
      fetchPolicy: fetchPolicyParam,
    });
    return dataObservable;
  }

  getQuery = queryName => queries[queryName].query;

  cleanFromNode(dataParam) {
    const dataArray = dataParam.edges;
    const dataArrayWithObjects = [];
    for (const object in dataArray) {
      if (typeof dataArray[object]['node'] !== 'undefined') {
        dataArrayWithObjects.push(Object.assign({}, dataArray[object]['node']));
      }
    }
    return dataArrayWithObjects;
  }
}
