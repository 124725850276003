import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';
import { CompanySuperService } from '../company.super.service';

@Injectable()
export class TodoTopicsService extends CompanySuperService {
  dataModel = 'todotopic';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'altLabels':
        dataSetFormatted = this.makeLabelsArray(data, true);
        break;
      case 'labelsNoDefault':
        dataSetFormatted = this.makeLabelsArrayNoDefault(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  cleanInActive() {}

  makeLabelsArray(data, alt?) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    if (alt) {
      dataObjectsWithLabels.push({
        // create object to push
        label: 'Välj rubrik...',
        value: null,
      });
    } else {
      dataObjectsWithLabels.push({
        // create object to push
        label: 'Välj/skapa rubriker...',
        value: null,
      });
    }

    for (const i in dataArray) {
      if (dataArray[i].node.active === 1) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.Name,
          value: +dataArray[i].node.id,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeLabelsArrayNoDefault(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    for (const i in dataArray) {
      if (dataArray[i].node.active === 1) {
        const dataObject = {
          // create object to push
          label: dataArray[i].node.Name,
          value: +dataArray[i].node.id,
        };
        dataObjectsWithLabels.push(dataObject);
      }
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const projectInfo =
        dataArray[i]['node']['trueId'] + ', ' + dataArray[i]['node']['mark'];

      const dataObject = { ...dataArray[i].node };

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }
      dataObject['projectInfo'] = projectInfo;
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }
}
