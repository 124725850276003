import { Apollo, gql } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HelperService } from '../helpers/index';
import { ApolloQueryService } from './apollo-query.service';

import { mutations } from './mutations-hardcoded';
import { MessageService } from '../message/message.service';
import { mutationDetails } from './queries';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Injectable()
export class ApolloMutationService {
  constructor(
    private angularApollo: Apollo,
    private helperService: HelperService,
    private messageService: MessageService,
    private apolloQueryService: ApolloQueryService
  ) {}

  staticConstructQueryAndExecuteMutation(
    gqlString,
    apolloVars,
    dataModel = null,
    refetchQueriesParam: any[] = null
  ): Observable<any> {
    return Observable.create(observer => {
      this.angularApollo
        .mutate({
          mutation: mutations[gqlString],
          variables: apolloVars,
          refetchQueries: this.checkRefetchQuery(refetchQueriesParam),
        })
        .subscribe(
          ({ data }) => {
            if (dataModel !== null) {
              const keyName = dataModel + 'TypeHyperionMutation';
              const mutationDataFromService = data[keyName];
              mutationDataFromService['mutationSucceededAllArguments'] =
                this.checkMutationSucceededAll(mutationDataFromService);
              observer.next(mutationDataFromService);
              observer.complete();
            } else {
              observer.next(data);
              observer.complete();
            }
          },
          onerror => {
            observer.error(onerror);
            throw onerror;
          }
        );
    });
  }

  displayMutationStatus(executedData) {
    this.messageService.insertDataFromMutation(executedData);
  }

  // Delete kan inte ha validation
  public constructQueryAndExecuteMutation(
    model: string,
    action: string,
    validate: boolean,
    variables,
    responseVariables?: string[],
    refetchQueriesParam: any[] = null
  ) {
    // TODO: Replace with enum
    const validActions = [
      'update',
      'delete',
      'create',
      'copy',
      'updateAndSendPasswordFor',
    ];
    if (validActions.indexOf(action) === -1) {
      throw new Error(
        `constructQueryAndExecuteMutation: Action does not exist wrong '${action}'`
      );
    }

    let mutationQueryString = this.helperService.getQueryString(model, action);
    if (responseVariables && responseVariables !== null) {
      mutationQueryString +=
        this.helperService.joinWithEndingSpace(responseVariables);
    }

    if (action === 'update' || action === 'create') {
      mutationQueryString += `
        ${mutationDetails}
        id
      }}
    `;
    } else {
      mutationQueryString += `${mutationDetails} }}`;
    }

    const gqlString = gql`
      ${mutationQueryString}
    `;

    const apolloVars = {};
    const hyperionMutationType = this.helperService.getMutationType(
      model,
      action
    );
    apolloVars[hyperionMutationType] = {};

    for (const key in variables) {
      const keyValue = variables[key];
      apolloVars[hyperionMutationType][key] = keyValue;
    }

    // If delete, don't pass validateOnHyperionMutation
    if (action !== 'delete') {
      apolloVars[hyperionMutationType]['validateOnHyperionMutation'] = validate;
    }

    const dataModel = this.helperService.lowerCaseFirstLetter(model);
    const refetchQueriesArr = [];

    if (refetchQueriesParam !== null) {
      for (const i in refetchQueriesParam) {
        const refetchInst = refetchQueriesParam[i];
        const refetchObj = {
          query: this.apolloQueryService.getQuery(refetchInst['name']),
          variables: refetchInst['variables'],
        };
        refetchQueriesArr.push(refetchObj);
      }
    }

    return Observable.create(observer => {
      this.angularApollo
        .mutate({
          mutation: gqlString,
          variables: apolloVars,
          refetchQueries: refetchQueriesArr,
        })
        .subscribe(
          ({ data }) => {
            const keyName = dataModel + 'TypeHyperionMutation';
            const mutationDataFromService = data[keyName];
            mutationDataFromService['mutationSucceededAllArguments'] =
              this.checkMutationSucceededAll(mutationDataFromService);
            observer.next(mutationDataFromService);
            observer.complete();
          },
          onerror => {
            observer.error(onerror);
            throw onerror;
          }
        );
    });
  } // End constructQueryAndExecuteMutation

  checkRefetchQuery(refetchQueriesParam) {
    const refetchQueriesArr = [];
    if (refetchQueriesParam !== null) {
      for (const i in refetchQueriesParam) {
        const refetchInst = refetchQueriesParam[i];
        const refetchObj = {
          query: this.apolloQueryService.getQuery(refetchInst['name']),
          variables: refetchInst['variables'],
        };
        refetchQueriesArr.push(refetchObj);
      }
    }
    return refetchQueriesArr;
  }

  getMutationDataFromForm(form: FormGroup, ignoreControls?) {
    const dataObjectToMutation: { [key: string]: any } = {};
    for (const controlKey in form.controls) {
      const control: AbstractControl = form.controls[controlKey];
      dataObjectToMutation[controlKey] = control.value;
    }
    delete dataObjectToMutation['__typename'];
    return dataObjectToMutation;
  } // End getMutationDataFromForm

  public checkMutationSucceededAll(mutationDataFromService) {
    let success = true;
    for (const details of mutationDataFromService.mutationDetails) {
      if (!details.mutationSucceeded) {
        success = false;
        break;
      }
    }
    return success;
  }
}
