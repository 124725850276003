/* eslint-disable @typescript-eslint/no-shadow */

import {
  Component,
  OnInit,
  Input,
  HostListener,
  QueryList,
  ViewChildren,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  Observable,
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  concat,
  from,
  of,
  NextObserver,
  Subscription,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { List } from 'immutable';
import {
  auditTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  scan,
  shareReplay,
  startWith,
  switchAll,
  switchMap,
  switchMapTo,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DocumentMetadata } from '../document-metadata';
import { GlobalService } from '../../shared/global/index';
import { ApolloQueryService } from '../../shared/apollo';
import { DocumentLogoService } from '../document-logo.service';
import { DocumentService, SaveAction } from '../document.service';
import { Document, DocumentRowInfo, DocumentRow, ViewId } from '../document';
import { DocumentChange, mkDocumentChanges } from '../document-change';
import { DocuSaveDialogComponent } from '../docu-save-dialog/docu-save-dialog.component';
import { DocuUpsellSavesDialogComponent } from '../docu-upsell-saves-dialog/docu-upsell-saves-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { CanBeDirty } from '../can-be-dirty';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';

@Component({
  selector: 'app-docu-edit',
  templateUrl: './docu-edit.component.html',
  styleUrls: ['./docu-edit.component.scss'],
  providers: [AppDialogService, ApolloQueryService],
})
export class DocuEditComponent extends CanBeDirty implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() id: Observable<number>;
  @Input() id2;
  shouldNavigateOnSave = true;
  dirty = false;
  lodingParams = true;
  inModal = false;

  display = false;
  overrideWithProjectData = false;

  reloadDocument: NextObserver<undefined>;

  dropDownParams;
  projectId: number;
  logo: Observable<string>;
  logoUploading = false;

  document: Observable<Document>;
  changes: Observable<List<DocumentChange>>;
  changeQueue: Observable<NextObserver<DocumentChange>>;
  metadataChangeQueue: Observable<
    NextObserver<(DocumentMetadata) => DocumentMetadata>
  >;
  lastSavedChangeQueue: Observable<NextObserver<List<DocumentChange>>>;

  documentMetadata: Observable<DocumentMetadata>;

  focusedSection: number = null;
  @ViewChildren('section')
  sections: QueryList<ElementRef>;

  focusDetectDebounceTimeout = null;

  isLoaded: Observable<boolean>;
  notFound: Observable<boolean>;
  companyProjectsSub: Subscription;
  public showCreateProjectDialog = false;

  get documentSectionHeaders(): Observable<List<DocumentRowInfo>> {
    return this.document.pipe(map(doc => doc.rows.map(section => section.row)));
  }

  newList = List;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private documentService: DocumentService,
    private logoService: DocumentLogoService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private dialogService: AppDialogService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dynamicDialogConfig: DynamicDialogConfig,
    private dynamicDialogRef: DynamicDialogRef,
    private messageService: MessageService
  ) {
    super();
  }

  public ngOnInit() {
    this.setAndSetProjectsToDropDown();

    if (this.id2) {
      this.shouldNavigateOnSave = false;
      this.id = of(this.id2);
    }

    if (
      this.dynamicDialogConfig &&
      this.dynamicDialogConfig.data &&
      this.dynamicDialogConfig.data.id2
    ) {
      this.shouldNavigateOnSave = false;
      this.inModal = true;
      this.id = of(this.dynamicDialogConfig.data.id2);
    }

    if (!this.id) {
      this.shouldNavigateOnSave = true;
      this.id = this.activatedRoute.paramMap.pipe(
        map(param => +param.get('id'))
      );
    }

    const reloadRequests = new BehaviorSubject(undefined);
    this.reloadDocument = reloadRequests;
    const id = reloadRequests.pipe(switchMapTo(this.id));

    this.logo = this.logoService.getLogoUrl();

    // Fetch document
    const baseMeta = id.pipe(
      switchMap(documentId => this.documentService.getMetadata(documentId)),
      shareReplay(1)
    );
    const baseDocument = baseMeta.pipe(
      filter(metadata => metadata !== undefined),
      switchMap(metadata => this.documentService.getDocument(metadata.id)),
      filter(doc => doc !== null),
      distinctUntilChanged((a, b) => a.equals(b)),
      shareReplay(1)
    );
    this.isLoaded = id.pipe(
      switchMapTo(
        combineLatest(baseMeta, baseDocument).pipe(
          map(() => true),
          startWith(false)
        )
      )
    );
    this.notFound = id.pipe(
      switchMapTo(
        baseMeta.pipe(
          map(meta => meta === undefined),
          startWith(false)
        )
      )
    );

    // Change queue
    const changesAndBaseDoc: Observable<{
      baseDoc: Document;
      baseMeta: DocumentMetadata;
      metaChanges: Subject<(DocumentMetadata) => DocumentMetadata>;
      baseChanges: BehaviorSubject<List<DocumentChange>>;
      newChanges: Subject<DocumentChange>;
      changes: Observable<DocumentChange>;
    }> = combineLatest(baseDocument, baseMeta).pipe(
      map(([baseDoc, baseMeta]) => ({
        baseDoc,
        baseMeta,
        baseChanges: new BehaviorSubject(List()),
      })),
      switchMap(({ baseDoc, baseMeta, baseChanges: baseChangesObs }) =>
        baseChangesObs.pipe(
          map(baseChanges => {
            const newChanges = new ReplaySubject<DocumentChange>(1);
            return {
              baseDoc,
              baseMeta,
              metaChanges: new ReplaySubject<
                (DocumentMetadata) => DocumentMetadata
              >(1),
              baseChanges: baseChangesObs,
              newChanges,
              changes: concat(from(baseChanges.toArray()), newChanges),
            };
          })
        )
      ),
      shareReplay(1)
    ) as any;
    const metadataChanges = (this.metadataChangeQueue = changesAndBaseDoc.pipe(
      map(({ metaChanges }) => metaChanges)
    ));
    this.changeQueue = changesAndBaseDoc.pipe(
      map(({ newChanges }) => newChanges)
    );
    this.lastSavedChangeQueue = changesAndBaseDoc.pipe(
      map(({ baseChanges }) => baseChanges)
    );
    this.changes = changesAndBaseDoc.pipe(
      switchMap(({ changes }) =>
        changes.pipe(
          scan<DocumentChange, List<DocumentChange>>(
            (changeList, change) => changeList.push(change),
            List()
          ),
          startWith(List())
        )
      ),
      shareReplay(1)
    ) as Observable<List<DocumentChange>>;
    // We need at least one observer in order to collect the changes
    this.changes.pipe(takeUntil(this.destroy$)).subscribe();

    combineLatest(
      this.changes,
      metadataChanges.pipe(
        map(x => x.pipe(startWith(null))),
        switchAll()
      )
    ).subscribe(([changes, latestMetaChange]) => {
      this.dirty =
        !changes.every(change => change.completed) || latestMetaChange !== null;
    });

    // Apply change queue
    this.document = changesAndBaseDoc.pipe(
      switchMap(({ baseDoc, changes }) =>
        changes.pipe(
          scan<DocumentChange, Document>(
            (doc, change) => change.applyToDocument(doc),
            baseDoc
          ),
          startWith(baseDoc)
        )
      ),
      tap(x => {
        window['__debug_docu_doc'] = x;
      }),
      shareReplay(1)
    );
    this.documentMetadata = changesAndBaseDoc.pipe(
      switchMap(({ baseMeta, metaChanges }) =>
        metaChanges.pipe(
          scan<(DocumentMetadata) => DocumentMetadata, DocumentMetadata>(
            (doc, change) => change(doc),
            baseMeta
          ),
          startWith(baseMeta)
        )
      ),
      tap(x => {
        window['__debug_docu_meta'] = x;
      }),
      shareReplay(1)
    );

    // Scroll listener
    this.document
      .pipe(auditTime(50), takeUntil(this.destroy$))
      .subscribe(doc => this.detectFocusedSection());
  }

  openAddProjectDialog() {
    this.showCreateProjectDialog = true;
  }

  save(action: SaveAction, unloading = false): Observable<boolean> {
    return combineLatest([
      this.documentService.remainingSaves(),
      this.documentMetadata,
      this.document,
      this.changes,
    ]).pipe(
      first(),
      switchMap(([remainingSaves, metadata, doc, changes]) => {
        if (remainingSaves > 0) {
          console.log('Saves remaining: ', remainingSaves);
          return of({ metadata, doc, changes });
        } else {
          return this.dialogService
            .openComponent(DocuUpsellSavesDialogComponent)
            .onClose.pipe(
              filter(x => x),
              map(() => ({ metadata, doc, changes }))
            );
        }
      }),
      switchMap(({ metadata, doc, changes }) => {
        const changeList = mkDocumentChanges(metadata, doc, changes);
        if (action === SaveAction.SaveAs || metadata.isTemplate) {
          this.dialogService.data = { changes: changeList.set('copy', true) };
          const dialog = this.dialogService.openComponent(
            DocuSaveDialogComponent
          );
          return dialog.onClose;
        } else {
          return of(changeList);
        }
      }),
      filter(changes => changes !== undefined),
      switchMap(changes => {
        this.globalService.toggleNavBarLoading(true);
        return this.documentService
          .saveDocument(changes, this.projectId, this.overrideWithProjectData)
          .pipe(
            finalize(() => {
              this.globalService.toggleNavBarLoading(false);
              this.overrideWithProjectData = false;
            })
          );
      }),
      map(savedChanges => {
        const failedChanges = savedChanges.changes.filter(
          change => !change.completed
        );

        if (failedChanges.isEmpty()) {
          this.messageService.insertData({
            severity: ToastMessageSeverityType.SUCCESS,
            summary: 'Dokumentet sparades',
          } as ToastMessage);

          this.lastSavedChangeQueue
            .pipe(first())
            .subscribe(queue => queue.next(savedChanges.changes));
          if (unloading) {
            this.dirty = false;
          } else {
            if (this.shouldNavigateOnSave) {
              this.router.navigate(['..', savedChanges.id], {
                relativeTo: this.activatedRoute,
              });
            } else if (action === SaveAction.SaveAs) {
              if (this.inModal) {
                this.dynamicDialogRef.close();
              } else {
                this.router.navigateByUrl('/docu/edit/' + savedChanges.id);
              }
            }
            this.reloadDocument.next(undefined);
          }
          return true;
        } else {
          console.log('display error goes here.. search for JOLLO');
          throw new Error('Server failed to apply changes');
        }
      })
    );
  }

  saveNow(action: SaveAction) {
    this.save(action).subscribe();
  }

  scrollToSection(row: DocumentRowInfo) {
    document.getElementById(`docu-edit-section-${row.id}`).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  applyChange(change: DocumentChange) {
    this.changeQueue.pipe(first()).subscribe(changes => {
      console.log('APPLYING ', change.toJS(), change.affectedItems.toJS());
      changes.next(change);
    });
  }

  changeName(newValue: string) {
    this.metadataChangeQueue
      .pipe(first())
      .subscribe(queue => queue.next(meta => meta.set('name', newValue)));
  }

  changeProjectId(newValue: string) {
    this.projectId = Number(newValue);
    this.metadataChangeQueue
      .pipe(first())
      .subscribe(queue => queue.next(meta => meta.set('projectId', newValue)));

    this.confirmationService.confirm({
      message: 'Vill du uppdatera dokumentet med data från projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-save',
      accept: () => {
        this.overrideWithProjectData = true;

        this.save(SaveAction.Save).subscribe();
      },
    });
  }

  changeLogo(newLogo: Blob) {
    this.logoUploading = true;
    this.logoService.uploadLogo(newLogo).subscribe(() => {
      this.logoUploading = false;
    });
  }

  trackSectionById(index: number, item: DocumentRow): ViewId {
    return item.row.viewId;
  }

  setAndSetProjectsToDropDown() {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyProjects',
        { status: [0, 1, 2] },
        'cache-and-network'
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.companyProjectsSub = sub;
        const res = this.apolloQueryService.cleanFromNode(
          data.company.projects
        );
        const options = res.map(project => ({
          label: `${project.trueId}, ${project.mark}`,
          value: +project.id,
        }));
        options.unshift({ label: `Välj projekt...`, value: null });
        this.dropDownParams = { options, filter: true };
        this.lodingParams = false;
      });
  }

  public displayNewProject(projectId: number) {
    this.showCreateProjectDialog = false;
    if (projectId) {
      this.changeProjectId(String(projectId));
      this.display = false;
    }
  }

  @HostListener('window:scroll')
  detectFocusedSection() {
    // Deciding which element is focused is a very slow operation, so don't do it too often
    if (this.focusDetectDebounceTimeout === null) {
      this.focusDetectDebounceTimeout = setTimeout(() => {
        const newFocused = this.sections.find(
          section =>
            section.nativeElement.offsetTop +
              section.nativeElement.offsetHeight / 2 >
            window.scrollY
        );
        if (newFocused !== undefined) {
          this.focusedSection = Number(newFocused.nativeElement.dataset.rowId);
        }
        this.focusDetectDebounceTimeout = null;
      }, 50);
    }
  }

  ngOnDestroy() {
    this.companyProjectsSub && this.companyProjectsSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
