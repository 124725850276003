import {
  Component,
  ViewChild,
  Input,
  ViewChildren,
  QueryList,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Table } from 'primeng/table';

import { GlobalService } from '../../../shared/global/index';
import { CalculationRowRowComponent } from './calculation-row-row/calculation-row-row.component';
import { SingleOfferService } from '../single-offer.service';
import { CompanyInfo, CompanyInfoService } from 'app/shared/company';
import { SelectItem } from 'primeng/api';

export type CalculationRowType = {
  id: number;
  name: string;
  cost: string | number;
  procent: string | number;
  tax: string | number;
  quantity: string | number;
  unit: string;
  rowSumPlusProcent_PreUserCalculationRowTypeHyperion?: string | number;
  productId: string | number;
  order: string | number;
  source: string;
  sourceId: string | number;
  calculationId?: string | number;
  listPrice?: string | number;
  listPriceUnit?: string | number;
  totalAmount?: string | number;
  greenTaxReduction?: string;
};

@Component({
  selector: 'app-offer-calculation-rows',
  templateUrl: './offer-calculation-rows.component.html',
})
export class OfferCalculationRowsComponent implements OnInit, OnDestroy {
  dataSet = [];
  @Input() offerInfo: {
    id?: any;
    currentExternalProject?: string;
    typeOffer?: any;
    groupCosts?: any;
    calculation?: any;
    [key: string]: any;
  };
  @Input() public isGreenTaxReduction: boolean;
  @Input() labels;
  @ViewChildren(CalculationRowRowComponent)
  offerCalculationRowComponents: QueryList<CalculationRowRowComponent>;
  @ViewChild('todoTable') todoTable: Table;

  formInterval;
  calculationId = 0;
  taxDropdown = new BehaviorSubject<any[]>([]);
  calculationRowSub: Subscription;
  public showProductPrices = true;
  public isMissingDeromeProductIntegration = false;
  public greenTaxDropdownOptions = new BehaviorSubject<SelectItem[]>([]);

  constructor(
    private singleOfferService: SingleOfferService,
    private globalService: GlobalService,
    private companyInfoService: CompanyInfoService
  ) {}

  ngOnInit() {
    this.taxDropdown.next(this.globalService.getTaxOptions());
    this.greenTaxDropdownOptions.next(this.globalService.getGreenTaxOptions());

    /* eslint-disable eqeqeq */
    if (
      typeof this.offerInfo['calculation'] !== 'undefined' &&
      this.calculationId == 0
    ) {
      /* eslint-enable eqeqeq */

      this.calculationId = Number(this.offerInfo['calculation']['id']);
    }

    this.calculationRowSub = this.singleOfferService.calculationRows$.subscribe(
      (calculationRows: CalculationRowType[]) => {
        this.dataSet = calculationRows.filter(
          row => row.source !== 'derome-webshop'
        );

        if (this.dataSet.length === 0) {
          this.addRow();
        } else {
          this.addRowIfNoEmpty();
        }
      }
    );

    this.companyInfoService.companyInfo$.subscribe(
      (companyInfo: CompanyInfo) => {
        this.showProductPrices = companyInfo.showProductSearchPrices;
        this.isMissingDeromeProductIntegration =
          companyInfo.isMissingDeromeProductIntegration;
      }
    );
  }

  addRowIfNoEmpty() {
    const calcs = this.dataSet;
    const calcsLength: string = (calcs.length - 1).toString();
    if (typeof calcs[calcsLength] !== 'undefined') {
      const calc = calcs[calcsLength];
      if (typeof calc['name'] !== 'undefined') {
        if (calc['name'] !== '' && calc['name'] !== null) {
          this.addRow();
        }
      }
    }
  }

  ngOnDestroy() {
    this.calculationRowSub && this.calculationRowSub.unsubscribe();
    clearInterval(this.formInterval);
  }

  addRow() {
    const taxValue =
      this.taxDropdown['value'].length > 0
        ? this.taxDropdown['value'][this.taxDropdown['value'].length - 1][
            'value'
          ]
        : null;

    if (
      typeof this.offerInfo['calculation'] !== 'undefined' &&
      +this.calculationId === 0
    ) {
      this.calculationId = +this.offerInfo['calculation']['id'];
    }

    const newObject: CalculationRowType = {
      id: null,
      name: null,
      cost: null,
      procent: null,
      tax: taxValue,
      quantity: null,
      unit: 'st',
      rowSumPlusProcent_PreUserCalculationRowTypeHyperion: null,
      productId: null,
      order: null,
      source: null,
      sourceId: null,
      calculationId: this.calculationId,
      greenTaxReduction: null,
    };
    this.dataSet.push(newObject);
  }

  removeRow(index: number) {
    this.dataSet.splice(index, 1);
    this.singleOfferService.addCalculationRows(this.dataSet);
  }

  public updateCalculationRows(eventParam, index): void {
    this.setRowId(eventParam, index);
    this.singleOfferService.addCalculationRows(this.dataSet);
  }

  private setRowId(eventParam, index) {
    this.dataSet[index].id = Number(eventParam.id);
  }
}
