import { Injectable } from '@angular/core';
import { Observable, of as observableOf, combineLatest } from 'rxjs';
import { ApolloQueryService } from '../apollo';
import { map, switchMap, tap } from 'rxjs/operators';
import { CompanyFunctionsService } from '.';
import { AuthService } from 'app/shared/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyDefinitionService {
  constructor(
    private apolloQueryService: ApolloQueryService,
    private companyFunctionsService: CompanyFunctionsService,
    private authService: AuthService
  ) {}

  private onlyIfLoggedIn(inner: Observable<boolean>): Observable<boolean> {
    return this.authService.isLoggedInObs.pipe(
      switchMap(loggedIn => (loggedIn ? inner : observableOf(false)))
    );
  }

  hasSubscribed(): Observable<boolean> {
    // Checks if costumer
    return this.onlyIfLoggedIn(
      this.apolloQueryService.apolloQuery('companyCustomerStatus', {}).pipe(
        map<any, boolean>(response => {
          return (
            !!response.data.company.foretagsfakta &&
            response.data.company.foretagsfakta.status === 'Kund'
          );
        })
      )
    );
  }

  canUploadLogo(): Observable<boolean> {
    return this.hasSubscribed();
  }

  /*
  efter x antal nerladdningar remainingExports(): Observable<number> -> exportCounts > 0
  och inte har projekt aktiverat !this.companyFunctionsService.hasCompanyFunction("useModuleProject")
    -> Show banner

*/

  documentCanActivateProjectTestPeriod(): Observable<boolean> {
    const exportCount = this.apolloQueryService
      .apolloQuery('documentExportCount', {})
      .pipe(
        map<any, number>(
          response => response.data.me.documentExports.totalCount
        )
      );

    return combineLatest([
      exportCount,
      this.companyFunctionsService.hasCompanyFunction('useModuleProject'),
    ]).pipe(
      map(([count, mayUseModuleProject]) => count > 0 && !mayUseModuleProject)
    );
  }
}
