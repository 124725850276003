<div class="p-grid">
  <div class="p-col-12">
    <spinner *ngIf="loading"></spinner>

    <div class="p-col-12">
      <h2>
        Fakturanummer
        <span
          class="help-tooltip"
          tooltipPosition="bottom"
          [escape]="false"
          pTooltip="<strong>Fakturanummer</strong><br><br>Ange nästa fakturanummer <br><br>
          Detta är ej kompatibelt med visma eller om fortnox fakturanummer används. <br><br>
          Fakturanummer måste vara större än det senaste fakturanumret. <br><br>
          "
          >?</span
        >
      </h2>
    </div>
    <div class="p-col-12 p-g-nopad">
      <label>Ange nästa fakturanummer</label>
      <input
        type="number"
        pInputText
        [formControl]="control"
        [placeholder]="nextInvoiceNr"
        [disabled]="useFortnoxInvoiceNumber || useVismaEAccounting || loading"
      />
      <span
        class="p-pt-2"
        style="color: var(--btn-danger-color)"
        *ngIf="!control?.valid && control?.dirty"
      >
        Nästa fakturanummer behövera vara högre än det högsta befintliga
        fakturanummret.
      </span>
    </div>
  </div>

  <div *ngIf="!loading" class="p-col-12 align-right">
    <button
      pButton
      type="button"
      class="p-button-primary-hyperion"
      [disabled]="
        useFortnoxInvoiceNumber ||
        useVismaEAccounting ||
        loading ||
        !control?.valid
      "
      (click)="actionUpdate()"
      label="Uppdatera"
      icon="pi pi-save"
    ></button>
  </div>
</div>
