import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { OfferCommentModule } from './offer-comment/offer-comment.module';
import { OfferDocumentModule } from './offer-document/offer-document.module';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { OfferAutoCompleteModule } from './auto-complete/offer-auto-complete.module';
import { OfferSideNavComponent } from './offer-side-nav/offer-side-nav.component';
import { SingleOfferComponent } from './single-offer.component';
import { OfferCalculationRowsComponent } from './offer-calculation-rows/offer-calculation-rows.component';
import { CalculationRowRowComponent } from './offer-calculation-rows/calculation-row-row/calculation-row-row.component';
import { OfferSummationComponent } from './offer-summation/offer-summation.component';
import { OfferTodoComponent } from './offer-todo/offer-todo.component';
import { OfferTodoRowComponent } from './offer-todo/offer-todo-row/offer-todo-row.component';
import { SignaturesComponent } from './signatures/signatures.component';
import { SignatureRowComponent } from './signatures/signature-row/signature-row.component';
import { ContractsComponent } from './contracts/contracts.component';
import { ContractsRowComponent } from './contracts/contracts-row/contracts-row.component';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationRowComponent } from './organization/organization-row/organization-row.component';
import { ProjectExternalModule } from 'app/shared/company/derome-integration/project-external.module';
import { DeromeWebshopProducts } from './derome-webshop/derome-webshop-products.component';
import { CardModule } from 'primeng/card';
import { ContactModule } from 'app/contact/contact.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { OfferSignDialogComponent } from './offer-sign-dialog/offer-sign-dialog.component';
import { OfferSignStatusComponent } from './offer-sign-status/offer-sign-status.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AutoCompleteModule,
    TableModule,
    CheckboxModule,
    TooltipModule,
    DragDropModule,
    HyperionSharedModule,
    OfferCommentModule,
    OfferDocumentModule,
    OfferAutoCompleteModule,
    ProjectExternalModule,
    CardModule,
    ContactModule,
    SplitButtonModule,
  ],
  declarations: [
    OfferCalculationRowsComponent,
    OfferSummationComponent,
    OfferTodoRowComponent,
    OfferTodoComponent,
    CalculationRowRowComponent,
    OfferSideNavComponent,
    SingleOfferComponent,
    SignaturesComponent,
    SignatureRowComponent,
    ContractsComponent,
    ContractsRowComponent,
    OrganizationComponent,
    OrganizationRowComponent,
    DeromeWebshopProducts,
    OfferSignDialogComponent,
    OfferSignStatusComponent,
  ],
  exports: [SingleOfferComponent],
})
export class SingleOfferModule {}
