<app-header>
  <app-button
    (onClick)="openAddSubcontractorDialog()"
    [type]="'showCreate'"
    [model]="'Subcontractor'"
  ></app-button>
</app-header>

<div class="page-container">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-inputgroup subcontractor-auto-complete">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <p-autoComplete
          id="subcontractor-auto-complete"
          #subcontractor_autocomplete
          [(ngModel)]="autoCompleteQuery"
          [inputStyle]="{ width: '100%' }"
          [style]="{ width: '100%', margin: '0' }"
          placeholder="Sök / lägg till underentreprenör"
          [suggestions]="autoCompleteResults | async"
          (completeMethod)="
            searchSubcontractor(subcontractor_autocomplete, $event)
          "
          (onSelect)="selectSubcontractor($event, subcontractor_autocomplete)"
          field="name"
          (click)="autoCompleteClickHandler(subcontractor_autocomplete, $event)"
          [minLength]="0"
        >
          <ng-template pTemplate="header">
            <div class="p-grid p-text-bold" style="margin: 0">
              <div class="p-col-6">Namn</div>
              <div class="p-col-6">Org. Nr</div>
            </div>
          </ng-template>
          <ng-template let-object pTemplate="item">
            <div class="p-grid" *ngIf="object.id" style="margin: 0">
              <div class="p-col-6">{{ object.name }}</div>
              <div class="p-col-6">{{ object.orgNr }}</div>
            </div>
            <div class="p-grid" *ngIf="!object.id">
              <div class="p-col-12">
                Ni har inga underentreprenörer. Tryck till höger för att lägga
                till ----->
              </div>
            </div>
          </ng-template>
        </p-autoComplete>
      </div>
    </div>

    <div class="p-col-12">
      <p>
        I den allmänna E-liggaren kan alla stämpla in även om de inte har konto.
        Då krävs en enhet på plats på arbetsplatsen.
        <br />
        Gå in på appen -> Inställningar -> Använd endast E-liggare för att öppna
        den allmänna E-liggaren i appen.
      </p>
      <br />
      <p>
        Som administratör kan du under denna flik välja att lägga in ett
        företags anställda i förväg.
        <br />
        Börja med att skapa företag. Välj sedan företaget och lägg till
        personer.
      </p>
    </div>

    <div class="p-col-12"></div>
  </div>

  <div>
    <subcontractor-edit
      *ngIf="selectedSubcontractor"
      [subcontractorData]="selectedSubcontractor"
      (subcontractorUpdated)="subcontractorUpdatedHandle($event)"
      (subcontractorDeleted)="subcontractorDeletedHandle()"
    ></subcontractor-edit>
  </div>
</div>
