<app-header>
  <button
    pButton
    class="p-button-primary-hyperion"
    label="Skapa tidrapport"
    icon="pi pi-plus"
    (click)="isDayFormVisible = true"
  ></button>
</app-header>

<div class="page-container">
  <div class="p-grid print-no-show">
    <div class="p-col-12 p-grid p-ai-center">
      <div
        class="p-col-12 p-lg-3"
        *ngIf="meUser?.type === '3' || useNotarized"
        tooltipPosition="top"
        pTooltip="Välj person / visa alla"
      >
        <label>Välj Person(er)</label>
        <p-multiSelect
          [options]="usersDropdown"
          [(ngModel)]="showDaysForUsers"
          defaultLabel="Välj person(er)"
          [style]="{ width: '100%' }"
          (onChange)="changeUsers()"
          selectedItemsLabel="{0} valda personer"
          [overlayOptions]="{ appendTo: 'body' }"
        ></p-multiSelect>
      </div>
      <div class="p-col-12 p-lg-2" tooltipPosition="top" pTooltip="Välj år">
        <label>Välj år</label>

        <p-dropdown
          [(ngModel)]="advancedVariables.checkedPeriodYearValue"
          (ngModelChange)="changeYear()"
          [options]="yearsDropdown"
        ></p-dropdown>
      </div>
      <div class="p-col-12 p-lg-3" tooltipPosition="top" pTooltip="Välj vecka">
        <label>Välj vecka</label>
        <div class="p-inputgroup">
          <button
            pButton
            class="p-inputgroup-addon"
            icon="pi pi-chevron-left"
            (click)="changeWeek('down')"
          ></button>
          <p-dropdown
            [(ngModel)]="advancedVariables.checkedPeriodTypeValue"
            [options]="weeksDropdown"
          ></p-dropdown>
          <button
            class="p-inputgroup-addon"
            pButton
            icon="pi pi-chevron-right"
            (click)="changeWeek('up')"
          ></button>
        </div>
      </div>
      <div class="p-col-12 p-lg-4 align-left">
        <label></label>
        <button
          pButton
          [disabled]="loading | async"
          (click)="getDays()"
          label="Visa Översikt"
          style="display: flex"
        ></button>
      </div>
    </div>
  </div>

  <div class="p-grid advanced-body-section">
    <p-card class="p-col-12 p-grid">
      <div class="p-col-12">
        <div class="table-container">
          <div
            *ngIf="(beforeFirst | async) && !(loading | async)"
            class="align-center"
          >
            <!-- Gör val ovan för att visa överblick -->
            <app-emptyState model="Day"></app-emptyState>
          </div>
          <spinner *ngIf="loading | async"></spinner>
          <table
            #table
            *ngIf="!(beforeFirst | async) && !(loading | async)"
            class="holder-table"
          >
            <thead>
              <tr>
                <th style="width: 21%">Projekt</th>
                <th style="width: 10%" *ngFor="let date of dates">
                  <div [class.weekend]="date.type === 'weekend'">
                    {{ date.name }}
                  </div>
                  <div [class.weekend]="date.type === 'weekend'">
                    {{ date.date }}
                  </div>
                </th>
                <th style="width: 9%">
                  <div>
                    <br />Summa vecka
                    {{ advancedVariables.checkedPeriodTypeValue }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <div *ngFor="let userData of dataSet">
                <tr>
                  <td colspan="9" class="align-center strong-text">
                    {{ userData.user.firstName }}
                    {{ userData.user.lastName }}
                  </td>
                </tr>
                <tr *ngFor="let project of userData.projectsArray">
                  <td style="width: 21%">
                    {{ project.data.trueId }}, {{ project.data.mark }}
                  </td>
                  <td
                    class="align-center"
                    style="width: 10%"
                    *ngFor="let date of dates"
                  >
                    <div
                      *ngIf="
                        returnHasOwnPropertyDay(project.dates[date['date']])
                      "
                    >
                      <div
                        *ngFor="let day of project.dates[date.date].days"
                        class="inner-day"
                      >
                        <div [class.has-error]="!day.error.status">
                          <div *ngIf="!day.error.status">
                            <i
                              class="fa fa-exclamation-triangle"
                              pTooltip="<p><strong>Dagen sparades inte</strong></p><p>{{
                                day.error.msg
                              }}</p>"
                              tooltipPosition="bottom"
                              [escape]="false"
                            ></i>
                          </div>
                          <div *ngIf="day.class === 'DayLeave'">
                            <!-- fix better tooltips -->
                            <div>
                              <span
                                pTooltip="<p><strong>Utfört arbete</strong></p><p>{{
                                  day.day.doneWork
                                }}</p>"
                                tooltipPosition="bottom"
                                [escape]="false"
                                >{{ day.day.hours }}</span
                              >
                              <span
                                *ngIf="useNotarized"
                                class="pull-right"
                                pTooltip="<p>Markera som attesterad</p>"
                                tooltipPosition="bottom"
                                [escape]="false"
                                ><p-checkbox
                                  [binary]="true"
                                  [(ngModel)]="day.isNotarized"
                                  (onChange)="day.touched = true"
                                ></p-checkbox
                              ></span>
                            </div>
                            <!-- notarixed User -->
                          </div>
                          <div *ngIf="day.class === 'Day'">
                            <div *ngIf="useNotarized">
                              <table class="inner-table">
                                <tr>
                                  <td>
                                    <span
                                      pTooltip="<p>Timmar</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      >H</span
                                    >
                                  </td>
                                  <td class="td-print-toggle">
                                    <input
                                      type="text"
                                      [(ngModel)]="day.day.hours"
                                      (focus)="day.touched = true"
                                      pTooltip="<p>Editera Timmar</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                    />
                                  </td>
                                  <td class="pull-right td-print-toggle">
                                    <input
                                      type="text"
                                      [(ngModel)]="day.day.hoursToInvoice"
                                      (focus)="day.touched = true"
                                      pTooltip="<p>Editera fakturerbara timmar</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                    />
                                  </td>
                                  <td class="td-print-toggle-on" colspan="2">
                                    <span
                                      >{{ day.day.hours }} /
                                      {{ day.day.hoursToInvoice }}</span
                                    >
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span
                                      pTooltip="<p>Mil</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      >M</span
                                    >
                                  </td>
                                  <td class="td-print-toggle">
                                    <input
                                      type="text"
                                      [(ngModel)]="day.day.mile"
                                      (focus)="day.touched = true"
                                      pTooltip="<p>Editera mil</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                    />
                                  </td>
                                  <td class="td-print-toggle">
                                    <input
                                      class="pull-right"
                                      type="text"
                                      [(ngModel)]="day.day.mileToInvoice"
                                      (focus)="day.touched = true"
                                      pTooltip="<p>Editera fakturerbara mil</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                    />
                                  </td>
                                  <td class="td-print-toggle-on" colspan="2">
                                    <span
                                      >{{ day.day.mile }} /
                                      {{ day.day.mileToInvoice }}</span
                                    >
                                  </td>
                                </tr>

                                <tr class="notarize-tr">
                                  <td>
                                    <span
                                      pTooltip="<p><strong>Utfört arbete</strong></p><p>{{
                                        day.day.doneWork
                                      }}</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      ><i
                                        class="fa fa-align-justify print-no-show"
                                      ></i
                                    ></span>
                                  </td>
                                  <td class="align-center">
                                    <span>{{ day.notarizedUser }}</span>
                                  </td>
                                  <td class="pull-right">
                                    <span
                                      pTooltip="<p>Markera som attesterad</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      ><p-checkbox
                                        [binary]="true"
                                        [(ngModel)]="day.isNotarized"
                                        (onChange)="day.touched = true"
                                      ></p-checkbox
                                    ></span>
                                  </td>
                                </tr>
                                <tr *ngIf="day.day.subsistenceDay === '1'">
                                  <td colspan="3" class="subsistence-cell">
                                    Heldagstraktamente
                                  </td>
                                </tr>
                                <tr *ngIf="day.day.subsistenceHalfDay === '1'">
                                  <td colspan="3" class="subsistence-cell">
                                    Halvdagtraktamente
                                  </td>
                                </tr>
                                <tr *ngIf="day.day.subsistenceNight === '1'">
                                  <td colspan="3" class="subsistence-cell">
                                    Natttraktamente
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div *ngIf="!useNotarized">
                              <span
                                pTooltip="<p><strong>Utfört arbete</strong></p><p>{{
                                  day.day.doneWork
                                }}</p>"
                                tooltipPosition="bottom"
                                [escape]="false"
                                >{{ day.day.hours }} /
                                {{ day.day.hoursToInvoice }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style="width: 9%"></td>
                </tr>
                <tr>
                  <td style="width: 21%">Totalt</td>
                  <td
                    style="width: 10%"
                    class="align-center"
                    *ngFor="let date of dates"
                  >
                    <span
                      pTooltip="<p><strong>Totalt {{
                        date.date
                      }}</strong></p><p>Arbetstid / Fakturerbar tid</p>"
                      tooltipPosition="bottom"
                      [escape]="false"
                      >{{ userData.dateSum[date['date']] }} /
                      {{ userData.dateSumhoursToInvoice[date['date']] }}</span
                    >
                  </td>
                  <td style="width: 9%">
                    <span
                      pTooltip="<p>Summa för {{ userData.user.firstName }} {{
                        userData.user.lastName
                      }} mellan</p><p>{{ dates[0].date }} och {{
                        dates[dates.length - 1].date
                      }}</p><p>Arbetstid / Fakturerbar tid</p>"
                      tooltipPosition="bottom"
                      [escape]="false"
                      >= {{ userData.total.dateSumTotal }} /
                      {{ userData.total.dateSumhoursToInvoiceTotal }}</span
                    >
                    <!--<div class="body">
                                            <p>Summa för {{userData.user.firstName}} {{userData.user.lastName}} mellan</p>
                                            <p>{{dates[0].date}} och {{dates[dates.length - 1].date}}</p>
                                            <p>Arbetstid / Fakturerbar tid</p>
                                        </div>-->
                  </td>
                </tr>
              </div>
            </tbody>
          </table>
        </div>
        <div
          *ngIf="
            !(beforeFirst | async) && !(loading | async) && dataSet.length > 0
          "
          class="p-col-12"
        >
          <button
            pButton
            *ngIf="useNotarized"
            class="p-mr-2 p-button-primary-hyperion"
            icon="pi pi-save"
            label="Attestera valda tidrapporter"
            [disabled]="loading | async"
            (click)="saveRequest()"
          ></button>

          <button
            pButton
            [disabled]="loading | async"
            (click)="printTable()"
            label="Spara pdf"
            icon="pi pi-file-pdf"
          ></button>
        </div>
      </div>
    </p-card>
  </div>
  <p-sidebar
    showCloseIcon="true"
    [(visible)]="isDayFormVisible"
    position="right"
  >
    <h1>Skapa tidrapport</h1>
    <app-day-form
      [productsEnabled]="true"
      [storeLastUsedProject]="false"
    ></app-day-form>
  </p-sidebar>
</div>
