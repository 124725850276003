import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MoveOfferToStatusMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  state?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type MoveOfferToStatusMutation = { __typename?: 'HyperionSchemaMutation', moveOfferToStatusMutation?: { __typename?: 'Offer', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type FetchSingleOfferForListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchSingleOfferForListQuery = { __typename?: 'HyperionSchemaQuery', offer?: { __typename?: 'Offer', id: string, trueId?: string | null, zignedId?: string | null, isDigitallySigned?: string | null, created?: string | null, status?: number | null, typeOffer?: number | null, groupCosts?: number | null, concerning?: string | null, state?: number | null, sended?: number | null, isLocked?: boolean | null, invoicedAmount?: string | null, sumMaterial?: string | null, sumWork?: string | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, mail?: string | null, address?: string | null } | null, latestSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null, sentSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null } | null };

export type FetchAtasQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchAtasQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', atas?: { __typename?: 'OfferConnection', edges?: Array<{ __typename?: 'OfferEdge', node?: { __typename?: 'Offer', id: string, trueId?: string | null, zignedId?: string | null, isDigitallySigned?: string | null, created?: string | null, status?: number | null, typeOffer?: number | null, groupCosts?: number | null, concerning?: string | null, state?: number | null, sended?: number | null, isLocked?: boolean | null, invoicedAmount?: string | null, sumMaterial?: string | null, sumWork?: string | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, mail?: string | null, address?: string | null } | null, latestSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null, sentSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null } | null } | null> | null } | null } | null };

export type FetchOffersQueryVariables = Types.Exact<{
  status?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
}>;


export type FetchOffersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', offers?: { __typename?: 'OfferConnection', totalCount: number, edges?: Array<{ __typename?: 'OfferEdge', node?: { __typename?: 'Offer', id: string, trueId?: string | null, zignedId?: string | null, isDigitallySigned?: string | null, created?: string | null, status?: number | null, typeOffer?: number | null, groupCosts?: number | null, concerning?: string | null, state?: number | null, sended?: number | null, isLocked?: boolean | null, invoicedAmount?: string | null, sumMaterial?: string | null, sumWork?: string | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, mail?: string | null, address?: string | null } | null, latestSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null, sentSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null } | null } | null> | null } | null } | null };

export type OfferInformationFragment = { __typename?: 'Offer', id: string, trueId?: string | null, zignedId?: string | null, isDigitallySigned?: string | null, created?: string | null, status?: number | null, typeOffer?: number | null, groupCosts?: number | null, concerning?: string | null, state?: number | null, sended?: number | null, isLocked?: boolean | null, invoicedAmount?: string | null, sumMaterial?: string | null, sumWork?: string | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, clientContact?: { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, mail?: string | null, address?: string | null } | null, latestSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null, sentSigningEvent?: { __typename?: 'SigningEventLog', created?: string | null } | null };

export type ClientInformationFragment = { __typename?: 'Contact', id: string, name?: string | null, orderBuisnessName?: string | null, mail?: string | null, address?: string | null };

export const ClientInformationFragmentDoc = gql`
    fragment ClientInformation on Contact {
  id
  name
  orderBuisnessName
  mail
  address
}
    `;
export const OfferInformationFragmentDoc = gql`
    fragment OfferInformation on Offer {
  id
  trueId
  zignedId
  isDigitallySigned
  created
  status
  typeOffer
  groupCosts
  user {
    id
    firstName
    lastName
  }
  clientContact {
    ...ClientInformation
  }
  concerning
  state
  sended
  isLocked
  invoicedAmount
  sumMaterial
  sumWork
  latestSigningEvent {
    created
  }
  sentSigningEvent {
    created
  }
}
    ${ClientInformationFragmentDoc}`;
export const MoveOfferToStatusDocument = gql`
    mutation moveOfferToStatus($id: Int, $state: Int) {
  moveOfferToStatusMutation(offerId: $id, state: $state) {
    id
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MoveOfferToStatusGQL extends Apollo.Mutation<MoveOfferToStatusMutation, MoveOfferToStatusMutationVariables> {
    document = MoveOfferToStatusDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSingleOfferForListDocument = gql`
    query fetchSingleOfferForList($id: Int) {
  offer(id: $id) {
    ...OfferInformation
  }
}
    ${OfferInformationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSingleOfferForListGQL extends Apollo.Query<FetchSingleOfferForListQuery, FetchSingleOfferForListQueryVariables> {
    document = FetchSingleOfferForListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchAtasDocument = gql`
    query fetchAtas($projectId: Int) {
  project(id: $projectId) {
    atas {
      edges {
        node {
          ...OfferInformation
        }
      }
    }
  }
}
    ${OfferInformationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchAtasGQL extends Apollo.Query<FetchAtasQuery, FetchAtasQueryVariables> {
    document = FetchAtasDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchOffersDocument = gql`
    query fetchOffers($status: [Int]) {
  company {
    offers(status: $status) {
      totalCount
      edges {
        node {
          ...OfferInformation
        }
      }
    }
  }
}
    ${OfferInformationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchOffersGQL extends Apollo.Query<FetchOffersQuery, FetchOffersQueryVariables> {
    document = FetchOffersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }