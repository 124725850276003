import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { MessageService } from '../../../shared/message/index';
import { GlobalService } from '../../../shared/global/index';
import { HelperService } from '../../../shared/helpers/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../../shared/apollo/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'companyQualityStamps-settings',
  templateUrl: 'company-quality-stamps.component.html',
})
export class CompanyQualityStampsComponent implements OnInit, OnDestroy {
  @Input() isModal = false; // Dont show hyperion-card style if in modal
  @Input() showTitle = 'titleRow';

  loading = true;
  dataModel = 'companyQualitystamp';
  dataSet = [];
  createDataObjectArray = [0];
  componentPrefix;
  urlPrefix;
  tableColums = [
    { header: 'Bild', width: '50%' },
    { header: 'Visa på faktura', width: '20%' },
    { header: 'Visa på offert', width: '20%' },
    { header: 'Ta bort', width: '10%' },
  ];
  companyQalityStampSub: Subscription;

  constructor(
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.getCompanyQualityStamps();
  }

  setBools() {
    const setBoolValue = {
      1: true,
      0: false,
    };

    for (const index in this.dataSet) {
      const object = this.dataSet[index];
      object['offerBool'] = setBoolValue['' + object['offer']];
      object['invoiceBool'] = setBoolValue['' + object['invoice']];
    }
  }

  onUpload(event) {
    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    if (dataAsJSON['status'] === 'success') {
      this.getCompanyQualityStamps();
    }

    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: dataAsJSON['status'],
    });
  }

  onCheckboxChange(object, type, event) {
    const dataToMutation = {};

    const objectValue = +event.checked;

    dataToMutation['id'] = Number(object.id);
    dataToMutation[type] = objectValue;

    this.actionUpdate(dataToMutation);
  }

  actionUpdate(dataToMutationParam) {
    const crudType = 'update';
    const dataToMutation = dataToMutationParam;

    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  confirmActionDelete(dataToMutationParam) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort bilden?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete(dataToMutationParam);
      },
      reject: () => {},
    });
  }

  private actionDelete(dataToMutationParam: any) {
    const crudType = 'delete';
    const dataToMutation = { id: Number(dataToMutationParam) };
    const refetchArr = [{ name: this.dataModel, variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.loading = true;
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  getCompanyQualityStamps() {
    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel)
      .subscribe(({ data, sub }) => {
        this.companyQalityStampSub = sub;
        this.loading = false;
        this.dataSet = this.helperService.cleanFromNode(
          data.company.qualityStamps
        );
        this.setBools();
      });
  }

  ngOnDestroy() {
    this.companyQalityStampSub && this.companyQalityStampSub.unsubscribe();
  }
}
