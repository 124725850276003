import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteImageMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeleteImageMutation = { __typename?: 'HyperionSchemaMutation', pictureCommentTypeHyperionMutation?: { __typename?: 'PictureComment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export type SetDisplayOnPrintMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  displayOnPrint?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type SetDisplayOnPrintMutation = { __typename?: 'HyperionSchemaMutation', pictureCommentTypeHyperionMutation?: { __typename?: 'PictureComment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export type UpdateImageCommentMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  comment?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateImageCommentMutation = { __typename?: 'HyperionSchemaMutation', pictureCommentTypeHyperionMutation?: { __typename?: 'PictureComment', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null } | null> | null } | null };

export const DeleteImageDocument = gql`
    mutation deleteImage($id: Int) {
  pictureCommentTypeHyperionMutation(deletePictureComment: {id: $id}) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteImageGQL extends Apollo.Mutation<DeleteImageMutation, DeleteImageMutationVariables> {
    document = DeleteImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetDisplayOnPrintDocument = gql`
    mutation setDisplayOnPrint($id: Int, $displayOnPrint: Int) {
  pictureCommentTypeHyperionMutation(
    updatePictureComment: {id: $id, displayOnPrint: $displayOnPrint}
  ) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetDisplayOnPrintGQL extends Apollo.Mutation<SetDisplayOnPrintMutation, SetDisplayOnPrintMutationVariables> {
    document = SetDisplayOnPrintDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateImageCommentDocument = gql`
    mutation updateImageComment($id: Int, $comment: String) {
  pictureCommentTypeHyperionMutation(
    updatePictureComment: {id: $id, comment: $comment}
  ) {
    mutationDetails {
      mutationSucceeded
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateImageCommentGQL extends Apollo.Mutation<UpdateImageCommentMutation, UpdateImageCommentMutationVariables> {
    document = UpdateImageCommentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }