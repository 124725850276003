import { Injectable } from '@angular/core';
import {
  SplitService,
  LocalhostFromObject,
} from '@splitsoftware/splitio-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfigService, EnvConfigType } from './config/config.service';

@Injectable({
  providedIn: 'root',
})
export class SplitIoService {
  /**
   * This is a map of mocked features you want to test on localhost
   */
  public localhostFeatures: SplitIO.MockedFeaturesMap = {
    zigned: 'on',
    show_company_seats: 'on',
  };
  public sdkReady = false;

  constructor(
    private splitService: SplitService,
    private configService: ConfigService
  ) {}

  public initPlugin(): void {
    const companyId = localStorage.getItem('MEcompanyId');

    if (!companyId || this.sdkReady) {
      return;
    }

    const isLocalhost =
      this.configService.envConfigType === EnvConfigType.Local;
    const authorizationKey =
      isLocalhost && this.localhostFeatures
        ? 'localhost'
        : this.configService.getEnvConfig().splitIoAuthKey;

    const sdkConfig: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey,
        key: companyId,
      },
    };

    if (isLocalhost && this.localhostFeatures) {
      sdkConfig.features = this.localhostFeatures;
      sdkConfig.sync = {
        localhostMode: LocalhostFromObject(),
      };
    }

    this.splitService.init(sdkConfig).subscribe(() => {
      this.sdkReady = this.splitService.isSDKReady;
    });
  }

  public getTreatment(
    featureName: string,
    attributes?: SplitIO.Attributes
  ): Observable<SplitIO.Treatment> {
    return this.splitService.sdkReady$.pipe(
      take(1),
      map(() => this.splitService.getTreatment(featureName, attributes))
    );
  }
}
