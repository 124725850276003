import {
  Component,
  forwardRef,
  Input,
  Injector,
  OnInit,
  AfterContentInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  NgModel,
} from '@angular/forms';
import { Dropdown } from 'primeng/dropdown';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-wide-dropDown',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WideFormDropDown2Component),
      multi: true,
    },
  ],
  template: `
    <div
      class="p-grid form-group"
      [class.has-danger]="control && control.showErrors"
    >
      <div class="p-col-12 p-md-3">
        <div class="wide-label">
          <label
            class="wide-label"
            *ngIf="!showToolTip"
            style="display:inline"
            [innerHTML]="htmlAttributes['label']['value'] || control.label"
          ></label>
          <label class="wide-label" *ngIf="showToolTip" style="display:inline"
            >{{ htmlAttributes['label']['value'] || control.label }}
            <span
              class="{{ tooltip['class'] }}"
              [tooltipStyleClass]="tooltip['tooltipStyleClass']"
              [tooltipPosition]="tooltip['tooltipPosition']"
              [pTooltip]="tooltip['msg']"
              [escape]="false"
            >
              ?</span
            >
          </label>
        </div>
      </div>
      <div class="p-col-12 p-md-9">
        <div class="p-inputgroup">
          <button
            pButton
            *ngIf="dropDownParams['leftIcon']"
            type="button"
            [disabled]="dropDownParams['disabled'] || disabled"
            [icon]="dropDownParams['leftIcon']"
            (click)="triggerLeftFunc()"
            class="p-button-secondary"
          ></button>
          <p-dropdown
            #dropdown
            [options]="dropDownParams['options']"
            [filter]="dropDownParams['filter']"
            [filterBy]="dropDownParams['filterBy'] || 'label'"
            [styleClass]="dropDownParams['styleClass'] || 'form-control'"
            [(ngModel)]="value"
            [group]="dropDownParams['group'] || false"
            [placeholder]="dropDownParams['placeholder'] || null"
            [disabled]="dropDownParams['disabled'] || disabled"
            (onBlur)="onTouched()"
            (onChange)="pushChanges($event.value)"
            (onShow)="onShow($event)"
            [attr.id]="htmlAttributes['input']['id']"
            [attr.form-control-name]="control['name']"
            [style]="
              control.showErrors
                ? { border: 'solid 1px #f25858', width: '100%' }
                : { width: '100%' }
            "
          ></p-dropdown>
          <button
            pButton
            *ngIf="dropDownParams['rightIcon']"
            type="button"
            [disabled]="dropDownParams['disabled'] || disabled"
            [icon]="dropDownParams['rightIcon']"
            (click)="triggerRightFunc()"
            class="p-button-secondary"
          ></button>
        </div>
        <div *ngIf="control.showErrors" class="vendium-tool-tip">
          <p
            *ngFor="let error of control.mergedErrors"
            class="form-control-feedback"
          >
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="error"></span>
          </p>
        </div>
      </div>
    </div>
  `,
})
export class WideFormDropDown2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @Input() public dropDownParams: any;

  @Output() public show: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown') public dropdownElement: Dropdown;

  value: NgModel;
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled: boolean;
  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** bubbles up the onShow event */
  public onShow(event: any): void {
    this.show.emit(event);
  }

  /** triggers the hide functio nin the parent */
  public hide(event: any): void {
    if (this.dropdownElement.hide) {
      this.dropdownElement.hide();
    }
  }
}
