import { Component, Input } from '@angular/core';
import { List } from 'immutable';
import { DocumentFolder } from '../document-metadata';

@Component({
  selector: 'app-docu-folder-list',
  templateUrl: './docu-folder-list.component.html',
  styleUrls: ['./docu-folder-list.component.scss'],
})
export class DocuFolderListComponent {
  @Input() docuRoot: string;
  @Input() folders: List<DocumentFolder>;
}
