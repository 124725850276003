<p-table
  [value]="dataSet"
  #supplierInvoiceTable
  [rowTrackBy]="helperService.rowTrackBy"
  dataKey="id"
  [paginator]="dataSet.length > 50"
  [rowsPerPageOptions]="[50, 100, 250, 500]"
  [rows]="50"
  paginatorPosition="both"
>
  <ng-template pTemplate="header">
    <tr>
      <th>Faktura</th>
      <th>Leverantör</th>
      <th style="width: 5rem">Visa</th>
      <th style="width: 5rem">Hämta</th>
      <th>Dokument</th>
      <th>Spara faktura till projekt</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-expanded="expanded">
    <tr>
      <td [pRowToggler]="rowData">{{ rowData['fakturaNr'] }}</td>
      <td class="p-text-truncate" [pRowToggler]="rowData">
        {{ rowData['supplier']['orderBuisnessName'] }}
      </td>
      <td>
        <i
          class="fa fa-file-o link"
          (click)="openDocument(rowData.id)"
          pTooltip="Förhandsgranska"
          tooltipPosition="top"
        ></i>
      </td>
      <td>
        <a [href]="urlPrefix + '/invoice/Print/type/getPDF/id/' + rowData.id">
          <i class="fa fa-download" pTooltip="Hämta" tooltipPosition="top"></i>
        </a>
      </td>
      <td>
        <div
          *ngFor="
            let sourceDoc of rowData.invoiceSourceDocuments_PreInvoiceTypeHyperion
          "
        >
          <span
            class="link"
            style="margin-right: 3px"
            (click)="openSourcedocument(sourceDoc.url)"
            >{{ sourceDoc.label }}</span
          >
        </div>
      </td>
      <td>
        <span *ngIf="rowData.allRowsMovedToProjectProduct"
          >Alla rader är flyttade</span
        >
        <div class="p-grid" *ngIf="!rowData.allRowsMovedToProjectProduct">
          <p-dropdown
            class="p-col-8"
            tooltipPosition="top"
            pTooltip="Välj materialtyp"
            [(ngModel)]="rowData.companyCostTypes"
            [options]="functionsData.companyCostTypes"
            appendTo="body"
            filter="true"
          ></p-dropdown>
          <button
            (click)="saveInvoiceToProject(rowData)"
            class="p-col-4"
            pButton
            icon="pi pi-save"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template
    let-invoice
    let-rowData
    pTemplate="rowexpansion"
    class="expanded-row"
  >
    <tr class="p-table-expanded-row">
      <td [attr.colspan]="6">
        <h4>Fakturarader</h4>
        <p-table
          [value]="invoice.rows"
          styleClass="supplier-invoice-table"
          [rowTrackBy]="helperService.rowTrackBy"
          [sortField]="sort.attribute"
          [sortOrder]="sort.ascDesc"
          (onSort)="changeSort($event)"
          [rowHover]="false"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="benamning" style="width: auto">
                Benämning <p-sortIcon field="benamning"></p-sortIcon>
              </th>
              <th pSortableColumn="antal" style="width: 7rem">
                Antal <p-sortIcon field="antal"></p-sortIcon>
              </th>
              <th pSortableColumn="apris" style="width: 7rem">
                A`-pris <p-sortIcon field="apris"></p-sortIcon>
              </th>
              <th pSortableColumn="discountOriginal" style="width: 7.5rem">
                <div
                  class="p-d-inline-block"
                  style="white-space: break-spaces; width: 4rem"
                >
                  Rabatt lev.
                </div>
                <p-sortIcon field="discountOriginal"></p-sortIcon>
              </th>
              <th pSortableColumn="pris" style="width: 7.5rem">
                <div
                  class="p-d-inline-block"
                  style="white-space: break-spaces; width: 4rem"
                >
                  Summa Lev.
                </div>
                <p-sortIcon field="pris"></p-sortIcon>
              </th>
              <th pSortableColumn="discount" style="width: 7.5rem">
                <div
                  class="p-d-inline-block"
                  style="white-space: break-spaces; width: 4rem"
                >
                  Rabatt A`-pris
                  <span
                    class="help-tooltip"
                    tooltipPosition="top"
                    [pTooltip]="
                      'Detta fält fylls i om du vill ge din kund en rabatt på leverantörens listpris'
                    "
                    [escape]="false"
                    >?</span
                  >
                </div>
                <p-sortIcon field="discount"></p-sortIcon>
              </th>
              <th pSortableColumn="prisWithDiscount" style="width: 7.5rem">
                <div
                  class="p-d-inline-block"
                  style="white-space: break-spaces; width: 4rem"
                >
                  Summa Kund
                </div>
                <p-sortIcon field="prisWithDiscount"></p-sortIcon>
              </th>
              <th pSortableColumn="extra" style="width: 10.5rem">
                Normal/Extra <p-sortIcon field="extra"></p-sortIcon>
              </th>
              <th pSortableColumn="companyCosttypeId" style="width: 11rem">
                Materialtyp
                <p-sortIcon field="companyCosttypeId"></p-sortIcon>
              </th>
              <th
                pSortableColumn="isMovedToProjectProductId"
                style="width: 6rem"
              >
                Spara
                <p-sortIcon field="isMovedToProjectProductId"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="white-space: break-spaces">
                {{ rowData['benamning'] }}
              </td>
              <td>
                <span>{{ rowData.antal }}</span>
                <span>{{ rowData.enhet }}</span>
              </td>
              <td>{{ rowData['apris'] }}</td>
              <td>{{ rowData.discountOriginal }}%</td>
              <td>{{ rowData['pris'] }}</td>
              <td>
                <input
                  *ngIf="rowData.isMovedToProjectProductId < 1"
                  [(ngModel)]="rowData.discount"
                  (change)="updatePris(rowData)"
                  style="width: 50%"
                  placeholder="%"
                />%
              </td>
              <td>{{ rowData['prisWithDiscount'] }}</td>
              <td>
                <appForm2-list-dropDown
                  [(ngModel)]="rowData.extra"
                  *ngIf="rowData.isMovedToProjectProductId < 1"
                  [dropDownParams]="{ options: extraDropdown }"
                ></appForm2-list-dropDown>
              </td>
              <td>
                <appForm2-list-dropDown
                  [(ngModel)]="rowData.companyCosttypeId"
                  *ngIf="rowData.isMovedToProjectProductId < 1"
                  [dropDownParams]="{
                    options: functionsData.companyCostTypes,
                    filter: true
                  }"
                ></appForm2-list-dropDown>
              </td>
              <td>
                <span
                  *ngIf="!(rowData.isMovedToProjectProductId < 1)"
                  style="font-weight: 600"
                  >Flyttad</span
                >
                <span *ngIf="rowData.isMovedToProjectProductId < 1">
                  <p-checkbox
                    [(ngModel)]="rowData.move"
                    [binary]="true"
                  ></p-checkbox>
                </span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td [attr.colspan]="9">
                <app-emptyState></app-emptyState>
              </td>
            </tr>
          </ng-template>
        </p-table>

        <div style="padding: 10px 0">
          <app-button
            style="float: right"
            (onClick)="saveMarkedRows(invoice)"
            [disabled]="invoice.allRowsMovedToProjectProduct"
            [type]="'save'"
          ></app-button>
          <div class="clear-both"></div>
        </div>
        <hr />
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr class="emptyStateTR">
      <td [attr.colspan]="6">
        <app-emptyState [model]="'Invoice'"></app-emptyState>
      </td>
    </tr>
  </ng-template>
</p-table>
