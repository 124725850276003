import { Config } from '../config';

export const config: Config = {
  name: 'default',
  subdomain: [],
  supportPhone: '031 - 300 65 20',
  supportMail: 'support@struqtur.se',
  economyMail: 'ekonomi@struqtur.se',
  title: 'Struqtur',
  theme: {
    primaryColor100: '#f5f5f5',
    primaryColor200: '#e6e6e6',
    primaryColor300: '#d7d7d7',
    primaryColor400: '#a8a8a8',
    primaryColor500: '#787878',
    primaryColor600: '#190a2d',
    linkColor: '#2c9157',
    loginBackground: '/images/hyperion/bg5.jpg',
    brandPrimaryColor: '#7814d7',
    brandSecondaryColor: '#efe5ff',
    topMenuColor: '#ffffff',
    topMenuHoverColor: '#ffffff',
    topMenuBackgroundColor: '#7814d7',
    topMenuBackgroundActiveColor: 'transparent',
    topMenuLogoMaxHeight: '40px',
    topMenuBoxShadowColor: 'rgba(0, 0, 0, 0.2)',
    topMenuTextTransform: 'none',
    topMenuFontWeight: '600',
    homeStartDarkColor: '#f5f5f5',
    homeStartLightColor: '#f5f5f5',
    buttonPrimaryColor: '#2c9157',
    buttonPrimaryColorDark: '#0f743a',
    buttonSuccessColor: '#2c9157',
    buttonInfoColor: '#3b68ff',
    buttonWarningColor: '#ffe450',
    buttonDangerColor: '#e34934',
    logoPath: '/images/site/logo.svg',
    iconPath: '/images/logo/Struqtur_Icon_RGB-White.svg',
    favicons: [],
    headerFontFamily: 'Gruffy',
    fontFamily: 'Plain',
    headerWeight: 'normal',
    invert: false,
    workDiagramColor: '#3b68ff',
    workNotInvoicedDiagramColor: '#6b98ff',
    materialDiagramColor: '#9ad9e5',
    materialNotInvoicedDiagramColor: '#bae9f5',
    mileageDiagramColor: '#001764',
    mileageNotInvoicedDiagramColor: '#506794',
    overheadDiagramColor: '#455ca9',
  },
};
