<div class="p-grid">
  <div class="p-grid p-col-12 p-g-nopad show-md labels-no-margin">
    <div class="p-col-12 p-md-5">
      <label
        class="form-control-label"
        [innerHTML]="labels['OfferContractor']['person']"
      ></label>
    </div>
    <div class="p-col-12 p-md-5">
      <label class="form-control-label"
        >*{{ labels['OfferContractor']['buisness'] }}</label
      >
    </div>
  </div>
  <div class="p-col-12 p-g-nopad" *ngFor="let object of dataSet; let i = index">
    <app-contracts-row
      [dataObjectFromParent]="object"
      [offerInfo]="offerInfo"
      (spliceObjectNotYetCreated)="spliceRow(null, i)"
      (deletedObject)="spliceRow($event)"
      (createdObject)="setRowId($event, i)"
    ></app-contracts-row>
  </div>
  <div class="p-col-12 align-right">
    <app-button (click)="addRow()" [type]="'add'"></app-button>
  </div>
</div>
