import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { OfferCommentComponent } from './offer-comment/offer-comment.component';
import { OfferCommentHandleComponent } from './offer-comment-handle/offer-comment-handle.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionCardModule,
    LoadingModule,
    InputTextareaModule,
    HyperionSharedModule,
  ],
  declarations: [OfferCommentComponent, OfferCommentHandleComponent],
  exports: [OfferCommentComponent],
})
export class OfferCommentModule {}
