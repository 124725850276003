import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'app/shared/global';

import { MessageService } from '../../shared/message/index';

@Component({
  selector: 'project-document-upload',
  templateUrl: 'project-document-upload.component.html',
})
export class ProjectDocumentUploadComponent implements OnInit {
  private jsonData = [];
  public urlPrefix;

  constructor(
    protected messageService: MessageService,
    protected globalService: GlobalService
  ) {}
  @Input() public projectId;

  ngOnInit() {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  public onUpload(event: any): void {
    this.jsonData = [];

    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    let type = 'error';

    if (dataAsJSON['status'] === 'success') {
      this.jsonData = dataAsJSON['status'];
      type = 'success';
    }

    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: type,
    });
  }
}
