import { Injectable } from '@angular/core';

export type TablesSortable = 'project' | 'invoice' | 'supplierInvoice';

@Injectable({
  providedIn: 'root',
})
export class TableSortSettingsService {
  constructor() {}

  private generateKey(table: TablesSortable, userId: number): string {
    return `${table}-${userId}`;
  }

  public saveSortSettings(
    table: TablesSortable,
    column: string,
    order: number
  ): void {
    const userId = +localStorage.getItem('MEid');

    localStorage.setItem(
      this.generateKey(table, userId),
      JSON.stringify({ column, order })
    );
  }

  public getSortSettings(
    table: TablesSortable
  ): { column: string; order: number } | null {
    const userId = +localStorage.getItem('MEid');
    const sortSettings = localStorage.getItem(this.generateKey(table, userId));

    if (sortSettings) {
      const { column, order } = JSON.parse(sortSettings);
      return { column, order };
    }

    return null;
  }
}
