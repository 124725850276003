import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HelperService } from 'app/shared/helpers';
import { ApolloMutationService } from 'app/shared/apollo';

@Injectable()
export class AttendanceReportService {
  constructor(
    private helperService: HelperService,
    private mutationService: ApolloMutationService
  ) {}

  formatAttendanceReportProjects(data) {
    const edges: any[] =
      data['company']['attendanceReportProjects_PreCompanyTypeHyperion'][
        'edges'
      ];
    const dropDownData = [{ label: 'Välj E-liggare-projekt', value: null }];

    edges.forEach(n => {
      const node = n.node;
      const projectLabel =
        this.helperService.getProjectLabel(node) +
        ', ' +
        node.constructionSiteNumber;
      const dropdownNode = { label: projectLabel, value: {} };

      for (const key in node) {
        dropdownNode.value[key] = node[key];
      }

      dropdownNode.value['label'] = projectLabel;

      dropDownData.push(dropdownNode);
    });

    if (dropDownData.length === 1) {
      dropDownData[0].label = 'Inga projekt ännu...';
    }

    return dropDownData;
  }

  formatProjectsWithAttendanceReports(data) {
    const edges = data['company']['projects']['edges'];
    const projects = [];

    for (const index in edges) {
      const node = edges[index]['node'];
      const project = Object.assign({}, node);
      project['label'] = this.helperService.getProjectLabel(project);
      project['attendanceReports'] = [];
      const attendanceEdges = this.helperService.cleanFromNode(
        node['attendanceReportsPerformanceOptimized_PreProjectTypeHyperion']
      );

      for (const a in attendanceEdges) {
        let attendanceReport = attendanceEdges[a];

        attendanceReport = this.cleanCompleteData(attendanceReport);
        attendanceReport = this.setOldData(attendanceReport);

        project['attendanceReports'].push(attendanceReport);
      }

      delete project[
        'attendanceReportsPerformanceOptimized_PreProjectTypeHyperion'
      ];
      projects.push(project);
    }

    return projects;
  }

  formatAttendanceReportsCompleteData(data, originalAttendanceReports) {
    const edges =
      data['projectGlobal'][
        'attendanceReportsPerformanceOptimized_PreProjectTypeHyperion'
      ]['edges'];
    const attendanceReports = [...originalAttendanceReports];

    for (const edgeIndex in edges) {
      let node: any = { ...edges[edgeIndex]['node'] };
      node = this.cleanCompleteData(node);
      node = this.setOldData(node);

      const index = attendanceReports.findIndex(a => {
        return Number(a.id) === Number(node.id);
      });

      if (index !== -1) {
        let existingRow = { ...attendanceReports[index] };
        existingRow = { ...node };
        attendanceReports[index] = existingRow;
      } else {
        attendanceReports.push(node);
      }
    }

    return attendanceReports;
  }

  formatSingleAttendanceReport(data: any, originalAttendanceReports) {
    const attendanceReports = [...originalAttendanceReports];
    let node = { ...data.projectGlobal.attendanceReports.edges[0].node };

    node = this.cleanCompleteData(node);
    node = this.setOldData(node);

    attendanceReports.push(node);

    return attendanceReports;
  }

  cleanCompleteData(nodeParam) {
    const node: any = { ...nodeParam };
    node['editsCached'] = [];
    node['startEditsCached'] = [];

    node.isCanceled = node.isCanceled_PreAttendanceReportTypeHyperion;
    node.startIsCanceled =
      node.start.isCanceled_PreAttendanceReportTypeHyperion;

    node.editsCached = this.helperService.cleanFromNode(
      node.editsCached_PreAttendanceReportTypeHyperion
    );
    node.startEditsCached = this.helperService.cleanFromNode(
      node.start.editsCached_PreAttendanceReportTypeHyperion
    );

    node.editsCached = this.helperService.sortArray(node.editsCached, 'id', -1);
    node.startEditsCached = this.helperService.sortArray(
      node.startEditsCached,
      'id',
      -1
    );

    return node;
  }

  setOldData(nodeParam) {
    const node: any = { ...nodeParam };

    node.oldCompanyOrgNr = '' + node.companyOrgNr;
    node.realUser_PreAttendanceReportTypeHyperion = {
      ...node.realUser_PreAttendanceReportTypeHyperion,
      oldFirstName: node.realUser_PreAttendanceReportTypeHyperion.firstName,
      oldLastName: node.realUser_PreAttendanceReportTypeHyperion.lastName,
      oldPersonalNumber:
        node.realUser_PreAttendanceReportTypeHyperion.personalNumber,
    };

    return node;
  }

  formatProjectSubcontractorsDropdown(data: any) {
    const dropdown = [{ label: 'Visa alla...', value: null }];
    const edges =
      data.company.attendanceProjectSubcontractors_PreCompanyTypeHyperion.edges;

    for (const index in edges) {
      const node = { ...edges[index].node };

      const subcontractor = { label: node.name, value: node.orgNr };
      dropdown.push(subcontractor);
    }

    return dropdown;
  }

  updateSubcontractorProjectRelation(dataToMutation) {
    return Observable.create(observer => {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'subcontractorRelationToProject',
          'update',
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            observer.next(executedData);
            observer.complete();

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    });
  }
}
