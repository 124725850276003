import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ContactFormSelectQueryVariables = Types.Exact<{
  contactId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ContactFormSelectQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', allContacts?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, trueId?: string | null, contactParentId?: number | null, contact?: number | null, contactType?: string | null, betalningsvillkor?: number | null, orderBuisnessName?: string | null, name?: string | null, orgNr?: string | null, address?: string | null, address2?: string | null, cityCode?: string | null, city?: string | null, propertyName?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, mailInvoice?: string | null, type?: number | null, reverseTax?: number | null, hourCost?: number | null, costProcent?: number | null, plusgiro?: string | null, bankgiro?: string | null, bicSwift?: string | null, iban?: string | null, note?: string | null, isVATRegistered?: number | null } | null } | null> | null } | null } | null };

export type PaymentTermsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PaymentTermsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', betalningsvillkor?: number | null } | null };

export const ContactFormSelectDocument = gql`
    query contactFormSelect($contactId: Int) {
  company {
    allContacts(id: $contactId) {
      edges {
        node {
          id
          trueId
          contactParentId
          contact
          contactType
          betalningsvillkor
          orderBuisnessName
          name
          orgNr
          address
          address2
          cityCode
          city
          propertyName
          housingAssociationOrgNumber
          apartmentDesignation
          phone
          mobilePhone
          mail
          mailInvoice
          type
          reverseTax
          hourCost
          costProcent
          plusgiro
          bankgiro
          bicSwift
          iban
          note
          isVATRegistered
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactFormSelectGQL extends Apollo.Query<ContactFormSelectQuery, ContactFormSelectQueryVariables> {
    document = ContactFormSelectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentTermsDocument = gql`
    query paymentTerms {
  company {
    betalningsvillkor
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentTermsGQL extends Apollo.Query<PaymentTermsQuery, PaymentTermsQueryVariables> {
    document = PaymentTermsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }