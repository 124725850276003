import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { mutationDetails, GQLFragment } from '../apollo/queries';

import { UserLocalStorageService } from 'app/shared/user';

@Injectable()
export class HelperService {
  constructor(private userLocalStorageService: UserLocalStorageService) {}

  upperCaseFirstLetter = (value: string) =>
    value.charAt(0).toUpperCase() + value.slice(1);

  lowerCaseFirstLetter = (value: string) =>
    value.charAt(0).toLowerCase() + value.slice(1);

  /**
   * @deprecated Use getMutationQuery instead
   *
   * @param model the string that identifies the model
   * @param action the action performed in the mutation
   */
  getQueryString(model: string, action: string): string {
    return `mutation ${action}${this.upperCaseFirstLetter(
      model
    )}(\$${action}${this.upperCaseFirstLetter(
      model
    )}Object: ${this.upperCaseFirstLetter(
      model
    )}TypeHyperionMutationInput) {${this.lowerCaseFirstLetter(
      model
    )}TypeHyperionMutation(${action}${this.upperCaseFirstLetter(
      model
    )}: \$${action}${this.upperCaseFirstLetter(model)}Object) {`;
  }

  /**
   * Returns the Mutation Type, a string following our naming conventions for the mutation type
   *
   * @param model the string that identifies the model
   * @param action the action performed in the mutation
   */
  public getMutationType(model: string, action: string): string {
    return `${action}${this.upperCaseFirstLetter(model)}Object`;
  }

  /**
   * Returns the GQL mutation string based on the parameters passed
   * @param model the string that identifies the model
   * @param action the action performed in the mutation
   * @param queryBody the query containing the variables we want to update
   *
   * @returns the resulting gql`` query
   */
  public getMutationQuery(
    model: string,
    action: string,
    queryBody?: GQLFragment
  ): any {
    return gql`
      mutation ${this.getMutationTitle(model, action)}(
        \$${this.getMutationTitle(
          model,
          action
        )}Object: ${this.upperCaseFirstLetter(model)}TypeHyperionMutationInput
      ) {
        ${this.lowerCaseFirstLetter(model)}TypeHyperionMutation(
          ${this.getMutationTitle(model, action)}: \$${this.getMutationTitle(
      model,
      action
    )}Object
        ) {
          ${
            queryBody && queryBody.definitions[0].name.value
              ? '...' + queryBody.definitions[0].name.value
              : ''
          }
          ${mutationDetails}
        }
      }
      ${queryBody ? queryBody : ''}
    `;
  }

  private getMutationTitle(model: string, action: string): string {
    return `${action}${this.upperCaseFirstLetter(model)}`;
  }

  stripCurlyBraces = (s: string) => s.slice(1, s.length - 1);

  getQueryCarsString(vars) {
    let queryVarsString = JSON.stringify(vars);
    queryVarsString = queryVarsString.replace(/\"([^(\")"]+)\":/g, '$1:');
    return this.stripCurlyBraces(queryVarsString);
  }

  isNumeric = num => !isNaN(num);

  // Test: ['a', 'b'] => 'a b '
  joinWithEndingSpace = (values: string[]): string => values.join(' ') + ' ';

  // Test: {a: 1, b: 2} => 'a b '
  joinObjectKeys = (obj): string => this.joinWithEndingSpace(Object.keys(obj));

  // Test: {a: 1, b: 2} => '1 2 '
  joinObjectValues = (obj): string =>
    this.joinWithEndingSpace(Object['values'](obj));

  stringToCleanNumber(stringNumber): number {
    if (typeof stringNumber === 'number') {
      return stringNumber;
    }

    let stringNum;

    stringNum = stringNumber.split(' ').join('');
    stringNum = stringNum.split(',').join('.');

    if (stringNum === '') {
      stringNum = 0;
    }

    const num = Number(stringNum);

    return num;
  }

  /*
      --- Conversion of JSON
  */
  cleanFromNode(dataParam) {
    const dataArray = dataParam.edges;
    const dataArrayWithObjects = [];
    for (const object in dataArray) {
      if (typeof dataArray[object]['node'] !== 'undefined') {
        dataArrayWithObjects.push(Object.assign({}, dataArray[object]['node']));
      }
    }

    return dataArrayWithObjects;
  }

  getIdsOfDataSet(dataSet) {
    const arrayOfIds = [];

    let isArrayOfString = false;

    if (dataSet.length > 0) {
      if (typeof dataSet[0] === 'string') {
        isArrayOfString = true;
      }
    }

    for (const index in dataSet) {
      const object = dataSet[index];

      if (!isArrayOfString) {
        arrayOfIds.push(object['id']);
      } else {
        arrayOfIds.push(object);
      }
    }

    return arrayOfIds;
  }

  numberFormater(
    value,
    decimals = 2,
    decimalPoint = '.',
    thousandsSeparator = ' '
  ) {
    // *     example 1: number_format(1234.56);
    // *     returns 1: '1.235'
    // *     example 2: number_format(1234.56, 2, ',', ' ');
    // *     returns 2: '1 234,56'
    // *     example 3: number_format(1234.5678, 2, '.', '');
    // *     returns 3: '1234.57'
    // *     example 4: number_format(67, 2, ',', '.');
    // *     returns 4: '67,00'
    // *     example 5: number_format(1000);
    // *     returns 5: '1.000'
    // *     example 6: number_format(67.311, 2);
    // *     returns 6: '67.31'
    // *     example 7: number_format(1000.55, 1);
    // *     returns 7: '1.000.6'
    // *     example 8: number_format(67000, 5, ',', '.');
    // *     returns 8: '67.000,00000'
    // *     example 9: number_format(0.9, 0);
    // *     returns 9: '1'
    // *    example 10: number_format('1.20', 2);
    // *    returns 10: '1.20'
    // *    example 11: number_format('1.20', 4);
    // *    returns 11: '1.2000'
    // *    example 12: number_format('1.2000', 3);
    // *    returns 12: '1.200'
    const n = !isFinite(+value) ? 0 : +value;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep =
      typeof thousandsSeparator === 'undefined' ? ',' : thousandsSeparator;
    const dec = typeof decimalPoint === 'undefined' ? '.' : decimalPoint;
    const toFixedFix = (num, precision) => {
      // Fix for IE parseFloat(0.55).toFixed(0) = 0;
      const k = Math.pow(10, precision);
      return Math.round(num * k) / k;
    };
    const s = (prec ? toFixedFix(n, prec) : Math.round(n))
      .toString()
      .split('.');

    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
  }

  /*
      --- Table helpers
  */
  rowTrackBy(index: number, row: any) {
    return row.id;
  }

  /*
      --- Date helpers
  */

  public dateFormat = (): string => 'YYYY-MM-DD';

  public getFormatedDateTime = (date): string =>
    moment(date).format('YYYY-MM-DD HH:mm');

  public getFormattedDateTimeWithSeconds(date): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  splitDateToNumber(dateString) {
    let compareDateNumber: number;

    const compareDate = dateString;
    const compareDateSplitted = compareDate.split('-');
    let compareDateString = '';
    for (const dateChunk in compareDateSplitted) {
      compareDateString += compareDateSplitted[dateChunk];
    }
    compareDateNumber = +compareDateString;

    return compareDateNumber;
  }

  public getCurrentYear(): number {
    const date = new Date();
    return date.getFullYear();
  }

  weeksInYear(year) {
    return moment(year + '-01-01').isoWeeksInYear();
  }

  getWeekNumber(date) {
    let week: number;

    const d = new Date(+date);
    d.setHours(0, 0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    const yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    week = Math.ceil(((+d - +yearStart) / 86400000 + 1) / 7);

    return week;
  }

  parseDateSafariFriendly = (createdParam: string) =>
    createdParam.replace(/-/g, '/'); // input = YYYY-MM-DD

  parseDateWithTimeSafariFriendly = (createdParam: string) =>
    createdParam.replace(' ', 'T') /* + "+01:00" */;

  dateFormatTimeZone(date, toIsoString?) {
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    let formattedDate: any = new Date(new Date(date).getTime() - tzoffset);

    if (toIsoString) {
      formattedDate = moment(formattedDate).format(this.dateFormat());
    }
    return formattedDate;
  }

  /**
   * Formats time in the 'YYYY-MM-DD HH:mm:SS' style
   * @param date A date.
   */
  dateWithTimeFormatTimeZone(date) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  stripDateToNumber = (date: string): number =>
    +moment(date).format('YYYYMMDD');

  getDateDifference(startDate: string | Date, endDate: string | Date) {
    const diff = moment(endDate).diff(moment(startDate), 'days') + 1;

    if (isNaN(diff)) {
      throw new Error('getTime getDateDifference error');
    }
    return diff;
  }

  public getDuration(object, subtractBreakDuration = false) {
    // Objects with startDate and endDate, returns duration in hours
    let duration = 0;
    const startDateTime = new Date(
      this.parseDateWithTimeSafariFriendly(object.startDate)
    ).getTime();
    const endDateTime = new Date(
      this.parseDateWithTimeSafariFriendly(object.endDate)
    ).getTime();

    duration = Math.abs(endDateTime - startDateTime) / 36e5; // timmar

    if (subtractBreakDuration) {
      duration += -(object.breakDuration / 60);
    } // minuter

    return duration;
  }

  /*
      --- Window dimensions helpers
  */
  getOrientation() {
    const windowWidth = window.innerWidth;

    const orientation = windowWidth < 1100 ? 'top' : 'left';

    return orientation;
  }

  /*
      --- Dropdown helpers
  */
  getProjectLabel(project) {
    let projectLabel: string;

    const trueId =
      project['trueId'] !== null &&
      project['trueId'] !== 0 &&
      project['trueId'] !== '0'
        ? project['trueId']
        : '0';
    const mark =
      project['mark'] && project['mark'] !== null
        ? project['mark']
        : '(Ingen märkning)';
    projectLabel = trueId + ', ' + mark;

    return projectLabel;
  }

  formatDropdownCustomLabelKey(data, labelKey: string, nullLabel?: string) {
    const edges = data['edges'];
    const dataObjectsWithLabels = [];

    if (nullLabel) {
      const nullObject = {
        label: nullLabel,
        value: null,
      };
      dataObjectsWithLabels.push(nullObject);
    }

    for (const i in edges) {
      const dataObject = {
        // create object to push
        label: edges[i]['node'][labelKey],
        value: +edges[i]['node']['id'],
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeGenericDropdown(edges) {
    const labelsArray = [];

    for (const index in edges) {
      const nodeObject = edges[index];

      const labelsObject = {
        value: nodeObject['node']['key'],
        label: nodeObject['node']['description'],
      };

      labelsArray.push(labelsObject);
    }

    return labelsArray;
  }

  /*
      --- Sorting helpers
  */
  sortArray(arrayParam: any[], keyName: string, order: number) {
    const sortedArray = arrayParam.sort((i1, i2) => {
      if (order === 1) {
        if (i1[keyName] > i2[keyName]) {
          return 1;
        }
        if (i1[keyName] < i2[keyName]) {
          return -1;
        }
      } else {
        if (i1[keyName] > i2[keyName]) {
          return -1;
        }
        if (i1[keyName] < i2[keyName]) {
          return 1;
        }
      }

      return 0;
    });

    return sortedArray;
  }

  /**
   * Returns a new array with added or replaced items based on key
   *
   * @param arr1
   * @param arr2
   * @param key
   * @returns Array
   */
  public addOrReplaceArray = (arr1: any[], arr2: any[], key: string): any[] => {
    const result = [...arr1];
    arr2.forEach(arr2Item => {
      const itemIndex = arr1.findIndex(
        (item: any) => +item[key] === +arr2Item[key]
      );

      if (itemIndex > -1) {
        result[itemIndex] = arr2Item;
      } else {
        result.push(arr2Item);
      }
    });
    return result;
  };
}
