import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ContactFormUpdateMutationVariables = Types.Exact<{
  updateContactObject?: Types.InputMaybe<Types.ContactTypeHyperionMutationInput>;
}>;


export type ContactFormUpdateMutation = { __typename?: 'HyperionSchemaMutation', contactTypeHyperionMutation?: { __typename?: 'Contact', id: string, trueId?: string | null, contactParentId?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type ContactFormCreateMutationVariables = Types.Exact<{
  createContactObject?: Types.InputMaybe<Types.ContactTypeHyperionMutationInput>;
}>;


export type ContactFormCreateMutation = { __typename?: 'HyperionSchemaMutation', contactTypeHyperionMutation?: { __typename?: 'Contact', id: string, trueId?: string | null, contactParentId?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type MutationDetailsAttributesFragment = { __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null };

export const MutationDetailsAttributesFragmentDoc = gql`
    fragment MutationDetailsAttributes on ModelErrorsTypeHyperionBase {
  argument
  errorsMsgs
  infoMsgs
  model
  modelErrorsMsgs
  modelSuccessMsgs
  modelWarningMsgs
  msg
  mutationSucceeded
  successMsgs
  warningMsgs
}
    `;
export const ContactFormUpdateDocument = gql`
    mutation contactFormUpdate($updateContactObject: ContactTypeHyperionMutationInput) {
  contactTypeHyperionMutation(updateContact: $updateContactObject) {
    id
    trueId
    contactParentId
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactFormUpdateGQL extends Apollo.Mutation<ContactFormUpdateMutation, ContactFormUpdateMutationVariables> {
    document = ContactFormUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContactFormCreateDocument = gql`
    mutation contactFormCreate($createContactObject: ContactTypeHyperionMutationInput) {
  contactTypeHyperionMutation(createContact: $createContactObject) {
    id
    trueId
    contactParentId
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactFormCreateGQL extends Apollo.Mutation<ContactFormCreateMutation, ContactFormCreateMutationVariables> {
    document = ContactFormCreateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }