import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';

@Component({
  selector: 'create-company-division',
  templateUrl: 'company-division-create.component.html',
  providers: [FormHandlerService],
})
export class CreateCompanyDivisionComponent implements OnInit {
  @Output() dataObjectDeleted = new EventEmitter();
  @Output() public createSibling = new EventEmitter();

  componentMainForm: FormGroup;
  dataModel = 'companyDivision';
  dataModelCapitalized = 'CompanyDivision';
  formSettings;
  formFields = {
    model: this.dataModelCapitalized,
    nestedForm: false,
    attributes: { name: '' },
  };
  componentPrefix;
  buttons;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private globalService: GlobalService
  ) {
    this.formSettings = this.globalService.getFormSettings();
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      create: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };
    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.initForm();
  }

  private initForm() {
    this.formHandler.groupSetLabelsRules(this.formFields).then(groupedForm => {
      groupedForm['controls']['name']['label'] =
        'Skriv för att skapa ny divison';
      this.componentMainForm = groupedForm;
    });
  }

  actionCreate() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const crudType = 'create';
      const dataToMutation = this.componentMainForm.value;
      const refetchArr = [{ name: this.dataModel, variables: null }];

      this.buttons = this.formHandler.lockButtons(this.buttons);
      this.formHandler.setLoadingClasses('start');

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(
              executedData,
              [
                {
                  form: this.componentMainForm,
                  argument: crudType + this.dataModelCapitalized,
                },
              ] // argument = like "createCompanyCostType"
            );
            let setLoadingClassesInput;
            if (executedData.mutationSucceededAllArguments) {
              // kommer hit = hela vägen in i mål
              setLoadingClassesInput = 'success';
              this.dataObjectDeleted.emit();
            } else {
              // Klarar validering men inte hard request + Lägg till Error handling
              setLoadingClassesInput = 'error';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            this.formHandler.setLoadingClasses();
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.warn(err);
          }
        );
    }
  }
}
