<div>
  <div>
    <h2>
      Tjänster läggs till
      <i *ngIf="fakeLoading" class="pi pi-spin pi-spinner pull-right"></i>
    </h2>
  </div>
  <div>
    <p>
      Om du har frågor hittar du ett hjälpavsnittet på nästa sida<br />
      där finns även kontaktuppgifter till vår support!
    </p>
    <p *ngIf="!fakeLoading">
      <br />
      Nu är det klart!<br />
      Appen laddas om
    </p>
  </div>
</div>
