import { EventColorsEnum } from '../planner-module-enums';

export const ColorNames: { [key in EventColorsEnum]: string } = {
  '#F44336': 'Röd',
  '#E91E63': 'Rosa',
  '#374046': 'Svart',
  '#9C27B0': 'Lila',
  '#673AB7': 'Mörklila',
  '#3F51B5': 'Indigo',
  '#0288D1': 'Blå',
  '#03A9F4': 'Ljusblå',
  '#00BCD4': 'Cyan',
  '#009688': 'Teal',
  '#4CAF50': 'Grön',
  '#8BC34A': 'Ljusgrön',
  '#CDDC39': 'Lime',
  '#FFEB3B': 'Gul',
  '#FFC107': 'Amber',
  '#FF9800': 'Orange',
  '#FF5722': 'Brandgul',
  '#795548': 'Brun',
  '#9E9E9E': 'Grå',
  '#607D8B': 'Blågrå',
};
