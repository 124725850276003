<app-feature-preview
  image="/images/hyperion/feature-previews/tidrapport.jpg"
  feature="Tidrapport"
>
  <p>
    Med hjälp {{ appName }}s tidrapport kan du och dina anställda enkelt
    rapportera er tid. Detta görs oftast via vår enkla och smidiga mobilapp som
    finns både till iOS och Android.
  </p>

  <p>
    När ni rapporterar tiden kan ni även lägga till det material som ni har
    använt under dagen. All information läggs in på projektet och undlättar
    därmed både fakturering och löneutbetalningar!
  </p>

  <p>
    Vi vet att varje verksamhet inom byggbranschen ser olika ut och har olika
    behov. Därför har vi utformat {{ appName }} på ett flexibelt sätt för att
    säkerställa att vi kan stödja just din organisation. Börja tidrapportera i
    {{ appName }} och säg hejdå till dubbelarbete!
  </p>
</app-feature-preview>
