<div class="p-grid">
  <div *ngIf="componentMainForm" class="p-col-12 p-lg-4 p-g-nopad">
    <spinner *ngIf="loadingForm | async"></spinner>
    <form
      *ngIf="!(loadingForm | async)"
      [formGroup]="componentMainForm"
      class="mail-form full-width no-margin"
    >
      <appForm2-simple-input formControlName="email"></appForm2-simple-input>

      <appForm2-simple-input formControlName="subject"></appForm2-simple-input>

      <div class="p-col-12 p-g-nopad">
        <div class="form-group">
          <div class="p-col-6">
            <p-checkbox
              [(ngModel)]="embedMsg.checked"
              [ngModelOptions]="{ standalone: true }"
              [binary]="true"
              label="Infoga systemtext"
            ></p-checkbox>
          </div>
          <div class="p-col-6" #systemtext>
            <span
              class="hover-pointer"
              [title]="
                'Detta mail har skickats direkt ifrån ' +
                appname +
                ', ett projektverktyg för byggare. För att svara till avsändaren tryck reply/svara eller skicka till aktuell adress. Meddelande från avsändaren:'
              "
              >Visa systemtext</span
            >
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div
          class="form-group"
          [class.has-danger]="componentMainForm.controls.message?.showErrors"
        >
          <label class="form-control-label">Meddelande</label>
          <p-editor
            #editor
            formControlName="message"
            [style]="{ height: '180px' }"
          >
            <p-header>
              <span class="ql-formats">
                <select class="ql-header" aria-label="Header"></select>
                <button class="ql-link" aria-label="Insert link"></button>
              </span>
              <span class="ql-formats">
                <button class="ql-bold" aria-label="Bold"></button>
                <button class="ql-underline" aria-label="Underline"></button>
              </span>
            </p-header>
          </p-editor>
          <div *ngIf="componentMainForm.controls.message?.showErrors">
            <p
              *ngFor="
                let error of componentMainForm.controls.message.mergedErrors
              "
              class="form-control-feedback"
            >
              <span><i class="fa fa-times"></i> </span
              ><span [innerHTML]="error"></span>
            </p>
          </div>
        </div>
      </div>
      <span *ngIf="mode === 'offer'" class="p-col-12 p-d-flex p-ai-end">
        <i
          style="color: var(--btn-info-color)"
          class="pi pi-info-circle p-mr-1"
        ></i>
        Offertlänken aktiveras först när mailet skickas.
      </span>
      <span *ngIf="mode === 'offer'" class="p-col-12 p-d-flex p-ai-center">
        <i
          style="color: var(--btn-info-color)"
          class="pi pi-info-circle p-mr-1"
        ></i>
        När kunden accepterar offerten kommer den bli låst för förändringar.
        <br />
        Om förändringar behövs måste offerten kopieras till en ny offert.
      </span>

      <appForm2-simple-input
        formControlName="replyEmail"
      ></appForm2-simple-input>

      <div class="p-col-12">
        <div class="column-title">
          <h4>
            Bifoga filer
            <small
              >Klicka i de du vill skicka
              <br />
              <p-checkbox
                [(ngModel)]="mergePDFs"
                [ngModelOptions]="{ standalone: true }"
                [binary]="true"
                label="Skicka valda filer som en PDF"
              ></p-checkbox>
            </small>
          </h4>
        </div>
        <div *ngFor="let attachment of attachments | async">
          <p-checkbox
            [(ngModel)]="attachment.checked"
            [ngModelOptions]="{ standalone: true }"
            [binary]="true"
            (click)="refreshPreview()"
            [label]="attachment.name"
            class="p-mb-1"
          ></p-checkbox>
        </div>
      </div>
      <div class="p-col-12">
        <button
          pButton
          class="p-button-primary-hyperion"
          [disabled]="buttons.create.disabled | async"
          (click)="actionSendMail()"
          icon="pi pi-send"
          label="Skicka"
        ></button>
      </div>
    </form>
  </div>

  <div class="p-col-12 {{ componentMainForm ? 'p-lg-8' : 'p-lg-12' }}">
    <spinner *ngIf="loadingList | async"></spinner>

    <p-table
      #mailTable
      dataKey="id"
      *ngIf="(mailList | async)?.length > 0"
      [value]="mailList | async"
      [columns]="tableColums"
      [rowHover]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pRowToggler]="rowData">
          <td class="p-text-truncate" *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
        <tr class="p-table-expanded-row">
          <td [attr.colspan]="columns.length">
            <div class="p-grid p-fluid">
              <div class="p-col-6">
                <div
                  style="white-space: break-spaces"
                  [innerHTML]="rowData.message"
                ></div>
              </div>
              <div class="p-col-6">
                <div>
                  <div>Bifogade filer:</div>
                  <div *ngFor="let attachment of rowData.attachments">
                    <a
                      [href]="
                        urlPrefix + '/site/getFile?path=' + attachment.tempName
                      "
                      >{{ attachment.name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="p-col-12">
                <div>
                  <span class="pull-right"
                    >Öppnat:
                    {{
                      rowData?.mandrillInfo?.opens
                        ? rowData?.mandrillInfo?.opens
                        : 0
                    }}
                    ggr
                  </span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length">
            <app-emptyState [createable]="false"></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <h3>Förhandsgranska bifogade PDF-filer</h3>

    <spinner *ngIf="previewIsLoading"></spinner>

    <div *ngIf="previewUrl" class="pdf-container">
      <iframe
        (load)="onPreviewLoaded()"
        [hidden]="previewIsLoading"
        [attr.src]="previewUrl"
        style="width: 100%"
        class="pdf-view-object"
        frameborder="0"
        scrolling="no"
      >
        <p>
          Kan inte läsa pdf i webbläsaren.
          <a [href]="previewUrl" target="_blank"
            >Tryck här för att ladda ned.</a
          >
        </p>
      </iframe>
    </div>
  </div>
</div>
