import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ExportPaperSizeEnum } from 'app/planner/planner-module-enums';
import {
  ExportProjectPlannerEvent,
  ExportFileFormat,
} from 'app/planner/planner-module-interfaces';
import { GlobalService } from 'app/shared/global';
import { PlannerExportSettingsService } from 'app/planner/services/planner-export-settings.service';

@Component({
  selector: 'app-export-pdf',
  templateUrl: './export-pdf.component.html',
  styleUrls: ['./export-pdf.component.scss'],
})
export class ExportPdfComponent implements OnChanges {
  @Input() private isExporting = false;
  @Input() private startDate: string;
  @Input() private endDate: string;

  @Output()
  public closeLightBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public export: EventEmitter<ExportProjectPlannerEvent> = new EventEmitter<ExportProjectPlannerEvent>();

  public isVisible = false;
  public form: FormGroup;
  public title = 'Exportera projektplanering';
  public introText =
    'Exporta projektplanering genom att välja start- och slutdatum på det intervall du vill exportera samt i vilket pappersformat. Formatet begränsar storleken på intervallet';
  public datePickerLocale: string;

  public handleCloseLightboxEvent = () => {
    this.closeLightBox.emit(false);
  };

  constructor(
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private plannerExportService: PlannerExportSettingsService
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');
  }

  public ngOnChanges(): void {
    if (this.isExporting) {
      this.isVisible = true;
      this.form = this.createFormGroup(this.startDate, this.endDate);
    } else {
      this.isVisible = false;
    }
  }

  private createFormGroup(start: string, end: string): FormGroup {
    const settings = this.plannerExportService.getExportSettingsLocalStorage();

    return this.formBuilder.group({
      startDate: [settings?.start ?? start, Validators.required],
      endDate: [settings?.end ?? end, Validators.required],
      format: [settings?.format ?? ExportPaperSizeEnum.A3, Validators.required],
      landscape: [settings?.landscape ?? false, Validators.required],
      fileFormat: [
        settings?.fileFormat ?? ExportFileFormat.PDF,
        Validators.required,
      ],
    });
  }

  public isRedDay = (date: any): boolean => {
    const day = new Date(date.year, date.month, date.day);
    return day.getDay() === 0;
  };

  public exportPlanner(): void {
    if (this.form.valid) {
      const event: ExportProjectPlannerEvent = {
        end: this.form.get('endDate').value,
        start: this.form.get('startDate').value,
        format: this.form.get('format').value,
        landscape: this.form.get('landscape').value,
        fileFormat: this.form.get('fileFormat').value as ExportFileFormat,
      };

      this.plannerExportService.storeExportSettingsLocalStorage(event);
      this.export.emit(event);
      this.handleCloseLightboxEvent();
    }
  }
}
