<div class="page-container p-grid">
  <p-card class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12 p-grid">
        <h1 class="p-col-12">Dela projekt med kund</h1>
        <div class="p-col-12">
          Här kan du dela ditt projekt med kunden. Du kan knyta flera projekt
          till en och samma kontakt och tvärtom.
        </div>
        <div class="p-col-12 p-text-bold">
          Detta gör du i följande tre steg:
        </div>
        <div class="p-col-12">
          1. Välj den kontakt eller skriv in uppgifter för den personen du vill
          dela projektet med.
        </div>
        <div class="p-col-12">
          2. Välj rättigheter, alltså vad ska kunden kunna se när den loggar in.
        </div>
        <div class="p-col-12">
          3. Tryck på "Dela projekt". Ett mail med inloggningsuppgifter skickas
          till kontakten.
        </div>
      </div>
      <form class="p-col-6 p-grid" [formGroup]="createPrivateCustomerForm">
        <h3 class="p-col-12 p-mb-0">Ange uppgifter</h3>
        <span class="p-col-12">
          <span class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-search"></i>
            </span>
            <app-contact-select
              (selectedContactEvent)="populateForm($event)"
            ></app-contact-select>
          </span>
        </span>
        <p-divider class="p-col-12"></p-divider>
        <span class="p-col-12">
          <label>Namn</label>
          <span class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-user"></i>
            </span>
            <input pInputText formControlName="firstName" />
          </span>
        </span>
        <span class="p-col-12">
          <label>Mail*</label>
          <span class="p-inputgroup">
            <span class="p-inputgroup-addon">
              <i class="pi pi-envelope"></i>
            </span>
            <input pInputText formControlName="email" type="email" />
          </span>
        </span>
      </form>
      <form class="p-col-6 p-grid">
        <div class="p-col-6 p-grid">
          <h3 class="p-col-12 p-mb-0">Sätt rättigheter</h3>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.offer"
              [binary]="true"
              label="Offert"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              label="Tidrapport"
              [formControl]="createCustomerRelationForm.controls.timereport"
              [binary]="true"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.product"
              [binary]="true"
              label="Produkter"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.invoice"
              [binary]="true"
              label="Fakturor"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.picture"
              [binary]="true"
              label="Bilder"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.document"
              [binary]="true"
              label="Dokument"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.todo"
              [binary]="true"
              label="Arbetsmoment"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="createCustomerRelationForm.controls.comment"
              [binary]="true"
              label="Kommentarer"
            ></p-checkbox>
          </span>
        </div>
        <span class="p-col-6 p-grid">
          <h3 class="p-col-12 p-mb-0">Notifikation</h3>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="
                createCustomerRelationForm.controls.invoiceNotification
              "
              [binary]="true"
              label="Fakturor"
            ></p-checkbox>
          </span>
          <span class="p-col-12">
            <p-checkbox
              [formControl]="
                createCustomerRelationForm.controls.documentNotification
              "
              [binary]="true"
              label="Dokument"
            ></p-checkbox>
          </span>
        </span>
      </form>
      <span class="p-col-12 p-d-flex p-jc-end">
        <button
          pButton
          class="p-button-primary-hyperion"
          label="Dela projekt"
          icon="pi pi-send"
          (click)="createPrivateCustomer()"
        ></button>
      </span>
    </div>
  </p-card>

  <p-table class="p-col-12" [value]="sharedToCustomers | async">
    <ng-template pTemplate="header">
      <tr>
        <th>Delat till</th>
        <th>Rättigheter</th>
        <th>Notifikation</th>
        <th>Administrera</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-value let-i="rowIndex">
      <tr>
        <td>
          <span class="p-grid">
            <span class="p-col-12 p-text-truncate">
              {{ value?.privateCostumer?.firstName
              }}{{ value?.privateCostumer?.lastName }}</span
            >
            <span class="p-col-12 p-text-truncate">
              {{ value.privateCostumer.email }}
            </span>
            <a
              (click)="openCustomerView(value.privateCostumer.id)"
              target="_blank"
              rel="noopener noreferrer"
              class="p-col-12 p-text-truncate"
              >Visa användarens vy när de loggar in</a
            >
          </span>
        </td>
        <td>
          <span class="p-grid">
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.offer"
                [binary]="true"
                label="Offert"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                label="Tidrapport"
                [formControl]="customerRelationForms[i].controls.timereport"
                [binary]="true"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.product"
                [binary]="true"
                label="Produkter"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.invoice"
                [binary]="true"
                label="Fakturor"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.picture"
                [binary]="true"
                label="Bilder"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.document"
                [binary]="true"
                label="Dokument"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.todo"
                [binary]="true"
                label="Arbetsmoment"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="customerRelationForms[i].controls.comment"
                [binary]="true"
                label="Kommentarer"
              ></p-checkbox>
            </span>
          </span>
        </td>
        <td>
          <span class="p-grid">
            <span class="p-col-12">
              <p-checkbox
                [formControl]="
                  customerRelationForms[i].controls.invoiceNotification
                "
                [binary]="true"
                label="Fakturor"
              ></p-checkbox>
            </span>
            <span class="p-col-12">
              <p-checkbox
                [formControl]="
                  customerRelationForms[i].controls.documentNotification
                "
                [binary]="true"
                label="Dokument"
              ></p-checkbox>
            </span>
          </span>
        </td>
        <td>
          <span class="p-grid">
            <button
              pButton
              class="p-col-12 p-mb-4 p-button-primary-inverted"
              icon="pi pi-save"
              label="Uppdatera"
              (click)="updateCustomerRelation(customerRelationForms[i])"
            ></button>
            <button
              pButton
              class="p-button-danger-inverted p-col-12"
              icon="pi pi-trash"
              label="Radera"
              type="button"
              (click)="deleteCustomerRelation(value.id)"
            ></button>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
