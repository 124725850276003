<p-dialog
  [(visible)]="isVisible"
  [header]="title"
  [modal]="true"
  (onHide)="handleCloseLightboxEvent()"
>
  <div>
    <form *ngIf="form" [formGroup]="form">
      <div class="content">
        <div class="cell-2">
          <div>
            <label>Projekt*</label>
          </div>
          <div>
            <p-dropdown
              formControlName="project"
              placeholder="Välj projekt.."
              optionLabel="label"
              dataKey="value"
              filter="true"
              appendTo="body"
              [options]="projectsDropdown"
              (onChange)="updateTodosDropdown()"
            ></p-dropdown>
          </div>
        </div>

        <div class="cell-2">
          <div>
            <label>Arbetsmoment</label>
          </div>
          <div>
            <p-dropdown
              formControlName="todo"
              placeholder="Välj arbetsmoment.."
              optionLabel="label"
              dataKey="value"
              filter="true"
              appendTo="body"
              showClear="true"
              [options]="todosDropdown"
            ></p-dropdown>
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Startdatum*</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="startDate"
              showTime="true"
              hourFormat="24"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              (onSelect)="onChangeStartDate()"
              [panelStyleClass]="'edit-planned-time-calendar'"
              [locale]="datePickerLocale"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Slutdatum*</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="endDate"
              showTime="true"
              hourFormat="24"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              [panelStyleClass]="'edit-planned-time-calendar'"
              [locale]="datePickerLocale"
              [minDate]="minDate"
              [maxDate]="maxDate"
              [showWeek]="true"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template>
            </p-calendar>
          </div>
        </div>

        <div class="cell-2">
          <div>
            <label>Medarbetare*</label>
          </div>
          <div>
            <p-dropdown
              *ngIf="!isNewEvent"
              formControlName="coworker"
              placeholder="Välj medarbetare.."
              optionLabel="label"
              dataKey="value"
              filter="true"
              appendTo="body"
              [options]="coworkersDropdown"
              [disabled]="viewContext === PlannerViewContext.Resource"
            ></p-dropdown>
            <p-multiSelect
              *ngIf="isNewEvent"
              [options]="coworkersDropdown"
              [style]="{ width: '100%' }"
              optionLabel="label"
              defaultLabel="Välj medarbetare.."
              formControlName="coworker"
              selectedItemsLabel="{0} valda"
              maxSelectedLabels="2"
              appendTo="body"
              [overlayOptions]="{ appendTo: 'body' }"
            ></p-multiSelect>
          </div>
        </div>

        <div class="cell-2">
          <div>
            <label>Meddelande</label>
          </div>
          <div>
            <input
              pInputText
              type="text"
              formControlName="text"
              placeholder="Meddelande.."
            />
          </div>
        </div>

        <div class="cell-2">
          <p-selectButton
            formControlName="repeat"
            styleClass="select-button-grid"
            [options]="repeatOptions"
          ></p-selectButton>

          <div #repeatDays class="repeat-days">
            <p-selectButton
              formControlName="repeatDays"
              multiple="multiple"
              styleClass="select-button-grid-days"
              [options]="repeatDaysOptions"
            ></p-selectButton>

            <div class="cell-1">
              <div>
                <label>Upprepa till</label>
              </div>
              <div>
                <p-calendar
                  [showIcon]="true"
                  formControlName="repeatEndDate"
                  dateFormat="yy-mm-dd"
                  showWeek="true"
                  appendTo="body"
                  [locale]="datePickerLocale"
                  [minDate]="minDate"
                  [maxDate]="maxDate"
                  [showWeek]="true"
                ></p-calendar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p-footer>
        <div class="buttons-grid">
          <div>
            <app-color-picker
              [form]="form"
              [controlName]="'color'"
            ></app-color-picker>
          </div>
          <div class="buttons">
            <button
              pButton
              *ngIf="!isNewEvent"
              type="button"
              class="p-button-danger button-width-update"
              [label]="!isDataDeleting ? 'Radera' : ''"
              [icon]="isDataDeleting ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid || isDataLoading || isDataDeleting"
              [style]="{ 'margin-right': '5px' }"
              (click)="deleteConfirm()"
            ></button>
            <div [pTooltip]="validationText(validationTextList)">
              <button
                pButton
                *ngIf="!isNewEvent"
                type="submit"
                class="p-button-primary-hyperion button-width-update"
                [label]="!isDataLoading ? 'Uppdatera' : ''"
                [icon]="isDataLoading ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="form.invalid || isDataLoading || isDataDeleting"
                (click)="update()"
              ></button>
              <button
                pButton
                *ngIf="isNewEvent"
                type="submit"
                class="p-button-primary-hyperion button-width-save"
                [label]="!isDataLoading ? 'Spara' : ''"
                [icon]="isDataLoading ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="form.invalid || isDataLoading || isDataDeleting"
                (click)="save()"
              ></button>
            </div>
          </div>
        </div>
      </p-footer>
    </form>
  </div>
</p-dialog>
