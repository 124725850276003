import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAllProjectTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllProjectTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', projectTypes?: { __typename?: 'CompanyProjectTypeConnection', edges?: Array<{ __typename?: 'CompanyProjectTypeEdge', node?: { __typename?: 'CompanyProjectType', id: string, prefix?: string | null, name?: string | null, formattedName?: string | null, addToMeny?: number | null } | null } | null> | null } | null } | null };

export type UpdateProjectTypeMutationVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.CompanyProjectTypeTypeHyperionMutationInput>;
}>;


export type UpdateProjectTypeMutation = { __typename?: 'HyperionSchemaMutation', companyProjectTypeTypeHyperionMutation?: { __typename?: 'CompanyProjectType', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type AddProjectTypeMutationVariables = Types.Exact<{
  type?: Types.InputMaybe<Types.CompanyProjectTypeTypeHyperionMutationInput>;
}>;


export type AddProjectTypeMutation = { __typename?: 'HyperionSchemaMutation', companyProjectTypeTypeHyperionMutation?: { __typename?: 'CompanyProjectType', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type RemoveProjectTypeMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type RemoveProjectTypeMutation = { __typename?: 'HyperionSchemaMutation', companyProjectTypeTypeHyperionMutation?: { __typename?: 'CompanyProjectType', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type ProjectTypeFragment = { __typename?: 'CompanyProjectType', id: string, prefix?: string | null, name?: string | null, formattedName?: string | null, addToMeny?: number | null };

export const ProjectTypeFragmentDoc = gql`
    fragment projectType on CompanyProjectType {
  id
  prefix
  name
  formattedName
  addToMeny
}
    `;
export const GetAllProjectTypesDocument = gql`
    query getAllProjectTypes {
  company {
    projectTypes {
      edges {
        node {
          ...projectType
        }
      }
    }
  }
}
    ${ProjectTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllProjectTypesGQL extends Apollo.Query<GetAllProjectTypesQuery, GetAllProjectTypesQueryVariables> {
    document = GetAllProjectTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectTypeDocument = gql`
    mutation updateProjectType($type: CompanyProjectTypeTypeHyperionMutationInput) {
  companyProjectTypeTypeHyperionMutation(updateCompanyProjectType: $type) {
    id
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectTypeGQL extends Apollo.Mutation<UpdateProjectTypeMutation, UpdateProjectTypeMutationVariables> {
    document = UpdateProjectTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddProjectTypeDocument = gql`
    mutation addProjectType($type: CompanyProjectTypeTypeHyperionMutationInput) {
  companyProjectTypeTypeHyperionMutation(createCompanyProjectType: $type) {
    id
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddProjectTypeGQL extends Apollo.Mutation<AddProjectTypeMutation, AddProjectTypeMutationVariables> {
    document = AddProjectTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProjectTypeDocument = gql`
    mutation removeProjectType($id: Int) {
  companyProjectTypeTypeHyperionMutation(deleteCompanyProjectType: {id: $id}) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProjectTypeGQL extends Apollo.Mutation<RemoveProjectTypeMutation, RemoveProjectTypeMutationVariables> {
    document = RemoveProjectTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }