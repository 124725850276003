import { Component, Output, Input, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { ApolloMutationService } from '../../../shared/apollo/index';
import { FormHandlerService } from '../../../shared/forms';
import { GlobalService } from '../../../shared/global/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';
import { AccountingPlanService } from '../services/accounting-plan.service';
import {
  AccountingPlanAccount,
  AccountingPlanAccountsDropdown,
} from '../services/accounting-plan.types';

const constDataModel = 'companyCostType';
@Component({
  selector: 'companyCostType-create',
  templateUrl: 'company-cost-type-create.component.html',
  styleUrls: ['./company-cost-type.scss'],
  providers: [FormHandlerService],
})
export class CreateCostTypeComponent implements OnInit {
  @Output() dataObjectDeleted = new EventEmitter();
  @Output() public createSibling = new EventEmitter();
  @Input() public hasAccountingPlanFunction: boolean;
  @Input() public accountingPlanAccounts: AccountingPlanAccount[];

  public accountingPlanAccountsDropdownParams: AccountingPlanAccountsDropdown;

  setFields = {
    componentMainForm: {
      name: '',
      procent: '',
      unit: '',
    },
  };
  formFields = {
    model: 'CompanyCostType',
    attributes: {
      name: '',
      procent: '',
      unit: '',
      accountingPlanAccount: null,
      reverseVatAccountingPlanAccount: null,
    },
  };
  dropDownParams = { options: QuantityUnits, filter: true };
  componentMainForm: FormGroup;
  dataModel = constDataModel;
  dataModelCapitalized;
  formSettings;
  fieldsForForm = {};
  componentPrefix;
  buttons;

  constructor(
    private mutationService: ApolloMutationService,
    private globalService: GlobalService,
    private formHandler: FormHandlerService,
    private accountingPlanService: AccountingPlanService
  ) {
    this.formSettings = this.globalService.getFormSettings();
    const buttonObject = {
      delete: {
        model: this.dataModel,
      },
      create: {
        model: this.dataModel,
      },
      update: {
        model: this.dataModel,
      },
    };

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );

    this.buttons = this.formHandler.getButtonValues(buttonObject);
  }

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['name']['label'] =
          'Skriv för att skapa ny produkttyp';
        this.componentMainForm['controls']['procent']['label'] =
          'Skriv för att skapa ny produkttyp';
      });

    this.accountingPlanAccountsDropdownParams =
      this.accountingPlanService.getAccountsDropdownParams(
        this.accountingPlanAccounts
      );
  }

  actionCreate() {
    const crudType = 'create';

    const dataToMutation = {
      name: this.componentMainForm.controls['name'].value,
      procent: this.componentMainForm.controls['procent'].value,
      unit: this.componentMainForm.controls['unit'].value,
      accountingPlanAccountId:
        this.componentMainForm.controls.accountingPlanAccount.value,
      reverseVatAccountingPlanAccountId:
        this.componentMainForm.controls.reverseVatAccountingPlanAccount.value,
    };

    this.buttons = this.formHandler.lockButtons(this.buttons);

    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
      this.formHandler.setLoadingClasses();
    } else {
      const refetchArr = [{ name: this.dataModel, variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            let setLoadingClassesInput;
            if (executedData.mutationSucceededAllArguments) {
              setLoadingClassesInput = 'success';
              this.dataObjectDeleted.emit();
            } else {
              setLoadingClassesInput = 'error';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }
}
