import { Component, Input, OnDestroy } from '@angular/core';
import { PageInfoService, PageInfoKey } from './page-info.service';

@Component({
  selector: 'app-meta',
  template: '',
})
export class MetaComponent implements OnDestroy {
  private key: PageInfoKey;
  private _name: string;
  private _content: string;

  @Input() set name(name: string) {
    this._name = name;
    this.refresh();
  }
  @Input() set content(content: string) {
    this._content = content;
    this.refresh();
  }
  private refresh() {
    const meta = {};
    if (this._name !== undefined) {
      meta[this._name] = this._content;
    }
    this.key = this.pageInfoService.setInfo(
      {
        meta,
      },
      this.key
    );
  }

  constructor(private pageInfoService: PageInfoService) {}

  ngOnDestroy() {
    this.pageInfoService.clearInfo(this.key);
  }
}
