<form [formGroup]="form">
  <p-dropdown
    class="color-picker"
    [panelStyleClass]="'color-dropdown'"
    appendTo="body"
    [formControlName]="controlName"
    [options]="colors"
  >
    <ng-template let-color pTemplate="selectedItem">
      <div class="selected-item" [style]="{ 'background-color': color.value }">
        &nbsp;
      </div>
    </ng-template>
    <ng-template let-color pTemplate="item">
      <div
        class="option-item"
        [style]="{ 'background-color': color.value }"
        [pTooltip]="getColorName(color.value)"
      >
        &nbsp;
      </div>
    </ng-template>
  </p-dropdown>
</form>
