import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';

import { ConnectionsComponent } from './connections/connections.component';
import { ConnectionsSubcontractorsComponent } from './connections-subcontractors/connections-subcontractors.component';
import { ConnectionsService } from 'app/attendance-report/connections/services/connections.service';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    TooltipModule,
    TableModule,
    HyperionSharedModule,
    HeaderModule,
  ],
  declarations: [ConnectionsComponent, ConnectionsSubcontractorsComponent],
  providers: [ConnectionsService],
})
export class ConnectionsModule {}
