import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, first, BehaviorSubject } from 'rxjs';
import {
  FetchProjectGQL,
  FetchProjectQuery,
  UpdateShortMessageGQL,
} from './project-info/graphql/project.generated';
import { MessageService } from '../../shared/message/index';
import { DropdownSelectListItem } from 'app/shared/dropdown-select/dropdown-select';
import { ProjectCountService } from '../project-count-service/project-count-service.service';
import { ProjectFrecencyService } from '../project-frecency.service';
import { ProjectStatusEnum } from 'app/planner/planner-module-enums';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
})
export class ProjectDetailComponent implements OnInit {
  public projectId: number;
  public PROJECT_STATUS = ProjectStatusEnum;

  public projectData: Observable<FetchProjectQuery['project']>;
  private projectDataSub = new BehaviorSubject<FetchProjectQuery['project']>({
    id: null,
  });

  constructor(
    private route: ActivatedRoute,
    private fetchProject: FetchProjectGQL,
    private updateShortMessage: UpdateShortMessageGQL,
    private messageService: MessageService,
    private router: Router,
    private projectCountService: ProjectCountService,
    private projectFrecencyService: ProjectFrecencyService
  ) {}

  public ngOnInit(): void {
    this.projectData = this.projectDataSub.asObservable();
    this.route.params.subscribe(params => {
      this.projectId = params.id;
      this.setProjectData();
      this.projectFrecencyService.addVisit(this.projectId);
    });
  }

  public onInfoDataChanged(_: any): void {
    this.setProjectData();
  }

  public handleKeyUp(event, shortmessage: string): void {
    if (event.keyCode === 13) {
      this.saveMessage(shortmessage);
    }
  }

  public saveMessage(shortmessage: string): void {
    this.updateShortMessage
      .mutate({
        projectId: Number(this.projectId),
        shortMessage: shortmessage,
      })
      .pipe(first())
      .subscribe(data => {
        const errors =
          data.data.projectTypeHyperionMutation.mutationDetails[0].errors;
        if (!errors) {
          this.messageService.insertData({
            summary: 'Meddelande uppdaterat!',
            type: 'success',
          });
        } else {
          this.messageService.insertData({
            summary: 'Meddelandet kunde inte uppdateras',
            textArray: errors,
            type: 'error',
          });
        }
      });
  }

  private setProjectData(): void {
    this.fetchProject
      .fetch({ id: this.projectId })
      .pipe(
        first(),
        map(result => result.data.project)
      )
      .subscribe(data => {
        this.projectDataSub.next(data);
        this.projectCountService.count(this.projectId);
      });
  }

  public onSelectedProjectDropdown(projectItem: DropdownSelectListItem): void {
    if (!projectItem.id) {
      return;
    }

    this.router.navigate(['/project/' + projectItem.id]);
  }

  public openCreateSubproject() {
    this.showCreateSubproject = true;
  }

  public onProjectCreatedEvent(projectId: number): void {
    this.showCreateSubproject = false;
    setTimeout(() => this.router.navigate(['project', projectId]), 0);
  }

  public showCreateSubproject = false;
}
