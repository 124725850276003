import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetMultiSelectUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMultiSelectUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, isDeleted?: number | null, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null } | null };

export type MultiSelectUserFragment = { __typename?: 'User', id: string, isDeleted?: number | null, firstName?: string | null, lastName?: string | null };

export const MultiSelectUserFragmentDoc = gql`
    fragment MultiSelectUser on User {
  id
  isDeleted
  firstName
  lastName
}
    `;
export const GetMultiSelectUsersDocument = gql`
    query getMultiSelectUsers {
  company {
    users {
      edges {
        node {
          ...MultiSelectUser
        }
      }
    }
  }
}
    ${MultiSelectUserFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMultiSelectUsersGQL extends Apollo.Query<GetMultiSelectUsersQuery, GetMultiSelectUsersQueryVariables> {
    document = GetMultiSelectUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }