<app-header>
  <app-button
    [type]="'showCreate'"
    model="Document"
    routerLink="/docu/templates"
  ></app-button>
</app-header>

<div class="page-container p-grid">
  <div class="p-col-12 p-md-10">
    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
      <input
        type="text"
        pInputText
        (input)="documentTable.filterGlobal($event.target.value, 'contains')"
        placeholder="Sök dokument"
      />
    </div>
  </div>
  <div class="p-col-12 p-md-2 align-right">
    <app-button
      (click)="showAdvancedSearch = !showAdvancedSearch"
      [type]="'showExtendSearch'"
    ></app-button>
  </div>

  <p-accordion class="p-col-12" [multiple]="true" *ngIf="showAdvancedSearch">
    <p-accordionTab header="Grunduppgifter" [selected]="true">
      <div class="p-grid">
        <div class="p-col-12 p-md-4">
          <appForm2-simple-input
            [ngModel]="''"
            [htmlAttributes]="{
              label: { value: labels['Document']['templateName'] }
            }"
            (ngModelChange)="
              documentTable.filter($event, 'templateName', 'contains')
            "
          ></appForm2-simple-input>

          <appForm2-simple-input
            [ngModel]="''"
            [htmlAttributes]="{
              label: { value: labels['Document']['title'] }
            }"
            (ngModelChange)="documentTable.filter($event, 'title', 'contains')"
          ></appForm2-simple-input>
        </div>

        <div class="p-col-12 p-md-4">
          <appForm2-simple-input
            [ngModel]="''"
            [htmlAttributes]="{
              label: { value: labels['Document']['projectId'] }
            }"
            (ngModelChange)="
              documentTable.filter($event, 'projectString', 'contains')
            "
          ></appForm2-simple-input>

          <appForm2-simple-input
            [ngModel]="''"
            [htmlAttributes]="{
              label: { value: labels['Document']['date'] }
            }"
            (ngModelChange)="documentTable.filter($event, 'date', 'contains')"
          ></appForm2-simple-input>
        </div>

        <div class="p-col-12 p-md-4">
          <appForm2-simple-dropDown
            [ngModel]="null"
            [dropDownParams]="{
              options: folders,
              filter: true,
              group: true,
              placeholder: 'Välj ' + labels['Document']['folderId']
            }"
            [htmlAttributes]="{
              label: { value: labels['Document']['folderId'] }
            }"
            (ngModelChange)="
              documentTable.filter($event, 'folderId', 'contains')
            "
          ></appForm2-simple-dropDown>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>

  <p-table
    *appLoaded="!loading as loadedFolder"
    #documentTable
    class="p-col-12"
    [rows]="50"
    dataKey="id"
    [rowHover]="true"
    rowExpandMode="single"
    [value]="documents"
    [responsive]="true"
    sortField="date"
    [sortOrder]="-1"
    [columns]="cols"
    paginatorPosition="both"
    [paginator]="documents.length > 50"
    [rowsPerPageOptions]="[50, 100, 250, 500]"
    [rowTrackBy]="helperService.rowTrackBy"
  >
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.field">
          {{ col.header }}
          <p-sortIcon [field]="col.field"></p-sortIcon>
        </th>
        <th class="actions"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-document>
      <tr
        [pRowToggler]="document"
        *ngIf="status.indexOf(document['status']) > -1"
      >
        <td *ngFor="let col of cols" class="p-text-truncate">
          <span class="p-column-title">{{ col.header }}</span>
          {{ document[col.field] }}
        </td>
        <td class="actions">
          <i
            pTooltip="Radera dokument"
            (click)="deleteDocument(document, $event)"
            class="fa fa-lg fa-trash-o"
          ></i>
          <i
            *ngIf="!archived"
            pTooltip="Arkivera dokument"
            (click)="archiveDocument(document.id, $event)"
            class="fa fa-angle-double-right"
            style="margin-left: 7px"
          ></i>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="rowexpansion" let-document let-columns>
      <tr *ngIf="status.indexOf(document['status']) > -1">
        <td [attr.colspan]="cols.length + 1" class="full-width-docu-below">
          <app-docu-edit [id2]="+document.id"></app-docu-edit>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="emptyStateTR">
        <td [attr.colspan]="columns.length + 1">
          <app-emptyState
            [model]="'Document'"
            [whereToCreate]="'topRight'"
          ></app-emptyState>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <app-docu-upgrade-banner></app-docu-upgrade-banner>
</div>
