import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-docu-field',
  templateUrl: './docu-field.component.html',
  styleUrls: ['./docu-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocuFieldComponent {
  @Input() public set value(val: string) {
    this._value = val;
    if (val === '0') {
      this.binaryValue = false;
    }
    if (val === '1') {
      this.binaryValue = true;
    }
  }
  public get value(): string {
    return this._value;
  }
  @Input() public type: string;
  @Input() public errorMsg: string;
  @Output() public valueChange: EventEmitter<string> = new EventEmitter();

  public readonly = true;

  private _value: string;
  public binaryValue: boolean;

  public updateValue(newValue: string): void {
    if (newValue !== this.value) {
      this.valueChange.emit(newValue);
    }
  }
}
