import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { HelperService } from '../../shared/helpers/index';
import { CompanyUsersService } from '../../shared/user/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { ApolloQueryService } from '../../shared/apollo';
import { AppDialogService } from 'app/shared/dialogs';
import { ProjectInstallmentCreateComponent } from './project-installment-create.component';

@Component({
  selector: 'project-installment',
  templateUrl: 'project-installment.component.html',
  providers: [AppDialogService],
})
export class ProjectInstallmentComponent implements OnDestroy, OnInit {
  @Input() projectInfo;

  labelModels = [
    {
      model: 'ProjectInstallment',
      labels: [
        'name',
        'dateToInvoice',
        'responsibleUserId',
        'MODEL_LABEL_0',
        'MODEL_LABEL_1',
        'MODEL_LABEL_2',
      ],
    },
    {
      model: 'ProjectInstallmentRow',
      labels: [
        'typeId',
        'arbetskostnad',
        'benamning',
        'antal',
        'invoiceId',
        'apris',
        'tax',
        'pris',
        'MODEL_LABEL_0',
        'MODEL_LABEL_1',
        'MODEL_LABEL_2',
      ],
    },
  ];

  dataSet = [];
  usersDropdown;
  labels;
  invoiceRowTypeListDropdown;
  optionallyInvoiceTaxDropdown;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  generalDataLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  generalDataSub: Subscription;
  userSub: Subscription;
  projectInstallmentsSub: Subscription;

  constructor(
    public helperService: HelperService,
    private userService: CompanyUsersService,
    private dialogService: AppDialogService,
    private hyperionLabelsService: HyperionLabelsService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.getInstallments();
    this.getUsers();
    this.getLabels();
    this.getGeneralData();
  }

  getInstallments() {
    const variables = {
      projectId: +this.projectInfo['id'],
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectInstallments', variables)
      .subscribe(data => {
        this.projectInstallmentsSub = data.sub;
        this.dataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']['edges'][0]['node']['installments']
        );
        this.loading.next(false);
      });
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.userSub = sub;
        this.usersDropdown = this.userService.cleanFromTypes(data, 3);
      });
  }

  getGeneralData() {
    this.apolloQueryService
      .apolloWatchQueryTwo('generalData')
      .subscribe(data => {
        this.generalDataSub = data.sub;
        this.optionallyInvoiceTaxDropdown =
          this.helperService.makeGenericDropdown(
            data['data']['company']['generalData_PreCompanyTypeHyperion'][
              'invoiceTaxList'
            ]['edges']
          );
        this.invoiceRowTypeListDropdown =
          this.helperService.makeGenericDropdown(
            data['data']['company']['generalData_PreCompanyTypeHyperion'][
              'invoiceRowTypeList'
            ]['edges']
          );
        this.generalDataLoading.next(false);
      });
  }

  getLabels() {
    this.hyperionLabelsService.getLabels(this.labelModels).subscribe(data => {
      this.labels = data;
    });
  }

  openDialog() {
    this.dialogService.data = {
      projectInfo: this.projectInfo,
      labels: this.labels,
      usersDropdown: this.usersDropdown,
    };
    this.dialogService
      .openComponent(ProjectInstallmentCreateComponent)
      .onClose.subscribe(res => {
        if (typeof res !== 'undefined') {
          if (res === 'success') {
            this.getInstallments();
          }
        }
      });
  }

  ngOnDestroy() {
    this.projectInstallmentsSub && this.projectInstallmentsSub.unsubscribe();
    this.generalDataSub && this.generalDataSub.unsubscribe();
    this.userSub && this.userSub.unsubscribe();
  }
}
