<app-feature-preview
  image="/images/hyperion/feature-previews/offert.jpg"
  feature="Offert"
>
  <p>
    Med hjälp av {{ appName }}s offertmodul går offertskrivandet lekande lätt.
    Olika avsnitt av offerten flyttas runt utan att skapa några problem
    överhuvudtaget. Offerten ser dessutom professionella ut och du kan garantera
    att varje offert håller snittet.
  </p>

  <p>
    I {{ appName }}s offertmodul kan du på ett enkelt vis specificera upp vilket
    arbete som ska utföras samt vilket material som kommer användas under
    projektets gång. Du kan söka på dina kundspecifika materialpriser och snabbt
    få ett bra underlag för de kostnader som kommer uppstå under projektets
    gång.
  </p>

  <p>
    Efter att du har skickat iväg offerten via mail från {{ appName }} kan
    kunden läsa offerten digitalt och acceptera offerten via mailet. Detta gör
    det extra enkelt när du snabbt behöver skicka iväg en fastpris ÄTA eftersom
    kunden kan godkänna den digitalt på direkten. Du kommer därmed snabbt vidare
    med arbetet utan att spilla massa tid i onödan.
  </p>
</app-feature-preview>
