<div class="page-container">
  <app-images
    (getImagesEvent)="fetchImages($event)"
    [images]="images"
    [totalImages]="totalImages"
    [projectId]="projectId"
    [imagesPerPage]="imagesPerPage"
    [readonly]="false"
  ></app-images>
</div>
