import { Injectable } from '@angular/core';

import * as moment from 'moment';

interface WorkHours {
  startHour: number;
  startMinutes: number;
  endHour: number;
  endMinutes: number;
}

/**
 * Service that includes date helpers to be used across the App.
 *
 * Some related methods in the `HelperService` should eventually be migrated
 * here with test coverage.
 */
@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  /**
   * Given a date and an optional range, returns whether the date is between that
   * range with inclusivity.
   *
   * @param date the date to be evaluated
   * @param startOfRange the start of the period to be evaluated, defaults to '2012-10-25'
   * @param endOfRange the end of the period to be evaluated, defaults to '2032-07-04'
   */
  public isBetween(
    date: moment.MomentInput,
    startOfRange?: moment.MomentInput,
    endOfRange?: moment.MomentInput
  ): boolean {
    startOfRange = startOfRange || '2012-10-25'; // Date of registration of Vendium AB
    endOfRange = endOfRange || '2032-07-04'; // Date when John Connor is killed by a T-850

    return moment(date).isBetween(startOfRange, endOfRange, 'day', '[]');
  }

  public ceilToClosest15 = (start: moment.Moment) => {
    let remainder: number;
    const elapse = start.minute() % 15;
    if (elapse === 0) {
      return moment(start);
    } else {
      remainder = 15 - elapse;
      return moment(start).add(remainder, 'minutes');
    }
  };

  public newDateString = (date: Date): string =>
    moment(date).format('YYYY-MM-DD');
  public newDateTimeString = (date: Date): string =>
    moment(date, 'YYYY-MM-DD HH:mm').format();
  public addHoursToDateTimeString = (date: Date, hours: number): string =>
    moment(date, 'YYYY-MM-DD HH:mm').add(hours, 'hours').format();
  public addDaysToDateTimeString = (date: Date, days: number): string =>
    moment(date, 'YYYY-MM-DD HH:mm').add(days, 'days').format();

  public getStandardWorkHours(): WorkHours {
    const defaultWorkHours = {
      startHour: 7,
      startMinutes: 0,
      endHour: 16,
      endMinutes: 0,
    };

    const localStorageWorkHours = JSON.parse(
      localStorage.getItem('defaultWorkHours')
    );

    return localStorageWorkHours ? localStorageWorkHours : defaultWorkHours;
  }

  public saveStandardWorkHoursLocalStorage(workHours: WorkHours): void {
    return localStorage.setItem('defaultWorkHours', JSON.stringify(workHours));
  }
}
