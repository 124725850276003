import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloQueryService } from '../apollo/index';

import { GlobalService } from '../global/index';

import { Observable, Subscription, of as observableOf, Subject } from 'rxjs';
import {
  map,
  first,
  filter,
  tap,
  shareReplay,
  switchMap,
} from 'rxjs/operators';
import { AuthService } from 'app/shared/auth/auth.service';

export interface CompanyFunctions {
  [name: string]: boolean;
}

@Injectable()
export class CompanyFunctionsService {
  private companyFunctions = {};
  private companyFunctionsObs: Observable<CompanyFunctions>;
  public companyFunctionsLoaded: Subject<boolean> = new Subject<boolean>();

  constructor(
    private apolloQueryService: ApolloQueryService,
    private authService: AuthService
  ) {
    this.companyFunctionsObs = this.authService.isLoggedInObs.pipe(
      switchMap(loggedIn =>
        loggedIn ? this.getCompanyFunctionsFromServer() : observableOf({})
      ),
      shareReplay(1)
    );
  }

  // @deprecated: not required by hasCompanyFunction
  getCompanyFunctions(): Observable<CompanyFunctions> {
    return this.companyFunctionsObs.pipe(
      tap(data => {
        this.companyFunctions = data;
        this.companyFunctionsLoaded.next(true);
      })
    );
  }

  // @deprecated: use hasCompanyFunction instead
  companyFunctionIsSet(functionName: string): boolean {
    return !!this.companyFunctions[functionName];
  }

  hasCompanyFunction(name: string): Observable<boolean> {
    return this.companyFunctionsObs.pipe(map(functions => !!functions[name]));
  }

  private extractFunctions(data: any): CompanyFunctions {
    const functions = {};
    for (const edge of data.edges) {
      functions[edge.node.function] = true;
    }
    return functions;
  }

  private getCompanyFunctionsFromServer(): Observable<CompanyFunctions> {
    return this.apolloQueryService
      .apolloWatchQueryTwo('companyFunctions', {}, 'cache-first')
      .pipe(
        filter(data => {
          data['data'] && data['sub'].unsubscribe();
          return data['data'] !== undefined;
        }),
        map(({ data }) => data.company.functions),
        map(functions => this.extractFunctions(functions))
      );
  }
}
