<app-header> </app-header>

<div class="page-container">
  <div class="p-grid">
    <app-application-message
      class="p-col-12 p-g-nopad"
    ></app-application-message>
    <div class="p-col-12" *ngIf="!isDerome && shouldShowRecommendWidget">
      <p-card>
        <div class="widget-recommend-container">
          <button
            pButton
            type="button"
            label="Stäng"
            icon="pi pi-times"
            class="close-button"
            (click)="closeRecommendWidget()"
          ></button>
          <div class="p-grid widget-recommend-content">
            <div class="p-col-12 p-lg-2 padded left-column">
              <h1>20% + 10%</h1>
              <h1>Rabatt</h1>
            </div>
            <div class="p-col-12 p-lg-10 padded">
              <h2>Rekommendera {{ appParams['name'] }} till en vän</h2>
              <h3>Du får 20% rabatt baserad på din väns årsavgift</h3>
              <p>
                När du rekryterar en vän får du 20% rabatt på din faktura
                beräknat på den första årsavgiften av din nyanslutna vän!
              </p>
              <h3>Din vän får 10% rabatt hela första året</h3>
              <p>
                Dessutom får din vän 10% rabatt hela första året av oss. En
                win-win helt enkelt!
              </p>
              <p-button
                [routerLink]="'/home/recommend'"
                label="Rekommendera {{ appParams['name'] }} till en vän"
              ></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12" *ngIf="!hideStartDivShowWelcomeDiv">
      <p-card>
        <div class="p-grid">
          <div class="p-col-12 p-lg-3 padded border-right">
            <h2>Välkommen!</h2>
            <table style="width: 100%">
              <tr>
                <td>
                  <strong>{{ meUser['companyName'] }}</strong
                  ><br />
                  {{ meUser['fullName'] }}
                </td>
                <td style="width: 60px">
                  <img
                    *ngIf="this.companyLogo && showImage"
                    (error)="showImage = false"
                    [src]="this.companyLogo"
                    width="59"
                  />
                </td>
              </tr>
            </table>

            <p></p>

            <div>
              <br />
              <a
                *ngIf="meUser.type === '3'"
                (click)="openMailDialog()"
                class="showHover"
                >Visa senast skickade mail</a
              ><br />
              <a
                *ngIf="meUser.type === '3'"
                (click)="openAppLogsDialog()"
                class="showHover"
                >Visa händelselogg</a
              ><br />
              <a (click)="openHelp()" class="showHover">Öppna hjälpavsnitt</a>
              <br />
              <a routerLink="/home/updates">Visa nyheter</a> <br />
            </div>
          </div>
          <div class="p-col-12 p-lg-5 padded border-right">
            <app-overview *ngIf="meUser.type === '3'"></app-overview>

            <div *ngIf="meUser.type !== '3' && !disableRssNews">
              <div class="header">
                <h4>
                  {{ isDerome ? 'Pressrum' : 'Byggnyheter' }}
                  <img
                    src="images/struktur/rss.png"
                    alt=""
                    style="height: 16px"
                  />
                </h4>
              </div>
              <div class="card-holder" style="height: 400px !important">
                <start-news
                  [dataFromParent]="dataForChildren.rssNewsFeed"
                  [loading]="loading.firstSection | async"
                ></start-news>
              </div>
            </div>
          </div>
          <div class="p-col-12 p-lg-4 padded" id="testFromHome">
            <div class="header">
              <h2>
                Internforum
                <span
                  class="help-tooltip"
                  tooltipPosition="right"
                  pTooltip="I internforumet kan alla inom företaget skriva. Internforumet är bara till för er inom företaget och inget som syns utanför systemet."
                  [escape]="false"
                  >?</span
                >
              </h2>
            </div>
            <div>
              <companyNoticeBoard-holder
                [messagesFromParent]="dataForChildren.companyNoticeBoard"
                [loading]="loading.firstSection | async"
              ></companyNoticeBoard-holder>
            </div>
          </div>
        </div>
      </p-card>
    </div>

    <ng-container *ngIf="meUser.type === '3' && !hideStartDivShowWelcomeDiv">
      <div class="p-col-12 p-lg-4" *ngIf="isStruqtur">
        <p-card hasHeader="true" toggleId="home_object_news">
          <div class="header">
            <h4>Juridisk rådgivning</h4>
          </div>
          <div class="card-holder holder-medium evive">
            <p>Har du frågor kring juridik?</p>
            <p>
              För oss på Struqtur är det viktigt att ni ska kunna få juridisk
              vägledning inom den snåriga entreprenadjuridiken. Låt oss hjälpa
              dig!
            </p>
            <p><a routerLink="/home/evive">Läs mer här</a></p>

            <img class="evive-logo" src="/images/evive/evive_law_black.svg" />
          </div>
        </p-card>
      </div>
      <div class="p-col-12 p-lg-4" *ngIf="!disableRssNews && !isStruqtur">
        <p-card hasHeader="true" toggleId="home_object_news">
          <div class="header">
            <h4>
              {{ isDerome ? 'Pressrum' : 'Byggnyheter' }}
              <img src="images/struktur/rss.png" alt="" style="height: 16px" />
            </h4>
          </div>
          <div class="card-holder holder-medium">
            <start-news
              [dataFromParent]="dataForChildren.rssNewsFeed"
              [loading]="loading.firstSection | async"
            ></start-news>
          </div>
        </p-card>
      </div>

      <div class="p-col-12 p-lg-4">
        <p-card hasHeader="true" toggleId="home_object_links">
          <div class="header">
            <h4>
              Senaste 30
              {{
                activeTabIndex === 'timeReports'
                  ? 'tidrapporterna'
                  : activeTabIndex === 'comments'
                  ? 'kommentarerna'
                  : 'bilderna'
              }}
              <small>samtliga användare</small>
            </h4>
          </div>
          <div class="card-holder holder-small" style="overflow: hidden">
            <div *ngIf="activeTabIndex === 'timeReports'">
              <home-start-days-holder
                [daysFromParent]="dataSet.company.days"
                [loading]="loading.firstSection | async"
              ></home-start-days-holder>
            </div>
            <div *ngIf="activeTabIndex === 'comments'">
              <home-start-projectComments-holder
                [projectCommentsFromParent]="dataSet.company.projectsCommets"
                [loading]="loading.firstSection | async"
              ></home-start-projectComments-holder>
            </div>
            <div *ngIf="activeTabIndex === 'images'">
              <home-start-pictures-holder
                [picturesFromParent]="dataSet.company.pictures"
                [loading]="loading.firstSection | async"
              ></home-start-pictures-holder>
            </div>
          </div>
          <p-tabMenu
            [model]="menuItems"
            [activeItem]="menuItems[0]"
            [styleClass]="'home-start-menu'"
          ></p-tabMenu>
        </p-card>
      </div>
      <div class="p-col-12 p-lg-4">
        <p-card hasHeader="true" toggleId="home_object_comming_actions">
          <div class="header">
            <h4>
              Kommande händelser
              <span
                class="help-tooltip"
                tooltipPosition="right"
                pTooltip="Här visas kommande projekt och delbetalningar samt ej utförda delbetalningar."
                [escape]="false"
                >?</span
              >
            </h4>
          </div>
          <div class="card-holder holder-medium" style="overflow: hidden">
            <upcomming-activities
              [dataFromParent]="dataForChildren.upcommingActivities"
              [loading]="loading.firstSection | async"
            ></upcomming-activities>
          </div>
        </p-card>
      </div>
    </ng-container>

    <ng-container class="p-grid p-col-12" *ngIf="!hideStartDivShowWelcomeDiv">
      <div class="p-col-12" *ngIf="showPictureFeedForUser">
        <p-card hasHeader="true" toggleable="true">
          <div class="header">
            <h4>
              Vad händer på {{ companyName }}?
              <small>senaste 50 bilderna</small>
              <span style="margin-left: 1em" *ngIf="meUser.type === '3'">
                <h5 style="display: inline">Visa för alla medarbetare?</h5>
                <span *ngIf="!showPicturesLoading">
                  <p-toggleButton
                    [disabled]="showPicturesWaiting"
                    (onChange)="handleChange($event)"
                    onLabel="Ja"
                    offLabel="Nej"
                    onIcon="fa fa-check-square"
                    offIcon="fa fa-times-circle"
                    [(ngModel)]="showPicturesBool"
                  ></p-toggleButton>
                </span>
              </span>
            </h4>
          </div>
          <div>
            <picture-feed></picture-feed>
          </div>
        </p-card>
      </div>
    </ng-container>

    <div class="p-col-12 p-lg-7" *ngIf="hideStartDivShowWelcomeDiv">
      <p-card hasHeader="true" toggleable="true">
        <div class="header">
          <h1>Välkommen till {{ appParams['name'] }}!</h1>
        </div>
        <p>
          För att komma igång med <b>projektverktyg</b> lite lättare finns till
          höger vår snabbstart. Den hjälper dig att skapa användare och ditt
          första projekt. Klicka på länkarna för att komma igång. <br /><br />
          Är du intresserad av vår <b>E-liggare</b> kan du läsa mer om den i
          avsnittet E-liggare till höger. Där kan du läsa hur du går till väga
          om du är en byggherre eller om du är underentreprenör. <br /><br />
          Kontakta gärna vår support på {{ appParams['supportPhone'] }} eller
          {{ appParams['supportMail'] }}om du har några frågor eller vill ha en
          kostnadsfri demonstration av systemet.<br />
          <br />
          Vi har placerat ut flera <b>info-punkter</b>
          <sup
            class="info pop"
            style="margin: 8px"
            data-title="Info om info-punkter"
            data-content="Här visas en kort förklaring av aktuell funktion."
            rel="popover"
            >?</sup
          >
          håll musen över dessa när du vill ha hjälp.
        </p>
      </p-card>
    </div>
  </div>
</div>
