import { Output, EventEmitter } from '@angular/core';
import { Component, Input, OnChanges } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EventColorsEnum } from 'app/planner/planner-module-enums';
import { SchedulerEventProject } from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import { Project } from 'app/planner/services/planner-query-types';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import { GlobalService } from 'app/shared/global';
import { MessageService } from 'app/shared/message';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-project-view',
  templateUrl: './edit-project-view.component.html',
  styleUrls: ['./edit-project-view.component.scss'],
})
export class EditProjectViewComponent implements OnChanges {
  @Input() private scheduledEvent: SchedulerEventProject;
  @Input() private childrenEdgeDates: { start: string; end: string };

  @Output()
  public closeLightBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public updateEvent: EventEmitter<Partial<Project>> = new EventEmitter<
    Partial<Project>
  >();

  public isVisible = true;
  public form: FormGroup;
  public title = 'Redigera projekt';
  public isDataLoading = false;
  public validationTextList: string[] = [];
  public datePickerLocale: any;
  public validationText = (list: string[]) => list.filter(Boolean).join('\n');

  public handleCloseLightboxEvent = () => {
    this.closeLightBox.emit(false);
  };

  constructor(
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private messageService: MessageService,
    private globalService: GlobalService
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');
  }

  public ngOnChanges(): void {
    this.form = this.createFormGroup(
      this.scheduledEvent as SchedulerEventProject
    );
  }

  private createFormGroup(dataItem: SchedulerEventProject): FormGroup {
    const group = new FormGroup(
      {
        id: new FormControl(dataItem.id),
        trueId: new FormControl(dataItem.trueId, Validators.required),
        startDate: new FormControl(dataItem.start_date),
        endDate: new FormControl(dataItem.end_date),
        mark: new FormControl(dataItem.text, Validators.required),
        color: new FormControl(
          dataItem.color ? dataItem.color.toUpperCase() : EventColorsEnum.Blue
        ),
      },
      {
        validators: [
          this.dateLessThan('startDate', 'endDate'),
          this.noChildrenOutOfRange('startDate', 'endDate'),
        ],
      }
    );

    group.disable();
    group.get('color').enable();
    group.get('startDate').enable();
    group.get('endDate').enable();

    return group;
  }

  private dateLessThan(from: string, to: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const f = new Date(group.controls[from].value);
      const t = new Date(group.controls[to].value);
      if (f.getTime() > t.getTime()) {
        this.validationTextList[0] =
          'Startdatum måste vara tidigare än slutdatum.';
        const error = {
          invalidDates: true,
        };
        group.controls[from].setErrors(error);
        group.controls[to].setErrors(error);
        return error;
      }
      group.controls[from].setErrors(null);
      group.controls[to].setErrors(null);
      this.validationTextList[0] = null;
      return null;
    };
  }

  private noChildrenOutOfRange(from: string, to: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      if (!this.childrenEdgeDates) {
        return null;
      }

      const projectStart = moment(group.controls[from].value);
      const projectEnd = moment(group.controls[to].value)
        .set('hours', 23)
        .set('minutes', 59);

      const childStart = moment(this.childrenEdgeDates.start);
      const childEnd = moment(this.childrenEdgeDates.end);

      if (
        projectStart.isBetween(childStart, childEnd) ||
        projectEnd.isBetween(childStart, childEnd)
      ) {
        this.validationTextList[1] =
          'Det finns arbetsmoment eller planerad tid utanför projektetperioden';
        const error = {
          invalidPeriod: true,
        };
        if (projectStart.isBetween(childStart, childEnd)) {
          group.controls[from].setErrors(error);
        }
        if (projectEnd.isBetween(childStart, childEnd)) {
          group.controls[to].setErrors(error);
        }
        return error;
      }
      this.validationTextList[1] = null;
      if (!projectStart.isBetween(childStart, childEnd)) {
        group.controls[from].setErrors(null);
      }
      if (!projectEnd.isBetween(childStart, childEnd)) {
        group.controls[to].setErrors(null);
      }
      return null;
    };
  }

  public update(): void {
    if (this.form.valid) {
      this.form.disable();
      this.isDataLoading = true;
      const project = this.getProjectFromForm(this.form);
      this.plannerWriteService.updateProject(project).subscribe(
        result => {
          if (result.mutationSucceededAllArguments) {
            this.closeLightBox.emit(false);
            this.updateEvent.emit(project);
          } else {
            this.messageService.insertDataFromMutation(result);
          }
        },
        error => {
          console.warn(error);
        },
        () => {
          this.isDataLoading = false;
          this.form.get('color').enable();
          this.form.get('startDate').enable();
          this.form.get('endDate').enable();
        }
      );
    }
  }

  private getProjectFromForm(form: FormGroup): Partial<Project> {
    const values = form.value;
    return {
      id: values.id,
      color: values.color.toUpperCase(),
      startDate: moment(values.startDate).format('YYYY-MM-DD'),
      endDate: moment(values.endDate).format('YYYY-MM-DD'),
    };
  }

  public isRedDay = (date: any): boolean => {
    const day = new Date(date.year, date.month, date.day);
    return day.getDay() === 0;
  };
}
