import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectProductTypeQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectProductTypeQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', projectProducts?: { __typename?: 'ProjectproductConnection', edges?: Array<{ __typename?: 'ProjectproductEdge', node?: { __typename?: 'Projectproduct', type?: string | null } | null } | null> | null } | null } | null };

export type UpdateRowAmountMutationVariables = Types.Exact<{
  rowId?: Types.InputMaybe<Types.Scalars['Int']>;
  amount?: Types.InputMaybe<Types.Scalars['Float']>;
}>;


export type UpdateRowAmountMutation = { __typename?: 'HyperionSchemaMutation', invoiceRowTypeHyperionMutation?: { __typename?: 'InvoiceRow', id: string } | null };

export const ProjectProductTypeDocument = gql`
    query projectProductType($projectId: Int, $id: Int) {
  project(id: $projectId) {
    projectProducts(id: $id) {
      edges {
        node {
          type
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectProductTypeGQL extends Apollo.Query<ProjectProductTypeQuery, ProjectProductTypeQueryVariables> {
    document = ProjectProductTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRowAmountDocument = gql`
    mutation updateRowAmount($rowId: Int, $amount: Float) {
  invoiceRowTypeHyperionMutation(updateInvoiceRow: {id: $rowId, antal: $amount}) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRowAmountGQL extends Apollo.Mutation<UpdateRowAmountMutation, UpdateRowAmountMutationVariables> {
    document = UpdateRowAmountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }