import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { Component, OnInit } from '@angular/core';

import { HelperService } from 'app/shared';

import { Observable } from 'rxjs';

const USER_SUBCOMPANIES_QUERY = gql`
  query UserSubcompanies {
    me {
      id
      userHasCompanyUsers {
        edges {
          node {
            id
            ownerUser {
              id
              firstName
              lastName
            }
            companyHasCompany {
              id
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

@Component({
  selector: 'app-subcompanies',
  templateUrl: './subcompanies.component.html',
  styleUrls: ['./subcompanies.component.scss'],
})
export class SubcompaniesComponent implements OnInit {
  public subcompanies = [];

  constructor(private apollo: Apollo, private helperService: HelperService) {}

  public ngOnInit(): void {
    this.getSubcompanies().subscribe(result => {
      this.subcompanies = this.helperService.cleanFromNode(
        result.data.me.userHasCompanyUsers
      );
    });
  }

  private getSubcompanies(): Observable<ApolloQueryResult<any>> {
    return this.apollo.query({
      query: USER_SUBCOMPANIES_QUERY,
    });
  }
}
