import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from 'app/shared/http';
import { GlobalService } from '../../../shared/global/index';
import { MessageService } from '../../../shared/message/index';

@Component({
  selector: 'import-products',
  templateUrl: 'import-products.component.html',
})
export class ImportProductsComponent implements OnInit {
  @Output() refetchProducts = new EventEmitter();

  excelFormUpdateExisting = true;
  emptyLabel;
  status = true;
  loadingStatus = new BehaviorSubject('dns');
  arrayKeys = ['update', 'new', 'zero', 'error'];
  dataSet = {
    update: {
      head: 'Updaterade produkter',
      rows: new BehaviorSubject([]),
      count: 0,
      extraHead: 'Fsg. Pris',
    },
    new: {
      head: 'Nya produkter',
      rows: new BehaviorSubject([]),
      count: 0,
      extraHead: 'Fsg. Pris',
    },
    zero: {
      head: 'Produkter utan pris',
      rows: new BehaviorSubject([]),
      count: 0,
      extraHead: 'Fsg. Pris',
    },
    error: {
      head: 'Felaktiga poster',
      rows: new BehaviorSubject([]),
      count: 0,
      extraHead: 'Fel',
    },
  };
  productsUploadSub;
  interval;
  tableColums: { field: string; header: string }[];

  constructor(
    private globalService: GlobalService,
    private httpService: HttpService,
    private messageService: MessageService
  ) {
    this.emptyLabel = this.globalService.getEmptyDataLabel();
  }

  ngOnInit(): void {
    this.setTableColums();
  }

  private setTableColums() {
    this.tableColums = [
      { field: 'artnr', header: 'Artnr' },
      { field: 'benamning', header: 'Benämning' },
      { field: 'extraString', header: 'extraString' },
    ];
  }

  onBeforeUpload(event) {}

  onBeforeSend(event) {}

  getProducts() {
    const url =
      this.globalService.getUrlPrefix() +
      '/product/ReadExcel2?updateExisting=' +
      (this.excelFormUpdateExisting ? 1 : 0);
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.pushStatusRows(data['rows']);
      const dataStatus = data['status'];

      if (dataStatus === 'finished') {
        this.status = false;
      }
      if (dataStatus === 'ready') {
        this.getProducts();
      }
    });
  }

  pushStatusRows(rows) {
    for (const statusArray in rows) {
      const dataSet = this.dataSet[statusArray]['rows'].value;
      for (const product in rows[statusArray]) {
        dataSet.push(rows[statusArray][product]);
        ++this.dataSet[statusArray]['count'];
      }

      const newDataSet = [...dataSet];
      this.dataSet[statusArray]['rows'].next(newDataSet);
    }
  }

  onUpload(event) {
    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    if (dataAsJSON['status'] === 'error') {
      this.messageService.insertData(
        {
          summary: 'Inläsning av produkter kunde inte slutföras',
          textArray: dataAsJSON.errors,
          type: 'error',
        },
        true
      );
    } else {
      this.loadingStatus.next('loading');

      this.clearProductStatusRows();
      this.getProducts();

      this.interval = setInterval(() => {
        if (!this.status) {
          clearInterval(this.interval);
          this.loadingStatus.next('finished');
          this.refetchProducts.emit();
          this.status = true;
          this.messageService.insertData({
            textArray: ['Inläsning av produkter färdigställdes'],
            time: 2000,
            type: 'success',
          });
        }
      }, 5000);
    }
  }

  clearProductStatusRows() {
    for (const key in this.dataSet) {
      const productStatusData = this.dataSet[key];

      productStatusData['rows'] = new BehaviorSubject([]);
      productStatusData['count'] = 0;
    }
  }

  cancelCall() {
    this.productsUploadSub.unsubscribe();
    clearInterval(this.interval);
    window.location.href =
      this.globalService.getAppUrlPrefix() + '/productSettings';
  }
}
