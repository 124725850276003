<div>
  <spinner *ngIf="loading"></spinner>

  <div *ngIf="!loading">
    <div class="p-grid" *ngIf="selectedProject">
      <div class="p-col-12 p-g-nopad">
        <div class="sub-title-row">
          <h3 style="font-weight: normal" *ngIf="!isMain">
            Huvudansvarig är {{ projectData?.company?.name }},
            {{ projectData?.company?.orgNr }}
          </h3>
        </div>
      </div>
      <div class="p-col-12">
        <hyperion-card>
          <div class="p-grid">
            <div class="p-col-12">
              <div class="contractor-header">
                <h4>
                  {{ companyInfo?.name }}, {{ companyInfo?.orgNr }}
                  <span *ngIf="isMain">Huvudansvarig </span
                  ><span *ngIf="projectData.haveErrors"
                    ><i
                      class="fa fa-exclamation-triangle fa-lg warning"
                      pTooltip="Innehåller fel"
                      tooltipPostion="right"
                    ></i
                  ></span>
                </h4>
              </div>
            </div>
            <div class="p-col-12">
              <attendance-reports-list
                [projectData]="projectData"
                [attendanceReportsInfo]="attendanceReportsInfo"
                [isCompany]="true"
                (refetchAttendanceReports)="refetchAttendanceReports()"
                (loadContractors)="setShowSubcontractors()"
              ></attendance-reports-list>
            </div>
          </div>
        </hyperion-card>
      </div>
      <div class="p-col-12" *ngIf="showSubcontractors">
        <contractor-drag-and-drop
          [subcontractorsData]="subcontractorsData"
          [mainCompanyData]="companyInfo"
          [projectId]="selectedProject['id']"
          [parentId]="selectedProject.subcontractorRelationToProjectId"
          (refetchAttendanceReports)="refetchAttendanceReports()"
          (subcontractorDeleted)="
            subcontractorDeleted($event, subcontractorsData)
          "
        ></contractor-drag-and-drop>
      </div>
    </div>
    <div
      class="p-grid attendance-report-subcontractor-section"
      *ngIf="selectedProject"
    >
      <div class="p-col-12 p-md-8 p-lg-9">
        <div
          class="p-inputgroup subcontractor-auto-complete"
          *ngIf="isMain || allowSubcontractorsToAddSubcontractors"
        >
          <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
          <p-autoComplete
            #subcontractor_autocomplete
            [(ngModel)]="autoCompleteQuery"
            [inputStyle]="{ width: '100%' }"
            [style]="{ width: '100%' }"
            placeholder="Sök / lägg till underentreprenör för valt projekt"
            [suggestions]="autoCompleteResults | async"
            (completeMethod)="
              searchSubcontractor(subcontractor_autocomplete, $event)
            "
            (onSelect)="
              openAddSubcontractorToProjectDialog(
                $event,
                selectedProject.subcontractorRelationToProjectId
              )
            "
            (onFocus)="searchSubcontractor(subcontractor_autocomplete)"
            field="name"
          >
            <ng-template let-object pTemplate="item">
              <div class="p-helper-clearfix" style="font-size: 12px">
                <div class="autocomplete-row autocomplete-header-row">
                  <div style="width: 49%">Namn</div>
                  <div style="width: 49%">Org. Nr</div>
                  <div style="float: none" class="clear-both"></div>
                </div>
                <div class="autocomplete-row" *ngIf="object.id">
                  <div style="width: 49%">{{ object.name }}</div>
                  <div style="width: 49%">{{ object.orgNr }}</div>
                  <div style="float: none" class="clear-both"></div>
                </div>
                <div class="autocomplete-row" *ngIf="!object.id">
                  <div style="width: 99%">Ni har inga underentreprenörer.</div>
                  <div style="float: none" class="clear-both"></div>
                </div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
      <div class="p-col-12 p-md-4 p-lg-3 align-right">
        <app-button
          (click)="openAddSubcontractorDialog()"
          [type]="'showCreate'"
          [model]="'SubcontractorRelationToProject'"
        ></app-button>
      </div>
    </div>
    <div class="p-grid" *ngIf="selectedProject && isMain">
      <div class="p-col-12 top-border pl-project-actions">
        <p-checkbox
          label="För projekt tillåt underentreprenörer att lägga till egna underentreprenörer"
          (onChange)="toggleAllowSubcontractorsToAddSubcontractors()"
          [(ngModel)]="allowSubcontractorsToAddSubcontractors"
          [binary]="true"
        ></p-checkbox>
        <div class="pull-right">
          <app-button
            *ngIf="!isInArchived()"
            (click)="getGatherList()"
            [type]="'gatherList'"
          ></app-button>
          <app-button
            class="ml5"
            (click)="confirmDeleteProject()"
            [type]="'delete'"
            [model]="'Project'"
          ></app-button>
          <app-button
            class="ml5"
            *ngIf="!isInArchived()"
            (click)="confirmUpdateProjectStatus(3)"
            [type]="'archive'"
            [model]="'Project'"
          ></app-button>
          <app-button
            class="ml5"
            *ngIf="isInArchived()"
            (click)="confirmUpdateProjectStatus(1)"
            [type]="'unArchive'"
          ></app-button>
        </div>
      </div>
    </div>
    <div
      class="p-grid"
      *ngIf="
        selectedProject && !(isMain || allowSubcontractorsToAddSubcontractors)
      "
    >
      <div class="p-col-12 top-border">
        <p>
          Vill du dela projektet med din underentreprenör, be
          huvudentreprenören/ansvarig för E-liggaren att aktivera detta.
        </p>
      </div>
    </div>
    <div class="p-grid" *ngIf="selectedProject && isMain">
      <div class="p-col-12">
        <h3>
          Ej knutna tider
          <span *ngIf="attendanceReportsWithoutRelationsHaveErrors"
            ><i
              class="fa fa-exclamation-triangle fa-lg warning"
              pTooltip="Innehåller fel"
              tooltipPostion="right"
            ></i
          ></span>
        </h3>
        <attendance-reports-list
          [attendanceReports]="attendanceReportsWithoutRelations"
          [withoutRelations]="true"
          (refetchAttendanceReports)="refetchAttendanceReports()"
        ></attendance-reports-list>
      </div>
    </div>
    <div
      class="p-col-12"
      *ngIf="
        selectedProject &&
        divisionsPanel.length > 0 &&
        useUserConnectionToProject &&
        isMain
      "
    >
      <h3>Divisioner - Välj användare för detta projekt</h3>
      <p>
        Nedan kan du välja vilka personer som ska få detta projekt tilldelat,
        projektet syns bara i appen om det är tilldelat.
      </p>
      <br />
      <br />

      <div>
        <p-accordion>
          <p-accordionTab
            [header]="panel.title"
            *ngFor="let panel of divisionsPanel"
          >
            <div class="p-grid">
              <div class="p-col-12">
                <p *ngIf="panel['users'].length > 0" style="float: right">
                  <a
                    (click)="
                      selectOrDeselectDivisionAllPersons(panel.title, '1')
                    "
                    style="cursor: pointer"
                  >
                    Markera alla</a
                  >
                  /
                  <a
                    (click)="
                      selectOrDeselectDivisionAllPersons(panel.title, '0')
                    "
                    style="cursor: pointer"
                  >
                    Avmarkera alla</a
                  >
                </p>
              </div>
              <div class="p-col-12 p-lg-3" *ngFor="let user of panel['users']">
                <appForm2-list-checkbox
                  [ngModel]="user['isChecked'] == 1 ? true : false"
                  (ngModelChange)="
                    divisionsUserSelected($event, +user['id'], panel.title)
                  "
                  [htmlAttributes]="{
                    label: { value: user['firstName'] + ' ' + user['lastName'] }
                  }"
                ></appForm2-list-checkbox>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </div>
</div>
