import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { OfferDocumentComponent } from './offer-document/offer-document.component';
import { OfferDocumentHandleComponent } from './offer-document-handle/offer-document-handle.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionCardModule,
    LoadingModule,
    InputTextareaModule,
    FileUploadModule,
    HyperionSharedModule,
  ],
  declarations: [OfferDocumentComponent, OfferDocumentHandleComponent],
  exports: [OfferDocumentComponent],
})
export class OfferDocumentModule {}
