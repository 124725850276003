import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'day',
  templateUrl: 'day.component.html',
})
export class DayComponent implements OnInit {
  show = {
    menu: false,
  };

  headerClass;

  ngOnInit() {
    this.headerClass = localStorage.getItem('appgetenvStruqturMode');
  }
}
