import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProjectCountsQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ProjectCountsQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', todoCount?: number | null, commentCount?: number | null, pictureCount?: number | null, documentCount?: number | null, attendanceReportsCount?: number | null, atasCount?: number | null, costumerRelationsToProjectCount?: number | null, installmentsCount?: number | null, rotsCount?: number | null, supplierInvoicesCount?: number | null } | null };

export const ProjectCountsDocument = gql`
    query projectCounts($projectId: Int) {
  project(id: $projectId) {
    todoCount
    commentCount
    pictureCount
    documentCount
    attendanceReportsCount
    atasCount
    costumerRelationsToProjectCount
    installmentsCount
    rotsCount
    supplierInvoicesCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProjectCountsGQL extends Apollo.Query<ProjectCountsQuery, ProjectCountsQueryVariables> {
    document = ProjectCountsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }