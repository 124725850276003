import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { CompanySuperService } from '../company.super.service';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProjectDaysService extends CompanySuperService {
  dataModel = 'day';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    // mode == labels || list

    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data, false);
        break;
      case 'daysLeave':
        dataSetFormatted = this.makeObjectsForForms(data, true);
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        // create object to push
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data, isLeave) {
    let modelName = this.dataModel;

    if (isLeave) {
      modelName += 'sLeave';
    } else {
      modelName += 's';
    }

    const mainDataArray = data.company['projects'].edges;
    const dataObjectsForForms = [];

    for (const i in mainDataArray) {
      const projectInfo =
        mainDataArray[i]['node']['trueId'] +
        ', ' +
        mainDataArray[i]['node']['mark'];
      const projectStatus = mainDataArray[i]['node']['status'];
      const projectId = mainDataArray[i]['node']['id'];

      const subDataArray = mainDataArray[i]['node'][modelName].edges;

      for (const k in subDataArray) {
        const dataObject = {};

        for (const key in subDataArray[k].node) {
          dataObject[key] = subDataArray[k].node[key];
        }
        if (dataObject['extra'] === 0) {
          dataObject['extraText'] = 'Nej';
        } else {
          dataObject['extraText'] = 'Ja';
        }
        dataObject['projectInfo'] = projectInfo;
        dataObject['project'] = {};
        dataObject['project']['status'] = projectStatus;
        dataObject['project']['id'] = projectId;
        dataObjectsForForms.push(dataObject);
      }
    }

    return dataObjectsForForms;
  }

  sortExtra(data) {
    const sortedData = {
      normal: [],
      extra: [],
    };

    const dataArray = data;

    for (const dataIndex in dataArray) {
      const dataObject = dataArray[dataIndex];
      if (dataObject['extra'] === 1) {
        sortedData.extra.push(dataObject);
      } else {
        sortedData.normal.push(dataObject);
      }
    }
    return sortedData;
  }

  sortTimeHour(data) {
    const days = {
      daysData: [],
      daysTimeHour2Data: [],
    };

    for (const i in data) {
      days.daysData.push(data[i]);

      if (data[i].timeHour === 2) {
        const pushTH2Day = { ...data[i] };

        const th1ThisDayAsync: BehaviorSubject<any> = new BehaviorSubject([]);

        pushTH2Day['th1thisDay'] = [];
        pushTH2Day['th1thisDayAsync'] = th1ThisDayAsync;
        pushTH2Day['toggled'] = false;
        pushTH2Day['showSaveIcon'] = false;
        days.daysTimeHour2Data.push(pushTH2Day);
      }
    }

    for (const j in data) {
      if (data[j].timeHour === 1) {
        const pushTH1day = { ...data[j] };

        pushTH1day['extraBool'] = pushTH1day['extra'] === 1;
        pushTH1day['notarizedBool'] =
          pushTH1day['notarized'] > 0 && pushTH1day['notarized'] !== null;
        pushTH1day['companyCostTypeId'] =
          '' + pushTH1day['costTypeHyperion']['companyCostTypeId'];

        for (const k in days.daysTimeHour2Data) {
          if (days.daysTimeHour2Data[k].date === pushTH1day['date']) {
            pushTH1day['parentId'] = days.daysTimeHour2Data[k].id;

            days.daysTimeHour2Data[k]['th1thisDay'].push(pushTH1day);

            days.daysTimeHour2Data[k]['th1thisDayAsync'].next(
              days.daysTimeHour2Data[k]['th1thisDay']
            );
          }
        }
      }
    }

    return days;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }
}
