<div class="notification-container">
  <div class="notifications">
    <table>
      <thead>
        <tr>
          <th style="width: 5%"></th>
          <th style="width: 25%">Källa</th>
          <th style="width: 50%">Notifikationer (50 senaste)</th>
          <th style="width: 20%">Skapad</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let notice of notificationData">
          <td
            style="width: 5%"
            [class.not-read]="notice.closedByUserId === null"
            [class.is-error]="notice.type === 2"
          >
            <i
              class="fa"
              [class.fa-envelope]="notice.closedByUserId === null"
              [class.fa-envelope-open-o]="notice.closedByUserId !== null"
            ></i>
          </td>
          <td
            style="width: 25%"
            [class.not-read]="notice.closedByUserId === null"
            style="white-space: break-spaces"
            class="p-text-truncate"
          >
            <span
              >{{
                notice.sourceName_PreCompanyUserGlobalMessageTypeHyperion
                  | titlecase
              }}
            </span>
          </td>
          <td
            style="width: 50%"
            [class.not-read]="notice.closedByUserId === null"
          >
            <span>{{ notice.body }}</span>
          </td>
          <td
            style="width: 20%"
            [class.not-read]="notice.closedByUserId === null"
          >
            <span>{{ notice.created }}</span>
          </td>
        </tr>
        <tr *ngIf="notificationData?.length === 0">
          <td style="width: 100%">Finns inga notifikationer</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="notification-icons">
    <table>
      <thead>
        <tr>
          <th style="width: 60%" class="hover-pointer" (click)="markAsRead()">
            <i class="fa fa-check"></i> Markera alla som lästa
          </th>
          <th style="width: 20%"></th>
          <th style="width: 20%"></th>
        </tr>
      </thead>
    </table>
  </div>
</div>
