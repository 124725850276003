import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { AttendanceReportsListModule } from 'app/attendance-report/handle/attendance-reports-list/attendance-reports-list.module';
import { CollectionListComponent } from './collection-list.component';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    RadioButtonModule,
    TooltipModule,
    HyperionCardModule,
    HyperionSharedModule,
    LoadingModule,
    TableModule,
    AttendanceReportsListModule,
    HeaderModule,
  ],
  declarations: [CollectionListComponent],
})
export class CollectionListModule {}
