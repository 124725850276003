import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export const appStateOptions: AppStates = {
  INITIAL_STATE: 'initState',
  LOGGED_OUT: 'loggedOut',
  LOGGED_IN: 'loggedIn',
  STARTUP_IN_PROGRESS: 'startUpInProgress',
  STARTUP_DONE: 'startUpDone',
};

@Injectable()
export class AppStateService {
  private stateSubject = new BehaviorSubject<string>(
    appStateOptions.INITIAL_STATE
  );

  getAppState() {
    return this.stateSubject.asObservable();
  }

  getAppStateValue() {
    return this.stateSubject.value;
  }

  setAppState(stateParam) {
    this.stateSubject.next(stateParam);
  }

  getDummyObservable() {
    return Observable.create(observer => {
      observer.next('done');
      observer.complete();
    });
  }
}

export interface AppStates {
  INITIAL_STATE: string;
  LOGGED_OUT: string;
  LOGGED_IN: string;
  STARTUP_IN_PROGRESS: string;
  STARTUP_DONE: string;
}
