<app-header>
  <button
    pButton
    class="p-button-danger"
    icon="pi pi-sign-out"
    label="Logga ut"
    routerLink="/logout"
  ></button>
</app-header>

<router-outlet></router-outlet>
