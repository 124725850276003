<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading" class="p-grid">
  <p *ngIf="invoice['status'] != 1">
    <small
      >Underlag för {{ taxreducationTypeString }} visas när fakturan är markerad
      som betald.</small
    >
  </p>

  <div *ngIf="invoice['status'] == 1">
    <div class="p-col-12">
      <div class="p-col-6">
        <a
          href="{{ url }}/invoice/XmlRotRequesting?invoiceId={{ id }}"
          (click)="getXML()"
          >Hämta {{ taxreducationTypeString }}-XML för skatteverket</a
        >
        <br />
      </div>

      <div class="p-col-6">
        <appForm2-list-checkbox
          class="p-col-12"
          [ngModel]="invoice['statusRot']"
          [htmlAttributes]="{ label: { value: 'Markera som inkommen' } }"
          (ngModelChange)="updateStatusRot($event)"
        ></appForm2-list-checkbox>

        <small>
          <span
            style="color: #f00"
            *ngIf="
              invoice['status'] == 1 && invoice['statusRot'] == 0;
              else statusRot
            "
            >{{ taxreducationTypeString }} försenad</span
          >
          <ng-template #statusRot>
            <i style="float: left" *ngIf="invoice['statusRot'] == 0; else final"
              >{{ taxreducationTypeString }} ej inkommen</i
            >
          </ng-template>
          <ng-template #final>
            <i style="float: left">{{ taxreducationTypeString }} inkommen</i>
          </ng-template>
        </small>
      </div>
    </div>

    <div class="p-col-12">
      <h3>Exporter för {{ taxreducationTypeString }}</h3>
      <p-table [rowHover]="true" [value]="tableDatas">
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tableData>
          <tr>
            <td *ngFor="let col of cols">
              {{ tableData[col.field] }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
