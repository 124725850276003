import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistryComponent } from './registry.component';
import { RouterOutlet } from '@angular/router';
import { HeaderModule } from '../header/header.module';
import {
  CompanyProductsListComponent,
  CompanyProductsSettingsComponent,
  CreateEditProductComponent,
  ImportProductsComponent,
} from './product';
import { TooltipModule } from 'primeng/tooltip';
import { HyperionSharedModule } from '../shared/shared.module';
import { SettingsModule } from '../settings/settings.module';
import { TableModule } from 'primeng/table';
import { UserSettingsModule } from 'app/registry/users/user-settings.module';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    RegistryComponent,

    CompanyProductsSettingsComponent,
    CreateEditProductComponent,
    CompanyProductsListComponent,
    ImportProductsComponent,
  ],
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderModule,
    TooltipModule,
    HyperionSharedModule,
    SettingsModule,
    TableModule,
    UserSettingsModule,
    SidebarModule,
    CardModule,
    InputSwitchModule,
  ],
})
export class RegistryModule {}
