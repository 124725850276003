import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { InvoiceStateService } from '../services/invoice-state.service';
import { CompanyInvoiceService } from '../services/company-invoice.service';
import { InvoiceType } from '../enums/invoice-type.enum';
import { FormHandlerService } from '../../../shared/forms/index';
import { HyperionLabelsService } from '../../../shared/labels/index';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';

@Component({
  selector: 'app-struktur-invoice-footer',
  templateUrl: 'invoice-footer.component.html',
  styleUrls: ['./invoice-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoiceFooterComponent implements OnInit, OnDestroy, OnChanges {
  formFields = {
    model: 'Invoice',
    attributes: {
      invoiceFee: 0,
      totalBox: 0,
      sumBox: 0,
      momsBox: 0,
      bruttoBox: 0,
      rotBox: 0,
      rounding: 0,
      taxReductionWork: 0,
      taxReductionMaterial: 0,
    },
  };

  @Input() sum;
  @Input() mode;
  @Input() saving;
  @Input() public type;
  @Input() public invoice;
  @Output() saveInvoice = new EventEmitter<any>();
  @Output() closeDialog = new EventEmitter<any>();
  InvoiceType = InvoiceType;
  invoiceType = InvoiceType.Normal;
  private invoiceTypeSubscription: Subscription;
  labels: any;
  footerForm: FormGroup;
  fieldsForForm = {};
  showTaxReduction = false;

  constructor(
    private invoiceStateService: InvoiceStateService,
    private labelsService: HyperionLabelsService,
    private formHandler: FormHandlerService,
    private companyInvoiceService: CompanyInvoiceService,
    private globalInvoiceService: GlobalInvoiceService,
    private mailDialogService: MailDialogService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.getLabels();

    this.invoiceTypeSubscription =
      this.invoiceStateService.invoiceTypeState$.subscribe(event => {
        this.invoiceType = event;
        this.showTaxReduction = this.globalInvoiceService.isTaxReduction(
          this.invoiceType
        );
      });
    for (const key in Object.keys(this.sum)) {
      this.sum[key] = Number(this.sum[key]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.footerForm && this.footerForm.patchValue(this.sum);
  }

  ngOnDestroy = () => this.invoiceTypeSubscription.unsubscribe();

  public saveInvoiceEmit(event) {
    if (!this.saving) {
      this.saveInvoice.emit(event);
    }
  }

  closeDialogEmit = () => this.closeDialog.emit();

  private getLabels() {
    this.labelsService.getLabels([{ model: 'Invoice' }]).subscribe(data => {
      this.labels = data;
    });
  } // End getLabels

  initForm() {
    this.footerForm = this.formHandler.groupedFormSimple(this.formFields);
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.footerForm)
      .then();
    this.companyInvoiceService.updateForm('footer', this.footerForm);
  } // End initForm

  public showMailModal(invoiceId: string): void {
    if (invoiceId) {
      this.mailDialogService.openMailDialog({ invoiceId: invoiceId });
    }
  }
}
