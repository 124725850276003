enum PROJECT_DONE_STATE {
  NOT_DONE = 0,
  DONE = 1,
  DONE_WITH_CHANGES = 2,
}

export const projectDoneStateLabels = [
  { value: PROJECT_DONE_STATE.NOT_DONE, label: 'Inte klart' },
  { value: PROJECT_DONE_STATE.DONE, label: 'Klart' },
  {
    value: PROJECT_DONE_STATE.DONE_WITH_CHANGES,
    label: 'Klart - efterregistreringar',
  },
];

export default PROJECT_DONE_STATE;
