import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ContactsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ContactsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', contactSearch?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', id: string, trueId?: string | null, name?: string | null, orderBuisnessName?: string | null, address?: string | null, cityCode?: string | null, city?: string | null, type?: number | null, contactType?: string | null } | null } | null> | null } | null } | null };

export type GetContactQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetContactQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', contacts?: { __typename?: 'ContactConnection', edges?: Array<{ __typename?: 'ContactEdge', node?: { __typename?: 'Contact', propertyName?: string | null, orgNr?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, reverseTax?: number | null, betalningsvillkor?: number | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, mailInvoice?: string | null, address2?: string | null, id: string, trueId?: string | null, name?: string | null, orderBuisnessName?: string | null, address?: string | null, cityCode?: string | null, city?: string | null, type?: number | null, contactType?: string | null } | null } | null> | null } | null } | null };

export type ContactFragment = { __typename?: 'Contact', id: string, trueId?: string | null, name?: string | null, orderBuisnessName?: string | null, address?: string | null, cityCode?: string | null, city?: string | null, type?: number | null, contactType?: string | null };

export type FullContactFragment = { __typename?: 'Contact', propertyName?: string | null, orgNr?: string | null, housingAssociationOrgNumber?: string | null, apartmentDesignation?: string | null, reverseTax?: number | null, betalningsvillkor?: number | null, phone?: string | null, mobilePhone?: string | null, mail?: string | null, mailInvoice?: string | null, address2?: string | null, id: string, trueId?: string | null, name?: string | null, orderBuisnessName?: string | null, address?: string | null, cityCode?: string | null, city?: string | null, type?: number | null, contactType?: string | null };

export const ContactFragmentDoc = gql`
    fragment Contact on Contact {
  id
  trueId
  name
  orderBuisnessName
  address
  cityCode
  city
  type
  contactType
}
    `;
export const FullContactFragmentDoc = gql`
    fragment FullContact on Contact {
  ...Contact
  propertyName
  orgNr
  housingAssociationOrgNumber
  apartmentDesignation
  reverseTax
  betalningsvillkor
  phone
  mobilePhone
  mail
  mailInvoice
  address2
}
    ${ContactFragmentDoc}`;
export const ContactsDocument = gql`
    query contacts($search: String) {
  company {
    contactSearch(search: $search) {
      edges {
        node {
          ...Contact
        }
      }
    }
  }
}
    ${ContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContactsGQL extends Apollo.Query<ContactsQuery, ContactsQueryVariables> {
    document = ContactsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetContactDocument = gql`
    query getContact($id: Int) {
  company {
    contacts(id: $id) {
      edges {
        node {
          ...FullContact
        }
      }
    }
  }
}
    ${FullContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetContactGQL extends Apollo.Query<GetContactQuery, GetContactQueryVariables> {
    document = GetContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }