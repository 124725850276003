import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UpdateProjectMutationVariables = Types.Exact<{
  updateProject?: Types.InputMaybe<Types.ProjectTypeHyperionMutationInput>;
}>;


export type UpdateProjectMutation = { __typename?: 'HyperionSchemaMutation', projectTypeHyperionMutation?: { __typename?: 'Project', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const UpdateProjectDocument = gql`
    mutation updateProject($updateProject: ProjectTypeHyperionMutationInput) {
  projectTypeHyperionMutation(updateProject: $updateProject) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
    id
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }