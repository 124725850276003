import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import * as currency from 'currency.js';

import { SharedServiceFunctions } from '../shared-service-functions';
import { HelperService } from 'app/shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';
import { InvoiceType } from 'app/invoice/single/enums/invoice-type.enum';
import { ContactValidator } from 'app/shared/contacts/contact-validator';

export interface HouseWorkType {
  type: string;
  name: string;
  description: string;
  reductionPercentage?: number;
}

export interface HouseWorkTypes {
  [InvoiceType.Rot]: HouseWorkType[];
  [InvoiceType.Rut]: HouseWorkType[];
  [InvoiceType.Green]: HouseWorkType[];
}

@Injectable({
  providedIn: 'root',
})
export class SharedInvoiceServiceFunctions extends SharedServiceFunctions {
  // costumer invoices
  public invoices = new BehaviorSubject<any>([]);
  invoicesAvailable$ = this.invoices.asObservable();
  public countInvoices = new BehaviorSubject<any>(undefined);
  countInvoicesAvailable$ = this.countInvoices.asObservable();
  public invoicesList = new BehaviorSubject<any>([]);
  invoicesListAvailable$ = this.invoicesList.asObservable();
  private invoicesOrder = [];

  // supplier invoices
  public supplierInvoices = new BehaviorSubject<any>([]);
  supplierInvoicesAvailable$ = this.supplierInvoices.asObservable();
  public countSupplierInvoices = new BehaviorSubject<any>(undefined);
  countSupplierInvoicesAvailable$ = this.countSupplierInvoices.asObservable();
  public supplierInvoicesList = new BehaviorSubject<any>([]);
  supplierInvoicesListAvailable$ = this.supplierInvoicesList.asObservable();

  private readonly isCommentRow = 99;

  public readonly taxReductionPercentage = {
    [InvoiceType.Rot]: _ => 30,
    [InvoiceType.Rut]: houseWorkType => {
      switch (houseWorkType?.type) {
        case 'TRANSPORTATIONSERVICES':
        case 'WASHINGANDCAREOFCLOTHING':
          return 25;
        default:
          return 50;
      }
    },
  };

  public readonly taxReductionInvoiceTypes = [
    InvoiceType.Rot,
    InvoiceType.Rut,
    InvoiceType.Green,
  ];
  public readonly houseWorkTypes: HouseWorkTypes = {
    [InvoiceType.Rot]: [
      { type: 'CONSTRUCTION', name: 'Bygg', description: 'Bygg' },
      { type: 'ELECTRICITY', name: 'El', description: 'El' },
      {
        type: 'GLASSMETALWORK',
        name: 'GlasPlatarbete',
        description: 'Glas & Plåtarbete',
      },
      {
        type: 'GROUNDDRAINAGEWORK',
        name: 'MarkDraneringarbete',
        description: 'Mark & Dräneringarbete',
      },
      { type: 'MASONRY', name: 'Murning', description: 'Murning' },
      {
        type: 'PAINTINGWALLPAPERING',
        name: 'MalningTapetsering',
        description: 'Målning & Tapetsering',
      },
      { type: 'HVAC', name: 'Vvs', description: 'VVS' },
      {
        type: 'OTHERCOSTS',
        name: 'OvrigKostnad',
        description: 'Övrig kostnad',
      },
    ],
    [InvoiceType.Rut]: [
      {
        type: 'HOMEMAINTENANCE',
        name: 'TillsynAvBostad',
        description: 'Tillsyn av bostad',
      },
      { type: 'FURNISHING', name: 'Moblering', description: 'Möblering' },
      {
        type: 'TRANSPORTATIONSERVICES',
        name: 'TransportTillForsaljning',
        description: 'Transport till försäljning',
      },
      {
        type: 'WASHINGANDCAREOFCLOTHING',
        name: 'TvattVidTvattinrattning',
        description: 'Tvätt vid tvättinrättning',
      },
      {
        type: 'MAJORAPPLIANCEREPAIR',
        name: 'ReparationAvVitvaror',
        description: 'Rep. vitvaror',
      },
      {
        type: 'MOVINGSERVICES',
        name: 'Flyttjanster',
        description: 'Flyttjänster',
      },
      { type: 'ITSERVICES', name: 'ItTjanster', description: 'It-tjänster' },
      { type: 'CLEANING', name: 'Stadning', description: 'Städning' },
      {
        type: 'TEXTILECLOTHING',
        name: 'KladOchTextilvard',
        description: 'Kläd och textilvård',
      },
      {
        type: 'SNOWPLOWING',
        name: 'Snoskottning',
        description: 'Snöskottning',
      },
      {
        type: 'GARDENING',
        name: 'Tradgardsarbete',
        description: 'Trädgardsarbete',
      },
      {
        type: 'BABYSITTING',
        name: 'Barnpassning',
        description: 'Barnpassning',
      },
      {
        type: 'OTHERCARE',
        name: 'PersonligOmsorg',
        description: 'Personlig omsorg',
      },
      {
        type: 'OTHERCOSTS',
        name: 'OvrigKostnad',
        description: 'Övrig kostnad',
      },
    ],
    [InvoiceType.Green]: [
      {
        type: 'SOLARCELLS',
        name: 'Solceller',
        description: 'Solceller',
        reductionPercentage: 20,
      },
      {
        type: 'STORAGESELFPRODUCEDELECTRICITY',
        name: 'LagringEgenproduceradEl',
        description: 'Lagring egenproducerad el',
        reductionPercentage: 50,
      },
      {
        type: 'CHARGINGSTATIONELECTRICVEHICLE',
        name: 'LaddningspunktElfordon',
        description: 'Laddningspunkt elfordon',
        reductionPercentage: 50,
      },
    ],
  };

  public readonly invoiceTypeString = {
    [InvoiceType.Rot]: 'ROT',
    [InvoiceType.Rut]: 'RUT',
    [InvoiceType.Green]: 'Grön',
  };

  public personsAssociatedWithProject = new BehaviorSubject<any>([]);
  personsAssociatedWithProject$ =
    this.personsAssociatedWithProject.asObservable();

  public helperService: HelperService;
  public apolloQueryService: ApolloQueryService;

  objectToArray(object) {
    const orderStore = this.invoicesOrder;
    return Object.keys(object)
      .sort((a, b) => {
        if (orderStore[a] && orderStore[b]) {
          if (orderStore[a] > orderStore[b]) {
            return 1;
          } else if (orderStore[a] < orderStore[b]) {
            return -1;
          }
        }
        return 0;
      })
      .map(key => object[key]);
  }

  public clearData() {
    this.listObject = {};
    this.dataObject = {};

    this.supplierInvoices.next(this.objectToArray(this.dataObject)); // Maka all invoice data available for components listning
    this.supplierInvoicesList.next(this.objectToArray(this.listObject)); // Maka invoice list available for components listning

    this.invoices.next(this.objectToArray(this.dataObject)); // Maka all invoice data available for components listning
    this.invoicesList.next(this.objectToArray(this.listObject)); //
  }

  public setData(object, listOrderIndex) {
    const projects = this.helperService.cleanFromNode(
      object.projects_PreInvoiceTypeHyperion
    );
    const projectsTrueIds = [];
    const projectsMark = [];

    let connectedToProjects = false;
    for (const i in projects) {
      projectsTrueIds.push(projects[i].trueId);
      projectsMark.push(projects[i].mark);

      connectedToProjects = true;
    }
    const projectsTrueIdString = projectsTrueIds.length
      ? projectsTrueIds.join()
      : 'Ej knuten';
    const projectsMarkString = projectsMark.length
      ? projectsMark.join()
      : 'Ej knuten';

    let taxreductionStatusCalculatedAsString = 'pending';

    /* eslint-disable eqeqeq */
    if (object.statusRot == 1) {
      taxreductionStatusCalculatedAsString = 'in';
    } else if (
      this.isValidDate(object.payDate) &&
      this.addDays(object.payDate).setHours(0, 0, 0, 0) <
        new Date().setHours(0, 0, 0, 0) &&
      object.statusRot == 0
    ) {
      taxreductionStatusCalculatedAsString = 'late';
    }
    let contactListName = '';
    if (object.contact) {
      contactListName = object.contact.orderBuisnessName;
    }

    let contactName = '';
    if (object.contact) {
      contactName = object.contact.name;
    }

    if (contactName && !contactListName) {
      contactListName = contactName;
    } else if (contactName && contactListName) {
      contactListName += ', ' + contactName;
    }

    const listObject = {
      ...object,
      id: Number(object.id),

      totalBox: Number(object.totalBox),
      fakturaNr: Number(object.fakturaNr),
      sumBox: Number(object.sumBox),
      ContactTrueId: ContactValidator.isValidTrueId(object?.contact?.trueId)
        ? object.contact.trueId
        : '-',
      ContactName: contactName,
      ContactOrderBuisnessName: contactListName,

      ContactAddress: object?.contact?.address,
      ContactCity: object?.contact?.city,
      ContactPhone: object?.contact?.phone,

      ProjectsTrueId: projectsTrueIdString,
      ProjectsMark: projectsMarkString,
      ConnectedToProjects: connectedToProjects,
      TaxreductionStatusCalculatedAsString:
        taxreductionStatusCalculatedAsString,

      fdatum2: object.fdatum,
    };

    const invoice = {
      id: Number(object.id),
      data: listObject,
      calculateData: [],
    };

    this.invoicesOrder[invoice['id']] = listOrderIndex;

    this.dataObject[invoice['id']] = invoice;
    this.listObject[invoice['id']] = listObject;
  }

  cleanAndStoreData(data) {
    const invoices = this.helperService.cleanFromNode(
      data.data.company.invoices
    );
    if (!invoices || !invoices.length) {
      return;
    }

    let indexOffset = 0;
    if (
      data &&
      data.queryOptions &&
      data.queryOptions.variables &&
      data.queryOptions.variables.offset
    ) {
      indexOffset = data.queryOptions.variables.offset;
    }

    invoices.forEach((invoice, index) => {
      if (
        typeof invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion'] !==
        'undefined'
      ) {
        invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion'] =
          this.helperService.cleanFromNode(
            invoice['invoiceSourceDocuments_PreInvoiceTypeHyperion']
          );
      }

      this.setData(invoice, index + indexOffset);
    });
    this.setNext(invoices[invoices.length - 1]);
  }

  getInvoicesByQueryType(query, variables, type = 'customer') {
    this.apolloQueryService
      .apolloWatchQueryTwoQuery(query, variables, 'cache-and-network')
      .subscribe(data => {
        const subs = data.sub;
        if (type === 'supplier') {
          this.countSupplierInvoices.next(
            data.data.company.invoices.totalCount
          );
        } else {
          this.countInvoices.next(data.data.company.invoices.totalCount);
        }

        this.cleanAndStoreData(data);

        setTimeout(() => {
          subs && subs.unsubscribe();
        }, 10000);
      });
  }

  protected getPaginatedInvoicesByQueryType(query, type = 'customer'): void {
    const totalCount = new BehaviorSubject<any>(0);
    const chunkSize = 1000;

    this.apolloQueryService
      .apolloWatchQueryTwoQuery(
        query,
        { last: 100, offset: 0 },
        'cache-and-network'
      )
      .subscribe(data => {
        const subs = data.sub;
        totalCount.next(data.data.company.invoices.totalCount);
        if (type === 'supplier') {
          this.countSupplierInvoices.next(
            data.data.company.invoices.totalCount
          );
        } else {
          this.countInvoices.next(data.data.company.invoices.totalCount);
        }

        this.cleanAndStoreData(data);

        setTimeout(() => {
          subs && subs.unsubscribe();
        }, 1000);
      });

    totalCount.subscribe(value => {
      if (value === 0) {
        return;
      }

      let startIndex = 500;

      const pages = [];
      // Prioritize loading the first 500 so we can fill the first page regardless of size
      pages.push({
        last: 400,
        offset: 100,
      });

      while (startIndex < value) {
        pages.push({
          last: chunkSize,
          offset: startIndex,
        });
        startIndex += chunkSize;
      }

      pages.forEach(page => {
        this.apolloQueryService
          .apolloWatchQueryTwoQuery(query, page, 'cache-and-network')
          .subscribe(data => {
            const subs = data.sub;
            this.cleanAndStoreData(data);
            setTimeout(() => {
              subs && subs.unsubscribe();
            }, 1000);
          });
      });
    });
  }

  delete(invoiceId) {
    delete this.listObject[invoiceId];
    delete this.dataObject[invoiceId];

    this.setNext({ type: 1337 });
  }

  setNext(invoice) {
    if (!invoice) {
      return;
    }

    const da = this.objectToArray(this.dataObject);
    const la = this.objectToArray(this.listObject);

    if (+invoice['type'] === 1 || +invoice['type'] === 1337) {
      this.supplierInvoices.next(
        da.filter(dataArrayInvoice => +dataArrayInvoice['type'] === 1)
      ); // Maka all invoice data available for components listning
      this.supplierInvoicesList.next(
        la.filter(listArrayInvoice => +listArrayInvoice['type'] === 1)
      ); // Maka invoice list available for components listning
    }
    if (+invoice['type'] === 0 || +invoice['type'] === 1337) {
      this.invoices.next(
        da.filter(dataArrayInvoice => +dataArrayInvoice['type'] === 0)
      ); // Maka all invoice data available for components listning
      this.invoicesList.next(
        la.filter(listArrayInvoice => +listArrayInvoice['type'] === 0)
      ); // Maka invoice list available for components listning
    }
  }

  addDays(date, days = 9) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  isValidDate(dateString) {
    let retval = false;
    if (typeof dateString === 'string') {
      const regEx = /^\d{4}-\d{2}-\d{2}$/;
      retval = dateString.match(regEx) != null;
    }
    return retval;
  }

  public modifyContainerRows = container => {
    // Change row values based on tax and discounts.
    container = this.replaceCommaWithDotOnInvoiceRows(container);
    container.rows.map(row => {
      row.apris = row.tax !== this.isCommentRow ? row.apris : 0;
      row.antal = row.tax !== this.isCommentRow ? row.antal : 0;
      if (row.discount <= 0) {
        row.pris = currency(row.apris).multiply(row.antal);
      } else {
        const discountMultiplier = currency(100, { precision: 4 })
          .subtract(row.discount)
          .divide(100);

        row.pris = currency(row.apris)
          .multiply(row.antal)
          .multiply(discountMultiplier);
      }
    });
    return container;
  };

  public sumRows = (
    container: any,
    invoiceAmounts: any,
    typeInvoice: number
  ): any => {
    container.rows.forEach(row => {
      invoiceAmounts.net = currency(invoiceAmounts.net).add(row.pris);
      invoiceAmounts.exclVat = currency(invoiceAmounts.exclVat).add(row.pris);
      invoiceAmounts.gross = currency(invoiceAmounts.gross).add(
        currency(row.apris).multiply(row.antal)
      );
      invoiceAmounts.vat = currency(invoiceAmounts.vat, { precision: 4 }).add(
        currency(row.pris, { precision: 4 }).multiply(row.tax / 100)
      );
      invoiceAmounts.workCost = currency(invoiceAmounts.workCost).add(
        row.arbetskostnad ? row.pris : 0
      );
      invoiceAmounts.workVat = currency(invoiceAmounts.workVat).add(
        row.arbetskostnad ? currency(row.pris).multiply(row.tax / 100) : 0
      );

      if (row.arbetskostnad) {
        const taxReduction = this.calculateTaxReductionPerRow(typeInvoice, row);
        invoiceAmounts.taxReduction = currency(invoiceAmounts.taxReduction).add(
          taxReduction
        );
        invoiceAmounts.taxReductionWork = currency(
          invoiceAmounts.taxReductionWork
        ).add(taxReduction);
      } else if (typeInvoice === InvoiceType.Green && row.houseWorkType) {
        const taxReduction = this.calculateTaxReductionPerRow(typeInvoice, row);
        invoiceAmounts.taxReduction = currency(invoiceAmounts.taxReduction).add(
          taxReduction
        );
        invoiceAmounts.taxReductionMaterial = currency(
          invoiceAmounts.taxReductionMaterial
        ).add(taxReduction);
      }
    });
    return invoiceAmounts;
  };

  public calculateInvoiceAmounts = invoiceAmounts => {
    invoiceAmounts.taxReduction = currency(
      Math.floor(invoiceAmounts.taxReduction.value)
    );
    // Round to two decimals
    invoiceAmounts.vat = currency(invoiceAmounts.vat);

    invoiceAmounts.sum = currency(invoiceAmounts.net)
      .add(invoiceAmounts.vat)
      .subtract(invoiceAmounts.taxReduction);
    invoiceAmounts.sumRounded = currency(invoiceAmounts.sum, { precision: 0 });
    invoiceAmounts.rounded = currency(invoiceAmounts.sumRounded).subtract(
      invoiceAmounts.sum
    );

    invoiceAmounts.total = currency(invoiceAmounts.sum).add(
      invoiceAmounts.rounded
    );

    return invoiceAmounts;
  };

  private calculateTaxReductionPerRow = (
    typeInvoice: number,
    row: any
  ): any => {
    let reductionPercentage = 0;
    if (typeInvoice === InvoiceType.Green) {
      const houseworkType = this.houseWorkTypes[InvoiceType.Green].find(
        houseWorkType => houseWorkType.type === row.houseWorkType
      );
      reductionPercentage = houseworkType
        ? houseworkType.reductionPercentage
        : 0;
    } else if (typeInvoice !== InvoiceType.Normal) {
      const houseWorkType = this.houseWorkTypes[typeInvoice].find(
        houseWorkType => houseWorkType.type === row.houseWorkType
      );
      reductionPercentage =
        this.taxReductionPercentage[typeInvoice](houseWorkType) || 0;
    }

    const priceWithVat = currency(row.pris).add(
      currency(row.pris).multiply(row.tax / 100)
    );
    return currency(priceWithVat).multiply(reductionPercentage / 100);
  };

  public replaceCommaWithDotOnInvoiceRows = (container: any) => {
    container.rows.forEach(row => {
      row.apris = row.apris
        ? this.replaceCommaWithDot(row.apris.toString())
        : row.apris;
      row.antal = row.antal
        ? this.replaceCommaWithDot(row.antal.toString())
        : row.antal;
      row.discount = row.discount
        ? this.replaceCommaWithDot(row.discount.toString())
        : row.discount;
    });
    return container;
  };

  private replaceCommaWithDot = value => {
    return value.replace(/,/, '.');
  };
}
