import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class MenuService {
  menuChange: Subject<any> = new Subject<any>();

  menuIsSet = false;

  constructor() {}

  setMenu(menuParam) {
    this.menuIsSet = true;

    this.menuChange.next(menuParam);
  }

  eraseMenu() {
    this.menuIsSet = false;
  }
}
