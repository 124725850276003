import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FetchProjectQuery } from '../graphql/project.generated';

@Component({
  selector: 'app-project-economy',
  templateUrl: './project-economy.component.html',
  styleUrls: ['./project-economy.component.scss'],
})
export class ProjectEconomyComponent {
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Output() public changed: EventEmitter<any> = new EventEmitter();
}
