<div class="header-container">
  <img class="icon" [src]="icon" />
  <h1>
    {{ title | async }}
    <span
      *ngIf="headerTextAsync | async"
      style="color: var(--primary-color-500)"
    >
      {{ headerTextAsync | async }}
    </span>
  </h1>
  <div class="action-section">
    <ng-content> </ng-content>
  </div>
</div>
<app-sub-menu class="p-d-block"></app-sub-menu>
