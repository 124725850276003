<div class="p-grid single-offer">
  <div class="p-col-12 p-g-nopad">
    <div
      class="p-col-12 p-md-6"
      style="white-space: nowrap; overflow: hidden; text-overflow: clip"
    >
      <h2>Dokument</h2>
      <p>{{ dataObjectFromParent.file }}</p>
      <a [attr.href]="documentUrl" target="_blank">
        <i class="fa fa-download" pTooltip="Hämta" tooltipPosition="top"></i>
      </a>
      <a
        [attr.href]="documentPreviewUrl"
        (click)="openDocument()"
        target="struqtur-preview"
        style="margin-left: 10px"
      >
        <i
          class="fa fa-file-o"
          pTooltip="Visa dokument"
          tooltipPosition="top"
        ></i>
      </a>
    </div>
    <div class="p-col-12 p-md-6 p-g-nopad">
      <form [formGroup]="componentMainForm" class="no-margin">
        <appForm2-simple-textarea
          [rows]="2"
          formControlName="comment"
        ></appForm2-simple-textarea>
      </form>
    </div>
  </div>

  <div class="p-col-12 p-g-nopad align-right">
    <app-button
      [disabled]="buttons['document']['delete']['disabled'] | async"
      (onClick)="actionDelete()"
      [type]="'delete'"
    ></app-button>
    <app-button
      class="ml5"
      [disabled]="buttons['document']['update']['disabled'] | async"
      (onClick)="actionUpdate()"
      [type]="'update'"
    ></app-button>
  </div>
</div>
