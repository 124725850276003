<app-header>
  <button
    pButton
    class="p-button-primary-hyperion"
    label="Skapa tidrapport"
    icon="pi pi-plus"
    (click)="isDayFormVisible = true"
  ></button>
</app-header>

<div class="page-container">
  <div class="p-grid print-no-show">
    <div class="p-col-12 p-grid p-ai-center">
      <div
        class="p-col-12 p-lg-3"
        *ngIf="meUser?.type === '3'"
        tooltipPosition="top"
        pTooltip="Välj person / visa alla"
      >
        <label>Välj Person(er)</label>
        <p-multiSelect
          [options]="usersDropdown"
          [(ngModel)]="showDaysForUsers"
          defaultLabel="Välj person(er)"
          [style]="{ width: '100%' }"
          (onChange)="changeUsers()"
          selectedItemsLabel="{0} valda personer"
          [overlayOptions]="{ appendTo: 'body' }"
        ></p-multiSelect>
      </div>
      <div class="p-col-12 p-lg-2" tooltipPosition="top" pTooltip="Välj år">
        <label>Välj år</label>

        <p-dropdown
          [(ngModel)]="advancedVariables.checkedPeriodYearValue"
          (ngModelChange)="changeYear()"
          [options]="yearsDropdown"
        ></p-dropdown>
      </div>
      <div class="p-col-12 p-lg-3" tooltipPosition="top" pTooltip="Välj vecka">
        <label>Välj vecka</label>
        <div class="p-inputgroup">
          <button
            pButton
            class="p-inputgroup-addon"
            icon="pi pi-chevron-left"
            (click)="changeWeek('down')"
          ></button>
          <p-dropdown
            [(ngModel)]="advancedVariables.checkedPeriodTypeValue"
            [options]="weeksDropdown"
          ></p-dropdown>
          <button
            class="p-inputgroup-addon"
            pButton
            icon="pi pi-chevron-right"
            (click)="changeWeek('up')"
          ></button>
        </div>
      </div>
      <div class="p-col-12 p-lg-4 align-left">
        <label></label>
        <button
          pButton
          [disabled]="loading | async"
          (click)="getDays()"
          label="Visa översikt"
          style="display: flex"
        ></button>
      </div>
    </div>
  </div>

  <div class="p-grid advanced-body-section">
    <div class="p-col-12">
      <p-card>
        <div class="p-grid">
          <div
            *ngIf="
              (beforeFirst | async) === false && (loading | async) === false
            "
            class="p-col-12 align-right print-no-show"
          >
            <button
              pButton
              [disabled]="loading | async"
              (click)="mark('mark')"
              icon="pi pi-check-circle"
              label="Markera alla"
            ></button>
            <button
              pButton
              class="p-ml-2"
              [disabled]="loading | async"
              (click)="mark('markNotExported')"
              icon="pi pi-info-circle"
              label="Markera ej exporterade"
            ></button>
            <button
              pButton
              class="p-ml-2"
              [disabled]="loading | async"
              (click)="mark('unmark')"
              label="Avmarkera alla"
              icon="pi pi-circle"
            ></button>
          </div>

          <div class="p-col-12">
            <div class="table-container">
              <div
                *ngIf="(beforeFirst | async) && (loading | async) === false"
                class="align-center"
              >
                <app-emptyState model="Day"></app-emptyState>
              </div>
              <spinner *ngIf="loading | async"></spinner>

              <table
                *ngIf="
                  (beforeFirst | async) === false && (loading | async) === false
                "
                class="holder-table"
              >
                <thead>
                  <tr>
                    <th style="width: 21%">Projekt</th>
                    <th style="width: 10%" *ngFor="let date of dates">
                      <div [class.weekend]="date.type === 'weekend'">
                        {{ date.name }}
                      </div>
                      <div [class.weekend]="date.type === 'weekend'">
                        {{ date.date }}
                      </div>
                    </th>
                    <th style="width: 9%">
                      <div>
                        <br />Summa vecka
                        {{ advancedVariables.checkedPeriodTypeValue }}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <div *ngFor="let userData of dataSet">
                    <tr>
                      <td colspan="9" class="align-center strong-text">
                        {{ userData.user.firstName }}
                        {{ userData.user.lastName }}
                      </td>
                    </tr>
                    <tr *ngFor="let project of userData.projectsArray">
                      <td style="width: 21%">
                        {{ project.data.trueId }}, {{ project.data.mark }}
                      </td>
                      <td
                        class="align-center"
                        style="width: 10%"
                        *ngFor="let date of dates"
                      >
                        <div
                          *ngIf="
                            returnHasOwnPropertyDay(project.dates[date['date']])
                          "
                        >
                          <div
                            *ngFor="let day of project.dates[date.date].days"
                            class="inner-day"
                          >
                            <div [class.has-error]="!day.error.status">
                              <div *ngIf="!day.error.status">
                                <i
                                  class="fa fa-exclamation-triangle"
                                  pTooltip="<p><strong>Dagen sparades inte</strong></p><p>{{
                                    day.error.msg
                                  }}</p>"
                                  tooltipPosition="bottom"
                                  [escape]="false"
                                ></i>
                              </div>
                              <div>
                                <table class="inner-table">
                                  <tr>
                                    <td>
                                      <span
                                        pTooltip="<p>Timmar</p>"
                                        tooltipPosition="bottom"
                                        [escape]="false"
                                        >H</span
                                      >
                                    </td>
                                    <td
                                      pTooltip="<p><strong>Utfört arbete</strong></p><p>{{
                                        day.day.doneWork
                                      }}</p>"
                                      tooltipPosition="bottom"
                                      [escape]="false"
                                      class="td-print-toggle"
                                    >
                                      {{ day.day.hours }}
                                    </td>
                                    <td>
                                      <span
                                        pTooltip="<p>Mil</p>"
                                        tooltipPosition="bottom"
                                        [escape]="false"
                                      >
                                        <!--
                                                                        M
                                                                    -->
                                      </span>
                                    </td>
                                    <td class="td-print-toggle">
                                      <!--
                                                                        {{day.day.privMile}}
                                                                        -->
                                    </td>
                                  </tr>
                                  <tr class="notarize-tr">
                                    <td
                                      [pTooltip]="
                                        day.isNotarized
                                          ? day.notarizedUser.trim() !== ''
                                            ? 'Attesterad av ' +
                                              day.notarizedUser
                                            : 'Attesterad'
                                          : 'Är inte attesterad'
                                      "
                                      class="align-left"
                                    >
                                      <span>
                                        <i
                                          *ngIf="day.isNotarized"
                                          class="pi pi-pencil"
                                          style="
                                            color: var(--brand-primary-color);
                                          "
                                        ></i>
                                        <i
                                          *ngIf="!day.isNotarized"
                                          class="pi pi-pencil"
                                          style="color: #8080801f"
                                        ></i>
                                      </span>
                                    </td>

                                    <td
                                      [pTooltip]="
                                        day.isExported
                                          ? day.exportedByUser.trim() !== ''
                                            ? 'Exporterad av ' +
                                              day.exportedByUser
                                            : 'Exporterad'
                                          : 'Är inte exporterad'
                                      "
                                    >
                                      <span>
                                        <i
                                          *ngIf="day.isExported"
                                          class="pi pi-check"
                                          style="
                                            color: var(--brand-primary-color);
                                          "
                                        ></i>
                                        <i
                                          *ngIf="!day.isExported"
                                          class="pi pi-check"
                                          style="color: #8080801f"
                                        ></i>
                                      </span>
                                    </td>
                                    <td></td>
                                    <td>
                                      <span
                                        pTooltip="<p>Markera för export</p>"
                                        tooltipPosition="bottom"
                                        [escape]="false"
                                        ><p-checkbox
                                          [binary]="true"
                                          [(ngModel)]="day.isMarkedForExport"
                                          (onChange)="onMark($event, day)"
                                        ></p-checkbox
                                      ></span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colspan="3" class="subsistence-cell">
                                      {{ day.day.overtimeHardCodedFullName }}
                                    </td>
                                  </tr>
                                  <tr *ngIf="day.day.subsistenceDay === '1'">
                                    <td colspan="3" class="subsistence-cell">
                                      Heldagstraktamente
                                    </td>
                                  </tr>
                                  <tr
                                    *ngIf="day.day.subsistenceHalfDay === '1'"
                                  >
                                    <td colspan="3" class="subsistence-cell">
                                      Halvdagtraktamente
                                    </td>
                                  </tr>
                                  <tr *ngIf="day.day.subsistenceNight === '1'">
                                    <td colspan="3" class="subsistence-cell">
                                      Natttraktamente
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style="width: 9%"></td>
                    </tr>
                    <tr style="border-bottom: solid thin rgb(56, 56, 56)">
                      <td style="width: 21%">Totalt</td>
                      <td
                        style="width: 10%"
                        class="align-center"
                        *ngFor="let date of dates"
                      >
                        <span
                          pTooltip="<p><strong>Totalt {{
                            date.date
                          }}</strong></p>"
                          tooltipPosition="bottom"
                          [escape]="false"
                          >{{ userData.dateSum[date['date']] }}</span
                        >
                      </td>
                      <td style="width: 9%">
                        <span
                          pTooltip="<p>Summa för {{
                            userData.user.firstName
                          }} {{ userData.user.lastName }} mellan</p><p>{{
                            dates[0].date
                          }} och {{ dates[dates.length - 1].date }}</p>"
                          tooltipPosition="bottom"
                          [escape]="false"
                        >
                          = {{ userData.total.dateSumTotal }}</span
                        >
                      </td>
                    </tr>
                  </div>
                </tbody>
              </table>
            </div>
          </div>

          <div
            class="p-col-12 align-right print-no-show"
            *ngIf="
              (beforeFirst | async) === false &&
              (loading | async) === false &&
              dataSet.length > 0
            "
          >
            <button
              pButton
              class="p-button-primary-hyperion"
              [disabled]="loading | async"
              (click)="sendReports()"
              label="Exportera valda rapporter till fortnox"
            ></button>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <p-sidebar
    showCloseIcon="true"
    [(visible)]="isDayFormVisible"
    position="right"
  >
    <h1>Skapa tidrapport</h1>
    <app-day-form
      [productsEnabled]="true"
      [storeLastUsedProject]="false"
    ></app-day-form>
  </p-sidebar>
</div>
