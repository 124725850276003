import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyDivisionService } from '../../../shared/company/index';
import { GlobalService } from '../../../shared/global/index';
import { HelperService } from 'app/shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'companyDivision-settings',
  templateUrl: 'company-division-settings.component.html',
})
export class CompanyDivisionSettingsComponent implements OnInit, OnDestroy {
  @Input() isModal = false;
  loading = true;
  dataModel = 'companyDivision';
  dataSet = [];
  createDataObjectArray = [{ fakeId: 0 }];
  componentPrefix;
  sub: Subscription;

  constructor(
    private companyDivisionService: CompanyDivisionService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private globalService: GlobalService
  ) {}

  ngOnInit() {
    this.componentPrefix = this.globalService.generateComponentPrefix();
    this.queryDataSet();
  }

  private queryDataSet() {
    this.apolloQueryService
      .apolloWatchQueryTwo(this.dataModel)
      .subscribe(({ data, sub }) => {
        this.loading = false;
        this.sub = sub;
        this.dataSet = this.companyDivisionService.dataFormater(data, 'list');
      });
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  dataRemoved(e) {
    const index = this.dataSet.findIndex(i => +i.id === +e.id);
    this.dataSet.splice(index, 1);
  }

  spliceDeletedDataObject(createComponentObj) {
    // Is this used?
    const index = this.createDataObjectArray.indexOf(createComponentObj);
    this.createDataObjectArray.splice(index, 1);
  }

  addCreateComponent(createComponentObj) {
    if (
      this.createDataObjectArray.indexOf(createComponentObj) ===
      this.createDataObjectArray.length - 1
    ) {
      const latest =
        this.createDataObjectArray[this.createDataObjectArray.length - 1];
      const newFakeId = latest['fakeId'] + 1;
      const pushThis = { fakeId: newFakeId };

      this.createDataObjectArray.push(pushThis);
    }
  }
}
