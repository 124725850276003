<app-feature-preview
  image="/images/hyperion/feature-previews/projekt.jpg"
  feature="Projekt"
>
  <p>
    Med {{ appName }} får du fullständig kontroll på dina projekt och missar
    inga intäkter eller kostnader. Du samlar här all information om projektet
    såsom kunduppgifter, ritningar, egenkontroller/checklistor, bilder,
    tidrapporter samt använt material.
  </p>

  <p>
    Du kan på detta sätt skicka fakturor på nolltid och får full kontroll på hur
    projekten går. Om du skapar ett projekt från en offert följer informationen
    med och du slipper dubbelarbete och sparar ännu mer tid.
  </p>

  <p>
    I {{ appName }} får du all information på ett och samma ställe vilket bl.a.
    innebär tidrapporter, material, kontaktuppgifter, arbetsmoment och dokument.
    Du kan även spara bilder i varje projekt för att dokumentera ert
    professionella arbete. Informationen finns tillgängligt via dator,
    surfplatta och mobil.
  </p>
</app-feature-preview>
