<div class="p-grid" [style]="{ position: 'relative' }">
  <div
    *ngIf="isLoading"
    class="p-col p-d-flex p-jc-center p-ai-center spinner"
    [class.hide]="loadingOverlayHidingInProgress"
    [style]="{ 'transition-duration': loadingOverlayTransitionDuration + 'ms' }"
  >
    <spinner></spinner>
  </div>
  <p-card #diagramCard class="p-col-8" [header]="'Intäkter och kostnader'">
    <app-project-overview-diagram
      [height]="diagramHeight + 'px'"
      [projectOverviewData]="projectOverviewData"
      [includeOverhead]="includeOverhead"
      [setMile]="setMile"
    ></app-project-overview-diagram>
  </p-card>
  <app-project-overview-summary
    class="p-col-4"
    [projectOverviewData]="projectOverviewData"
    [projectData]="projectData"
    [includeOverhead]="includeOverhead"
    [setMile]="setMile"
    (timeSpanChanged)="onTimeSpanChanged($event)"
    (includeSubProjectsChanged)="onIncludeSubProjectsChanged($event)"
    (overheadChanged)="includeOverhead = $event"
  ></app-project-overview-summary>
  <p-accordion
    class="p-col-12 subheader"
    [multiple]="true"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
    *ngIf="isAdmin"
  >
    <p-accordionTab
      [selected]="false"
      appSaveExpansion="projectCosts"
      header="Projektets kostnader"
      [style]="{ position: 'relative' }"
    >
      <app-project-overview-costs
        [projectOverviewData]="projectOverviewData"
        [projectData]="projectData"
        (costCalculationChanged)="onCostCalculationChanged($event)"
        [includeOverhead]="includeOverhead"
        [setMile]="setMile"
      ></app-project-overview-costs>
    </p-accordionTab>
  </p-accordion>
</div>
