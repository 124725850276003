import { Injectable } from '@angular/core';

@Injectable()
export class ConnectionsService {
  constructor() {}

  formatConnectionsData(data) {
    const connections = {
      subcontractors: [],
      attendanceClients: [],
    };

    const subcontractorsEdges: any[] = [
      ...data['company']['subcontractors']['edges'],
    ];
    const attendanceClientsEdges: any[] = [
      ...data['company']['attendanceClients_PreCompanyTypeHyperion']['edges'],
    ];

    connections.subcontractors = this.cleanFromNode(subcontractorsEdges);
    connections.attendanceClients = this.cleanFromNode(attendanceClientsEdges);

    return connections;
  }

  cleanFromNode(edges: any[]) {
    const nodeArray = [];

    edges.forEach(s => {
      const node: any = Object.assign({}, s.node);
      delete node['__typename'];
      node['approvedByCompanyBool'] = Number(node['approvedByCompanyId']) > 0;
      nodeArray.push(node);
    });

    return nodeArray;
  }

  countNotApprovedAttendanceClients(attendanceClients: any[]) {
    let count = 0;

    attendanceClients.forEach(a => {
      if (!a['approvedByCompanyBool']) {
        count++;
      }
    });

    if (count === 0) {
      count = null;
    }
    return count;
  }
}
