import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { MessageService } from 'app/shared/message';
import {
  DeleteProjectproductGQL,
  UpdateProjectproductGQL,
} from 'app/shared/projectproduct/project-product-form/graphql/project-product.mutation.generated';
import { MenuItem, SortEvent } from 'primeng/api';
import { BehaviorSubject, debounceTime, first, map, Observable } from 'rxjs';
import {
  ProductFragment,
  ProjectCostTypeFragment,
} from '../../graphql/project.generated';
import cloneDeep from 'lodash.clonedeep';
import {
  SetProductExtraGQL,
  RemoveMaterialInvoiceConnectionGQL,
} from './graphql/project-material.generated';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import moment from 'moment';

interface CostType {
  id: number;
  benamning: string;
  totalSum: number;
  totalSalesSum: number;
  products: ProductFragment[];
  procent: number;
}

interface TableData {
  costs: CostType[];
  totalCost: number;
  totalSalesPrice: number;
}

export enum Mode {
  INCOME = 'income',
  COSTS = 'costs',
}
export const LOCALSTORAGE_KEY = 'projectMaterialMode';
const IS_LARGE_LIMIT = 100;

@Component({
  selector: 'app-project-material',
  templateUrl: './project-material.component.html',
  styleUrls: ['./project-material.component.scss'],
})
export class ProjectMaterialComponent implements OnChanges {
  @Input() public projectId: number;
  @Input() public isExtra = false;
  @Input() public costTypeData: Observable<ProjectCostTypeFragment[]>;
  @Input() public isExpanded = false;
  @Output() public projectProductUpdatedEvent = new EventEmitter<boolean>();
  @Output() public modeChanged = new EventEmitter<Mode>();

  private costs: BehaviorSubject<CostType[]> = new BehaviorSubject([]);
  public tableData: BehaviorSubject<TableData> = new BehaviorSubject({
    costs: [],
    totalCost: 0,
    totalSalesPrice: 0,
  });

  public products: ProductFragment[];
  public productMenus: { [x: string]: MenuItem[] } = {};

  public isProductFormSidebarVisible = false;
  public selectedEditProjectProductId: number;

  public expandedRows: { [keys: string]: boolean } = {};

  public modeEnum = Mode;
  public mode = Mode.COSTS;

  public displayCostsFromDate = moment()
    .add(-1, 'month')
    .startOf('month')
    .toDate();
  public isLarge = false;
  public totalCostEntries = 0;
  private userFlags: UserFlags;

  public costColumns = [
    {
      header: 'Benämning',
      field: 'benamning',
      width: 'auto',
      editable: true,
      sortable: true,
    },
    {
      header: 'Antal',
      field: 'antal',
      width: '6rem',
      editable: true,
      sortable: true,
    },
    {
      header: 'Ink. pris',
      field: 'avtalsprisCost',
      width: '7rem',
      editable: true,
      sortable: true,
      tooltip: 'Inköpspris',
    },
    {
      header: 'Summa',
      field: 'totalSum',
      width: '8rem',
      sortable: true,
      tooltip: 'Summa inköpspris',
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];

  public incomeColumns = [
    {
      header: 'Benämning',
      field: 'benamning',
      width: 'auto',
      editable: true,
      sortable: true,
    },
    {
      header: 'Antal',
      field: 'antal',
      width: '6rem',
      editable: true,
      sortable: true,
    },
    {
      header: 'F. pris',
      field: 'avtalspris',
      width: '7rem',
      editable: true,
      sortable: true,
      tooltip: 'Försäljningspris',
    },
    {
      header: 'Sum. (F. pris) + %',
      field: 'totalSalesSum',
      width: '10.5rem',
      sortable: true,
      tooltip: 'Summa försäljningspris inklusive påslag',
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];

  public extendedColumns = [
    {
      header: 'Artnr',
      field: 'artnr',
      width: '8rem',
      editable: true,
      sortable: true,
    },
    {
      header: 'Datum',
      field: 'date',
      width: '10.5rem',
      sortable: true,
    },
    {
      header: 'Benämning',
      field: 'benamning',
      width: '10rem',
      editable: true,
      sortable: true,
    },
    {
      header: 'Antal',
      field: 'antal',
      width: '6rem',
      editable: true,
      sortable: true,
    },
    {
      header: 'I. pris',
      field: 'avtalsprisCost',
      width: '7rem',
      editable: true,
      sortable: true,
      tooltip: 'Inköpspris',
    },
    {
      header: 'F. pris',
      field: 'avtalspris',
      width: '7rem',
      editable: true,
      sortable: true,
      tooltip: 'Försäljningspris',
    },
    {
      header: 'Sum. (I. pris)',
      field: 'totalSum',
      width: '9rem',
      tooltip: 'Summa inköpspris',
      sortable: true,
    },
    {
      header: 'Sum. (F. pris) + %',
      field: 'totalSalesSum',
      width: '10.5rem',
      tooltip: 'Summa försäljningspris inklusive påslag',
      sortable: true,
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];
  public columns: { header: string; field: string; width: string }[];

  private isEditing: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public createActionMenu(product: ProductFragment): MenuItem[] {
    const actions = [
      {
        label: `Redigera`,
        icon: 'pi pi-pencil',
        command: () => {
          this.showProductFormSidebar();
        },
        disabled: Boolean(product.invoiceId),
      },
      {
        label: `Radera`,
        icon: 'pi pi-trash',
        command: () => {
          this.deleteProduct(this.selectedEditProjectProductId);
          this.selectedEditProjectProductId = null;
        },
        disabled: Boolean(product.invoiceId),
      },
      {
        label:
          product.extra === '1' ? 'Flytta till normal' : 'Flytta till extra',
        icon: 'pi pi-plus',
        command: () => {
          this.setExtra(
            Number(this.selectedEditProjectProductId),

            product.extra === '1' ? false : true
          );
          this.selectedEditProjectProductId = null;
        },
        disabled: Boolean(product.invoiceId),
      },
    ];
    if (this.userFlags.isSuperAdmin && product.invoiceId) {
      actions.push({
        label: 'Ta bort fakturakoppling',
        icon: 'pi pi-times',
        command: () => this.removeInvoiceConnection(Number(product.id)),
        disabled: false,
      });
    }
    return actions;
  }

  constructor(
    private deleteProductService: DeleteProjectproductGQL,
    private messageService: MessageService,
    private updateProjectProductGQL: UpdateProjectproductGQL,
    private setProductExtraGQL: SetProductExtraGQL,
    private removeMaterialInvoiceConnectionMutation: RemoveMaterialInvoiceConnectionGQL,
    private userFlagService: UserFlagsService
  ) {
    this.userFlagService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
      });
  }

  public ngOnChanges(): void {
    const mode = localStorage.getItem(LOCALSTORAGE_KEY) as Mode;
    if (mode) {
      this.mode = mode;
    }
    this.updateColumns();
    this.costTypeData
      .pipe(
        map(cts => {
          return cts.filter(ct => ct.products.edges.length > 0);
        })
      )
      .subscribe(cts => {
        const costs: CostType[] = cts.map(ct => {
          const products = cloneDeep(ct.products.edges.map(e => e.node));
          return {
            id: Number(ct.id),
            benamning: ct.name,
            totalSalesSum: this.salesPriceSum(products, ct.procent),
            totalSum: this.sumProducts(products),
            products: products.map(p => ({
              ...p,
              totalSum: p.avtalsprisCost * p.antal,
              totalSalesSum:
                p.antal *
                (1 + (ct.procent === 0 ? 0 : ct.procent / 100)) *
                p.avtalspris,
            })),
            procent: ct.procent,
          };
        });

        this.totalCostEntries = costs.flatMap(c => c.products).length;
        this.isLarge = this.totalCostEntries >= IS_LARGE_LIMIT;

        this.costs.next(costs);
        this.products = cts
          .map(ct => ct.products.edges.map(e => e.node))
          .flat();

        this.products.forEach(p => {
          this.productMenus[p.id] = this.createActionMenu(p);
        });
        this.updateDisplayedCosts();
      });
  }

  public sumProducts(products: ProductFragment[]): number {
    let total = 0;
    products.forEach(p => (total += p.avtalsprisCost * p.antal));
    return total;
  }

  public calculateTotal(costTypes: CostType[]): number {
    const allProducts = costTypes.flatMap(p => p.products);
    return this.sumProducts(allProducts);
  }

  public salesPriceSum(
    products: ProductFragment[],
    surchargePercentage = 0
  ): number {
    let actualSurchargePercentage = 1;
    if (surchargePercentage && surchargePercentage !== 0) {
      actualSurchargePercentage += surchargePercentage / 100;
    }

    let total = 0;
    products.forEach(
      p => (total += p.avtalspris * p.antal * actualSurchargePercentage)
    );
    return total;
  }

  public salesPriceTotal(costTypes: CostType[]): number {
    return costTypes
      .map(c => this.salesPriceSum(c.products, c.procent))
      .reduce((p, n) => p + n, 0);
  }

  public updateColumns(): void {
    const collapsedColumns =
      this.mode === Mode.COSTS ? this.costColumns : this.incomeColumns;

    this.columns = this.isExpanded ? this.extendedColumns : collapsedColumns;
  }

  public showProductFormSidebar(): void {
    this.isProductFormSidebarVisible = !this.isProductFormSidebarVisible;
  }

  public onProjectProductUpdatedEvent(projectCostTypeId: number): void {
    this.projectProductUpdatedEvent.emit(true);
    this.selectedEditProjectProductId = null;
    this.isProductFormSidebarVisible = false;
    this.expandedRows[projectCostTypeId] = true;
  }

  public onHideProductFormSidebar(): void {
    this.selectedEditProjectProductId = null;
  }

  public deleteProduct(id: number): void {
    this.deleteProductService
      .mutate({ id: Number(id) })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectproductTypeHyperionMutation
        );
        this.projectProductUpdatedEvent.emit(true);
      });
  }

  public setExtra(id: number, extra: boolean): void {
    this.setProductExtraGQL
      .mutate({ id: id, extra: extra })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.setProjectProductExtraMutation
        );
        this.projectProductUpdatedEvent.emit(true);
      });
  }

  public openPdf(id: string): void {
    this.openPdfFromUrl(this.getPdfUrl(id));
  }

  private getPdfUrl(id: string): string {
    return '/invoice/Print/type/showPDF/id/' + id;
  }

  public openPdfFromUrl(url: string): void {
    window.open(url);
  }

  public setEditing(editing: boolean): void {
    this.isEditing.next(editing);
  }

  public updateProduct(product: ProductFragment): void {
    this.updateProjectProductGQL
      .mutate({
        updateProjectproduct: {
          id: Number(product.id),
          artnr: product.artnr,
          benamning: product.benamning,
          antal: product.antal,
          avtalspris: product.avtalspris,
          avtalsprisCost: product.avtalsprisCost,
        },
      })
      .pipe(first())
      .subscribe(() => {
        this.isEditing.pipe(debounceTime(100)).subscribe(editing => {
          if (!editing) {
            this.projectProductUpdatedEvent.emit(true);
          }
        });
      });
  }

  public sortCostTypes(event: SortEvent): void {
    const field = event.field;
    event.data.sort((a, b) => {
      const result = this.sortFn(a, b, field);
      return event.order * result;
    });
    event.data.forEach((ct: CostType) => {
      ct.products.sort((a, b) => {
        const result = this.sortFn(a, b, field);
        return event.order * result;
      });
    });
  }

  private sortFn(a: any, b: any, field: string): number {
    if (a[field] === null && b[field] !== null) {
      return -1;
    } else if (a[field] !== null && b[field] === null) {
      return 1;
    } else if (typeof a[field] === 'string' && typeof b[field] === 'string') {
      return (a[field] as string).localeCompare(b[field] as string);
    } else {
      return a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0;
    }
  }

  public changeMode(): void {
    this.mode = this.mode === Mode.COSTS ? Mode.INCOME : Mode.COSTS;
    localStorage.setItem(LOCALSTORAGE_KEY, this.mode);
    this.updateColumns();

    this.modeChanged.emit(this.mode);
  }

  public updateDisplayedCosts(): void {
    this.costs.pipe(first()).subscribe(costs => {
      const displayedCosts = this.isLarge
        ? costs
            .map(c => {
              const products = c.products.filter(
                p => new Date(p.date) >= this.displayCostsFromDate
              );
              return {
                ...c,
                products: [...products],
                totalSum: this.sumProducts(products),
                totalSalesSum: this.salesPriceSum(products, c.procent),
              };
            })
            .filter(c => c.products.length > 0)
        : costs;
      const costWrapper = {
        costs: displayedCosts,
        totalSalesPrice: this.salesPriceTotal(displayedCosts),
        totalCost: this.calculateTotal(displayedCosts),
      };
      this.tableData.next(costWrapper);
    });
  }

  public showAllCosts(): void {
    this.costs.pipe(first()).subscribe(costs => {
      const firstDate = new Date(
        costs
          .flatMap(c => c.products)
          .map(p => p.date)
          .sort()
          .at(0)
      );
      this.displayCostsFromDate = firstDate;
      this.updateDisplayedCosts();
    });
  }

  private removeInvoiceConnection(id: number) {
    this.removeMaterialInvoiceConnectionMutation
      .mutate({
        id: id,
      })
      .pipe(first())
      .subscribe(result => {
        if (
          result.data.removeMaterialInvoiceConnectionMutation.mutationDetails[0]
            .mutationSucceeded
        ) {
          this.projectProductUpdatedEvent.emit(true);
        }
      });
  }
}
