<form
  *ngIf="componentMainForm"
  [formGroup]="componentMainForm"
  (keyup.enter)="actionCreate()"
  class="full-width p-grid"
>
  <div class="p-col-12 p-lg-6">
    <appForm2-simple-input formControlName="firstName"></appForm2-simple-input>
    <appForm2-simple-input formControlName="lastName"></appForm2-simple-input>
    <appForm2-simple-input formControlName="email"></appForm2-simple-input>

    <appForm2-simple-dropDown
      formControlName="type"
      [dropDownParams]="{ options: userTypes }"
      [tooltip]="{ msg: tooltip, tooltipPosition: 'left' }"
    ></appForm2-simple-dropDown>
    <appForm2-simple-textarea formControlName="note"></appForm2-simple-textarea>
  </div>
  <div class="p-col-12 p-lg-6">
    <appForm2-simple-input
      formControlName="mobilePhone"
    ></appForm2-simple-input>
    <appForm2-simple-input
      formControlName="personalNumber"
    ></appForm2-simple-input>
    <appForm2-simple-input formControlName="salary"></appForm2-simple-input>

    <appForm2-simple-dropDown
      formControlName="companyDivisionId"
      *ngIf="!divisionsLoading && showDivisions"
      [dropDownParams]="{ options: divisionsOptions }"
    ></appForm2-simple-dropDown>

    <appForm2-simple-checkbox
      formControlName="contractor"
    ></appForm2-simple-checkbox>
  </div>
</form>
<div class="p-grid">
  <div class="p-col-12 align-right">
    <button
      pButton
      class="p-button-primary-hyperion"
      [disabled]="disableBtn"
      (click)="actionCreate()"
      icon="pi pi-plus"
      label="Skapa"
    ></button>
  </div>
</div>
