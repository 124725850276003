import { Component, OnInit } from '@angular/core';

import { GlobalService } from 'app/shared/global';
import { MessageService } from 'app/shared/message';
import { HttpService } from 'app/shared/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'add-subcontractor-mail',
  templateUrl: './add-subcontractor-mail.component.html',
})
export class AddSubcontractorMailComponent implements OnInit {
  emailModel: string;
  mutationDone = false;

  public subcontractorInfo: any = {};

  constructor(
    private globalService: GlobalService,
    private messageService: MessageService,
    private httpService: HttpService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.dialogConfig['data'] && this.initComponentFromDialogData();
    this.emailModel = this.subcontractorInfo.email;
  }

  initComponentFromDialogData() {
    this.subcontractorInfo = this.dialogConfig['data']['subcontractorInfo'];
    setTimeout(() => {
      this.dialogConfig.header = 'Skicka mail till UE';
    }, 0);
  }

  sendMailToSubcontractor() {
    const subcontractorId = '' + this.subcontractorInfo.id;
    let url =
      this.globalService.getUrlPrefix() +
      '/Subcontractor/mail?id=' +
      subcontractorId;

    if (this.emailModel && this.emailModel !== '') {
      url += '&mail=' + this.emailModel;
    }

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      data.status === 'success' && this.closeAction();
      this.messageService.insertData({
        textArray: [data.msg],
        time: 2000,
        type: data.status,
      });
    });
  }

  closeAction() {
    this.dialogRef.close(this.mutationDone);
  }
}
