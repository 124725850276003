<div id="menu" class="drawer-menu">
  <form [formGroup]="filterForm">
    <div class="drawer-grid">
      <div>
        <p-calendar
          dateFormat="yy-mm-dd"
          showWeek="true"
          formControlName="miniCalendar"
          selectionMode="range"
          readonlyInput="true"
          [inline]="true"
          [locale]="datePickerLocale"
          (onSelect)="handleMiniCalendarChange()"
          [showWeek]="true"
        >
          <ng-template pTemplate="date" let-date>
            <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
          </ng-template>
        </p-calendar>
      </div>
      <div class="header-cell">Filter</div>
      <div class="color-grid">
        <div
          *ngFor="let color of getColors()"
          class="color-container"
          (click)="selectColor(color)"
        >
          <div
            class="color-circle"
            [style]="{
              'background-color': color,
              'color': getColorText(color)
            }"
            [pTooltip]="getColorName(color)"
          >
            <i *ngIf="isSelected(color)" class="pi pi-check"></i>
          </div>
        </div>
      </div>
      <div class="dropdown-grid">
        <div class="flex">
          <label>Projekt</label>
        </div>
        <div>
          <p-multiSelect
            #filterProjects
            [options]="[]"
            [style]="{ 'width': '100%', 'max-width': '100%' }"
            (onChange)="filterChanged()"
            optionLabel="label"
            defaultLabel="Välj projekt.."
            formControlName="projects"
            selectedItemsLabel="{0} valda"
            maxSelectedLabels="1"
            appendTo="body"
            [overlayOptions]="{ appendTo: 'body' }"
          ></p-multiSelect>
        </div>
        <div class="flex">
          <label>Medarbetare</label>
        </div>
        <div>
          <p-multiSelect
            #filterCoworkers
            [options]="[]"
            [style]="{ 'width': '100%', 'max-width': '100%' }"
            (onChange)="filterChanged()"
            optionLabel="label"
            defaultLabel="Välj medarbetare.."
            formControlName="coworkers"
            selectedItemsLabel="{0} valda"
            maxSelectedLabels="2"
            appendTo="body"
            [overlayOptions]="{ appendTo: 'body' }"
          ></p-multiSelect>
        </div>
      </div>
      <div
        class="header-cell"
        *ngIf="
          filterForm.controls['projects'].value?.length > 0 ||
          filterForm.controls['coworkers'].value?.length > 0
        "
      >
        Valda filter
      </div>
      <div class="selected-filters">
        <div
          *ngIf="filterForm.controls['projects'].value?.length > 0"
          class="filter-label"
        >
          <label>Projekt</label>
        </div>
        <div class="tag-container">
          <div
            *ngFor="let project of filterForm.controls['projects'].value"
            class="filter-tag"
            [pTooltip]="project.label"
          >
            <span>{{ project.label }}</span>
            <i
              class="pi pi-times-circle"
              (click)="removeFilter(+project.value, 'projects')"
            ></i>
          </div>
        </div>
        <hr
          *ngIf="
            filterForm.controls['projects'].value?.length > 0 &&
            filterForm.controls['coworkers'].value?.length > 0
          "
        />
        <div
          *ngIf="filterForm.controls['coworkers'].value?.length > 0"
          class="filter-label"
        >
          <label>Medarbetare</label>
        </div>
        <div class="tag-container">
          <div
            *ngFor="let coworker of filterForm.controls['coworkers'].value"
            class="filter-tag"
            [pTooltip]="coworker.label"
          >
            <span>{{ coworker.label }}</span>
            <i
              class="pi pi-times-circle"
              (click)="removeFilter(+coworker.value, 'coworkers')"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
