<div class="fake-body">
  <div class="login-container">
    <div class="login-card form-group full-width">
      <div class="text-center">
        <a href="{{ appURL }}">
          <img
            *ngIf="!logoLoading"
            [src]="appLogoPath"
            alt=""
            class="login-image center-block"
          />
        </a>
      </div>
      <spinner *ngIf="loading"></spinner>
      <div class="p-grid">
        <div class="p-col-12 login-content">
          <div *ngIf="!authService.isLoggedIn">
            <div class="field">
              <label for="email" class="block">Användarnamn</label>
              <input
                [(ngModel)]="email"
                id="email"
                type="email"
                pInputText
                (keyup)="handleKeyUp($event)"
                autofocus
              />
            </div>
            <br />
            <div class="field">
              <label for="password" class="block">Lösenord</label>
              <input
                [(ngModel)]="password"
                id="password"
                type="password"
                pInputText
                (keyup)="handleKeyUp($event)"
              />
              <div class="link" (click)="displaySendPassword = true">
                <small>Har du glömt ditt lösenord?</small>
              </div>
            </div>
            <br />
          </div>
          <div
            *ngIf="authService.isLoggedIn && !loading"
            style="padding: 0.5em; text-align: center"
          >
            <div>Inloggad som {{ meUser?.fullName }}</div>
          </div>
          <div class="p-grid" *ngIf="!authService.isLoggedIn">
            <div class="p-col-9">
              <p-checkbox
                [binary]="true"
                class="adjust-chkbox-v2 align-left"
                [(ngModel)]="rememberMe"
                [ngModelOptions]="{ standalone: true }"
                label="Kom ihåg mig"
              ></p-checkbox>
            </div>
            <div>
              <app-button
                *ngIf="!loading"
                [class]="'block'"
                (click)="login()"
                [type]="'login'"
              ></app-button>
            </div>
          </div>

          <div class="p-col-12 p-g-nopad">
            <div class="p-col-12" *ngIf="displaySendPassword">
              <p style="margin-bottom: 1em">
                Ange ditt användarnamn nedan och klicka sedan på skicka, nya
                användaruppgifter kommer då skickas till dig.
              </p>
              <appForm2-simple-input
                [(ngModel)]="userNameModel"
                [ngModelOptions]="{ standalone: true }"
                (keyup.enter)="sendNewPassword()"
                [htmlAttributes]="{ label: { value: 'Användarnamn' } }"
              ></appForm2-simple-input>
              <app-button
                [class]="'block'"
                (click)="sendNewPassword()"
                [type]="'resetPassword'"
              ></app-button>
            </div>
            <div
              class="p-col-12 p-g-nopad"
              *ngIf="authService.isLoggedIn && !loading"
            >
              <div class="p-col-12">
                <app-button
                  [class]="'block'"
                  (click)="goToHome()"
                  [type]="'goHome'"
                ></app-button>
              </div>
              <div class="p-col-12">
                <app-button
                  [class]="'block'"
                  (click)="logout()"
                  [type]="'logout'"
                ></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!authService.isLoggedIn && isAccountCreationAllowed"
      class="align-center"
      style="padding-top: 20px"
    >
      <a
        *ngIf="!isDerome; else showDeromeRegisterLink"
        class="link-dark-background"
        href="/site/CreateEasyLogin"
      >
        Registrera nytt företagskonto
      </a>
      <ng-template #showDeromeRegisterLink>
        <a
          class="link-dark-background"
          href="https://www.derome.se/bygg-industri/tjanster/digitala-tjanster/proffsappen"
        >
          Har du inget konto idag? Klicka här.
        </a>
      </ng-template>
    </div>
  </div>
</div>
<hyperion-footer></hyperion-footer>
