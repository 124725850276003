import { Injectable } from '@angular/core';
import { ApolloFetchPolicy, ApolloQueryService } from 'app/shared';
import { Observable } from 'rxjs';
import { plannerQueries } from './plannerQueryFragments/plannedTime';
import { map } from 'rxjs/operators';
import {
  PlannerQueryVars,
  Project,
  TodoTopic,
  User,
} from 'app/planner/services/planner-query-types';
import { ProjectStatusEnum } from '../planner-module-enums';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PlannerReadService {
  public static DEFAULT_PLANNER_QUERY_VARS: PlannerQueryVars = {
    status: [
      ProjectStatusEnum.Planned,
      ProjectStatusEnum.Ongoing,
      ProjectStatusEnum.Leave,
    ],
    fromDate: '2013-01-01',
    toDate: moment(new Date(new Date().getFullYear() + 5, 0, 1)).format(
      'YYYY-MM-DD'
    ),
  };
  public static DEFAULT_PLANNER_DROPDOWN_QUERY_VARS: PlannerQueryVars = {
    status: [
      ProjectStatusEnum.Planned,
      ProjectStatusEnum.Ongoing,
      ProjectStatusEnum.Leave,
    ],
    fromDate: '2013-01-01',
    toDate: moment(new Date(new Date().getFullYear() + 5, 0, 1)).format(
      'YYYY-MM-DD'
    ),
  };

  constructor(private apolloQueryService: ApolloQueryService) {}

  /**
   * Get a stream of projects with their planned times and todo
   * @param plannerQueryVars apollo query vars
   */
  public getProjects(
    plannerQueryVars: PlannerQueryVars
  ): Observable<Project[]> {
    return this.apolloQueryService
      .apolloWatchQueryTwoQuery(
        plannerQueries.projectsQuery.query,
        plannerQueryVars,
        ApolloFetchPolicy.NETWORK_ONLY
      )
      .pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          return data.company.projects.edges.map((edge: any) => edge.node);
        }),
        map((projects: Project[]) => {
          projects = JSON.parse(JSON.stringify(projects));
          projects.map((project: Project) => {
            this.removeEdgeMap(project, 'plannedWork');
            this.removeEdgeMap(project, 'todos');
            return project;
          });
          return projects;
        })
      );
  }

  /**
   * Get a stream of internal projects with their planned times
   * @param plannerQueryVars apollo query vars
   */
  public getProjectsInternalWithPlannedTime(
    plannerQueryVars: PlannerQueryVars
  ): Observable<Project[]> {
    return this.apolloQueryService
      .apolloWatchQueryTwoQuery(
        plannerQueries.projectsInternalWithPlannedTimeQuery.query,
        plannerQueryVars,
        ApolloFetchPolicy.NETWORK_ONLY
      )
      .pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          return data.company.projectsInternal.edges.map(
            (edge: any) => edge.node
          );
        }),
        map((projects: Project[]) => {
          projects = JSON.parse(JSON.stringify(projects));
          return projects
            .map((project: Project) => {
              this.removeEdgeMap(project, 'plannedWork');
              return project;
            })
            .filter((project: Project) => project.plannedWork.length > 0);
        })
      );
  }

  /**
   * Get a stream of company workers
   */
  public getUsers(): Observable<User[]> {
    return this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          return response.data.company.users.edges.map(
            (edge: any) => edge.node
          );
        })
      );
  }

  /**
   * Get a stream of company workers
   */
  public getTodotopics(): Observable<TodoTopic[]> {
    return this.apolloQueryService
      .apolloWatchQueryTwoQuery(
        plannerQueries.todotopicsQuery.query,
        null,
        ApolloFetchPolicy.CACHE_AND_NETWORK
      )
      .pipe(
        map((response: any) => {
          const data = JSON.parse(JSON.stringify(response.data));
          return data.company.todotopics.edges.map((edge: any) => edge.node);
        })
      );
  }

  /**
   * Remove edges and nodes from incoming data
   * @template T Generic type for mapping from
   * @template K Key of generic type T
   * @param item Item of type T
   * @param key Property key of T to remove edges and nodes from
   */
  private removeEdgeMap<T extends Project, K extends keyof T>(
    item: T,
    key: K
  ): void {
    if (item[key] && (item[key] as any).edges) {
      item[key] = (item[key] as any).edges.map((i: any) => i.node);
    }
  }
}
