<app-header>
  <app-button
    [disabled]="childLoading | async"
    (onClick)="createOffer()"
    [type]="'showCreate'"
    [model]="'Offer'"
  ></app-button>
</app-header>

<div class="page-container">
  <div *ngIf="projectId === null" class="p-grid">
    <div class="p-col-12">
      <div class="p-col-12 p-md-8 p-lg-10" id="offerTemplateButtons">
        <div class="p-grid">
          <div class="p-col-12 p-md-4 p-lg-2">
            <span>Befintliga offertmallar</span>
          </div>
          <div
            class="p-col-4 p-md-4 p-lg-2"
            *ngFor="let offerTemplate of offerTemplates | async"
            class="p-text-truncate"
            style="padding: 0.2rem 0.3rem; overflow: visible"
          >
            <app-split-button
              [data]="{ label: offerTemplate.templateName }"
              [btnModel]="offerTemplate.btns"
              [disabled]="childLoading | async"
              (onClick)="createOffer(offerTemplate.id)"
              [class.active-button]="
                (setTemplateBtnActive | async) === offerTemplate.id
              "
            ></app-split-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12 p-g-nopad">
      <spinner
        *ngIf="(childLoading | async) || (loadingAsync | async)"
      ></spinner>
    </div>
  </div>

  <app-single-offer
    *ngIf="!(loadingAsync | async)"
    [hidden]="childLoading | async"
    [offerVariable]="offerVariable"
    [offerMode]="offerMode"
    (parentLoadingState)="childLoading.next($event)"
    (triggerCreate)="createOffer($event)"
  ></app-single-offer>
</div>
