import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { HelperService } from 'app/shared/helpers/index';
import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';

@Component({
  selector: 'project-installment-row',
  styleUrls: ['./project-installment-row.scss'],
  templateUrl: 'project-installment-row.component.html',
  providers: [FormHandlerService],
})
export class ProjectInstallmentRowComponent implements OnInit {
  @Input() projectInfo;
  @Input() dataObjectFromParent;
  @Input() projectInstallment;
  @Input() labels;
  @Input() isCreate = false;
  @Input() isFirst = false;
  @Input() invoiceRowTypeListDropdown;
  @Input() optionallyInvoiceTaxDropdown;

  typeDropdown = [
    { value: 1, label: 'Arbetstimmar' },
    { value: 2, label: 'Material' },
    { value: 3, label: 'Milersättning' },
    { value: 5, label: 'Övrigt' },
    { value: 7, label: 'Dröjsmålsränta' },
    { value: 8, label: 'Påminnelseavgift' },
    { value: 0, label: 'Inget valt' },
  ];
  taxDropdown = [
    { value: 99, label: 'Endast benämning' },
    { value: 25, label: '25%' },
    { value: 12, label: '12%' },
    { value: 6, label: '6%' },
    { value: 0, label: '0%' },
  ];
  public componentMainForm: ExtendedFormGroup;
  dataModel = 'projectInstallmentRow';
  dataModelCapitalized;
  formFields = {
    model: 'ProjectInstallmentRow',
    attributes: {},
  };
  antal: number = null;
  apris: number = null;
  calculatedPrice: number = null;
  showInvoiceCheckBox = false;
  invoiceCheckValue = true;

  constructor(
    private helperService: HelperService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private formHandler: FormHandlerService
  ) {}

  ngOnInit() {
    this.prepareAndInitForms();
  }

  prepareAndInitForms() {
    if (this.isCreate) {
      this.setFieldsForCreate();
    } else {
      this.setFieldsForUpdate();
    }
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formInit();
  }

  calculatePrice(value, field) {
    if (this.helperService.isNumeric(value)) {
      if (field === 'antal') {
        this.antal = +value;
      } else {
        this.apris = +value;
      }
    }

    const calcPrice = this.antal * this.apris;

    this.calculatedPrice = calcPrice;
  }

  setFieldsForCreate() {
    this.formFields['attributes'] = {
      typeId: 1,
      arbetskostnad: 0,
      benamning: '',
      antal: '',
      apris: '',
      tax: 25,
      pris: '',
    };
  }

  private setFieldsForUpdate(): void {
    this.formFields['attributes'] = {
      invoiceId: this.dataObjectFromParent.invoiceId,
      typeId: this.dataObjectFromParent.typeId,
      arbetskostnad: this.dataObjectFromParent.arbetskostnad,
      benamning: this.dataObjectFromParent.benamning,
      antal: this.dataObjectFromParent.antal,
      apris: this.dataObjectFromParent.apris,
      tax: this.dataObjectFromParent.tax,
      pris: this.dataObjectFromParent.pris,
    };

    if (this.dataObjectFromParent.invoiceId > 0) {
      this.showInvoiceCheckBox = true;
    }

    this.calculatedPrice = this.dataObjectFromParent.pris;
  }

  private formInit(): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm['controls']['invoiceId'] &&
          (this.componentMainForm['controls']['invoiceId']['label'] =
            'Fakturerad');
      });
    this.onFormValChanges();
  }

  onFormValChanges(): void {
    this.componentMainForm.get('apris').valueChanges.subscribe(() => {
      this.calculatePris();
    });
    this.componentMainForm.get('antal').valueChanges.subscribe(() => {
      this.calculatePris();
    });
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.returnDataToMutation();

    dataToMutation['projectInstallmentId'] = this.projectInstallment['id'];

    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.setLoadingClasses();
    } else {
      const refetchVars = { projectId: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectInstallments', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.formInit();
            }

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = this.returnDataToMutation();

    dataToMutation['id'] = +this.dataObjectFromParent['id'];

    if (this.formHandler.formValid([this.componentMainForm])) {
      const refetchVars = { projectId: +this.projectInfo['id'] };
      const refetchArr = [
        { name: 'projectInstallments', variables: refetchVars },
      ];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  confirmDeleteDocument() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort dokumentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteDocument();
      },
      reject: () => {},
    });
  }

  private deleteDocument() {
    const crudType = 'delete';
    const dataToMutation = {
      id: Number(this.dataObjectFromParent.id),
    };
    const refetchVars = { projectId: +this.projectInfo['id'] };
    const refetchArr = [
      { name: 'projectInstallments', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          this.formHandler.showServerErrorsOnAttributes(executedData, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  returnDataToMutation() {
    const dataToMutation = {
      typeId: this.componentMainForm.controls['typeId']['value'],
      arbetskostnad: this.componentMainForm.controls['arbetskostnad']['value'],
      benamning: this.componentMainForm.controls['benamning']['value'],
      antal: this.componentMainForm.controls['antal']['value'],
      apris: this.componentMainForm.controls['apris']['value'],
      tax: this.componentMainForm.controls['tax']['value'],
      pris: this.componentMainForm.controls['pris']['value'],
    };

    if (this.showInvoiceCheckBox) {
      dataToMutation['invoiceId'] = this.invoiceCheckValue
        ? this.componentMainForm.controls['typeId']['value']
        : null;
    }

    return dataToMutation;
  }

  calculatePris() {
    let antalValue = this.componentMainForm.controls['antal'].value;
    let aprisValue = this.componentMainForm.controls['apris'].value;

    antalValue = antalValue.replace(',', '.');
    aprisValue = aprisValue.replace(',', '.');

    if (!isNaN(antalValue) && !isNaN(aprisValue)) {
      const antal = Number(antalValue);
      const apris = Number(aprisValue);

      const calcPris = antal * apris;

      this.componentMainForm.controls['pris'].setValue(
        parseFloat(calcPris.toFixed(2))
      );
    }
  }
}
