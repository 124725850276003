import { Injectable } from '@angular/core';
import { ApolloQueryService } from '../apollo';
import { AuthService } from 'app/shared/auth/auth.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface CompanyInfo {
  id?: string;
  address?: string;
  address2?: string;
  betalningsvillkor?: number;
  city?: string;
  cityCode?: string;
  faxNr?: any;
  interest?: number;
  mail?: string;
  name?: string;
  orgNr?: string;
  phone?: string;
  plusGiro?: any;
  postGiro?: any;
  iban?: any;
  bicSwift?: any;
  webb?: any;
  created?: string;
  startupSettingsAreDone?: string;
  remainingUsersInPlan?: number;
  showProductSearchPrices?: boolean;
  isMissingDeromeProductIntegration?: boolean;
  showProductExternalConnectInterface?: boolean;
  hasDeromeWebshopActivated?: boolean;
  hasBookkeepingIntegration?: boolean;
  bookkeepingIntegrationName?: string;
}

@Injectable()
export class CompanyInfoService {
  public companyInfo$: Observable<CompanyInfo>;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private authService: AuthService
  ) {
    this.companyInfo$ = this.authService.isLoggedInObs.pipe(
      switchMap(loggedIn =>
        loggedIn ? this.getCompanyInfoFromServer() : of({})
      )
    );
  }

  private getCompanyInfoFromServer(): Observable<CompanyInfo> {
    return this.apolloQueryService
      .apolloWatchQueryTwo('companyInfo', {}, 'cache-first')
      .pipe(
        filter(data => !!data['data']),
        map(({ data }) => data.company)
      );
  }
}
