<div class="p-grid">
  <div class="p-col-12 project-title-section p-mb-2">
    <div class="sub-title-row">
      <h4>Befintliga bilder {{ count }}</h4>
      <div
        style="width: 400px; max-width: 100%"
        class="export-buttons"
        *ngIf="meUser.type === '3'"
      >
        <div>
          <app-button
            (onClick)="exportFile('download')"
            [disabled]="count === 0"
            [type]="'download'"
          ></app-button>

          <app-button
            class="ml5"
            [disabled]="count === 0"
            (onClick)="exportFile('showPDF')"
            [type]="'showPdf'"
          ></app-button>

          <app-button
            (onClick)="exportFile('getPDF')"
            [disabled]="count === 0"
            class="ml5"
            [type]="'getPdf'"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-g-nopad">
    <upload-project-images
      [projectId]="projectInfo.id"
      (imagesAddedToParent)="handleNewImages($event)"
    ></upload-project-images>
  </div>
  <div class="p-col-12">
    <image-gallery
      [images]="images"
      [totalImages]="count"
      [isLoading]="isLoading"
      (imageUpdateToParent)="handleUpdateEmit($event)"
      (imageDeleteToParent)="handleDeleteEmit($event)"
      (imageRotateToParent)="handleRotateEmit($event)"
      (fetchImages)="fetchImages($event)"
    ></image-gallery>
  </div>
</div>
