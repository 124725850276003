import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetProjectParametersQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetProjectParametersQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', mileCost?: number | null, privMileCost?: number | null, invoicedInOtherSystem?: number | null } | null };

export type UpdateProjectParametersMutationVariables = Types.Exact<{
  project?: Types.InputMaybe<Types.ProjectTypeHyperionMutationInput>;
}>;


export type UpdateProjectParametersMutation = { __typename?: 'HyperionSchemaMutation', projectTypeHyperionMutation?: { __typename?: 'Project', mileCost?: number | null, privMileCost?: number | null, invoicedInOtherSystem?: number | null, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const GetProjectParametersDocument = gql`
    query getProjectParameters($projectId: Int) {
  project(id: $projectId) {
    mileCost
    privMileCost
    invoicedInOtherSystem
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProjectParametersGQL extends Apollo.Query<GetProjectParametersQuery, GetProjectParametersQueryVariables> {
    document = GetProjectParametersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectParametersDocument = gql`
    mutation updateProjectParameters($project: ProjectTypeHyperionMutationInput) {
  projectTypeHyperionMutation(updateProject: $project) {
    mileCost
    privMileCost
    invoicedInOtherSystem
    mutationDetails {
      argument
      errorsMsgs
      infoMsgs
      model
      modelErrorsMsgs
      modelSuccessMsgs
      modelWarningMsgs
      msg
      mutationSucceeded
      successMsgs
      warningMsgs
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectParametersGQL extends Apollo.Mutation<UpdateProjectParametersMutation, UpdateProjectParametersMutationVariables> {
    document = UpdateProjectParametersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }