import * as Types from '../../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetAcontosQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetAcontosQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', aconto?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null, fdatum?: string | null, sumBox?: number | null, status?: number | null, paymentPassed?: string | null, daysCount?: number | null, productsCount?: number | null, invoicedByProject?: number | null } | null } | null> | null } | null } | null };

export type GetSubprojectAcontosQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetSubprojectAcontosQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', subProjectsAconto?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', id: string, fakturaNr?: string | null, fdatum?: string | null, sumBox?: number | null, status?: number | null, paymentPassed?: string | null, daysCount?: number | null, productsCount?: number | null, invoicedByProject?: number | null } | null } | null> | null } | null } | null };

export type AcontoInvoiceFragment = { __typename?: 'Invoice', id: string, fakturaNr?: string | null, fdatum?: string | null, sumBox?: number | null, status?: number | null, paymentPassed?: string | null, daysCount?: number | null, productsCount?: number | null, invoicedByProject?: number | null };

export const AcontoInvoiceFragmentDoc = gql`
    fragment acontoInvoice on Invoice {
  id
  fakturaNr
  fdatum
  sumBox
  status
  paymentPassed
  daysCount
  productsCount
  invoicedByProject(projectId: $projectId)
}
    `;
export const GetAcontosDocument = gql`
    query getAcontos($projectId: Int) {
  project(id: $projectId) {
    aconto {
      edges {
        node {
          ...acontoInvoice
        }
      }
    }
  }
}
    ${AcontoInvoiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAcontosGQL extends Apollo.Query<GetAcontosQuery, GetAcontosQueryVariables> {
    document = GetAcontosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSubprojectAcontosDocument = gql`
    query getSubprojectAcontos($projectId: Int) {
  project(id: $projectId) {
    subProjectsAconto {
      edges {
        node {
          ...acontoInvoice
        }
      }
    }
  }
}
    ${AcontoInvoiceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSubprojectAcontosGQL extends Apollo.Query<GetSubprojectAcontosQuery, GetSubprojectAcontosQueryVariables> {
    document = GetSubprojectAcontosDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }