import { Component, OnInit, ViewChild } from '@angular/core';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Observable, BehaviorSubject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { DialogComponent } from 'app/shared/dialogs/dialog.component';
import { ApolloQueryService } from 'app/shared/apollo';
import { ButtonsAsync } from 'app/shared/forms/buttons-async';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';
import { GlobalInvoiceService } from 'app/global-services/invoice/invoice.service';
import { Editor } from 'primeng/editor';

@Component({
  selector: 'mail-dialog',
  templateUrl: 'mail-dialog.component.html',
  styleUrls: ['mail-dialog.component.scss'],
})
export class MailDialogComponent extends DialogComponent implements OnInit {
  @ViewChild('editor', { static: false }) public editor: Editor;

  public invoiceId = null;
  public projectId = null;
  public reminder = null;
  public offerId = null;
  public email = null;
  public mode: '' | 'offer' | 'project' | 'invoice' = '';
  public files = null;
  public mergePDFs = true;
  public previewIsLoading = true;
  public previewUrl: SafeUrl;

  public appname: string;
  public urlPrefix: string;
  public componentMainForm: ExtendedFormGroup;
  private dataModel = 'mail';
  private dataModelCapitalized: string;
  public loadingForm = new BehaviorSubject<boolean>(true);
  public loadingList = new BehaviorSubject<boolean>(true);
  public attachments = new BehaviorSubject<any[]>([]);
  public mailList = new BehaviorSubject<any[]>([]);
  private singleMailLoading = new BehaviorSubject<any>({});

  public buttons: ButtonsAsync;
  systemText = {
    value: null,
    checked: true,
  };
  private buttonObject = ['create'];
  public embedMsg = {
    value: null,
    checked: true,
  };

  public tableColums = [
    { field: 'email', header: 'Skickat till' },
    { field: 'created', header: 'Skickat' },
    { field: 'subject', header: 'Meddelande' },
    { field: 'translatedState', header: 'Status' },
  ];

  constructor(
    private apolloQueryService: ApolloQueryService,
    private globalInvoiceService: GlobalInvoiceService,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  public ngOnInit(): void {
    this.mode === 'offer' &&
      this.tableColums.push({ field: '', header: 'Accepterad' });

    if (this.dialogConfig.data) {
      this.initComponentWithDialogData();
    }

    this.appname = localStorage.getItem('appname');

    if (this.invoiceId > 0) {
      this.mode = 'invoice';
    } else if (this.offerId > 0) {
      this.mode = 'offer';
    } else if (this.projectId > 0) {
      this.mode = 'project';
    }

    this.urlPrefix = this.globalService.getUrlPrefix();
    this.buttons = this.formHandler.getButtonValuesAsync(this.buttonObject);
    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );
    this.getMails();
  }

  private initComponentWithDialogData() {
    this.invoiceId = this.dialogConfig.data.invoiceId || null;
    this.offerId = this.dialogConfig.data.offerId || null;
    this.email = this.dialogConfig.data.email || null;
    this.reminder = this.dialogConfig.data.reminder || null;
    this.projectId = this.dialogConfig.data.projectId || null;
    this.files = this.dialogConfig.data.files || null;
    setTimeout(() => {
      this.dialogConfig.header = 'Mail';
    }, 0);
  }

  private getMails(): void {
    let dName = '';
    let variables = {};

    if (this.invoiceId !== null) {
      this.getMailFormJson();

      variables = {
        id: Number(this.invoiceId),
      };
      dName = 'invociesAndSupplierInvoice';
    } else if (this.offerId !== null) {
      this.getMailFormJson();

      variables = {
        id: Number(this.offerId),
      };
      dName = 'offersAndAta';
    } else if (this.projectId !== null) {
      this.getMailFormJson();

      variables = {
        id: Number(this.projectId),
      };
      dName = 'project';
    } else {
      dName = 'allLast';
      variables = null;
    }

    const qName = dName + 'Mails';

    this.apolloQueryService
      .apolloQuery(qName, variables)
      .subscribe(({ data }) => {
        let node = [];

        if (qName === 'allLastMails') {
          node = data['company'];
          this.previewIsLoading = false;
        } else {
          node = data['company'][dName + 's']['edges'][0]['node'];
        }
        const mailsData = { ...node['mails'] };

        let cleanedMails = this.helperService.cleanFromNode(mailsData);

        cleanedMails = this.parseData(cleanedMails);

        this.mailList.next(cleanedMails);

        const singleMailLoadingInst = {};
        for (const i in cleanedMails) {
          const mailId = cleanedMails[i]['id'];
          singleMailLoadingInst[mailId] = false;
        }
        const newSingleMailLoading = { ...singleMailLoadingInst };
        this.singleMailLoading.next(newSingleMailLoading);

        this.loadingList.next(false);

        for (const i in cleanedMails) {
          const mailObject = cleanedMails[i];
          this.refreshMandrillInfoSingleMail(mailObject);
        }
      });
  }

  private parseData(mails) {
    const mailsWithAttachments = mails;

    for (const index in mailsWithAttachments) {
      const mail = mailsWithAttachments[index];
      const attachments = this.helperService.cleanFromNode(
        mail['attachmentsAsArray_PreMailTypeHyperion']
      );
      const acceptances = this.helperService.cleanFromNode(mail['acceptances']);
      const mandrillInfo = JSON.parse(mail['mandrillInfo']);
      mail['attachments'] = attachments;
      mail['acceptances'] = acceptances;
      mail['mandrillInfo'] = mandrillInfo;
    }

    return mailsWithAttachments;
  }

  /* Send Mail */
  public actionSendMail(): void {
    if (this.formHandler.formValid([this.componentMainForm])) {
      this.formHandler.lockButtonsAsync(this.buttons);
      this.sendMailHttpPost().subscribe(data => {
        this.formHandler.unlockButtonsAsync(this.buttons);
        if (data['status'] === 'success') {
          this.loadingList.next(true);
          this.getMails();
          if (this.invoiceId) {
            this.globalInvoiceService.mutationOcured([+this.invoiceId]);
          }
        }
      });
    }
  }

  private sendMailHttpPost(): Observable<any> {
    const urlParam = this.getPostMailFormUrl();
    const valuesParam = { Mail: {} };
    valuesParam['Mail'] = this.formHandler.getCleanedMutationVariable(
      this.componentMainForm
    );
    valuesParam['Mail']['attachments'] = [];
    const attachmentsDataSet = this.attachments.value;
    for (const index in attachmentsDataSet) {
      const att = attachmentsDataSet[index];
      const attVal = att['path'];
      if (att['checked']) {
        valuesParam['Mail']['attachments'].push(attVal);
      }
    }
    valuesParam['reminder'] = this.reminder !== null;
    valuesParam['mergePDFs'] = this.mergePDFs;
    valuesParam['Mail']['addDefualtMsg'] = this.embedMsg['checked'] ? 1 : 0;

    return new Observable(observer => {
      this.httpService
        .makeHttpPostRequest(urlParam, valuesParam)
        .subscribe(data => {
          if (data['status'] === 'success') {
            this.messageService.insertData({
              textArray: [data['msg']],
              time: 2000,
              type: 'success',
            });
          } else {
            this.messageService.insertData({
              textArray: [data['msg']],
              time: 2000,
              type: 'error',
            });
          }

          observer.next(data);
          observer.complete();
        });
    });
  }

  private getMailFormJson(): void {
    const url = this.getPostMailFormUrl();

    this.httpService
      .makeHttpPostRequest(url, { files: this.files })
      .subscribe(mailFormSettings => {
        if (mailFormSettings.status === 'success') {
          this.handleFormData(mailFormSettings);
        } else {
          this.messageService.insertData({
            textArray: [mailFormSettings.msg],
            time: 2000,
            type: 'error',
          });
        }
      });
  }

  private getPostMailFormUrl(): string {
    return (
      '/company/HyperionMailForm?' +
      (this.invoiceId !== null ? 'invoiceId=' + this.invoiceId : '') +
      (this.projectId !== null ? 'projectId=' + this.projectId : '') +
      (this.offerId !== null ? 'offerId=' + this.offerId : '') +
      (this.reminder !== null ? '&reminder=remind' : '')
    );
  }

  private handleFormData(dataParam): void {
    const fields = {
      model: this.dataModelCapitalized,
      attributes: { ...dataParam['model'] },
    };

    delete fields.attributes.attachments;
    delete fields.attributes.created;
    delete fields.attributes.display;
    delete fields.attributes.id;
    delete fields.attributes.embedMsgWithLabel;
    delete fields.attributes.mandrillId;
    delete fields.attributes.mandrillInfo;
    delete fields.attributes.mandrillResponse;
    delete fields.attributes.userId;

    if (this.email !== null) {
      fields.attributes.email = this.email;
    }

    this.embedMsg.value = dataParam.model.embedMsgWithLabel;

    this.initForm(fields);
    this.handleAttachments(dataParam.attachments);
    this.refreshPreview();
  }

  private initForm(formFields: { model: string; attributes: any }): void {
    this.componentMainForm = this.formHandler.groupedFormSimple(formFields);
    this.loadingForm.next(false);
    this.formHandler
      .groupSetLabelsRules(formFields, this.componentMainForm)
      .then();
  }

  private handleAttachments(attachmentsParam): void {
    const attachmentsArray = [];

    let i = 0;
    for (const key in attachmentsParam) {
      const att: Attachment = {
        name: attachmentsParam[key],
        path: key,
        checked: i++ === 0,
      };

      attachmentsArray.push(att);
    }

    this.attachments.next(attachmentsArray);
  }

  private refreshMandrillInfoSingleMail(mailObject): void {
    const mailId = mailObject['id'];

    const singleMailLoadingInst = this.singleMailLoading.value;
    singleMailLoadingInst[mailId] = true;
    const newSingleMailLoading = { ...singleMailLoadingInst };
    this.singleMailLoading.next(newSingleMailLoading);
    const url = this.urlPrefix + '/mail/getInfo/id/' + mailId;
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.handleMailResponse(data);
    });
  }

  private handleMailResponse(data): void {
    const mailId = data['model']['id'];
    const mailMandrillInfo = JSON.parse(data['model']['mandrillInfo']);

    if (data.status === 'success') {
      const mails = this.mailList.value;
      for (const index in mails) {
        const mailObject = mails[index];

        if (mailObject['id'] === mailId) {
          mailObject['translatedState'] =
            this.helperService.upperCaseFirstLetter(
              data['model']['translatedState']
            );
          mailObject['mandrillInfo'] = mailMandrillInfo;
          break;
        }
      }
      const newMailList = [...mails];
      this.mailList.next(newMailList);
    }

    const singleMailLoadingInst = this.singleMailLoading.value;
    singleMailLoadingInst[mailId] = false;
    const newSingleMailLoading = { ...singleMailLoadingInst };
    this.singleMailLoading.next(newSingleMailLoading);
  }

  public closeAction(): void {
    this.dialogRef.close();
  }

  public refreshPreview(): void {
    this.previewIsLoading = true;
    const attachments = [];
    const currentAttachments = this.attachments.value;
    for (const attachment of currentAttachments) {
      if (attachment['checked']) {
        attachments.push('attachments[]=' + attachment['path']);
      }
    }
    const baseUrl = '/company/MailPdfPreview';
    const url = baseUrl + '?' + attachments.join('&');
    this.previewUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public onPreviewLoaded(): void {
    this.previewIsLoading = false;
    // Hack to force the editor to refresh
    // Fixes odd bug where the editors content is duplicated in safari
    if (this.editor?.el?.nativeElement) {
      this.editor.el.nativeElement.style.display = 'none';
      this.editor.el.nativeElement.style.display = 'block';
    }
  }
}

interface Attachment {
  name: string;
  path: string;
  safePath?: SafeUrl;
  checked: boolean;
}
