<div class="p-grid">
  <div class="p-col-12">
    <div class="p-grid">
      <div class="p-col-12 p-lg-2 p-g-nopad">
        <div class="sub-menu sidebar-left">
          <h4>Dela projekt med</h4>
          <ul>
            <li
              [class.active]="activeLink === 'customer'"
              (click)="activeLink = 'customer'"
            >
              <a title="Kund">Kund</a>
            </li>
            <li
              *ngIf="shareProjectPartner"
              [class.active]="activeLink === 'company'"
              (click)="activeLink = 'company'"
            >
              <a title="company">Samarbetspartner </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="p-col-12 p-lg-10 p-g-nopad">
        <hyperion-card>
          <div class="p-grid">
            <div class="p-col-12">
              <app-share-project-customer
                *ngIf="activeLink === 'customer'"
                [projectInfo]="projectInfo"
              ></app-share-project-customer>

              <ng-container *ngIf="activeLink === 'company'">
                <app-share-project-company
                  [projectInfo]="projectInfo"
                ></app-share-project-company>
                <share-project-client-rights
                  [projectInfo]="projectInfo"
                ></share-project-client-rights>
              </ng-container>
            </div>
          </div>
        </hyperion-card>
      </div>
    </div>
  </div>

  <div
    class="p-col-12"
    *ngIf="!(relationshipsLoading | async) && activeLink === 'customer'"
  >
    <app-emptyState *ngIf="dataSet.length === 0"></app-emptyState>
    <div *ngFor="let row of dataSet; trackBy: helperService.rowTrackBy">
      <div class="p-grid">
        <div class="p-col-12">
          <hyperion-card>
            <project-share-row
              [dataObjectfromParent]="row"
              [projectInfo]="projectInfo"
            ></project-share-row>
          </hyperion-card>
        </div>
      </div>
    </div>
  </div>
</div>
