<div class="p-col-12">
  <div class="column-title">
    <h4>Dela projekt</h4>
  </div>
  <div class="column-body">
    <p>
      Här kan du dela ditt projekt med kunden. Du kan knyta flera projekt till
      en och samma kontakt och tvärtom.

      <br /><br /><strong>Detta gör du i följande tre steg:</strong> <br />1.
      Välj den kontakt eller skriv in uppgifter för den personen du vill dela
      projektet med. <br />2. Välj rättigheter, alltså vad ska kunden kunna se
      när den loggar in. <br />3. Tryck på "Dela projekt". Ett mail med
      inloggningsuppgifter skickas till kontakten.
    </p>
  </div>
</div>
<div class="p-col-12 p-lg-7 p-g-nopad">
  <div class="p-col-12">
    <div class="column-title">
      <h4>Ange uppgifter</h4>
    </div>
    <spinner *ngIf="loading"></spinner>
    <div class="column-body" *ngIf="!loading">
      <form [formGroup]="inputForm">
        <div class="p-col-12">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
            <p-autoComplete
              [suggestions]="results"
              placeholder="Sök kontakt..."
              (completeMethod)="searchContacts($event)"
              inputStyleClass="form-control"
              [(ngModel)]="autoCompleteModel"
              [ngModelOptions]="{ standalone: true }"
              (onSelect)="setContactFromAutosuggest($event)"
              [style]="{ width: '100%' }"
              styleClass="wide-panel"
              [inputStyle]="{ width: '100%' }"
              field="id"
            >
              <ng-template let-object pTemplate="item">
                <div class="p-helper-clearfix" style="font-size: 12px">
                  <div class="autocomplete-row autocomplete-header-row">
                    <div style="width: 10%">ID</div>
                    <div style="width: 22%">Namn</div>
                    <div style="width: 23%">Företag</div>
                    <div style="width: 42%">Adress</div>
                    <div style="float: none" class="clear-both"></div>
                  </div>
                  <div class="autocomplete-row">
                    <div style="width: 10%">{{ object.trueId }}</div>
                    <div style="width: 22%">{{ object.name }}</div>
                    <div style="width: 22%">{{ object.orderBuisnessName }}</div>
                    <div style="width: 22%">
                      {{ object.address }}, {{ object.address2 }}
                    </div>
                    <div style="width: 22%">
                      {{ object.cityCode }}, {{ object.city }}
                    </div>
                    <div style="float: none" class="clear-both"></div>
                  </div>
                </div>
              </ng-template>
            </p-autoComplete>
          </div>
        </div>

        <appForm2-simple-input
          formControlName="firstName"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="lastName"
        ></appForm2-simple-input>

        <appForm2-simple-input formControlName="email"></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="telephonenumber"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="mobilenumber"
        ></appForm2-simple-input>
      </form>
    </div>
  </div>
</div>
<div class="p-col-12 p-lg-5 p-g-nopad">
  <form [formGroup]="checkBoxesForm" *ngIf="!loading">
    <div class="p-grid">
      <div class="p-col-12 p-lg-7">
        <div class="column-title">
          <h4>Sätt rättigheter</h4>
        </div>
        <div class="column-body">
          <appForm2-list-checkbox
            formControlName="offer"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="timereport"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="product"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="invoice"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="picture"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="document"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="todo"
          ></appForm2-list-checkbox>

          <appForm2-list-checkbox
            formControlName="comment"
          ></appForm2-list-checkbox>
        </div>
      </div>
      <div class="p-col-12 p-lg-5">
        <div class="column-title">
          <h4>
            Notifikation
            <span
              class="help-tooltip smaller"
              tooltipPosition="bottom"
              pTooltip="Klicka i detta alternativ för respektive post om du vill att ett mail ska skickas ut varje gång en post skapas."
              [escape]="false"
              >?</span
            >
          </h4>
        </div>
        <div class="column-body">
          <div class="checkbox-formgroup-space show-lg"></div>
          <div class="checkbox-formgroup-space show-lg"></div>
          <div class="checkbox-formgroup-space show-lg"></div>

          <appForm2-list-checkbox
            formControlName="invoiceNotification"
          ></appForm2-list-checkbox>

          <div class="checkbox-formgroup-space show-lg"></div>

          <appForm2-list-checkbox
            formControlName="documentNotification"
          ></appForm2-list-checkbox>
        </div>
      </div>
    </div>
  </form>
  <div class="p-grid">
    <div class="p-col-12 align-right">
      <app-button
        overrideButtonType="primary"
        [disabled]="lockButtons || loading"
        (onClick)="actionCreate()"
        type="share"
        model="Project"
      ></app-button>
    </div>
  </div>
</div>
