import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppStateService } from 'app/store/app-state.service';
import { SortService } from 'app/store/sort.service';

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [AppStateService, SortService],
})
export class StoreModule {}
