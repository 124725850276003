<div class="p-grid">
  <div class="p-col-12">
    <spinner *ngIf="loading | async"></spinner>
  </div>
  <div class="p-grid" *ngIf="!(loading | async)">
    <div class="p-col-12 p-g-nopad">
      <div class="p-col-12">
        <label>Dagar att rapportera</label>
        <p-dropdown
          [(ngModel)]="selectedDays"
          [options]="daysToReportOptions"
          appendTo="body"
        >
        </p-dropdown>
      </div>
      <div class="p-col-12">
        <appForm2-list-checkbox
          [tooltip]="{ msg: mdTooltip }"
          [(ngModel)]="showSubsistenceOnTimereportBool"
          [htmlAttributes]="{
            label: { value: 'Aktivera val för traktamente' }
          }"
        ></appForm2-list-checkbox>
      </div>
    </div>
    <div class="p-col-12 align-right">
      <app-button
        [disabled]="buttons.update.disabled"
        (onClick)="actionUpdate()"
        [type]="'save'"
      ></app-button>
    </div>
  </div>
</div>
