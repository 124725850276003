import { Component, Input, OnInit } from '@angular/core';
import { LaborCosts } from '../project-overview/project-overview.service';

@Component({
  selector: 'app-project-overview-miles',
  templateUrl: './project-overview-miles.component.html',
  styleUrls: ['./project-overview-miles.component.scss'],
})
export class ProjectOverviewMilesComponent implements OnInit {
  @Input() public costs: LaborCosts;

  constructor() {}

  ngOnInit(): void {}
}
