import { Injectable } from '@angular/core';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';

import { ProjectUnderlagComponent } from 'app/old-project/project-underlag/project-underlag.component';
import { ProjectContentDialogComponent } from 'app/old-project/project-content/project-content-dialog.component';

@Injectable({ providedIn: 'root' })
export class AppDialogService extends DialogService {
  public data: any = {};
  public layout: 'auto' | 'wide' = 'auto';
  public showHeader = true;

  private layouts = {
    auto: { width: 'auto' },
    wide: { width: '90vw' },
  };

  private params = {
    data: {},
    header: null,
    width: this.layouts[this.layout].width,
    showHeader: this.showHeader,
    contentStyle: {
      'max-height': '80vh',
      'max-width': '90vw',
      'overflow-y': 'auto',
    },
  };

  openComponent(
    Component,
    header = 'Choose Header',
    styleClass = null
  ): DynamicDialogRef {
    return this.open(Component, {
      ...this.params,
      header,
      data: this.data,
      width: this.layouts[this.layout].width,
      showHeader: this.showHeader,
      styleClass: styleClass,
    });
  }
}
