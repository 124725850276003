import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-seo-info',
  templateUrl: './seo-info.component.html',
})
export class SeoInfoComponent {
  @Input() title: string;
  @Input() description: string;
}
