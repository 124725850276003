import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ConvertNumberService {
  constructor() {}

  public fromString(value: string): number {
    if (!value) {
      return null;
    }
    if (typeof value === 'number') {
      return value;
    }
    if (typeof value !== 'string') {
      return NaN;
    }
    return Number(value.replace(',', '.'));
  }

  public toString(value: number): string {
    return value.toLocaleString('sv-SE');
  }
}
