<div class="p-grid">
  <div class="p-col-12">
    <p-table
      #freeUsersTable
      dataKey="id"
      [rowHover]="true"
      [rowTrackBy]="helperService.rowTrackBy"
      [value]="dataSet | async"
      [columns]="freeUsersTableColumns"
      (onEditComplete)="editCompleteHandler($event)"
    >
      <ng-template pTemplate="caption">
        <div class="p-inputgroup" style="font-size: 13px; margin: 0">
          <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
          <input
            type="text"
            [(ngModel)]="globalSearchString"
            pInputText
            (input)="
              freeUsersTable.filterGlobal($event.target.value, 'contains')
            "
            placeholder="Sök bland {{ totalCount }} personer"
          />
        </div>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td colspan="4">
            <app-emptyState [model]="'AttendanceFreeUser'"></app-emptyState>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'firstName'">
            Förnamn
            <p-sortIcon [field]="'firstName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'lastName'">
            Efternamn
            <p-sortIcon [field]="'lastName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'personalNumber'">
            Personnummer
            <p-sortIcon [field]="'personalNumber'"></p-sortIcon>
          </th>
          <th style="width: 10%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td
            pEditableColumn
            [pEditableColumn]="rowData"
            [pEditableColumnField]="'firstName'"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="rowData.firstName" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.firstName }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            pEditableColumn
            [pEditableColumn]="rowData"
            [pEditableColumnField]="'lastName'"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="rowData.lastName" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.lastName }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td
            pEditableColumn
            [pEditableColumn]="rowData"
            [pEditableColumnField]="'personalNumber'"
          >
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input type="text" [(ngModel)]="rowData.personalNumber" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ rowData.personalNumber }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <i
              class="fa fa-floppy-o cursor-pointer"
              style="font-size: 14px; margin-right: 7px"
              pTooltip="Spara ändringar"
              tooltipPosition="top"
              (click)="actionUpdate(rowData)"
            ></i>
            <i
              class="fa fa-times delete"
              style="font-size: 14px"
              pTooltip="Radera person"
              tooltipPosition="top"
              (click)="deleteClickHandler(rowData)"
            ></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
