import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  EventColorsEnum,
  EventTextColorEnum,
} from 'app/planner/planner-module-enums';
import { LightboxDropdown } from 'app/planner/planner-module-interfaces';
import { ColorNames } from 'app/planner/utils/color-names';
import { GlobalService } from 'app/shared/global';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-scheduler-side-menu',
  templateUrl: './scheduler-side-menu.component.html',
  styleUrls: ['./scheduler-side-menu.component.scss'],
})
export class SchedulerSideMenuComponent implements OnChanges {
  @Input() private dateRange: { start: Date; end: Date };
  @Input() private projects: LightboxDropdown[];
  @Input() private coworkers: LightboxDropdown[];
  @Output() private datePicked: EventEmitter<Date[]> = new EventEmitter<
    Date[]
  >();
  @Output() private filterUpdate: EventEmitter<{
    projects: LightboxDropdown[];
    coworkers: LightboxDropdown[];
    colors: string[];
  }> = new EventEmitter<{
    projects: LightboxDropdown[];
    coworkers: LightboxDropdown[];
    colors: string[];
  }>();

  @ViewChild('filterProjects', { static: true })
  private filterProjectsDropdown: Dropdown;
  @ViewChild('filterCoworkers', { static: true })
  private filterCoworkersDropdown: Dropdown;

  public filterForm: FormGroup;
  public datePickerLocale: any;
  public isDisplayingProjects = false;
  public activeColors: string[] = [];
  public getColors = (): string[] => Object.values(EventColorsEnum);
  public getColorName = (color: EventColorsEnum): string => ColorNames[color];
  public getColorText = (color: EventColorsEnum): EventTextColorEnum => {
    switch (color.toUpperCase()) {
      case EventColorsEnum.LightGreen:
      case EventColorsEnum.Lime:
      case EventColorsEnum.Yellow:
      case EventColorsEnum.Amber:
      case EventColorsEnum.Orange:
        return EventTextColorEnum.Black;
      default:
        return EventTextColorEnum.White;
    }
  };

  constructor(
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');
    this.filterForm = this.formBuilder.group({
      miniCalendar: null,
      projects: null,
      coworkers: null,
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dateRange && changes.dateRange.currentValue) {
      const range = changes.dateRange.currentValue;
      this.filterForm.get('miniCalendar').setValue([range.start, range.end]);
    }

    if (this.projects && this.coworkers) {
      this.filterProjectsDropdown.options = this.projects;
      this.filterCoworkersDropdown.options = this.coworkers;
    }
  }

  public handleMiniCalendarChange(): void {
    const dates = this.filterForm.get('miniCalendar').value;
    this.datePicked.emit(dates);
  }

  public filterChanged(): void {
    const projects = this.filterForm.get('projects').value;
    const coworkers = this.filterForm.get('coworkers').value;

    const filterData = {
      projects: projects?.length > 0 ? projects : [],
      coworkers: coworkers?.length > 0 ? coworkers : [],
      colors: this.activeColors,
    };
    this.filterUpdate.emit(filterData);
  }

  public isRedDay = (date: any): boolean => {
    const day = new Date(date.year, date.month, date.day);
    return day.getDay() === 0;
  };

  public isSelected = (color: EventColorsEnum): boolean =>
    !!this.activeColors.find((c: string) => c === color);

  public selectColor = (color: EventColorsEnum): void => {
    const index = this.activeColors.findIndex(c => c === color);
    if (index !== -1) {
      this.activeColors.splice(index, 1);
    } else {
      this.activeColors.push(color);
    }

    this.filterChanged();
  };

  public removeFilter = (value: number, controlName: string): void => {
    const controller = this.filterForm.get(controlName);
    const selectedValues: LightboxDropdown[] = controller.value;
    const index = selectedValues.findIndex(
      selected => +selected.value === value
    );
    if (index !== -1) {
      selectedValues.splice(index, 1);
      controller.patchValue(selectedValues);
      this.filterChanged();
    }
  };
}
