import { Component, Input, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { MessageService } from 'app/shared/message';
import { GlobalService } from 'app/shared/global';
import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { HttpService } from 'app/shared/http';
import { HtmlModalService } from 'app/shared/html-modal';
import { AppDialogService } from 'app/shared/dialogs';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';

import {
  DeleteImageEvent,
  ImageGalleryComponent,
  RotateImageEvent,
  UpdateImageEvent,
  Image,
} from 'app/shared/extended-directives/image-gallery';
import { UploadProjectImagesComponent } from './upload-project-images.component';
import { Observable } from 'rxjs';
import { ProjectImagesService } from './project-images.service';

@Component({
  selector: 'project-images',
  templateUrl: 'project-images.component.html',
  providers: [AppDialogService],
})
export class ProjectImagesComponent {
  @Input() projectInfo;
  @Input() count;
  @ViewChild(ImageGalleryComponent, { static: true })
  private galleryComponent: ImageGalleryComponent;

  public get imageEndpointUri(): string {
    return `${this.urlPrefix}/project/hyperionpictures/projectId/${this.projectInfo.id}`;
  }

  public images: Image[] = [];
  meUser: MeUserWithCompany;
  public isLoading = true;
  dataModel = 'projectImages';
  dataSet = [];
  allData;
  urlPrefix;
  pdfWindow;

  constructor(
    private messageService: MessageService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private httpService: HttpService,
    private htmlModalService: HtmlModalService,
    private apolloQueryService: ApolloQueryService,
    private dialogService: AppDialogService,
    private userLocalStorageService: UserLocalStorageService,
    private projectImagesService: ProjectImagesService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  errorImg(id) {
    for (const index in this.dataSet) {
      const img = this.dataSet[index];

      if (id === img.id) {
        img['picture'] = 'false';
        break;
      }
    }
  }

  handleUpdateEmit(event: UpdateImageEvent) {
    this.actionUpdate(event);
  }

  handleDeleteEmit(event: DeleteImageEvent) {
    this.confirmDeleteImage(event);
  }

  confirmDeleteImage(variablesParam: DeleteImageEvent) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort bilden?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteImage(variablesParam);
      },
      reject: () => {},
    });
  }

  public fetchImages(event): void {
    this.isLoading = true;
    this.projectImagesService
      .fetch(this.projectInfo.id, event.take, event.skip)
      .subscribe(images => {
        this.images = images;
        this.isLoading = false;
      });
  }

  private deleteImage(variablesParam: DeleteImageEvent) {
    const crudType = 'delete';
    const dataToMutation = variablesParam;
    const refetchVars = { id: +this.projectInfo['id'] };
    const refetchArr = [
      { name: 'singleProjectTotalCounts', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'pictureComment',
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (!executedData.mutationSucceededAllArguments) {
            this.mutationErrorsToChild(executedData);
          } else {
            this.galleryComponent.handleDeletedImage(
              variablesParam['id'],
              executedData
            );
          }

          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  actionUpdate(variables: UpdateImageEvent) {
    const crudType = 'update';
    const dataToMutation = variables;
    const returnAttributes = this.getReturnAttributes();

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'pictureComment',
        crudType,
        false,
        dataToMutation,
        returnAttributes
      )
      .subscribe(
        executedData => {
          if (!executedData.mutationSucceededAllArguments) {
            this.mutationErrorsToChild(executedData);
          }

          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  handleRotateEmit(eventParam: RotateImageEvent) {
    const url =
      '/project/RotatePictureHyperion?pictureCommentId=' +
      eventParam.id +
      '&degress=' +
      eventParam.degrees;

    this.httpService.makeHttpPostRequest(url).subscribe(data => {
      if (data.hasOwnProperty('id')) {
        this.messageService.insertData({
          textArray: ['Bilden roterades'],
          type: 'success',
        });
        this.galleryComponent.handleRotatedImage(data);
      } else {
        this.messageService.insertData(
          {
            textArray: ['Något blev fel, kunde inte rotera bild'],
            type: 'error',
          },
          true
        );
      }
    });
  }

  exportFile(mode) {
    let urlParam;

    if (mode === 'download') {
      urlParam =
        this.globalService.getUrlPrefix() +
        '/project/DownloadPictures?projectId=' +
        this.projectInfo['id'];
    } else {
      urlParam =
        this.globalService.getUrlPrefix() +
        '/project/printPicture/id/' +
        this.projectInfo['id'] +
        '/type/' +
        mode;
    }

    if (mode === 'showPDF') {
      this.pdfWindow = this.htmlModalService.ny_sida(urlParam, '700', '800');
    } else {
      window.open(urlParam);
    }
  }

  exportFilePostRequest(urlParam, mode) {
    if (mode === 'showPdf') {
      this.pdfWindow = this.htmlModalService.emptyPage('700', '800');
    }

    this.httpService.makeHttpPostRequest(urlParam).subscribe(data => {
      const dataAsJSON = data.json();

      const fileDownloadLink =
        this.globalService.getUrlPrefix() + dataAsJSON['pathForDocument'];

      this.serveFile(fileDownloadLink, mode);
    });
  }

  serveFile(fileDownloadLink, mode) {
    if (mode === 'showPdf') {
      this.pdfWindow.location.href = fileDownloadLink;
    } else {
      location.href = fileDownloadLink;
    }
  }

  mutationErrorsToChild(executedData) {
    this.galleryComponent.handleErrorsInMutations(executedData);
  }

  getReturnAttributes() {
    const attributesToReturn = ['id', 'comment', 'displayOnPrint'];
    return attributesToReturn;
  }

  public handleNewImages(images): void {
    if (typeof images !== 'undefined') {
      if (images.length > 0) {
        const refetchVars = { id: +this.projectInfo['id'] };
        this.apolloQueryService
          .apolloQuery('singleProjectTotalCounts', refetchVars)
          .subscribe();
        this.galleryComponent.handleNewImages(images);
      }
    }
  }

  openAddImages() {
    this.dialogService.layout = 'wide';
    this.dialogService.data = { projectInfo: this.projectInfo };
    this.dialogService
      .openComponent(UploadProjectImagesComponent)
      .onClose.subscribe(res => {
        if (typeof res !== 'undefined') {
          if (res.length > 0) {
            const refetchVars = { id: +this.projectInfo['id'] };
            this.apolloQueryService
              .apolloQuery('singleProjectTotalCounts', refetchVars)
              .subscribe();
            this.galleryComponent.handleNewImages(res);
          }
        }
      });
  }
}
