<div class="p-grid project-share-row">
  <spinner *ngIf="loading"></spinner>
  <div class="p-col-12 p-lg-4 p-g-nopad" *ngIf="!loading">
    <div class="p-col-12">
      <div class="column-title">
        <h4>Delat till</h4>
      </div>
      <div class="column-body">
        <div>
          {{ dataObjectfromParent.privateCostumer.firstName }}
          {{ dataObjectfromParent.privateCostumer.lastName }}
        </div>
        <div>{{ dataObjectfromParent.privateCostumer.email }}</div>
        <div>
          <a target="_blank" [href]="customerViewLink"
            >Visa användarens vy när den loggar in</a
          >
        </div>
      </div>
    </div>
  </div>
  <div class="p-col-12 p-lg-4 p-g-nopad" *ngIf="!loading">
    <form [formGroup]="customerRelationshipForm">
      <div class="p-grid">
        <div class="p-col-12 p-lg-7">
          <div class="column-title">
            <h4>Sätt rättigheter</h4>
          </div>

          <div class="column-body mb20">
            <appForm2-list-checkbox
              formControlName="offer"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="timereport"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="product"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="invoice"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="picture"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="document"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="todo"
            ></appForm2-list-checkbox>

            <appForm2-list-checkbox
              formControlName="comment"
            ></appForm2-list-checkbox>
          </div>
        </div>
        <div class="p-col-12 p-lg-5">
          <div class="column-title">
            <h4>
              Notifikation
              <span
                class="help-tooltip smaller"
                tooltipPosition="bottom"
                pTooltip="Klicka i detta alternativ för respektive post om du vill att ett mail ska skickas ut varje gång en post skapas."
                [escape]="false"
                >?</span
              >
            </h4>
          </div>
          <div class="column-body mb20">
            <div class="checkbox-formgroup-space show-lg"></div>
            <div class="checkbox-formgroup-space show-lg"></div>
            <div class="checkbox-formgroup-space show-lg"></div>

            <appForm2-list-checkbox
              formControlName="invoiceNotification"
            ></appForm2-list-checkbox>

            <div class="checkbox-formgroup-space show-lg"></div>

            <appForm2-list-checkbox
              formControlName="documentNotification"
            ></appForm2-list-checkbox>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="p-col-12 p-lg-4 p-g-nopad" *ngIf="!loading">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="column-title">
          <h4>Administrera</h4>
        </div>
        <div class="column-body">
          <div class="p-grid">
            <div class="p-col-6">
              <app-button
                [disabled]="lockButtons"
                (onClick)="confirmDeleteComment()"
                [type]="'delete'"
              ></app-button>
            </div>
            <div class="p-col-6">
              <app-button
                [disabled]="lockButtons"
                (onClick)="actionUpdate()"
                [type]="'update'"
              ></app-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
