import { Injectable } from '@angular/core';
import { Config, EnvConfig } from './config';
import { config as struqturConfig } from './configs/struqtur.config';
import { config as bmedConfig } from './configs/bmed.config';
import { config as enklakontoretConfig } from './configs/enklakontoret.config';
import { config as proffsappConfig } from './configs/proffsapp.config';
import { config as trebolitConfig } from './configs/trebolit.config';
import { config as wabyggConfig } from './configs/wabygg.config';

import { config as localConfig } from './env-config/local.config';
import { config as developmentConfig } from './env-config/development.config';
import { config as stagingConfig } from './env-config/staging.config';
import { config as productionConfig } from './env-config/production.config';

export enum ConfigType {
  Struqtur,
  Bmed,
  Enklakontoret,
  Proffsapp,
  Trebolit,
  Wabygg,
}

export enum EnvConfigType {
  Local,
  Development,
  Staging,
  Production,
}

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configs: ReadonlyMap<ConfigType, Config> = new Map([
    [ConfigType.Struqtur, struqturConfig],
    [ConfigType.Bmed, bmedConfig],
    [ConfigType.Enklakontoret, enklakontoretConfig],
    [ConfigType.Proffsapp, proffsappConfig],
    [ConfigType.Trebolit, trebolitConfig],
    [ConfigType.Wabygg, wabyggConfig],
  ]);

  private envConfigs: ReadonlyMap<EnvConfigType, EnvConfig> = new Map([
    [EnvConfigType.Local, localConfig],
    [EnvConfigType.Development, developmentConfig],
    [EnvConfigType.Staging, stagingConfig],
    [EnvConfigType.Production, productionConfig],
  ]);

  public configType: ConfigType = ConfigType.Struqtur;
  public envConfigType: EnvConfigType = EnvConfigType.Production;

  constructor() {
    this.configType = this.getConfigTypeFromUrl();
    this.envConfigType = this.getEnvConfigTypeFromUrl();
  }

  public getConfig(): Config {
    return this.configs.get(this.configType);
  }

  public getEnvConfig(): EnvConfig {
    return this.envConfigs.get(this.envConfigType);
  }

  private getConfigTypeFromUrl(): ConfigType {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    for (const type of this.configs.keys()) {
      const config = this.configs.get(type);
      if (config.subdomain.includes(subdomain)) {
        return type;
      }
    }

    return ConfigType.Struqtur;
  }

  private getEnvConfigTypeFromUrl(): EnvConfigType {
    const host = window.location.host;

    if (host.includes('localhost')) {
      return EnvConfigType.Local;
    }

    if (host.includes('d-struqtur.com')) {
      return EnvConfigType.Development;
    }

    if (host.includes('s-struqtur.com')) {
      return EnvConfigType.Staging;
    }

    return EnvConfigType.Production;
  }
}
