import { Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Component, Input, OnChanges } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  ProjectTodoStatus,
  MapTypeToDropdown,
  EventColorsEnum,
  RepeatEventEnum,
  WeekdayEnum,
  PlannerViewContext,
} from 'app/planner/planner-module-enums';
import {
  LightboxDropdown,
  ProjectTodoMaper,
  SchedulerEventPlannedTime,
} from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import {
  Project,
  ProjectTodo,
  UserPlannedWork,
} from 'app/planner/services/planner-query-types';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import { GlobalService } from 'app/shared/global';
import { DateService } from 'app/shared/helpers/date.service';
import { MessageService } from 'app/shared/message';
import * as moment from 'moment';
import { ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-edit-planned-time-view',
  templateUrl: './edit-planned-time-view.component.html',
  styleUrls: ['./edit-planned-time-view.component.scss'],
})
export class EditPlannedTimeViewComponent implements OnChanges {
  @Input() public isNewEvent: boolean;
  @Input() public projects: Project[];
  @Input() public coworkersDropdown: LightboxDropdown[];
  @Input() public allTodos: ProjectTodoMaper[];
  @Input() private scheduledEvent: SchedulerEventPlannedTime;
  @Input() public viewContext: PlannerViewContext;

  @Output()
  public closeLightBox: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public saveEvent: EventEmitter<any> = new EventEmitter();
  @Output()
  public updateEvent: EventEmitter<UserPlannedWork> = new EventEmitter<UserPlannedWork>();
  @Output()
  public deleteEvent: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('repeatDays') private repeatDays: ElementRef;

  private readonly ON_WEEKEND = 1;
  private readonly BREAK_DURATION = 60;

  public PlannerViewContext = PlannerViewContext;
  public isVisible = true;
  public form: FormGroup;
  public title: string;
  public isDataLoading = false;
  public isDataDeleting = false;
  public todosDropdown: LightboxDropdown[] = [];
  public projectsDropdown: LightboxDropdown[] = [];
  public datePickerLocale: any;
  public minDate: Date = null;
  public maxDate: Date = null;
  public repeatOptions: LightboxDropdown[] = [
    { label: 'Upprepas inte', value: RepeatEventEnum.NoRepeat },
    { label: 'Dagligen', value: RepeatEventEnum.Daily },
    { label: 'Veckovis', value: RepeatEventEnum.Weekly },
    { label: 'Anpassad', value: RepeatEventEnum.Custom },
  ];
  public repeatDaysOptions: LightboxDropdown[] = [
    { label: 'Mån', value: WeekdayEnum.Monday },
    { label: 'Tis', value: WeekdayEnum.Tuesday },
    { label: 'Ons', value: WeekdayEnum.Wednesday },
    { label: 'Tors', value: WeekdayEnum.Thursday },
    { label: 'Fre', value: WeekdayEnum.Friday },
    { label: 'Lör', value: WeekdayEnum.Saturday },
    { label: 'Sön', value: WeekdayEnum.Sunday },
  ];
  public validationTextList: string[] = [];
  public validationText = (list: string[]) => list.filter(Boolean).join('\n');

  public handleCloseLightboxEvent = () => {
    this.closeLightBox.emit(false);
  };

  constructor(
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private messageService: MessageService,
    private dateService: DateService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');
  }

  public ngOnChanges(): void {
    this.mapProjectDropdown();

    if (this.isNewEvent) {
      this.title = 'Skapa ny planerad tid';
      this.scheduledEvent.text = null;
    } else {
      this.title = 'Redigera planerad tid';
    }

    this.form = this.createFormGroup(this.scheduledEvent);
    this.watchRepeatChanges();
    this.updateTodosDropdown();
    this.form.get('project').valueChanges.subscribe(() => {
      this.form.get('todo').setValue(null);
      const start = this.form.get('startDate');
      start.setErrors(null);
      start.updateValueAndValidity();

      const end = this.form.get('endDate');
      end.setErrors(null);
      end.updateValueAndValidity();
    });
  }

  public onChangeStartDate(): void {
    const start = this.form.get('startDate');
    const startDate = moment(start.value);

    const end = this.form.get('endDate');
    const endDate = moment(end.value)
      .year(startDate.year())
      .month(startDate.month())
      .date(startDate.date());

    end.setValue(endDate.format('YYYY-MM-DD HH:mm'));
  }

  /**
   * Needed for old versions of Safari which returns
   * NaN errors when space used as time separator
   */
  private replaceSpaceWithTimeSeparator(date: string | Date): string | Date {
    if (typeof date === 'string') {
      return date.replace(' ', 'T');
    }

    return date;
  }

  private createFormGroup(dataItem: SchedulerEventPlannedTime): FormGroup {
    const group = this.formBuilder.group(
      {
        id: dataItem.id,
        startDate: [
          new Date(
            this.replaceSpaceWithTimeSeparator(
              dataItem.realStartDate
                ? dataItem.realStartDate
                : dataItem.start_date
            )
          ),
          Validators.required,
        ],
        endDate: [
          new Date(
            this.replaceSpaceWithTimeSeparator(
              dataItem.realEndDate ? dataItem.realEndDate : dataItem.end_date
            )
          ),
          Validators.required,
        ],
        project: [null, Validators.required],
        coworker: [null, Validators.required],
        todo: null,
        text: dataItem.text,
        color: dataItem.color
          ? dataItem.color.toUpperCase()
          : EventColorsEnum.Blue,
        repeat: RepeatEventEnum.NoRepeat,
        repeatDays: [],
        repeatEndDate: moment(dataItem.end_date).add(1, 'day').toDate(),
      },
      {
        validators: [
          this.dateLessThan('startDate', 'endDate'),
          this.startDateWithinProjectRange(),
          this.endDateWithinProjectRange(),
          this.startDateWithinTodoRange(),
          this.endDateWithinTodoRange(),
        ],
      }
    );

    this.updateInitialDropdownValue(dataItem, group);

    if (group.get('project').value) {
      group.get('todo').enable();
    } else {
      group.get('todo').disable();
    }

    return group;
  }

  private dateLessThan(from: string, to: string): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const f = new Date(group.controls[from].value);
      const t = new Date(group.controls[to].value);
      if (f.getTime() > t.getTime()) {
        this.validationTextList[0] =
          'Startdatum måste vara tidigare än slutdatum.';
        const error = {
          invalidDates: true,
        };
        group.controls[from].setErrors(error);
        group.controls[to].setErrors(error);
        return error;
      }
      group.controls[from].setErrors(null);
      group.controls[to].setErrors(null);
      this.validationTextList[0] = null;
      return null;
    };
  }

  private startDateWithinProjectRange(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const startDate = new Date(group.controls['startDate'].value);
      const projectId = group.controls['project'].value;
      if (projectId) {
        const project = this.projects.find(
          proj => +proj.id === +projectId.value
        );

        if (this.plannerDataAdapterService.isSystemTypeProject(project)) {
          this.validationTextList[2] = null;
          return null;
        }

        const start = moment(project.startDate)
          .set('hours', 0)
          .set('minutes', 0)
          .toDate();
        const end = moment(project.endDate)
          .set('hours', 23)
          .set('minutes', 59)
          .toDate();
        this.minDate = start;
        this.maxDate = end;
        if (
          startDate.getTime() < start.getTime() ||
          startDate.getTime() > end.getTime()
        ) {
          this.validationTextList[2] =
            'Startdatum måste vara inom projektets start och slut.';
          const error = {
            invalidStartDateProject: true,
          };
          group.controls['startDate'].setErrors(error);
          return error;
        }
      }
      this.validationTextList[2] = null;
      return null;
    };
  }
  private endDateWithinProjectRange(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const endDate = new Date(group.controls['endDate'].value);
      const projectId = group.controls['project'].value;
      if (projectId) {
        const project = this.projects.find(
          proj => +proj.id === +projectId.value
        );

        if (this.plannerDataAdapterService.isSystemTypeProject(project)) {
          this.validationTextList[3] = null;
          return null;
        }

        const start = moment(project.startDate)
          .set('hours', 0)
          .set('minutes', 0)
          .toDate();
        const end = moment(project.endDate)
          .set('hours', 23)
          .set('minutes', 59)
          .toDate();
        if (
          endDate.getTime() > end.getTime() ||
          endDate.getTime() < start.getTime()
        ) {
          this.validationTextList[3] =
            'Slutdatum måste vara inom projektets start och slut.';
          const error = {
            invalidEndDateProject: true,
          };
          group.controls['endDate'].setErrors(error);
          return error;
        }
      }
      this.validationTextList[3] = null;
      return null;
    };
  }

  private startDateWithinTodoRange(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const startDate = new Date(group.controls['startDate'].value);
      const projectId = group.controls['project'].value;
      const todoId = group.controls['todo'].value;
      if (projectId && todoId) {
        const projectTodo = this.allTodos.find(
          at => +at.projectId === +projectId.value
        );
        const todo = projectTodo.todos.find(to => +to.id === +todoId.value);
        if (!todo) {
          this.validationTextList[4] = null;
          return null;
        }
        const start = moment(todo.startDate)
          .set('hours', 0)
          .set('minutes', 0)
          .toDate();
        const end = moment(todo.endDate)
          .set('hours', 23)
          .set('minutes', 59)
          .toDate();
        this.minDate = start;
        this.maxDate = end;
        if (
          startDate.getTime() < start.getTime() ||
          startDate.getTime() > end.getTime()
        ) {
          this.validationTextList[4] =
            'Startdatum måste vara inom arbetsmomentets start och slut.';
          const error = {
            invalidStartDateTodo: true,
          };
          group.controls['startDate'].setErrors(error);
          return error;
        }
      }
      this.validationTextList[4] = null;
      return null;
    };
  }
  private endDateWithinTodoRange(): ValidatorFn {
    return (group: FormGroup): { [key: string]: any } | null => {
      const endDate = new Date(group.controls['endDate'].value);
      const projectId = group.controls['project'].value;
      const todoId = group.controls['todo'].value;
      if (projectId && todoId) {
        const projectTodo = this.allTodos.find(
          at => +at.projectId === +projectId.value
        );
        const todo = projectTodo.todos.find(to => +to.id === +todoId.value);
        if (!todo) {
          this.validationTextList[5] = null;
          return null;
        }
        const start = moment(todo.startDate)
          .set('hours', 0)
          .set('minutes', 0)
          .toDate();
        const end = moment(todo.endDate)
          .set('hours', 23)
          .set('minutes', 59)
          .toDate();
        if (
          endDate.getTime() > end.getTime() ||
          endDate.getTime() < start.getTime()
        ) {
          this.validationTextList[5] =
            'Slutdatum måste vara inom arbetsmomentets start och slut.';
          const error = {
            invalidEndDateTodo: true,
          };
          group.controls['endDate'].setErrors(error);
          return error;
        }
      }
      this.validationTextList[5] = null;
      return null;
    };
  }

  private updateInitialDropdownValue(
    dataItem: SchedulerEventPlannedTime,
    group: FormGroup
  ): void {
    let project = null;
    let coworker = null;
    let todo: LightboxDropdown;
    let projectTodos: ProjectTodoMaper;

    if (dataItem.projectId) {
      project = this.projectsDropdown.find(
        p => +p.value === +dataItem.projectId
      );
      projectTodos = this.allTodos.find(
        p => +p.projectId === +dataItem.projectId
      );
    }

    if (dataItem.userId) {
      coworker =
        this.coworkersDropdown.find(c => +c.value === +dataItem.userId) || null;
    }
    if (dataItem.todoId) {
      const t = projectTodos.todos.find(td => +td.id === +dataItem.todoId);
      todo = t ? { label: t.description, value: t.id } : null;
    }

    group.patchValue({
      project,
      coworker,
      todo,
    });
  }

  public save = (): void => {
    if (this.form.valid) {
      this.isDataLoading = true;
      const plannedWork = this.getPlannedWorkFromForm(this.form);
      this.form.disable();

      const users: LightboxDropdown[] = this.form.get('coworker').value;
      const observables = users.map(user => {
        plannedWork.userId = +user.value;
        return this.plannerWriteService.createPlannedTime(plannedWork);
      });

      forkJoin(observables).subscribe(
        allResults => {
          allResults.forEach((result, index) => {
            if (result.mutationSucceededAllArguments) {
              plannedWork.id = result.id;
              plannedWork.userId = +users[index].value;
              this.saveEvent.emit(plannedWork);
            } else {
              this.messageService.insertDataFromMutation(result);
            }
          });
        },
        error => {
          console.warn(error);
        },
        () => {
          this.isDataLoading = false;
          this.form.enable();
          this.closeLightBox.emit(false);
        }
      );

      const workHours = {
        startHour: moment(plannedWork.startDate).hour(),
        startMinutes: moment(plannedWork.startDate).minutes(),
        endHour: moment(plannedWork.endDate).hour(),
        endMinutes: moment(plannedWork.endDate).minutes(),
      };

      this.dateService.saveStandardWorkHoursLocalStorage(workHours);
    }
  };

  public update(): void {
    if (this.form.valid) {
      this.isDataLoading = true;
      const plannedWork = this.getPlannedWorkFromForm(this.form);
      this.form.disable();
      this.plannerWriteService.updatePlannedTime(plannedWork).subscribe(
        result => {
          if (result.mutationSucceededAllArguments) {
            this.closeLightBox.emit(false);
            this.updateEvent.emit(plannedWork);
          } else {
            this.messageService.insertDataFromMutation(result);
          }
        },
        error => {
          console.warn(error);
        },
        () => {
          this.isDataLoading = false;
          this.form.enable();
        }
      );
    }
  }

  public deleteConfirm(): void {
    this.confirmationService.confirm({
      header: 'Ta bort planerad tid',
      message: 'Vill du verkligen ta bort den planerade tiden?',
      acceptLabel: 'Ta bort',
      rejectLabel: 'Avbryt',
      accept: this.delete,
      reject: () => {},
    });
  }

  private delete = (): void => {
    this.isDataDeleting = true;
    const plannedWork = this.getPlannedWorkFromForm(this.form);
    this.form.disable();
    this.plannerWriteService.deletePlannedTime(+plannedWork.id).subscribe(
      result => {
        if (result.mutationSucceededAllArguments) {
          this.closeLightBox.emit(false);
          this.deleteEvent.emit(+plannedWork.id);
        } else {
          this.messageService.insertDataFromMutation(result);
        }
      },
      err => {
        console.error(err);
      },
      () => {
        this.isDataDeleting = false;
        this.form.enable();
      }
    );
  };

  private getPlannedWorkFromForm = (form: FormGroup): UserPlannedWork => {
    return {
      breakDuration: this.BREAK_DURATION,
      endDate: this.plannerDataAdapterService.formatDateToString(
        new Date(form.controls['endDate'].value)
      ),
      id: form.controls['id'].value,
      messageToUser: form.controls['text'].value,
      onWeekend: this.ON_WEEKEND,
      color: form.controls['color'].value
        ? form.controls['color'].value
        : EventColorsEnum.Blue,
      projectId: form.controls['project'].value.value,
      repeat: form.controls['repeat'].value,
      repeatDays: this.getRepeatDays(form),
      repeatEndDate: this.getRepeatDate(form),
      startDate: this.plannerDataAdapterService.formatDateToString(
        new Date(form.controls['startDate'].value)
      ),
      todoId: form.controls['todo'].value
        ? form.controls['todo'].value.value
        : null,
      userId: form.controls['coworker'].value
        ? form.controls['coworker'].value.value
        : null,
    };
  };

  public updateTodosDropdown(): void {
    this.todosDropdown = [];
    const project = this.form.get('project').value;
    if (project === null) {
      return;
    }
    const projectTodos = this.allTodos.find(
      projectTodo => projectTodo.projectId === project.value
    );
    if (projectTodos !== undefined) {
      this.todosDropdown =
        this.plannerDataAdapterService.mapTypeToDropdownList<ProjectTodo>(
          projectTodos.todos.filter(
            todo => todo.done !== ProjectTodoStatus.Done
          ),
          MapTypeToDropdown.Todos
        );
      this.form.get('todo').enable();
    } else {
      this.form.get('todo').disable();
    }
  }

  private mapProjectDropdown = () => {
    if (this.projects.length > 0) {
      this.projectsDropdown = [];
      this.projectsDropdown =
        this.plannerDataAdapterService.mapTypeToDropdownList<Project>(
          this.projects,
          MapTypeToDropdown.Projects
        );
    }
  };

  private getRepeatDays = (form: any): string => {
    if (form.controls['repeat'].value !== RepeatEventEnum.Custom) {
      return '';
    }
    const days = form.controls['repeatDays'].value.sort(
      (a: string, b: string) => (a > b ? 1 : -1)
    );
    return days.join(',');
  };

  private getRepeatDate = (form: any): string => {
    if (form.controls['repeat'].value === RepeatEventEnum.NoRepeat) {
      return moment(form.controls['endDate'].value)
        .set('hour', 23)
        .set('minutes', 59)
        .format('YYYY-MM-DD HH:mm:ss');
    }
    return moment(form.controls['repeatEndDate'].value)
      .set('hour', 23)
      .set('minutes', 59)
      .format('YYYY-MM-DD HH:mm:ss');
  };

  private watchRepeatChanges = (): void => {
    const startDateController = this.form.get('startDate');
    const endDateController = this.form.get('endDate');
    const repeatController = this.form.get('repeat');
    const repeatDaysController = this.form.get('repeatDays');
    const repeatEndDateController = this.form.get('repeatEndDate');
    const projectController = this.form.get('project');
    repeatController.valueChanges.subscribe({
      next: value => {
        const repeatContainer = this.repeatDays.nativeElement as HTMLElement;
        repeatEndDateController.updateValueAndValidity();

        if (value === RepeatEventEnum.NoRepeat) {
          repeatContainer.classList.remove('open');
          repeatDaysController.setValue([], { emitEvent: false });
          repeatEndDateController.setValue(endDateController.value, {
            emitEvent: false,
          });
          repeatDaysController.disable();
          repeatEndDateController.clearValidators();
          repeatEndDateController.updateValueAndValidity();
        } else {
          repeatContainer.classList.add('open');
          if (value !== RepeatEventEnum.Custom) {
            switch (value) {
              case RepeatEventEnum.Daily:
                repeatDaysController.setValue(
                  this.repeatDaysOptions.map(option => +option.value),
                  { emitValue: false }
                );
                break;
              case RepeatEventEnum.Weekly:
                const weekday = moment(this.form.get('startDate').value).get(
                  'day'
                );
                const start = weekday === 0 ? 7 : weekday;
                const day = this.repeatDaysOptions.find(
                  d => +d.value === start
                );
                repeatDaysController.setValue([+day.value], {
                  emitValue: false,
                });
                break;
            }
            repeatDaysController.disable();
          } else {
            repeatDaysController.enable();
          }

          repeatEndDateController.setValidators(this.validRepeatDate);
          repeatEndDateController.updateValueAndValidity();
        }
      },
      error: undefined,
    });

    projectController.valueChanges.subscribe({
      next: () => repeatEndDateController.updateValueAndValidity(),
    });

    startDateController.valueChanges.subscribe({
      next: value => {
        if (value && repeatController.value === RepeatEventEnum.Weekly) {
          const weekday = moment(value).get('day');
          const start = weekday === 0 ? 7 : weekday;
          const day = this.repeatDaysOptions.find(d => +d.value === start);
          repeatDaysController.setValue([+day.value], {
            emitValue: false,
          });
        }
      },
    });
  };

  private validRepeatDate = (
    control: FormControl
  ): { [s: string]: boolean } => {
    if (control.value) {
      const date = new Date(control.value);
      const selectedProject = this.form.get('project').value;
      const endDate = new Date(this.form.get('endDate').value);

      if (selectedProject) {
        const project = this.projects.find(
          p => +p.id === +selectedProject.value
        );
        if (this.plannerDataAdapterService.isSystemTypeProject(project)) {
          this.validationTextList[1] = null;
          return null;
        }
        const projectStart = new Date(project.startDate);
        const projectEnd = new Date(project.endDate);
        if (
          date.getTime() > projectEnd.getTime() ||
          date.getTime() < projectStart.getTime() ||
          date.getTime() < endDate.getTime()
        ) {
          this.validationTextList[1] =
            'Slutdatum för upprepning måste vara efter slutdatum och inom projektets tidsperiod.';
          return { invalidRepeatDate: true };
        }
      }
    }
    this.validationTextList[1] = null;
    return null;
  };

  public isRedDay = (date: any): boolean => {
    const day = new Date(date.year, date.month, date.day);
    return day.getDay() === 0;
  };
}
