<div class="p-grid">
  <form class="p-col-12" [formGroup]="componentMainForm">
    <div class="p-col-12 p-g-nopad">
      <div
        class="p-col-12 p-md-4"
        [class.has-danger]="
          componentMainForm.controls['benamning']['showErrors']
        "
      >
        <div class="p-col-12">
          <label
            class="form-control-label"
            [innerHTML]="componentMainForm.controls['benamning']['label']"
          ></label>
          <p-autoComplete
            *ngIf="!reset"
            [disabled]="disabelProductAutoComplete"
            [suggestions]="results | async"
            (completeMethod)="searchProducts($event)"
            inputStyleClass="form-control"
            (keyup)="setProductBenamning()"
            (onBlur)="setProductBenamning()"
            field="benamning"
            (onSelect)="setProductFromAutosuggest($event)"
            [style]="{ width: '100%' }"
            [styleClass]="'wide-panel'"
            [inputStyle]="{ width: '100%' }"
            [(ngModel)]="productAutoModel"
            [ngModelOptions]="{ standalone: true }"
            [inputId]="benamningId"
            [tabindex]="projectId + '2'"
          >
            <ng-template let-product pTemplate="item">
              <div
                class="p-helper-clearfix"
                style="font-size: 12px"
                *ngIf="showProductPrices; else hidePrices"
              >
                <div class="autocomplete-row autocomplete-header-row">
                  <div style="width: 23%">Art. nr</div>
                  <div style="width: 47%">
                    Benämning, <span *ngIf="showStockColumn">Lagersaldo</span>
                  </div>
                  <div style="width: 15%">Förs.pris</div>
                  <div style="width: 15%">Inköpspris</div>
                  <div style="float: none" class="clear-both"></div>
                </div>

                <div class="autocomplete-row">
                  <div style="width: 23%">{{ product.artnr }}</div>
                  <div style="width: 47%">
                    {{ product.benamning }},
                    <span *ngIf="showStockColumn">{{
                      product.inventoryCount
                    }}</span>
                  </div>
                  <div style="width: 15%">{{ product.avtalspris }}</div>
                  <div style="width: 15%">{{ product.avtalsprisCost }}</div>
                  <div style="float: none" class="clear-both"></div>
                </div>
              </div>

              <ng-template #hidePrices>
                <div class="autocomplete-row autocomplete-header-row">
                  <div style="width: 23%">Art. nr</div>
                  <div style="width: 77%">
                    Benämning, <span *ngIf="showStockColumn">Lagersaldo</span>
                  </div>
                  <div style="float: none" class="clear-both"></div>
                </div>
                <div class="autocomplete-row">
                  <div style="width: 23%">{{ product.artnr }}</div>
                  <div style="width: 77%">
                    {{ product.benamning }},
                    <span *ngIf="showStockColumn">{{
                      product.inventoryCount
                    }}</span>
                  </div>
                  <div style="float: none" class="clear-both"></div>
                </div>
              </ng-template>
            </ng-template>
          </p-autoComplete>
          <div *ngIf="componentMainForm.controls['benamning']['showErrors']">
            <p
              *ngFor="
                let error of componentMainForm.controls['benamning'][
                  'mergedErrors'
                ]
              "
              class="form-control-feedback"
            >
              <span><i class="fa fa-times"></i> </span
              ><span [innerHTML]="error"></span>
            </p>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-md-2">
        <appForm2-simple-input
          formControlName="antal"
          (input)="calculate()"
        ></appForm2-simple-input>
      </div>

      <div class="p-col-12 p-md-1">
        <appForm2-simple-input
          formControlName="discount"
          (input)="calculate()"
        ></appForm2-simple-input>
      </div>

      <div class="p-col-12 p-md-2">
        <appForm2-simple-input
          formControlName="avtalspris"
          (input)="calculate()"
        ></appForm2-simple-input>
      </div>
      <div class="p-col-12 p-md-1">
        <appForm2-simple-checkbox
          formControlName="extra"
        ></appForm2-simple-checkbox>
      </div>

      <div class="p-col-2">
        <div class="p-col-12">
          <div class="form-control-label ng-star-inserted">Summa</div>

          {{ sum }}
        </div>
      </div>
    </div>

    <div class="p-col-12 p-g-nopad">
      <appForm2-list-checkbox
        class="p-col-6"
        [ngModel]="useOwnProducts"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="updateAutoSuggestObj(!!$event)"
        [htmlAttributes]="{ label: { value: 'Endast egna produkter' } }"
        [tooltip]="{
          msg: 'I systemet finns tusentals produkter inlagda. Vill du bara söka bland de produkter som ni i företaget själva har lagt in välj detta alternativ.'
        }"
      ></appForm2-list-checkbox>
      <div class="p-col-6">
        <app-button (click)="resetForm()" [type]="'cancel'"></app-button>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-col-12 p-lg-4"></div>
      <div class="p-col-12 p-lg-4">
        <app-button (click)="bookProducts()" [type]="'book'"></app-button>
      </div>
      <div class="p-col-12 p-lg-4"></div>
    </div>
  </form>

  <div class="p-col-12">
    <p-table [value]="bookedProducts" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 80px" *ngIf="showStockColumn">Lagersaldo</th>
          <th>Benänmning</th>
          <th style="width: 60px">Antal</th>
          <th style="width: 80px">Ta ut</th>
          <th style="width: 180px">Uttagna</th>
          <th style="width: 40px"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-bookedProduct>
        <tr
          *ngIf="
            !(bookedProduct['antalPickedOnload'] >= bookedProduct['antal']) ||
            showAllPicked
          "
        >
          <td
            *ngIf="showStockColumn"
            [style.color]="bookedProduct['latestInventory'] < 1 ? '#f00' : ''"
          >
            {{ bookedProduct['latestInventory'] }}
          </td>
          <td>{{ bookedProduct['benamning'] }}</td>
          <td>{{ bookedProduct['antal'] }}</td>
          <td>
            <appForm2-list-input
              [ngModel]="bookedProduct['antalPicked']"
              [htmlAttributes]="{ label: { value: '0' } }"
              (ngModelChange)="setAmountToPick(i, $event)"
            ></appForm2-list-input>
          </td>
          <td>
            <span *ngFor="let picked of bookedProduct['picks']">
              {{ picked['amount'] }}st, {{ picked['created'] }} <br />
            </span>
          </td>
          <td>
            <i
              (click)="deleteBookedProduct(bookedProduct['id'])"
              class="fa fa-lg fa-trash-o"
            ></i>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <app-button
          *ngIf="componentMainForm"
          [style.width.px]="75"
          (onClick)="updateBookedProduct()"
          [type]="'pick'"
          [disabled]="picking | async"
        ></app-button>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-12">
    <span class="pull-right link" (click)="showAllPicked = !showAllPicked"
      >Visa alla plockade</span
    >
  </div>
  <div class="p-col-12">
    <span
      *ngFor="let dNote of deliverNotes"
      style="cursor: pointer; font-size: 14px"
    >
      <a (click)="openDeliveryNote(dNote['id'])"
        >Följesedel {{ dNote['trueId'] + ', ' + dNote['created'] }}</a
      ><br />
    </span>
  </div>
</div>
