import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { HelperService } from 'app/shared/helpers';

@Component({
  selector: 'project-handle-comment',
  templateUrl: 'project-handle-comment.component.html',
  providers: [FormHandlerService],
})
export class ProjectHandleCommentComponent implements OnInit {
  @Input() dataObjectFromParent;
  @Input() dataModelCapitalized;

  dataModel = 'comment';
  buttons = {
    update: {
      label: 'Uppdatera',
      disabled: false,
    },
    delete: {
      label: 'Ta bort',
      disabled: false,
    },
  };
  formFields = {};
  componentMainForm: FormGroup;

  constructor(
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private confirmationService: ConfirmationService,
    public helperService: HelperService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.formFields['model'] = this.dataModelCapitalized;
    this.formFields['attributes'] = {
      comment: this.dataObjectFromParent.comment,
    };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['comment']['label'] =
          'Skapad ' +
          this.helperService.getFormatedDateTime(
            this.dataObjectFromParent['created']
          ) +
          ' av ' +
          this.dataObjectFromParent['user']['firstName'] +
          ' ' +
          this.dataObjectFromParent['user']['lastName'];
      });
  }

  actionUpdate() {
    const crudType = 'update';
    const dataToMutation = {
      id: Number(this.dataObjectFromParent.id),
      comment: this.componentMainForm.controls['comment'].value,
    };
    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      const returnAttributes = ['id', 'comment'];

      const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
      const refetchArr = [{ name: 'projectComments', variables: refetchVars }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          returnAttributes,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  confirmActionDelete() {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort kommentaren?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteComment();
        this.buttons = this.formHandler.lockButtons(this.buttons);
      },
      reject: () => {},
    });
  }

  private deleteComment() {
    const crudType = 'delete';
    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };
    const refetchVars = { id: +this.dataObjectFromParent['projectId'] };
    const refetchArr = [
      { name: 'projectComments', variables: refetchVars },
      { name: 'singleProjectTotalCounts', variables: refetchVars },
    ];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            this.componentMainForm
          );

          this.mutationService.displayMutationStatus(data);

          this.buttons = this.formHandler.unlockButtons(this.buttons, true);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.buttons = this.formHandler.unlockButtons(this.buttons, true);
        }
      );
  }
}
