import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact } from 'generated/types';
import { first } from 'rxjs';
import {
  ContactFragment,
  ContactsGQL,
  GetContactGQL,
} from './graphql/contact.generated';

interface ContactWithLabel extends Contact {
  label: string;
}

@Component({
  selector: 'app-contact-select',
  templateUrl: './contact-select.component.html',
  styleUrls: ['./contact-select.component.scss'],
})
export class ContactSelectComponent {
  @Output() public selectedContactEvent = new EventEmitter<number>();
  @Input() set selectedContact(contact: Contact) {
    const labelArray = [];

    if (contact.trueId && Number(contact.trueId) > 0) {
      labelArray.push(contact.trueId);
    }
    if (contact.name) {
      labelArray.push(contact.name);
    }
    if (contact.orderBuisnessName) {
      labelArray.push(contact.orderBuisnessName);
    }
    if (contact.address) {
      labelArray.push(contact.address);
    }
    if (contact.cityCode) {
      labelArray.push(contact.cityCode);
    }
    if (contact.city) {
      labelArray.push(contact.city);
    }

    const label = labelArray.join(' - ');
    this.selectedContactWithLabel = { label: label, ...contact };
  }
  @Input() public disabled = false;
  @Input() public createButton = true;

  public selectedContactWithLabel: ContactWithLabel;

  constructor(
    private contactsGQL: ContactsGQL,
    private getContactGQL: GetContactGQL
  ) {}

  public contacts: ContactFragment[] = [];

  public select(value: ContactFragment): void {
    const id = Number(value.id);
    this.selectedContact = value;
    this.selectedContactEvent.emit(id);
  }

  public focus(autocomplete: any): void {
    if (autocomplete.inputValue?.length >= autocomplete.minLength) {
      autocomplete.show();
    }
  }

  public search(event: { originalEvent: InputEvent; query: string }): void {
    this.fetchContacts(event.query);
  }

  private fetchContacts(query: string): void {
    this.contactsGQL
      .watch({
        search: query,
      })
      .valueChanges.pipe(first())
      .subscribe(response => {
        this.contacts = response.data.company.contactSearch.edges.map(
          ({ node }) => ({
            id: node.id,
            trueId: node.trueId,
            name: node.name,
            orderBuisnessName: node.orderBuisnessName,
            address: node.address,
            city: node.city,
            cityCode: node.cityCode,
          })
        );
      });
  }
}
