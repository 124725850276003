<div class="p-grid">
  <div class="p-col-12">
    <form [formGroup]="componentMainForm">
      <div class="p-grid">
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input
            formControlName="name"
            [tooltip]="{ msg: toolTipMsg, tooltipPosition: 'top' }"
          ></appForm2-wide-input>
        </div>
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input formControlName="email"></appForm2-wide-input>
        </div>
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-input formControlName="orgNr"></appForm2-wide-input>
        </div>
      </div>
    </form>
    <div class="p-col-12 align-right">
      <app-button
        (onClick)="actionCreate()"
        [type]="'create'"
        [model]="'Subcontractor'"
      ></app-button>
    </div>
  </div>
</div>
