import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { ReusableForm2Module } from 'app/shared/forms/reUseableForm2/reusable-form-2.module';

import { ProjectExternalDropdownComponent } from './project-external-dropdown/project-external-dropdown.component';

@NgModule({
  imports: [
    DropdownModule,
    CommonModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    ReusableForm2Module,
  ],
  exports: [ProjectExternalDropdownComponent],
  declarations: [ProjectExternalDropdownComponent],
})
export class ProjectExternalModule {}
