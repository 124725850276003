<app-header> </app-header>

<div class="page-container">
  <div class="p-grid p-g-nopad">
    <div class="p-col-12 p-g-nopad">
      <div class="p-grid">
        <div class="p-col-12 p-lg-4 p-g-nopad">
          <div class="p-col-12">
            <div [class]="isExport ? 'p-col-12 fake-card' : ''">
              <div class="column-title">
                <h4>
                  Välj projekt och period
                  <small>valfritt</small>
                </h4>
              </div>
              <div class="p-grid">
                <div class="p-col-12">
                  <appForm2-wide-dropDown
                    [(ngModel)]="controlObject.project"
                    (ngModelChange)="projectChangeHandle()"
                    [htmlAttributes]="{ label: { value: 'Projekt' } }"
                    [dropDownParams]="{
                      options: projectsDropdown,
                      filter: true
                    }"
                  ></appForm2-wide-dropDown>
                </div>
                <div class="p-col-12">
                  <appForm2-wide-calendar
                    [(ngModel)]="controlObject.from"
                    [htmlAttributes]="{ label: { value: 'Fr.o.m.' } }"
                  ></appForm2-wide-calendar>
                </div>
                <div class="p-col-12">
                  <appForm2-wide-calendar
                    [(ngModel)]="controlObject.to"
                    [htmlAttributes]="{ label: { value: 'T.o.m.' } }"
                  ></appForm2-wide-calendar>
                </div>
                <ng-container *ngIf="isExport">
                  <div class="p-col-12">
                    <appForm2-wide-dropDown
                      [(ngModel)]="controlObject.orgNr"
                      [ngModelOptions]="{ standalone: true }"
                      [htmlAttributes]="{ label: { value: 'Företag' } }"
                      [dropDownParams]="{
                        options: subcontractorsDropdown,
                        filter: true
                      }"
                    ></appForm2-wide-dropDown>
                  </div>
                  <div class="p-col-12 align-left">
                    <app-button
                      [disabled]="loading | async"
                      (onClick)="getExportFile()"
                      [type]="'getExcel'"
                    ></app-button>
                  </div>
                </ng-container>

                <div class="p-grid">
                  <div class="p-col-12 align-center">
                    <span
                      class="link"
                      [class.disabled]="!controlObject.project"
                      (click)="handleExportToggleClick()"
                    >
                      <span *ngIf="isExport">Dölj</span>
                      Skatteverket export
                    </span>
                    <span
                      *ngIf="!controlObject.project"
                      class="help-tooltip"
                      tooltipPosition="bottom"
                      pTooltip="Välj projekt för skatteverket export"
                      [escape]="false"
                      >?</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-lg-4 p-g-nopad">
          <div class="p-col-12">
            <div class="column-title">
              <h4>
                Status
                <small>valfritt</small>
              </h4>
            </div>
            <div class="column-body">
              <div class="p-col-12">
                <p-radioButton
                  name="startOrStopTime"
                  value="notClosed"
                  label="Instämplade just nu"
                  [(ngModel)]="controlObject.startOrStopTime"
                ></p-radioButton>
              </div>
              <div class="p-col-12">
                <p-radioButton
                  name="startOrStopTime"
                  value="closed"
                  label="Historik"
                  [(ngModel)]="controlObject.startOrStopTime"
                ></p-radioButton>
              </div>
              <div class="p-col-12">
                <p-radioButton
                  name="startOrStopTime"
                  value="notClosedAndClosed"
                  label="Både Instämplade just nu och historik"
                  [(ngModel)]="controlObject.startOrStopTime"
                ></p-radioButton>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12 p-lg-4 p-g-nopad">
          <div class="p-col-12">
            <div class="column-title">
              <h4>
                Välj medarbetare
                <small>valfritt</small>
              </h4>
            </div>
            <div class="column-body user-ids">
              <div class="p-col-12">
                <p-multiSelect
                  [style]="{ width: '100%' }"
                  [options]="usersDropdown"
                  [(ngModel)]="controlObject.userIds"
                  defaultLabel="Välj person(er)"
                  selectedItemsLabel="{0} valda personer"
                  [overlayOptions]="{ appendTo: 'body' }"
                >
                </p-multiSelect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-col-12 align-right">
      <app-button
        (onClick)="getAttendanceReports()"
        [type]="'show'"
        overrideButtonType="primary"
        [model]="'AttendanceReport'"
        [disabled]="loading | async"
      ></app-button>
    </div>

    <spinner *ngIf="loading | async"></spinner>

    <div class="p-col-12" *ngIf="projectsData.length > 0">
      <div class="p-grid">
        <div class="p-col-12" *ngFor="let project of projectsData">
          <h4>
            {{ project['label'] }} - {{ project['constructionSiteNumber'] }}
          </h4>
          <attendance-reports-list
            [projectData]="project"
            [inCollectionList]="true"
          ></attendance-reports-list>
        </div>
      </div>
    </div>
  </div>
</div>
