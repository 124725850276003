import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Observable, ReplaySubject, combineLatest } from 'rxjs';
import { ApolloQueryService } from '../apollo';
import { map } from 'rxjs/operators';

export interface PageInfoKey {
  __titleKey: true;
}

export interface PageInfo {
  title?: { value: string; skipSuffix?: boolean };
  meta?: { [name: string]: string };
}

interface PageInfoEntry {
  info: PageInfo;
  key: PageInfoKey;
}

/*
 * Maintains a stack of per-page metadata, such as the title and meta-description tags
 *
 * You probably want to use MetaComponent (app-meta), TitleComponent (app-title), or GuestTitleComponent (app-guest-title)
 * instead of calling this directly, since they automatically handle destruction and key reuse.
 *
 * Usage (for changing the title):
 * 1. When initializing the page:
 *      this.pageInfoKey = setInfo({title: {value: "My fancy title"}})
 *    This creates a new entry on the stack.
 * 2. When updating the title:
 *      this.pageInfoKey = setInfo({title: {value: "That title wasn't fancy enough"}}, this.pageInfoKey)
 *    This updates the existing entry. This also won't override newer entries on the stack (until they're removed).
 * 3. When destroying the page:
 *      clearInfo(this.pageInfoKey)
 *    This removes the entry from the stack, reverting to the previous value.
 *
 * It is fine to update or clear an entry that is not at the top of the stack.
 */
@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  private itemStack: PageInfoEntry[] = [];
  private topItem = new ReplaySubject<PageInfo>(1);
  private appliedMetaTags: { [name: string]: HTMLMetaElement } = {};

  constructor(
    private titleManager: Title,
    private metaManager: Meta,
    private apolloQueryService: ApolloQueryService
  ) {
    this.importStaticInfo();

    const appParams = apolloQueryService
      .apolloWatchQueryTwo('companyAppParams', {}, 'cache-and-network')
      .pipe(map<any, any>(({ data }) => data.appParams));

    combineLatest(appParams, this.topItem)
      .pipe(map(([params, info]) => this.updateTitle(params, info)))
      .subscribe(info => {
        this.updateMetaTags(info);
      });
  }

  private importStaticInfo() {
    const meta = {};
    for (const metaTag of this.metaManager.getTags('name')) {
      this.appliedMetaTags[metaTag.name] = metaTag;
      meta[metaTag.name] = metaTag.content;
    }
    this.setInfo({
      title: {
        value: this.titleManager.getTitle(),
        skipSuffix: true,
      },
      meta,
    });
  }

  private updateTitle(appParams: any, info: PageInfo): PageInfo {
    let fullTitle = info.title.value;
    if (!info.title.skipSuffix) {
      fullTitle += ' | ' + (appParams as any).appURLCostumerText;
    }
    this.titleManager.setTitle(fullTitle);
    return {
      ...info,
      meta: {
        ...info.meta,
        'title': fullTitle,
        'og:title': fullTitle,
      },
    };
  }

  private updateMetaTags({ meta }: PageInfo) {
    const oldMetaTags = { ...this.appliedMetaTags };
    const currentMetaTags = {};
    for (const metaKey in meta) {
      let tag = oldMetaTags[metaKey];
      delete oldMetaTags[metaKey];
      if (tag === undefined) {
        tag = this.metaManager.addTag({
          name: metaKey,
        });
      }
      currentMetaTags[metaKey] = tag;
      tag.content = meta[metaKey];
    }
    for (const metaKey in oldMetaTags) {
      this.metaManager.removeTagElement(oldMetaTags[metaKey]);
    }
    this.appliedMetaTags = currentMetaTags;
  }

  public setInfo(info: PageInfo, key?: PageInfoKey): PageInfoKey {
    if (key === undefined) {
      key = { __titleKey: true };
      this.itemStack.push({
        info,
        key,
      });
    } else {
      const { entry } = this.getEntry(key);
      if (entry !== undefined) {
        entry.info = info;
      } else {
        throw new Error(
          'Tried to change a PageInfo record that has already been cleared'
        );
      }
    }
    this.updateInfo();
    return key;
  }

  public clearInfo(key: PageInfoKey): void {
    const { index, entry } = this.getEntry(key);
    if (entry !== undefined) {
      this.itemStack.splice(index, 1);
      this.updateInfo();
    }
  }

  private updateInfo(): void {
    let topTitle;
    const mergedMeta = {};

    for (const entry of this.itemStack) {
      const { title, meta } = entry.info;
      if (title !== undefined) {
        topTitle = title;
      }
      if (meta !== undefined) {
        for (const metaKey in meta) {
          const value = meta[metaKey];
          if (value !== undefined) {
            mergedMeta[metaKey] = meta[metaKey];
          }
        }
      }
    }
    this.topItem.next({
      title: topTitle,
      meta: mergedMeta,
    });
  }

  private getEntry(key: PageInfoKey): { index: number; entry: PageInfoEntry } {
    for (let i = 0; i < this.itemStack.length; i++) {
      const entry = this.itemStack[i];
      if (entry.key === key) {
        return { index: i, entry };
      }
    }
    return { index: -1, entry: undefined };
  }
}
