import { Injectable } from '@angular/core';
import { CompanySuperService } from '../../company.super.service';

@Injectable()
export class ProjectBookmarksService extends CompanySuperService {
  makeObjectsForForms(data) {
    const dataArray = data['me']['ProjectBookmarks']['edges'];
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const projectInfo =
        dataArray[i]['node']['Project']['trueId'] +
        ', ' +
        dataArray[i]['node']['Project']['mark'];

      const dataObject = {};
      for (const key in dataArray[i]['node']['Project']) {
        dataObject[key] = dataArray[i]['node']['Project'][key];
      }

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }

      dataObject['projectInfo'] = projectInfo;
      dataObject['projectBookmarks'] = this.cleanFromNode(
        dataObject['projectBookmarks']
      );
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }
}
