import { Config } from '../config';
import { config as defaultConfig } from './default.config';

export const config: Config = {
  ...defaultConfig,
  name: 'wabygg',
  subdomain: ['wabygg'],
  title: 'W&Å Bygg',
  theme: {
    ...defaultConfig.theme,
    logoPath: '/images/site/storyBo.jpg',
    loginBackground: '/images/hyperion/waBg.jpg',
    brandPrimaryColor: '#da212d',
    brandSecondaryColor: '#dfdfdf',
    topMenuBackgroundColor: '#d62429',
    buttonPrimaryColor: '#da212d',
    linkColor: '#da212d',
  },
};
