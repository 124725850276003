<div class="container">
  <div *ngIf="!hasShared" class="p-grid p-g-nopad">
    <div class="p-col-6">
      <h2>Att dela ett projekt till en samarbetspartner</h2>
      <p>
        Om ni delar ett projekt med en samarbetspartner så får denna möjlighet
        att kopiera projektet till sitt egna Struqturkonto. Adressuppgifter samt
        referens i ert egna projekt läggs till i er samarbetspartners projekt.
      </p>
      <ul style="list-style: disc">
        <li style="margin-left: 10px">
          Grundläggande kontaktuppgifter exporteras.
        </li>
        <li style="margin-left: 10px">
          Ni kan skicka arbetsmoment till de samarbetspartners ni har delat
          projektet med.
        </li>
        <li style="margin-left: 10px">
          Om samarbetspartnern accepterar delningen så får ni tillgång till
          deras tidrapporter i projektet.
        </li>
        <li style="margin-left: 10px">
          Mer inställningar hittar ni under "Delning projekt" i projektmenyn.
        </li>
      </ul>
    </div>
    <div class="p-col-6">
      <h2>Att få ett projekt delat från en samarbetspartner</h2>
      <p>
        Om en samarbetspartnerdelar ett projekt till er är det ni som bestämmer
        om ni vill kopiera in det till era egna projekt i systemet. Ni bestämmer
        även vilken data ni ska dela med er samarbetspartner.
      </p>
      <ul style="list-style: disc">
        <li style="margin-left: 10px">
          När ni väljer att kopiera projektet skapas ett projekt med
          grundläggande uppgifter från er samarbetspartners projekt
          (adressuppgifter samt referens).
        </li>
        <li style="margin-left: 10px">
          Om ni accepterar kan er samarbetspartner skicka arbetsmoment till er.
        </li>
        <li style="margin-left: 10px">
          Om ni accepterar visas fakturerbara timmar från tidrapporter i
          projektet för er samarbetspartner.
        </li>
        <li style="margin-left: 10px">
          Ni kan alltid ändra delningsinställnignar under "Delning projekt" i
          projektmenyn
        </li>
      </ul>
    </div>

    <div class="p-col-12">
      <p>
        När man delar ett projekt får samarbetspartnern möjlighet att kopiera
        det delade projektet. Detta innebär att det skapas ett helt nytt projekt
        i samarbetspartnerns konto med ett eget projekt-ID och som administreras
        separat på samarbetspartnerns konto. Det är sedan den data som läggs in
        i det kopierade projektet som delas till ert projekt.
      </p>
    </div>
  </div>

  <div class="p-grid" *ngIf="hasShared">
    <div class="p-col-12 p-md-12">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <input
          type="text"
          pInputText
          [disabled]="loading"
          (input)="
            customerSupplierTable.filterGlobal($event.target.value, 'contains')
          "
          placeholder="Sök rapporter"
        />
      </div>
    </div>

    <div *ngIf="!hasSharedAndConnectionsAccepted">
      <p>Projektet är delat men ingen har accepterat</p>
    </div>

    <div *ngFor="let data of dataSet; let i = index">
      <div class="clear-both"></div>
      <h2>
        {{ data['company']['name'] }}
        <small>Tidrapporter {{ data['trueId'] }}, {{ data['mark'] }} </small>
      </h2>
      <div class="p-col-12 p-g-nopad">
        <spinner *ngIf="loading"></spinner>
        <div class="p-col-12" [style.display]="loading ? 'none' : 'block'">
          <p-table
            #customerSupplierTable
            [columns]="cols"
            [rowHover]="true"
            [value]="data['days']"
            [sortField]="sort.attribute"
            [sortOrder]="sort.ascDesc"
            (onSort)="changeSort($event)"
            paginatorPosition="both"
            [rowsPerPageOptions]="[50, 100, 250, 500]"
            [rows]="50"
            [paginator]="data['days'].length > 50"
          >
            <ng-template pTemplate="header" let-columns>
              <tr>
                <th
                  *ngFor="let col of columns"
                  [pSortableColumn]="col.field"
                  [style.width]="col.width"
                >
                  {{ col.header }}
                  <p-sortIcon
                    [field]="col.field"
                    *ngIf="col.field !== 'markAsSaved'"
                  ></p-sortIcon>
                </th>
              </tr>
            </ng-template>

            <ng-template
              pTemplate="body"
              let-rowData
              let-columns="columns"
              let-rowIndex="rowIndex"
            >
              <tr
                app-project-child-row
                [parentId]="+data['parentId']"
                [data]="rowData"
                [dropDownOptions]="dropDownOptions"
                [tableBtns]="tableBtns"
              ></tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="p-col-12 p-g-nopad">
    <app-share-project-company
      *ngIf="projectInfo['id'] && !hasSharedAndConnectionsAccepted"
      [projectInfo]="projectInfo"
    ></app-share-project-company>
  </div>
</div>
