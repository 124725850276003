/*
 *
 * Values here should not be changed without considation with Mårten fisrt
 *
 */
export enum RowType {
  DaysExtra = 0,
  Days = 1,
  Products,
  Installaments,
  StandAloneRow,
  Offer,
  OfferFromProject,
  SubsistenceNight,
  SubsistenceDay,
  SubsistenceDayHalf,
  ATA,
  ProductsExtra,
}
