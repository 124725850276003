import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { Observable, map, first, BehaviorSubject } from 'rxjs';
import {
  FetchProjectGQL,
  FetchProjectQuery,
} from '../project-info/graphql/project.generated';

@Component({
  selector: 'app-project-ata',
  templateUrl: './project-ata.component.html',
  styleUrls: ['./project-ata.component.scss'],
})
export class ProjectAtaComponent implements OnInit {
  public projectId: number;
  public projectData: Observable<FetchProjectQuery['project']>;
  private projectDataSub = new BehaviorSubject<FetchProjectQuery['project']>({
    id: null,
  });

  constructor(
    private route: ActivatedRoute,
    private fetchProject: FetchProjectGQL,
    private projectCountService: ProjectCountService
  ) {}

  public ngOnInit(): void {
    this.projectData = this.projectDataSub.asObservable();
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;
      this.projectDataSub.next({ id: this.projectId.toString() });
      this.setProjectData();
    });
  }

  public setProjectData(): void {
    this.fetchProject
      .fetch({ id: this.projectId })
      .pipe(
        first(),
        map(result => result.data.project)
      )
      .subscribe(data => {
        this.projectDataSub.next(data);
        this.projectCountService.count(this.projectId);
      });
  }
}
