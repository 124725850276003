import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNum' })
export class FormatNumberPipe implements PipeTransform {
  transform(
    value: number,
    decimals = 2,
    decPoint = '.',
    separator = ' '
  ): string {
    if (!isNaN(value)) {
      const s = (
        decimals ? this.toFixedFix(value, decimals) : Math.round(value)
      )
        .toString()
        .split(decPoint);
      if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, separator);
      }
      if ((s[1] || '').length < decimals) {
        s[1] = s[1] || '';
        s[1] += new Array(decimals - s[1].length + 1).join('0');
      }
      return s.join(decPoint);
    } else {
      return '0';
    }
  }

  private toFixedFix(n, prec) {
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    const k = Math.pow(10, prec);
    return Math.round(n * k) / k;
  }
}
