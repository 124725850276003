import {
  Component,
  forwardRef,
  Injector,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-list-texteditor',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListFormTextEditor2Component),
      multi: true,
    },
  ],
  template: `
    <div class="p-col-12 p-g-nopad" #container style="position:relative">
      <p-editor
        [(ngModel)]="value"
        [readonly]="disabled"
        (blur)="onTouched()"
        [style]="{ height: '180px' }"
        (ngModelChange)="pushChanges($event)"
        [class.form-control-danger]="control.showErrors || ''"
        [attr.id]="htmlAttributes['input']['id']"
        [attr.form-control-name]="control['name']"
        [placeholder]="htmlAttributes['label']['value'] || control.label"
      >
        <p-header>
          <span class="ql-formats">
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-underline" aria-label="Underline"></button>
            <button class="ql-italic" aria-label="Underline"></button>
          </span>
          <span class="ql-formats">
            <select
              class="ql-color ql-picker ql-color-picker"
              aria-label="Color"
            ></select>
            <select
              class="ql-background ql-picker ql-color-picker"
              aria-label="Background"
            ></select>
          </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered" aria-label="List"></button>
            <button class="ql-list" value="bullet" aria-label="List"></button>
            <select class="ql-align" aria-label="Align"></select>
          </span>
          <span class="ql-formats">
            <select class="ql-header" aria-label="Header"></select>
            <button class="ql-link" aria-label="Insert link"></button>
          </span>
        </p-header>
      </p-editor>
      <div *ngIf="control.showErrors" class="vendium-tool-tip">
        <p
          *ngFor="let error of control['mergedErrors']"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class ListFormTextEditor2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  value: any;
  onChange: (value: any) => void;
  onTouched: () => void;
  disabled: boolean;
  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    this.fixFocusScrollIssue();
    super.ngOnInit();

    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  fixFocusScrollIssue() {
    // MANI 2019-06-14
    // https://github.com/zenoamaro/react-quill/issues/317
    this.disabled = true;
    setTimeout(() => {
      this.disabled = false;
    }, 300);
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? value : null;
  }

  pushChanges(value: any) {
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
