import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CompanyFunctionsService } from 'app/shared/company/index';

import { HelperService } from '../../shared/helpers/index';
import { ApolloQueryService } from '../../shared/apollo/index';

@Component({
  selector: 'project-share',
  templateUrl: 'project-share.component.html',
})
export class ProjectShareComponent implements OnInit, OnDestroy {
  @Input() projectInfo;

  dataSet = [];
  totalCount: number;
  relationshipsLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  shareProjectPartner = false;
  activeLink = 'customer';
  projectCustomerRelationshipsSub: Subscription;

  constructor(
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private companyFunctionsService: CompanyFunctionsService
  ) {}

  ngOnInit() {
    this.getCustomerRelationships();

    this.companyFunctionsService
      .hasCompanyFunction('shareProjectPartner')
      .subscribe(x => {
        this.shareProjectPartner = x;
      });
  }

  ngOnDestroy() {
    this.projectCustomerRelationshipsSub &&
      this.projectCustomerRelationshipsSub.unsubscribe();
  }

  getCustomerRelationships() {
    const variables = {
      id: +this.projectInfo['id'],
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectCustomerRelationships', variables)
      .subscribe(data => {
        this.projectCustomerRelationshipsSub = data.sub;
        this.totalCount =
          data.data['company']['projects']['edges'][0]['node'][
            'costumerRelationsToProject'
          ]['totalCount'];
        this.dataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']['edges'][0]['node'][
            'costumerRelationsToProject'
          ]
        );
        this.relationshipsLoading.next(false);
      });
  }
}
