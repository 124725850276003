import { Component, Input } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: 'spinner.component.html',
})
export class Spinner {
  @Input() inComponent = false;

  constructor() {}
}
