<app-feature-preview
  image="/images/hyperion/feature-previews/faktura.jpg"
  feature="Faktura"
>
  <p>
    Med hjälp av {{ appName }} kan du med några få knapptryck skapa snygga och
    proffsiga kundfakturor. Detta är otroligt enkelt eftersom allt underlag har
    sparats under projektets gång.
  </p>

  <p>
    Du kan själv välja ifall du vill fakturera ett projekt löpande eller till
    ett fast pris. Systemet håller självklart koll på vilka kostnader som redan
    fakturerats vidare så att du slipper hålla koll på det själv! Utöver detta
    kan du självklart bifoga snygga fakturaunderlag som innehåller både tid- och
    materialrapporter i detalj.
  </p>
</app-feature-preview>
