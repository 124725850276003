import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

import { ConfirmationService } from 'primeng/api';
import { ApolloMutationService } from 'app/shared/apollo';
import { AttendanceReportService } from 'app/attendance-report/services/attendance-report.service';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'subcontractor-drag',
  styleUrls: ['./subcontractor-drag.component.scss'],
  templateUrl: './subcontractor-drag.component.html',
})
export class SubcontractorDragComponent implements OnInit, OnChanges {
  @Input() projectId;
  @Input() parentData = {};
  @Input() subcontractorData: any = {};
  @Output() subcontractorDeleted = new EventEmitter();
  @Output() refetchAttendanceReports = new EventEmitter();
  @Output() subcontractorWithChildren = new EventEmitter();
  @ViewChildren(SubcontractorDragComponent)
  private dragComponents: QueryList<SubcontractorDragComponent>;

  children = [];
  showAttendanceReports = false;
  attendanceReportsInfo = { projectId: null, orgNr: null };
  deleteSubcontractorInProgress = new BehaviorSubject(false);

  constructor(
    private confirmationService: ConfirmationService,
    private mutationService: ApolloMutationService,
    private attendanceReportService: AttendanceReportService
  ) {}

  ngOnInit() {
    this.setAttendanceReportsInfo();
    if (this.subcontractorData['opened']) {
      this.showAttendanceReports = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    changes['subcontractorData'] && this.setChildren();
  }

  setChildren() {
    if (this.subcontractorData.hasOwnProperty('children')) {
      if ((this.subcontractorData['children'] as any[]).length > 0) {
        this.children = this.subcontractorData['children'];
      }
    }
  }

  setChildrenFromParent(children: any[]) {
    this.children = children;
    this.setChildrenInSubcontractorDrag();
  }

  setChildrenInSubcontractorDrag() {
    this.dragComponents.forEach((component, index) => {
      if (this.children[index].hasOwnProperty('children')) {
        component.setChildrenFromParent(this.children[index].children);
      }
    });
  }

  deleteRelationProjectSubcontractor() {
    this.deleteSubcontractorInProgress.next(true);
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort företaget från projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteRelationProjectSubcontractorMutation();
      },
      reject: () => {
        this.deleteSubcontractorInProgress.next(false);
      },
    });
  }

  deleteRelationProjectSubcontractorMutation() {
    const dataToMutation = { id: Number(this.subcontractorData.id) };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'subcontractorRelationToProject',
        'delete',
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.subcontractorDeleted.emit(this.subcontractorData.id);
          }

          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
          this.deleteSubcontractorInProgress.next(false);
        },
        err => {
          console.log(err);
          this.deleteSubcontractorInProgress.next(false);
        }
      );
  }

  toggleShowAttendanceReports() {
    this.showAttendanceReports = !this.showAttendanceReports;
    this.subcontractorData['opened'] = this.showAttendanceReports;
  }

  setAttendanceReportsInfo() {
    const subcontractor = this.subcontractorData['subcontractor'];
    const attendanceReportsInfo = { ...this.attendanceReportsInfo };
    attendanceReportsInfo.projectId = Number(this.projectId);
    attendanceReportsInfo.orgNr = subcontractor['orgNr'];

    this.attendanceReportsInfo = attendanceReportsInfo;
  }

  emitSubcontractorWithChildren() {
    const subcontractor = this.subcontractorData;
    subcontractor['children'] = this.children;
    this.subcontractorWithChildren.emit(subcontractor);
  }
}
