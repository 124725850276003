import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CompanyPicturesQueryVariables = Types.Exact<{
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type CompanyPicturesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, pictures?: { __typename?: 'PictureCommentConnection', totalCount: number, edges?: Array<{ __typename?: 'PictureCommentEdge', node?: { __typename?: 'PictureComment', id: string, width?: number | null, height?: number | null, comment?: string | null, created?: string | null, displayOnPrint?: number | null, thumbnail?: string | null, largerImage?: string | null, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null, project?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null } | null } | null } | null> | null } | null } | null };

export const CompanyPicturesDocument = gql`
    query companyPictures($offset: Int, $count: Int) {
  company {
    id
    pictures(offset: $offset, last: $count) {
      totalCount
      edges {
        node {
          id
          thumbnail: imageUrl(width: 300, height: 300)
          largerImage: imageUrl(width: 2000, height: 2000)
          width
          height
          comment
          user {
            id
            firstName
            lastName
          }
          created
          displayOnPrint
          project {
            id
            trueId
            mark
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanyPicturesGQL extends Apollo.Query<CompanyPicturesQuery, CompanyPicturesQueryVariables> {
    document = CompanyPicturesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }