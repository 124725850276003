<form [formGroup]="form" *appLoaded="form" #formBody>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="form-control-label">Pris</div>
      <div class="form-control">{{ (pricing | async).yearly }}kr/år</div>
    </div>

    <div class="p-col-12 p-g-nopad">
      <appForm2-simple-input formControlName="address"></appForm2-simple-input>

      <appForm2-simple-input formControlName="city"></appForm2-simple-input>

      <appForm2-simple-input formControlName="cityCode"></appForm2-simple-input>
    </div>

    <div class="p-col-12 align-right">
      <app-button (onClick)="closeDialog()" type="cancel"></app-button>
      <app-button
        *ngIf="!saving"
        class="ml5"
        [disabled]="saving"
        overrideButtonType="primary"
        (onClick)="subscribe()"
        [plainText]="{ label: 'Börja Prenumerera' }"
      ></app-button>
      <app-button
        *ngIf="saving"
        class="ml5"
        [disabled]="saving"
        overrideButtonType="primary"
        (onClick)="subscribe()"
        [plainText]="{ label: 'Sparar...' }"
      ></app-button>
    </div>
  </div>
</form>
