import { Apollo } from 'apollo-angular';
import { DataProxy } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import PROJECT_STATUS from 'app/shared/global/project-status.enum';
import {
  rawQueries as queries,
  GQLFragment,
} from 'app/shared/apollo/queries/index';

import { HelperService } from 'app/shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class ProjectIndexService {
  constructor(private helperService: HelperService, private apollo: Apollo) {}

  /**
   * Retrieves a MutationQuery that updates a status of a project
   */
  public getMutationQuery(
    id: number,
    oldStatus: PROJECT_STATUS,
    newStatus: PROJECT_STATUS
  ): Observable<any> {
    const model = 'project';
    const action = 'update';
    const queryName = 'companyProjects';

    return this.prepareProjectStatusMutation({
      model,
      action,
      queryName,
      queryBody: queries[queryName].body,
      oldData: {
        id,
        status: oldStatus,
      },
      newData: {
        id,
        status: newStatus,
      },
    }).pipe(
      map(({ data }) => {
        if (
          data.projectTypeHyperionMutation.mutationDetails[0].mutationSucceeded
        ) {
          return data;
        }
        throw data;
      }),
      catchError(error => of({ error: error }))
    );
  }

  /**
   * Appends the project type labels if they exist, otherwise return the old array
   *
   * @returns the modified dataset
   */
  public appendProjectTypeLabels(
    dataset: any,
    projectCostTypes: {
      value: number;
      label: string;
    }[]
  ): any {
    if (!projectCostTypes.length) {
      return dataset;
    }

    let newDataSet = {};
    const projectCostTypesMap = this.mapProjectCostTypes(projectCostTypes);

    newDataSet = dataset.map(item => {
      return {
        ...item,
        typeLabel: item.typeId ? projectCostTypesMap[item.typeId] : '',
      };
    });

    return newDataSet;
  }

  /**
   * Auxiliary method to map the array of project types to a key=>value object
   * for faster retrieval
   */
  private mapProjectCostTypes(
    projectCostTypes: { value: number; label: string }[]
  ) {
    const projectCostTypesMap = {};

    projectCostTypes.forEach(projectType => {
      projectCostTypesMap[projectType.value] = projectType.label;
    });

    return projectCostTypesMap;
  }

  /**
   * Prepares and returns the apollo mutation Observable
   * @param arg the configuration
   *
   * @returns the mutation observable
   */
  private prepareProjectStatusMutation({
    model,
    action,
    queryName,
    queryBody,
    oldData,
    newData,
  }: {
    model: string;
    action: string;
    queryName: string;
    queryBody: GQLFragment;
    oldData: ProjectStatusMutationData;
    newData: ProjectStatusMutationData;
  }): Observable<any> {
    const mutation = this.helperService.getMutationQuery(
      model,
      action,
      queryBody
    );

    const variables = this.getMutationVariables(model, action, newData);

    return this.apollo.mutate({
      mutation,
      variables,
      update: (store, { data: { projectTypeHyperionMutation } }: any) => {
        this.updateApolloStore(
          store,
          projectTypeHyperionMutation,
          queryName,
          oldData,
          newData
        );
      },
    });
  }

  /**
   * Returns an object with the mutationType as key, and the newData as value
   */
  private getMutationVariables(
    model: string,
    action: string,
    newData: ProjectStatusMutationData
  ): { [key: string]: ProjectStatusMutationData } {
    const variables = {};
    const hyperionMutationType = this.helperService.getMutationType(
      model,
      action
    );
    variables[hyperionMutationType] = { ...newData };

    return variables;
  }

  /**
   * Updates the apollo store accordingly if the mutation has succeeded
   */
  private updateApolloStore(
    store: DataProxy,
    projectTypeHyperionMutation: {
      mutationDetails: { mutationSucceeded: boolean }[];
    },
    queryName: string,
    oldData: ProjectStatusMutationData,
    newData: ProjectStatusMutationData
  ) {
    if (!projectTypeHyperionMutation.mutationDetails[0].mutationSucceeded) {
      return;
    }

    const fromStoredData = store.readQuery({
      query: queries[queryName].query,
      variables: { status: [oldData.status] },
    });

    const fromStoredDataCopy = JSON.parse(JSON.stringify(fromStoredData));
    /**
     * We wrap in a try/catch because we might not have populated the store yet
     * The problem we are addressing is the following:
     *
     * Pre-conditions:
     * 1. We load the project page with the `ONGOING` status.
     * 2. Without opening the `FINISHED` projects tab - and therefore not
     *    populating the `FINISHED` cache.
     * 2.1 Select a few projects and update their status to `FINISHED`.
     *
     * At this point in time have successfully performed the mutation in the
     * backend, and want now to update the cache accordingly.
     *
     * Given the target cache (`toStoredData`) is not set, we will only pop the
     * element from the `fromStoredData`, but not inject it in an inexistent cache.
     *
     * If the target cache would exist though, then we would not only pop the
     * element from the `fromStoredData` but also push it into the `toStoredData`.
     */
    let toStoredData;
    try {
      toStoredData = store.readQuery({
        query: queries[queryName].query,
        variables: { status: [newData.status] },
      });
    } catch {
      toStoredData = null;
    }

    const toStoredDataCopy = JSON.parse(JSON.stringify(toStoredData));

    const updatedStore = this.moveBetweenGraphQLCaches({
      id: newData.id,
      from: fromStoredDataCopy['company']['projects'],
      to: toStoredData ? toStoredDataCopy['company']['projects'] : null,
    });

    fromStoredDataCopy['company']['projects'] = updatedStore.from;
    store.writeQuery({
      query: queries[queryName].query,
      variables: { status: [oldData.status] },
      data: fromStoredDataCopy,
    });

    // Only re-write if there is data in the store
    if (toStoredDataCopy && updatedStore.to) {
      toStoredDataCopy['company']['projects'] = updatedStore.to;
      store.writeQuery({
        query: queries[queryName].query,
        variables: { status: [newData.status] },
        data: toStoredDataCopy,
      });
    }
  }

  /**
   * Moves an element with the given id from one GraphQL Cache to another.
   * _If no `to` cache is given, then the element is simply removed_
   *
   * @returns the updated cache fragment
   */
  private moveBetweenGraphQLCaches(
    cacheFragment: CacheFragmentUpdate
  ): CacheFragmentUpdate {
    const from = { ...cacheFragment.from };
    const to = cacheFragment.to ? { ...cacheFragment.to } : null;
    const index = from.edges.findIndex(
      (edge: any) => +edge.node.id === cacheFragment.id
    );

    if (index || index === 0) {
      const node = from.edges.splice(index, 1)[0];
      from.totalCount--;

      if (to && to.edges && (to.totalCount || to.totalCount === 0)) {
        to.edges.push(node);
        to.totalCount++;
      }
    }

    return { id: cacheFragment.id, from, to };
  }
}

export interface ProjectStatusMutationData {
  id: number;
  status: PROJECT_STATUS;
}

export interface CacheFragmentUpdate {
  id: number;
  from: GraphQLConnection;
  to?: GraphQLConnection;
}

export interface GraphQLConnection {
  totalCount: number;
  edges: any[];
}
