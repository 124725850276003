import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { HttpService } from '../../shared/http/index';
import { MessageService } from '../../shared/message/index';
import { CompanyFunctionsService } from '../../shared/company/index';
import {
  CompanyUsersService,
  UserLocalStorageService,
} from 'app/shared/user/index';
import { HelperService } from '../../shared/helpers/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { MeUser } from 'app/shared/user/me-user';
import { PrintPdfService } from 'app/shared/print-pdf.service';

@Component({
  selector: 'day-advanced',
  templateUrl: 'day-advanced.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DayAdvancedComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  advancedVariables = {
    displayDays: 1,
    checkedPeriodType: 'Week',
    displayType: 'perUser',
    checkedPeriodYearValue: null,
    checkedPeriodTypeValue: null,
  };
  public meUser: MeUser;

  dates = [];
  dataSet = [];
  usersDropdown;
  updateDays = [];
  mutatedDays = [];
  dataModel = 'day';
  yearsDropdown = [];
  weeksDropdown = [];
  public useNotarized = false;
  showDaysForUsers = [];
  normalDate = new Date();
  usersSub: Subscription;
  functionsSub: Subscription;
  loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  beforeFirst: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isDayFormVisible: boolean;

  @ViewChild('table') public table: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    private httpService: HttpService,
    private helperService: HelperService,
    private messageService: MessageService,
    private userService: CompanyUsersService,
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private companyFunctionsService: CompanyFunctionsService,
    private userLocalStorageService: UserLocalStorageService,
    private printPdfService: PrintPdfService
  ) {}

  public ngOnInit(): void {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.getCompanyFunctions();
    this.getUsers();
    this.setUsers();

    this.yearsDropdown = this.makeYearsOptions();
    this.weeksDropdown = this.makeWeekOptions(
      this.helperService.getCurrentYear()
    );
    this.advancedVariables.checkedPeriodTypeValue =
      this.helperService.getWeekNumber(new Date());
    this.advancedVariables.checkedPeriodYearValue =
      this.helperService.getCurrentYear();
  }

  changeUsers() {
    const model = this.advancedVariables;

    if (this.showDaysForUsers.length > 0) {
      model['users'] = {};

      for (const index in this.showDaysForUsers) {
        const userObject = this.showDaysForUsers[index];

        const userKey = '' + userObject;

        model['users'][userKey] = 'on';
      }
    } else {
      delete model['users'];
    }

    this.advancedVariables = model;
  }

  changeWeek(directionParam) {
    let weekModel = this.advancedVariables['checkedPeriodTypeValue'];

    if (directionParam === 'down') {
      weekModel =
        weekModel > this.weeksDropdown[0]['value'] ? weekModel - 1 : weekModel;
    } else {
      weekModel =
        weekModel < this.weeksDropdown[this.weeksDropdown.length - 1]['value']
          ? weekModel + 1
          : weekModel;
    }
    setTimeout(() => {
      this.advancedVariables['checkedPeriodTypeValue'] = weekModel;
      this.cdr.detectChanges();
    }, 0);
  }

  changeYear() {
    this.weeksDropdown = this.makeWeekOptions(
      this.advancedVariables['checkedPeriodYearValue']
    );
  }

  private makeYearsOptions(): any[] {
    const currentYear = this.helperService.getCurrentYear();
    const start = currentYear - 5;
    const end = currentYear + 1;
    const options = [];

    for (let i = start; i <= end; i++) {
      const yearObject = {
        label: '' + i,
        value: i,
      };

      options.push(yearObject);
    }

    return options;
  }

  makeWeekOptions(year) {
    const start = 1;
    const end = this.helperService.weeksInYear(year);
    const options = [];

    for (let i = start; i <= end; i++) {
      const weekObject = {
        label: '' + i,
        value: i,
      };

      options.push(weekObject);
    }

    return options;
  }

  setUsers() {
    const newVariables = this.advancedVariables;

    if (this.meUser && this.meUser.type !== '3') {
      const userId = this.meUser.id;

      newVariables['users'] = {};
      newVariables['users'][userId] = 'on';
    }
  }

  private getCompanyFunctions(): void {
    this.functionsSub = this.companyFunctionsService
      .getCompanyFunctions()
      .subscribe(() => {
        this.useNotarized = false;
        if (
          this.companyFunctionsService.companyFunctionIsSet('useNotarized') &&
          this.meUser
        ) {
          this.useNotarized =
            this.meUser.type === '3' ||
            (this.meUser.type === '2' &&
              this.companyFunctionsService.companyFunctionIsSet(
                'advancedUserCanNotarizeTimereports'
              ));
        }
      });
  }

  private getUsers(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.userService.makeLabelsArray(data);
        this.showDaysForUsers = [];
      });
  }

  public getDays(): void {
    this.loading.next(true);

    this.httpService
      .makeHttpPostRequest('/day/AdvancedHyperion', this.advancedVariables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.handleDataResponse(data);
        this.loading.next(false);
        this.beforeFirst.next(false);
      });
  }

  handleDataResponse(dataParam) {
    if (dataParam['status'] && dataParam['status'] === 'success') {
      const dataSet = dataParam['return'];

      this.dates = dataSet['dates'];
      this.dataSet = this.formatData(dataSet['data']);
    } else {
      const status = dataParam['status'] || 'failed';
      const msg = dataParam['msg'] || 'Invalid server response.';
      this.messageService.insertData({ textArray: [msg], type: status });
    }
  }

  formatData(data) {
    const formatedData = data;

    for (const userIndex in formatedData) {
      const userData = formatedData[userIndex];

      userData['projectsArray'] = this.makeProjectsArray(userData['projects']);
    }

    return formatedData;
  }

  returnHasOwnPropertyDay = projectDate => !!projectDate['days'];

  makeProjectsArray(projectsObject) {
    const projectsArray = [];

    for (const key in projectsObject) {
      if (key !== 'dates') {
        let project = projectsObject[key];

        project = this.setCheckBoxesValues(project);

        projectsArray.push(project);
      }
    }

    return projectsArray;
  }

  setCheckBoxesValues(projectParam) {
    let formatedProject;
    const dates = projectParam['dates'];

    for (const dateIndex in dates) {
      const dateObject = dates[dateIndex];
      if (dateObject['days']) {
        for (const dayIndex in dateObject['days']) {
          let dayObject = dateObject['days'][dayIndex];

          dayObject['isNotarized'] = dayObject['day']['notarized'] > 0;
          dayObject = this.createTouchedParam(dayObject);
        }
      }
    }

    return projectParam;
  }

  createTouchedParam(dayParam) {
    const day = dayParam;

    day['touched'] = false;
    day['error'] = {
      status: true,
      msgArray: [],
    };

    return day;
  }

  saveRequest() {
    this.loading.next(true);
    this.loopDays('save');
  }

  loopDays(mode, mutatedDays?) {
    let hasErrors: boolean;

    if (mode === 'save') {
      this.updateDays = [];
    }

    for (const userIndex in this.dataSet) {
      const userObject = this.dataSet[userIndex];

      for (const projectIndex in userObject['projects']) {
        const projectDates = userObject['projects'][projectIndex]['dates'];

        for (const dateIndex in projectDates) {
          const dateObject = projectDates[dateIndex];

          if (dateObject['days']) {
            for (const dayIndex in dateObject['days']) {
              const dayObject = dateObject['days'][dayIndex];

              if (mode === 'save') {
                if (dayObject['touched']) {
                  this.updateDays.push(dayObject);
                }
              } else {
                for (const mutatedDayIndex in mutatedDays) {
                  const mutatedDayObject = mutatedDays[mutatedDayIndex];
                  const dayError = mutatedDayObject['error'];
                  if (!dayError['status']) {
                    hasErrors = true;
                  }
                }
              }
            }
          }
        }
      }
    }

    if (mode === 'afterUpdate') {
      this.mutatedDays = [];
      if (hasErrors) {
        this.messageService.insertData({
          textArray: ['Markerade dagar sparades inte'],
          type: 'error',
        });
      } else {
        this.messageService.insertData({
          textArray: ['Redigerade dagar sparades'],
          type: 'success',
        });
      }

      this.loading.next(false);
    } else {
      this.updateDaysRequest();
    }
  }

  handleUpdatedDays() {
    this.loopDays('afterUpdate', this.mutatedDays);
  }

  updateDaysRequest() {
    for (const index in this.updateDays) {
      const dayObject = this.updateDays[index];

      this.actionUpdate(dayObject).subscribe(day => {
        this.mutatedDays.push(day);
        if (this.mutatedDays.length === this.updateDays.length) {
          this.handleUpdatedDays();
        }
      });
    }
  }

  actionUpdate(dayParam) {
    const day = dayParam;

    const mode = day['class'];

    let notarizedValue;

    if (day['day']['notarized'] === '0' || day['day']['notarized'] === null) {
      if (day['isNotarized']) {
        notarizedValue = Number(this.meUser.id);
      } else {
        notarizedValue = day['day']['notarized'];
      }
    } else {
      if (day['isNotarized']) {
        notarizedValue = day['day']['notarized'];
      } else {
        notarizedValue = '0';
      }
    }

    const variables = {
      notarized: notarizedValue,
      id: Number(day['day']['id']),
      hours: day['day']['hours'],
    };

    if (mode === 'Day') {
      variables['hoursToInvoice'] = day['day']['hoursToInvoice'];
      variables['mile'] = day['day']['mile'];
      variables['mileToInvoice'] = day['day']['mileToInvoice'];
    }

    const modelToMutation = 'day';

    const crudType = 'update';

    return Observable.create(observer => {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          modelToMutation,
          crudType,
          false,
          variables
        )
        .subscribe(executedData => {
          if (!executedData.mutationSucceededAllArguments) {
            this.mutationService.displayMutationStatus(executedData);
            day['error'] = {
              status: false,
              msgArray: executedData['mutationDetails'][0]['errorsMsgs'],
              msg: executedData['mutationDetails'][0]['msg'],
            };
          } else {
            for (const key in day) {
              if (day[key] === 'error') {
                day.splice(key, 1);
              }
            }
          }

          observer.next(day);
          executeMutationSub.unsubscribe();
        });
    });
  }

  makeJSONObjectsOfArray(arrayOfJSONStrings) {
    const arrayOfObjects = [];

    for (const index in arrayOfJSONStrings) {
      let stringObject = arrayOfJSONStrings[index];
      stringObject = stringObject.json();

      arrayOfObjects.push();
    }

    return arrayOfObjects;
  }

  public printTable(): void {
    const fileName = `Tidrapporter v${this.advancedVariables.checkedPeriodTypeValue} - ${this.advancedVariables.checkedPeriodYearValue}`;
    this.printPdfService.generatePdf(this.table, fileName);
  }

  public ngOnDestroy(): void {
    this.functionsSub?.unsubscribe();
    this.usersSub?.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
