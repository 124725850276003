import { Component, OnInit, Input } from '@angular/core';
import { ProjectWithKpis } from 'generated/types';
import { Observable } from 'rxjs';
import { FetchProjectQuery } from '../project-detail/project-info/graphql/project.generated';
import { ProjectFragment } from '../project-list/graphql/projects.generated';

@Component({
  selector: 'app-project-status-icon',
  templateUrl: './project-status-icon.component.html',
  styleUrls: ['./project-status-icon.component.scss'],
})
export class ProjectStatusIconComponent implements OnInit {
  @Input() public project:
    | ProjectFragment
    | FetchProjectQuery['project']
    | ProjectWithKpis;
  @Input() public projectAsync: Observable<
    ProjectFragment | FetchProjectQuery['project'] | ProjectWithKpis
  >;
  public hasStarted: boolean;
  public hasEnded: boolean;

  public ngOnInit(): void {
    if (this.project) {
      this.setValues(this.project);
    } else if (this.projectAsync) {
      this.projectAsync.subscribe(project => {
        this.setValues(project);
      });
    }
  }

  private setValues(
    project: ProjectFragment | FetchProjectQuery['project'] | ProjectWithKpis
  ): void {
    if (!project.endDate || !project.startDate) {
      return;
    }

    const now = new Date();
    const endDate = new Date(project.endDate);
    const startDate = new Date(project.startDate);

    now.setUTCHours(0, 0, 0, 0);

    this.hasStarted = now >= startDate;
    this.hasEnded = now > endDate;
  }
}
