import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { DocuDirtyConfirmDiscardDialogComponent } from './docu-dirty-confirm-discard-dialog/docu-dirty-confirm-discard-dialog.component';
import { SaveAction } from './document.service';
import { AppDialogService } from 'app/shared/dialogs';
import { CanBeDirty } from './can-be-dirty';

@Injectable()
export class DocuDirtyService implements CanDeactivate<CanBeDirty> {
  constructor(private dialogService: AppDialogService) {}

  canDeactivate(
    component: CanBeDirty,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> {
    if (component && component.dirty) {
      const ref = this.dialogService.openComponent(
        DocuDirtyConfirmDiscardDialogComponent
      );
      return ref.onClose.pipe(
        switchMap(response => {
          if (response === 'save') {
            return component.save(SaveAction.Save, true);
          } else {
            return observableOf(response === 'discard');
          }
        })
      );
    } else {
      return observableOf(true);
    }
  }
}
