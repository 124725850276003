<p-sidebar [(visible)]="showEditAttendance" position="right">
  <h1>{{ getEditHeader() }}</h1>
  <form
    class="p-grid"
    [formGroup]="editAttendanceForm"
    (submit)="editAttendanceAction()"
  >
    <span class="p-col-12" *ngIf="showTime()">
      <label>Ny tid</label>
      <p-calendar
        showIcon="true"
        showTime="true"
        dataType="string"
        formControlName="time"
        [showWeek]="true"
      ></p-calendar>
    </span>
    <span class="p-col-12">
      <label>Kommentar</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-comment"></i>
        </span>
        <textarea pInputTextarea formControlName="comment"></textarea>
      </span>
    </span>
    <span class="p-col-12 p-d-flex p-jc-end">
      <button
        pButton
        type="submit"
        class="p-button-primary-hyperion"
        label="Spara"
        icon="pi pi-save"
      ></button>
    </span>
  </form>
</p-sidebar>
<p-sidebar [(visible)]="showCreateAttendance" position="right">
  <h1>Skapa tid</h1>
  <form
    class="p-grid"
    [formGroup]="attendanceForm"
    (submit)="createAttendance()"
  >
    <span
      *ngIf="attendanceCreateId === (companyData | async)?.orgNr"
      class="p-col-12"
    >
      <label>Välj medarbetare*</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <p-dropdown
          [options]="users | async"
          optionValue="id"
          formControlName="userId"
          placeholder="Välj medarbetare..."
        >
          <ng-template pTemplate="item" let-user>
            {{ user.firstName }} {{ user.lastName }}
          </ng-template>
          <ng-template pTemplate="selectedItem" let-user>
            {{ user.firstName }} {{ user.lastName }}
          </ng-template>
        </p-dropdown>
      </span>
    </span>
    <span
      *ngIf="attendanceCreateId !== (companyData | async)?.orgNr"
      class="p-col-6"
    >
      <label>Förnamn</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-user"></i>
        </span>
        <input pInputText type="text" formControlName="firstName" />
      </span>
    </span>
    <span
      *ngIf="attendanceCreateId !== (companyData | async)?.orgNr"
      class="p-col-6"
    >
      <label>Efternamn</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-briefcase"></i>
        </span>
        <input pInputText type="text" formControlName="lastName" />
      </span>
    </span>
    <span
      *ngIf="attendanceCreateId !== (companyData | async)?.orgNr"
      class="p-col-12"
    >
      <label>Personnr</label>
      <span class="p-inputgroup">
        <span class="p-inputgroup-addon">
          <i class="pi pi-hashtag"></i>
        </span>
        <input pInputText type="text" formControlName="personalNr" />
      </span>
    </span>

    <span class="p-col-12">
      <label>Arbetspasset påbörjas*</label>
      <p-calendar
        showIcon="true"
        showTime="true"
        dataType="string"
        formControlName="startTime"
        [showWeek]="true"
      ></p-calendar>
    </span>
    <span class="p-col-12">
      <label>Sluttid</label>
      <p-calendar
        showIcon="true"
        showTime="true"
        dataType="string"
        formControlName="stopTime"
        [showWeek]="true"
      ></p-calendar>
    </span>
    <span class="p-col-12 p-d-flex p-jc-end">
      <button
        pButton
        type="submit"
        class="p-button-primary-hyperion"
        label="Skapa"
        icon="pi pi-plus"
      ></button>
    </span>
  </form>
</p-sidebar>
<p-sidebar [(visible)]="showCreateSubcontractor" position="right">
  <app-project-subcontractor-form
    (createEmitter)="createSubcontractor($event)"
  ></app-project-subcontractor-form>
</p-sidebar>
<div class="page-container p-grid">
  <span class="p-col-12 p-d-flex p-jc-between p-ai-start">
    <span class="p-grid p-p-0">
      <span class="p-inputgroup p-col-12 p-p-0">
        <span class="p-inputgroup-addon">
          <i class="pi pi-search"></i>
        </span>
        <app-project-subcontractor-search
          [projectId]="projectId"
          (selectionEmitter)="addSubcontractor($event)"
        ></app-project-subcontractor-search>
      </span>
      <p-checkbox
        class="p-col-12"
        label="Tillåt underentreprenörer att lägga till egna underentreprenörer"
        [(ngModel)]="allowAddSubcontractors"
        (onChange)="allowChange($event.checked)"
        binary="true"
      ></p-checkbox>
    </span>
    <span>
      <button
        pButton
        icon="pi pi-list"
        label="Återsamlingslista"
        (click)="getGatherlist()"
      ></button>
      <button
        pButton
        (click)="showCreateSubcontractor = !showCreateSubcontractor"
        class="p-button-primary-hyperion p-ml-2"
        label="Skapa underleverantör"
        icon="pi pi-plus"
      ></button>
    </span>
  </span>
  <p-card class="p-col-12 p-danger" *ngIf="!allOk()">
    <div class="p-grid">
      <h1
        class="p-col-12 p-d-flex p-ai-center"
        style="color: var(--btn-danger-color)"
      >
        <i class="pi pi-exclamation-triangle p-mr-2"></i>
        Ogiltig personalliggare
      </h1>
      <span class="p-col-12" *ngIf="!allHavePersonalNumbers()">
        Vissa användare saknar personnummer
      </span>
      <span class="p-col-12" *ngIf="!hasWorkplaceId()">
        Det saknas ett byggarbetsplats-ID på projektet.
      </span>
      <span class="p-col-12" *ngIf="!hasWorkplaceId()">
        Lägg till detta under Huvudinfo > Projektinformation > Information
      </span>
    </div>
  </p-card>
  <p-card class="p-col-12" *ngIf="this.workplaceId">
    <h1>
      {{ (companyData | async)?.name }}, {{ (companyData | async)?.orgNr }}
    </h1>
    <h3>Huvudansvarig</h3>
    <app-project-attendance-table
      [reportsInput]="reports"
      [sumHours]="sumHourMap[(companyData | async)?.orgNr]"
      (editEvent)="openEditAttendanceForm($event.id, $event.orgNr, $event.mode)"
    ></app-project-attendance-table>
    <span class="p-d-flex p-jc-end">
      <button
        pButton
        (click)="openAttendanceForm()"
        class="p-button-primary-hyperion"
        icon="pi pi-plus"
        label="Lägg till tid"
      ></button>
    </span>
  </p-card>
  <ng-container *ngIf="this.workplaceId">
    <p-accordion
      *ngFor="let subcontractor of subcontractors | async"
      class="p-col-12"
    >
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span
            class="p-col-12 p-d-flex p-jc-between p-ai-center p-pl-4 p-pr-4"
          >
            <h4>{{ subcontractor.name }} {{ subcontractor.orgNr }}</h4>
            <button
              *ngIf="subcontractor.orgNr !== 'Ej Kopplade'"
              pButton
              (click)="removeSubcontractor(subcontractor.id)"
              class="p-button-icon-danger"
              icon="pi pi-trash"
            ></button>
          </span>
        </ng-template>
        <span class="p-grid">
          <app-project-attendance-table
            class="p-col-12"
            [reportsInput]="subcontractorReportMap[subcontractor.orgNr]"
            (editEvent)="
              openEditAttendanceForm($event.id, $event.orgNr, $event.mode)
            "
            [sumHours]="sumHourMap[subcontractor.orgNr]"
          ></app-project-attendance-table>
          <span class="p-col-12 p-d-flex p-jc-end">
            <button
              pButton
              (click)="openAttendanceForm(subcontractor.orgNr)"
              class="p-button-primary-hyperion"
              icon="pi pi-plus"
              label="Lägg till tid"
            ></button>
          </span>
        </span>
      </p-accordionTab>
    </p-accordion>
  </ng-container>
</div>
