<div class="p-grid">
  <div class="p-col-12">
    <h1>Leverantörsfakturor</h1>
    <app-button
      *ngIf="!(relationshipsLoading | async)"
      (click)="viewAllInvoicesPDF()"
      [type]="'showPdf'"
    ></app-button>
  </div>
  <div class="p-col-12" *ngIf="relationshipsLoading | async">
    <spinner></spinner>
  </div>
  <div class="p-col-12 p-g-nopad" *ngIf="!(relationshipsLoading | async)">
    <app-emptyState
      *ngIf="dataSet.length === 0"
      [model]="'Invoice'"
    ></app-emptyState>
    <div *ngFor="let supplierInvociesBySource of dataSet; trackBy: rowTrackBy">
      <div class="p-grid">
        <div class="p-col-12">
          <hyperion-card>
            <h3>{{ supplierInvociesBySource.name }}</h3>
            <supplier-invoice-row-holder
              [projectInfo]="projectInfo"
              [dataSet]="supplierInvociesBySource.invoices"
              [functionsData]="functionsData"
              [projectDropdown]="allProjectsLabels"
              [companyCostTypeId]="supplierInvociesBySource.companyCostTypeId"
              (refetchSupplierInvoices)="getSupplierInvoices()"
            ></supplier-invoice-row-holder>
          </hyperion-card>
        </div>
      </div>
    </div>
  </div>
</div>
