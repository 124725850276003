import {
  Component,
  Input,
  OnInit,
  ViewChildren,
  ViewChild,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AutoComplete } from 'primeng/autocomplete';

import { HttpService } from 'app/shared/http';
import { GlobalService } from 'app/shared/global/index';
import { HelperService } from 'app/shared/helpers/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { CompanyUsersService } from 'app/shared/user/index';
import { ProjectDefaultTodoService } from './project-default-todo.service';
import { ProjectDefaultTodoRowComponent } from './project-default-todo-row.component';
import {
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo/index';
import {
  ProjectDefaultTodosService,
  TodoTopicsService,
} from 'app/shared/company/index';
import { ExtendedFormGroup } from 'app/shared/forms/extended-form-group';

@Component({
  selector: 'project-default-todo',
  templateUrl: 'project-default-todo.component.html',
  providers: [FormHandlerService],
})
export class ProjectDefaultTodoComponent implements OnDestroy, OnInit {
  @Input() projectData;
  @ViewChildren(ProjectDefaultTodoRowComponent)
  projectRowComponents: QueryList<ProjectDefaultTodoRowComponent>;
  @ViewChild(AutoComplete, { static: true })
  autoCompleteComponent: AutoComplete;

  buttons;
  topicButtons;
  dataModel = 'projectDefualtTodo';
  dataModelCapitalized = 'ProjectDefualtTodo';
  topicsSub: Subscription;
  todosSub: Subscription;
  public componentMainForm: ExtendedFormGroup;
  todoTopicForm: FormGroup;
  setFields = {
    componentMainForm: {
      model: 'ProjectDefualtTodo',
      nestedForm: false,
      attributes: {
        description: '',
        estimatedTime: '',
        type: null,
      },
    },
    todoTopicForm: {
      model: 'Todotopic',
      nestedForm: false,
      attributes: {
        Name: '',
      },
    },
  };
  eraseTopicModel;
  createTopicModel;
  todoTopicsDropdown;
  altTodoTopicsDropdown;
  todoAutoModel: string;
  todoSearchString: string;
  reset = false;
  loading = true;
  interrupt = false;
  dataSet = {
    todos: [],
    todoTopics: [],
    todoTopicsWithChilds: [],
  };
  usersDropdown = [];
  results: BehaviorSubject<any> = new BehaviorSubject([]);
  totals = {
    estimatedTime: 0,
  };
  usersSub: Subscription;

  constructor(
    private todoTopicsService: TodoTopicsService,
    private projectDefaultTodosService: ProjectDefaultTodosService,
    private helperService: HelperService,
    private globalService: GlobalService,
    private mutationService: ApolloMutationService,
    private userService: CompanyUsersService,
    private httpService: HttpService,
    private projectDefaultTodoService: ProjectDefaultTodoService,
    private apolloQueryService: ApolloQueryService,
    private formHandler: FormHandlerService
  ) {}

  ngOnInit() {
    this.getUsers();

    this.listenToCallSetTodos();

    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    const topicButtonObject = {
      create: {
        model: 'todotopic',
      },
      delete: {
        model: 'todotopic',
      },
    };

    this.initForm();

    this.buttons = this.formHandler.getButtonValues(buttonObject);
    this.topicButtons = this.formHandler.getButtonValues(topicButtonObject);

    this.getTodoTopics();
  }

  ngOnDestroy() {
    this.topicsSub && this.topicsSub.unsubscribe();
    this.todosSub && this.todosSub.unsubscribe();
    this.usersSub && this.usersSub.unsubscribe();
  }

  initForm() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.setFields['componentMainForm']
    );
    this.todoTopicForm = this.formHandler.groupedFormSimple(
      this.setFields['todoTopicForm']
    );

    this.formHandler
      .groupSetLabelsRules(
        this.setFields['componentMainForm'],
        this.componentMainForm
      )
      .then();
    this.formHandler
      .groupSetLabelsRules(this.setFields['todoTopicForm'], this.todoTopicForm)
      .then();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.componentMainForm
    );

    this.buttons = this.formHandler.lockButtons(this.buttons);

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
    } else {
      this.loading = true;

      const refetchArr = [{ name: 'projectDefaultTodos', variables: null }];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.todoSearchString = null;
              this.formHandler
                .groupSetLabelsRules(this.setFields['componentMainForm'])
                .then(form => (this.componentMainForm = form));
            } else {
              this.loading = false;
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);
            executeMutationSub.unsubscribe();
          },
          err => {
            this.buttons = this.formHandler.unlockButtons(this.buttons);
            console.warn(err);
          }
        );
    }
  }

  createTopic() {
    const crudType = 'create';
    const dataToMutation = this.mutationService.getMutationDataFromForm(
      this.todoTopicForm
    );

    dataToMutation['active'] = 1;

    this.topicButtons = this.formHandler.lockButtons(this.topicButtons);

    if (!this.formHandler.formValid([this.todoTopicForm])) {
      this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
    } else {
      const refetchArr = [{ name: 'companyTodoTopics', variables: null }];

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'todotopic',
          crudType,
          false,
          dataToMutation,
          null,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              { form: this.todoTopicForm, argument: crudType + 'Todotopic' },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.formHandler
                .groupSetLabelsRules(this.setFields['todoTopicForm'])
                .then(form => (this.todoTopicForm = form));
            }

            this.topicButtons = this.formHandler.unlockButtons(
              this.topicButtons
            );

            executeMutationSub.unsubscribe();
          },
          err => {
            console.warn(err);
            this.topicButtons = this.formHandler.unlockButtons(
              this.topicButtons
            );
          }
        );
    }
  }

  listenToCallSetTodos() {
    this.projectDefaultTodoService.getTodos.subscribe(data => {
      this.setSaveTodosWithProjects();
    });
  }

  setSaveTodosWithProjects() {
    const saveTodos = [];

    this.projectRowComponents.forEach(item => {
      if (item.saveToProject) {
        saveTodos.push(+item.dataObjectFromParent['id']);
      }
    });

    this.projectDefaultTodoService.todos = saveTodos;
  }

  calculateTotals() {
    let total = 0;

    this.projectRowComponents.forEach(item => {
      const timeValue = item.componentMainForm.controls['estimatedTime'].value;
      if (!isNaN(timeValue)) {
        total += Number(timeValue);
      }
    });
    this.totals['estimatedTime'] = total;
  }

  eraseTopic() {
    if (this.eraseTopicModel != null) {
      this.eraseTopicMutation();
    }
  }

  eraseTopicMutation() {
    const crudType = 'update';
    const dataToMutation = {};

    dataToMutation['id'] = Number(this.eraseTopicModel);
    dataToMutation['active'] = 0;

    this.topicButtons = this.formHandler.lockButtons(this.topicButtons);

    const refetchArr = [{ name: 'companyTodoTopics', variables: null }];
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'todotopic',
        crudType,
        false,
        dataToMutation,
        null,
        refetchArr
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.eraseTopicModel = null;
          }
          this.mutationService.displayMutationStatus(executedData);

          this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
          executeMutationSub.unsubscribe();
        },
        err => {
          this.topicButtons = this.formHandler.unlockButtons(this.topicButtons);
          console.warn(err);
        }
      );
  }

  getReturnAttributes() {
    const returnAttributes = [
      'id',
      'type',
      'orderNr',
      'description',
      'descriptionOffer',
      'estimatedTime',
    ];

    return returnAttributes;
  }

  getTodoTopics() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyTodoTopics')
      .subscribe(data => {
        this.topicsSub = data.sub;
        this.todoTopicsDropdown = this.todoTopicsService.dataFormater(
          data.data,
          'labels'
        );
        this.altTodoTopicsDropdown = this.todoTopicsService.dataFormater(
          data.data,
          'altLabels'
        );
        this.dataSet.todoTopics = this.helperService.cleanFromNode(
          data.data.company.todotopics
        );
        this.getTodos();
      });
  }

  getTodos() {
    this.apolloQueryService
      .apolloWatchQueryTwo('projectDefaultTodos')
      .subscribe(data => {
        this.todosSub = data.sub;
        this.loading = false;
        this.dataSet.todos = this.projectDefaultTodosService.dataFormater(
          data.data,
          'listNew'
        );
        this.sortTodos(this.dataSet.todos);
        setTimeout(() => {
          this.calculateTotals();
        }, 500);
      });
  }

  sortTodos(dataParam) {
    this.dataSet.todoTopicsWithChilds = [];
    const todos = dataParam; // indata

    const topics = {};
    for (const todoIndex in todos) {
      const todo = todos[todoIndex];
      if (!topics.hasOwnProperty(todo['topic']['id'])) {
        const topic = {
          id: todo['topic']['id'],
          name: todo['topic']['Name'],
          childs: [],
        };
        topic['childs'].push(todo);
        topics[todo['topic']['id']] = topic;
      } else {
        topics[todo['topic']['id']]['childs'].push(todo);
      }
    }

    for (const key in topics) {
      const topicWithChilds = topics[key];
      this.dataSet.todoTopicsWithChilds.push(topicWithChilds);
    }

    this.loading = false;
  }

  getUsers() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.userService.makeLabelsArray(data);
        this.addNullUser();
      });
  }

  addNullUser() {
    const nullUser = {
      value: null,
      label: 'Välj medarbetare...',
    };

    this.usersDropdown.splice(0, 0, nullUser);
  }

  estimatedChangedInChild() {
    this.calculateTotals();
  }

  closeDropdown() {
    setTimeout(() => {
      this.autoCompleteComponent.overlayVisible = false;
    }, 20);
  }

  search() {
    this.componentMainForm.controls['description'].setValue(
      this.todoSearchString
    );

    if (this.todoSearchString.length > 2) {
      this.searchTodos(this.todoSearchString);
    } else {
      this.autoCompleteComponent.overlayVisible = false;
    }
  }

  searchTodos(query) {
    const url =
      this.globalService.getUrlPrefix() +
      '/todo/AutoCompleteHyperion?q=' +
      query +
      '&limit=50';
    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      this.results.next(data);
      if (this.results.value.length > 0) {
        this.autoCompleteComponent.overlayVisible = true;
      } else {
        this.autoCompleteComponent.overlayVisible = false;
      }
    });
  }

  setTodoFromAutosuggest(value) {
    const todo = { ...value };
    this.componentMainForm.controls['description'].setValue(
      todo['description']
    );
    this.componentMainForm.controls['type'].setValue(todo['type']);
    this.todoSearchString = todo['description'];
    this.autoCompleteComponent.overlayVisible = false;
  }
}
