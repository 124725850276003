import {
  Component,
  forwardRef,
  OnInit,
  Injector,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';
import { GlobalService } from '../../../global/index';

@Component({
  selector: 'appForm2-wide-calendar',
  styleUrls: ['../form.component.scss', './form-wide.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WideFormCalendar2Component),
      multi: true,
    },
  ],
  template: `
    <div class="p-grid p-ai-center form-group">
      <div class="p-col-12 p-md-3">
        <div class="wide-label">
          <label
            class="wide-label"
            *ngIf="!showToolTip"
            style="display:inline"
            [innerHTML]="htmlAttributes['label']['value'] || control.label"
          ></label>
          <label class="wide-label" *ngIf="showToolTip" style="display:inline"
            >{{ htmlAttributes['label']['value'] || control.label }}
            <span
              class="{{ tooltip['class'] }}"
              [tooltipStyleClass]="tooltip['tooltipStyleClass']"
              [tooltipPosition]="tooltip['tooltipPosition']"
              [pTooltip]="tooltip['msg']"
              [escape]="false"
            >
              ?</span
            >
          </label>
        </div>
      </div>
      <div class="p-col-12 p-md-9">
        <p-calendar
          [appendTo]="appendTo"
          hourFormat="24"
          class="calendar"
          [locale]="locale"
          dataType="string"
          [(ngModel)]="value"
          dateFormat="yy-mm-dd"
          [showTime]="showTime"
          [showIcon]="true"
          [monthNavigator]="true"
          [yearNavigator]="yearNavigator"
          [yearRange]="yearRange"
          [style]="{ width: '100%' }"
          [inline]="inline"
          [selectionMode]="selectionMode"
          inputStyleClass="form-control"
          (onBlur)="blurEvent()"
          (onInput)="pushChanges(value)"
          (onSelect)="pushChanges(value)"
          (ngModelChange)="pushChanges($event)"
          [attr.id]="htmlAttributes['input']['id']"
          [attr.form-control-name]="control['name']"
          [showWeek]="true"
          [inputStyle]="
            control.showErrors
              ? { border: 'solid 1px #f25858', width: 'calc(100% - 2.143em)' }
              : { width: 'calc(100% - 2.143em)' }
          "
          [appendTo]="appendTo"
        ></p-calendar>
        <div *ngIf="control.showErrors" class="vendium-tool-tip">
          <p
            *ngFor="let error of control.mergedErrors"
            class="form-control-feedback"
          >
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="error"></span>
          </p>
        </div>
      </div>
    </div>
  `,
})
export class WideFormCalendar2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  locale;
  value: any;
  disabled: boolean;
  ngControl: NgControl;
  onTouched: () => void;
  onChange: (_: any) => void;

  constructor(private inj: Injector, private globalService: GlobalService) {
    super();
    this.locale = this.globalService.getLocale('sv');
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value;
  }
  pushChanges(value: any): void {
    this.onChange(value);
  }

  blurEvent(): void {
    this.onBlur.emit(true);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
