<div class="p-grid">
  <div class="p-col-12">
    <h3>
      <span *ngIf="mode === 'subcontractors'">Dina underentreprenörer</span>
      <span *ngIf="mode === 'attendanceClients'">Dina beställare</span>
      <small>(Organisationsnummer måste matcha exakt)</small>
    </h3>

    <p-table [value]="data" [columns]="tableColums">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ col.header }}
            <span
              *ngIf="col.header === 'Accepterat'"
              class="help-tooltip invert"
              tooltipPosition="top"
              [pTooltip]="tooltip"
              [escape]="false"
              >?</span
            >
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns">
            <ng-container *ngIf="col.field !== 'approvedByCompanyId'">
              {{
                mode === 'attendanceClients'
                  ? rowData['company'][col.field]
                  : rowData[col.field]
              }}
            </ng-container>

            <ng-container *ngIf="col.field === 'approvedByCompanyId'">
              <span
                *ngIf="
                  mode === 'subcontractors';
                  else attendance_client_checkbox
                "
              >
                <span
                  *ngIf="rowData.approvedByCompanyId > 0; else not_approved"
                >
                  Accepterat
                </span>
                <ng-template #not_approved>Ej besvarad</ng-template>
              </span>
              <ng-template #attendance_client_checkbox>
                <p-checkbox
                  (onChange)="handleApprovedByCompanyChange(rowData)"
                  [(ngModel)]="rowData.approvedByCompanyBool"
                  [binary]="true"
                ></p-checkbox>
              </ng-template>
            </ng-container>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>
        <tr class="emptyStateTR">
          <td [attr.colspan]="columns.length">
            <app-emptyState></app-emptyState>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12" *ngIf="mode === 'attendanceClients'">
    <span class="hover-pointer" (click)="getData()"
      ><img
        style="width: 15px; vertical-align: middle; margin-right: 0.1em"
        src="images/struktur/refresh.gif"
        alt="Uppdatera beställare"
        title="Uppdatera beställare"
      /><span> Uppdatera beställare</span></span
    >
  </div>
</div>
