<div class="dialog-container full-height">
  <div class="p-grid full-width">
    <div class="p-col-12">
      <p-tabView
        [orientation]="orientation"
        class="create-project-tabview"
        [class.sticky-header]="orientation === 'left'"
        (onChange)="changeActiveTab($event); scrollToTop()"
      >
        <p-tabPanel
          header="Offertvärde"
          [selected]="activeTab === 'orderValue'"
        >
          <div class="content">
            <div class="p-grid p-g-nopad">
              <div class="p-col-12">
                <div class="p-grid">
                  <div class="p-col-12">
                    <div class="p-col-4">
                      <div class="p-col-6 p-g-nopad">
                        <appForm2-list-calendar
                          [(ngModel)]="minCreated"
                          (ngModelChange)="setGraphData()"
                          [htmlAttributes]="{
                            label: { value: 'Offerter från' }
                          }"
                        ></appForm2-list-calendar>
                      </div>
                      <div class="p-col-6 p-g-nopad" style="padding-left: 5px">
                        <appForm2-list-calendar
                          [(ngModel)]="maxCreated"
                          (ngModelChange)="setGraphData()"
                          [htmlAttributes]="{
                            label: { value: 'Offerter till' }
                          }"
                        ></appForm2-list-calendar>
                      </div>
                    </div>

                    <div class="p-col-8">
                      <div
                        class="p-col-4 p-g-nopad"
                        style="border-bottom: solid thin #ffe7ab"
                      >
                        <p-checkbox
                          [(ngModel)]="stateOne"
                          label="Väntar&nbsp;svar"
                          [binary]="true"
                          (onChange)="setGraphData()"
                        ></p-checkbox
                        ><br />
                        <span
                          pTooltip="Antal väntande offerter för valt spann"
                          style="margin-left: 5px"
                          class="pull-left"
                          >{{ stateOneCount }}st</span
                        >
                        <span
                          pTooltip="Procent av antal väntande offerter för valt spann"
                          class="pull-right"
                          >{{ stateOneCountProcent }}%</span
                        >
                      </div>
                      <div
                        class="p-col-4 p-g-nopad"
                        style="border-bottom: solid thin #90e8d7"
                      >
                        <p-checkbox
                          [(ngModel)]="stateTwo"
                          label="Accepterat"
                          [binary]="true"
                          (onChange)="setGraphData()"
                        ></p-checkbox
                        ><br />
                        <span
                          pTooltip="Antal accepterade offerter för valt spann"
                          style="margin-left: 5px"
                          class="pull-left"
                          >{{ stateTwoCount }}st</span
                        >
                        <span
                          pTooltip="Procent av antal accepterade offerter för valt spann"
                          class="pull-right"
                          >{{ stateTwoCountProcent }}%</span
                        >
                      </div>
                      <div
                        class="p-col-4 p-g-nopad"
                        style="border-bottom: solid thin #ffab99"
                      >
                        <p-checkbox
                          [(ngModel)]="stateThree"
                          label="Avböjt"
                          [binary]="true"
                          (onChange)="setGraphData()"
                        ></p-checkbox
                        ><br />
                        <span
                          pTooltip="Antal avböjda offerter för valt spann"
                          style="margin-left: 5px"
                          class="pull-left"
                          >{{ stateThreeCount }}st</span
                        >
                        <span
                          pTooltip="Procent av antal avböjda offerter för valt spann"
                          class="pull-right"
                          >{{ stateThreeCountProcent }}%</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-grid">
                  <div class="p-col-12">
                    <div
                      style="min-height: calc(70vh)"
                      [ng2-highcharts]="chartBar"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
