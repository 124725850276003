import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  InMemoryCache,
  ApolloLink,
  concat,
  DefaultOptions,
} from '@apollo/client/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
// Apollo

import { environment } from '../../../environments/environment';
import { HyperionErrorHandler } from 'app/shared/errors';

const graphQL = environment.urlPrefix + '/hyperion/GraphQl';

const apolloDefaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

@NgModule({
  imports: [ApolloModule],
  exports: [HttpClientModule],
})
export class ApolloGraphQLModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
    private errorHandler: HyperionErrorHandler
  ) {
    const http = httpLink.create({ uri: graphQL });

    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      operation.setContext({
        uri: `${graphQL}?op=${operation.operationName}`,
      });

      return forward(operation);
    });

    const logoutLink = onError((errors: ErrorResponse) => {
      const error = this.getGraphQLError(errors);
      errorHandler.handleError(error);
    });

    const cache = new InMemoryCache();

    apollo.create({
      link: logoutLink.concat(concat(authMiddleware, http)),
      cache,
      defaultOptions: apolloDefaultOptions,
    });
  }

  private getGraphQLError(errorResponse: ErrorResponse): Error {
    if (errorResponse.networkError) {
      return errorResponse.networkError;
    }
    return new GraphQLResponseError(errorResponse);
  }
}

class GraphQLResponseError extends Error {
  constructor(public errorResponse: ErrorResponse) {
    super();
  }
}

// Cache-Control: no-cache, no-store
