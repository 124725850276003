import { NgModule } from '@angular/core';
import { SubMenuComponent } from './index';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';

@NgModule({
  imports: [ButtonModule, CommonModule, TabMenuModule],
  declarations: [SubMenuComponent],
  exports: [SubMenuComponent],
  providers: [],
})
export class SubMenuModule {}
