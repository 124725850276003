<app-header *ngIf="!isDialog" [headerText]="invoiceNumber">
  <app-invoice-select
    (selectedInvoiceEvent)="goToInvoice($event)"
    [invoiceId]="invoiceId"
    [isSupplierInvoice]="this.context === 'supplierInvoice'"
  ></app-invoice-select>
</app-header>

<div *ngIf="loading || saving" class="spinner-container">
  <div class="spinner-content">
    <spinner></spinner>
    <p *ngIf="saving && hasBookkeepingIntegration">
      Exporterar till {{ bookkeepingIntegrationName }}...
    </p>
  </div>
</div>

<div
  [style.display]="loading ? 'none' : ''"
  class="page-container p-grid"
  [formGroup]="invoiceForm"
>
  <app-struktur-invoice-head
    class="p-col-12 p-grid"
    [openAllAccordions]="openAllAccordionsHead"
    [selectedProjectFrmRow]="selectedProjectFrmRow"
    formControlName="invoiceHead"
    (lastSelectedProjectId)="updateInvoiceFormProjectId($event)"
    (projectChanged)="loadProjects($event, invoiceType)"
    [projectToOpen]="invoice?.source?.projectId ?? projectIdFromDialpgRef"
    [isDialog]="isDialog"
    [betalningsvillkor]="betalningsvillkor"
    [typeInvoice]="typeInvoice"
    [type]="type"
    [invoice]="invoice"
    [rotPersons]="rotPersons"
    [customer]="customerInfo"
    (selectByDateRange)="selectByDate($event)"
  ></app-struktur-invoice-head>

  <app-struktur-invoice-body
    class="p-col-12 p-grid"
    formControlName="invoiceBody"
    (sumChanged)="changeSum($event)"
    (lastSelectedProjectId)="updateInvoiceFormProjectId($event)"
    (rowProjectToGet)="loadProjectToRow($event)"
    [mode]="mode"
    [context]="context"
    [fullInvoiceToOpen]="invoice"
    [noRot]="noRot"
    [selectByDateRange]="selectByDateRange"
  ></app-struktur-invoice-body>

  <app-struktur-invoice-footer
    class="p-col-12 p-grid"
    [sum]="sum"
    [saving]="saving"
    [type]="type"
    [invoice]="invoice"
    (saveInvoice)="actionCreate($event)"
    (closeDialog)="closeDialog()"
    [mode]="mode"
  ></app-struktur-invoice-footer>
</div>

<div class="p-col-12 p-p-0" style="color: #f00">
  {{ projectShortMessage }}
</div>
