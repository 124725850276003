<form [formGroup]="componentMainForm" class="input-no-margin">
  <div class="p-grid">
    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="name"
    ></appForm2-simple-input>

    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="personalNumber"
    ></appForm2-simple-input>

    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="nameOfProperty"
    ></appForm2-simple-input>

    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="housingAssociationOrgNumber"
    ></appForm2-simple-input>

    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="apartmentDesignation"
    ></appForm2-simple-input>

    <appForm2-simple-input
      class="p-col-12 p-lg-6"
      formControlName="customerWantedWorkAmount"
    ></appForm2-simple-input>
  </div>
</form>
