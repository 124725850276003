<div class="p-grid full-width" *ngIf="!loading">
  <div class="p-col-2">
    <form [formGroup]="componentMainForm">
      <appForm2-list-input
        formControlName="cost"
        (input)="hasTyped = true"
      ></appForm2-list-input>
    </form>
  </div>
  <div class="p-col-1"><span>kr</span></div>
  <div class="p-col-4 break-words">
    <span>{{ dataObjectFromParent.name }}</span>
  </div>
  <div class="p-col-5">
    <span>{{ dataObjectFromParent['total'] | formatNum }} kr</span>
  </div>
</div>
<div class="p-grid" *ngIf="loading">
  <div class="p-col-12">
    <spinner [inComponent]="true"></spinner>
  </div>
</div>
