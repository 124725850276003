import { gql } from 'apollo-angular';

import { GQLFragment } from '.';

// GQL FRAGMENTS

const USER_NAME_FULL = gql`
  fragment UserNameFull on User {
    id
    firstName
    lastName
  }
` as unknown as GQLFragment;

const USER_NAME_FULL_AND_COLOR = gql`
  fragment UserNameFullAndColor on User {
    id
    firstName
    lastName
    color
  }
` as unknown as GQLFragment;

const USER_DATA = gql`
  fragment UserData on User {
    id
    address
    address2
    firstName
    lastName
    email
    phone
    mobilePhone
    costTypeId
    city
    cityCode
    type
    color
    relative1name
    relative1phone
    relative2name
    relative2phone
    approvePlanCostIncrease
  }
` as unknown as GQLFragment;

/** Export GQL Fragments on User */
export const User = {
  fragments: {
    fullName: USER_NAME_FULL,
    fullNameAndColor: USER_NAME_FULL_AND_COLOR,
    data: USER_DATA,
  },
};
