import { Component, OnInit, Input } from '@angular/core';
import { AutoComplete } from 'primeng/autocomplete';
import { BehaviorSubject } from 'rxjs';

import { HttpService } from 'app/shared/http';
import { GlobalService } from 'app/shared/global';
import { ApolloQueryService } from 'app/shared/apollo';
import { ApolloMutationService } from 'app/shared/apollo';
import { AddSubcontractorComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor/add-subcontractor.component';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { AddSubcontractorToProjectComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-to-project/add-subcontractor-to-project.component';
import { AddSubcontractorMailComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-mail/add-subcontractor-mail.component';

@Component({
  selector: 'app-share-project-company',
  templateUrl: './share-project-company.component.html',
  styleUrls: ['../project-share-row.component.scss'],
  providers: [AppDialogService],
})
export class ShareProjectCompanyComponent implements OnInit {
  @Input() projectInfo;

  autoCompleteResults = new BehaviorSubject([]);
  autoCompleteQuery: string;
  subcontractorsRelations = [];

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService,
    private dialogService: AppDialogService,
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService
  ) {}

  ngOnInit() {
    this.projectSubcontractorsRelations();
  }

  projectSubcontractorsRelations() {
    const variables = {
      multiId: [this.projectInfo.id],
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('projectSubcontractorsRelations', variables)
      .subscribe(ret => {
        const data = ret.data;
        const sub = ret.sub;

        this.subcontractorsRelations = [];
        this.apolloQueryService
          .cleanFromNode(data.company.projects)
          .map(project => {
            this.apolloQueryService
              .cleanFromNode(project['subcontractorsRelations'])
              .map(rel => {
                const retval = rel.subcontractor;
                retval['id'] = rel.id;

                retval['copied'] = +rel.copied === 1;
                retval['mainContractorWhantsTimereports'] =
                  +rel.mainContractorWhantsTimereports === 1;
                retval['mainContractorWhantsToPutTodos'] =
                  +rel.mainContractorWhantsToPutTodos === 1;
                retval['clientSharesTimereports'] =
                  +rel.clientSharesTimereports === 1;
                retval['clientAcceptTodos'] = +rel.clientAcceptTodos === 1;

                this.subcontractorsRelations.push(retval);
              });
          });

        sub.unsubscribe();
      });
  }

  updateSubcontractorRelation(subcontractorsRelation) {
    const dataToMutate = {
      id: subcontractorsRelation['id'],
      mainContractorWhantsTimereports: subcontractorsRelation[
        'mainContractorWhantsTimereports'
      ]
        ? 1
        : 0,
      mainContractorWhantsToPutTodos: subcontractorsRelation[
        'mainContractorWhantsToPutTodos'
      ]
        ? 1
        : 0,
    };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'subcontractorRelationToProject',
        'update',
        false,
        dataToMutate
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  searchSubcontractor(autoCompleteElement?: AutoComplete, event?) {
    const query = event ? event.query : null;
    let url = this.globalService.getUrlPrefix();
    url += query
      ? '/subcontractor/AutoComplete?q=' + query
      : '/subcontractor/AutoComplete?q=';

    this.httpService.makeHttpGetRequest(url).then(({ data }) => {
      !event && data.length === 0 && data.push({ id: null });

      this.autoCompleteResults.next(data);
      !event && autoCompleteElement.show();
    });
  }

  openAddSubcontractorDialog() {
    this.dialogService
      .openComponent(AddSubcontractorComponent)
      .onClose.subscribe(res => {
        res && this.openAddSubcontractorToProjectDialog(res);
      });
  }

  openAddSubcontractorToProjectDialog(subcontractorData) {
    const projectData = this.projectInfo;
    projectData['label'] = projectData.trueId + ', ' + projectData.mark;

    projectData['attendance'] = 0;
    projectData['clientProject'] = 1;

    this.autoCompleteQuery = null;

    this.dialogService.data = {
      subcontractorInfo: subcontractorData,
      projectInfo: projectData,
      parentId: null,
    };

    this.dialogService
      .openComponent(AddSubcontractorToProjectComponent)
      .onClose.subscribe(res => {
        if (res) {
          this.projectSubcontractorsRelations();
          this.dialogService.data = { subcontractorInfo: subcontractorData };
          this.dialogService.openComponent(AddSubcontractorMailComponent);
        }
      });
  }
}
