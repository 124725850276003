import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Subscription, BehaviorSubject } from 'rxjs';

import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../../shared/apollo/index';
import { MessageService } from '../../../shared/message/index';
import { HelperService } from '../../../shared/helpers/index';
import { QuantityUnits } from 'app/shared/quantity-units/quantity-units';

@Component({
  selector: 'app-company-products-list',
  templateUrl: 'company-products-list.component.html',
})
export class CompanyProductsListComponent
  implements OnDestroy, OnInit, OnChanges
{
  @Input() public companyCostTypes: { [x: string]: any };

  private readonly dataModel = 'product';
  public dataSet: any[];
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public companyCostTypeNamesObject = {};

  public units = [...QuantityUnits];
  public productSearchModel = '';
  public productSearchLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public doneSearch: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public totalCount: number;
  private activeSub: Subscription;
  private variables = {
    last: 50,
    offset: 0,
    filterParam: '',
  };
  public tableColums: {
    field: string;
    header: string;
    style: { [key: string]: any };
    inputType: string;
  }[];
  public unitsTypes = {};

  constructor(
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.setUnitTypes();

    this.constructCostTypeNamesObject();

    this.getProducts();

    this.setTableColumns();
  }

  private setUnitTypes(): void {
    this.units.forEach(unit => {
      this.unitsTypes[unit['value']] = unit['label'];
    });
  }

  private setTableColumns(): void {
    this.tableColums = [
      {
        field: 'companyCostTypeId',
        header: 'Typ',
        style: { overflow: 'visible', width: '15%' },
        inputType: null,
      },
      {
        field: 'artnr',
        header: 'Artnr',
        style: { width: '15%' },
        inputType: 'input',
      },
      {
        field: 'benamning',
        header: 'Benämning',
        style: { width: '25%' },
        inputType: 'input',
      },
      {
        field: 'enhet',
        header: 'Enhet',
        style: { width: '10%' },
        inputType: null,
      },
      {
        field: 'avtalspris',
        header: 'Förs. pris',
        style: { width: '7.5%' },
        inputType: 'input',
      },
      {
        field: 'avtalsprisCost',
        header: 'In. pris',
        style: { width: '7.5%' },
        inputType: 'input',
      },
      {
        field: 'leverantor',
        header: 'Leverantör',
        style: { width: '10%' },
        inputType: 'input',
      },
      {
        field: 'top',
        header: 'Topp',
        style: { width: '5%' },
        inputType: null,
      },
      { field: 'id', header: '', style: { width: '5%' }, inputType: null },
    ];
  }

  public ngOnDestroy(): void {
    if (this.activeSub) {
      this.activeSub.unsubscribe();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyCostTypes) {
      this.constructCostTypeNamesObject();
    }
  }

  public onEditComplete(column: any, data: any): void {
    const rowData = data;
    const dataToMutation = this.constructDataToMutation(rowData);
    this.actionUpdate(dataToMutation);
  }

  public onEditCancel(event: any): void {
    this.messageService.insertData({
      textArray: ['Redigering av produkten avbröts'],
      type: 'warn',
      summary: 'Redigering avbröts',
    });
  }

  public topBoolChange(
    event: { checked: any },
    rowData: { [x: string]: any }
  ): void {
    const dataToMutation = { id: Number(rowData['id']) };
    let topValue = 0;
    if (event.checked) {
      topValue = 1;
    }
    dataToMutation['top'] = topValue;
    this.actionUpdate(dataToMutation);
  }

  private constructDataToMutation(object: any): any {
    const dataToMutation = { ...object };
    dataToMutation['id'] = Number(dataToMutation['id']);
    delete dataToMutation['top'];
    delete dataToMutation['__typename'];

    return dataToMutation;
  }

  public rowAction(product: any, crudType: string): void {
    const dataToMutation = this.constructDataToMutation(product);
    if (crudType === 'update') {
      this.actionUpdate(dataToMutation);
    } else {
      this.confirmActionDelete(dataToMutation);
    }
  }

  private actionUpdate(dataToMutationParam: { id: number }): void {
    const crudType = 'update';
    const dataToMutation = dataToMutationParam;

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  private confirmActionDelete(dataToMutationParam: any): void {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort produkten?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete(dataToMutationParam);
      },
    });
  }

  private actionDelete(dataToMutationParam: any): void {
    const crudType = 'delete';
    const dataToMutation = { id: Number(dataToMutationParam['id']) };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            this.spliceDeleted(dataToMutationParam['id']);
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  private spliceDeleted(deletedId: any): void {
    const dataSet = [...this.dataSet];

    const newDataSet = dataSet.filter(
      o => Number(o['id']) !== Number(deletedId)
    );
    this.dataSet = newDataSet;

    this.cdr.detectChanges();
  }

  public clearSearch(): void {
    this.doneSearch.next(false);
    this.productSearchModel = '';
    this.searchForProducts();
  }

  public searchForProductsIfAllowed(): void {
    const data = this.productSearchLoading.value;
    if (!data) {
      this.searchForProducts();
    }
  }

  public searchForProducts(): void {
    this.productSearchLoading.next(true);
    this.loading.next(true);

    this.variables['offset'] = 0;
    this.variables['filterParam'] = this.productSearchModel;

    if (this.activeSub) {
      this.activeSub.unsubscribe();
    }
    this.getProducts();
  }

  public paginate(event: { rows: any; first: any }): void {
    this.variables = {
      last: event.rows,
      offset: event.first,
      filterParam: this.productSearchModel,
    };

    this.loading.next(true);
    if (this.activeSub) {
      this.activeSub.unsubscribe();
    }
    this.getProducts();
  }

  // This function kinda sucks, but if rewriting resulted in some weirdness
  private constructCostTypeNamesObject(): void {
    for (const costTypeIndex in this.companyCostTypes) {
      const costType = this.companyCostTypes[costTypeIndex];
      this.companyCostTypeNamesObject[costType['value']] = costType['label'];
    }
  }

  public refetchData(): void {
    this.getProducts();
  }

  private getProducts(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyProducts', this.variables)
      .subscribe(res => {
        this.activeSub = res.sub;
        const dataSet = this.helperService.cleanFromNode(
          res['data'].company.products
        );

        this.dataSet = dataSet.map(set => {
          const unit =
            this.units.find(obj => obj.value === set['enhet']) ||
            this.units.find(obj => obj.value === 'st');
          return { ...set, enhet: unit['value'], top: set.top === 1 };
        });

        this.totalCount = res['data'].company.productsCount;
        if (this.variables['filterParam'] !== '') {
          this.doneSearch.next(true);
          this.loading.next(false);
          this.productSearchLoading.next(false);
        }
        this.loading.next(false);
      });
  }
}
