<p-autoComplete
  placeholder="Sök"
  minLength="2"
  [suggestions]="contacts"
  (completeMethod)="search($event)"
  (onFocus)="focus(autocomplete)"
  (onSelect)="select($event)"
  [(ngModel)]="selectedContactWithLabel"
  [disabled]="disabled"
  minLength="2"
  delay="300"
  field="label"
  #autocomplete
>
  <ng-template let-contact pTemplate="item">
    <div>
      <div class="p-grid" *ngIf="contact.trueId">
        <div class="p-col-3">Id:</div>
        <div class="p-col p-text-truncate">{{ contact.trueId }}</div>
      </div>

      <div class="p-grid" *ngIf="contact.name">
        <div class="p-col-3">Namn:</div>
        <div class="p-col p-text-truncate">{{ contact.name }}</div>
      </div>

      <div class="p-grid" *ngIf="contact.orderBuisnessName">
        <div class="p-col-3">Företag:</div>
        <div class="p-col p-text-truncate">
          {{ contact.orderBuisnessName }}
        </div>
      </div>

      <div class="p-grid" *ngIf="contact.address">
        <div class="p-col-3">Adress:</div>
        <div class="p-col p-text-truncate">{{ contact.address }}</div>
      </div>

      <div class="p-grid" *ngIf="contact.city">
        <div class="p-col-3">Ort:</div>
        <div class="p-col p-text-truncate">{{ contact.city }}</div>
      </div>

      <div class="p-grid" *ngIf="contact.cityCode">
        <div class="p-col-3">Postnr:</div>
        <div class="p-col p-text-truncate">{{ contact.cityCode }}</div>
      </div>
    </div>
  </ng-template>
</p-autoComplete>
