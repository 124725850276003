import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import '@struqtur/dhtmlx-gantt';
import { Gantt, GanttStatic } from '@struqtur/dhtmlx-gantt';
import {
  EventColorsEnum,
  EventTextColorEnum,
  EventTypeEnum,
  ExportPaperSizeEnum,
  GanttSizes,
  MapTypeToDropdown,
  ProjectStatusEnum,
} from 'app/planner/planner-module-enums';
import {
  ContextMenuEvent,
  ExportFileFormat,
  ExportProjectPlannerEvent,
  GanttData,
  LightboxDropdown,
  SchedulerEvent,
  SchedulerEventPlannedTime,
  SchedulerEventProject,
  SchedulerEventWorkTask,
  UpdateColorEvent,
} from 'app/planner/planner-module-interfaces';
import { PlannerDataAdapterService } from 'app/planner/services/planner-data-adapter.service';
import {
  PlannerQueryVars,
  Project,
  ProjectTodo,
  TodoTopic,
  User,
  UserPlannedWork,
} from 'app/planner/services/planner-query-types';
import { PlannerReadService } from 'app/planner/services/planner-read.service';
import { PlannerWriteService } from 'app/planner/services/planner-write.service';
import { GlobalService } from 'app/shared/global';
import * as moment from 'moment';
import { forkJoin, Observable, Subject } from 'rxjs';
import { first, take, map, debounceTime } from 'rxjs/operators';
import {
  MessageService,
  ToastMessage,
  ToastMessageSeverityType,
} from 'app/shared/message';
import { Dropdown } from 'primeng/dropdown';
import { DateService } from 'app/shared/helpers/date.service';
import { SelectItem, TreeNode } from 'primeng/api';
import {
  calculateGanttColumnWidth,
  getTemplateClassByDate,
} from 'app/planner/utils/gantt';
import '../../dhtmlx/export-api.js';
import '../../dhtmlx/dhtmlxgantt_fullscreen.js';
import {
  UpdateProjectDateSpanGQL,
  UpdateTodoDateSpanGQL,
} from './graphql/gantt.generated';
import { PlannerExportSettingsService } from 'app/planner/services/planner-export-settings.service';
import { ProjectTypesGQL } from 'app/project/project-list/graphql/projectTypes.generated';
import cloneDeep from 'lodash.clonedeep';
import {
  GetStatesGQL,
  CreatePlanningStateGQL,
  UpdatePlanningStateGQL,
  PlanningStatesFragment,
  DeletePlanningStateGQL,
} from './graphql/planningStates.generated';
import { UserFlagsService } from 'app/user-flags.service';

enum PaperSizeInMm {
  A4_WIDTH = 210,
  A4_HEIGHT = 297,
  A3_WIDTH = 297,
  A3_HEIGHT = 420,
}

@Component({
  encapsulation: ViewEncapsulation.Emulated,
  selector: 'app-gantt-view',
  templateUrl: './gantt-view.component.html',
  styleUrls: ['./gantt-view.component.scss'],
})
export class GanttViewComponent implements OnInit, OnDestroy {
  @ViewChild('gantt', { static: true }) private ganttRef: ElementRef;
  @ViewChild('customLightBox', { static: true })
  private customLightBox: ElementRef;
  @ViewChild('filterProjects', { static: true })
  private filterProjectsDropdown: Dropdown;

  private gantt: GanttStatic;
  public currentTask: SchedulerEventWorkTask;

  private isViewResolved = false;
  private isViewResolvedSubject: Subject<boolean> = new Subject<boolean>();
  private ganttData: GanttData | null = null;
  private ganttEvents: SchedulerEvent[];
  private projects: Project[];
  private coworkers: User[];
  private splitTasks: SchedulerEvent[];

  public ganttEventPlannedTime: SchedulerEvent;
  public isNewEvent: boolean;
  public allTodos: {
    projectId: number;
    todos: ProjectTodo[];
  }[] = [];
  public projectsData: Project[] = [];
  public coworkersDropdown: LightboxDropdown[] = [];
  public todotopicsDropdown: LightboxDropdown[] = [];
  public zoomLevelDropdown: SelectItem[] = [];
  public filterDropdown: TreeNode<number>[] = [];
  public datePickerLocale: any;
  public filterForm: FormGroup;
  public isLoading = false;
  public colorContextMenuTitle = 'Välj färg';
  public contextMenuEventSubject: Subject<ContextMenuEvent> =
    new Subject<ContextMenuEvent>();
  public isExporting = false;
  public disabledExportTooltip =
    'Export och utskrift av vyn för projektplanering kommer inom kort.';
  public childrenEdgeDates: { start: string; end: string } = null;

  public EventTypeEnum = EventTypeEnum;

  private readonly DISABLED_TASK_COLOR = '#00000040';

  public statesDropdown: SelectItem<number>[] = [];
  public states: PlanningStatesFragment[];
  public isAdmin: boolean;
  public showResetButton = false;

  constructor(
    private plannerReadService: PlannerReadService,
    private plannerWriteService: PlannerWriteService,
    private plannerDataAdapterService: PlannerDataAdapterService,
    private messageService: MessageService,
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private plannerExportService: PlannerExportSettingsService,
    private projectTypesGQL: ProjectTypesGQL,
    private updateProjectDateSpanGQL: UpdateProjectDateSpanGQL,
    private updateTodoDateSpanGQL: UpdateTodoDateSpanGQL,
    private getStatesGQL: GetStatesGQL,
    private createPlanningStateGQL: CreatePlanningStateGQL,
    private updatePlanningStateGQL: UpdatePlanningStateGQL,
    private deletePlanningStateGQL: DeletePlanningStateGQL,
    private userFlagsService: UserFlagsService
  ) {
    this.datePickerLocale = this.globalService.getLocale('sv');

    this.isViewResolvedSubject.subscribe(() => {
      if (this.ganttData && this.isViewResolved) {
        this.loadFilteredGanttData();

        this.isViewResolved = false;
        this.isLoading = false;
      }
    });

    const firstOfLastMonth = moment().subtract(1, 'month').set('date', 1);

    this.filterForm = this.formBuilder.group({
      startDate: firstOfLastMonth.toDate(),
      endDate: firstOfLastMonth.add(7, 'month').subtract(1, 'day').toDate(),
      projects: null,
      selectedZoomLevel: 'week',
      filter: null,
      selectedState: null,
    });
  }

  public ngOnInit(): void {
    this.isLoading = true;

    this.initGantt();

    this.getScheduledProjects(this.calculateDataQueryDateSpan());
    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
    this.getCompanyWorkers();
    this.getCompanyTodotopics();

    this.setFilterDropdown();

    this.isViewResolvedSubject.next((this.isViewResolved = true));
    this.getStates();
    this.userFlagsService
      .getFlags()
      .subscribe(flags => (this.isAdmin = flags.isAdmin));
  }

  private getStates(): void {
    this.getStatesGQL
      .fetch()
      .pipe(
        first(),
        map(res => res.data.company.planningStates.edges.map(e => e.node))
      )
      .subscribe(states => {
        this.statesDropdown = [
          { label: 'Skapa nytt läge', value: null },
          ...states.map(s => ({
            label: s.name,
            value: Number(s.id),
          })),
        ];
        this.states = states;
        const selectedState = this.getSelectedState();
        if (this.statesDropdown.find(sd => sd.value === selectedState)) {
          this.filterForm.get('selectedState').setValue(selectedState);
          this.applyState(selectedState);
        }
      });
  }

  private applyState(stateId: number): void {
    const stateString = this.states.find(
      s => Number(s.id) === stateId || (stateId === null && s.id === null)
    )?.state;

    if (!stateString) {
      return;
    }

    const state = JSON.parse(stateString);

    this.filterForm.get('selectedZoomLevel').setValue(state.selectedZoomLevel);
    this.filterForm.get('projects').setValue(state.projects);
    const newFilter = [
      ...this.filterDropdown,
      ...this.filterDropdown.map(n => n.children).flat(),
    ].filter(
      tn =>
        state.filter.findIndex(
          f => tn.data === f.data && tn.type === f.type
        ) !== -1
    );
    this.filterForm.get('filter').setValue(newFilter);
  }

  private getCurrentState() {
    return {
      projects: this.filterForm.get('projects').value,
      selectedZoomLevel: this.filterForm.get('selectedZoomLevel').value,
      filter: this.filterForm.get('filter').value.map((tn: TreeNode) => ({
        label: tn.label,
        data: tn.data,
        type: tn.type,
      })),
    };
  }

  public createState(): void {
    const name = this.filterForm.get('selectedState').value;
    const state = this.getCurrentState();

    this.createPlanningStateGQL
      .mutate({
        name: name,
        state: JSON.stringify(state),
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.companyPlanningStateTypeHyperionMutation
        );

        this.saveSelectedState(
          Number(res.data.companyPlanningStateTypeHyperionMutation.id)
        );
        this.getStates();
      });
  }

  public updateState(): void {
    const id = this.filterForm.get('selectedState').value;
    const state = this.getCurrentState();
    this.updatePlanningStateGQL
      .mutate({
        id: id,
        state: JSON.stringify(state),
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.companyPlanningStateTypeHyperionMutation
        );
        this.getStates();
      });
  }

  public deleteState(id: number): void {
    this.deletePlanningStateGQL
      .mutate({
        id: id,
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.companyPlanningStateTypeHyperionMutation
        );
        this.getStates();
      });
  }

  private setFilterDropdown(): void {
    this.projectTypesGQL
      .fetch()
      .pipe(
        first(),
        map(r => r.data.company.projectTypes.edges.map(e => e.node))
      )
      .subscribe(projectTypes => {
        this.filterDropdown = [
          {
            label: 'Projektstatus',
            expanded: true,
            data: -1,
            children: [
              {
                label: 'Planerade',
                data: ProjectStatusEnum.Planned,
                type: 'status',
              },
              {
                label: 'Pågående',
                data: ProjectStatusEnum.Ongoing,
                type: 'status',
              },
              {
                label: 'Frånvaro',
                data: ProjectStatusEnum.Leave,
                type: 'status',
              },
            ],
          },
          {
            label: 'Poster att inkludera',
            expanded: true,
            data: -2,
            children: [
              {
                label: 'Arbetsmoment',
                data: EventTypeEnum.WorkTask,
                type: 'eventType',
              },
              {
                label: 'Schemalagd tid',
                data: EventTypeEnum.PlannedTime,
                type: 'eventType',
              },
            ],
          },
          {
            label: 'Projekttyper att inkludera',
            expanded: true,
            data: -3,
            children: [
              { label: 'Ingen typ', data: null, type: 'projectType' },
              ...projectTypes.map(pt => ({
                label: `${pt.prefix}:${pt.name}`,
                data: Number(pt.id),
                type: 'projectType',
              })),
            ],
          },
        ];

        this.filterForm
          .get('filter')
          .setValue([
            ...this.filterDropdown,
            ...this.filterDropdown.map(n => n.children).flat(),
          ]);
      });
  }
  public resetState(): void {
    this.filterForm.get('selectedState').setValue(null);

    this.filterForm.patchValue({
      projects: null,
      selectedZoomLevel: 'week',
      filter: null,
      selectedState: null,
    });
    this.filterForm
      .get('filter')
      .setValue([
        ...this.filterDropdown,
        ...this.filterDropdown.map(n => n.children).flat(),
      ]);
  }

  private getScheduledProjects(queryVars: PlannerQueryVars): void {
    forkJoin([
      this.plannerReadService.getProjects(queryVars).pipe(take(1)),
      this.plannerReadService
        .getProjectsInternalWithPlannedTime(queryVars)
        .pipe(take(1)),
    ]).subscribe(([projects, projectsInternal]) => {
      this.projects = [
        ...this.sortProjectsByTrueIdWithSubProjects(projects),
        ...projectsInternal,
      ];

      this.ganttData = { data: [], links: [] };
      this.ganttEvents =
        this.plannerDataAdapterService.mapDataWithInternalProjectsToGanttEvents(
          this.sortProjectsByTrueIdWithSubProjects(projects),
          projectsInternal,
          this.gantt.uid
        );

      this.splitTasks = this.ganttEvents.filter(
        event => event.render === 'split'
      );

      this.filterProjectsDropdown.options =
        this.plannerDataAdapterService.mapTypeToDropdownList(
          [...projects, ...projectsInternal],
          MapTypeToDropdown.Projects
        );
      this.ganttData.data = this.ganttEvents;
      this.isViewResolved = true;
      this.isViewResolvedSubject.next(this.isViewResolved);
    });
  }

  private sortProjectsByTrueIdWithSubProjects(projects: Project[]): Project[] {
    return projects.sort((a, b) => {
      if (!a.trueId || !b.trueId) {
        return (!a.trueId ? 1 : 0) - (!b.trueId ? 1 : 0);
      }

      const [baseA, suffixA] = a.trueId.split('-');
      const [baseB, suffixB] = b.trueId.split('-');

      const numBaseA = Number(baseA);
      const numBaseB = Number(baseB);

      if (numBaseA !== numBaseB) {
        return numBaseB - numBaseA;
      }

      return (suffixA || '').localeCompare(suffixB || '');
    });
  }

  private getAllCompanyProjects(queryVars: PlannerQueryVars): void {
    this.plannerReadService
      .getProjects(queryVars)
      .pipe(take(1))
      .subscribe(projects => {
        projects.map(project => {
          if (!project.todos.length) {
            return;
          }

          const existingTodoIndex = this.allTodos.findIndex(
            existingTodo => +existingTodo.projectId === +project.id
          );

          if (existingTodoIndex >= 0) {
            this.allTodos[existingTodoIndex] = {
              projectId: project.id,
              todos: project.todos,
            };
          } else {
            this.allTodos.push({
              projectId: project.id,
              todos: project.todos,
            });
          }
        });
        this.ganttEvents =
          this.plannerDataAdapterService.mapDataToGanttEvent(projects);

        this.projectsData = projects;
        this.isViewResolved = true;
        this.isViewResolvedSubject.next(this.isViewResolved);
      });
  }

  private getCompanyWorkers(): void {
    this.plannerReadService
      .getUsers()
      .pipe(take(1))
      .subscribe(users => {
        this.coworkers = users;
        this.coworkersDropdown =
          this.plannerDataAdapterService.mapTypeToDropdownList<User>(
            users,
            MapTypeToDropdown.Coworkers
          );
        this.isViewResolvedSubject.next(this.isViewResolved);
      });
  }

  private getCompanyTodotopics(): void {
    this.plannerReadService
      .getTodotopics()
      .pipe(take(1))
      .subscribe(topics => {
        this.todotopicsDropdown =
          this.plannerDataAdapterService.mapTypeToDropdownList<TodoTopic>(
            topics,
            MapTypeToDropdown.Todotopics
          );
      });
  }

  private initGantt(): void {
    this.gantt = Gantt.getGanttInstance();
    this.initGanttConfig();
    this.initGanttZoom();
    this.initGanttTemplates();
    this.initGanttEvents();

    this.gantt.init(this.ganttRef.nativeElement);
    this.filterForm
      .get('startDate')
      .valueChanges.subscribe({ next: this.handleDateChange });
    this.filterForm
      .get('endDate')
      .valueChanges.subscribe({ next: this.handleDateChange });
    this.filterForm
      .get('projects')
      .valueChanges.subscribe({ next: this.loadFilteredGanttData });
    this.filterForm
      .get('filter')
      .valueChanges.subscribe({ next: this.loadFilteredGanttData });
    this.filterForm
      .get('selectedZoomLevel')
      .valueChanges.subscribe({ next: this.handleChangeZoomLevel });
    this.filterForm.get('selectedState').valueChanges.subscribe({
      next: value => {
        this.saveSelectedState(value);
        this.applyState(value);
      },
    });
    this.filterForm
      .get('selectedState')
      .valueChanges.pipe(debounceTime(100))
      .subscribe({
        next: value => {
          this.saveSelectedState(value);
          this.applyState(value);
        },
      });

    this.filterForm.valueChanges.subscribe(value => {
      if (value.selectedZoomLevel !== 'week') {
        this.showResetButton = true;
        return;
      }
      if (value.projects?.length > 0) {
        this.showResetButton = true;
        return;
      }
      if (
        value.filter?.length !==
        [
          ...this.filterDropdown,
          ...this.filterDropdown.map(n => n.children).flat(),
        ].length
      ) {
        this.showResetButton = true;
        return;
      }
      this.showResetButton = false;
    });
  }

  private handleChangeZoomLevel = (zoomLevel: string): void => {
    this.gantt.ext.zoom.setLevel(zoomLevel);
  };

  private handleDateChange = (): void => {
    this.gantt.clearAll();
    this.isViewResolved = true;
    this.getScheduledProjects(this.calculateDataQueryDateSpan());
    this.plannerExportService.deleteExportSettingsLocalStorage();
  };

  private loadFilteredGanttData = (): void => {
    this.gantt.clearAll();
    this.plannerExportService.deleteExportSettingsLocalStorage();

    const filteredProjects = this.filterForm.get('projects').value;
    const filters: TreeNode[] = this.filterForm.get('filter').value;

    const filteredStatuses = filters
      ?.filter(f => f.type === 'status')
      .map(f => f.data);

    const filteredPostTypes =
      filters?.filter(f => f.type === 'eventType').map(f => f.data) ?? [];

    const filteredProjectTypes = filters
      ?.filter(f => f.type === 'projectType')
      .map(f => f.data);

    const filteredData = cloneDeep(this.ganttData);

    if (filteredProjects && filteredProjects.length) {
      filteredData.data = filteredData.data.filter((event: SchedulerEvent) => {
        return (
          filteredProjects.map(project => +project.value).includes(+event.id) ||
          filteredProjects
            .map(project => +project.value)
            .includes(+event.projectId)
        );
      });
    }

    filteredData.data = filteredData.data.filter((event: SchedulerEvent) => {
      const project = this.projects.find(
        p =>
          Number(p.id) === Number(event.projectId) ||
          Number(p.id) === Number(event.id)
      );

      return filteredStatuses?.includes(project.status);
    });

    filteredPostTypes.push(EventTypeEnum.Project);
    if (filteredPostTypes.includes(EventTypeEnum.PlannedTime)) {
      filteredPostTypes.push(EventTypeEnum.Placeholder);
    }
    if (filteredPostTypes.includes)
      filteredData.data = filteredData.data.filter((event: SchedulerEvent) => {
        return filteredPostTypes?.includes(event.eventType);
      });

    filteredData.data = filteredData.data.filter((event: SchedulerEvent) => {
      const project = this.projects.find(
        p =>
          Number(p.id) === Number(event.projectId) ||
          Number(p.id) === Number(event.id)
      );

      return filteredProjectTypes?.includes(project.typeId);
    });

    this.gantt.parse(filteredData);

    this.gantt.addMarker({
      start_date: new Date(),
    });
  };

  private initGanttConfig(): void {
    this.gantt.i18n.setLocale('sv');

    this.gantt.plugins({
      tooltip: true,
      marker: true,
    });

    this.gantt.config = {
      ...this.gantt.config,
      date_format: '%Y-%m-%d %H:%i',
      xml_date: '%Y-%m-%d %H:%i',
      open_tree_initially: true,
      columns: [
        {
          name: 'text',
          label: 'Projekt',
          width: GanttSizes.Sidebar,
          tree: true,
          template: obj => {
            const user = this.coworkersDropdown.find(
              c => +c.value === +obj.userId
            );
            return obj.eventType === EventTypeEnum.PlannedTime
              ? user?.label
              : obj.text;
          },
        },
        {
          name: 'projectStartDate',
          label: 'Start',
          width: GanttSizes.StartDateColumn,
          template: obj => obj.start_date,
        },
      ],
      scale_height: 70,
      drag_links: false,
      order_branch: 'marker',
      drag_progress: false,
      open_split_tasks: true,
      round_dnd_dates: false,
    };
  }

  private initGanttZoom(): void {
    const zoomConfig = {
      levels: [
        {
          name: 'day',
          label: 'Dag',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(1.5),
          scales: [
            {
              unit: 'day',
              step: 1,
              format: '%Y - v%W',
              css: date => getTemplateClassByDate(date, 'day'),
            },
            {
              unit: 'day',
              step: 1,
              format: '%d %M',
              css: date => getTemplateClassByDate(date, 'day'),
            },
          ],
        },
        {
          name: 'week',
          label: 'Vecka',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(7.5),
          scales: [
            {
              unit: 'week',
              step: 1,
              format: '%Y - v%W',
            },
            {
              unit: 'day',
              step: 1,
              format: '%d %M',
              css: date => getTemplateClassByDate(date, 'day'),
            },
          ],
        },
        {
          name: 'month',
          label: 'Månad',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(4 * 1),
          scales: [
            { unit: 'quarter', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%F',
              css: date => getTemplateClassByDate(date, 'month'),
            },
            {
              unit: 'week',
              step: 1,
              format: 'v%W',
            },
          ],
        },
        {
          name: 'quarter',
          label: 'Kvartal',
          height: 70,
          min_column_width: calculateGanttColumnWidth(4 * 3),
          scales: [
            { unit: 'quarter', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%F',
              css: date => getTemplateClassByDate(date, 'month'),
            },
            {
              unit: 'week',
              step: 1,
              format: 'v%W',
            },
          ],
        },
        {
          name: 'year',
          label: 'År',
          scale_height: 70,
          min_column_width: calculateGanttColumnWidth(12.5),
          scales: [
            { unit: 'year', step: 1, format: '%Y' },
            {
              unit: 'month',
              step: 1,
              format: '%M',
              css: date => getTemplateClassByDate(date, 'month'),
            },
          ],
        },
      ],
      useKey: 'ctrlKey',
      trigger: 'wheel',
      element: () => {
        return (this.gantt as any).$root.querySelector('.gantt_task');
      },
    };

    this.zoomLevelDropdown = zoomConfig.levels.map(level => ({
      label: level.label,
      value: level.name,
    }));

    this.gantt.ext.zoom.init(zoomConfig);
    this.gantt.ext.zoom.setLevel('week');
  }

  private initGanttTemplates(): void {
    this.gantt.templates.format_date = (date: Date) =>
      this.gantt.date.date_to_str('%Y-%m-%d %H:%i')(date);
    this.gantt.templates.xml_format = (date: Date) =>
      this.gantt.date.date_to_str(this.gantt.config.xml_date)(date);

    this.gantt.templates.task_text = this.setTaskTemplateText;
    this.gantt.templates.tooltip_text = this.setTooltipTemplate;
    this.gantt.templates.timeline_cell_class = (item, date) =>
      getTemplateClassByDate(date, 'day');
    this.initLightBoxTemplate();

    this.gantt.templates.task_class = (start, end, task) => {
      const children = this.gantt.getChildren(task.id);
      if (task.eventType === EventTypeEnum.Placeholder && children.length) {
        return 'hidden-task';
      }
      return '';
    };

    this.gantt.templates.scale_cell_class = date =>
      getTemplateClassByDate(date, this.getCurrentZoomLevelName());
    this.gantt.templates.timeline_cell_class = (item, date) =>
      getTemplateClassByDate(date, this.getCurrentZoomLevelName());
  }

  private initGanttEvents(): void {
    this.gantt.attachEvent('onTaskClick', this.handleClickEvent, null);
    this.gantt.attachEvent('onTaskDblClick', () => false, null);
    this.gantt.attachEvent(
      'onBeforeTaskDrag',
      this.handleWorkTaskBeforeDragEvent,
      null
    );
    this.gantt.attachEvent('onTaskDrag', this.handleWorkTaskDragEvent, null);
    this.gantt.attachEvent(
      'onAfterTaskDrag',
      this.handleWorkTaskAfterDragEvent,
      null
    );
    this.gantt.attachEvent('onContextMenu', this.handleContextMenuEvent, null);
    this.gantt.attachEvent('onBeforeRowDragEnd', this.handleRowDrag, null);
    this.gantt.attachEvent('onRowDragEnd', this.handleRowDragEnd, null);
    this.gantt.ext.zoom.attachEvent('onAfterZoom', (level, config) => {
      this.initGanttTemplates();
      this.gantt.render();
    });
    this.gantt.attachEvent(
      'onTaskOpened',
      id => {
        const task = this.gantt.getTask(id);
        if (this.gantt.isSplitTask(task)) {
          task.render = '';
          this.gantt.render();
        }
      },
      null
    );
    this.gantt.attachEvent(
      'onTaskClosed',
      id => {
        const task = this.gantt.getTask(id);
        const split = this.splitTasks.find(t => +t.id === +id);
        if (task.eventType === EventTypeEnum.Placeholder && split) {
          task.render = 'split';
          this.gantt.render();
        }
      },
      null
    );
  }

  private initLightBoxTemplate(): void {
    this.gantt.showLightbox = id => {
      this.ganttEventPlannedTime = this.gantt.getTask(id);
      this.customLightBox.nativeElement.style.display = 'block';
    };
  }

  private getCurrentZoomLevelName(): string {
    return this.gantt.ext.zoom.getLevels()[
      this.gantt.ext.zoom.getCurrentLevel()
    ].name;
  }

  private setTaskTemplateText = (
    start: Date,
    end: Date,
    task: SchedulerEventPlannedTime | SchedulerEventWorkTask
  ): string => {
    if (task.eventType === EventTypeEnum.PlannedTime) {
      const project = this.projects.find(x => +x.id === +task.projectId);
      const coworker = this.coworkersDropdown.find(
        x => +x.value === +(task as SchedulerEventPlannedTime).userId
      );
      return project.trueId + ' - ' + coworker?.label.toString();
    } else if (task.eventType === EventTypeEnum.WorkTask) {
      const topic = this.todotopicsDropdown.find(
        t => +t.value === +(task as SchedulerEventWorkTask).headerId
      );
      const doneIcon = (isDone: number) =>
        isDone ? '<i class="pi pi-check-circle"></i> ' : '';

      return (
        doneIcon((task as SchedulerEventWorkTask).done) +
        topic?.label +
        ' - ' +
        task.text
      );
    }
    return task.text;
  };

  private createTaskPlaceholderParent(task: UserPlannedWork): number {
    task.user = this.coworkers.find(coworker => +coworker.id === +task.userId);

    const placeholder =
      this.plannerDataAdapterService.createSchedulerEventPlaceholder(
        [task],
        this.gantt.uid(),
        task.projectId,
        task.userId
      );

    this.gantt.addTask(placeholder);
    this.splitTasks = [...this.splitTasks, placeholder];

    return +placeholder.id;
  }

  public handleCloseEvent = () => {
    this.customLightBox.nativeElement.style.display = 'none';
    this.gantt.hideLightbox();
    this.ganttEventPlannedTime = null;
    this.isNewEvent = false;
    this.isExporting = false;
  };

  public handleAfterSavePlannedTimeEvent(taskData: UserPlannedWork): void {
    const placeholderId =
      this.getTaskPlaceholderId(taskData) ||
      this.createTaskPlaceholderParent(taskData);

    const newTask: SchedulerEventPlannedTime = {
      todoId: +taskData.todoId,
      userId: +taskData.userId,
      projectId: +taskData.projectId,
      color: taskData.color,
      textColor: this.plannerDataAdapterService.setTextColor(taskData.color),
      text: taskData.messageToUser,
      end_date: moment(taskData.endDate)
        .set('hours', 23)
        .set('minutes', 59)
        .toDate(),
      start_date: moment(taskData.startDate)
        .set('hours', 0)
        .set('minutes', 0)
        .toDate(),
      realStartDate: taskData.startDate,
      realEndDate: taskData.endDate,
      eventType: EventTypeEnum.PlannedTime,
    };

    this.gantt.addTask(newTask, placeholderId, 0);

    this.isNewEvent = false;
  }

  private getTaskPlaceholderId(task: UserPlannedWork): number | null {
    const [placeholderTask] = this.gantt.getTaskBy(
      t =>
        t.eventType === EventTypeEnum.Placeholder &&
        +t.todoId === +task.todoId &&
        +t.userId === +task.userId &&
        +t.projectId === +task.projectId,
      null
    );
    return placeholderTask?.id;
  }

  public handleUpdatePlannedTimeEvent(updatedTask: UserPlannedWork): void {
    const task = this.gantt.getTask(
      updatedTask.id
    ) as SchedulerEventPlannedTime;
    const oldParent: SchedulerEventPlannedTime = this.gantt.getTask(
      this.gantt.getParent(updatedTask.id)
    );
    task.parent = null;

    if (updatedTask.userId) {
      // Add user object for use in placehodler creation
      const user = this.coworkers.find(
        coworker => +coworker.id === +updatedTask.userId
      );
      updatedTask.user = user;
    }

    const parentId = updatedTask.todoId
      ? updatedTask.todoId
      : updatedTask.projectId;
    this.gantt.getChildren(parentId).forEach(child => {
      const childTask = this.gantt.getTask(child);
      if (
        childTask.eventType === EventTypeEnum.Placeholder &&
        +childTask.userId === +updatedTask.userId
      ) {
        task.parent = child;
        return;
      }
    });

    // If no planned time placeholder exist, create one.
    if (task.parent === null) {
      const placeholder =
        this.plannerDataAdapterService.createSchedulerEventPlaceholder(
          [updatedTask],
          this.gantt.uid(),
          updatedTask.projectId,
          updatedTask.userId
        );
      this.gantt.addTask(placeholder, updatedTask.todoId);
      this.splitTasks.push(Object.assign({}, placeholder));
      task.parent = +placeholder.id;
    }

    task.color = updatedTask.color;
    task.textColor = this.plannerDataAdapterService.setTextColor(
      updatedTask.color
    );
    task.end_date = moment(updatedTask.endDate)
      .set('hours', 23)
      .set('minutes', 59)
      .toDate();
    task.realEndDate = updatedTask.endDate;
    task.projectId = updatedTask.projectId;
    task.start_date = moment(updatedTask.startDate)
      .set('hours', 0)
      .set('minutes', 0)
      .toDate();
    task.realStartDate = updatedTask.startDate;
    task.text = updatedTask.messageToUser;
    task.todoId = updatedTask.todoId;
    task.userId = updatedTask.userId;

    this.gantt.updateTask(updatedTask.id.toString(), task);

    const parent = this.gantt.getTask(task.parent);
    const placeholderChildren = [];
    this.gantt.getChildren(task.parent).forEach(childId => {
      const child = this.gantt.getTask(childId);
      placeholderChildren.push(child);
    });
    const edgeDates = this.plannerDataAdapterService.getEdgeDates([
      ...placeholderChildren.map(item =>
        moment(item.start_date).format('YYYY-MM-DD HH:mm')
      ),
      ...placeholderChildren.map(item =>
        moment(item.end_date).format('YYYY-MM-DD HH:mm')
      ),
    ]);
    parent.start_date = new Date(edgeDates.start);
    parent.end_date = new Date(edgeDates.end);
    this.gantt.updateTask(task.parent.toString(), parent);

    this.gantt.render();

    if (this.gantt.getChildren(oldParent.id).length === 0) {
      this.gantt.deleteTask(oldParent.id);
      const splitIndex = this.splitTasks.findIndex(
        split => +split.id === +oldParent.id
      );
      this.splitTasks.splice(splitIndex, 1);
      this.gantt.render();
    }

    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
  }

  public handleAfterSaveWorkTaskEvent(task: ProjectTodo): void {
    const newTask = this.plannerDataAdapterService.mapDataToGanttWorkTask([
      task,
    ])[0];
    newTask.headerId = task.type;
    this.ganttEvents = [...this.ganttEvents, newTask];
    this.ganttData.data = [...this.ganttData.data, newTask];
    this.isViewResolvedSubject.next((this.isViewResolved = true));
  }

  public handleUpdateWorkTaskEvent(updatedTask: ProjectTodo): void {
    const task = this.gantt.getTask(updatedTask.id) as SchedulerEventWorkTask;

    task.color = updatedTask.color;
    task.textColor = this.plannerDataAdapterService.setTextColor(
      updatedTask.color
    );
    task.done = updatedTask.done;
    task.end_date = moment(updatedTask.endDate)
      .set('hours', 23)
      .set('minutes', 59)
      .toDate();
    task.estimatedTime = updatedTask.estimatedTime;
    task.start_date = moment(updatedTask.startDate)
      .set('hours', 0)
      .set('minutes', 0)
      .toDate();
    task.headerId = updatedTask.type;
    task.parent = updatedTask.projectId;
    task.projectId = updatedTask.projectId;
    task.text = updatedTask.description;
    this.gantt.updateTask(updatedTask.id.toString(), task);
    this.getScheduledProjects(this.calculateDataQueryDateSpan());
    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
  }

  public handleDeleteEvent(id: number): void {
    const task = this.gantt.getTask(id);
    const parent = this.gantt.getTask(task.parent);

    this.gantt.deleteTask(id);
    if (
      parent.eventType === EventTypeEnum.Placeholder &&
      !this.gantt.hasChild(parent.id)
    ) {
      this.gantt.deleteTask(parent.id);
    }
  }

  public createNewPlannedTime(): void {
    const { startHour, startMinutes, endHour, endMinutes } =
      this.dateService.getStandardWorkHours();

    const startDate = moment().hour(startHour).minute(startMinutes).second(0);
    const endDate = moment().hour(endHour).minute(endMinutes).second(0);

    const task: SchedulerEventPlannedTime = {
      id: this.gantt.uid(),
      duration: 24,
      color: EventColorsEnum.Blue,
      end_date: endDate.format('YYYY-MM-DD HH:mm'),
      realEndDate: endDate.format('YYYY-MM-DD HH:mm'),
      parent: undefined,
      projectId: undefined,
      progress: 0,
      start_date: startDate.format('YYYY-MM-DD HH:mm'),
      realStartDate: startDate.format('YYYY-MM-DD HH:mm'),
      text: '',
      textColor: EventTextColorEnum.White,
      todoId: undefined,
      type: 'task',
      userId: undefined,
      eventType: EventTypeEnum.PlannedTime,
    };
    this.ganttEventPlannedTime = task;
    this.customLightBox.nativeElement.style.display = 'block';
    this.isNewEvent = true;
  }

  public createNewWorkTask(): void {
    const task: SchedulerEventWorkTask = {
      id: this.gantt.uid(),
      duration: 56,
      color: EventColorsEnum.Blue,
      end_date: this.dateService.addDaysToDateTimeString(
        this.dateService.ceilToClosest15(moment()).toDate(),
        7
      ),
      headerId: undefined,
      estimatedTime: 56,
      done: null,
      parent: undefined,
      projectId: undefined,
      progress: 0,
      start_date: this.dateService.newDateTimeString(
        this.dateService.ceilToClosest15(moment()).toDate()
      ),
      text: '',
      textColor: EventTextColorEnum.White,
      eventType: EventTypeEnum.WorkTask,
    };
    this.ganttEventPlannedTime = task;
    this.customLightBox.nativeElement.style.display = 'block';
    this.isNewEvent = true;
  }

  private handleClickEvent = (id: string, event: PointerEvent) => {
    const task = this.gantt.getTask(id);
    if (
      (event.target as Element).classList.contains('gantt_task_content') &&
      task.eventType !== EventTypeEnum.Placeholder
    ) {
      const children = this.gantt.getChildren(id);
      const childrenDates = children.map(child => {
        const childTask: SchedulerEvent = this.gantt.getTask(child);
        return {
          startDate: moment(childTask.start_date).format('YYYY-MM-DD HH:mm'),
          endDate: moment(childTask.end_date).format('YYYY-MM-DD HH:mm'),
        };
      });

      this.childrenEdgeDates = this.plannerDataAdapterService.getEdgeDates([
        ...childrenDates.map(item => item.startDate),
        ...childrenDates.map(item => item.endDate),
      ]);
      this.gantt.showLightbox(id);
    }
    return true;
  };

  private calculateDataQueryDateSpan = (): PlannerQueryVars => {
    const start = this.dateService.newDateString(
      this.filterForm.get('startDate').value
    );
    const end = this.dateService.newDateString(
      this.filterForm.get('endDate').value
    );

    return {
      ...PlannerReadService.DEFAULT_PLANNER_QUERY_VARS,
      fromDate: start,
      toDate: end,
    } as PlannerQueryVars;
  };

  private handleWorkTaskBeforeDragEvent = (
    id: string,
    mode: string,
    event: Event
  ): boolean => {
    const task = this.gantt.getTask(id);
    this.currentTask = task;
    if (
      task.eventType !== EventTypeEnum.Project &&
      task.eventType !== EventTypeEnum.WorkTask
    ) {
      this.currentTask = null;
      return false;
    }
    this.disableChildren(id);
    return true;
  };

  private handleWorkTaskDragEvent = (
    id: string,
    mode: string,
    task: any,
    original: any,
    event: Event
  ): void => {
    const diff = original.duration * (1000 * 60 * 60 * 24);
    if (mode === 'move') {
      task.end_date = new Date(+task.start_date + diff);
    }

    if (task.parent !== 0) {
      const parent = this.gantt.getTask(task.parent);
      if (+task.end_date > +parent.end_date) {
        task.end_date = new Date(parent.end_date);
      }
      if (+task.start_date < +parent.start_date) {
        task.start_date = new Date(parent.start_date);
      }
    }
  };

  private handleWorkTaskAfterDragEvent = (
    id: string,
    mode: string,
    event: Event
  ): void => {
    const task: SchedulerEventWorkTask = this.gantt.getTask(id);
    let startDate: Date = new Date(task.start_date);
    let endDate: Date = new Date(task.end_date);
    if (mode === 'move') {
      startDate = moment(startDate)
        .set('hour', 0)
        .set('minute', 0)
        .set('second', 0)
        .toDate();
      endDate = moment(endDate)
        .add(-1, 'day')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
        .toDate();
    }
    if (task.eventType === EventTypeEnum.WorkTask) {
      if (mode === 'move' || mode === 'resize') {
        const workTask: ProjectTodo =
          this.plannerDataAdapterService.mapSchedulerEventWorkTaskToWorkTask(
            task
          );

        this.updateTodoDateSpanGQL
          .mutate({
            todoId: workTask.id,
            startDate: startDate.toLocaleString('SV-se'),
            endDate: endDate.toLocaleString('SV-se'),
          })
          .subscribe(
            result => {
              if (
                result.data.updateTodoDateSpanMutation.mutationDetails[0]
                  .mutationSucceeded
              ) {
                workTask.startDate =
                  result.data.updateTodoDateSpanMutation.startDate;
                workTask.endDate =
                  result.data.updateTodoDateSpanMutation.endDate;
                this.handleUpdateWorkTaskEvent(workTask);
              }
            },
            error => {
              console.warn(error);
            },
            () => {
              this.currentTask = null;
              return false;
            }
          );
      }
    } else if (task.eventType === EventTypeEnum.Project) {
      const projectId = Number(task.id);
      this.updateProjectDateSpanGQL
        .mutate({
          projectId,
          startDate: startDate.toLocaleString('SV-se'),
          endDate: endDate.toLocaleString('SV-se'),
        })
        .subscribe({
          next: result => {
            this.disableTask(id);

            this.messageService.insertDataFromMutation(
              result.data.updateProjectDateSpanMutation
            );
            this.handleUpdateProject({
              id: Number(result.data.updateProjectDateSpanMutation.id),
              startDate: result.data.updateProjectDateSpanMutation.startDate,
              endDate: result.data.updateProjectDateSpanMutation.endDate,
            });
          },
        });
    }
  };

  private disableTask(id: string): void {
    const task = this.gantt.getTask(id);
    task.color = this.DISABLED_TASK_COLOR;
    this.gantt.refreshTask(id);
  }

  private disableChildren(id: string): void {
    const children = this.gantt.getChildren(id);
    if (children.length === 0) {
      return;
    }

    for (const childId of children) {
      const child = this.gantt.getTask(childId);
      child.originalColor = child.color;
      child.color = this.DISABLED_TASK_COLOR;
      this.gantt.refreshTask(childId);
    }
  }

  private handleContextMenuEvent = (
    taskId: string,
    linkId: string,
    event: Event
  ) => {
    const id = +taskId;
    if (id) {
      const schedulerEvent = this.gantt.getTask(id);
      this.contextMenuEventSubject.next({ id, schedulerEvent, event });
      return false;
    }
    return true;
  };

  public handleUpdateColorEvent(event: UpdateColorEvent): void {
    let apiCall: (item: any) => Observable<any>;
    if (event.isProject) {
      apiCall = this.plannerWriteService.updateProject;
    } else {
      apiCall = this.plannerWriteService.updatePlannedTime;
    }
    apiCall({ id: +event.id, color: event.color }).subscribe(
      result => {
        if (result.mutationSucceededAllArguments) {
          this.updateEventColor(event);
        } else {
          this.messageService.insertDataFromMutation(result);
        }
      },
      error => {
        console.warn(error);
      }
    );
  }

  public handleUpdateProject(project: Partial<Project>): void {
    const task: SchedulerEventProject = this.gantt.getTask(project.id);
    task.start_date = moment(project.startDate).toDate();
    task.end_date = moment(project.endDate)
      .set('hours', 23)
      .set('minutes', 59)
      .toDate();
    task.color = project.color;
    task.textColor = this.plannerDataAdapterService.setTextColor(project.color);
    this.gantt.updateTask(task.id.toString(), task);

    this.getScheduledProjects(this.calculateDataQueryDateSpan());
    this.getAllCompanyProjects(
      PlannerReadService.DEFAULT_PLANNER_DROPDOWN_QUERY_VARS
    );
  }

  private updateEventColor(update: UpdateColorEvent): void {
    const event = this.gantt.getTask(update.id);
    event.color = update.color;
    event.textColor = this.plannerDataAdapterService.setTextColor(update.color);
    this.gantt.updateTask(update.id.toString(), event);
  }

  private handleRowDrag = (
    id: number,
    parent: number,
    tindex: number
  ): boolean => {
    let msg: ToastMessage;
    const task = this.gantt.getTask(id);
    // Only permit reordering on todos
    if (task.eventType !== EventTypeEnum.WorkTask) {
      return false;
    }
    const type = (eventType: EventTypeEnum) => {
      switch (eventType) {
        case EventTypeEnum.Project:
          return 'projekt';
        case EventTypeEnum.WorkTask:
          return 'arbetsmoment';
        case EventTypeEnum.PlannedTime:
          return 'planerad tid';
      }
    };

    if (parent > 0) {
      if (+task.parent !== +parent) {
        const parentTask = this.gantt.getTask(parent);
        msg = {
          severity: ToastMessageSeverityType.ERROR,
          closable: true,
          detail: `Det går inte att flytta ${type(
            task.eventType
          )} utanför dess ${type(parentTask?.eventType)}.`,
        };

        this.messageService.insertData(msg);
        return false;
      }
    }
    return true;
  };

  private handleRowDragEnd = (
    parentTaskId: number,
    target: number
  ): boolean => {
    const task = this.gantt.getTask(parentTaskId);
    // Only handle reordering on todos
    if (task.eventType !== EventTypeEnum.WorkTask) {
      return false;
    }

    const parentTask = this.gantt.getParent(parentTaskId);
    this.updateChildTaskOrdering(+parentTask);
    return true;
  };

  private updateChildTaskOrdering = (parentId: number) => {
    this.gantt.getChildren(parentId).forEach(taskId => {
      const task = this.gantt.getTask(+taskId);
      const taskIndex = this.gantt.getTaskIndex(+taskId);
      this.setTaskOrdering(task, taskIndex);
    });
  };

  private setTaskOrdering = (task: SchedulerEventWorkTask, index: number) => {
    const workTask = { id: +task.id, orderNr: index };
    this.plannerWriteService.updateWorkTask(workTask).subscribe(
      result => {
        if (result.mutationSucceededAllArguments !== true) {
          this.messageService.insertDataFromMutation(result);
        }
      },
      error => {
        console.warn(error);
      },
      () => {
        this.currentTask = null;
      }
    );
  };

  private setTooltipTemplate = (start: Date, end: Date, task: any) => {
    switch (task.eventType) {
      case EventTypeEnum.Project:
        return this.projectEventTooltipTemplate(start, end, task);
      case EventTypeEnum.PlannedTime:
        return this.plannedTimeEventTooltipTemplate(start, end, task);
      case EventTypeEnum.WorkTask:
        return this.workTaskEventTooltipTemplate(start, end, task);
      default:
        return '';
    }
  };

  private plannedTimeEventTooltipTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime
  ): string => {
    const project = this.projects?.find(p => +p.id === +event.projectId);
    const coworker = this.coworkersDropdown?.find(
      c => +c.value === +event.userId
    );
    const projectTodos = this.allTodos?.find(
      at => +at.projectId === +event.projectId
    );
    let todo: ProjectTodo;
    if (projectTodos) {
      todo = projectTodos.todos.find(t => +t.id === +event.todoId);
    }

    return (
      '<div style="font-size: 1.1em;"><strong>Planerad tid</strong></div>' +
      (project
        ? '<div class="event-text-template-overflow"><strong>Projekt:</strong><br />' +
          project.trueId +
          ', ' +
          project.mark +
          '</div>'
        : '') +
      (coworker
        ? '<div class="event-text-template-overflow"><strong>Medarbetare:</strong><br />' +
          coworker.label +
          '</div>'
        : '') +
      (todo
        ? '<div class="event-text-template-overflow"><strong>Arbetsmoment:</strong><br />' +
          todo.topic.Name +
          ', ' +
          todo.description +
          '</div>'
        : '') +
      (event.text
        ? '<div class="event-text-template-overflow"><strong>Meddelande:</strong><br />' +
          event.text +
          '</div>'
        : '')
    );
  };

  private workTaskEventTooltipTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventWorkTask
  ): string => {
    const project = this.projects?.find(p => +p.id === +event.projectId);
    const projectTodos = this.allTodos?.find(
      at => +at.projectId === +event.projectId
    );
    let todo: ProjectTodo;
    if (projectTodos) {
      todo = projectTodos.todos.find(t => +t.id === +event.id);
    }

    return `<div style="font-size: 1.1em;">
              <strong>Arbetsmoment</strong>
            </div>
            <div class="event-text-template-overflow">
              <strong>Projekt:</strong><br />
              ${project.trueId}, ${project.mark}
            </div>
            <div class="event-text-template-overflow">
              <strong>Arbetsmoment:</strong><br />
              ${todo?.topic?.Name}, ${todo?.description}
            </div>
            <div class="event-text-template-overflow">
              <strong>${
                todo?.done ? '<i class="pi pi-check-circle"></i> Utförd' : ''
              }</strong>
            </div>
            `;
  };

  private projectEventTooltipTemplate = (
    start: Date,
    end: Date,
    event: SchedulerEventPlannedTime
  ): string => {
    const project = this.projects?.find(p => +p.id === +event.id);
    return `<div style="font-size: 1.1em;">
              <strong>Projekt</strong>
            </div>
            <div class="event-text-template-overflow">
              <strong>
                ${project.trueId}, ${project.mark}
              </strong>
            </div>
            <div style="height: 3px">&nbsp;</div>
            <div class="event-text-template-overflow">
              ${moment(event.start_date).format('YYYY-MM-DD')} - ${moment(
      event.end_date
    ).format('YYYY-MM-DD')}
            </div>`;
  };

  public exportFile(event: ExportProjectPlannerEvent): void {
    const startDate = this.gantt.date.date_to_str(
      this.gantt.config.date_format
    )(new Date(event.start));
    const endDate = this.gantt.date.date_to_str(this.gantt.config.date_format)(
      new Date(event.end)
    );
    const settings = {
      start: startDate,
      end: endDate,
      name:
        'gantt-' +
        new Date().toISOString() +
        `.${event.fileFormat.toLowerCase()}`,
      locale: 'sv', // Currently bugged..
      skin: 'material',
      raw: false,
      additional_settings: {},
    };

    if (event.fileFormat === ExportFileFormat.PDF) {
      settings.additional_settings = {
        merge_pages: true,
      };

      /**
       * The API is not handling landscape mode correctly without cropping too much so we specify width and height explicitly instead
       */
      switch (event.format) {
        case ExportPaperSizeEnum.A4:
          settings.additional_settings = {
            ...settings.additional_settings,
            width:
              (event.landscape
                ? PaperSizeInMm.A4_HEIGHT
                : PaperSizeInMm.A4_WIDTH) * 5,
            height:
              (event.landscape
                ? PaperSizeInMm.A4_WIDTH
                : PaperSizeInMm.A4_HEIGHT) * 5,
          };
          break;
        case ExportPaperSizeEnum.A3:
          settings.additional_settings = {
            ...settings.additional_settings,
            width:
              (event.landscape
                ? PaperSizeInMm.A3_HEIGHT
                : PaperSizeInMm.A3_WIDTH) * 5,
            height:
              (event.landscape
                ? PaperSizeInMm.A3_WIDTH
                : PaperSizeInMm.A3_HEIGHT) * 5,
          };
          break;
      }

      this.gantt.exportToPDF(settings);
    }

    if (event.fileFormat === ExportFileFormat.PNG) {
      this.gantt.exportToPNG(settings);
    }
  }

  public exportProjectPlanner(): void {
    this.customLightBox.nativeElement.style.display = 'block';
    this.isExporting = true;
  }
  public showToday(): void {
    this.gantt.showDate(new Date());
  }

  public ngOnDestroy(): void {
    this.gantt.ext.tooltips.tooltip.hide();
  }

  private getSelectedState(): number {
    const selectedState = localStorage.getItem('selectedState');
    return selectedState === 'null' ? null : Number(selectedState);
  }

  public saveSelectedState(selectedState: number | string): void {
    if (typeof selectedState === 'string') {
      return;
    }

    localStorage.setItem('selectedState', String(selectedState));
  }
}
