import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import {
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { Table } from 'primeng/table';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';

import {
  CompanyCostTypeService,
  CompanyProjectTypeService,
  CompanyProjectsForStatusZeroService,
  CompanyFunctionsService,
  CompanyUserCostTypeService,
  ProjectBookmarksService,
} from '../../shared/company/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from '../../shared/apollo/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { HttpService } from '../../shared/http/index';
import { MessageService } from '../../shared/message/index';
import { HelperService } from '../../shared/helpers/index';
import { GlobalService } from '../../shared/global/index';

import { SortService } from '../../store/sort.service';
import { AppDialogService } from '../../shared/dialogs/dynamic-dialog.service';
import { UserSettingsComponent } from 'app/registry/users';
import { ShareProjectClientRightsComponent } from '../project-share/share-project-company/client/share-project-client-rights.component';
import { CustomSort } from 'app/store/custom-sort';
import { ProjectIndexService } from './project-index.service';
import PROJECT_STATUS from 'app/shared/global/project-status.enum';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { ProjectDataComponent } from 'app/shared/dialogs/project/data/projectData.component';
import { ProjectCreateComponent } from 'app/shared/dialogs/project/create/projectCreate.component';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActiveItemLabelService } from 'app/header/active-item-label.service';

@Component({
  selector: 'projectindex',
  templateUrl: 'project-index.component.html',
  styleUrls: ['project-index.component.scss'],
  providers: [AppDialogService],
})
export class ProjectIndexComponent implements OnInit, OnDestroy {
  @Input() public isProjectBookmarks: false;

  private labelModels = [
    { model: 'Project' },
    { model: 'Contact' },
    { model: 'User' },
    { model: 'Day' },
    {
      model: 'Projectproduct',
      labels: [
        'companyCostTypeId',
        'avtalspris',
        'enhet',
        'antal',
        'benamning',
      ],
    },
  ];

  public statusText: any;
  private projectTypeId = null;
  public mode: string = null;
  public state: string = null;
  public projectTypeModel = null;
  public currentStatus: number = null;
  public showAdvancedSearch = false;
  private projectListHasSelection = false;
  private dataSet = [];
  private statusModel = [];
  private projectListSelected: any = [];

  /** The action selected for the actions dropdown */
  public multiProjectAction = [];
  public availableMultiProjectActionsForDropdown = [];
  public projectLabels: any = {};
  public userCostTypesRaw = {};
  public projectParams: any = {};
  public dataSetAsync: BehaviorSubject<any> = new BehaviorSubject([]);
  public loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private checkForCompanyFunctions = [
    'useUserCostType',
    'usePickUserCostTypeOnTimereport',
    'showNotInvoicedDaysCount',
    'useModuleSupplierInvoice',
    'extraAlwaysOpen',
    'setMile',
    'useNotarized',
    'advancedUserCanNotarizeTimereports',
    'setPrivMile',
    'useProjectTypes',
    'useOnlyTimestampTimeReport',
  ];
  public functionsThisModel = {};
  public functionsData: {
    projectCostTypes?: {
      value: number;
      label: string;
    }[];
  } = {};
  private functionsDataExists = false;
  public meUser: MeUserWithCompany;
  public projectTableRow = {
    toggle: false,
    trueId: null,
    created: null,
  };
  public allLoadingDone = {
    projects: false,
    companyCostTypes: false,
    userCostTypes: false,
  };
  public dateRange: {
    from: string;
    to: string;
  };
  public showSummation = false;
  public projectStatus = [
    { value: PROJECT_STATUS.PLANNED, label: 'Planerade' },
    { value: PROJECT_STATUS.ONGOING, label: 'Pågående' },
    { value: PROJECT_STATUS.FINISHED, label: 'Avslutade' },
    { value: PROJECT_STATUS.INHOUSE, label: 'Interna' },
    { value: PROJECT_STATUS.ARCHIVED, label: 'Arkiverade' },
  ];
  public projectStatusForSummationModel = [];
  public projectSummationDateFilter = {
    from: new Date(new Date().getFullYear(), 0, 1),
    to: new Date(new Date().getFullYear(), 11, 31),
    fromDate: '',
    toDate: '',
  };
  public yearRange = '2010:2025';
  public sort: CustomSort = {
    attribute: 'trueId',
    ascDesc: 1,
    object: 'project_table',
  };

  @ViewChild('projectTable', { static: true }) public projectTable: Table;
  public possibleTableColumnsDefualt = [];
  public possibleTableColumnsCalc = [];
  public selectedColumns = [];
  public selectedRows = [];
  public availableProjects = [];
  public selectedSummationColumns = [];
  private querySub: Subscription;
  private projectSub: Subscription;
  private userCostTypesSub: Subscription;
  private projectTypesSub: Subscription;
  private companyCostTypesSub: Subscription;
  private routerSubscriber: Subscription;
  private functionsDataSub: Subscription;
  public statusOptions = [
    {
      label: 'Välj status',
      value: { val: null, field: 'allTodosDone', type: 'contains' },
    },
    {
      label: 'Alla arbetsmoment klara',
      value: { val: true, field: 'allTodosDone', type: 'contains' },
    },
    {
      label: 'Ej klara arbetsmoment',
      value: { val: false, field: 'allTodosDone', type: 'contains' },
    },
    {
      label: 'Har leverantörsfakturor',
      value: { val: 0, field: 'supplierInvoiceRowsSTAT', type: 'gt' },
    },
    {
      label: 'Ej inlästa leverantörsfakturor',
      value: { val: 0, field: 'unMovedSupplierInvoiceRowsSTAT', type: 'gt' },
    },
    {
      label: 'Ej fakturerade tidrapporter',
      value: { val: 0, field: 'notInvoicedDaysCount', type: 'gt' },
    },
  ];

  public get availableMultiProjectActions(): any[] {
    if (!this.currentStatus) {
      return [];
    }
    switch (this.currentStatus) {
      case PROJECT_STATUS.ONGOING:
        return [
          {
            label: 'Avsluta valda projekt',
            name: 'Avsluta valda projekt',
            code: 'complete',
            value: 'complete',
          },
          {
            label: 'Arkivera valda projekt',
            name: 'Arkivera valda projekt',
            code: 'archive',
            value: 'archive',
          },
        ];
      case PROJECT_STATUS.FINISHED:
        return [
          {
            label: 'Gör projekt pågående',
            name: 'Gör projekt pågående',
            code: 'ongoing',
            value: 'ongoing',
          },
          {
            label: 'Arkivera valda projekt',
            name: 'Arkivera valda projekt',
            code: 'archive',
            value: 'archive',
          },
        ];
    }
  }

  private attendanceReportProjectsSub: Subscription;

  public todoTooltipLabel: string;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService,
    public helperService: HelperService,
    private labelsService: HyperionLabelsService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyUserCostTypeService: CompanyUserCostTypeService,
    private companyCostTypeService: CompanyCostTypeService,
    private companyProjectTypeService: CompanyProjectTypeService,
    private companyProjectsForStatusZeroService: CompanyProjectsForStatusZeroService,
    private projectBookmarksService: ProjectBookmarksService,
    private mutationService: ApolloMutationService,
    private messageService: MessageService,
    private httpService: HttpService,
    private apolloQueryService: ApolloQueryService,
    private sortService: SortService,
    private confirmationService: ConfirmationService,
    private dialogService: AppDialogService,
    private projectIndexService: ProjectIndexService,
    private userLocalStorageService: UserLocalStorageService,
    private http: HttpClient,
    private activeItemLabelService: ActiveItemLabelService
  ) {
    this.dateRange = {
      from: moment().startOf('month').format(this.helperService.dateFormat()),
      to: moment().endOf('month').format(this.helperService.dateFormat()),
    };

    this.setYearRange();
    this.setMode();

    this.projectSummationDateFilter.fromDate =
      this.helperService.dateFormatTimeZone(
        this.projectSummationDateFilter.from,
        true
      );
    this.projectSummationDateFilter.toDate =
      this.helperService.dateFormatTimeZone(
        this.projectSummationDateFilter.to,
        true
      );

    this.meUser = this.userLocalStorageService.getMeUserWithCompany();

    this.sort = this.sortService.getSort(this.sort);
    this.projectParams = this.globalService.getModelParams('project');

    this.labelsService.getLabels(this.labelModels).subscribe(data => {
      this.setTableHeader(data);
      this.subscribeToRouteChange();
    });
  }

  private setTableHeader(data: any): void {
    this.projectLabels = data;
    let order = 1;
    const cols = [
      // this is automactic?
      {
        field: 'trueId',
        sortField: 'trueId',
        header: data.Project.trueId,
        order: order++,
        total: '',
        width: '5.5rem',
      },
      {
        field: 'mark',
        sortField: 'mark',
        header: data.Project.mark,
        order: order++,
        total: '',
        width: 'auto',
      },
      {
        field: 'clientContact_orderBuisnessName',
        sortField: 'clientContact_orderBuisnessName',
        header: 'Kund',
        order: order++,
        total: '',
        width: '18rem',
      },
      {
        field: 'establishmentContact_name',
        sortField: 'establishmentContact_name',
        header: 'Arbetsställe',
        order: order++,
        total: '',
        width: '15rem',
      },
    ];

    if (this.mode === 'attendance') {
      cols.push({
        field: 'constructionSiteNumber',
        sortField: 'constructionSiteNumber',
        header: data.Project.constructionSiteNumber,
        order: order++,
        total: '',
        width: 'auto',
      });
    }
    this.selectedColumns = [...cols];
    this.selectedSummationColumns = [...cols];
    this.possibleTableColumnsDefualt = [...cols]; // Makes the view list
    this.possibleTableColumnsCalc = [
      {
        field: 'cost',
        sortField: 'costForSort',
        header: 'Kostnader',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'notPaydInvoices',
        sortField: 'notPaydInvoicesForSort',
        header: 'Ej bet. fakturor',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'paydInvoicesAndCredit',
        sortField: 'paydInvoicesAndCreditForSort',
        header: 'Betalda fakturor',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'offerSum',
        sortField: 'offerSumForSort',
        header: 'Offererat',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'workedHours',
        sortField: 'workedHoursForSort',
        header: 'Timmar - normala',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'workedHoursExtra',
        sortField: 'workedHoursExtraForSort',
        header: 'Timmar - extra',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'invoicedHours',
        sortField: 'invoicedHoursForSort',
        header: 'Fakturerade timmar',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'hoursLeftToInvoice',
        sortField: 'hoursLeftToInvoiceForSort',
        header: 'Timmar kvar att fakturera',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'result',
        sortField: 'resultForSort',
        header: 'Resultat',
        order: order++,
        total: 0,
        width: '90px',
      },
      {
        field: 'remainingInvoice',
        sortField: 'remainingInvoiceForSort',
        header: 'Återstående fakturering',
        order: order++,
        total: 0,
        width: '110px',
      },
      {
        field: 'notInvoiced',
        sortField: 'notInvoicedForSort',
        header: 'Ej fakturerat',
        order: order++,
        total: 0,
        width: '110px',
      },
    ];
    this.setSelectedColumnsCalc();
  }

  private setSelectedColumnsCalc() {
    let sort = {};
    this.possibleTableColumnsCalc.map(row => {
      sort = this.sortService.getSort({
        attribute: 'null',
        ascDesc: -1,
        object: 'project_index_kpi_' + row['sortField'],
      });

      if (sort['ascDesc'] === 1) {
        this.selectedSummationColumns.push(row);
      }
    });
  }

  public updateSort(event: any, col: any): void {
    const sortObject = {
      attribute: 'null',
      ascDesc: event.checked ? 1 : -1,
      object: 'project_index_kpi_' + col['sortField'],
    };
    this.sortService.setSort(
      { field: 'null', order: event.checked ? 1 : -1 },
      sortObject
    );
  }

  ngOnInit() {
    this.getCostTypes();
    this.getClientProjectsNotCopied();
  }

  public statusSearchSwitch(value) {
    for (const st in this.statusOptions) {
      if (this.statusOptions.hasOwnProperty(st)) {
        const status = this.statusOptions[st];
        this.projectTable.filter(null, status['value']['field'], 'contains');
      }
    }

    this.projectTable.filter(value.val, value.field, value.type);
  }

  public changeSort(event) {
    this.sortService.setSort(event, this.sort);
  }

  private getObjectIndex(array, id) {
    array.findIndex(a => Number(a.id) === Number(id));
  }

  private subscribeToRouteChange() {
    const routerStateUrl: string =
      this.activeRoute['_routerState']['snapshot']['url'];
    const isInHomeStart: boolean = routerStateUrl.search('home/start') !== -1;

    if (!isInHomeStart) {
      this.routerSubscriber = this.activeRoute.url.subscribe(_ => {
        this.loading.next(true);
        this.onNavigationEnd();
      });
    } else {
      this.setCompanyFunctionsData();
      this.getProjectBookmarks();
    }
  }

  public filterByProjectType(value) {
    const projectTypes = this.functionsData['projectCostTypes'];

    if (value === null) {
      const multiValues = [];

      for (const index in projectTypes) {
        if (projectTypes.hasOwnProperty(index)) {
          const projectType = projectTypes[index];
          if (projectType['addToMeny'] <= 0 || !projectType['addToMeny']) {
            if (projectType.value !== null) {
              multiValues.push(+projectType.value);
            } else {
              multiValues.push(0);
            }
          }
        }
      }
      this.projectTable.filter(multiValues, 'typeId', 'in');
    } else {
      this.projectTable.filter(value, 'typeId', 'equals');
    }
  }

  private queryUnsubscribe() {
    if (typeof this.projectSub !== 'undefined') {
      this.projectSub.unsubscribe();
    }
    if (typeof this.querySub !== 'undefined') {
      this.querySub.unsubscribe();
    }
  }

  private onNavigationEnd() {
    this.setMode();
    this.activeItemLabelService.unsetActiveItem();

    if (this.mode) {
      // Get project that have attendance report here
      this.getAttendanceReportProjects();
    } else {
      const statusNumber = Number(this.activeRoute.snapshot.url[1].path);
      // * new
      if (statusNumber > this.projectParams.status.CONST.INHOUSE) {
        this.router.navigate([
          '/old-project/index/' + this.projectParams.status.CONST.ONGOING,
        ]);
      } else {
        const queryParams = this.activeRoute.snapshot.queryParams || null;
        this.setCompanyFunctionsData();
        this.routeHasChanged(statusNumber, queryParams);
      }
    }
  }

  private setMode(): void {
    this.mode = null;
    this.state = null;

    const parentUrlSegments = this.activeRoute.parent.snapshot.url;
    if (
      parentUrlSegments.length > 0 &&
      parentUrlSegments[0].path === 'attendanceReport'
    ) {
      this.mode = 'attendance';
      const urlSegments = this.activeRoute.snapshot.url;
      if (urlSegments.length === 2) {
        this.state = urlSegments[1].path;
      }
    }
  }

  public getAttendanceReportProjects(
    variables = { status: 1, forceAll: false }
  ) {
    this.loading.next(true);
    this.attendanceReportProjectsSub &&
      this.attendanceReportProjectsSub.unsubscribe();

    // Checks for forceAll, If true then the user has requested to see all data.
    if (this.state === 'archived' && !variables.forceAll) {
      variables.status = 3;
    }

    this.apolloQueryService
      .apolloWatchQueryTwo(
        'attendanceReportProjects2',
        variables,
        'cache-and-network'
      )
      .subscribe(data => {
        this.attendanceReportProjectsSub = data.sub;
        this.chekIfLoadingIsDone();
        this.handleProjectData(data.data, null, true);
      });
  }

  private setCompanyFunctionsData() {
    if (!this.functionsDataExists) {
      this.functionsDataExists = true;

      this.getCompanyFunctionsThisModel();
      this.getFunctionsData();
    }
  }

  public projectListCheckboxOnChange(event, projectId) {
    projectId = projectId * 1;
    if (event.checked) {
      // Project is selected
      this.projectListSelected.push(projectId);
    } else {
      // Project NOT selected
      const index = this.projectListSelected.indexOf(projectId);
      if (index !== -1) {
        this.projectListSelected.splice(index, 1);
      }
    }

    this.projectListHasSelection = this.projectListSelected.length > 0;
  }

  public openProjectDataDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.openComponent(ProjectDataComponent, 'Data för projekt');
  }

  private routeHasChanged(status, queryParamsData = null) {
    this.currentStatus = status;
    this.statusText =
      this.projectParams.status.strings[this.currentStatus][1][1];

    this.resetExpandedRows();
    this.handleUrlMaster(status, queryParamsData);
    this.toggleColumns(false);
  }

  private toggleColumns(setValue?) {
    this.selectedColumns = [
      ...this.possibleTableColumnsDefualt.sort(
        (a, b) => parseFloat(a.order) - parseFloat(b.order)
      ),
    ];
    this.selectedSummationColumns = [
      ...this.possibleTableColumnsDefualt.sort(
        (a, b) => parseFloat(a.order) - parseFloat(b.order)
      ),
    ];

    this.setSelectedColumnsCalc();

    this.showSummation =
      typeof setValue !== 'undefined' ? setValue : !this.showSummation;
  }

  private resetExpandedRows() {
    if (typeof this.projectTable !== 'undefined') {
      this.projectTable.expandedRowKeys = {};
    }
  }

  private handleUrlMaster(statusParam, queryParamsData = null) {
    if (queryParamsData !== null) {
      this.projectTypeId = queryParamsData['projectTypeId'];

      if (queryParamsData['created']) {
        this.projectTableRow['created'] = queryParamsData['created'];
      }

      if (queryParamsData['id']) {
        this.projectTableRow['toggle'] = true;
        this.projectTableRow['trueId'] = queryParamsData['id'];
      }
    }

    this.projectStatusForSummationModel = [];
    this.projectStatusForSummationModel.push(+statusParam);
    this.getProjects(statusParam);
  }

  public getProjects(statusParam, dontReset = false) {
    if (!dontReset) {
      this.dataSet = [];
      this.dataSetAsync.next([]);
    }

    this.selectedRows = [];
    this.statusModel = [+statusParam];
    this.queryUnsubscribe();

    +statusParam > -1 &&
      +statusParam < this.projectParams.status.CONST.INHOUSE + 1 &&
      this.subscribeToProjectData(+statusParam);
  }

  private subscribeToProjectData(statusParam) {
    let model =
      +statusParam === 4 ? 'companyProjectsWithDeleted' : 'companyProjects';

    if (+statusParam === 3) {
      if (this.projectTableRow['toggle']) {
        const created = this.projectTableRow['created'];
        created &&
          !this.isCreatedInDateRange(created) &&
          this.setDateRange(created);
      }
      model = 'archivedProjects';
    }
    const vars =
      +statusParam === 3
        ? { fromDate: this.dateRange['from'], toDate: this.dateRange['to'] }
        : { status: this.statusModel };

    this.projectTypeId !== null &&
      typeof this.projectTypeId !== 'undefined' &&
      this.filterProjectTypeId(this.projectTypeId);

    this.apolloQueryService
      .apolloWatchQueryTwo(model, vars, 'cache-and-network')
      .subscribe(data => {
        this.querySub = data.sub;
        this.projectSub = data.sub;
        this.chekIfLoadingIsDone();
        this.handleProjectData(data.data);
      });
    this.currentStatus = +statusParam;
  }

  private handleProjectData(data, isSummations = false, isAttendance = false) {
    data = JSON.parse(JSON.stringify(data));

    let newDataSet;
    // Sets project ids to ints for sorting.
    if (data.company && data.company.projects && data.company.projects.edges) {
      data.company.projects.edges = data.company.projects.edges.map(
        project => ({
          ...project,
          node: {
            ...project.node,
            trueId: +project.node.trueId,
          },
        })
      );
    }
    if (isAttendance) {
      newDataSet =
        this.companyProjectsForStatusZeroService.makeObjectsForFormsByModel(
          data,
          'attendanceReportProjects_PreCompanyTypeHyperion'
        );
    } else {
      newDataSet = this.companyProjectsForStatusZeroService.dataFormater(
        data,
        'list',
        +this.currentStatus === 4
      );
    }

    this.allLoadingDone['projects'] = true;
    this.loading.next(false);

    newDataSet = this.setClasses(newDataSet);

    if (isSummations) {
      newDataSet = this.appendSummations(newDataSet);
    }

    if (
      this.functionsThisModel['useProjectTypes'] &&
      this.functionsData['projectCostTypes']
    ) {
      newDataSet = this.projectIndexService.appendProjectTypeLabels(
        newDataSet,
        this.functionsData['projectCostTypes']
      );
    }

    this.dataSet = newDataSet;
    this.dataSetAsync.next(newDataSet);

    setTimeout(() => {
      this.filterProjectTypeId(this.projectTypeModel);

      if (this.projectTableRow['toggle'] && !isSummations) {
        this.openRowInTable(this.projectTableRow['trueId']);
      }
    }, 500);
  }

  private setClasses(data) {
    this.availableMultiProjectActionsForDropdown =
      this.availableMultiProjectActions;

    const dataSet = data;
    for (const index in dataSet) {
      if (dataSet.hasOwnProperty(index)) {
        let project = dataSet[index];
        const cleanedTodos = this.helperService.cleanFromNode(project['todos']);
        for (const i in cleanedTodos) {
          if (cleanedTodos.hasOwnProperty(i)) {
            const todo = cleanedTodos[i];
            todo['users'] = this.helperService.cleanFromNode(
              todo['usersTodoRelation']
            );
          }
        }
        project['todosList'] = cleanedTodos;

        project = this.setClassesProject(project);
      }
    }
    return dataSet;
  }

  private setClassesProject(project) {
    // append stats
    const rowsCountMoved =
      project['invoiceData_PreProjectTypeHyperion']['supplier'][
        'rowsCountMoved'
      ];
    const rowsCountUnMoved =
      project['invoiceData_PreProjectTypeHyperion']['supplier'][
        'rowsCountUnMoved'
      ];
    project['unMovedSupplierInvoiceRowsSTAT'] = rowsCountUnMoved;
    project['supplierInvoiceRowsSTAT'] = rowsCountMoved + rowsCountUnMoved;

    // append html class
    project['unMovedSupplierInvoiceRowsSTATClass'] =
      this.functionsThisModel['useModuleSupplierInvoice'] &&
      rowsCountUnMoved > 0
        ? 'display-as-inline-block'
        : 'display-none';
    project['movedSupplierInvoiceRowsSTATClass'] =
      this.functionsThisModel['useModuleSupplierInvoice'] &&
      rowsCountUnMoved < 1 &&
      rowsCountMoved > 0
        ? 'display-as-inline-block'
        : 'display-none';
    project['invoicedDaysCountClass'] =
      this.functionsThisModel['showNotInvoicedDaysCount'] &&
      project['notInvoicedDaysCount'] > 0
        ? 'display-as-inline-block'
        : 'display-none';
    project['shortMessageClass'] =
      project['shortMessage'] !== null && project['shortMessage'] !== ''
        ? 'display-as-inline-block'
        : 'display-none';

    // other

    project['bookmarkedByUserObject'] = project['projectBookmarks'].find(
      bookmark => bookmark.userId === +this.meUser.id
    );
    project['allTodosDone'] = this.allTodosDone(project);
    project['todosClass'] =
      project['todosList'].length > 0
        ? 'display-as-inline-block'
        : 'display-none';

    project['clientContact_orderBuisnessName'] = '';
    const clientContact = project && project.clientContact;
    if (clientContact) {
      if (clientContact.orderBuisnessName) {
        project.clientContact_orderBuisnessName =
          clientContact.orderBuisnessName;
      } else if (clientContact.name) {
        project.clientContact_orderBuisnessName = clientContact.name;
      }
    }

    project['establishmentContact_name'] =
      project['establishmentContact']['name'];
    project['establishmentContact_address'] =
      project['establishmentContact']['address'];

    project['ClientSearchString'] = JSON.stringify(project['clientContact']);
    project['EstablishmentSearchString'] = JSON.stringify(
      project['establishmentContact']
    );
    project['TodooSearchString'] = JSON.stringify(project['todosList']);

    project['daysToEndDateTooltip'] = this.daysToEndDate(
      project['endDate'],
      false
    );
    project['daysToEndDateClass'] = this.daysToEndDate(
      project['endDate'],
      true
    );

    return project;
  }

  private setDateRange(date) {
    const fromThisDate = new Date(
      this.helperService.parseDateSafariFriendly(date)
    );
    const startOfMonth = new Date(
      fromThisDate.getFullYear(),
      fromThisDate.getMonth() - 1,
      2
    );
    const endOfMonth = new Date(
      fromThisDate.getFullYear(),
      fromThisDate.getMonth() + 1,
      1
    );
    this.dateRange = {
      from: moment(startOfMonth).format(this.helperService.dateFormat()),
      to: moment(endOfMonth).format(this.helperService.dateFormat()),
    };
  }

  private isCreatedInDateRange(created) {
    const createdNumber = this.helperService.stripDateToNumber(created);
    return (
      createdNumber >=
        this.helperService.stripDateToNumber(this.dateRange['from']) &&
      createdNumber <=
        this.helperService.stripDateToNumber(this.dateRange['to'])
    );
  }

  private openRowInTable(projectTrueId) {
    const dataSet = this.dataSetAsync.value;
    for (const index in dataSet) {
      if (dataSet.hasOwnProperty(index)) {
        const projectInDS: any = dataSet[index];

        if (Number(projectInDS['trueId']) === Number(projectTrueId)) {
          // use find
          // this.goToPage(index);
          this.projectTable.toggleRow(projectInDS);
          this.scrollElementIntoView(projectTrueId);
          this.projectTableRow['toggle'] = false;
          break;
        }
      }
    }
  }

  private scrollElementIntoView(idValue) {
    const trueIdSpan = document.getElementById(idValue);
    if (typeof trueIdSpan !== 'undefined' && trueIdSpan !== null) {
      const el = document.getElementById(idValue);
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  private filterProjectTypeId(id: any) {
    this.projectTypeModel = id === '0' ? null : id;
    this.filterByProjectType(this.projectTypeModel);
    this.projectTypeId = null;
  }

  private getCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyCostType', null, 'cache-and-network')
      .subscribe(data => {
        this.companyCostTypesSub = data.sub;
        this.functionsData['companyCostTypes'] =
          this.companyCostTypeService.dataFormater(data.data, 'labels');
        this.allLoadingDone['companyCostTypes'] = true;
        if (this.chekIfLoadingIsDone()) {
          this.loading.next(false);
        }
      });
  }

  private getCompanyFunctionsThisModel() {
    for (const companyFunction in this.checkForCompanyFunctions) {
      if (this.checkForCompanyFunctions.hasOwnProperty(companyFunction)) {
        const functionName = this.checkForCompanyFunctions[companyFunction];
        this.functionsThisModel[functionName] =
          this.companyFunctionsService.companyFunctionIsSet(functionName);
      }
    }

    if (this.companyFunctionsService.companyFunctionIsSet('useNotarized')) {
      this.functionsThisModel['useNotarized'] =
        +this.meUser.type === 3 ||
        (+this.meUser.type === 2 &&
          this.companyFunctionsService.companyFunctionIsSet(
            'advancedUserCanNotarizeTimereports'
          ));
    }
  }

  private getFunctionsData() {
    this.getUserCostTypes();
    if (this.functionsThisModel['useProjectTypes']) {
      this.getProjectTypes();
    }
  }

  public updateMessage(dataParam) {
    const id = dataParam['id'];
    const message = dataParam['message'];

    const projects = this.dataSetAsync.value;

    for (const index in projects) {
      if (projects.hasOwnProperty(index)) {
        // use find
        const project = projects[index];
        if (Number(project['id']) === Number(id)) {
          project['shortMessage'] = message;
          break;
        }
      }
    }

    const newData = [...projects];
    this.dataSetAsync.next(newData);
  }

  private getUserCostTypes() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUserCostType', null, 'cache-and-network')
      .subscribe(data => {
        this.userCostTypesSub = data.sub;
        this.userCostTypesRaw = data.data;
        this.functionsData['userCostTypes'] =
          this.companyUserCostTypeService.dataFormater(data.data, 'labels');
        this.allLoadingDone['userCostTypes'] = true;
        if (this.chekIfLoadingIsDone()) {
          this.loading.next(false);
        }
      });
  }

  private getProjectTypes() {
    this.allLoadingDone['projectTypes'] = false;

    this.apolloQueryService
      .apolloWatchQueryTwo('companyProjectType', null, 'cache-and-network')
      .subscribe(data => {
        this.projectTypesSub = data.sub;
        this.functionsData['projectCostTypes'] =
          this.companyProjectTypeService.dataFormater(
            data.data,
            'labels',
            false,
            'Alla projekttyper'
          );
        this.allLoadingDone['projectTypes'] = true;
        if (this.chekIfLoadingIsDone()) {
          this.loading.next(false);
        }
      });
  }

  private chekIfLoadingIsDone() {
    for (const key in this.allLoadingDone) {
      if (!this.allLoadingDone[key]) {
        return false;
      }
    }
    return true;
  }

  public daysToEndDate(endDate, sendClass = false) {
    const today: any = new Date();
    const endDateDate: any = new Date(
      this.helperService.parseDateSafariFriendly(endDate)
    );
    const endDateNum = Math.floor(endDateDate / 8.64e7);
    const difference = endDateNum - Math.floor(today / 8.64e7);

    if (sendClass) {
      if (difference < 0) {
        return 'end-date-passed';
      }
      if (difference >= 0 && difference <= 10) {
        return 'within-ten-days';
      }
      if (difference > 10 && difference <= 19) {
        return 'outside-ten-days';
      }
      if (difference > 20) {
        return 'outside-twenty-days';
      }
    } else {
      let tooltip: string;
      if (difference === 0) {
        tooltip = 'Planerat slutdatum är idag ' + endDate + '.';
      } else if (difference > 0) {
        tooltip = 'Planerat slutdatum om ' + difference + ' dagar.';
      } else {
        tooltip =
          'Planerat slutdatum passerat med ' + Math.abs(difference) + ' dagar.';
      }
      return tooltip;
    }
  }

  public openCreateDialog() {
    this.dialogService
      .openComponent(ProjectCreateComponent)
      .onClose.subscribe(res => {
        if (typeof res !== 'undefined') {
          const statusFromMutation = res['status'];
          this.statusModel = [];
          this.statusModel.push(+this.currentStatus);
          if (this.statusModel[0] === +statusFromMutation) {
            this.loading.next(true);
            this.projectTableRow['toggle'] = true;
            this.projectTableRow['trueId'] = res['trueId'];
            this.resetExpandedRows();
            this.getProjects(this.statusModel[0]);
          }
        }
      });
  }

  public restoreProject(projectIdParam) {
    const projectId = Number(projectIdParam);
    const url = '/old-project/restoreProject/id/' + projectId;

    this.httpService.makeHttpPostRequest(url).subscribe(data => {
      if (data['status'] === 'success') {
        this.messageService.insertData({
          textArray: [data['msg']],
          type: 'success',
        });
        this.apolloQueryService
          .apolloQuery('companyProjectsWithDeleted', {
            status: this.statusModel,
          })
          .subscribe();
      } else {
        this.messageService.insertData(
          { textArray: [data['msg']], type: 'error' },
          true
        );
      }
    });
  }

  public onRowClick(originalEvent, rowData, tableInstance): void {
    if (
      !originalEvent['target'].classList.contains('pi') &&
      !originalEvent['target'].classList.contains('fa') &&
      !originalEvent['target'].classList.contains('p-checkbox-box') &&
      !originalEvent['target'].classList.contains('p-checkbox-icon')
    ) {
      tableInstance.toggleRow(rowData);

      if (tableInstance.isRowExpanded(rowData)) {
        this.activeItemLabelService.setActiveItem({
          id: rowData.trueId,
          name: rowData.mark,
        });
      } else {
        this.activeItemLabelService.unsetActiveItem();
      }
    }
  }

  public exportProjectsExcel() {
    location.href =
      '/old-project/index?status=' + this.currentStatus + '&excel=1';
  }

  public dataIsEmpty() {
    let data = [];

    if (this.projectTable) {
      data = this.projectTable.filteredValue
        ? this.projectTable.filteredValue
        : this.projectTable.value;
    } else {
      data = this.dataSetAsync.value;
    }

    return data.length === 0;
  }

  private setYearRange() {
    const companyCreatedFromLS = localStorage.getItem('MEcompanyCreated');
    let companyCreated = '2013';
    if (companyCreatedFromLS && companyCreatedFromLS !== null) {
      companyCreated = '' + (+companyCreatedFromLS.slice(0, 4) - 2);
    }
    const companyCreatedYear: string = companyCreated;
    const threeYearsFromNow = new Date().getFullYear() + 3;
    this.yearRange = companyCreatedYear + ':' + threeYearsFromNow;
  }

  // SUMMATION START

  public getSummation() {
    this.selectedColumns = this.selectedSummationColumns.sort(
      (a, b) => parseFloat(a.order) - parseFloat(b.order)
    );

    this.showSummation = true;
    this.getProjectDataWithSummations();
  }

  public restoreList() {
    this.toggleColumns(false);
    this.projectListSelected = [];
    this.projectListHasSelection = false;
    this.getProjects(this.currentStatus);
  }

  private getProjectDataWithSummations() {
    let variables = {};

    if (this.projectListHasSelection) {
      variables = {
        multiId: this.projectListSelected,
        status: null,
        fromDate: null,
        toDate: null,
      };
    } else {
      const arr = this.projectListSelected;
      variables = {
        multiId: arr,
        status: this.projectStatusForSummationModel,
        fromDate: this.projectSummationDateFilter.fromDate,
        toDate: this.projectSummationDateFilter.toDate,
      };
    }

    this.statusModel = this.projectStatusForSummationModel;

    this.queryUnsubscribe();

    this.loading.next(true);
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'projectsDataWithSummation',
        variables,
        'cache-and-network'
      )
      .subscribe(data => {
        this.projectSub = data.sub;
        if (typeof data.data !== 'undefined') {
          let subData;
          subData = data.data;

          if (subData.hasOwnProperty('company')) {
            this.handleProjectData(data.data, true);
          }
        }
      });
  }

  private appendSummations(data) {
    const totals = {
      cost: 0,
      paydInvoicesAndCredit: 0,
      notPaydInvoices: 0,
      offerSum: 0,
      workedHours: 0,
      workedHoursExtra: 0,

      invoicedHours: 0,
      hoursLeftToInvoice: 0,
      result: 0,
      remainingInvoice: 0,
      notInvoiced: 0,
    };
    this.selectedColumns.map(column => {
      if (typeof totals[column.field] !== 'undefined') {
        column.total = 0;
      }
      return column;
    });

    for (const index in data) {
      if (data.hasOwnProperty(index)) {
        const project = data[index];

        const summationObj = project['summation_PreProjectTypeHyperion'];
        for (const key in summationObj) {
          if (summationObj.hasOwnProperty(key)) {
            project[key + 'ForSort'] = +summationObj[key];
            project[key] = this.helperService.numberFormater(
              summationObj[key],
              0
            );

            totals[key] += summationObj[key];
          }
        }
      }
    }

    this.selectedColumns.map(column => {
      if (typeof totals[column.field] !== 'undefined') {
        column.total = this.helperService.numberFormater(
          +totals[column.field],
          0
        );
      }
      return column;
    });

    return data;
  }

  public exportSummationsExcel(): void {
    let data = [];

    if (this.projectTable) {
      data = this.projectTable.filteredValue
        ? this.projectTable.filteredValue
        : this.projectTable.value;
    } else {
      data = this.dataSetAsync.value;
    }

    const url = this.globalService.getUrlPrefix() + '/company/exportProjects';
    const exportFilename = `projects_export_${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`;

    this.http
      .post(
        url,
        { projects: data.map(project => project.id) },
        {
          responseType: 'blob',
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
        }
      )
      .subscribe((blob: any) => {
        // This is currently the only way to handle custom filename download from POST response in JS
        const downloadEl = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob);

        downloadEl.href = blobUrl;
        downloadEl.download = exportFilename;
        downloadEl.click();

        downloadEl.remove();
        window.URL.revokeObjectURL(blobUrl);
      });
  }

  public exportPerCostTypeExcel() {
    let projectIds = '';
    let data = [];

    if (this.projectTable) {
      data = this.projectTable.filteredValue
        ? this.projectTable.filteredValue
        : this.projectTable.value;
    } else {
      data = this.dataSetAsync.value;
    }

    for (const index in data) {
      if (data.hasOwnProperty(index)) {
        projectIds += 'p' + data[index]['id'];
      }
    }

    location.href =
      this.globalService.getUrlPrefix() +
      '/company/exportPerCostType?projects=' +
      projectIds +
      '&fromDate=' +
      this.projectSummationDateFilter.fromDate +
      '&toDate=' +
      this.projectSummationDateFilter.toDate;
  }

  // SUMMATION END

  // TODO START
  public handleTodoCrud(eventParam) {
    const dataSet = this.dataSetAsync.value;
    let project = dataSet.find(proj => +proj.id === eventParam['projectId']);
    const action = eventParam['action'];
    if (action === 'delete') {
      this.handleDeletedTodo(eventParam, project);
    } else if (action === 'update') {
      this.handleUpdatedTodo(eventParam, project);
    } else if (action === 'create') {
      this.handleCreatedTodo(eventParam, project);
    }
    project = this.setClassesProject(project);
  }

  private handleDeletedTodo(eventParam, project) {
    const index = this.getObjectIndex(
      project['todosList'],
      eventParam['todoId']
    );
    project['todosList'].splice(+index, 1);
  }

  private handleUpdatedTodo(eventParam, project) {
    const todo = project['todosList'].find(
      todoItem => +todoItem.id === eventParam['todoId']
    );
    const emittedTodo = eventParam['todoObj'];
    todo['description'] = emittedTodo['description'];
    todo['done'] = emittedTodo['done'];
  }

  private handleCreatedTodo(eventParam, project) {
    project['todosList'].push(eventParam['todoObj']);
  }

  private allTodosDone(project) {
    return (
      typeof project['todosList'].find(
        todo => todo.done === 0 || todo.done === null
      ) === 'undefined'
    );
  }

  public tooltipTodos(project) {
    let tooltip = this.allTodosDone(project)
      ? 'Alla arbetsmoment är klara'
      : 'Projektet har arbetsmoment';
    const todoList = project['todosList'];

    tooltip +=
      '<br><br><table><thead><tr><th style="padding-right:15px;"><strong>Arbetsmoment</strong></th><th style="padding-right:15px"><strong>Status</strong></th><th><strong>Medarbetare</strong></th></tr></thead><tbody>';

    for (const index in todoList) {
      const todo = todoList[index];
      const description = todo['description'];

      tooltip +=
        '<tr style="border-bottom: solid 1px"><td style="padding: 1px 8px 1px 1px; max-height: 75px; max-width:350px;  float: left;">' +
        description +
        '</td><td style="vertical-align:top; padding: 1px;">' +
        (todo['done'] > 0 ? 'Utfört' : '-') +
        '</td><td>';
      for (const i in todo['users']) {
        const user = todo['users'][i];
        const userLabel =
          user['user']['firstName'] + ' ' + user['user']['lastName'];
        tooltip += +i > 0 ? ', ' + userLabel : userLabel;
      }
      tooltip += '</td></tr>';
    }
    tooltip += '</tbody></table>';

    this.todoTooltipLabel = tooltip;
  }
  // TODO END

  // BOOKMARK START
  public addBookmark(project) {
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'projectBookmark',
        'create',
        false,
        { userId: this.meUser.id, projectId: project['id'] },
        ['id', 'projectId', 'userId']
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            project['projectBookmarks'].push(executedData);
            project['bookmarkedByUserObject'] = executedData;
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  public deleteBookmark(project) {
    const dataToMutation = [];
    dataToMutation['id'] = Number(project['bookmarkedByUserObject']['id']);

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'projectBookmark',
        'delete',
        false,
        dataToMutation
      )
      .subscribe(
        executedData => {
          if (executedData.mutationSucceededAllArguments) {
            if (!this.isProjectBookmarks) {
              project['bookmarkedByUserObject'] = false;
            } else {
              // IF ONLY SHOW BOOKMARKED PROJECTS
              const dataSet = this.dataSetAsync.value;
              dataSet.splice(+this.getObjectIndex(dataSet, project['id']), 1);
              this.dataSetAsync.next([...dataSet]);
            }
          }
          this.mutationService.displayMutationStatus(executedData);
          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
        }
      );
  }

  private getProjectBookmarks() {
    this.apolloQueryService
      .apolloWatchQueryTwo('projectBookmarks', null, 'cache-and-network')
      .subscribe(data => {
        this.projectSub = data.sub;
        this.allLoadingDone['projects'] = true;
        if (this.chekIfLoadingIsDone()) {
          this.loading.next(false);
        }
        if (data.data.hasOwnProperty('me')) {
          this.dataSet = this.setClasses(
            this.projectBookmarksService.makeObjectsForForms(data.data)
          );
          this.dataSetAsync.next(this.dataSet);
        }
      });
  }
  // BOOKMARK END

  ngOnDestroy() {
    this.routerSubscriber && this.routerSubscriber.unsubscribe();
    this.projectSub && this.projectSub.unsubscribe();
    this.userCostTypesSub && this.userCostTypesSub.unsubscribe();
    this.companyCostTypesSub && this.companyCostTypesSub.unsubscribe();
    this.functionsDataSub && this.functionsDataSub.unsubscribe();
    this.projectTypesSub && this.projectTypesSub.unsubscribe();
    this.attendanceReportProjectsSub &&
      this.attendanceReportProjectsSub.unsubscribe();
    this.queryUnsubscribe(); // projectSub and querySub
    this.activeItemLabelService.unsetActiveItem();
  }

  private getClientProjectsNotCopied() {
    const sub = this.apolloQueryService
      .apolloQuery('clients', null)
      .subscribe(({ data }) => {
        this.availableProjects = [];

        const clients = this.apolloQueryService.cleanFromNode(
          data.company.clients
        );
        let hasclientProjectsNotCopied = false;
        clients.map(client => {
          client['clientProjectsNotCopied'] =
            this.apolloQueryService.cleanFromNode(
              client['clientProjectsNotCopied']
            );

          hasclientProjectsNotCopied =
            hasclientProjectsNotCopied ||
            client['clientProjectsNotCopied'].length > 0;
        });
        hasclientProjectsNotCopied && (this.availableProjects = clients);
        sub.unsubscribe();
      });
  }

  public acceptProject(id) {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill kopiera projektet?',
      header: 'Bekräfta val',
      icon: 'fa fa-copy',
      accept: () => {
        const returnVars = ['id'];

        const sub = this.mutationService
          .constructQueryAndExecuteMutation(
            'project',
            'copy',
            false,
            { id: +id },
            returnVars
          )
          .subscribe(executedData => {
            this.mutationService.displayMutationStatus(executedData);
            sub.unsubscribe();
            this.getClientProjectsNotCopied();
            this.getProjects(this.currentStatus);

            this.dialogService.layout = 'wide';
            this.dialogService.data = {
              projectInfo: { id: executedData['id'] },
            };
            this.dialogService.openComponent(
              ShareProjectClientRightsComponent,
              'Samarbetspartners'
            );
          });
      },
      reject: () => {},
    });
  }

  public selectMultiProjectAction(code) {
    if (!this.selectedRows.length) {
      return;
    }

    const selectedProjectsTrueIds = this.selectedRows.map(
      project => project.trueId
    );

    const selectedProjects = this.selectedRows.map(project => ({
      id: +project.id,
      currentStatus: +project.status,
    }));

    switch (code) {
      case 'ongoing':
        this.promptSetOngoingSelectedProjects(
          selectedProjects,
          selectedProjectsTrueIds
        );
        break;
      case 'archive':
        this.promptArchiveSelectedProjects(
          selectedProjects,
          selectedProjectsTrueIds
        );
        break;
      case 'complete':
        this.promptCompleteSelectedProjects(
          selectedProjects,
          selectedProjectsTrueIds
        );
        break;
      default:
        throw new Error('not recognized');
    }
    this.multiProjectAction = [];
  }

  public promptSetOngoingSelectedProjects(
    selectedProjects: { id: number; currentStatus: PROJECT_STATUS }[],
    selectedProjectsTrueIds: string[]
  ) {
    this.confirmationService.confirm({
      message: `Vill du göra följande projekt pågående? ${selectedProjectsTrueIds.join(
        ', '
      )}`,
      header: `Göra ${selectedProjectsTrueIds.length} projekt pågående?`,
      icon: 'fa fa-tasks',
      accept: () =>
        this.updateProjectsStatus(selectedProjects, PROJECT_STATUS.ONGOING),
      reject: () => {},
    });
  }

  public promptArchiveSelectedProjects(
    selectedProjects: { id: number; currentStatus: PROJECT_STATUS }[],
    selectedProjectsTrueIds: string[]
  ) {
    this.confirmationService.confirm({
      message: `Vill du arkivera följande projekt? ${selectedProjectsTrueIds.join(
        ', '
      )}`,
      header: `Arkivera ${selectedProjectsTrueIds.length} projekt?`,
      icon: 'fa fa-archive',
      accept: () =>
        this.updateProjectsStatus(selectedProjects, PROJECT_STATUS.ARCHIVED),
      reject: () => {},
    });
  }

  public promptCompleteSelectedProjects(
    selectedProjects: { id: number; currentStatus: PROJECT_STATUS }[],
    selectedProjectsTrueIds: string[]
  ) {
    this.confirmationService.confirm({
      message: `Vill du avsluta följande projekt? ${selectedProjectsTrueIds.join(
        ', '
      )}`,
      header: `Avsluta ${selectedProjectsTrueIds.length} projekt?`,
      icon: 'fa fa-check',
      accept: () =>
        this.updateProjectsStatus(selectedProjects, PROJECT_STATUS.FINISHED),
      reject: () => {},
    });
  }

  private updateProjectsStatus(
    projects: { id: number; currentStatus: PROJECT_STATUS }[],
    newStatus: PROJECT_STATUS
  ) {
    const mutations$ = [];
    projects.forEach(project => {
      mutations$.push(
        this.projectIndexService.getMutationQuery(
          project.id,
          project.currentStatus,
          newStatus
        )
      );
    });

    forkJoin(mutations$).subscribe(data => {
      const successIds = [];
      const failIds = [];

      data.forEach((mutationResult: any) => {
        if (mutationResult.error) {
          failIds.push(mutationResult.error.projectTypeHyperionMutation.trueId);
        } else {
          successIds.push(mutationResult.projectTypeHyperionMutation.trueId);
        }
      });

      if (failIds.length) {
        this.messageService.insertData({
          summary: 'Följande projekt kunde inte flyttas',
          textArray: [failIds.join(', ')],
          type: 'error',
        });
      }

      if (successIds.length) {
        this.messageService.insertData({
          summary: 'Följande projekt flyttades',
          textArray: [successIds.join(', ')],
          type: 'success',
        });
      }

      this.selectedRows = [];
    });
  }
}
