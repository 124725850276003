<p-table class="inline-table" [value]="projects" [columns]="columns">
  <ng-template pTemplate="header" let-cols>
    <tr>
      <th *ngFor="let col of cols" [style.width]="col.width">
        {{ col.header }}
      </th>
      <th [style.width]="'4rem'"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-cols="columns" let-project>
    <tr
      [style.position]="'relative'"
      pTooltip="Visa projekt"
      tooltipPosition="top"
    >
      <td
        *ngFor="let col of cols"
        [style.width]="col.width"
        [style.justify-content]="col.field === '' ? 'center' : ''"
      >
        <a [routerLink]="['../../', project.id]" class="stretched-link"></a>
        {{ project[col.field] }}
      </td>
      <td class="text-cell-center">
        <i class="pi pi-external-link"></i>
      </td>
    </tr>
  </ng-template>
</p-table>
