import { Injectable } from '@angular/core';

import { GlobalService } from '../../global/index';
import { HttpService } from 'app/shared/http';
import { ContactValidator } from 'app/shared/contacts/contact-validator';

@Injectable()
export class ContactsAutosuggestService {
  urlPrefix;

  constructor(
    private globalService: GlobalService,
    private httpService: HttpService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  getContacts(query, type = 0) {
    const url =
      this.urlPrefix +
      '/contact/AutoComplete?q=' +
      query +
      '&type=' +
      type +
      '&limit=10&timestamp=1503558949530';
    return this.httpService.makeHttpGetRequest(url);
  }

  setAutoCompleteOnInit(autoCompleteParam, model, dataParam) {
    const autoComplete = autoCompleteParam;
    const data = dataParam;

    autoComplete[model]['model'] = ContactValidator.isValidTrueId(
      data[model + 'Contact']['trueId']
    )
      ? data[model + 'Contact']['trueId']
      : null;
    if (ContactValidator.isValidTrueId(data[model + 'Contact']['trueId'])) {
      autoComplete[model]['setFromStart'].next(true);
      autoComplete[model]['showSave'].next(false);
    } else {
      autoComplete[model]['setFromStart'].next(false);
      autoComplete[model]['showSave'].next(true);
    }
  }
}
