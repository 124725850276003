import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DocumentColumn, ViewId } from '../document';
import { List } from 'immutable';
import { DocumentChange } from '../document-change';

@Component({
  selector: 'app-docu-columns',
  templateUrl: './docu-columns.component.html',
  styleUrls: ['./docu-columns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocuColumnsComponent {
  @Input() columns: List<DocumentColumn>;
  @Input() showLabels = true;
  @Input() labelsOnly = false;
  @Input() width: number;
  @Output() docChange: EventEmitter<DocumentChange> = new EventEmitter();

  trackColById(index: number, item: DocumentColumn): ViewId {
    return item.col.viewId;
  }
}
