<div class="p-grid" style="min-height: 40vh">
  <div class="p-col-12">
    <h2>Fakturamall</h2>
  </div>

  <div class="p-col-12">
    <spinner *ngIf="loading"></spinner>
  </div>

  <div class="p-col-12">
    <form *ngIf="!loading" [formGroup]="componentMainForm">
      <div class="p-grid p-g-nopad" *ngIf="notImageError">
        <div class="p-col-12">
          <h3>Justera loggans bredd</h3>
        </div>
        <div class="p-col-6">
          <p-slider
            [max]="max"
            [min]="min"
            [(ngModel)]="invoiceLogoWith"
            [ngModelOptions]="{ standalone: true }"
          ></p-slider>
        </div>
        <div class="p-col-12" *ngIf="id">
          <img
            [src]="logoUrl"
            [style.max-width.px]="invoiceLogoWith"
            (error)="onImgError()"
          />
        </div>
      </div>
      <div class="p-grid p-g-nopad">
        <div class="p-col-12">
          <h3>
            Generella fakturatexter
            <small>Visas ovanför / nedanför fakturaraderna</small>
          </h3>
        </div>
        <div class="p-col-6">
          <label>Huvud</label>
          <input type="text" pInputText formControlName="header" />
        </div>

        <div class="p-col-6">
          <label>Fot</label>
          <input type="text" pInputText formControlName="footer" />
        </div>
      </div>
    </form>
  </div>

  <div class="p-col-12 align-right" *ngIf="componentMainForm">
    <button
      pButton
      type="button"
      class="p-button-primary-hyperion"
      [disabled]="disableButton || loading"
      (click)="actionUpdate()"
      label="Uppdatera"
      icon="pi pi-save"
    ></button>
  </div>
</div>
