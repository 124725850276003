<div class="page-container p-grid">
  <p-card class="p-col-12">
    <ng-template pTemplate="header">
      <h3>Ladda upp dokument</h3>
    </ng-template>
    <p-fileUpload
      [url]="'/project/UploadDocuments/projectId/' + projectId"
      (onUpload)="fetchDocuments()"
      name="DocumentsForm[]"
      chooseLabel="Ange fil(er)"
      [multiple]="true"
      uploadLabel="Ladda upp"
      cancelLabel="Avbryt"
      invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
      invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
    >
      <ng-template pTemplate="content">
        <app-drag-and-drop-template></app-drag-and-drop-template>
      </ng-template>
    </p-fileUpload>
  </p-card>
  <p-table
    [value]="documents | async"
    dataKey="id"
    editMode="row"
    class="p-col-12"
  >
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [style.width]="col.width"
          [style.whiteSpace]="'break-spaces'"
        >
          <span class="p-d-flex p-ai-center p-jc-between">
            {{ col.title }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
          </span>
        </th>
        <th [style.width]="'4rem'"></th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-document
      let-editing="editing"
      let-ri="rowIndex"
    >
      <tr>
        <td [style.whiteSpace]="'break-spaces'">{{ document.created }}</td>
        <td class="p-text-truncate">
          {{ document.file }}
        </td>

        <td
          [pEditableColumn]="document.comment"
          pEditableColumnField="comment"
          [style.whiteSpace]="'break-spaces'"
        >
          <p-cellEditor (change)="updateDocument(document)">
            <ng-template pTemplate="input">
              <input pInputText [(ngModel)]="document.comment" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ document.comment }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td
          [pEditableColumn]="document.publicForAllUsers"
          pEditableColumnField="publicForAllUsers"
          class="p-text-center"
        >
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-checkbox
                [(ngModel)]="document.publicForAllUsers"
                [binary]="true"
                (onChange)="updateDocument(document)"
              ></p-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <i
                class="pi {{
                  document.publicForAllUsers ? 'pi-check' : 'pi-times'
                }}"
              ></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td
          [pEditableColumn]="document.public"
          pEditableColumnField="public"
          class="p-text-center"
        >
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-checkbox
                [(ngModel)]="document.public"
                [binary]="true"
                (onChange)="updateDocument(document)"
              ></p-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <i class="pi {{ document.public ? 'pi-check' : 'pi-times' }}"></i>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="p-text-center">
          <p-menu #menu appendTo="body" [popup]="true" [model]="menus[ri]">
          </p-menu>
          <button
            pButton
            (click)="menu.toggle($event)"
            class="p-button-text legacy-rounded-button"
            icon="pi pi-ellipsis-v"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
