import { Injectable } from '@angular/core';
import { GlobalService } from 'app/shared/global';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ExternalProject } from './external-project.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectExternalProjectConnectionService {
  private URL = this.globalService.getUrlPrefix() + '/project/ConnectExternal/';

  constructor(
    private httpClient: HttpClient,
    private globalService: GlobalService
  ) {}

  public setExternalProjectId(
    projectId: number,
    projectToSet: ExternalProject
  ) {
    const httpParams: HttpParams = new HttpParams()
      .set('source', projectToSet.source)
      .set('sourceId', projectToSet.id);

    return this.httpClient.post(this.URL + `${projectId}`, httpParams);
  }
}
