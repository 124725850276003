import {
  Component,
  forwardRef,
  Injector,
  OnInit,
  AfterContentInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';

import { FormCommonFunctions2 } from '../form-common-functions';

@Component({
  selector: 'appForm2-simple-checkbox',
  styleUrls: ['../form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleFormListCheckBox2Component),
      multi: true,
    },
  ],
  template: `
    <div class="p-col-12">
      <div
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        *ngIf="!showToolTip"
        [innerHTML]="htmlAttributes['label']['value'] || control.label"
      ></div>
      <div
        class="form-control-label {{
          htmlAttributes['label']['responsive'] ? 'responsive-label' : null
        }}"
        *ngIf="showToolTip"
      >
        {{ htmlAttributes['label']['value'] || control.label }}
        <span
          class="{{ tooltip['class'] }}"
          [tooltipStyleClass]="tooltip['tooltipStyleClass']"
          [tooltipPosition]="tooltip['tooltipPosition']"
          [pTooltip]="tooltip['msg']"
          [escape]="false"
        >
          ?</span
        >
      </div>

      <div style="height: 30px">
        <p-checkbox
          [binary]="true"
          class="checker"
          [disabled]="disabled"
          [(ngModel)]="value"
          (onChange)="pushChanges($event.checked)"
          [attr.id]="htmlAttributes['input']['id']"
          [attr.form-control-name]="control['name']"
        ></p-checkbox>
      </div>

      <div *ngIf="control.showErrors" class="vendium-tool-tip">
        <p
          *ngFor="let error of control.mergedErrors"
          class="form-control-feedback"
        >
          <span><i class="fa fa-times"></i> </span
          ><span [innerHTML]="error"></span>
        </p>
      </div>
    </div>
  `,
})
export class SimpleFormListCheckBox2Component
  extends FormCommonFunctions2
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  value: boolean;
  onChange: (_: any) => void;
  onTouched: () => void;
  disabled: boolean;

  ngControl: NgControl;

  constructor(private inj: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.inj.get(NgControl);
    this.control = this.ngControl;
  }

  ngAfterContentInit() {
    this.control = this.ngControl.control;
  }

  writeValue(value: any): void {
    this.value = value !== undefined ? !!value : null;
  }
  pushChanges(value: boolean) {
    this.onChange(value ? 1 : 0);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
