import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloMutationService } from '../../shared/apollo/index';
import { FormHandlerService } from '../../shared/forms/index';
import { GlobalService } from '../../shared/global/index';
import { HelperService } from '../../shared/helpers/index';

@Component({
  selector: 'project-installment-create',
  templateUrl: 'project-installment-create.component.html',
  providers: [FormHandlerService],
})
export class ProjectInstallmentCreateComponent implements OnInit {
  buttons;
  componentMainForm: FormGroup;
  formFields = {
    model: 'ProjectInstallment',
    attributes: {
      responsibleUserId: null,
      dateToInvoice: null,
      name: '',
    },
  };
  dataModel = 'projectInstallment';
  dataModelCapitalized;
  createSuccess = 'false';
  public projectInfo;
  public usersDropdown;
  public labels;

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    protected globalService: GlobalService,
    protected formBuilder: FormBuilder,
    protected mutationService: ApolloMutationService,
    protected helperService: HelperService,
    protected formHandler: FormHandlerService
  ) {
    this.formFields['attributes']['dateToInvoice'] =
      this.helperService.dateFormatTimeZone(new Date(), true);
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
  }

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }

    const buttonObject = {
      create: {
        model: this.dataModel,
      },
    };

    this.buttons = this.formHandler.getButtonValues(buttonObject);

    this.dataModelCapitalized = this.globalService.capitalizeFirstLetter(
      this.dataModel
    );

    this.formInit();
  }

  private initComponentWithDialogData() {
    this.projectInfo = this.dialogConfig['data']['projectInfo'];
    this.labels = this.dialogConfig['data']['labels'];
    this.usersDropdown = this.dialogConfig['data']['usersDropdown'];
    setTimeout(() => {
      this.dialogConfig.header = 'Skapa ny delbetalning';
    }, 0);
  }

  formInit() {
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = this.returnDataToMutation();

    this.buttons = this.formHandler.lockButtons(this.buttons);

    this.formHandler.setLoadingClasses('start');

    if (!this.formHandler.formValid([this.componentMainForm])) {
      this.buttons = this.formHandler.unlockButtons(this.buttons);
      this.formHandler.setLoadingClasses();
    } else {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            if (executedData.mutationSucceededAllArguments) {
              this.createSuccess = 'success';
              this.closeAction();
            }

            this.buttons = this.formHandler.unlockButtons(this.buttons);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.buttons = this.formHandler.unlockButtons(this.buttons);
          }
        );
    }
  }

  returnDataToMutation() {
    const dataToMutation = {
      responsibleUserId:
        this.componentMainForm.controls['responsibleUserId']['value'],
      dateToInvoice: this.componentMainForm.controls['dateToInvoice']['value'],
      name: this.componentMainForm.controls['name']['value'],
      projectId: this.projectInfo['id'],
    };

    return dataToMutation;
  }

  closeAction() {
    this.dialogRef.close(this.createSuccess);
  }
}
