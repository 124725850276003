<div>
  <div>
    <spinner [hidden]="!loading"></spinner>
    <div class="p-grid">
      <span class="p-col-5">
        <label>Prefix</label>
      </span>
      <span class="p-col-4">
        <label>Namn</label>
      </span>
    </div>
    <div class="p-grid" *ngFor="let pt of projectTypes">
      <span class="p-col-5">
        <input pInputText [(ngModel)]="pt.prefix" />
      </span>
      <span class="p-col-4">
        <input pInputText [(ngModel)]="pt.name" />
      </span>
      <span class="p-col-3">
        <button
          pButton
          [style.width]="'100%'"
          label="Radera"
          icon="pi pi-trash"
          (click)="onDelete(pt.id)"
        ></button>
      </span>
    </div>
    <div class="p-grid">
      <span class="p-col-5">
        <input pInputText #prefix placeholder="Prefix" />
      </span>
      <span class="p-col-4">
        <input pInputText #name placeholder="Namn" />
      </span>
      <span class="p-col-3">
        <button
          pButton
          [style.width]="'100%'"
          label="Lägg till"
          icon="pi pi-plus"
          (click)="onAdd(prefix.value, name.value)"
        ></button>
      </span>
      <span class="p-col-9"></span>
      <span class="p-col-3">
        <button
          pButton
          [style.width]="'100%'"
          label="Spara"
          icon="pi pi-save"
          class="p-button-primary-hyperion"
          (click)="onSave()"
        ></button>
      </span>
    </div>
  </div>
</div>
