import { Injectable } from '@angular/core';

import { HttpService } from 'app/shared/http';
import { GlobalService } from '../../global/index';
import { Observable, of as observableOf, Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

@Injectable()
export class ProductsAutosuggestService {
  urlPrefix;

  private searchQuery: Subject<string> = new Subject();
  private searchQuery$ = this.searchQuery.asObservable();
  public searchQueryOutput: Observable<any>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private query: string;
  private stored: any;

  constructor(
    private httpService: HttpService,
    private globalService: GlobalService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  getProducts(query, stored, limit = 50): Promise<any> {
    let useStored;

    if (+stored === -1) {
      useStored = '-1';
    } else {
      if (stored) {
        useStored = '1';
      } else {
        useStored = '0';
      }
    }

    const url =
      this.urlPrefix +
      '/product/AutoComplete?q=' +
      query +
      '&limit=' +
      limit +
      '&stored=' +
      useStored;
    return this.httpService
      .makeHttpGetRequest(url)
      .then(({ data }) => observableOf(data).toPromise());
  }

  public setupSearchQuery(): void {
    this.searchQueryOutput = this.searchQuery$.pipe(
      debounceTime(800),
      takeUntil(this.destroy$),
      switchMap(() => {
        return this.getProducts(this.query, this.stored).then(products => {
          return products;
        });
      })
    );
  }

  public searchNext(query: string, stored: any = 0): void {
    this.stored = stored;
    this.query = query;
    this.searchQuery.next(query);
  }

  public destroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
