import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContactTypeHyperionMutationInput } from '../../../generated/types';
import {
  ContactFormSelectGQL,
  ContactFormSelectQuery,
  PaymentTermsGQL,
} from './graphql/contact.query.generated';
import {
  ContactFormCreateGQL,
  ContactFormUpdateGQL,
  ContactFormCreateMutation,
  ContactFormUpdateMutation,
} from './graphql/contact.mutation.generated';
import { first, map } from 'rxjs/operators';
import { MessageService } from 'app/shared/message';
import { MutationResult } from 'apollo-angular';

export enum ContactFormContext {
  Customer,
  Supplier,
  ProjectClient,
  ProjectEstablishment,
}

export enum ContactType {
  Private = 'Privat',
  Business = 'Företag',
}

export enum Contact {
  Child,
  Parent,
}

type ContactQueryResponse =
  ContactFormSelectQuery['company']['allContacts']['edges'][0]['node'];

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  @Output() public contactUpdatedEvent = new EventEmitter<number>();

  @Input() set context(c: ContactFormContext) {
    this.contactIsCustomer = c === ContactFormContext.Customer;
    this.contactIsSupplier = c === ContactFormContext.Supplier;
    this.contactIsProjectClient = c === ContactFormContext.ProjectClient;
    this.contactIsProjectEstablishment =
      c === ContactFormContext.ProjectEstablishment;
    this.initForm();
  }

  @Input() set contactId(contactId: number) {
    this.loading = true;

    if (!contactId) {
      this.contactData = this.DEFAULT_CONTACT_DATA;
      this.isEdit = false;
      this.initForm();
      this.loading = false;
      return;
    }

    this.selectContactService
      .fetch({ contactId: contactId })
      .pipe(first())
      .subscribe(response => {
        const contact = response?.data?.company?.allContacts?.edges?.[0]?.node;
        this.contactData = {
          ...contact,
          contactType: contact.contactType ?? ContactType.Business,
        };
        this.isEdit = true;
        this.initForm();
        this.loading = false;
      });
  }

  @Input() public showSearch = true;

  public loading: boolean;
  public readonly DEFAULT_CONTACT_DATA: ContactQueryResponse = {
    id: null,
    contactType: ContactType.Business,
    betalningsvillkor: 30,
  };
  public contactData: ContactQueryResponse = this.DEFAULT_CONTACT_DATA;
  public paymentTermOptions = [
    { label: '0 dagar', value: 0 },
    { label: '10 dagar', value: 10 },
    { label: '15 dagar', value: 15 },
    { label: '20 dagar', value: 20 },
    { label: '30 dagar', value: 30 },
  ];
  public contactTypeOptions = [
    { label: ContactType.Private, value: ContactType.Private },
    { label: ContactType.Business, value: ContactType.Business },
  ];
  public contactForm: FormGroup;

  public isEdit = false;
  public contactIsCustomer = true;
  public contactIsSupplier = false;
  public contactIsProjectClient = false;
  public contactIsProjectEstablishment = false;
  public contactIsPrivate = true;
  public saveProjectClientOptions: [{ label: string; command: () => void }] = [
    {
      label: 'Spara som ny kontakt',
      command: () => this.createParentFromChildContact(),
    },
  ];

  constructor(
    private selectContactService: ContactFormSelectGQL,
    private updateContactService: ContactFormUpdateGQL,
    private createContactService: ContactFormCreateGQL,
    private paymentTermsGQL: PaymentTermsGQL,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    if (!this.context) {
      this.initForm();
    }
  }

  public onSubmit(): void {
    this.contactForm.markAllAsTouched();
    if (!this.contactForm.valid) {
      return;
    }

    if (this.isEdit) {
      this.updateContact();
    } else {
      this.createContact();
    }
  }

  private updateContact(): void {
    const contact: ContactTypeHyperionMutationInput = {
      id: this.contactData.id,
      trueId: this.contactData.trueId,
      contact: this.contactData.contact,
      contactParentId: this.contactData.contactParentId,
      type: this.contactData.type,
      contactType: this.contactData.contactType,
      ...this.contactForm.value,
      hourCost: this.contactForm.value.hourCost
        ? this.contactForm.value.hourCost
        : null,
      costProcent: this.contactForm.value.costProcent
        ? this.contactForm.value.costProcent
        : null,
    };

    this.updateContactService
      .mutate({
        updateContactObject: contact,
      })
      .pipe(first())
      .subscribe(result => this.afterMutation(result));
  }

  private createContact(): void {
    const contact: ContactTypeHyperionMutationInput = {
      contactParentId: this.contactData.contactParentId,
      contact: this.contactIsProjectEstablishment ? 0 : 1,
      type: this.contactIsSupplier ? 1 : 0,
      ...this.contactForm.value,
    };

    this.createContactService
      .mutate({
        createContactObject: contact,
      })
      .pipe(first())
      .subscribe(result => this.afterMutation(result));
  }

  public createParentFromChildContact(): void {
    const parentContact: ContactTypeHyperionMutationInput = {
      contactParentId: null,
      contact: Contact.Parent,
      ...this.contactForm.value,
    };

    this.createContactService
      .mutate({
        createContactObject: parentContact,
      })
      .pipe(first())
      .subscribe(result => {
        this.afterMutation(result);

        if (result.data) {
          const childContact = {
            ...parentContact,
            id: Number(this.contactData.id),
            contact: Contact.Child,
            contactParentId: Number(result.data.contactTypeHyperionMutation.id),
            trueId: result.data.contactTypeHyperionMutation.trueId,
          };

          this.updateContactService
            .mutate({ updateContactObject: childContact })
            .pipe(first())
            .subscribe(updateResult => this.afterMutation(updateResult));
        }
      });
  }

  private afterMutation(
    result: MutationResult<
      ContactFormCreateMutation | ContactFormUpdateMutation
    >
  ): void {
    const mutationData = result?.data?.contactTypeHyperionMutation;
    if (!mutationData) {
      return;
    }
    this.messageService.insertDataFromMutation(mutationData);

    const mutationDetails = mutationData?.mutationDetails[0];
    const isMutationSuccessful =
      mutationDetails?.errorsMsgs.length === 0 ?? true;

    if (isMutationSuccessful) {
      this.contactUpdatedEvent.emit(Number(mutationData.id));
      if (!this.isEdit) {
        this.resetForm();
      } else {
        this.contactId = Number(mutationData.id);
      }
    }
  }

  private initForm(): void {
    this.contactIsPrivate =
      this.contactData.contactType === ContactType.Private;

    this.contactForm = new FormGroup({
      orgNr: new FormControl(this.contactData.orgNr),
      contactType: new FormControl(this.contactData.contactType),
      orderBuisnessName: new FormControl(
        this.contactData.orderBuisnessName,
        this.contactData.contactType === ContactType.Business &&
        !this.contactIsProjectEstablishment
          ? [Validators.required]
          : []
      ),
      name: new FormControl(
        this.contactData.name,
        this.contactData.contactType === ContactType.Private &&
        !this.contactIsProjectEstablishment
          ? [Validators.required]
          : []
      ),
      betalningsvillkor: new FormControl(this.contactData.betalningsvillkor),
      address: new FormControl(this.contactData.address),
      address2: new FormControl(this.contactData.address2),
      cityCode: new FormControl(this.contactData.cityCode),
      city: new FormControl(this.contactData.city),
      propertyName: new FormControl(this.contactData.propertyName),
      housingAssociationOrgNumber: new FormControl(
        this.contactData.housingAssociationOrgNumber
      ),
      apartmentDesignation: new FormControl(
        this.contactData.apartmentDesignation
      ),
      phone: new FormControl(this.contactData.phone),
      mobilePhone: new FormControl(this.contactData.mobilePhone),
      mail: new FormControl(this.contactData.mail),
      mailInvoice: new FormControl(this.contactData.mailInvoice),
      reverseTax: new FormControl(this.contactData.reverseTax === 1),
      isVATRegistered: new FormControl(
        this.contactData.isVATRegistered === 1 ||
          (this.contactData.contactType === ContactType.Business &&
            this.contactData.isVATRegistered === undefined)
      ),
      hourCost: new FormControl(this.contactData.hourCost),
      costProcent: new FormControl(this.contactData.costProcent),
      plusgiro: new FormControl(this.contactData.plusgiro),
      bankgiro: new FormControl(this.contactData.bankgiro),
      bicSwift: new FormControl(this.contactData.bicSwift),
      iban: new FormControl(this.contactData.iban),
      note: new FormControl(this.contactData.note),
    });

    if (this.contactIsSupplier || this.contactData.contactParentId) {
      this.contactForm.controls.contactType.disable();
    } else {
      this.contactForm.controls.contactType.enable();
    }
    if (!this.contactData.contactParentId && !this.contactData.id) {
      this.setPaymentTerms();
    }
  }

  public onParentContactSelected(contactId: number): void {
    this.selectContactService
      .fetch({ contactId: contactId })
      .pipe(
        first(),
        map(r => r.data.company.allContacts.edges.map(e => e.node))
      )
      .subscribe(contacts => {
        const contact = contacts[0];
        if (!contact) {
          return;
        }

        const contactData: ContactQueryResponse = { ...this.contactData };
        for (const key in contact) {
          contactData[key] = contact[key];
        }

        if (this.isEdit) {
          contactData.contactParentId = contactId;
        }

        contactData.id = this.contactData.id;
        contactData.contact = this.contactData.contact;
        this.contactData = contactData;
        this.initForm();
      });
  }

  public onContactTypeChanged($event: {
    originalEvent: Event;
    value: string;
  }): void {
    this.contactIsPrivate =
      this.contactForm.value.contactType === ContactType.Private;

    this.contactForm.controls.orderBuisnessName.clearValidators();
    this.contactForm.controls.name.clearValidators();

    if (this.contactIsProjectEstablishment) {
      this.contactForm.controls.orderBuisnessName.setValidators([]);
      this.contactForm.controls.name.setValidators([]);
    } else {
      this.contactForm.controls.name.setValidators(
        this.contactIsPrivate ? [Validators.required] : []
      );
      this.contactForm.controls.orderBuisnessName.setValidators(
        this.contactIsPrivate ? [] : [Validators.required]
      );
    }

    this.contactForm.controls.orderBuisnessName.updateValueAndValidity();
    this.contactForm.controls.name.updateValueAndValidity();
  }

  private resetForm(): void {
    this.contactForm.reset();
    this.contactId = null;
  }

  public removeParent(): void {
    this.contactData = {
      ...this.contactData,
      trueId: null,
      contactParentId: null,
    };
    this.contactForm.controls.contactType.enable();
  }

  private setPaymentTerms(): void {
    this.paymentTermsGQL
      .fetch()
      .pipe(first())
      .subscribe(res => {
        this.contactForm.controls.betalningsvillkor.patchValue(
          res.data.company.betalningsvillkor
        );
      });
  }
}
