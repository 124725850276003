import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

import { GlobalService } from 'app/shared/global/index';
import { HelperService } from 'app/shared/helpers/index';

@Component({
  selector: 'home-start-projectComments-holder',
  templateUrl: 'project-comments-read-holder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeStartProjectCommentsReadHolder implements OnChanges {
  @Input() projectCommentsFromParent;
  @Input() loading = true;
  dataSet;
  urlPrefix;

  constructor(
    private helperService: HelperService,
    private globalService: GlobalService
  ) {
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectCommentsFromParent'].currentValue['edges']) {
      this.dataSet = this.helperService.cleanFromNode(
        changes['projectCommentsFromParent'].currentValue
      );
    }
  }
}
