import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { HyperionSharedModule } from 'app/shared/shared.module';
import { LoadingModule } from 'app/shared/loading/loading.module';
import { FreeUsersComponent } from './subcontractor-edit/free-users/free-users.component';
import { SubcontractorEditComponent } from './subcontractor-edit/subcontractor-edit.component';
import { SubcontractorHandleComponent } from './subcontractor-handle/subcontractor-handle.component';
import { AttendanceReportDialogsModule } from 'app/attendance-report/handle/attendance-report-dialogs/attendance-report.dialogs.module';
import { HeaderModule } from 'app/header/header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    InputTextModule,
    TableModule,
    TooltipModule,

    HyperionCardModule,
    HyperionSharedModule,
    LoadingModule,
    AttendanceReportDialogsModule,
    HeaderModule,
  ],
  declarations: [
    SubcontractorHandleComponent,
    SubcontractorEditComponent,
    FreeUsersComponent,
  ],
})
export class SubcontractorModule {}
