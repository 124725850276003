import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EventColorsEnum } from 'app/planner/planner-module-enums';
import { LightboxDropdown } from 'app/planner/planner-module-interfaces';
import { ColorNames } from '../color-names';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Input() public form: FormGroup;
  @Input() public controlName: string;

  public colors: LightboxDropdown[] = [];

  constructor() {
    this.generateColorsDropdown();
  }

  private generateColorsDropdown(): void {
    this.colors = [];
    this.colors = Object.keys(EventColorsEnum).map(c => ({
      value: EventColorsEnum[c],
      label: c,
    }));
  }

  public getColorName(color: EventColorsEnum): string {
    return ColorNames[color];
  }
}
