<div class="p-grid">
  <div class="p-col-12">
    <div style="width: 800px"></div>
    <p-fileUpload
      name="PictureForm[]"
      accept="image/*,.heif,.heic"
      chooseLabel="Ange fil(er)"
      [multiple]="true"
      [url]="urlPrefix + '/old-project/uploadPictures/projectId/' + projectId"
      uploadLabel="Ladda upp"
      cancelLabel="Avbryt"
      invalidFileTypeMessageSummary="Otillåten filtyp för: {0},"
      invalidFileTypeMessageDetail="tillåtna filtyper är: {0}."
      (onUpload)="onUpload($event)"
    >
      <ng-template pTemplate="content">
        <app-drag-and-drop-template></app-drag-and-drop-template>
      </ng-template>
    </p-fileUpload>
  </div>
</div>
