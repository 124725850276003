import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { FormHandlerService } from '../forms/index';
import { HyperionLabelsService } from '../../shared/labels/index';
import { MessageService } from '../../shared/message/index';
import { GlobalService } from '../../shared/global/index';
import { HelperService } from '../../shared/helpers/helper.service';
import { HttpService } from '../../shared/http/http.service';
import { ServiceLocator } from '../../shared/helpers/index';

@Component({
  selector: 'dialog-component',
  template: '',
  providers: [FormHandlerService],
})
export class DialogComponent {
  protected labelsService: HyperionLabelsService;
  protected formBuilder: FormBuilder;
  protected messageService: MessageService;
  protected httpService: HttpService;
  protected globalService: GlobalService;
  protected helperService: HelperService;
  protected http: HttpClient;
  protected formHandler: FormHandlerService;

  constructor() {
    this.formBuilder = ServiceLocator.injector.get(FormBuilder);
    this.formHandler = ServiceLocator.injector.get(FormHandlerService);
    this.labelsService = ServiceLocator.injector.get(HyperionLabelsService);
    this.messageService = ServiceLocator.injector.get(MessageService);
    this.httpService = ServiceLocator.injector.get(HttpService);
    this.globalService = ServiceLocator.injector.get(GlobalService);
    this.helperService = ServiceLocator.injector.get(HelperService);
    this.http = ServiceLocator.injector.get(HttpClient);
  }
}
