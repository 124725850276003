import { Component, Input, OnInit } from '@angular/core';
import { ProjectProductCostFragment } from '../project-overview/graphql/project-overview.generated';

@Component({
  selector: 'app-project-overview-material',
  templateUrl: './project-overview-material.component.html',
  styleUrls: ['./project-overview-material.component.scss'],
})
export class ProjectOverviewMaterialComponent implements OnInit {
  @Input() public costs: ProjectProductCostFragment;

  constructor() {}

  ngOnInit(): void {}
}
