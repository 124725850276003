import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { GlobalService } from '../../../../shared/global/index';

import { CompanySuperService } from '../../company.super.service';

@Injectable()
export class CompanyProjectsForStatusZeroService extends CompanySuperService {
  dataModel = 'project';
  modelForObs = 'companyProjects';
  isEstablished = false;

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode, isProjectsWithDeleted = false) {
    // mode == labels || list

    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(
          data,
          isProjectsWithDeleted
        );
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    dataObjectsWithLabels.push({ label: 'Visa alla projekt', value: null });

    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.trueId + ', ' + dataArray[i].node.mark,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data, isProjectsWithDeleted) {
    let dataModel = this.dataModel + 's';
    if (isProjectsWithDeleted) {
      dataModel = 'projectsWithDeleted';
    }

    const dataArray = data.company[dataModel].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const projectInfo =
        dataArray[i]['node']['trueId'] + ', ' + dataArray[i]['node']['mark'];

      const dataObject = { ...dataArray[i].node };

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }

      if (dataObject['typeId'] === null) {
        dataObject['typeId'] = 0;
      }

      dataObject['projectInfo'] = projectInfo;
      dataObject['projectBookmarks'] = this.cleanFromNode(
        dataObject['projectBookmarks']
      );

      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms['extra'] == null) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }

  makeObjectsForFormsByModel(data, dataModel) {
    const dataArray = data.company[dataModel].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const projectInfo =
        dataArray[i]['node']['trueId'] + ', ' + dataArray[i]['node']['mark'];

      const dataObject = { ...dataArray[i].node };

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }

      if (dataObject['typeId'] === null) {
        dataObject['typeId'] = 0;
      }

      dataObject['projectInfo'] = projectInfo;
      dataObject['projectBookmarks'] = this.cleanFromNode(
        dataObject['projectBookmarks']
      );

      dataObjectsForForms.push(dataObject);
    }

    return dataObjectsForForms;
  }
}
