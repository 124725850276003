import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { HtmlModalService } from 'app/shared/html-modal';
import { HttpService } from '../../shared/http/index';
import { CompanyFunctionsService } from '../../shared/company/index';
import { HelperService } from '../../shared/helpers';
import { ApolloQueryService } from 'app/shared/apollo';
import { SortService } from 'app/store/sort.service';
import { MessageService } from 'app/shared/message';

@Component({
  selector: 'day-report',
  templateUrl: 'day-report.component.html',
  styleUrls: ['day-report.component.scss'],
})
export class DayReportComponent implements OnInit {
  meUser = {
    firstName: null,
    lastName: null,
    fullName: null,
    type: null,
    id: null,
    username: null,
    companyName: null,
  };
  checkForCompanyFunctions = [
    'useProjectTypes',
    'usePickUserCostTypeOnTimereport',
    'useOnlyTimestampTimeReport',
    'usePickOvertimeOnTimereport',
    'setMile',
    'setPrivMile',
    'useNotarized',
  ];
  columns: string[] = [];

  columnsCheckBoxes = [
    'date',
    'hours',
    'mile',
    'privMile',
    'costTypes',
    'user',
    'doneWork',
    'project',
    'todo',
    'allowance',
    'attendance',
    'notarized',
  ];
  layoutCheckBoxes = [
    'onlyUserSums',
    'sumAttendance',
    'sumTodos',
    'sumWorkType',
    'sumSubsistence',
  ];
  sortFieldsColumns = {};
  sortFieldsLayout = {};

  public mode;
  public users;
  public project;
  public timeSpan;
  public dataSet: {
    days: any[];
    daysLeave: any[];
  };
  public rowGrouping;
  public functionsThisModel;
  public order: 'latestFirst' | 'oldestFirst' = 'latestFirst';

  pdfWindow;
  loading = false;
  preview = true;
  pdfUrl: SafeUrl;
  page = 1;
  head = 'current';
  layout = 'portrait';
  showSubstistenceOptions: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public selectProject: string | number;

  constructor(
    private htmlModalService: HtmlModalService,
    private helperService: HelperService,
    private httpService: HttpService,
    private domSanitizer: DomSanitizer,
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private companyFunctionsService: CompanyFunctionsService,
    private apolloQueryService: ApolloQueryService,
    private sortService: SortService,
    private messageService: MessageService
  ) {
    this.setObjsForSortFields();
  }

  private setObjsForSortFields() {
    this.columnsCheckBoxes.forEach(sortField => {
      this.sortFieldsColumns[sortField] = {
        attribute: 'null',
        ascDesc: false,
        object: sortField + '_dayreport_column',
      };
    });

    this.layoutCheckBoxes.forEach(sortField => {
      this.sortFieldsLayout[sortField] = {
        attribute: 'null',
        ascDesc: false,
        object: sortField + '_dayreport_layout',
      };
    });
  }

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }
    this.getSessionSettingsLS();
    this.getCompanySettings();

    this.previewCall();
    this.setSortFields();
  }

  private setSortFields() {
    Object.keys(this.sortFieldsColumns).forEach(sortField => {
      this.sortFieldsColumns[sortField] = this.sortService.getSort(
        this.sortFieldsColumns[sortField]
      );

      if (this.sortFieldsColumns[sortField]['ascDesc'] === 1) {
        this.columns.push(sortField);
      }
    });

    const initialColumns = ['date', 'hours', 'user', 'project'];

    this.columns.length === 0 &&
      ((this.columns = initialColumns),
      initialColumns.forEach(columnName => {
        this.updatePreviewDetails({ checked: true }, columnName, 'column');
      }));

    this.layoutCheckBoxes.forEach(sortField => {
      this.sortFieldsLayout[sortField] = this.sortService.getSort(
        this.sortFieldsLayout[sortField]
      );
      this.sortFieldsLayout[sortField].ascDesc =
        this.sortFieldsLayout[sortField].ascDesc === 1;
    });
  }

  private initComponentWithDialogData() {
    this.users = this.dialogConfig.data.users;
    this.rowGrouping = this.dialogConfig.data.rowGrouping;
    this.timeSpan = this.dialogConfig.data.timeSpan;
    this.functionsThisModel = this.dialogConfig.data.functionsThisModel;
    this.project = this.dialogConfig.data.project;
    this.dataSet = this.dialogConfig.data.dataSet;
    setTimeout(() => {
      this.dialogConfig.header = 'Rapportgenerator';
    }, 0);
  }

  exportFile(mode) {
    const sendToServer = {
      returnType: mode,
      users: this.users,
      rowGrouping: this.rowGrouping,
      timeSpan: this.timeSpan,
      project: this.project,
      data: this.getDataSetInOrder(),
    };

    this.exportFilePostRequest(sendToServer, true);
  }

  public updatePreviewDetails(
    event: { checked: any; originalEvent?: Event },
    name: string,
    type: 'layout' | 'column'
  ): void {
    const state =
      typeof event.checked === 'boolean'
        ? event.checked
        : (event.checked as string[]).includes(name);

    const dataToMutate = { field: 'null', order: state ? 1 : 0 };
    type === 'column' &&
      this.sortService.setSort(dataToMutate, this.sortFieldsColumns[name]);
    type === 'layout' &&
      (this.sortService.setSort(dataToMutate, this.sortFieldsLayout[name]),
      (this.sortFieldsLayout[name]['ascDesc'] = state));
    this.previewCall();
  }

  public saveToProjectCall(projectId: number | string): void {
    setTimeout(() => {
      const sendToServer = {
        returnType: 'saveToProject',
        users: this.users,
        rowGrouping: this.rowGrouping,
        timeSpan: this.timeSpan,
        project: this.project,
        data: this.getDataSetInOrder(),
        projectId: projectId,
      };

      this.exportFilePostRequest(sendToServer, false, false);
    }, 100);
  }

  previewCall() {
    setTimeout(() => {
      const sendToServer = {
        returnType: 'showPdf',
        users: this.users,
        rowGrouping: this.rowGrouping,
        timeSpan: this.timeSpan,
        project: this.project,
        data: this.getDataSetInOrder(),
      };

      if (this.preview) {
        this.exportFilePostRequest(sendToServer, false, true);
      }

      this.setSessionSettingsLS();
    }, 100);
  }

  setSessionSettingsLS() {
    const settingsObject = {
      // columns: this.columns,
      head: this.head,
      layout: this.layout,
    };

    const settingsJson = JSON.stringify(settingsObject);

    localStorage.setItem('dayReportSettings', settingsJson);
  }

  getSessionSettingsLS() {
    const lsSettingsData = localStorage.getItem('dayReportSettings');

    if (lsSettingsData) {
      const parsedSettings = JSON.parse(lsSettingsData);

      // this.columns = parsedSettings["columns"];
      this.head = parsedSettings['head'];
      this.layout = parsedSettings['layout'];
    }
  }

  getCompanySettings() {
    this.apolloQueryService
      .apolloQuery('daysSettings')
      .subscribe(({ data }) => {
        if (Number(data['company']['showSubsistenceOnTimereport']) === 1) {
          this.showSubstistenceOptions.next(true);
        } else {
          this.showSubstistenceOptions.next(false);
        }
      });
  }

  getCompanyFunctionsThisModel() {
    for (const companyFunction in this.checkForCompanyFunctions) {
      const functionName = this.checkForCompanyFunctions[companyFunction];
      if (this.companyFunctionsService.companyFunctionIsSet(functionName)) {
        this.functionsThisModel[functionName] = true;
      } else {
        this.functionsThisModel[functionName] = false;
      }
    }
  }

  previewLink() {
    this.preview = true;
    this.previewCall();
  }

  previewToggle() {
    if (this.preview) {
      this.previewCall();
    } else {
      this.pdfUrl = null;
    }
  }

  exportFilePostRequest(fileVars, getFile = false, showPreview = false) {
    const exportFileVariables = fileVars;

    if (this.preview && !getFile && showPreview) {
      this.loading = true;
    }

    if (getFile && exportFileVariables['returnType'] === 'showPdf') {
      this.pdfWindow = this.htmlModalService.emptyPage('700', '800');
    }

    const days = exportFileVariables['data']['days'];
    const daysLeave = exportFileVariables['data']['daysLeave'];

    exportFileVariables['data']['days'] =
      this.helperService.getIdsOfDataSet(days);
    exportFileVariables['data']['daysLeave'] =
      this.helperService.getIdsOfDataSet(daysLeave);

    exportFileVariables['meta'] = {};
    exportFileVariables['meta']['columns'] = this.columns;
    exportFileVariables['meta']['layout'] = this.layout;
    exportFileVariables['meta']['head'] = this.head;
    exportFileVariables['meta']['format'] = {};
    exportFileVariables['meta']['format']['onlyUserSums'] =
      this.sortFieldsLayout['onlyUserSums']['ascDesc'];
    exportFileVariables['meta']['format']['sumAttendance'] =
      this.sortFieldsLayout['sumAttendance']['ascDesc'];
    exportFileVariables['meta']['format']['sumTodos'] =
      this.sortFieldsLayout['sumTodos']['ascDesc'];
    exportFileVariables['meta']['format']['sumWorkType'] =
      this.sortFieldsLayout['sumWorkType']['ascDesc'];
    exportFileVariables['meta']['format']['sumSubsistence'] =
      this.sortFieldsLayout['sumSubsistence']['ascDesc'];

    this.httpService
      .makeHttpPostRequest('/day/report', exportFileVariables)
      .subscribe(data => {
        const fileDownloadLink = data['pathForDocument'];

        if (showPreview) {
          this.pdfUrl =
            this.domSanitizer.bypassSecurityTrustResourceUrl(fileDownloadLink);
        }
        if (getFile) {
          this.serveFile(exportFileVariables, fileDownloadLink);
        }

        if (this.preview && !getFile && showPreview) {
          this.loading = false;
        }

        if (!showPreview && !getFile) {
          this.messageService.insertData(
            { severity: data.status, summary: data.msg },
            data.status !== 'success'
          );
        }
      });
  }

  serveFile(exportFileVariables, fileDownloadLink) {
    if (exportFileVariables['returnType'] === 'showPdf') {
      this.pdfWindow.location.href = fileDownloadLink;
    } else {
      location.href = fileDownloadLink;
    }
  }

  closeAction() {
    this.dialogRef.close();
  }

  public getDataSetInOrder() {
    if (this.order === 'oldestFirst') {
      return {
        days: this.dataSet.days.reverse(),
        daysLeave: this.dataSet.daysLeave.reverse(),
      };
    } else {
      return this.dataSet;
    }
  }
}
