<div class="p-grid">
  <div class="p-col-12">
    <p class="strong-text">Vill du lägga till:</p>
    <p>{{ subcontractorInfo['name'] }}, {{ subcontractorInfo['orgNr'] }}</p>
    <p class="strong-text">I projekt:</p>
    <p>{{ projectInfo['label'] }}</p>
  </div>

  <div class="p-col-12" *ngIf="projectInfo.clientProject > 0">
    <p class="strong-text">Funktionalitet mot underentreprenör</p>

    <table>
      <tr>
        <td>Tillgång till tidrapporter</td>
        <td>
          <p-checkbox
            [(ngModel)]="mainContractorWhantsTimereports"
            [binary]="true"
          ></p-checkbox>
        </td>
      </tr>

      <tr>
        <td>Möjlighet att skicka arbetsmoment</td>
        <td>
          <p-checkbox
            [(ngModel)]="mainContractorWhantsToPutTodos"
            [binary]="true"
          ></p-checkbox>
        </td>
      </tr>
    </table>
  </div>

  <div class="p-col-12 space-between">
    <app-button (onClick)="closeAction()" [type]="'cancel'"></app-button>
    <app-button (onClick)="actionUpdate()" [type]="'add'"></app-button>
  </div>
</div>
