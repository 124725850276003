<spinner *ngIf="loading"></spinner>
<div *ngIf="!loading">
  <form
    class="p-grid"
    [formGroup]="componentMainForm"
    (ngSubmit)="actionCreate()"
  >
    <span class="p-col-12">
      <label>Typ*</label>
      <p-dropdown
        [options]="companyCostTypes"
        formControlName="companyCostTypeId"
      ></p-dropdown>
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Artikelnr</label>
      <input pInputText formControlName="artnr" />
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Benämning*</label>
      <input pInputText formControlName="benamning" />
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Inköpspris</label>
      <input pInputText formControlName="avtalsprisCost" />
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Förs. pris</label>
      <input pInputText formControlName="avtalspris" />
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Enhet</label>
      <p-dropdown
        [options]="units"
        [formControl]="componentMainForm.controls['enhet']"
      ></p-dropdown>
    </span>

    <span class="p-col-12 p-lg-6">
      <label>Leverantör</label>
      <input pInputText formControlName="leverantor" />
    </span>

    <span class="p-col-12 p-lg-6 p-d-flex p-ai-center">
      <p-inputSwitch
        formControlName="top"
        [trueValue]="1"
        [falseValue]="0"
        class="p-mr-2"
      ></p-inputSwitch>
      <label class="p-m-0">Topmärkt</label>
    </span>

    <div class="p-col-12 align-right">
      <button
        pButton
        class="p-button-primary-hyperion"
        [disabled]="buttons.create.disabled"
        (click)="actionCreate()"
        icon="pi pi-plus"
        label="Skapa"
      ></button>
    </div>
  </form>
</div>
