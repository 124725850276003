import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2HighchartsModule } from 'ng2-highcharts';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SharedModule } from 'primeng/api';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';

import {
  ProjectDefaultTodoComponent,
  ProjectDefaultTodoRowComponent,
  ProjectDefaultTodoService,
} from './index';
import { HyperionSharedModule } from '../../shared/shared.module';
import { HyperionCardModule } from 'app/shared/card/hyperion-card.module';
import { LoadingModule } from '../../shared/loading/loading.module';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    Ng2HighchartsModule,
    HyperionCardModule,
    HyperionSharedModule,
    LoadingModule,
    // Primeng
    AutoCompleteModule,
    CheckboxModule,
    DialogModule,
    DropdownModule,
    InputTextareaModule,
    SharedModule,
    TabViewModule,
    TooltipModule,
  ],
  declarations: [ProjectDefaultTodoComponent, ProjectDefaultTodoRowComponent],
  exports: [ProjectDefaultTodoComponent, ProjectDefaultTodoRowComponent],
  providers: [ProjectDefaultTodoService],
})
export class ProjectDefaultTodoModule {}
