import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type PlanningStatesFragment = { __typename?: 'CompanyPlanningState', id: string, name?: string | null, state?: string | null };

export type GetStatesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStatesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', planningStates?: { __typename?: 'CompanyPlanningStateConnection', edges?: Array<{ __typename?: 'CompanyPlanningStateEdge', node?: { __typename?: 'CompanyPlanningState', id: string, name?: string | null, state?: string | null } | null } | null> | null } | null } | null };

export type CreatePlanningStateMutationVariables = Types.Exact<{
  name?: Types.InputMaybe<Types.Scalars['String']>;
  state?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreatePlanningStateMutation = { __typename?: 'HyperionSchemaMutation', companyPlanningStateTypeHyperionMutation?: { __typename?: 'CompanyPlanningState', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type UpdatePlanningStateMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
  state?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdatePlanningStateMutation = { __typename?: 'HyperionSchemaMutation', companyPlanningStateTypeHyperionMutation?: { __typename?: 'CompanyPlanningState', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export type DeletePlanningStateMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type DeletePlanningStateMutation = { __typename?: 'HyperionSchemaMutation', companyPlanningStateTypeHyperionMutation?: { __typename?: 'CompanyPlanningState', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', mutationSucceeded?: boolean | null, msg?: string | null } | null> | null } | null };

export const PlanningStatesFragmentDoc = gql`
    fragment planningStates on CompanyPlanningState {
  id
  name
  state
}
    `;
export const GetStatesDocument = gql`
    query getStates {
  company {
    planningStates {
      edges {
        node {
          ...planningStates
        }
      }
    }
  }
}
    ${PlanningStatesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStatesGQL extends Apollo.Query<GetStatesQuery, GetStatesQueryVariables> {
    document = GetStatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePlanningStateDocument = gql`
    mutation createPlanningState($name: String, $state: String) {
  companyPlanningStateTypeHyperionMutation(
    createCompanyPlanningState: {name: $name, state: $state}
  ) {
    id
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePlanningStateGQL extends Apollo.Mutation<CreatePlanningStateMutation, CreatePlanningStateMutationVariables> {
    document = CreatePlanningStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePlanningStateDocument = gql`
    mutation updatePlanningState($id: Int, $state: String) {
  companyPlanningStateTypeHyperionMutation(
    updateCompanyPlanningState: {id: $id, state: $state}
  ) {
    id
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePlanningStateGQL extends Apollo.Mutation<UpdatePlanningStateMutation, UpdatePlanningStateMutationVariables> {
    document = UpdatePlanningStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePlanningStateDocument = gql`
    mutation deletePlanningState($id: Int) {
  companyPlanningStateTypeHyperionMutation(deleteCompanyPlanningState: {id: $id}) {
    mutationDetails {
      mutationSucceeded
      msg
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePlanningStateGQL extends Apollo.Mutation<DeletePlanningStateMutation, DeletePlanningStateMutationVariables> {
    document = DeletePlanningStateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }