<div class="p-grid">
  <div class="p-col-12">
    <spinner *ngIf="loading | async"></spinner>
    <project-content
      *ngIf="!(loading | async)"
      [functionsThisModel]="functionsThisModel"
      [functionsData]="functionsData"
      [projectFromIndex]="selectedProject"
      [projectLabels]="projectLabels"
      [projectParams]="projectParams"
      (projectStatusChange)="removeProjectWithUpdatedStatusFromDataset($event)"
      (projectUpdated)="updateProjectInDataSet($event)"
      (projectDeleted)="projectDeleted()"
      (projectMessageChange)="updateMessage($event)"
      [CompanyUserCostTypes]="userCostTypesRaw"
      [meUser]="meUser"
    ></project-content>
  </div>
</div>
