import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectCountService } from 'app/project/project-count-service/project-count-service.service';
import { BehaviorSubject, first } from 'rxjs';
import {
  ImageFragment,
  ProjectImagesGQL,
} from './graphql/project-images.query.generated';

@Component({
  selector: 'app-project-images',
  templateUrl: './project-images.component.html',
  styleUrls: ['./project-images.component.scss'],
})
export class ProjectImagesComponent implements OnInit {
  public projectId: number;

  public images: BehaviorSubject<ImageFragment[]> = new BehaviorSubject([]);
  public totalImages: number;

  public imagesPerPage = 23;

  constructor(
    private route: ActivatedRoute,
    private getImages: ProjectImagesGQL,
    private projectCountService: ProjectCountService
  ) {}

  public fetchImages(settings: { offset: number; count: number }): void {
    this.getImages
      .fetch({
        id: this.projectId,
        offset: settings.offset,
        count: settings.count,
      })
      .pipe(first())
      .subscribe(data => {
        const images = [];

        if (!this.totalImages) {
          this.totalImages = data.data.project.pictureComments.totalCount;
        }

        this.projectCountService.count(this.projectId);
        data.data.project.pictureComments.edges.forEach(n =>
          images.push({
            ...n.node,
            editable: false,
            preloadedLarge: false,
            preloadedThumbnail: false,
          })
        );
        this.images.next(images);
      });
  }

  public ngOnInit(): void {
    this.route.parent.params.pipe(first()).subscribe(params => {
      this.projectId = params.id;
      this.fetchImages({ offset: 0, count: this.imagesPerPage });
    });
  }
}
