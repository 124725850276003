import { Config } from '../config';
import { config as defaultConfig } from './default.config';

export const config: Config = {
  ...defaultConfig,
  name: 'enklakontoret',
  subdomain: ['beijerbygg', 'betabeijerbygg'],
  supportPhone: '010 - 21 20 100',
  supportMail: 'beijerbygg@struqtur.se',
  title: 'Enkla Kontoret',
  theme: {
    ...defaultConfig.theme,
    iconPath: '/images/beijerbygg/logo_square.png',
    logoPath: '/images/beijerbygg/logo-2020-273x64.png',
    brandPrimaryColor: '#fed141',
    brandSecondaryColor: '#d0d3d4',
    topMenuBackgroundColor: '#fed141',
    topMenuColor: 'black',
    fontFamily: 'Roboto',
    headerFontFamily: 'Roboto',
    buttonPrimaryColor: '#06326b',
    buttonPrimaryColorDark: '#0b5ac8',
    buttonDangerColor: '#f58220',
    buttonWarningColor: '#fed141',
    buttonInfoColor: '#0b5ac8',
    linkColor: '#06326b',
    invert: true,
    headerWeight: '600',
    topMenuTextTransform: 'uppercase',
    workDiagramColor: '#06326b',
    workNotInvoicedDiagramColor: '#0b5ec9',
    materialDiagramColor: '#fed141',
    materialNotInvoicedDiagramColor: '#fee8a0',
    mileageDiagramColor: '#0b5ac8',
    mileageNotInvoicedDiagramColor: '#2a7ef3',
    overheadDiagramColor: '#f58220',

    favicons: [
      {
        rel: 'shortcut icon',
        href: '/images/beijerbygg/icon-2020-69x66.png',
      },
    ],
  },
};
