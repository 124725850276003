import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { FetchProjectQuery } from '../graphql/project.generated';
import {
  CostCalculationMode,
  EconomyData,
  ProjectOverviewData,
  ProjectOverviewService,
} from '../project-overview/project-overview.service';

@Component({
  selector: 'app-project-overview-costs',
  templateUrl: './project-overview-costs.component.html',
  styleUrls: ['./project-overview-costs.component.scss'],
})
export class ProjectOverviewCostsComponent implements OnInit, OnChanges {
  @Input() public projectOverviewData: Observable<ProjectOverviewData>;
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Input() public includeOverhead: boolean;
  @Input() public setMile = true;
  @Output() public costCalculationChanged =
    new EventEmitter<CostCalculationMode>();

  public CostCalculationMode = CostCalculationMode;

  public showParameterSettings: boolean;

  public rows: { label: string; value: string }[];
  public economyData: EconomyData;

  constructor(public projectOverviewService: ProjectOverviewService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.setMile) {
      const setMile = changes.setMile.currentValue;

      if (setMile) {
        this.rows = [
          { label: 'Arbetstid', value: 'labor' },
          { label: 'Milkostnader', value: 'miles' },
          { label: 'Material och övriga kostnader', value: 'material' },
        ];
      } else {
        this.rows = [
          { label: 'Arbetstid', value: 'labor' },
          { label: 'Material och övriga kostnader', value: 'material' },
        ];
      }
    }

    if (changes.includeOverhead) {
      const includeOverhead = changes.includeOverhead.currentValue;

      if (includeOverhead) {
        this.rows.push({ label: 'Omkostnader', value: 'overhead' });
      } else {
        this.rows = this.rows.filter(r => r.value !== 'overhead');
      }
    }
  }

  public ngOnInit(): void {
    this.projectOverviewData.subscribe(data => {
      this.economyData = data.economyData;
    });
  }

  public calculateTotal(): number {
    if (this.economyData === undefined) {
      return 0;
    }
    const base =
      this.economyData.costs?.material?.total +
      this.economyData.costs?.labor?.totalMileCost;

    const total =
      this.projectOverviewService.costCalculationMode ===
      CostCalculationMode.ByUser
        ? base + this.economyData.costs?.labor?.byUser.total ?? 0
        : base + this.economyData.costs?.labor?.byLaborType.total ?? 0;

    return this.includeOverhead
      ? total + this.economyData.costs?.labor?.overhead
      : total;
  }

  public calculatePercent(): {
    labor: string;
    miles: string;
    material: string;
    overhead: string;
  } {
    if (this.economyData === undefined) {
      return {
        labor: '0',
        miles: '0',
        material: '0',
        overhead: '0',
      };
    }
    let labor: number;
    if (
      this.projectOverviewService.costCalculationMode ===
      CostCalculationMode.ByUser
    ) {
      labor = this.economyData.costs?.labor?.byUser.total ?? 0;
    } else {
      labor = this.economyData.costs?.labor?.byLaborType.total ?? 0;
    }
    const miles = this.economyData.costs?.labor.totalMileCost ?? 0;
    const material = this.economyData.costs?.material.total ?? 0;
    const overhead = this.economyData.costs?.labor.overhead ?? 0;
    const total = this.calculateTotal();

    if (total === 0) {
      return {
        labor: '0',
        material: '0',
        miles: '0',
        overhead: '0',
      };
    }

    return {
      labor: ((labor / total) * 100).toFixed(0),
      material: ((material / total) * 100).toFixed(0),
      overhead: ((overhead / total) * 100).toFixed(0),
      miles: ((miles / total) * 100).toFixed(0),
    };
  }

  public openParameterSettings(): void {
    this.showParameterSettings = true;
  }

  public projectParametersUpdated(): void {
    this.showParameterSettings = false;
    this.projectOverviewService.refetchInvoiceData();
  }
}
