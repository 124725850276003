<div *ngIf="remainingTrialDays >= 1" class="trial-banner trial-banner-warning">
  Du har
  {{ remainingTrialDays }}
  dag(ar) kvar av din testperiod. För att kunna fortsätta använda Struqturs
  tjänster kontakta {{ salesRepOwner.firstName }} på
  <a class="trial-banner-hyperlink" href="tel:{{ salesRepOwner.phone }}">{{
    salesRepOwner.phone
  }}</a>
  eller
  <a class="trial-banner-hyperlink" href="mailto:{{ salesRepOwner.email }}">{{
    salesRepOwner.email
  }}</a
  >.
</div>

<div
  *ngIf="remainingTrialDays && remainingTrialDays <= 0"
  class="trial-banner trial-banner-danger"
>
  Din testperiod är slut. För att kunna fortsätta använda Struqturs tjänster
  kontakta
  {{ salesRepOwner.firstName }} på
  <a class="trial-banner-hyperlink" href="tel:{{ salesRepOwner.phone }}">{{
    salesRepOwner.phone
  }}</a>
  eller
  <a class="trial-banner-hyperlink" href="mailto:{{ salesRepOwner.email }}">{{
    salesRepOwner.email
  }}</a
  >.
</div>
