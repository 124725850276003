import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule as PTableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { SpinnerModule } from 'primeng/spinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToggleButtonModule } from 'primeng/togglebutton';

import { HyperionSharedModule } from '../shared/shared.module';
import { UserDayService } from './services/index';
import { UserSettingsModule } from 'app/registry/users/user-settings.module';
import {
  DayComponent,
  DayAdvancedComponent,
  DayExportComponent,
  DaySettingsComponent,
  EditDayComponent,
  HandleDaysProductRowComponent,
  HandleDaysProductRowsHolderComponent,
  DaysSettingsComponent,
  DayReportComponent,
} from './index';

import { TableModule } from 'app/shared/table/table.module';
import { HeaderModule } from 'app/header/header.module';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DayFormComponent } from './day-form/day-form.component';
import { SidebarModule } from 'primeng/sidebar';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { MessageModule } from 'primeng/message';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HyperionSharedModule,
    CheckboxModule,
    SpinnerModule,
    RadioButtonModule,
    ToggleButtonModule,
    PTableModule,
    UserSettingsModule,
    TableModule,
    HeaderModule,
    DropdownModule,
    SplitButtonModule,
    SidebarModule,
    CardModule,
    DividerModule,
    MessageModule,
    TreeTableModule,
  ],
  declarations: [
    DayAdvancedComponent,
    DayExportComponent,
    DayComponent,
    EditDayComponent,
    DaySettingsComponent,
    HandleDaysProductRowComponent,
    HandleDaysProductRowsHolderComponent,
    DaysSettingsComponent,
    DayReportComponent,
    DayFormComponent,
  ],
  exports: [DayFormComponent],
  providers: [UserDayService],
})
export class DayModule {}
