<app-header>
  <button
    pButton
    class="p-mr-2"
    (click)="showProducts('excel')"
    label="Ladda ner"
    icon="pi pi-file-excel"
  ></button>
  <button
    pButton
    class="p-mr-2"
    (click)="toggleImportProducts()"
    label="Importera artiklar"
    icon="pi pi-inbox"
  ></button>
  <button
    pButton
    class="p-button-primary-hyperion"
    (click)="showCreateProduct = !showCreateProduct"
    label="Skapa artikel"
    icon="pi pi-plus"
  ></button>
</app-header>

<p-sidebar [(visible)]="showCreateProduct" position="right">
  <h1>Skapa artikel</h1>
  <spinner *ngIf="costTypesLoading | async"></spinner>
  <app-create-product
    *ngIf="!(costTypesLoading | async)"
    [companyCostTypes]="companyCostTypes"
    (createdProduct)="refetchProducts()"
  ></app-create-product>
</p-sidebar>

<p-sidebar [(visible)]="importProductsShow" position="right">
  <h1>Importera artiklar</h1>
  <import-products (refetchProducts)="refetchProducts()"></import-products>
</p-sidebar>

<div class="page-container product-settings p-grid">
  <div class="p-col-12">
    <spinner *ngIf="costTypesLoading | async"></spinner>
    <app-company-products-list
      [companyCostTypes]="companyCostTypes"
    ></app-company-products-list>
  </div>
</div>
