import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { FormHandlerService } from 'app/shared/forms';
import { ApolloMutationService } from 'app/shared/apollo';
import { GlobalService } from 'app/shared/global';
import { UserLocalStorageService } from 'app/shared/user';

import { CompanyNotice } from './company-notice';
import { MeUserWithCompany } from 'app/shared/user/me-user';

@Component({
  selector: 'companyNoticeBoard-holder',
  templateUrl: 'company-notice-board-read-holder.component.html',
  providers: [FormHandlerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyNoticeBoardReadHolderComponent implements OnInit {
  @Input() public messagesFromParent: CompanyNotice[];
  @Input() public loading = true;

  dataModel = 'companyNoticeboard';
  dataModelCapitalized = 'CompanyNoticeboard';
  dataSet = [];
  private meUser: MeUserWithCompany;
  public eraseMsg = '';
  componentMainForm: FormGroup;
  formFields = {
    model: 'CompanyNoticeboard',
    attributes: {
      head: '',
      text: '',
    },
  };
  formSettings;
  componentPrefix;
  createdObject;
  connecting: boolean;
  hasCreatedSibling = false;
  toggleCreate = false;

  constructor(
    private mutationService: ApolloMutationService,
    private cdr: ChangeDetectorRef,
    private formHandler: FormHandlerService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private userLocalStorageService: UserLocalStorageService
  ) {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.initForm();
  }

  private initForm() {
    this.formHandler.groupSetLabelsRules(this.formFields).then(groupedForm => {
      this.componentMainForm = groupedForm;
      this.componentMainForm.controls['head']['label'] = 'Rubrik...';
      this.componentMainForm.controls['text']['label'] = 'Inlägg...';

      this.componentMainForm.controls['head']['name'] = 'noticeHead';
      this.componentMainForm.controls['text']['name'] = 'noticeText';
      this.cdr.markForCheck();
    });
  }

  ngOnInit(): void {
    this.componentPrefix = this.globalService.generateComponentPrefix();
  }

  private removeFromArray(deletedMessageId): void {
    this.messagesFromParent = this.messagesFromParent.filter(message => {
      // eslint-disable-next-line eqeqeq
      return message.id != deletedMessageId;
    });
  }

  addCreateComponent(dataObjectParam) {
    const instanceOfDataSet = this.messagesFromParent;
    instanceOfDataSet.splice(0, 0, dataObjectParam);
    this.cdr.markForCheck();
  }

  actionCreate() {
    const crudType = 'create';
    const dataToMutation = {
      head: this.componentMainForm.controls['head'].value,
      text: this.componentMainForm.controls['text'].value,
    };

    const responseParams = [
      'head',
      'text',
      'id',
      'created',
      'userId',
      'user {firstName lastName}',
    ];

    this.formHandler.setLoadingClasses('start');

    if (this.formHandler.formValid([this.componentMainForm])) {
      this.connecting = true;
      this.formHandler.setLoadingClasses();

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation,
          responseParams
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);

            this.mutationService.displayMutationStatus(executedData);

            let setLoadingClassesInput;

            if (executedData.mutationSucceededAllArguments) {
              this.componentMainForm.reset({ head: '', text: '' });
              setLoadingClassesInput = 'success';
              this.createdObject = executedData;
              this.addCreateComponent(executedData);
            } else {
              setLoadingClassesInput = 'error';
            }

            this.formHandler.setLoadingClasses(setLoadingClassesInput);

            setTimeout(() => {
              this.formHandler.setLoadingClasses();
            }, 1000);

            this.connecting = false;

            executeMutationSub.unsubscribe();
            this.cdr.detectChanges();
          },
          err => {
            console.warn(err);
          }
        );
    }
  }

  public canDelete(message: CompanyNotice): boolean {
    return this.meUser.type === '3' || this.meUser.id === message.userId;
  }

  public confirmActionDelete(message: CompanyNotice): void {
    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort inlägget?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.actionDelete(message);
      },
      reject: () => {},
    });
  }

  private actionDelete(message: CompanyNotice): void {
    this.eraseMsg = 'Tar bort post...';
    const crudType = 'delete';
    const dataToMutation = { id: +message.id };
    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          const result = this.formHandler.showServerErrorsOnAttributes(data, [
            {
              form: this.componentMainForm,
              argument: crudType + this.dataModelCapitalized,
            },
          ]);

          this.mutationService.displayMutationStatus(data);

          if (!result.errors) {
            this.removeFromArray(message.id);
          }

          this.cdr.markForCheck();

          this.eraseMsg = '';

          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }
}
