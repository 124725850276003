import { Component } from '@angular/core';
import { GlobalService } from './shared/global/index';
import { CompanyAppParamsService } from './shared/company/index';

@Component({
  template: `
    <app-meta name="robots" content="noindex"></app-meta>

    <div class="p-grid" style="padding: 10%;">
      <div class="p-lg-8  p-md-12 p-sm-12">
        <div class="p-col-12">
          <h2>Sidan finns inte...</h2>
        </div>

        <div class="p-col-12">För att komma vidare prova nedan alternativ</div>

        <div class="p-col-12">
          <span class="link" (click)="goBack()">Gå till föregående sida</span> |
          <a routerLink="/home">Gå till hem</a> |
          <a routerLink="/project">Gå till projekt</a>
        </div>
      </div>

      <div class="p-lg-4 p-md-12 p-sm-12 align-center">
        <img
          src="{{ prefix }}/images/hyperion/bendtNail.png"
          style="width: 73px; opacity: 0.5;"
        />
      </div>
    </div>
  `,
})
export class PageNotFoundComponent {
  logoPath;
  prefix;
  logoLoading = true;

  constructor(
    private companyAppParamsService: CompanyAppParamsService,
    private globalService: GlobalService
  ) {
    this.prefix = this.globalService.getUrlPrefix();

    this.companyAppParamsService.logo.subscribe(data => {
      this.logoPath = this.globalService.getUrlPrefix() + data;
      this.logoLoading = false;
    });
  }

  goBack() {
    window.history.back();
  }
}
