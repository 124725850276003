import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ApolloMutationService, ApolloQueryService } from 'app/shared/apollo';
import { ConnectionsService } from 'app/attendance-report/connections/services/connections.service';

@Component({
  selector: 'connections-subcontractors',
  templateUrl: './connections-subcontractors.component.html',
})
export class ConnectionsSubcontractorsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() data = [];
  @Input() mode: string;
  @Output() mutationDone = new EventEmitter();
  @Output() reopen = new EventEmitter();

  public tooltip = '';
  tableColums: { field: string; header: string }[];

  constructor(
    private mutationService: ApolloMutationService,
    private apolloQueryService: ApolloQueryService,
    private connectionsService: ConnectionsService
  ) {}

  ngOnInit() {
    this.setupComponentByMode();
  }

  setupComponentByMode() {
    this.tableColums = [
      { field: 'name', header: 'Namn' },
      { field: 'orgNr', header: 'Företagets org' },
      { field: 'approvedByCompanyId', header: 'Accepterat' },
    ];

    this.tooltip = '<strong>Accepterat</strong><br>';
    this.tooltip +=
      'Här listas de företag i ' +
      localStorage.getItem('appname') +
      ' som har angivit dig som underentreprenör. Om du markerar Acceptera så kommer ni kunna rapportera till de projekt som beställaren har delat till er.';

    if (this.mode === 'subcontractors') {
      this.tooltip = '<strong>Accepterat</strong><br>';
      this.tooltip +=
        'Om din underentreprenör har accepterat dig som beställare så listas de delade E-liggarna för aktuell underentreprenör. Underentreprenören kan endast se sin del av E-liggaren.';
    }
  }

  handleApprovedByCompanyChange(data) {
    const attendanceClient = data;
    const companyId = localStorage.getItem('MEcompanyId');
    const approvedByCompanyId = attendanceClient['approvedByCompanyBool']
      ? Number(companyId)
      : null;

    const dataToMutation = {
      id: Number(attendanceClient['id']),
      approvedByCompanyId: approvedByCompanyId,
    };

    this.actionUpdate(dataToMutation);
  }

  actionUpdate(dataToMutationParam) {
    const dataModel = 'subcontractor';
    const dataToMutation = dataToMutationParam;

    this.mutationService
      .constructQueryAndExecuteMutation(
        dataModel,
        'update',
        false,
        dataToMutation
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(executedData => {
        this.mutationDone.emit(true);
        this.mutationService.displayMutationStatus(executedData);
      });
  }

  getData() {
    this.apolloQueryService
      .apolloQuery('companyConnections')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data }) => {
        const connectionsData =
          this.connectionsService.formatConnectionsData(data);
        this.data = connectionsData['attendanceClients'];
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
