import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { AttendanceReportCreateProjectComponent } from 'app/attendance-report/handle/attendance-report-dialogs/create-project/create-project.component';
import { AttendanceReportConstructionSiteNumberComponent } from 'app/attendance-report/handle/attendance-report-dialogs/construction-site-number/construction-site-number.component';
import { AddSubcontractorComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor/add-subcontractor.component';
import { AddSubcontractorToProjectComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-to-project/add-subcontractor-to-project.component';
import { AddSubcontractorMailComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-subscontractor-mail/add-subcontractor-mail.component';
import { AddAttendanceFreeUserComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-attendance-free-user/add-attendance-free-user.component';
import { EditAttendanceReportTimeComponent } from 'app/attendance-report/handle/attendance-report-dialogs/edit-attendance-report-time/edit-attendance-report-time.component';
import { AddAttendanceReportComponent } from 'app/attendance-report/handle/attendance-report-dialogs/add-attendance-report/add-attendance-report.component';
import { HyperionSharedModule } from 'app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HyperionSharedModule,
  ],
  declarations: [
    AttendanceReportCreateProjectComponent,
    AttendanceReportConstructionSiteNumberComponent,
    AddSubcontractorComponent,
    AddSubcontractorToProjectComponent,
    AddSubcontractorMailComponent,
    AddAttendanceFreeUserComponent,
    EditAttendanceReportTimeComponent,
    AddAttendanceReportComponent,
  ],
  providers: [],
})
export class AttendanceReportDialogsModule {}
