import { Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { MailDialogComponent } from './mail-dialog.component';

@Injectable({ providedIn: 'root' })
export class MailDialogService {
  constructor(private appDialogService: AppDialogService) {}

  public openMailDialog(data): DynamicDialogRef {
    this.appDialogService.layout = 'wide';
    this.appDialogService.data = data;
    return this.appDialogService.openComponent(MailDialogComponent);
  }
}
