<div class="sub-menu-container" *ngIf="subMenuLinks && subMenuLinks.length">
  <ng-container *ngFor="let item of subMenuLinks">
    <div>
      <a
        [class.inverted]="theme.invert"
        [routerLink]="item.path"
        routerLinkActive="active"
        [relativeTo]="activatedRoute"
        >{{ item.data.label }}
        <span *ngIf="item.data.countService"
          >({{
            item.data.countService.getCount(item.data.countField) | async
          }})</span
        >
      </a>
    </div>
  </ng-container>
</div>
