import { gql } from 'apollo-angular';

import { MutationDetails } from './common';

export const CreateInvoice = gql`
  mutation createInvoiceMutation(
    $createInvoice: InvoiceTypeHyperionMutationInput
    $invoiceClientContact: ContactTypeHyperionMutationInput
    $invoiceRows: [InvoiceRowTypeHyperionMutationInput]
    $invoiceRotRequestings: [InvoiceRotRequestingTypeHyperionMutationInput]
    $invoiceRotRequestingExtra: InvoiceRotRequestingExtraTypeHyperionMutationInput
    $invoiceDayTransactions: [Int]
    $invoiceDaySubsistenceTransactions: [String]
    $invoiceProjectproductTransactions: [Int]
    $invoiceInstallamentransactions: [Int]
    $returnMode: String
  ) {
    invoiceTypeHyperionMutation(
      createInvoice: $createInvoice
      invoiceClientContact: $invoiceClientContact
      invoiceRows: $invoiceRows

      invoiceRotRequestings: $invoiceRotRequestings
      invoiceRotRequestingExtra: $invoiceRotRequestingExtra

      invoiceDayTransactions: $invoiceDayTransactions
      invoiceDaySubsistenceTransactions: $invoiceDaySubsistenceTransactions
      invoiceProjectproductTransactions: $invoiceProjectproductTransactions
      invoiceInstallamentransactions: $invoiceInstallamentransactions
      returnMode: $returnMode
    ) {
      mutationDetails {
        ...MutationDetailsAttributes
      }
      id
      created
      status
      previewUrl
      contact {
        id
        name
        contactType
      }
    }
  }
  ${MutationDetails.fragments.attributes}
`;
