<app-header *ngIf="mode !== 'attendance' && !isProjectBookmarks">
  <ng-container *ngIf="currentStatus === 1 || currentStatus === 2">
    <app-button
      (click)="exportProjectsExcel()"
      [type]="'getExcel'"
    ></app-button>
    <app-button
      (click)="openProjectDataDialog()"
      [type]="'showSummation'"
    ></app-button>
  </ng-container>
  <app-button
    *ngIf="currentStatus !== 6 && (meUser.type === '2' || meUser.type === '3')"
    (onClick)="openCreateDialog()"
    [type]="'showCreate'"
    [model]="'Project'"
    [disabled]="loading | async"
  ></app-button>
</app-header>

<div>
  <app-attendance-head
    *ngIf="mode === 'attendance'"
    [dataSetAsync]="dataSetAsync"
    [isProjectBookmarks]="isProjectBookmarks"
    (getUnrelatedProjects)="getAttendanceReportProjects($event)"
  ></app-attendance-head>

  <div class="page-container">
    <div
      class="p-grid index-page-header print-no-show"
      *ngIf="!isProjectBookmarks && mode !== 'attendance'"
    >
      <div *ngIf="currentStatus === 6" class="p-col-12 sub-header">
        <h4>
          Kontakta support för att få hjälp med att skapa nya interna projekt.
        </h4>
      </div>
    </div>

    <div
      class="p-grid"
      *ngIf="
        currentStatus === 3 && !isProjectBookmarks && mode !== 'attendance'
      "
    >
      <div class="p-col-6">
        <appForm2-list-calendar
          [(ngModel)]="dateRange.from"
          (ngModelChange)="getProjects(3)"
          [htmlAttributes]="{ label: { value: ' ' } }"
        ></appForm2-list-calendar>
      </div>
      <div class="p-col-1"><span class="choose-date-hyphen"> - </span></div>
      <div class="p-col-5">
        <appForm2-list-calendar
          [(ngModel)]="dateRange.to"
          (ngModelChange)="getProjects(3)"
          [htmlAttributes]="{ label: { value: ' ' } }"
        ></appForm2-list-calendar>
      </div>
    </div>

    <div
      class="p-grid p-ai-center p-nogutter"
      [style.display]="isProjectBookmarks ? 'none' : 'flex'"
    >
      <div
        class="p-md-12 p-p-0"
        [ngClass]="{
          'p-lg-8': functionsThisModel['useProjectTypes'],
          'p-lg-10': !functionsThisModel['useProjectTypes']
        }"
      >
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
          <input
            type="text"
            pInputText
            (input)="projectTable.filterGlobal($event.target.value, 'contains')"
            placeholder="Sök projekt"
          />
        </div>
      </div>

      <div
        class="p-lg-2 p-md-6 p-p-0"
        *ngIf="functionsThisModel['useProjectTypes']"
      >
        <appForm2-list-dropDown
          [(ngModel)]="projectTypeModel"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="filterByProjectType($event)"
          [dropDownParams]="{ options: functionsData.projectCostTypes }"
        ></appForm2-list-dropDown>
      </div>

      <div class="p-inputgroup" class="p-lg-2 p-md-6 align-right p-p-0">
        <app-button
          (click)="showAdvancedSearch = !showAdvancedSearch"
          [type]="'showExtendSearch'"
        ></app-button>
      </div>
    </div>

    <div class="p-grid" *ngIf="showAdvancedSearch">
      <div class="p-col-12">
        <p-accordion [multiple]="true">
          <p-accordionTab header="Utökad sökning" [selected]="true">
            <div class="p-grid">
              <div class="p-col-12 p-md-4">
                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['trueId'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter($event, 'trueId', 'contains')
                  "
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['mark'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter($event, 'mark', 'contains')
                  "
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: {
                      value: projectLabels['Project']['constructionSiteNumber']
                    }
                  }"
                  (ngModelChange)="
                    projectTable.filter(
                      $event,
                      'constructionSiteNumber',
                      'contains'
                    )
                  "
                ></appForm2-simple-input>
              </div>

              <div class="p-col-12 p-md-4">
                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['madeBy'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter($event, 'madeBy', 'contains')
                  "
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['orderNumber'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter($event, 'orderNumber', 'contains')
                  "
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['client'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter(
                      $event,
                      'ClientSearchString',
                      'contains'
                    )
                  "
                ></appForm2-simple-input>
              </div>

              <div class="p-col-12 p-md-4">
                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{
                    label: { value: projectLabels['Project']['establishment'] }
                  }"
                  (ngModelChange)="
                    projectTable.filter(
                      $event,
                      'EstablishmentSearchString',
                      'contains'
                    )
                  "
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  [htmlAttributes]="{ label: { value: 'Arbetsmoment' } }"
                  (ngModelChange)="
                    projectTable.filter($event, 'TodooSearchString', 'contains')
                  "
                ></appForm2-simple-input>
                <appForm2-simple-dropDown
                  [ngModel]="null"
                  [dropDownParams]="{ options: statusOptions }"
                  [htmlAttributes]="{ label: { value: 'Status' } }"
                  (ngModelChange)="statusSearchSwitch($event)"
                ></appForm2-simple-dropDown>
              </div>
            </div>
          </p-accordionTab>

          <p-accordionTab
            header="Sammanställning"
            *ngIf="
              currentStatus !== 4 &&
              meUser.type === '3' &&
              mode !== 'attendance'
            "
            [selected]="true"
          >
            <div class="p-grid">
              <div class="p-col-12 p-md-2">
                <div class="summation-filter-status">
                  Välj status på projekt<br />

                  <div *ngFor="let status of projectStatus">
                    <p-checkbox
                      name="projectStatusCheckboxGroup"
                      [value]="status.value"
                      [label]="status.label"
                      [(ngModel)]="projectStatusForSummationModel"
                    ></p-checkbox>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-md-3">
                Välj kolumner - Projektinformation <br />
                <div *ngFor="let col of possibleTableColumnsDefualt">
                  <p-checkbox
                    name="columnsGroup"
                    [value]="col"
                    [label]="col.header"
                    [(ngModel)]="selectedSummationColumns"
                  ></p-checkbox>
                </div>
              </div>
              <div class="p-col-12 p-md-3">
                Välj kolumner - KPIer <br />
                <div *ngFor="let col of possibleTableColumnsCalc">
                  <p-checkbox
                    name="columnsGroup"
                    [value]="col"
                    (onChange)="updateSort($event, col)"
                    [label]="col.header"
                    [(ngModel)]="selectedSummationColumns"
                  ></p-checkbox>
                </div>
              </div>
              <div class="p-col-12 p-md-4">
                <p>Välj Period</p>
                <appForm2-simple-calendar
                  [yearNavigator]="true"
                  [yearRange]="yearRange"
                  [(ngModel)]="projectSummationDateFilter.fromDate"
                  [htmlAttributes]="{ label: { value: 'Från' } }"
                ></appForm2-simple-calendar>
                <appForm2-simple-calendar
                  [yearNavigator]="true"
                  [yearRange]="yearRange"
                  [(ngModel)]="projectSummationDateFilter.toDate"
                  [htmlAttributes]="{ label: { value: 'Till' } }"
                ></appForm2-simple-calendar>

                <div class="p-col-12">
                  <app-button
                    [disabled]="loading | async"
                    (onClick)="getSummation()"
                    [type]="'getSummation'"
                    class="pull-right"
                  ></app-button>

                  <app-button
                    [disabled]="loading | async"
                    (onClick)="restoreList()"
                    [type]="'restore'"
                  ></app-button>
                </div>
                <div class="p-col-12" *ngIf="showSummation">
                  <app-button
                    [disabled]="dataIsEmpty()"
                    (onClick)="exportSummationsExcel()"
                    [type]="'getExcel'"
                  ></app-button>
                  <app-button
                    class="pull-right"
                    [disabled]="dataIsEmpty()"
                    (onClick)="exportPerCostTypeExcel()"
                    [type]="'getExcelProjectCosts'"
                  ></app-button>
                </div>
              </div>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>

    <spinner *ngIf="loading | async"></spinner>

    <div class="p-grid">
      <div
        class="p-col-12"
        *ngIf="
          (currentStatus === 1 || currentStatus === 2) &&
          (loading | async) === false
        "
      >
        <div class="p-lg-2">
          <appForm2-list-dropDown
            [(ngModel)]="multiProjectAction"
            (ngModelChange)="selectMultiProjectAction($event)"
            [dropDownParams]="{
              options: availableMultiProjectActionsForDropdown,
              placeholder: 'Välj åtgärd…',
              disabled: !selectedRows.length
            }"
          ></appForm2-list-dropDown>
        </div>
      </div>

      <div class="p-col-12" [style.display]="loading.value ? 'none' : 'block'">
        <p-table
          #projectTable
          [rows]="50"
          dataKey="id"
          [responsive]="true"
          [rowHover]="true"
          styleClass="expand-rows"
          [columns]="selectedColumns"
          [sortField]="sort.attribute"
          [sortOrder]="sort.ascDesc"
          (onSort)="changeSort($event)"
          [value]="dataSetAsync | async"
          [rowTrackBy]="helperService.rowTrackBy"
          [(selection)]="selectedRows"
        >
          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td
                [attr.colspan]="
                  columns.length +
                  3 +
                  (functionsThisModel['useProjectTypes'] ? 1 : 0)
                "
              >
                <app-emptyState
                  [model]="'Project'"
                  [whereToCreate]="'topRight'"
                  [showDetail]="false"
                ></app-emptyState>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="header" let-columns>
            <tr>
              <th class="col-checkbox">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
              </th>
              <th style="width: 3rem">
                <span>
                  <span
                    class="help-tooltip invert smaller"
                    tooltipPosition="top"
                    pTooltip="Bokmärkta projekt markeras med stjärna samt visas på startsidan. Bokmärk genom att klicka i kolumnen nedan"
                    [escape]="false"
                  >
                    ?
                  </span>
                </span>
              </th>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.sortField"
                style="width:{{ col.width }}"
              >
                {{ col.header }}
                <p-sortIcon [field]="col.sortField"></p-sortIcon>
              </th>
              <th
                *ngIf="functionsThisModel['useProjectTypes']"
                style="width: 9rem"
                [pSortableColumn]="'typeLabel'"
              >
                Typ
                <p-sortIcon [field]="'typeLabel'"></p-sortIcon>
              </th>
              <th *ngIf="meUser.type !== '1'" style="width: 6rem"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr
              (click)="onRowClick($event, rowData, projectTable)"
              [attr.id]="rowData.trueId"
              [pSelectableRow]="rowData"
            >
              <td class="col-checkbox">
                <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
              </td>
              <td>
                <span
                  *ngIf="rowData['bookmarkedByUserObject']; else mark_content"
                  (click)="deleteBookmark(rowData)"
                >
                  <i class="fa fa-star" style="color: orange"></i>
                </span>
                <ng-template #mark_content>
                  <i
                    class="fa fa-star"
                    (click)="addBookmark(rowData)"
                    style="color: rgb(214, 214, 214)"
                  ></i>
                </ng-template>
              </td>
              <td *ngFor="let col of columns" class="p-text-truncate">
                <span class="p-column-title">{{ col.header }}</span>
                <span
                  pTooltip="{{ rowData[col.field] }}"
                  tooltipPosition="top"
                  style="display: block; margin-bottom: 0.3rem"
                  class="p-text-truncate"
                >
                  {{ rowData[col.field] }}
                </span>
                <span *ngIf="col.header === 'Kund'" style="display: block">
                  {{ rowData['clientContact']['address'] }}
                </span>
                <span
                  *ngIf="col.header === 'Arbetsställe'"
                  style="display: block"
                >
                  {{ rowData['establishmentContact_address'] }}
                </span>
              </td>
              <td
                *ngIf="functionsThisModel['useProjectTypes']"
                class="p-text-truncate"
              >
                {{ rowData.typeLabel }}
              </td>
              <td *ngIf="meUser.type !== '1'">
                <!-- Status -->
                <div class="status-icons">
                  <span
                    class="{{ rowData['unMovedSupplierInvoiceRowsSTATClass'] }}"
                  >
                    <i
                      class="fa fa-inbox inbox-red"
                      pTooltip="Det finns leverantörsfakturor som inte är flyttade till projektet ({{
                        rowData['unMovedSupplierInvoiceRowsSTAT']
                      }} rader)"
                      tooltipPosition="bottom"
                    ></i>
                  </span>
                  <span
                    class="{{ rowData['movedSupplierInvoiceRowsSTATClass'] }}"
                  >
                    <i
                      class="fa fa-inbox"
                      pTooltip="Det finns leverantörsfakturor med totalt {{
                        rowData['supplierInvoiceRowsSTAT']
                      }} rader inom projektet"
                      tooltipPosition="bottom"
                    ></i>
                  </span>
                  <span class="{{ rowData['invoicedDaysCountClass'] }}">
                    <i
                      class="fa fa-clock-o"
                      pTooltip="Det finns tidsrapporter som inte är fakturerade ( {{
                        rowData['notInvoicedDaysCount']
                      }} st)"
                      tooltipPosition="bottom"
                    ></i>
                  </span>
                  <span [class]="rowData['todosClass']">
                    <i
                      class="fa fa-th-list"
                      [class.th-list-green]="rowData['allTodosDone']"
                      (mouseenter)="tooltipTodos(rowData); op3.show($event)"
                      (mouseleave)="op3.hide()"
                    ></i>
                  </span>
                  <span class="{{ rowData['shortMessageClass'] }}">
                    <i
                      class="fa fa-flag"
                      [pTooltip]="
                        'Notering för projekt: <br>' + rowData['shortMessage']
                      "
                      tooltipPosition="bottom"
                      [escape]="false"
                    ></i>
                  </span>
                  <!-- Aktivitet -->
                  <span>
                    {{ rowData.daysFromActiveness_PreProjectTypeHyperion }}
                  </span>
                  <span class="pull-right" *ngIf="showSummation">
                    <p-checkbox
                      pTooltip="Visa endast vid Sammanställning"
                      (onChange)="
                        projectListCheckboxOnChange($event, rowData.id)
                      "
                    ></p-checkbox>
                  </span>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-rowData>
            <tr class="p-table-expanded-row">
              <td
                [attr.colspan]="
                  selectedColumns.length +
                  3 +
                  (functionsThisModel['useProjectTypes'] ? 1 : 0)
                "
              >
                <project-content
                  *ngIf="currentStatus !== 4"
                  [functionsThisModel]="functionsThisModel"
                  [functionsData]="functionsData"
                  [projectFromIndex]="rowData"
                  [projectLabels]="projectLabels"
                  [projectParams]="projectParams"
                  [CompanyUserCostTypes]="userCostTypesRaw"
                  [meUser]="meUser"
                  [mode]="mode"
                  (projectMessageChange)="updateMessage($event)"
                  (todoCrudAction)="handleTodoCrud($event)"
                ></project-content>
                <div *ngIf="currentStatus === 4" class="p-grid">
                  <div class="p-col-12 align-center">
                    <app-button
                      (click)="restoreProject(rowData.id)"
                      [type]="'restore'"
                      [model]="'Project'"
                    ></app-button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div
        class="hide-sub-header"
        [style.display]="loading.value ? 'none' : 'block'"
      >
        <p-overlayPanel #op3 styleClass="project-todo-overlay">
          <div
            style="font-size: 13px; max-width: 50vw"
            class="overlay-table"
            [innerHTML]="todoTooltipLabel"
          ></div>
        </p-overlayPanel>
      </div>
    </div>
  </div>
</div>
