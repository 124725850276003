import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFormErrorMessage]',
})
export class FormErrorMessageDirective {
  constructor(private elementRef: ElementRef) {
    this.elementRef.nativeElement.classList.add('form-error-message');
  }
}
