export enum ProjectStatusEnum {
  Planned = 0,
  Ongoing = 1,
  Finished = 2,
  Archived = 3,
  Deleted = 4,
  Leave = 5,
  Inhouse = 6,
}

export enum ProjectSystemTypeCode {
  PROJ = 'PROJ',
}

export enum ProjectTodoStatus {
  Done = 1,
}

export enum MapTypeToDropdown {
  Projects = 1,
  Coworkers,
  Todos,
  Todotopics,
}

export enum EventColorsEnum {
  Red = '#F44336',
  Pink = '#E91E63',
  Black = '#374046',
  Purple = '#9C27B0',
  DeepPurple = '#673AB7',
  Indigo = '#3F51B5',
  Blue = '#0288D1', // Primary color of the DHTMLX Scheduler Component
  LightBlue = '#03A9F4',
  Cyan = '#00BCD4',
  Teal = '#009688',
  Green = '#4CAF50',
  LightGreen = '#8BC34A',
  Lime = '#CDDC39',
  Yellow = '#FFEB3B',
  Amber = '#FFC107',
  Orange = '#FF9800',
  DeepOrange = '#FF5722',
  Brown = '#795548',
  Grey = '#9E9E9E',
  BlueGrey = '#607D8B',
}

export enum EventTextColorEnum {
  White = '#FFFFFF',
  Black = '#212121',
}

export enum ContextMenuStatusEnum {
  ON = 'ON',
  OFF = 'OFF',
}

export enum RepeatEventEnum {
  NoRepeat = '',
  Daily = 'daily',
  Weekly = 'weekly',
  Custom = 'custom',
}

export enum WeekdayEnum {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
  Sunday = '7',
}

export enum EventTypeEnum {
  Project = 1,
  PlannedTime,
  WorkTask,
  Placeholder,
}

export enum ExportPaperSizeEnum {
  NoSize = 'NoSize',
  A3 = 'A3',
  A4 = 'A4',
}

export enum GanttSizes {
  Sidebar = 300,
  StartDateColumn = 100,
}

export enum PlannerViewContext {
  Project,
  Resource,
}
