<div class="p-grid">
  <div class="p-col-12">
    <form
      [formGroup]="freeUserForm"
      *ngIf="mode === 'freeUser'; else users_dropdown"
    >
      <div class="p-col-12 p-g-nopad">
        <appForm2-wide-input formControlName="firstName"></appForm2-wide-input>
      </div>
      <div class="p-col-12 p-g-nopad">
        <appForm2-wide-input formControlName="lastName"></appForm2-wide-input>
      </div>
      <div class="p-col-12 p-g-nopad">
        <appForm2-wide-input
          formControlName="personalNumber"
        ></appForm2-wide-input>
      </div>
    </form>

    <form [formGroup]="componentMainForm">
      <ng-template #users_dropdown>
        <div class="p-col-12 p-g-nopad">
          <appForm2-wide-dropDown
            formControlName="userId"
            [dropDownParams]="{ options: usersDropdown, filter: true }"
          ></appForm2-wide-dropDown>
        </div>
      </ng-template>
      <div class="p-col-12 p-g-nopad">
        <appForm2-wide-calendar
          [showTime]="true"
          formControlName="startTime"
          [appendTo]="'body'"
        ></appForm2-wide-calendar>
      </div>
      <div class="p-col-12 p-g-nopad">
        <appForm2-wide-calendar
          [showTime]="true"
          formControlName="stopTime"
          [appendTo]="'body'"
        ></appForm2-wide-calendar>
      </div>
    </form>
    <div class="p-col-12 align-right">
      <app-button (onClick)="actionCreate()" [type]="'create'"></app-button>
    </div>
  </div>
</div>
