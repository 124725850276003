import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { GlobalService } from '../../shared/global/index';
import { CompanySuperService } from './company.super.service';

@Injectable()
export class UserDayService extends CompanySuperService {
  dataModel = 'daysAll';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode: 'labels' | 'list' | 'daysLeave') {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data, false);
        break;
      case 'daysLeave':
        dataSetFormatted = this.makeObjectsForForms(data, true);
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    /*
             För aktuell modell:
             Kolla om costType.active == 1, annars, ta inte med i listan
         */

    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];
    for (const i in dataArray) {
      const dataObject = {
        // create object to push
        label: dataArray[i].node.name,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data, isLeave) {
    //  console.log("format days")
    //  console.log(data)

    /*
             För aktuell model:
         */

    // console.log("makeobjectforforms")

    const dataModel = this.dataModel;

    const dataArray = data.company[dataModel].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const dataObject = {};
      for (const key in dataArray[i].node) {
        dataObject[key] = dataArray[i].node[key];
      }
      //  if(
      //      dataObject['project']['status'] == 0 ||
      //      dataObject['project']['status'] == 1 ||
      //      dataObject['project']['status'] == 2
      //  ){

      if (dataObject['extra'] === 0) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }

      const newProjectInfo: string =
        dataObject['project']['trueId'] + ', ' + dataObject['project']['mark'];
      dataObject['projectInfo'] = newProjectInfo;
      // if(dataObject["doneWork"] === "SE"){
      //     console.log("donwork SE");
      //     console.log(dataObject['projectInfo']);
      // }

      dataObjectsForForms.push(dataObject);
      //  }
    }

    return dataObjectsForForms;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    // eslint-disable-next-line eqeqeq
    if (dataObjectForForms['extra'] == 0) {
      dataObjectForForms['extraText'] = 'Nej';
    } else {
      dataObjectForForms['extraText'] = 'Ja';
    }
    dataObjectForForms['projectInfo'] =
      dataObjectForForms['project']['trueId'] +
      ', ' +
      dataObjectForForms['project']['mark'];
    return dataObjectForForms;
  }
}
