<div class="p-grid">
  <div class="p-col-4 p-mb-0"></div>
  <p-table class="inline-table p-col-8 header-only">
    <ng-template pTemplate="header">
      <tr>
        <th [width]="'50%'">Procent av den totala kostnaden</th>
        <th [width]="'50%'">Totalt</th>
      </tr>
    </ng-template>
  </p-table>
  <p-table
    [value]="rows"
    class="p-col-12 inline-table no-alternating external-header"
    [dataKey]="'label'"
  >
    <ng-template pTemplate="body" let-rowData let-expanded="expanded">
      <tr>
        <td [width]="'33.3%'">
          <div class="p-d-flex p-jc-between p-ai-center">
            <span style="color: var(--primary-color-600)"
              >{{ rowData.label }}
            </span>
            <button
              pButton
              style="background-color: var(--primary-color-200)"
              icon="pi pi-plus"
              [style]="{
                transform: expanded ? 'rotate(45deg)' : 'rotate(0deg)'
              }"
              class="p-button-rounded legacy-rounded-button"
              [pRowToggler]="rowData"
              *ngIf="rowData.value !== 'overhead'"
            ></button>
          </div>
        </td>

        <td [width]="'33.3%'" style="color: var(--primary-color-600)">
          {{ calculatePercent()[rowData.value] }}%
        </td>
        <td
          [width]="'33.3%'"
          *ngIf="rowData.value === 'labor'"
          style="color: var(--primary-color-600)"
        >
          {{
            (projectOverviewService.costCalculationMode ===
            CostCalculationMode.ByUser
              ? economyData?.costs[rowData.value]?.byUser.total
              : economyData?.costs[rowData.value]?.byLaborType.total) || 0
              | currency: 'SEK'
          }}
        </td>
        <td
          [width]="'33.3%'"
          *ngIf="rowData.value === 'overhead'"
          style="color: var(--primary-color-600)"
        >
          {{ economyData?.costs.labor.overhead || 0 | currency: 'SEK' }}
        </td>
        <td
          [width]="'33.3%'"
          *ngIf="rowData.value === 'miles'"
          style="color: var(--primary-color-600)"
        >
          {{ economyData?.costs.labor.totalMileCost || 0 | currency: 'SEK' }}
        </td>
        <td
          [width]="'33.3%'"
          *ngIf="rowData.value === 'material'"
          style="color: var(--primary-color-600)"
        >
          {{ economyData?.costs[rowData.value].total || 0 | currency: 'SEK' }}
        </td>
      </tr>
    </ng-template>
    <ng-template let-value pTemplate="rowexpansion">
      <tr>
        <td colspan="3">
          <app-project-overview-labor
            style="width: 100%"
            [costs]="economyData?.costs.labor"
            [type]="projectOverviewService.costCalculationMode"
            *ngIf="value.label === 'Arbetstid'"
          ></app-project-overview-labor>
          <app-project-overview-miles
            style="width: 100%"
            [costs]="economyData?.costs.labor"
            *ngIf="value.label === 'Milkostnader'"
          ></app-project-overview-miles>
          <app-project-overview-material
            style="width: 100%"
            [costs]="economyData?.costs.material"
            *ngIf="value.label === 'Material och övriga kostnader'"
          ></app-project-overview-material>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td colspan="2">Summa kostnader</td>
        <td class="p-text-bold" style="color: var(--primary-color-600)">
          {{ calculateTotal() | currency: 'SEK' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div>
    <button
      pButton
      class="p-mr-2"
      label="Ange parametrar"
      (click)="openParameterSettings()"
    ></button>
  </div>
</div>
<p-sidebar [(visible)]="showParameterSettings" position="right">
  <app-project-parameter-settings
    [projectData]="projectData"
    (projectParametersUpdated)="projectParametersUpdated()"
  ></app-project-parameter-settings>
</p-sidebar>
