import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { ApolloQueryService } from 'app/shared/apollo';
import { map, shareReplay, tap } from 'rxjs/operators';

export interface AppParams {
  readonly appLogoPath: string;
  readonly appURL: string;
  readonly appURLCostumer: string;
  readonly appURLCostumerText: string;
  readonly appURLLogin: string;
  readonly appURLText: string;
  readonly country: string;
  readonly getenvStruqturMode: string;
  readonly hyperionHomePage: string;
  readonly isAccountCreationAllowed: string;
  readonly isDev: string;
  readonly msg: string;
  readonly name: string;
  readonly newROTHandlingInvoiceId: string;
  readonly support: string;
  readonly supportMail: string;
  readonly supportPhone: string;
  readonly videoFastPresentation: string;
  readonly viewPrefix: string;
}

@Injectable()
export class CompanyAppParamsService {
  private companyAppParams: AppParams = null;
  public appParams: Observable<AppParams>;
  public companyAppParamsLoaded: Subject<boolean> = new Subject<boolean>();

  public logo: Observable<string>;
  public headerClass: Observable<string>;

  constructor(private apolloQueryService: ApolloQueryService) {
    this.appParams = this.apolloQueryService
      .apolloWatchQueryTwo('companyAppParams', null, 'cache-first')
      .pipe(
        map<any, AppParams>(({ data }) => data.appParams),
        tap<AppParams>(appParams => {
          this.setAppParamsInLocaleStorage(appParams);
          this.companyAppParams = appParams;
          this.companyAppParamsLoaded.next(true);
        }),
        shareReplay(1)
      );

    this.logo = this.appParams.pipe(map(params => params.appLogoPath));
    this.headerClass = this.appParams.pipe(
      map(params => params.getenvStruqturMode)
    );
  }

  // TODO: Remove once all localStorage.app* usages are migrated to appParams
  private setAppParamsInLocaleStorage(appParamsData) {
    for (const key in appParamsData) {
      const paramValue = appParamsData[key];
      localStorage.setItem('app' + key, paramValue);
    }
  }

  public getAppParam = <K extends keyof AppParams>(key: K): string => {
    return this.companyAppParams[key];
  };
}
