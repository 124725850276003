import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ModalBridgeService } from 'app/shared/idle/modals/modal-bridge.service';

import { HyperionErrorHandler } from './hyperion-error-handler';

@NgModule({
  imports: [CommonModule, BrowserModule],
  providers: [
    HyperionErrorHandler,
    ModalBridgeService,
    { provide: ErrorHandler, useClass: HyperionErrorHandler },
  ],
})
export class HyperionErrorModule {}
