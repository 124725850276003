import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

import { GlobalService } from 'app/shared/global/index';
import { HelperService } from 'app/shared/helpers/index';
import { MessageService } from 'app/shared/message/index';
import { ApolloQueryService } from 'app/shared/apollo';

@Component({
  selector: 'app-offer-document',
  templateUrl: './offer-document.component.html',
  styleUrls: ['./offer-document.component.scss'],
})
export class OfferDocumentComponent implements OnInit, OnDestroy {
  @Input() offerInfo;
  @Output() totalDocuments = new EventEmitter();

  dataModel = 'documentMeta';
  dataModelCapitalized = 'DocumentMeta';
  documents = new BehaviorSubject([]);
  loading = new BehaviorSubject(true);
  buttons = {
    comment: {},
  };
  buttonObject = ['create'];
  urlPrefix;
  jsonData = [];
  totalCount: number;
  offerDocumentsSub: Subscription;

  constructor(
    private globalService: GlobalService,
    private messageService: MessageService,
    public helperService: HelperService,
    private apolloQueryService: ApolloQueryService
  ) {}

  public ngOnInit(): void {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.getData();
  }

  public getData(): void {
    const variables = { id: Number(this.offerInfo['id']) };

    this.apolloQueryService
      .apolloWatchQueryTwo('offerDocuments', variables, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.offerDocumentsSub = sub;
        this.totalDocuments.emit(data['offer']['documentMeta']['totalCount']);
        const offerDocumentData = { ...data['offer']['documentMeta'] };
        this.documents.next(
          this.helperService.cleanFromNode(offerDocumentData)
        );
        this.loading.next(false);
      });
  }

  public ngOnDestroy(): void {
    if (this.offerDocumentsSub) {
      this.offerDocumentsSub.unsubscribe();
    }
  }

  public onUpload(event): void {
    this.jsonData = [];

    let dataAsJSON;

    if (event.xhr) {
      dataAsJSON = JSON.parse(event.xhr.response);
    } else {
      dataAsJSON = event.originalEvent.body;
    }

    let type = 'error';

    if (dataAsJSON['status'] === 'success') {
      this.jsonData = dataAsJSON['status'];
      type = 'success';
    }
    this.messageService.insertData({
      textArray: [dataAsJSON['msg']],
      time: 2000,
      type: type,
    });

    if (type === 'success') {
      this.getData();
    }
  }
}
