import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { emailValidator } from 'app/shared/forms/validators';
import { MessageService } from 'app/shared/message';
import { first, map } from 'rxjs';
import {
  SigningEventFragment,
  SignlogGQL,
  UpdateMailGQL,
} from './graphql/signlog.generated';

@Component({
  selector: 'app-offer-sign-status',
  templateUrl: './offer-sign-status.component.html',
  styleUrls: ['./offer-sign-status.component.scss'],
})
export class OfferSignStatusComponent implements OnInit {
  @Input() public offerId: string;
  @Output() public showDocuments = new EventEmitter<void>();
  public events: {
    created: string;
    state: string;
    message: string;
    documentUrl: string;
    allowUpdateMail: boolean;
  }[];
  public loading = false;

  public showUpdateMailDialog = false;
  public emailFormControl = new FormControl('', [emailValidator()]);
  public isSending = false;

  constructor(
    private messageService: MessageService,
    private signlogGQL: SignlogGQL,
    private updateMailGQL: UpdateMailGQL
  ) {}

  public STATE_INITIAL = 'initial';
  public STATE_PENDING = 'pending';
  public STATE_VIEWED = 'viewed';
  public STATE_SIGNED = 'signed';
  public STATE_BOUNCED = 'bounced';
  public STATE_REJECTED = 'rejected';
  public STATE_CANCELLED = 'cancelled';

  public TYPE_LEGACY = 'legacy';
  public TYPE_ZIGNED = 'zigned';

  public BY_USER = 'user';
  public BY_CUSTOMER = 'customer';

  public ngOnInit(): void {
    this.getEvents();
  }

  public actionShowUpdateMailDialog(): void {
    this.showUpdateMailDialog = true;
  }

  public actionUpdateMail(): void {
    this.isSending = true;
    const email = this.emailFormControl.value;
    this.updateMailGQL
      .mutate({ offerId: Number(this.offerId), email: email })
      .pipe(first())
      .subscribe({
        next: res => {
          this.isSending = false;
          this.showUpdateMailDialog = false;
          this.messageService.insertDataFromMutation(
            res.data.replaceAgreementParticipantMutation
          );
        },
        error: () => {
          this.showUpdateMailDialog = false;
          this.isSending = false;
        },
      });
  }

  public getEvents(): void {
    this.loading = true;
    this.signlogGQL
      .fetch({ offerId: Number(this.offerId) })
      .pipe(
        first(),
        map(res => res.data.offer.signingEvents.edges.map(e => e.node))
      )
      .subscribe(events => {
        this.events = events.map(e => {
          return {
            created: e.created,
            state: this.getEventStatus(e),
            message: this.getMessage(e),
            documentUrl: e.document?.url,
            allowUpdateMail: e.state === this.STATE_BOUNCED,
          };
        });
        this.loading = false;
      });
  }

  private getEventStatus(event: SigningEventFragment): string {
    switch (event.state) {
      case this.STATE_INITIAL:
        return 'Skapad';
      case this.STATE_PENDING:
        return event.type === this.TYPE_ZIGNED
          ? 'Skickad till signering'
          : 'Skickad';
      case this.STATE_VIEWED:
        return 'Visad';
      case this.STATE_SIGNED:
        return event.type === this.TYPE_ZIGNED ? 'Signerad' : 'Accepterad';
      case this.STATE_REJECTED:
        return 'Avslagen';
      case this.STATE_CANCELLED:
        return 'Avbruten';
      case this.STATE_BOUNCED:
        return 'Mail ej levererat';
    }
  }

  private getMessage(event: SigningEventFragment): string {
    const status = this.getEventStatus(event).toLowerCase();

    if (event.state === this.STATE_BOUNCED) {
      return `Kunde ej levereras till ${event.email}.`;
    }

    if (event.by == this.BY_USER) {
      return `Offert ${status} av användare ${event.user.firstName} ${event.user.lastName} från systemet.`;
    } else {
      const via = event.type === this.TYPE_LEGACY ? 'via e-post' : 'med BankID';
      return `Offert ${status} av kund ${event.email} ${via}.`;
    }
  }
}
