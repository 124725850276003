<div class="upgrade-container" *ngIf="enable | async">
  <h1>Gillar du våra mallar?</h1>
  <p>Testa även våra andra tjänster - offert, tidrapport och projekt</p>

  <p>
    <button
      pButton
      type="button"
      label="Testa kostnadsfritt"
      (click)="showDialog()"
    ></button>
  </p>
</div>
