import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CompanySeatsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CompanySeatsQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', id: string, seats?: { __typename?: 'CompanySeats', seats?: number | null, usedSeats?: number | null } | null } | null };

export const CompanySeatsDocument = gql`
    query companySeats {
  company {
    id
    seats {
      seats
      usedSeats
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CompanySeatsGQL extends Apollo.Query<CompanySeatsQuery, CompanySeatsQueryVariables> {
    document = CompanySeatsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }