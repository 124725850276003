import {
  Component,
  OnChanges,
  Input,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConfirmationService } from 'primeng/api';

import { FormHandlerService } from '../../../../../shared/forms/index';
import { CompanyInvoiceService } from '../../../services/company-invoice.service';
import { InvoiceType } from 'app/invoice/single/enums/invoice-type.enum';

@Component({
  selector: 'app-personer-rot-info',
  templateUrl: './personer-rot-info.component.html',
  styleUrls: ['./personer-rot-info.component.scss'],
  providers: [FormHandlerService],
})
export class PersonerRotInfoComponent implements OnChanges {
  personObject = {
    name: '',
    personalNumber: '',
    nameOfProperty: '',
    housingAssociationOrgNumber: '',
    apartmentDesignation: '',
    customerWantedWorkAmount: '',
    wantedWorkAmount: '',
  };
  formFields = {
    model: 'InvoiceRotRequesting',
    nestedForm: true,
    attributes: {
      persons: [this.personObject],
    },
  };

  @Input() rotPersons: any[];
  @Input() persons;
  @Input() invoiceType: InvoiceType;

  headRotInfoForm: FormGroup;
  rotpersonsSub: Subscription;
  projectPersonSub: Subscription;

  public isRUT: boolean;

  constructor(
    private companyInvoiceService: CompanyInvoiceService,
    private confirmationService: ConfirmationService,
    private cdr: ChangeDetectorRef,
    private formhandler: FormHandlerService
  ) {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isRUT = this.invoiceType === InvoiceType.Rut;

    if (
      changes['rotPersons'] &&
      changes['rotPersons'].currentValue &&
      this.rotPersons.length !== 0
    ) {
      this.clearFormArray(this.DynamicFormControls); // remove old forms and place new forms
      this.rotPersons.map(person => {
        delete person.id;
        if (!person.wantedWorkAmount) {
          person.wantedWorkAmount = '';
        }
        this.addPerson(person);
      });
    }
  }

  get DynamicFormControls() {
    return this.headRotInfoForm.get('persons') as FormArray;
  }

  addPerson(data = this.persons) {
    this.formhandler.addItemToFormArray(
      this.headRotInfoForm,
      this.formFields,
      'persons',
      Object.assign(this.personObject, data)
    );
  }

  deletePerson(postion) {
    let checkIfNotPristine;
    const formValue = (Object as any).values(
      this.DynamicFormControls.controls[postion]['value']
    );
    for (const element in formValue) {
      if (formValue[element] !== '') {
        checkIfNotPristine = true;
        break;
      }
    }

    if (checkIfNotPristine) {
      this.confirmationService.confirm({
        message: 'Vill du verkligen ta bort person?',
        header: 'Bekräfta val',
        icon: 'fa fa-trash',
        accept: () => {
          this.DynamicFormControls.removeAt(postion);
          this.cdr.detectChanges();
        },
      });
    } else {
      this.DynamicFormControls.removeAt(postion);
      this.cdr.detectChanges();
    }
  }

  initForm() {
    const fields = { ...this.formFields };
    this.headRotInfoForm = this.formhandler.groupedFormSimple(fields);
    this.formhandler.groupSetLabelsRules(fields, this.headRotInfoForm);
    this.companyInvoiceService.updateForm(
      'rotPersonInfo',
      this.headRotInfoForm
    );
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };
}
