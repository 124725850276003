import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Table } from 'primeng/table';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

import { ApolloQueryService } from '../../shared/apollo';
import { HyperionLabelsService } from '../../shared/labels/index';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
})
export class LogsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  logs = [];
  cols: any[];
  logsSub: Subscription;

  @ViewChild('logsTable', { static: true }) public logsTable: Table;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private labelsService: HyperionLabelsService,
    public dialogConfig: DynamicDialogConfig
  ) {
    this.dialogConfig.header = 'Händelselogg';
  }

  ngOnInit() {
    this.setTables();
  }

  setTables() {
    this.labelsService.getLabels([{ model: 'CompanyLog' }]).subscribe(data => {
      this.getLogs();

      this.cols = [
        { field: 'model', header: data.CompanyLog.model, width: '10%' },
        { field: 'created', header: data.CompanyLog.created, width: '20%' },
        { field: 'message', header: data.CompanyLog.message, width: 'auto' },
        { field: 'fullName', header: data.CompanyLog.userId, width: '18%' },
      ];
    });
  }

  getLogs() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyLogs')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.logsSub = sub;
        const cleanedData = this.apolloQueryService.cleanFromNode(
          data.company.companyLogs
        );
        this.logs = cleanedData.map(x => {
          let fullName = 'System';

          if (x['user']) {
            fullName = `${x['user']['firstName']} ${x['user']['lastName']}`;
          }

          return { ...x, ...{ fullName: fullName } };
        });

        if (this.cols.length > 0) {
          this.logsTable.globalFilterFields = [
            'model',
            'created',
            'message',
            'fullName',
          ];
        }
      });
  }

  ngOnDestroy() {
    this.logsSub && this.logsSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
