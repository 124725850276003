import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { GlobalService } from 'app/shared/global/index';
import { FormHandlerService } from 'app/shared/forms/index';
import {
  ApolloQueryService,
  ApolloMutationService,
} from 'app/shared/apollo/index';
import { ObservableSubscription } from '@apollo/client/core';

@Component({
  selector: 'app-invoice-mall-settings',
  templateUrl: './invoice-mall-settings.component.html',
  styleUrls: ['./invoice-mall-settings.component.scss'],
  providers: [FormHandlerService],
})
export class InvoiceMallSettingsComponent implements OnInit, OnDestroy {
  loading: boolean;
  disableButton: boolean;
  notImageError = true;
  val3: number;
  urlPrefix: string;
  logoUrl: string;
  id: number;
  max = 350;
  min = 50;
  step = 1;
  invoiceLogoWith = 50;
  componentMainForm: FormGroup;
  invoiceTemplateTextId;
  formFields = {
    model: 'InvoiceTemplateText',
    attributes: {
      footer: '',
      header: '',
    },
  };

  private querySubscription: ObservableSubscription;

  constructor(
    private formHandler: FormHandlerService,
    public apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private globalService: GlobalService
  ) {
    this.loading = true;
    this.urlPrefix = this.globalService.getUrlPrefix();
  }

  ngOnInit() {
    this.initiateCompWithServerDat();
  }

  private initiateCompWithServerDat() {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyInvoiceTemplateText',
        null,
        'cache-and-network'
      )
      .subscribe(({ data, loading, sub }) => {
        this.querySubscription = sub;
        this.id = data['company']['id'];
        this.invoiceLogoWith = data['company']['invoiceLogoWith'];
        this.invoiceTemplateTextId =
          data['company']['InvoiceTemplateText']['id'];
        this.logoUrl = data['company']['logoUrl'];
        this.initForm(data, loading);
      });
  }

  private initForm(data: any, loading: any) {
    this.formHandler
      .groupSetLabelsRules(
        this.formFields,
        null,
        data['company']['InvoiceTemplateText']
      )
      .then(groupedForm => {
        this.componentMainForm = groupedForm;
        this.loading = loading;
      });
  }

  onImgError() {
    this.notImageError = false;
  }

  actionUpdate() {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const variables = this.componentMainForm.value;
      variables['id'] = this.invoiceTemplateTextId;
      this.disableButton = true;
      this.mutationService
        .constructQueryAndExecuteMutation(
          'InvoiceTemplateText',
          'update',
          false,
          variables
        )
        .subscribe(data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            [
              {
                form: this.componentMainForm,
                argument: 'updateInvoiceTemplateText',
              },
            ],
            true
          );

          this.mutationService.displayMutationStatus(data);

          this.disableButton = false;
        });

      this.mutationService
        .constructQueryAndExecuteMutation('Company', 'update', false, {
          id: this.id,
          invoiceLogoWith: this.invoiceLogoWith,
        })
        .subscribe(data => {
          this.mutationService.displayMutationStatus(data);
          this.disableButton = false;
        });
    }
  }

  ngOnDestroy() {
    this.querySubscription && this.querySubscription.unsubscribe();
  }
}
