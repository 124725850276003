<div style="clear: both">
  <div class="hyperion-footer print-no-show">
    <div class="footer-head">
      Supporten når du på {{ configService.getConfig().supportPhone }} eller
      {{ configService.getConfig().supportMail }}
    </div>
    <div class="hr"></div>
    <div class="footer-sub">
      Copyright © {{ year }} by Struqtur AB. All Rights Reserved.
    </div>
  </div>
  <p-confirmDialog
    [style]="{ width: '425' }"
    acceptLabel="Bekräfta"
    rejectLabel="Avbryt"
    #cd
  >
  </p-confirmDialog>

  <p-toast>
    <ng-template let-message pTemplate="message">
      <div class="p-grid p-toast-message-content-container">
        <i
          class="p-col-3 p-toast-icon pi"
          [ngClass]="{
            'pi-info-circle': message.severity === 'info',
            'pi-exclamation-triangle': message.severity === 'warn',
            'pi-times-circle': message.severity === 'error',
            'pi-check-circle': message.severity === 'success'
          }"
        ></i>
        <div class="p-col-9 p-toast-message-text-content">
          <h3 class="p-toast-summary">
            {{ message.summary }}
          </h3>
          <div class="p-toast-detail" [innerHtml]="message['detail']"></div>
        </div>
      </div>
    </ng-template>
  </p-toast>

  <ng-idle
    *ngIf="showIdle | async"
    #idle
    (closeModal)="closeModal()"
    (toggleModal)="toggleModal($event)"
    [latestModal]="latestModal"
  ></ng-idle>

  <p-dialog
    [(visible)]="display.timeout"
    modal="true"
    styleClass="idle-modal timeout-warning"
    resizable="false"
  >
    <timeout-warning #timeout></timeout-warning>
  </p-dialog>
  <p-dialog
    [(visible)]="display.locked"
    modal="true"
    styleClass="idle-modal locked-screen"
    resizable="false"
    closeOnEscape="false"
    [closable]="false"
  >
    <locked-screen #locked (closeModal)="closeModal()"></locked-screen>
  </p-dialog>
</div>
