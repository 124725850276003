import { Apollo } from 'apollo-angular';
import { Injectable, ErrorHandler } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { HyperionLabelsService, LabelsService } from '../labels/index';
import { HyperionRulesService } from '../rules/index';
import {
  CompanyFunctionsService,
  CompanyAppParamsService,
} from '../company/index';

import { zip as observableZip, Observable, Subscription } from 'rxjs';

import { ApolloQueryService } from 'app/shared/apollo';
import { AuthService } from 'app/shared/auth/auth.service';
import { StartupService } from 'app/shared/startup';
import {
  AppStateService,
  appStateOptions,
  AppStates,
} from 'app/store/app-state.service';
import { GlobalService } from 'app/shared/global';
import { IdleService } from 'app/shared/idle';
import { SortService } from 'app/store/sort.service';
import { AnalyticsService } from '../analytics/analytics.service';
import { GlobalInvoiceService } from '../../global-services/invoice/invoice.service';
import { CompleteMetadata } from '../labels/labels.service';
import { UserLocalStorageService } from 'app/shared/user';
import { SplitIoService } from '../split-io.service';

/*
  Run at every route-navigation
*/

@Injectable()
export class DefaultTasks implements Resolve<any> {
  stateOptions: AppStates;
  prevState: string;

  constructor(
    private labelsService: LabelsService,
    private rulesService: HyperionRulesService,
    private companyFunctionsService: CompanyFunctionsService,
    private companyAppParamsService: CompanyAppParamsService,
    private apollo: Apollo,
    private apolloQueryService: ApolloQueryService,
    private authService: AuthService,
    private startupService: StartupService,
    private appStateService: AppStateService,
    private router: Router,
    private globalService: GlobalService,
    private idleService: IdleService,
    private sortService: SortService,
    private errorHandler: ErrorHandler,
    private analyticsService: AnalyticsService,
    private globalInvoiceService: GlobalInvoiceService,
    private userLocalStorageService: UserLocalStorageService,
    private splitIoService: SplitIoService
  ) {
    this.stateOptions = appStateOptions;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.runTasks(state);
  }

  runTasks(routerStateSnapshot) {
    this.analyticsService.navigatedToPage(routerStateSnapshot);
    return this.resolveApp(routerStateSnapshot);
  }

  resolveApp(routerStateSnapshot) {
    const currentState = this.appStateService.getAppStateValue();

    if (currentState === this.stateOptions.INITIAL_STATE) {
      this.appStateService.setAppState(this.stateOptions.STARTUP_IN_PROGRESS); // initialize start
    }

    if (currentState !== this.stateOptions.STARTUP_DONE) {
      return this.setupWithCleanup(currentState, routerStateSnapshot);
    } else {
      return this.normalSetup(currentState, routerStateSnapshot);
    }
  }

  /*
   * If setup hasn't been done before, do a cleanup if data from previous sessions still exists in the client
   */
  setupWithCleanup(currentState, routerStateSnapshot) {
    return Observable.create(observer => {
      this.authService.clearData(currentState, this.prevState).subscribe(v => {
        this.prevState = currentState;
        this.authService.setUserLocaleStorage();

        this.normalSetup(currentState, routerStateSnapshot).subscribe(observer);
      });
    });
  }

  /*
   * If setup is already done, dont do cleanup of data (will erase session data)
   */
  normalSetup(currentState, routerStateSnapshot) {
    return Observable.create(observer => {
      this.getSetupObservables().subscribe(
        () => {
          this.appStateService.setAppState(this.stateOptions.STARTUP_DONE);
          this.idleService.startIdle();

          // EVERYTHING IS DONE, CHECK IF SEND TO STARTUP=TRUE
          this.handleStartUpSettingsAreDone(routerStateSnapshot);
          this.splitIoService.initPlugin();

          observer.next('done');
          observer.complete();
        },
        err => {
          console.error('Failed to set up');
          this.errorHandler.handleError(err);
        }
      );
    });
  }

  handleStartUpSettingsAreDone(routerStateSnapshot) {
    const companyStartupSettingsAreDone = localStorage.getItem(
      'MEcompanyStartupSettingsAreDone'
    );
    const numValue = Number(companyStartupSettingsAreDone);
    const stateUrl = '' + routerStateSnapshot.url;

    if (
      this.authService.isLoggedIn &&
      numValue !== 1 &&
      stateUrl.search('startup=true') === -1
    ) {
      this.router.navigate(['/home/start'], {
        queryParams: { startup: 'true' },
      });
    }
  }

  private getSetupObservables(): Observable<any> {
    const companyFunctions = this.getCompanyFunctions();
    const sortState = this.getSortState();
    const companyInfo = this.getCompanyInfo();
    const appMetaData: Observable<CompleteMetadata> =
      this.labelsService.getAll();
    const appParams: Observable<any> = this.companyAppParamsService.appParams;

    if (this.authService.isLoggedIn) {
      const retval = observableZip(
        companyFunctions,
        sortState,
        companyInfo,
        appMetaData,
        appParams
      );
      return retval;
    } else {
      const retval = observableZip(appMetaData, appParams);
      return retval;
    }
  }

  /* --- CALLBACK SERVICES --- */
  getCompanyFunctions() {
    const currentState = this.appStateService.getAppStateValue();
    if (currentState === this.stateOptions.STARTUP_DONE) {
      return this.appStateService.getDummyObservable();
    } else {
      return this.companyFunctionsService.getCompanyFunctions();
    }
  }

  getSortState() {
    const currentState = this.appStateService.getAppStateValue();
    if (currentState === this.stateOptions.STARTUP_DONE) {
      return this.appStateService.getDummyObservable();
    } else {
      return this.sortService.getSortStateFromServer();
    }
  }

  getCompanyInfo() {
    const currentState = this.appStateService.getAppStateValue();
    if (currentState === this.stateOptions.STARTUP_DONE) {
      return this.appStateService.getDummyObservable();
    } else {
      return Observable.create(observer => {
        this.apolloQueryService
          .apolloQuery('companyInfo')
          .subscribe(({ data }) => {
            this.setCompanyInfoInLS(data);

            observer.next('done');
            observer.complete();
          });
      });
    }
  }

  setCompanyInfoInLS(data) {
    localStorage.setItem(
      'MEcompanyStartupSettingsAreDone',
      data['company']['startupSettingsAreDone']
    );
    localStorage.setItem('MEcompanyName', data['company']['name']);
    localStorage.setItem('MEcompanyLogo', data['company']['logoUrl']);
    localStorage.setItem('MEcompanyId', data['company']['id']);
    localStorage.setItem('MEcompanyCreated', data['company']['created']);
  }
}
