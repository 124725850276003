import { DialogComponent } from 'app/shared/dialogs/dialog.component';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApolloQueryService } from 'app/shared/apollo';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'offer-data',
  templateUrl: 'offer-data.component.html',
})
export class OfferDataComponent
  extends DialogComponent
  implements OnInit, OnDestroy
{
  loading = false;
  activeTab = 'orderValue';
  orientation = 'top';
  display = false;
  locale = this.globalService.getLocale('sv');
  chartBar = {};
  offerData = [];
  stateOne = true;
  stateTwo = true;
  stateThree = true;
  maxCreated;
  minCreated;
  stateThreeCount = 0;
  stateTwoCount = 0;
  stateOneCount = 0;
  stateThreeCountProcent = 0;
  stateTwoCountProcent = 0;
  stateOneCountProcent = 0;
  subscription: Subscription;

  constructor(
    public dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    protected apolloQueryService: ApolloQueryService
  ) {
    super();
    this.dialogConfig.header = 'Data för offert';
  }

  ngOnInit() {
    const variables = { status: [1] };

    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyOffersWithSummation',
        variables,
        'cache-and-network'
      )
      .subscribe(data => {
        this.subscription = data.sub;
        this.offerData = this.helperService.cleanFromNode(
          data.data['company']['offers']
        );

        if (this.offerData.length > 1) {
          this.maxCreated = this.offerData.reduce((prev, current) => {
            return prev.created > current.created ? prev : current;
          }).created;
          this.minCreated = this.offerData.reduce((prev, current) => {
            return prev.created < current.created ? prev : current;
          }).created;
        }
        this.setGraphData();
      });
  }

  setGraphData() {
    this.stateThreeCount = 0;
    this.stateTwoCount = 0;
    this.stateOneCount = 0;

    const offerData = this.offerData;
    const dateArray = this.getDates(
      new Date(this.minCreated),
      new Date(this.maxCreated)
    );

    const daSerie = [];

    for (const i in offerData) {
      const offer = offerData[i];

      let add = false;
      let color = '';
      switch (offer.state) {
        case 1:
          color = '#ffe7ab';
          add = this.stateOne;
          break;
        case 2:
          color = '#90e8d7';
          add = this.stateTwo;
          break;
        case 3:
          color = '#ffab99';
          add = this.stateThree;
          break;
      }

      const currentDate = new Date(offer.created);
      const currentYearMonth =
        currentDate.getMonth() + 1 + '/' + currentDate.getFullYear();
      const index = dateArray.indexOf(currentYearMonth);

      if (add && index !== -1) {
        const offerGraphData = dateArray.slice(0).fill(0);
        offerGraphData[index] += Math.round(
          offer.priceSummation_PreOfferTypeHyperion.priceListSum * 1
        );

        this.stateThreeCount += +(+offer.state === 3);
        this.stateTwoCount += +(+offer.state === 2);
        this.stateOneCount += +(+offer.state === 1);

        daSerie.push({
          name: offer.trueId + ', ' + offer.concerning,
          stack: offer.state,
          data: offerGraphData,
          color: color,
        });
      }
    }
    const totalCount =
      this.stateThreeCount + this.stateTwoCount + this.stateOneCount;

    this.stateThreeCountProcent =
      totalCount > 0
        ? Math.round((this.stateThreeCount / totalCount) * 100)
        : 0;
    this.stateTwoCountProcent =
      totalCount > 0 ? Math.round((this.stateTwoCount / totalCount) * 100) : 0;
    this.stateOneCountProcent =
      totalCount > 0 ? Math.round((this.stateOneCount / totalCount) * 100) : 0;

    this.chartBar = {
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        allowDecimals: false,

        categories: dateArray,
        title: {
          text: 'Offert skapad, månad',
        },
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: 'Summa offerter / månad',
        },
        labels: {
          overflow: 'justify',
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          borderColor: '#000',
        },
      },
      legend: {
        enabled: true,
      },
      tooltip: {
        formatter: function () {
          return (
            '<b>' +
            this.x +
            '</b><br/>' +
            this.series.name +
            ': ' +
            this.y +
            'kr<br/>' +
            'Total: ' +
            this.point.stackTotal +
            'kr'
          );
        },
      },
      series: daSerie,
    };
  }

  toogleDialog() {
    if (!this.display) {
      this.display = true;
    } else {
      this.display = false;
    }
  }
  scrollToTop() {
    const mdDialogContainer =
      document.getElementsByClassName('p-dialog-content')[0];
    mdDialogContainer.scrollTop = 0;
  }

  changeActiveTab(event) {
    const index = event['index'];

    switch (index) {
      case 0:
        this.activeTab = 'orderValue';
        break;
      case 1:
        this.activeTab = 'days?';
        break;
    }
  }

  private getDates(startDate, stopDate) {
    const dateArray = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      const currentYearMonth =
        currentDate.getMonth() + 1 + '/' + currentDate.getFullYear();

      if (dateArray.indexOf(currentYearMonth) === -1) {
        dateArray.push(currentYearMonth);
      }
      const dat = new Date(currentDate);
      dat.setDate(dat.getDate() + 1);
      currentDate = dat;
    }
    return dateArray;
  }

  closeAction() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }
}
