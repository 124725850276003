<div class="p-grid">
  <div class="p-col-12">
    <p-table
      #table
      [value]="days | async"
      class="inline-table"
      [showCurrentPageReport]="true"
      [showPageLinks]="false"
      [showFirstLastIcon]="false"
      currentPageReportTemplate="Sida {first}"
      rowGroupMode="subheader"
      groupRowsBy="day.date"
      groupRowsByOrder="-1"
      dataKey="day.date"
      [class.scrollable]="!isExpanded"
      [class.scrollable-fullscreen]="isExpanded"
      (onRowExpand)="recheckOpenedNodes()"
      (onRowCollapse)="recheckOpenedNodes()"
      (onEditComplete)="setEditing(false)"
      (onEditInit)="setEditing(true)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th
            *ngFor="let col of getColumns()"
            [style.width]="col.width"
            [class]="col.icon ? 'text-cell-center' : 'p-text-truncate'"
          >
            <span *ngIf="col.field !== 'date'">
              {{ col.header }}

              <i
                [pTooltip]="col.tooltip"
                *ngIf="col.icon"
                [class]="col.icon"
              ></i>
            </span>

            <span *ngIf="col.field === 'date'" class="p-d-flex p-jc-between">
              {{ col.header }}
              <button
                *ngIf="col.field === 'date'"
                class="p-link"
                [style]="{ fontSize: '1.1rem' }"
                (click)="toggleAll($event)"
              >
                <i
                  class="pi pi-angle-double-right"
                  *ngIf="!areTimereportsExpanded"
                ></i>
                <i
                  class="pi pi-angle-double-down"
                  *ngIf="areTimereportsExpanded"
                ></i>
              </button>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="groupheader" let-dayData let-expanded="expanded">
        <tr pRowGroupHeader>
          <ng-container *ngFor="let col of getColumns()">
            <td
              *ngIf="col.field === 'doneWork'"
              [pEditableColumn]="dayData.day[col.field]"
              [pEditableColumnField]="col.field"
              [pEditableColumnDisabled]="dayData.isFullDayInvoiced"
              [style.whiteSpace]="'break-spaces'"
              [class]="
                col.field !== 'invoiceId' && col.field !== 'actionMenu'
                  ? 'p-text-truncate'
                  : ''
              "
            >
              <p-cellEditor (change)="updateDay(dayData)">
                <ng-template pTemplate="input">
                  <textarea
                    pInputTextarea
                    appDisableArrowNavigation
                    (keydown.enter)="updateDay(dayData)"
                    [(ngModel)]="dayData.day[col.field]"
                  ></textarea>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ dayData.day[col.field] }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td
              *ngIf="col.field !== 'doneWork'"
              [style.whiteSpace]="'break-spaces'"
              [class]="
                col.field !== 'invoiceId' && col.field !== 'actionMenu'
                  ? 'p-text-truncate'
                  : ''
              "
            >
              <span
                *ngIf="col.field === 'date'"
                [pRowToggler]="dayData"
                [attr.class]="
                  col.field === 'date'
                    ? 'p-text-bold p-d-flex p-jc-between'
                    : ''
                "
                [style.cursor]="'pointer'"
                class=""
              >
                {{ dayData.day[col.field] }}
                <i
                  *ngIf="!dayData.day.isFullDayEmpty"
                  class="pi pi-chevron-right"
                  [style.transform]="
                    expanded ? 'rotate(90deg)' : 'rotate(0deg)'
                  "
                ></i>
              </span>

              <span
                *ngIf="
                  [
                    'hoursToInvoice',
                    'hours',
                    'mileToInvoice',
                    'mile',
                    'privMile'
                  ].includes(col.field)
                "
              >
                {{
                  calculateDateSumField(
                    days | async,
                    col.field,
                    dayData.day.date
                  ) | number: '1.0-2'
                }}</span
              >

              <span *ngIf="col.field === 'invoiceId'">
                <i
                  *ngIf="dayData.isFullDayInvoiced"
                  class="pi pi-check"
                  style="color: var(--btn-success-color)"
                ></i>
              </span>

              <ng-container *ngIf="col.field === 'actionMenu'">
                <p-menu
                  appendTo="body"
                  #menuFullDay
                  [popup]="true"
                  [model]="actionMenus"
                ></p-menu>
                <button
                  *ngIf="!dayData.isSaving"
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectedDayEdit = null;
                    selectedDayEdit = dayData.day;
                    generateActionsMenuFullDay(dayData);
                    menuFullDay.toggle($event)
                  "
                ></button>
                <button
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-spinner pi-spin"
                  *ngIf="dayData.isSaving"
                ></button>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-dayData>
        <tr *ngIf="dayData.day.user">
          <ng-container *ngFor="let col of getColumns()">
            <td
              *ngIf="!col.editable"
              [style.whiteSpace]="'break-spaces'"
              [class]="
                col.field !== 'invoiceId' && col.field !== 'actionMenu'
                  ? 'p-text-truncate'
                  : ''
              "
            >
              <span *ngIf="col.field === 'date'">
                {{ dayData.day.user.firstName }} {{ dayData.day.user.lastName }}
              </span>

              <span *ngIf="col.field === 'notarized'">
                {{ dayData.notarizedUserInitials }}
              </span>

              <span *ngIf="col.field === 'invoiceId'">
                <i
                  *ngIf="dayData.day.invoiceId"
                  class="pi pi-check"
                  style="color: var(--btn-success-color); cursor: pointer"
                  [routerLink]="['../../../', 'invoice', dayData.day.invoiceId]"
                  pTooltip="Visa relaterad faktura"
                ></i>
              </span>

              <span *ngIf="col.field === 'todoId'">
                <i
                  *ngIf="dayData.day.todoId"
                  class="pi pi-link"
                  [pTooltip]="todoMap[dayData.day.todoId].description"
                  [routerLink]="['../', 'worktasks']"
                ></i>
                <i
                  *ngIf="dayData.day.todoRelation?.todo_id"
                  class="pi pi-link"
                  [pTooltip]="
                    todoMap[dayData.day.todoRelation?.todo_id].description
                  "
                  [routerLink]="['../', 'worktasks']"
                ></i>
              </span>

              <ng-container *ngIf="col.field === 'actionMenu'">
                <p-menu
                  appendTo="body"
                  #menu
                  [popup]="true"
                  [model]="actionMenus"
                ></p-menu>
                <button
                  *ngIf="!dayData.isSaving"
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectedDayEditId = dayData.day.id;
                    selectedDayEdit = null;
                    generateActionsMenu(dayData);
                    menu.toggle($event)
                  "
                ></button>
                <button
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-spinner pi-spin"
                  *ngIf="dayData.isSaving"
                ></button>
              </ng-container>
            </td>
            <td
              *ngIf="col.editable"
              [pEditableColumn]="dayData.day[col.field]"
              [pEditableColumnField]="col.field"
              [pEditableColumnDisabled]="
                dayData.editDisabled && col.field !== 'doneWork'
              "
              [style.whiteSpace]="'break-spaces'"
              [class]="
                col.field !== 'invoiceId' && col.field !== 'actionMenu'
                  ? 'p-text-truncate'
                  : ''
              "
            >
              <p-cellEditor
                (change)="updateDay(dayData)"
                *ngIf="
                  col.field === 'hoursToInvoice' ||
                  col.field === 'mileToInvoice' ||
                  col.field === 'hours' ||
                  col.field === 'privMile'
                "
                [pTooltip]="dayData.day[col.field]"
              >
                <ng-template pTemplate="output">
                  {{ dayData.day[col.field] }}
                </ng-template>
                <ng-template pTemplate="input">
                  <input
                    pInputText
                    appDisableArrowNavigation
                    [(ngModel)]="dayData.day[col.field]"
                    (keydown.enter)="updateDay(dayData)"
                  />
                </ng-template>
              </p-cellEditor>

              <p-cellEditor
                (change)="updateDay(dayData)"
                [pTooltip]="dayData.day[col.field]"
                *ngIf="col.field === 'doneWork'"
              >
                <ng-template pTemplate="output">
                  <span class="p-d-flex p-jc-between p-ai-center">
                    {{ dayData.day.doneWork }}

                    <button
                      pButton
                      class="p-button-text legacy-rounded-button"
                      icon="pi pi-arrow-up"
                      (click)="
                        addTextToParent(dayData.day.doneWork, dayData.parentId)
                      "
                      pTooltip="Lägg till text i underlag"
                    ></button>
                  </span>
                </ng-template>
                <ng-template pTemplate="input">
                  <textarea
                    pInputTextarea
                    appDisableArrowNavigation
                    [(ngModel)]="dayData.day[col.field]"
                    (keydown.enter)="updateDay(dayData)"
                  ></textarea>
                </ng-template>
              </p-cellEditor>

              <p-cellEditor
                (change)="updateDay(dayData)"
                *ngIf="col.field === 'companyCostTypeId'"
              >
                <ng-template pTemplate="output">
                  {{ dayData.userCostType?.name }}
                </ng-template>
                <ng-template pTemplate="input">
                  <p-dropdown
                    appDisableArrowNavigation
                    [options]="userCosttypeDropdown"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="dayData.day.newCostTypeId"
                    appendTo="body"
                    (onChange)="updateDay(dayData)"
                    [filter]="true"
                  ></p-dropdown>
                </ng-template>
              </p-cellEditor>

              <p-cellEditor
                (change)="updateDay(dayData)"
                *ngIf="col.field === 'overtimeHardCoded'"
              >
                <ng-template pTemplate="output">
                  {{ dayData.attendanceType?.name }}
                </ng-template>
                <ng-template pTemplate="input">
                  <p-dropdown
                    appDisableArrowNavigation
                    [options]="attendanceTypeDropdown"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="dayData.day.overtimeHardCoded"
                    appendTo="body"
                    (onChange)="updateDay(dayData)"
                  ></p-dropdown>
                </ng-template>
              </p-cellEditor>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [colSpan]="2">
            <div class="p-d-flex p-jc-between p-ai-center">
              <span> Visa fullständig summering </span>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-plus"
                class="p-button-rounded p-button-outlined legacy-rounded-button"
                (click)="toggleShowFullSummary()"
              ></button>
            </div>
          </td>
          <td class="p-text-bold" *ngIf="isExpanded">
            {{ fieldsSummary.hours | number: '1.2-2' }}
          </td>
          <td class="p-text-bold">
            {{ fieldsSummary.hoursToInvoice | number: '1.2-2' }}
          </td>
          <td class="p-text-bold" *ngIf="isExpanded && setMile && setPrivMile">
            {{ fieldsSummary.privMile | number: '1.0-2' }}
          </td>
          <td class="p-text-bold" *ngIf="setMile">
            {{ fieldsSummary.mileToInvoice | number: '1.0-2' }}
          </td>
          <td
            [colSpan]="
              isExpanded
                ? (useUserCostType ? 1 : 0) +
                  (usePickOvertimeOnTimereport ? 1 : 0) +
                  (useNotarized ? 1 : 0) +
                  3
                : 3
            "
          ></td>
        </tr>
        <tr *ngIf="showFullSummary">
          <td
            [colSpan]="
              isExpanded
                ? (useUserCostType ? 1 : 0) +
                  (usePickOvertimeOnTimereport ? 1 : 0) +
                  (setMile ? 1 : 0) +
                  (setMile && setPrivMile ? 1 : 0) +
                  8
                : setMile
                ? 7
                : 5
            "
            style="border-top: 0"
          >
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column">
              <span
                >Antal dagar:
                {{ fullCalculationSummary.totalDays | number: '1.2-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Fakturerade timmar:
                {{ fullCalculationSummary.hoursInvoiced | number: '1.2-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Ej fakturerade timmar:
                {{ fullCalculationSummary.hoursNotInvoiced | number: '1.2-2' }}
                st</span
              >
            </div>
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column" *ngIf="setMile">
              <span class="p-mt-1"
                >Fakturerade mil:
                {{ fullCalculationSummary.milesInvoiced | number: '1.0-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Ej fakturerade mil:
                {{ fullCalculationSummary.milesNotInvoiced | number: '1.0-2' }}
                st</span
              >
            </div>
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column">
              <span class="p-mt-1">Traktamenten</span>
              <span class="p-mt-1"
                >Heldag:
                {{ fullCalculationSummary.subsistenceDays }}
                st</span
              >
              <span class="p-mt-1"
                >Halvdag:
                {{ fullCalculationSummary.subsistenceHalfDays }}
                st</span
              >
              <span class="p-mt-1"
                >Natt:
                {{ fullCalculationSummary.subsistenceNights }}
                st</span
              >
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col-12">
    <button
      pButton
      style="float: right"
      class="p-button-primary-hyperion"
      label="Lägg till tidrapporter"
      icon="pi pi-plus"
      (click)="showCreateNewDayForm()"
    ></button>
  </div>

  <p-sidebar
    showCloseIcon="false"
    [(visible)]="isDayFormSidebarVisible"
    (onHide)="onHideDayFormSidebar()"
    position="right"
    [style]="{ width: '45rem' }"
  >
    <h1 *ngIf="(selectedDayEditId || selectedDayEdit) && !isEditHeader">
      Redigera tidrapport
    </h1>
    <h1 *ngIf="(selectedDayEditId || selectedDayEdit) && isEditHeader">
      Redigera fakturaunderlag
    </h1>
    <h1 *ngIf="!selectedDayEditId && !selectedDayEdit">Skapa tidrapport</h1>
    <app-day-form
      (dayUpdatedEvent)="onDayUpdatedEvent($event)"
      [day]="{ dayId: selectedDayEditId, day: selectedDayEdit }"
      [projectId]="projectId"
      [isExtra]="isExtra"
    ></app-day-form>
  </p-sidebar>
</div>
