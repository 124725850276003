import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

@Injectable()
export class ModalBridgeService {
  modalSubject = new Subject();
  storedUserSubject = new Subject();

  triggerModal(modalName: string) {
    this.modalSubject.next(modalName);
  }
}
