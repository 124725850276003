import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MessageService } from 'app/shared/message';
import { UserFlagsService } from 'app/user-flags.service';
import { first } from 'rxjs';

import {
  NextInvoiceNrGQL,
  SetNextInvoiceNrPreferenceGQL,
} from './graphql/nextInvoiceNr.generated';

@Component({
  selector: 'app-invoice-number-settings',
  templateUrl: './invoice-number-settings.component.html',
})
export class InvoiceNumberSettingsComponent implements OnInit {
  public loading = false;
  public control: FormControl<number>;
  public nextInvoiceNr: number;
  public useVismaEAccounting: boolean;
  public useFortnoxInvoiceNumber: boolean;

  constructor(
    private nextInvoiceNrGQL: NextInvoiceNrGQL,
    private setNextInvoiceNrPreferenceGQL: SetNextInvoiceNrPreferenceGQL,
    private userFlagsService: UserFlagsService,
    private messageService: MessageService
  ) {}

  public ngOnInit(): void {
    this.getNextInvoiceNr();

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.useVismaEAccounting = flags.hasFlag('useVismaEAccounting');
      });
  }

  private getNextInvoiceNr(): void {
    this.loading = true;
    this.nextInvoiceNrGQL
      .fetch()
      .pipe(first())
      .subscribe(res => {
        this.nextInvoiceNr = res.data.company.nextInvoiceNr;
        this.control = new FormControl(null, [
          Validators.min(this.nextInvoiceNr - 1),
        ]);
        this.useFortnoxInvoiceNumber =
          res.data.company.fortnox?.useFortnoxInvoiceNumber === 1;
        this.loading = false;
      });
  }

  public actionUpdate(): void {
    if (!this.control.valid) {
      return;
    }
    this.loading = true;
    const nextInvoiceNr = this.control.value;
    console.log('nextInvoiceNr', nextInvoiceNr);
    this.setNextInvoiceNrPreferenceGQL
      .mutate({ nextPreferredInvoiceNumber: nextInvoiceNr })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.companyTypeHyperionMutation
        );
        this.loading = false;
        this.control.setValue(null);
        this.getNextInvoiceNr();
      });
    return;
  }
}
