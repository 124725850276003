<p-dialog
  [(visible)]="isVisible"
  [header]="title"
  [modal]="true"
  (onHide)="handleCloseLightboxEvent()"
>
  <div>
    <form *ngIf="form" [formGroup]="form">
      <div class="content">
        <div class="cell-2">
          <div>
            <label>Projekt</label>
          </div>
          <div>
            <p-dropdown
              formControlName="project"
              placeholder="Välj projekt.."
              optionLabel="label"
              dataKey="value"
              filter="true"
              appendTo="body"
              [options]="projectsDropdown"
            ></p-dropdown>
          </div>
        </div>

        <div class="cell-2">
          <div>
            <label>Rubrik</label>
          </div>
          <div>
            <p-dropdown
              formControlName="header"
              placeholder="Välj rubrik.."
              optionLabel="label"
              dataKey="value"
              filter="true"
              appendTo="body"
              [options]="todotopicsDropdown"
            ></p-dropdown>
          </div>
        </div>

        <div class="cell-2">
          <div>
            <label>Arbetsmoment</label>
          </div>
          <div>
            <input
              pInputText
              type="text"
              formControlName="text"
              placeholder="Arbetsmoment.."
            />
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Startdatum</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="startDate"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              [panelStyleClass]="'edit-work-task-calendar'"
              [locale]="datePickerLocale"
              [minDate]="projectStartDate"
              [maxDate]="projectEndDate"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template></p-calendar
            >
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label>Slutdatum</label>
          </div>
          <div>
            <p-calendar
              [showIcon]="true"
              formControlName="endDate"
              dateFormat="yy-mm-dd"
              showWeek="true"
              appendTo="body"
              [minDate]="projectStartDate"
              [maxDate]="projectEndDate"
              [panelStyleClass]="'edit-work-task-calendar'"
              [locale]="datePickerLocale"
            >
              <ng-template pTemplate="date" let-date>
                <span [class.is-red-day]="isRedDay(date)">{{ date.day }}</span>
              </ng-template></p-calendar
            >
          </div>
        </div>

        <div class="cell-1">
          <div>
            <label
              >Estimerad tid
              <span style="font-size: 0.8em; font-style: italic"
                >(timmar)</span
              ></label
            >
          </div>
          <div>
            <input
              pInputText
              type="number"
              formControlName="estimatedTime"
              placeholder="Estimerad tid.."
            />
          </div>
        </div>
        <div class="cell-1">
          <div>
            <label>Utförd</label>
          </div>
          <div>
            <p-toggleButton
              formControlName="done"
              [onLabel]="'Utförd'"
              [offLabel]="'Ej utförd'"
              [onIcon]="'pi pi-check-circle'"
              [offIcon]="'pi pi-circle-off'"
              [style]="{ width: '100%' }"
            ></p-toggleButton>
          </div>
        </div>
      </div>

      <p-footer>
        <div class="buttons-grid">
          <div>
            <app-color-picker
              [form]="form"
              [controlName]="'color'"
            ></app-color-picker>
          </div>
          <div class="buttons">
            <button
              pButton
              *ngIf="!isNewEvent"
              type="button"
              class="p-button-danger button-width-update"
              [label]="!isDataDeleting ? 'Radera' : ''"
              [icon]="isDataDeleting ? 'pi pi-spin pi-spinner' : ''"
              [disabled]="form.invalid || isDataLoading || isDataDeleting"
              [style]="{ 'margin-right': '5px' }"
              (click)="deleteConfirm()"
            ></button>
            <div [pTooltip]="validationText(validationTextList)">
              <button
                pButton
                *ngIf="!isNewEvent"
                type="submit"
                class="p-button-primary-hyperion button-width-update"
                [label]="!isDataLoading ? 'Uppdatera' : ''"
                [icon]="isDataLoading ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="form.invalid || isDataLoading || isDataDeleting"
                (click)="update()"
              ></button>
              <button
                pButton
                *ngIf="isNewEvent"
                type="submit"
                class="p-button-primary-hyperion button-width-save"
                [label]="!isDataLoading ? 'Spara' : ''"
                [icon]="isDataLoading ? 'pi pi-spin pi-spinner' : ''"
                [disabled]="form.invalid || isDataLoading || isDataDeleting"
                (click)="save()"
              ></button>
            </div>
          </div>
        </div>
      </p-footer>
    </form>
  </div>
</p-dialog>
