import { gql } from 'apollo-angular';

const COMPANY_ACCOUNTING_PLAN_ACCOUNTS_QUERY = gql`
  query fetchCompanyAccountingPlanAccounts {
    company {
      id
      accountingPlanAccounts {
        edges {
          node {
            id
            active
            number
            description
          }
        }
      }
    }
  }
`;

export const accountingPlanQueries = {
  getCompanyAccountingPlanAccounts: COMPANY_ACCOUNTING_PLAN_ACCOUNTS_QUERY,
};
