import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { CompanySuperService } from '../company.super.service';

@Injectable()
export class CompanyProjectsService extends CompanySuperService {
  dataModel = 'project';
  modelForObs = 'companyProjects';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsWithLabels = [];

    dataObjectsWithLabels.push({ label: 'Visa alla projekt', value: null });

    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.trueId + ', ' + dataArray[i].node.mark,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel + 's'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      const projectInfo =
        dataArray[i]['node']['trueId'] + ', ' + dataArray[i]['node']['mark'];

      const dataObject = { ...dataArray[i].node };

      if (dataObject['extra'] == null) {
        dataObject['extraText'] = 'Nej';
      } else {
        dataObject['extraText'] = 'Ja';
      }
      dataObject['projectInfo'] = projectInfo;
      dataObjectsForForms.push(dataObject);
    }
    return dataObjectsForForms;
  }

  formatExecutedData(dataObject) {
    const dataObjectForForms = dataObject;

    if (dataObjectForForms.hasOwnProperty('mutationDetails')) {
      delete dataObjectForForms['mutationDetails'];
    }

    if (dataObjectForForms.hasOwnProperty('__typename')) {
      delete dataObjectForForms['__typename'];
    }

    return dataObjectForForms;
  }
}
