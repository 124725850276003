import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FilterMatchMode, SortEvent } from 'primeng/api';
import { Table } from 'primeng/table';

import {
  TableMetadata,
  RowGroupedMetadata,
  TableSums,
  TableDataRow,
  ColumnMetadata,
} from './table-metadata';
import { SortService } from 'app/store/sort.service';

import { DynamicPipe } from 'app/shared/pipes/dynamic-pipe/dynamic.pipe';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() public models: TableDataRow[];

  @Input() public tableMetadata: TableMetadata;

  @Input() public rowExpand: TemplateRef<any>;

  @Input() public tableCaption: TemplateRef<any>;

  @Input() public dataKey = 'id';

  @Output() public sorted = new EventEmitter<any[]>();

  @Output() public filtered = new EventEmitter<any[]>();

  public showFilterInput: boolean[] = [];

  public expandedRows: boolean[] = [];

  public rowGroupMetadata: RowGroupedMetadata = {};

  public tableSums: TableSums = {};

  public hasCustomSort = false;
  public customSortFunction: (event: SortEvent) => void;

  public isLoading: boolean;

  public hasPagination: boolean;

  @ViewChild('table', { static: true })
  private table: Table;

  constructor(
    private sortService: SortService,
    private dynamicPipe: DynamicPipe
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    if (this.models && this.tableMetadata) {
      this.setupTable(this.tableMetadata);
      this.updateData(this.models);
    }
    this.isLoading = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }

    if (changes.models && changes.models.currentValue) {
      this.updateData(changes.models.currentValue);
    }

    if (changes.tableMetadata && changes.tableMetadata.currentValue) {
      this.setupTable(changes.tableMetadata.currentValue);
    }
  }

  private setupTable(tableMetadata: TableMetadata): void {
    if (!this.tableMetadata || !this.table) {
      throw new Error('setupTable did not have the required parameters');
    }

    if (tableMetadata.paginator && tableMetadata.paginator.rows && this.table) {
      this.table.resetPageOnSort = false;
      this.hasPagination = true;
    }

    if (tableMetadata.groupBy) {
      this.hasCustomSort = true;
      this.customSortFunction = this.customSort;
    }

    if (tableMetadata.sortBy) {
      const customSortAttributes = this.sortService.getSort(
        tableMetadata.sortBy
      );
      tableMetadata.sortBy = customSortAttributes;

      /**
       * HACK: sets the header sorting. Notice we set it to the table private
       * variables, as the public setters force a sort that we don't want to do.
       * We do not trigger a sort because the data is then sorted when updating
       * the table data (@batista 20191016)
       */
      this.table._sortOrder = customSortAttributes.ascDesc;
      this.table._sortField = customSortAttributes.attribute;
    }

    this.tableMetadata = tableMetadata;
  }

  private updateData(models: TableDataRow[]): void {
    this.models = this.parseDataCellsToString(
      models,
      this.tableMetadata.columnsMetadata
    );
  }

  /**
   * Filters values across the table based on the fields defined in `tableMetadata.globalFilterFields`
   * @param value the search query
   * @param matchMode the type of matching to be used in
   */
  public filterGlobal(value: any, matchMode: FilterMatchMode): void {
    this.table.filterGlobal(value, matchMode);
  }

  /**
   * Given a SortEvent, sorts the table and saves the new sorting definition.
   *
   * @param event the sorting specs
   */
  public customSort(event: SortEvent): any[] {
    this.isLoading = true;

    let data = event.data;
    data = this.sort(data, this.tableMetadata.groupBy.field, 1);
    data = this.sort(data, event.field, event.order);

    this.calculateTable(data);

    this.updateSort({ ...event, data: data });

    this.isLoading = false;

    return data;
  }

  public updateSort(event) {
    this.sortService.setSort(
      {
        field: event.field,
        order: event.order,
      },
      this.tableMetadata.sortBy
    );

    this.sorted.emit(event.data);
  }

  /**
   * Given a groupKey, returns the index of its summary row within the whole table
   */
  public summaryIndex(groupKey: string): number {
    if (!groupKey || !this.rowGroupMetadata[groupKey]) {
      return;
    }
    return (
      this.rowGroupMetadata[groupKey].index +
      this.rowGroupMetadata[groupKey].size -
      1
    );
  }

  public rowTrackBy(index: number, row: any) {
    return row.id;
  }

  public columnTrackBy(index: number, column: any) {
    return column.key;
  }

  private sort(array: any[], field: string, order: number): any[] {
    return array.sort((data1, data2) => {
      if (
        !data1[this.tableMetadata.groupBy.field] ||
        !data2[this.tableMetadata.groupBy.field]
      ) {
        return;
      }

      const group1 = data1[this.tableMetadata.groupBy.field]
        .toString()
        .toLowerCase();
      const group2 = data2[this.tableMetadata.groupBy.field]
        .toString()
        .toLowerCase();
      if (group1 > group2) {
        return 1;
      }
      if (group1 < group2) {
        return -1;
      }

      const value1 = data1[field];
      const value2 = data2[field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else {
        result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
      }

      return order * result;
    });
  }

  public toggleFilterInput(key: string): void {
    this.showFilterInput[key] = !this.showFilterInput[key];
    if (!this.showFilterInput[key]) {
      this.resetFilter(key);
    }
  }

  public filter(event) {
    this.isLoading = true;
    this.calculateTable(event.filteredValue);

    this.filtered.emit(event.filteredValue);
    this.isLoading = false;
  }

  /**
   * Processes the data with their corresponding pipe (if any),
   * and appends as `model[key + 'text']`, example: `model[time] => model[timeText]`
   */
  private parseDataCellsToString(
    data: TableDataRow[],
    columnsMetadata: ColumnMetadata[]
  ): TableDataRow[] {
    return data.map(model => {
      columnsMetadata.forEach(column => {
        model[column.key + 'text'] = this.valueToText(
          column,
          model[column.key]
        );
      });

      return model;
    });
  }

  private calculateTable(data: TableDataRow[]): void {
    this.updateRowGroupMetaData(data);

    if (this.tableMetadata.showTableTotals) {
      this.tableSums = this.calculateTotals(data);
    }
  }

  private updateRowGroupMetaData(data: TableDataRow[]): TableDataRow[] {
    this.rowGroupMetadata = {};

    if (!data || !data.length) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      const rowData = data[i];

      if (
        !this.tableMetadata.groupBy ||
        !rowData.hasOwnProperty(this.tableMetadata.groupBy.field)
      ) {
        return data;
      }

      const group = rowData[this.tableMetadata.groupBy.field];
      if (i === 0) {
        this.rowGroupMetadata[group] = { index: 0, size: 1 };
      } else {
        const previousRowData = this.models[i - 1];
        const previousRowGroup =
          previousRowData[this.tableMetadata.groupBy.field];
        if (group === previousRowGroup) {
          this.rowGroupMetadata[group] && this.rowGroupMetadata[group].size++;
          !this.rowGroupMetadata[group] && { index: 0, size: 1 };
        } else {
          this.rowGroupMetadata[group] = { index: i, size: 1 };
        }
      }

      if (
        !this.tableMetadata.groupTotalsPosition ||
        this.tableMetadata.groupTotalsPosition === 'none'
      ) {
        continue;
      }

      let foundSummables = false;
      this.tableMetadata.columnsMetadata.forEach(column => {
        if (!column.isSummable) {
          return;
        }
        foundSummables = true;

        if (!this.rowGroupMetadata[group]) {
          this.rowGroupMetadata[group] = {
            index: 0,
            sums: {},
            size: 0,
          };
        }

        if (!this.rowGroupMetadata[group].sums) {
          this.rowGroupMetadata[group].sums = {};
        }
        if (!this.rowGroupMetadata[group].sums[column.key]) {
          this.rowGroupMetadata[group].sums[column.key] = {
            value: 0,
            text: '',
          };
        }
        this.rowGroupMetadata[group].sums[column.key].value +=
          +rowData[column.key];
      });

      if (!foundSummables) {
        this.tableMetadata.groupTotalsPosition = 'none';
      }
    }

    for (const rowData of data) {
      const group = rowData[this.tableMetadata.groupBy.field];

      this.tableMetadata.columnsMetadata.forEach(column => {
        if (!column.isSummable) {
          return;
        }

        this.rowGroupMetadata[group].sums[column.key].text = this.valueToText(
          column,
          this.rowGroupMetadata[group].sums[column.key].value
        );
      });
    }
  }

  /**
   * Converts a value to its presentation form based on the corresponding pipes
   *
   * @param columnMetadata the metadata for the column, expects a `pipe` property
   * @param value the value to be transformed
   */
  private valueToText(columnMetadata: ColumnMetadata, value: any) {
    if (!columnMetadata.pipe) {
      return value + '';
    } else {
      return this.dynamicPipe.transform(
        value,
        columnMetadata.pipe.pipe,
        columnMetadata.pipe.args
      );
    }
  }

  // PERF: can't this be merged with group totals?
  //   no because group summary is adjusted on filter, and table totals are not!
  private calculateTotals(data: TableDataRow[]): TableSums {
    const tableSummary = this.initializeTableSummary(
      this.tableMetadata.columnsMetadata
    );

    // Calculate sums
    data.forEach(row => {
      this.tableMetadata.columnsMetadata.forEach(columnMetadata => {
        if (columnMetadata.isSummable) {
          const key = columnMetadata.key;

          // Only consider if parseable number if
          const parsed = Number(row[key]);
          if (!isNaN(parsed)) {
            tableSummary[key].sum += parsed;
          }
        }
      });
    });

    // Parse table summary to text with the pipes
    this.tableMetadata.columnsMetadata.forEach(columnMetadata => {
      const key = columnMetadata.key;

      if (tableSummary.hasOwnProperty(key)) {
        tableSummary[key] = {
          ...tableSummary[key],
          text: this.dynamicPipe.transform(
            tableSummary[key].sum,
            columnMetadata.pipe.pipe,
            columnMetadata.pipe.args
          ),
        };
      }
    });

    return tableSummary;
  }

  private initializeTableSummary(columnsMetadata: ColumnMetadata[]): TableSums {
    const tableSummary: TableSums = {};

    columnsMetadata.forEach(columnMetadata => {
      if (columnMetadata.isSummable) {
        tableSummary[columnMetadata.key] = { sum: 0, text: '' };
      }
    });
    return tableSummary;
  }

  private resetFilter(key: string) {
    this.table.filter(null, key, 'contains');
  }
}
