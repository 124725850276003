<p-accordion class="p-col-12">
  <p-accordionTab header="Poster" [selected]="true">
    <p-message
      severity="error"
      *ngIf="!!taxReductionErrors"
      [text]="taxReductionErrors"
      [escape]="false"
    ></p-message>

    <div style="width: 100%; overflow-x: auto">
      <p-table
        class="inline-table"
        [formGroup]="invoiceBodyForm"
        [value]="rowDataContainers"
      >
        <ng-template pTemplate="header">
          <tr>
            <th
              *ngIf="isTaxReduction"
              style="width: 3rem; text-align: center"
              pTooltip="Husarbete
              Husarbete bockas för i de rader i fakturan som är skattereduktionsgrundande."
            >
              <i class="pi pi-home"></i>
            </th>

            <th *ngIf="showProductsDropDown" style="width: 8rem">Artikel</th>
            <th style="width: 8rem">Benämning</th>

            <th
              *ngIf="
                isTaxReduction && invoiceCreatedWithUseRowBasedTaxReduction
              "
              style="width: 4rem"
            >
              {{ invoiceTypeString }}
              <br />
              Antal
            </th>
            <th
              *ngIf="
                isTaxReduction && invoiceCreatedWithUseRowBasedTaxReduction
              "
              style="width: 5.5rem"
            >
              {{ invoiceTypeString }} Typ
            </th>

            <th style="width: 4rem">Antal</th>
            <th style="width: 4.5rem">Enhet</th>
            <th style="width: 5rem">Moms</th>
            <th style="width: 6.5rem">Apris</th>
            <th style="width: 3.5rem">Rabatt</th>
            <th style="width: 6.5rem">Pris</th>
            <th style="width: 5.5rem">Projekt</th>
            <th
              *ngIf="hasAccountingPlanFunction && context !== 'supplierInvoice'"
              style="width: 5rem"
            >
              Konto
            </th>
            <th
              *ngIf="context !== 'supplierInvoice'"
              [style.width]="mode !== 'update' ? '5.5rem' : '3rem'"
            ></th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-ix="rowIndex"
          let-itm
          formArrayName="rowDataContainers"
        >
          <ng-template ngFor let-rix="index" let-row [ngForOf]="itm.rows">
            <tr>
              <td
                *ngIf="isTaxReduction"
                style="text-align: center"
                class="p-text-truncate"
              >
                <p-checkbox
                  [(ngModel)]="row.arbetskostnad"
                  [ngModelOptions]="{ standalone: true }"
                  [binary]="true"
                  [class.has-danger]="noRot"
                  (onChange)="noRot = null"
                ></p-checkbox>
              </td>
              <td
                *ngIf="showProductsDropDown"
                [pEditableColumn]="row.product"
                pEditableColumnField="product"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete
                      [(ngModel)]="row.product"
                      [ngModelOptions]="{ standalone: true }"
                      [suggestions]="companyProducts"
                      (completeMethod)="searchProducts($event)"
                      (onSelect)="setProductFromAutocomplete($event, row)"
                      (onClear)="removeProductFromRow(row)"
                      field="artnr"
                      [minLength]="1"
                      [style]="{ width: '100%' }"
                      appendTo="body"
                    >
                      <ng-template pTemplate="header">
                        <div class="p-grid p-text-bold">
                          <div class="p-col-3 p-text-nowrap">Artnr</div>
                          <div class="p-col-7">Benämning</div>
                        </div>
                      </ng-template>
                      <ng-template let-product pTemplate="item">
                        <div class="p-grid" style="margin: 0; padding: 0.2rem">
                          <div class="p-col-3 p-text-nowrap p-text-left">
                            {{ product.artnr }}
                          </div>
                          <div
                            class="p-col-7 p-text-left"
                            style="white-space: pre-line"
                          >
                            {{ product.benamning }}
                          </div>
                        </div>
                      </ng-template>
                    </p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.product?.artnr }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                [pEditableColumn]="row.benamning"
                pEditableColumnField="benamning"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      [disabled]="context === 'supplierInvoice'"
                      type="text"
                      pInputText
                      [(ngModel)]="row.benamning"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.benamning }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                *ngIf="
                  isTaxReduction && invoiceCreatedWithUseRowBasedTaxReduction
                "
                [pEditableColumn]="row.houseWorkHoursToReport"
                pEditableColumnField="houseWorkHoursToReport"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      [disabled]="context === 'supplierInvoice'"
                      type="text"
                      pInputText
                      [(ngModel)]="row.houseWorkHoursToReport"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.houseWorkHoursToReport }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                *ngIf="
                  isTaxReduction && invoiceCreatedWithUseRowBasedTaxReduction
                "
                class="p-text-truncate"
                [pEditableColumn]="row.houseWorkType"
                pEditableColumnField="houseWorkType"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      appDisableArrowNavigation
                      [(ngModel)]="row.houseWorkType"
                      [ngModelOptions]="{ standalone: true }"
                      [options]="houseWorkTypeTypes"
                      [disabled]="context === 'supplierInvoice'"
                      appendTo="body"
                      (onChange)="
                        ix === 0 && rix === 0
                          ? setHouseWorkType($event.value)
                          : null
                      "
                    ></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ houseWorkTypeTypesMap[row.houseWorkType] }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                [pEditableColumn]="row.antal"
                pEditableColumnField="antal"
                *ngIf="itm.isFree"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      type="text"
                      style="width: 100%"
                      [disabled]="context === 'supplierInvoice'"
                      (change)="recalc(itm, rix)"
                      [(ngModel)]="row.antal"
                      [ngModelOptions]="{ standalone: true }"
                      pInputText
                      pKeyFilter="num"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.antal | number: '1.0-2' }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td *ngIf="!itm.isFree">{{ row.antal | number: '1.0-2' }}</td>

              <td
                class="p-text-truncate"
                [pEditableColumn]="row.enhet"
                pEditableColumnField="enhet"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      appDisableArrowNavigation
                      [(ngModel)]="row.enhet"
                      [options]="units"
                      [disabled]="context === 'supplierInvoice'"
                      [ngModelOptions]="{ standalone: true }"
                      panelStyleClass="invoice-body-dropdown"
                      filter="true"
                      appendTo="body"
                    ></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.enhet }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td
                [pEditableColumn]="row.tax"
                pEditableColumnField="tax"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      appDisableArrowNavigation
                      [(ngModel)]="row.tax"
                      [ngModelOptions]="{ standalone: true }"
                      [options]="vats"
                      [disabled]="context === 'supplierInvoice'"
                      (ngModelChange)="recalc(itm, rix)"
                      panelStyleClass="invoice-body-dropdown"
                      appendTo="body"
                    ></p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.tax !== 99 ? row.tax : 'Endast benämning' }}
                    {{ row.tax !== 99 ? '%' : '' }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                *ngIf="itm.isFree"
                [pEditableColumn]="row.apris"
                pEditableColumnField="apris"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      type="text"
                      (change)="recalc(itm, rix)"
                      style="width: 100%"
                      [disabled]="context === 'supplierInvoice'"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="row.apris"
                      pInputText
                      pKeyFilter="num"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.apris | currency: 'SEK' }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                class="p-text-truncate"
                *ngIf="!itm.isFree && (itm.isAprisSame || itm.isMultiRow)"
              >
                {{ row.apris | currency: 'SEK' }}
              </td>

              <td
                class="p-text-truncate"
                *ngIf="!itm.isFree && !itm.isAprisSame && !itm.isMultiRow"
              >
                <span>{{ row.apris | currency: 'SEK' }}</span>
              </td>

              <td
                [pEditableColumn]="row.discount"
                pEditableColumnField="discount"
                class="p-text-truncate"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      type="text"
                      maxlength="5"
                      [disabled]="context === 'supplierInvoice'"
                      style="width: 100%"
                      (change)="recalc(itm, rix)"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="row.discount"
                      pInputText
                      pKeyFilter="num"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.discount }} %
                  </ng-template>
                </p-cellEditor>
              </td>
              <td class="p-text-truncate">
                <span>{{ +row.pris | currency: 'SEK' }}</span>
              </td>
              <td
                class="p-text-truncate"
                [pEditableColumn]="row.trueId"
                pEditableColumnField="trueId"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      appDisableArrowNavigation
                      filter="true"
                      [options]="projectTrueIds"
                      [(ngModel)]="row.trueId"
                      placeholder="{{
                        row.trueId ? row.trueId : 'Välj projekt'
                      }}"
                      [ngModelOptions]="{ standalone: true }"
                      optionLabel="label"
                      (onChange)="actionSetProjectId(row, $event.value)"
                      panelStyleClass="invoice-body-dropdown"
                      appendTo="body"
                    >
                      <ng-template let-item pTemplate="selectedItem">
                        {{ item.label }}
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.trueId }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td
                *ngIf="
                  hasAccountingPlanFunction && context !== 'supplierInvoice'
                "
                [pEditableColumn]="row.accountingPlanAccountId"
                pEditableColumnField="accountingPlanAccountId"
                class="p-text-truncate"
                style="max-width: 12rem"
              >
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-dropdown
                      appDisableArrowNavigation
                      *ngIf="companyAccountingPlanAccounts"
                      [disabled]="
                        mode !== 'create' && row.accountingPlanAccountId
                      "
                      [(ngModel)]="row.accountingPlanAccountId"
                      [ngModelOptions]="{ standalone: true }"
                      [options]="accountingPlanAccountsDropdownParams.options"
                      panelStyleClass="invoice-body-dropdown"
                      appendTo="body"
                      filter="true"
                      filterBy="label"
                    >
                      <ng-template let-item pTemplate="item">
                        {{ item.label }}
                      </ng-template>

                      <ng-template let-item pTemplate="selectedItem">
                        {{ item.label }}
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{
                      accountingPlanAccountsDropdownItemMap[
                        row.accountingPlanAccountId
                      ]?.label
                    }}
                  </ng-template>
                </p-cellEditor>
              </td>

              <td *ngIf="context !== 'supplierInvoice'">
                <span
                  class="p-d-flex p-jc-center"
                  [class.p-jc-between]="mode !== 'update'"
                >
                  <button
                    pButton
                    class="p-button-text legacy-rounded-button p-button-icon-danger"
                    (click)="actionDeleteRow(row, ix)"
                    icon="pi pi-trash"
                  ></button>
                  <button
                    *ngIf="mode !== 'update'"
                    pButton
                    class="p-button-text legacy-rounded-button"
                    (click)="
                      toggleRows(itm); setFilterSubtypes(itm.mainTypeId, ix)
                    "
                    style="margin-left: 10px"
                    icon="pi pi-chevron-right "
                    [style.transform]="itm.open ? 'rotate(90deg)' : ''"
                  ></button>
                </span>
              </td>
            </tr>
          </ng-template>

          <tr *ngIf="itm.open" style="background-color: #fff !important">
            <td
              [colSpan]="
                9 +
                (hasAccountingPlanFunction && context !== 'supplierInvoice'
                  ? 1
                  : 0) +
                (isTaxReduction ? 3 : 0) +
                (showProductsDropDown ? 1 : 0)
              "
            >
              <div class="p-grid">
                <p-checkbox
                  class="p-col-12"
                  [(ngModel)]="itm.isMultiRow"
                  [ngModelOptions]="{ standalone: true }"
                  [binary]="true"
                  (onChange)="buildRows(itm)"
                  label="Skriv ut alla rader"
                >
                </p-checkbox>

                <p-table
                  #sub
                  [value]="itm.rowDetail"
                  selectionMode="multiple"
                  [(selection)]="itm.rowDetailSelected"
                  (onRowSelect)="onRowSelect($event, itm)"
                  (onRowUnselect)="onRowUnselect($event, itm)"
                  class="inline-table p-col-12"
                >
                  <ng-template pTemplate="header">
                    <tr style="background-color: #ababab !important">
                      <th style="text-align: center; width: 3.5rem">
                        <p-tableHeaderCheckbox
                          #headerCheckbox
                          (click)="
                            changeMarkForItmDetails(headerCheckbox.checked, itm)
                          "
                        ></p-tableHeaderCheckbox>
                      </th>
                      <th pSortableColumn="display.name">
                        Benämning
                        <p-sortIcon
                          field="display.name"
                          class="pull-right"
                        ></p-sortIcon>
                      </th>
                      <th pSortableColumn="date">
                        Sparad
                        <p-sortIcon
                          field="date"
                          class="pull-right"
                        ></p-sortIcon>
                      </th>
                      <th pSortableColumn="apris">
                        Apris
                        <p-sortIcon
                          field="apris"
                          class="pull-right"
                        ></p-sortIcon>
                      </th>
                      <th pSortableColumn="antal">
                        Antal
                        <p-sortIcon
                          field="antal"
                          class="pull-right"
                        ></p-sortIcon>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template
                    pTemplate="body"
                    let-detail
                    let-columns="columns"
                    let-rowIndex="rowIndex"
                  >
                    <tr>
                      <td style="text-align: center">
                        <p-tableCheckbox
                          [value]="detail"
                          [disabled]="hasCheckedTwin(detail)"
                        ></p-tableCheckbox>
                        <span *ngIf="hasCheckedTwin(detail)">
                          Vald på annan rad
                        </span>
                        <span *ngIf="detail.invoiceId > 0"> Fakturerad </span>
                      </td>
                      <td style="white-space: break-spaces">
                        {{ detail.display?.name }}
                      </td>
                      <td style="white-space: break-spaces">
                        {{ detail.display?.date }}
                      </td>
                      <td style="white-space: break-spaces">
                        {{ detail.apris }}
                      </td>
                      <td style="white-space: break-spaces">
                        {{ detail.antal }}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="p-col-12 align-right">
      <button
        pButton
        *ngIf="context !== 'supplierInvoice'"
        class="p-button-primary-hyperion"
        (click)="addEmptyRowContainer()"
        label="Ny rad"
        icon="pi pi-plus"
      ></button>
    </div>
    <div class="p-col-12">
      {{ invoiceTypeSubInfo }}
    </div>
  </p-accordionTab>
</p-accordion>
