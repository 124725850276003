<hyperion-card>
  <div class="feature-preview">
    <img *ngIf="image" class="feature-preview-image" src="{{ image }}" />
    <div class="feature-preview-text">
      <h1>Förhandsvisning av {{ feature }}</h1>

      <ng-content></ng-content>

      <p>Vill du veta mer?</p>

      <p>
        Du kan chatta med våra duktiga medarbetare här och få mer information
        eller kontakta oss på {{ supportPhone }} eller {{ supportMail }}.
      </p>

      <p>Eller varför inte boka in en demo?</p>
      <button (click)="bookDemoHandler()" [disabled]="isSending">
        Boka demo
      </button>
      <span *ngIf="!hideReturnButton" class="feature-preview-back-link">
        <a (click)="goBackHistory()"> &larr; Nej tack, ta mig tillbaka</a>
      </span>
    </div>
  </div>
</hyperion-card>
