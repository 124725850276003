<div class="p-grid">
  <ng-container *ngIf="!subprojects">
    <span class="p-col-8 p-mt-2">
      Nedan listas det som redan är fakturerat för detta projekt. Detta räknas
      samman och utgör "Summa A-conto" under "Intäkt för projektet".
    </span>
    <p-divider class="p-col-12"></p-divider>
  </ng-container>
  <p-table
    [value]="acontos"
    class="p-col-12 inline-table scrollable-tall"
    [responsiveLayout]="'scroll'"
    sortField="fdatum"
    [sortOrder]="-1"
  >
    <ng-template pTemplate="header">
      <tr>
        <th [style.width]="'auto'">Fakturanummer</th>
        <th [style.width]="'8rem'">Fakturadatum</th>
        <th *ngIf="!subprojects" [style.width]="'8rem'">Detta projektet</th>
        <th [style.width]="'8rem'">Summa</th>
      </tr>
    </ng-template>
    <ng-template let-aconto pTemplate="body" let-i="rowIndex">
      <tr>
        <td>
          <span class="p-d-flex p-jc-between p-ai-center">
            <a [routerLink]="['../../../', 'invoice', aconto.id]">
              <button
                pButton
                class="p-button-icon-success p-button-text p-p-2"
                [label]="aconto.fakturaNr"
              ></button>
            </a>
            <span class="p-d-flex p-ai-center p-jc-between">
              <i
                *ngIf="aconto.status === 1"
                class="pi pi-wallet"
                style="color: var(--btn-success-color)"
                pTooltip="Fakturan är betald"
              ></i>
              <i
                *ngIf="aconto.status === 0 && !aconto.paymentPassed"
                class="pi pi-wallet"
                pTooltip="Fakturan är ej betald"
              ></i>
              <i
                *ngIf="aconto.status === 0 && aconto.paymentPassed"
                style="color: var(--btn-danger-color)"
                class="pi pi-wallet"
                pTooltip="Fakturan är förfallen"
              ></i>
              <button
                pButton
                class="p-button-text legacy-rounded-button p-ml-2"
                icon="pi pi-ellipsis-v"
                (click)="menu.toggle($event)"
              >
                <p-menu
                  appendTo="body"
                  #menu
                  popup="true"
                  [model]="menus[aconto.id]"
                ></p-menu>
              </button>
            </span>
          </span>
        </td>
        <td>{{ aconto.fdatum }}</td>
        <td *ngIf="!subprojects" class="p-text-bold p-text-right">
          {{ aconto.invoicedByProject | currency: 'SEK' }}
        </td>
        <td class="p-text-bold p-text-right">
          {{ aconto.sumBox | currency: 'SEK' }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr>
        <td style="border-right: none">Totalt:</td>
        <td></td>
        <td *ngIf="!subprojects" class="p-text-bold p-text-right">
          {{ totalThisProject | currency: 'SEK' }}
        </td>
        <td class="p-text-bold p-text-right">{{ total | currency: 'SEK' }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
