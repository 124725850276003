import { Apollo } from 'apollo-angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';

import { ApolloMutationService } from 'app/shared/apollo/index';
import { FormHandlerService } from 'app/shared/forms/index';
import { GlobalService } from 'app/shared/global/index';
import { queries } from 'app/shared/apollo/queries/index';

import { ButtonsAsync } from 'app/shared/forms/buttons-async';
import { HtmlModalService } from 'app/shared/html-modal';

@Component({
  selector: 'app-offer-document-handle',
  templateUrl: './offer-document-handle.component.html',
  providers: [FormHandlerService],
})
export class OfferDocumentHandleComponent implements OnInit {
  @Input() public parentId;
  @Input() public dataObjectFromParent;
  @Input() public dataModelCapitalized;
  @Output() public updateEmitter = new EventEmitter();

  formFields = {
    model: 'DocumentMeta',
    attributes: {},
  };
  componentMainFormFields: {};
  componentMainForm: FormGroup;
  dataModel = 'documentMeta';
  fieldsForForm;
  setFields = {};
  buttons: { document: ButtonsAsync } = {
    document: {},
  };
  buttonObject = ['update', 'delete'];
  urlPrefix;
  public documentUrl: string;
  public documentPreviewUrl: string;

  constructor(
    private angularApollo: Apollo,
    private mutationService: ApolloMutationService,
    private formHandler: FormHandlerService,
    private confirmationService: ConfirmationService,
    private globalService: GlobalService,
    private htmlModalService: HtmlModalService
  ) {}

  public ngOnInit(): void {
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.buttons.document = this.formHandler.getButtonValuesAsync(
      this.buttonObject
    );

    this.initForm();
    this.documentPreviewUrl = this.getDocumentPreviewUrl();
    this.documentUrl = this.getDocumentUrl();
  }

  private initForm(): void {
    this.formFields.attributes = { comment: this.dataObjectFromParent.comment };
    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then(() => {
        this.componentMainForm.controls['comment']['label'] = 'Beskrivning';
      });
  }

  public actionUpdate(): void {
    if (this.formHandler.formValid([this.componentMainForm])) {
      const crudType = 'update';
      const dataToMutation = {
        id: Number(this.dataObjectFromParent.id),
        comment: this.componentMainForm.controls['comment'].value,
      };

      this.formHandler.lockButtonsAsync(this.buttons['document']);

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          this.dataModel,
          crudType,
          false,
          dataToMutation
        )
        .subscribe(
          executedData => {
            this.formHandler.showServerErrorsOnAttributes(executedData, [
              {
                form: this.componentMainForm,
                argument: crudType + this.dataModelCapitalized,
              },
            ]);
            if (executedData.mutationSucceededAllArguments) {
              this.updateEmitter.emit();
            }

            this.formHandler.unlockButtonsAsync(this.buttons.document);

            this.mutationService.displayMutationStatus(executedData);

            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
            this.formHandler.unlockButtonsAsync(this.buttons.document);
          }
        );
    }
  }

  public actionDelete(): void {
    this.formHandler.lockButtonsAsync(this.buttons.document, true);

    this.confirmationService.confirm({
      message: 'Är du säker på att du vill ta bort dokumentet?',
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteDocument();
      },
      reject: () => {
        this.formHandler.unlockButtonsAsync(this.buttons.document, true);
      },
    });
  }

  private deleteDocument(): void {
    const crudType = 'delete';

    const dataToMutation = { id: Number(this.dataObjectFromParent.id) };

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        this.dataModel,
        crudType,
        false,
        dataToMutation
      )
      .subscribe(
        data => {
          this.formHandler.showServerErrorsOnAttributes(
            data,
            this.componentMainForm
          );

          if (data.mutationSucceededAllArguments) {
            this.updateEmitter.emit();
          }
          this.mutationService.displayMutationStatus(data);

          this.formHandler.unlockButtonsAsync(this.buttons.document, true);

          executeMutationSub.unsubscribe();
        },
        err => {
          console.log(err);
          this.formHandler.unlockButtonsAsync(this.buttons.document, true);
        }
      );
  }

  private getDocumentUrl(): string {
    return (
      this.urlPrefix +
      '/offer/getDocument?file=' +
      this.dataObjectFromParent.file +
      '&offerId=' +
      this.dataObjectFromParent.offerId
    );
  }

  private getDocumentPreviewUrl(): string {
    return `${this.getDocumentUrl()}&output=show`;
  }

  public openDocument(): void {
    this.htmlModalService.ny_sida(this.documentPreviewUrl, 900, 800);
  }
}
