<div class="p-grid single-offer">
  <div class="p-col-12 p-g-nopad">
    <form [formGroup]="componentMainForm" class="no-margin">
      <appForm2-simple-textarea
        [rows]="2"
        formControlName="comment"
      ></appForm2-simple-textarea>
    </form>
  </div>

  <div class="p-col-12 p-g-nopad align-right">
    <app-button
      [disabled]="buttonDisabled"
      (onClick)="actionDelete()"
      [type]="'delete'"
    ></app-button>
    <app-button
      class="ml5"
      [disabled]="buttonDisabled"
      (onClick)="actionUpdate()"
      [type]="'update'"
    ></app-button>
  </div>
</div>
