<ul class="docu-row-columns docu-row-width-{{ width }}">
  <li
    class="docu-row-column docu-row-column-width-{{ col.col.with }}"
    *ngFor="let col of columns; index as i; trackBy: trackColById"
  >
    <app-docu-column
      [column]="col"
      [showLabel]="showLabels"
      [showField]="!labelsOnly"
      [showChildren]="!labelsOnly"
      (docChange)="docChange.emit($event)"
    ></app-docu-column>
  </li>
</ul>
