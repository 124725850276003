import { Injectable } from '@angular/core';
import { ApolloMutationService } from 'app/shared/apollo';
import { Observable } from 'rxjs';
import { Project, ProjectTodo, UserPlannedWork } from './planner-query-types';

@Injectable({
  providedIn: 'root',
})
export class PlannerWriteService {
  private readonly projectModel = 'project';
  private readonly plannedWorkModel = 'userPlannedWork';
  private readonly workTaskModel = 'todo';
  constructor(private mutationService: ApolloMutationService) {}

  public createPlannedTime(plannedTime: UserPlannedWork): Observable<any> {
    if (!plannedTime) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.plannedWorkModel,
      'create',
      false,
      plannedTime
    );
  }

  public deletePlannedTime(id: number): Observable<any> {
    if (!id || id <= 0) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.plannedWorkModel,
      'delete',
      false,
      {
        id,
      }
    );
  }

  public updatePlannedTime = (
    plannedTime: Partial<UserPlannedWork>
  ): Observable<any> => {
    if (!plannedTime) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.plannedWorkModel,
      'update',
      false,
      plannedTime
    );
  };

  public updateProject = (project: Partial<Project>): Observable<any> => {
    if (!project || !project.id) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.projectModel,
      'update',
      false,
      project
    );
  };

  public createWorkTask = (workTask: ProjectTodo): Observable<any> => {
    if (!workTask) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.workTaskModel,
      'create',
      false,
      workTask
    );
  };

  public updateWorkTask = (workTask: Partial<ProjectTodo>): Observable<any> => {
    if (!workTask) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.workTaskModel,
      'update',
      false,
      workTask
    );
  };

  public deleteWorkTask = (id: number): Observable<any> => {
    if (!id || id <= 0) {
      return null;
    }

    return this.mutationService.constructQueryAndExecuteMutation(
      this.workTaskModel,
      'delete',
      false,
      {
        id,
      }
    );
  };
}
