import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';

import { SharedDialogModule } from 'app/shared/dialogs/shared.dialog.module';
import { HyperionSharedModule } from '../shared/shared.module';
import { SingleOfferModule } from './single/single-offer.module';
import { OfferIndexComponent } from './index/index.component';
import { OfferCreateComponent } from './create/create.component';
import { OfferComponent } from './offer/offer.component';
import { HeaderModule } from 'app/header/header.module';
import { SplitButtonModule } from 'primeng/splitbutton';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    HyperionSharedModule,
    SingleOfferModule,
    SharedDialogModule,
    HeaderModule,
    SplitButtonModule,
  ],
  declarations: [OfferIndexComponent, OfferCreateComponent, OfferComponent],
  exports: [OfferIndexComponent],
})
export class OfferModule {}
