import * as Types from '../../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type FetchProjectProductUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchProjectProductUsersQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', users?: { __typename?: 'UserConnection', edges?: Array<{ __typename?: 'UserEdge', node?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null } | null };

export type FetchProjectProductCompanyCostTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchProjectProductCompanyCostTypesQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', companyCostTypes?: { __typename?: 'CompanyCostTypeConnection', edges?: Array<{ __typename?: 'CompanyCostTypeEdge', node?: { __typename?: 'CompanyCostType', id: string, name?: string | null, procent?: number | null, active?: number | null } | null } | null> | null } | null } | null };

export type FetchProjectProductQueryVariables = Types.Exact<{
  projectId?: Types.InputMaybe<Types.Scalars['Int']>;
  productId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type FetchProjectProductQuery = { __typename?: 'HyperionSchemaQuery', project?: { __typename?: 'Project', projectCostTypes?: { __typename?: 'ProjectCostTypeConnection', edges?: Array<{ __typename?: 'ProjectCostTypeEdge', node?: { __typename?: 'ProjectCostType', id: string, name?: string | null, procent?: number | null, companyCostTypeId?: string | null, products?: { __typename?: 'ProjectproductConnection', edges?: Array<{ __typename?: 'ProjectproductEdge', node?: { __typename?: 'Projectproduct', id: string, artnr?: string | null, antal?: number | null, benamning?: string | null, avtalspris?: number | null, avtalsprisCost?: number | null, date?: string | null, enhet?: string | null, extra?: string | null, companyCostTypeId?: number | null, projectCostTypeId?: number | null, userId?: number | null, projectId?: number | null, source?: string | null, sourceId?: string | null } | null } | null> | null } | null } | null } | null> | null } | null } | null };

export const FetchProjectProductUsersDocument = gql`
    query fetchProjectProductUsers {
  company {
    users {
      edges {
        node {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectProductUsersGQL extends Apollo.Query<FetchProjectProductUsersQuery, FetchProjectProductUsersQueryVariables> {
    document = FetchProjectProductUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProjectProductCompanyCostTypesDocument = gql`
    query fetchProjectProductCompanyCostTypes {
  company {
    companyCostTypes {
      edges {
        node {
          id
          name
          procent
          active
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectProductCompanyCostTypesGQL extends Apollo.Query<FetchProjectProductCompanyCostTypesQuery, FetchProjectProductCompanyCostTypesQueryVariables> {
    document = FetchProjectProductCompanyCostTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProjectProductDocument = gql`
    query fetchProjectProduct($projectId: Int, $productId: Int) {
  project(id: $projectId) {
    projectCostTypes {
      edges {
        node {
          id
          name
          procent
          companyCostTypeId
          products(id: $productId) {
            edges {
              node {
                id
                artnr
                antal
                benamning
                avtalspris
                avtalsprisCost
                date
                enhet
                antal
                extra
                companyCostTypeId
                projectCostTypeId
                userId
                projectId
                source
                sourceId
              }
            }
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProjectProductGQL extends Apollo.Query<FetchProjectProductQuery, FetchProjectProductQueryVariables> {
    document = FetchProjectProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }