<app-header>
  <app-button (click)="exportExcel()" [type]="'getExcel'"></app-button>
  <button
    pButton
    class="p-button-primary-hyperion"
    [label]="typeString === 'leverantör' ? 'Skapa leverantör' : 'Skapa kund'"
    (click)="openCreateContactDialog()"
    icon="pi pi-plus"
  ></button>
</app-header>

<p-sidebar showCloseIcon="false" [(visible)]="showForm" position="right">
  <h1>{{ selectedContactId ? 'Redigera' : 'Skapa' }} {{ typeString }}</h1>
  <app-contact-form
    [context]="
      typeString === 'leverantör'
        ? contactFormContext.Supplier
        : contactFormContext.Customer
    "
    [contactId]="selectedContactId"
    (contactUpdatedEvent)="onContactUpdated($event)"
  >
  </app-contact-form>
</p-sidebar>

<div class="page-container">
  <div class="p-grid">
    <div class="p-col print-no-show">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="fa fa-search"></i></span>
        <input
          type="text"
          pInputText
          [disabled]="loading"
          (input)="
            customerSupplierTable.filterGlobal($event.target.value, 'contains')
          "
          placeholder="Sök {{ typeString }}er"
        />
      </div>
    </div>
    <div class="p-inputgroup print-no-show" class="p-col-3 p-xl-2 align-right">
      <app-button
        [disabled]="loading"
        (onClick)="resetSearchParams()"
        [type]="'showExtendSearch'"
      ></app-button>
    </div>

    <div class="p-col-12" *ngIf="showAdvancedSearch">
      <p-accordion>
        <p-accordionTab header="Grunduppgifter" [selected]="true">
          <div class="p-grid">
            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'trueId', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['trueId'] } }"
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter(
                      $event,
                      'orderBuisnessName',
                      'contains'
                    )
                  "
                  [htmlAttributes]="{
                    label: { value: label['orderBuisnessName'] }
                  }"
                ></appForm2-simple-input>

                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'orgNr', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['orgNr'] } }"
                ></appForm2-simple-input>
              </div>
            </div>

            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'name', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['name'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'address', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['address'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'mail', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['mail'] } }"
                ></appForm2-simple-input>
              </div>
            </div>
            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'address2', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['address2'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'cityCode', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['cityCode'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'plusgiro', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['plusgiro'] } }"
                ></appForm2-simple-input>
              </div>
            </div>
            <div class="p-col-12 p-md-3">
              <div class="p-col-12">
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'city', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['city'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter(
                      $event,
                      'mobilePhone',
                      'contains'
                    )
                  "
                  [htmlAttributes]="{ label: { value: label['mobilePhone'] } }"
                ></appForm2-simple-input>
                <appForm2-simple-input
                  [ngModel]="''"
                  (ngModelChange)="
                    customerSupplierTable.filter($event, 'bankgiro', 'contains')
                  "
                  [htmlAttributes]="{ label: { value: label['bankgiro'] } }"
                ></appForm2-simple-input>
              </div>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="clear-both"></div>

    <div class="p-col-12 p-g-nopad">
      <spinner *ngIf="loading"></spinner>
      <div [style.display]="loading ? 'none' : 'block'">
        <p-table
          #customerSupplierTable
          dataKey="id"
          [columns]="cols"
          [rowHover]="true"
          [value]="dataSetAsync | async"
          [rowTrackBy]="helperService.rowTrackBy"
          rowExpandMode="single"
          [sortField]="sort.attribute"
          [responsive]="true"
          [sortOrder]="sort.ascDesc"
          (onSort)="changeSort($event)"
          paginatorPosition="bottom"
          [rowsPerPageOptions]="[50, 100, 250, 500]"
          [rows]="50"
          [paginator]="(dataSetAsync | async).length > 50"
          (sortFunction)="naturalSortOrder($event)"
          [customSort]="true"
          class="p-text-truncate"
          responsiveLayout="scroll"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
                [style.min-width]="col.width"
                class="p-text-truncate"
              >
                {{ col.header }}
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="body"
            let-rowData
            let-expanded="expanded"
            let-columns="columns"
          >
            <tr [pRowToggler]="rowData" pSelectableRow>
              <td *ngFor="let col of columns" class="p-text-truncate">
                <span class="p-column-title">{{ col.header }}</span>
                {{
                  col.field === 'orderBuisnessName' &&
                  rowData['contactType'] === 'Privat'
                    ? '-'
                    : rowData[col.field]
                }}
              </td>
            </tr>
          </ng-template>

          <ng-template
            pTemplate="rowexpansion"
            let-rowData
            let-columns="columns"
            let-contact="rowData"
          >
            <tr class="p-table-expanded-row">
              <td [attr.colspan]="cols.length" class="contact-expanded-cell">
                <app-contact-detail
                  *ngIf="type === 'supplier'"
                  (objectDeleted)="onContactDeleted($event)"
                  (objectUpdated)="onContactUpdated($event)"
                  [id]="rowData['id']"
                  [typeString]="typeString"
                  [type]="type"
                ></app-contact-detail>

                <ng-container *ngIf="type !== 'supplier'">
                  <p-tabView
                    class="contact-tabs"
                    (onChange)="handleTabChange($event)"
                  >
                    <p-tabPanel header="Kundkort">
                      <app-contact-detail
                        (objectDeleted)="onContactDeleted($event)"
                        (objectUpdated)="onContactUpdated($event)"
                        [id]="rowData['id']"
                        [typeString]="typeString"
                        [type]="type"
                      ></app-contact-detail>
                    </p-tabPanel>
                    <p-tabPanel
                      header="Fakturor ({{
                        totalInvoices[rowData['id']] || 0
                      }})"
                      [disabled]="!totalInvoices[rowData['id']]"
                    >
                      <app-contact-invoices
                        (totalCount)="totalInvoices[rowData['id']] = $event"
                        [contact]="rowData"
                        [triggerReload$]="
                          loadInvoicesDataSubject.asObservable()
                        "
                      ></app-contact-invoices>
                    </p-tabPanel>
                    <p-tabPanel
                      header="Projekt ({{ totalProjects[rowData['id']] || 0 }})"
                      [disabled]="!totalProjects[rowData['id']]"
                    >
                      <app-contact-projects
                        (totalCount)="totalProjects[rowData['id']] = $event"
                        [contact]="rowData"
                        [triggerReload$]="
                          loadProjectsDataSubject.asObservable()
                        "
                      ></app-contact-projects>
                    </p-tabPanel>
                  </p-tabView>
                </ng-container>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage" let-columns>
            <tr class="emptyStateTR">
              <td [attr.colspan]="columns.length">
                <app-emptyState
                  [model]="'Contact'"
                  [whereToCreate]="'topRight'"
                ></app-emptyState>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
