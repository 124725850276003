import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { ConfirmationService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

import { FormHandlerService } from 'app/shared/forms';
import { ApolloMutationService } from 'app/shared/apollo';
import { ApolloQueryService } from 'app/shared/apollo';
import { HelperService } from 'app/shared/helpers/index';
import { FormatNumberPipe } from 'app/shared/pipes/format-number.pipe';
import { GlobalInvoiceService } from '../../../global-services/invoice/invoice.service';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.scss'],
})
export class InvoicePaymentComponent implements OnInit, OnDestroy {
  cols = [
    { field: 'date', header: 'Datum' },
    { field: 'sum', header: 'Summa inbetalning' },
  ];
  formFields = {
    model: 'InvoicePayment',
    attributes: {},
  };

  payments: {};
  balance = 0;
  fieldsForForm = {};
  componentMainForm: FormGroup;
  setFields = {};
  data: any;
  invoicePaymentsSub: Subscription;

  constructor(
    private apolloQueryService: ApolloQueryService,
    private formHandler: FormHandlerService,
    private mutationService: ApolloMutationService,
    private confirmationService: ConfirmationService,
    private helperService: HelperService,
    private globalInvoiceService: GlobalInvoiceService,
    private dialog: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogdata();
    }
    this.getInvoicePayments();
  }

  private initComponentWithDialogdata() {
    this.data = this.dialogConfig['data']['vars'];
    setTimeout(() => {
      const amount = new FormatNumberPipe();
      this.dialogConfig.header = `Betalningar för faktura ${
        this.data.invoice.fakturaNr
      } (Debiterat belopp: ${amount.transform(this.data.invoice.totalBox)}kr)`;
    }, 1);
  }

  setComponentMainForm(rowsSum) {
    this.balance = this.data.invoice.totalBox - rowsSum;

    this.formFields.attributes = {
      sum: this.balance,
      date: moment().format(this.helperService.dateFormat()),
    };

    this.componentMainForm = this.formHandler.groupedFormSimple(
      this.formFields
    );
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.componentMainForm)
      .then();
  }

  ngOnDestroy() {
    this.invoicePaymentsSub && this.invoicePaymentsSub.unsubscribe();
  }

  closeDialog = () => this.dialog.close();

  createPayment() {
    const crudType = 'create';
    const dataToMutation = Object.assign(
      this.mutationService.getMutationDataFromForm(this.componentMainForm),
      { invoiceId: this.data.invoice.id }
    );

    if (this.formHandler.formValid([this.componentMainForm])) {
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'invoicePayment',
          crudType,
          false,
          dataToMutation,
          null,
          null
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);

            if (executedData.mutationSucceededAllArguments) {
              this.getInvoicePayments();
              this.closeDialog();
            }

            this.globalInvoiceService.mutationOcured([this.data.invoice.id]); // Get invoices,
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  deletePayment(payment) {
    this.confirmationService.confirm({
      message: 'Vill du radera betalningen?',
      header: 'Radera',
      icon: 'fa fa-trash',
      accept: () => {
        const dataToMutation = {
          id: Number(payment.id),
          invoiceId: Number(payment.invoiceId),
        };
        const executeMutationSub = this.mutationService
          .constructQueryAndExecuteMutation(
            'invoicePayment',
            'delete',
            false,
            dataToMutation
          )
          .subscribe(
            executedData => {
              this.mutationService.displayMutationStatus(executedData);
              this.getInvoicePayments();
              this.globalInvoiceService.mutationOcured([payment.invoiceId]); // Get invoices,

              executeMutationSub.unsubscribe();
            },
            err => {
              console.warn(err);
            }
          );
      },
      reject: () => {},
    });
  }

  getInvoicePayments() {
    const variables = { id: this.data.invoice.id };
    let paymentsSum = 0;
    this.apolloQueryService
      .apolloWatchQueryTwo('invoicePayments', variables)
      .subscribe(data => {
        this.invoicePaymentsSub = data.sub;
        const invoice = this.helperService.cleanFromNode(
          data.data.company.invoices
        )[0];
        this.payments = this.helperService.cleanFromNode(invoice.payments);

        for (const p in this.payments) {
          const pm = this.payments[p];
          paymentsSum += +pm.sum;
        }

        this.setComponentMainForm(paymentsSum);
      });
  }

  public getPaymentCreator(user): string {
    if (!user || !user.firstName || !user.lastName) {
      return 'System';
    }
    return user.firstName + ' ' + user.lastName;
  }
}
