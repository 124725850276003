import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HyperionSharedModule } from '../shared/shared.module';
import { HandleModule } from 'app/attendance-report/handle/handle.module';
import { ConnectionsModule } from 'app/attendance-report/connections/connections.module';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { SubcontractorModule } from 'app/attendance-report/subcontractor/subcontractor.module';
import { AttendanceReportService } from 'app/attendance-report/services/attendance-report.service';
import { CollectionListModule } from 'app/attendance-report/collection-list/collection-list.module';

@NgModule({
  imports: [
    CommonModule,
    HandleModule,
    ConnectionsModule,
    CollectionListModule,
    SubcontractorModule,
    HyperionSharedModule,
    CollectionListModule,
  ],
  declarations: [AttendanceReportComponent],
  providers: [AttendanceReportService],
})
export class AttendanceReportModule {}
