import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ContextMenuStatusEnum,
  EventColorsEnum,
} from 'app/planner/planner-module-enums';
import {
  ContextMenuEvent,
  SchedulerEventProject,
  UpdateColorEvent,
} from 'app/planner/planner-module-interfaces';
import { Observable } from 'rxjs';
import { ColorNames } from '../color-names';

@Component({
  selector: 'app-color-context-menu',
  templateUrl: './color-context-menu.component.html',
  styleUrls: ['./color-context-menu.component.scss'],
})
export class ColorContextMenuComponent implements OnInit, OnDestroy {
  @ViewChild('colorContextMenu') public contextMenu: ElementRef;

  @Input() public title: string;
  @Input() public contextMenuEventObserver: Observable<ContextMenuEvent>;

  @Output()
  public updateColorEvent: EventEmitter<UpdateColorEvent> = new EventEmitter<UpdateColorEvent>();

  private currentId: number;
  private isProject: boolean;

  public colors: string[];
  public posX: number;
  public posY: number;
  public activeColor: string;

  constructor() {}

  public ngOnInit(): void {
    this.generateColorList();
    this.addContextMenuListeners();

    this.contextMenuEventObserver.subscribe(event =>
      this.handleContextMenuEvent(event)
    );
  }

  public ngOnDestroy(): void {
    this.removeContextMenuListeners();
  }
  private generateColorList(): void {
    this.colors = [];
    this.colors = Object.keys(EventColorsEnum).map(c => EventColorsEnum[c]);
  }

  public updateColor(color: string): void {
    const event: UpdateColorEvent = {
      id: this.currentId,
      color,
      isProject: this.isProject,
    };
    this.updateColorEvent.emit(event);
  }

  private toogleMenu(state: string): void {
    switch (state) {
      case ContextMenuStatusEnum.ON:
        this.contextMenu.nativeElement.style.display = 'block';
        break;
      case ContextMenuStatusEnum.OFF:
      default:
        this.contextMenu.nativeElement.style.display = 'none';
        break;
    }
  }

  private escapeKeyListener = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      this.toogleMenu(ContextMenuStatusEnum.OFF);
    }
  };

  private resizeListener = () => {
    this.toogleMenu(ContextMenuStatusEnum.OFF);
  };

  private clickListener = (e: PointerEvent) => {
    if (e.target !== this.contextMenu.nativeElement) {
      this.toogleMenu(ContextMenuStatusEnum.OFF);
    }
  };

  private addContextMenuListeners(): void {
    window.addEventListener('keyup', this.escapeKeyListener);
    window.addEventListener('resize', this.resizeListener);
    window.addEventListener('click', this.clickListener);
  }

  private removeContextMenuListeners(): void {
    window.removeEventListener('keyup', this.escapeKeyListener);
    window.removeEventListener('resize', this.resizeListener);
    window.removeEventListener('click', this.clickListener);
  }

  private positionContextMenu(event: any): void {
    const clickCoords = {
      x:
        event.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft,
      y:
        event.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop,
    };

    const menuWidth = this.contextMenu.nativeElement.offsetWidth + 10;
    const menuHeight = this.contextMenu.nativeElement.offsetHeight + 10;
    const footer = document.querySelector<HTMLElement>('.hyperion-footer');

    if (window.innerWidth - clickCoords.x < menuWidth) {
      this.posX = window.innerWidth - menuWidth - 30;
    } else {
      this.posX = clickCoords.x;
    }

    if (clickCoords.y + menuHeight > window.innerHeight) {
      this.posY = window.innerHeight - menuHeight - footer.offsetHeight;
    } else {
      this.posY = clickCoords.y - menuHeight;
    }
  }

  private handleContextMenuEvent = (event: ContextMenuEvent) => {
    this.toogleMenu(ContextMenuStatusEnum.OFF);
    if (event.id) {
      this.activeColor = event.schedulerEvent.color.toUpperCase();
      this.toogleMenu(ContextMenuStatusEnum.ON);
      this.currentId = event.id;
      this.isProject = !!(event.schedulerEvent as SchedulerEventProject).trueId;
      this.positionContextMenu(event.event);

      return false;
    }
    return true;
  };

  public getColorName(color: EventColorsEnum): string {
    return ColorNames[color];
  }
}
