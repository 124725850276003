import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import { ApolloErrorHandler } from 'app/shared/apollo';
import { CompanySuperService } from '../company.super.service';

@Injectable()
export class ProjectDefaultTodosService extends CompanySuperService {
  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode) {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
      case 'listNew':
        dataSetFormatted = this.makeObjectsForFormsNew(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    const dataArray = data.project['todos'].edges;
    const dataObjectsWithLabels = [];

    const nullObject = {
      label: 'Välj arbetsmoment',
      value: null,
    };
    dataObjectsWithLabels.push(nullObject);

    for (const i in dataArray) {
      const dataObject = {
        label: dataArray[i].node.description,
        value: dataArray[i].node.id,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }

  makeObjectsForForms(data) {
    const dataArray = data.project['todos'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      dataObjectsForForms.push({ ...dataArray[i].node });
    }

    return dataObjectsForForms;
  }

  makeObjectsForFormsNew(data) {
    const dataArray = data.company['projectDefualtTodos'].edges;
    const dataObjectsForForms = [];

    for (const i in dataArray) {
      dataObjectsForForms.push({ ...dataArray[i].node });
    }

    return dataObjectsForForms;
  }
}
