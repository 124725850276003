import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { CompanySuperService } from './company.super.service';

import { ApolloErrorHandler } from 'app/shared/apollo';

import { Observable, Subscription } from 'rxjs';

@Injectable()
export class CompanyAttendanceTypeService extends CompanySuperService {
  dataModel = 'attendanceTypes_PreCompanyTypeHyperion';

  constructor(apollo: Apollo, apolloErrorHandler: ApolloErrorHandler) {
    super(apollo, apolloErrorHandler);
  }

  dataFormater(data, mode: 'labels' | 'list') {
    let dataSetFormatted;

    switch (mode) {
      case 'labels':
        dataSetFormatted = this.makeLabelsArray(data);
        break;
      case 'list':
        dataSetFormatted = this.makeObjectsForForms(data);
        break;
    }

    return dataSetFormatted;
  }

  makeLabelsArray(data) {
    /*
            För aktuell modell:

        */

    const dataArray = data.company[this.dataModel].edges;
    const dataObjectsWithLabels = [];

    for (const i in dataArray) {
      const dataObject = {
        // create object to push
        label: dataArray[i].node.name,
        value: dataArray[i].node.code,
      };
      dataObjectsWithLabels.push(dataObject);
    }
    return dataObjectsWithLabels;
  }
  makeObjectsForForms(data) {
    const dataArray = data.company[this.dataModel].edges;
    const dataObjectsForForms = [];

    dataArray.forEach(_ => {
      dataObjectsForForms.push({});
    });

    return dataObjectsForForms;
  }
}
